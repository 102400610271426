import { Box, Typography } from "@material-ui/core"
import React, { Fragment } from "react"

const AccountTypeChecker = () => {
    return (
        <Fragment>
            <Box display="flex" width="100%" height="calc(100vh - 120px)" justifyContent="center" alignItems="center">
                <Box display="block">
                    <Typography>
                        Your account is not authorized to access this website.
                        But you can try login with this URL:
                    </Typography>
                </Box>
                <Box display="block">
                    <Typography variant="h6" color="primary">
                        <a
                            href="https://www.gtcdoc.com"
                            onClick={() => {
                                localStorage.removeItem('hmis_token');
                                localStorage.removeItem('hmis_page');
                            }}
                        >
                            GTC DOC
                        </a>
                    </Typography>
                </Box>
            </Box>
        </Fragment>
    )
}
export default AccountTypeChecker;