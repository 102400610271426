import React, { Fragment } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const HepatitisProfilePrintAll = ({
  allowHeaderPatientInfo,
  patients,
  hepatitisProfOrderDetails,
}) => {
  return (
    <>
      {hepatitisProfOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                HEPATITIS PROFILE
              </span>
            </Typography>
          </Box>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      TEST
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      RESULT
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      NORMAL VALUES
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      REMARKS
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {hepatitisProfOrderDetails.data.map((data, index) => {
                  return (
                    <Fragment key={index}>
                      <TableRow hidden={data.hbsag_quali === null}>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            HBsAG(Quali)
                          </span>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.hbsag_quali}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Non Reactive
                          </span>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.hbsag_quali_remarks}
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.antihbs_quali === null}>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Anti-HBs(Quali)
                          </span>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                color: "#000",
                              }}
                            >
                              {data.antihbs_quali}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Reactive
                          </span>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.antihbs_quali_remarks}
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.antihcv_quali === null}>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Anti-HCV(Quali)
                          </span>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                color: "#000",
                              }}
                            >
                              {data.antihcv_quali}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Non Reactive
                          </span>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.antihcv_quali_remarks}
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.hbsag_quanti === null}>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            HBsAG(Quanti)
                          </span>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                color: "#000",
                              }}
                            >
                              {data.hbsag_quanti}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <span
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >{` > 2.0 (Reactive)`}</span>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.hbsag_quanti_remarks}
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.antihbs_quanti === null}>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Anti-HBs(Quanti)
                          </span>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                color: "#000",
                              }}
                            >
                              {data.antihbs_quanti}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            ABOVE 10 IU/L (Reactive)
                          </span>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.antihbs_quanti_remarks}
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.hbeaag === null}>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            HBeAg
                          </span>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                color: "#000",
                              }}
                            >
                              {data.hbeaag}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Reactive : {` > 0.105`}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.hbeaag_remarks}
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.antihbe === null}>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Anti-HBe
                          </span>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                color: "#000",
                              }}
                            >
                              {data.antihbe}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Non Reactive : {` > 0.81`}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.antihbe_remarks}
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.antihbc_igm === null}>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Anti-BHc(IgM)
                          </span>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                color: "#000",
                              }}
                            >
                              {data.antihbc_igm}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Non Reactive :
                            {` > OR = 1.68; Reactive : < OR = 1.68 `}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.antihbc_igm_remarks}
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.antihav_igm === null}>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Anti-HAV(IgM)
                          </span>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                color: "#000",
                              }}
                            >
                              {data.antihav_igm}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Negative
                          </span>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.antihav_igm_remarks}
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.anti_havigm_igg === null}>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Anti-HAVIGM/IGG
                          </span>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                color: "#000",
                              }}
                            >
                              {data.anti_havigm_igg}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Negative
                          </span>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.anti_havigm_igg_remarks}
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.antihbc_iggtotal === null}>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Anti-HBc(IgG Total)
                          </span>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                color: "#000",
                              }}
                            >
                              {data.antihbc_iggtotal}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Reactive : {`<1.0`}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.antihbc_iggtotal_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Box my={1} align="center">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {hepatitisProfOrderDetails.data.length > 0 &&
                        hepatitisProfOrderDetails.data[0].pathologist}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Pathologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {hepatitisProfOrderDetails.data.length > 0 &&
                          hepatitisProfOrderDetails.data[0].pathologist_lcn}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xs={12} sm={4}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {hepatitisProfOrderDetails.data.length > 0 &&
                        hepatitisProfOrderDetails.data[0].chief_medtech}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Chief Med Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {hepatitisProfOrderDetails.data.length > 0 &&
                          hepatitisProfOrderDetails.data[0].chief_medtech_lci}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      <span
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                      >
                        {hepatitisProfOrderDetails.data.length > 0 &&
                          hepatitisProfOrderDetails.data[0].medtech}
                      </span>
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Medical Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {hepatitisProfOrderDetails.data.length > 0 &&
                            hepatitisProfOrderDetails.data[0].lic_no}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default HepatitisProfilePrintAll;
