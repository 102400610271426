import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { useForm } from "react-hook-form";
import Img from "src/components/Img";
import FlatIcon from "src/components/FlatIcon";
import ActionBtn from "src/components/ActionBtn";
import axios from "axios";
import TextInputField from "src/components/forms/TextInputField";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ListItem = ({ onClick, personnel }) => {
	console.log("personnelpersonnel", personnel);
	return (
		<div
			className={`flex items-center gap-4 p-3 rounded-xl border bg-opacity-40 ${
				personnel?.selected ? "bg-foreground border-primary" : ""
			}`}
		>
			<Img
				type={"user"}
				name={personnel?.name}
				src={personnel?.avatar}
				className="w-11 h-11 rounded-full border border-border"
			/>
			<div className="flex flex-col">
				<span className="text-sm text-primary font-semibold mb-1 gap-2 flex items-center">
					<FlatIcon icon="rr-id-badge" /> {personnel?.name}
				</span>
				<span className="text-xs text-placeholder  mb-1 gap-2 flex items-center">
					<FlatIcon icon="rr-briefcase" /> {personnel?.type}
				</span>
				{/* <span className="text-xs text-placeholder gap-2 flex items-center">
					<FlatIcon icon="rr-map-marker" />{" "}
					{` ${personnel?.purok?.name}, ${personnel?.barangay?.name}, ${personnel?.municipality?.name}`}
				</span> */}
			</div>
			<div className=" ml-auto flex items-center gap-4">
				{personnel?.selected ? (
					<div
						className="text-white bg-danger flex items-center justify-center  h- rounded-md gap-2 cursor-pointer p-2"
						onClick={onClick}
					>
						<FlatIcon icon="rr-cross-small" />
						Unselect
					</div>
				) : (
					<div
						className="text-white bg-primary flex items-center justify-center  h- rounded-md gap-2 cursor-pointer p-2"
						onClick={onClick}
					>
						<FlatIcon icon="rr-check" />
						Select
					</div>
				)}
			</div>
		</div>
	);
};
const AssignClinicPersonnelModal = (props, ref) => {
	const { staticModal, refreshData, clinic_personnel, setClinicPersonnel } =
		props;
	const [open, setOpen] = useState(false);
	const [data, setData] = useState(null);
	const [keyword, setKeyword] = useState("");
	const [loading, setLoading] = useState(false);
	const [list, setList] = useState([]);
	const [selectedList, setSelectedList] = useState([]);
	const btnRef = useRef(null);

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));
	useEffect(() => {
		getList();
	}, [keyword]);
	const show = (showData) => {
		setSelectedList(clinic_personnel);
		setOpen(true);
		getList();
	};
	const hide = () => {
		setOpen(false);
	};
	const params = useParams();

	const isSelected = (person) => {
		return clinic_personnel?.findIndex((x) => x.id == person.id) > 0;
	};

	const getList = () => {
		axios
			.get(
				`v1/management/clinic-personnel?keyword=${keyword}&clinic_id=${params.id}&paginate=100`
			)
			.then((res) => {
				let results = res.data.data?.map((x) => {
					console.log(
						"isSelected(x)isSelected(x)",
						x,
						clinic_personnel,
						isSelected(x)
					);
					if (isSelected(x)) {
						return {
							...x,
							selected: true,
						};
					}
					return x;
				});
				setList(results);
			});
	};

	const submit = () => {
		let selected_list = list.filter((x) => x.selected);
		let formData = new FormData();
		formData.append("_method", "PATCH");
		console.log("selectedList", selectedList);
		selectedList.map((x) => {
			formData.append("user_id[]", x.id);
		});
		axios
			.post(`v1/management/clinic-personnel/${params?.id}`, formData)
			.then((res) => {
				setClinicPersonnel(selected_list);
				setTimeout(() => {
					refreshData();
					toast.success("Clinic personnel updated successfully!");
				}, 200);
				hide();
			});
	};

	const addToSelected = (data) => {
		setSelectedList((prevList) => [...prevList, data]);
	};
	const removeFromSelected = (data) => {
		setSelectedList((prevList) =>
			prevList.filter((x) => x?.id != data?.id)
		);
	};
	const checkIfSelected = (data) => {
		return selectedList?.find((x) => x.id == data?.id) ? true : false;
	};
	return (
		<Modal
			open={open}
			hide={hide}
			className={`w-[768px] min-w-[768px] max-w-[768px]`}
			staticModal={staticModal}
		>
			<ModalHeader title={"Assign clinic personnel"} hide={hide} />
			<ModalBody className={`!pt-6 !px-0 bg-white `}>
				<div className="flex items-center px-6 pb-6">
					<TextInputField
						inputClassName=" pl-10"
						className="w-full lg:w-[320px]"
						onChange={(e) => {
							setKeyword(e.target.value);
						}}
						icon={<FlatIcon icon="rr-search" className="text-sm" />}
						placeholder="Search"
					/>
				</div>
				<div className="max-h-[512px] overflow-auto flex flex-col gap-y-4 pb-6 px-6">
					{/* {console.log("llliiittst", list)} */}
					{list.map((item) => {
						// console.log("ittemmmm", item);
						return (
							<ListItem
								personnel={{
									...item,
									selected: checkIfSelected(item),
								}}
								key={`lp-${item?.id}-${
									item?.selected ? "selected" : "-"
								}`}
								onClick={(e) => {
									if (checkIfSelected(item)) {
										removeFromSelected(item);
									} else {
										addToSelected(item);
									}
									// setList((currentList) =>
									// 	currentList.map((person) => {
									// 		if (person.id == item?.id) {
									// 			return {
									// 				...person,
									// 				selected:
									// 			};
									// 		}
									// 		return person;
									// 	})
									// );
								}}
							/>
						);
					})}
				</div>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button
					size="md"
					onClick={submit}
					loading={loading}
					ref={btnRef}
				>
					Save clinic personnel
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(AssignClinicPersonnelModal);
