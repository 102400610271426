import {
	Box,
	Dialog,
	TextField,
	Typography,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
	Grid,
	// IconButton,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Notify from "../../notification/Notify";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "../../utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
// import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
// import Specimen from "./Specimen";

const StoolTestDetails = ({
	queue,
	formheader,
	order,
	getLabStoolTestOrder,
	resetDisplay,
	process_for,
}) => {
	const { users } = React.useContext(UsersData);
	const history = useHistory();
	const [orderDetails, setOrderDetails] = useState({
		data: [],
		ready: false,
	});

	// const [microscopic, setMicroscopy] = useState({
	//   dfs: false,
	//   kt: false,
	//   kk: false,
	// });

	const [pendingDialog, setPendingDialog] = useState(false);
	const [processDialog, setProcessDialog] = useState({
		data: null,
		dialog: false,
	});

	const [pendingSubmitting, setPendingSubmitting] = useState(false);
	const [processSubmitting, setProcessSubmitting] = useState(false);
	const [resultSubmitting, setResultSubmitting] = useState(false);
	const [savebtnDisabled, setSavebtnDisabled] = useState(true);

	const [orderItems, setOrderItems] = useState([]);

	const getStooltestOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order.trace_number };
		Axios.get("laboratory/order/ordernew-stooltest/details", { params })
			.then((response) => {
				const data = response.data;
				setOrderDetails({
					data: data,
					ready: true,
				});

				checkIfSaveBtnIsEnabled(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const checkIfSaveBtnIsEnabled = (data) => {
		for (let i = 0; i < data.length; i++) {
			if (Boolean(data[i].is_processed)) {
				setSavebtnDisabled(false);
			}
		}
	};

	const handleSaveResult = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("trace_number", order.trace_number);
		formdata.set("queue", queue);
		// formdata.set("dfs", microscopic.dfs ? 1 : 0);
		// formdata.set("kt", microscopic.kt ? 1 : 0);
		// formdata.set("kk", microscopic.kk ? 1 : 0);
		formdata.set("process_for", process_for);

		var error = [];

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setResultSubmitting(true);
			Axios.post(
				"laboratory/order/ordernew-stooltest/save-process-result",
				formdata
			)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						setSavebtnDisabled(true);
						Notify.successRequest("order result added.");
						setTimeout(() => {
							history.push(
								`/sph/app/laboratory/record/print/order/${order.trace_number}`
							);
						}, 5000);
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				});
		}
	};

	const handlePendingOrder = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", order.order_id);

		var error = [];

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setPendingSubmitting(true);
			Axios.post("laboratory/order/ordernew-sorology/save-setpending", formdata)
				.then((response) => {
					const data = response.data;
					if (data === "pass-invalid") {
						Notify.fieldInvalid("password");
					}
					if (data === "success") {
						getLabStoolTestOrder();
						resetDisplay();
						setPendingDialog(false);
						Notify.successRequest("order pending");
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => setPendingSubmitting(false));
		}
	};

	const handleProcessOrder = async (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", processDialog.data.order_id);
		formdata.set("trace_number", processDialog.data.trace_number);

		var error = [];
		setProcessSubmitting(true);

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			let response = await Axios.post(
				"laboratory/stooltest/order-setprocess/custom-qty",
				formdata
			);

			if (response.data.message === "reagent-error") {
				Notify.customToast("Reagent Not Error", "Reagent/items not available.");
			}

			if (response.data.message === "pass-invalid") {
				Notify.fieldInvalid("password");
			}

			if (response.data.message === "success") {
				Notify.successRequest("process");
				setProcessDialog({ data: null, dialog: false });
				getStooltestOrderDetails();
			}
		}

		setTimeout(() => {
			setProcessSubmitting(false);
		}, 2000);
	};

	const handleProcessSelectedOrder = async (data) => {
		let response = await Axios.get(
			"laboratory/items/laborder/list-available-items",
			{
				params: {
					user_id: users.user_id,
					management_id: users.management_id,
					order_id: data.order_id,
				},
			}
		);

		setOrderItems(response.data);

		setProcessDialog({ data: data, dialog: true });
	};

	useEffect(() => {
		getStooltestOrderDetails();

		// eslint-disable-next-line
	}, [order]);

	return (
		<>
			<form onSubmit={handleSaveResult}>
				<Card elevation={0}>
					<CardHeader
						component={Box}
						align="center"
						title={formheader && formheader.name}
						subheader={
							<Box>
								<Typography>{formheader && formheader.address}</Typography>
								<Typography>
									{formheader && formheader.contact_number}
								</Typography>
							</Box>
						}
					/>

					<CardContent>
						{/* paitent information */}
						<Box display="flex">
							<Box flexGrow={1} mb={2}>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT NAME:{" "}
										</Typography>
										{`${order.lastname}, ${order.firstname} ${
											order.middle === null ? "" : order.middle
										}`}
									</Typography>
								</Box>

								<Box display="flex" mb={2}>
									<Box>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												AGE:{" "}
											</Typography>
											{order.birthday === null
												? "none"
												: Notify.calculateAge(order.birthday)}
										</Typography>
									</Box>
									<Box ml={5}>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												GENDER:{" "}
											</Typography>
											{order.gender === null ? "none" : order.gender}
										</Typography>
									</Box>
								</Box>

								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT ADDRESS:{" "}
										</Typography>
										{`${order.street} ${order.barangay} ${order.city} `}
									</Typography>
								</Box>

								<Box>
									<Typography variant="caption" className="font-weight-bold">
										PATIENT CONDITION:{" "}
									</Typography>
									{order.patient_condition === null
										? " none"
										: order.patient_condition}
								</Box>
							</Box>

							<Box>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											DATE:{" "}
										</Typography>
										{Notify.createdAt(new Date().toLocaleString())}
									</Typography>
								</Box>
							</Box>
						</Box>
					</CardContent>

					{/* <CardContent>
            <Specimen
              patient_id={order.patient_id}
              trace_number={order.trace_number}
            />
          </CardContent> */}

					<CardContent>
						{/* order details */}
						{orderDetails.ready
							? orderDetails.data.length > 0
								? orderDetails.data.map((data, index) => (
										<Fragment key={index}>
											{/* fecalysis order */}
											<Box hidden={!Boolean(parseInt(data.fecalysis))}>
												<Box mb={2} display="flex">
													<Box flexGrow={1}>
														<Box align="center">
															<Typography variant="h6">
																<b> FECALYSIS </b>
															</Typography>
														</Box>
													</Box>

													<Box>
														<Button
															disabled={Boolean(parseInt(data.is_processed))}
															size="small"
															variant="contained"
															color="primary"
															onClick={() => {
																handleProcessSelectedOrder(data);
															}}
														>
															Process
														</Button>
													</Box>
												</Box>

												<Box hidden={!Boolean(parseInt(data.is_processed))}>
													{/* hidden inputs */}
													<Box>
														<TextField
															margin="dense"
															fullWidth
															name="order_id[]"
															label="order_id"
															value={data.order_id}
															hidden
														/>
														<TextField
															margin="dense"
															fullWidth
															name="patient_id"
															label="patient_id"
															value={data.patient_id}
															hidden
														/>

														<TextField
															margin="dense"
															fullWidth
															name="doctors_id"
															label="doctor_id"
															value={
																data.doctor_id === null ? "" : data.doctor_id
															}
															hidden
														/>
													</Box>
													{/* fecal hidden if not process */}
													<Box>
														<Grid container spacing={1}>
															<Grid item xs={12}>
																<Box
																	width="100%"
																	style={{
																		backgroundColor: "#AEAEAE",
																	}}
																>
																	<Typography
																		style={{
																			color: "#000",
																			marginLeft: 20,
																		}}
																	>
																		<b>MACROSCOPIC</b>
																	</Typography>
																</Box>
															</Grid>

															<Grid item xs={6}>
																<Box>
																	<TextField
																		label="Color"
																		name="color[]"
																		fullWidth
																	/>
																</Box>
															</Grid>

															<Grid item xs={6}>
																<Box>
																	<TextField
																		label="Consistency"
																		name="consistency[]"
																		fullWidth
																	/>
																</Box>
															</Grid>

															{/* <Grid item xs={12} sm={4}>
                                <Box>
                                  <TextField
                                    label="Occult Blood"
                                    name="occult_blood[]"
                                    fullWidth
                                  />
                                </Box>
                              </Grid> */}
														</Grid>
													</Box>

													<Box>
														<Grid container spacing={1}>
															<Grid item xs={12}>
																<Box display={"flex"}>
																	<Box
																		width="50%"
																		style={{
																			backgroundColor: "#AEAEAE",
																		}}
																	>
																		<Typography
																			style={{
																				color: "#000",
																				marginLeft: 20,
																			}}
																		>
																			<b>MICROSCOPIC</b>
																		</Typography>
																	</Box>

																	<Box
																		width="50%"
																		style={{
																			backgroundColor: "#AEAEAE",
																		}}
																	>
																		<Typography
																			style={{
																				color: "#000",
																				marginLeft: 20,
																			}}
																		>
																			<b>PARASITE</b>
																		</Typography>
																	</Box>
																</Box>
															</Grid>

															<Grid item xs={6}>
																<Box>
																	<TextField
																		label="Pus Cells"
																		name="pus_cells[]"
																		fullWidth
																	/>
																</Box>

																<Box>
																	<TextField
																		label="RBC"
																		name="rbc[]"
																		fullWidth
																	/>
																</Box>

																<Box>
																	<TextField
																		label="Bacteria"
																		name="bacteria[]"
																		fullWidth
																	/>
																</Box>

																<Box>
																	<TextField
																		label="Yeast Cells"
																		name="yeast_cells[]"
																		fullWidth
																	/>
																</Box>

																<Box>
																	<TextField
																		label="Others"
																		name="others[]"
																		fullWidth
																	/>
																</Box>
															</Grid>

															<Grid item xs={6}>
																<Box>
																	<TextField
																		label="Ascaris Lumbricoides"
																		name="dfs_ascaris[]"
																		fullWidth
																	/>
																</Box>

																<Box>
																	<TextField
																		label="Hookworm"
																		name="dfs_hookworm[]"
																		fullWidth
																	/>
																</Box>

																<Box>
																	<TextField
																		label="Trichuris Trichura"
																		name="dfs_trichuris[]"
																		fullWidth
																	/>
																</Box>

																<Box>
																	<TextField
																		label="Enterobius Vermicularis"
																		name="enterobius_vermicularis[]"
																		fullWidth
																	/>
																</Box>

																<Box>
																	<TextField
																		label="Entamoeba Coli"
																		name="entamoeba_coli[]"
																		fullWidth
																	/>
																</Box>

																<Box>
																	<TextField
																		label="Entamoeba Histolytica"
																		name="entamoeba_histolytica[]"
																		fullWidth
																	/>
																</Box>
															</Grid>

															<Grid item xs={12}>
																<Box display={"flex"}>
																	<Box
																		width="50%"
																		style={{
																			backgroundColor: "#AEAEAE",
																		}}
																	>
																		<Typography
																			style={{
																				color: "#000",
																				marginLeft: 20,
																			}}
																		>
																			<b>RAPID IMMUNOASSAY</b>
																		</Typography>
																	</Box>

																	<Box
																		width="50%"
																		style={{
																			backgroundColor: "#AEAEAE",
																		}}
																	>
																		<Typography
																			style={{
																				color: "#000",
																				marginLeft: 20,
																			}}
																		>
																			<b>OTHERS</b>
																		</Typography>
																	</Box>
																</Box>
															</Grid>

															<Grid item xs={6}>
																<Box>
																	<TextField
																		label="Occult Blood"
																		name="occult_blood[]"
																		fullWidth
																	/>
																</Box>

																<Box>
																	<TextField
																		label="H.Pylori Stool"
																		name="hpylori_stool[]"
																		fullWidth
																	/>
																</Box>
															</Grid>

															<Grid item xs={6}>
																<Box>
																	<TextField
																		label="Remarks"
																		name="remarks[]"
																		fullWidth
																	/>
																</Box>
															</Grid>

															{/* <Grid item xs={12} sm={3}>
                                <Box mt={2}>
                                  <Typography>
                                    <strong> Microscopy </strong>
                                  </Typography>
                                </Box>

                                <Box mt={3}>
                                  <Typography>Ascaris Lumbricoides:</Typography>
                                </Box>

                                <Box>
                                  <Typography> Hookworm: </Typography>
                                </Box>

                                <Box mt={2}>
                                  <Typography>Blastocystis Hominis:</Typography>
                                </Box>

                                <Box mt={2.1}>
                                  <Typography>
                                    <strong> Giardia Lamblia </strong>
                                  </Typography>
                                </Box>

                                <Box>
                                  <Typography align="right"> Cyst: </Typography>
                                </Box>

                                <Box mt={1}>
                                  <Typography align="right">
                                    Trophozoite:
                                  </Typography>
                                </Box>

                                <Box mt={1}>
                                  <Typography> Trichuris Trichiura </Typography>
                                </Box>

                                <Box mt={1.3}>
                                  <Typography>
                                    <strong> Entamoeba Histolytica </strong>
                                  </Typography>
                                </Box>

                                <Box mt={2}>
                                  <Typography align="right"> Cyst: </Typography>
                                </Box>

                                <Box mt={1}>
                                  <Typography align="right">
                                    Trophozoite:
                                  </Typography>
                                </Box>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Box align="center">
                                  {microscopic.dfs ? (
                                    <IconButton
                                      onClick={() =>
                                        setMicroscopy({
                                          ...microscopic,
                                          dfs: false,
                                        })
                                      }
                                    >
                                      <CheckBox color="primary" />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={() =>
                                        setMicroscopy({
                                          ...microscopic,
                                          dfs: true,
                                        })
                                      }
                                    >
                                      <CheckBoxOutlineBlank />
                                    </IconButton>
                                  )}
                                  Direct Fecal Smear
                                </Box>

                                <Box>
                                  <TextField
                                    InputProps={{ disabled: !microscopic.dfs }}
                                    label=""
                                    name="dfs_ascaris[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    InputProps={{ disabled: !microscopic.dfs }}
                                    label=""
                                    name="dfs_hookworm[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    InputProps={{ disabled: !microscopic.dfs }}
                                    label=""
                                    name="dfs_blasto[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box mt={4}>
                                  <TextField
                                    InputProps={{ disabled: !microscopic.dfs }}
                                    label=""
                                    name="dfs_giadia_cyst[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    InputProps={{ disabled: !microscopic.dfs }}
                                    label=""
                                    name="dfs_giadia_trophozoite[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    InputProps={{ disabled: !microscopic.dfs }}
                                    label=""
                                    name="dfs_trichuris[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box mt={4}>
                                  <TextField
                                    InputProps={{ disabled: !microscopic.dfs }}
                                    label=""
                                    name="dfs_estamoeba_cyst[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    InputProps={{ disabled: !microscopic.dfs }}
                                    label=""
                                    name="dfs_estamoeba_trophozoite[]"
                                    fullWidth
                                  />
                                </Box>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Box>
                                  {microscopic.kt ? (
                                    <IconButton
                                      onClick={() =>
                                        setMicroscopy({
                                          ...microscopic,
                                          kt: false,
                                        })
                                      }
                                    >
                                      <CheckBox color="primary" />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={() =>
                                        setMicroscopy({
                                          ...microscopic,
                                          kt: true,
                                        })
                                      }
                                    >
                                      <CheckBoxOutlineBlank />
                                    </IconButton>
                                  )}
                                  Kato-Thick
                                </Box>

                                <Box>
                                  <TextField
                                    label=""
                                    disabled={!microscopic.kt}
                                    name="kt_ascaris[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    label=""
                                    disabled={!microscopic.kt}
                                    name="kt_hookworm[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    label=""
                                    disabled={!microscopic.kt}
                                    name="kt_blasto[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box mt={4}>
                                  <TextField
                                    label=""
                                    disabled={!microscopic.kt}
                                    name="kt_giadia_cyst[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    label=""
                                    disabled={!microscopic.kt}
                                    name="kt_giadia_trophozoite[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    label=""
                                    disabled={!microscopic.kt}
                                    name="kt_trichuris[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box mt={4}>
                                  <TextField
                                    label=""
                                    disabled={!microscopic.kt}
                                    name="kt_estamoeba_cyst[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    label=""
                                    disabled={!microscopic.kt}
                                    name="kt_estamoeba_trophozoite[]"
                                    fullWidth
                                  />
                                </Box>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Box>
                                  {microscopic.kk ? (
                                    <IconButton
                                      onClick={() =>
                                        setMicroscopy({
                                          ...microscopic,
                                          kk: false,
                                        })
                                      }
                                    >
                                      <CheckBox color="primary" />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={() =>
                                        setMicroscopy({
                                          ...microscopic,
                                          kk: true,
                                        })
                                      }
                                    >
                                      <CheckBoxOutlineBlank />
                                    </IconButton>
                                  )}
                                  Kato-Katz
                                </Box>

                                <Box align="center">
                                  <TextField
                                    disabled={!microscopic.kk}
                                    label=""
                                    name="kk_ascaris[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    disabled={!microscopic.kk}
                                    label=""
                                    name="kk_hookworm[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    disabled={!microscopic.kk}
                                    label=""
                                    name="kk_blasto[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box mt={4}>
                                  <TextField
                                    disabled={!microscopic.kk}
                                    label=""
                                    name="kk_giadia_cyst[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    disabled={!microscopic.kk}
                                    label=""
                                    name="kk_giadia_trophozoite[]"
                                    fullWidth
                                  />
                                </Box>
                                <Box>
                                  <TextField
                                    disabled={!microscopic.kk}
                                    label=""
                                    name="kk_trichuris[]"
                                    fullWidth
                                  />
                                </Box>
                                <Box mt={4}>
                                  <TextField
                                    disabled={!microscopic.kk}
                                    label=""
                                    name="kk_estamoeba_cyst[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    disabled={!microscopic.kk}
                                    label=""
                                    name="kk_estamoeba_trophozoite[]"
                                    fullWidth
                                  />
                                </Box>
                              </Grid> */}
														</Grid>
													</Box>

													{/* <Box>
                            <TextField
                              label="Other"
                              name="others[]"
                              multiline
                              fullWidth
                            />
                          </Box>

                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={4}>
                                <Box>
                                  <TextField
                                    label="Pus Cells"
                                    name="pus_cells[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    label="Red Blood Cells"
                                    name="rbc[]"
                                    fullWidth
                                  />
                                </Box>

                                <Box>
                                  <TextField
                                    label="Fat Globules"
                                    name="fat_globules[]"
                                    fullWidth
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Box>
                                  <TextField
                                    label="Yeast Cells"
                                    name="yeast_cells[]"
                                    fullWidth
                                  />
                                </Box>
                                <Box>
                                  <TextField
                                    label="Bacteria"
                                    name="bacteria[]"
                                    fullWidth
                                  />
                                </Box>
                                <Box>
                                  <TextField
                                    label="Oil Droplets"
                                    name="oil_droplets[]"
                                    fullWidth
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Box>
                                  <TextField
                                    label="Undigested Food Particles"
                                    name="undigested_food[]"
                                    fullWidth
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>

                          <Box>
                            <TextField
                              label="Remarks"
                              name="remarks[]"
                              multiline
                              fullWidth
                            />
                          </Box> */}
												</Box>
											</Box>
											{/* fecalysis order end */}
										</Fragment>
								  ))
								: Notify.noRecord()
							: Notify.loading()}
					</CardContent>
					<Box display="flex" m={1}>
						<Box flexGrow={1} />
						<Box>
							<CardActions>
								<Button
									variant="contained"
									size="large"
									color="primary"
									type="submit"
									disabled={savebtnDisabled}
									startIcon={
										resultSubmitting && (
											<CircularProgress size={20} color="inherit" />
										)
									}
								>
									Save Result
								</Button>
							</CardActions>
						</Box>
					</Box>
				</Card>
			</form>

			{/* set as pending dialog */}
			<Dialog
				open={pendingDialog}
				onClose={() => setPendingDialog(false)}
				disableBackdropClick
				PaperComponent={DraggableDialog}
			>
				<DialogTitle id="draggable-handle">Set as pending</DialogTitle>
				<form onSubmit={handlePendingOrder}>
					<DialogContent dividers>
						<Box mb={2}>
							<TextField
								rows={5}
								fullWidth
								name="reason"
								label={`Pending Reason`}
								variant="outlined"
								multiline
							/>
						</Box>

						<Box>
							<TextField
								fullWidth
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setPendingDialog(false)}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="secondary"
							type="submit"
							disabled={pendingSubmitting}
							startIcon={
								pendingSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<ErrorOutlineIcon />
								)
							}
						>
							pending
						</Button>
					</DialogActions>
				</form>
			</Dialog>

			{/* set as process dialog */}
			<Dialog
				open={processDialog.dialog}
				onClose={() => setProcessDialog({ data: null, dialog: false })}
				disableBackdropClick
				PaperComponent={DraggableDialog}
				maxWidth={"xs"}
				fullWidth
			>
				<DialogTitle id="draggable-handle">Process order</DialogTitle>
				<form onSubmit={handleProcessOrder}>
					<DialogContent dividers>
						{orderItems.length > 0
							? orderItems.map((data, index) => (
									<Box key={index} mb={2}>
										<Box>
											<TextField
												fullWidth
												name="item_id[]"
												value={data.item_id}
												variant="outlined"
												hidden
											/>
										</Box>

										<Box>
											<TextField
												fullWidth
												required
												label={`${data.description} qty to process`}
												name="qty[]"
												variant="outlined"
												defaultValue={1}
												InputLabelProps={{
													shrink: true,
												}}
												// InputProps={{
												//   inputProps: {
												//     min: 0,
												//     max: data._total_qty_available,
												//   },
												// }}
												type="number"
											/>
										</Box>
									</Box>
							  ))
							: "No reagent/item found."}

						<Box mt={2}>
							<TextField
								fullWidth
								required
								defaultValue="ok"
								name="remarks"
								label={`Remarks`}
								variant="outlined"
							/>
						</Box>

						<Box mt={2}>
							<TextField
								fullWidth
								required
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setProcessDialog({ data: null, dialog: false })}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={processSubmitting}
							startIcon={
								processSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<CheckCircleIcon />
								)
							}
						>
							process
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default StoolTestDetails;
