import FlatIcon from "src/components/FlatIcon";

const themes = {
	gray: {
		parentBg:
			"!bg-opacity-10 bg-gray-500 !border !border-gray-500 !border-opacity-20",
		titleColor: "text-gray-700",
		textColor: "text-gray-600",
	},
	blue: {
		parentBg:
			"!bg-opacity-10 bg-blue-500 !border !border-blue-500 !border-opacity-20",
		titleColor: "text-primary-dark",
		textColor: "text-primary-dark",
	},
	red: {
		parentBg:
			"!bg-opacity-10 bg-red-500 !border !border-red-500 !border-opacity-20",
		titleColor: "text-red-700",
		textColor: "text-red-600",
	},
	green: {
		parentBg:
			"!bg-opacity-10 bg-green-500 !border !border-green-500 !border-opacity-20",
		titleColor: "text-green-700",
		textColor: "text-green-600",
	},
	yellow: {
		parentBg:
			"!bg-opacity-10 bg-yellow-500 !border !border-yellow-500 !border-opacity-20",
		titleColor: "text-yellow-700",
		textColor: "text-yellow-500",
	},
	orange: {
		parentBg:
			"!bg-opacity-10 bg-orange-500 !border !border-orange-500 !border-opacity-20",
		titleColor: "text-orange-700",
		textColor: "text-orange-600",
	},
	pink: {
		parentBg:
			"!bg-opacity-10 bg-pink-500 !border !border-pink-500 !border-opacity-20",
		titleColor: "text-pink-700",
		textColor: "text-pink-600",
	},
};
const CensusCard = ({
	title,
	value,
	theme = "blue",
	icon = "rr-chart-histogram",
}) => {
	return (
		<div
			className={`rounded-xl ${themes[theme]?.parentBg} gap-2 flex py-4 px-4 flex-col justify-center relative`}
		>
			<h4
				className={`text-xl ${themes[theme]?.titleColor} font-bold mb-0`}
			>
				{title}
			</h4>
			<span className={`text-3xl ${themes[theme]?.textColor} font-bold`}>
				{value || 0}
			</span>
			<div className="absolute right-6 opacity-40">
				<FlatIcon
					icon={icon}
					className={`text-6xl ${themes[theme]?.textColor}`}
				/>
			</div>
		</div>
	);
};

export default CensusCard;
