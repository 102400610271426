import React from "react";
import { formatDate } from "src/helpers/utils";
import Header from "../editPatientProfile/Header";
import { styles } from "../../styles/styles";

export default function PersonalInformation({ patient }) {
  return (
    <>
      <Header title={"Personal Information"} />
      <div className="px-4 mb-3">
        <div style={styles.parentDivContainerNoGap}>
          <div className={styles.borderRight}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: " 100px auto",
                gap: "25px",
              }}
            >
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Prefix</label>
                <div className={styles.textContainer}>{patient?.prefix}</div>
              </div>
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Last Name</label>
                <div className={styles.textContainer}>{patient?.lastname}</div>
              </div>
            </div>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>First Name</label>
              <div className={styles.textContainer}>{patient?.firstname}</div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto 100px",
                gap: "25px",
              }}
            >
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Middle Name</label>
                <div className={styles.textContainer}>{patient?.middle}</div>
              </div>
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Suffix</label>
                <div className={styles.textContainer}>{patient?.suffix}</div>
              </div>
            </div>
            <div style={styles.divContainer}>
              <div style={styles.divContainerColumn}>
                <div style={styles.divContainer}>
                  <label className={styles.tuiLabel}>Sex</label>
                  <div className={styles.textContainer}>{patient?.gender}</div>
                </div>
                <div style={styles.divContainer}>
                  <label className={styles.tuiLabel}>Birth Date</label>
                  <div className={styles.textContainer}>
                    {formatDate(new Date(patient?.birthday))}
                  </div>
                </div>
              </div>
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Birth Place</label>
                <div className={styles.textContainer}>
                  {patient?.birthplace}
                </div>
              </div>
              <div style={styles.divContainerColumn}>
                <div style={styles.divContainer}>
                  <label className={styles.tuiLabel}>Civil Status</label>
                  <div className={styles.textContainer}>
                    {patient?.civil_status}
                  </div>
                </div>
                <div style={styles.divContainer}>
                  <label className={styles.tuiLabel}>
                    Educational Attainment
                  </label>
                  <div className={styles.textContainer}>
                    {patient?.education_attainment}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.borderLeft}>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Employment Status</label>
                <div className={styles.textContainer}>
                  {patient?.employment_status}
                </div>
              </div>
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Religion</label>
                <div className={styles.textContainer}>{patient?.religion}</div>
              </div>
            </div>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Indigenous</label>
                <div className={styles.textContainer}>
                  {patient?.indigenous === true ? "Yes" : "No"}
                </div>
              </div>
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Blood Type</label>
                <div className={styles.textContainer}>
                  {" "}
                  {patient?.blood_type}
                </div>
              </div>
            </div>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>Mother First Name</label>
              <div className={styles.textContainer}>
                {patient?.mother_firstname}
              </div>
            </div>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>Mother Last Name </label>
              <div className={styles.textContainer}>
                {patient?.mother_lastname}
              </div>
            </div>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>Mother Middle Name </label>
              <div className={styles.textContainer}>
                {patient?.mother_middlename}
              </div>
            </div>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>Mother Birthdate </label>
              <div className={styles.textContainer}>
                {formatDate(new Date(patient?.mother_birthdate))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
