import {
  Box,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import { User } from "react-feather";
import Container from "src/layout/Container";

const LaboratoryVirtualOrder = () => {
  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "virtual",
        items: [{ name: "Dashboard", path: "/sph/app" }],
      }}
      title="Virtual"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6" color="textSecondary">
                Patients With Order
              </Typography>
            </Box>

            <List component={Box}>
              <ListItem button>
                <ListItemIcon>
                  <User />
                </ListItemIcon>
                <ListItemText
                  className={`text-capitalize`}
                  primary={"data.name"}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={8} md={9}>
          <Paper>
            <Box p={2}>SSSSSSSSSS SSSSSSSSSSS</Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LaboratoryVirtualOrder;
