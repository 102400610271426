import React, {
	useEffect,
	Fragment,
	useCallback,
	useState,
	useContext,
} from "react";
import Axios from "axios";
import Notify from "src/notification/Notify";
import { Box, Paper, Tabs, Tab } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarCheck,
	faCheckDouble,
	faHeartbeat,
} from "@fortawesome/free-solid-svg-icons";
import PatientHeaderDetails from "./PatientHeaderDetails";
import PatientProfileDetails from "./vitals/PatientProfileDetails";
import PatientQueueAppointment from "./appointment/PatientQueueAppointment";
import { UsersData } from "src/ContextAPI";
import PatientSetAsDone from "./setasdone/PatientSetAsDone";

const PatientQueueDetails = ({
	patient_id,
	getNurseOnQueue,
	onClose,
	trace_number,
}) => {
	const [category, setCategory] = React.useState(0);
	const { users } = useContext(UsersData);
	const [info, setInfo] = React.useState({
		data: null,
		ready: false,
	});

	const [appointment, setAppointment] = useState({
		data: [],
		ready: false,
	});

	const getPatientInformation = useCallback(async () => {
		var params = { patient_id: patient_id };
		await Axios.get("nurse/patient/patient-information", { params })
			.then((response) => {
				const data = response.data;
				setInfo({
					data: data,
					ready: true,
				});
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	}, [patient_id]);

	const getAppointmentListLocal = useCallback(async () => {
		var params = { user_id: users.user_id, patient_id: patient_id };
		await Axios.get("encoder/patients/appointment/local/list", { params })
			.then((response) => {
				const data = response.data;
				setAppointment({ data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	}, [users, patient_id]);

	const handleChange = (event, newCategory) => {
		event.persist();
		setCategory(newCategory);
	};

	const closeSetAsDone = () => {
		setCategory(0);
	};

	useEffect(() => {
		getPatientInformation();
		getAppointmentListLocal();
		// eslint-disable-next-line
	}, [patient_id, getPatientInformation, getAppointmentListLocal]);

	return (
		<>
			{console.log("infoinfoinfo", info)}
			{info.ready ? (
				<Fragment>
					<PatientHeaderDetails
						getPatientInformation={() => getPatientInformation()}
						patient_id={patient_id}
						info={info}
					/>

					<Box mt={2} m={0} p={0}>
						<Box component={Paper} elevation={1} mb={2}>
							<Tabs
								value={category}
								onChange={handleChange}
								indicatorColor="primary"
								textColor="primary"
								variant="scrollable"
								scrollButtons="on"
							>
								<Tab
									wrapped
									label="Vitals"
									icon={
										<FontAwesomeIcon
											icon={faHeartbeat}
											size={"2x"}
										/>
									}
								/>

								<Tab
									wrapped
									label="Appointment"
									icon={
										<FontAwesomeIcon
											icon={faCalendarCheck}
											size={"2x"}
										/>
									}
								/>

								<Tab
									wrapped
									label="Set As Done"
									icon={
										<FontAwesomeIcon
											icon={faCheckDouble}
											size={"2x"}
										/>
									}
								/>
							</Tabs>
						</Box>

						<TabPanel value={category} index={0}>
							<PatientProfileDetails
								patient_id={patient_id}
								getNurseOnQueue={() => getNurseOnQueue()}
								getPatientInformation={() =>
									getPatientInformation()
								}
								info={info}
							/>
						</TabPanel>

						<TabPanel value={category} index={1}>
							<PatientQueueAppointment
								patient_id={patient_id}
								trace_number={trace_number}
								getAppointmentListLocal={() =>
									getAppointmentListLocal()
								}
								appointment={appointment}
							/>
						</TabPanel>

						<TabPanel value={category} index={2}>
							<PatientSetAsDone
								patient_id={patient_id}
								getNurseOnQueue={() => getNurseOnQueue()}
								info={info}
								closeSetAsDone={closeSetAsDone}
								onClose={() => onClose()}
							/>
						</TabPanel>
					</Box>
				</Fragment>
			) : (
				Notify.loading()
			)}
		</>
	);
};

export default PatientQueueDetails;
