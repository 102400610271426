import React, { Fragment, useCallback, useState } from "react";
import Container from "src/layout/Container";
import {
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  Collapse,
  Divider,
  Badge,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import AccountList from "./AccountList";
import axios from "axios";
// import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import QrDialog from "./QrDialog";
import Notify from "src/notification/Notify";

const useStyles = makeStyles(() => ({
  badgeOnline: {
    backgroundColor: "green",
    color: "#fff",
    marginTop: -4,
  },
}));

const HIMSManpower = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);
  const { users } = React.useContext(UsersData);
  const [accountActive, setAccountActive] = useState({
    data: [],
    ready: false,
  });
  const [qrDialog, setQrDialog] = useState({
    open: false,
    data: null,
    type: "",
  });

  const employeeGroup = [
    { name: "accounting" },
    { name: "billing" },
    { name: "cashier" },
    { name: "doctor" },
    { name: "documentation" },
    { name: "haptech" },
    { name: "hr" },
    { name: "imaging" },
    { name: "laboratory" },
    { name: "om" },
    { name: "psychology" },
    { name: "radiologist" },
    { name: "registration" },
    { name: "stockroom" },
    { name: "triage" },
    { name: "others" },
  ];

  const getAccountNotYetOut = useCallback(async () => {
    var params = { branch: users.management_id, user_id: users.user_id };
    await axios
      .get("hims/get-all/active-not-yet", { params })
      .then((response) => {
        const data = response.data;
        setAccountActive({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users]);

  React.useEffect(() => {
    getAccountNotYetOut();
  }, [getAccountNotYetOut]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "manpower",
          items: [{ name: "Dashboard", path: "/sph/app" }],
        }}
        title="Manpower"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box component={Paper} variant="outlined">
              <Box m={2}>
                {employeeGroup.length > 0
                  ? employeeGroup.map((data, index) => {
                      return (
                        <Fragment key={index}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={() => {
                              setQrDialog({
                                ...qrDialog,
                                type: data.name,
                              });
                            }}
                          >
                            <Box flexGrow={1}>
                              {accountActive.data.length > 0 ? (
                                <Badge
                                  badgeContent={
                                    data.name === "accounting"
                                      ? accountActive.data[0]
                                          .activeCountAccounting
                                      : data.name === "billing"
                                      ? accountActive.data[0].activeCountBilling
                                      : data.name === "cashier"
                                      ? accountActive.data[0].activeCountCashier
                                      : data.name === "doctor"
                                      ? accountActive.data[0].activeCountDoctor
                                      : data.name === "documentation"
                                      ? accountActive.data[0].activeCountEncoder
                                      : data.name === "haptech"
                                      ? accountActive.data[0].activeCountHaptech
                                      : data.name === "hr"
                                      ? accountActive.data[0].activeCountHr
                                      : data.name === "imaging"
                                      ? accountActive.data[0].activeCountImaging
                                      : data.name === "laboratory"
                                      ? accountActive.data[0]
                                          .activeCountLaboratory
                                      : data.name === "om"
                                      ? accountActive.data[0].activeCountOM
                                      : data.name === "pharmacy"
                                      ? accountActive.data[0]
                                          .activeCountPharmacy
                                      : data.name === "psychology"
                                      ? accountActive.data[0]
                                          .activeCountPsychology
                                      : data.name === "radiologist"
                                      ? accountActive.data[0]
                                          .activeCountRadiologist
                                      : data.name === "registration"
                                      ? accountActive.data[0]
                                          .activeCountRegistration
                                      : data.name === "stockroom"
                                      ? accountActive.data[0]
                                          .activeCountStockroom
                                      : data.name === "triage"
                                      ? accountActive.data[0].activeCountTriage
                                      : data.name === "warehouse"
                                      ? accountActive.data[0]
                                          .activeCountWarehouse
                                      : data.name === "others"
                                      ? accountActive.data[0].activeCountOthers
                                      : null
                                  }
                                  classes={{ badge: classes.badgeOnline }}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                >
                                  <Typography
                                    variant={"subtitle1"}
                                    color="primary"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    <strong> {data.name} </strong>
                                  </Typography>
                                </Badge>
                              ) : (
                                <Typography
                                  variant={"subtitle1"}
                                  color="primary"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  <strong> {data.name} </strong>
                                </Typography>
                              )}
                            </Box>

                            <Box>
                              <IconButton
                                variant="outlined"
                                color={"primary"}
                                onClick={() =>
                                  setExpanded(
                                    expanded === data.name ? null : data.name
                                  )
                                }
                              >
                                {expanded === data.name ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </IconButton>
                            </Box>
                          </Box>
                          <Collapse in={expanded === data.name}>
                            {expanded === data.name ? (
                              <AccountList
                                qrDialog={qrDialog}
                                setQrDialog={setQrDialog}
                                account={data.name}
                                accountActive={accountActive}
                              />
                            ) : null}
                          </Collapse>
                          {parseFloat(employeeGroup.length) - 1 !== index && (
                            <Divider />
                          )}
                        </Fragment>
                      );
                    })
                  : null}
              </Box>
            </Box>
          </Grid>
        </Grid>

        {qrDialog.open && (
          <QrDialog
            open={qrDialog.open}
            close={() => setQrDialog({ ...qrDialog, open: false, data: null })}
            qrDialog={qrDialog}
            setQrDialog={setQrDialog}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default HIMSManpower;
