import { TextField } from "@material-ui/core";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { dateToday, dateYYYYMMDD } from "src/helpers/utils";
import Clock from "react-live-clock";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { toast } from "react-toastify";
import Diagnosis from "src/utils/Diagnosis";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";

const diagnosisOptions = Diagnosis.list().map((diagnosis) => ({
	value: diagnosis?.value,
	label: diagnosis?.label,
}));
const AddDiagnosisModal = (props, ref) => {
	const { getData } = props;
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [patient_id, setPatientId] = useState(null);
	const [value, setTextValue] = useState("");
	const btnRef = useRef(null);
	const [name, setName] = useState("");
	const {
		register,
		handleSubmit,
		setValue,
		control,
		reset,
		watch,
		formState: { errors },
	} = useForm();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (showData) => {
		setName(showData?.name);
		setPatientId(showData?.id);
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = (data) => {
		console.log("datadatadata", data);

		let formData = new FormData();
		let date = new Date();

		let time = `${String(date.getHours()).padStart(2, "0")}:${String(
			date.getMinutes()
		).padStart(2, "0")}:${String(date.getSeconds()).padStart(2, "0")}`;
		formData.append("title", data?.title);
		formData.append("description", value);
		formData.append("patient_id", patient_id);
		formData.append("date", dateYYYYMMDD());
		formData.append("time", time);
		formData.append("datetime", `${dateYYYYMMDD()} ${time}`);
		if (data?.diagnosis_list) {
			data?.diagnosis_list?.map((dl) => {
				formData.append("diagnosis_list[]", dl?.value);
			});
		}
		axios.post(`/v1/clinic/patient-diagnosis`, formData).then((res) => {
			setTimeout(() => {
				getData();
				toast.success("Patient diagnosis added successfully!");
			}, 100);
			hide();
		});
	};
	return (
		<Modal
			open={open}
			hide={hide}
			className={`w-[768px] min-w-[768px] max-w-[768px]`}
			staticModal={true}
		>
			<ModalHeader title={`History form`} hide={hide} />
			<ModalBody className={`p- bg-white !pb-6`}>
				<div className="p-4 flex flex-col">
					<div className="flex flex-col gap-y-2 w-full mb-4">
						<div className="flex items-center gap-2 mb-0">
							<b className="text-sm font-bold text-secondary">
								<Clock
									format={"MMMM D, YYYY"}
									ticking={true}
									timezone={"ASIA/Manila"}
								/>
							</b>
							<b className="text-sm font-bold text-darker">
								<Clock
									format={"hh:mm:ss A"}
									ticking={true}
									timezone={"ASIA/Manila"}
								/>
							</b>
						</div>
						<h1 className="mb-2 font-bold text-xl">
							Patient name: <u>{name}</u>
						</h1>

						{/* <div className="flex items-center gap-4 mb-3">
							<TextField
								className="w-full bg-white  lg:w-1/2"
								label={
									<>
										Date
										<b className="text-red-500 ml-1">*</b>
									</>
								}
								variant="outlined"
								{...register("date", {
									required: true,
								})}
								value={dateYYYYMMDD()}
								type="date"
								placeholder="Date"
								InputLabelProps={{ shrink: true }}
								error={errors?.date}
								helperText={
									errors?.date && "This field is required"
								}
							/>
							<TextField
								className="w-full bg-white  lg:w-1/2"
								label={
									<>
										Time
										<b className="text-red-500 ml-1">*</b>
									</>
								}
								variant="outlined"
								{...register("time", {
									required: true,
								})}
								type="time"
								placeholder="Time"
								InputLabelProps={{ shrink: true }}
								error={errors?.date}
								helperText={
									errors?.date && "This field is required"
								}
							/>
						</div>
 */}

						<div className="flex items-center gap-4">
							<TextField
								className="w-full bg-white"
								label={
									<>
										Title
										<b className="text-red-500 ml-1">*</b>
									</>
								}
								variant="outlined"
								{...register("title", {
									required: true,
								})}
								placeholder="Title"
								InputLabelProps={{ shrink: true }}
								error={errors?.title}
								helperText={
									errors?.title && "This field is required"
								}
							/>
						</div>
						<Controller
							name="diagnosis_list[]"
							control={control}
							rules={{
								required: {
									value: true,
									message: "This field is required",
								},
							}}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: {
									invalid,
									isTouched,
									isDirty,
									error,
								},
							}) => (
								<ReactSelectInputField
									isClearable={false}
									label={
										<>
											Select Diagnosis
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									isMulti={true}
									inputClassName=" "
									ref={ref}
									value={value}
									onChange={onChange}
									onBlur={onBlur} // notify when input is touched
									error={error?.message}
									placeholder="Select diagnosis"
									options={diagnosisOptions}
									onChangeGetData={(val) => {
										console.log("onChangeGetData", val);
									}}
								/>
							)}
						/>
						<ReactQuill
							theme="snow"
							value={value}
							placeholder="Write notes or remarks here..."
							onChange={setTextValue}
							style={{ height: 256 }}
						/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button
					onClick={handleSubmit(submit)}
					loading={loading}
					ref={btnRef}
				>
					Save
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(AddDiagnosisModal);
