import { Typography, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
// import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";
// import Footer from "src/laboratory/print/Footer";

const TumorMakerOrder = ({ order_id, patient_id, formheader }) => {
	const { users } = React.useContext(UsersData);
	const [details, setDetails] = useState([]);

	const getpaidLaboratoryOrderDetails = async () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set("trace_number", order_id);
		formdata.set("table", "laboratory_tumor_maker");
		formdata.set("connection", "local");

		try {
			let response = await axios.post(
				"doctor/patient/laboratory/order/paid-detailsbytable",
				formdata
			);
			const data = response.data;

			setDetails(data);
		} catch (error) {
			console.log("lab form order laboratory_tumor_maker table error", error);
		}
	};

	useEffect(() => {
		getpaidLaboratoryOrderDetails();

		// eslint-disable-next-line
	}, [order_id]);

	return (
		<>
			{details.length > 0 && (
				<>
					<Box my={2} p={2}>
						<Box>
							<Typography variant="h6" align="center">
								<b> TUMOR MAKER </b>
							</Typography>
						</Box>

						<Box>
							{details.map((data, key) => {
								return (
									<Box my={2} key={key}>
										<Box mb={3} hidden={!Boolean(parseInt(data.aso))}>
											<Box display="flex">
												<Box flexGrow={1}>
													<Typography variant="subtitle1">
														<b> ASO </b>
													</Typography>
												</Box>
											</Box>

											{Boolean(parseInt(data.is_processed)) && (
												<Box my={1}>
													<Typography
														style={{
															whiteSpace: "pre-line",
														}}
													>
														{data.aso_result}
													</Typography>
												</Box>
											)}
										</Box>

										<Box mb={3} hidden={!Boolean(parseInt(data.biopsy))}>
											<Box display="flex">
												<Box flexGrow={1}>
													<Typography variant="subtitle1">
														<b> BIOPSY </b>
													</Typography>
												</Box>
											</Box>

											{Boolean(parseInt(data.is_processed)) && (
												<Box my={1}>
													<Typography
														style={{
															whiteSpace: "pre-line",
														}}
													>
														{data.biopsy_result}
													</Typography>
												</Box>
											)}
										</Box>

										<Box mb={3} hidden={!Boolean(parseInt(data.c3))}>
											<Box display="flex">
												<Box flexGrow={1}>
													<Typography variant="subtitle1">
														<b> C3 </b>
													</Typography>
												</Box>
											</Box>

											{Boolean(parseInt(data.is_processed)) && (
												<Box my={1}>
													<Typography
														style={{
															whiteSpace: "pre-line",
														}}
													>
														{data.c3_result}
													</Typography>
												</Box>
											)}
										</Box>

										<Box mb={3} hidden={!Boolean(parseInt(data.ca_125))}>
											<Box display="flex">
												<Box flexGrow={1}>
													<Typography variant="subtitle1">
														<b> CA 125 </b>
													</Typography>
												</Box>
											</Box>

											{Boolean(parseInt(data.is_processed)) && (
												<Box my={1}>
													<Typography
														style={{
															whiteSpace: "pre-line",
														}}
													>
														{data.ca_125_result}
													</Typography>
												</Box>
											)}
										</Box>

										<Box mb={3} hidden={!Boolean(parseInt(data.cea))}>
											<Box display="flex">
												<Box flexGrow={1}>
													<Typography variant="subtitle1">
														<b> CEA </b>
													</Typography>
												</Box>
											</Box>

											{Boolean(parseInt(data.is_processed)) && (
												<Box my={1}>
													<Typography
														style={{
															whiteSpace: "pre-line",
														}}
													>
														{data.cea_result}
													</Typography>
												</Box>
											)}
										</Box>

										<Box mb={3} hidden={!Boolean(parseInt(data.psa_prostate))}>
											<Box display="flex">
												<Box flexGrow={1}>
													<Typography variant="subtitle1">
														<b> PSA Prostate </b>
													</Typography>
												</Box>
											</Box>

											{Boolean(parseInt(data.is_processed)) && (
												<Box my={1}>
													<Typography
														style={{
															whiteSpace: "pre-line",
														}}
													>
														{data.psa_prostate_result}
													</Typography>
												</Box>
											)}
										</Box>

										<Box hidden={!Boolean(parseInt(data.afp))}>
											<Box display="flex">
												<Box flexGrow={1}>
													<Typography variant="subtitle1">
														<b> AFP </b>
													</Typography>
												</Box>
											</Box>

											{Boolean(parseInt(data.is_processed)) && (
												<Box my={1}>
													<Typography
														style={{
															whiteSpace: "pre-line",
														}}
													>
														{data.afp_result}
													</Typography>
												</Box>
											)}
										</Box>
									</Box>
								);
							})}
						</Box>
					</Box>

					{/* <Footer formheader={formheader} /> */}
				</>
			)}
		</>
	);
};

export default TumorMakerOrder;
