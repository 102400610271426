import { Box, Grid, Paper, Typography } from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import { PharmacyRoleAndId } from "../../ContextAPI";
import Container from "../../layout/Container";
import QRCode from "qrcode.react";

const PharmacyQRCode = () => {
  const userRAndId = useContext(PharmacyRoleAndId);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "pharmacy qr code",
          items: [
            { name: "Dashboard", path: "/sph/app" },
            { name: "Account", path: "/sph/app/pharmacy/account" },
          ],
        }}
        title="Pharmacy QR Code"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Paper elevation={3} variant="outlined">
              <Box>
                <Box m={3}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <QRCode
                      value={userRAndId.userRoleAndId.pharmacy_id}
                      level="H"
                      size={300}
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    m={3}
                  >
                    <Typography variant="h6" color="primary">
                      For delivery purposes only.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default PharmacyQRCode;
