import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Homepage from "../auth/Homepage";
import PrivacyPolicy from "src/auth/PrivacyPolicy";

const RouteHomepage = () => {
	return (
		<Fragment>
			<BrowserRouter>
				<Switch>
					<Route
						exact
						path="/"
						component={() => <Redirect to="/sph/app" />}
					/>
					<Route
						exact
						path="/bmcdc"
						component={() => <Redirect to="/sph/app" />}
					/>
					<Route
						exact
						path="/privacy-policy"
						component={PrivacyPolicy}
					/>
					<Route exact path="/sph/app" component={Homepage} />
					<Route
						render={() => <PageNotFound title="Page not found" />}
					/>
				</Switch>
			</BrowserRouter>
		</Fragment>
	);
};

export default RouteHomepage;
