import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useMemo, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import { faUserInjured } from "@fortawesome/free-solid-svg-icons";
import PatientProfileModal from "../admission/components/PatientProfileModal";
import AddPatientFormModal from "../admission/components/AddPatientFormModal";
import axios from "axios";
import ViewPatientProfile from "../admission/components/ViewPatientProfile";
import { Fade } from "react-reveal";
import Img from "src/components/Img";
import PhoPatientFormModal from "./components/PhoPatientFormModal";
import PhoPatientProfileModal from "./components/PhoPatientProfileModal";
import PhoViewPatientProfile from "./components/PhoViewPatientProfile";
import { Link } from "react-router-dom";
import { replaceFname, replaceLname, replaceMname } from "src/helpers/utils";
import ReasonForEditModal from "./components/ReasonForEditModal";
import { UsersData } from "src/ContextAPI";
import PhoFormModal from "./components/PhoFormModal";

const PatientItem = ({ data, onClick }) => {
	return (
		<div
			className="flex items-center gap-3 p-2 bg-background mb-3 last:mb-0 !rounded-xl hover:bg-blue-50 cursor-pointer border"
			onClick={onClick}
		>
			<div className="w-11 h-11 flex items-center justify-center overflow-hidden">
				<Img
					type="user"
					name={`${data?.lastname}-${data?.firstname}-${data?.middle}`}
					src={data?.avatar || ""}
					className="w-full rounded-lg aspect-square object-cover"
					alt=""
					key={`key-${data?.id}-${data?.avatarBase64}`}
				/>
			</div>
			<div className="flex flex-col items-center lg:items-start xl:items-start w-full text-left">
				<h6
					className={`text-left text-sm font-semibold ${
						data?.gender == "Male"
							? "text-blue-700"
							: "text-pink-700"
					} mb-0`}
				>
					{`${replaceLname(data?.lastname)}, ${replaceFname(
						data?.firstname
					)} ${replaceMname(data?.middle)}`}
				</h6>
				<p className="mb-0 text-placeholder">{`${
					data?.municipality?.name
						? data?.municipality?.name + ", "
						: ""
				} ${data?.barangay?.name ? data?.barangay?.name + ", " : ""} ${
					data?.purok?.name ? data?.purok?.name + ", " : ""
				} ${data?.street}`}</p>
			</div>
		</div>
	);
};

const PhoPatients = () => {
	const patient_profile_ref = useRef(null);
	const add_patient_ref = useRef(null);
	const { users } = useContext(UsersData);
	const [view, setView] = useState("table");
	const {
		data,
		loading,
		setPage,
		setPaginate,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
	} = useDataTable("v1/patients");
	const [patient, setPatient] = useState(null);
	const reason_for_edit_ref = useRef(null);
	const pho_form_ref = useRef(null);
	console.log("usersusers", users);
	const getPatientInfo = (id) => {
		axios.get(`v1/patients/${id}`).then((res) => {
			setPatient(res.data.data);
		});
	};
	const columns = useMemo(
		() => [
			{
				header: "",
				accessorKey: "patient",
				headerClassName: "min-w-[60px] w-[60px]",
				cell: function (data) {
					return (
						<div
							className="flex items-center gap-3 cursor-pointer"
							onClick={() => {
								patient_profile_ref.current.show(data);
							}}
						>
							<div className="w-11 h-11 rounded-full flex items-center justify-center">
								<Img
									type="user"
									src={data?.avatar}
									name={`${data?.lastname}-${data?.firstname}-${data?.middle}`}
									className="w-full  aspect-square object-cover rounded-full"
									alt=""
								/>
							</div>
						</div>
					);
				},
			},
			{
				header: "Lastname",
				accessorKey: "lastname",
				sortable: true,
			},
			{
				header: "Firstname",
				accessorKey: "firstname",
				sortable: true,
			},
			{
				header: "Middlename",
				accessorKey: "middle",
				sortable: true,
			},
			{
				header: "Municipality",
				accessorKey: "address",
				cell: (data) => {
					return data?.municipality?.name;
				},
			},
			{
				header: "Barangay",
				accessorKey: "address",
				cell: (data) => {
					return data?.barangay?.name;
				},
			},
			{
				header: "Purok",
				accessorKey: "address",
				cell: (data) => {
					return data?.purok_text;
				},
			},
			{
				header: "Action",
				accessorKey: "action",
				className: "w-[244px]",
				cell: (data) => {
					return (
						<div className="flex items-center gap-2">
							<ActionBtn
								size="sm"
								type="foreground-light"
								onClick={() => {
									patient_profile_ref.current.show(data);
								}}
							>
								<FlatIcon
									icon="rr-eye"
									className="text-lg mr-2"
								/>
								View
							</ActionBtn>
							{users?.type != "PHO-FP" ? (
								<Link
									to={`/sph/app/pho/patients/${data?.id}`}
									className="!no-underline"
								>
									<ActionBtn
										size="sm"
										type="primary-light"
										onClick={() => {
											// pho_form_ref.current.show(data);
											/* reason_for_edit_ref.current.show(
												data
											); */
										}}
										className="!no-underline"
									>
										<FlatIcon
											icon="rr-edit"
											className="text-lg mr-2"
										/>
										Edit
									</ActionBtn>
								</Link>
							) : (
								""
							)}
							{/*<ActionBtn size="sm" type="danger-light">
								<FlatIcon icon="rr-trash" className="text-lg mr-2" />
								Delete
							</ActionBtn> */}
						</div>
					);
				},
			},
		],
		[]
	);
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faUserInjured}
						color="white"
						size={"2x"}
					/>
				}
				title="Patient List"
				subtitle="View, Register, and Update Patients"
			>
				{console.log("userrrr", users)}
				<CardContainer
					containerClassName={` border border- border-opacity-10`}
					header={{
						title: "Patient List Data",
						children: (
							<div className="md:ml-auto flex md:items-center flex-col md:flex-row md:justify-end flex-wrap gap-3 md:w-[calc(100%-160px)]">
								<div className="flex items-center flex-wrap gap-3">
									<a
										// href={`https://ee.kobotoolbox.org/edit/OU7e65oc?instance_id=ae888cdf-b747-4d1f-813f-01d9543d40dc&return_url=false`}
										href={`https://ee.humanitarianresponse.info/x/eqGTE1D2?d[surveyor]=${users?.username.replace(
											"-",
											" "
										)}&d[surveyor_id]=${users?.user_id}`}
										target="_blank"
										rel="noreferrer"
										className="!no-underline w-[calc(100%-68px)] lg:w-[unset]"
									>
										<ActionBtn
											type="primary"
											size="sm"
											className="px-3 !h-[42px]"
										>
											<FlatIcon
												icon="rr-add-document"
												className="text-xl mr-2"
											/>
											<span className="!no-underline">
												Kobotoolbox Form
											</span>
										</ActionBtn>
									</a>

									<Link
										to="/sph/app/pho/patients/create"
										className="!no-underline"
									>
										<ActionBtn
											size="sm"
											className="px-3 !h-[42px] !no-underline"
										>
											<FlatIcon
												icon="br-user-add"
												className="text-xl lg:mr-2"
											/>
											<span className="!no-underline hidden lg:block">
												Add patient
											</span>
										</ActionBtn>
									</Link>
								</div>
								{view == "table" ? (
									<div className="flex flex-row lg:flex-col gap-3">
										<ActionBtn
											size="sm"
											className="!h-[42px] !w-11 lg:!w-[168px]"
											type="background-gradient"
											onClick={() => {
												setView("list");
											}}
										>
											<FlatIcon
												icon="rr-ballot"
												className="text-xl lg:mr-2"
											/>
											<span className="hidden lg:block">
												List view
											</span>
										</ActionBtn>
										<TextInputField
											inputClassName=" lg:pl-10"
											className="w-full"
											icon={
												<FlatIcon
													icon="rr-search"
													className="text-sm"
												/>
											}
											placeholder="Search patient"
											onChange={(e) => {
												console.log(
													"onChange",
													e.target.value
												);
												setFilters((prevFilters) => ({
													...prevFilters,
													keyword: e.target.value,
												}));
											}}
										/>
									</div>
								) : (
									<ActionBtn
										size="sm"
										className="px-3 !h-[42px]"
										type="background-gradient"
										onClick={() => {
											setView("table");
										}}
									>
										<FlatIcon
											icon="rr-grid"
											className="text-xl mr-2"
										/>
										Table view
									</ActionBtn>
								)}
							</div>
						),
					}}
					className="!p-0"
				>
					{view == "table" ? (
						<TableV2
							paginationClassName="px-4"
							columns={columns}
							pagination={true}
							loading={loading}
							data={data?.data}
							column={column}
							meta={data?.meta}
							setColumn={setColumn}
							direction={direction}
							setDirection={setDirection}
							onTableChange={(data) => {
								setPage(data.pageIndex + 1);
								setPaginate(data.pageSize);
							}}
						/>
					) : (
						<div
							className={`grid grid-cols-1 lg:grid-cols-12 transition-all duration-1000 ${
								view == "list" ? "opacity-100" : "opacity-0 "
							}`}
						>
							<div className="flex flex-col xl:col-span-3 lg:col-span-4 border-r p-3 max-h-[calc(100vh-300px)] lg:max-h-[unset]">
								<TextInputField
									inputClassName=" pl-10"
									className="w-full mb-4"
									icon={
										<FlatIcon
											icon="rr-search"
											className="text-sm"
										/>
									}
									placeholder="Search patient"
									onChange={(e) => {
										console.log("onChange", e.target.value);
										setFilters((prevFilters) => ({
											...prevFilters,
											keyword: e.target.value,
											search: e.target.value,
											query: e.target.value,
										}));
									}}
								/>
								{data?.data?.map((data) => {
									return (
										<PatientItem
											data={data}
											onClick={() => {
												if (
													window.screen.width < 1024
												) {
													patient_profile_ref.current.show(
														data
													);
												} else {
													getPatientInfo(data?.id);
												}
											}}
										/>
									);
								})}
							</div>
							<div className="lg:flex flex-col xl:col-span-9 lg:col-span-8 border-r hidden">
								{patient == null ? (
									<p className="text-placeholder py-11 text-center">
										Select patient to view.
									</p>
								) : (
									<Fade key={`patient-${patient?.id}`}>
										<PhoViewPatientProfile
											patient={patient}
											setPatient={setPatient}
											add_patient_ref={add_patient_ref}
										/>
									</Fade>
								)}
							</div>
						</div>
					)}
				</CardContainer>
			</LayoutContainer>
			<PhoPatientProfileModal
				ref={patient_profile_ref}
				add_patient_ref={add_patient_ref}
			/>
			<PhoFormModal ref={pho_form_ref} staticModal={true} />
			<PhoPatientFormModal ref={add_patient_ref} />
			<ReasonForEditModal
				ref={reason_for_edit_ref}
				pho_form_ref={pho_form_ref}
			/>
		</>
	);
};

export default PhoPatients;
