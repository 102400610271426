import { faMapMarkedAlt, faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardContainer from "src/components/CardContainer";
import LayoutContainer from "src/components/LayoutContainer";
import {
	CircleMarker,
	FeatureGroup,
	LayerGroup,
	MapContainer,
	Marker,
	Polygon,
	Popup,
	Rectangle,
	TileLayer,
	Tooltip,
	useMap,
	useMapEvents,
	Circle,
	GeoJSON,
} from "react-leaflet";
import { Search } from "react-feather";
import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import TextInputField from "src/components/forms/TextInputField";
import ActionBtn from "src/components/ActionBtn";
import MapFilterModal from "./components/MapFilterModal";
import axios from "axios";
import { formatDate } from "src/helpers/utils";
import MapLegendsModal from "./components/MapLegendsModal";
import { features } from "../json_data/sarangani.json";
import { UsersData } from "src/ContextAPI";
import { v4 as uuidv4 } from "uuid";
const mapStyle = {
	fillColor: "#fff",
	weight: 2,
	opacity: 1,
	color: "#808080",
	fillOpacity: 0.1,
	dashArray: "3",
};

const MapEvents = () => {
	const mapEvents = useMapEvents({
		zoomend: () => {
			console.log("MapEvents props", mapEvents);
		},
	});
};

const MapCirclePin = ({ data }) => {
	return (
		<>
			<Circle
				center={[data.latitude, data.longitude]}
				pathOptions={{ color: data?.disease?.color }}
				radius={data?.disease?.radius || 30}
			>
				<Tooltip>
					<div className="flex flex-col gap-y-1">
						<span className="capitalize">
							Disease:{" "}
							<b style={{ color: data?.disease?.color }}>
								{data?.disease?.name}
							</b>
						</span>
						<span>
							Patient:{" "}
							<b>{`${data?.patient?.lastname}, ${data?.patient?.firstname} ${data?.patient?.middle}`}</b>
						</span>
						<span>Gender: {data?.patient?.gender}</span>
						<span>
							Date started: {formatDate(data?.date_started)}
						</span>
						<span>Radius: {data?.disease?.radius} m</span>
					</div>
				</Tooltip>
			</Circle>
		</>
	);
};
const MapCirclePinPatient = ({ data }) => {
	if (data?.lat && data?.lng) {
		return (
			<>
				<Circle
					center={[data.lat, data.lng]}
					pathOptions={{ color: "#0481e5" }}
					radius={1}
				>
					<Tooltip>
						<div className="flex flex-col gap-y-1">
							<span>
								Patient:{" "}
								<b>{`${data?.lastname}, ${data?.firstname} ${data?.middle}`}</b>
							</span>
						</div>
					</Tooltip>
				</Circle>
			</>
		);
	}
	return "";
};
const PhoAnalyticsMap = () => {
	const { users } = useContext(UsersData);
	const map_filter_ref = useRef(null);
	const map_container_ref = useRef(null);
	const map_legends_ref = useRef(null);

	const [currentUser, setCurrentUser] = useState(null);
	const [data, setData] = useState([]);
	const [center, setCenter] = useState([6.0498006, 125.15]);
	const [zoom, setZoom] = useState(10);
	const [patientLocations, setPatientLocations] = useState([]);
	const [featureData, setFeaturedata] = useState(features);
	var prevLayerClicked = null;

	const [filters, setFilters] = useState({
		date: "",
		date: "",
		disease: "",
		municipality: "",
		barangay: "",
		radius: 10000,
	});
	useEffect(() => {
		let t = setTimeout(() => {
			fetchMapData();
		}, 200);
		return () => {
			clearTimeout(t);
		};
	}, [filters]);

	useEffect(() => {
		let t = setTimeout(() => {
			getPhoUsers();
			getPatientsLocations();
		}, 200);
		return () => {
			clearTimeout(t);
		};
	}, []);

	// useEffect(() => {
	// 	console.log("currentUsercurrentUser", currentUser);
	// }, [currentUser]);

	const getPatientsLocations = () => {
		axios.get("/v1/pho/patient-maps").then((res) => {
			setPatientLocations(res.data);
		});
	};
	useEffect(() => {
		let t = setTimeout(() => {
			if (currentUser?.type == "MUNICIPAL-HO") {
				setZoom(12);
				recomputeCenter();
			}
			if (currentUser?.type == "BARANGAY-HO") {
				setZoom(14);
				recomputeCenter();
			}
		}, 200);
		return () => {
			clearTimeout(t);
		};
	}, [currentUser, featureData]);

	const recomputeCenter = useCallback(() => {
		let coordinates_ = featureData.map((x) => x.geometry.coordinates[0]);
		let flattened_coordinates = coordinates_.flat();
		let sum_coordinates = flattened_coordinates.reduce(
			(a, c) => [a[0] + c[0], a[1] + c[1]],
			[0, 0]
		);
		setCenter([
			sum_coordinates[1] / flattened_coordinates.length,
			sum_coordinates[0] / flattened_coordinates.length,
		]);
	}, [featureData, currentUser]);

	const getPhoUsers = () => {
		axios.get("v1/pho/users").then((res) => {
			let res_users = res.data.data || [];
			let found = res_users.find((x) => x.user_id == users?.user_id);
			console.log("resssssssss", featureData, found);

			if (found?.type == "MUNICIPAL-HO" && found?.municipality?.name) {
				/* GET ALL LOCATIONS ONLY FOR THIS MUNICIPALITY */
				setFeaturedata((currentData) =>
					currentData?.filter((x) => {
						let names = x.properties.name.split(", ");
						return names[1] == found?.municipality?.name;
					})
				);
			}
			if (found?.type == "BARANGAY-HO" && found?.barangay?.name) {
				/* GET ALL LOCATIONS ONLY FOR THIS MUNICIPALITY */
				setFeaturedata((currentData) =>
					currentData?.filter((x) => {
						let names = x.properties.name.split(", ");
						return names[0] == found?.barangay?.name;
					})
				);
			}

			setCurrentUser(found);
		});
	};

	const groupData = (groupBy = "municipality") => {
		return data.reduce(function (r, a) {
			r[a[groupBy]] = r[a[groupBy]] || [];
			r[a[groupBy]].push(a);
			return r;
		}, Object.create(null));
	};
	const fetchMapData = () => {
		const { date, disease, municipality, barangay, radius } = filters;
		axios
			.get(
				`v1/pho/analytics-map?date=${date}&date_started=${date}&municipality=${municipality}&barangay=${barangay}&disease=${disease}`
			)
			.then((res) => {
				setData(res.data.data);
			});
	};

	const mapOnEachFeature = (feature, layer) => {
		layer.bindPopup(
			`${feature.properties.name}, ${feature.properties.city}`
		);
		layer.on({
			click: (e) => {
				// const map = mapRef.current.contextValue.map;
				var layer = e.target;
				// map.fitBounds(e.target.getBounds());
				if (prevLayerClicked !== null) {
					prevLayerClicked.setStyle({
						fillColor: "#FFF",
						weight: 2,
						opacity: 1,
						color: "#808080",
						fillOpacity: 0.1,
						dashArray: "3",
					});
				}
				layer.setStyle({
					weight: 4,
					color: "blue",
					dashArray: "",
					fillOpacity: 0.3,
				});
				if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
					layer.bringToFront();
				}
				prevLayerClicked = layer;
			},
		});
	};
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faMapMarkedAlt}
						color="white"
						size={"2x"}
					/>
				}
				title="Map"
				subtitle="View Kobotoolbox Analytics in map"
				titleChildren={
					<div className="flex flex-col lg:flex-row items-center gap-4 w-full lg:w-[unset] lg:ml-auto">
						<div
							className="cursor-pointer  text-center lg:ml-auto hover:bg-slate-200 focus:bg-slate-200 duration-200"
							onClick={() => {
								map_legends_ref.current.show();
							}}
						>
							<div className="flex items-center text-sm">
								Click to view map legends
							</div>
						</div>
						<ActionBtn
							className="px-2 ml-6"
							onClick={() => {
								map_filter_ref.current.show();
							}}
						>
							<Search className="w-5 mr-2" />
							Filter Map
						</ActionBtn>
					</div>
				}
			>
				<CardContainer
					containerClassName={` border border- border-opacity-10 !overflow-auto relative`}
					className="!p-0"
				>
					<div className="h-[calc(100vh-280px)]">
						<MapContainer
							key={`center-${center[0]}-${center[1]}`}
							center={center}
							zoom={zoom}
							scrollWheelZoom={true}
							measureControl={true}
							ref={map_container_ref}
						>
							<MapEvents />
							{/* <MainHeatMap
								groupData={groupData()}
								radius={filters?.radius}
							/> */}

							<GeoJSON
								key={`geo-key-${uuidv4()}`}
								style={mapStyle}
								data={featureData}
								onEachFeature={mapOnEachFeature}
							/>

							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
							{data?.map((item) => {
								return (
									<MapCirclePin
										data={item}
										key={`heatmap-${item?.id}`}
									/>
								);
							})}
							{patientLocations?.map((patient, index) => {
								return (
									<MapCirclePinPatient
										data={patient}
										key={`mcpp-${index}`}
									/>
								);
							})}
						</MapContainer>
					</div>
				</CardContainer>
				<MapFilterModal ref={map_filter_ref} setFilters={setFilters} />
				<MapLegendsModal ref={map_legends_ref} />
			</LayoutContainer>
		</>
	);
};

export default PhoAnalyticsMap;
