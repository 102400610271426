import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import PsychologyLocalOrder from "./PsychologyLocalOrder";

const Psychology = ({
  connection,
  patient_id,
  getPermission,
  getPatientsList,
  permission,
  trace_number,
}) => {
  useEffect(() => {}, [patient_id]);
  return (
    <>
      <Box>
        <PsychologyLocalOrder
          connection={connection}
          patient_id={patient_id}
          getPermission={getPermission}
          getPatientsList={getPatientsList}
          permission={permission}
          trace_number={trace_number}
        />
      </Box>
    </>
  );
};

export default Psychology;
