import {
	faBox,
	faBoxes,
	faChartBar,
	faClock,
	faDoorClosed,
	faFlask,
	faHome,
	faListAlt,
	faPenAlt,
	faPenFancy,
	faPenSquare,
	faPencilAlt,
	faTruckLoading,
	faUser,
	faUserInjured,
	faUserLock,
	faUserMd,
	faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ClinicDoctors from "src/clinic/ClinicDoctors";
import ClinicNurseDashboard from "src/clinic/ClinicNurseDashboard";
import ClinicNursePatients from "src/clinic/ClinicNursePatients";
import ClinicPatientProfile from "src/clinic/ClinicPatientProfile";
import ClinicPatientsReceiving from "src/clinic/ClinicPatientsReceiving";
import {
	ClinicContextWrapper,
	useClinicContext,
} from "src/clinic/context/ClinicContext";
import PatientQueuePage from "src/clinic/PatientQueuePage";
import Logout from "../auth/Logout";
import { UsersData, UsersHeader } from "../ContextAPI";
import Sidebar from "../layout/Sidebar";
import PageNotFound from "../PageNotFound";
import CisPatients from "src/cis/CisPatients";
import CisPatientProfile from "src/cis/CisPatientProfile";
import CisDashboard from "src/cis/CisDashboard";
import CisQueue from "src/cis/CisQueue";
import CisPatientsForm from "src/cis/components/profile/CisPatientsForm";
import CisEditPatientsForm from "src/cis/components/profile/CisEditPatientsForm ";
import CisPatientHistory from "src/cis/components/history/CisPatientHistory";
import CisCreatePatientsHistory from "src/cis/components/history/CisCreatePatienstsHistory";
import CisVisits from "src/cis/CisVisits";
import CisTBTreatmentModule from "src/cis/CisTBTreatmentModule";
import CisMyAccount from "src/cis/CisMyAccount";
import PhoHouseholdMemberFormPage from "src/pho/PhoHouseholdMemberPage";
import Pmrf from "src/pho/printable-forms/Pmrf";
import CisLMIS from "src/cis/CisLMIS";
import CisLMISView from "src/cis/CisLMISView";
import CisInventory from "src/cis/CisInventory";
import ManagePhoUsers from "src/pho/ManagePhoUsers";
import OtherDashboard from "src/other-account/OtherDashboard";
import PhoClinicPersonnel from "src/pho/clinic-personnel/PhoClinicPersonel";
import PhoClinicManagement from "src/pho/clinic-management/PhoClinicManagement";
import PhoClinicProfile from "src/pho/clinic-management/PhoClinicProfile";
import OperatingRooms from "src/pho/operating-rooms/OperatingRooms";
import LaboratoryTests from "src/pho/laboratory-tests/LaboratoryTests";
import CisPatientList from "src/pho/patients/CisPatientList";

const RouteCISAdmin = () => {
	const { clinic } = useClinicContext();
	const [sidebarHeader, setSidebarHeader] = useState([]);
	const userContext = useContext(UsersData);
	// console.log("userContextuserContext", userContext);
	const { users } = userContext;
	const getSidebarHeaderInfo = async () => {
		var params = { user_id: userContext.users.user_id };
		const { data } = await axios.get(
			"admitting/sidebar/header-infomartion",
			{
				params,
			}
		);
		setSidebarHeader(data);
	};

	const sidebarRoute = [
		{
			name: "Dashboard",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faHome}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/cis/dashboard",
			subitem: [],
		},

		{
			name: "Manage Clinics",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserMd}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/cis/manage-clinics",
			subitem: [],
		},
		{
			name: "Manage Doctors",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserMd}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/cis/manage-doctors",
			subitem: [],
		},
		{
			name: "Manage Operating Rooms",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faDoorClosed}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/cis/manage-or-rooms",
			subitem: [],
		},
		{
			name: "Manage Laboratory Tests",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faFlask}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/cis/manage-lab-test",
			subitem: [],
		},
		{
			name: "Manage Patients",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUsers}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/cis/patients",
			subitem: [],
		},

		{
			name: "My Account",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserLock}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/cis/user",
			subitem: [],
		},
	];

	const handleRenderInfo = () => {
		getSidebarHeaderInfo();
	};

	useEffect(() => {
		getSidebarHeaderInfo();
		//eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<UsersHeader.Provider
				value={{
					sidebarHeader: sidebarHeader,
					renderPharmacyInfo: handleRenderInfo,
				}}
			>
				<BrowserRouter>
					<Sidebar
						notification={{
							enable: true,
							owner: "pho",
						}}
						header={{
							...sidebarHeader,
							name: users?.name,
							avatar: {
								name: users?.name,
								src:
									users?.avatar ||
									`https://avatars.dicebear.com/api/initials/${users?.name}.svg`,
							},
						}}
						routes={sidebarRoute}
						module={users?.type.replace("-", " ")}
					/>
					<Switch>
						<Route
							exact
							path="/sph/app"
							component={() => (
								<Redirect to="/sph/app/cis/dashboard" />
							)}
						/>
						<Route
							exact
							path="/sph/app/cis/dashboard"
							component={OtherDashboard}
						/>
						<Route
							exact
							path="/sph/app/cis/manage-doctors"
							component={PhoClinicPersonnel}
						/>
						<Route
							exact
							path="/sph/app/cis/manage-or-rooms"
							component={OperatingRooms}
						/>
						<Route
							exact
							path="/sph/app/cis/manage-lab-test"
							component={LaboratoryTests}
						/>
						<Route
							exact
							path="/sph/app/cis/patients"
							component={CisPatientList}
						/>
						<Route
							exact
							path="/sph/app/cis/manage-clinics"
							component={PhoClinicManagement}
						/>
						<Route
							exact
							path="/sph/app/pho/clinic-management/:id"
							component={PhoClinicProfile}
						/>

						<Route
							exact
							path="/sph/app/cis/user"
							component={CisMyAccount}
						/>

						<Route
							exact
							path="/sph/app/logout"
							component={Logout}
						/>

						<Route
							render={() => (
								<PageNotFound title="Page not found" />
							)}
						/>
					</Switch>
				</BrowserRouter>
			</UsersHeader.Provider>
		</Fragment>
	);
};

export default RouteCISAdmin;
