import React from "react";
import {
  Box,
  Typography,
  FormHelperText,
  Divider,
  Button,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const AddBrandDialog = ({ getBrandList, close }) => {
  const { users } = React.useContext(UsersData);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Box m={2}>
      <Formik
        initialValues={{
          username: users.username,
          user_id: users.user_id,
          management_id: users.management_id,
          main_mgmt_id: users.main_mgmt_id,
          brand: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          brand: Yup.string().required(),
          password: Yup.string().required(),
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting, resetForm }
        ) => {
          try {
            const request = await axios.post(
              "pharmacy/warehouse/create/new-brand",
              getFormData(values)
            );
            if (request.data.message === "pass-invalid") {
              setErrors({ password: "Password is invalid." });
              Notify.fieldInvalid("password");
            }
            if (request.data.message === "success") {
              Notify.successRequest("add new brand");
              resetForm();
              setSubmitting(true);
              getBrandList();
              close();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box mb={2}>
              <Typography color="textPrimary" variant="subtitle2">
                NEW BRAND
              </Typography>
            </Box>

            <Divider />

            <Box my={2} className={`labselect2`}>
              <TextField
                error={Boolean(touched.brand && errors.brand)}
                helperText={touched.brand && errors.brand}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.brand}
                fullWidth
                required
                name="brand"
                label="Brand"
                variant="outlined"
              />
            </Box>

            <Box mb={2}>
              <TextField
                fullWidth
                required
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                label="Enter your password"
                variant="outlined"
                type="password"
              />
            </Box>

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Divider />

            <Box my={2} display="flex">
              <Box flexGrow={1} />

              <Button
                variant="contained"
                color="default"
                startIcon={<ClearIcon />}
                onClick={() => close()}
              >
                Close
              </Button>
              <Box ml={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddBrandDialog;
