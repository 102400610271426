import {
  faSpinner,
  faArrowRight,
  faSave,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Badge } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";

const EditPrescriptionForm = ({
  patient_id,
  displayList,
  formValues,
  setListView,
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { users } = React.useContext(UsersData);
  const [isProcess, setIsProcess] = useState(false);

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("prescription", data?.prescription || "");
    formData.append("quantity", data?.quantity || "");
    formData.append("type", data?.type || "");
    formData.append("remarks", data?.remarks || "");
    console.log("onSubmit data", formData);
    console.log(formValues.id);
    // setIsProcess(true);

    // axios
    //   .post(`/v1/patient-prescription/update/`, formData)
    //   .then((response) => {
    //     Notify.successRequest(response.message);
    //   })
    //   .catch((err) => console.log(err));
  };

  useEffect(() => {}, [formValues]);

  return (
    <Fragment>
      <div className="flex flex-row justify-content-between gap-1 mb-4 mt-4">
        <h4 className="text-lg font-bold mb-0">Update Doctor's Prescription</h4>
        <Button
          variant="outlined"
          size="medium"
          onClick={() => setListView(true)}
        >
          View List <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </Button>
      </div>
      <form>
        <Row>
          <Col sm={12}>
            <Box mb={2}>
              <TextField
                variant={"outlined"}
                name="prescription"
                label="Prescription"
                value={formValues?.data?.prescription}
                fullWidth
                {...register("prescription", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                error={errors?.prescription?.message}
                helperText={errors?.prescription?.message}
              />
            </Box>
          </Col>
          <Col sm={6}>
            <TextField
              variant={"outlined"}
              name="quantity"
              label="Quantity"
              fullWidth
              type="number"
              {...register("quantity", {
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
              error={errors?.quantity?.message}
              helperText={errors?.quantity?.message}
              value={formValues?.data?.quantity}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: 10000,
                  step: 1,
                },
              }}
            />
          </Col>
          <Col sm={6}>
            <Box className="mt-4 mt-sm-0">
              <TextField
                select
                required
                fullWidth
                name="type"
                label="Type"
                value={formValues?.data?.type}
                variant="outlined"
                {...register("type", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                error={errors?.type?.message}
                helperText={errors?.type?.message}
              >
                <option value=""> -- Select -- </option>
                <option value="Bottle"> Bottle </option>
                <option value="Box"> Box </option>
                <option value="Can"> Can </option>
                <option value="Capsule"> Capsule </option>
                <option value="Liniment"> Liniment </option>
                <option value="Sachet"> Sachet </option>
                <option value="Suspension"> Suspension </option>
                <option value="Powder"> Powder </option>
                <option value="Tablet"> Tablet </option>
              </TextField>
            </Box>
          </Col>
        </Row>
        <Box my={2}>
          <TextField
            variant={"outlined"}
            name="remarks"
            label="Sig."
            value={formValues?.data?.remarks}
            fullWidth
            rows={3}
            multiline
            {...register("remarks", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.remarks?.message}
            helperText={errors?.remarks?.message}
          />
        </Box>

        <Button
          variant="contained"
          color="primary"
          className="mr-2"
          disabled={isProcess}
          onClick={() => handleSubmit(onSubmit)}
        >
          <FontAwesomeIcon
            icon={isProcess ? faSpinner : faSave}
            spin={isProcess}
            className="mr-2"
          />{" "}
          Update
        </Button>
        <Button
          type="reset"
          variant="contained"
          className="mr-2"
          onClick={() => setListView(true)}
        >
          <FontAwesomeIcon icon={faBan} className="mr-2" />
          Cancel
        </Button>
      </form>
    </Fragment>
  );
};

export default EditPrescriptionForm;
