import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import { XCircle } from "react-feather";
import HeaderPrintAll from "./printall/HeaderPrintAll";
import PatientInfoPrintAll from "./printall/PatientInfoPrintAll";
import MedicalExamPrintAll from "./printall/MedicalExamPrintAll";
import { useReactToPrint } from "react-to-print";
import LaboratoryPrintAll from "./printall/LaboratoryPrintAll";
import PsychologyPrintAll from "./printall/PsychologyPrintAll";
import ImagingPrintAll from "./printall/ImagingPrintAll";
import MedicalCertificatePrintAll from "./printall/MedicalCertificatePrintAll";
import ECGDashPrintAll from "./printall/ECGDashPrintAll";
import OtherPrintAll from "./printall/OtherPrintAll";
import { UsersData } from "src/ContextAPI";

const PrintMobileVanAllOrders = ({
	close,
	selectedTraceNoData,
	handleSearchPatient,
	selectedBranch,
}) => {
	const { users } = useContext(UsersData);
	const [patients, setPatients] = useState({
		data: [],
		ready: false,
	});

	const [medCertOrderDetails, setMedicalCertOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const [medicalExamOrderDetails, setMedicalExamOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const handlePrint = useReactToPrint({
		content: () => printableRef.current,
	});

	const printableRef = React.useRef();

	const getPatientInformation = () => {
		var params = { patient_id: selectedTraceNoData.patient_id };
		Axios.get("documentation/get/patient-info/patient-id", { params })
			.then((response) => {
				const data = response.data;
				setPatients({ data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getMedicalCertOrderDetails = () => {
		var params = {
			user_id: users.user_id,
			trace_number: selectedTraceNoData.trace_number,
			patient_id: selectedTraceNoData.patient_id,
		};
		Axios.get(
			"laboratory/order/ordernew-medicalcert/complete/medicalcert-print",
			{
				params,
			}
		)
			.then((response) => {
				const data = response.data;
				setMedicalCertOrderDetails({ data: data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getMedicalExamOrderDetails = () => {
		var params = {
			user_id: users.user_id,
			trace_number: selectedTraceNoData.trace_number,
		};
		Axios.get("laboratory/order/ordernew-medicalexam/complete/details-print", {
			params,
		})
			.then((response) => {
				const data = response.data;
				setMedicalExamOrderDetails({ data: data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	useEffect(() => {
		getPatientInformation();
		getMedicalCertOrderDetails();
		getMedicalExamOrderDetails();
		// eslint-disable-next-line
	}, [selectedTraceNoData]);

	return (
		<Fragment>
			<Dialog
				fullScreen
				disableBackdropClick
				disableEscapeKeyDown
				open={true}
				onClose={() => console.log("dialog unclosable")}
			>
				<Box display="flex" className="gtc-hideonprint">
					<Box flexGrow={1}>
						<DialogTitle> Print Result Report </DialogTitle>
					</Box>
					<Box>
						<IconButton onClick={() => close()} color="secondary">
							<XCircle />
						</IconButton>
					</Box>
				</Box>

				<DialogContent>
					<Box m={2}>
						<Grid container spacing={2}>
							<Grid className="gtc-hideonprint" item xs={12} sm={3} lg={2}>
								<Box>
									<Button
										variant="outlined"
										id="print-all"
										size="large"
										color="primary"
										onClick={handlePrint}
										fullWidth
										component={Box}
										my={1}
									>
										PRINT ALL
									</Button>
								</Box>
							</Grid>

							<Grid item xs={12} sm={9} lg={10}>
								<Box ref={printableRef}>
									<Box m={6}>
										{/* header */}
										<HeaderPrintAll />
										{/* patient info and picture */}
										<PatientInfoPrintAll details={patients} />
										{/* print content */}
										<MedicalExamPrintAll
											allowHeaderPatientInfo={"notallowed"}
											patient_id={selectedTraceNoData.patient_id}
											trace_number={selectedTraceNoData.trace_number}
											patients={patients}
											medicalExamOrderDetails={medicalExamOrderDetails}
										/>
										<LaboratoryPrintAll
											allowHeaderPatientInfo={"notallowed"}
											patient_id={selectedTraceNoData.patient_id}
											trace_number={selectedTraceNoData.trace_number}
											patients={patients}
											selectedBranch={selectedBranch}
										/>
										<PsychologyPrintAll
											allowHeaderPatientInfo={"notallowed"}
											patient_id={selectedTraceNoData.patient_id}
											trace_number={selectedTraceNoData.trace_number}
											patients={patients}
										/>
										<ImagingPrintAll
											allowHeaderPatientInfo={"notallowed"}
											patient_id={selectedTraceNoData.patient_id}
											trace_number={selectedTraceNoData.trace_number}
											patients={patients}
										/>
										<ECGDashPrintAll
											allowHeaderPatientInfo={"notallowed"}
											patient_id={selectedTraceNoData.patient_id}
											trace_number={selectedTraceNoData.trace_number}
											patients={patients}
											selectedBranch={selectedBranch}
										/>
										<OtherPrintAll
											allowHeaderPatientInfo={"notallowed"}
											patient_id={selectedTraceNoData.patient_id}
											trace_number={selectedTraceNoData.trace_number}
											patients={patients}
											selectedBranch={selectedBranch}
										/>
										<MedicalCertificatePrintAll
											allowHeaderPatientInfo={"notallowed"}
											patient_id={selectedTraceNoData.patient_id}
											trace_number={selectedTraceNoData.trace_number}
											patients={patients}
											medCertOrderDetails={medCertOrderDetails}
										/>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
			</Dialog>
		</Fragment>
	);
};

export default PrintMobileVanAllOrders;
