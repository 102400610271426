import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faSave } from "@fortawesome/free-solid-svg-icons";
import {
  TextField,
  MenuItem,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Button,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import ButtonV3 from "src/components/ButtonV3";

const ConsultationForm = ({ setSelectedIndex, patient, handleClickList }) => {
  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    console.log("onSubmit data", data);
    let formData = new FormData();
    formData.append("nature_of_visit", data?.nature_of_visit || "");
    formData.append("mode_of_transaction", data?.mode_of_transaction || "");
    formData.append("type_of_consultation", data?.type_of_consultation || "");
    formData.append("consultation_date", data?.consultation_date || "");
    formData.append("consultation_time", data?.consultation_time || "");
    formData.append("age_in_years", data?.age_in_years || "");
    formData.append("age_in_months", data?.age_in_months || "");
    formData.append("age_in_days", data?.age_in_days || "");
    formData.append("height", data?.height || "");
    formData.append("weight", data?.weight || "");
    formData.append("bmi_category", data?.bmi_category || "");
    formData.append("height_for_age", data?.height_for_age || "");
    formData.append("weight_for_age", data?.weight_for_age || "");
    formData.append("attending_provider", data?.attending_provider || "");
    formData.append("chief_complaint", data?.chief_complaint || "");
    formData.append("patient_consent", data?.patient_consent || "");
  };

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-row justify-content-between gap-1">
          <h4 className="text-lg font-bold mb-0">Add Consultation Record</h4>
          <Button variant="contained">
            <FontAwesomeIcon icon={faExpand} size="2x" />
          </Button>
        </div>
        <div className="flex flex-col lg:flex-row gap-4 ">
          <TextField
            id="nature-of-visit"
            name="nature_of_visit"
            select
            label={
              <>
                Nature of Visit <b className="text-red-500 ml-1">*</b>
              </>
            }
            className="w-full bg-white  lg:w-1/2"
            variant="outlined"
            {...register("nature_of_visit", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.nature_of_visit?.message}
            helperText={errors?.nature_of_visit?.message}
          >
            <MenuItem key="1" value="New Consultation/Case">
              New Consultation/Case
            </MenuItem>
            <MenuItem key="2" value="Follow-up Consultation">
              Follow-up Consultation
            </MenuItem>
          </TextField>
          <TextField
            id="mode-of-transaction"
            name="mode_of_transaction"
            select
            label={
              <>
                Mode of Transaction
                <b className="text-red-500 ml-1">*</b>
              </>
            }
            className="w-full bg-white  lg:w-1/2"
            variant="outlined"
            {...register("mode_of_transaction", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.mode_of_transaction?.message}
            helperText={errors?.mode_of_transaction?.message}
          >
            <MenuItem key="1" value="walk-in">
              Walk-in
            </MenuItem>
            <MenuItem key="2" value="online">
              Online
            </MenuItem>
            <MenuItem key="3" value="phone-call">
              Phone Call
            </MenuItem>
          </TextField>
        </div>
        <div className="flex flex-col lg:flex-row gap-4">
          <FormControl component="fieldset" name="type_of_consultation">
            <FormLabel component="legend">
              Type of Consultation/Purpose of Visit
              <b className="text-red-500 ml-1">*</b>:
            </FormLabel>
            <FormGroup row className="gap-3">
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="adult-immunization" />}
                  color="primary"
                  label="Adult Immunization"
                  value={"Adult Immunization"}
                  {...register("type_of_consultation", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                  error={errors?.type_of_consultation?.message}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  color="primary"
                  label="Animal Bite"
                  value={"Animal Bite"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  disabled
                  color="primary"
                  label="Child Care"
                  value={"Child Care"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  disabled
                  color="primary"
                  label="Child Immunization"
                  value={"Child Immunization"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  disabled
                  color="primary"
                  label="Child Nutrition"
                  value={"Child Nutrition"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  color="primary"
                  label="Covid Form"
                  value={"Covid Form"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  color="primary"
                  label="Dental Care"
                  value={"Dental Care"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  color="primary"
                  label="Family Planning"
                  value={"Family Planning"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  color="primary"
                  label="Firecracker Injury"
                  value={"Firecracker Injury"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  color="primary"
                  label="General"
                  value={"General"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  color="primary"
                  label="Injury"
                  value={"Injury"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  disabled
                  color="primary"
                  label="Post Partum"
                  value={"Post Partum"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  disabled
                  color="primary"
                  label="Prenatal"
                  value={"Prenatal"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  disabled
                  color="primary"
                  label="Sick Children"
                  value={"Sick Children"}
                  {...register("type_of_consultation")}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={<Checkbox name="type_of_consultation" />}
                  color="primary"
                  label="Tuberculosis"
                  value={"Tuberculosis"}
                  {...register("type_of_consultation")}
                />
              </Grid>
            </FormGroup>
            {errors?.type_of_consultation && (
              <FormHelperText error>
                {errors.type_of_consultation.message}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div className="flex flex-col lg:flex-row gap-4 ">
          <TextField
            className="w-full bg-white  lg:w-1/2"
            id="consultation-date"
            name="consultation_date"
            label={
              <>
                Consultation Date <b className="text-red-500 ml-1">*</b>
              </>
            }
            type="date"
            variant="outlined"
            {...register("consultation_date", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.consultation_date?.message}
            helperText={errors?.consultation_date?.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            className="w-full bg-white  lg:w-1/2"
            id="consultation-time"
            name="consultation_time"
            label={
              <>
                Consultation Time <b className="text-red-500 ml-1">*</b>
              </>
            }
            type="time"
            variant="outlined"
            {...register("consultation_time", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.consultation_time?.message}
            helperText={errors?.consultation_time?.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-4 ">
          <TextField
            className="w-full bg-white  lg:w-1/3"
            id="age-in-years"
            name="age_in_years"
            label={
              <>
                Patient Age in Year/s <b className="text-red-500 ml-1">*</b>
              </>
            }
            type="number"
            {...register("age_in_years", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.age_in_years?.message}
            helperText={errors?.age_in_years?.message}
            variant="outlined"
          />
          <TextField
            className="w-full bg-white  lg:w-1/3"
            id="age-in-months"
            name="age_in_months"
            label={
              <>
                Patient Age in Month/s <b className="text-red-500 ml-1">*</b>
              </>
            }
            type="number"
            {...register("age_in_months", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.age_in_months?.message}
            helperText={errors?.age_in_months?.message}
            variant="outlined"
          />
          <TextField
            className="w-full bg-white  lg:w-1/3"
            id="age-in-days"
            name="age_in_days"
            label={
              <>
                Patient Age in Day/s
                <b className="text-red-500 ml-1">*</b>
              </>
            }
            type="number"
            {...register("age_in_days", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.age_in_days?.message}
            helperText={errors?.age_in_days?.message}
            variant="outlined"
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-4">
          <TextField
            className="w-full bg-white  lg:w-1/3"
            id="height"
            name="height"
            label={
              <>
                Patient Height <b className="text-red-500 ml-1">*</b>
              </>
            }
            type="number"
            {...register("height", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.height?.message}
            helperText={errors?.height?.message}
            variant="outlined"
          />
          <TextField
            className="w-full bg-white  lg:w-1/3"
            id="weight"
            name="weight"
            label={
              <>
                Patient Weight <b className="text-red-500 ml-1">*</b>
              </>
            }
            {...register("weight", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.weight?.message}
            helperText={errors?.weight?.message}
            type="number"
            variant="outlined"
          />
          <TextField
            id="bmi-category"
            name="bmi_category"
            select
            label={
              <>
                BMI Category <b className="text-red-500 ml-1">*</b>
              </>
            }
            {...register("bmi_category", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.bmi_category?.message}
            helperText={errors?.bmi_category?.message}
            className="w-full bg-white  lg:w-1/3"
            variant="outlined"
          >
            <MenuItem value="category_1">Category 1</MenuItem>
            <MenuItem value="category_2">Category 2</MenuItem>
            <MenuItem value="category_3">Category 3</MenuItem>
          </TextField>
        </div>
        <div className="flex flex-col lg:flex-row gap-4">
          <TextField
            id="height-for-age"
            name="height_for_age"
            select
            label={
              <>
                Height for Age <b className="text-red-500 ml-1">*</b>
              </>
            }
            {...register("height_for_age", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.height_for_age?.message}
            helperText={errors?.height_for_age?.message}
            className="w-full bg-white  lg:w-1/3"
            variant="outlined"
          >
            <MenuItem value="category_1">Category 1</MenuItem>
            <MenuItem value="category_2">Category 2</MenuItem>
            <MenuItem value="category_3">Category 3</MenuItem>
          </TextField>
          <TextField
            id="weight-for-age"
            name="weight_for_age"
            select
            label={
              <>
                Weight for Age <b className="text-red-500 ml-1">*</b>
              </>
            }
            {...register("weight_for_age", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.weight_for_age?.message}
            helperText={errors?.weight_for_age?.message}
            className="w-full bg-white  lg:w-1/3"
            variant="outlined"
          >
            <MenuItem value="category_1">Category 1</MenuItem>
            <MenuItem value="category_2">Category 2</MenuItem>
            <MenuItem value="category_3">Category 3</MenuItem>
          </TextField>
          <TextField
            id="attending-provider"
            name="attending_provider"
            select
            label={
              <>
                Name of Attending Provider{" "}
                <b className="text-red-500 ml-1">*</b>
              </>
            }
            {...register("attending_provider", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.attending_provider?.message}
            helperText={errors?.attending_provider?.message}
            className="w-full bg-white  lg:w-1/3"
            variant="outlined"
          >
            <MenuItem value="Provider_1">Provider 1</MenuItem>
            <MenuItem value="Provider_2">Provider 2</MenuItem>
            <MenuItem value="Provider_3">Provider 3</MenuItem>
          </TextField>
        </div>
        <div className="flex flex-col lg:flex-row gap-4">
          <TextField
            variant="outlined"
            name="chief_complaint"
            label={
              <>
                Chief Complaint <b className="text-red-500 ml-1">*</b>
              </>
            }
            {...register("chief_complaint", {
              required: {
                value: true,
                message: "This field is required",
              },
            })}
            error={errors?.chief_complaint?.message}
            helperText={errors?.chief_complaint?.message}
            fullWidth
            rows={3}
            multiline
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-4 mb-0">
          <FormControl component="fieldset">
            <FormLabel component="legend">Patient Consent</FormLabel>
            <RadioGroup aria-label="patient_consent" name="patient_consent">
              <FormControlLabel
                value="No"
                name="patient_consent"
                control={<Radio />}
                error={errors?.patient_consent?.message}
                {...register("patient_consent", {
                  required: "This field is required",
                })}
                label={
                  <>
                    <span className="font-weight-bold">No, </span>
                    <span className="font-weight-light">
                      I don't give my consent to transmit my health data to the
                      PHIE lite.
                    </span>
                  </>
                }
              />
              <FormControlLabel
                value="Yes"
                name="patient_consent"
                control={<Radio />}
                error={errors?.patient_consent?.message}
                {...register("patient_consent", {
                  required: "This field is required",
                })}
                label={
                  <>
                    <span className="font-weight-bold">Yes, </span>
                    <span className="font-weight-light">
                      I give my consent to transmit my health data to the PHIE
                      lite.
                    </span>
                  </>
                }
              />
            </RadioGroup>
            {errors?.patient_consent && (
              <FormHelperText error>
                {errors.patient_consent.message}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div>
          <a href="#" className="font-weight-bold">
            {"[Click here to view the Patient Consent Form]"}
          </a>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <ButtonV3 onClick={handleSubmit(onSubmit)}>
          <div className="flex">
            <FontAwesomeIcon
              icon={faSave}
              color="primary"
              style={{ fontSize: 18 }}
              className="pr-2"
            />
            Submit
          </div>
        </ButtonV3>
        <ButtonV3 type="primary-outline" onClick={handleClickList}>
          Cancel
        </ButtonV3>
      </div>
    </div>
  );
};

export default ConsultationForm;
