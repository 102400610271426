import React, { useContext, useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
	Grid,
	Box,
	useTheme,
	List,
	ListItem,
	ListItemText,
	Avatar,
	TablePagination,
	ListItemIcon,
	Card,
	CardContent,
	CardActions,
	CardHeader,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import axios from "axios";
import female from "../../../assets/female.png";
const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
	formControl: {
		padding: theme.spacing(1),
		width: "50%",
	},
	selectEmpty: {
		"&:before": {
			borderBottom: "none !important",
		},
		"&:after": {
			borderBottom: "none !important",
		},
		fontSize: 18,
		fontWeight: 800,
	},
	cardHeader: {
		padding: theme.spacing(1),
		height: 50,
	},
	primary: {
		backgroundColor: "orange",
		color: "#fff",
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
}));

export default function ListPatientHasDiseases() {
	const theme = useTheme();
	const classes = useStyles();
	const [cardValue2, setCardValue2] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [selectedPatient, setSelectedPatient] = useState("new-patient");
	const [search, setSearch] = useState("");
	const { users } = useContext(UsersData);
	const [patient, setPatient] = useState({
		data: [],
		ready: false,
	});

	const handleChange = (event) => {
		setCardValue2(event.target.value);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	const getAllPatientList = useCallback(() => {
		var params = {
			user_id: users.user_id,
			management_id: users.management_id,
			main_mgmt_id: users.main_mgmt_id,
		};
		axios
			.get("admission/patients/getpatient-list", { params })
			.then((response) => {
				const data = response.data;
				setPatient({ data: data, ready: true });
			})
			.catch((error) => {
				console.log("error: ", error);
			});
	}, [users]);

	const searchable = patient.data.filter((data) => {
		return (
			data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
			data.lastname.toLowerCase().indexOf(search.trim()) !== -1
		);
	});

	useEffect(() => {
		getAllPatientList();
	}, [getAllPatientList]);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} lg={12}>
					<Card elevation={2} className={"pointer"} component={Box}>
						<div className="flex w-full bg-warning-light px-3 py-1">
							<FormControl
								className={`${classes.formControl} bg-inherit`}
							>
								<Select
									value={cardValue2}
									onChange={handleChange}
									displayEmpty
									className={`${classes.selectEmpty} text-white`}
									inputProps={{
										"aria-label": "Without label",
									}}
								>
									<MenuItem value="">
										PUI (Tuberculosis)
									</MenuItem>
									<MenuItem value={10}>LIST2</MenuItem>
									<MenuItem value={20}>LIST3</MenuItem>
									<MenuItem value={30}>LIST4</MenuItem>
								</Select>
							</FormControl>
						</div>

						<List component="div">
							{patient.ready
								? patient.data.length > 0
									? searchable
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((data, index) => (
												<ListItem
													key={index}
													button
													className="my-2"
													onClick={() =>
														setSelectedPatient(
															data.patient_id
														)
													}
													selected={
														selectedPatient ===
														data.patient_id
													}
												>
													<ListItemIcon>
														{data.image === null ? (
															<Avatar
																className={
																	classes.primary
																}
															>
																<img
																	src={female}
																	className="w-12 h-12 object-contain"
																/>
															</Avatar>
														) : (
															<Avatar
																className={
																	classes.primary
																}
																src={`${imageLocation}patients/${data.image}`}
																alt=""
															/>
														)}
													</ListItemIcon>
													<ListItemText
														className={`gtc-capitalize`}
														primary={`${
															data?.lastname
														}, ${data?.firstname} ${
															Boolean(
																data?.middle
															)
																? data?.middle
																: ``
														} `}
													/>
												</ListItem>
											))
									: Notify.noRecord()
								: Notify.loading()}
						</List>

						<CardActions>
							<TablePagination
								component="div"
								count={patient.data.length}
								rowsPerPageOptions={[
									10,
									50,
									100,
									{ label: "All", value: -1 },
								]}
								page={page}
								onChangePage={handleChangePage}
								rowsPerPage={rowsPerPage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								labelRowsPerPage="List"
							/>
						</CardActions>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}
