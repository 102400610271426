import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAddressCard,
	// faFileMedical,
	faHome,
	faListOl,
	// faTasks,
	faUserInjured,
	faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import NurseDashboard from "src/nurse/NurseDashboard";
import LeaveForm from "src/nurse/leave/LeaveForm";
import Account from "src/nurse/account/Account";
import PatientQueu from "src/nurse/queue/PatientQueu";
import IsMountedRef from "src/utils/IsMountedRef";
import {
	Badge,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Button,
	// Box,
} from "@material-ui/core";
import FollowUpCheck from "src/nurse/queue/followupcheck";
import MedicalCertificate from "src/nurse/medical-certificate";
import NSPatientList from "src/nurse/patientlist/NSPatientList";
import RoomNurse from "src/nurse/room-nurse";
import WardNurse from "src/nurse/ward-nurse";
import DischargeNurse from "src/nurse/discharge-nurse";
import PatientForOperation from "src/nurse/for-operation";
import PatientForPacu from "src/nurse/pacu-nurse";

const RouteNurse = () => {
	const [sidebarHeader, setSidebarHeader] = useState([]);
	const mounted = IsMountedRef();
	const userContext = useContext(UsersData);
	const [patient, setPatients] = useState([]);
	const [newPatientCount, setNewPatientCount] = useState(0);
	var interval = null;

	var [nurseDepartment, setNurseDepartment] = useState("opd-department");

	var [dialog, setDialog] = useState({ open: true });

	const getSidebarHeaderInfo = React.useCallback(async () => {
		try {
			var params = { user_id: userContext.users.user_id };
			const response = await axios.get(
				"nurse/sidebar/header-infomartion",
				{
					params,
				}
			);
			if (mounted.current) {
				setSidebarHeader(response.data);
			}
		} catch (error) {
			console.log("Unable to get sidebar header info.", error);
		}
	}, [userContext, mounted]);

	const getPatientsOnQueue = React.useCallback(async () => {
		let response = await axios.get("nurse/patients/nurse-queue", {
			params: {
				management_id: userContext.users.management_id,
				user_id: userContext.users.user_id,
				department:
					nurseDepartment === "opd-department"
						? "opd-patient"
						: nurseDepartment === "er-department"
						? "er-patient"
						: nurseDepartment === "room-department"
						? "admitted-patient"
						: nurseDepartment === "discharge-department"
						? "admitted-patient"
						: "",
			},
		});

		const data = response.data;
		if (mounted.current) {
			setPatients(response.data);
			if (data.length > 0) {
				setNewPatientCount(data.length);
			} else {
				setNewPatientCount(0);
			}
		}
	}, [mounted, userContext, nurseDepartment]);

	const sidebarRoute = [
		{
			name: "Dashboard",
			show: true,
			icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
			path: "/sph/app/nurse",
			subitem: [],
		},
		{
			name: "Patient Queue",
			show: true,
			icon: <FontAwesomeIcon icon={faListOl} color="white" size={"2x"} />,
			path: "/sph/app/nurse/patient-queue",
			subitem: [],
		},

		{
			name: "Patient List",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserInjured}
					color="white"
					size={"2x"}
				/>
			),
			path: "/sph/app/nurse/patient-list",
			subitem: [],
		},

		// {
		//   name: "Medical Certificate",
		//   show: true,
		//   icon: <FontAwesomeIcon icon={faFileMedical} color="white" size={"2x"} />,
		//   path: "/sph/app/nurse/medical-certificate",
		//   subitem: [],
		// },

		// {
		//   name: "Follow-up Check",
		//   show: true,
		//   icon: <FontAwesomeIcon icon={faTasks} color="white" size={"2x"} />,
		//   path: "/sph/app/nurse/follow-up-check",
		//   subitem: [],
		// },

		{
			name: "Leave Application",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faAddressCard}
					color="white"
					size={"2x"}
				/>
			),
			path: "/sph/app/nurse/leave-application",
			subitem: [],
		},
		{
			name: "Account",
			show: true,
			icon: (
				<FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />
			),
			path: "/sph/app/nurse/account",
			subitem: [],
		},
	];

	const initializeInterval = () => {
		interval = setInterval(() => {
			getPatientsOnQueue();
		}, 20000);
	};

	const handleRenderInfo = () => {
		getSidebarHeaderInfo();
	};

	useEffect(() => {
		getSidebarHeaderInfo();
		getPatientsOnQueue();
		//eslint-disable-next-line
	}, [getSidebarHeaderInfo, getPatientsOnQueue]);

	useEffect(() => {
		initializeInterval();
		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<UsersHeader.Provider
				value={{
					sidebarHeader: sidebarHeader,
					renderPharmacyInfo: handleRenderInfo,
					queue: {
						patient: patient,
						getPatientsOnQueue: () => getPatientsOnQueue(),
					},
					nurseDepartment: nurseDepartment,
					setNurseDepartment: setNurseDepartment,
				}}
			>
				<BrowserRouter>
					<Sidebar
						notification={{
							enable: true,
							owner: "nurse",
						}}
						header={sidebarHeader}
						routes={sidebarRoute}
						module={"nurse"}
					/>
					<Switch>
						<Route
							exact
							path="/"
							component={() => <Redirect to="/sph/app/nurse" />}
						/>

						<Route
							exact
							path="/bmcdc"
							component={() => <Redirect to="/sph/app/nurse" />}
						/>

						<Route
							exact
							path="/sph/app"
							component={() => <Redirect to="/sph/app/nurse" />}
						/>

						<Route
							exact
							path="/sph/app/nurse"
							component={NurseDashboard}
						/>
						{console.log("nurseDepartment", nurseDepartment)}
						<Route
							exact
							path="/sph/app/nurse/patient-queue"
							component={PatientQueu}
						/>

						<Route
							exact
							path="/sph/app/nurse/patient-list"
							component={NSPatientList}
						/>

						<Route
							exact
							path="/sph/app/nurse/medical-certificate"
							component={MedicalCertificate}
						/>

						<Route
							exact
							path="/sph/app/nurse/follow-up-check"
							component={FollowUpCheck}
						/>

						<Route
							exact
							path="/sph/app/nurse/leave-application"
							component={LeaveForm}
						/>

						<Route
							exact
							path="/sph/app/nurse/account"
							component={Account}
						/>

						<Route
							exact
							path="/sph/app/logout"
							component={Logout}
						/>

						<Route
							render={() => (
								<PageNotFound title="Page not found" />
							)}
						/>
					</Switch>
				</BrowserRouter>
			</UsersHeader.Provider>

			<Dialog
				open={dialog.open}
				onClose={() => setDialog({ open: false })}
			>
				<DialogTitle>{"Select Department"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Select department/station where you are currently assign
					</DialogContentText>

					<Button
						onClick={() => {
							setDialog({ open: false });
							setNurseDepartment("opd-department");
						}}
						color="primary"
						variant="contained"
						style={{ marginLeft: 2, marginRight: 2, marginTop: 2 }}
					>
						OPD
					</Button>

					<Button
						onClick={() => {
							setDialog({ open: false });
							setNurseDepartment("er-department");
						}}
						color="primary"
						variant="contained"
						style={{ marginLeft: 2, marginRight: 2, marginTop: 2 }}
					>
						ER
					</Button>

					<Button
						onClick={() => {
							setDialog({ open: false });
							setNurseDepartment("or-department");
						}}
						color="primary"
						variant="contained"
						style={{ marginLeft: 2, marginRight: 2, marginTop: 2 }}
					>
						OR
					</Button>

					<Button
						onClick={() => {
							setDialog({ open: false });
							setNurseDepartment("pacu-department");
						}}
						color="primary"
						variant="contained"
						style={{ marginLeft: 2, marginRight: 2, marginTop: 2 }}
					>
						pacu
					</Button>

					<Button
						onClick={() => {
							setDialog({ open: false });
							setNurseDepartment("ward-department");
						}}
						color="primary"
						variant="contained"
						style={{ marginLeft: 2, marginRight: 2, marginTop: 2 }}
					>
						WARD
					</Button>

					<Button
						onClick={() => {
							setDialog({ open: false });
							setNurseDepartment("room-department");
						}}
						color="primary"
						variant="contained"
						style={{ marginLeft: 2, marginRight: 2, marginTop: 2 }}
					>
						ROOM
					</Button>

					<Button
						onClick={() => {
							setDialog({ open: false });
							setNurseDepartment("discharge-department");
						}}
						color="primary"
						variant="contained"
						style={{ marginLeft: 2, marginRight: 2, marginTop: 2 }}
					>
						DISCHARGE
					</Button>
				</DialogContent>
			</Dialog>
		</Fragment>
	);
};

export default RouteNurse;
