const ModalBody = ({ className, children }) => {
	return (
		<div
			className={` bg-background last:rounded-b-xl ${
				className ? className : "p-3"
			}`}
		>
			{children}
		</div>
	);
};

export default ModalBody;
