import axios from "axios";
import { useClinicContext } from "../context/ClinicContext";

const useClinicHook = () => {
	// const { clinic } = useClinicContext();
	const approveTBPatient = (id) => {
		return axios.post(`/v1/tuberculosis-program/approve/${id}`, {
			_method: "PATCH",
		});
	};
	const servePatient = (patient_id) => {
		return axios.post(`/v1/clinic/patient-serve/${patient_id}`, {
			_method: "PATCH",
			clinic_id: 1,
		});
	};
	const doneServePatient = (patient_id) => {
		return axios.post(`/v1/clinic/patient-done-serve/${patient_id}`, {
			_method: "DELETE",
			clinic_id: 1,
		});
	};
	const clearClinicQueue = () => {
		return axios.post(`/v1/clinic-queue-clear/${1}`, {
			_method: "DELETE",
		});
	};
	return {
		servePatient,
		doneServePatient,
		clearClinicQueue,
		approveTBPatient,
	};
};

export default useClinicHook;
