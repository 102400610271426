import React, { Fragment } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
  TableBody,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import HeaderPrintAll from "src/documentation/printforvan/printall/HeaderPrintAll";
import PatientInfoPrintAll from "src/documentation/printforvan/printall/PatientInfoPrintAll";

const IshiharaPrintAll = ({
  allowHeaderPatientInfo,
  patients,
  ishiharaOrderDetails,
}) => {
  return (
    <>
      {ishiharaOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bolder", color: "#000" }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
                style={{ fontWeight: "bolder", color: "#000" }}
              >
                ISHIHARA TEST
              </span>
            </Typography>
          </Box>
          <Box>
            {ishiharaOrderDetails.data.map((data, index) => (
              <Fragment key={index}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <b>PLATE Nos.</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>NORMAL PERSON</b>
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          <b>
                            PERSON WITH RED-GREEN <br /> DEFICIENCIES
                          </b>
                        </TableCell>
                        <TableCell align="center">
                          <b>
                            PERSON WITH TOTAL COLOR <br /> BLINDNESS AND
                            WEAKNESS
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            1
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.one) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          12
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.one) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          12
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.one) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          12
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            2
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.two) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          8
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.two) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          3
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.two) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            3
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.three) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          6
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.three) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          5
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.three) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            4
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.four) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          29
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.four) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          70
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.four) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            5
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.five) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          57
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.five) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          35
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.five) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            6
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.six) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          5
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.six) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          2
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.six) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            7
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.seven) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          3
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.seven) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          5
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.seven) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            8
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.eight) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          15
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.eight) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          17
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.eight) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            9
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.nine) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          74
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.nine) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          21
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.nine) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            10
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.ten) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          2
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.ten) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.ten) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            11
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.eleven) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          6
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.eleven) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.eleven) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            12
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.twelve) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          97
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twelve) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.twelve) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            13
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.thirteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          45
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.thirteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.thirteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            14
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.fourteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          5
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.fourteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.fourteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            15
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.fifteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          7
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.fifteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.fifteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            16
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.sixteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          16
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.sixteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.sixteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            17
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.seventeen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          73
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.seventeen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.seventeen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            18
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.eighteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.eighteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.eighteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            19
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.nineteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.nineteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.nineteen) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            20
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.twenty) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twenty) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          45
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.twenty) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            21
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.twentyone) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                        <TableCell align="center" colSpan={4}>
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentyone) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          73
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.twentyone) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell align="center" colSpan={2}>
                          <b>Protan</b>
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          <b>Deutan</b>
                        </TableCell>
                        <TableCell />
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            22
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.twentytwo) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          26
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentytwo) === 1 &&
                            data.blindness_type === "protan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          6
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentytwo) === 2 &&
                            data.blindness_type === "protan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          (2) 6
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentytwo) === 1 &&
                            data.blindness_type === "deutan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          2
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentytwo) === 2 &&
                            data.blindness_type === "deutan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          (2) 6
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.twentytwo) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            23
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.twentythree) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          42
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentythree) === 1 &&
                            data.blindness_type === "protan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          2
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentythree) === 2 &&
                            data.blindness_type === "protan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          (4) 2
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentythree) === 1 &&
                            data.blindness_type === "deutan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          4
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentythree) === 2 &&
                            data.blindness_type === "deutan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          (4) 2
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.twentythree) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            24
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.twentyfour) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          35
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentyfour) === 1 &&
                            data.blindness_type === "protan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          2
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentyfour) === 2 &&
                            data.blindness_type === "protan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          (4) 2
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentyfour) === 1 &&
                            data.blindness_type === "deutan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          4
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentyfour) === 2 &&
                            data.blindness_type === "deutan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          (4) 2
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.twentyfour) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={data.ishihara_test === null}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            25
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {data.type_person_1_2_3 === "normal" ? (
                            parseFloat(data.twentyfive) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          96
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentyfive) === 1 &&
                            data.blindness_type === "protan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          2
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentyfive) === 2 &&
                            data.blindness_type === "protan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          (4) 2
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentyfive) === 1 &&
                            data.blindness_type === "deutan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          4
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "deficiencies" ? (
                            parseFloat(data.twentyfive) === 2 &&
                            data.blindness_type === "deutan" ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          (4) 2
                        </TableCell>

                        <TableCell align="center">
                          {data.type_person_1_2_3 === "blindness" ? (
                            parseFloat(data.twentyfive) === 1 ? (
                              <CheckIcon color="primary" />
                            ) : (
                              <CloseIcon color="secondary" />
                            )
                          ) : null}{" "}
                          x
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box>
                  <Box display="flex" my={3}>
                    <Box flexGrow={1}>
                      <Box mt={2} display="flex">
                        <Typography variant="subtitle1">
                          <b> REMARKS: </b>
                        </Typography>

                        <Box ml={2} borderBottom={1} width={250} align="center">
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bolder" }}
                          >
                            {`${data.test_score}/25 ${
                              parseFloat(data.test_score) > 19
                                ? "ADEQUATE"
                                : "INADEQUATE"
                            }`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {/* <Box>
                  <Box align="center" width={250}>
                    <Box mt={5}>
                      <Box borderTop={1}>
                        <b> SIGNATURE OF TECHNICIAN </b>
                      </Box>
                    </Box>
                  </Box>
                </Box> */}
                  </Box>
                </Box>
              </Fragment>
            ))}
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default IshiharaPrintAll;
