import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import Divider from "@material-ui/core/Divider";
import SarsCovPrintAll from "./forms/SarsCovPrintAll";

const OtherPrintAll = ({
  allowHeaderPatientInfo,
  patient_id,
  trace_number,
  patients,
  selectedBranch,
}) => {
  const { users } = useContext(UsersData);
  const [sarsCovOrderDetails, setSarsCovOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const getOtherOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get("laboratory/order/ordernew-sarscov/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setSarsCovOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getOtherOrderDetails();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {sarsCovOrderDetails.ready && sarsCovOrderDetails.data.length > 0 && (
        <>
          <SarsCovPrintAll
            allowHeaderPatientInfo={allowHeaderPatientInfo}
            patients={patients}
            sarsCovOrderDetails={sarsCovOrderDetails}
          />
          <Divider light />
        </>
      )}
    </>
  );
};

export default OtherPrintAll;
