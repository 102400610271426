import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import {
	Avatar,
	Dialog,
	Zoom,
	IconButton,
	makeStyles,
	Button,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { Paper } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { CancelOutlined } from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import QRCode from "qrcode.react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faImage } from "@fortawesome/free-solid-svg-icons";
import ImagePicker from "src/components/forms/ImagePicker";
import { dataURItoBlob, isBase64, patientFullName } from "src/helpers/utils";
import axios from "axios";
import Img from "src/components/Img";
// import { SRLWrapper } from "simple-react-lightbox";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "0 30px",
		width: "100%",
	},
	image: {
		marginTop: 25,
		marginBottom: 55,
		height: isMobile ? 250 : 400,
		border: "1px solid #aaa",
		margin: "auto",
		display: "block",
	},
	tag: {
		zIndex: 1,
		position: "absolute",
		paddingTop: 26,
		marginLeft: 38,
	},
	tag_label: {
		backgroundColor: "#00000094",
		color: "#fff",
		padding: 2,
		paddingLeft: 10,
		paddingRight: 10,
		borderRadius: 10,
	},
}));

const PatientDetailsHeader = ({ updatePatientData, patient_id, info }) => {
	const [openLightbox, setOpenLightbox] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const classes = useStyles();
	const [current, setCurrent] = useState(0);

	// const options = {
	//   buttons: {
	//     backgroundColor: "#000",
	//     iconColor: "#fff",
	//     showFullscreenButton: true,
	//     showThumbnailsButton: true,
	//     showDownloadButton: false,
	//   },
	//   settings: {
	//     disablePanzoom: true,
	//   },
	//   thumbnails: {
	//     showThumbnails: false,
	//   },
	// };

	const downloadQRCode = () => {
		// Generate download with use canvas and stream
		const canvas = document.getElementById("qr-gen");
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = `${info.data.lastname}_${info.data.firstname}.png`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	const saveAvatar = (avatar) => {
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
			// onUploadProgress: progressEvent => onProgress(progressEvent),
		};
		console.log("avataravatar", avatar);
		const form = new FormData();
		const file = dataURItoBlob(avatar);
		form.append("_method", "PATCH");
		form.append("avatar", file);

		axios.post(`v1/patient-profile/${info?.data?.id}`, form, config);
	};

	return (
		<>
			<Box component={Paper} variant="outlined" borderRadius={4} p={2}>
				{info.ready ? (
					info.data ? (
						<div>
							<Box display={"flex"}>
								<Box flexGrow={1}>
									<div className="clearfix">
										<div className="float-left relative">
											{console.log(
												"info.datainfo.data",
												info.data
											)}
											{/* <SRLWrapper options={options}> */}
											<Img
												style={{
													width: "120px",
													height: "120px",
													cursor: "pointer",
													border: "1px solid",
												}}
												className="object-contain rounded-full"
												type="user"
												src={info.data.avatar}
												name={`${info?.data?.firstname} ${info?.data?.lastname}`}
												onClick={() => {
													setSelectedImage(
														info.data.image
													);
													setOpenLightbox(true);
												}}
											/>
											<ImagePicker
												className="absolute -bottom-1 cursor-pointer -mr-4 w-11 h-11 bg-primary bg-opacity-40 group-hover:bg-primary group-hover:bg-opacity-100 duration-200 rounded-full flex items-center justify-center"
												onChange={(data) => {
													updatePatientData({
														id: info?.data?.id,
														avatar: data,
													});
													if (isBase64(data)) {
														saveAvatar(data);
													}
												}}
											>
												{() => {
													return (
														<FontAwesomeIcon
															icon={faCamera}
															color="white"
															size={"2x"}
														/>
													);
												}}
											</ImagePicker>
											{/* <label className="w-11 h-11 bg-slate-100 border hover:shadow hover:bg-slate-200 duration-200 z-10 absolute rounded-full mt-[-120px] ml-[92px] flex items-center justify-center cursor-pointer">
												<input
													type="file"
													accept="image/*"
													capture="user"
													className="opacity-0 !w-0"
												/>
												<img
													src="/upload-photo.png"
													className="w-6 h-6 object-contain ml-1"
												/>
											</label> */}
											{/* </SRLWrapper> */}
										</div>
										<div className="ml-sm-4 float-left">
											<p className="h5 header-label text-capitalize m-0 my-2 font-weight-bold">
												{info.data.lastname},{" "}
												{info.data.firstname}{" "}
												{info.data.middle}
											</p>
											<p className="m-0 my-2">
												<span className="text-muted gtc-small text-uppercase">
													{" "}
													Age:{" "}
												</span>{" "}
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
												{Notify.calculateAge(
													info.data.birthday
												)}{" "}
												years old
											</p>
											<p className="m-0 my-2">
												<span className="text-muted gtc-small text-uppercase">
													{" "}
													Gender:{" "}
												</span>{" "}
												&nbsp;&nbsp; {info.data.gender}
											</p>
											<p className="m-0 my-2">
												<span className="text-muted gtc-small text-uppercase">
													{" "}
													Birthday:{" "}
												</span>{" "}
												{Notify.birthday(
													info.data.birthday
												)}
											</p>
										</div>
									</div>
								</Box>

								<Box textAlign={"center"}>
									<Box>
										<QRCode
											id="qr-gen"
											value={JSON.stringify({
												patient_id:
													info.data.patient_id,
												user_id: info.data.user_id,
												philhealth:
													info.data.philhealth,
											})}
											level="H"
											size={130}
										/>
									</Box>

									<Box>
										<Button
											onClick={downloadQRCode}
											color="primary"
											size="small"
											variant="contained"
										>
											{" "}
											Download QR Core{" "}
										</Button>
									</Box>
								</Box>
							</Box>
						</div>
					) : (
						Notify.noRecord()
					)
				) : (
					Notify.loading()
				)}
			</Box>

			<Dialog
				open={openLightbox}
				onClose={() => setOpenLightbox(false)}
				TransitionComponent={Zoom}
				transitionDuration={800}
				maxWidth="sm"
				fullWidth
			>
				<Box display="flex">
					<Box flexGrow={1} />
					<IconButton
						color="secondary"
						onClick={() => {
							setOpenLightbox(false);
						}}
					>
						<CancelOutlined />
					</IconButton>
				</Box>
				<Box>
					<SwipeableViews
						className={classes.root}
						slideStyle={{ padding: "0 10px" }}
						enableMouseEvents
						index={current}
						onChangeIndex={(e) => setCurrent(e)}
					>
						<Box>
							<img
								alt="attach-file-pt"
								src={`${imageLocation}/patients/${selectedImage}`}
								className={classes.image}
							/>
						</Box>
					</SwipeableViews>
				</Box>
			</Dialog>
		</>
	);
};

export default PatientDetailsHeader;
