import { faHouseUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import CardContainer from "src/components/CardContainer";
import LayoutContainer from "src/components/LayoutContainer";
import CollapseDiv from "./components/CollapseDiv";
import IdentificationForm from "./components/Forms/IdentificationForm";
import HousingForm from "./components/Forms/HousingForm";
import HouseholdCharacteristicsForm from "./components/Forms/HouseholdCharacteristicsForm";
import CalamityForm from "./components/Forms/CalamityForm";
import WasteManagementForm from "./components/Forms/WasteManagementForm";
import HouseForm from "./components/Forms/HouseForm";
import IncomeForm from "./components/Forms/IncomeForm";
import WaterAndSanitation from "./components/Forms/WaterAndSanitation";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { RiseLoader } from "react-spinners";
import LoadingTemplate from "src/components/LoaderTemplate";
import ActionBtn from "src/components/ActionBtn";
import { Save } from "react-feather";
import { useLayoutEffect } from "react";
import Notify from "src/notification/Notify";

const SaveForm = ({ handleSubmit, submit, saving }) => {
	return (
		<div className="ml-auto flex items-center">
			<ActionBtn
				type="success"
				onClick={handleSubmit(submit)}
				loading={saving}
			>
				<Save className="w-5 mr-2" /> Save changes
			</ActionBtn>
		</div>
	);
};

const excluded_fields = [
	"fp_method",
	"fp_natural",
	"number_pets",
	"jobList",
	"calamity",
	"garbage_disposal",
	"collector",
	"often_garbage",
	"com_channel",
	"birthdate",
	"date_interview_001",
	"time_started",
];

const invalidValue = ["null", null, "undefined", undefined];

const valueToArray = (val) => {
	if (invalidValue.includes(val)) {
		return "";
	}
	return val.split(",");
};
const valueToArraySpace = (val) => {
	if (invalidValue.includes(val)) {
		return "";
	}
	return val.split(" ");
};
const HouseholdFormPage = () => {
	let params = useParams();
	const history = useHistory();
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		control,
		watch,
		getValues,
		formState: { errors },
	} = useForm({
		defaultValues: {
			jobList: [],
			jobIncome: "",
			incomeValues: [],
		},
	});
	const [purokList, setPurokList] = useState([]);

	const getPurokList = (brgy_id) => {
		axios.get(`v1/purok-by-barangay?barangay_id=${brgy_id}`).then((res) => {
			setPurokList(res.data);
		});
	};
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [patient, setPatient] = useState(null);
	const [household, setHousehold] = useState(null);
	const [city_id, setCityId] = useState(0);

	const [municipalities, setMunicipalities] = useState([]);
	const [brgys, setBrgys] = useState([]);

	useLayoutEffect(() => {
		console.log("paramsss", params);
		getLocations();
	}, []);
	const getLocations = () => {
		axios.get("/v1/locations").then((res) => {
			setMunicipalities(res.data);
			setTimeout(() => {
				if (params?.id) {
					getHouseholdData(params?.id);
				} else {
					setLoading(false);
				}
			}, 100);
		});
	};

	const setBarangayValue = useCallback(
		(data) => {
			setValue("barangay", data?.barangay?.id);
		},
		[brgys, municipalities]
	);

	useEffect(() => {
		if (city_id > 0) {
			console.log(' watch("municipality")', watch("municipality"));
			setBrgys(
				municipalities.find((x) => x.id == city_id)
					? municipalities.find((x) => x.id == city_id).barangays
					: []
			);
		}
	}, [city_id, watch("municipality")]);
	const getHouseholdData = (id) => {
		return axios.get(`v1/households/${id}`).then((res) => {
			let data = res?.data?.data || null;
			setHousehold(data);
			setTimeout(() => {
				setValue("province", data?.province);
				setValue("municipality", data?.municipality?.id);
				setCityId(data?.municipality?.id);
				setValue("zone", data?.zone);
				setValue("lat", data?.lat);
				setValue("lng", data?.lng);
				setValue("respondent", data?.respondent);
				console.log(
					"brgys 111",
					municipalities.find((x) => x.id == data?.municipality?.id)
						? municipalities.find(
								(x) => x.id == data?.municipality?.id
						  ).barangays
						: "empty"
				);
				setTimeout(() => {
					setBarangayValue(data);
				}, 500);
				setValue("purok_sitio", data?.purok);
				setValue("purok", data?.purok);

				setValue("street", data?.street);
				setValue("house_number", data?.house_number);
				setValue("house_id", data?.house_id);

				if (data?.rawAnswer != null) {
					Object.keys(data?.rawAnswer).map((key) => {
						if (!excluded_fields.includes(key)) {
							setValue(
								key,
								invalidValue.includes(data?.rawAnswer[key])
									? ""
									: data?.rawAnswer[key]
							);
						}
					});
					setValue(
						"fp_method",
						valueToArray(data?.rawAnswer?.fp_method)
					);
					setValue(
						`fp_natural`,
						valueToArray(data?.rawAnswer?.fp_natural)
					);
					setValue(
						`number_pets`,
						valueToArray(data?.rawAnswer?.number_pets)
					);
					setValue("jobList", valueToArray(data?.rawAnswer?.jobList));
					setValue(
						"calamity",
						valueToArray(data?.rawAnswer?.calamity)
					);
					setValue(
						"garbage_disposal",
						valueToArray(data?.rawAnswer?.garbage_disposal)
					);
					setValue(
						"collector",
						valueToArray(data?.rawAnswer?.collector)
					);
					setValue(
						"often_garbage",
						valueToArray(data?.rawAnswer?.often_garbage)
					);
					setValue(
						"com_channel",
						valueToArray(data?.rawAnswer?.com_channel)
					);
				}
				setLoading(false);
			}, 1000);
		});
	};
	const submit = (data) => {
		setSaving(true);
		console.log("submit data", data);
		let formdata = new FormData();
		Object.keys(data).map((key) => {
			if (key != "jobIncome" || key != "incomeValues") {
				formdata.append(key, data[key]);
			}
		});
		formdata.append("jobIncome", data.incomeValues);

		let url = `/v1/households`;
		if (household?.id) {
			url = url + `/${household?.id}`;
			formdata.append("_method", "PATCH");
		}
		axios
			.post(url, formdata)
			.then((res) => {
				setTimeout(() => {
					if (household?.id) {
						Notify.customToast(
							"success",
							"Household updated successfully!"
						);
					} else {
						Notify.customToast(
							"success",
							"New household created successfully!"
						);
					}
				}, 400);
				history.goBack();
			})
			.catch((error) => {
				Notify.requestError(error);
			})
			.finally(() => {
				setSaving(false);
			});
	};
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faHouseUser}
						color="white"
						size={"2x"}
					/>
				}
				title={`${household?.id ? "Edit" : "Add"} Household`}
				subtitle={`${
					household?.id ? "Update" : "Fill-in"
				} household information`}
				titleChildren={
					<div className="w-full lg:w-[unset] lg:ml-auto ">
						<SaveForm
							handleSubmit={handleSubmit}
							submit={submit}
							saving={saving}
						/>
					</div>
				}
			>
				<div className="container gap-y-4 flex flex-col p-">
					{loading ? (
						<LoadingTemplate />
					) : (
						<>
							<CollapseDiv
								defaultOpen={true}
								headerClassName="bg-slate-100"
								bodyClassName="bg-white"
								title="A. Identification"
							>
								<div>
									<IdentificationForm
										household={household}
										Controller={Controller}
										register={register}
										errors={errors}
										setValue={setValue}
										control={control}
										watch={watch}
										municipalities={municipalities}
										setMunicipalities={setMunicipalities}
										brgys={brgys}
										setBrgys={setBrgys}
										purokList={purokList}
										setPurokList={setPurokList}
									/>
								</div>
							</CollapseDiv>
							<CollapseDiv
								defaultOpen={true}
								headerClassName="bg-slate-100"
								bodyClassName="bg-white"
								title="B. HOUSING CHARACTERISTICS"
							>
								<div>
									<HousingForm
										household={household}
										Controller={Controller}
										register={register}
										errors={errors}
										setValue={setValue}
										control={control}
										watch={watch}
										municipalities={municipalities}
										setMunicipalities={setMunicipalities}
										brgys={brgys}
										setBrgys={setBrgys}
										purokList={purokList}
										setPurokList={setPurokList}
									/>
								</div>
							</CollapseDiv>
							<CollapseDiv
								defaultOpen={true}
								headerClassName="bg-slate-100"
								bodyClassName="bg-white"
								title="C. CHARACTERISTICS OF THE HOUSEHOLD"
							>
								<div>
									<HouseholdCharacteristicsForm
										Controller={Controller}
										register={register}
										errors={errors}
										setValue={setValue}
										control={control}
										watch={watch}
										household={household}
									/>
								</div>
							</CollapseDiv>
							<CollapseDiv
								defaultOpen={true}
								headerClassName="bg-slate-100"
								bodyClassName="bg-white"
								title="K. WATER AND SANITATION"
							>
								<div>
									<WaterAndSanitation
										Controller={Controller}
										register={register}
										errors={errors}
										setValue={setValue}
										control={control}
										watch={watch}
									/>
								</div>
							</CollapseDiv>
							<CollapseDiv
								defaultOpen={true}
								headerClassName="bg-slate-100"
								bodyClassName="bg-white"
								title="L. HOUSING"
							>
								<div>
									<HouseForm
										Controller={Controller}
										register={register}
										errors={errors}
										setValue={setValue}
										control={control}
										watch={watch}
									/>
								</div>
							</CollapseDiv>
							<CollapseDiv
								defaultOpen={true}
								headerClassName="bg-slate-100"
								bodyClassName="bg-white"
								title="M. WASTE MANAGEMENT"
							>
								<div>
									<WasteManagementForm
										Controller={Controller}
										register={register}
										errors={errors}
										setValue={setValue}
										control={control}
										watch={watch}
									/>
								</div>
							</CollapseDiv>
							<CollapseDiv
								defaultOpen={true}
								headerClassName="bg-slate-100"
								bodyClassName="bg-white"
								title="N. SOURCES OF INCOME"
							>
								<div>
									<IncomeForm
										Controller={Controller}
										register={register}
										errors={errors}
										setValue={setValue}
										control={control}
										getValues={getValues}
										watch={watch}
									/>
								</div>
							</CollapseDiv>

							<CollapseDiv
								defaultOpen={true}
								headerClassName="bg-slate-100"
								bodyClassName="bg-white"
								title="CALAMITY"
								show={true}
							>
								<div>
									<CalamityForm
										Controller={Controller}
										register={register}
										errors={errors}
										setValue={setValue}
										control={control}
										watch={watch}
									/>
								</div>
							</CollapseDiv>
							<SaveForm
								handleSubmit={handleSubmit}
								submit={submit}
							/>
						</>
					)}
				</div>
			</LayoutContainer>
		</>
	);
};

export default HouseholdFormPage;
