import React, { useState, useContext } from "react";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import DischargePatient from "./DischargePatient";

const Details = ({ details, getAdmittedPatientsForBillout, resetDisplay }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [patientBills, setPatientBills] = useState([]);
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();

  const [dischargeDialog, setDischargeDialog] = useState({
    open: false,
  });

  const getallBillForPaymentByPatient = React.useCallback(async () => {
    axios
      .get(`billing/admitted/patient/get-patient-billslist`, {
        params: {
          trace_number: details?.trace_number,
          patient_id: details?.patient_id,
          management_id: users?.management_id,
          main_mgmt_id: users?.main_mgmt_id,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setPatientBills(data);
        }
      })
      .catch((err) => console.log(`error:`, err.message))
      .finally(() => setIsLoading(false));
  }, [details, mounted, users]);

  React.useEffect(() => {
    getallBillForPaymentByPatient();
  }, [getallBillForPaymentByPatient]);

  let totalbill = 0;

  return (
    <Box>
      <Card>
        <CardHeader title="Patients Bill" />
        <CardContent>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong> Bill </strong>
                  </TableCell>

                  <TableCell>
                    <strong> Type </strong>
                  </TableCell>

                  <TableCell>
                    <strong> Amount </strong>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!isLoading ? (
                  patientBills.length > 0 ? (
                    patientBills.map((data, key) => (
                      <TableRow key={key}>
                        <TableCell> {data.bill_name} </TableCell>
                        <TableCell> {data.bill_from} </TableCell>
                        <TableCell align="right">
                          {data.bill_amount}
                          <span hidden>
                            {(totalbill += parseFloat(data.bill_amount))}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography color="primary">
                        Loading, please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell />
                  <TableCell>
                    <strong>Bill Amount</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{Notify.convertToNumber(totalbill)}</strong>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell />
                  <TableCell>
                    <strong>Amount Philhealth </strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>
                      {Notify.convertToNumber(
                        patientBills?.[0]?.philhealth_amount ?? 0
                      )}
                    </strong>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell />
                  <TableCell>
                    <strong>Total To Pay </strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>
                      {Notify.convertToNumber(
                        totalbill -
                          parseFloat(patientBills?.[0]?.philhealth_amount ?? 0)
                      )}
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </CardContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setDischargeDialog({ open: true })}
            // disabled={patientBills.length === 0}
          >
            Discharge Patient
          </Button>
        </DialogActions>
      </Card>

      <Dialog
        open={dischargeDialog.open}
        onClose={() => setDischargeDialog({ open: false })}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle> Discharge Patient </DialogTitle>
        <DischargePatient
          amount={
            totalbill - parseFloat(patientBills?.[0]?.philhealth_amount ?? 0)
          }
          philhealth={patientBills?.[0]?.philhealth}
          philhealth_caseno={patientBills?.[0]?.philhealth_caseno}
          philhealth_amount={patientBills?.[0]?.philhealth_amount}
          patient_id={details?.patient_id}
          trace_number={details?.trace_number}
          onClose={() => setDischargeDialog({ open: false })}
          getallBillForPaymentByPatient={getallBillForPaymentByPatient}
          getAdmittedPatientsForBillout={getAdmittedPatientsForBillout}
          resetDisplay={resetDisplay}
        />
      </Dialog>
    </Box>
  );
};

export default Details;
