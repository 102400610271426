import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";

const ReportByPhilhealth = () => {
  const mounted = IsMountedRef();
  const [philhealthList, setPhilhealthList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { users } = useContext(UsersData);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const getPhilhealthList = React.useCallback(() => {
    axios
      .get(`cashier/report/philhealth-list`, {
        params: {
          management_id: users.management_id,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setPhilhealthList(data);
        }
      })
      .catch((err) => console.log(`error:`, console.log(err.message)))
      .finally(() => setIsLoading(false));
  }, [mounted, users]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  useEffect(() => {
    getPhilhealthList();
  }, [getPhilhealthList]);

  const searchable = philhealthList.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <div>
      <Card>
        <CardHeader title={"Philhealth Report"} />
        <CardContent>
          <Box maxWidth={230}>
            <TextField
              fullWidth
              label="Search Patient"
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              margin="dense"
            />
          </Box>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Patient </TableCell>
                <TableCell> Trace Number </TableCell>
                <TableCell> Philhealth </TableCell>
                <TableCell> Case No </TableCell>
                <TableCell> Amount </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!isLoading ? (
                searchable.length > 0 ? (
                  (limit > 0
                    ? searchable.slice(page * limit, page * limit + limit)
                    : searchable
                  ).map((data, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        {data.lastname}, {data.firstname}
                      </TableCell>
                      <TableCell>
                        {data.trace_number?.replace("trace-", "")}
                      </TableCell>
                      <TableCell> {data.philhealth} </TableCell>
                      <TableCell> {data.philhealth_caseno} </TableCell>
                      <TableCell align={"right"}> {data.amount} </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography color="secondary">No record found</Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography color="primary">
                      Loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions>
          <TablePagination
            component={"div"}
            count={searchable.length}
            labelRowsPerPage="List"
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 20, 50]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

export default ReportByPhilhealth;
