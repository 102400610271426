import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TablePagination, TextField, IconButton, useTheme } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Search } from 'react-feather';
import { UsersData } from 'src/ContextAPI';
import Notify from 'src/notification/Notify';

const RadiologistPatientReviewed = () => {
 
    const { users } = React.useContext(UsersData)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const themes = useTheme()
    const [records , setRecords] = useState({
        data: [],
        ready: false
    }) 

    const getPatientRead = React.useCallback( async() =>{
        try { 
            var params = { 
                user_id: users.user_id
            }
            const request =  await axios.get('radiologist/patients/getpatients-read', {params})
                setRecords({
                    data: request.data,
                    ready: true
                })
        } catch (error) {
            Notify.requestError(error)
        }
    },[users])


    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }; 

    const handleFilteredReport = async (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
            formdata.set('user_id', users.user_id)
        let response = await axios.post('radiologist/patients/getpatients-read/bydate', formdata)
        setRecords({ data: response.data, ready: true })
    }       


    useEffect(() => {

        getPatientRead()

    },[getPatientRead])

    return(
        <Paper
            variant="outlined"
            component={Box}
            p={2}
        >
            <Box display="flex" my={2}>
                <Box flexGrow={1}>
                    <Typography
                        variant="h6"
                        color="textSecondary" 
                    >
                        Patient Reviewed
                    </Typography> 
                </Box> 
                
                <form onSubmit={ handleFilteredReport }>
                    <Box display="flex">
                        <Box>
                            <TextField  
                                InputLabelProps={{
                                    shrink: true
                                }} 
                                type="date" 
                                variant="outlined" 
                                label="From" 
                                name="from"
                            />
                        </Box>

                        <Box mx={2}>
                            <TextField  
                                InputLabelProps={{
                                    shrink: true
                                }} 
                                type="date" 
                                variant="outlined" 
                                label="To" 
                                name="to"
                            />
                        </Box>
                        <Box>
                            <IconButton type="submit" style={{ backgroundColor: themes.palette.primary.main, color: '#fff' }}>
                                <Search />
                            </IconButton>
                        </Box>
                    </Box>
                </form>
            </Box>
            
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow> 
                            <TableCell> Patient </TableCell> 
                            <TableCell> Order </TableCell>
                            <TableCell> Date </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            records.ready ? 
                                records.data.length > 0 ?
                                    (rowsPerPage > 0 ? records.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : records.data)
                                    .map((data, index) => (
                                        <TableRow
                                            key={ index }
                                            hover
                                        > 
                                            <TableCell>
                                                { data.patient_name }
                                            </TableCell> 
                                            <TableCell>
                                                { data.imaging_order }
                                            </TableCell>
                                            <TableCell> 
                                                { Notify.dateTimeConvert(data.created_at) }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                :   <TableRow>
                                        <TableCell colSpan={3}>
                                            <Typography color="error">
                                                No record found.
                                            </Typography>
                                        </TableCell> 
                                    </TableRow>
                            :   <TableRow>
                                    <TableCell colSpan={3}>
                                        <Typography color="textPrimary">
                                            please wait...
                                        </Typography>
                                    </TableCell> 
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                labelRowsPerPage="list"
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={records.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    )
}

export default RadiologistPatientReviewed;