import axios from "axios";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Button from "src/components/Button";
import TextInputField from "src/components/forms/TextInputField";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { UsersData, UsersHeader } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import NewPatientForm from "../NewPatientForm";
import PatientForm from "./PatientForm";

const AddPatientFormModal = (props, ref) => {
	const { setKey } = props;
	const [open, setOpen] = useState(false);
	const [patient, setPatient] = useState(null);
	const [selectedSickness, setSelectedSickness] = useState([]);
	const { sidebarHeader } = useContext(UsersHeader);
	const [accreditedCompany, setAccreditedCompany] = useState([]);
	const sicknessValue = "";
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { users } = useContext(UsersData);
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));
	const getPatientInfo = (patient) => {
		var params = { patient_id: patient?.patient_id };
		axios
			.get("admission/patient/patient-information", { params })
			.then((res) => {
				setPatient(res.data);
				reset({ ...res.data, middlename: res.data?.middle });
			});
	};
	const show = (data) => {
		console.log("datadatadata", data);
		if (data?.patient_id) {
			setTimeout(() => {
				getPatientInfo(data);
				// reset({ ...patient });
			}, 200);
		} else {
			setPatient(null);
			reset({
				id: "",
				patient_id: "",
				encoders_id: "",
				doctors_id: "",
				management_id: "",
				main_mgmt_id: "",
				user_id: "",
				firstname: "",
				lastname: "",
				middle: "",
				email: "",
				mobile: "",
				telephone: "",
				birthday: "",
				birthplace: "",
				gender: "",
				civil_status: "",
				religion: "",
				height: "",
				weight: "",
				occupation: "",
				street: "",
				barangay: "",
				city: "",
				municipality: "",
				tin: "",
				philhealth: "",
				company: "",
				zip: "",
				blood_type: "",
				blood_systolic: "",
				blood_diastolic: "",
				temperature: "",
				pulse: "",
				lmp: "",
				rispiratory: "",
				glucose: "",
				uric_acid: "",
				hepatitis: "",
				tuberculosis: "",
				dengue: "",
				cholesterol: "",
				allergies: "",
				medication: "",
				covid_19: "",
				swine_flu: "",
				hiv: "",
				asf: "",
				vacinated: "",
				pro: "",
				remarks: "",
				image: "",
				status: "",
				patient_condition: "",
				doctors_response: "",
				is_edited_bydoc: "",
				package_selected: "",
				join_category: "",
				added_by: "",
				added_from: "",
				created_at: "",
				updated_at: "",
				zone: "",
				house_number: "",
				purok: "",
				instance_id: "",
				surveyor_id: "",
				sickness: "",
				latestCCRecord: "",
				purpose: "",
				last_xray_taken: "",
				last_xray_result: "",
				history_of_travel_date: "",
				history_of_travel_days: "",
				history_of_travel_place: "",
				contact_with_puipum: "",
				contact_with_positive: "",
				crt_purpose: "",
				crt_requestedby: "",
				work_company: "",
				contact_information: "",
				allow_quarantine_ifpositive: "",
				triage_staff: "",
			});
		}
		getAccreditedCompanyList();
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};
	const getAccreditedCompanyList = () => {
		axios
			.get("admission/accredited/company/get-list", {
				params: {
					management_id: users.management_id,
					main_mgmt_id: users.main_mgmt_id,
				},
			})
			.then((res) => {
				setAccreditedCompany(res.data);
			});
	};
	const submit = (data) => {
		setLoading(true);
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("management_id", users.management_id);
		formdata.set("main_mgmt_id", users.main_mgmt_id);
		formdata.set("sickness", data?.gilad);
		formdata.set("triage_staff", sidebarHeader.name);
		formdata.set(
			"trace_number",
			`trace-${Math.floor(Math.random() * 9999)}-${new Date().getTime()}`
		);
		if (data) {
			Object.keys(data).map((key) => {
				formdata.set(key, data[key]);
			});
		}
		axios
			.post("admission/patients/newpatient-save", formdata)
			.then((response) => {
				setLoading(false);
				const data = response.data;
				if (data === "success") {
					Notify.successRequest("new patient added.");
					setKey(`key-${Math.random(1000)}`);
					hide();
				}
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const handleChangeSickness = (e) => {
		let test = selectedSickness;
		if (test.includes(e.target.value)) {
			let testx = test.filter((item) => item !== e.target.value);
			setSelectedSickness(testx);
		} else {
			setSelectedSickness(test.concat(e.target.value));
		}
	};
	return (
		<Modal open={open} hide={hide} size="xl">
			<ModalHeader
				title={"Patient Form"}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p- bg-white`}>
				<PatientForm
					register={register}
					errors={errors}
					selectedSickness={selectedSickness}
					setValue={setValue}
					setSelectedSickness={setSelectedSickness}
					accreditedCompany={accreditedCompany}
				/>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button loading={loading} onClick={handleSubmit(submit)}>
					Submit
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(AddPatientFormModal);
