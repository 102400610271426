import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TextField,
  IconButton,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
  DialogContent,
  Grid,
} from "@material-ui/core";
import React, { useContext, useCallback, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import { useReactToPrint } from "react-to-print";
import { CheckSquare, Edit, Printer, XCircle } from "react-feather";
import FormHeader from "./FormHeader";
import axios from "axios";
import {
  AddCircleOutline,
  Cancel,
  BorderColor,
  Delete,
  Add,
} from "@material-ui/icons";
import Notify from "src/notification/Notify";

const FormFourteen = ({
  patient_id,
  details,
  info,
  doctorsList,
  allowEdit,
}) => {
  const printableRef = React.useRef();
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [isProcess, setIsProcess] = React.useState(false);
  const { users } = useContext(UsersData);
  const [isprocessCancel, setIsProcessCancel] = React.useState(false);
  const [addToTable, setAddToTable] = React.useState(false);
  const [ccpac_id, setccpac_id] = useState(null);
  const [existingPostAnesthesiaChart, setExistingPostAnesthesiaChart] =
    React.useState({
      data: [],
      ready: false,
    });

  const [
    existingPostAnesthesiaChartTable,
    setExistingPostAnesthesiaChartTable,
  ] = React.useState({
    data: [],
    ready: false,
  });

  const [cancelOrder, setCancelOrder] = React.useState({
    data: null,
    dialog: false,
  });

  const [editOrder, setEditOrder] = React.useState({
    data: null,
    dialog: false,
  });

  const [_inputNewPostAnesthesia, setInputNewPostAnesthesia] = React.useState({
    data: [],
    ready: true,
  });

  // const [chartOpDoctor, setChartOpDoctor] = React.useState("");

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const getPostAnesthesiaCareUnitChart = useCallback(async () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/postanesthesiacareunit", { params })
      .then((response) => {
        const data = response.data;
        setExistingPostAnesthesiaChart({ data, ready: true });
        setccpac_id(data[0].ccpac_id);
      })
      .catch((error) => console.log(error));
  }, [patient_id, users, details]);

  const getPostAnesthesiaCareUnitChartTable = useCallback(async () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/postanesthesiacareunittable", { params })
      .then((response) => {
        const data = response.data;
        setExistingPostAnesthesiaChartTable({ data, ready: true });
      })
      .catch((error) => console.log(error));
  }, [patient_id, users, details]);

  const addField = () => {
    setInputNewPostAnesthesia({
      data: _inputNewPostAnesthesia.data.concat({
        date_time: "date_time[]",
        bp: "bp[]",
        temp: "temp[]",
        pr: "pr[]",
        rr: "rr[]",
        o2_sat: "o2_sat[]",
        remarks: "remarks[]",
        ivf_solution: "ivf_solution[]",
        ivf_date_time: "ivf_date_time[]",
        ivf_remarks: "ivf_remarks[]",
      }),
      ready: true,
    });
  };

  const removeField = (index, length) => {
    const list = _inputNewPostAnesthesia.data;
    setInputNewPostAnesthesia({
      ..._inputNewPostAnesthesia,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputNewPostAnesthesia({
      data: list,
      ready: true,
    });
  };

  const handleSubmitNewPostAnesthesia = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", details.trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    formdata.set("status", ccpac_id === null ? "for-new" : "for-update");
    formdata.set("ccpac_id", ccpac_id);

    setIsProcess(true);

    axios
      .post("or/update/chart/postanesthesiacareunit", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          getPostAnesthesiaCareUnitChart();
          getPostAnesthesiaCareUnitChartTable();
          setEnableEdit(false);
          e.target.reset();
          Notify.successRequest("chart updated");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  const handlePostAnesthesiaCareUnit = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);

    setIsProcessCancel(true);
    axios
      .post("or/remove/chart/postanesthesiacareunit", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          getPostAnesthesiaCareUnitChartTable();
          Notify.successRequest("chart updated");
          setCancelOrder({ data: null, dialog: false });
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  const handleEditPostAnesthesiaCareUnit = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);

    setIsProcessCancel(true);
    axios
      .post("or/edit/chart/postanesthesiacareunit", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          getPostAnesthesiaCareUnitChartTable();
          Notify.successRequest("chart updated");
          setEditOrder({ data: null, dialog: false });
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  // const checkSelectedDoctor = (selDoctor) => {
  //   if (doctorsList.length > 0) {
  //     let xx = doctorsList.filter((x) => x.doctors_id === selDoctor);
  //     if (xx.length > 0) {
  //       return xx[0].name;
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  const handleAddNote = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("ccpac_id", ccpac_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);
    formdata.set("trace_number", details.trace_number);

    setIsProcessCancel(true);
    axios
      .post("or/create/chart/postanesthesiacareunittable", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setAddToTable(false);
          getPostAnesthesiaCareUnitChartTable();
          Notify.successRequest("note created");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  useEffect(() => {
    getPostAnesthesiaCareUnitChart();
    getPostAnesthesiaCareUnitChartTable();
  }, [
    patient_id,
    getPostAnesthesiaCareUnitChart,
    getPostAnesthesiaCareUnitChartTable,
  ]);

  return (
    <Box>
      <Card ref={printableRef}>
        <Box m={2}>
          <Box>
            <FormHeader />
          </Box>
          <Box>
            <form onSubmit={handleSubmitNewPostAnesthesia}>
              <Box my={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          disabled={isProcess}
                          color="primary"
                          startIcon={<CheckSquare />}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            SET
                          </Button>
                        </Box>
                      )}

                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box align="center" mb={2}>
                <Box>
                  <Typography>
                    <b> POST ANESTHESIA CARE UNIT </b>
                  </Typography>
                </Box>
                <Box align="center" mb={2}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bolder" }}
                  >
                    Nurse Notes
                  </Typography>
                </Box>
              </Box>

              <Box mb={2}>
                <Table size="small" className="table-bordered">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Patient's Name:
                          </span>
                        </Typography>

                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="patient_name"
                              defaultValue={
                                info
                                  ? `${info.lastname}, ${info.firstname} ${
                                      info.middle === null ? "" : info.middle
                                    }`
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </>
                        )}

                        <Box>
                          <Typography>
                            {!enableEdit && (
                              <span className="text-capitalize">
                                {info
                                  ? `${info.lastname}, ${info.firstname} ${
                                      info.middle === null ? "" : info.middle
                                    }`
                                  : ""}
                              </span>
                            )}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Age/Sex:
                          </span>
                        </Typography>

                        <Box>
                          <Typography>
                            <span className="text-capitalize">
                              {info
                                ? `${
                                    info.birthday === null
                                      ? ""
                                      : Notify.calculateAge(info.birthday)
                                  } / ${info.gender}`
                                : ""}
                            </span>
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Attending Physician:
                          </span>
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              defaultValue={
                                existingPostAnesthesiaChart.data.length > 0
                                  ? existingPostAnesthesiaChart.data[0]
                                      .attending_physician
                                  : ""
                              }
                              // onChange={(e) => setChartOpDoctor(e.target.value)}
                              fullWidth
                              name="attending_physician"
                            />

                            {/* <TextField
                              fullWidth
                              name="attending_physician"
                              InputProps={{
                                disableUnderline: !enableEdit,
                                style: {
                                  textTransform: "capitalize",
                                },
                              }}
                              select
                              SelectProps={{ native: true }}
                              InputLabelProps={{ shrink: true }}
                              readOnly={!enableEdit}
                              value={chartOpDoctor}
                              onChange={(e) => setChartOpDoctor(e.target.value)}
                            >
                              <option value=""> Select </option>
                              {doctorsList.length > 0 ? (
                                doctorsList.map((data, key) => (
                                  <option key={key} value={data.doctors_id}>
                                    {data.name}
                                  </option>
                                ))
                              ) : (
                                <option value=""> No doctor found. </option>
                              )}
                            </TextField> */}
                          </>
                        )}
                        <Box>
                          <Typography className="text-capitalize">
                            {!enableEdit
                              ? existingPostAnesthesiaChart.data.length > 0
                                ? // checkSelectedDoctor(
                                  //     existingPostAnesthesiaChart.data[0]
                                  //       .attending_physician
                                  //   )
                                  existingPostAnesthesiaChart.data[0]
                                    .attending_physician
                                : ""
                              : null}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box align="center" mb={2}>
                <Box display={"flex"} hidden={!enableEdit}>
                  <Box flexGrow={1} />
                  <Box mx={1}>
                    <Button
                      color="primary"
                      onClick={addField}
                      startIcon={<AddCircleOutline />}
                    >
                      ADD
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      color="secondary"
                      onClick={
                        _inputNewPostAnesthesia.data.length > 0
                          ? () =>
                              removeField(
                                1,
                                _inputNewPostAnesthesia.data.length
                              )
                          : (e) => e.preventDefault()
                      }
                      startIcon={<Cancel />}
                    >
                      REMOVE
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box mb={2}>
                <Box
                  display={"flex"}
                  className="d-print-none"
                  hidden={enableEdit}
                >
                  <Box flexGrow={1} />
                  <Box>
                    <IconButton
                      color={"primary"}
                      onClick={() => setAddToTable(true)}
                    >
                      <Add fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>

                <TableContainer>
                  <Table size="small" className="table-bordered">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <strong>Date/Time</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>BP</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>TEMP</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>PR</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>RR</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>
                            O<sub>2</sub> Sat
                          </strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>{`Printed Name`}</strong>
                          <br />
                          <strong>{`and Signature`}</strong>
                          <br />
                          <strong>{`of Nurse`}</strong>
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                          <strong>IVF</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>{`Printed Name`}</strong>
                          <br />
                          <strong>{`and Signature`}</strong>
                          <br />
                          <strong>{`of Nurse`}</strong>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-print-none"
                          hidden={enableEdit}
                        >
                          <strong>ACTION</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell align="center">
                          <strong>{"Solution & Rate"}</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>{"Date & Time"}</strong>
                        </TableCell>
                        <TableCell />
                        <TableCell
                          className="d-print-none"
                          hidden={enableEdit}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {enableEdit &&
                        _inputNewPostAnesthesia.ready &&
                        _inputNewPostAnesthesia.data.map((data, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <TextField
                                  name={data.date_time}
                                  type="datetime-local"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  style={{ width: 80 }}
                                  required
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  name={data.bp}
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  multiline
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  name={data.temp}
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  multiline
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  name={data.pr}
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  multiline
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  name={data.rr}
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  multiline
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  name={data.o2_sat}
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  multiline
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  name={data.remarks}
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  multiline
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  name={data.ivf_solution}
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  multiline
                                />
                              </TableCell>
                              <TableCell>
                                <Box>
                                  <TextField
                                    name={data.ivf_date_time}
                                    type="datetime-local"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    InputProps={{
                                      disableUnderline: !enableEdit,
                                      disabled: !enableEdit,
                                    }}
                                    style={{ width: 80 }}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  name={data.ivf_remarks}
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  multiline
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {!enableEdit &&
                        existingPostAnesthesiaChartTable.ready &&
                        existingPostAnesthesiaChartTable.data.length > 0 &&
                        existingPostAnesthesiaChartTable.data.map(
                          (data, items) => (
                            <TableRow key={items}>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {`${Notify.createdAt(
                                    data.date_time
                                  )} ${Notify.createdTime(data.date_time)}`}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {data.bp}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {data.temp}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {data.pr}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {data.rr}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {data.o2_sat}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    whiteSpace: "pre-line",
                                    inlineSize: "100px",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {data.remarks}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {data.ivf_solution}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {`${Notify.createdAt(
                                    data.ivf_date_time
                                  )} ${Notify.createdTime(data.ivf_date_time)}`}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    whiteSpace: "pre-line",
                                    inlineSize: "100px",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {data.ivf_remarks}
                                </Typography>
                              </TableCell>
                              <TableCell className="d-print-none">
                                <Box display={"flex"} justifyContent={"center"}>
                                  <Box>
                                    <IconButton
                                      color={"primary"}
                                      onClick={() =>
                                        setEditOrder({
                                          data: data,
                                          dialog: true,
                                        })
                                      }
                                    >
                                      <BorderColor fontSize={"small"} />
                                    </IconButton>
                                  </Box>
                                  <Box>
                                    <IconButton
                                      color={"secondary"}
                                      onClick={() =>
                                        setCancelOrder({
                                          data: data,
                                          dialog: true,
                                        })
                                      }
                                    >
                                      <Delete fontSize={"small"} />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box mt={12}>
                  <Box display={"flex"}>
                    <Box align="center">
                      <Box borderBottom={1}>
                        <Typography variant="subtitle2">
                          {!enableEdit
                            ? existingPostAnesthesiaChart.data.length > 0
                              ? existingPostAnesthesiaChart.data[0]
                                  .signature_pacu_nurse
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <Box style={{ width: "calc(40vw - 200px)" }}>
                              <TextField
                                fullWidth
                                name="signature_pacu_nurse"
                                defaultValue={
                                  existingPostAnesthesiaChart.data.length > 0
                                    ? existingPostAnesthesiaChart.data[0]
                                        .signature_pacu_nurse
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                      <Box> Signature Over Printed Name of PACU Nurse </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Card>

      <Dialog
        open={cancelOrder.dialog}
        onClose={() => setCancelOrder({ data: null, dialog: false })}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
      >
        {cancelOrder.data && cancelOrder.dialog && (
          <form onSubmit={handlePostAnesthesiaCareUnit}>
            <DialogTitle>Are you sure to remove this data?</DialogTitle>
            <TextField
              label={"ANESTHESIA ID"}
              variant="outlined"
              name="ccpact_id"
              defaultValue={cancelOrder.data.ccpact_id}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              hidden
            />
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setCancelOrder({ data: null, dialog: false })}
              >
                No
              </Button>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isprocessCancel}
                startIcon={
                  isprocessCancel && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      <Dialog
        open={editOrder.dialog}
        onClose={() => setEditOrder({ data: null, dialog: false })}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
      >
        {editOrder.data && editOrder.dialog && (
          <form onSubmit={handleEditPostAnesthesiaCareUnit}>
            <DialogTitle>Edit note</DialogTitle>
            <DialogContent>
              <Box mb={2}>
                <TextField
                  label={"ANESTHESIA ID"}
                  variant="outlined"
                  name="ccpact_id"
                  defaultValue={editOrder.data.ccpact_id}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  hidden
                />

                <TextField
                  label={"Date/Time"}
                  name="date_time"
                  defaultValue={
                    editOrder.data.date_time !== null
                      ? editOrder.data.date_time.replace(" ", "T")
                      : ""
                  }
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <TextField
                      label={"BP"}
                      variant="outlined"
                      name="bp"
                      defaultValue={
                        editOrder.data.bp !== null ? editOrder.data.bp : ""
                      }
                      fullWidth
                      multiline
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      label={"PR"}
                      variant="outlined"
                      name="pr"
                      defaultValue={
                        editOrder.data.pr !== null ? editOrder.data.pr : ""
                      }
                      fullWidth
                      multiline
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      label={"O2 Sat"}
                      variant="outlined"
                      name="o2_sat"
                      defaultValue={
                        editOrder.data.o2_sat !== null
                          ? editOrder.data.o2_sat
                          : ""
                      }
                      fullWidth
                      multiline
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <TextField
                      label={"Temp"}
                      variant="outlined"
                      name="temp"
                      defaultValue={
                        editOrder.data.temp !== null ? editOrder.data.temp : ""
                      }
                      fullWidth
                      multiline
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      label={"RR"}
                      variant="outlined"
                      name="rr"
                      defaultValue={
                        editOrder.data.rr !== null ? editOrder.data.rr : ""
                      }
                      fullWidth
                      multiline
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      label={"Printed Name of Nurse"}
                      variant="outlined"
                      name="remarks"
                      defaultValue={
                        editOrder.data.remarks !== null
                          ? editOrder.data.remarks
                          : ""
                      }
                      fullWidth
                      multiline
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box mb={2}>
                <TextField
                  label={"IVF Solution"}
                  variant="outlined"
                  name="ivf_solution"
                  defaultValue={
                    editOrder.data.ivf_solution !== null
                      ? editOrder.data.ivf_solution
                      : ""
                  }
                  fullWidth
                  multiline
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label={"IVF Date/Time"}
                  name="ivf_date_time"
                  defaultValue={
                    editOrder.data.ivf_date_time !== null
                      ? editOrder.data.ivf_date_time.replace(" ", "T")
                      : ""
                  }
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Box>

              <Box mb={2}>
                <TextField
                  label={"Printed Name of Nurse"}
                  variant="outlined"
                  name="ivf_remarks"
                  defaultValue={
                    editOrder.data.ivf_remarks !== null
                      ? editOrder.data.ivf_remarks
                      : ""
                  }
                  fullWidth
                  multiline
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setEditOrder({ data: null, dialog: false })}
              >
                No
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isprocessCancel}
                startIcon={
                  isprocessCancel && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                Yes
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      <Dialog
        open={addToTable}
        onClose={() => setAddToTable(false)}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
      >
        <form onSubmit={handleAddNote}>
          <DialogTitle>Add note</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <TextField
                label={"Date/Time"}
                name="date_time"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                variant="outlined"
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box mb={2}>
                  <TextField
                    label={"BP"}
                    variant="outlined"
                    name="bp"
                    fullWidth
                    multiline
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label={"PR"}
                    variant="outlined"
                    name="pr"
                    fullWidth
                    multiline
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label={"O2 Sat"}
                    variant="outlined"
                    name="o2_sat"
                    fullWidth
                    multiline
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mb={2}>
                  <TextField
                    label={"Temp"}
                    variant="outlined"
                    name="temp"
                    fullWidth
                    multiline
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label={"RR"}
                    variant="outlined"
                    name="rr"
                    fullWidth
                    multiline
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label={"Printed Name of Nurse"}
                    variant="outlined"
                    name="remarks"
                    fullWidth
                    multiline
                  />
                </Box>
              </Grid>
            </Grid>

            <Box mb={2}>
              <TextField
                label={"IVF Solution"}
                variant="outlined"
                name="ivf_solution"
                fullWidth
                multiline
              />
            </Box>

            <Box mb={2}>
              <TextField
                label={"IVF Date/Time"}
                name="ivf_date_time"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                variant="outlined"
              />
            </Box>

            <Box mb={2}>
              <TextField
                label={"Printed Name of Nurse"}
                variant="outlined"
                name="ivf_remarks"
                fullWidth
                multiline
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setAddToTable(false)}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isprocessCancel}
              startIcon={
                isprocessCancel && (
                  <CircularProgress size={20} color="inherit" />
                )
              }
            >
              Yes
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default FormFourteen;
