import {
	Box,
	Grid,
	Button,
	Backdrop,
	CircularProgress,
	makeStyles,
	Typography,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import { XCircle } from "react-feather";
// import { Print } from "@material-ui/icons";
import AudiometryOrder from "./AudiometryOrder";
import IshiharaOrder from "./IshiharaOrder";
import NeurologyOrder from "./NeurologyOrder";

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

const PrintOrder = () => {
	const classes = useStyles();
	const { users } = React.useContext(UsersData);
	const { order_id } = useParams();
	const [print, setPrint] = useState("");
	const [printProcess, setPrintProcess] = useState(false);
	const history = useHistory();

	const [audiometryOrderDetails, setAudiometryOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const [ishiharaOrderDetails, setIshiharaOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const [neurologyOrderDetails, setNeurologyOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const [formHeader, setFormHeader] = useState({
		data: [],
		ready: false,
	});

	const getAudiometryOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order_id };
		Axios.get("psychology/order/ordernew-audiology/complete/details-print", {
			params,
		})
			.then((response) => {
				const data = response.data;
				setAudiometryOrderDetails({ data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getIshiharaOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order_id };
		Axios.get("psychology/order/ordernew-ishihara/complete/details-print", {
			params,
		})
			.then((response) => {
				const data = response.data;
				setIshiharaOrderDetails({ data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getNeurologyOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order_id };
		Axios.get("psychology/order/ordernew-neurology/complete/details-print", {
			params,
		})
			.then((response) => {
				const data = response.data;
				setNeurologyOrderDetails({ data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getLabFormHeader = () => {
		var params = { management_id: users.management_id };
		Axios.get("laboratory/order/formheader-details", { params })
			.then((response) => {
				const data = response.data;
				setFormHeader(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	useEffect(() => {
		getLabFormHeader();
		getAudiometryOrderDetails();
		getIshiharaOrderDetails();
		getNeurologyOrderDetails();
		// eslint-disable-next-line
	}, []);

	const handlePrint = (e) => {
		setPrint(e.currentTarget.id);
		setPrintProcess(true);
		setTimeout(() => {
			setPrintProcess(false);
			window.print();
		}, 4000);
	};

	return (
		<Fragment>
			<Dialog
				fullScreen
				disableBackdropClick
				disableEscapeKeyDown
				open={true}
				onClose={() => console.log("dialog unclosable")}
			>
				<Box display="flex" className="gtc-hideonprint">
					<Box flexGrow={1}>
						<DialogTitle> Print Result Report </DialogTitle>
					</Box>
					<Box>
						<IconButton
							onClick={() => history.push("/sph/app/psychology/report")}
							color="secondary"
						>
							<XCircle />
						</IconButton>
					</Box>
				</Box>

				<DialogContent>
					<Box m={2}>
						<Backdrop open={printProcess} className={classes.backdrop}>
							<Box alignItems="center" align="center">
								<CircularProgress color="inherit" />
								<Typography>please wait...</Typography>
							</Box>
						</Backdrop>

						<Grid container spacing={2}>
							<Grid className="gtc-hideonprint" item xs={12} sm={3} lg={2}>
								<Box hidden={audiometryOrderDetails.data.length === 0}>
									<Button
										variant="outlined"
										id="audio"
										size={"large"}
										color="primary"
										onClick={handlePrint}
										fullWidth
										component={Box}
										my={1}
									>
										Print Audiometric Report
									</Button>

									{/* <Button
                    hidden={audiometryOrderDetails.data.length === 0}
                    onClick={handlePrint}
                    id="audio"
                    size={"large"}
                    color="primary"
                    startIcon={<Print />}
                    fullWidth
                    component={Box}
                    my={1}
                  >
                    Print Audiometric Report
                  </Button> */}
								</Box>

								<Box hidden={ishiharaOrderDetails.data.length === 0}>
									<Button
										variant="outlined"
										id="ishihara"
										size={"large"}
										color="primary"
										onClick={handlePrint}
										fullWidth
										component={Box}
										my={1}
									>
										Print Ishihara Test
									</Button>

									{/* <Button
                    hidden={ishiharaOrderDetails.data.length === 0}
                    onClick={handlePrint}
                    id="ishihara"
                    size={"large"}
                    color="primary"
                    startIcon={<Print />}
                    fullWidth
                    component={Box}
                    my={1}
                  >
                    Print Ishihara Test
                  </Button> */}
								</Box>

								<Box hidden={neurologyOrderDetails.data.length === 0}>
									<Button
										variant="outlined"
										id="neuro-exam"
										size={"large"}
										color="primary"
										onClick={handlePrint}
										fullWidth
										component={Box}
										my={1}
									>
										Print Neurology Exam
									</Button>

									{/* <Button
                    hidden={neurologyOrderDetails.data.length === 0}
                    onClick={handlePrint}
                    id="neuro-exam"
                    size={"large"}
                    color="primary"
                    startIcon={<Print />}
                    fullWidth
                    component={Box}
                    my={1}
                  >
                    Print Neurology Examination
                  </Button> */}
								</Box>
							</Grid>

							<Grid item xs={12} sm={9} lg={10}>
								{Object.keys(formHeader).length > 0 ? (
									<>
										<Box
											mb={2}
											className={
												print === "audio"
													? "gtc-showonprint"
													: "gtc-hideonprint"
											}
										>
											{audiometryOrderDetails.ready &&
												audiometryOrderDetails.data.length > 0 && (
													<AudiometryOrder
														formheader={formHeader}
														audiometryOrderDetails={audiometryOrderDetails}
													/>
												)}
										</Box>

										<Box
											mb={2}
											className={
												print === "ishihara"
													? "gtc-showonprint"
													: "gtc-hideonprint"
											}
										>
											{ishiharaOrderDetails.ready &&
												ishiharaOrderDetails.data.length > 0 && (
													<IshiharaOrder
														formheader={formHeader}
														ishiharaOrderDetails={ishiharaOrderDetails}
													/>
												)}
										</Box>

										<Box
											mb={2}
											className={
												print === "neuro-exam"
													? "gtc-showonprint"
													: "gtc-hideonprint"
											}
										>
											{neurologyOrderDetails.ready &&
												neurologyOrderDetails.data.length > 0 && (
													<NeurologyOrder
														formheader={formHeader}
														neurologyOrderDetails={neurologyOrderDetails}
													/>
												)}
										</Box>
									</>
								) : (
									"Psychology information not set in database."
								)}
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
			</Dialog>
		</Fragment>
	);
};

export default PrintOrder;
