import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

class OralGlucose {
  baseline = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          4.2 - 6 - 4 mmol/L
        </Typography>
      </Box>
    </Box>
  );

  firstHour = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          7.7 - 10.6 mmol/L
        </Typography>
      </Box>
    </Box>
  );

  secondHour = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          7.2 - 9.2 mmol/L
        </Typography>
      </Box>
    </Box>
  );
}

const RefOralGlucose = new OralGlucose();
export default RefOralGlucose;
