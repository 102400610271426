import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAddressCard,
	faHome,
	faPersonBooth,
	faUsers,
	faBars,
	faUserLock,
	faMapMarked,
	faChartBar,
	faListUl,
	faUserInjured,
} from "@fortawesome/free-solid-svg-icons";
import PhoDashboard from "src/pho/PhoDashboard";
import PhoPatientList from "src/pho/PhoPatientList";
import PhoPatients from "src/pho/PhoPatients";
import PhoHouseholdMemberFormPage from "src/pho/PhoHouseholdMemberPage";

const RoutePhoFp = () => {
	const [sidebarHeader, setSidebarHeader] = useState([]);
	const userContext = useContext(UsersData);
	// console.log("userContextuserContext", userContext);
	const { users } = userContext;
	const getSidebarHeaderInfo = async () => {
		var params = { user_id: userContext.users.user_id };
		const { data } = await axios.get(
			"admitting/sidebar/header-infomartion",
			{
				params,
			}
		);
		setSidebarHeader(data);
	};

	const sidebarRoute = [
		{
			name: "Dashboard",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faHome}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/dashboard",
			subitem: [],
		},

		{
			name: "Patients",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserInjured}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/patients",
			subitem: [],
		},
	];

	const handleRenderInfo = () => {
		getSidebarHeaderInfo();
	};

	useEffect(() => {
		getSidebarHeaderInfo();
		//eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<UsersHeader.Provider
				value={{
					sidebarHeader: sidebarHeader,
					renderPharmacyInfo: handleRenderInfo,
				}}
			>
				<BrowserRouter>
					<Sidebar
						notification={{
							enable: true,
							owner: "pho",
						}}
						header={{ ...sidebarHeader, name: "PHO FP" }}
						routes={sidebarRoute}
						module={"PHO FP"}
					/>
					<Switch>
						<Route
							exact
							path="/sph/app"
							component={() => (
								<Redirect to="/sph/app/pho/dashboard" />
							)}
						/>
						<Route
							exact
							path="/sph/app/pho/dashboard"
							component={PhoDashboard}
						/>
						<Route
							exact
							path="/sph/app/pho/patients"
							component={PhoPatients}
						/>

						<Route
							exact
							path="/sph/app/pho/patients/create"
							component={PhoHouseholdMemberFormPage}
						/>
						<Route
							exact
							path="/sph/app/pho/patients/:id"
							component={PhoHouseholdMemberFormPage}
						/>
						<Route
							exact
							path="/sph/app/logout"
							component={Logout}
						/>

						<Route
							render={() => (
								<PageNotFound title="Page not found" />
							)}
						/>
					</Switch>
				</BrowserRouter>
			</UsersHeader.Provider>
		</Fragment>
	);
};

export default RoutePhoFp;
