import {
	Box,
	Grid,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	CardHeader,
	Typography,
	Card,
	CardContent,
} from "@material-ui/core";
import React, { useEffect, useState, Fragment } from "react";
import PersonIcon from "@material-ui/icons/Person";
import VirtualOrderReport from "./VirtualOrderReport";
import Notify from "src/notification/Notify";
import axios from "axios";
import ImagingOrderVirtualDetails from "./ImagingOrderVirtualDetails";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";

var interval = null;
const ImagingOrderVirtual = () => {
	const { users } = React.useContext(UsersData);
	const [order, setOrder] = useState({ data: [], ready: false });
	const [selectedPatient, setSelectedPatient] = useState({
		data: null,
		ready: false,
	});

	const getImagingVirtualOrder = () => {
		var params = { user_id: users.user_id };
		axios
			.get("imaging/virtual/get/patient/forimaging", { params })
			.then((response) => {
				const data = response.data;
				setOrder({ data: data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const resetDisplay = () => {
		setSelectedPatient({ data: null, ready: false });
	};

	useEffect(() => {
		getImagingVirtualOrder();

		interval = setInterval(() => {
			getImagingVirtualOrder();
		}, 7000);

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: true,
					current: "virtual",
					items: [{ name: "Dashboard", path: "/sph/app" }],
				}}
				title="Virtual"
			>
				<Grid container spacing={2}>
					<Grid item xs={12} md={3} className={`d-print-none`}>
						<Card variant="outlined">
							<CardHeader
								component={Box}
								disableTypography
								title={
									<Typography variant="h6" color="textSecondary">
										Patients With Order
									</Typography>
								}
							/>

							<CardContent>
								<List component={Box}>
									{order.ready
										? order.data.length > 0
											? order.data.map((data, index) => (
													<ListItem
														button
														key={index}
														onClick={() =>
															setSelectedPatient({ data: data, ready: true })
														}
														selected={
															selectedPatient.data &&
															selectedPatient.data.imaging_center_id ===
																data.imaging_center_id
														}
													>
														<ListItemIcon>
															<PersonIcon
																color={
																	selectedPatient.data &&
																	selectedPatient.data.imaging_center_id ===
																		data.imaging_center_id
																		? "secondary"
																		: "inherit"
																}
															/>
														</ListItemIcon>
														<ListItemText
															className={`text-capitalize`}
															primaryTypographyProps={{
																color:
																	selectedPatient.data &&
																	selectedPatient.data.imaging_center_id ===
																		data.imaging_center_id
																		? "secondary"
																		: "inherit",
															}}
															primary={data.name}
														/>
													</ListItem>
											  ))
											: Notify.noRecord()
										: Notify.loading()}
								</List>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} md={9}>
						{!selectedPatient.ready && selectedPatient.data === null && (
							<VirtualOrderReport />
						)}

						{selectedPatient.ready && selectedPatient.data && (
							<ImagingOrderVirtualDetails
								doctorID={selectedPatient.data.doctors_id}
								patientId={selectedPatient.data.patients_id}
								imagingOrderID={selectedPatient.data.imaging_center_id}
								resetDisplay={resetDisplay}
								getImagingVirtualOrder={getImagingVirtualOrder}
							/>
						)}
					</Grid>
				</Grid>
			</Container>
		</Fragment>
	);
};

export default ImagingOrderVirtual;
