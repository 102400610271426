import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { dataURItoBlob, getErrors } from "src/helpers/utils";
import TextInputField from "src/components/forms/TextInputField";
const OperatingRoomFormModal = (props, ref) => {
	const [mapKey, setMapKey] = useState(`map-${uuidv4()}`);
	const { staticModal, updateInList, addToList } = props;
	const [open, setOpen] = useState(false);
	const [data, setData] = useState({
		id: 1,
	});
	const [loading, setLoading] = useState(false);

	const btnRef = useRef(null);

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		control,
		setError,
		formState: { errors },
	} = useForm();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (showData) => {
		console.log("showDatashowData", showData);
		if (showData?.id) {
			setData(showData);
			setValue("name", showData?.name);
			setValue("type", showData?.type);
			setValue("capacity", showData?.capacity);
		} else {
			setData(null);
			reset({
				name: "",
				color: "",
				type: "",
				radius: "",
			});
		}
		setTimeout(() => {
			setMapKey(`map-${uuidv4()}`);
		}, 500);
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = (form) => {
		setLoading(true);
		let formData = new FormData();
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
			// onUploadProgress: progressEvent => onProgress(progressEvent),
		};
		formData.append("name", form?.name);
		formData.append("type", form?.type);
		formData.append("capacity", form?.capacity);

		let url = `/v1/operating-rooms/store`;

		if (data?.id) {
			formData.append("_method", "PATCH");
			url = `/v1/operating-rooms/update` + `/${data?.id}`;
		}

		axios
			.post(url, formData, config)
			.then((res) => {
				setTimeout(() => {
					toast.success(
						`Operating Room ${
							data?.id ? "updated" : "created"
						} successfully!`
					);
					if (data?.id) {
						updateInList(res.data.data);
					} else {
						addToList(res.data.data);
					}
				}, 200);

				hide();
			})
			.catch((err) => {
				console.log("errerr", err?.response?.data?.errors);
				if (err?.response?.data?.errors)
					getErrors(err?.response?.data?.errors, setError);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<Modal
			open={open}
			hide={hide}
			className={`w-[448px] min-w-[448px] max-w-[448px]`}
			staticModal={staticModal}
		>
			<ModalHeader
				title={(data?.id ? "Edit " : "Add ") + `Operating Room Form`}
				hide={hide}
			/>
			<ModalBody className={`p- bg-white !py-6`}>
				<div className="flex flex-col gap-y-4 patient-form px-6">
					<TextInputField
						label={<>Room Name</>}
						placeholder="Enter Room Name"
						className=""
						variant="outlined"
						{...register("name", {
							required: "This field is required",
						})}
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						error={errors?.name?.message}
					/>
					<TextInputField
						label={<>Room Type</>}
						placeholder="Enter Room Type"
						className=""
						variant="outlined"
						{...register("type", {
							required: "This field is required",
						})}
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						error={errors?.type?.message}
					/>
					<TextInputField
						label={<>Room Capacity</>}
						className=""
						placeholder="Enter Room Capacity"
						variant="outlined"
						{...register("capacity", {
							required: "This field is required",
						})}
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						error={errors?.capacity?.message}
					/>
				</div>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button
					onClick={handleSubmit(submit)}
					loading={loading}
					ref={btnRef}
				>
					Submit
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(OperatingRoomFormModal);
