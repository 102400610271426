import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";

import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import SelectInputField from "src/components/forms/SelectInputField";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import TextAreaField from "src/components/forms/TextAreaField";
import { dataURItoBlob } from "src/helpers/utils";
import { UsersData } from "src/ContextAPI";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";

// const modalKey = uuidv4();
const symptoms2 = [
	{
		label: "Chest pain/discomfort/heaviness",
		value: "Chest pain/discomfort/heaviness",
		name: "chest_pain_discomfort_heaviness",
	},
	{
		label: "Acute fracture/dislocation/injuries",
		value: "Acute fracture/dislocation/injuries",
		name: "acute_fracture_dislocation_injuries",
	},
	{
		label: "Difficulty breathing",
		value: "Difficulty breathing",
		name: "difficulty_breathing",
	},
	{
		label: "Signs of abuse (i.e. multiple bruises/injuries)",
		value: "Signs of abuse (i.e. multiple bruises/injuries)",
		name: "signs_of_abuse",
	},
	{
		label: "Seizure/convulsion",
		value: "Seizure/convulsion",
		name: "deizure_convulsion",
	},
	{
		label: "Severe abdominal pain",
		value: "Severe abdominal pain",
		name: "severe_abdominal_pain",
	},
	{
		label: "Unconscious/restless/lethargic",
		value: "Unconscious/restless/lethargic",
		name: "unconscious_restless_lethargic",
	},
	{
		label: "Persistent vomiting",
		value: "Persistent vomiting",
		name: "persistent_vomiting",
	},
	{
		label: "Not oriented to time, person/place",
		value: "Not oriented to time, person/place",
		name: "not_oriented_to_time_person_place",
	},
	{
		label: "Persistent diarrhea (>14 days)",
		value: "Persistent diarrhea (>14 days)",
		name: "persistent_diarrhea",
	},
	{
		label: "Bluish discoloration of skin/lips",
		value: "Bluish discoloration of skin/lips",
		name: "bluish_discoloration_of_skin_lips",
	},
	{
		label: "Unable to tolerate fluids",
		value: "Unable to tolerate fluids",
		name: "unable_to_tolerate_fluids",
	},
	{
		label: "Act of self-harm/suicide",
		value: "Act of self-harm/suicide",
		name: "act_of_self_harm_suicide",
	},
];
const symptoms = [
	{
		value: "cough_for_3_weeks_or_longer",
		label: "Cough for three weeks or longer",
	},
	{
		value: "coughing_up_blood_or_mucus",
		label: "Coughing up blood or mucus",
	},
	{ value: "chest_pain", label: "Chest pain" },
	{
		value: "pain_with_breathing_or_coughing",
		label: "Pain with breathing or coughing",
	},
	{ value: "fever", label: "Fever" },
	{ value: "chills", label: "Chills" },
	{ value: "night_sweats", label: "Night sweats" },
	{ value: "weight_loss", label: "Weight loss" },
	{ value: "not_wanting_to_eat", label: "Not wanting to eat" },
	{ value: "tiredness", label: "Tiredness" },
	{
		value: "not_feeling_well_in_general",
		label: "Not feeling well in general",
	},
];

const generalHistories = [
	{
		label: "Hypertension",
		value: "Hypertension",
		name: "hypertension",
	},
	{
		label: "Stroke",
		value: "Stroke",
		name: "stroke",
	},
	{
		label: "Heart disease",
		value: "Heart disease",
		name: "heart_disease",
	},
	{
		label: "High cholesterol",
		value: "High cholesterol",
		name: "high_cholesterol",
	},
	{
		label: "Bleeding disorders",
		value: "Bleeding disorders",
		name: "bleeding_disorders",
	},
	{
		label: "Diabetes",
		value: "Diabetes",
		name: "diabetes",
	},
	{
		label: "Kidney disease",
		value: "Kidney disease",
		name: "kidney_disease",
	},
	{
		label: "Liver disease",
		value: "Liver disease",
		name: "liver_disease",
	},
	{
		label: "COPD",
		value: "COPD",
		name: "copd",
	},
	{
		label: "Asthma",
		value: "Asthma",
		name: "asthma",
	},
	{
		label: "Mental, Neurological and substance abuse",
		value: "Mental, Neurological and substance abuse",
		specify: "Please specify",
		name: "mental_neurological_substance_abuse",
	},
	{
		label: "Cancer",
		value: "Cancer",
		specify: "Please specify",
		name: "cancer",
	},
	{
		label: "Others",
		value: "Others",
		specify: "Please specify",
		name: "others",
	},
];
const medicalSurgicalHistories = [
	{
		label: "Asthma",
		name: "asthma_history",
	},
	{
		label: "Allergies",
		name: "allergies",
	},
	{
		label: "Allergies to medicine",
		name: "allergies_to_medicine",
	},
	{
		label: "Immunization",
		name: "immunization",
	},
	{
		label: "Injuries/accidents",
		name: "injuries_accidents",
	},
	{
		label: "Hearing problems",
		name: "hearing_problems",
	},
	{
		label: "Vision problems",
		name: "vision_problems",
	},
	{
		label: "Heart disease",
		name: "heart_disease_history",
	},
	{
		label: "Mental, neurological, learning. or substance use conditions (i.e. diagnosed learning disability, etc.)",
		name: "neurological_substance_use_conditions",
	},
	{
		label: "Cancer",
		name: "cancer_history",
	},
	{
		label: "Other organ disorders (i.e. thyroid, kidney, hypertension,diabetes, TB, etc.)",
		name: "other_organ_disorders",
	},
	{
		label: "Previous hospitalizations",
		name: "previous_hospitalizations",
	},
	{
		label: "Previous surgeries",
		name: "previous_surgeries",
	},
	{
		label: "Others",
		name: "other_medical_surgical_history",
	},
];
const environmentalHistories = [
	{
		label: "None",
	},
	{
		label: "Point source (protected well/developed spring)",
	},
	{
		label: "Communal faucet/standpost",
	},
	{
		label: "Waterworks/individual house connection",
	},
];

const sanitaryOptions = [
	{
		label: "Open defecation",
	},
	{
		label: "Toilet w/o water",
	},
	{
		label: "Toilet w/ septic tank",
	},
	{
		label: "Toilet (in house)",
	},
	{
		label: "Toilet (out of house)",
	},
	{
		label: "Toilet (public)",
	},
];

const accessWasteOptions = [
	{
		label: "Waste segregation",
	},
	{
		label: "Composting",
	},
	{
		label: "Recycling/reuse",
	},
	{
		label: "City/municipal collection and disposal",
	},
	{
		label: "Burying",
	},
	{
		label: "Burning",
	},
];

const CreateAppointmentModal = (props, ref) => {
	const {
		selected,
		setSelected,
		referToRHURef,
		patient,
		patientSelfie,
		onSuccess,
		appointmentVitalsRef,
	} = props;
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		watch,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			cough_for_3_weeks_or_longer: "No",
			coughing_up_blood_or_mucus: "No",
			chest_pain: "No",
			pain_with_breathing_or_coughing: "No",
			fever: "No",
			chills: "No",
			night_sweats: "No",
			weight_loss: "No",
			not_wanting_to_eat: "No",
			tiredness: "No",
			not_feeling_well_in_general: "No",
		},
	});

	const { users } = useContext(UsersData);
	const [open, setOpen] = useState(false);
	const [noActiveSymptoms, setNoActiveSymptoms] = useState(false);
	const [appointment_id, setAppointmentId] = useState(0);
	const [loading, setLoading] = useState(false);
	const [prescriptionItems, setPrescriptionItems] = useState([]);
	const [modalData, setModalData] = useState(null);
	const [ctr, setCtr] = useState(0);
	const AddCtr = () => {
		setCtr((val) => val + 1);
	};
	const DeductCtr = () => {
		setCtr((val) => val - 1);
	};
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		getRHUData();
		// setAppointmentId(data);
		setModalData(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const [selectedRHU, setSelectedRHU] = useState(null);
	const [locationsData, setLocationsData] = useState([]);
	const getRHUData = () => {
		axios.get(`/v1/health-unit/list?type=RHU`).then((res) => {
			setLocationsData(res.data.data);
		});
	};
	const submit = (data) => {
		console.log("SUBMIT DATAAA", data);
		// return;
		const formData1 = new FormData();
		const formData = new FormData();
		formData1.append("notes", data?.notes);
		formData1.append("patient_id", patient?.id);
		formData1.append("disease", data?.disease);
		formData1.append("update_selfie", modalData?.update_selfie);
		formData1.append(
			"cough_for_3_weeks_or_longer",
			data?.cough_for_3_weeks_or_longer
		);
		formData1.append(
			"coughing_up_blood_or_mucus",
			data?.coughing_up_blood_or_mucus
		);
		formData1.append("chest_pain", data?.chest_pain);
		formData1.append(
			"pain_with_breathing_or_coughing",
			data?.pain_with_breathing_or_coughing
		);
		formData1.append("fever", data?.fever);
		formData1.append("chills", data?.chills);
		formData1.append("night_sweats", data?.night_sweats);
		formData1.append("weight_loss", data?.weight_loss);
		formData1.append("not_wanting_to_eat", data?.not_wanting_to_eat);
		formData1.append("tiredness", data?.tiredness);
		formData1.append(
			"not_feeling_well_in_general",
			data?.not_feeling_well_in_general
		);
		formData1.append(
			"chest_pain_discomfort_heaviness",
			data?.chest_pain_discomfort_heaviness
		);
		formData1.append("difficulty_breathing", data?.difficulty_breathing);
		formData1.append("seizure_convulsion", data?.seizure_convulsion);
		formData1.append(
			"unconscious_restless_lethargic",
			data?.unconscious_restless_lethargic
		);
		formData1.append(
			"not_oriented_to_time_person_place",
			data?.not_oriented_to_time_person_place
		);
		formData1.append(
			"bluish_discoloration_of_skin_lips",
			data?.bluish_discoloration_of_skin_lips
		);
		formData1.append(
			"act_of_self_harm_suicide",
			data?.act_of_self_harm_suicide
		);
		formData1.append(
			"acute_fracture_dislocation_injuries",
			data?.acute_fracture_dislocation_injuries
		);
		formData1.append("signs_of_abuse", data?.signs_of_abuse);
		formData1.append("severe_abdominal_pain", data?.severe_abdominal_pain);
		formData1.append("persistent_vomiting", data?.persistent_vomiting);
		formData1.append("persistent_diarrhea", data?.persistent_diarrhea);
		formData1.append(
			"unable_to_tolerate_fluids",
			data?.unable_to_tolerate_fluids
		);
		formData1.append("notes", data?.notes);
		formData1.append("history", data?.history);
		formData1.append("hypertension", data?.hypertension);
		formData1.append("stroke", data?.stroke);
		formData1.append("heart_disease", data?.heart_disease);
		formData1.append("high_cholesterol", data?.high_cholesterol);
		formData1.append("bleeding_disorders", data?.bleeding_disorders);
		formData1.append("diabetes", data?.diabetes);
		formData1.append("kidney_disease", data?.kidney_disease);
		formData1.append("liver_disease", data?.liver_disease);
		formData1.append("copd", data?.copd);
		formData1.append("asthma", data?.asthma);
		formData1.append(
			"mental_neurological_substance_abuse",
			data?.mental_neurological_substance_abuse
		);
		formData1.append("cancer", data?.cancer);
		formData1.append("others", data?.others);
		formData1.append("asthma_history", data?.asthma_history);
		formData1.append("allergies", data?.allergies);
		formData1.append("allergies_to_medicine", data?.allergies_to_medicine);
		formData1.append("immunization", data?.immunization);
		formData1.append("injuries_accidents", data?.injuries_accidents);
		formData1.append("hearing_problems", data?.hearing_problems);
		formData1.append("vision_problems", data?.vision_problems);
		formData1.append("heart_disease_history", data?.heart_disease_history);
		formData1.append(
			"neurological_substance_use_conditions",
			data?.neurological_substance_use_conditions
		);
		formData1.append("cancer_history", data?.cancer_history);
		formData1.append("other_organ_disorders", data?.other_organ_disorders);
		formData1.append(
			"previous_hospitalizations",
			data?.previous_hospitalizations
		);
		formData1.append("previous_surgeries", data?.previous_surgeries);
		formData1.append(
			"other_medical_surgical_history",
			data?.other_medical_surgical_history
		);
		formData1.append("intake_high_sugar", data?.intake_high_sugar);
		formData1.append("take_supplements", data?.take_supplements);
		formData1.append("deworming_6months", data?.deworming_6months);
		formData1.append("flouride_toothpaste", data?.flouride_toothpaste);
		formData1.append("physical_activity", data?.physical_activity);
		formData1.append("daily_screen_time", data?.daily_screen_time);
		formData1.append("violence_injuries", data?.violence_injuries);
		formData1.append("bully_harassment", data?.bully_harassment);
		formData1.append("safe_water", data?.safe_water);
		formData1.append(
			"access_to_sanitary_toilet",
			data?.access_to_sanitary_toilet
		);
		formData1.append(
			"satisfactory_waste_disposal",
			data?.satisfactory_waste_disposal
		);
		formData1.append(
			"prolong_exposure_biomass_fuel",
			data?.prolong_exposure_biomass_fuel
		);
		formData1.append("exposure_tabacco_vape", data?.exposure_tabacco_vape);
		formData1.append(
			"exposure_tabacco_vape_details",
			data?.exposure_tabacco_vape_details
		);
		formData1.append("disease", data?.disease);
		formData1.append("mode_of_consultation", data?.mode_of_consultation);
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
			// onUploadProgress: progressEvent => onProgress(progressEvent),
		};
		if (patientSelfie) {
			const file = dataURItoBlob(patientSelfie);

			formData1.append("patient_selfie", file);
		}
		if (selected == "tuberculosis") {
			formData.append(
				"cough_for_3_weeks_or_longer",
				data?.cough_for_3_weeks_or_longer
			);
			formData.append(
				"coughing_up_blood_or_mucus",
				data?.coughing_up_blood_or_mucus
			);
			formData.append(
				"pain_with_breathing_or_coughing",
				data?.pain_with_breathing_or_coughing
			);

			formData.append("chest_pain", data?.chest_pain);
			formData.append("fever", data?.fever);
			formData.append("chills", data?.chills);
			formData.append("night_sweats", data?.night_sweats);
			formData.append("weight_loss", data?.weight_loss);
			formData.append("not_wanting_to_eat", data?.not_wanting_to_eat);
			formData.append(
				"not_feeling_well_in_general",
				data?.not_feeling_well_in_general
			);
			formData.append("tiredness", data?.tiredness);
			formData.append("_method", "PATCH");
		}
		axios
			.post(`/v1/clinic/appointments`, formData1, config)
			.then((res) => {
				if (selected == "tuberculosis") {
					axios.post(
						`/v1/clinic/tb-symptoms/${res.data.data?.id}`,
						formData
					);
				}
				setTimeout(() => {
					onSuccess();
					toast.success("New appointment successfully created!");
				}, 300);
				// setNewAppointment(false);
				hide();
			})
			.finally(() => {});

		console.log("submitdatadata", data);
	};
	const handleChange = () => {};
	return (
		<Modal
			staticModal={true}
			open={open}
			size="xl"
			hide={hide}
			className=" w-full"
		>
			<ModalHeader
				title={`Appointment form`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<b className="mb-4 text-base !font-semibold">
					<Clock
						format={"MMMM D, YYYY"}
						ticking={true}
						timezone={"ASIA/Manila"}
					/>
				</b>
				<h4 className="border-y-2 text-base font-bold p-2">
					Patient Symptoms
				</h4>
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-4 py-3 ">
					<div className="lg:col-span-8 grid grid-cols-1 lg:grid-cols-12 gap-4">
						<div className="lg:col-span-6 flex flex-col gap-y-">
							{symptoms2?.map((data, index) => {
								if (index % 2 == 0)
									return (
										<label className="mb-2 flex items-center text-base gap-2 text-gray-600 hover:bg-blue-100 duration-200 !text-black">
											<input
												type="checkbox" onChange={(e)=>{
													console.log('checkboxcheckboxcheckbox', e)
												}}
												{...register(data?.name, {})}
											/>
											<span>{data?.label}</span>
										</label>
									);
							})}
						</div>
						<div className="lg:col-span-6 flex flex-col gap-y-">
							{symptoms2?.map((data, index) => {
								if (index % 2 != 0)
									return (
										<label className="mb-2 flex items-center text-base gap-2 text-gray-600 hover:bg-blue-100 duration-200 !text-black">
											<input
												type="checkbox"
												{...register(data?.name, {})}
											/>
											<span>{data?.label}</span>
										</label>
									);
							})}
						</div>
						<div className="lg:col-span-8 flex flex-col gap-2">
							{noActiveSymptoms ? (
								<ActionBtn
									type="danger"
									size="sm"
									className="whitespace-nowrap mt-"
									onClick={() => {
										setNoActiveSymptoms(false);
									}}
								>
									Click if Patient{" "}
									<b className="font-weight-bold">
										HAS ACTIVE
									</b>{" "}
									symptoms!
								</ActionBtn>
							) : (
								<ActionBtn
									type="success"
									size="sm"
									className="whitespace-nowrap mt-"
									onClick={() => {
										setNoActiveSymptoms(true);
									}}
								>
									Click if Patient has <b>NO ACTIVE</b>{" "}
									symptoms!
								</ActionBtn>
							)}
						</div>
					</div>
					<div className="lg:col-span-4 flex flex-col relative">
						<span className="text-sm mb-2 font-bold">
							Patient Picture
						</span>
						<div className="relative w-full rounded-xl overflow-hidden mb-11">
							<div className="pt-[70%]">
								<img
									src={patientSelfie}
									className="w-full h-full object-contain  bg-black absolute top-0 left-0"
								/>
							</div>
						</div>
					</div>

					{noActiveSymptoms ? (
						""
					) : (
						<div className="col-span-12">
							<p className="text-red-600 text-lg italic font-semibold text-center">
								If any of these symptoms are present DURING the
								patient visit. Refer immediately to the nearest
								PCPN!.
							</p>
						</div>
					)}
					<div className="col-span-5 flex items-center gap-4">
						{noActiveSymptoms ? (
							""
						) : (
							<>
								<div className="flex items-center mb-3 w-full">
									<ReactSelectInputField
										isClearable={false}
										label={
											<>
												Refer to
												<span className="text-danger ml-1">
													*
												</span>
											</>
										}
										inputClassName=" "
										value={selectedRHU}
										{...register("refer_to_rhu", {
											required: false,
										})}
										onChange={(val) => {
											console.log(
												"onChangeonChange refer_to_rhu",
												val
											);
											setSelectedRHU(val);
										}} // send value to hook form
										// onBlur={onBlur} // notify when input is touched
										placeholder="Refer to"
										options={locationsData?.map((loc) => {
											return {
												value: loc?.id,
												label: loc?.name,
											};
										})}
									/>
								</div>
								<ActionBtn
									type="accent"
									className="whitespace-nowrap mt-2"
									size="sm"
									disabled={selectedRHU == null}
									onClick={() => {
										referToRHURef.current.show(
											null,
											selectedRHU
										);
									}}
								>
									Refer Now <FlatIcon icon="rr-arrow-right" />
								</ActionBtn>
							</>
						)}
					</div>
					<div className="col-span-3"></div>

					{noActiveSymptoms ? (
						<>
							<div className="col-span-8">
								<div className="flex flex-col mb-3">
									<label className="text-sm font-bold">
										Chief complaint
									</label>
									<TextAreaField
										error={errors?.notes?.message}
										className="rounded-xl"
										rows="2"
										placeholder="Enter reason for appointment..."
										{...register("notes", {
											required: "This field is required!",
										})}
									/>
								</div>
								<div className="flex items-center mb-3">
									<div className="w-full lg:w-1/2">
										<Controller
											name="disease"
											control={control}
											rules={{
												required: {
													value: true,
													message:
														"This field is required",
												},
											}}
											render={({
												field: {
													onChange,
													onBlur,
													value,
													name,
													ref,
												},
												fieldState: {
													invalid,
													isTouched,
													isDirty,
													error,
												},
											}) => (
												<ReactSelectInputField
													isClearable={false}
													label={
														<>
															Consultation type
															<span className="text-danger ml-1">
																*
															</span>
														</>
													}
													inputClassName=" "
													ref={ref}
													value={value}
													onChange={(val) => {
														console.log(
															"onChangeonChange",
															val
														);
														setSelected(
															String(
																val
															).toLowerCase()
														);
														if (onChange) {
															onChange(val);
														}
													}} // send value to hook form
													onBlur={onBlur} // notify when input is touched
													error={error?.message}
													placeholder="Consultation type"
													options={[
														{
															label: "General Malaria",
															value: "general malaria",
														},
														{
															label: "Diabetes",
															value: "diabetes",
														},
														{
															label: "Tuberculosis",
															value: "tuberculosis",
														},
														{
															label: "Hypertension",
															value: "hypertension",
														},
														{
															label: "Urinary Tract Infection",
															value: "urinary tract infection",
														},
														{
															label: "Respiratory Tract Infection",
															value: "respiratory tract infection",
														},
														{
															label: "Diarrhea",
															value: "diarrhea",
														},
														{
															label: "Wound, all forms Skin Diseases",
															value: "wound all forms skin diseases",
														},

														{
															label: "Animal Bite - RHU",
															value: "animal Bite - RHU",
														},
														{
															label: "Human Immunodeficiency Virus - SPH",
															value: "human immunodeficiency virus - SPH",
														},
														{
															label: "High Risk Pregnancy - SPH",
															value: "high risk pregnancy - SPH",
														},
														{
															label: "Cancer, all forms - SPH",
															value: "cancer all forms - SPH",
														},
														{
															label: "Dengue Hemorrhagic Fever - SPH",
															value: "dengue hemorrhagic fever - SPH",
														},
														{
															label: "Typhoid Fever - SPH",
															value: "typhoid fever - SPH",
														},
													]}
												/>
											)}
										/>
									</div>
								</div>
								{selected == "tuberculosis" ? (
									<div className="flex-col gap-3">
										<h5 className="font-bold text-sm mb-2">
											TB Symptoms
										</h5>
										{symptoms?.map((x) => {
											return (
												<FormControl className="w-full flex !flex-row hover:bg-green-50 duration-200">
													<FormLabel
														// error={errors?.gender}
														id="demo-controlled-radio-buttons-group"
														className=" !mb-0 !h-[28px] w-1/2 flex items-center"
													>
														{x.label}
													</FormLabel>

													<Controller
														rules={{
															required: true,
														}}
														name={x.value}
														control={control}
														render={({ field }) => (
															<RadioGroup
																{...field}
																row
																onChange={(
																	e
																) => {
																	// console.log(
																	// 	"ONCHANGES",

																	// );
																	if (
																		e.target
																			.value ==
																		"Yes"
																	) {
																		AddCtr();
																	} else {
																		DeductCtr();
																	}
																	setValue(
																		x.value,
																		e.target
																			.value
																	);
																	return e
																		.target
																		.value;
																}}
																className="!h-[22px] no-padding-radio-btn"
																key={`tb-symp-${field?.value}`}
															>
																<FormControlLabel
																	value={
																		"Yes"
																	}
																	control={
																		<Radio />
																	}
																	label={
																		"Yes"
																	}
																/>
																<FormControlLabel
																	value={"No"}
																	defaultChecked
																	control={
																		<Radio />
																	}
																	label={"No"}
																/>
															</RadioGroup>
														)}
													/>
												</FormControl>
											);
										})}
									</div>
								) : (
									""
								)}
							</div>
							<div className="col-span-4">
								<Controller
									name="mode_of_consultation"
									control={control}
									rules={{
										required: {
											value: true,
											message: "This field is required",
										},
									}}
									render={({
										field: {
											onChange,
											onBlur,
											value,
											name,
											ref,
										},
										fieldState: {
											invalid,
											isTouched,
											isDirty,
											error,
										},
									}) => (
										<ReactSelectInputField
											isClearable={false}
											label={
												<>
													Mode of consultation
													<span className="text-danger ml-1">
														*
													</span>
												</>
											}
											inputClassName=" "
											ref={ref}
											value={value}
											onChange={(val) => {
												console.log(
													"onChangeonChange",
													val
												);
												if (onChange) {
													onChange(val);
												}
											}} // send value to hook form
											onBlur={onBlur} // notify when input is touched
											error={error?.message}
											placeholder="Mode of consultation"
											options={[
												{
													label: "Walk-in (non-PHIC member)",
													value: "Walk-in (non-PHIC member)",
												},
												{
													label: "Walk-in (PHIC member)",
													value: "Walk-in (PHIC member)",
												},
											]}
										/>
									)}
								/>
							</div>
							<div className="col-span-12">
								<div className="flex flex-col mb-3">
									<label className="text-sm font-bold">
										History of present illness / Health
										problem
									</label>
									<TextAreaField
										error={errors?.history?.message}
										className="rounded-xl"
										rows="3"
										placeholder="Enter History of present illness / Health problem..."
										{...register("history", {
											required: "This field is required!",
										})}
									/>
								</div>
							</div>
							<div className="col-span-12">
								<h4 className="border-y-2 text-base font-bold p-2">
									General History
								</h4>

								<div className="grid grid-cols-1 lg:grid-cols-12 gap-x-4 w-full">
									<div className="flex flex-col lg:col-span-4">
										{generalHistories?.map(
											(data, index) => {
												if (index <= 4)
													return (
														<div className="flex flex-col">
															<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
																<input
																	type="checkbox"
																	{...register(
																		data?.name
																	)}
																/>
																<span>
																	{
																		data?.label
																	}
																</span>
															</label>
														</div>
													);
											}
										)}
									</div>
									<div className="flex flex-col lg:col-span-4">
										{generalHistories?.map(
											(data, index) => {
												if (index > 4 && index <= 9)
													return (
														<div className="flex flex-col">
															<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
																<input
																	type="checkbox"
																	{...register(
																		data?.name
																	)}
																/>
																<span className="">
																	{
																		data?.label
																	}
																</span>
															</label>
														</div>
													);
											}
										)}
									</div>
									<div className="flex flex-col lg:col-span-4">
										{generalHistories?.map(
											(data, index) => {
												if (index > 9)
													return (
														<div className="flex flex-col">
															<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
																<input
																	type="checkbox"
																	{...register(
																		data?.name
																	)}
																/>
																<span>
																	{
																		data?.label
																	}
																</span>
															</label>
															{data?.specify ? (
																<TextInputField
																	labelClassName="whitespace-nowrap"
																	className="flex items-center gap-4"
																	inputClassName="!p-2 !h-8"
																	label={`${data?.specify}:`}
																	placeholder="Please specify"
																	disabled={
																		!Boolean(
																			watch(
																				data?.name
																			)
																		)
																	}
																	{...register(
																		`${data?.name}_details`
																	)}
																/>
															) : (
																""
															)}
														</div>
													);
											}
										)}
									</div>
								</div>
							</div>

							<div className="col-span-12">
								<h4 className="border-y-2 text-base font-bold p-2">
									Medical and Surgical History
								</h4>
								<div className="table table-bordered">
									<table className="bordered">
										<thead>
											<tr>
												<th className="w-1/3">
													Click if patient has an
													experience
												</th>
												<th>
													Details (i.e. medications
													taken, yeat diagnosed, year
													of surgery or injury, etc.)
												</th>
											</tr>
										</thead>
										<tbody>
											{medicalSurgicalHistories?.map(
												(data) => {
													return (
														<tr>
															<td className="!py-0 align-middle">
																<label className=" mb-0 p-2 flex items-center text-sm gap-2 text-gray-600">
																	<input
																		type="checkbox"
																		{...register(
																			data?.name,
																			{}
																		)}
																	/>
																	<span>
																		{
																			data?.label
																		}
																	</span>
																</label>
															</td>
															<td className="p-1">
																<TextInputField
																	inputClassName="bg-white"
																	placeholder={`${data?.label} details...`}
																	disabled={
																		!Boolean(
																			watch(
																				data?.name
																			)
																		)
																	}
																	{...register(
																		`${data?.name}_details`,
																		{}
																	)}
																/>
															</td>
														</tr>
													);
												}
											)}
										</tbody>
									</table>
								</div>
							</div>

							<div className="col-span-12">
								<h4 className="border-y-2 text-base font-bold p-2">
									Personal / Social history
								</h4>

								<div className="table table-bordered mb-4">
									<table>
										<tbody>
											<tr>
												<th
													colSpan={3}
													className="!text-blue-600"
												>
													Diet, feeding, nutrition (in
													most days of the week)
												</th>
											</tr>
											<tr>
												<td>
													Intake of high sugar
													(chocolates, pastries,
													cakes, softdrinks, etc):
												</td>
												<td className="lg:w-1/4">
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="no"
															{...register(
																"intake_high_sugar",
																{}
															)}
														/>
														<span>
															No, patient follows
															balanced diet
														</span>
													</label>
												</td>
												<td className="lg:w-1/4">
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="yes"
															{...register(
																"intake_high_sugar",
																{}
															)}
														/>
														<span>Yes</span>
													</label>
												</td>
											</tr>

											<tr>
												<td>Takes supplements</td>
												<td colSpan={2}>
													<div className="flex items-center gap-10">
														<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
															<input
																type="radio"
																value="no"
																{...register(
																	"take_supplements",
																	{}
																)}
															/>
															<span>No</span>
														</label>
														<div className="flex items-center gap-2">
															<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
																<input
																	type="radio"
																	value="yes"
																	{...register(
																		"take_supplements",
																		{}
																	)}
																/>
																<span>Yes</span>
															</label>
															<TextInputField
																{...register(
																	"take_supplements_details",
																	{}
																)}
																disabled={
																	watch(
																		"take_supplements"
																	) != "yes"
																}
																inputClassName="bg-white"
																placeholder="Specify..."
															/>
														</div>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													Deworming every 6 months
													(only until age 12)
												</td>
												<td colSpan={2}>
													<div className="flex items-center gap-10">
														<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
															<input
																type="radio"
																value="no"
																{...register(
																	"deworming_6months",
																	{}
																)}
															/>
															<span>No</span>
														</label>
														<div className="flex items-center gap-2">
															<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
																<input
																	type="radio"
																	value="yes"
																	{...register(
																		"deworming_6months",
																		{}
																	)}
																/>
																<span>Yes</span>
															</label>
															<TextInputField
																inputClassName="bg-white"
																disabled={
																	watch(
																		"deworming_6months"
																	) != "yes"
																}
																placeholder="Specify..."
																{...register(
																	"deworming_6months_details",
																	{}
																)}
															/>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div className="table table-bordered mb-4">
									<table>
										<tbody>
											<tr>
												<th
													colSpan={3}
													className="!text-blue-600"
												>
													Oral health
												</th>
											</tr>

											<tr>
												<td>
													Use of flouride toothpaste
												</td>
												<td>
													<div className="flex items-center gap-10">
														<label className="cursor-pointer mb-0 flex items-center text-sm gap-2 text-gray-600">
															<input
																type="radio"
																value="no"
																{...register(
																	"flouride_toothpaste",
																	{}
																)}
															/>
															<span>No</span>
														</label>
														<label className="cursor-pointer mb-0 flex items-center text-sm gap-2 text-gray-600">
															<input
																type="radio"
																value="yes"
																{...register(
																	"flouride_toothpaste",
																	{}
																)}
															/>
															<span>Yes</span>
														</label>
														<TextInputField
															disabled={
																watch(
																	"flouride_toothpaste"
																) != "yes"
															}
															labelClassName="!mb-0 whitespace-nowrap"
															className="flex flex-row items-center gap-2"
															type="date"
															label="Last dental check-up:"
															inputClassName="bg-white"
														/>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div className="table table-bordered mb-4">
									<table>
										<tbody>
											<tr>
												<th
													colSpan={3}
													className="!text-blue-600"
												>
													Physical activity
												</th>
											</tr>
											<tr>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="Sedentary"
															{...register(
																"physical_activity",
																{}
															)}
														/>
														<span>Sedentary</span>
													</label>
												</td>
												<td>
													<div className="flex flex-col">
														<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
															<input
																type="radio"
																value="With supervised moderate"
																{...register(
																	"physical_activity",
																	{}
																)}
															/>
															<span>
																With supervised
																moderate to
																vigorous
																physical
																activites (brisk
																walk, jogging,
																running,
																bicycling, etc.)
																for at least 1
																hour/day
															</span>
														</label>

														<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
															<input
																type="radio"
																value="With vigorous-intensity"
																{...register(
																	"physical_activity",
																	{}
																)}
															/>
															<span>
																With
																vigorous-intensity
																activities,
																including those
																which strengthen
																the muscle and
																bones, at least
																3х / week
															</span>
														</label>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div className="table table-bordered mb-4">
									<table>
										<tbody>
											<tr>
												<th
													colSpan={3}
													className="!text-blue-600"
												>
													Daily screen time
												</th>
											</tr>
											<tr>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="no screen time"
															{...register(
																"daily_screen_time",
																{}
															)}
														/>
														<span>
															No screen time
														</span>
													</label>
												</td>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="less 1 hour"
															{...register(
																"daily_screen_time",
																{}
															)}
														/>
														<span>
															{`< 1 hour sedentary screen time`}
														</span>
													</label>
												</td>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="more 1 hour"
															{...register(
																"daily_screen_time",
																{}
															)}
														/>
														<span>
															{`> 1 hour sedentary screen time`}
														</span>
													</label>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div className="table table-bordered mb-4">
									<table>
										<tbody>
											<tr>
												<th
													colSpan={5}
													className="!text-blue-600"
												>
													Violence and injuries
												</th>
											</tr>
											<tr>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="none"
															{...register(
																"violence_injuries",
																{}
															)}
														/>
														<span>None</span>
													</label>
												</td>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="electrocution"
															{...register(
																"violence_injuries",
																{}
															)}
														/>
														<span>
															Electrocution
														</span>
													</label>
												</td>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="falls"
															{...register(
																"violence_injuries",
																{}
															)}
														/>
														<span>Falls</span>
													</label>
												</td>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="mauling"
															{...register(
																"violence_injuries",
																{}
															)}
														/>
														<span>Mauling</span>
													</label>
												</td>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="others"
															{...register(
																"violence_injuries",
																{}
															)}
														/>
														<span>Others</span>
														<TextInputField
															disabled={
																watch(
																	"violence_injuries"
																) != "others"
															}
															inputClassName="bg-white"
															placeholder="Others..."
															{...register(
																"violence_injuries_details",
																{}
															)}
														/>
													</label>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div className="table table-bordered mb-4">
									<table>
										<tbody>
											<tr>
												<th
													colSpan={5}
													className="!text-blue-600"
												>
													Bullying and harassment
												</th>
											</tr>
											<tr>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="none"
															{...register(
																"bully_harassment",
																{}
															)}
														/>
														<span>None</span>
													</label>
												</td>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="at home"
															{...register(
																"bully_harassment",
																{}
															)}
														/>
														<span>At home</span>
													</label>
												</td>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="in school"
															{...register(
																"bully_harassment",
																{}
															)}
														/>
														<span>In school</span>
													</label>
												</td>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="online"
															{...register(
																"bully_harassment",
																{}
															)}
														/>
														<span>Online</span>
													</label>
												</td>
												<td>
													<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
														<input
															type="radio"
															value="others"
															{...register(
																"bully_harassment",
																{}
															)}
														/>
														<span>Others</span>
														<TextInputField
															disabled={
																watch(
																	"bully_harassment"
																) != "others"
															}
															inputClassName="bg-white"
															placeholder="Others..."
															{...register(
																"bully_harassment_details",
																{}
															)}
														/>
													</label>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div className="col-span-12">
								<h4 className="border-y-2 text-base font-bold p-2">
									Environmental History
								</h4>

								<span className="text-blue-600 text-sm font-bold px-2">
									Access to safe water
								</span>
								<div className="p-2 gap-4 flex items-center flex-wrap mb-3">
									{environmentalHistories?.map((data) => {
										return (
											<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
												<input
													type="radio"
													{...register(
														"safe_water",
														{}
													)}
													value={data?.label}
												/>
												<span>{data?.label}</span>
											</label>
										);
									})}
								</div>

								<span className="text-blue-600 text-sm font-bold px-2">
									Access to sanitary toilet
								</span>
								<div className="p-2 gap-4 flex items-center flex-wrap mb-3">
									{sanitaryOptions?.map((data) => {
										return (
											<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
												<input
													type="radio"
													value={String(data?.label)
														.toLowerCase()
														.replace(/\s/g, "")}
													{...register(
														"access_to_sanitary_toilet",
														{}
													)}
												/>
												<span>{data?.label}</span>
											</label>
										);
									})}
								</div>
								<span className="text-blue-600 text-sm font-bold px-2">
									Access to satisfactory waste disposal
								</span>
								<div className="p-2 gap-4 flex items-center flex-wrap mb-3">
									{accessWasteOptions?.map((data) => {
										return (
											<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
												<input
													type="radio"
													{...register(
														"satisfactory_waste_disposal",
														{}
													)}
													value={String(data?.label)
														.toLowerCase()
														.replace(/\s/g, "")}
												/>
												<span>{data?.label}</span>
											</label>
										);
									})}
								</div>
								<span className="text-blue-600 text-sm font-bold px-2">
									Prolonged exposure to biomass fuel for
									cooking (charcoal, firewood, sawdust, animal
									manure, or crop residue)
								</span>
								<div className="p-2 gap-4 flex items-center flex-wrap mb-3">
									<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
										<input
											type="radio"
											value="no"
											{...register(
												"prolong_exposure_biomass_fuel",
												{}
											)}
										/>
										<span>No</span>
									</label>
									<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
										<input
											type="radio"
											value="yes"
											{...register(
												"prolong_exposure_biomass_fuel",
												{}
											)}
										/>
										<span>Yes</span>
									</label>
								</div>
								<span className="text-blue-600 text-sm font-bold px-2">
									Exposure to tobacco or vape
								</span>
								<div className="p-2 gap-4 flex items-center flex-wrap mb-3">
									<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
										<input
											type="radio"
											value="yes"
											{...register(
												"exposure_tabacco_vape",
												{}
											)}
										/>
										<span>No</span>
									</label>
									<label className="mb-0 flex items-center text-sm gap-2 text-gray-600">
										<input
											type="radio"
											value="yes"
											{...register(
												"exposure_tabacco_vape",
												{}
											)}
										/>
										<span>Yes, by whom?</span>
										<TextInputField
											placeholder="by whom?"
											{...register(
												"exposure_tabacco_vape_details",
												{}
											)}
										/>
									</label>
								</div>
							</div>
						</>
					) : (
						""
					)}
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center"}>
				<ActionBtn
					className="ml-auto"
					onClick={handleSubmit(submit)}
					loading={loading}
				>
					Submit appointment
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(CreateAppointmentModal);
