import React, { useState, useContext, useCallback, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  TablePagination,
  IconButton,
  Grid,
  Collapse,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import axios from "axios";
import ChevronRight from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const NSPatientDoctorNotes = ({ patient_id }) => {
  const { users } = useContext(UsersData);
  const [notes, setNotes] = useState({ data: [], ready: false });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedId, setSelectedId] = useState(null);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getNotes = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      patient_id,
    };
    try {
      let response = await axios.get("nurse/patient/notes/notes-list", {
        params,
      });
      const data = response.data;
      setNotes({ data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, patient_id]);

  useEffect(() => {
    getNotes();
  }, [getNotes]);

  return (
    <Box p={2} mt={2} component={Paper} elevation={2}>
      <Box mb={2}>
        <Typography color="textSecondary" variant="h6">
          <strong>Doctor's Notes</strong>
        </Typography>
      </Box>
      <Box>
        {notes.ready
          ? notes.data.length > 0
            ? (rowsPerPage > 0
                ? notes.data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : notes.data
              ).map((data, index) => (
                <Box p={1} key={index}>
                  <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          setSelectedId(selectedId === data.id ? null : data.id)
                        }
                      >
                        {selectedId === data.id ? (
                          <KeyboardArrowDownIcon fontSize="large" />
                        ) : (
                          <ChevronRight fontSize="large" />
                        )}
                      </IconButton>
                    </Box>
                    <Box width="100%">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                          <Box />
                          <Typography
                            variant="subtitle1"
                            style={{
                              fontWeight: "bolder",
                              textTransform: "capitalize",
                            }}
                          >
                            From : {data.doctors_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Typography
                            variant="subtitle1"
                            style={{
                              fontWeight: "bolder",
                              textTransform: "capitalize",
                            }}
                          >
                            Diagnosis : {data.initial_diagnosis}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Typography variant="subtitle2">
                        {Notify.dateTimeConvert(data.created_at)}
                      </Typography>
                    </Box>
                  </Box>

                  <Collapse in={selectedId === data.id ? true : false}>
                    <Box my={3}>
                      <div
                        className="py-2"
                        dangerouslySetInnerHTML={{ __html: data.notes }}
                      />
                    </Box>
                  </Collapse>
                </Box>
              ))
            : Notify.noRecord()
          : Notify.loading()}
      </Box>
      <TablePagination
        labelRowsPerPage="List"
        rowsPerPageOptions={[5, 20, 50, 100]}
        component="div"
        count={notes.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default NSPatientDoctorNotes;
