import {
  Card,
  CardContent,
  Box,
  CardHeader,
  Typography,
  CardActions,
  Button,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const Details = ({ details }) => {
  const history = useHistory();

  return (
    <>
      <Box>
        <Card>
          <CardHeader title={`Doctors Admitting Order`} />
          <CardContent>
            <Box>
              <Typography> </Typography>

              <Typography
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {details.doctors_order}
              </Typography>
            </Box>
          </CardContent>

          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                history.push(
                  `/sph/app/admitting/details/admit-patient/${details.trace_number}/${details.patient_id}`
                )
              }
            >
              Admit Patient
            </Button>
          </CardActions>
        </Card>
      </Box>
    </>
  );
};

export default Details;
