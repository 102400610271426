import {
	Box,
	Grid,
	Button,
	Backdrop,
	CircularProgress,
	makeStyles,
	Typography,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import PrintIcon from "@material-ui/icons/Print";
import { UsersData } from "src/ContextAPI";
import { XCircle } from "react-feather";
import SarsCovPrintAll from "./printall/forms/SarsCovPrintAll";
import ECGPrintAll from "./printall/forms/ECGPrintAll";
import MedicalExamPrintAll from "./printall/MedicalExamPrintAll";
import MedicalCertificatePrintAll from "./printall/MedicalCertificatePrintAll";

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

const PrintMobileVanOrderOther = ({
	close,
	selectedTraceNoData,
	handleSearchPatient,
	selectedBranch,
}) => {
	const classes = useStyles();
	const { users } = React.useContext(UsersData);
	const [print, setPrint] = useState("");
	const [printProcess, setPrintProcess] = useState(false);

	const [formHeader, setFormHeader] = useState({
		data: [],
		ready: false,
	});

	const [patients, setPatients] = useState({
		data: [],
		ready: false,
	});

	const [medicalExamOrderDetails, setMedicalExamOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const [medCertOrderDetails, setMedCertOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const [ECGOrderDetails, setECGOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const [sarsCovOrderDetails, setSarsCovOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const getLabFormHeader = () => {
		var params = { management_id: selectedBranch };
		Axios.get("laboratory/order/formheader-details", { params })
			.then((response) => {
				const data = response.data;
				setFormHeader(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getPatientInformation = () => {
		var params = { patient_id: selectedTraceNoData.patient_id };
		Axios.get("documentation/get/patient-info/patient-id", { params })
			.then((response) => {
				const data = response.data;
				setPatients({ data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getMedicalExamOrderDetails = () => {
		var params = {
			user_id: users.user_id,
			trace_number: selectedTraceNoData.trace_number,
		};
		Axios.get("laboratory/order/ordernew-medicalexam/complete/details-print", {
			params,
		})
			.then((response) => {
				const data = response.data;
				setMedicalExamOrderDetails({ data: data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getCompletedMedCert = () => {
		var params = {
			patient_id: selectedTraceNoData.patient_id,
			trace_number: selectedTraceNoData.trace_number,
		};
		Axios.get(
			"laboratory/order/ordernew-medicalcert/complete/medicalcert-print",
			{
				params,
			}
		)
			.then((response) => {
				const data = response.data;
				setMedCertOrderDetails({ data: data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getCompletedECG = () => {
		var params = {
			user_id: users.user_id,
			trace_number: selectedTraceNoData.trace_number,
			management_id: selectedBranch,
		};
		Axios.get("laboratory/order/ordernew-ecg/complete/details-print", {
			params,
		})
			.then((response) => {
				const data = response.data;
				setECGOrderDetails({ data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getSarsCovOrderDetails = () => {
		var params = {
			user_id: users.user_id,
			trace_number: selectedTraceNoData.trace_number,
			management_id: selectedBranch,
		};
		Axios.get("laboratory/order/ordernew-sarscov/complete/details-print", {
			params,
		})
			.then((response) => {
				const data = response.data;
				setSarsCovOrderDetails({ data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	useEffect(() => {
		getLabFormHeader();
		getPatientInformation();
		getMedicalExamOrderDetails();
		getCompletedMedCert();
		getCompletedECG();
		getSarsCovOrderDetails();
		// eslint-disable-next-line
	}, [selectedTraceNoData]);

	const handlePrint = (e) => {
		setPrint(e.currentTarget.id);
		setPrintProcess(true);
		setTimeout(() => {
			setPrintProcess(false);
			window.print();
		}, 4000);
	};

	return (
		<Fragment>
			<Dialog
				fullScreen
				disableBackdropClick
				disableEscapeKeyDown
				open={true}
				onClose={() => console.log("dialog unclosable")}
			>
				<Box display="flex" className="gtc-hideonprint">
					<Box flexGrow={1}>
						<DialogTitle> Print Result Report </DialogTitle>
					</Box>
					<Box>
						<IconButton onClick={() => close()} color="secondary">
							<XCircle />
						</IconButton>
					</Box>
				</Box>

				<DialogContent>
					<Box m={2}>
						<Backdrop open={printProcess} className={classes.backdrop}>
							<Box alignItems="center" align="center">
								<CircularProgress color="inherit" />
								<Typography>Please wait...</Typography>
							</Box>
						</Backdrop>

						<Grid container spacing={2}>
							<Grid className="gtc-hideonprint" item xs={12} sm={3} lg={2}>
								<Box>
									<Button
										hidden={medicalExamOrderDetails.data.length === 0}
										variant="outlined"
										onClick={handlePrint}
										id="physical-exam"
										color="primary"
										startIcon={<PrintIcon />}
										fullWidth
										size="large"
										component={Box}
										my={1}
									>
										Physical Exam
									</Button>

									<Button
										hidden={medCertOrderDetails.data.length === 0}
										variant="outlined"
										onClick={handlePrint}
										id="med-cert"
										color="primary"
										startIcon={<PrintIcon />}
										fullWidth
										size={"large"}
										component={Box}
										my={1}
									>
										Medical Certificate
									</Button>

									<Button
										hidden={ECGOrderDetails.data.length === 0}
										variant="outlined"
										onClick={handlePrint}
										id="ecg"
										color="primary"
										startIcon={<PrintIcon />}
										fullWidth
										size="large"
										component={Box}
										my={1}
									>
										ECG
									</Button>

									<Button
										hidden={sarsCovOrderDetails.data.length === 0}
										variant="outlined"
										onClick={handlePrint}
										id="sars"
										color="primary"
										startIcon={<PrintIcon />}
										fullWidth
										size="large"
										component={Box}
										my={1}
									>
										SARSCOV
									</Button>
								</Box>
							</Grid>

							<Grid item xs={12} sm={9} lg={10}>
								{Object.keys(formHeader).length > 0 ? (
									<>
										<Box
											mb={2}
											className={
												print === "physical-exam"
													? "gtc-showonprint"
													: "gtc-hideonprint"
											}
										>
											{medicalExamOrderDetails.ready &&
												medicalExamOrderDetails.data.length > 0 && (
													<>
														<MedicalExamPrintAll
															allowHeaderPatientInfo={"allowed"}
															patient_id={selectedTraceNoData.patient_id}
															trace_number={selectedTraceNoData.trace_number}
															patients={patients}
															medicalExamOrderDetails={medicalExamOrderDetails}
														/>
													</>
												)}
										</Box>

										<Box
											mb={2}
											className={
												print === "med-cert"
													? "gtc-showonprint"
													: "gtc-hideonprint"
											}
										>
											{medCertOrderDetails.ready && medCertOrderDetails.data && (
												<>
													<MedicalCertificatePrintAll
														allowHeaderPatientInfo={"allowed"}
														patient_id={selectedTraceNoData.patient_id}
														trace_number={selectedTraceNoData.trace_number}
														patients={patients}
														medCertOrderDetails={medCertOrderDetails}
													/>
												</>
											)}
										</Box>

										<Box
											mb={2}
											className={
												print === "ecg" ? "gtc-showonprint" : "gtc-hideonprint"
											}
										>
											{ECGOrderDetails.ready &&
												ECGOrderDetails.data.length > 0 && (
													<ECGPrintAll
														allowHeaderPatientInfo={"allowed"}
														patients={patients}
														ECGOrderDetails={ECGOrderDetails}
													/>
												)}
										</Box>

										<Box
											mb={2}
											className={
												print === "sars" ? "gtc-showonprint" : "gtc-hideonprint"
											}
										>
											{sarsCovOrderDetails.ready &&
												sarsCovOrderDetails.data.length > 0 && (
													<SarsCovPrintAll
														allowHeaderPatientInfo={"allowed"}
														patients={patients}
														sarsCovOrderDetails={sarsCovOrderDetails}
													/>
												)}
										</Box>
									</>
								) : (
									"Others information not set in database."
								)}
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
			</Dialog>
		</Fragment>
	);
};

export default PrintMobileVanOrderOther;
