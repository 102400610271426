import React, { Fragment } from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const CBCPrintAll = ({
  allowHeaderPatientInfo,
  patients,
  CBCTestOrderDetails,
}) => {
  return (
    <>
      {CBCTestOrderDetails.data.length > 0 &&
        CBCTestOrderDetails.data.map((data, key) => (
          <Fragment key={key}>
            {allowHeaderPatientInfo !== "notallowed" && (
              <>
                <HeaderPrintAll />
                <PatientInfoPrintAll details={patients} />
              </>
            )}
            {Boolean(parseInt(data.cbc)) && (
              <Box>
                <Box>
                  <Typography
                    variant="h6"
                    align="center"
                    style={{
                      fontWeight: "bolder",
                      color: "#000",
                      textDecoration: "underline",
                    }}
                  >
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning={true}
                    >
                      HEMATOLOGY
                    </span>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ fontWeight: "bolder", color: "#000" }}
                  >
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning={true}
                    >
                      COMPLETE BLOOD COUNT
                    </span>
                  </Typography>
                </Box>

                <Box mt={2} hidden={!Boolean(parseInt(data.is_processed))}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4}>
                      <Box
                        display="flex"
                        hidden={data.wbc === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            WBC :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.wbc}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.lym === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            LYM :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.lym}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.mid === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            MID :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.mid}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.neut === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            NEUT :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.neut}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.rbc === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            RBC :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.rbc}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <Box
                        display="flex"
                        hidden={data.hgb === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            HGB :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography align="center">{data.hgb}</Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.hct === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            HTC :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.hct}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.hct === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            MCV :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.mcv}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.mch === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            MCH :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.mch}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.mchc === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            MCHC :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.mchc}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <Box
                        display="flex"
                        hidden={data.rdw_sd === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            RDW-SD :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.rdw_sd}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.rdw_cv === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            RDW-CV :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.rdw_cv}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.mpv === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            MPV :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.mpv}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.pdw === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            PDW :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.pdw}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.pct === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            PCT :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.pct}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box
                    display="flex"
                    hidden={data.p_lcr === null ? true : false}
                  >
                    <Box width={90}>
                      <Typography
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        P-LCR :{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        align="center"
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        {data.p_lcr}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    hidden={data.remarks === null ? true : false}
                  >
                    <Box width={90}>
                      <Typography
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        REMARKS :{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        align="center"
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        {data.remarks}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {Boolean(parseInt(data.cbc_platelet)) && (
              <Box>
                <Box>
                  <Typography
                    variant="h6"
                    align="center"
                    style={{
                      fontWeight: "bolder",
                      color: "#000",
                      textDecoration: "underline",
                    }}
                  >
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning={true}
                    >
                      HEMATOLOGY
                    </span>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{
                      fontWeight: "bolder",
                      color: "#000",
                    }}
                  >
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning={true}
                    >
                      COMPLETE BLOOD COUNT WITH PLATELET
                    </span>
                  </Typography>
                </Box>

                <Box mt={2} hidden={!Boolean(parseInt(data.is_processed))}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4}>
                      <Box
                        display="flex"
                        hidden={data.wbc === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            WBC :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.wbc}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.lym === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            LYM :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.lym}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.mid === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            MID :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.mid}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.neut === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            NEUT :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.neut}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.rbc === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            RBC :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.rbc}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.hgb === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            HGB :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.hgb}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <Box
                        display="flex"
                        hidden={data.hct === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            HTC :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.hct}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.mcv === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            MCV :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.mcv}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.mch === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            MCH :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.mch}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.mchc === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            MCHC :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.mchc}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.rdw_sd === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            RDW-SD :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.rdw_sd}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.rdw_cv === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            RDW-CV :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.rdw_cv}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <Box
                        display="flex"
                        hidden={data.mpv === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            MPV :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.mpv}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.pdw === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            PDW :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.pdw}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.pct === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            PCT :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.pct}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        hidden={data.plt === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            PLT :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.plt}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        hidden={data.p_lcr === null ? true : false}
                      >
                        <Box width={90}>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            P-LCR :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.p_lcr}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box
                    display="flex"
                    hidden={data.remarks === null ? true : false}
                  >
                    <Box width={90}>
                      <Typography
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        REMARKS :{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        align="center"
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        {data.remarks}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Fragment>
        ))}
      <Box my={1} align="center">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box align="center" width={350}>
              <Box mt={1}>
                <Typography
                  className={`gtc-uppercase`}
                  variant="subtitle2"
                  style={{ color: "#000", fontWeight: "bolder" }}
                >
                  {CBCTestOrderDetails.data.length > 0 &&
                    CBCTestOrderDetails.data[0].pathologist}
                </Typography>
                <Box borderTop={1}>
                  <b style={{ color: "#000" }}> Pathologist </b>
                </Box>
                <Box>
                  <Typography
                    className={`gtc-uppercase`}
                    variant="subtitle2"
                    style={{ color: "#000", fontWeight: "bolder" }}
                  >
                    {CBCTestOrderDetails.data.length > 0 &&
                      CBCTestOrderDetails.data[0].pathologist_lcn}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={4}>
            <Box align="center" width={250}>
              <Box mt={1}>
                <Typography
                  className={`gtc-uppercase`}
                  variant="subtitle2"
                  style={{ color: "#000", fontWeight: "bolder" }}
                >
                  {CBCTestOrderDetails.data.length > 0 &&
                    CBCTestOrderDetails.data[0].chief_medtech}
                </Typography>
                <Box borderTop={1}>
                  <b style={{ color: "#000" }}> Chief Med Technologist </b>
                </Box>
                <Box>
                  <Typography
                    className={`gtc-uppercase`}
                    variant="subtitle2"
                    style={{ color: "#000", fontWeight: "bolder" }}
                  >
                    {CBCTestOrderDetails.data.length > 0 &&
                      CBCTestOrderDetails.data[0].chief_medtech_lci}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <Box align="center" width={350}>
              <Box mt={1}>
                <Typography
                  className={`gtc-uppercase`}
                  variant="subtitle2"
                  style={{ color: "#000", fontWeight: "bolder" }}
                >
                  <span
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                  >
                    {CBCTestOrderDetails.data.length > 0 &&
                      CBCTestOrderDetails.data[0].medtech}
                  </span>
                </Typography>
                <Box borderTop={1}>
                  <b style={{ color: "#000" }}> Medical Technologist </b>
                </Box>
                <Box>
                  <Typography
                    className={`gtc-uppercase`}
                    variant="subtitle2"
                    style={{ color: "#000", fontWeight: "bolder" }}
                  >
                    <span
                      contentEditable={true}
                      suppressContentEditableWarning={true}
                    >
                      {CBCTestOrderDetails.data.length > 0 &&
                        CBCTestOrderDetails.data[0].lic_no}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CBCPrintAll;
