class ChartCustomTooltipClass {
    show = (tooltipModel, chartRef) => {   
        // Tooltip Element
        var tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div class="bg-dark rounded text-white p-1 border"></div>';
            document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem) {
            return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
            var titleLines = tooltipModel.title || [];
            var bodyLines = tooltipModel.body.map(getBody);

            var innerHtml = `<div>`;

                titleLines.forEach(function(title) {
                    innerHtml += `<p class="m-0 p-0"> ${ title } </p>`;
                });  

                bodyLines.forEach(function(body, i) { 
                    innerHtml += `<div>${body}</div>`;
                });
            
            innerHtml += `</div>`;

            var tableRoot = tooltipEl.querySelector('div');
            tableRoot.innerHTML = innerHtml;
        }

        // `this` will be the overall tooltip
        var position = chartRef.current.chartInstance.canvas.getBoundingClientRect();  

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX +  'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
   }
}

const ChartCustomTooltip = new ChartCustomTooltipClass();
export default ChartCustomTooltip;