import { TextField } from "@material-ui/core";
import axios from "axios";
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useForm } from "react-hook-form";
import { FiX } from "react-icons/fi";
import ActionBtn from "src/components/ActionBtn";
import Button from "src/components/Button";
import FlatIcon from "src/components/FlatIcon";
import Img from "src/components/Img";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";

const Patient = ({ patient, hide, receive_patient_profile_ref }) => {
	return (
		<div className="rounded-xl border bg-slate-100 p-3 flex items-start gap-3">
			<div className="w-11 h-11 min-w-[44px] min-h-11[44px] rounded-full bg-slate-300 relative overflow-hidden">
				<Img
					className={`absolute top-0 left-0 w-full h-full object-contain`}
					src={patient?.avatar}
					name={`${patient?.lastname}, ${patient?.firstname}, ${patient?.middle}`}
					type="user"
				/>
			</div>
			<div className="flex flex-col gap-y-1 mr-auto">
				<span className="text-lg font-bold ">{`${patient?.lastname}, ${patient?.firstname}, ${patient?.middle}`}</span>
				<div className="flex items-center gap-2">
					<FlatIcon icon="rr-map" className="text-placeholder" />

					<span className="text-sm text-placeholder mr-auto">{`${patient?.municipality?.name}, ${patient?.barangay?.name}, ${patient?.puroks?.name}`}</span>
				</div>
			</div>
			<ActionBtn
				onClick={() => {
					receive_patient_profile_ref.current.show(patient);
					hide();
				}}
			>
				Select Patient
			</ActionBtn>
		</div>
	);
};

const QueueNumberModal = (props, ref) => {
	const [open, setOpen] = useState(false);
	const [data, setData] = useState(null);

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (showData) => {
		setData(showData);
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	return (
		<Modal
			open={open}
			hide={hide}
			className={`w-[384px] min-w-[384px] max-w-[384px]`}
			staticModal={true}
		>
			<ModalBody className={`p- rounded-t-xl bg-white !pb-6`}>
				<div className="flex flex-col gap-y-3 px-6 pt-11 relative">
					<div
						className="absolute top-3 right-3 ml-auto w-8 h-8 rounded-full bg-red-600 flex items-center justify-center hover:cursor-pointer hover:shadow-lg duration-200 text-white"
						onClick={hide}
					>
						<FiX className=" font-bold" />
					</div>
					<h3 className="text-2xl font-bold text-center mt-1">
						PATIENT QUEUE NUMBER
					</h3>
					<h1 className="text-[88px] font-bold text-blue-600 text-center w-full">
						{data?.number}
					</h1>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default forwardRef(QueueNumberModal);
