import React, { Fragment, useContext, useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Container from "src/layout/Container";
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Avatar,
  useTheme,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import { UsersData } from "src/ContextAPI";
import Details from "./Details";

const DoctorAdmittedPatient = () => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [admittedPatient, setAdmittedPatient] = useState([]);
  const [admittedPatientRd, setAdmittedPatientRd] = useState(false);

  const [selectedPatient, setSelectedPatient] = useState(null);
  const theme = useTheme();

  const getAdmittedPatientList = React.useCallback(() => {
    setAdmittedPatientRd(true);
    axios
      .get(`doctor/patient/admitted-list`, {
        params: {
          user_id: users.user_id,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setAdmittedPatient(data);
        }
      })
      .catch((err) => console.log(`err:`, err.message))
      .finally(() => setAdmittedPatientRd(false));
  }, [mounted, users]);

  useEffect(() => {
    getAdmittedPatientList();
  }, [getAdmittedPatientList]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Admission",
          items: [
            {
              name: "Dashboard",
              path: "/sph/app/doctor",
            },
          ],
        }}
        title={"Admitted Patients List"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <CardHeader title="Patients" />
              <CardContent>
                <List>
                  {!admittedPatientRd ? (
                    admittedPatient.length > 0 ? (
                      admittedPatient.map((data, index) => (
                        <ListItem
                          key={index}
                          button
                          onClick={() => setSelectedPatient(data)}
                        >
                          <ListItemIcon>
                            <Avatar
                              style={{
                                backgroundColor: theme.palette.primary.main,
                                color: "#fff",
                              }}
                            >
                              {index++ + 1}
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText
                            primary={`${data.lastname}, ${data.firstname}`}
                            primaryTypographyProps={{
                              style: {
                                textTransform: "capitalize",
                              },
                            }}
                            secondary={Notify.dateTimeConvert(data.created_at)}
                          />
                        </ListItem>
                      ))
                    ) : (
                      <>{Notify.noRecord()}</>
                    )
                  ) : (
                    Notify.loading()
                  )}
                </List>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            {selectedPatient !== null && (
              <Details
                patient_id={selectedPatient.patient_id}
                trace_number={selectedPatient.trace_number}
                details={selectedPatient}
                getAdmittedPatientList={getAdmittedPatientList}
                onClose={() => setSelectedPatient(null)}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default DoctorAdmittedPatient;
