import React, { Fragment } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const ClinicalMicroscopyPrintAll = ({
  allowHeaderPatientInfo,
  patients,
  clinicalMicroscopyOrderDetails,
}) => {
  return (
    <>
      {clinicalMicroscopyOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                CLINICAL MICROSCOPY
              </span>
            </Typography>
          </Box>
          <Box>
            {clinicalMicroscopyOrderDetails.data.map((data, index) => (
              <Fragment key={index}>
                <Box mb={2}>
                  {Boolean(parseInt(data.chemical_test)) && (
                    <>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          CHEMICAL TEST
                        </Typography>
                      </Box>
                      <Box ml={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={4}>
                            <Box
                              mt={1}
                              display="flex"
                              hidden={
                                data.chemical_test_color === null ? true : false
                              }
                            >
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                COLOR:
                              </Typography>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.chemical_test_color}
                                </span>
                              </Box>
                            </Box>

                            <Box
                              mt={1}
                              display="flex"
                              hidden={
                                data.chemical_test_transparency === null
                                  ? true
                                  : false
                              }
                            >
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                TRANSPARENCY:
                              </Typography>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.chemical_test_transparency}
                                </span>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={4} sm={4}>
                            <Box
                              mt={1}
                              display="flex"
                              hidden={
                                data.chemical_test_ph === null ? true : false
                              }
                            >
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                PH:
                              </Typography>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.chemical_test_ph}
                                </span>
                              </Box>
                            </Box>
                            <Box
                              mt={1}
                              display="flex"
                              hidden={
                                data.chemical_test_spicific_gravity === null
                                  ? true
                                  : false
                              }
                            >
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                SPECIFIC GRAVITY:
                              </Typography>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.chemical_test_spicific_gravity}
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <Box
                              mt={1}
                              display="flex"
                              hidden={
                                data.chemical_test_glucose === null
                                  ? true
                                  : false
                              }
                            >
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                GLUCOSE:
                              </Typography>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.chemical_test_glucose}
                                </span>
                              </Box>
                            </Box>
                            <Box
                              mt={1}
                              display="flex"
                              hidden={
                                data.chemical_test_albumin === null
                                  ? true
                                  : false
                              }
                            >
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                ALBUMIN:
                              </Typography>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.chemical_test_albumin}
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                </Box>

                <Box mb={2}>
                  {/* clinical microscopy */}
                  {Boolean(parseInt(data.microscopic_test)) && (
                    <>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          MICROSCOPIC TEST
                        </Typography>
                      </Box>
                      <Box ml={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6}>
                            <>
                              <Box mt={1}>
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  CELLS
                                </Typography>
                              </Box>
                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_squamous === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Squamous Cells
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_squamous}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_pus === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Pus Cells
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_pus}
                                  </span>
                                </Box>
                                /HPF
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_redblood === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Red Blood Cells
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_redblood}
                                  </span>
                                </Box>
                                /HPF
                              </Box>
                            </>

                            <>
                              <Box mt={1}>
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  CASTS
                                </Typography>
                              </Box>
                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_hyaline === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Hyaline Cast
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_hyaline}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_wbc === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  WBC Cast
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_wbc}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_rbc === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  RBC Cast
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_rbc}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_fine_granular === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Fine Granualar Cast
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_fine_granular}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_coarse_granular === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Coarse Granualar Cast
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_coarse_granular}
                                  </span>
                                </Box>
                              </Box>
                            </>
                          </Grid>

                          <Grid item xs={6} sm={6}>
                            <>
                              <Box mt={1}>
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  CRYSTALS
                                </Typography>
                              </Box>
                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_calcium_oxalate === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Calcium Oxalate
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_calcium_oxalate}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_triple_phospahte ===
                                  null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Triple Phosphate
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_triple_phospahte}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_leucine_tyrosine ===
                                  null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Leucine/Tyrosine
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_leucine_tyrosine}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_ammonium_biurate ===
                                  null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Ammonium Biurate
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_ammonium_biurate}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_amorphous_urates ===
                                  null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Amorphous Urates
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_amorphous_urates}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_amorphous_phosphates ===
                                  null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  <b> Amorphous Phosphates </b>
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_amorphous_phosphates}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_uricacid === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Uric Acid
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_uricacid}
                                  </span>
                                </Box>
                              </Box>
                            </>
                            <>
                              <Box mt={1}>
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  OTHERS
                                </Typography>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_mucus_thread === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Mucus Thread
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_mucus_thread}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_bacteria === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Bacteria
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_bacteria}
                                  </span>
                                </Box>
                              </Box>

                              <Box
                                mt={1}
                                display="flex"
                                hidden={
                                  data.microscopic_test_yeast === null
                                    ? true
                                    : false
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Yeast
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_yeast}
                                  </span>
                                </Box>
                              </Box>
                            </>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                </Box>

                <Box mb={2}>
                  {/* clinical microscopy */}
                  {Boolean(parseInt(data.pregnancy_test_hcg)) && (
                    <>
                      <Box
                        mt={1}
                        display="flex"
                        hidden={
                          data.pregnancy_test_hcg_result === null ? true : false
                        }
                      >
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          PREGNANCY TEST (HCG):
                        </Typography>
                        <Box
                          mx={2}
                          align="center"
                          minWidth={100}
                          borderBottom={1}
                        >
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.pregnancy_test_hcg_result}
                          </span>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>

                <Box mb={2}>
                  {/* clinical microscopy */}
                  {Boolean(parseInt(data.micral_test)) && (
                    <Box
                      hidden={data.micral_test_result === null ? true : false}
                    >
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          MICRAL TEST
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.micral_test_result}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box mb={2}>
                  {/* clinical microscopy */}
                  {Boolean(parseInt(data.seminalysis_test)) && (
                    <Box
                      hidden={data.seminalysis_result === null ? true : false}
                    >
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> SEMENALYSIS </b>
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.seminalysis_result}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box mb={2}>
                  {/* clinical microscopy */}
                  {Boolean(parseInt(data.occult_blood_test)) && (
                    <Box
                      hidden={
                        data.occult_blood_test_result === null ? true : false
                      }
                    >
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          OCCULT BLOOD TEST
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.occult_blood_test_result}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box mb={2}>
                  {/* clinical microscopy */}
                  {index + 1 === clinicalMicroscopyOrderDetails.data.length && (
                    <Box hidden={data.result_remarks === null ? true : false}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          REMARKS
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.result_remarks}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Fragment>
            ))}
          </Box>
          <Box my={1} align="center">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {clinicalMicroscopyOrderDetails.data.length > 0 &&
                        clinicalMicroscopyOrderDetails.data[0].pathologist}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Pathologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {clinicalMicroscopyOrderDetails.data.length > 0 &&
                          clinicalMicroscopyOrderDetails.data[0]
                            .pathologist_lcn}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xs={12} sm={4}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {clinicalMicroscopyOrderDetails.data.length > 0 &&
                        clinicalMicroscopyOrderDetails.data[0].chief_medtech}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Chief Med Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {clinicalMicroscopyOrderDetails.data.length > 0 &&
                          clinicalMicroscopyOrderDetails.data[0]
                            .chief_medtech_lci}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      <span
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                      >
                        {clinicalMicroscopyOrderDetails.data.length > 0 &&
                          clinicalMicroscopyOrderDetails.data[0].medtech}
                      </span>
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Medical Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {clinicalMicroscopyOrderDetails.data.length > 0 &&
                            clinicalMicroscopyOrderDetails.data[0].lic_no}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default ClinicalMicroscopyPrintAll;
