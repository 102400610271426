import html2canvas from "html2canvas";
import {
	calculateAge,
	formatDate,
	replaceFname,
	replaceLname,
	replaceMname,
	dataURItoBlob,
	isBase64,
} from "../../../src/helpers/utils";
// import CollapseContainer from "src/components/CollapseContainer";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import QRCode from "qrcode.react";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import axios from "axios";
import ImagePicker from "src/components/forms/ImagePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPlus } from "@fortawesome/free-solid-svg-icons";
import InfoText from "src/components/InfoText";
import FlatIcon from "src/components/FlatIcon";
import ResponseDetails from "src/pho/components/ReponseDetails";
import TabGroup from "src/components/TabGroup";
import ActionBtn from "src/components/ActionBtn";
import Img from "src/components/Img";
import CardContainer from "src/components/CardContainer";
import PatientChartsHistory from "./PatientChartsHistory";
import { Fade } from "react-reveal";
import ClinicProfileVitals from "src/clinic/components/ClinicProfileVitals";
import ProfileConsultations from "src/cis/components/patient-profile/consultations/ProfileConsultations";
import CisVitalsChart from "./vitalsChart/CisVitalsChart";
import CisAppointments from "./appointment/CisAppointments";
import CisPatientHistory from "./history/CisPatientHistory";
import CisPatientHealth from "./health/CisPatientHealth";
import CisPatientLaboratory from "./laboratory/CisPatientLaboratory";
import CisPatientImaging from "./imaging/CisPatientsImaging";
import PrescriptionMainTab from "./patient-profile/prescriptions/PrescriptionMainTab";
import CisNotes from "./notes/CisNotes";
import CisTreatmentPlan from "./treatmentPlan/CisTreatmentPlan";
import MedicalCertificateList from "./patient-profile/medical-certificate/MedicalCertificateList";
import CisPatientsForm from "./profile/CisPatientsForm";
import ClinicProfileVitalsForm from "src/clinic/components/ClinicProfileVitalsForm";
import ButtonV3 from "src/components/ButtonV3";
import AddModal from "src/cis-laboratory/utils/AddModal";
import AddOR from "./charts/AddOR";
import Modal from "./charts/Modal";
import ClinicUpdateVitalsModal from "src/clinic/components/ClinicUpdateVitalsModal";
import PatientProfileContent from "./PatientProfileContent";
import ClinicDiagnosis from "src/clinic/components/ClinicDiagnosis";
// import AddContactTracingModal from "./AddContactTracingModal";
import { v4 as uuidv4 } from "uuid";
import CisLaboratoryListXray from "./laboratory/laboratoryList/CisLaboratoryListXray";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";
const uniqKey = uuidv4();
const MenuTitle = ({ src, children, className }) => {
	return (
		<div
			className={`flex items-center justify-center gap-2 px-2 p-1 py-1 max-w-[144px] ${
				className || ""
			}`}
		>
			<img src={src} className="h-[24px] w-8 object-contain" />
			<span className="text-xs font-semibold whitespace-nowrap">
				{children}
			</span>
		</div>
	);
};

const ViewPatientProfile = ({
	patient,
	setPatient,
	editable = true,
	className = "",
	profileDisplayClassName,
	add_patient_ref,
}) => {
	const { users } = useContext(UsersData);
	const [rooms, setRooms] = useState([]);
	const [selectedRoom, setSelectedRoom] = useState(null);
	const [addOR, setAddOR] = useState(false);
	const [uniqK, setUniqK] = useState(uniqKey);
	const clinicUpdateVitalsref = useRef(null);

	const saveAvatar = (avatar) => {
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
			// onUploadProgress: progressEvent => onProgress(progressEvent),
		};
		console.log("avataravatar", avatar);
		const form = new FormData();
		const file = dataURItoBlob(avatar);
		form.append("_method", "PATCH");
		form.append("avatar", file);

		axios.post(`v1/patient-profile/${patient?.id}`, form, config);
	};

	const PrintDiv = () => {
		/* html2canvas(document.getElementById("qr-gen"), {
			allowTaint: true,
			useCORS: true,
			logging: false,
		}).then(function (canvas) {
			var link = document.createElement("a");
			document.body.appendChild(link);
			link.download = `${patient.lastname}_${patient.firstname}.png`;
			console.log("PrintDiv canvas", canvas);
			link.href = canvas.toDataURL();
			link.target = "_blank";
			link.click();
		}); */
		html2canvas(document.getElementById("qr-gen"), {
			allowTaint: false,
			useCORS: true,
		}).then(function (canvas) {
			var anchorTag = document.createElement("a");
			document.body.appendChild(anchorTag);
			// document.getElementById("previewImg").appendChild(canvas);
			anchorTag.download = `${patient.lastname}_${patient.firstname}.jpg`;
			anchorTag.href = canvas.toDataURL();
			anchorTag.target = "_blank";
			anchorTag.click();
		});
	};

	const getOperatingRooms = () => {
		axios.get(`/v1/operating-rooms/list`).then((res) => {
			setRooms(res.data.data);
		});
	};
	useEffect(() => {
		getOperatingRooms();
		if (patient?.id) {
			getBase64Avatar();
		}
	}, []);
	const getBase64Avatar = () => {
		axios.get(`v1/base64-patient/${patient?.id}`).then((res) => {
			console.log("resss", res.data);
			setPatient({
				...patient,
				avatar:
					res.data.image?.length > 0
						? "data:image/png;base64," + res.data.image
						: "",
				avatarBase64:
					res.data.image?.length > 0
						? "data:image/png;base64," + res.data.image
						: "",
			});
		});
	};

	const handleOpenPopup = (id) => {
		if (id === "add_OR") {
			setAddOR(true);
		}
	};
	const handleClosePopup = () => {
		setUniqK(uuidv4());
		setAddOR(false);
	};
	const refreshPageData = () => {
		setUniqK(uuidv4());
	};
	return (
		<>
			<CardContainer className={`!p-0 rounded-xl ${className}`}>
				<div className="flex lg:grid lg:grid-cols-12 lx:grid-cols-12 flex-col gap-4 pb-4 z-20 bg-white">
					<div className="col-span-12 w-full">
						<div className="flex flex-col lg:flex-row gap-4 items-center px-4 pt-2 border-b justify-center md:justify-start pb-4">
							<div className="group relative h-32 w-32 min-h-[128px] min-w-[128px] rounded-full aspect-square bg-background">
								<Img
									type="user"
									name={`${patient?.lastname}-${patient?.firstname}-${patient?.middle}`}
									src={patient?.avatar || ""}
									className="min-h-[128px] min-w-[128px] aspect-square object-cover rounded-full"
									alt=""
									id="user-image-sample"
									key={`key-${patient?.id}-${patient?.avatar}`}
								/>

								<ImagePicker
									className="absolute -bottom-0 -right-0 w-8 h-8 bg-primary bg-opacity-40 group-hover:bg-primary group-hover:bg-opacity-100 duration-200 rounded-full flex items-center justify-center"
									onChange={(data) => {
										setPatient({
											...patient,
											avatar: data,
										});
										if (isBase64(data)) {
											saveAvatar(data);
										}
									}}
								>
									{() => {
										return (
											<FontAwesomeIcon
												title="Click to upload camera"
												icon={faCamera}
												color="white"
												size={"lg"}
											/>
										);
									}}
								</ImagePicker>
							</div>
							<div className="flex flex-col pl-4">
								<h6
									className={`text-left text-2xl mb-1 font-semibold flex items-center ${
										patient?.gender == "Male"
											? "text-blue-800"
											: "text-pink-800"
									} mb-0`}
								>
									{`${patient?.lastname}, ${
										patient?.firstname
									} ${patient?.middle || ""}`}
								</h6>
								<div className="flex gap-6 mb-2">
									<div className="flex items-center gap-2 text-base">
										<FlatIcon
											icon="rr-calendar-clock"
											className="text-lg"
										/>
										<span>
											{calculateAge(patient?.birthday)}{" "}
											yrs. old
										</span>
									</div>
									<div className="flex items-center gap-2 text-base">
										<FlatIcon
											icon="rr-calendar"
											className="text-lg"
										/>
										<span>
											{formatDate(patient?.birthday)}
										</span>
									</div>
								</div>
								<div className="flex gap-4 mb-2">
									<div className="flex items-center gap-2 text-base">
										<FlatIcon
											icon="rr-venus-mars"
											className="text-lg"
										/>
										{patient?.gender == "Male" ? (
											<span className="text-blue-700">
												Male
											</span>
										) : (
											<span className="text-pink-700">
												Female
											</span>
										)}
									</div>
								</div>
							</div>
							<div className="flex flex-col gap-y-1 ml-auto">
								<QRCode
									value={JSON.stringify({
										patient_id: patient?.patient_id,
										user_id: users?.user_id,
										philhealth: "philhealth",
									})}
									level="H"
									size={108}
								/>
								<ActionBtn size="xs" onClick={PrintDiv}>
									Download QR
								</ActionBtn>
							</div>
						</div>
						<TabGroup
							tabClassName={`py-3 bg-slate-100 border-b`}
							contents={[
								{
									title: (
										<MenuTitle src="/patient.png">
											Profile
										</MenuTitle>
									),
									// (
									// 	<div className="flex flex-col items-center justify-center gap-2 p-1 py-2 w-[108px] bg-white">
									// 		<FlatIcon
									// 			icon="rr-mode-portrait"
									// 			className="mr-1 text-2xl"
									// 		/>
									// 		Profile
									// 	</div>
									// ),
									content: (
										<PatientProfileContent
											patient={patient}
											setPatient={setPatient}
										/>
									),
								},

								{
									title: (
										<MenuTitle src="/timeline-2.png">
											Charts
										</MenuTitle>
									),

									contentClassName: "p-0",
									content: (
										<>
											<div className="flex flex-col w-full mb-3">
												<h5 className="text-sm font-semibold text-indigo-700 w-2/3 flex items-center gap-2">
													<span>
														<img
															src="/timeline-2.png"
															className="h-8 object-contain"
														/>
													</span>
													<div>Charts</div>
												</h5>
												<div className="w-2/3 h-[1px] bg-indigo-600 mb-[2px]"></div>
												<div className="w-1/2 h-[1px] bg-indigo-400"></div>
											</div>

											<div className="flex flex-col gap-y-2">
												<div className="flex items-center gap-7">
													<h5 className="font-bold text-lg pt-2">
														Operating Room
													</h5>

													<ButtonV3
														type="primary"
														onClick={() =>
															handleOpenPopup(
																"add_OR"
															)
														}
													>
														<div className="flex items-center">
															<FontAwesomeIcon
																icon={faPlus}
																color="primary"
																className="pr-2"
															/>
															Add
														</div>
													</ButtonV3>
													<Modal
														title={
															<h3 className="font-bold text-lg pt-2">
																Add OR
															</h3>
														}
														open={addOR}
														onClose={
															handleClosePopup
														}
														content={
															<AddOR
																close={
																	handleClosePopup
																}
																patient={
																	patient
																}
															/>
														}
													/>
												</div>
												{/* <div className="w-1/4">
													<ReactSelectInputField
														value={selectedRoom}
														onChange={(data) => {
															setSelectedRoom(
																data
															);
														}}
														label="Select Operating Room"
														options={rooms?.map(
															(room) => ({
																label: `${room?.name} - [${room?.type}]`,
																value: room?.id,
															})
														)}
													/>
												</div> */}
												{/* {selectedRoom ? (
													
												) : (
													<p className="text-slate-200">
														Please select room
														number
													</p>
												)} */}
												<Fade>
													<PatientChartsHistory
														type="OR"
														key={`pch-${uniqK}-${selectedRoom}`}
														patient_id={patient?.id}
													/>
												</Fade>
											</div>
										</>
									),
								},
								{
									title: (
										<MenuTitle
											src="/vitals/vitals.png"
											className={" bg-white"}
										>
											Vital signs
										</MenuTitle>
									),
									content: (
										<div className="flex flex-col w-full pb-2 relative">
											<div className="flex flex-col w-full mb-3">
												<h5 className="relative text-sm font-semibold text-indigo-700 w-2/3 flex items-center gap-2">
													<span>
														<img
															src="/vitals/vitals.png"
															className="h-8 object-contain"
														/>
													</span>
													<div>Vital Signs</div>
													<ActionBtn
														size="sm"
														type="accent"
														className="absolute right-0 ml-auto"
														onClick={() => {
															clinicUpdateVitalsref.current.show();
														}}
													>
														<FlatIcon icon="rr-edit" />
														Update vitals
													</ActionBtn>
												</h5>
												<div className="w-2/3 h-[1px] bg-indigo-600 mb-[2px]"></div>
												<div className="w-1/2 h-[1px] bg-indigo-400"></div>
											</div>

											{patient?.id && (
												<ClinicProfileVitals
													key={`cpv-${uniqKey}`}
													updateVitals={() => {
														clinicUpdateVitalsref.current.show(
															null,
															{
																fn: refreshPageData,
															}
														);
													}}
													patient={patient}
												/>
											)}
										</div>
									),
								},

								{
									title: (
										<MenuTitle
											src="/vitals/vitals-2.png"
											className={" bg-white"}
										>
											Vital charts
										</MenuTitle>
									),
									content: (
										<>
											<div className="flex flex-col w-full mb-3">
												<h5 className="text-sm font-semibold text-indigo-700 w-2/3 flex items-center gap-2">
													<span>
														<img
															src="/vitals/vitals-2.png"
															className="h-8 object-contain"
														/>
													</span>
													<div>Vital Charts</div>
												</h5>
												<div className="w-2/3 h-[1px] bg-indigo-600 mb-[2px]"></div>
												<div className="w-1/2 h-[1px] bg-indigo-400"></div>
											</div>
											<CisVitalsChart patient={patient} />
										</>
									),
								},
								// {
								// 	title: (
								// 		<MenuTitle
								// 			src="/vitals/appointment.png"
								// 			className={" bg-white"}
								// 		>
								// 			Appointments
								// 		</MenuTitle>
								// 	),
								// 	content: <CisAppointments />,
								// },

								{
									title: (
										<MenuTitle
											src="/vitals/history.png"
											className={" bg-white"}
										>
											Patient Medical History
										</MenuTitle>
									),
									content: (
										// <CisPatientHistory patient={patient} />
										<ClinicDiagnosis patient={patient} />
									),
								},

								{
									title: (
										<MenuTitle src="/vitals/laboratory.png">
											Laboratory
										</MenuTitle>
									),
									content: (
										<CisPatientLaboratory
											pageIcon="/vitals/laboratory.png"
											pageTitle={"Laboratory List"}
											labType="laboratory-test"
											patient={patient}
										/>
									),
								},
								{
									title: (
										<MenuTitle src="/landing-page.png">
											Imaging
										</MenuTitle>
									),
									content: (
										<>
											<CisPatientLaboratory
												pageIcon="/landing-page.png"
												pageTitle={"Imaging"}
												labType="imaging"
												patient={patient}
											/>
										</>
									),
								},
								{
									title: (
										<MenuTitle src="/vitals/prescription.png">
											Prescription
										</MenuTitle>
									),
									content: ({ setSelectedIndex }) => (
										<div className="px-3 pt-2">
											<PrescriptionMainTab
												patient={patient}
											/>
										</div>
									),
								},
								{
									title: (
										<MenuTitle src="/vitals/notes.png">
											Notes
										</MenuTitle>
									),
									content: <CisNotes patient={patient} />,
								},
								{
									title: (
										<MenuTitle
											src="/vitals/treatment.png"
											className={`w-[144px]`}
										>
											Treatment Plan
										</MenuTitle>
									),
									content: (
										<CisTreatmentPlan patient={patient} />
									),
								},
								// {
								// 	title: (
								// 		<MenuTitle src="/vitals/certification.png">
								// 			Med Cert
								// 		</MenuTitle>
								// 	),
								// 	content: (
								// 		<MedicalCertificateList
								// 			patient={patient}
								// 		/>
								// 	),
								// },
								// {
								// 	title: (
								// 		<MenuTitle src="/vitals/form.png">
								// 			Profile form
								// 		</MenuTitle>
								// 	),
								// 	content: (
								// 		<CisPatientsForm patient={patient} />
								// 	),
								// },
								// {
								// 	title: (
								// 		<MenuTitle src="/vitals/consulting.png">
								// 			Consultations
								// 		</MenuTitle>
								// 	),
								// 	content: ({ setSelectedIndex }) => (
								// 		<div className="px-3 pt-2">
								// 			<ProfileConsultations
								// 				setSelectedIndex={
								// 					setSelectedIndex
								// 				}
								// 				patient={patient}
								// 			/>
								// 		</div>
								// 	),
								// },

								/*
								{
									title: (
										<>
											<FlatIcon icon="rr-running" className="mr-1" />
											Walk-In additional
										</>
									),
									content: <WalkInPatient />,
								}, */
							]}
						/>
					</div>
				</div>
			</CardContainer>
			<ClinicUpdateVitalsModal
				patient={patient}
				ref={clinicUpdateVitalsref}
			/>
		</>
	);
};

export default ViewPatientProfile;
