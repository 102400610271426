import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import Clock from "react-live-clock";
import axios from "axios";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ActionBtn from "src/components/ActionBtn";
import ButtonV3 from "src/components/ButtonV3";
import SelectInputField from "src/components/forms/SelectInputField";
import useDataTable from "src/helpers/useDataTable";
import {
	dataURItoBlob,
	dateMMDDYYYYHHIIA,
	formatDateMMDDYYYYHHIIA,
} from "src/helpers/utils";
import CreatePrescriptionModal from "./CreatePrescriptionModal";
import { UsersData } from "src/ContextAPI";
import ReleasePrescriptionModal from "./ReleasePrescriptionModal";
import TextAreaField from "src/components/forms/TextAreaField";
import FlatIcon from "src/components/FlatIcon";
import { v4 as uuidv4 } from "uuid";
import CreateBhsServiceModal from "./CreateBhsServiceModal";
import PrivacyStatementModal from "./PrivacyStatementModal";
import ConfirmTBModal from "./ConfirmTBModal";
import CreateAppointmentModal from "./CreateAppointmentModal";
import AppointmentCreateVitalsModal from "./AppointmentCreateVitalsModal";
import BhsPatientVitals from "./BhsPatientVitals";
import ReferToSPHModal from "./ReferToSPHModal";
import ReferToSPHDoctorModal from "./ReferToSPHDoctorModal";
import ApproveReleaseMedicineModal from "./ApproveReleaseMedicineModal";
import AcceptPatientModal from "./AcceptPatientModal";
import ReferToRhuModal from "./ReferToRhuModal";
import CreateLabOrderModal from "./CreateLabOrderModal";
import AddXrayResultModal from "./AddXrayResultModal";
import AddLabResultModal from "./AddLabResultModal";
import AddLabResultReadingModal from "./AddLabResultReadingModal";
import ReferToSPHFormModal from "./ReferToSPHFormModal";
import SPHPatientBillingModal from "./SPHPatientBillingModal";
import PatientBillCashierModal from "./PatientBillCashierModal";
import TableV2 from "src/components/table/TableV2";
import ViewAppointmentModal from "./ViewAppointmentModal";
const modalKey = uuidv4();
const symptoms = [
	{
		value: "cough_for_3_weeks_or_longer",
		label: "Cough for three weeks or longer",
	},
	{
		value: "coughing_up_blood_or_mucus",
		label: "Coughing up blood or mucus",
	},
	{ value: "chest_pain", label: "Chest pain" },
	{
		value: "pain_with_breathing_or_coughing",
		label: "Pain with breathing or coughing",
	},
	{ value: "fever", label: "Fever" },
	{ value: "chills", label: "Chills" },
	{ value: "night_sweats", label: "Night sweats" },
	{ value: "weight_loss", label: "Weight loss" },
	{ value: "not_wanting_to_eat", label: "Not wanting to eat" },
	{ value: "tiredness", label: "Tiredness" },
	{
		value: "not_feeling_well_in_general",
		label: "Not feeling well in general",
	},
];
const RenderAppointmentStatus = ({ vitals, prescriptions, status = "" }) => {
	// pending
	// pending-for-doctor-
	switch (status) {
		case "pending":
			return vitals == null ? (
				<span className="text-orange-500 font-bold">
					Pending for get vitals
				</span>
			) : (
				<span className="text-orange-500 font-bold">
					Pending for service
				</span>
			);

		case "pending-doctor-consultation":
			return (
				<span className="text-orange-600 font-bold">
					Pending for doctor consultation
				</span>
			);
		case "pending-for-bhw-release":
			return (
				<span className="text-orange-600 font-bold">
					Pending for medicine release
				</span>
			);
		case "pending-doctor-confirmation":
			return (
				<span className="text-orange-600 font-bold">
					pending for doctor's confirmation
				</span>
			);
		case "pending-for-pharmacy-release":
			return prescriptions?.length == 0 ? (
				<span className="text-orange-600 font-bold">
					pending for doctor's prescription
				</span>
			) : (
				<span className="text-orange-500 font-bold">
					pending for pharmacy release
				</span>
			);

		default:
			return status;
	}
};
const Appointments = ({
	skipPrivacyStatement = false,
	disableNewAppointment,
	openAppointmentForm,
	patient,
}) => {
	// console.log("patientpatientpatient", patient);
	const history = useHistory();
	const { users } = useContext(UsersData);
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const {
		data,
		setPage,
		loading,
		setPaginate,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
		keyword,
		setKeyword,
		addToList,
	} = useDataTable(`v1/clinic/appointments/${patient?.id}`, null, {});

	const [list, setList] = useState([]);
	const [selected, setSelected] = useState(null);
	const [modalKeys, setModalKeys] = useState(`${modalKey}`);
	const [newAppointment, setNewAppointment] = useState(false);
	const [patientSelfie, setPatientSelfie] = useState(null);
	const prescriptionRef = useRef(null);
	const releasePrescriptionRef = useRef(null);
	const serviceModal = useRef(null);
	const viewPrivacyRef = useRef(null);
	const confirmTB = useRef(null);
	const createAppointmentRef = useRef(null);
	const appointmentVitalsRef = useRef(null);
	const referToSPH = useRef(null);
	const referToSPHDoc = useRef(null);
	const approveReleasePrescriptionRef = useRef(null);
	const acceptPatientRef = useRef(null);
	const referToRHURef = useRef(null);
	const labOrderRef = useRef(null);
	const xRayResultRef = useRef(null);
	const labResultRef = useRef(null);
	const referToSPHFormRef = useRef(null);
	const labReading = useRef(null);
	const patientBillingRef = useRef(null);
	const patientBillModalRef = useRef(null);
	const viewAppointmentRef = useRef(null);

	useEffect(() => {
		let t = setTimeout(() => {
			if (openAppointmentForm) {
				createAppointmentRef.current.show(data);
			}
		}, 300);
		return () => {
			clearTimeout(t);
		};
	}, [openAppointmentForm]);
	useEffect(() => {
		// setFilters((prevFilters) => ({
		// 	...prevFilters,
		// 	// clinic_id: 1,
		// }));
		// setPaginate(10);
	}, []);
	useEffect(() => {
		setList(data?.data);
	}, [data]);

	const columns = useMemo(
		() => [
			{
				header: "Info",
				accessorKey: "date",
				headerClassName: "text-center",
				className: "text-left",
				cell: (data) => {
					return (
						<div className="flex flex-col pt-2">
							<div className="flex items-center gap-2 mb-3">
								<span className="font-semibold text-sm flex items-center gap-1">
									<FlatIcon
										icon="rr-calendar"
										className="-mt-[2px]"
									/>
									Date:
								</span>
								<span className="">
									{formatDateMMDDYYYYHHIIA(
										new Date(data.created_at)
									)}
								</span>
							</div>
							<span className="font-semibold text-sm flex items-center gap-1">
								<FlatIcon
									icon="rr-pen-field"
									className="-mt-[2px]"
								/>
								Chief complaint:
							</span>
							<span className=" py-1 mb-3">{data.pre_notes}</span>
						</div>
					);
				},
			},
			{
				header: "Status",
				accessorKey: "status",
				headerClassName: "text-center",
				className: "text-left",
				cell: (data) => {
					return <RenderAppointmentStatus {...data} />;
				},
			},
			{
				header: "Initial Diagnosis",
				accessorKey: "status",
				headerClassName: "text-center",
				className: "text-left",
				cell: (data) => {
					return data.post_notes || "-";
				},
			},
			{
				header: "Action",
				accessorKey: "action",
				className: "w-[244px]",
				cell: (data) => {
					return (
						<div className="flex items-center gap-2">
							{data?.vitals == null ? (
								<ActionBtn
									size="sm"
									className="px-2"
									type="accent"
									onClick={() => {
										appointmentVitalsRef.current.show(data);
									}}
								>
									<FlatIcon icon="rr-upload" /> Update Vitals
								</ActionBtn>
							) : (
								""
							)}
							<ActionBtn
								size="sm"
								type="primary"
								className="px-2"
								onClick={() => {
									viewAppointmentRef.current.show(data);
								}}
							>
								<FlatIcon
									icon="rr-eye"
									className="text-base mr-1"
								/>
								View
							</ActionBtn>
						</div>
					);
				},
			},
		],
		[]
	);

	return (
		<div className="bg-red- p-0">
			<div className=" flex-col relative pb-11">
				<div className="flex items-center mb-3">
					<h5 className="font-bold mb-0 mr-4 text-tertiary">
						Appointment records{" "}
					</h5>
					{newAppointment || disableNewAppointment
						? ""
						: (users?.type == "BHS-BHW" ||
								users?.type == "RHU-NURSE") && (
								<ActionBtn
									size="sm"
									onClick={() => {
										// createAppointmentRef.current.show(data);
										if (skipPrivacyStatement) {
											// appointmentVitalsRef.current.show(null);
											createAppointmentRef.current.show(
												data
											);
										} else {
											viewPrivacyRef.current.show();
										}
									}}
								>
									<FlatIcon icon="rr-plus" /> Create new
									appointment
								</ActionBtn>
						  )}
				</div>
				<div>
					<TableV2
						paginationClassName="px-4"
						columns={columns}
						pagination={true}
						loading={loading}
						data={data?.data}
						onTableChange={(data) => {}}
					/>
				</div>
				<span className="mr-auto h-full absolute border-l" />
				{list?.map((x) => {
					return (
						<div
							className="flex flex-col gap-y-1 bg-white border  shadow-sm p-3 rounded-xl ml-3 mb-5 relative"
							key={`appsmnts-${x?.id}`}
						>
							<span className="absolute z-20 rounded-full bg-yellow-500 h-[8px] w-[8px] -left-[20px] top-[28px]" />
							<div className="flex items-center mb-2">
								<span className="text-sm font-bold mr-6 !text-black">
									{formatDateMMDDYYYYHHIIA(
										new Date(x.created_at)
									)}{" "}
								</span>
								<div className="flex text-sm items-center mr-auto">
									<span className="font-bold text-sm mr-0">
										Status:
									</span>
									<span className="ml-2 px-1 py-0 border-b-2 font-bold">
										<RenderAppointmentStatus {...x} />
										{/* {x?.status} */}
										{/* {x?.is_done
											? x?.is_tb_positive
												? "TB Positive"
												: "TB Negative"
											: x?.is_tb_positive
											? "TB Positive"
											: x?.serviced_by
											? "Pending for confirmation"
											: "Pending for service"} */}
									</span>

									{users?.type?.includes("SPH") ? (
										""
									) : x?.status == "confirmed" ? (
										x?.is_tb_positive ? (
											<span className=" px-2 py-0 font-semibold ml-2 border-l-4">
												Pending for PHARMACIST
											</span>
										) : (
											""
										)
									) : (
										""
									)}
									{x?.status == "done" ? (
										x?.is_tb_positive ? (
											<span className=" px-2 py-0 text-green-700 font-bold ml-2 border-l-4">
												Medicine released
											</span>
										) : (
											""
										)
									) : (
										""
									)}
								</div>
								{users?.type == "RHU-NURSE" &&
								x?.status == "pending-for-acceptance" ? (
									<ActionBtn
										size="sm"
										type="accent"
										onClick={() => {
											acceptPatientRef.current.show(x);
										}}
									>
										<FlatIcon icon="rr-checkbox" /> Accept
										patient
									</ActionBtn>
								) : (
									""
								)}
								{users?.type == "SPH-BILLING" &&
								x?.status == "pending-for-billing" ? (
									<ActionBtn
										size="sm"
										type="accent"
										onClick={() => {
											patientBillingRef.current.show(x);
										}}
									>
										<FlatIcon icon="rr-receipt" /> Patient
										Billing
									</ActionBtn>
								) : (
									""
								)}
								{users?.type == "SPH-CASHIER" &&
								x?.status == "pending-for-cashier" ? (
									<ActionBtn
										size="sm"
										type="accent"
										onClick={() => {
											patientBillModalRef.current.show(x);
										}}
									>
										<FlatIcon icon="rr-print" /> Print
										Patient Bill
									</ActionBtn>
								) : (
									""
								)}

								{users?.type == "BHS-BHW" ? (
									<>
										{x?.vitals == null ? (
											<ActionBtn
												size="sm"
												type="accent"
												onClick={() => {
													appointmentVitalsRef.current.show(
														x
													);
												}}
											>
												<FlatIcon icon="rr-refresh" />{" "}
												Update Vitals
											</ActionBtn>
										) : (
											""
										)}
										{x?.vitals != null &&
										x?.serviced_by == null ? (
											<>
												<ActionBtn
													size="sm"
													className="ml-2"
													onClick={() => {
														serviceModal.current.show(
															x
														);
													}}
												>
													<FlatIcon icon="rr-plus" />{" "}
													Add service
												</ActionBtn>
												<ActionBtn
													size="sm"
													type="danger"
													className="ml-2"
													onClick={() => {
														referToRHURef.current.show(
															x
														);
													}}
												>
													<FlatIcon icon="rr-paper-plane" />{" "}
													Refer to RHU
												</ActionBtn>
											</>
										) : (
											""
										)}
									</>
								) : (
									""
								)}
								{users?.type == "RHU-NURSE" ? (
									<>
										{x?.vitals == null ? (
											<ActionBtn
												size="sm"
												type="accent"
												onClick={() => {
													appointmentVitalsRef.current.show(
														x
													);
												}}
											>
												<FlatIcon icon="rr-refresh" />{" "}
												Update Vitals
											</ActionBtn>
										) : (
											""
										)}
										{x?.vitals != null &&
										x?.serviced_by == null ? (
											<>
												<ActionBtn
													size="sm"
													className="ml-2"
													onClick={() => {
														serviceModal.current.show(
															x
														);
													}}
												>
													<FlatIcon icon="rr-plus" />{" "}
													Add service
												</ActionBtn>
												<ActionBtn
													size="sm"
													type="danger"
													className="ml-2"
													onClick={() => {
														referToSPHFormRef.current.show(
															x
														);
													}}
												>
													<FlatIcon icon="rr-paper-plane" />{" "}
													Refer to SPH
												</ActionBtn>
											</>
										) : (
											""
										)}
									</>
								) : (
									""
								)}

								{x?.for_sph && users?.type == "SPH-NURSE" ? (
									x?.sph_referred_to ? (
										""
									) : (
										<>
											<ActionBtn
												size="sm"
												className="mr-2"
												onClick={() => {
													appointmentVitalsRef.current.show(
														x
													);
												}}
											>
												<FlatIcon icon="rr-refresh" />{" "}
												Update Vitals
											</ActionBtn>
											<ActionBtn
												size="sm"
												type="accent"
												onClick={() => {
													referToSPHDoc.current.show(
														x
													);
												}}
											>
												<FlatIcon icon="rr-share-square" />{" "}
												Refer to SPH Doctor
											</ActionBtn>
										</>
									)
								) : (
									""
								)}
								{x?.for_sph ? (
									users?.type == "SPH-DOCTOR" && (
										<>
											{x?.status ==
												"pending-doctor-consultation" ||
											x?.status == "pending-for-xray" ||
											x?.status ==
												"pending-for-lab-order" ? (
												<>
													<ActionBtn
														size="sm"
														className="mr-2"
														onClick={() => {
															labOrderRef.current.show(
																x
															);
														}}
													>
														<FlatIcon icon="rr-plus-hexagon" />
														Create order
													</ActionBtn>
												</>
											) : (
												""
											)}
											{x?.status ==
											"pending-for-doctor-read-lab-result" ? (
												<>
													<ActionBtn
														size="sm"
														className="mr-2"
														onClick={() => {
															prescriptionRef.current.show(
																x
															);
														}}
													>
														<FlatIcon icon="rr-plus-hexagon" />
														Add prescription
													</ActionBtn>
												</>
											) : (
												""
											)}
										</>
									)
								) : users?.type == "RHU-DOCTOR" ? (
									<>
										{x?.prescriptions?.length > 0 ? (
											""
										) : (
											<>
												{x?.status ==
												"pending-for-pharmacy-release" ? (
													<>
														<ActionBtn
															size="sm"
															className="mr-2"
															onClick={() => {
																prescriptionRef.current.show(
																	x,
																	"bhs"
																);
															}}
														>
															<FlatIcon icon="rr-plus-hexagon" />
															Add prescription
														</ActionBtn>
														{/* <ActionBtn
															size="sm"
															type="danger"
															onClick={() => {
																referToSPH.current.show(
																	x
																);
															}}
														>
															<FlatIcon icon="rr-share-square" />
															Refer patient to SPH
														</ActionBtn> */}
													</>
												) : x?.status ==
												  "pending-doctor-confirmation" ? (
													<ActionBtn
														size="sm"
														className="mr-3"
														type="accent"
														onClick={() => {
															confirmTB.current.show(
																x,
																"bhs"
															);
															// setNewAppointment(true);
														}}
													>
														<FlatIcon icon="rr-check" />{" "}
														Confirm TB diagnosis
													</ActionBtn>
												) : (
													""
												)}
											</>
										)}
										{x?.status ==
										"pending-doctor-consultation" ? (
											<ActionBtn
												size="sm"
												className="mr-2"
												type="accent"
												onClick={() => {
													prescriptionRef.current.show(
														x
													);
												}}
											>
												<FlatIcon icon="rr-plus-hexagon" />
												Add prescription
											</ActionBtn>
										) : (
											""
										)}
										{x?.status ==
											"pending-doctor-consultation" ||
										x?.status == "pending-for-xray" ||
										x?.status == "pending-for-lab-order" ? (
											<ActionBtn
												size="sm"
												className="mr-2"
												onClick={() => {
													labOrderRef.current.show(x);
												}}
											>
												<FlatIcon icon="rr-plus-hexagon" />
												Create order
											</ActionBtn>
										) : (
											""
										)}
										{/* 
										<ActionBtn
												size="sm"
												className="mr-2"
												onClick={() => {
													labReading.current.show(x);
												}}
											>
												<FlatIcon icon="rr-plus-hexagon" />
												Update Laboratory Result Reading
											</ActionBtn> */}
										{x?.status ==
										"pending-for-doctor-read-lab-result" ? (
											<ActionBtn
												size="sm"
												className="mr-2"
												onClick={() => {
													prescriptionRef.current.show(
														x
													);
												}}
											>
												<FlatIcon icon="rr-plus-hexagon" />
												Add prescription
											</ActionBtn>
										) : (
											""
										)}
									</>
								) : (
									""
								)}
								{x?.status ==
									"pending-for-pharmacy-medicine-release" &&
								(users?.type == "RHU-PHARMACY" ||
									users?.type == "SPH-PHAR") ? (
									<ActionBtn
										type="success"
										size="sm"
										onClick={() => {
											releasePrescriptionRef.current.show(
												x
											);
										}}
									>
										<FlatIcon icon="rr-clipboard-list" />
										Process Prescription
									</ActionBtn>
								) : (
									""
								)}
								{x?.for_sph ? (
									x?.status == "confirmed" &&
									users?.type == "SPH-PHAR" && (
										<ActionBtn
											type="success"
											size="sm"
											onClick={() => {
												releasePrescriptionRef.current.show(
													x
												);
											}}
										>
											<FlatIcon icon="rr-clipboard-list" />
											Process Prescription
										</ActionBtn>
									)
								) : x?.status == "confirmed" &&
								  users?.type == "RHU-PHARMACY" ? (
									<ActionBtn
										type="success"
										size="sm"
										onClick={() => {
											releasePrescriptionRef.current.show(
												x
											);
										}}
									>
										<FlatIcon icon="rr-clipboard-list" />
										Process Prescription
									</ActionBtn>
								) : (
									""
								)}
								{x?.status == "pending-for-pharmacy-release" &&
								x?.prescriptions?.length > 0 &&
								users?.type == "RHU-PHARMACY" ? (
									<ActionBtn
										type="success"
										size="sm"
										onClick={() => {
											approveReleasePrescriptionRef.current.show(
												x,
												"bhs"
											);
										}}
									>
										<FlatIcon icon="rr-list-check" />
										Approve release of medication
									</ActionBtn>
								) : (
									""
								)}
								{x?.status == "pending-for-bhw-release" &&
								x?.prescriptions?.length > 0 &&
								users?.type == "BHS-BHW" ? (
									<ActionBtn
										className="ml-4"
										type="success"
										size="sm"
										onClick={() => {
											releasePrescriptionRef.current.show(
												x
											);
										}}
									>
										<FlatIcon icon="rr-list-check" />
										Release medication
									</ActionBtn>
								) : (
									""
								)}
								{(users?.type == "RHU-LAB" ||
									users?.type == "SPH-LAB") &&
								x?.for_lab == 1 ? (
									<ActionBtn
										className="ml-4"
										size="sm"
										onClick={() => {
											labResultRef.current.show(x);
										}}
									>
										<FlatIcon icon="rr-tubes" />
										Add lab result
									</ActionBtn>
								) : (
									""
								)}
								{(users?.type == "RHU-XRAY" ||
									users?.type == "SPH-XRAY") &&
								x?.for_xray == 1 ? (
									<ActionBtn
										className="ml-4"
										size="sm"
										onClick={() => {
											xRayResultRef.current.show(x);
										}}
									>
										<FlatIcon icon="rr-x-ray" />
										Add x-ray result
									</ActionBtn>
								) : (
									""
								)}
							</div>

							<div className="flex items-center">
								<span className="font-bold text-sm mr-1">
									{x?.is_done
										? "Diagnosis"
										: "Initial Diagnosis"}
									:
								</span>
								<span className="font-semibold capitalize text-blue-700 px-0 py-0 rounded-lg">
									{x.post_notes}
								</span>
							</div>

							<span className="font-bold text-sm">
								Chief complaint:
							</span>
							<span className="border-l-4 pl-2 min-h-[56px] py-1 mb-3">
								{x.pre_notes}
							</span>
							<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
								{x?.patient_selfie ? (
									<div className="w-full mt-3 h-full">
										<span className="text-sm mb-2 font-bold">
											Patient Selfie
										</span>
										<div className="w-full relative">
											<div className="aspect-[3/2] w-full" />
											<img
												src={x?.patient_selfie}
												className="bg-black object-contain absolute top-0 left-0 h-full w-full"
											/>
										</div>
									</div>
								) : (
									""
								)}
								{x?.tb_symptoms != null ? (
									<div className="flex flex-col gap-1 mt-3 !shadow-yellow-600 rounded-sm bg-white">
										<span className="font-bold text-sm">
											Symptoms
										</span>

										<ul>
											{symptoms?.map((symp) => {
												return (
													<li className="!text-xs flex justify-between">
														<span>
															{symp.label} -{" "}
														</span>
														<b className="text-center">
															{x?.tb_symptoms[
																symp.value
															]
																? "YES"
																: "no "}
														</b>
													</li>
												);
											})}
										</ul>
									</div>
								) : (
									""
								)}

								{x.prescriptions?.length > 0 ? (
									<div className="flex flex-col gap-1 mt-3 shadow-sm !shadow-yellow-600 rounded-sm p-2 bg-slate-50">
										<span className="font-bold text-sm">
											Prescriptions
										</span>

										<ul
											className="pl-3"
											style={{ listStyleType: "disc" }}
										>
											{x.prescriptions?.map(
												(prescription) => {
													return (
														<li className="!text-xs flex flex-col mb-2">
															<b className="mb-1">
																{
																	prescription
																		?.item
																		?.name
																}{" "}
																-{" "}
																{
																	prescription?.quantity
																}{" "}
																{
																	prescription
																		?.item
																		?.unit_measurement
																}
															</b>
															<div>
																Sig.:
																<span className=" ml-2">
																	{
																		prescription?.details
																	}
																</span>
															</div>
														</li>
													);
												}
											)}
										</ul>
									</div>
								) : (
									""
								)}
								{x.vitals ? (
									<div className="flex flex-col mt- lg:col-span-3 rounded-sm">
										<span className="font-bold text-sm mb-1">
											Vital Signs
										</span>

										<BhsPatientVitals vitals={x?.vitals} />
									</div>
								) : (
									""
								)}

								{x?.xray_result || x?.hemoglobin ? (
									<div className="lg:col-span-3 grid grid-cols-1 lg:grid-cols-2 gap-4">
										{x?.xray_result &&
										users?.type.includes("DOC") ? (
											<div className="w-full mt-0 h-full">
												<span className="text-sm mb-2 font-bold">
													Patient X-Ray Result
												</span>
												<div className="w-full relative">
													<div className="aspect-[3/2] w-full" />
													<img
														src={x?.xray_result}
														className="bg-black object-contain absolute top-0 left-0 h-full w-full"
													/>
												</div>
											</div>
										) : (
											""
										)}
										{x?.hemoglobin &&
										users?.type.includes("DOC") ? (
											<div className="w-full mt-0 h-full">
												<span className="text-sm mb-2 font-bold">
													Patient Laboratory Results
												</span>
												<div className="w-full table relative">
													<table>
														<tbody>
															<tr>
																<th>TEST</th>
																<th>RESULT</th>
																<th>UNIT</th>
																<th>
																	REFERENCE
																	RANGE
																</th>
															</tr>
															<tr>
																<th>
																	Hemoglobin
																</th>
																<td className="text-right">
																	<b>
																		{
																			x?.hemoglobin
																		}
																	</b>
																</td>
																<td>g/L</td>
																<td>
																	120 - 170
																</td>
															</tr>
															<tr>
																<th>
																	Hematocrit
																</th>
																<td className="text-right">
																	<b>
																		{
																			x?.hematocrit
																		}
																	</b>
																</td>
																<td>L/L</td>
																<td>
																	0.40 - 0.54
																</td>
															</tr>
															<tr>
																<th>
																	RBC Count
																</th>
																<td className="text-right">
																	<b>
																		{x?.rbc}
																	</b>
																</td>
																<td>x10¹²/L</td>
																<td>
																	4.60 - 6.00
																</td>
															</tr>
															<tr>
																<th>
																	WBC Count
																</th>
																<td className="text-right">
																	<b>
																		{x?.wbc}
																	</b>
																</td>
																<td>x10⁹/L</td>
																<td>
																	5.10 - 10.00
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										) : (
											""
										)}
									</div>
								) : (
									""
								)}

								{x?.selfie ? (
									<div className="w-full mt-0 h-full">
										<span className="text-sm mb-2 font-bold">
											Release Medication Selfie
										</span>
										<div className="w-full relative">
											<div className="aspect-[3/2] w-full" />
											<img
												src={x?.selfie}
												className="bg-black object-contain absolute top-0 left-0 h-full w-full"
											/>
										</div>
									</div>
								) : (
									""
								)}

								{x?.satisfaction ? (
									<div className="flex flex-col items-center justify-start">
										<span className="font-bold text-sm mr-1 mb-3">
											Patient Statisfaction
										</span>
										{x.satisfaction == "very satisfied" && (
											<img
												className="h-11 w-11 my-2"
												src="/happy.png"
											/>
										)}
										{x.satisfaction == "neutral" && (
											<img
												className="h-11 w-11 my-2"
												src="/neutral.png"
											/>
										)}
										{x.satisfaction == "unsatisfied" && (
											<img
												className="h-11 w-11 my-2"
												src="/sad.png"
											/>
										)}
										<span className="font-bold capitalize text-green-700 px-0 py-0 rounded-lg">
											{x.satisfaction}
										</span>
									</div>
								) : (
									""
								)}
								{x.prescriptions?.length > 0 &&
								x?.released_by ? (
									<div className="flex flex-col gap-1 rounded-sm">
										<span className="font-bold text-sm mb-2">
											Medications released
										</span>
										<table className="text-xs table-bordered">
											<thead>
												<tr>
													<th className="p-1">
														Item name
													</th>
													<th className="p-1">
														Unit
													</th>
													<th className="p-1">Qty</th>
												</tr>
											</thead>
											<tbody>
												{x.prescriptions?.map(
													(prescription) => {
														return (
															<tr>
																<td className="p-1">
																	{
																		prescription
																			?.item
																			?.name
																	}
																</td>
																<td className="p-1">
																	{
																		prescription
																			?.item
																			?.unit_measurement
																	}
																</td>
																<td className="p-1">
																	{
																		prescription?.quantity
																	}
																</td>
															</tr>
														);
													}
												)}
											</tbody>
										</table>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					);
				})}
				{loading ? (
					<p className="pl-3 text-slate-300">Loading ...</p>
				) : (
					""
				)}
				{list?.length == 0 ? (
					<p className="pl-3 text-slate-300">
						No appointments found.
					</p>
				) : (
					""
				)}
			</div>{" "}
			<ViewAppointmentModal
				ref={viewAppointmentRef}
				serviceModal={serviceModal}
				referToRHURef={referToRHURef}
				appointmentVitalsRef={appointmentVitalsRef}
			/>
			<ReferToSPHDoctorModal
				ref={referToSPHDoc}
				key={`ReferToSPHDoctorModal-${modalKeys}`}
				selected={selected}
				setSelected={setSelected}
				patient={patient}
				patientSelfie={patientSelfie}
				setPatientSelfie={setPatientSelfie}
				onSuccess={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
					setModalKeys(uuidv4());
				}}
			/>
			<PatientBillCashierModal
				ref={patientBillModalRef}
				key={`PatientBillCashierModal-${modalKeys}`}
				selected={selected}
				setSelected={setSelected}
				patient={patient}
				patientSelfie={patientSelfie}
				setPatientSelfie={setPatientSelfie}
				onSuccess={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
					setModalKeys(uuidv4());
				}}
			/>
			<ReferToSPHModal
				ref={referToSPH}
				key={`ReferToSPHModal-${modalKeys}`}
				selected={selected}
				setSelected={setSelected}
				patient={patient}
				patientSelfie={patientSelfie}
				setPatientSelfie={setPatientSelfie}
				onSuccess={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
					setModalKeys(uuidv4());
				}}
			/>
			<AcceptPatientModal
				ref={acceptPatientRef}
				key={`AcceptPatientModal-${modalKeys}`}
				patient={patient}
				refresh={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
				}}
				onSuccess={() => {
					setModalKeys(uuidv4());
				}}
			/>
			<AddLabResultReadingModal
				ref={labReading}
				key={`AddLabResultReadingModal-${modalKeys}`}
				patient={patient}
				refresh={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
				}}
				onSuccess={() => {
					setModalKeys(uuidv4());
				}}
			/>
			<AddLabResultModal
				ref={labResultRef}
				key={`AddLabResultModal-${modalKeys}`}
				patient={patient}
				refresh={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
				}}
				onSuccess={() => {
					setModalKeys(uuidv4());
				}}
			/>
			<AddXrayResultModal
				ref={xRayResultRef}
				key={`AddXrayResultModal-${modalKeys}`}
				patient={patient}
				refresh={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
				}}
				onSuccess={() => {
					setModalKeys(uuidv4());
				}}
			/>
			<AppointmentCreateVitalsModal
				ref={appointmentVitalsRef}
				key={`AppointmentCreateVitalsModal-${modalKeys}`}
				selected={selected}
				setSelected={setSelected}
				patient={patient}
				patientSelfie={patientSelfie}
				setPatientSelfie={setPatientSelfie}
				onSuccess={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
					setModalKeys(uuidv4());
				}}
			/>
			<CreateAppointmentModal
				ref={createAppointmentRef}
				key={`CreateAppointmentModal-${modalKeys}`}
				selected={selected}
				appointmentVitalsRef={appointmentVitalsRef}
				referToRHURef={referToRHURef}
				setSelected={setSelected}
				patient={patient}
				patientSelfie={patientSelfie}
				setPatientSelfie={setPatientSelfie}
				onSuccess={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
					setModalKeys(uuidv4());
				}}
			/>
			<CreatePrescriptionModal
				key={`CreatePrescriptionModal-${modalKeys}`}
				ref={prescriptionRef}
				onSuccess={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
					setModalKeys(uuidv4());
				}}
			/>
			<ApproveReleaseMedicineModal
				key={`ApproveReleaseMedicineModal-${modalKeys}`}
				ref={approveReleasePrescriptionRef}
				onSuccess={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
					setModalKeys(uuidv4());
				}}
			/>
			<ReleasePrescriptionModal
				key={`ReleasePrescriptionModal-${modalKeys}`}
				ref={releasePrescriptionRef}
				onSuccess={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
					setModalKeys(uuidv4());
				}}
			/>
			<CreateBhsServiceModal
				key={`CreateBhsServiceModal-${modalKeys}`}
				ref={serviceModal}
				patient={patient}
				refresh={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
				}}
				onSuccess={() => {
					setModalKeys(uuidv4());
				}}
			/>
			<ReferToRhuModal
				key={`ReferToRhuModal-${modalKeys}`}
				ref={referToRHURef}
				appointmentVitalsRef={appointmentVitalsRef}
				patient={patient}
				refresh={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
				}}
				onSuccess={() => {
					setModalKeys(uuidv4());
				}}
			/>{" "}
			<SPHPatientBillingModal
				key={`SPHPatientBillingModal-${modalKeys}`}
				ref={patientBillingRef}
				patient={patient}
				refresh={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
				}}
				onSuccess={() => {
					setModalKeys(uuidv4());
				}}
			/>
			<ReferToSPHFormModal
				key={`ReferToSPHFormModal-${modalKeys}`}
				ref={referToSPHFormRef}
				patient={patient}
				refresh={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
				}}
				onSuccess={() => {
					setModalKeys(uuidv4());
				}}
			/>
			<ConfirmTBModal
				key={`ConfirmTBModal-${modalKeys}`}
				ref={confirmTB}
				patient={patient}
				refresh={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
				}}
				onSuccess={() => {
					setModalKeys(uuidv4());
				}}
			/>
			<CreateLabOrderModal
				key={`CreateLabOrderModal-${modalKeys}`}
				ref={labOrderRef}
				patient={patient}
				refresh={() => {
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
				}}
				onSuccess={() => {
					setModalKeys(uuidv4());
				}}
			/>
			<PrivacyStatementModal
				key={`PrivacyStatementModal-${modalKeys}`}
				onSuccess={(data) => {
					createAppointmentRef.current.show(data);
				}}
				patient={patient}
				ref={viewPrivacyRef}
				patientSelfie={patientSelfie}
				setPatientSelfie={setPatientSelfie}
			/>
		</div>
	);
};

export default Appointments;
