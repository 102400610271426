import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { FormInformation, UsersData, UsersHeader } from "../ContextAPI";
import BillingDashboard from "src/billing";
import AccountBilling from "src/billing/account/Account";
import BillingSOA from "src/billing/soa";
import PatientSoa from "src/billing/soa/PatientSoa";
import CompanySoa from "src/billing/soa/CompanySoa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAddressCard,
	faCog,
	faFileInvoice,
	faFolderOpen,
	faHome,
	faHospitalUser,
	faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import BillingPrintOption from "src/billing/option/BillingPrintOption";
import CompanyHMOSoa from "src/billing/soa/CompanyHMOSoa";
import AdmittedPatient from "src/billing/admitted-patient";
import DischargedPatient from "src/billing/discharged-patient";
import BillingPatients from "src/disease-treatment/sph-billing/BillingPatients";
import DischargedBillingPatients from "src/disease-treatment/sph-billing/DischargedBillingPatients";
import BillingSPHSoa from "src/disease-treatment/sph-billing/BillingSPHSoa";
import SPHPatientSoa from "src/disease-treatment/sph-billing/SPHPatientSoa";

const RouteRHUBilling = () => {
	const [sidebarHeader, setSidebarHeader] = useState([]);
	const [formInfo, setFormInfo] = useState([]);
	const { users } = useContext(UsersData);
	const userContext = useContext(UsersData);
	const [pendings, setPendings] = useState(null);

	const getSidebarHeaderInfo = async () => {
		try {
			var params = { user_id: userContext.users.user_id };
			const response = await axios.get(
				"billing/sidebar/header-infomartion",
				{
					params,
				}
			);

			setSidebarHeader(response.data);
		} catch (error) {
			console.log("Unable to get sidebar header info.", error);
		}
	};

	const getCurrentFormInfoForSOA = async () => {
		try {
			var params = {
				user_id: userContext.users.user_id,
				management_id: userContext.users.management_id,
				main_mgmt_id: userContext.users.main_mgmt_id,
			};
			const response = await axios.get("billing/get/form-infomartion", {
				params,
			});
			setFormInfo(response.data);
		} catch (error) {
			console.log("Unable to get sidebar header info.", error);
		}
	};

	const getPendingForCashier = () => {
		axios.get(`/v1/clinic/pending-cashier`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	const getPendingForBilling = () => {
		axios.get(`/v1/clinic/pending-billing`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	useEffect(() => {
		if (users?.type == "SPH-BILLING") {
			getPendingForBilling();
		}
		if (users?.type == "SPH-CASHIER") {
			getPendingForCashier();
		}
	}, []);

	useEffect(() => {
		let t = setInterval(() => {
			if (users?.type == "SPH-BILLING") {
				getPendingForBilling();
			}
			if (users?.type == "SPH-CASHIER") {
				getPendingForCashier();
			}
		}, 10000);
		return () => {
			clearTimeout(t);
		};
	}, []);

	const sidebarRoute = [
		{
			name: "Dashboard",
			show: true,
			icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
			path: "/sph/app/billing",
			subitem: [],
		},

		{
			name:
				users?.type == "SPH-BILLING"
					? "Patient For Bill"
					: "Patient for Discharge",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faFileInvoice}
					color="white"
					size={"2x"}
				/>
			),
			path: "/sph/app/billing/admitted-patient",
			subitem: [],
			children: (
				<div
					className={`absolute left-0 top-0 ${
						(pendings?.count || 0) == 0 ? "hidden" : ""
					}`}
				>
					<div className="left-8  bg-red-500 rounded-full z-20 absolute top-2 text-white text-xs h-5  w-5 flex items-center justify-center">
						{pendings?.count || 0}
					</div>

					<div className="left-8  bg-red-500 rounded-full z-10 absolute top-2 text-white text-xs h-5  w-5 flex items-center justify-center animate-ping"></div>
				</div>
			),
		},

		{
			name: "Discharged Patient",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faHospitalUser}
					color="white"
					size={"2x"}
				/>
			),
			path: "/sph/app/billing/discharged-patient",
			subitem: [],
		},

		{
			name: "SOA",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faFolderOpen}
					color="white"
					size={"2x"}
				/>
			),
			path: "/sph/app/billing/soa",
			subitem: [],
		},
		{
			name: "Print Option",
			show: true,
			icon: <FontAwesomeIcon icon={faCog} color="white" size={"2x"} />,
			path: "/sph/app/billing/print-option",
			subitem: [],
		},
		// {
		// 	name: "Leave Application",
		// 	show: true,
		// 	icon: (
		// 		<FontAwesomeIcon
		// 			icon={faAddressCard}
		// 			color="white"
		// 			size={"2x"}
		// 		/>
		// 	),
		// 	path: "/sph/app/billing/leave-application",
		// 	subitem: [],
		// },
		{
			name: "Account",
			show: true,
			icon: (
				<FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />
			),
			path: "/sph/app/billing/account",
			subitem: [],
		},
	];

	const handleRenderInfo = () => {
		getSidebarHeaderInfo();
	};

	const handleRerenderFormInfo = () => {
		getCurrentFormInfoForSOA();
	};

	useEffect(() => {
		getSidebarHeaderInfo();
		getCurrentFormInfoForSOA();
		//eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<UsersHeader.Provider
				value={{
					sidebarHeader: sidebarHeader,
					renderPharmacyInfo: handleRenderInfo,
				}}
			>
				<FormInformation.Provider
					value={{
						formInfo: formInfo,
						renderFormInfo: handleRenderInfo,
					}}
				>
					<BrowserRouter>
						<Sidebar
							notification={{
								enable: true,
								owner: "pho",
							}}
							header={{ ...sidebarHeader, ...users }}
							routes={sidebarRoute}
							module={users?.type?.replace("-", " ")}
						/>
						<Switch>
							<Route
								exact
								path="/"
								component={() => (
									<Redirect to="/sph/app/billing" />
								)}
							/>

							<Route
								exact
								path="/bmcdc"
								component={() => (
									<Redirect to="/sph/app/billing" />
								)}
							/>

							<Route
								exact
								path="/sph/app"
								component={() => (
									<Redirect to="/sph/app/billing" />
								)}
							/>

							<Route
								exact
								path="/sph/app/billing"
								component={BillingDashboard}
							/>

							<Route
								exact
								path="/sph/app/billing/account"
								component={AccountBilling}
							/>

							<Route
								exact
								path="/sph/app/billing/admitted-patient"
								component={BillingPatients}
							/>

							<Route
								exact
								path="/sph/app/billing/discharged-patient"
								component={DischargedBillingPatients}
							/>

							<Route
								exact
								path="/sph/app/billing/soa"
								component={BillingSPHSoa}
							/>

							<Route
								exact
								path="/sph/app/billing/print-option"
								component={BillingPrintOption}
							/>

							<Route
								exact
								path="/sph/app/billing/soa/patient/:patient_id"
								component={SPHPatientSoa}
							/>

							{/* <Route
								exact
								path="/sph/app/billing/leave-application"
								component={PatientSoa}
							/> */}

							<Route
								exact
								path="/sph/app/billing/soa/company/:company"
								component={CompanySoa}
							/>

							<Route
								exact
								path="/sph/app/billing/soa/hmo/:hmo_id"
								component={CompanyHMOSoa}
							/>

							<Route
								exact
								path="/sph/app/logout"
								component={Logout}
							/>

							<Route
								render={() => (
									<PageNotFound title="Page not found" />
								)}
							/>
						</Switch>
					</BrowserRouter>
				</FormInformation.Provider>
			</UsersHeader.Provider>
		</Fragment>
	);
};

export default RouteRHUBilling;
