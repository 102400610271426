import React, {
	Fragment,
	useState,
	useEffect,
	useCallback,
	useContext,
} from "react";
import {
	Dialog,
	DialogContent,
	CircularProgress,
	TableBody,
	Table,
	TableHead,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	Button,
	TextField,
	InputAdornment,
	Grid,
	Card,
	Box,
	Tooltip,
} from "@material-ui/core";
import {
	faArrowRight,
	faEdit,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Search } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import axios from "axios";
import TablePagination from "@material-ui/core/TablePagination";
import { UsersData } from "src/ContextAPI";
import { formatDateTime } from "src/helpers/utils";
import Notify from "src/notification/Notify";
import BMCDCRXDoc from "src/utils/Rx/BMCDCRXDoc";
import Zoom from "@material-ui/core/Zoom";
import EditPrescriptionForm from "./EditPrescriptionForm";

function PrescriptionList({
	patient_id,
	displayForm,
	formValues,
	setFormValues,
}) {
	const { users } = useContext(UsersData);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [selectedid, setselectedid] = useState(null);
	const [loading, setLoading] = useState(false);
	const [prescriptions, setPrescriptions] = useState([]);
	const [listView, setListView] = useState(true);
	const [removeSelectedId, setRemoveSelectedId] = useState(null);
	const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

	useEffect(() => {
		let t = setTimeout(() => {
			axios
				.get(`v1/patient-prescription/${patient_id}`)
				.then(function (response) {
					setPrescriptions(response.data);
					setLoading(true);
				})
				.catch(function (error) {
					console.log(error);
				})
				.finally(function () {});
		}, 300);
		return () => {
			clearTimeout(t);
		};
	}, [patient_id]);

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	const onEdit = (id) => {
		axios
			.get(`/v1/patient-prescription/show/${id}`)
			.then((response) => {
				setFormValues(response.data);
			})
			.catch((err) => console.log(err));

		setListView(false);
	};

	const closeRemovedialog = () => {
		setRemoveSelectedId(null);
		setRemoveDialogOpen(false);
	};

	const handleSelectedProd = (e) => {
		setRemoveDialogOpen(true);
		setRemoveSelectedId(e.currentTarget.id);
	};

	const handleRemove = (e) => {};

	const [selectedPrescription, setSelectedPrescription] = useState({
		data: [],
		open: false,
	});

	return (
		<>
			{listView ? (
				<Grid item xs={12} sm={12} className="mt-4">
					<div>
						<Box elevation={2}>
							<div className="flex flex-row justify-content-between gap-1">
								<div>
									<h4 className="text-lg font-bold mb-0">
										Prescriptions
									</h4>
									<p className="text-sm">
										{prescriptions?.data?.length} Total List
										of Prescriptions
									</p>
								</div>
								<div className="flex align-items-center">
									<div className="flex flex-row align-items-center gap-3">
										<div>
											<TextField
												label="Search"
												variant="outlined"
												margin="dense"
												InputProps={{
													endAdornment: (
														<InputAdornment>
															<Search color="default" />
														</InputAdornment>
													),
												}}
											/>
										</div>
										<div>
											<Button
												variant="contained"
												color="primary"
												size="medium"
												onClick={displayForm}
												startIcon={
													<AddCircleOutlineIcon />
												}
											>
												Create Prescription
											</Button>
										</div>
									</div>
								</div>
							</div>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell className="text-uppercase">
												<strong> Prescription </strong>
											</TableCell>
											<TableCell className="text-uppercase">
												<strong> Quantity </strong>
											</TableCell>
											<TableCell className="text-uppercase">
												<strong> Type </strong>
											</TableCell>
											<TableCell className="text-uppercase">
												<strong> Sig./Remarks </strong>
											</TableCell>
											<TableCell className="text-uppercase">
												<strong> Date </strong>
											</TableCell>
											{/* <TableCell className="text-uppercase">
												<strong> Action </strong>
											</TableCell> */}
										</TableRow>
									</TableHead>
									<TableBody>
										{loading ? (
											prescriptions.data?.length > 0 ? (
												<>
													{prescriptions.data
														.slice(
															page * rowsPerPage,
															page * rowsPerPage +
																rowsPerPage
														)
														.map(
															({
																id,
																prescription,
																quantity,
																type,
																remarks,
																created_at,
															}) => (
																<TableRow
																	hover
																	key={id}
																>
																	<TableCell>
																		{
																			prescription
																		}
																	</TableCell>
																	<TableCell>
																		{
																			quantity
																		}
																	</TableCell>
																	<TableCell>
																		{type}
																	</TableCell>
																	<TableCell>
																		{
																			remarks
																		}
																	</TableCell>
																	<TableCell>
																		{formatDateTime(
																			new Date(
																				created_at
																			)
																		)}
																	</TableCell>
																	{/* <TableCell>
																		<div className="d-flex flex-row gap-2">
																			<div>
																				<Tooltip
																					arrow
																					title="Edit"
																				>
																					<Button
																						variant="contained"
																						size="small"
																						className="p-2"
																					>
																						<FontAwesomeIcon
																							icon={
																								faEdit
																							}
																							className="mr-2"
																							onClick={() =>
																								onEdit(
																									id
																								)
																							}
																						/>
																						Edit
																					</Button>
																				</Tooltip>
																			</div>
																			<div>
																				<Tooltip
																					arrow
																					title="Delete"
																				>
																					<Button
																						variant="contained"
																						size="small"
																						color="secondary"
																						className="p-2"
																						onClick={() =>
																							handleSelectedProd(
																								id
																							)
																						}
																					>
																						<FontAwesomeIcon
																							icon={
																								faTrash
																							}
																							className="mr-2"
																						/>
																						Delete
																					</Button>
																				</Tooltip>
																			</div>
																		</div>
																	</TableCell> */}
																</TableRow>
															)
														)}
												</>
											) : (
												<TableRow hover key={1}>
													<TableCell colSpan={6}>
														<Typography
															color="secondary"
															align="center"
														>
															No record found.
														</Typography>
													</TableCell>
												</TableRow>
											)
										) : (
											<TableRow hover key={1}>
												<TableCell colSpan={6}>
													<Typography
														color="primary"
														align="center"
													>
														Please wait...
													</Typography>
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
								<TablePagination
									component="div"
									count={prescriptions?.data?.length}
									rowsPerPageOptions={[
										10,
										50,
										100,
										{ label: "All", value: -1 },
									]}
									page={page}
									onChangePage={handleChangePage}
									rowsPerPage={rowsPerPage}
									onChangeRowsPerPage={
										handleChangeRowsPerPage
									}
									labelRowsPerPage="List"
								/>
							</TableContainer>
						</Box>

						<Dialog
							open={selectedPrescription.open}
							onClose={() =>
								setSelectedPrescription({
									data: [],
									open: false,
								})
							}
							fullScreen
							TransitionComponent={Zoom}
							transitionDuration={800}
						>
							<BMCDCRXDoc
								selectedPrescription={selectedPrescription}
								close={() =>
									setSelectedPrescription({
										data: [],
										open: false,
									})
								}
							/>
						</Dialog>
					</div>
				</Grid>
			) : (
				<EditPrescriptionForm
					patient_id={patient_id}
					formValues={formValues}
					setListView={setListView}
				/>
			)}
		</>
	);
}

export default PrescriptionList;
