import {
  faChartLine,
  faFlask,
  faHome,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import { useContext, useRef } from "react";
import LayoutContainer from "src/components/LayoutContainer";
import { UsersData } from "src/ContextAPI";

export default function CisDashboard() {
  const userContext = useContext(UsersData);
  const { users } = userContext;
  const name = users?.name;
  const receive_patient_ref = useRef(null);

  return (
    <LayoutContainer
      icon={<FontAwesomeIcon icon={faHome} color="white" size={"2x"} />}
      title={
        <Typography variant={window.innerWidth > 500 ? "h4" : "h5"}>
          Dashboard
        </Typography>
      }
      subtitle={`Welcome Back, ${name}! 👋`}
      titleChildren={<></>}
      className={`relative`}
    >
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} lg={4}>
          <Card elevation={3} className="p-4" component={Box}>
            <div className="flex items-center justify-between px-3 gap-3 bg-white">
              <div className="flex flex-col">
                <span className="text-sm font-light text-placeholder">
                  TOTAL PATIENTS
                </span>
                <span className="text-lg font-bold !text-green-700">2,040</span>
              </div>
              <FontAwesomeIcon
                icon={faUsers}
                style={{ fontSize: 50 }}
                className="pr-2 !text-green-700"
              />
            </div>
          </Card>
        </Grid>
        <Grid item xs={4} sm={4} lg={4}>
          <Card elevation={3} className="p-4" component={Box}>
            <div className="flex items-center justify-between px-3 gap-3 bg-white">
              <div className="flex flex-col">
                <span className="text-sm font-light text-placeholder">
                  TOTAL PATIENTS TESTED
                </span>
                <span className="text-lg font-bold !text-blue-700">100</span>
              </div>
              <FontAwesomeIcon
                icon={faFlask}
                style={{ fontSize: 50 }}
                className="pr-2 !text-blue-700"
              />
            </div>
          </Card>
        </Grid>
        <Grid item xs={4} sm={4} lg={4}>
          <Card elevation={3} className="p-4" component={Box}>
            <div className="flex items-center justify-between px-3 gap-3 bg-white">
              <div className="flex flex-col">
                <span className="text-sm font-light text-placeholder">
                  TOTAL POSITVE PATIENTS
                </span>
                <span className="text-lg font-bold !text-red-700">99</span>
              </div>
              <FontAwesomeIcon
                icon={faChartLine}
                style={{ fontSize: 50 }}
                className="pr-2 !text-red-700"
              />
            </div>
          </Card>
        </Grid>
      </Grid>
    </LayoutContainer>
  );
}
