import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { styles } from "src/cis/components/styles/styles";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import ImagePicker from "src/components/forms/ImagePicker";
import Img from "src/components/Img";
import LayoutContainer from "src/components/LayoutContainer";
import { UsersData } from "src/ContextAPI";
import { getErrors } from "src/helpers/utils";
import Notify from "src/notification/Notify";

const config = {
	headers: {
		"content-type": "multipart/form-data",
	},
};
export default function CisMyAccount() {
	const userContext = useContext(UsersData);
	const { users } = userContext;
	const [avatar, setAvatar] = useState(null);
	const [user, setUser] = useState();
	const [savingProfile, setSavingProfile] = useState(false);
	const [savingPassword, setSavingPassword] = useState(false);
	const [savingUsername, setSavingUsername] = useState(false);

	// useEffect(() => {
	//   axios
	//     .get(`v1/profile/show`)
	//     .then(function (response) {
	//       console.log(response.data);
	//       setUser(response.data);
	//     })
	//     .catch(function (error) {
	//       // handle error
	//       console.log(error);
	//     })
	//     .finally(function () {
	//       // always executed
	//     });
	// }, []);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const {
		register: password_register,
		handleSubmit: password_handleSubmit,
		formState: { errors: password_errors },
	} = useForm();
	const {
		register: username_register,
		handleSubmit: username_handleSubmit,
		setError: setUsernameError,
		reset: resetUsernameForm,
		formState: { errors: username_errors },
	} = useForm();

	const saveProfile = (data) => {
		setSavingProfile(true);
		let formdata = new FormData();
		formdata.append("name", data?.name || "");
		formdata.append("email", data?.email || "");

		axios
			.patch(`v1/profile/`, formdata)
			.then((response) => {
				let data = response.data;
				setSavingProfile(false);
				Notify.edit("Updated");
			})
			.catch((err) => {
				setSavingProfile(false);
				console.log(err);
			});
	};

	const savePassword = (data) => {
		setSavingPassword(true);
		let formdata = new FormData();
		formdata.append("old_password", data?.current_password || "");
		formdata.append("new_password", data?.password || "");
		formdata.append(
			"confirm_new_password",
			data?.password_confirmation || ""
		);

		axios
			.patch(`v1/profile/password`, formdata)
			.then((response) => {
				let data = response.data;
				setSavingPassword(false);
				Notify.edit("Updated");
			})
			.catch((err) => {
				setSavingPassword(false);
				console.log(err);
			});
	};

	const saveUsername = (data) => {
		setSavingUsername(true);
		let formdata = new FormData();
		formdata.append("old_username", data?.old_username || "");
		formdata.append("username", data?.username || "");
		formdata.append("password", data?.password || "");
		formdata.append("new_username", data?.username || "");
		formdata.append("confirm_new_username", data?.confirm_username || "");

		axios
			.patch(`v1/profile/username`, formdata)
			.then((response) => {
				let data = response.data;
				setSavingUsername(false);
				resetUsernameForm({
					old_username: "",
					username: "",
					confirm_username: "",
				});
				Notify.edit("Updated");
			})
			.catch((err) => {
				setSavingUsername(false);
				console.log(err);
				if (err?.response?.data?.errors)
					getErrors(err?.response?.data?.errors, setUsernameError);
			});
	};

	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faUserLock}
						color="white"
						size={"2x"}
					/>
				}
				title="Account settings"
				subtitle={`Update your account information`}
				className="w-full grid grid-cols-1 lg:grid-cols-12 gap-6"
			>
				<CardContainer
					header={{ title: "Profile" }}
					containerClassName={`col-span-4`}
					className={styles.cardContainer}
				>
					<div className="flex items-center justify-center">
						<ImagePicker
							className="rounded-full bg-primary bg-opacity-40 group-hover:bg-primary group-hover:bg-opacity-100 duration-200"
							onChange={(data) => {
								setUser((prevData) => ({
									...prevData,
									avatar: data,
								}));
								setAvatar(data);
							}}
						>
							{() => {
								return (
									<Img
										key={`avatar-${user?.avatar}`}
										src={user?.avatar}
										name={user?.name}
										type="user"
										alt=""
										className="w-[144px] h-[144px] rounded-full"
									/>
								);
							}}
						</ImagePicker>
					</div>
					<TextField
						label={<label style={styles.label}>Name</label>}
						variant="outlined"
						fullWidth
						defaultValue={users?.name}
						{...register("name", { required: true })}
						error={errors.name}
						helperText={errors.name && "Name is required"}
					/>
					<TextField
						label={<label style={styles.label}>Email</label>}
						variant="outlined"
						type="email"
						fullWidth
						defaultValue={users?.email}
						{...register("email", { required: true })}
						error={errors.email}
						helperText={errors.email && "Email is required"}
					/>
					<ActionBtn
						className="ml-auto"
						onClick={handleSubmit(saveProfile)}
						loading={savingProfile}
					>
						<FlatIcon icon="rr-check" className="mr-2" /> Save
						Changes
					</ActionBtn>
				</CardContainer>

				<CardContainer
					header={{ title: "Change password" }}
					containerClassName={`col-span-4`}
					className={styles.cardContainer}
				>
					<TextField
						label={
							<label style={styles.label}>Current Password</label>
						}
						variant="outlined"
						type="password"
						fullWidth
						error={password_errors.current_password}
						helperText={
							password_errors.current_password &&
							"Current Password is required"
						}
						{...password_register("current_password", {
							required: true,
						})}
					/>
					<TextField
						label={<label style={styles.label}>New Password</label>}
						type="password"
						variant="outlined"
						fullWidth
						error={password_errors.password}
						helperText={
							password_errors.password &&
							"New Password is required"
						}
						{...password_register("password", {
							required: true,
						})}
					/>
					<TextField
						label={
							<label style={styles.label}>
								Confirm New Password
							</label>
						}
						type="password"
						variant="outlined"
						fullWidth
						error={password_errors.password_confirmation}
						helperText={
							password_errors.password_confirmation &&
							"Confirmation Password is required"
						}
						{...password_register("password_confirmation", {
							required: true,
						})}
					/>
					<ActionBtn
						className="ml-auto"
						onClick={password_handleSubmit(savePassword)}
						loading={savingPassword}
					>
						<FlatIcon icon="rr-check" className="mr-2" /> Save new
						password
					</ActionBtn>
				</CardContainer>

				<CardContainer
					header={{ title: "Update username" }}
					containerClassName={`col-span-4`}
					className={styles.cardContainer}
				>
					<TextField
						label={
							<label style={styles.label}>Current Username</label>
						}
						variant="outlined"
						fullWidth
						defaultValue={users?.username}
						error={username_errors.old_username}
						helperText={username_errors.old_username?.message}
						{...username_register("old_username", {
							required: "This field is required",
						})}
					/>
					<TextField
						label={<label style={styles.label}>New Username</label>}
						variant="outlined"
						fullWidth
						error={username_errors.username}
						helperText={username_errors.username?.message}
						{...username_register("username", {
							required: "This field is required",
						})}
					/>
					<TextField
						label={
							<label style={styles.label}>Confirm Username</label>
						}
						variant="outlined"
						fullWidth
						error={username_errors.confirm_username}
						helperText={username_errors.confirm_username?.message}
						{...username_register("confirm_username", {
							required: "This field is required",
						})}
					/>

					<ActionBtn
						className="ml-auto"
						onClick={username_handleSubmit(saveUsername)}
						loading={savingUsername}
					>
						<FlatIcon icon="rr-check" className="mr-2" /> Save
					</ActionBtn>
				</CardContainer>
			</LayoutContainer>
		</>
	);
}
