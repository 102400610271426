import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Collapse,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const Details = ({ details }) => {
  const mounted = IsMountedRef();
  const [isLoading, setIsLoading] = useState(true);
  const [tracenumberList, setTracenumberList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const [billRecord, setBillrecord] = useState([]);

  const getPatientsTraceNumbers = React.useCallback(() => {
    axios
      .get(`billing/discharged/patient/get-patientlist-tracenumber`, {
        params: {
          patient_id: details?.patient_id,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setTracenumberList(data);
        }
      })
      .catch((err) => console.log("error:", err.message))
      .finally(() => setIsLoading(false));
  }, [details, mounted]);

  const handleSelectedTraceNumber = async (__data) => {
    if (selectedPatient?.id === __data.id) {
      setSelectedPatient(null);
      return;
    }

    let { data } = await axios.get(`billing/discharged/patient/bill-records`, {
      params: {
        patient_id: __data?.patient_id,
        trace_number: __data?.trace_number,
      },
    });

    setBillrecord(data);
    setSelectedPatient(__data);
  };

  useEffect(() => {
    getPatientsTraceNumbers();
  }, [getPatientsTraceNumbers]);

  let billAmount = 0;

  return (
    <>
      <Box>
        <Card>
          <CardHeader title={"Transactions List"} />
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell> Date </TableCell>
                  <TableCell> Trace Number </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!isLoading ? (
                  tracenumberList.length > 0 ? (
                    tracenumberList.map((data, key) => (
                      <React.Fragment key={key}>
                        <TableRow>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => {
                                handleSelectedTraceNumber(data);
                              }}
                            >
                              {selectedPatient?.id === data.id ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {Notify.createdAt(data.created_at)}
                          </TableCell>
                          <TableCell> {data.trace_number} </TableCell>
                        </TableRow>

                        {selectedPatient?.id === data.id && (
                          <TableRow>
                            <TableCell colSpan={3}>
                              <Collapse
                                in={selectedPatient?.id === data.id}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box>
                                  <Box mb={1}>
                                    <Typography> Billing Records </Typography>
                                  </Box>

                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>
                                          <strong> Bill </strong>
                                        </TableCell>

                                        <TableCell>
                                          <strong> Amount </strong>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {billRecord.length > 0 ? (
                                        billRecord.map((_data, _key) => (
                                          <TableRow key={_key}>
                                            <TableCell>
                                              {_data.bill_name}
                                            </TableCell>

                                            <TableCell align={"right"}>
                                              {_data.bill_amount}
                                              <span hidden>
                                                {" "}
                                                {
                                                  (billAmount += parseFloat(
                                                    _data.bill_amount
                                                  ))
                                                }{" "}
                                              </span>
                                            </TableCell>
                                          </TableRow>
                                        ))
                                      ) : (
                                        <TableRow>
                                          <TableCell colSpan={2}>
                                            <Typography color="secondary">
                                              No record
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}

                                      <TableRow>
                                        <TableCell>
                                          <strong> Total </strong>
                                        </TableCell>

                                        <TableCell align={"right"}>
                                          {Notify.convertToNumber(billAmount)}
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <strong>Bill Amount</strong>
                                        </TableCell>
                                        <TableCell align="right">
                                          <strong>
                                            {Notify.convertToNumber(billAmount)}
                                          </strong>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <strong>Amount Philhealth </strong>
                                        </TableCell>
                                        <TableCell align="right">
                                          <strong>
                                            {Notify.convertToNumber(
                                              billRecord?.[0]
                                                ?.philhealth_amount ?? 0
                                            )}
                                          </strong>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <strong>Total Amount To Pay </strong>
                                        </TableCell>
                                        <TableCell align="right">
                                          <strong>
                                            {Notify.convertToNumber(
                                              billAmount -
                                                parseFloat(
                                                  billRecord?.[0]
                                                    ?.philhealth_amount ?? 0
                                                )
                                            )}
                                          </strong>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <strong> Payment Amount </strong>
                                        </TableCell>
                                        <TableCell align="right">
                                          <strong>
                                            {Notify.convertToNumber(
                                              billRecord?.[0]?.payment_amount ??
                                                0
                                            )}
                                          </strong>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography color="primary">
                        Loading, please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow></TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default Details;
