import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import PsychologyDashboard from "src/psychology";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faBrain,
  faClinicMedical,
  faFolderOpen,
  faHome,
  faShuttleVan,
  faUserInjured,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import Account from "src/psychology/account/Account";
import PsychologyPatients from "src/psychology/patients";
import PsychologyTest from "src/psychology/test";
import LeaveForm from "src/psychology/leave/LeaveForm";
import PrintOrder from "src/psychology/print/PrintOrder";
// import Report from "src/psychology/Report";
import IsMountedRef from "src/utils/IsMountedRef";
import { Badge } from "@material-ui/core";
import PsychologyPatientVan from "src/psychology/vanpatients/PsychologyPatientVan";
import PsychologyReport from "src/psychology/PsychologyReport";

const RoutePsychology = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);
  const mounted = IsMountedRef();
  const [list, setList] = useState({ data: [], ready: false });
  const [listMobile, setListMobile] = useState({ data: [], ready: false });
  const [newPatientCount, setNewPatientCount] = useState(0);
  const [newPatientCountVan, setNewPatientCountVan] = useState(0);
  var interval = null;

  const getSidebarHeaderInfo = React.useCallback(async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get(
        "psychology/sidebar/header-infomartion",
        {
          params,
        }
      );
      if (mounted.current) {
        setSidebarHeader(response.data);
      }
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  }, [userContext.users, mounted]);

  const getPatientClinicPsychology = React.useCallback(async () => {
    let response = await axios.get("psychology/patient/new-order", {
      params: {
        management_id: userContext.users.management_id,
      },
    });
    const data = response.data;
    if (mounted.current) {
      setList({ data, ready: true });
      if (data.length > 0) {
        let count = data.reduce((x, data) => {
          return Boolean(parseInt(data.order_count)) ? x + 1 : x + 0;
        }, 0);
        setNewPatientCount(count);
      } else {
        setNewPatientCount(0);
      }
    }
  }, [userContext.users, mounted]);

  const getPatientMobileVanPsychology = React.useCallback(async () => {
    let response = await axios.get("psychology/patient/van/new-order", {
      params: {
        management_id: userContext.users.management_id,
      },
    });
    const data = response.data;
    if (mounted.current) {
      setListMobile({ data, ready: true });
      if (data.length > 0) {
        let count = data.reduce((x, data) => {
          return Boolean(parseInt(data.order_count)) ? x + 1 : x + 0;
        }, 0);
        setNewPatientCountVan(count);
      } else {
        setNewPatientCountVan(0);
      }
    }
  }, [userContext.users, mounted]);

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/sph/app/psychology",
      subitem: [],
    },
    {
      name: "Patients",
      show: true,
      icon: (
        <Badge
          color="error"
          badgeContent={newPatientCount + newPatientCountVan}
        >
          <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
        </Badge>
      ),
      subitem: [
        {
          name: "Clinic",
          show: true,
          icon: (
            <Badge color="error" badgeContent={newPatientCount}>
              <FontAwesomeIcon
                icon={faClinicMedical}
                color="white"
                style={{ fontSize: "17px" }}
              />
              ,
            </Badge>
          ),
          path: "/sph/app/psychology/patients",
          subitem: [],
        },
        {
          name: "Van",
          show: true,
          icon: (
            <Badge color="error" badgeContent={newPatientCountVan}>
              <FontAwesomeIcon
                icon={faShuttleVan}
                color="white"
                style={{ fontSize: "17px" }}
              />
              ,
            </Badge>
          ),
          path: "/sph/app/psychology/mobile/van/patients",
          subitem: [],
        },
      ],
    },
    {
      name: "Psychology Test",
      show: true,
      icon: <FontAwesomeIcon icon={faBrain} color="white" size={"2x"} />,
      path: "/sph/app/psychology/test",
      subitem: [],
    },
    {
      name: "Report",
      show: true,
      icon: <FontAwesomeIcon icon={faFolderOpen} color="white" size={"2x"} />,
      path: "/sph/app/psychology/report",
      subitem: [],
    },
    {
      name: "Leave Application",
      show: true,
      icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
      path: "/sph/app/psychology/leave-application",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/sph/app/psychology/account",
      subitem: [],
    },
  ];

  const initializeInterval = () => {
    interval = setInterval(() => {
      getPatientClinicPsychology();
      getPatientMobileVanPsychology();
    }, 20000);
  };

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    getPatientClinicPsychology();
    getPatientMobileVanPsychology();
  }, [
    getSidebarHeaderInfo,
    getPatientClinicPsychology,
    getPatientMobileVanPsychology,
  ]);

  useEffect(() => {
    initializeInterval();
    return () => {
      clearInterval(interval);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,

          queue: {
            patient: list,
            getPatientsOnQueue: () => getPatientClinicPsychology(),
          },

          van_queue: {
            vaPatient: listMobile,
            getVanPatientsOnQueue: () => getPatientMobileVanPsychology(),
          },
        }}
      >
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              owner: "psychology",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"psychology"}
          />
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/sph/app/psychology" />}
            />

            <Route
              exact
              path="/bmcdc"
              component={() => <Redirect to="/sph/app/psychology" />}
            />

            <Route
              exact
              path="/sph/app"
              component={() => <Redirect to="/sph/app/psychology" />}
            />

            <Route
              exact
              path="/sph/app/psychology"
              component={PsychologyDashboard}
            />

            <Route
              exact
              path="/sph/app/psychology/patients"
              component={PsychologyPatients}
            />

            <Route
              exact
              path="/sph/app/psychology/mobile/van/patients"
              component={PsychologyPatientVan}
            />

            <Route
              exact
              path="/sph/app/psychology/test"
              component={PsychologyTest}
            />

            <Route
              exact
              path="/sph/app/psychology/leave-application"
              component={LeaveForm}
            />

            <Route
              exact
              path="/sph/app/psychology/account"
              component={Account}
            />

            <Route
              exact
              path="/sph/app/psychology/record/print/order/:order_id"
              component={PrintOrder}
            />

            <Route
              exact
              path="/sph/app/psychology/report"
              component={PsychologyReport}
            />

            <Route exact path="/sph/app/logout" component={Logout} />

            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RoutePsychology;
