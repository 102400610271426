import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  Zoom,
  Dialog,
  Avatar,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import Axios from "axios";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import { UsersData } from "src/ContextAPI";
import { isMobile } from "react-device-detect";
import { CameraAlt, Send } from "@material-ui/icons";
import Cropper from "react-cropper";

const NewPatientTriage = ({ getIncompleteReg }) => {
  const { users } = React.useContext(UsersData);
  const cropperRef = useRef();
  var profileimage = useRef();

  const [imgUploadTemp, setImgUploadTemp] = useState(null);
  const [image, setImage] = useState(null);
  const [openCropImage, setOpenCropImage] = useState(false);

  const sickness = [
    "Dry cough",
    "Fever",
    "Shortness of breath",
    "Difficulty of breathing",
    "Sore throat",
    "Headache",
    "Nasal congestions",
    "Diarrhea",
    "Colds",
    "Fatigue or body malaise",
  ];

  const handleChangeSickness = (e, setFieldValue, values) => {
    let test = values.sickness;
    if (test.includes(e.target.value)) {
      let testx = test.filter((item) => item !== e.target.value);
      setFieldValue("sickness", testx);
    } else {
      setFieldValue("sickness", test.concat(e.target.value));
    }
    console.log("test", test);
  };

  const getFormData = (object) => {
    const formData = new FormData();
    formData.append("image", image);
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleUploadImage = (event) => {
    var file = event.target.files[0];
    if (file !== undefined) {
      var reader = new FileReader();
      reader.onloadend = function () {
        var b64 = reader.result;
        setImgUploadTemp(b64);
      };
      reader.readAsDataURL(file);
      setOpenCropImage(true);
    }
  };

  return (
    <Paper component={Box} p={2}>
      <Formik
        initialValues={{
          username: users.username,
          user_id: users.user_id,
          management_id: users.management_id,
          main_mgmt_id: users.main_mgmt_id,
          // password: "",
          lastname: "",
          firstname: "",
          middlename: "",
          gender: "",
          birthday: "",
          birthplace: "",
          street: "",
          barangay: "",
          city: "",
          occupation: "",
          civil_status: "",
          religion: "",
          mobile: "",
          telephone: "",
          email: "",

          philhealth: "",
          company: "",
          temp: "",
          sickness: [],
        }}
        validationSchema={Yup.object().shape({
          // password: Yup.string().trim().required("Password is required."),
          lastname: Yup.string().trim().required("Lastname is required."),
          firstname: Yup.string().trim().required("Firstname is required."),
          gender: Yup.string().trim().required("Gender is required."),
          birthday: Yup.date().required("Birth date is required."),
          street: Yup.string().trim().required("Street is required."),
          barangay: Yup.string().trim().required("Barangay is required."),
          city: Yup.string().trim().required("City is required."),
          occupation: Yup.string().trim().required("Occupation is required."),
          civil_status: Yup.string()
            .trim()
            .required("Civil status is required."),
          religion: Yup.string().trim().required("Religion is required."),
          mobile: Yup.string().trim().required("Mobile is required."),
          temp: Yup.string().trim().required("Temperature is required."),
        })}
        onSubmit={async (
          values,
          { setErrors, setSubmitting, resetForm, setFieldValue }
        ) => {
          var errored = [];

          if (image === null) {
            Notify.fieldRequired("image required");
            errored = "error";
            return;
          }

          if (errored.length > 0) {
            console.log("form error.");
          } else {
            try {
              const request = await Axios.post(
                "triage/patients/newpatient-save",
                getFormData(values)
              );

              // if (request.data === "pass-invalid") {
              //   setErrors({ password: "Password is invalid." });
              //   Notify.fieldInvalid("password");
              // }

              if (request.data === "email-exist") {
                setErrors({ email: "Email is not available." });
                Notify.fieldInvalid("email");
              }

              if (request.data === "success") {
                Notify.successRequest("new patient");
                resetForm();
                setFieldValue("sickness", []);
                setImage(null);
                setSubmitting(true);
                getIncompleteReg();
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box mb={2}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow={1}>
                  <Typography color="primary" variant="subtitle2">
                    NEW PATIENT
                  </Typography>
                </Box>
                <Box>
                  {isMobile ? (
                    <IconButton
                      color="primary"
                      onClick={() => profileimage.click()}
                    >
                      <CameraAlt />
                    </IconButton>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CameraAlt />}
                      onClick={() => profileimage.click()}
                    >
                      Take Photo
                    </Button>
                  )}
                </Box>
                <input
                  type="file"
                  id="image"
                  onChange={handleUploadImage}
                  className="d-none"
                  ref={(ref) => (profileimage = ref)}
                />
              </Box>
            </Box>

            <Box my={1}>
              <Grid container spacing={1}>
                <Grid
                  component={Box}
                  hidden={image === null}
                  item
                  xs={12}
                  sm={4}
                >
                  <Box
                    width={"100%"}
                    height={
                      isMobile
                        ? 200
                        : window.innerWidth <= 959
                        ? 200
                        : window.innerWidth <= 898
                        ? 200
                        : 194
                    }
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {image !== null && (
                      <Avatar
                        style={{
                          width: isMobile
                            ? 180
                            : window.innerWidth <= 959
                            ? 180
                            : window.innerWidth <= 898
                            ? 180
                            : 160,
                          height: isMobile
                            ? 180
                            : window.innerWidth <= 959
                            ? 180
                            : window.innerWidth <= 898
                            ? 180
                            : 160,
                        }}
                        alt="profile-pic"
                        src={image}
                      />
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={image === null ? 12 : 8}>
                  <Box my={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.lastname && errors.lastname)}
                            helperText={touched.lastname && errors.lastname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastname}
                            fullWidth
                            required
                            name="lastname"
                            label="Lastname"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.firstname && errors.firstname
                            )}
                            helperText={touched.firstname && errors.firstname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstname}
                            fullWidth
                            required
                            name="firstname"
                            label="Firstname"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.middlename && errors.middlename
                            )}
                            helperText={touched.middlename && errors.middlename}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.middlename}
                            fullWidth
                            name="middlename"
                            label="Middle Name"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box my={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.gender && errors.gender)}
                            helperText={touched.gender && errors.gender}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.gender}
                            fullWidth
                            required
                            name="gender"
                            label="Gender"
                            variant="outlined"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <option value={""}>Select</option>
                            <option value={"Male"}>Male</option>
                            <option value={"Female"}>Female</option>
                          </TextField>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.birthday && errors.birthday)}
                            helperText={touched.birthday && errors.birthday}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.birthday}
                            fullWidth
                            required
                            name="birthday"
                            label="Birthday"
                            variant="outlined"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.birthplace && errors.birthplace
                            )}
                            helperText={touched.birthplace && errors.birthplace}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.birthplace}
                            fullWidth
                            required
                            name="birthplace"
                            label="Birth Place"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box my={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.street && errors.street)}
                            helperText={touched.street && errors.street}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.street}
                            fullWidth
                            required
                            name="street"
                            label="Street"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.barangay && errors.barangay)}
                            helperText={touched.barangay && errors.barangay}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.barangay}
                            fullWidth
                            required
                            name="barangay"
                            label="Barangay"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.city && errors.city)}
                            helperText={touched.city && errors.city}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.city}
                            fullWidth
                            required
                            name="city"
                            label="City"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box my={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.occupation && errors.occupation)}
                      helperText={touched.occupation && errors.occupation}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.occupation}
                      fullWidth
                      required
                      name="occupation"
                      label="Occupation"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(
                        touched.civil_status && errors.civil_status
                      )}
                      helperText={touched.civil_status && errors.civil_status}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.civil_status}
                      fullWidth
                      required
                      name="civil_status"
                      label="Civil Status"
                      variant="outlined"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <option value={""}>Select</option>
                      <option value={"Single"}>Single</option>
                      <option value={"Married"}>Married</option>
                      <option value={"Divorced"}>Divorced</option>
                    </TextField>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.religion && errors.religion)}
                      helperText={touched.religion && errors.religion}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.religion}
                      fullWidth
                      required
                      name="religion"
                      label="Religion"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box my={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.mobile && errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobile}
                      fullWidth
                      required
                      name="mobile"
                      label="Mobile"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.telephone && errors.telephone)}
                      helperText={touched.telephone && errors.telephone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.telephone}
                      fullWidth
                      name="telephone"
                      label="Telephone"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      fullWidth
                      required
                      name="email"
                      label="Email"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box my={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      error={Boolean(touched.philhealth && errors.philhealth)}
                      helperText={touched.philhealth && errors.philhealth}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.philhealth}
                      fullWidth
                      name="philhealth"
                      label="Philhealth"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      error={Boolean(touched.temp && errors.temp)}
                      helperText={touched.temp && errors.temp}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.temp}
                      fullWidth
                      required
                      name="temp"
                      label="Temperature"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 10000,
                          step: 0.01,
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box>
                <TextField
                  error={Boolean(touched.company && errors.company)}
                  helperText={touched.company && errors.company}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company}
                  fullWidth
                  name="company"
                  label="Company"
                  variant="outlined"
                  hidden
                />
              </Box>
            </Box>

            <Box>
              <Box my={3}>
                <Box mb={2}>
                  <Typography variant="h6" color="textSecondary">
                    Contact Tracing Form
                  </Typography>
                </Box>

                {console.log("sickness", sickness)}
                <Grid container spacing={1}>
                  {sickness.map((data, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4}>
                      <Box>
                        <FormControl component="fieldset">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="gilad"
                                  value={data}
                                  onChange={(e) =>
                                    handleChangeSickness(
                                      e,
                                      setFieldValue,
                                      values
                                    )
                                  }
                                />
                              }
                              label={data}
                            />
                          </FormGroup>
                        </FormControl>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>

            {/* <Box my={2}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                fullWidth
                required
                name="password"
                type="password"
                label="Password"
                variant="outlined"
              />
            </Box> */}

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box mb={2} display="flex">
              <Box flexGrow={1} />

              <Button
                variant="contained"
                color="default"
                onClick={resetForm}
                startIcon={<ClearIcon />}
              >
                Clear
              </Button>
              <Box ml={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <CheckIcon />
                    )
                  }
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>

      <Dialog
        open={openCropImage}
        fullScreen
        onClose={() => setOpenCropImage(false)}
        TransitionComponent={Zoom}
        transitionDuration={900}
      >
        <Box
          display="flex"
          width="100%"
          height="80vh"
          justifyContent="center"
          alignItems="center"
        >
          <Cropper
            style={{ height: "100%", width: "100%" }}
            zoomTo={0.5}
            aspectRatio={1}
            src={imgUploadTemp}
            viewMode={1}
            guides={false}
            background={false}
            responsive={true}
            dragMode={"move"}
            cropBoxResizable={true}
            checkOrientation={false}
            rotatable
            scalable
            ref={cropperRef}
          />
        </Box>
        <Box
          display="flex"
          width="100%"
          height="20vh"
          justifyContent="center"
          alignItems="center"
          border={1}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<Send />}
            onClick={() => {
              const imageElement = cropperRef.current;
              const cropper = imageElement.cropper;
              setImage(cropper.getCroppedCanvas().toDataURL());
              setImgUploadTemp(null);
              setOpenCropImage(false);
            }}
          >
            Confirm
          </Button>
        </Box>
      </Dialog>
    </Paper>
  );
};

export default NewPatientTriage;
