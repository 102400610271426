import { useEffect, useState } from "react";
import {
	dateMMDDYYYY,
	formatCurrency,
	getBirthDayYYYYMMDD,
	getStringArray,
	motherMaindenName,
	patientFullName,
	patientMI,
} from "src/helpers/utils";

const BoxInput = ({
	className = "",
	defaultValue = "",
	inputClassName = "",
}) => {
	return (
		<>
			<label
				className={`flex items-center border-l last:border-r border-b border-black ${className}`}
			>
				<input
					defaultValue={defaultValue}
					type="text"
					className={`w-4 p-0 leading-none text-center border-0 !text-xs h-3 ${inputClassName}`}
					maxLength={1}
				/>
			</label>
		</>
	);
};
const BoxInputGroup = ({
	children,
	label = "",
	className = "",
	labelClassName = "",
}) => {
	return (
		<div
			className={`flex flex-col items-center relative pt-1 ${className}`}
		>
			<div className="flex items-center">{children}</div>
			{label && (
				<label
					className={` absolute !text-[8px] -bottom-3 ${labelClassName}`}
				>
					{label}
				</label>
			)}
		</div>
	);
};
const UnderlineInput = ({
	label = "",
	className = "",
	labelClassName = "",
	inputClassName = "",
	defaultValue,
}) => {
	return (
		<label
			className={`flex flex-col justify-center items-center gap-0 ${className}`}
		>
			<input
				defaultValue={defaultValue}
				type="text"
				className={` uppercase w-full ${inputClassName}`}
				style={{ borderWidth: "0px", borderBottom: "1px solid #000" }}
			/>
			<label className={`!text-[10px] ${labelClassName}`}>{label}</label>
		</label>
	);
};
const RowInput = ({
	label,
	subLabel,
	className = "",
	labelClassName = "",
	inputClassName = "",
	defaultValue,
}) => {
	return (
		<div className={`flex flex-col w-full ${className}`}>
			{label && (
				<span
					className={`font-bold text-left w-full text-[8px] mb-[2px] ${labelClassName}`}
				>
					{label}
				</span>
			)}
			<input
				className={`flex border uppercase pl-1 h-5 ${inputClassName}`}
				defaultValue={defaultValue}
			/>
			{subLabel && (
				<span className="font-bold text-left w-full text-[6px] mt-[2px] mb-[2px]">
					{subLabel}
				</span>
			)}
		</div>
	);
};
const TextInput = ({
	label = "",
	className = "",
	labelClassName = "",
	inputClassName = "",
}) => {
	return (
		<label className={`flex items-center gap-0 ${className}`}>
			<label className={`text-xs ${labelClassName}`}>{label}</label>
			<input
				type="text"
				className={`uppercase w-full ${inputClassName}`}
				style={{ borderWidth: "0px", borderBottom: "1px solid #000" }}
			/>
		</label>
	);
};

const CheckBox = ({
	label,
	checked = "",
	className = "",
	inputClassName = "",
}) => {
	const [inputChecked, setInputChecked] = useState("");
	useEffect(() => {
		let t = setTimeout(() => {
			if (checked == "checked") {
				setInputChecked(checked);
			}
		}, 100);
		return () => {
			clearTimeout(t);
		};
	}, [checked]);
	return (
		<label
			className={`flex items-center text-xs gap-1 font-normal ${className}`}
		>
			<input
				checked={inputChecked}
				type="checkbox"
				className={inputClassName}
				onChange={() => {
					setInputChecked((inputChecked) =>
						inputChecked == "checked" ? "" : "checked"
					);
				}}
			/>
			{label}
		</label>
	);
};

const PMRForm = ({ patient }) => {
	return (
		<div className="bg-gray-600 p-11 min-h-[14in]  overflow-auto phic-forms">
			<div
				className="bg-white p-[0.5in]  flex flex-col w-[9.5in] gap-y-6 mx-auto flex-wrap"
				id="phic-form-printable"
			>
				<div className="bg-white flex flex-col w-[8.5in] h-[13in] border break-after-page">
					<div className="flex items-center p-1">
						<div className="flex flex-col mr-auto w-3/5">
							<img
								src="/philhealth-2.png"
								className="w-[144px]"
							/>
							<u className="font-bold text-xs mb-1">REMINDERS:</u>
							<ol className="text-xs">
								<li>
									1.Your PhilHealth Identification Number
									(PIN) is your unique and permanent number.
								</li>
								<li>
									2.Always use your PIN in all transactions
									with PhilHealth.
								</li>
								<li>
									3.For Updating/Amendment check the
									appropriate box and provide details to be
									accomplished and submit corresponding
									supporting documents.
								</li>
								<li>
									4. Please read instructions at the back
									before filling-out this form.
								</li>
							</ol>
						</div>
						<div className="flex flex-col items-center justify-center ">
							<h3 className="text-3xl font-bold">PMRF</h3>
							<b className="text-xs">
								PHILHEALTH MEMBER REGISTRATION FORM
							</b>
							<small className="text-xs font-bold">
								UHC v.1 January 2020
							</small>
							<div className="flex items-center justify-center">
								<span
									className="border-l text-center uppercase border-y w-5 h-5 p-0 text-xs"
									contentEditable={true}
								></span>
								<span
									className="border-l text-center uppercase border-y w-5 h-5 p-0 text-xs"
									contentEditable={true}
								></span>
								<span
									className="border-l text-center uppercase border-y w-5 h-5 p-0 text-xs"
									contentEditable={true}
								></span>
								<span
									className="border-x text-center uppercase border-y w-5 h-5 p-0 text-xs mr-1"
									contentEditable={true}
								></span>

								<span
									className="border-l text-center uppercase border-y w-5 h-5 p-0 text-xs"
									contentEditable={true}
								></span>
								<span
									className="border-l text-center uppercase border-y w-5 h-5 p-0 text-xs"
									contentEditable={true}
								></span>
								<span
									className="border-l text-center uppercase border-y w-5 h-5 p-0 text-xs"
									contentEditable={true}
								></span>
								<span
									className="border-x text-center uppercase border-y w-5 h-5 p-0 text-xs mr-1"
									contentEditable={true}
								></span>

								<span
									className="border-l text-center uppercase border-y w-5 h-5 p-0 text-xs"
									contentEditable={true}
								></span>
								<span
									className="border-l text-center uppercase border-y w-5 h-5 p-0 text-xs"
									contentEditable={true}
								></span>
								<span
									className="border-l text-center uppercase border-y w-5 h-5 p-0 text-xs"
									contentEditable={true}
								></span>
								<span
									className="border-x text-center uppercase border-y w-5 h-5 p-0 text-xs"
									contentEditable={true}
								></span>
							</div>

							<small className="text-[8px] font-bold">
								PHILHEALTH IDENTIFICATION NUMBER (PIN)
							</small>
							<div className="flex flex-col">
								<span className="font-bold text-xs pt-2">
									PURPOSE:
								</span>
								<div className="flex gap-4">
									<CheckBox label={`REGISTRATION`} />
									<CheckBox label={`UPDATING/AMENDMENT`} />
								</div>
							</div>
							<div className="flex flex-col w-full">
								<span className="font-bold text-left w-full text-[8px] pt-2 mb-[2px]">
									Preferred KonSulTa Provider
								</span>
								<div
									className="flex border p-2 uppercase"
									contentEditable
								></div>
							</div>
						</div>
					</div>
					<div className="flex items-center border-y p-1 justify-center bg-[#dbe2c6]">
						<b className="text-sm">I. PERSONAL DETAILS</b>
					</div>
					<div className="border-y">
						<table className="bordered-table text-xs w-full">
							<tr>
								<th rowSpan={2} className="w-[128px]"></th>
								<th rowSpan={2}>LAST NAME</th>
								<th rowSpan={2}>FIRST NAME</th>
								<th rowSpan={2} className="w-[62px]">
									<div className="text-center text-[8px]">
										NAME
										<br />
										EXTENSION
										<br />
										(Jr./Sr./III)
									</div>
								</th>
								<th rowSpan={2}>MIDDLE NAME</th>
								<th className="w-[44px]">
									<div className="text-center text-[8px]">
										NO
										<br />
										MIDDLE
										<br />
										NAME
									</div>
								</th>
								<th className="w-[44px] text-[8px]">MONONYM</th>
							</tr>
							<tr>
								<th colSpan={2} className=" text-[7px]">
									(Check if applicable only)
								</th>
							</tr>
							<tr>
								<th className="h-[32px] text-left">MEMBER</th>
								<td
									className="text-left uppercase"
									contentEditable
								>
									{patient?.lastname || ""}
								</td>
								<td
									className="text-left uppercase"
									contentEditable
								>
									{patient?.firstname || ""}
								</td>
								<td
									className="text-center uppercase"
									contentEditable
								>
									{patient?.prefix || ""}
								</td>
								<td contentEditable className=" uppercase">
									{patient?.middlename || ""}
								</td>
								<td contentEditable>
									<div className=" uppercase flex items-center justify-center">
										<CheckBox />
									</div>
								</td>
								<td className="">
									<div className=" uppercase flex items-center justify-center">
										<CheckBox />
									</div>
								</td>
							</tr>
							<tr>
								<th className="h-[32px] text-left">
									MOTHER’s <br /> MAIDEN NAME
								</th>
								<td
									className="text-left uppercase"
									contentEditable
								>
									{(patient?.information?.mother_maiden_name
										?.length > 0 &&
										motherMaindenName(
											patient?.information
												?.mother_maiden_name
										)[0]) ||
										""}
								</td>
								<td
									className="text-left uppercase"
									contentEditable
								>
									{(patient?.information?.mother_maiden_name
										?.length > 0 &&
										motherMaindenName(
											patient?.information
												?.mother_maiden_name
										)[1]) ||
										""}
								</td>
								<td
									className="text-center uppercase"
									contentEditable
								></td>
								<td contentEditable className=" uppercase">
									{(patient?.information?.mother_maiden_name
										?.length > 0 &&
										motherMaindenName(
											patient?.information
												?.mother_maiden_name
										)[2]) ||
										""}
								</td>
								<td contentEditable>
									<div className="flex items-center justify-center">
										<CheckBox />
									</div>
								</td>
								<td className="">
									<div className="flex items-center justify-center">
										<CheckBox />
									</div>
								</td>
							</tr>
							<tr>
								<th className="h-[32px] text-left">
									SPOUSE <br />
									<small className="text-[8px]">
										(If Married)
									</small>
								</th>
								<td contentEditable className=" uppercase"></td>
								<td contentEditable className=" uppercase"></td>
								<td contentEditable className=" uppercase"></td>
								<td contentEditable className=" uppercase"></td>
								<td contentEditable className=" uppercase">
									<div className="flex items-center justify-center">
										<CheckBox />
									</div>
								</td>
								<td className="">
									<div className="flex items-center justify-center">
										<CheckBox />
									</div>
								</td>
							</tr>
						</table>
						<table className="bordered-table text-xs w-full">
							<tr>
								<td colSpan={2}>
									<div className="flex flex-col pb-3 px-1 pt-1">
										<b className="text-xs">DATE OF BIRTH</b>
										<div className="flex items-center">
											<BoxInputGroup label="m">
												<BoxInput
													defaultValue={
														getBirthDayYYYYMMDD(
															patient?.birthday
														)[4]
													}
													className="border"
													inputClassName="w-3 h-5"
												/>
											</BoxInputGroup>
											<BoxInputGroup
												label="m"
												className="mr-1"
											>
												<BoxInput
													defaultValue={
														getBirthDayYYYYMMDD(
															patient?.birthday
														)[5]
													}
													className="border"
													inputClassName="w-3 h-5"
												/>
											</BoxInputGroup>
											<BoxInputGroup label="d">
												<BoxInput
													defaultValue={
														getBirthDayYYYYMMDD(
															patient?.birthday
														)[6]
													}
													className="border"
													inputClassName="w-3 h-5"
												/>
											</BoxInputGroup>
											<BoxInputGroup
												label="d"
												className="mr-1"
											>
												<BoxInput
													defaultValue={
														getBirthDayYYYYMMDD(
															patient?.birthday
														)[7]
													}
													className="border"
													inputClassName="w-3 h-5"
												/>
											</BoxInputGroup>
											<BoxInputGroup label="y">
												<BoxInput
													defaultValue={
														getBirthDayYYYYMMDD(
															patient?.birthday
														)[0]
													}
													className="border"
													inputClassName="w-3 h-5"
												/>
											</BoxInputGroup>
											<BoxInputGroup label="y">
												<BoxInput
													defaultValue={
														getBirthDayYYYYMMDD(
															patient?.birthday
														)[1]
													}
													className="border"
													inputClassName="w-3 h-5"
												/>
											</BoxInputGroup>
											<BoxInputGroup label="y">
												<BoxInput
													defaultValue={
														getBirthDayYYYYMMDD(
															patient?.birthday
														)[2]
													}
													className="border"
													inputClassName="w-3 h-5"
												/>
											</BoxInputGroup>
											<BoxInputGroup label="y">
												<BoxInput
													defaultValue={
														getBirthDayYYYYMMDD(
															patient?.birthday
														)[3]
													}
													className="border"
													inputClassName="w-3 h-5"
												/>
											</BoxInputGroup>
										</div>
									</div>
								</td>
								<td contentEditable>
									<div className="flex flex-col px-1 pb-1">
										<div className="mb-0">
											<b className="text-xs  mr-1">
												PLACE OF BIRTH
											</b>
											<span className="text-[8px] font-bold">
												(City/Municipality/Province/Country)
											</span>
										</div>
										<span className="text-[8px] font-bold">
											(Please indicate country if born
											outside the Philippines)
										</span>
										<span
											contentEditable
											className=" uppercase"
										>
											{patient?.birthplace || ""}&nbsp;
										</span>
									</div>
								</td>
								<td rowSpan={2}>
									<div className="flex flex-col p-1 gap-3">
										<div className="flex flex-col">
											<b className="text-xs">
												PHILSYS ID NUMBER{" "}
												<small className="">
													{" "}
													(Optional)
												</small>
											</b>
											<div className="flex items-center">
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[0]
													)}
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[1]
													)}
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[2]
													)}
												/>
												<BoxInput
													className="border mr-1"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[3]
													)}
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[5]
													)}
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[6]
													)}
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[7]
													)}
												/>
												<BoxInput
													className="border mr-1"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[8]
													)}
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[10]
													)}
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[11]
													)}
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[12]
													)}
												/>
												<BoxInput
													className="border mr-1"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[13]
													)}
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[15]
													)}
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[16]
													)}
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[17]
													)}
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
													defaultValue={getStringArray(
														patient?.information
															?.philsys[18]
													)}
												/>
											</div>
										</div>
										<div className="flex flex-col">
											<b className="text-xs">
												TAX PAYER IDENTIFICATION NUMBER
												<small className="">
													(TIN) (Optional)
												</small>
											</b>
											<div className="flex items-center">
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border mr-1"
													inputClassName="w-3 h-5"
												/>

												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border mr-1"
													inputClassName="w-3 h-5"
												/>

												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border"
													inputClassName="w-3 h-5"
												/>
											</div>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td className="align-top">
									<div className="flex flex-col pb- px-1 pt-1">
										<b className="text-xs">SEX</b>
										<div className="flex flex-col">
											<CheckBox
												label="Male"
												checked={
													patient?.gender == "male"
														? "checked"
														: ""
												}
											/>
											<CheckBox
												label="Female"
												checked={
													patient?.gender == "female"
														? "checked"
														: ""
												}
											/>
										</div>
									</div>
								</td>
								<td contentEditable>
									<div className="flex flex-col pb- px-1 pt-1">
										<b className="text-xs">CIVIL STATUS</b>
										<div className="grid grid-cols-2">
											<CheckBox
												label="Single"
												checked={
													patient?.information?.marital_status?.includes(
														"single"
													)
														? "checked"
														: ""
												}
											/>
											<CheckBox
												label="Annulled"
												checked={
													patient?.information?.marital_status?.includes(
														"annul"
													)
														? "checked"
														: ""
												}
											/>
											<CheckBox
												label="Married"
												checked={
													patient?.information?.marital_status?.includes(
														"married"
													)
														? "checked"
														: ""
												}
											/>
											<CheckBox
												label="Widower"
												checked={
													patient?.information?.marital_status?.includes(
														"widow"
													)
														? "checked"
														: ""
												}
											/>
											<CheckBox
												label="Legally Separated"
												className="col-span-2"
												checked={
													patient?.civil_status ==
													"legally-separated"
														? "checked"
														: ""
												}
											/>
										</div>
									</div>
								</td>
								<td contentEditable>
									<div className="flex flex-col pb- px-1 pt-1">
										<b className="text-xs mb-2">
											CITIZENSHIP
										</b>
										<div className="grid grid-cols-2">
											<CheckBox
												label="FILIPINO"
												checked={
													patient?.citizenship ==
													"fil"
														? "checked"
														: ""
												}
											/>
											<CheckBox
												label="FOREIGN NATIONAL"
												checked={
													patient?.citizenship ==
													"f-n"
														? "checked"
														: ""
												}
											/>
											<CheckBox
												label="DUAL CITIZEN "
												checked={
													patient?.citizenship ==
													"d-c"
														? "checked"
														: ""
												}
											/>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>

					<div className="flex items-center border-y p-1 justify-center bg-[#dbe2c6]">
						<b className="text-sm">
							II. ADDRESS and CONTACT DETAILS
						</b>
					</div>
					<div className="border-y">
						<table className="bordered-table text-xs w-full">
							<tr>
								<td className="pb-1 !align-top">
									<div className="flex flex-col pb- px-1 pt-1">
										<b className="text-xs">
											PERMANENT HOME ADDRESS
										</b>
										<div className="w-full grid grid-cols-4 gap-3">
											<b className="text-[8px]">
												Unit/Room No./Floor
											</b>
											<b className="text-[8px]">
												Building Name
											</b>
											<b className="text-[8px]">
												Lot/Block/Phase/House Number
											</b>
											<b className="text-[8px]">
												Street Name
											</b>
										</div>
									</div>
									<div className="w-full grid grid-cols-4 gap-3">
										<div
											contentEditable
											className=" uppercase"
										></div>
										<div
											contentEditable
											className=" uppercase"
										></div>
										<div
											contentEditable
											className=" uppercase"
										>
											{patient?.household?.house_number ||
												""}
										</div>
										<div
											contentEditable
											className=" uppercase"
										>
											{patient?.household?.street !=
											"UNKNOWN"
												? patient?.household?.street
												: ""}
										</div>
									</div>
								</td>
								<td rowSpan={4} className="w-1/4">
									<div className="flex flex-col w-full gap-2 px-1 py-2">
										<RowInput
											label="Home Phone Number"
											subLabel={`(COUNTRY CODE + AREA CODE +
												TELEPHONE NUM BER)`}
											defaultValue={
												patient?.telephone || ""
											}
										/>
										<RowInput
											label="Mobile Number (Required)"
											defaultValue={patient?.mobile || ""}
										/>
										<RowInput label="Business (Direct Line)" />
										<RowInput
											label="E-mail Address (Required for OFW)"
											defaultValue={patient?.email || ""}
										/>
									</div>
								</td>
							</tr>

							<tr>
								<td className="!align-top">
									<div className="flex flex-col pb-1 px-1 pt-1">
										<div className="w-full flex items-center justify-between gap-3">
											<div className="text-[8px]">
												<span>Subdivision</span>
												<input
													type="text"
													className="uppercase passive-input w-[100px]"
												/>
											</div>
											<div className="text-[8px]">
												<span>Barangay</span>
												<input
													type="text"
													className="uppercase passive-input w-[100px]"
													defaultValue={
														patient?.barangay || ""
													}
												/>
											</div>
											<div className="text-[8px]">
												<span>Municipality/City</span>
												<input
													type="text"
													className="uppercase passive-input w-[100px]"
													defaultValue={
														patient?.municipality ||
														""
													}
												/>
											</div>
											<div className="text-[8px]">
												<span>
													Province/State/Country (If
													abroad)
												</span>
												<input
													type="text"
													className="uppercase passive-input w-[100px]"
													defaultValue={
														patient?.household
															?.province || ""
													}
												/>
											</div>
											<div className="text-[8px]">
												<span>ZIP Code</span>
												<input
													type="text"
													className="uppercase passive-input w-[100px]"
													defaultValue={
														patient?.zip_code || ""
													}
												/>
											</div>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td className=" !align-top">
									<div className="flex flex-col pb-2 px-1 pt-1">
										<b className="text-xs">
											MAILING ADDRESS
										</b>
										<div className="w-full grid grid-cols-4 gap-3">
											<b className="text-[8px]">
												Unit/Room No./Floor
											</b>
											<b className="text-[8px]">
												Building Name
											</b>
											<b className="text-[8px]">
												Lot/Block/Phase/House Number
											</b>
											<b className="text-[8px]">
												Street Name
											</b>
										</div>
										<div className="w-full grid grid-cols-4 gap-3">
											<div
												contentEditable
												className="uppercase"
											></div>
											<div
												contentEditable
												className="uppercase"
											></div>
											<div
												contentEditable
												className="uppercase"
											>
												{patient?.household
													?.house_number || ""}
											</div>
											<div
												contentEditable
												className="uppercase"
											>
												{patient?.household?.street !=
												"UNKNOWN"
													? patient?.household?.street
													: ""}
											</div>
										</div>
									</div>
								</td>
							</tr>

							<tr>
								<td className="!align-top">
									<div className="flex flex-col pb-1 px-2 pt-1">
										<div className="w-full flex items-center justify-between gap-3">
											<div className="text-[8px]">
												<span>Subdivision</span>
												<input
													type="text"
													className="uppercase passive-input w-[100px]"
												/>
											</div>
											<div className="text-[8px]">
												<span>Barangay</span>
												<input
													type="text"
													className="uppercase passive-input w-[100px]"
													defaultValue={
														patient?.barangay || ""
													}
												/>
											</div>
											<div className="text-[8px]">
												<span>Municipality/City</span>
												<input
													type="text"
													className="uppercase passive-input w-[100px]"
													defaultValue={
														patient?.municipality ||
														""
													}
												/>
											</div>
											<div className="text-[8px]">
												<span>
													Province/State/Country (If
													abroad)
												</span>
												<input
													type="text"
													className="uppercase passive-input w-[100px]"
													defaultValue={
														patient?.household
															?.province || ""
													}
												/>
											</div>
											<div className="text-[8px]">
												<span>ZIP Code</span>
												<input
													type="text"
													className="uppercase passive-input w-[100px]"
													defaultValue={
														patient?.zip_code || ""
													}
												/>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>

					<div className="flex items-center border-y p-1 justify-center bg-[#dbe2c6]">
						<b className="text-sm">
							III. DECLARATION OF DEPENDENTS
						</b>
					</div>
					<div className="border-y">
						<table className="bordered-table text-xs w-full">
							<tr>
								<th rowSpan={2}>LAST NAME</th>
								<th rowSpan={2}>FIRST NAME</th>
								<th rowSpan={2} className="w-[62px]">
									<div className="text-center text-[8px]">
										NAME
										<br />
										EXTENSION
										<br />
										(Jr./Sr./III)
									</div>
								</th>
								<th rowSpan={2}>MIDDLE NAME</th>
								<th rowSpan={2}>
									<span className="text-[8px]">
										RELATIONSHIP
									</span>
								</th>
								<th rowSpan={2} className="w-[62px]">
									<div className="text-center text-[8px]">
										DATE OF
										<br />
										BIRTH
										<br />
										(mm-dd-yyyy)
									</div>
								</th>
								<th rowSpan={2}>
									<span className="text-[8px]">
										CITIZENSHIP
									</span>
								</th>
								<th className="w-[44px]">
									<div className="text-center text-[8px]">
										NO
										<br />
										MIDDLE
										<br />
										NAME
									</div>
								</th>
								<th className="w-[44px] text-[8px]">MONONYM</th>
								<th rowSpan={2} className="w-11 text-[8px]">
									Check if with Permanent Disability
								</th>
							</tr>
							<tr>
								<td colSpan={2}>
									<span className="text-[6px] flex items-center justify-center text-center font-bold">
										(Check if applicable only)
									</span>
								</td>
							</tr>
							<tr>
								<td
									contentEditable
									className="uppercase !h-8"
								></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td>
									<div className="flex items-center justify-center p-[2px]">
										<CheckBox />
									</div>
								</td>
								<td>
									<div className="flex items-center justify-center p-[2px]">
										<CheckBox />
									</div>
								</td>
								<td>
									<div className="flex items-center justify-center p-[2px]">
										<CheckBox />
									</div>
								</td>
							</tr>
							<tr>
								<td
									contentEditable
									className="uppercase !h-8"
								></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td>
									<div className="flex items-center justify-center p-[2px]">
										<CheckBox />
									</div>
								</td>
								<td>
									<div className="flex items-center justify-center p-[2px]">
										<CheckBox />
									</div>
								</td>
								<td>
									<div className="flex items-center justify-center p-[2px]">
										<CheckBox />
									</div>
								</td>
							</tr>
							<tr>
								<td contentEditable className="!h-8"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td>
									<div className="flex items-center justify-center p-[2px]">
										<CheckBox />
									</div>
								</td>
								<td>
									<div className="flex items-center justify-center p-[2px]">
										<CheckBox />
									</div>
								</td>
								<td>
									<div className="flex items-center justify-center p-[2px]">
										<CheckBox />
									</div>
								</td>
							</tr>
							<tr>
								<td
									contentEditable
									className="uppercase !h-8"
								></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td contentEditable className="uppercase"></td>
								<td>
									<div className="flex items-center justify-center p-[2px]">
										<CheckBox />
									</div>
								</td>
								<td>
									<div className="flex items-center justify-center p-[2px]">
										<CheckBox />
									</div>
								</td>
								<td>
									<div className="flex items-center justify-center p-[2px]">
										<CheckBox />
									</div>
								</td>
							</tr>
						</table>
					</div>

					<div className="flex items-center border-y p-1 justify-center bg-[#dbe2c6]">
						<b className="text-sm">IV. MEMBER TYPE</b>
					</div>
					<div className="border-y">
						<table className="bordered-table text-xs w-full">
							<tr>
								<td colSpan={3}>
									<div className="w-full flex flex-col">
										<div className="w-full text-center font-bold text-xs">
											DIRECT CONTRIBUTOR
										</div>
										<div className="grid grid-cols-5 gap-2">
											<div className="flex flex-col p-1 gap-1 col-span-2">
												<CheckBox label="Employed Private" />
												<CheckBox label="Employed Government" />
												<CheckBox label="Professional Practitioner" />
												<CheckBox label="Self-Earning Individual" />
												<div className="flex flex-col gap-1 pl-4">
													<CheckBox label="Individual" />
													<CheckBox label="Sole Proprietor" />
													<CheckBox label="Group Enrollment Scheme" />
												</div>
												<TextInput
													className="pl-8"
													labelClassName="whitespace-pre"
												/>
											</div>

											<div className="flex flex-col p-1 col-span-3 gap-1">
												<div className="flex items-center gap-11">
													<CheckBox label="Kasambahay" />
													<CheckBox label="Family Driver" />
												</div>
												<CheckBox label="Migrant Worker" />
												<div className="flex items-center gap-4 pl-4">
													<CheckBox label="Land-Based" />
													<CheckBox
														label="Sea-Based
"
													/>
												</div>
												<CheckBox label="Lifetime Member" />
												<CheckBox label="Filipinos with Dual Citizenship / Living Abroad" />
												<CheckBox
													label="Foreign National
"
												/>
												<TextInput
													label="PRA SRRV No."
													className="pl-5"
													labelClassName="whitespace-pre"
												/>
												<TextInput
													label="ACR I-Card No."
													className="pl-5"
													labelClassName="whitespace-pre"
												/>
											</div>
										</div>
									</div>
								</td>

								<td className="w-2/5 !align-top">
									<div className="w-full flex flex-col p-1 items-start">
										<div className="w-full text-center font-bold text-xs mb-2">
											INDIRECT CONTRIBUTOR
										</div>
										<div className="grid grid-cols-2 gap-4">
											<div className="flex flex-col gap-1">
												<CheckBox label="Listahan" />
												<CheckBox label="4Ps/MCCT" />
												<CheckBox label="Senior Citizen" />
												<CheckBox label="PAMANA" />
												<CheckBox label="KIA/KIPO" />
												<CheckBox label="Bangsamoro/Normalization" />
											</div>
											<div className="flex flex-col gap-1">
												<CheckBox label="LGU-sponsored " />
												<CheckBox label="NGA-sponsored" />
												<CheckBox label="Private-sponsored " />
												<CheckBox label="Person with Disability " />
												<TextInput
													label="
												PWD ID No."
													className="pl-4"
													labelClassName="whitespace-pre"
												/>
											</div>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td className="!align-top p-1 text-[10px]">
									<div className="flex w-[200px] flex-col gap-2">
										<div className="flex">
											<b>PROFESSION:</b>
											<span className="text-[6px]">
												(Except Employed, Lifetime
												Members and Sea-based Migrant
												Worker)
											</span>
										</div>
										<div
											contentEditable
											className="h-full"
										></div>
									</div>
								</td>
								<td className="!align-top p-1 text-[10px]">
									<div className="flex flex-col gap-2">
										<b className="whitespace-pre">
											MONTHLY INCOME:
										</b>
										<div contentEditable>
											{formatCurrency(
												patient?.information?.month_cash
											) || ""}
										</div>
									</div>
								</td>
								<td className="!align-top p-1 text-[10px]">
									<div className="flex flex-col gap-2">
										<b className="whitespace-pre">
											PROOF OF INCOME:
										</b>
										<div
											contentEditable
											className="uppercase"
										></div>
									</div>
								</td>
								<td className="!align-top">
									<div className="w-full flex flex-col p-1">
										<div className="w-full text-center font-bold text-xs mb-2">
											For PhilHealth Use only:
										</div>
										<div className="grid grid-cols-1 gap-1">
											<CheckBox label="Point of Service (POS) Financially Incapable" />
											<CheckBox label="Financially Incapable" />
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td colSpan={4} className="!align-top">
									<div className="flex pt-3">
										<b>
											This form may be reproduced and is
											not for sale
										</b>
										<b className="ml-20">
											Continue at the back
										</b>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>

				<div className="bg-white flex flex-col w-[8.5in] min-h-[13in] border">
					<div className="flex items-center border-y p-1 justify-center bg-[#dbe2c6]">
						<b className="text-sm">V. UPDATING/AMENDMENT</b>
					</div>
					<div className="border-y">
						<table className="bordered-table text-xs w-full">
							<tr>
								<th className="w-1/3 text-left py-2 px-2">
									Please check:
								</th>
								<th className="w-1/3 text-center py-2 px-2">
									FROM
								</th>
								<th className="w-1/3 text-center py-2 px-2">
									TO
								</th>
							</tr>
							<tr>
								<td className="w-1/3 text-left px-1 py-3">
									<CheckBox
										label={
											<div className="flex flex-col">
												<span>
													Change/Correction of Name
												</span>
												<span className="text-[6px]">
													(Last Name, First Name, Name
													Extension (Jr./Sr./III)
													Middle Name)
												</span>
											</div>
										}
									/>
								</td>
								<td
									className="w-1/3 text-left uppercase"
									contentEditable
								></td>
								<td
									className="w-1/3 text-left uppercase"
									contentEditable
								></td>
							</tr>
							<tr>
								<td className="w-1/3 text-left px-1 py-3">
									<CheckBox
										label={`Correction of Date of Birth`}
									/>
								</td>
								<td
									className="w-1/3 text-left uppercase"
									contentEditable
								></td>
								<td
									className="w-1/3 text-left uppercase"
									contentEditable
								></td>
							</tr>
							<tr>
								<td className="w-1/3 text-left px-1 py-3">
									<CheckBox label={`Correction of Sex`} />
								</td>
								<td
									className="w-1/3 text-left uppercase"
									contentEditable
								></td>
								<td
									className="w-1/3 text-left uppercase"
									contentEditable
								></td>
							</tr>
							<tr>
								<td className="w-1/3 text-left px-1 py-3">
									<CheckBox
										label={`Change of Civil Status`}
									/>
								</td>
								<td
									className="w-1/3 text-left uppercase"
									contentEditable
								></td>
								<td
									className="w-1/3 text-left uppercase"
									contentEditable
								></td>
							</tr>
							<tr>
								<td className="w-1/3 text-left px-1">
									<CheckBox
										label={`Updating of Personal Information/Address/
										Telephone Number/Mobile Number/e-mail
										Address`}
									/>
								</td>
								<td
									className="w-1/3 text-left uppercase"
									contentEditable
								></td>
								<td
									className="w-1/3 text-left uppercase"
									contentEditable
								></td>
							</tr>
						</table>
					</div>

					<div className="border-y my-1">
						<table className="bordered-table text-xs w-full">
							<tr>
								<td className="w-2/3" rowSpan={2}>
									<div className="flex flex-col p-2 text-justify">
										<p className="text-sm leading-tight">
											Under penalty of law, I hereby
											attest that the information
											provided, including the documents I
											have attached to this form, are true
											and accurate to the best of my
											knowledge. I agree and authorize
											PhilHealth for the subsequent
											validation, verification and for
											other data sharing purposes only
											under the following circumstances:
										</p>
										<ul className="text-sm pl-4 pt-2 list-disc ml-4">
											<li>
												As necessary for the proper
												execution of processes related
												to the legitimate and declared
												purpose;
											</li>
											<li>
												The use or disclosure is
												reasonably necessary, required
												or authorized by or under the
												law; and,
											</li>
											<li>
												Adequate security measures are
												employed to protect my
												information.
											</li>
										</ul>
										<div className="flex items-end gap-6">
											<div className="grid grid-cols-12 gap-4 w-full">
												<UnderlineInput
													label="Member’s Signature over Printed Name"
													labelClassName="font-bold pt-1"
													className="col-span-8"
													inputClassName="text-center "
													defaultValue={patientFullName(
														patient
													)}
												/>
												<UnderlineInput
													label="Date"
													labelClassName="font-bold pt-1"
													className="col-span-4"
													inputClassName="text-center"
													defaultValue={dateMMDDYYYY()}
												/>
											</div>
											<div className="flex flex-col">
												<div className="border rounded-xl w-24 h-20"></div>
												<span className="text-[8px] text-center whitespace-pre pt-1">
													Please affix right <br />
													thumbmark if unable to write
												</span>
											</div>
										</div>
									</div>
								</td>
								<td className="bg-[#dbe2c6]">
									<div className="flex flex-col">
										<div className="flex items-left p-1">
											<b className="text-base">
												FOR PHILHEALTH USE ONLY
											</b>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td className="bg-[#f5f5f5]">
									<div className="flex flex-col">
										<div className="flex text-sm items-left p-1 mb-3">
											<b>RECEIVED BY:</b>
										</div>
										<div className="flex text-xs items-left p-1 mb-0">
											Full Name:
										</div>
										<div className="flex text-sm items-left p-1 mb-3">
											<TextInput
												inputClassName="bg-[#f5f5f5]"
												className="w-full"
											/>
										</div>
										<div className="flex text-xs items-left p-1 mb-0">
											PRO/LHIO/Branch:
										</div>
										<div className="flex text-sm items-left p-1 mb-3">
											<TextInput
												inputClassName="bg-[#f5f5f5]"
												className="w-full"
											/>
										</div>
										<div className="flex text-xs items-left p-1 mb-0">
											Date & Time:
										</div>
										<div className="flex text-sm items-left p-1 mb-3">
											<TextInput
												inputClassName="bg-[#f5f5f5]"
												className="w-full"
											/>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>

					<div className="border-y my-1 flex flex-col p-2">
						<h4 className="font-bold text-center">INSTRUCTIONS</h4>
						<ol className="list-[auto] pl-6 text-sm gap-y-2 py-2">
							<li className="pl-3">
								All information should be written in UPPER
								CASE/CAPITAL LETTERS. If the information is not
								applicable, write “N/A.”
							</li>
							<li className="pl-3">
								All fields are mandatory unless indicated as
								optional. By affixing your signature, you
								certify the truthfulness and accuracy of all
								information provided.
							</li>
							<li className="pl-3">
								A properly accomplished PMRF shall be
								accompanied by a valid proof of identity for
								first time registrants, and supporting documents
								to establish relationship between member and
								dependent/s for updating or request for
								amendment.
							</li>
							<li className="pl-3">
								On the PURPOSE, check the appropriate box if for{" "}
								<u className="font-bold">Registration</u> or for{" "}
								<u className="font-bold">Updating/Amendment</u>{" "}
								of information.
							</li>
							<li className="pl-3">
								Indicate preferred KonSulTa provider near the
								place of work or residence.
							</li>
							<li className="pl-3">
								<p>
									For PERSONAL DETAILS, all name entries
									should follow the format given below. Check
									the appropriate box if registrant has no
									middle name and/or with single name
									(mononym).
								</p>
								<div className="flex text-center justify-between py-2 px-11">
									<div className="flex flex-col">
										<b>LAST NAME</b>
										<span>SANTOS</span>
									</div>
									<div className="flex flex-col">
										<b>FIRST NAME</b>
										<span>JUAN ANDRES</span>
									</div>
									<div className="flex flex-col">
										<b>NAME EXTENSION (Jr./Sr./III)</b>
										<span>III</span>
									</div>
									<div className="flex flex-col">
										<b>MIDDLE NAME</b>
										<span>DELA CRUZ</span>
									</div>
								</div>
							</li>
							<li className="pl-3">
								Indicate registrant’s/member’s name as it
								appears in the birth certificate.
							</li>
							<li className="pl-3">
								The full mother’s maiden name of
								registrant/member must be indicated as it
								appears in the birth certificate.
							</li>
							<li className="pl-3">
								Indicate the full name of spouse if
								registrant/member is married.
							</li>
							<li className="pl-3">
								Indicate the complete permanent and mailing
								addresses and contact numbers.
							</li>
							<li className="pl-3">
								For updating/amendment, check the appropriate
								box to be updated/amended and indicate the
								correct data.
							</li>
							<li className="pl-3">
								For MEMBER TYPE, check the appropriate box which
								best describes your current membership status.
							</li>
							<li className="pl-3">
								For Direct Contributors, except employed,
								sea-based migrant workers and lifetime members,
								indicate the profession, monthly income and
								proof of income to be submitted.
							</li>
							<li className="pl-3">
								For Self-earning individuals, Kasambahays and
								Family Drivers, indicate the actual monthly
								income in the space provided.
							</li>
							<li className="pl-3">
								In declaring dependents, provide the full name
								of the living spouse, children below 21 years
								old, and parents who are 60 years old and above
								totally dependent to the member.
							</li>
							<li className="pl-3">
								Dependents with disability shall be registered
								as principal members in accordance with Republic
								Act 11228 on mandatory PhilHealth coverage for
								all persons with disability (PWD).
							</li>
							<li className="pl-3">
								The registrant must affix his/her signature over
								printed name (or right thumbmark if unable to
								write) and indicate the date when the PMRF was
								signed.
							</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PMRForm;
