import { faVirus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import Img from "src/components/Img";
import LayoutContainer from "src/components/LayoutContainer";
import LocationDisplay from "../components/LocationDisplay";
import AssignClinicPersonnelModal from "./components/AssignClinicPersonnelModal";
import LaboratoryItemManagement from "./components/LaboratoryItemManagement";
import ManageClinicPersonnelModal from "./components/ManageClinicPersonnelModal";

const Personel = ({ personnel }) => {
	return (
		<div className="bg-slate-50 p-4 rounded-xl border flex flex-col items-center  shadow-sm">
			<Img
				type="user"
				name={personnel?.name}
				src={personnel?.avatar}
				className="w-20 h-20 rounded-full bg-white border border-border object-contain mb-3"
			/>
			<span className="text-base font-bold mb-0">{personnel?.name}</span>
			<span className="text-xs">{personnel?.type}</span>
		</div>
	);
};
const PhoClinicProfile = () => {
	const params = useParams();
	const [clinic, setClinic] = useState(null);
	const [loading, setLoading] = useState(true);
	const manage_personel_ref = useRef(null);
	const assign_personel_ref = useRef(null);
	const [clinic_personnel, setClinicPersonnel] = useState([]);

	useEffect(() => {
		setLoading(true);
		let t = setTimeout(() => {
			refreshData();
		}, 800);

		return () => {
			clearTimeout(t);
		};
	}, [params?.id]);

	const refreshData = () => {
		if (params?.id) {
			getClinic(params?.id);
			getClinicPersonnel(params?.id);
		}
	};

	const getClinic = (id) => {
		axios
			.get(`/v1/management/clinic/${id}`)
			.then((res) => {
				setClinic(res.data.data);
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 200);
			});
	};

	const getClinicPersonnel = (id) => {
		axios
			.get(`/v1/management/clinic-personnel/${id}?paginate=9999`)
			.then((res) => {
				setClinicPersonnel(res.data.data);
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 200);
			});
	};
	return (
		<LayoutContainer
			icon={<FontAwesomeIcon icon={faVirus} color="white" size={"2x"} />}
			title="Clinic Profile"
			subtitle="View and add clinic personnel"
		>
			<div className="container bg-white rounded-xl p-6 flex flex-col">
				<div className="mb-3    ">
					<LocationDisplay
						position={
							clinic?.id ? [clinic?.lat, clinic?.lng] : null
						}
					/>
				</div>
				<div className="flex flex-col items-center justify-center mb-4">
					<h2 className="text-xl font-bold text-dark mb-0">
						<u>{clinic?.name}</u>
					</h2>
					<span className="text-placeholder text-xs mb-0 capitalize">
						{clinic?.type} clinic
					</span>
				</div>
				<div className="flex flex-col items-center justify-center w-full mb-4">
					<div className="flex flex-col">
						<p className="text-sm font-bold text-dark mb-0 text-center">
							{`${clinic?.street},`}
						</p>
						<p className="text-sm font-bold text-dark mb-0 text-center">
							{`${clinic?.purok?.name}, ${clinic?.barangay?.name}, ${clinic?.municipality?.name}`}
						</p>
						<span className="text-placeholder text-xs mb-0 text-center">
							Address
						</span>
					</div>
				</div>
				<hr className="w-full" />
				<div className="flex flex-col  mb-4">
					<div className="flex items-center gap-4 mb-4">
						<span className="text-sm font-bold text-dark">
							Clinic Personnel
						</span>
						<ActionBtn
							type="primary"
							size="sm"
							className="gap-2"
							onClick={(e) => {
								manage_personel_ref.current.show();
							}}
						>
							<FlatIcon icon="rr-edit" />
							Manage personnel
						</ActionBtn>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
						{clinic_personnel.map((data) => {
							return (
								<Personel
									personnel={data}
									key={`p-list-${data?.id}`}
								/>
							);
						})}
						{clinic_personnel.length == 0 ? (
							<p className="text-placeholder ">
								No personnel assigned yet.
							</p>
						) : (
							""
						)}
					</div>
				</div>

				<hr className="w-full" />

				{/* 
				<div className="flex flex-col  mb-4">
					
				<LaboratoryItemManagement />

					</div> */}
			</div>

			<ManageClinicPersonnelModal
				ref={manage_personel_ref}
				refreshData={refreshData}
				assign_personel_ref={assign_personel_ref}
				clinic_personnel={clinic_personnel}
				setClinicPersonnel={setClinicPersonnel}
			/>
			<AssignClinicPersonnelModal
				ref={assign_personel_ref}
				refreshData={refreshData}
				manage_personel_ref={manage_personel_ref}
				clinic_personnel={clinic_personnel}
				setClinicPersonnel={setClinicPersonnel}
			/>
		</LayoutContainer>
	);
};

export default PhoClinicProfile;
