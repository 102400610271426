import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IsMountedRef from "src/utils/IsMountedRef";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";
import Details from "./details";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const imageLocation = process.env.REACT_APP_API_IMAGE;

const PatientForAdmit = () => {
  const classes = useStyles();
  const mounted = IsMountedRef();
  const [patients, setPatients] = useState([]);
  const { users } = useContext(UsersData);

  const [selectedPatient, setSelectedPatient] = useState(null);

  const getPatientForAdmit = React.useCallback(async () => {
    let { data } = await axios.get(`admitting/patients/for-admitlist`, {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setPatients(data);
    }
  }, [mounted, users]);

  useEffect(() => {
    getPatientForAdmit();
  }, [getPatientForAdmit]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5} lg={4}>
          <Card>
            <CardHeader title={"Patients For Admit"} />
            <CardContent>
              <List>
                {patients.length > 0 ? (
                  patients.map((data, key) => (
                    <ListItem
                      key={key}
                      button
                      onClick={() => {
                        setSelectedPatient(data);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          className={classes.primary}
                          src={`${imageLocation}patients/${data.image}`}
                          alt=""
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${data.lastname}, ${data.firstname}`}
                        secondary={Notify.createdAt(data.created_at)}
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography color="secondary">No patient found.</Typography>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {selectedPatient && (
          <Grid item xs={12} md={7} lg={8}>
            <Details details={selectedPatient} />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default PatientForAdmit;
