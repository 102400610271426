import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";

import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Notify from "src/notification/Notify";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";
import { geolocations } from "src/helpers/locations";

const AddPhoUserModal = (props, ref) => {
	const { addToList } = props;
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		watch,
		control,
		formState: { errors },
	} = useForm();
	const [user, setUser] = useState(null);
	const [open, setOpen] = useState(false);
	const [municipalities, setMunicipalities] = useState([]);
	const [brgys, setBrgys] = useState([]);
	const [purokList, setPurokList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [provinceList, setProvinceList] = useState([]);
	const [municipalityList, setMunicipalityList] = useState([]);
	const [puroks, setPuroks] = useState([]);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));
	useEffect(() => {
		if (user?.municipality?.id) {
			setBrgys(
				municipalities.find((x) => x.id == user?.municipality?.id)
					?.barangays || []
			);
		}
	}, [user?.municipality?.id]);
	useEffect(() => {
		if (user?.municipality && brgys?.length > 0) {
			setTimeout(() => {
				setValue("barangay", user?.barangay?.id);
				getPurokList(user?.barangay?.id);
			}, 200);
		}
	}, [user?.municipality?.id, brgys]);
	useEffect(() => {
		if (user?.barangay?.id) {
			setTimeout(async () => {
				await getPurokList(user?.barangay?.id);
				setValue("purok", user?.purok?.id);
			}, 200);
		}
	}, [user?.barangay?.id]);

	const getUser = (id) => {
		axios.get(`/v1/pho/users/${id}`);
	};
	const show = (data) => {
		if (data) {
			getUser(data.id);
			console.log("shoowww", data);
			setUser(data);
			setTimeout(() => {
				setValue("type", data?.type);
				setValue("municipality", data?.municipality?.id);
				setValue("username", data?.username);
				setValue("email", data?.email);
			}, 500);
		}
		reset();
		getLocations();
		setOpen(true);
	};
	const hide = () => {
		setUser(null);
		reset();
		setOpen(false);
	};
	const submit = (data) => {
		setLoading(true);
		let formData = new FormData();
		Object.keys(data).map((key) => {
			formData.append(key, data[key]);
		});
		if (user?.id) {
			formData.append("_method", "PATCH");
		}
		axios
			.post(`/v1/pho/users${user?.id ? `/${user?.id}` : ""}`, formData)
			.then(() => {
				addToList(data);
				setTimeout(() => {
					if (user?.id) {
						Notify.customToast(
							"success",
							"User updated successfully!"
						);
					} else {
						Notify.customToast(
							"success",
							"User created successfully!"
						);
					}
				}, 400);
				hide();
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const getLocations = () => {
		axios.get("/v1/locations").then((res) => {
			setMunicipalities(res.data);
		});
	};

	const getPurokList = (brgy_id) => {
		return axios
			.get(`v1/purok-by-barangay?barangay_id=${brgy_id}`)
			.then((res) => {
				setPurokList(res.data);
			});
	};
	return (
		<Modal open={open} hide={hide} size="lg" className="w-full">
			<ModalHeader
				title={`${user?.id ? "Update" : "Add"} Health Officer Account`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="flex flex-col lg:grid lg:grid-cols-3 gap-6">
					{console.log("errrrorrr", errors)}
					<TextField
						className="w-full col-span-3"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`User type`}
						variant="outlined"
						error={Boolean(errors?.type?.message)}
						helperText={errors?.type?.message}
						{...register("type", {
							required: "This field is required.",
						})}
						select
					>
						{/* <option value="MUNICIPAL-HO">City HO</option>
						<option value="BARANGAY-HO">Barangay HO</option>
						<option value="PUROK-HO">Purok HO</option> */}
						{/* <option value="PHO-HO">Health Officer</option> */}
						{/* <option value="PHO-FP">Health Officer</option> */}
						<option value="LMIS-WH">LMIS Warehuse</option>
						<option value="LMIS-CNOR">LMIS Consignor</option>
						<option value="LMIS-SPH">SPH LMIS Pharmacy User</option>
						<option value="LMIS-RHU">RHU LMIS Pharmacy User</option>
						<option value="LMIS-BHS">BHS LMIS Pharmacy User</option>
						<option value="BHS-BHW">
							BHS - Barangay Health Worker
						</option>
						<option value="BHS-PHAR">BHS - Pharmacist</option>
						<option value="RHU-NURSE">RHU - Nurse</option>
						<option value="RHU-DOCTOR">RHU - Doctor</option>
						<option value="RHU-PHARMACY">RHU - Pharmacist</option>
						<option value="RHU-LAB">RHU - Laboratory</option>
						<option value="RHU-XRAY">RHU - X-Ray</option>
						<option value="SPH-NURSE">SPH - Nurse</option>
						<option value="SPH-DOCTOR">SPH - Doctor</option>
						<option value="SPH-PHAR">SPH - Pharmacist</option>
						<option value="SPH-LAB">SPH - Laboratory</option>
						<option value="SPH-XRAY">SPH - X-Ray</option>
						<option value="SPH-BILLING">SPH - Billing</option>
						<option value="SPH-CASHIER">SPH - Cashier</option>
					</TextField>
					<div className="lg:col-span-4">
						<Controller
							name="region"
							control={control}
							rules={{
								required: {
									value: true,
									message: "This field is required",
								},
							}}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: {
									invalid,
									isTouched,
									isDirty,
									error,
								},
							}) => (
								<ReactSelectInputField
									isClearable={false}
									label={
										<>
											Select Region
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									inputClassName=" "
									ref={ref}
									value={value}
									onChange={onChange}
									onChangeGetData={(data) => {
										setProvinceList(
											Object.keys(data.province_list).map(
												(key) => {
													return {
														name: key,
														...data.province_list[
															key
														],
													};
												}
											)
										);
									}} // send value to hook form
									onBlur={onBlur} // notify when input is touched
									error={error?.message}
									placeholder="Select Province"
									options={Object.values(geolocations).map(
										(loc) => ({
											value: loc?.region_name,
											label: loc?.region_name,
											province_list: loc?.province_list,
										})
									)}
								/>
							)}
						/>
					</div>
					<div className="lg:col-span-4">
						<Controller
							name="province"
							control={control}
							rules={{
								required: {
									value: true,
									message: "This field is required",
								},
							}}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: {
									invalid,
									isTouched,
									isDirty,
									error,
								},
							}) => (
								<ReactSelectInputField
									isClearable={false}
									label={
										<>
											Select Province
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									inputClassName=" "
									ref={ref}
									value={value}
									onChange={onChange} // send value to hook form
									onChangeGetData={(data) => {
										setMunicipalityList(
											Object.keys(
												data.municipality_list
											).map((key) => {
												return {
													name: key,
													...data.municipality_list[
														key
													],
												};
											})
										);
									}}
									onBlur={onBlur} // notify when input is touched
									error={error?.message}
									placeholder="Select Province"
									options={provinceList.map((province) => ({
										label: province?.name,
										value: province?.name,
										municipality_list:
											province?.municipality_list,
									}))}
								/>
							)}
						/>
					</div>
					<div className="lg:col-span-4">
						<Controller
							name="municipality"
							control={control}
							rules={{
								required: {
									value: true,
									message: "This field is required",
								},
							}}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: {
									invalid,
									isTouched,
									isDirty,
									error,
								},
							}) => (
								<ReactSelectInputField
									isClearable={false}
									label={
										<>
											Select Municipality
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									placeholder="Select Municipality"
									inputClassName="normal-case"
									ref={ref}
									value={value}
									onChange={(data) => {
										let selected_ = locations?.find(
											(x) =>
												String(x.name).toLowerCase() ==
												String(data).toLowerCase()
										);
										console.log(
											"selected_selected_",
											String(data).toLowerCase(),
											selected_
										);
										// setBrgys(
										// 	selected_?.barangays
										// );
										setValue(
											"zip_code",
											selected_?.zipcode || ""
										);
										onChange(data);
									}} // send value to hook form
									onChangeGetData={(data) => {
										setBrgys(
											data.barangay_list.map((key) => {
												return {
													name: key,
												};
											})
										);
									}}
									onBlur={onBlur} // notify when input is touched
									error={error?.message}
									options={municipalityList.map(
										(municipality) => ({
											label: municipality?.name,
											value: municipality?.name,
											barangay_list:
												municipality?.barangay_list,
										})
									)}
								/>
							)}
						/>
					</div>
					<div className="lg:col-span-4">
						<Controller
							name="barangay"
							control={control}
							rules={{
								required: {
									value: true,
									message: "This field is required",
								},
							}}
							onChange={(data) => {}}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: {
									invalid,
									isTouched,
									isDirty,
									error,
								},
							}) => (
								<ReactSelectInputField
									isClearable={false}
									label={
										<>
											Select Barangay
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									inputClassName=" "
									ref={ref}
									value={value}
									onChange={(data) => {
										let selected_ = brgys?.find(
											(x) => x.name == data
										);
										setPuroks(selected_?.puroks);
										onChange(data);
									}} // send value to hook form
									onBlur={onBlur} // notify when input is touched
									error={error?.message}
									placeholder="Select Barangay"
									options={brgys.map((data) => ({
										label: data?.name,
										value: data?.name,
									}))}
								/>
							)}
						/>
					</div>
				</div>
				<hr className="my-8" />
				<h5 className="mb-3">Account Information</h5>
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
					<TextField
						className="lg:col-span-2"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`Name`}
						variant="outlined"
						error={Boolean(errors?.name?.message)}
						helperText={errors?.name?.message}
						{...register("name", {
							required: "This field is required.",
						})}
					/>
					<TextField
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`Username`}
						variant="outlined"
						error={Boolean(errors?.name?.message)}
						helperText={errors?.name?.message}
						{...register("username", {
							required: "This field is required.",
						})}
					/>
					<TextField
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`Email address`}
						variant="outlined"
						error={Boolean(errors?.email?.message)}
						helperText={errors?.email?.message}
						{...register("email", {
							required: "This field is required.",
						})}
					/>
					{user?.id ? (
						""
					) : (
						<>
							<TextField
								SelectProps={{ native: true }}
								InputLabelProps={{ shrink: true }}
								label={`Password`}
								variant="outlined"
								error={Boolean(errors?.password?.message)}
								helperText={errors?.password?.message}
								type={"password"}
								{...register("password", {
									required: "This field is required.",
									validate: (val) => {
										if (watch("password") != val) {
											return "Passwords does not match";
										}
									},
								})}
							/>
							<TextField
								SelectProps={{ native: true }}
								InputLabelProps={{ shrink: true }}
								label={`Confirm Password`}
								variant="outlined"
								error={Boolean(
									errors?.password_confirmation?.message
								)}
								helperText={
									errors?.password_confirmation?.message
								}
								type={"password"}
								{...register("password_confirmation", {
									required: "This field is required.",
									validate: (val) => {
										if (watch("password") != val) {
											return "Passwords does not match";
										}
									},
								})}
							/>
						</>
					)}
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center"}>
				<ActionBtn
					className="ml-auto"
					onClick={handleSubmit(submit)}
					loading={loading}
				>
					Submit
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(AddPhoUserModal);
