import { useRef } from "react";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import UpdateCisPatientProfileModal from "src/disease-treatment/components/UpdateCisPatientProfileModal";
import CollapseDiv from "src/pho/components/CollapseDiv";
import { marital_status_lists } from "src/pho/components/Forms/household/patient_choices";
const Info = ({ title, icon, value }) => {
	return (
		<div className="flex flex-col">
			<div className="flex items-center  text-sm">
				<FlatIcon
					icon={icon}
					className="text-sm mr-2 bg-slate-50 h-8 w-8 rounded-full flex items-center justify-center"
				/>
				<span className="w-full">{value}</span>
			</div>
			<span className="text-[10px] text-slate-400 -mt-1">{title}</span>
		</div>
	);
};
const TRow = ({ title, value }) => {
	return (
		<tr>
			<td className="text-sm pb-2">
				<span className="text-slate-500 text-xs">{title}</span>
			</td>
			<td className="text-sm pb-2 px-2">
				{typeof value == "object"
					? JSON.stringify(value)
					: value || "-"}
			</td>
		</tr>
	);
};
const PatientProfileContent = ({ patient }) => {
	const updateProfileRef = useRef(null);
	return (
		<div className="flex flex-col gap-y-4 items-start">
			<div className="flex flex-col w-full">
				<h5 className="text-sm font-semibold text-indigo-700 w-2/3 flex items-center gap-2">
					<span>
						<img
							src="/patient.png"
							className="h-8 object-contain"
						/>
					</span>
					<div>Peronal Information</div>
					<ActionBtn
						size="sm"
						className="text-sm px-2 ml-"
						type="primary"
						onClick={() => {
							updateProfileRef.current.show();
							// setShown(false);
						}}
					>
						<FlatIcon icon="rr-edit" /> Update
					</ActionBtn>
				</h5>
				<div className="w-2/3 h-[1px] bg-indigo-600 mb-[2px]"></div>
				<div className="w-1/2 h-[1px] bg-indigo-400"></div>
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-2 gap-6 w-full">
				<CollapseDiv
					defaultOpen={true}
					title="I. PERSONAL DETAILS"
					bodyClassName="p-0"
				>
					<div className="square-table w-full">
						<table className="">
							<tbody className="">
								<TRow
									title="Lastname:"
									value={patient?.lastname}
								/>
								<TRow
									title="Firstname:"
									value={patient?.firstname}
								/>
								<TRow
									title="Middlename:"
									value={patient?.middle}
								/>
								<TRow title="Suffix:" value={patient?.suffix} />
								<TRow title="Gender:" value={patient?.gender} />
								<TRow
									title="Civil Status:"
									value={
										patient?.information
											? marital_status_lists.find(
													(x) =>
														x.value ==
														patient?.information
															?.marital_status
											  )?.label
											: patient?.civil_status
									}
								/>
								{patient?.information?.mother_maiden_name ? (
									<>
										<TRow
											title="Mothers Maiden Name:"
											value={
												patient?.information
													?.mother_maiden_name
											}
										/>
									</>
								) : (
									<>
										<TRow
											title="Mothers Maiden Lastname:"
											value={patient?.mother_lastname}
										/>
										<TRow
											title="Mothers Maiden Firstname:"
											value={patient?.mother_firstname}
										/>
										<TRow
											title="Mothers Maiden Middlename:"
											value={patient?.mother_middlename}
										/>
									</>
								)}
							</tbody>
						</table>
					</div>
				</CollapseDiv>
				<CollapseDiv
					defaultOpen={true}
					title="II. ADDRESS AND CONTACT DETAILS"
				>
					<div className="square-table w-full">
						<table className="">
							<tbody className="">
								<TRow title="Latitude:" value={patient?.lat} />
								<TRow title="Latitude:" value={patient?.lng} />
								<TRow title="Region:" value={patient?.region} />
								<TRow
									title="Province:"
									value={patient?.province}
								/>
								<TRow
									title="Municipality:"
									value={patient?.municipality}
								/>
								<TRow
									title="Barangay:"
									value={patient?.barangay}
								/>
								<TRow title="Purok:" value={patient?.purok} />
								<TRow
									title="Street:"
									value={
										patient?.household?.street
											? patient?.household?.street
											: patient?.street
									}
								/>
								<TRow
									title="UNIT/Room No./Floor:"
									value={patient?.unit || "N/A"}
								/>
								<TRow
									title="Lot/Blk/phase/House No.:"
									value={
										patient?.household?.house_number
											? patient?.household?.house_number
											: patient?.house_number
									}
								/>
								<TRow
									title="Subdivision:"
									value={patient?.subdivision || "N/A"}
								/>
							</tbody>
						</table>
					</div>
				</CollapseDiv>
				<CollapseDiv
					defaultOpen={true}
					title="III. DECLARATION OF DEPENDENTS"
				>
					{patient?.dependents?.map((dependent) => {
						return (
							<div className="square-table w-full border p-2 rounded-lg mb-2">
								<table className="">
									<tbody className="">
										<TRow
											title="Lastname:"
											value={dependent?.lastname}
										/>
										<TRow
											title="Firstname:"
											value={dependent?.firstname}
										/>
										<TRow
											title="Middlename:"
											value={dependent?.middle_name || ""}
										/>
										<TRow
											title="Relationship:"
											value={dependent?.relationship}
										/>
									</tbody>
								</table>
							</div>
						);
					})}
					{patient?.dependents?.length == 0 ? (
						<p className="text-slate-200 italic">
							No dependents declared.
						</p>
					) : (
						""
					)}
				</CollapseDiv>
				<CollapseDiv defaultOpen={true} title="IV. MEMBER TYPE">
					<div className="square-table w-full mb-4">
						<table className="">
							<tbody className="">
								<TRow
									title="Philhealth No.:"
									value={patient?.philhealth}
								/>
								<TRow title="TIN:" value={patient?.tin} />
							</tbody>
						</table>
					</div>
					<div className="square-table w-full mb-4">
						<table className="">
							<tbody className="">
								<TRow
									title="Direct Contributor:"
									value={patient?.direct_contributor}
								/>
								<TRow
									title="Indirect Contributor:"
									value={patient?.indirect_contributor}
								/>
							</tbody>
						</table>
					</div>
					<div className="square-table w-full mb-2">
						<table className="">
							<tbody className="">
								<TRow
									title="Profession:"
									value={patient?.profession || "-"}
								/>
								<TRow
									title="Salary:"
									value={patient?.salary || "-"}
								/>
							</tbody>
						</table>
					</div>
				</CollapseDiv>
			</div>
			<UpdateCisPatientProfileModal
				ref={updateProfileRef}
				patient={patient}
			/>
		</div>
	);
};

export default PatientProfileContent;
