import React, { Fragment, useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import Notify from "../../notification/Notify";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import PatientAppointmentLocalCreate from "./PatientAppointmentLocalCreate";
import {
	Badge,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Paper,
	Tooltip,
} from "@material-ui/core";
import Label from "src/utils/Label";
import { PatientsUnRead, UsersData } from "src/ContextAPI";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PatientAppointmentLocalDetails from "./PatientAppointmentLocalDetails";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

function PatientAppointmentLocal({ patient_id, connection }) {
	const history = useHistory();

	const [localapp, setlocalapp] = useState([]);
	const [localappready, setlocalappready] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const [createProductdialog, setcreateProductdialog] = useState(false);

	const [selectedDetails, setSelectedDetails] = useState({
		data: null,
		dialog: false,
	});

	const unread = useContext(PatientsUnRead);
	const { users } = React.useContext(UsersData);

	const checkPatientUnreadNotif = (category, department, notifId) => {
		let xx = unread.unviewNotif;
		let count = 0;

		for (let i = 0; i < xx.length; i++) {
			if (
				patient_id === xx[i].patient_id &&
				category === xx[i].category &&
				department === xx[i].department &&
				notifId === xx[i].order_id
			) {
				count += 1;
			}
		}

		return count;
	};

	const getappointmentLocalRecord = () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);

		Axios.post("doctors/appointment/patients/appointmentrecord-local", formdata)
			.then((response) => {
				const data = response.data;
				setlocalapp(data);
				setlocalappready(true);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const [page, setPage] = useState(0);

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// const showCreateProductDialog = (e) => {
	//   setcreateProductdialog(true);
	// };

	useEffect(() => {
		getappointmentLocalRecord();

		// eslint-disable-next-line
	}, [patient_id]);

	return (
		<Fragment>
			<Grid item xs={12} sm={12}>
				<Box component={Paper} elevation={2} p={2}>
					<TableContainer>
						<PerfectScrollbar>
							<Box display="flex">
								<Box pl={2} pt={2} flexGrow={1}>
									<Typography variant="h6" color="textSecondary">
										{" "}
										Appointments{" "}
									</Typography>
								</Box>

								{/* <Box m={1}>
                  <Button
                    hidden={connection === "online"}
                    color="primary"
                    onClick={showCreateProductDialog}
                    startIcon={<AddCircleOutline />}
                  >
                    Create
                  </Button>
                </Box> */}
							</Box>

							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell> Date </TableCell>
										<TableCell> Service </TableCell>
										<TableCell> Rate </TableCell>
										<TableCell> Reason </TableCell>
										<TableCell> Status </TableCell>
										<TableCell> Action </TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{localappready ? (
										localapp.length > 0 ? (
											(rowsPerPage > 0
												? localapp.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage
												  )
												: localapp
											).map((data, index) => (
												<TableRow hover key={index}>
													<TableCell component="th" scope="row">
														<Badge
															color="secondary"
															anchorOrigin={{
																vertical: "top",
																horizontal: "left",
															}}
															invisible={
																!Boolean(
																	parseInt(
																		checkPatientUnreadNotif(
																			"appointment",
																			"local-appointment",
																			data.appointment_id
																		)
																	)
																)
															}
															variant="dot"
														>
															{Boolean(parseInt(data.is_reschedule))
																? Notify.dateTimeConvert(
																		data.is_reschedule_date
																  )
																: Notify.dateTimeConvert(data.app_date)}
														</Badge>
													</TableCell>
													<TableCell>
														{data.services === null ? "None" : data.services}
													</TableCell>
													<TableCell align="right">
														{data.amount === null ? "None" : data.amount}
													</TableCell>
													<TableCell>
														{data.app_reason === null
															? "None"
															: data.app_reason}
													</TableCell>
													<TableCell>
														<Label
															color={
																Boolean(parseInt(data.is_complete))
																	? "success"
																	: "primary"
															}
														>
															{Boolean(parseInt(data.is_complete))
																? "complete"
																: "incomplete"}
														</Label>
													</TableCell>
													<TableCell>
														<Tooltip title="Details">
															<IconButton
																color="primary"
																onClick={() =>
																	history.push(
																		`/sph/app/doctor/appointment/calendar/details/local/${data.appointment_id}`
																	)
																}
															>
																<ArrowForwardIcon />
															</IconButton>
														</Tooltip>
													</TableCell>
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell colSpan={6}>
													<Typography color="error">
														No local appointment recorded.
													</Typography>
												</TableCell>
											</TableRow>
										)
									) : (
										<TableRow>
											<TableCell colSpan={6}>
												<Typography color="primary">please wait...</Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>

							<TablePagination
								labelRowsPerPage="List"
								rowsPerPageOptions={[5, 20, 50, 100]}
								component="div"
								count={localapp.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						</PerfectScrollbar>
					</TableContainer>
				</Box>
			</Grid>

			{/* create appointment dialog */}
			<Dialog
				disableBackdropClick
				onClose={() => setcreateProductdialog(false)}
				open={createProductdialog}
			>
				<DialogTitle>Create Appointment</DialogTitle>
				<PatientAppointmentLocalCreate
					patient_id={patient_id}
					closecreateDialog={() => setcreateProductdialog(false)}
					getappointmentLocalRecord={() => getappointmentLocalRecord()}
				/>
			</Dialog>

			{/* appointment details */}
			<Dialog
				disableBackdropClick
				onClose={() => setSelectedDetails({ data: null, dialog: false })}
				open={selectedDetails.dialog}
			>
				<DialogTitle>APPOINTMENT DETAILS</DialogTitle>

				<DialogContent dividers>
					{selectedDetails.data && selectedDetails.dialog && (
						<PatientAppointmentLocalDetails details={selectedDetails.data} />
					)}
				</DialogContent>
				<DialogActions>
					<Button
						startIcon={<HighlightOffIcon />}
						onClick={() => setSelectedDetails({ data: null, dialog: false })}
						variant="contained"
						color="default"
					>
						close
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}

export default PatientAppointmentLocal;
