import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import SelectItemsSelector from "./SelectItemsSelector";
import Img from "src/components/Img";
import { UsersData } from "src/ContextAPI";
import {
	dataURItoBlob,
	dateYYYYMMDD,
	formatDateMMDDYYYYHHIIA,
} from "src/helpers/utils";
import ItemServiceList from "./ItemServiceList";
import SelectInputField from "src/components/forms/SelectInputField";
import SelectToUseItems from "./SelectToUseItems";

const symptoms = [
	{
		value: "cough_for_3_weeks_or_longer",
		label: "Cough for three weeks or longer",
	},
	{
		value: "coughing_up_blood_or_mucus",
		label: "Coughing up blood or mucus",
	},
	{ value: "chest_pain", label: "Chest pain" },
	{
		value: "pain_with_breathing_or_coughing",
		label: "Pain with breathing or coughing",
	},
	{ value: "fever", label: "Fever" },
	{ value: "chills", label: "Chills" },
	{ value: "night_sweats", label: "Night sweats" },
	{ value: "weight_loss", label: "Weight loss" },
	{ value: "not_wanting_to_eat", label: "Not wanting to eat" },
	{ value: "tiredness", label: "Tiredness" },
	{
		value: "not_feeling_well_in_general",
		label: "Not feeling well in general",
	},
];
const ConfirmTBModal = (props, ref) => {
	const { patient, refresh, onSuccess } = props;
	const { users } = useContext(UsersData);
	const { register: register1, watch: watch1 } = useForm();
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		trigger,
		control,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(0);
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const [refreshKey, setRefreshKey] = useState(1);
	const [selected, setSelected] = useState(null);
	const [selectedRHU, setSelectedRHU] = useState(null);
	const [selectedClick, setSelectedClick] = useState(null);
	const [isPositive, setIsPositive] = useState(false);
	const [itemsUsed, setItemsUsed] = useState(false);
	const [items, setItems] = useState([]);

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};

	const submitPositive = (data) => {
		axios
			.post(`/v1/clinic/tb-positive/${appointment?.id}`, {
				_method: "PATCH",
			})
			.then((res) => {
				refresh();
				setTimeout(() => {
					onSuccess();
					toast.success("Success Patient is marked as TB Positive!");
				}, 200);
				setIsPositive(true);
			});
	};
	const submitNegative = (data) => {
		axios
			.post(`/v1/clinic/tb-negative/${appointment?.id}`, {
				_method: "PATCH",
			})
			.then((res) => {
				setTimeout(() => {
					toast.success("Patient is TB Negative! Closing form.");
				}, 200);
				hide();
				// setIsPositive(false);
			});
	};
	return (
		<Modal
			open={open}
			staticModal={true}
			size="lg"
			hide={hide}
			className=" w-full"
		>
			<ModalHeader
				title={`Confirm TB Patient`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="pb-3 flex items-center gap-4 px-2">
					<div className="flex flex-col w-1/2">
						<label>Refered by: </label>
						<b className="text-lg">
							{appointment?.bhs?.name
								? appointment?.bhs?.name
								: appointment?.rhu?.name}
						</b>
					</div>
					<div className="flex flex-col w-1/2">
						<label>Initial diagnosis: </label>
						<b className="text-lg">Tuberculosis</b>
					</div>
				</div>
				<div className="flex flex-col">
					<span className="text-base font-semibold mb-3">
						Specimen Picture
					</span>
					<div className="relative">
						<div className="pt-[45%]" />
						<img
							src={appointment?.specimen_picture}
							className="w-full h-full absolute top-0 bg-black border object-contain border-black left-0"
						/>
					</div>
				</div>
				<div>
					{appointment?.tb_symptoms != null ? (
						<div className="flex w-full lg:w-1/2 px-2 flex-col gap-1 mt-3 !shadow-yellow-600 rounded-sm bg-white">
							<ul>
								<li className="text-base font-semibold mb-2">
									Symptoms
								</li>
								{symptoms?.map((symp) => {
									return (
										<li className="!text-sm flex justify-between">
											<span>{symp.label} - </span>
											<b className="text-center">
												{appointment?.tb_symptoms[
													symp.value
												]
													? "YES"
													: "no "}
											</b>
										</li>
									);
								})}
							</ul>
						</div>
					) : (
						""
					)}
				</div>
				<div className=" flex-col relative gap-y-2 px-2">
					<div className="text-base capitalize font-bold pb-3">
						Tuberculosis Test Procedures
					</div>
					<label className="flex items-center gap-3 pl-2  text-sm">
						<input
							type="checkbox"
							id="step-1"
							className="scale-150"
							{...register("step1", {
								required: "This field is required",
							})}
						/>
						<span
							className={
								errors?.step1?.message ? "text-red-500" : ""
							}
						>
							Step 1 - Examine specimen picture
						</span>
					</label>
					<label className="flex items-center gap-3 pl-2  text-sm">
						<input
							type="checkbox"
							className="scale-150"
							disabled={!watch("step1") || watch("negative")}
							{...register("positive", {
								required: !watch("negative"),
							})}
						/>
						<span
							className={
								errors?.step1?.message ? "text-red-500" : ""
							}
						>
							Step 2.A - Check if POSITIVE
						</span>
					</label>
					<label className="flex items-center gap-3 pl-2 text-sm">
						<input
							type="checkbox"
							className="scale-150"
							disabled={!watch("step1") || watch("positive")}
							{...register("negative", {
								required: !watch("positive"),
							})}
						/>
						<span
							className={
								errors?.step1?.message ? "text-red-500" : ""
							}
						>
							Step 2.B - Check if NEGATIVE.
						</span>
					</label>
				</div>{" "}
			</ModalBody>
			<ModalFooter className={"flex items-center justify-between"}>
				<>
					<ActionBtn
						className="w-1/3"
						type="success"
						onClick={handleSubmit(submitPositive)}
						disabled={!watch("positive")}
					>
						<FlatIcon icon="rr-plus" />
						Confirm TB Positive
					</ActionBtn>
					<ActionBtn
						className={`ml-auto ${watch("negative") ? "" : ""}`}
						type="danger"
						disabled={!watch("negative")}
						onClick={handleSubmit(submitNegative)}
					>
						<FlatIcon icon="rr-minus" />
						TB Negative, Close!
					</ActionBtn>
				</>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(ConfirmTBModal);
