import React, { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	Box,
	Typography,
	TextField,
	Card,
} from "@material-ui/core";
import { styles } from "../styles/styles";
import { useForm } from "react-hook-form";
import axios from "axios";
import Notify from "src/notification/Notify";
import { useClinicContext } from "src/clinic/context/ClinicContext";

export default function PatientReferral({ open, onClose, patient }) {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const { clinic } = useClinicContext();
	const [municipalityClinics, setMunicipalityClinics] = useState([]);
	const [loading, setLoading] = useState(false);
	const patientId = patient?.id;
	useEffect(() => {
		getMunicipalityClinics();
	}, []);
	const onSubmit = (data) => {
		console.log(data);
		setLoading(true);

		if (clinic?.type === "barangay") {
			let formdata = new FormData();
			formdata.append("patient_id", patientId);
			formdata.append("brgy_refferal_date", data?.date || "");
			formdata.append("brgy_notes", data?.notes || "");
			formdata.append("rhu", data?.refer || "");
			formdata.append("program", data?.refer || "");
			formdata.append("barangay_id", clinic?.barangay_id);
			formdata.append("barangay_clinic_id", clinic?.barangay_id);
			formdata.append("municipality_clinic_id", clinic?.municipality_id);

			axios
				.post(`v1/tuberculosis-program/create-from-barangay`, formdata)
				.then((response) => {
					let data = response.data;
					console.log(data);
					setLoading(false);
					Notify.successRequest("Patient referral success!");
					onClose();
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
				});
		}

		if (clinic?.type === "municipality") {
			let formdata = new FormData();
			formdata.append("rhu_refferal_date", data?.date || "");
			formdata.append("rhu_notes", data?.notes || "");
			formdata.append("hospital_id", "6");

			axios
				.patch(
					`v1/tuberculosis-program/rhu-to-sph-referral/3`,
					formdata
				)
				.then((response) => {
					let data = response.data;
					console.log(data);
					Notify.successRequest("Patient Referral");
					onClose();
				})
				.catch((err) => console.log(err));
		}
	};

	const getMunicipalityClinics = () => {
		axios.get(`/v1/management/getClinics/municipality`).then((res) => {
			setMunicipalityClinics(res.data.data);
		});
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogTitle>
					<div className="flex justify-between items-center">
						<Box>
							<Typography variant="h5" color="textSecondary">
								<strong>Patient Referral</strong>
							</Typography>
						</Box>
						<button
							className="text-red-600 hover:bg-red-300 py-2 px-3  rounded-lg "
							onClick={onClose}
							type="button"
						>
							CLOSE
						</button>
					</div>
				</DialogTitle>
				<DialogContent>
					<Card elevation={3} className="pt-4  px-2" component={Box}>
						<div className="w-[500px]">
							<div style={styles.divContainerColumn}>
								<div style={styles.divContainer}>
									<TextField
										label={
											<label style={styles.label}>
												Date
												<span
													style={styles.astirisColor}
												>
													*
												</span>
											</label>
										}
										variant="outlined"
										type="date"
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										{...register("date", {
											required: true,
										})}
										error={errors.date}
										helperText={
											errors.date && "Date is required"
										}
									/>
								</div>
								<div style={styles.divContainer}>
									<TextField
										label={
											<label style={styles.label}>
												Refer to:
											</label>
										}
										variant="outlined"
										fullWidth
										select
										SelectProps={{
											native: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										{...register("refer", {
											required: true,
										})}
									>
										<option value="">Select</option>

										{clinic?.type == "barangay" &&
											municipalityClinics?.map(
												(clinicData) => {
													return (
														<option
															value={
																clinicData?.id
															}
														>
															{clinicData?.name}
														</option>
													);
												}
											)}
										{clinic?.type == "municipality" && (
											<option value="2">
												Sarangani Provincial Hospital
											</option>
										)}
									</TextField>
								</div>
							</div>
							<div style={styles.divContainer}>
								<TextField
									label={
										<label style={styles.label}>
											Diagnosis
										</label>
									}
									variant="outlined"
									fullWidth
									select
									SelectProps={{
										native: true,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									{...register("refer", {
										required: true,
									})}
								>
									<option value="">Select</option>
									<option value="animal_bites">
										Animal Bites
									</option>
									<option value="tuberculosis">
										Tuberculosis
									</option>
									<option value="hypertension">
										Hypertension
									</option>
								</TextField>
							</div>
							<div style={styles.divContainer}>
								<TextField
									label={
										<label style={styles.label}>
											Notes
											<span style={styles.astirisColor}>
												*
											</span>
										</label>
									}
									variant="outlined"
									fullWidth
									{...register("notes", { required: true })}
									error={errors.notes}
									helperText={
										errors.notes && "Notes is required"
									}
								/>
							</div>
						</div>
					</Card>
					<div className="py-3 mt-2">
						<Button
							fullWidth
							style={{ fontSize: "18px" }}
							variant="contained"
							color="primary"
							type="submit"
						>
							Submit
						</Button>
					</div>
				</DialogContent>
			</form>
		</Dialog>
	);
}
