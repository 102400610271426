import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@material-ui/core";
import { useContext, useEffect, useRef } from "react";
import { UsersData } from "src/ContextAPI";
import LayoutContainer from "src/components/LayoutContainer";
import HealthUnitDetails from "./components/HealthUnitDetails";

const BhsAppointments = () => {
	const userContext = useContext(UsersData);
	const { users } = userContext;
	const name = users?.name;

	return (
		<LayoutContainer
			icon={<FontAwesomeIcon icon={faHome} color="white" size={"2x"} />}
			title={
				<Typography variant={window.innerWidth > 500 ? "h4" : "h5"}>
					Appointments
				</Typography>
			}
			subtitle={`Welcome Back, ${name}! 👋`}
			titleChildren={<HealthUnitDetails />}
			className={`relative`}
		>
			<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
				<div className="flex flex-col">
					<h2 className="text-lg font-bold">Appointments today</h2>
				</div>
				<div className="flex flex-col">
					<h2 className="text-lg font-bold">Appointments today</h2>
				</div>
			</div>
		</LayoutContainer>
	);
};

export default BhsAppointments;
