import { Box, Grid, Typography, CardMedia } from "@material-ui/core";
import React from "react";
import Notify from "src/notification/Notify";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const Header = ({ details }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box
          // mb={1}
          >
            <Typography style={{ color: "#000", fontWeight: "bolder" }}>
              <Typography
                variant="caption"
                className="font-weight-bold"
                style={{ color: "#000", fontWeight: "bolder" }}
              >
                PATIENT NAME:{" "}
              </Typography>
              <span className="text-capitalize">
                {`${details.data[0].lastname}, ${details.data[0].firstname}`}
              </span>
            </Typography>
          </Box>
          <Box
          // mb={1}
          >
            <Typography style={{ color: "#000", fontWeight: "bolder" }}>
              <Typography
                variant="caption"
                className="font-weight-bold"
                style={{ color: "#000", fontWeight: "bolder" }}
              >
                PATIENT ADDRESS:{" "}
              </Typography>
              {`${details.data[0].street} ${details.data[0].barangay} ${details.data[0].city}`}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            display="flex"
            // mb={1}
          >
            <Box>
              <Typography style={{ color: "#000", fontWeight: "bolder" }}>
                <Typography
                  variant="caption"
                  className="font-weight-bold"
                  style={{ color: "#000", fontWeight: "bolder" }}
                >
                  AGE:{" "}
                </Typography>
                {details.data[0].birthday === null
                  ? "None"
                  : Notify.calculateAge(details.data[0].birthday)}
              </Typography>
            </Box>

            <Box mx={2}>
              <Typography style={{ color: "#000", fontWeight: "bolder" }}>
                <Typography
                  variant="caption"
                  className="font-weight-bold"
                  style={{ color: "#000", fontWeight: "bolder" }}
                >
                  GENDER:{" "}
                </Typography>
                {details.data[0].gender === null
                  ? "None"
                  : details.data[0].gender}
              </Typography>
            </Box>

            <Box
            // mb={1}
            >
              <Typography style={{ color: "#000", fontWeight: "bolder" }}>
                <Typography
                  variant="caption"
                  className="font-weight-bold"
                  style={{ color: "#000", fontWeight: "bolder" }}
                >
                  DATE:{" "}
                </Typography>
                {Notify.createdAt(details.data[0].created_at)}
              </Typography>
            </Box>
          </Box>
          <Box
          // mb={1}
          >
            <Typography style={{ color: "#000", fontWeight: "bolder" }}>
              <Typography
                variant="caption"
                className="font-weight-bold"
                style={{ color: "#000", fontWeight: "bolder" }}
              >
                PHYSICIAN:{" "}
              </Typography>
              {/* {`${details.data[0].street} ${details.data[0].barangay} ${details.data[0].city}`} */}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Box display="flex" justifyContent="center">
            <Box flexGrow={1} />
            <Box>
              {details.data[0].image !== null ? (
                <CardMedia
                  style={{ width: 80 }}
                  component={"img"}
                  src={`${imageLocation}patients/${details.data[0].image}`}
                />
              ) : (
                <CardMedia
                  style={{ width: 80 }}
                  component={"img"}
                  src={`${imageLocation}doctor/no-image.jpg`}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Header;
