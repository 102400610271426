import { Box, Paper, Tabs, Tab } from "@material-ui/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Container from "src/layout/Container";
import TabPanel from "src/utils/TabPanel";
import InventoryLaboratory from "./InventoryLaboratory";
import InventoryStockroom from "./InventoryStockroom";

const Inventory = () => {
  const [category, setCategory] = useState("stockroom");
  const { branch } = useParams();

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: `Branch Inventory`,
        items: [{ name: "Dashboard", path: "/sph/app" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}> {branch.replace("-", " ")} Inventory </Box>
          <Box>
            <Paper>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab value="stockroom" wrapped label="Stockroom" />
                <Tab value="laboratory" wrapped label="Laboratory" />
              </Tabs>
            </Paper>
          </Box>
        </Box>
      }
    >
      <TabPanel value={category} index="stockroom">
        <InventoryStockroom />
      </TabPanel>
      <TabPanel value={category} index="laboratory">
        <InventoryLaboratory />
      </TabPanel>
    </Container>
  );
};

export default Inventory;
