import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  InputAdornment,
  Paper,
  Dialog,
  DialogContent,
  Zoom,
} from "@material-ui/core";
import Notify from "../../notification/Notify";
import Axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import PrintIcon from "@material-ui/icons/Print";
import { UsersData } from "../../ContextAPI";
import CashierORPrint from "./CashierORPrint";

function LaboratoryOR() {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [receipt, setReceipt] = useState([]);
  const [receiptReady, setReceiptReady] = useState(false);
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const { users } = useContext(UsersData);
  const [total, setTotal] = useState(0);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const calculateTotalSales = (data) => {
    var total = 0;
    for (let i = 0; i < data.length; i++) {
      total += parseFloat(data[i].total_payment);
    }
    return total;
  };

  const fetchReceiptList = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      bill_from: "laboratory",
    };
    await Axios.get("hmis/get-all-or/kwqebwqetkjuiAFNFINafjafnf", { params })
      .then((response) => {
        const data = response.data;
        setReceipt(data);
        setReceiptReady(true);
        setTotal(calculateTotalSales(data));
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users]);

  useEffect(() => {
    fetchReceiptList();
  }, [fetchReceiptList]);

  const searchable = receipt.filter((data) => {
    return (
      data.patient_name.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.receipt_number.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography style={{ marginTop: 20 }} variant="h6">
            <strong>
              Overall Sales: {receiptReady && Notify.convertToNumber(total)}{" "}
            </strong>
          </Typography>
        </Box>
        <Box mb={1}>
          <TextField
            label="Search client or receipt"
            variant="outlined"
            margin="dense"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Paper>
        <Box m={1}>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center"> Client Name </TableCell>
                  <TableCell align="center"> Client Address </TableCell>
                  <TableCell align="center"> Receipt </TableCell>
                  <TableCell align="center"> Total Amount </TableCell>
                  <TableCell align="center"> Action </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {receiptReady ? (
                  receipt.length > 0 ? (
                    searchable
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <TableRow key={index}>
                          <TableCell> {data.patient_name} </TableCell>
                          <TableCell>{data.patient_address}</TableCell>
                          <TableCell align="right">
                            {data.receipt_number}
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            {Notify.numberFormat(data.total_payment)}{" "}
                          </TableCell>
                          <TableCell align="center">
                            <PrintIcon
                              color={
                                selectedReceipt === data.receipt_number
                                  ? "secondary"
                                  : "primary"
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSelectedReceipt(data.receipt_number);
                                setOpenModal(true);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle2" color="secondary">
                          {" "}
                          No receipt added{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="subtitle2" color="primary">
                        {" "}
                        Loading...{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <TablePagination
              component={"div"}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={3}
              count={receipt.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
        <Dialog
          open={openModal}
          fullScreen
          disableBackdropClick
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          TransitionComponent={Zoom}
          transitionDuration={1000}
        >
          <DialogContent>
            <CashierORPrint
              closeModal={() => setOpenModal(false)}
              selectedReceipt={selectedReceipt}
            />
          </DialogContent>
        </Dialog>
      </Paper>
    </Fragment>
  );
}

export default LaboratoryOR;
