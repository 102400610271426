import React, { useState } from "react";
import { Box, TextField, Button, makeStyles, Card } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Keyboard } from "@material-ui/icons";
import CreatableSelect from "react-select/creatable";
import Diagnosis from "./CisDiagnosis";
import Notify from "src/notification/Notify";
import { styles } from "../../styles/styles";
import { useForm } from "react-hook-form";
import axios from "axios";
import ButtonV3 from "src/components/ButtonV3";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    fontSize: "18px",
    padding: "6px",
  },
}));

export default function CisPatientDiagnosisForm({ patient }) {
  const [isSearchable, setIsSearchable] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const classes = useStyles();
  const patientId = patient.id;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    let formData = new FormData();
    formData.append("patient_id", patientId);
    formData.append("title", "");
    formData.append("description", data?.description || "");
    formData.append("datetime", "2023-05-18T10:29:25.0000007");
    formData.append("diagnosis_list[0]", "Anxiety");
    formData.append("diagnosis_list[1]", "Bone Cancer");
    formData.append("diagnosis_list[2]", "Dementia");
    formData.append("diagnosis_list[3]", "Dental abscess");

    axios
      .post(`/v1/patient/diagnoses/store`, formData)
      .then((response) => {
        let data = response.data;
        console.log(data);
        Notify.successRequest("created");
      })
      .catch((err) => console.log(err));
  };

  // Handle the onChange event
  const handleSelectChange = (newValue) => {
    setSelectedOption(newValue ? newValue.map((option) => option.value) : []);
  };

  console.log(selectedOption);

  const diagnosisOptions = Diagnosis.list().map((diagnosis) => ({
    value: diagnosis,
    label: diagnosis,
  }));

  return (
    <div className="flex w-full flex-col">
      <form>
        <div className="mx-auto" style={styles.parentContainerAdd}>
          <Card elevation={3} className="p-4" component={Box}>
            <Box pb={3}>
              <Box display="flex">
                <Box>
                  <IconButton
                    onClick={() => {
                      setIsSearchable(!isSearchable);
                    }}
                    size="small"
                    color={isSearchable ? "primary" : "default"}
                  >
                    <Keyboard />
                  </IconButton>
                </Box>
              </Box>
              <CreatableSelect
                options={diagnosisOptions}
                isMulti
                name="diagnosis[]"
                placeholder="Diagnosis"
                isSearchable={isSearchable}
                onChange={handleSelectChange}
                value={selectedOption}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    color: "black",
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: 55,
                  }),
                }}
              />
            </Box>

            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Description</label>}
                variant="outlined"
                fullWidth
                {...register("description")}
              />
            </div>
          </Card>
        </div>

        <div className="mx-auto" style={styles.button}>
          <ButtonV3 onClick={handleSubmit(onSubmit)}>Save</ButtonV3>
        </div>
      </form>
    </div>
  );
}
