import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TextField,
  IconButton,
  useTheme,
  Dialog,
  Zoom,
  DialogContent,
  DialogTitle,
  Button,
  Divider,
} from "@material-ui/core";
import Print from "@material-ui/icons/Print";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Edit, Search } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
// import ImagingPrintOrder from "./ImagingPrintOrder";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import XRayPrint from "./printtemplate/XRayPrint";
import TemplateTableUTZ from "./printtemplate/TemplateTableUTZ";
import TemplateNoTableUTZ from "./printtemplate/TemplateNoTableUTZ";
import TemplateTableUTZ2 from "./printtemplate/TemplateTableUTZ2";
import TemplateTableUTZ3 from "./printtemplate/TemplateTableUTZ3";

const ImagingReport = () => {
  const { users } = React.useContext(UsersData);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalReport, setTotalReport] = React.useState(0);
  const themes = useTheme();
  const [printOpen, setPrintOpen] = useState(false);
  const [selectedImagingId, setSelectedImagingId] = useState(null);
  const [openEditResult, setOpenEditResult] = useState(false);
  const [selectedResult, setSelectedResult] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({
    print_category: "",
    template_name: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [records, setRecords] = useState({
    data: [],
    ready: false,
  });
  const [formHeader, setFormHeader] = useState({
    data: [],
    ready: false,
  });

  const getImagingFormHeader = () => {
    var params = { management_id: users.management_id };
    axios
      .get("imaging/order/formheader-details", { params })
      .then((response) => {
        const data = response.data;
        setFormHeader(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getPatientRead = React.useCallback(async () => {
    try {
      const request = await axios.post(
        "imaging/patients/getpatients-recorded",
        {
          user_id: users.user_id,
        }
      );
      setRecords({
        data: request.data,
        ready: true,
      });
      setTotalReport(calculateTotal(request.data));
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users]);

  const calculateTotal = (data) => {
    var total = 0;
    for (let i = 0; i < data.length; i++) {
      total +=
        parseFloat(
          data[i].imagingCostPackage === null
            ? data[i].imagingCostReg
            : data[i].imagingCostPackage
        ) * 1;
    }
    return total;
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilteredReport = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);

    if (
      formdata.get("date_from").trim() === "" ||
      formdata.get("date_to").trim() === ""
    ) {
      Notify.customToast("Date Invalid", "Date is a required field.");
      return false;
    }

    let response = await axios.post(
      "imaging/patients/getpatients-recorded",
      formdata
    );
    setRecords({ data: response.data, ready: true });
    setTotalReport(calculateTotal(response.data));
  };

  const handleEditSaveResult = (e) => {
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("management_id", users.management_id);
    formdata.append("imaging_center_id", selectedResult.imaging_center_id);

    var error = [];
    if (
      formdata.get("new_result").length === 0 ||
      formdata.get("new_result").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("new result");
    }

    if (
      formdata.get("new_impression").length === 0 ||
      formdata.get("new_impression").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("new impression");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("imaging/edit/confirmed/result", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getPatientRead();
            setOpenEditResult(false);
            e.target.reset();
            Notify.successRequest("edit result");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    getPatientRead();
    getImagingFormHeader();

    // eslint-disable-next-line
  }, [getPatientRead]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Report",
        items: [{ name: "Dashboard", path: "/sph/app" }],
      }}
      title={"Report"}
    >
      <Paper>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={1}
          >
            <Box flexGrow={1}>
              <Typography variant="h5" color="textSecondary">
                <strong>Patient Reviewed</strong>
              </Typography>
            </Box>

            <form onSubmit={handleFilteredReport}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="outlined"
                    label="From"
                    name="date_from"
                    margin="dense"
                  />
                </Box>

                <Box mx={1}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="outlined"
                    label="To"
                    name="date_to"
                    margin="dense"
                  />
                </Box>
                <Box>
                  <IconButton
                    type="submit"
                    style={{
                      width: 40,
                      height: 40,
                      backgroundColor: themes.palette.primary.main,
                      color: "#fff",
                    }}
                  >
                    <Search />
                  </IconButton>
                </Box>
              </Box>
            </form>
          </Box>
          <Divider light />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Date</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Patient</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Order</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>Amount</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>Action</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.ready ? (
                  records.data.length > 0 ? (
                    (rowsPerPage > 0
                      ? records.data.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : records.data
                    ).map((data, index) => (
                      <TableRow key={index} hover>
                        <TableCell>
                          {Notify.dateTimeConvert(data.created_at)}
                        </TableCell>
                        <TableCell>
                          {`${data.lastname}, ${data.firstname}`}
                        </TableCell>
                        <TableCell>
                          {data.imaging_order}{" "}
                          {data.orderDescPackage !== null &&
                            `(${data.orderDescPackage})`}
                        </TableCell>
                        <TableCell align="right">
                          {Notify.convertToNumber(
                            data.imagingCostPackage === null
                              ? data.imagingCostReg
                              : data.imagingCostPackage
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box>
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  setSelectedImagingId(data.imaging_center_id);
                                  setSelectedTemplate({
                                    print_category: data.print_category,
                                    template_name: data.template_name,
                                  });
                                  setPrintOpen(true);
                                }}
                              >
                                <Print />
                              </IconButton>
                            </Box>

                            <Box>
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  setOpenEditResult(true);
                                  setSelectedResult(data);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography color="error">No record found.</Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography color="textPrimary">
                        Please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}

                {records.data.length > 0 && (
                  <TableRow>
                    <TableCell colSpan={3} align="right">
                      <b>Total:</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>{Notify.convertToNumber(totalReport)}</b>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            labelRowsPerPage="list"
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={records.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>

      <Dialog
        open={printOpen}
        onClose={() => setPrintOpen(false)}
        TransitionComponent={Zoom}
        transitionDuration={900}
        fullScreen
      >
        {selectedTemplate.print_category === "template" ? (
          selectedTemplate.template_name === "x-ray" ? (
            <XRayPrint
              selectedImagingId={selectedImagingId}
              formheader={formHeader}
              close={() => {
                setPrintOpen(false);
              }}
            />
          ) : selectedTemplate.template_name === "neck-utz" ? (
            <TemplateNoTableUTZ
              selectedImagingId={selectedImagingId}
              formheader={formHeader}
              close={() => {
                setPrintOpen(false);
              }}
            />
          ) : selectedTemplate.template_name === "bps-utz" ? (
            <TemplateTableUTZ
              selectedImagingId={selectedImagingId}
              formheader={formHeader}
              close={() => {
                setPrintOpen(false);
              }}
            />
          ) : selectedTemplate.template_name === "kub-utz" ? (
            <TemplateNoTableUTZ
              selectedImagingId={selectedImagingId}
              formheader={formHeader}
              close={() => {
                setPrintOpen(false);
              }}
            />
          ) : selectedTemplate.template_name === "kub-prostate-utz" ? (
            <TemplateNoTableUTZ
              selectedImagingId={selectedImagingId}
              formheader={formHeader}
              close={() => {
                setPrintOpen(false);
              }}
            />
          ) : selectedTemplate.template_name === "tvs" ? (
            <TemplateNoTableUTZ
              selectedImagingId={selectedImagingId}
              formheader={formHeader}
              close={() => {
                setPrintOpen(false);
              }}
            />
          ) : selectedTemplate.template_name === "tvs-utz" ? (
            <TemplateTableUTZ2
              selectedImagingId={selectedImagingId}
              formheader={formHeader}
              close={() => {
                setPrintOpen(false);
              }}
            />
          ) : selectedTemplate.template_name === "preg-utz" ? (
            <TemplateTableUTZ3
              selectedImagingId={selectedImagingId}
              formheader={formHeader}
              close={() => {
                setPrintOpen(false);
              }}
            />
          ) : selectedTemplate.template_name === "whole-abdomen-pelvis" ? (
            <TemplateNoTableUTZ
              selectedImagingId={selectedImagingId}
              formheader={formHeader}
              close={() => {
                setPrintOpen(false);
              }}
            />
          ) : selectedTemplate.template_name === "prostate-utz" ? (
            <TemplateNoTableUTZ
              selectedImagingId={selectedImagingId}
              formheader={formHeader}
              close={() => {
                setPrintOpen(false);
              }}
            />
          ) : selectedTemplate.template_name === "whole-abdomen" ? (
            <TemplateNoTableUTZ
              selectedImagingId={selectedImagingId}
              formheader={formHeader}
              close={() => {
                setPrintOpen(false);
              }}
            />
          ) : selectedTemplate.template_name === "whole-abdomen-prostate" ? (
            <TemplateNoTableUTZ
              selectedImagingId={selectedImagingId}
              formheader={formHeader}
              close={() => {
                setPrintOpen(false);
              }}
            />
          ) : null
        ) : (
          <XRayPrint
            selectedImagingId={selectedImagingId}
            formheader={formHeader}
            close={() => {
              setPrintOpen(false);
            }}
          />
        )}
      </Dialog>

      <Dialog
        open={openEditResult}
        onClose={() => setOpenEditResult(false)}
        TransitionComponent={Zoom}
        transitionDuration={900}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Result</DialogTitle>
        <DialogContent>
          <form onSubmit={handleEditSaveResult}>
            <Box mb={2}>
              <TextField
                name="new_result"
                label="Edit Result"
                variant="outlined"
                fullWidth
                rows={4}
                defaultValue={
                  selectedResult === null ? "" : selectedResult.imaging_result
                }
                multiline
              />
            </Box>

            <Box mb={2}>
              <TextField
                name="new_impression"
                label="Edit Impression"
                variant="outlined"
                fullWidth
                rows={4}
                defaultValue={
                  selectedResult === null
                    ? ""
                    : selectedResult.imaging_results_remarks
                }
                multiline
              />
            </Box>

            <Box mb={3}>
              <TextField
                type="password"
                required
                name="password"
                label="Password"
                autoComplete="off"
                fullWidth
                variant="outlined"
              />
            </Box>

            <Box my={2} display="flex">
              <Box flexGrow={1} />

              <Button
                variant="contained"
                color="default"
                startIcon={<ClearIcon />}
                onClick={() => setOpenEditResult(false)}
              >
                Cancel
              </Button>

              <Box ml={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ImagingReport;
