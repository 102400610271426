import React, { Fragment, useContext, useState } from "react";

import Container from "src/layout/Container";
import { useTheme, TextField } from "@material-ui/core";
import axios from "axios";
import { PatientsUnRead, UsersData } from "src/ContextAPI";
import ActionBtn from "src/components/ActionBtn";

const ClinicDoctorAppointments = () => {
	const { medicalCert } = useContext(PatientsUnRead);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const theme = useTheme();
	const { users } = useContext(UsersData);
	const [isProcess, setIsProcess] = useState({ data: null, open: false });

	const getFormData = (object) => {
		const formData = new FormData();
		Object.keys(object).forEach((key) => formData.append(key, object[key]));
		return formData;
	};

	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: true,
					current: "Clinic Appointments",
					items: [
						{
							name: "Dashboard",
							path: "/sph/app/doctor",
						},
					],
				}}
				title={"Clinic Appointments"}
			>
				<div className="w-full h-full pt-6 flex flex-col gap-6">
					<div className=" flex flex-col lg:flex-row items-center gap-4">
						<TextField
							id={`date-from`}
							className={"w-[256px] rounded-xl"}
							InputLabelProps={{ shrink: true }}
							label={"Date from"}
							variant={"outlined"}
							type={"date"}
							name={"date-from"}
						/>
						<TextField
							id={`date-to`}
							className={"w-[256px] rounded-xl"}
							InputLabelProps={{ shrink: true }}
							label={"Date to"}
							variant={"outlined"}
							type={"date"}
							name={"date-to"}
						/>
						<ActionBtn type="danger" className="ml-auto">
							Clear Queue
						</ActionBtn>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
						<div className="h-full !bg-success border-success !bg-opacity-5 lg:col-span-6 gap-4 rounded-lg border">
							<h3 className="text-lg font-bold text-success border-success p-3 border-b">
								NOW SERVING
							</h3>
							<div className="flex flex-col divide-y gap-2 px-3 pb-3 py-2">
								<div className="flex flex-col gap-1 py-1">
									<div className="flex items-center gap-4">
										<span className="font-bold text-base">
											#2
										</span>
										<span className="font-bold text-xl">
											John Doe
										</span>
										<span className="flex items-center gap-2">
											Room{" "}
											<span className="text-lg font-bold text-tertiary">
												#1
											</span>
										</span>
									</div>
									<p className="text-gray-500 mb-0 border-l-4 pl-2 border-l-gray-400  text-lg">
										Severe backache and headache.
									</p>
								</div>
								<div className="flex flex-col gap-1 py-1">
									<div className="flex items-center gap-4">
										<span className="font-bold text-base">
											#3
										</span>
										<span className="font-bold text-xl">
											Jane Dean
										</span>
										<span className="flex items-center gap-2">
											Room{" "}
											<span className="text-lg font-bold text-tertiary">
												#1
											</span>
										</span>
									</div>
									<p className="text-gray-500 mb-0 border-l-4 pl-2 border-l-gray-400  text-lg">
										Stomach ache for 3 days in a row.
									</p>
								</div>
							</div>
						</div>
						<div className="h-full !bg-primary border-primary !bg-opacity-5 lg:col-span-3 gap-4 rounded-lg border">
							<h3 className="text-lg font-bold text-primary border-primary p-3 border-b">
								Returnee
							</h3>
							<div className="flex flex-col divide-y gap-2 px-3 pb-3 py-2">
								<div className="flex flex-col gap-1 py-1">
									<div className="flex items-center gap-4">
										<span className="font-bold text-base">
											#1
										</span>
										<span className="font-bold text-xl">
											Angel Gonazales
										</span>
										<span className="flex items-center gap-2">
											Room{" "}
											<span className="text-lg font-bold text-tertiary">
												#1
											</span>
										</span>
									</div>
									<p className="text-gray-500 mb-0 border-l-4 pl-2 border-l-gray-400  text-lg">
										Severe backache and headache.
									</p>
								</div>
							</div>
						</div>
						<div className="h-full !bg-tertiary !border-tertiary !bg-opacity-5 lg:col-span-3 gap-4 rounded-lg !border">
							<h3 className="text-lg font-bold text-tertiary border-tertiary p-3 border-b">
								Waiting
							</h3>
							<div className="flex flex-col divide-y gap-2 px-3 pb-3 py-2">
								<div className="flex flex-col gap-1 py-1">
									<div className="flex items-center gap-4">
										<span className="font-bold text-base">
											#4
										</span>
										<span className="font-bold text-xl">
											Mona Gonazales
										</span>
									</div>
									<p className="text-gray-500 mb-0 border-l-4 pl-2 border-l-gray-400  text-lg">
										Severe backache and headache.
									</p>
								</div>
								<div className="flex flex-col gap-1 py-1">
									<div className="flex items-center gap-4">
										<span className="font-bold text-base">
											#5
										</span>
										<span className="font-bold text-xl">
											Janine Amparo
										</span>
									</div>
									<p className="text-gray-500 mb-0 border-l-4 pl-2 border-l-gray-400  text-lg">
										Severe backache and headache.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</Fragment>
	);
};

export default ClinicDoctorAppointments;
