import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

const Modal = (props, ref) => {
	const {
		open,
		hide,
		children,
		size,
		className = "",
		staticModal = false,
	} = props;
	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[2050]"
				onClose={() => {
					if (!staticModal) {
						hide();
					}
				}}
				static={true}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div
						className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
					/>
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div
						className={`flex min-h-full items-center justify-center p-2 lg:p-4 text-center`}
					>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel
								className={`w-full transform mx-auto bg-foreground text-left align-middle shadow-xl transition-all rounded-xl  ${
									size ? `modal-${size}` : `max-w-md`
								} ${className}`}
							>
								{children}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default Modal;
