import { faColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutContainer from "src/components/LayoutContainer";
import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import MagicInputFields from "src/components/forms/MagicInputFields";
import useDataTable from "src/helpers/useDataTable";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import { useEffect, useState } from "react";
import CisPatientListItem from "src/cis/components/CisPatientListItem";
import PatientProfileOverview from "src/cis-doctor/components/PatientProfileOverview";

export default function CisLaboratoryPatients() {
	const { clinic } = useClinicContext();

	const {
		data,
		setPage,
		loading,
		setPaginate,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
		keyword,
		setKeyword,
	} = useDataTable(`v1/clinic/patients`, null, {});

	const [list, setList] = useState([]);
	const [selected, setSelected] = useState(null);

	useEffect(() => {
		setFilters((prevFilters) => ({
			...prevFilters,
			clinic_id: 1,
		}));
		setPaginate(20);
	}, []);
	useEffect(() => {
		setList(data?.data);
	}, [data]);
	return (
		<LayoutContainer
			icon={
				<FontAwesomeIcon icon={faColumns} color="white" size={"2x"} />
			}
			title="Clinic patients list"
		>
			<div className="flex flex-col gap-2 h-[calc(100vh-204px)]">
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
					<div className="lg:col-span-4 flex flex-col gap-4 h-[calc(100vh-204px)]">
						<div className="relative w-full flex items-center">
							<input
								type="text"
								placeholder="Search patient"
								className="bg-white rounded-xl !border !border-slate-300 py-2 pr-3 h-11 w-full pl-[48px] !duraton-200 text-sm"
								onChange={(e) => {
									setKeyword(e.target.value);
								}}
							/>
							<span className="absolute left-3 text-slate-300 text-base font-bold pr-2 !border-r flex items-center">
								<FlatIcon icon="rr-search" className=" " />
							</span>
						</div>

						<div className="flex flex-col gap-3 divide-y max-h-[calc(100vh-274)] overflow-auto">
							{loading ? (
								<div className="p-5 flex items-center justify-center bg-foreground animate-pulse rounded-xl">
									<b className="text-placeholder">
										Loading...
									</b>
								</div>
							) : list?.length == 0 ? (
								keyword?.length > 0 ? (
									<div className="p-5 flex items-center justify-center">
										<b className="text-placeholder">
											No results found for "{keyword}".
										</b>
									</div>
								) : (
									<div className="p-5 flex items-center justify-center">
										<b className="text-placeholder">
											No data to display.
										</b>
									</div>
								)
							) : (
								list?.map((x) => {
									return (
										<CisPatientListItem
											patient={x}
											onMouseEnter={() => {
												setSelected(x);
											}}
											onMouseLeave={() => {
												setSelected(null);
											}}
										/>
									);
								})
							)}
						</div>
					</div>
					<div className="lg:col-span-8">
						{selected == null ? (
							<div className="p-5 flex items-center justify-center text-placeholder-dark text-lg">
								{loading
									? "Loading, please wait..."
									: `Hover a patient to display overview.`}
							</div>
						) : (
							<PatientProfileOverview patient={selected} />
						)}
					</div>
				</div>
			</div>
		</LayoutContainer>
	);
}
