import React, {
  useCallback,
  useEffect,
  useState,
  Fragment,
  useContext,
} from "react";
import {
  Dialog,
  Divider,
  IconButton,
  makeStyles,
  Typography,
  Zoom,
  Button,
  CardMedia,
  Badge,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "@material-ui/core/Card";
import Axios from "axios";
import Notify from "src/notification/Notify";
import SearchIcon from "@material-ui/icons/Search";
import Container from "src/layout/Container";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import { Filter, Search, X } from "react-feather";
import DocuPatientDetails from "./DocuPatientDetails";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const DocuPatientListVan = () => {
  const classes = useStyles();
  const { users } = useContext(UsersData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPatient, setSelectedPatient] = useState("new-patient");
  const [selectedPatientInfo, setSelectedPatientInfo] = useState("new-patient");
  const [search, setSearch] = useState("");
  const [accreditedCompany, setAccreditedCompany] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const mounted = IsMountedRef();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [docuFilter, setDocuFilter] = useState(false);
  const [branchList, setBranchList] = useState({ data: [], ready: false });
  const [isSearch, setIsSearch] = useState(false);

  const [patient, setPatient] = useState({
    data: [],
    ready: false,
  });
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const getAllBranches = React.useCallback(async () => {
    var params = {
      management_id: users.management_id,
      main_management_id: users.main_mgmt_id,
    };
    try {
      let response = await Axios.get("hr/get/all-branches", { params });
      const data = response.data;
      setBranchList({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users]);

  const getAccreditedCompanyList = React.useCallback(async () => {
    let response = await Axios.get("admission/accredited/company/get-list", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
      },
    });

    if (mounted.current) {
      setAccreditedCompany(response.data);
    }
  }, [mounted, users]);

  const handleSearchPatient = useCallback(() => {
    var params = {
      company: selectedCompany,
      management_id: selectedBranch,
      main_mgmt_id: users.main_mgmt_id,
    };
    Axios.get("van/mobile-queue/patient-list", { params })
      .then((response) => {
        const data = response.data;
        setPatient({ data: data, ready: true });
        setIsSearch(true);
        setDocuFilter(false);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, [users, selectedCompany, selectedBranch]);

  useEffect(() => {
    getAllBranches();
    getAccreditedCompanyList();
  }, [getAllBranches, getAccreditedCompanyList]);

  const searchable = patient.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "patient list",
          items: [{ name: "dashboard", path: "/sph/app/registration" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Patient List</Box>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={3}>
            <Card>
              <Box
                m={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box flexGrow={1} m={2}>
                  <Typography color={"primary"} variant="subtitle1">
                    Patient List
                  </Typography>
                </Box>
                <Box>
                  <IconButton onClick={() => setDocuFilter(true)}>
                    <Filter fontSize="small" />
                  </IconButton>
                </Box>
              </Box>

              <Divider light />
              {/* <CardHeader title="Patient List" /> */}

              <Box m={2}>
                <Box>
                  <TextField
                    label="Search for patient"
                    variant="outlined"
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box>
                  {isSearch ? (
                    patient.ready ? (
                      searchable.length > 0 ? (
                        <List component="div">
                          {searchable
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((data, index) => (
                              <ListItem
                                button
                                className="mb-2"
                                onClick={() => {
                                  setSelectedPatient(data.patient_id);
                                  setSelectedPatientInfo(data);
                                }}
                                selected={selectedPatient === data.patient_id}
                                key={index}
                              >
                                <ListItemIcon>
                                  {data.image === null ? (
                                    <Avatar className={classes.primary}>
                                      {data.lastname.charAt().toUpperCase()}
                                    </Avatar>
                                  ) : (
                                    <Avatar
                                      className={classes.primary}
                                      src={`${imageLocation}patients/${data.image}`}
                                      alt=""
                                    />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  className={`gtc-capitalize`}
                                  primary={`${data.lastname}, ${
                                    data.firstname
                                  } ${
                                    Boolean(data.middle) ? data.middle : ``
                                  } `}
                                />
                                <Badge
                                  color="secondary"
                                  badgeContent={
                                    parseInt(data.count_doctor) +
                                    parseInt(data.count_laboratory) +
                                    parseInt(data.count_xray) +
                                    parseInt(data.count_other)
                                  }
                                />
                              </ListItem>
                            ))}
                        </List>
                      ) : (
                        Notify.noRecord()
                      )
                    ) : (
                      Notify.loading()
                    )
                  ) : (
                    <Box align="center">
                      <Typography variant="subtitle1" color="secondary">
                        Select Filter First
                      </Typography>
                    </Box>
                  )}
                </Box>
                <TablePagination
                  component="div"
                  count={searchable.length}
                  rowsPerPageOptions={[10, 50, 100]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="List"
                />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} sm={8} lg={9}>
            {selectedPatient === "new-patient" ? (
              <RenderSelectPatient />
            ) : (
              <DocuPatientDetails
                selectedPatientInfo={selectedPatientInfo}
                patient={patient}
                updatePatientList={() => handleSearchPatient()}
                selectedCompany={selectedCompany}
                selectedBranch={selectedBranch}
                patient_id={selectedPatient}
              />
            )}
          </Grid>
        </Grid>

        <Dialog
          open={docuFilter}
          onClose={() => setDocuFilter(false)}
          TransitionComponent={Zoom}
          transitionDuration={800}
          maxWidth="xs"
          fullWidth
        >
          <Box m={2}>
            <Box mb={1}>
              <Typography variant="h5">Filter</Typography>
            </Box>
            <Divider light />
            <Box mt={2} mb={2}>
              <TextField
                select
                SelectProps={{ native: true }}
                onChange={(e) => {
                  setSelectedBranch(e.target.value);
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                name="branch"
                label="Branch"
                variant="outlined"
                value={selectedBranch}
              >
                <option value={""}> Select </option>
                {branchList.data.length > 0 ? (
                  branchList.data.map((data, index) => (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No branches found.
                  </option>
                )}
              </TextField>
            </Box>

            <Box mb={2}>
              <TextField
                select
                SelectProps={{ native: true }}
                onChange={(e) => setSelectedCompany(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                name="company"
                label="Company"
                variant="outlined"
                value={selectedCompany}
              >
                <option value={""}> Select </option>
                {accreditedCompany.length > 0 ? (
                  accreditedCompany.map((data, index) => (
                    <option key={index} value={data.company_id}>
                      {data.label}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No company found.
                  </option>
                )}
              </TextField>
            </Box>

            <Box display="flex">
              <Box flexGrow={1} />
              <Box mr={1}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<X />}
                  onClick={() => setDocuFilter(false)}
                >
                  Close
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Search />}
                  disabled={selectedBranch === null || selectedCompany === null}
                  onClick={handleSearchPatient}
                >
                  Search
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
      </Container>
    </Fragment>
  );
};

export default DocuPatientListVan;

const RenderSelectPatient = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 270 }}
    />
    <Typography color="secondary" variant="h3">
      NO PATIENT SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select patient in the list to view details.
    </Typography>
  </Box>
);
