import React, { useState } from "react";
import { TextField, Button, makeStyles, Card, Box } from "@material-ui/core";
import { styles } from "../../styles/styles";
import { useForm } from "react-hook-form";
import axios from "axios";
import Notify from "src/notification/Notify";
import ButtonV3 from "src/components/ButtonV3";

export default function CisAddPatientDiet({ patient }) {
  const patientId = patient.id;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("patient_id", patientId);
    formData.append("meals", data?.meals || "");
    formData.append("date", data?.date || "");
    formData.append("description", data?.description || "");

    axios
      .post(`/v1/patient/diet/store`, formData)
      .then((response) => {
        Notify.successRequest("Diet");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="flex w-full flex-col">
      <form>
        <div className="mx-auto" style={styles.parentContainerAdd}>
          <Card elevation={3} className="p-4" component={Box}>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>
                      Meals<span style={styles.astirisColor}>*</span>
                    </label>
                  }
                  variant="outlined"
                  fullWidth
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("meals", { required: true })}
                  error={errors.meals}
                  helperText={errors.meals && "Meals is required"}
                >
                  <option value="">Select</option>
                  <option value="breakfast">Breakfast</option>
                  <option value="lunch">Lunch</option>
                  <option value="dinner">Dinner</option>
                </TextField>
              </div>

              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>
                      Date<span style={styles.astirisColor}>*</span>
                    </label>
                  }
                  variant="outlined"
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("date", { required: true })}
                  error={errors.date}
                  helperText={errors.date && "Date is required"}
                />
              </div>
            </div>
            <div style={styles.divContainer}>
              <TextField
                label={
                  <label style={styles.label}>
                    Description<span style={styles.astirisColor}>*</span>
                  </label>
                }
                variant="outlined"
                fullWidth
                {...register("description", { required: true })}
                error={errors.description}
                helperText={errors.description && "Description is required"}
              />
            </div>
          </Card>
        </div>

        <div className="mx-auto" style={styles.button}>
          <ButtonV3 onClick={handleSubmit(onSubmit)}>Save</ButtonV3>
        </div>
      </form>
    </div>
  );
}
