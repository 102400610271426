import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Grid,
} from "@material-ui/core";
import React, { Fragment } from "react";
import CheckIcon from "@material-ui/icons/Check";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Header from "src/laboratory/print/Header";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const NeurologyOrder = ({ formheader, neurologyOrderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={formheader && formheader.name}
              subheader={
                <Box>
                  <Typography>{formheader && formheader.address}</Typography>
                  <Typography>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <CardContent>
          <Box>
            <Typography variant="h6" align="center">
              <b> TABULATED PSYCHOLOGICAL EVALUATION FORM </b>
            </Typography>
          </Box>

          <CardContent>
            <Header details={neurologyOrderDetails} />
          </CardContent>

          {neurologyOrderDetails.data.map((data, index) => (
            <Fragment key={index}>
              <Box>
                <Typography variant="subtitle1">
                  <b>TEST ADMINISTERED</b>
                </Typography>
              </Box>

              <Box ml={6} mb={1}>
                <Box display="flex">
                  <Typography>Intelligence test(IQ): </Typography>
                  <Box width={250} borderBottom={1} borderColor={"#000"} />
                </Box>

                <Box display="flex">
                  <Typography>Personal Test: </Typography>
                  <Box width={295} borderBottom={1} borderColor={"#000"} />
                </Box>

                <Box display="flex">
                  <Typography>Others: </Typography>
                  <Box width={342} borderBottom={1} borderColor={"#000"} />
                </Box>
              </Box>

              <Box>
                <Typography variant="subtitle1">
                  <b>I. INTELLECTUAL LEVEL:</b>
                </Typography>
              </Box>

              <Box ml={6} mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={4}>
                    <Box align="left">
                      {data.intel_level === "very_high" ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="secondary" />
                      )}{" "}
                      <b>Very High</b>
                    </Box>
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <Box align="left">
                      {data.intel_level === "average" ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="secondary" />
                      )}{" "}
                      <b>Average</b>
                    </Box>
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <Box align="left">
                      {data.intel_level === "very_low" ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="secondary" />
                      )}{" "}
                      <b>Very Low</b>
                    </Box>
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <Box align="left">
                      {data.intel_level === "high_average" ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="secondary" />
                      )}{" "}
                      <b>High Average</b>
                    </Box>
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <Box align="left">
                      {data.intel_level === "below_average" ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="secondary" />
                      )}{" "}
                      <b>Below Average</b>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sm={4} />

                  <Grid item xs={4} sm={4}>
                    <Box align="left">
                      {data.intel_level === "above_average" ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="secondary" />
                      )}{" "}
                      <b>Above Average</b>
                    </Box>
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <Box align="left">
                      {data.intel_level === "low" ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="secondary" />
                      )}{" "}
                      <b>Low</b>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  <b>II. PERSONAL TRAITS AND CHARACTERISTICS:</b>
                </Typography>
              </Box>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <b>SENSE OF RESPONSIBILITY</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>
                          1 <br /> Very Low
                        </b>
                      </TableCell>
                      <TableCell align="center">
                        <b>
                          2 <br /> Low
                        </b>
                      </TableCell>
                      <TableCell align="center">
                        <b>
                          3 <br /> Low Average
                        </b>
                      </TableCell>
                      <TableCell align="center">
                        <b>
                          4 <br /> Average
                        </b>
                      </TableCell>

                      <TableCell align="center">
                        <b>
                          5 <br /> High Average
                        </b>
                      </TableCell>

                      <TableCell align="center">
                        <b>
                          6 <br /> High
                        </b>
                      </TableCell>

                      <TableCell align="center">
                        <b>
                          7 <br /> Very High
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Perseverance
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.perseverance === "perseverance_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.perseverance === "perseverance_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.perseverance === "perseverance_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.perseverance === "perseverance_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.perseverance === "perseverance_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.perseverance === "perseverance_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.perseverance === "perseverance_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Obedience
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.obedience === "obedience_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.obedience === "obedience_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.obedience === "obedience_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.obedience === "obedience_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.obedience === "obedience_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.obedience === "obedience_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.obedience === "obedience_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Self-discipline
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.self_discipline === "self_discipline_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.self_discipline === "self_discipline_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.self_discipline === "self_discipline_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.self_discipline === "self_discipline_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.self_discipline === "self_discipline_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.self_discipline === "self_discipline_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.self_discipline === "self_discipline_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Enthusiasm
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.enthusiasm === "enthusiasm_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.enthusiasm === "enthusiasm_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.enthusiasm === "enthusiasm_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.enthusiasm === "enthusiasm_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.enthusiasm === "enthusiasm_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.enthusiasm === "enthusiasm_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.enthusiasm === "enthusiasm_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Initiative
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.initiative === "initiative_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.initiative === "initiative_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.initiative === "initiative_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.initiative === "initiative_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.initiative === "initiative_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.initiative === "initiative_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.initiative === "initiative_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="center">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          <strong>
                            <b>EMOTIONAL STABILITY</b>
                          </strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">6</TableCell>
                      <TableCell align="center">7</TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Can withstand boredom and work alone
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.cwbawa === "cwbawa_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.cwbawa === "cwbawa_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.cwbawa === "cwbawa_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.cwbawa === "cwbawa_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.cwbawa === "cwbawa_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.cwbawa === "cwbawa_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.cwbawa === "cwbawa_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Tolerance to stress, pressure and
                          <br />
                          incoveniences
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.ttspai === "ttspai_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.ttspai === "ttspai_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.ttspai === "ttspai_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.ttspai === "ttspai_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.ttspai === "ttspai_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.ttspai === "ttspai_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.ttspai === "ttspai_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Faces reality
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.faces_reality === "faces_reality_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.faces_reality === "faces_reality_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.faces_reality === "faces_reality_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.faces_reality === "faces_reality_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.faces_reality === "faces_reality_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.faces_reality === "faces_reality_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.faces_reality === "faces_reality_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Confidence
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.confidence === "confidence_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.confidence === "confidence_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.confidence === "confidence_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.confidence === "confidence_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.confidence === "confidence_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.confidence === "confidence_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.confidence === "confidence_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Relaxed
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.relaxed === "relaxed_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.relaxed === "relaxed_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.relaxed === "relaxed_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.relaxed === "relaxed_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.relaxed === "relaxed_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.relaxed === "relaxed_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.relaxed === "relaxed_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="center">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          <strong>
                            <b>OBJECTIVITY</b>
                          </strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">6</TableCell>
                      <TableCell align="center">7</TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Tough-mindedness
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.tough_mindedness === "tough_mindedness_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.tough_mindedness === "tough_mindedness_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.tough_mindedness === "tough_mindedness_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.tough_mindedness === "tough_mindedness_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.tough_mindedness === "tough_mindedness_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.tough_mindedness === "tough_mindedness_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.tough_mindedness === "tough_mindedness_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Adaptability
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.adaptability === "adaptability_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.adaptability === "adaptability_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.adaptability === "adaptability_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.adaptability === "adaptability_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.adaptability === "adaptability_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.adaptability === "adaptability_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.adaptability === "adaptability_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Practically
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.practicality === "practicality_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.practicality === "practicality_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.practicality === "practicality_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.practicality === "practicality_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.practicality === "practicality_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.practicality === "practicality_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.practicality === "practicality_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="center">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          <strong>
                            <b>MOTIVATION</b>
                          </strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">6</TableCell>
                      <TableCell align="center">7</TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Assertiveness
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.assertiveness === "assertiveness_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.assertiveness === "assertiveness_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.assertiveness === "assertiveness_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.assertiveness === "assertiveness_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.assertiveness === "assertiveness_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.assertiveness === "assertiveness_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.assertiveness === "assertiveness_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Independence
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.independence === "independence_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.independence === "independence_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.independence === "independence_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.independence === "independence_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.independence === "independence_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.independence === "independence_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.independence === "independence_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Resourcesfulness
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.resourcefulness === "resourcefulness_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.resourcefulness === "resourcefulness_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.resourcefulness === "resourcefulness_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.resourcefulness === "resourcefulness_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.resourcefulness === "resourcefulness_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.resourcefulness === "resourcefulness_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.resourcefulness === "resourcefulness_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="center">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          <strong>
                            <b>INTERPERSONAL AND PERSONAL ADJUSTMENT</b>
                          </strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">6</TableCell>
                      <TableCell align="center">7</TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Relationship with Peers and Co-workers
                          <br />
                          (Teammanship)
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.rwpac_temmanship === "rwpac_temmanship_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.rwpac_temmanship === "rwpac_temmanship_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.rwpac_temmanship === "rwpac_temmanship_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.rwpac_temmanship === "rwpac_temmanship_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.rwpac_temmanship === "rwpac_temmanship_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.rwpac_temmanship === "rwpac_temmanship_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.rwpac_temmanship === "rwpac_temmanship_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Relationship with Superiors, Employers and
                          <br />
                          Authority Figures (Defence)
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.rwseaa_deference === "rwseaa_deference_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.rwseaa_deference === "rwseaa_deference_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.rwseaa_deference === "rwseaa_deference_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.rwseaa_deference === "rwseaa_deference_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.rwseaa_deference === "rwseaa_deference_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.rwseaa_deference === "rwseaa_deference_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.rwseaa_deference === "rwseaa_deference_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Self-Esteem
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.self_esteem === "self_esteem_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.self_esteem === "self_esteem_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.self_esteem === "self_esteem_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.self_esteem === "self_esteem_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.self_esteem === "self_esteem_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.self_esteem === "self_esteem_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.self_esteem === "self_esteem_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Aggressive Tendencies
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.aggressive_tendencies ===
                        "aggressive_tendencies_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.aggressive_tendencies ===
                        "aggressive_tendencies_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.aggressive_tendencies ===
                        "aggressive_tendencies_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.aggressive_tendencies ===
                        "aggressive_tendencies_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.aggressive_tendencies ===
                        "aggressive_tendencies_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.aggressive_tendencies ===
                        "aggressive_tendencies_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.aggressive_tendencies ===
                        "aggressive_tendencies_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="center">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          <strong>
                            <b>Goal - Orientation</b>
                          </strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">6</TableCell>
                      <TableCell align="center">7</TableCell>
                    </TableRow>

                    <TableRow hidden={data.neuroexam_test === null}>
                      <TableCell align="left">
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Direct one's effort toward clear cut
                          <br /> objectives
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.doetcco === "doetcco_1" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.doetcco === "doetcco_2" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.doetcco === "doetcco_3" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.doetcco === "doetcco_4" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.doetcco === "doetcco_5" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.doetcco === "doetcco_6" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {data.doetcco === "doetcco_7" ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="secondary" />
                        )}{" "}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mb={2}>
                <Typography variant="subtitle1">
                  <b>III. CONCLUSION/REMARKS:</b>
                </Typography>
              </Box>
              <Box ml={6} mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Box align="left">
                      {data.conclusion === "recommended" ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="secondary" />
                      )}{" "}
                      <b>RECOMMENDED</b>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box ml={5}>
                      <Typography variant="caption">
                        No significant personality problems noted at the time of
                        evaluation
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box align="left">
                      {data.conclusion === "for-further" ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="secondary" />
                      )}{" "}
                      <b>FOR FUTHER EVALUATION</b>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Box display="flex" my={3}>
                  <Box flexGrow={1}>
                    <Box align="center" width={250}>
                      <Box mt={5}>
                        <Box borderTop={1}>
                          <b> LIC PSYCHOMETRICIAN </b>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box align="center" width={250}>
                      <Box mt={5}>
                        <Box borderTop={1}>
                          <b> LIC PSYCHOLOGIST </b>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Fragment>
          ))}
        </CardContent>
      </Card>
    </>
  );
};

export default NeurologyOrder;
