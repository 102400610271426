import React from "react";
import Header from "../editPatientProfile/Header";
import { styles } from "../../styles/styles";

export default function AddressAndContactInformation({ patient }) {
  return (
    <>
      <Header title={"Address And Contact Information"} />
      <div className="px-4 mb-3">
        <div style={styles.parentDivContainerNoGap}>
          <div className={styles.borderRight}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: " auto auto",
                gap: "25px",
              }}
            >
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Country</label>
                <div className={styles.textContainer}>{patient?.country}</div>
              </div>
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Region</label>
                <div className={styles.textContainer}>{patient?.region}</div>
              </div>
            </div>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>Province</label>
              <div className={styles.textContainer}>{patient?.province}</div>
            </div>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>City/Municipality</label>
              <div className={styles.textContainer}>
                {patient?.municipality?.name}
              </div>
            </div>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>Barangay</label>
              <div className={styles.textContainer}>
                {patient?.barangay?.name}
              </div>
            </div>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Street/Zone</label>
                <div className={styles.textContainer}>{patient?.street}</div>
              </div>
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Zip Code</label>
                <div className={styles.textContainer}>{patient?.zip_code}</div>
              </div>
            </div>
          </div>

          <div className={styles.borderLeft}>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>Email</label>
              <div className={styles.textContainer}>{patient?.email}</div>
            </div>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>Mobile</label>
              <div className={styles.textContainer}>{patient?.mobile}</div>
            </div>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>Telephone</label>
              <div className={styles.textContainer}>{patient?.telephone}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
