import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { useForm, Controller } from "react-hook-form";
import usePatientHook from "src/admission/hooks/usePatientHook";
import StaticModal from "src/components/modal/StaticModal";
import FlatIcon from "src/components/FlatIcon";
import IdentificationForm from "./Forms/IdentificationForm";
import HousingForm from "./Forms/HousingForm";
import HouseholdCharacteristicsForm from "./Forms/HouseholdCharacteristicsForm";
import CalamityForm from "./Forms/CalamityForm";
import WasteManagementForm from "./Forms/WasteManagementForm";
import HouseForm from "./Forms/HouseForm";
import IncomeForm from "./Forms/IncomeForm";
import WaterAndSanitation from "./Forms/WaterAndSanitation";
import axios from "axios";
import { HashLoader } from "react-spinners";

const CollapseDiv = ({ title, children, show = true }) => {
	const [open, setOpen] = useState(show);
	return (
		<div
			className={`flex flex-col border rounded-xl w-full overflow-hidden duration-200 group ${
				open ? "max-h-[9999px]" : "max-h-[60px]"
			}`}
		>
			<div
				className="flex items-center bg-slate-100 cursor-pointer p-3"
				onClick={() => {
					setOpen((open) => !open);
				}}
			>
				<h3 className="mb-0 text-base font-bold text-primary capitalize">
					{title}
				</h3>
				<div
					className={`rounded-full aspect-square duration-200 bg-opacity-60 group-hover:bg-opacity-100 text-secondary ml-auto flex items-center justify-center ${
						open ? "rotate-180" : "rotate-0"
					}`}
				>
					<FlatIcon icon="rr-caret-down" className="text-2xl" />
				</div>
			</div>
			<div
				className={`bg-white p-4 duration-200  ${
					open ? "max-h-[9999px]" : "max-h-[0px] h-0 overflow-hidden"
				}`}
			>
				{children}
			</div>
		</div>
	);
};
const Forms = ({
	Controller,
	register,
	errors,
	setValue,
	control,
	watch,
	municipalities,
	setMunicipalities,
	brgys,
	setBrgys,
}) => {
	return (
		<>
			<CollapseDiv title="A. Address">
				<div>
					<IdentificationForm
						Controller={Controller}
						register={register}
						errors={errors}
						setValue={setValue}
						control={control}
						watch={watch}
						municipalities={municipalities}
						setMunicipalities={setMunicipalities}
						brgys={brgys}
						setBrgys={setBrgys}
					/>
				</div>
			</CollapseDiv>
			<CollapseDiv title="B. HOUSING CHARACTERISTICS">
				<div>
					<HousingForm
						Controller={Controller}
						register={register}
						errors={errors}
						setValue={setValue}
						control={control}
						watch={watch}
					/>
				</div>
			</CollapseDiv>
			<CollapseDiv title="C. CHARACTERISTICS OF THE HOUSEHOLD">
				<div>
					<HouseholdCharacteristicsForm
						Controller={Controller}
						register={register}
						errors={errors}
						setValue={setValue}
						control={control}
						watch={watch}
					/>
				</div>
			</CollapseDiv>
			<CollapseDiv title="K. WATER AND SANITATION">
				<div>
					<WaterAndSanitation
						Controller={Controller}
						register={register}
						errors={errors}
						setValue={setValue}
						control={control}
						watch={watch}
					/>
				</div>
			</CollapseDiv>
			<CollapseDiv title="L. HOUSING">
				<div>
					<HouseForm
						Controller={Controller}
						register={register}
						errors={errors}
						setValue={setValue}
						control={control}
						watch={watch}
					/>
				</div>
			</CollapseDiv>
			<CollapseDiv title="M. WASTE MANAGEMENT">
				<div>
					<WasteManagementForm
						Controller={Controller}
						register={register}
						errors={errors}
						setValue={setValue}
						control={control}
						watch={watch}
					/>
				</div>
			</CollapseDiv>
			<CollapseDiv title="N. SOURCES OF INCOME">
				<div>
					<IncomeForm
						Controller={Controller}
						register={register}
						errors={errors}
						setValue={setValue}
						control={control}
						watch={watch}
					/>
				</div>
			</CollapseDiv>

			<CollapseDiv title="CALAMITY" show={true}>
				<div>
					<CalamityForm
						Controller={Controller}
						register={register}
						errors={errors}
						setValue={setValue}
						control={control}
						watch={watch}
					/>
				</div>
			</CollapseDiv>
		</>
	);
};
const HouseholdFormModal = (props, ref) => {
	const [open, setOpen] = useState(false);
	const [patient, setPatient] = useState(null);
	const [household, setHousehold] = useState(null);
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		control,
		watch,
		formState: { errors },
	} = useForm();

	const [municipalities, setMunicipalities] = useState([]);
	const [brgys, setBrgys] = useState([]);
	useEffect(() => {
		getLocations();
	}, []);
	const getLocations = () => {
		axios.get("/v1/locations").then((res) => {
			setMunicipalities(res.data);
		});
	};

	const getHouseholdData = (id) => {
		return axios.get(`v1/households/${id}`).then((res) => {
			let data = res?.data?.data || null;
			setHousehold(data);
			setTimeout(() => {
				setValue("province", data?.province);
				setValue("municipality", data?.municipality?.name);

				setBrgys(
					municipalities.find(
						(x) => x.name == data?.municipality?.name
					).barangays
				);
				setValue("zone", data?.zone);
				setTimeout(() => {
					setValue("barangay", data?.barangay?.name);
				}, 200);
				setValue("purok_sitio", data?.purok);
				setValue("street", data?.street);
				setValue("house_number", data?.house_number);
				setValue("house_id", data?.house_id);
				setValue("birthdate", data?.birthdate);
				setValue("date_interview_001", data?.date_interview_001);
				setValue("time_started", data?.time_started);

				setValue("building_type", data?.rawAnswer?.building_type);
				setValue("roof_materials", data?.rawAnswer?.roof_materials);
				setValue("wall_materials", data?.rawAnswer?.wall_materials);

				setValue("overseas_members", data?.rawAnswer?.overseas_members);
				setValue("nuclear_families", data?.rawAnswer?.nuclear_families);
				setValue("plan", data?.rawAnswer?.plan);
				setValue("fam_plan", data?.rawAnswer?.fam_plan);
				setValue("pregnant", data?.rawAnswer?.pregnant);
				setValue("pregnant_number", data?.rawAnswer?.pregnant_number);
				setValue("solo", data?.rawAnswer?.solo);
				setValue("memberssolo", data?.rawAnswer?.memberssolo);
				setValue("pwd", data?.rawAnswer?.pwd);
				setValue("disabled_number", data?.rawAnswer?.disabled_number);
				setValue("pets", data?.rawAnswer?.pets);
				setValue("number_pets ", data?.rawAnswer?.number_pets);
				setValue("number_pets ", data?.rawAnswer?.number_pets);
				setValue("pet_vax", data?.rawAnswer?.pet_vax);
				setValue("pet_vaccine_date", data?.rawAnswer?.pet_vaccine_date);
				setValue("no_of_hh", data?.rawAnswer?.no_of_hh);
			}, 1000);
		});
	};
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (id) => {
		reset();
		if (id) {
			getHouseholdData(id);
		}
		setOpen(true);
	};
	const hide = () => {
		setHousehold(null);
		setOpen(false);
	};

	const submit = (data) => {
		console.log("submit data", data);
	};
	return (
		<StaticModal staticModal={true} open={open} hide={hide} size="xl">
			<ModalHeader
				title={"Edit Household Data"}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody
				className={`p-4 bg-white flex flex-col gap-y-5`}
				key={`household-${household?.id}`}
			>
				{console.log("household?.idhousehold?.id", household?.id)}
				{household?.id > 0 ? (
					<Forms
						Controller={Controller}
						register={register}
						errors={errors}
						setValue={setValue}
						control={control}
						watch={watch}
						municipalities={municipalities}
						setMunicipalities={setMunicipalities}
						brgys={brgys}
						setBrgys={setBrgys}
					/>
				) : (
					<div className="p-20 flex items-center justify-center">
						<HashLoader
							size={20}
							className="text-slate-400 fill-slate-400 animate-spin animate-pulse mr-2"
						/>
						<span className="animate-pulse text-slate-400 text-lg">
							Loading...
						</span>
					</div>
				)}
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button onClick={handleSubmit(submit)}>Submit</Button>
			</ModalFooter>
		</StaticModal>
	);
};

export default forwardRef(HouseholdFormModal);
