import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";

const PreviewImageModal = (props, ref) => {
	const { getData } = props;
	const [open, setOpen] = useState(false);

	const [title, setTitle] = useState(null);
	const [image, setImage] = useState("");

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (showData) => {
		setImage(showData?.image);
		setTitle(showData?.title);
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	return (
		<Modal
			open={open}
			hide={hide}
			className={`w-[768px] min-w-[768px] max-w-[768px]`}
		>
			<ModalHeader title={title || "Preview image"} hide={hide} />

			<ModalBody className={`p-0 bg-white !rounded-b-lg overflow-hidden`}>
				<div className="w-full aspect-[3/2]">
					<img
						src={image}
						className="w-full h-full object-contain bg-slate-600"
					/>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default forwardRef(PreviewImageModal);
