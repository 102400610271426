import React, {
  Fragment,
  useContext,
  useEffect,
  // useRef,
  useState,
} from "react";

import Grid from "@material-ui/core/Grid";
import Container from "src/layout/Container";
import {
  Card,
  CardContent,
  CardHeader,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  Typography,
  IconButton,
  Box,
  Dialog,
  InputAdornment,
  TextField,
  TablePagination,
} from "@material-ui/core";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import { UsersData } from "src/ContextAPI";
import { Print, Search } from "@material-ui/icons";
import Notify from "src/notification/Notify";
import BMCDCMedCert from "./BMCDCMedCert";
// import ForDemo from "./ForDemo";

const MedicalCertificate = () => {
  const [medCert, setMedCert] = useState([]);
  const mounted = IsMountedRef();
  const { users } = useContext(UsersData);
  const [dialog, setDialog] = useState({ data: null, open: false });
  // const printableRef = useRef(null);
  // const [doctorsInfo, setDoctorsInfo] = useState([]);
  // const [patientInfo, setPatientInfo] = useState([]);

  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getCompletedMedCert = React.useCallback(async () => {
    let response = await axios.get(
      "nurse/certificates/medicalcert-allcompleted",
      {
        params: {
          management_id: users.management_id,
        },
      }
    );

    if (mounted.current) {
      setMedCert(response.data);
    }
  }, [mounted, users]);

  useEffect(() => {
    getCompletedMedCert();
  }, [getCompletedMedCert]);

  // const handleSelectedMedCert = async (data) => {
  //   let response = await axios.get(
  //     "nurse/certificates/medicalcert-doctors-info",
  //     {
  //       params: {
  //         doctor_id: data.doctors_id,
  //       },
  //     }
  //   );

  //   setDoctorsInfo(response.data);

  //   let xpres = await axios.get("nurse/certificates/medicalcert-patient-info", {
  //     params: {
  //       patient_id: data.patient_id,
  //     },
  //   });

  //   setPatientInfo(xpres.data);

  //   setTimeout(() => {
  //     setDialog({ data: data, open: true });
  //   }, 1000);
  // };

  const searchable = medCert.filter((data) => {
    return (
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Medical Certificate",
          items: [
            {
              name: "Dashboard",
              path: "/sph/app/doctor",
            },
          ],
        }}
        title={"Medical Certificate"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardHeader
                title={
                  <Box display="flex">
                    <Box flexGrow={1}>List</Box>

                    <Box>
                      <TextField
                        label="Search patient"
                        variant="outlined"
                        fullWidth
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <Search color="primary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                }
              />

              <CardContent>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> Date </TableCell>
                        <TableCell> Patient </TableCell>
                        <TableCell> Diagnosis </TableCell>
                        <TableCell> Action </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {searchable.length > 0 ? (
                        searchable
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((data, key) => (
                            <TableRow key={key}>
                              <TableCell>
                                {Notify.createdAt(data.issued_at)}
                              </TableCell>
                              <TableCell
                                style={{ textTransform: "capitalize" }}
                              >
                                {`${data.lastname}, ${data.firstname}`}
                              </TableCell>
                              <TableCell> {data.diagnosis_findings} </TableCell>
                              <TableCell>
                                <IconButton
                                  color="primary"
                                  // onClick={() => handleSelectedMedCert(data)}
                                  onClick={() =>
                                    setDialog({ data: data, open: true })
                                  }
                                >
                                  <Print />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Typography color="secondary">
                              No record found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  <TablePagination
                    component={"div"}
                    count={searchable.length}
                    labelRowsPerPage="List"
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 50, 100]}
                  />
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* print medical cert */}
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ data: null, open: false })}
        fullScreen
      >
        <BMCDCMedCert
          details={dialog.data}
          close={() => setDialog({ data: null, open: false })}
        />

        {/* <ForDemo
          patientInfo={patientInfo}
          dialog={dialog}
          doctorsInfo={doctorsInfo}
          close={() => setDialog({ data: null, open: false })}
        /> */}
      </Dialog>
    </Fragment>
  );
};

export default MedicalCertificate;
