import React, {
	Fragment,
	useCallback,
	useEffect,
	useState,
	useContext,
} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PersonalInfo from "./PersonalInfo";
import PersonalInfoHeader from "./PersonalInfoHeader";
import Health from "./Health";
import Notes from "./Notes";
import PatientHistory from "./PatientHistory";
import Vitals from "./Vitals";
import Imaging from "./imaging/Imaging";
import Laboratory from "./laboratory_new/Laboratory";
import Prescription from "./Prescription";
import { Grid, Paper, Typography, Badge } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LocalHospital from "@material-ui/icons/LocalHospital";
import Whatshot from "@material-ui/icons/Whatshot";
import EventNote from "@material-ui/icons/EventNote";
import HdrWeak from "@material-ui/icons/HdrWeak";
import InvertColors from "@material-ui/icons/InvertColors";
import VerticalSplit from "@material-ui/icons/VerticalSplit";
import Timeline from "@material-ui/icons/Timeline";
import TreatmentPlan from "./treatmentplan/TreatmentPlan";
import EventAvailable from "@material-ui/icons/EventAvailable";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import PatientAppointment from "./PatientAppointment";
import TabPanel from "../../utils/TabPanel";
import Notify from "src/notification/Notify";
import Axios from "axios";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import { PatientsUnRead, UsersData } from "src/ContextAPI";
import { useParams } from "react-router-dom";
import Container from "src/layout/Container";
import { CardGiftcard, NaturePeople } from "@material-ui/icons";
import Psychology from "./psychology/Psychology";
import Medical from "./certificates/Medical";
import { GitBranch } from "react-feather";
import SentToAdmitting from "./sent-toadmitting";
import { faChartLine, faUserInjured } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PatientChartRecords from "./PatientChartRecords";
import SentToOperation from "./sent-tooperation";

function PatientHeader({ connection, getPatientsList }) {
	const [category, setCategory] = useState("charts");

	const [permission, setPermission] = useState([]);

	const { patient_id, tracenumber } = useParams();

	const { users } = React.useContext(UsersData);

	const unread = useContext(PatientsUnRead);

	const checkPatientUnreadNotif = (category) => {
		let xx = unread.unviewNotif;
		let yy = unread.unviewNotifVirtual;

		let count = 0;

		for (let i = 0; i < xx.length; i++) {
			if (patient_id === xx[i].patient_id && category === xx[i].category) {
				count += 1;
			}
		}

		for (let i = 0; i < yy.length; i++) {
			if (patient_id === yy[i].patient_id && category === yy[i].category) {
				count += 1;
			}
		}

		return count;
	};

	const handleChange = (event, newCategory) => {
		event.persist();
		setCategory(newCategory);
	};

	const getPermission = useCallback(async () => {
		try {
			var formdata = new FormData();
			formdata.append("user_id", users.user_id);
			formdata.append("patient_id", patient_id);
			formdata.append("connection", "local");

			const request = await Axios.post(
				"doctor/patients/permission/get-permission",
				formdata
			);
			setPermission(request.data);
		} catch (error) {
			Notify.requestError(error);
		}
	}, [patient_id, users]);

	useEffect(() => {
		getPermission();

		// eslint-disable-next-line
	}, [getPermission, patient_id]);

	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: true,
					current: "information",
					items: [
						{ name: "Dashboard", path: "/sph/app/doctor" },
						{ name: "Patient", path: "/sph/app/doctor/patient/list" },
					],
				}}
				title="Patient Information"
			>
				<Grid container>
					<Grid item xs={12} sm={12}>
						<PersonalInfoHeader
							patient_id={patient_id}
							connection={connection}
						/>

						<Box m={0} p={0}>
							<Box component={Paper} elevation={1} mb={2}>
								<Tabs
									value={category}
									onChange={handleChange}
									indicatorColor="primary"
									textColor="primary"
									variant="scrollable"
									scrollButtons="on"
								>
									<Tab
										wrapped
										value="charts"
										label="Charts"
										icon={
											<FontAwesomeIcon
												icon={faChartLine}
												style={{ fontSize: "18px" }}
											/>
										}
									/>

									<Tab
										wrapped
										value="appointment"
										label="Appointment"
										icon={
											Boolean(
												parseInt(checkPatientUnreadNotif("appointment"))
											) ? (
												<Badge
													color="error"
													badgeContent={checkPatientUnreadNotif("appointment")}
												>
													<PermContactCalendar />
												</Badge>
											) : (
												<PermContactCalendar />
											)
										}
									/>
									<Tab
										wrapped
										value="profile"
										label="Profile"
										icon={<AccountCircle />}
									/>
									<Tab
										wrapped
										value="health"
										label="Health"
										icon={<LocalHospital />}
									/>
									<Tab
										wrapped
										value="vitals"
										label="Vitals"
										icon={<Whatshot />}
									/>

									{/* need further edit */}
									<Tab
										wrapped
										value="laboratory"
										label="Laboratory"
										icon={
											Boolean(
												parseInt(checkPatientUnreadNotif("laboratory"))
											) ? (
												<Badge
													color="error"
													badgeContent={checkPatientUnreadNotif("laboratory")}
												>
													<InvertColors />
												</Badge>
											) : (
												<InvertColors />
											)
										}
									/>

									<Tab
										wrapped
										value="imaging"
										label="Imaging"
										icon={
											Boolean(parseInt(checkPatientUnreadNotif("imaging"))) ? (
												<Badge
													color="error"
													badgeContent={checkPatientUnreadNotif("imaging")}
												>
													<VerticalSplit />
												</Badge>
											) : (
												<VerticalSplit />
											)
										}
									/>
									{/* need futher edit for psychology */}
									<Tab
										wrapped
										value="psychology"
										label="Psychology"
										icon={<NaturePeople />}
									/>
									{/* <Tab
                    wrapped
                    value="pe-exam"
                    label="P.E Exam"
                    icon={
                      <FontAwesomeIcon
                        icon={faRunning}
                        style={{ fontSize: "18px" }}
                      />
                    }
                  /> */}
									<Tab
										wrapped
										value="prescription"
										label="Prescription"
										icon={<HdrWeak />}
									/>
									<Tab
										wrapped
										value="notes"
										label="Notes"
										icon={<EventNote />}
									/>

									<Tab
										wrapped
										value="certificates"
										label="Certificates"
										icon={<CardGiftcard />}
									/>

									<Tab
										wrapped
										value="treat-plan"
										label="Treatment Plan"
										icon={<EventAvailable />}
									/>
									<Tab
										wrapped
										value="history"
										label="History"
										icon={<Timeline />}
									/>

									{tracenumber !== "tracenumber" && (
										<Tab
											wrapped
											value="sent-toadmitting"
											label="For Admission"
											icon={<GitBranch />}
										/>
									)}

									{tracenumber !== "tracenumber" && (
										<Tab
											wrapped
											value="sent-tooperation"
											label="For Operation"
											icon={
												<FontAwesomeIcon
													icon={faUserInjured}
													style={{ fontSize: "24px" }}
												/>
											}
										/>
									)}
								</Tabs>
							</Box>

							<TabPanel value={category} index={"charts"}>
								<PatientChartRecords
									patient_id={patient_id}
									viewtype={"list"}
								/>
							</TabPanel>

							<TabPanel value={category} index={"appointment"}>
								{permission.length > 0 ? (
									<PatientAppointment
										patient_id={patient_id}
										connection={connection}
									/>
								) : (
									<NoPermission />
								)}
							</TabPanel>

							<TabPanel value={category} index={"profile"}>
								<PersonalInfo patient_id={patient_id} connection={connection} />
							</TabPanel>

							<TabPanel value={category} index={"health"}>
								{permission.length > 0 ? (
									<Health patient_id={patient_id} connection={connection} />
								) : (
									<NoPermission />
								)}
							</TabPanel>

							<TabPanel value={category} index={"vitals"}>
								{permission.length > 0 ? (
									<Vitals patient_id={patient_id} connection={connection} />
								) : (
									<NoPermission />
								)}
							</TabPanel>

							<TabPanel value={category} index={"laboratory"}>
								{permission.length > 0 ? (
									<Laboratory
										patient_id={patient_id}
										connection={connection}
										getPermission={() => getPermission()}
										getPatientsList={() => getPatientsList()}
										permission={permission}
										trace_number={tracenumber}
									/>
								) : (
									<NoPermission />
								)}
							</TabPanel>

							<TabPanel value={category} index={"imaging"}>
								{permission.length > 0 ? (
									<Imaging
										patient_id={patient_id}
										connection={connection}
										trace_number={tracenumber}
									/>
								) : (
									<NoPermission />
								)}
							</TabPanel>

							<TabPanel value={category} index={"psychology"}>
								{permission.length > 0 ? (
									<Psychology
										patient_id={patient_id}
										connection={connection}
										getPermission={() => getPermission()}
										getPatientsList={() => getPatientsList()}
										permission={permission}
										trace_number={tracenumber}
									/>
								) : (
									<NoPermission />
								)}
							</TabPanel>

							{/* <TabPanel value={category} index={"pe-exam"}>
                <PatientsPE patient_id={patient_id} connection={connection} />
              </TabPanel> */}

							<TabPanel value={category} index={"prescription"}>
								{permission.length > 0 ? (
									<Prescription
										patient_id={patient_id}
										connection={connection}
									/>
								) : (
									<NoPermission />
								)}
							</TabPanel>

							<TabPanel value={category} index={"certificates"}>
								<Medical patient_id={patient_id} />
							</TabPanel>

							<TabPanel value={category} index={"notes"}>
								<Notes patient_id={patient_id} connection={connection} />
							</TabPanel>

							<TabPanel value={category} index={"treat-plan"}>
								<TreatmentPlan
									patient_id={patient_id}
									connection={connection}
								/>
							</TabPanel>

							<TabPanel value={category} index={"history"}>
								{permission.length > 0 ? (
									<PatientHistory
										patient_id={patient_id}
										connection={connection}
									/>
								) : (
									<NoPermission />
								)}
							</TabPanel>

							<TabPanel value={category} index={"sent-toadmitting"}>
								<SentToAdmitting
									patient_id={patient_id}
									connection={connection}
									setCategory={setCategory}
								/>
							</TabPanel>

							<TabPanel value={category} index={"sent-tooperation"}>
								<SentToOperation
									patient_id={patient_id}
									connection={connection}
									setCategory={setCategory}
								/>
							</TabPanel>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Fragment>
	);
}

export default PatientHeader;

const NoPermission = () => {
	return (
		<Paper component={Box} variant="outlined" p={2} align={"center"}>
			<EnhancedEncryptionIcon color="error" style={{ fontSize: "3rem" }} />
			<Typography color="error" variant="h5">
				NO PERMISSION
			</Typography>
			<Typography
				color="textSecondary"
				variant="caption"
				className={`gtc-uppercase`}
			>
				YOUR PERMISSION IS REMOVE BY PATIENT
			</Typography>
		</Paper>
	);
};
