import React from "react";
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    padding: theme.spacing(3),
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
  },
  logo: {
    width: 200,
    maxWidth: "100%",
  },
}));

function SlashScreen() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" mb={2}>
        <img src="/gtc-logo.png" width={150} alt="" />
      </Box>
      <CircularProgress size={30} />
      <Box mt={1}>
        <Typography color="primary"> Almost there...</Typography>{" "}
      </Box>
    </div>
  );
}

export default SlashScreen;
