import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import IsMountedRef from "src/utils/IsMountedRef";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MeetingRoom from "@material-ui/icons/MeetingRoom";
import Label from "src/utils/Label";
import NoMeetingRoom from "@material-ui/icons/NoMeetingRoom";

const RoomDetails = () => {
  const { users } = useContext(UsersData);
  const [roomDetails, setRoomDetails] = useState(null);
  const mounted = IsMountedRef();
  const { room_id } = useParams();

  const [roomList, setRoomList] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const [roomBeds, setRoomBeds] = useState([]);
  const [roomBedsRd, setRoomBedsRd] = useState(false);

  const getRoomDetails = React.useCallback(async () => {
    let { data } = await axios.get(`admitting/rooms/room-details`, {
      params: {
        management_id: users.management_id,
        room_id: room_id,
      },
    });

    if (mounted.current) {
      setRoomDetails(data);
    }
  }, [mounted, users, room_id]);

  const getRoomList = React.useCallback(() => {
    setRoomBedsRd(true);
    axios
      .get(`admitting/rooms/room-listbyid`, {
        params: {
          management_id: users.management_id,
          room_id: room_id,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setRoomList(data);
        }
      })
      .catch((err) => console.log("error:", err.message))
      .finally(() => setRoomBedsRd(false));
  }, [mounted, users, room_id]);

  const getRoomBedsList = async (room) => {
    setRoomBedsRd(true);
    axios
      .get(`admitting/rooms/room-bedbyid`, {
        params: {
          room_number: room.room_number,
          room_id: room_id,
        },
      })
      .then(({ data }) => {
        setRoomBeds(data);
        setSelectedRoom(room);
      })
      .catch((err) => console.log(`error:`, err.message))
      .finally(() => setRoomBedsRd(false));
  };

  useEffect(() => {
    getRoomDetails();
    getRoomList();
  }, [getRoomDetails, getRoomList]);

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "room",
          items: [{ name: "Dashboard", path: "/sph/app" }],
        }}
        title="Rooms Details"
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardHeader title={`Room Information`} />
              <CardContent>
                <Box mb={2}>
                  <Typography> Room Name </Typography>
                  <Typography> {roomDetails?.room_name} </Typography>
                </Box>

                <Box mb={2}>
                  <Typography> No of rooms </Typography>
                  <Typography> {roomDetails?.no_of_rooms} </Typography>
                </Box>

                <Box>
                  <Typography> Room Type </Typography>
                  <Typography> {roomDetails?.type} </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card>
              <CardHeader title={`Room List`} />
              <CardContent>
                {roomBedsRd && (
                  <Box align="center">
                    <CircularProgress color="primary" size={20} />
                    <Typography color="primary">Loading...</Typography>
                  </Box>
                )}

                <Grid container spacing={2}>
                  {roomList.length > 0 ? (
                    roomList.map((data, key) => (
                      <Grid item xs={12} key={key}>
                        <Box>
                          <Accordion
                            elevation={0}
                            expanded={data.id === selectedRoom?.id}
                            onChange={() => {
                              if (data.id === selectedRoom?.id) {
                                setSelectedRoom(null);
                                return;
                              }

                              getRoomBedsList(data);
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel${data.id}-content`}
                              id={`panel${data.id}-header`}
                            >
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "uppercase",
                                }}
                              >
                                {`${data.room_number} - ${data.no_of_beds} beds`}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box width={"100%"}>
                                <Grid container spacing={2}>
                                  {roomBeds.length > 0 ? (
                                    roomBeds.map((bed, bedKey) => (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        lg={3}
                                        key={bedKey}
                                      >
                                        <Box>
                                          <Box
                                            border={1}
                                            borderColor={"#ccc"}
                                            borderRadius={5}
                                            padding={2}
                                          >
                                            <Box>
                                              <Typography variant="caption">
                                                BED NUMBER
                                              </Typography>
                                            </Box>

                                            <Box
                                              display={"flex"}
                                              align="center"
                                            >
                                              <Box mb={1}>
                                                <Box>
                                                  {Boolean(
                                                    bed._unavailable_count
                                                  ) ? (
                                                    <NoMeetingRoom
                                                      style={{
                                                        fontSize: 60,
                                                        color: "red",
                                                      }}
                                                    />
                                                  ) : (
                                                    <MeetingRoom
                                                      style={{
                                                        fontSize: 60,
                                                        color: "green",
                                                      }}
                                                    />
                                                  )}
                                                </Box>
                                              </Box>

                                              <Box p={1} align="center">
                                                <Typography variant={"h3"}>
                                                  {bed.bed_number}
                                                </Typography>

                                                <Typography variant="caption">
                                                  ROOM STATUS
                                                </Typography>

                                                <Typography>
                                                  <Label
                                                    color={
                                                      Boolean(
                                                        bed._unavailable_count
                                                      )
                                                        ? "error"
                                                        : "success"
                                                    }
                                                  >
                                                    {Boolean(
                                                      bed._unavailable_count
                                                    )
                                                      ? "Not-available"
                                                      : "Available"}
                                                  </Label>
                                                </Typography>
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Grid>
                                    ))
                                  ) : (
                                    <Grid item xs={12}>
                                      <Box>
                                        <Typography
                                          align={"center"}
                                          color="secondary"
                                        >
                                          No bed found.
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Box>
                        <Typography color="secondary">
                          No room created.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default RoomDetails;
