import {
	Typography,
	Box,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	TablePagination,
} from "@material-ui/core";
import axios from "axios";
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { Printer } from "react-feather";
import ClinicMgmtSOADetails from "./ClinicMgmtSOADetails";

const ClinicMgmtSOATable = ({ selectedBranch }) => {
	const { users } = useContext(UsersData);
	let printRef = useRef();
	const [details, setDetails] = useState({ data: [], ready: false });
	// pagination
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [selectedSOA, setSelectedSOA] = React.useState(null);
	const [oldState, setOldState] = React.useState(null);

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const getAllManagementSOA = useCallback(() => {
		var params = {
			user_id: users.user_id,
			main_mgmt_id: users.main_mgmt_id,
			management_id: selectedBranch.management_id,
		};
		axios
			.get("accounting/get/soa/details-by-mgmt-id", { params })
			.then((response) => {
				const data = response.data;
				setDetails({ data: data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	}, [users.user_id, users.main_mgmt_id, selectedBranch]);

	useEffect(() => {
		if (selectedBranch !== oldState) {
			setOldState(selectedBranch);
			setSelectedSOA(null);
		}
		getAllManagementSOA();
		// eslint-disable-next-line
	}, [getAllManagementSOA]);

	return (
		<Paper component={Box} p={2} ref={printRef}>
			{selectedSOA === null && (
				<Box>
					<Box mb={2}>
						<Typography variant="h6">
							{/* {console.log("selectedBranch", selectedBranch)} */}
							<b>{`${selectedBranch.name} SOA List`}</b>
						</Typography>
					</Box>

					<Box>
						<TableContainer>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell align="center">
											<b> Date </b>
										</TableCell>
										<TableCell align="center">
											<b> Control Number </b>
										</TableCell>
										<TableCell align="center">
											<b> Action </b>
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{details.data.length > 0 ? (
										(rowsPerPage > 0
											? details.data.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
											  )
											: details.data
										).map((data, index) => (
											<React.Fragment key={index}>
												<TableRow>
													<TableCell style={{ width: 250 }}>
														{Notify.createdAt(data.created_at)}
													</TableCell>
													<TableCell style={{ width: 250 }} align="center">
														{data.soa_id}
													</TableCell>
													<TableCell align="center">
														<IconButton
															color="primary"
															onClick={() => setSelectedSOA(data)}
														>
															<Printer size={15} />
														</IconButton>
													</TableCell>
												</TableRow>
											</React.Fragment>
										))
									) : (
										<TableRow>
											<TableCell colSpan={3} align="center">
												<Typography color="secondary">
													No record found
												</Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
							<TablePagination
								rowsPerPageOptions={[5, 20, 50, 100]}
								component="div"
								count={details.data.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						</TableContainer>
					</Box>
				</Box>
			)}
			{selectedSOA !== null && (
				<ClinicMgmtSOADetails
					selectedBranch={selectedBranch}
					selectedSOA={selectedSOA}
					printRef={printRef}
				/>
			)}
		</Paper>
	);
};

export default ClinicMgmtSOATable;
