import { Box, Grid, Typography, CardMedia } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";
// import Footer from "src/laboratory/print/Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ECGOrder = ({ order_id, patient_id, formheader }) => {
	const { users } = React.useContext(UsersData);

	const [details, setDetails] = useState([]);

	const getpaidLaboratoryOrderDetails = async () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set("trace_number", order_id);
		formdata.set("table", "laboratory_ecg");
		formdata.set("connection", "local");

		try {
			let response = await axios.post(
				"doctor/patient/laboratory/order/paid-detailsbytable",
				formdata
			);
			const data = response.data;

			setDetails(data);
		} catch (error) {
			console.log("lab form order laboratory_hematology table error", error);
		}
	};

	const checkResult = (order) => {
		return order === "new-order" ? (
			<Label color="error"> no result </Label>
		) : order === "refund" ? (
			<Label color="warning"> {order} </Label>
		) : (
			<Label color="success"> {order} </Label>
		);
	};

	useEffect(() => {
		getpaidLaboratoryOrderDetails();

		// eslint-disable-next-line
	}, []);

	return (
		<>
			{details.length > 0 && (
				<>
					<Box mb={2}>
						<Typography variant="h6" align="center">
							Electrocardiogram (ECG)
						</Typography>
					</Box>

					{details.map((data, index) => (
						<>
							<Box key={index}>
								<Box>
									{data.order_image && (
										<CardMedia
											style={{ width: "100%" }}
											component={"img"}
											src={`${imageLocation}laboratory/${data.order_image}`}
										/>
									)}
								</Box>

								<Grid container spacing={3}>
									<Grid item xs={12} sm={6}>
										<Box display="flex">
											<Box>
												<Typography
													variant="caption"
													style={{ textTransform: "uppercase" }}
												>
													Atrial / Ventricular Rate:
												</Typography>
											</Box>

											<Box mx={2}>
												<Typography>
													{checkResult(data.atrial_ventricular_rate)}
												</Typography>
											</Box>
										</Box>

										<Box display="flex">
											<Box>
												<Typography
													variant="caption"
													style={{ textTransform: "uppercase" }}
												>
													Rhythm:
												</Typography>
											</Box>
											<Box mx={2}>
												<Typography>{checkResult(data.rhythm)}</Typography>
											</Box>
										</Box>

										<Box display="flex">
											<Box>
												<Typography
													variant="caption"
													style={{ textTransform: "uppercase" }}
												>
													Axis:
												</Typography>
											</Box>
											<Box mx={2}>
												<Typography>{checkResult(data.axis)}</Typography>
											</Box>
										</Box>

										<Box display="flex">
											<Box>
												<Typography
													variant="caption"
													style={{ textTransform: "uppercase" }}
												>
													P wave:
												</Typography>
											</Box>
											<Box mx={2}>
												<Typography>{checkResult(data.p_wave)}</Typography>
											</Box>
										</Box>

										<Box display="flex">
											<Box>
												<Typography
													variant="caption"
													style={{ textTransform: "uppercase" }}
												>
													Others:
												</Typography>
											</Box>
											<Box mx={2}>
												<Typography>{checkResult(data.others)}</Typography>
											</Box>
										</Box>
									</Grid>

									<Grid item xs={12} sm={6}>
										<Box display="flex">
											<Box>
												<Typography
													variant="caption"
													style={{ textTransform: "uppercase" }}
												>
													PR Interval:
												</Typography>
											</Box>
											<Box mx={2}>
												<Typography>{checkResult(data.pr_interval)}</Typography>
											</Box>
										</Box>

										<Box display="flex">
											<Box>
												<Typography
													variant="caption"
													style={{ textTransform: "uppercase" }}
												>
													QRS:
												</Typography>
											</Box>
											<Box mx={2}>
												<Typography>{checkResult(data.qrs)}</Typography>
											</Box>
										</Box>

										<Box display="flex">
											<Box>
												<Typography
													variant="caption"
													style={{ textTransform: "uppercase" }}
												>
													QT Interval:
												</Typography>
											</Box>
											<Box mx={2}>
												<Typography>{checkResult(data.qt_interval)}</Typography>
											</Box>
										</Box>

										<Box display="flex">
											<Box>
												<Typography
													variant="caption"
													style={{ textTransform: "uppercase" }}
												>
													QRS Complex:
												</Typography>
											</Box>
											<Box mx={2}>
												<Typography>{checkResult(data.qrs_complex)}</Typography>
											</Box>
										</Box>

										<Box display="flex">
											<Box>
												<Typography
													variant="caption"
													style={{ textTransform: "uppercase" }}
												>
													ST Segment:
												</Typography>
											</Box>
											<Box mx={2}>
												<Typography>{checkResult(data.st_segment)}</Typography>
											</Box>
										</Box>
									</Grid>
								</Grid>

								<Box my={2}>
									<Typography> Interpretation </Typography>
									<Typography> {checkResult(data.interpretation)} </Typography>
								</Box>
							</Box>

							{/* <Footer formheader={formheader} /> */}
						</>
					))}
				</>
			)}
		</>
	);
};

export default ECGOrder;
