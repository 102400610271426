import {
  Box,
  Collapse,
  Paper,
  Typography,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "src/notification/Notify";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue } from "@material-ui/core/colors";
import DocuPatientDetailsXrayDetails from "./DocuPatientDetailsXrayDetails";

const DocuPatientDetailsXray = ({
  patient_id,
  radiologist,
  updatePatientList,
}) => {
  const [newXRAYOrder, setNewXRAYOrder] = useState([]);
  const [newXRAYOrderRd, setNewXRAYOrderRd] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const getPEOrderToProcess = () => {
    axios
      .get("documentation/van/patient/imaging/get-neworderXRAY", {
        params: {
          patient_id,
        },
      })
      .then((response) => {
        const data = response.data;
        setNewXRAYOrder(data);
      })
      .catch((error) => console.log("error:", error.message))
      .finally(() => {
        setNewXRAYOrderRd(true);
      });
  };

  useEffect(() => {
    getPEOrderToProcess();
    // eslint-disable-next-line
  }, [patient_id]);

  return (
    <>
      <Box my={1}>
        <Typography color="textSecondary" variant="h6">
          X-Ray/UTZ Order
        </Typography>
      </Box>
      {newXRAYOrderRd
        ? newXRAYOrder.length > 0
          ? newXRAYOrder.map((data, index) => {
              return (
                <Fragment key={index}>
                  <Paper component={Box} my={2} p={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Box display="flex">
                          <Box mt={1} mr={2}>
                            <ListAltIcon
                              style={{ fontSize: "2rem", color: blue[500] }}
                            />
                          </Box>

                          <Box>
                            <Box>
                              <Typography
                                variant="subtitle1"
                                className={`gtc-uppercase`}
                              >
                                {data.imaging_order}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                className={`gtc-uppercase`}
                              >
                                {Notify.dateTimeConvert(data.created_at)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={() =>
                            setSelectedOrder(
                              selectedOrder &&
                                selectedOrder.imaging_center_id ===
                                  data.imaging_center_id
                                ? null
                                : data
                            )
                          }
                        >
                          {selectedOrder &&
                          selectedOrder.imaging_center_id ===
                            data.imaging_center_id ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                    <>
                      {/* order result form */}
                      {selectedOrder && (
                        <Collapse
                          in={
                            selectedOrder.imaging_center_id ===
                            data.imaging_center_id
                          }
                        >
                          <Box mt={2}>
                            <DocuPatientDetailsXrayDetails
                              patient_id={patient_id}
                              details={selectedOrder}
                              getPEOrderToProcess={() => {
                                getPEOrderToProcess();
                                setSelectedOrder(null);
                              }}
                              radiologist={radiologist}
                              updatePatientList={() => updatePatientList()}
                            />
                          </Box>
                        </Collapse>
                      )}
                    </>
                  </Paper>
                </Fragment>
              );
            })
          : Notify.noRecord()
        : Notify.loading()}
    </>
  );
};

export default DocuPatientDetailsXray;
