import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PatientProfileOverview from "src/cis-doctor/components/PatientProfileOverview";
import CisPatientListItem from "src/cis/components/CisPatientListItem";
import PatientProfilePage from "src/cis/components/PatientProfilePage";
import ButtonV3 from "src/components/ButtonV3";
import FlatIcon from "src/components/FlatIcon";
import LayoutContainer from "src/components/LayoutContainer";
import useDataTable from "src/helpers/useDataTable";
import BhsPatientProfile from "./BhsPatientProfile";
import HealthUnitDetails from "./components/HealthUnitDetails";
import axios from "axios";
import Pagination from "src/components/table/Pagination";
import ActionBtn from "src/components/ActionBtn";

const BhsVideoCall = () => {
	const history = useHistory();

	const {
		data,
		page,
		setPage,
		meta,
		loading,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
		keyword,
		setKeyword,
		paginate,
		setPaginate,
	} = useDataTable(`v1/patients`, null, {});

	const [list, setList] = useState([]);
	const [selected, setSelected] = useState(null);
	const [selectedClick, setSelectedClick] = useState(null);
	const [pendings, setPendings] = useState(null);

	const getPendingForMedsRelease = () => {
		axios.get(`/v1/clinic/bhw-pending-for-medicine-release`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	useEffect(() => {
		// let t = setInterval(() => {
		// 	getPendingForMedsRelease();
		// }, 5000);
		// return () => {
		// 	clearInterval(t);
		// };
	}, []);

	useEffect(() => {
		getPendingForMedsRelease();
		// setFilters((prevFilters) => ({
		// 	...prevFilters,
		// 	// clinic_id: 1,
		// }));
		// setPaginate(10);
	}, []);
	useEffect(() => {
		setList(data?.data);
	}, [data]);

	const updatePatient = (patient) => {
		console.log("updatePatientupdatePatient,", patient);
		setSelectedClick(patient);
		setList((prevList) =>
			prevList.map((p) =>
				patient?.id == p?.id
					? {
							...p,
							...patient,
					  }
					: p
			)
		);
	};

	return (
		<LayoutContainer
			icon={<FontAwesomeIcon icon={faHome} color="white" size={"2x"} />}
			title={
				<Typography variant={window.innerWidth > 500 ? "h4" : "h5"}>
					Dashboard
				</Typography>
			}
			subtitle={`Patients`}
			titleChildren={<HealthUnitDetails />}
			className={`relative`}
		>
			<div className="flex flex-col gap-2 h-[calc(100vh-204px)] ">
				<div className="w-full aspect-[3/2] bg-black relative">
					<video
						autoPlay
						controls
						loop
						className="h-full absolute w-full top-0 left-0"
					>
						<source src="mov_bbb.mp4" type="video/mp4" />
						<source src="mov_bbb.ogg" type="video/ogg" />
					</video>
				</div>
				<div className="h-[144px] w-full flex items-center justify-center gap-6">
					<ActionBtn>
						<FlatIcon icon="rr-microphone" />
					</ActionBtn>
					<ActionBtn>
						<FlatIcon icon="rr-microphone-slash" />
					</ActionBtn>
					<ActionBtn>
						<FlatIcon icon="rr-user-add" />
					</ActionBtn>
					<ActionBtn>
						<FlatIcon icon="rr-stop" />
					</ActionBtn>
				</div>
			</div>
		</LayoutContainer>
	);
};

export default BhsVideoCall;
