import React, { useEffect, useState } from "react";
import {
  faArrowLeft,
  faEdit,
  faPencilAlt,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { formatDate } from "src/helpers/utils";
import { styles } from "../../styles/styles";
import CisPatientDiagnosisForm from "./CisPatientDiagnosisForm";
import { Box, Button, Card, TablePagination } from "@material-ui/core";
import axios from "axios";
import Notify from "src/notification/Notify";
import ButtonV3 from "src/components/ButtonV3";

export default function CisPatientDiagnosis({ patient }) {
  const [shown, setShown] = useState(true);
  const patientId = patient.id;
  const [patientDiagnosis, setPatientDiagnosis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRefresh = () => {
    axios
      .get(`v1/patient/diagnoses/${patientId}`)
      .then(function (response) {
        setPatientDiagnosis(response.data);
        setLoading(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(true);
      })
      .finally(function () {
        // always executed
      });
  };

  useEffect(() => {
    handleRefresh();
  }, [shown]);

  const handleDelete = (id) => {
    let result = window.confirm("Press OK to Proceed ");
    if (result === true) {
      axios
        .delete(`v1/patient/diagnoses/delete/${id}`)
        .then(function (response) {
          Notify.delete(id);
          handleRefresh();
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          console.log("not okay");
        })
        .finally(function () {
          // always executed
        });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  console.log(patientDiagnosis);
  return (
    <div className="flex w-full flex-col pr-2">
      <div className="flex w-full mb-2 items-center ">
        <div style={styles.headerContainer}>
          <b className="text-2xl">Diagnosis</b>
        </div>
        {shown ? (
          <ButtonV3
            onClick={() => {
              setShown(false);
            }}
          >
            <div className="flex">
              <FontAwesomeIcon
                icon={faPlus}
                color="primary"
                style={{ fontSize: 18 }}
                className="pr-2"
              />
              Add
            </div>
          </ButtonV3>
        ) : (
          <ButtonV3
            onClick={() => {
              setShown(true);
            }}
          >
            <div className="flex">
              <FontAwesomeIcon
                icon={faArrowLeft}
                color="primary"
                style={{ fontSize: 18 }}
                className="pr-2"
              />
              back
            </div>
          </ButtonV3>
        )}
      </div>
      {shown ? (
        <>
          {loading ? (
            patientDiagnosis.data.length > 0 ? (
              <Card elevation={3} className="p-4" component={Box}>
                <TableContainer style={styles.parentContainer}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={styles.tableHeader}>
                          <strong>Date</strong>
                        </TableCell>
                        <TableCell style={styles.tableHeader}>
                          <strong>Diagnosis</strong>
                        </TableCell>
                        <TableCell style={styles.tableHeader}>
                          <strong>Description</strong>
                        </TableCell>
                        <TableCell style={styles.tableHeader}>
                          <strong>Actions</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patientDiagnosis.data.map(
                        ({ id, datetime, description, relationships }) => (
                          <TableRow className={styles.tableHover} key={id}>
                            <TableCell>{`${formatDate(
                              new Date(datetime)
                            )}`}</TableCell>
                            <TableCell>
                              <ul>
                                {relationships.diagnosis_list.map(
                                  ({ id, description }) => (
                                    <li
                                      className="bg-gray-200 rounded-xl flex justify-center p-1 mb-1"
                                      key={id}
                                    >
                                      {description}
                                    </li>
                                  )
                                )}
                              </ul>
                            </TableCell>
                            <TableCell>{description}</TableCell>
                            <TableCell>
                              <div className="d-flex flex-row gap-2">
                                <div>
                                  <ButtonV3 type="primary-outline">
                                    <div className="flex items-center">
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        className="mr-2"
                                      />
                                      Edit
                                    </div>
                                  </ButtonV3>
                                </div>
                                <div>
                                  <ButtonV3
                                    onClick={() => handleDelete(id)}
                                    type="tertiary-outline"
                                  >
                                    <div className="flex items-center">
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="mr-2"
                                      />
                                      Delete
                                    </div>
                                  </ButtonV3>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>

                  <TablePagination
                    labelRowsPerPage="List"
                    rowsPerPageOptions={[
                      10,
                      50,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    component="div"
                    count={patientDiagnosis.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Card>
            ) : (
              Notify.noRecord()
            )
          ) : (
            Notify.loading()
          )}
        </>
      ) : (
        <CisPatientDiagnosisForm patient={patient} />
      )}
    </div>
  );
}
