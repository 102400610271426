import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import AudiometryPrintAll from "./forms/psychology/AudiometryPrintAll";
import IshiharaPrintAll from "./forms/psychology/IshiharaPrintAll";
import NeurologyPrintAll from "./forms/psychology/NeurologyPrintAll";

const PsychologyPrintAll = ({
  allowHeaderPatientInfo,
  patient_id,
  trace_number,
  patients,
}) => {
  const { users } = useContext(UsersData);
  const [audiometryOrderDetails, setAudiometryOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [ishiharaOrderDetails, setIshiharaOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [neurologyOrderDetails, setNeurologyOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const getAudiometryOrderDetails = () => {
    var params = { user_id: users.user_id, trace_number: trace_number };
    axios
      .get("psychology/order/ordernew-audiology/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setAudiometryOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getIshiharaOrderDetails = () => {
    var params = { user_id: users.user_id, trace_number: trace_number };
    axios
      .get("psychology/order/ordernew-ishihara/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setIshiharaOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getNeurologyOrderDetails = () => {
    var params = { user_id: users.user_id, trace_number: trace_number };
    axios
      .get("psychology/order/ordernew-neurology/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setNeurologyOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getAudiometryOrderDetails();
    getIshiharaOrderDetails();
    getNeurologyOrderDetails();

    // eslint-disable-next-line
  }, []);
  return (
    <>
      {audiometryOrderDetails.ready &&
        audiometryOrderDetails.data.length > 0 && (
          <AudiometryPrintAll
            allowHeaderPatientInfo={allowHeaderPatientInfo}
            patients={patients}
            audiometryOrderDetails={audiometryOrderDetails}
          />
        )}

      {ishiharaOrderDetails.ready && ishiharaOrderDetails.data.length > 0 && (
        <IshiharaPrintAll
          allowHeaderPatientInfo={allowHeaderPatientInfo}
          patients={patients}
          ishiharaOrderDetails={ishiharaOrderDetails}
        />
      )}

      {neurologyOrderDetails.ready && neurologyOrderDetails.data.length > 0 && (
        <NeurologyPrintAll
          allowHeaderPatientInfo={allowHeaderPatientInfo}
          patients={patients}
          neurologyOrderDetails={neurologyOrderDetails}
        />
      )}
    </>
  );
};

export default PsychologyPrintAll;
