import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Box,
  IconButton,
  ListItem,
  Avatar,
  TablePagination,
  InputAdornment,
  List,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Search, XCircle } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import Details from "./Details";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const DischargeNurse = () => {
  const classes = useStyles();

  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [admittedPatient, setAdmittedPatient] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedPatientTracenumber, setSelectedPatientTracenumber] =
    useState(null);

  const [searchDisplay, setSearchDisplay] = useState(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const searchable = admittedPatient.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  const getAdmittedPatient = useCallback(() => {
    axios
      .get(`nurse/room/get-admitted-patient`, {
        params: {
          management_id: users.management_id,
          department: "discharge-department",
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setAdmittedPatient(data);
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));
  }, [users, mounted]);

  useEffect(() => {
    getAdmittedPatient();
  }, [getAdmittedPatient]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Discharge Patient",
        items: [{ name: "Dashboard", path: "/sph/app" }],
      }}
      title={"Discharge Patient"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={3}>
          <Card>
            <CardHeader
              title={
                <Box display={"flex"}>
                  <Box flexGrow={1}> Discharge Patient </Box>
                  <Box>
                    <IconButton
                      color={!searchDisplay ? "primary" : "secondary"}
                      onClick={() => setSearchDisplay(!searchDisplay)}
                    >
                      {!searchDisplay ? <Search /> : <XCircle />}
                    </IconButton>
                  </Box>
                </Box>
              }
            />

            <CardContent>
              <List component="div">
                <Box mb={1} hidden={!searchDisplay}>
                  <TextField
                    label="Search Patient"
                    variant="outlined"
                    fullWidth
                    // margin="dense"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {!isLoading
                  ? admittedPatient.length > 0
                    ? searchable
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data, index) => (
                          <ListItem
                            key={index}
                            button
                            className="mb-2"
                            onClick={() => {
                              setSelectedPatient(data);
                              setSelectedPatientTracenumber(data.trace_number);
                            }}
                            selected={selectedPatient === data.patient_id}
                          >
                            <ListItemIcon>
                              {data.image === null ? (
                                <Avatar className={classes.primary}>
                                  {data.lastname.charAt().toUpperCase()}
                                </Avatar>
                              ) : (
                                <Avatar
                                  className={classes.primary}
                                  src={`${imageLocation}patients/${data.image}`}
                                  alt=""
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              className={`gtc-capitalize`}
                              primary={`${data.lastname}, ${data.firstname} ${
                                Boolean(data.middle) ? data.middle : ``
                              } `}
                            />
                          </ListItem>
                        ))
                    : Notify.noRecord()
                  : Notify.loading()}
              </List>

              <TablePagination
                component="div"
                count={admittedPatient.length}
                rowsPerPageOptions={[10, 50, 100, { label: "All", value: -1 }]}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="List"
              />
            </CardContent>
          </Card>
        </Grid>

        {selectedPatient && (
          <Grid item xs={12} sm={8} lg={9}>
            <Details
              patient_id={selectedPatient?.patient_id}
              onClose={() => {
                setSelectedPatient(null);
                setSelectedPatientTracenumber(null);
              }}
              trace_number={selectedPatientTracenumber}
              getAdmittedPatient={getAdmittedPatient}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default DischargeNurse;
