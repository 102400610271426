import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Dialog,
  DialogContent,
  TextField,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { Check, Send, X } from "react-feather";

const ItemForApprovalDetails = ({
  request_id,
  getItemForApprovalList,
  closeDetails,
}) => {
  const { users } = useContext(UsersData);
  const [forApprovalList, setForApprovalList] = useState({
    data: [],
    ready: false,
  });
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [selectedTypeApproval, setSelectedTypeApproval] = useState("");

  const calculateTotal = (qty, srp) => {
    // let total = 0;
    // total += parseInt(qty) * parseFloat(srp);
    return parseInt(qty) * parseFloat(srp);
  };

  const getAllLeaveRequest = React.useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      request_id,
    };
    try {
      let response = await axios.get("hmis/get/item-for-approval", {
        params,
      });
      const data = response.data;
      setForApprovalList({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users, request_id]);

  const handleSubmitFromCOO = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("request_id", request_id);
    axios
      .post("hmis/update/item-approval-by-id", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setConfirmationDialog(false);
          closeDetails();
          getItemForApprovalList();
          Notify.successRequest("request");
        } else {
          Notify.warnRequest("request");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getAllLeaveRequest();
  }, [getAllLeaveRequest]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <b>Brand</b>
              </TableCell>
              <TableCell>
                <b>Description</b>
              </TableCell>
              <TableCell>
                <b>Unit</b>
              </TableCell>
              <TableCell>
                <b>Batch</b>
              </TableCell>
              <TableCell>
                <b>Exp. Date</b>
              </TableCell>
              <TableCell>
                <b>Mfg. Date</b>
              </TableCell>
              <TableCell>
                <b>Dr. Qty</b>
              </TableCell>
              <TableCell>
                <b>Srp</b>
              </TableCell>
              <TableCell>
                <b>Amount</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {forApprovalList.ready ? (
              forApprovalList.data.length > 0 ? (
                forApprovalList.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {parseInt(data.item_check) === 1 ? (
                        <Check style={{ color: "green" }} />
                      ) : parseInt(data.item_check) === 0 ? (
                        <X style={{ color: "red" }} />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell> {data.product_name} </TableCell>
                    <TableCell>{data.product_generic}</TableCell>
                    <TableCell> {data.unit} </TableCell>
                    <TableCell> {data.batch_number} </TableCell>
                    <TableCell>{data.expiration_date}</TableCell>
                    <TableCell>{data.manufactured_date}</TableCell>
                    <TableCell align="right">{data.qty}</TableCell>
                    <TableCell align="right">
                      {Notify.convertToNumber(data.srp)}
                    </TableCell>
                    <TableCell align="center">
                      {Notify.convertToNumber(
                        calculateTotal(data.qty, data.srp)
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography color="secondary">No record found.</Typography>
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography color="primary"> Loading... </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box mt={2} hidden={forApprovalList.data.length > 0 ? false : true}>
          <Box display={"flex"} alignContent="center" alignItems={"center"}>
            <Box flexGrow={1} />
            <Box>
              <Button
                size="small"
                variant="contained"
                startIcon={<Send />}
                onClick={() => setConfirmationDialog(true)}
                color="primary"
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </TableContainer>

      <Dialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(false)}
        disableBackdropClick
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <form onSubmit={handleSubmitFromCOO}>
            <Box mb={2}>
              <Typography color="primary" variant="h5">
                <strong>Update Approval</strong>
              </Typography>
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                required
                name="owner_approve"
                label="Type Approval"
                variant="outlined"
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setSelectedTypeApproval(e.target.value)}
                value={selectedTypeApproval}
              >
                <option value=""> Select </option>
                <option value={"1"}> Approve </option>
                <option value={"0"}> Disapprove </option>
              </TextField>
            </Box>

            {selectedTypeApproval === "0" && (
              <Box mb={2}>
                <TextField
                  name="owner_disapprove_reason"
                  label="Reason"
                  variant="outlined"
                  fullWidth
                  rows={4}
                  multiline
                />
              </Box>
            )}

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => {
                    setConfirmationDialog(false);
                  }}
                >
                  No
                </Button>
              </Box>
              <Box ml={2}>
                <Button type="submit" variant="contained" color="primary">
                  Yes
                </Button>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ItemForApprovalDetails;
