import {
  Box,
  Typography,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  Paper,
  TableBody,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const StockroomMonitoring = () => {
  const { users } = React.useContext(UsersData);
  const mounted = IsMountedRef();
  const [products, setProducts] = useState({ data: [], ready: false });

  const getStockroomMonitoring = React.useCallback(async () => {
    let response = await axios.get("stockroom/product/monitoring/get-list", {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setProducts({ data: response.data, ready: true });
    }
  }, [mounted, users]);

  useEffect(() => {
    getStockroomMonitoring();
  }, [getStockroomMonitoring]);

  return (
    <Paper>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Dr Number</TableCell>
                <TableCell>Dr Date</TableCell>
                <TableCell> Out Reason </TableCell>
                <TableCell> Out Date </TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Qty</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {products.ready ? (
                products.data.length > 0 ? (
                  products.data.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {Notify.dateTimeConvert(data.created_at)}
                      </TableCell>
                      <TableCell>{data.product}</TableCell>
                      <TableCell>{data.unit}</TableCell>
                      <TableCell>{data.dr_number} </TableCell>
                      <TableCell>
                        {" "}
                        {data.type === "IN"
                          ? Notify.createdAt(data.dr_date)
                          : ""}{" "}
                      </TableCell>
                      <TableCell> {data.out_reason} </TableCell>
                      <TableCell>
                        {" "}
                        {data.type === "OUT"
                          ? Notify.createdAt(data.out_date)
                          : ""}{" "}
                      </TableCell>
                      <TableCell>{data.type}</TableCell>
                      <TableCell align="right"> {data.qty} </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={9}>
                    <Typography color="primary">
                      loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default StockroomMonitoring;
