import React, { Fragment } from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const TumorMakerPrintAll = ({
  allowHeaderPatientInfo,
  patients,
  tumorMakerOrderDetails,
}) => {
  return (
    <>
      {tumorMakerOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                TUMOR MAKER
              </span>
            </Typography>
          </Box>
          <Box>
            {tumorMakerOrderDetails.data.map((data, key) => {
              return (
                <Box my={2} key={key}>
                  <Box mb={3} hidden={!Boolean(parseInt(data.aso))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> ASO </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box my={1}>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.aso_result}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box mb={3} hidden={!Boolean(parseInt(data.biopsy))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> BIOPSY </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box my={1}>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.biopsy_result}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box mb={3} hidden={!Boolean(parseInt(data.c3))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> C3 </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box my={1}>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.c3_result}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box mb={3} hidden={!Boolean(parseInt(data.ca_125))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> CA 125 </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box my={1}>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.ca_125_result}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box mb={3} hidden={!Boolean(parseInt(data.cea))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> CEA </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box my={1}>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.cea_result}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box mb={3} hidden={!Boolean(parseInt(data.psa_prostate))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> PSA Prostate </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box my={1}>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.psa_prostate_result}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box hidden={!Boolean(parseInt(data.afp))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> AFP </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box my={1}>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.afp_result}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box my={1} align="center">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {tumorMakerOrderDetails.data.length > 0 &&
                        tumorMakerOrderDetails.data[0].pathologist}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Pathologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {tumorMakerOrderDetails.data.length > 0 &&
                          tumorMakerOrderDetails.data[0].pathologist_lcn}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xs={12} sm={4}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {tumorMakerOrderDetails.data.length > 0 &&
                        tumorMakerOrderDetails.data[0].chief_medtech}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Chief Med Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {tumorMakerOrderDetails.data.length > 0 &&
                          tumorMakerOrderDetails.data[0].chief_medtech_lci}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      <span
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                      >
                        {tumorMakerOrderDetails.data.length > 0 &&
                          tumorMakerOrderDetails.data[0].medtech}
                      </span>
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Medical Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {tumorMakerOrderDetails.data.length > 0 &&
                            tumorMakerOrderDetails.data[0].lic_no}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default TumorMakerPrintAll;
