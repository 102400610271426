import {
	Box,
	Dialog,
	TextField,
	Typography,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
	Grid,
	CardMedia,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Notify from "../../notification/Notify";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "../../utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import Specimen from "./Specimen";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const PapsmearTestDetails = ({
	queue,
	formheader,
	order,
	getLabStoolTestOrder,
	resetDisplay,
	process_for,
}) => {
	const { users } = React.useContext(UsersData);
	const history = useHistory();
	const [orderDetails, setOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const [pendingDialog, setPendingDialog] = useState(false);
	const [processDialog, setProcessDialog] = useState({
		data: null,
		dialog: false,
	});

	const [values, setValues] = useState({
		cervix: false,
		vagina: false,
		satisfactory_evaluation: false,
		satisfactory_no_lmp: false,
		unsatisfactory: false,
		with_normal_limits: false,
		benign_cell_changes: false,
		epithelial_cell_abno: false,
		infection_trichomonas: false,
		infection_fungi: false,
		infection_predominance: false,
		infection_cellular: false,
		infection_others: false,
		reactive_inflammation: false,
		reactive_atrophy: false,
		reactive_follicular: false,
		reactive_radiation: false,
		reactive_iud: false,
		reactive_des: false,
		reactive_others: false,
		squamous_typical: false,
		squamous_low: false,
		squamous_low_hpv: false,
		squamous_low_mild: false,
		squamous_high: false,
		squamous_high_moderate: false,
		squamous_high_severe: false,
		squamous_high_carcinoma: false,
		squamous_cell: false,
		giandulare_endomentrial: false,
		giandulare_typical: false,
		giandulare_endocervical: false,
		giandulare_endometrial: false,
		giandulare_extraiterine: false,
		giandulare_adenocarcinoma: false,
		hormonal_compatible: false,
		hormonal_incompatible: false,
		hormonal_non_possible: false,
	});

	const handleChange = (event) => {
		setValues({ ...values, [event.target.name]: event.target.checked });
	};

	const [pendingSubmitting, setPendingSubmitting] = useState(false);
	const [processSubmitting, setProcessSubmitting] = useState(false);
	const [resultSubmitting, setResultSubmitting] = useState(false);
	const [savebtnDisabled, setSavebtnDisabled] = useState(true);
	const [orderItems, setOrderItems] = useState([]);

	//okay
	const getStooltestOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order.trace_number };
		Axios.get("laboratory/order/ordernew-papsmeartest/details", { params })
			.then((response) => {
				const data = response.data;
				setOrderDetails({
					data: data,
					ready: true,
				});

				checkIfSaveBtnIsEnabled(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	//okay
	const checkIfSaveBtnIsEnabled = (data) => {
		for (let i = 0; i < data.length; i++) {
			if (Boolean(data[i].is_processed)) {
				setSavebtnDisabled(false);
			}
		}
	};

	const handleSaveResult = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("trace_number", order.trace_number);
		formdata.set("queue", queue);

		formdata.set("cervix", values.cervix ? 1 : 0);
		formdata.set("vagina", values.vagina ? 1 : 0);
		formdata.set(
			"satisfactory_evaluation",
			values.satisfactory_evaluation ? 1 : 0
		);
		formdata.set("satisfactory_no_lmp", values.satisfactory_no_lmp ? 1 : 0);
		formdata.set("unsatisfactory", values.unsatisfactory ? 1 : 0);
		formdata.set("with_normal_limits", values.with_normal_limits ? 1 : 0);
		formdata.set("benign_cell_changes", values.benign_cell_changes ? 1 : 0);
		formdata.set("epithelial_cell_abno", values.epithelial_cell_abno ? 1 : 0);
		formdata.set("infection_trichomonas", values.infection_trichomonas ? 1 : 0);
		formdata.set("infection_fungi", values.infection_fungi ? 1 : 0);
		formdata.set(
			"infection_predominance",
			values.infection_predominance ? 1 : 0
		);
		formdata.set("infection_cellular", values.infection_cellular ? 1 : 0);
		formdata.set("infection_others", values.infection_others ? 1 : 0);
		formdata.set("reactive_inflammation", values.reactive_inflammation ? 1 : 0);
		formdata.set("reactive_atrophy", values.reactive_atrophy ? 1 : 0);
		formdata.set("reactive_follicular", values.reactive_follicular ? 1 : 0);
		formdata.set("reactive_radiation", values.reactive_radiation ? 1 : 0);
		formdata.set("reactive_iud", values.reactive_iud ? 1 : 0);
		formdata.set("reactive_des", values.reactive_des ? 1 : 0);
		formdata.set("reactive_others", values.reactive_others ? 1 : 0);
		formdata.set("squamous_typical", values.squamous_typical ? 1 : 0);
		formdata.set("squamous_low", values.squamous_low ? 1 : 0);
		formdata.set("squamous_low_hpv", values.squamous_low_hpv ? 1 : 0);
		formdata.set("squamous_low_mild", values.squamous_low_mild ? 1 : 0);
		formdata.set("squamous_high", values.squamous_high ? 1 : 0);
		formdata.set(
			"squamous_high_moderate",
			values.squamous_high_moderate ? 1 : 0
		);
		formdata.set("squamous_high_severe", values.squamous_high_severe ? 1 : 0);
		formdata.set(
			"squamous_high_carcinoma",
			values.squamous_high_carcinoma ? 1 : 0
		);
		formdata.set("squamous_cell", values.squamous_cell ? 1 : 0);
		formdata.set(
			"giandulare_endomentrial",
			values.giandulare_endomentrial ? 1 : 0
		);
		formdata.set("giandulare_typical", values.giandulare_typical ? 1 : 0);
		formdata.set(
			"giandulare_endocervical",
			values.giandulare_endocervical ? 1 : 0
		);
		formdata.set(
			"giandulare_endometrial",
			values.giandulare_endometrial ? 1 : 0
		);
		formdata.set(
			"giandulare_extraiterine",
			values.giandulare_extraiterine ? 1 : 0
		);
		formdata.set(
			"giandulare_adenocarcinoma",
			values.giandulare_adenocarcinoma ? 1 : 0
		);
		formdata.set("hormonal_compatible", values.hormonal_compatible ? 1 : 0);
		formdata.set("hormonal_incompatible", values.hormonal_incompatible ? 1 : 0);
		formdata.set("hormonal_non_possible", values.hormonal_non_possible ? 1 : 0);
		formdata.set("process_for", process_for);

		var error = [];

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setResultSubmitting(true);
			Axios.post(
				"laboratory/order/ordernew-papsmear/save-process-result",
				formdata
			)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						setSavebtnDisabled(true);
						Notify.successRequest("order result added.");
						setTimeout(() => {
							history.push(
								`/sph/app/laboratory/record/print/order/${order.trace_number}`
							);
						}, 5000);
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				});
		}
	};

	//gtanggal na daw ni
	const handlePendingOrder = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", order.order_id);

		var error = [];

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setPendingSubmitting(true);
			Axios.post("laboratory/order/ordernew-sorology/save-setpending", formdata)
				.then((response) => {
					const data = response.data;
					if (data === "pass-invalid") {
						Notify.fieldInvalid("password");
					}
					if (data === "success") {
						getLabStoolTestOrder();
						resetDisplay();
						setPendingDialog(false);
						Notify.successRequest("order pending");
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => setPendingSubmitting(false));
		}
	};

	//okay
	const handleProcessOrder = async (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", processDialog.data.order_id);
		formdata.set("trace_number", processDialog.data.trace_number);

		var error = [];
		setProcessSubmitting(true);

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			let response = await Axios.post(
				"laboratory/papsmeartest/order-setprocess/custom-qty",
				formdata
			);

			if (response.data.message === "reagent-error") {
				Notify.customToast("Reagent Not Error", "Reagent/items not available.");
			}

			if (response.data.message === "pass-invalid") {
				Notify.fieldInvalid("password");
			}

			if (response.data.message === "success") {
				Notify.successRequest("process");
				setProcessDialog({ data: null, dialog: false });
				getStooltestOrderDetails();
			}
		}

		setTimeout(() => {
			setProcessSubmitting(false);
		}, 2000);
	};

	//okay
	const handleProcessSelectedOrder = async (data) => {
		let response = await Axios.get(
			"laboratory/items/laborder/list-available-items",
			{
				params: {
					user_id: users.user_id,
					management_id: users.management_id,
					order_id: data.order_id,
				},
			}
		);

		setOrderItems(response.data);

		setProcessDialog({ data: data, dialog: true });
	};

	//okay
	useEffect(() => {
		getStooltestOrderDetails();
		// eslint-disable-next-line
	}, [order]);

	return (
		<>
			<form onSubmit={handleSaveResult}>
				<Card elevation={0}>
					<Box mt={2}>
						{formheader && (
							<CardMedia
								style={{ width: 70 }}
								component={"img"}
								src={`${imageLocation}laboratory/logo/${formheader.logo}`}
							/>
						)}
					</Box>
					<Box>
						<CardHeader
							component={Box}
							align="center"
							title={formheader && formheader.name}
							subheader={formheader && formheader.address}
						/>
					</Box>

					<CardContent>
						{/* paitent information */}
						<Box display="flex">
							<Box flexGrow={1} mb={2}>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											NAME:
										</Typography>
										{`${order.lastname}, ${order.firstname} ${
											order.middle === null ? "" : order.middle
										}`}
									</Typography>
								</Box>

								<Box display="flex" mb={2}>
									<Box>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												AGE:
											</Typography>
											{order.birthday === null
												? "none"
												: Notify.calculateAge(order.birthday)}
										</Typography>
									</Box>
									<Box ml={5}>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												SEX:
											</Typography>
											{order.gender === null ? "none" : order.gender}
										</Typography>
									</Box>
								</Box>

								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											ADDRESS:
										</Typography>
										{`${order.street} ${order.barangay} ${order.city} ${order.zip} `}
									</Typography>
								</Box>

								<Box>
									<Typography variant="caption" className="font-weight-bold">
										PATIENT CONDITION:
									</Typography>
									{order.patient_condition === null
										? " none"
										: order.patient_condition}
								</Box>
							</Box>

							<Box>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											DATE:
										</Typography>
										{Notify.createdAt(new Date().toLocaleString())}
									</Typography>
								</Box>
							</Box>
						</Box>
					</CardContent>

					<CardContent>
						<Specimen
							patient_id={order.patient_id}
							trace_number={order.trace_number}
						/>
					</CardContent>

					<CardContent>
						{/* order details */}
						{orderDetails.ready
							? orderDetails.data.length > 0
								? orderDetails.data.map((data, index) => (
										<Fragment key={index}>
											{/* papsmear order */}
											<Box>
												<Box
													mb={2}
													display="flex"
													borderBottom={1}
													borderColor={"#AEAEAE"}
												>
													<Box flexGrow={1}>
														<Typography variant="h6" align="center">
															<b> CERVICAL VAGINAL CYTOLOGY REPORT </b>
														</Typography>
														<Typography variant="h6" align="center">
															(THE BETHESDA SYSTEM)
														</Typography>
													</Box>

													<Box>
														<Button
															disabled={Boolean(parseInt(data.is_processed))}
															size="small"
															variant="contained"
															color="primary"
															onClick={() => {
																handleProcessSelectedOrder(data);
															}}
														>
															Process
														</Button>
													</Box>
												</Box>

												<Box hidden={!Boolean(parseInt(data.is_processed))}>
													{/* hidden inputs */}
													<Box>
														<TextField
															margin="dense"
															fullWidth
															name="order_id[]"
															label="order_id"
															value={data.order_id}
															hidden
														/>
														<TextField
															margin="dense"
															fullWidth
															name="patient_id"
															label="patient_id"
															value={data.patient_id}
															hidden
														/>

														<TextField
															margin="dense"
															fullWidth
															name="doctors_id"
															label="doctor_id"
															value={
																data.doctor_id === null ? "" : data.doctor_id
															}
															hidden
														/>
													</Box>
													{/* papsmear hidden if not process */}
													<Box>
														<Grid container spacing={2}>
															<Grid item xs={12} sm={6}>
																<Box borderBottom={1} borderColor={"#AEAEAE"}>
																	<Box
																		display="flex"
																		justifyContent="center"
																		alignItems="center"
																	>
																		<Typography
																			variant="subtitle2"
																			style={{ textDecoration: "underline" }}
																		>
																			SPECIMEN
																		</Typography>
																	</Box>
																	<Box>
																		<Typography variant="subtitle2">
																			FIXED SLIDES FROM:
																		</Typography>
																	</Box>
																	<Box>
																		<FormGroup>
																			<FormControlLabel
																				control={
																					<Checkbox
																						checked={values.cervix}
																						onChange={handleChange}
																						name="cervix"
																					/>
																				}
																				label="CERVIX"
																			/>
																			<FormControlLabel
																				control={
																					<Checkbox
																						checked={values.vagina}
																						onChange={handleChange}
																						name="vagina"
																					/>
																				}
																				label="VAGINA"
																			/>
																		</FormGroup>
																	</Box>
																</Box>
																<Box>
																	<Box>
																		<Typography
																			variant="subtitle2"
																			style={{ textDecoration: "underline" }}
																		>
																			Adequacy of the specimen
																		</Typography>
																	</Box>
																	<FormGroup>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						values.satisfactory_evaluation
																					}
																					onChange={handleChange}
																					name="satisfactory_evaluation"
																				/>
																			}
																			label="Satisfactory for evaluation"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.satisfactory_no_lmp}
																					onChange={handleChange}
																					name="satisfactory_no_lmp"
																				/>
																			}
																			label="Satisfactory for evaluation but limited by no LMP"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.unsatisfactory}
																					onChange={handleChange}
																					name="unsatisfactory"
																				/>
																			}
																			label="Unsatisfactory for evaluation due to"
																		/>
																	</FormGroup>
																</Box>
																<Box>
																	<Box>
																		<Typography
																			variant="subtitle2"
																			style={{ textDecoration: "underline" }}
																		>
																			General Categorization
																		</Typography>
																	</Box>
																	<FormGroup>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.with_normal_limits}
																					onChange={handleChange}
																					name="with_normal_limits"
																				/>
																			}
																			label="Within Normal Limits"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.benign_cell_changes}
																					onChange={handleChange}
																					name="benign_cell_changes"
																				/>
																			}
																			label="Benign Cellular Changes"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.epithelial_cell_abno}
																					onChange={handleChange}
																					name="epithelial_cell_abno"
																				/>
																			}
																			label="Epithelial Cell Abnormalities"
																		/>
																	</FormGroup>
																</Box>
																<Box>
																	<Box>
																		<Typography
																			variant="subtitle2"
																			style={{ fontWeight: "bolder" }}
																		>
																			Benign Cellular Changes
																		</Typography>
																		<Typography
																			variant="subtitle2"
																			style={{ textDecoration: "underline" }}
																		>
																			Infection
																		</Typography>
																	</Box>
																	<FormGroup>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.infection_trichomonas}
																					onChange={handleChange}
																					name="infection_trichomonas"
																				/>
																			}
																			label="Trichomonas vaginalis"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.infection_fungi}
																					onChange={handleChange}
																					name="infection_fungi"
																				/>
																			}
																			label="Fungi consistent with Candida spp."
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						values.infection_predominance
																					}
																					onChange={handleChange}
																					name="infection_predominance"
																				/>
																			}
																			label="Predominance of cocobacilli consistent with shift of vaginal flora"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.infection_cellular}
																					onChange={handleChange}
																					name="infection_cellular"
																				/>
																			}
																			label="Cellular changes associated with hempes simples virus"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.infection_others}
																					onChange={handleChange}
																					name="infection_others"
																				/>
																			}
																			label="Others"
																		/>
																	</FormGroup>
																</Box>
																<Box>
																	<Box>
																		<Typography
																			variant="subtitle2"
																			style={{ textDecoration: "underline" }}
																		>
																			Reactive
																		</Typography>
																	</Box>
																	<FormGroup>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.reactive_inflammation}
																					onChange={handleChange}
																					name="reactive_inflammation"
																				/>
																			}
																			label="Inflammation (includes typical repair)"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.reactive_atrophy}
																					onChange={handleChange}
																					name="reactive_atrophy"
																				/>
																			}
																			label="Atrophy with inflammation"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.reactive_follicular}
																					onChange={handleChange}
																					name="reactive_follicular"
																				/>
																			}
																			label="Follicular cervicitis"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.reactive_radiation}
																					onChange={handleChange}
																					name="reactive_radiation"
																				/>
																			}
																			label="Radiation effect"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.reactive_iud}
																					onChange={handleChange}
																					name="reactive_iud"
																				/>
																			}
																			label="IUD effect"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.reactive_des}
																					onChange={handleChange}
																					name="reactive_des"
																				/>
																			}
																			label="DES changes"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.reactive_others}
																					onChange={handleChange}
																					name="reactive_others"
																				/>
																			}
																			label="Other Presence of RBC"
																		/>
																	</FormGroup>
																</Box>
																<Box>
																	<Box>
																		<Typography variant="subtitle2">
																			Maturation Index
																		</Typography>
																	</Box>

																	<Box>
																		<Box>
																			<Typography variant="subtitle2">
																				<b> Suggest: </b>
																			</Typography>
																			<Box>
																				<TextField
																					fullWidth
																					name="maturation_suggest[]"
																					margin="dense"
																				/>
																			</Box>
																		</Box>
																	</Box>
																</Box>
															</Grid>

															<Grid item xs={12} sm={6}>
																<Box>
																	<Box
																		display="flex"
																		justifyContent="center"
																		alignItems="center"
																	>
																		<Typography
																			variant="subtitle2"
																			style={{ textDecoration: "underline" }}
																		>
																			CLINICAL DATE
																		</Typography>
																	</Box>
																	<Grid container spacing={2}>
																		<Grid item xs={6} sm={6}>
																			<Box
																				display="flex"
																				justifyContent="center"
																				alignItems="center"
																			>
																				<Typography variant="subtitle2">
																					AGE:
																				</Typography>
																				<TextField
																					fullWidth
																					name="age[]"
																					margin="dense"
																				/>
																			</Box>
																		</Grid>
																		<Grid item xs={6} sm={6}>
																			<Box
																				display="flex"
																				justifyContent="center"
																				alignItems="center"
																			>
																				<Typography variant="subtitle2">
																					LMP:
																				</Typography>
																				<TextField
																					fullWidth
																					name="lmp[]"
																					margin="dense"
																				/>
																			</Box>
																		</Grid>
																	</Grid>

																	<Box
																		display="flex"
																		justifyContent="center"
																		alignItems="center"
																	>
																		<Typography variant="subtitle2">
																			HORMONES:
																		</Typography>
																		<TextField
																			fullWidth
																			name="hormones[]"
																			margin="dense"
																		/>
																	</Box>

																	<Box
																		display="flex"
																		justifyContent="center"
																		alignItems="center"
																	>
																		<Typography variant="subtitle2">
																			IUD:
																		</Typography>
																		<TextField
																			fullWidth
																			name="iud[]"
																			margin="dense"
																		/>
																	</Box>
																</Box>
																<Box mt={4}>
																	<Box mb={1}>
																		<Typography variant="subtitle2">
																			Epithelial Cell Abnormalities
																		</Typography>
																	</Box>
																	<Box>
																		<Typography
																			variant="subtitle2"
																			style={{ fontWeight: "bolder" }}
																		>
																			Aquamous Cells
																		</Typography>
																	</Box>
																	<FormGroup>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.squamous_typical}
																					onChange={handleChange}
																					name="squamous_typical"
																				/>
																			}
																			label="A typical squamous cells of undetermined significance ASCUC: qualify favor reactive"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.squamous_low}
																					onChange={handleChange}
																					name="squamous_low"
																				/>
																			}
																			label="Low grade squamous intraepithelial lesion"
																		/>

																		<Box ml={5}>
																			<FormGroup>
																				<FormControlLabel
																					control={
																						<Checkbox
																							checked={values.squamous_low_hpv}
																							onChange={handleChange}
																							name="squamous_low_hpv"
																						/>
																					}
																					label="HPV associated changes"
																				/>
																				<FormControlLabel
																					control={
																						<Checkbox
																							checked={values.squamous_low_mild}
																							onChange={handleChange}
																							name="squamous_low_mild"
																						/>
																					}
																					label="Mild Dysplasia"
																				/>
																			</FormGroup>
																		</Box>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.squamous_high}
																					onChange={handleChange}
																					name="squamous_high"
																				/>
																			}
																			label="High grade squamous intraepithelial lesion"
																		/>

																		<Box ml={5}>
																			<FormGroup>
																				<FormControlLabel
																					control={
																						<Checkbox
																							checked={
																								values.squamous_high_moderate
																							}
																							onChange={handleChange}
																							name="squamous_high_moderate"
																						/>
																					}
																					label="Moderate Dysplasia"
																				/>
																				<FormControlLabel
																					control={
																						<Checkbox
																							checked={
																								values.squamous_high_severe
																							}
																							onChange={handleChange}
																							name="squamous_high_severe"
																						/>
																					}
																					label="Severe Dysplasia"
																				/>
																				<FormControlLabel
																					control={
																						<Checkbox
																							checked={
																								values.squamous_high_carcinoma
																							}
																							onChange={handleChange}
																							name="squamous_high_carcinoma"
																						/>
																					}
																					label="Carcinoma in situ"
																				/>
																			</FormGroup>
																		</Box>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.squamous_cell}
																					onChange={handleChange}
																					name="squamous_cell"
																				/>
																			}
																			label="Squamous cell carcinoma"
																		/>
																	</FormGroup>
																</Box>
																<Box>
																	<Box>
																		<Typography
																			variant="subtitle2"
																			style={{ fontWeight: "bolder" }}
																		>
																			Giandulare cells
																		</Typography>
																	</Box>
																	<FormGroup>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						values.giandulare_endomentrial
																					}
																					onChange={handleChange}
																					name="giandulare_endomentrial"
																				/>
																			}
																			label="Endometrial cells cytologically benign in a postmenopausal"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.giandulare_typical}
																					onChange={handleChange}
																					name="giandulare_typical"
																				/>
																			}
																			label="A typical giandulae cells of undetermined significance (AGUS): quality favor reactive or pre-or malignant process"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						values.giandulare_endocervical
																					}
																					onChange={handleChange}
																					name="giandulare_endocervical"
																				/>
																			}
																			label="Endocervical adenocarcinoma"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						values.giandulare_endometrial
																					}
																					onChange={handleChange}
																					name="giandulare_endometrial"
																				/>
																			}
																			label="Endometrial adenocarcinoma"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						values.giandulare_extraiterine
																					}
																					onChange={handleChange}
																					name="giandulare_extraiterine"
																				/>
																			}
																			label="Extralterine adenocarcinoma"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						values.giandulare_adenocarcinoma
																					}
																					onChange={handleChange}
																					name="giandulare_adenocarcinoma"
																				/>
																			}
																			label="Adenocarcinoma no otherwise specified"
																		/>

																		<TextField
																			margin="dense"
																			fullWidth
																			name="giandulare_adeno_specify[]"
																		/>
																	</FormGroup>
																</Box>
																<Box>
																	<Box>
																		<Typography variant="subtitle2">
																			Other malignant neoplasms: Specify
																		</Typography>

																		<TextField
																			margin="dense"
																			fullWidth
																			name="other_malignant[]"
																		/>
																	</Box>
																	<Box>
																		<Typography variant="subtitle2">
																			Hormonal evaluation (applies to vaginal
																			smear only)
																		</Typography>
																	</Box>

																	<FormGroup>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.hormonal_compatible}
																					onChange={handleChange}
																					name="hormonal_compatible"
																				/>
																			}
																			label="Compatible with age and history"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.hormonal_incompatible}
																					onChange={handleChange}
																					name="hormonal_incompatible"
																				/>
																			}
																			label="Incompatible with age and history: Specify"
																		/>

																		<TextField
																			margin="dense"
																			fullWidth
																			name="hormonal_incompatible_spec[]"
																		/>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={values.hormonal_non_possible}
																					onChange={handleChange}
																					name="hormonal_non_possible"
																				/>
																			}
																			label="Non possible due to: Specify"
																		/>

																		<TextField
																			margin="dense"
																			fullWidth
																			name="non_possible_specify[]"
																		/>
																	</FormGroup>
																</Box>
															</Grid>
														</Grid>
													</Box>
												</Box>
											</Box>
											{/* papsmear order end */}
										</Fragment>
								  ))
								: Notify.noRecord()
							: Notify.loading()}
					</CardContent>
					<Box display="flex" m={1}>
						<Box flexGrow={1} />
						<Box>
							<CardActions>
								<Button
									variant="contained"
									size="large"
									color="primary"
									type="submit"
									disabled={savebtnDisabled}
									startIcon={
										resultSubmitting && (
											<CircularProgress size={20} color="inherit" />
										)
									}
								>
									Save Result
								</Button>
							</CardActions>
						</Box>
					</Box>
				</Card>
			</form>

			{/* set as pending dialog */}
			<Dialog
				open={pendingDialog}
				onClose={() => setPendingDialog(false)}
				disableBackdropClick
				PaperComponent={DraggableDialog}
			>
				<DialogTitle id="draggable-handle">Set as pending</DialogTitle>
				<form onSubmit={handlePendingOrder}>
					<DialogContent dividers>
						<Box mb={2}>
							<TextField
								rows={5}
								fullWidth
								name="reason"
								label={`Pending Reason`}
								variant="outlined"
								multiline
							/>
						</Box>

						<Box>
							<TextField
								fullWidth
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setPendingDialog(false)}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="secondary"
							type="submit"
							disabled={pendingSubmitting}
							startIcon={
								pendingSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<ErrorOutlineIcon />
								)
							}
						>
							pending
						</Button>
					</DialogActions>
				</form>
			</Dialog>

			{/* set as process dialog */}
			<Dialog
				open={processDialog.dialog}
				onClose={() => setProcessDialog({ data: null, dialog: false })}
				disableBackdropClick
				PaperComponent={DraggableDialog}
				maxWidth={"xs"}
				fullWidth
			>
				<DialogTitle id="draggable-handle">Process order</DialogTitle>
				<form onSubmit={handleProcessOrder}>
					<DialogContent dividers>
						{orderItems.length > 0
							? orderItems.map((data, index) => (
									<Box key={index} mb={2}>
										<Box>
											<TextField
												fullWidth
												name="item_id[]"
												value={data.item_id}
												variant="outlined"
												hidden
											/>
										</Box>

										<Box>
											<TextField
												fullWidth
												required
												label={`${data.description} qty to process`}
												name="qty[]"
												variant="outlined"
												defaultValue={1}
												InputLabelProps={{
													shrink: true,
												}}
												// InputProps={{
												//   inputProps: {
												//     min: 0,
												//     max: data._total_qty_available,
												//   },
												// }}
												type="number"
											/>
										</Box>
									</Box>
							  ))
							: "No reagent/item found."}

						<Box mt={2}>
							<TextField
								fullWidth
								required
								defaultValue="ok"
								name="remarks"
								label={`Remarks`}
								variant="outlined"
							/>
						</Box>

						<Box mt={2}>
							<TextField
								fullWidth
								required
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setProcessDialog({ data: null, dialog: false })}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={processSubmitting}
							startIcon={
								processSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<CheckCircleIcon />
								)
							}
						>
							process
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default PapsmearTestDetails;
