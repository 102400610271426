import React, {
  useCallback,
  useEffect,
  useState,
  Fragment,
  useContext,
} from "react";
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  TablePagination,
  TextField,
  InputAdornment,
  makeStyles,
  CardMedia,
  CardHeader,
  CardContent,
  Card,
} from "@material-ui/core";
import Axios from "axios";
import Notify from "src/notification/Notify";
import SearchIcon from "@material-ui/icons/Search";
import Container from "../layout/Container";
import { UsersData } from "../ContextAPI";
import PatientDetailsTriage from "./patientsontriage/PatientDetailsTriage";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const AdmissionDashboard = () => {
  const classes = useStyles();
  const { users } = useContext(UsersData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPatient, setSelectedPatient] = useState("new-patient");

  const [patient, setPatient] = useState({
    data: [],
    ready: false,
  });

  const [search, setSearch] = useState("");

  const getDoctorsPatient = useCallback(() => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    Axios.get("admission/patients/triage/getpatient-list", { params })
      .then((response) => {
        const data = response.data;
        setPatient({ data: data, ready: true });
      })
      .catch((error) => {
        console.log("error: ", error);
        // Notify.requestError(error);
      });
  }, [users]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getDoctorsPatient();
  }, [getDoctorsPatient]);

  const searchable = patient.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Contact Tracing Queue",
          items: [{ name: "dashboard", path: "/sph/app/registration" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Contact Tracing Queue</Box>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={3}>
            <Card>
              <CardHeader title={"Queuing From Triage"} />
              <CardContent>
                <Box mb={1}>
                  <TextField
                    label="Search for patient"
                    variant="outlined"
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box>
                  {patient.ready ? (
                    searchable.length > 0 ? (
                      <List component="div">
                        {searchable
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((data, index) => (
                            <ListItem
                              key={index}
                              button
                              className="mb-2"
                              onClick={() =>
                                setSelectedPatient(data.patient_id)
                              }
                              selected={selectedPatient === data.patient_id}
                            >
                              <ListItemIcon>
                                {data.image === null ? (
                                  <Avatar className={classes.primary}>
                                    {data.lastname.charAt().toUpperCase()}
                                  </Avatar>
                                ) : (
                                  <Avatar
                                    className={classes.primary}
                                    src={`${imageLocation}patients/${data.image}`}
                                    alt=""
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                className={`gtc-capitalize`}
                                primary={`${data.lastname}, ${data.firstname} ${
                                  Boolean(data.middle) ? data.middle : ``
                                } `}
                              />
                            </ListItem>
                          ))}
                      </List>
                    ) : (
                      Notify.noRecord()
                    )
                  ) : (
                    Notify.loading()
                  )}

                  <TablePagination
                    component="div"
                    count={searchable.length}
                    rowsPerPageOptions={[10, 50, 100]}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage="List"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={8} lg={9}>
            {selectedPatient === "new-patient" ? (
              <RenderSelectPatient />
            ) : (
              <PatientDetailsTriage
                patient_id={selectedPatient}
                getDoctorsPatient={getDoctorsPatient}
                setSelectedPatient={() => setSelectedPatient("new-patient")}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default AdmissionDashboard;

const RenderSelectPatient = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 250 }}
    />
    <Typography color="secondary" variant="h3">
      NO PATIENT SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select patient in the list to view details.
    </Typography>
  </Box>
);
