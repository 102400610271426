import React, { Fragment, useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Axios from "axios";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button, IconButton } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "react-feather";
import PatientCharts from "src/charts";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

function PatientChartRecords({ patient_id, viewtype }) {
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);
	const [selectedView, setSelectedView] = useState("tracenolist");
	const [selectTraceNoInList, setSelectTraceNoInList] = useState([]);
	const { users } = useContext(UsersData);
	const [traceNoList, setTraceNoList] = useState({
		data: null,
		dialog: false,
	});

	const getAllTraceNumber = () => {
		var params = {
			patient_id,
			management_id: users.management_id,
			main_mgmt_id: users.main_mgmt_id,
		};
		Axios.get("doctor/get/tracenumber-list/patient-id", { params })
			.then((response) => {
				const data = response.data;
				setTraceNoList({ data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		getAllTraceNumber();
		// eslint-disable-next-line
	}, [patient_id]);

	return (
		<Fragment>
			<Grid item xs={12} sm={12}>
				{selectedView === "tracenolist" ? (
					<Box component={Paper} elevation={2} p={2}>
						<TableContainer>
							<PerfectScrollbar>
								<Box display="flex">
									<Box pl={2} pt={2} flexGrow={1}>
										<Typography variant="h6" color="textSecondary">
											Admittion List
										</Typography>
									</Box>
								</Box>

								{traceNoList.ready ? (
									traceNoList.data.length > 0 ? (
										<>
											<Table aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>
															<strong>Date</strong>
														</TableCell>
														<TableCell>
															<strong>Reason</strong>
														</TableCell>
														<TableCell>
															<strong>Action</strong>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{traceNoList.data.map((data, index) => (
														<TableRow key={index}>
															<TableCell>
																{Notify.createdAt(data.created_at)}
															</TableCell>
															<TableCell>{data.reason}</TableCell>
															<TableCell>
																<IconButton
																	onClick={() => {
																		setSelectTraceNoInList(data);
																		setSelectedView("patientcharts");
																	}}
																	color={"primary"}
																>
																	<ArrowRight />
																</IconButton>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>

											<TablePagination
												labelRowsPerPage="List"
												rowsPerPageOptions={[5, 20, 50, 100]}
												component="div"
												count={traceNoList.data.length}
												rowsPerPage={rowsPerPage}
												page={page}
												onChangePage={handleChangePage}
												onChangeRowsPerPage={handleChangeRowsPerPage}
											/>
										</>
									) : (
										Notify.noRecord()
									)
								) : (
									Notify.loading()
								)}
							</PerfectScrollbar>
						</TableContainer>
					</Box>
				) : (
					<>
						<Box mb={1}>
							<Button
								color={"primary"}
								variant="contained"
								startIcon={<ArrowLeft />}
								onClick={() => {
									setSelectedView("tracenolist");
									setSelectTraceNoInList([]);
								}}
							>
								Back
							</Button>
						</Box>

						<PatientCharts
							patient_id={selectTraceNoInList.patients_id}
							trace_number={selectTraceNoInList.appointment_id}
							details={selectTraceNoInList}
							allowEdit={true} // allow edit for monitoring purpose
							viewtype={viewtype}
						/>
					</>
				)}
			</Grid>
		</Fragment>
	);
}

export default PatientChartRecords;
