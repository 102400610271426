import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faHome,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import HaptechDashboard from "src/haptech/HaptechDashboard";
import AccountHaptech from "src/haptech/account/Account";
import LeaveForm from "src/haptech/leave/LeaveForm";

const RouteHaptech = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("haptech/sidebar/header-infomartion", {
        params,
      });
      console.log("response.data: ", response.data);
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/sph/app/haptech",
      subitem: [],
    },
    {
      name: "Leave Application",
      show: true,
      icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
      path: "/sph/app/haptech/leave-application",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/sph/app/haptech/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
        }}
      >
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              owner: "haptech",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"haptech"}
          />
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/sph/app/haptech" />}
            />

            <Route
              exact
              path="/bmcdc"
              component={() => <Redirect to="/sph/app/haptech" />}
            />

            <Route
              exact
              path="/sph/app"
              component={() => <Redirect to="/sph/app/haptech" />}
            />

            <Route exact path="/sph/app/haptech" component={HaptechDashboard} />

            <Route
              exact
              path="/sph/app/haptech/leave-application"
              component={LeaveForm}
            />

            <Route
              exact
              path="/sph/app/haptech/account"
              component={AccountHaptech}
            />

            <Route exact path="/sph/app/logout" component={Logout} />

            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteHaptech;
