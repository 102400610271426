import React, { useContext, useState, Fragment, useEffect } from "react";
import {
	Paper,
	Box,
	Grid,
	Typography,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	// Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	CircularProgress,
	TablePagination,
	TableContainer,
	Table,
	// TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import InboxIcon from "@material-ui/icons/Inbox";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import axios from "axios";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import NoAppointmentDetected from "../NoAppointmentDetected";

const PsychologyOrder = ({ patient_id, trace_number }) => {
	const { users } = useContext(UsersData);
	const [addProcess, setAddProcess] = useState(null);
	const [cancelProcess, setCancelProcess] = useState(false);
	const [processLab, setProcessLab] = useState(false);
	const [labProcess, setLabProcess] = useState(false);

	const [deptDetails, setDeptDetails] = useState({
		data: [],
		ready: false,
	});

	const [unsaveOrder, setUnsaveOrder] = useState({
		data: [],
		ready: false,
	});

	const [cancelDialog, setCancelDialog] = useState({
		open: false,
		removeid: null,
	});

	const getDeptDetail = () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("management_id", users.management_id);

		axios
			.post("doctor/psychology/new/order/department-details", formdata)
			.then((response) => {
				const data = response.data;
				setDeptDetails({
					data: data,
					ready: true,
				});
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getUnsaveOrder = () => {
		axios
			.get("doctor/psychology/new/order/unsave-orderlist", {
				params: {
					patient_id: patient_id,
					user_id: users.user_id,
					management_id: users.management_id,
				},
			})
			.then((response) => {
				const data = response.data;
				setUnsaveOrder({
					data: data,
					ready: true,
				});
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const addLabOrder = (orderdetails) => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set("trace_number", trace_number);

		formdata.set("management_id", users.management_id);
		formdata.set("main_mgmt_id", users.main_mgmt_id);
		formdata.set("department", orderdetails.department);
		formdata.set("psychology_test_id", orderdetails.ptl_id);
		formdata.set("psychology_test", orderdetails.test);
		formdata.set("psychology_rate", orderdetails.rate);
		setAddProcess(orderdetails.ptl_id);

		axios
			.post("doctor/psychology/new/order/order-addtounsave", formdata)
			.then((response) => {
				const data = response.data;
				if (data === "success") {
					getUnsaveOrder();
					Notify.successRequest("Psychology Order Added.");
				}
			})
			.catch((error) => {
				Notify.requestError(error);
			})
			.finally(() => setAddProcess(null));
	};

	const cancelOrder = (removeid) => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set("removeid", removeid);
		setCancelProcess(true);
		axios
			.post("doctor/psychology/new/order/order-cancel", formdata)
			.then((response) => {
				const data = response.data;
				if (data === "success") {
					getUnsaveOrder();
					setCancelDialog({ open: false, removeid: null });
					Notify.successRequest("Psychology Order Cancel.");
				}
			})
			.catch((error) => {
				Notify.requestError(error);
			})
			.finally(() => setCancelProcess(false));
	};

	const handleProcessLab = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.append("user_id", users.user_id);
		formdata.append("username", users.username);
		formdata.append("patient_id", patient_id);
		formdata.append("trace_number", trace_number);

		formdata.set("management_id", users.management_id);
		formdata.set("main_mgmt_id", users.main_mgmt_id);

		var error = [];

		if (
			formdata.get("password").length === 0 ||
			formdata.get("password").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("password");
		}

		if (
			formdata.get("remarks").length === 0 ||
			formdata.get("remarks").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("remarks");
		}

		if (error.length > 0) {
			console.warn("Form error. fix to continue");
		} else {
			setLabProcess(true);
			axios
				.post("doctor/psychology/new/order/process-laborder", formdata)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						getUnsaveOrder();
						setProcessLab(false);
						Notify.successRequest("Psychology Order Process.");
					}
					if (data === "pass-invalid") {
						Notify.fieldInvalid("password");
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => setLabProcess(false));
		}
	};

	useEffect(() => {
		getDeptDetail();
		getUnsaveOrder();

		// eslint-disable-next-line
	}, [patient_id]);

	return (
		<>
			{trace_number === "tracenumber" ? (
				<NoAppointmentDetected title={"Psychology order"} />
			) : (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={6} lg={4}>
							<Paper component={Box} elevation={2} p={2} mb={2}>
								<Box mb={2}>
									<Typography variant="h6" color="textSecondary">
										Select Order
									</Typography>
								</Box>

								<Box>
									<RenderAvailableTest
										addProcess={addProcess}
										deptDetails={deptDetails}
										addLabOrder={addLabOrder}
									/>
								</Box>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={12} md={6} lg={8}>
							<Paper component={Box} elevation={2} p={2} mb={2}>
								<Box mb={2}>
									<Typography variant="h6" color="textSecondary">
										Unsave Order
									</Typography>
								</Box>

								<Box mb={1}>
									<TableContainer>
										<Table>
											<TableBody>
												{unsaveOrder.ready ? (
													unsaveOrder.data.length > 0 ? (
														unsaveOrder.data.map((data, index) => (
															<TableRow key={index}>
																<TableCell
																	style={{ textTransform: "capitalize" }}
																>
																	<b style={{ fontSize: 16 }}>
																		{data.psychology_test}
																	</b>
																	<br />
																	{data.department}
																</TableCell>
																<TableCell
																	align="right"
																	style={{ fontWeight: "bolder" }}
																>
																	&#8369;{" "}
																	{Notify.convertToNumber(data.psychology_rate)}
																</TableCell>
																<TableCell align="center">
																	<IconButton
																		color="secondary"
																		onClick={() => {
																			setCancelDialog({
																				open: true,
																				removeid: data.id,
																			});
																		}}
																	>
																		<DeleteSweepIcon />
																	</IconButton>
																</TableCell>
															</TableRow>
														))
													) : (
														<Box
															display="flex"
															justifyContent="center"
															align="center"
														>
															<Box>
																<InboxIcon
																	fontSize={"large"}
																	color="secondary"
																/>
																<Typography
																	variant="subtitle2"
																	color="secondary"
																>
																	No unsave order found.
																</Typography>
															</Box>
														</Box>
													)
												) : (
													<Box>{Notify.loading()}</Box>
												)}
											</TableBody>
										</Table>
									</TableContainer>

									{/* <List>
                {unsaveOrder.ready ? (
                  unsaveOrder.data.length > 0 ? (
                    unsaveOrder.data.map((data, index) => (
                      <Fragment key={index}>
                        <ListItem
                          divider={unsaveOrder.data.length !== index + 1}
                        >
                          <ListItemText
                            className={`gtc-capitalize`}
                            primary={
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <Typography>
                                    {data.psychology_test}
                                  </Typography>
                                </Box>
                                <Box flexGrow={1}>
                                  <Typography>
                                    <b> &#8369; {data.psychology_rate} </b>
                                  </Typography>
                                </Box>
                              </Box>
                            }
                            secondary={data.department}
                          />
                          <ListItemIcon>
                            <Tooltip arrow title="Cancel Order">
                              <IconButton
                                color="secondary"
                                onClick={() => {
                                  setCancelDialog({
                                    open: true,
                                    removeid: data.id,
                                  });
                                }}
                              >
                                <DeleteSweepIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemIcon>
                        </ListItem>
                      </Fragment>
                    ))
                  ) : (
                    <Box display="flex" justifyContent="center" align="center">
                      <Box>
                        <InboxIcon fontSize={"large"} color="secondary" />
                        <Typography variant="subtitle2" color="secondary">
                          No unsave order found.
                        </Typography>
                      </Box>
                    </Box>
                  )
                ) : (
                  <Box>{Notify.loading()}</Box>
                )}
              </List> */}
								</Box>
								<Box display={"flex"}>
									<Box flexGrow={1} />
									<Box>
										<Button
											hidden={unsaveOrder.data.length === 0}
											size="large"
											variant="contained"
											color="primary"
											onClick={() => setProcessLab(true)}
										>
											Process Order
										</Button>
									</Box>
								</Box>
							</Paper>
						</Grid>
					</Grid>

					{/* confirm cancel dialog render */}
					{cancelDialog.open && cancelDialog.removeid && (
						<CancelOrderConfirmation
							cancelOrder={cancelOrder}
							removeid={cancelDialog.removeid}
							open={cancelDialog.open}
							close={() => setCancelDialog({ open: false, removeid: null })}
							cancelProcess={cancelProcess}
						/>
					)}

					{/* process laboratory dialog */}
					<Dialog
						open={processLab}
						onClose={() => setProcessLab(false)}
						disableBackdropClick
						disableEscapeKeyDown
						maxWidth={"xs"}
						fullWidth
					>
						<form onSubmit={handleProcessLab}>
							<DialogTitle>Process Order</DialogTitle>
							<DialogContent dividers>
								<Box>
									<TextField
										label="Order Remarks"
										margin="normal"
										variant="outlined"
										name="remarks"
										multiline
										fullWidth
										rows={4}
									/>
								</Box>
								<Box>
									<TextField
										label="Password"
										margin="normal"
										variant="outlined"
										name="password"
										type="password"
										fullWidth
									/>
								</Box>
							</DialogContent>
							<DialogActions>
								<Button
									color="default"
									variant="contained"
									onClick={() => setProcessLab(false)}
									startIcon={<HighlightOffIcon />}
								>
									No
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={labProcess}
									startIcon={
										labProcess ? (
											<CircularProgress color="inherit" size={20} />
										) : (
											<CheckCircleOutlineIcon />
										)
									}
								>
									Process
								</Button>
							</DialogActions>
						</form>
					</Dialog>
				</>
			)}
		</>
	);
};

export default PsychologyOrder;

const RenderAvailableTest = ({ deptDetails, addLabOrder, addProcess }) => {
	const [search, setSearch] = useState("");

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const searchable = deptDetails.data.filter((data) => {
		return data.test.toLowerCase().indexOf(search.trim()) !== -1;
	});

	return (
		<>
			<Box>
				<TextField
					label="Search Order"
					variant="outlined"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					fullWidth
					margin="dense"
				/>
			</Box>

			<List>
				{deptDetails.data.length > 0 ? (
					(rowsPerPage > 0
						? searchable.slice(
								page * rowsPerPage,
								page * rowsPerPage + rowsPerPage
						  )
						: searchable
					).map((data, index) => (
						<Fragment key={index}>
							<ListItem divider={deptDetails.data.length !== index + 1}>
								<ListItemText
									className={`gtc-capitalize`}
									primary={data.test}
									secondary={data.department}
								/>
								<ListItemIcon>
									<IconButton
										color="primary"
										disabled={addProcess === data.ptl_id}
										onClick={() => {
											addLabOrder(data);
										}}
									>
										{addProcess === data.ptl_id ? (
											<CircularProgress size={20} color="inherit" />
										) : (
											<PlaylistAddIcon />
										)}
									</IconButton>
								</ListItemIcon>
							</ListItem>
						</Fragment>
					))
				) : (
					<Typography align="center" color="secondary" variant="subtitle2">
						No order found
					</Typography>
				)}
			</List>
			<TablePagination
				labelRowsPerPage="List"
				rowsPerPageOptions={[5, 20, 50, 100]}
				component="div"
				count={searchable.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</>
	);
};

const CancelOrderConfirmation = ({
	cancelProcess,
	cancelOrder,
	open,
	close,
	removeid,
}) => (
	<Dialog open={open} onClose={close}>
		<DialogContent>
			<Box>
				<Typography>Are you sure to cancel this order?</Typography>
			</Box>
		</DialogContent>

		<DialogActions>
			<Button
				variant="contained"
				color="default"
				onClick={close}
				startIcon={<HighlightOffIcon />}
			>
				No
			</Button>
			<Button
				variant="contained"
				color="secondary"
				onClick={() => cancelOrder(removeid)}
				disabled={cancelProcess}
				startIcon={
					cancelProcess ? (
						<CircularProgress color="inherit" size={20} />
					) : (
						<DeleteSweepIcon />
					)
				}
			>
				Yes
			</Button>
		</DialogActions>
	</Dialog>
);
