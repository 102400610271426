import {
	Box,
	Grid,
	Paper,
	TextField,
	Typography,
	CircularProgress,
	FormHelperText,
	Button,
	IconButton,
} from "@material-ui/core";
import Axios from "axios";
import React, { useState, useContext } from "react";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import {
	Edit as EditIcon,
	HighlightOff as ClearIcon,
	CheckCircleOutline as CheckIcon,
} from "@material-ui/icons";
import { UsersData } from "src/ContextAPI";
import { isMobile } from "react-device-detect";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";

const PatientDetailsProfileTriage = ({
	patient_id,
	getDoctorsPatient,
	getPatientInformation,
	info,
}) => {
	const { users } = useContext(UsersData);
	const [edit, setEdit] = useState(false);

	const [accreditedCompany, setAccreditedCompany] = useState([]);
	const mounted = IsMountedRef();

	const getFormData = (object) => {
		const formData = new FormData();
		Object.keys(object).forEach((key) => formData.append(key, object[key]));
		return formData;
	};

	const getAccreditedCompanyList = React.useCallback(async () => {
		let response = await axios.get("admission/accredited/company/get-list", {
			params: {
				management_id: users.management_id,
			},
		});

		if (mounted.current) {
			setAccreditedCompany(response.data);
		}
	}, [mounted, users]);

	React.useEffect(() => {
		getAccreditedCompanyList();
		// eslint-disable-next-line
	}, [getAccreditedCompanyList]);

	return (
		<Box component={Paper} variant="outlined" mt={2} p={2}>
			<Box className={edit ? "" : `gtc-textfield-noborder`}>
				{info.ready ? (
					info.data ? (
						<Formik
							enableReinitialize
							initialValues={{
								username: users.username,
								user_id: users.user_id,
								// password: "",
								patient_id: info.data.patient_id,
								lastname: info.data.lastname,
								firstname: info.data.firstname,
								middlename: info.data.middle === null ? "" : info.data.middle,
								gender: info.data.gender === null ? "" : info.data.gender,
								birthday: info.data.birthday === null ? "" : info.data.birthday,
								birthplace:
									info.data.birthplace === null ? "" : info.data.birthplace,
								street: info.data.street === null ? "" : info.data.street,
								barangay: info.data.barangay === null ? "" : info.data.barangay,
								city: info.data.city === null ? "" : info.data.city,
								occupation:
									info.data.occupation === null ? "" : info.data.occupation,
								civil_status:
									info.data.civil_status === null ? "" : info.data.civil_status,
								religion: info.data.religion === null ? "" : info.data.religion,
								mobile: info.data.mobile === null ? "" : info.data.mobile,
								telephone:
									info.data.telephone === null ? "" : info.data.telephone,
								email: info.data.email === null ? "" : info.data.email,

								philhealth:
									info.data.philhealth === null ? "" : info.data.philhealth,

								company: info.data.company === null ? "" : info.data.company,

								// health
								height: info.data.height === null ? "" : info.data.height,
								weight: info.data.weight === null ? "" : info.data.weight,
								pulse: info.data.pulse === null ? "" : info.data.pulse,
								temp:
									info.data.temperature === null ? "" : info.data.temperature,
								bp_systolic:
									info.data.blood_systolic === null
										? ""
										: info.data.blood_systolic,
								bp_diastolic:
									info.data.blood_diastolic === null
										? ""
										: info.data.blood_diastolic,
								blood_type:
									info.data.blood_type === null ? "" : info.data.blood_type,
							}}
							validationSchema={Yup.object().shape({
								// password: Yup.string().trim().required("Password is required."),
								lastname: Yup.string().trim().required("Lastname is required."),
								firstname: Yup.string()
									.trim()
									.required("Firstname is required."),
								gender: Yup.string().trim().required("Gender is required."),
								birthday: Yup.date().required("Birth date is required."),
								street: Yup.string().trim().required("Street is required."),
								barangay: Yup.string().trim().required("Barangay is required."),
								city: Yup.string().trim().required("City is required."),
								occupation: Yup.string()
									.trim()
									.required("Occupation is required."),
								civil_status: Yup.string()
									.trim()
									.required("Civil status is required."),
								religion: Yup.string().trim().required("Religion is required."),
								mobile: Yup.string().trim().required("Mobile is required."),
							})}
							onSubmit={async (
								values,
								{ setErrors, setSubmitting, resetForm }
							) => {
								try {
									const request = await Axios.post(
										"admission/patients/edit-patient",
										getFormData(values)
									);
									// if (request.data === "pass-invalid") {
									//   setErrors({ password: "Password is invalid." });
									//   Notify.fieldInvalid("password");
									// }
									if (request.data === "success") {
										getDoctorsPatient();
										getPatientInformation();
										Notify.successRequest("update patient");
										resetForm();
										setSubmitting(true);
										setEdit(false);
									}
								} catch (error) {
									const message = error.message || "Something went wrong";
									setErrors({ submit: message });
									setSubmitting(false);
								}
							}}
						>
							{({
								errors,
								handleBlur,
								handleChange,
								handleSubmit,
								isSubmitting,
								touched,
								values,
							}) => (
								<form noValidate onSubmit={handleSubmit}>
									<Box
										mb={2}
										display="flex"
										justifyContent="center"
										alignItems="center"
									>
										<Box flexGrow={1}>
											<Typography color="primary" variant="subtitle2">
												PATIENT INFORMATION
											</Typography>
										</Box>
										<Box hidden={edit}>
											{isMobile ? (
												<IconButton
													color="primary"
													onClick={() => setEdit(true)}
												>
													<EditIcon fontSize="small" />
												</IconButton>
											) : (
												<Button
													variant="contained"
													color="primary"
													startIcon={<EditIcon />}
													onClick={() => setEdit(true)}
												>
													Edit
												</Button>
											)}
										</Box>
									</Box>

									<Box my={1}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(touched.lastname && errors.lastname)}
														helperText={touched.lastname && errors.lastname}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.lastname}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														required
														name="lastname"
														label="Lastname"
														variant="outlined"
													/>
												</Box>
											</Grid>

											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(
															touched.firstname && errors.firstname
														)}
														helperText={touched.firstname && errors.firstname}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.firstname}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														required
														name="firstname"
														label="Firstname"
														variant="outlined"
													/>
												</Box>
											</Grid>

											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(
															touched.middlename && errors.middlename
														)}
														helperText={touched.middlename && errors.middlename}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.middlename}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														name="middlename"
														label="Middle Name"
														variant="outlined"
													/>
												</Box>
											</Grid>
										</Grid>
									</Box>

									<Box my={1}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(touched.gender && errors.gender)}
														helperText={touched.gender && errors.gender}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.gender}
														fullWidth
														required
														name="gender"
														label="Gender"
														variant="outlined"
														select
														SelectProps={{
															native: true,
															disabled: !edit,
														}}
														InputLabelProps={{
															shrink: true,
														}}
													>
														<option value={""}>Select</option>
														<option value={"Male"}>Male</option>
														<option value={"Female"}>Female</option>
													</TextField>
												</Box>
											</Grid>

											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(touched.birthday && errors.birthday)}
														helperText={touched.birthday && errors.birthday}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.birthday}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														required
														name="birthday"
														label="Birthday"
														variant="outlined"
														type="date"
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Box>
											</Grid>

											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(
															touched.birthplace && errors.birthplace
														)}
														helperText={touched.birthplace && errors.birthplace}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.birthplace}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														required
														name="birthplace"
														label="Birth Place"
														variant="outlined"
													/>
												</Box>
											</Grid>
										</Grid>
									</Box>

									<Box my={1}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(touched.street && errors.street)}
														helperText={touched.street && errors.street}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.street}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														required
														name="street"
														label="Street"
														variant="outlined"
													/>
												</Box>
											</Grid>

											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(touched.barangay && errors.barangay)}
														helperText={touched.barangay && errors.barangay}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.barangay}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														required
														name="barangay"
														label="Barangay"
														variant="outlined"
													/>
												</Box>
											</Grid>

											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(touched.city && errors.city)}
														helperText={touched.city && errors.city}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.city}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														required
														name="city"
														label="City"
														variant="outlined"
													/>
												</Box>
											</Grid>
										</Grid>
									</Box>

									<Box my={1}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(
															touched.occupation && errors.occupation
														)}
														helperText={touched.occupation && errors.occupation}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.occupation}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														required
														name="occupation"
														label="Occupation"
														variant="outlined"
													/>
												</Box>
											</Grid>

											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(
															touched.civil_status && errors.civil_status
														)}
														helperText={
															touched.civil_status && errors.civil_status
														}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.civil_status}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														required
														name="civil_status"
														label="Civil Status"
														variant="outlined"
														select
														SelectProps={{
															native: true,
															disabled: !edit,
														}}
														InputLabelProps={{
															shrink: true,
														}}
													>
														<option value={""}>Select</option>
														<option value={"Single"}>Single</option>
														<option value={"Married"}>Married</option>
														<option value={"Divorced"}>Divorced</option>
													</TextField>
												</Box>
											</Grid>

											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(touched.religion && errors.religion)}
														helperText={touched.religion && errors.religion}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.religion}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														required
														name="religion"
														label="Religion"
														variant="outlined"
													/>
												</Box>
											</Grid>
										</Grid>
									</Box>

									<Box my={1}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(touched.mobile && errors.mobile)}
														helperText={touched.mobile && errors.mobile}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.mobile}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														required
														name="mobile"
														label="Mobile"
														variant="outlined"
													/>
												</Box>
											</Grid>

											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(
															touched.telephone && errors.telephone
														)}
														helperText={touched.telephone && errors.telephone}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.telephone}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														name="telephone"
														label="Telephone"
														variant="outlined"
													/>
												</Box>
											</Grid>

											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(touched.email && errors.email)}
														helperText={touched.email && errors.email}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.email}
														fullWidth
														InputProps={{
															readOnly: true,
														}}
														required
														name="email"
														label="Email"
														variant="outlined"
													/>
													<FormHelperText error>
														{" "}
														Email address not editable{" "}
													</FormHelperText>
												</Box>
											</Grid>
										</Grid>
									</Box>

									<Box my={1}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(
															touched.philhealth && errors.philhealth
														)}
														helperText={touched.philhealth && errors.philhealth}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.philhealth}
														fullWidth
														InputProps={{
															readOnly: !edit,
														}}
														name="philhealth"
														label="Phil Health"
														variant="outlined"
													/>
												</Box>
											</Grid>

											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														select
														SelectProps={{ native: true }}
														InputLabelProps={{ shrink: true }}
														error={Boolean(touched.company && errors.company)}
														helperText={touched.company && errors.company}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.company}
														fullWidth
														required
														name="company"
														label="Company"
														variant="outlined"
														InputProps={{
															disabled: !edit,
														}}
													>
														<option value=""> Select </option>
														{accreditedCompany.length > 0 ? (
															accreditedCompany.map((data, index) => (
																<option key={index} value={data.value}>
																	{data.label}
																</option>
															))
														) : (
															<option value="" disabled>
																No company found.
															</option>
														)}
													</TextField>
												</Box>
											</Grid>

											<Grid item xs={12} sm={4}>
												<Box>
													<TextField
														error={Boolean(touched.temp && errors.temp)}
														helperText={touched.temp && errors.temp}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.temp}
														fullWidth
														required
														name="temp"
														label="Temperature"
														variant="outlined"
														type="number"
														InputProps={{
															inputProps: {
																min: 1,
																max: 10000,
																step: 0.01,
															},
															readOnly: !edit,
														}}
													/>
												</Box>
											</Grid>
										</Grid>
									</Box>

									<Box my={1}>
										<Box mb={2}>
											<Typography color="textSecondary">
												{" "}
												Health Records{" "}
											</Typography>
										</Box>

										<Grid container spacing={3}>
											<Grid item xs={12} sm={6}>
												<Box>
													<TextField
														error={Boolean(touched.height && errors.height)}
														helperText={touched.height && errors.height}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.height}
														fullWidth
														required
														name="height"
														label="Height(ft)"
														variant="outlined"
														type="number"
														InputProps={{
															inputProps: {
																min: 1,
																max: 10000,
																step: 0.01,
															},
															readOnly: !edit,
														}}
													/>
												</Box>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Box>
													<TextField
														error={Boolean(touched.weight && errors.weight)}
														helperText={touched.weight && errors.weight}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.weight}
														fullWidth
														required
														name="weight"
														label="Weight(kg)"
														variant="outlined"
														type="number"
														InputProps={{
															inputProps: {
																min: 1,
																max: 10000,
																step: 0.01,
															},
															readOnly: !edit,
														}}
													/>
												</Box>
											</Grid>
										</Grid>

										<Grid container spacing={3}>
											<Grid item xs={12} sm={6}>
												<Box>
													<TextField
														error={Boolean(touched.pulse && errors.pulse)}
														helperText={touched.pulse && errors.pulse}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.pulse}
														fullWidth
														required
														name="pulse"
														label="Pulse"
														variant="outlined"
														type="number"
														InputProps={{
															inputProps: {
																min: 1,
																max: 10000,
																step: 0.01,
															},
															readOnly: !edit,
														}}
													/>
												</Box>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Box>
													<TextField
														error={Boolean(
															touched.blood_type && errors.blood_type
														)}
														helperText={touched.blood_type && errors.blood_type}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.blood_type}
														fullWidth
														required
														name="blood_type"
														label="Blood Type"
														variant="outlined"
														select
														SelectProps={{ native: true, disabled: !edit }}
														InputLabelProps={{
															shrink: true,
														}}
													>
														<option value=""> Select </option>
														<option value="A"> A </option>
														<option value="B"> B </option>
														<option value="AB"> AB </option>
														<option value="O"> O </option>
														<option value="A-negative"> A-Negative </option>
														<option value="B-positive"> B-Positive </option>
														<option value="B-negative"> B-Negative </option>
														<option value="O-positive"> O-Positive </option>
														<option value="O-negative"> O-Negative </option>
														<option value="AB-positive">AB-Positive </option>
														<option value="AB-negative">AB-Negative </option>
													</TextField>
												</Box>
											</Grid>
										</Grid>

										<Grid container spacing={3}>
											<Grid item xs={12} sm={6}>
												<Box>
													<TextField
														error={Boolean(
															touched.bp_systolic && errors.bp_systolic
														)}
														helperText={
															touched.bp_systolic && errors.bp_systolic
														}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.bp_systolic}
														fullWidth
														required
														name="bp_systolic"
														label="BP Systolic"
														variant="outlined"
														type="number"
														InputProps={{
															inputProps: {
																min: 1,
																max: 10000,
																step: 0.01,
															},
															readOnly: !edit,
														}}
													/>
												</Box>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Box>
													<TextField
														error={Boolean(
															touched.bp_diastolic && errors.bp_diastolic
														)}
														helperText={
															touched.bp_diastolic && errors.bp_diastolic
														}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.bp_diastolic}
														fullWidth
														required
														name="bp_diastolic"
														label="BP Diastolic"
														variant="outlined"
														type="number"
														InputProps={{
															inputProps: {
																min: 1,
																max: 10000,
																step: 0.01,
															},
															readOnly: !edit,
														}}
													/>
												</Box>
											</Grid>
										</Grid>
									</Box>

									<Box hidden={!edit}>
										{/* <Box my={1}>
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        fullWidth
                        InputProps={{
                          readOnly: !edit,
                        }}
                        required
                        name="password"
                        type="password"
                        label="Password"
                        variant="outlined"
                      />
                    </Box> */}

										{errors.submit && (
											<Box mt={2}>
												<FormHelperText error>{errors.submit}</FormHelperText>
											</Box>
										)}

										<Box mb={1} mt={1} display="flex">
											<Box flexGrow={1} />

											<Button
												variant="contained"
												color="default"
												onClick={() => setEdit(false)}
												startIcon={<ClearIcon />}
											>
												Cancel
											</Button>
											<Box ml={2}>
												<Button
													type="submit"
													variant="contained"
													color="primary"
													startIcon={
														isSubmitting ? (
															<CircularProgress size={20} color="inherit" />
														) : (
															<CheckIcon />
														)
													}
													disabled={isSubmitting}
												>
													Save
												</Button>
											</Box>
										</Box>
									</Box>
								</form>
							)}
						</Formik>
					) : (
						Notify.noRecord()
					)
				) : (
					Notify.loading()
				)}
			</Box>
		</Box>
	);
};

export default PatientDetailsProfileTriage;
