import { faVirus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import { localArraySearch } from "src/helpers/utils";
import DiseasesFormModal from "./components/DiseasesFormModal";

const PhoDiseases = () => {
	const [list, setList] = useState([]);
	const [keyword, setKeyword] = useState("");
	const { data, loading, updateInList } = useDataTable(
		"/v1/diseases/",
		setList,
		{}
	);
	useEffect(() => {
		if (data) {
			setList(data || []);
		}
	}, [data]);
	const diseases_modal = useRef(null);
	const columns = [
		{
			header: "Disease",
			accessorKey: "name",
			headerClassName: "text-left min-w-[328px]",
			className: "text-left",
		},
		{
			header: "Color",
			accessorKey: "color",
			headerClassName: "text-left",
			className: "text-left",
			cell: (data) => {
				return (
					<div
						className="w-6 h-6 rounded-lg"
						style={{ background: data?.color }}
					/>
				);
			},
		},
		{
			header: "Radius",
			accessorKey: "radius",
			headerClassName: "text-center",
			className: "text-center",
			cell: (data) => {
				return data?.radius + " meters";
			},
		},
		{
			header: "Type",
			accessorKey: "type",
			headerClassName: "text-left",
			className: "text-left",
		},
		{
			header: "Action",
			accessorKey: "action",
			className: "w-[244px]",
			cell: (data) => {
				return (
					<div className="flex items-center gap-2">
						<ActionBtn
							size="sm"
							type="primary-light"
							onClick={() => {
								console.log("datadata", data);
								diseases_modal.current.show(data);
							}}
						>
							<FlatIcon icon="rr-edit" className="text-lg mr-2" />
							Edit
						</ActionBtn>
					</div>
				);
			},
		},
	];
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon icon={faVirus} color="white" size={"2x"} />
				}
				title="Diseases"
				subtitle="List of diseases"
			>
				<CardContainer
					containerClassName={` border border- border-opacity-10 w-full`}
					header={{
						title: "Diseases list data",
						children: (
							<div className="ml-auto flex items-center gap-3">
								{/* <ActionBtn
									size="sm"
									type="primary"
									onClick={() => {
										diseases_modal.current.show();
									}}
								>
									<FlatIcon
										icon="rr-plus"
										className="text-lg mr-2"
									/>
									Add record
								</ActionBtn> */}
								<TextInputField
									inputClassName=" pl-10"
									className="w-full lg:w-[320px]"
									onChange={(e) => {
										setKeyword(e.target.value);
									}}
									icon={
										<FlatIcon
											icon="rr-search"
											className="text-sm"
										/>
									}
									placeholder="Search"
								/>
							</div>
						),
					}}
					className="!p-0"
				>
					<div className="max-h-[calc(100vh-328px)] overflow-auto">
						<TableV2
							paginationClassName="px-4"
							columns={columns}
							pagination={false}
							loading={loading}
							data={
								keyword?.length > 0
									? localArraySearch(list, keyword)
									: list
							}
							onTableChange={(data) => {}}
							theadClassName={`sticky top-[-1px]`}
						/>
					</div>
				</CardContainer>
			</LayoutContainer>
			<DiseasesFormModal
				ref={diseases_modal}
				updateInList={updateInList}
			/>
		</>
	);
};

export default PhoDiseases;
