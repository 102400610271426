import React, { useState, useCallback, useEffect } from "react";
import { Grid, Box, TextField, Button, useTheme } from "@material-ui/core";
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";
import IsMountedRef from "src/utils/IsMountedRef";
import Axios from "axios";
import Select from "react-select";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";

const AccountingCreatePurchaseOrder = ({
  getTempDrProductsList,
  request_id,
}) => {
  const mounted = IsMountedRef();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [product, setProduct] = useState();
  const [batches, setBatches] = useState([]);
  const [selectedBatchDetails, setSelectedBatchDetails] = useState({});
  const { users } = React.useContext(UsersData);
  const [selectedSort, setSelectedSort] = useState(null);
  const [brand, setBrand] = useState();
  const [description, setDescription] = useState();
  const [productSrp, setProductSrp] = useState(0);
  const theme = useTheme();

  const getProductBatches = async (product_id) => {
    let response = await Axios.get(
      "accounting/warehouse/inventory/product-details",
      {
        params: {
          management_id: users.management_id,
          main_mgmt_id: users.main_mgmt_id,
          product_id: product_id,
        },
      }
    );

    setBatches(response.data);
  };

  const handlvalueGetAllDescByBrand = async (data) => {
    let response = await Axios.get(
      "accounting/warehouse/new-invoice/get-desclist",
      {
        params: {
          management_id: users.management_id,
          main_mgmt_id: users.main_mgmt_id,
          brand: data,
        },
      }
    );
    setDescription(response.data);
  };

  const getProductBatchesDetails = async (batch_number) => {
    let response = await Axios.get(
      "accounting/warehouse/account/getProductBatchesDetails",
      {
        params: {
          management_id: users.management_id,
          main_mgmt_id: users.main_mgmt_id,
          product_id: selectedProduct.product_id,
          batch_number: batch_number,
        },
      }
    );

    if (response.data.length > 0) {
      setSelectedBatchDetails({
        ...selectedBatchDetails,
        batch_number: batch_number,
        exp_date: response.data[0].expiration_date,
        mfg_date: response.data[0].manufactured_date,
        availableQty:
          parseInt(response.data[0]._total_in_qty) -
          parseInt(response.data[0]._total_out_qty) -
          parseInt(response.data[0]._total_out_qty_intemp),
      });
    }

    console.log("select batch bracket", response.data);
  };

  const handleSelectedProductAdd = async () => {
    var error = [];
    try {
      if (
        parseInt(selectedBatchDetails.orderQty) >
        parseInt(selectedBatchDetails.availableQty)
      ) {
        Notify.customToast(
          "Order quantity Invalid.",
          "Order quantity must be less than in available qty."
        );
        error = "error";
      }

      if (Object.keys(selectedProduct).length === 0) {
        Notify.fieldRequired("product");
        error = "error";
      } else {
        if (!selectedProduct.product_name) {
          Notify.fieldRequired("product");
          error = "error";
        }
      }

      if (Object.keys(selectedBatchDetails).length === 0) {
        Notify.fieldRequired("product batch");
        error = "error";
      } else {
        if (!selectedBatchDetails.orderQty) {
          Notify.fieldRequired("qty");
          error = "error";
        }
      }

      if (error.length > 0) {
        console.log("form has an error");
      } else {
        var formdata = new FormData();
        formdata.set("user_id", users.user_id);
        formdata.set("management_id", users.management_id);
        formdata.set("main_mgmt_id", users.main_mgmt_id);
        formdata.set("product_id", selectedProduct.product_id);
        formdata.set("brand", selectedProduct.brandName);
        formdata.set("product_name", selectedProduct.product_name);
        formdata.set("product_generic", selectedProduct.product_generic);
        formdata.set("unit", selectedProduct.unit);
        formdata.set("msrp", selectedProduct.msrp);
        formdata.set("srp", productSrp);
        formdata.set("batch_number", selectedBatchDetails.batch_number);
        formdata.set("expiration_date", selectedBatchDetails.exp_date);
        formdata.set("manufactured_date", selectedBatchDetails.mfg_date);
        formdata.set("availableQty", selectedBatchDetails.availableQty);
        formdata.set("orderQty", selectedBatchDetails.orderQty);
        formdata.set("request_id", request_id);

        let response = await Axios.post(
          "accounting/create/add-dr-product-to-temp",
          formdata
        );
        let result = response.data;

        if (result.message === "product-exist") {
          Notify.customToast("Product Exist.", "Product is already added.");
        }
        if (result.message === "success") {
          getTempDrProductsList();
          setSelectedProduct({});
          setSelectedBatchDetails({});
          setBatches([]);
          setSelectedSort(null);
          setDescription();
          getBrandList();
          Notify.successRequest("Product added to temp.");
        }
      }
    } catch (error) {
      Notify.requestError(error);
    }
  };

  const getBrandList = useCallback(async () => {
    let response = await Axios.get(
      "accounting/warehouse/new-invoice/get-brandlist",
      {
        params: {
          user_id: users.user_id,
          management_id: users.management_id,
          main_mgmt_id: users.main_mgmt_id,
        },
      }
    );
    if (mounted.current) {
      setBrand(response.data);
    }
  }, [users, mounted]);

  const getProductList = useCallback(async () => {
    try {
      let response = await Axios.get(
        "accounting/warehouse/new-invoice/get-productlist",
        {
          params: {
            user_id: users.user_id,
            management_id: users.management_id,
            main_mgmt_id: users.main_mgmt_id,
          },
        }
      );
      if (mounted.current) {
        setProduct(response.data);
      }
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, mounted]);

  useEffect(() => {
    getProductList();
    getBrandList();
  }, [getProductList, getBrandList]);

  return (
    <>
      <Box mb={2}>
        <TextField
          required
          fullWidth
          name="sort"
          label="Sort"
          variant="outlined"
          select
          onChange={(e) => {
            setSelectedSort(e.target.value);
            setSelectedProduct({});
            setDescription();
          }}
          SelectProps={{
            native: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        >
          <option value="">Select</option>
          <option value="code">Code</option>
          <option value="brand">Brand & Description</option>
        </TextField>
      </Box>

      {selectedSort === "code" && (
        <>
          <Box mb={2}>
            <Select
              options={product}
              onChange={(value) => {
                setSelectedProduct(value);
                getProductBatches(value.product_id);
                setProductSrp(value.srp);
              }}
              placeholder="Select Product"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 99999,
                  color: "#000",
                }),
                control: (base) => ({
                  ...base,
                  minHeight: 55,
                  backgroundColor: "transparent",
                }),
                singleValue: () => ({
                  color: theme.palette.type === "dark" ? "#fff" : "#000",
                }),
              }}
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              value={
                Boolean(Object.keys(selectedProduct).length)
                  ? selectedProduct.product_generic
                  : ""
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </>
      )}

      {selectedSort === "brand" && (
        <>
          <Box mb={2}>
            <Select
              options={brand}
              onChange={(value) => {
                handlvalueGetAllDescByBrand(value.label);
              }}
              placeholder="Select Brand"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 99999,
                  color: "#000",
                }),
                control: (base) => ({
                  ...base,
                  minHeight: 55,
                  backgroundColor: "transparent",
                }),
                singleValue: () => ({
                  color: theme.palette.type === "dark" ? "#fff" : "#000",
                }),
              }}
            />
          </Box>

          <Box mb={2}>
            <Select
              options={description}
              onChange={(value) => {
                setSelectedProduct(value);
                getProductBatches(value.product_id);
                setProductSrp(value.srp);
              }}
              placeholder="Select Description"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 99999,
                  color: "#000",
                }),
                control: (base) => ({
                  ...base,
                  minHeight: 55,
                  backgroundColor: "transparent",
                }),
                singleValue: () => ({
                  color: theme.palette.type === "dark" ? "#fff" : "#000",
                }),
              }}
            />
          </Box>
        </>
      )}

      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                label="Unit"
                variant="outlined"
                fullWidth
                value={
                  Boolean(Object.keys(selectedProduct).length)
                    ? selectedProduct.unit
                    : ""
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                label="Srp"
                variant="outlined"
                fullWidth
                value={productSrp}
                onChange={(e) => setProductSrp(e.target.value)}
                name="productSrp"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mb={2}>
        <TextField
          label="Select batch"
          select
          SelectProps={{
            native: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
          onChange={(e) => {
            getProductBatchesDetails(e.target.value);
          }}
        >
          <option value=""> Select </option>
          {batches.map((data, index) => (
            <option key={index} value={data.batch_number}>
              {data.batch_number} - ({data._total_available_qty})
            </option>
          ))}
        </TextField>
      </Box>

      <Box mb={2}>
        <TextField
          label="Available Qty"
          variant="outlined"
          fullWidth
          value={
            Object.keys(selectedBatchDetails).length > 0
              ? selectedBatchDetails.availableQty
              : 0
          }
          InputProps={{ disabled: true }}
        />
      </Box>

      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                label="Mfg. Date"
                variant="outlined"
                fullWidth
                value={
                  Object.keys(selectedBatchDetails).length > 0
                    ? selectedBatchDetails.mfg_date
                    : ""
                }
                InputProps={{ disabled: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                label="Exp. Date"
                variant="outlined"
                fullWidth
                value={
                  Object.keys(selectedBatchDetails).length > 0
                    ? selectedBatchDetails.exp_date
                    : ""
                }
                InputProps={{ disabled: true }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mb={2}>
        <TextField
          label="Quantity"
          variant="outlined"
          fullWidth
          value={
            selectedBatchDetails
              ? selectedBatchDetails.orderQty
                ? selectedBatchDetails.orderQty
                : ""
              : ""
          }
          onChange={(e) =>
            setSelectedBatchDetails({
              ...selectedBatchDetails,
              orderQty: e.target.value,
            })
          }
        />
      </Box>

      <Box display="flex">
        <Box mr={2}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<CheckCircleOutline />}
            onClick={handleSelectedProductAdd}
          >
            Add
          </Button>
        </Box>

        <Button
          variant="contained"
          color="default"
          size="large"
          startIcon={<HighlightOff />}
        >
          Clear
        </Button>
      </Box>
    </>
  );
};

export default AccountingCreatePurchaseOrder;
