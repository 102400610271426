import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersData, UsersHeader } from "../ContextAPI";
import CashierDashboard from "../cashier/CashierDashboard";
import Account from "../cashier/account/Account";
import BillingReceipt from "src/cashier/billing/BillingReceipt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAddressCard,
	// faCoins,
	faFileInvoice,
	faHome,
	faHospitalUser,
	// faListAlt,
	faListOl,
	faProcedures,
	// faReceipt,
	// faListUl,
	faUserInjured,
	faUserLock,
} from "@fortawesome/free-solid-svg-icons";
// import CashierPatientsForCharge from "src/cashier/charge/CashierPatientsForCharge";
import LeaveForm from "src/cashier/leave/LeaveForm";
import PatientQueue from "src/cashier/queue/PatientQueue";
import ReportCashier from "src/cashier/report";
import IsMountedRef from "src/utils/IsMountedRef";
import { Badge } from "@material-ui/core";
import DoctorsWage from "src/cashier/doctorwage/DoctorsWage";
import HMO from "src/cashier/hmolist/HMO";
import CashierQueuingView from "src/cashier/queuingmonitor/CashierQueuingView";
import AdmissionCompanyList from "src/admission/company-list";
import CashierSOAListPrint from "src/cashier/report/CashierSOAListPrint";
import PatientForDischarge from "src/cashier/patient-fordischarge";
import PatientDischarged from "src/cashier/patients-discharged";

const RouteCashier = () => {
	const [sidebarHeader, setSidebarHeader] = useState([]);
	const userContext = useContext(UsersData);
	const mounted = IsMountedRef();
	var interval = null;
	const [newPatientCount, setNewPatientCount] = useState(0);
	const [patient, setPatients] = useState({ data: [], ready: false });

	const getSidebarHeaderInfo = React.useCallback(async () => {
		try {
			var params = { user_id: userContext.users.user_id };
			const response = await axios.get("cashier/sidebar/header-infomartion", {
				params,
			});
			if (mounted.current) {
				setSidebarHeader(response.data);
			}
		} catch (error) {
			console.log("Unable to get sidebar header info.", error);
		}
	}, [userContext, mounted]);

	const getPatientsOnQueue = React.useCallback(async () => {
		let response = await axios.get("cashier/get/patient/cashier-queue", {
			params: {
				management_id: userContext.users.management_id,
				user_id: userContext.users.user_id,
			},
		});

		const data = response.data;
		if (mounted.current) {
			setPatients({ data, ready: true });
			if (data.length > 0) {
				setNewPatientCount(data.length);
			} else {
				setNewPatientCount(0);
			}
		}
	}, [mounted, userContext]);

	const sidebarRoute = [
		{
			name: "Dashboard",
			show: true,
			icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
			path: "/sph/app/cashier",
			subitem: [],
		},
		{
			name: "Patient on Queue",
			show: true,
			icon: (
				<Badge color="error" badgeContent={newPatientCount}>
					<FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />
				</Badge>
			),
			path: "/sph/app/cashier/patient-on-queue",
			subitem: [],
		},

		{
			name: "Patient For Discharge",
			show: true,
			icon: <FontAwesomeIcon icon={faProcedures} color="white" size={"2x"} />,
			path: "/sph/app/cashier/patient-fordischarge",
			subitem: [],
		},

		// {
		//   name: "Doctor's Salary",
		//   show: true,
		//   icon: <FontAwesomeIcon icon={faCoins} color="white" size={"2x"} />,
		//   path: "/sph/app/cashier/doctor-salary",
		//   subitem: [],
		// },

		{
			name: "Report",
			show: true,
			icon: <FontAwesomeIcon icon={faFileInvoice} color="white" size={"2x"} />,
			path: "/sph/app/cashier/report",
			subitem: [],
		},

		{
			name: "Discharged Patient",
			show: true,
			icon: <FontAwesomeIcon icon={faHospitalUser} color="white" size={"2x"} />,
			path: "/sph/app/cashier/discharged-patient",
			subitem: [],
		},

		// {
		//   name: "HMO List",
		//   show: true,
		//   icon: <FontAwesomeIcon icon={faListAlt} color="white" size={"2x"} />,
		//   path: "/sph/app/cashier/hmo-list",
		//   subitem: [],
		// },

		// {
		//   name: "Company List",
		//   show: true,
		//   icon: <FontAwesomeIcon icon={faListUl} color="white" size={"2x"} />,
		//   path: "/sph/app/cashier/corporates",
		//   subitem: [],
		// },

		{
			name: "Queuing List",
			show: true,
			icon: <FontAwesomeIcon icon={faListOl} color="white" size={"2x"} />,
			path: "/sph/app/cashier/queuing-list",
			subitem: [],
		},

		{
			name: "Leave Application",
			show: true,
			icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
			path: "/sph/app/cashier/leave-application",
			subitem: [],
		},
		{
			name: "Account",
			show: true,
			icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
			path: "/sph/app/cashier/account",
			subitem: [],
		},
	];

	const initializeInterval = () => {
		interval = setInterval(() => {
			getPatientsOnQueue();
		}, 20000);
	};

	const handleRenderInfo = () => {
		getSidebarHeaderInfo();
	};

	useEffect(() => {
		getSidebarHeaderInfo();
		getPatientsOnQueue();
		//eslint-disable-next-line
	}, [getSidebarHeaderInfo, getPatientsOnQueue]);

	useEffect(() => {
		initializeInterval();
		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<UsersHeader.Provider
				value={{
					sidebarHeader: sidebarHeader,
					renderPharmacyInfo: handleRenderInfo,
					queue: {
						patient: patient,
						getPatientsOnQueue: () => getPatientsOnQueue(),
					},
				}}
			>
				<BrowserRouter>
					<Sidebar
						notification={{
							enable: true,
							owner: "cashier",
						}}
						header={sidebarHeader}
						routes={sidebarRoute}
						module={"cashier"}
					/>

					<Switch>
						<Route
							exact
							path="/"
							component={() => <Redirect to="/sph/app/cashier" />}
						/>

						<Route
							exact
							path="/bmcdc"
							component={() => <Redirect to="/sph/app/cashier" />}
						/>

						<Route
							exact
							path="/sph/app"
							component={() => <Redirect to="/sph/app/cashier" />}
						/>

						<Route exact path="/sph/app/cashier" component={CashierDashboard} />

						<Route
							exact
							path="/sph/app/cashier/patient-on-queue"
							component={PatientQueue}
						/>

						<Route
							exact
							path="/sph/app/cashier/patient-fordischarge"
							component={PatientForDischarge}
						/>

						<Route
							exact
							path="/sph/app/cashier/discharged-patient"
							component={PatientDischarged}
						/>

						<Route
							exact
							path="/sph/app/cashier/doctor-salary"
							component={DoctorsWage}
						/>

						<Route
							exact
							path="/sph/app/cashier/report"
							component={ReportCashier}
						/>

						<Route exact path="/sph/app/cashier/hmo-list" component={HMO} />

						<Route
							exact
							path="/sph/app/cashier/corporates"
							component={AdmissionCompanyList}
						/>

						<Route
							exact
							path="/sph/app/cashier/queuing-list"
							component={CashierQueuingView}
						/>

						<Route
							exact
							path="/sph/app/cashier/leave-application"
							component={LeaveForm}
						/>

						<Route exact path="/sph/app/cashier/account" component={Account} />

						<Route
							exact
							path="/sph/app/cashier/billing/receipt/:receipt_number"
							component={BillingReceipt}
						/>

						<Route
							exact
							path="/sph/app/cashier/soa/:soa_id"
							component={CashierSOAListPrint}
						/>

						<Route exact path="/sph/app/logout" component={Logout} />

						<Route render={() => <PageNotFound title="Page not found" />} />
					</Switch>
				</BrowserRouter>
			</UsersHeader.Provider>
		</Fragment>
	);
};

export default RouteCashier;
