import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import HMISDashboard from "../hmis/HMISDashboard";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersData, UsersHeader } from "../ContextAPI";
import HIMSPharmacyMonitor from "src/hmis/pharmacy/HIMSPharmacyMonitor";
import HIMSLaboratoryMonitor from "src/hmis/laboratory/HIMSLaboratoryMonitor";
import HIMSImagingMonitor from "src/hmis/imaging/HIMSImagingMonitor";
import HIMSCashierMonitor from "src/hmis/cashier/HIMSCashierMonitor";
import PayrollDashboard from "src/hmis/payroll/PayrollDashboard";
import HIMSManpower from "src/hmis/manpower/HIMSManpower";
import HIMSAccounts from "src/hmis/account/HIMSAccounts";
import HIMSHospAccounts from "src/hmis/hosp-accounts/HIMSHospAccounts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUserLock,
  faVials,
  faXRay,
  // faUserTie,
  faDesktop,
  // faPrescription,
  faCashRegister,
  faUsers,
  faThumbsUp,
  faUserMd,
  faBrain,
  // faMoneyCheckAlt,
  // faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import LeaveForApproval from "src/hmis/for-approval/LeaveForApproval";
import HIMSDoctorMonitor from "src/hmis/doctor/HIMSDoctorMonitor";
import HIMSPsychologyMonitor from "src/hmis/psychology/HIMSPsychologyMonitor";
import HIMSOtherTestMonitor from "src/hmis/othertest/HIMSOtherTestMonitor";
import ItemForApproval from "src/hmis/for-approval/ItemForApproval";

const RouteHMIS = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("hmis/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/sph/app/hmis",
      subitem: [],
    },
    {
      name: "Management",
      show: true,
      icon: <FontAwesomeIcon icon={faDesktop} color="white" size={"2x"} />,
      subitem: [
        // {
        //   name: "Pharmacy",
        //   show: true,
        //   icon: (
        //     <FontAwesomeIcon icon={faPrescription} color="white" size={"2x"} />
        //   ),
        //   path: "/sph/app/hmis/pharmacy",
        // },
        {
          name: "Doctor",
          show: true,
          icon: <FontAwesomeIcon icon={faUserMd} color="white" size={"2x"} />,
          path: "/sph/app/hmis/doctor",
        },
        {
          name: "Laboratory",
          show: true,
          icon: <FontAwesomeIcon icon={faVials} color="white" size={"2x"} />,
          path: "/sph/app/hmis/laboratory",
        },
        {
          name: "Imaging",
          show: true,
          icon: <FontAwesomeIcon icon={faXRay} color="white" size={"2x"} />,
          path: "/sph/app/hmis/imaging",
        },
        {
          name: "Psychology",
          show: true,
          icon: <FontAwesomeIcon icon={faBrain} color="white" size={"2x"} />,
          path: "/sph/app/hmis/psychology",
        },
        {
          name: "Other Test",
          show: true,
          icon: <FontAwesomeIcon icon={faBrain} color="white" size={"2x"} />,
          path: "/sph/app/hmis/other-test",
        },
        {
          name: "Packages",
          show: true,
          icon: <FontAwesomeIcon icon={faBrain} color="white" size={"2x"} />,
          path: "/sph/app/hmis/packages",
        },
        {
          name: "Cashier",
          show: true,
          icon: (
            <FontAwesomeIcon icon={faCashRegister} color="white" size={"2x"} />
          ),
          path: "/sph/app/hmis/cashier",
        },
      ],
    },

    // {
    //   name: "HR",
    //   show: true,
    //   icon: <FontAwesomeIcon icon={faUserTie} color="white" size={"2x"} />,
    //   subitem: [
    //     {
    //       name: "Manpower",
    //       show: true,
    //       icon: <FontAwesomeIcon icon={faUsers} color="white" size={"2x"} />,
    //       path: "/sph/app/hmis/manpower",
    //     },
    //     {
    //       name: "Payroll",
    //       show: true,
    //       icon: (
    //         <FontAwesomeIcon icon={faMoneyCheckAlt} color="white" size={"2x"} />
    //       ),
    //       path: "/sph/app/hmis/payroll",
    //     },
    //     {
    //       name: "Hospital Accounts",
    //       show: true,
    //       icon: (
    //         <FontAwesomeIcon icon={faUserCircle} color="white" size={"2x"} />
    //       ),
    //       path: "/sph/app/hmis/hospital-accounts",
    //     },
    //   ],
    // },
    {
      name: "Manpower",
      show: true,
      icon: <FontAwesomeIcon icon={faUsers} color="white" size={"2x"} />,
      path: "/sph/app/hmis/manpower",
      subitem: [],
    },

    {
      name: "Items For Approval",
      show: true,
      icon: <FontAwesomeIcon icon={faThumbsUp} color="white" size={"2x"} />,
      path: "/sph/app/hmis/item-for-approval",
      subitem: [],
    },

    {
      name: "Leave For Approval",
      show: true,
      icon: <FontAwesomeIcon icon={faThumbsUp} color="white" size={"2x"} />,
      path: "/sph/app/hmis/leaver-for-approval",
      subitem: [],
    },

    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/sph/app/hmis/account",
      subitem: [],
    },
  ];

  useEffect(() => {
    getSidebarHeaderInfo();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
        }}
      >
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              owner: "hmis",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"hmis"}
          />

          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/sph/app/hmis" />}
            />

            <Route
              exact
              path="/bmcdc"
              component={() => <Redirect to="/sph/app/hmis" />}
            />

            <Route
              exact
              path="/sph/app"
              component={() => <Redirect to="/sph/app/hmis" />}
            />

            <Route exact path="/sph/app/hmis" component={HMISDashboard} />

            <Route
              exact
              path="/sph/app/hmis/account"
              component={HIMSAccounts}
            />

            <Route
              exact
              path="/sph/app/hmis/pharmacy"
              component={HIMSPharmacyMonitor}
            />

            <Route
              exact
              path="/sph/app/hmis/doctor"
              component={HIMSDoctorMonitor}
            />

            <Route
              exact
              path="/sph/app/hmis/laboratory"
              component={HIMSLaboratoryMonitor}
            />

            <Route
              exact
              path="/sph/app/hmis/psychology"
              component={HIMSPsychologyMonitor}
            />

            <Route
              exact
              path="/sph/app/hmis/other-test"
              component={HIMSOtherTestMonitor}
            />

            <Route
              exact
              path="/sph/app/hmis/imaging"
              component={HIMSImagingMonitor}
            />

            <Route
              exact
              path="/sph/app/hmis/cashier"
              component={HIMSCashierMonitor}
            />

            <Route
              exact
              path="/sph/app/hmis/manpower"
              component={HIMSManpower}
            />

            <Route
              exact
              path="/sph/app/hmis/payroll"
              component={PayrollDashboard}
            />

            <Route
              exact
              path="/sph/app/hmis/item-for-approval"
              component={ItemForApproval}
            />

            <Route
              exact
              path="/sph/app/hmis/leaver-for-approval"
              component={LeaveForApproval}
            />

            <Route
              exact
              path="/sph/app/hmis/hospital-accounts"
              component={HIMSHospAccounts}
            />

            <Route exact path="/sph/app/logout" component={Logout} />

            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteHMIS;
