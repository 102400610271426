import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    width: "35%", // Set the width to 75% of the screen
    maxWidth: "none", // Prevent the modal from having a maximum width
  },
}));

export default function Modal({ title, content, open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.dialogRoot, // Apply the custom styles to the Dialog component
      }}
    >
      <form>
        <DialogTitle>
          <div className="flex justify-between items-center">
            <Box>
              <Typography variant="h5" color="textSecondary">
                <strong>{title}</strong>
              </Typography>
            </Box>
            <button
              className="text-red-600 hover:bg-red-300 py-2 px-3 rounded-lg"
              onClick={onClose}
              type="button"
            >
              CLOSE
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="flex w-full mb-3">{content}</div>
        </DialogContent>
      </form>
    </Dialog>
  );
}
