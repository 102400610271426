import {
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { ArrowForward, Search } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const List = ({ product }) => {
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const searchable = product.data.filter((data) => {
    return (
      (data.product_generic &&
        data.product_generic
          .toLowerCase()
          .indexOf(search.trim().toLowerCase()) !== -1) ||
      (data.product_name &&
        data.product_name.toLowerCase().indexOf(search.trim().toLowerCase()) !==
          -1)
    );
  });

  return (
    <Paper>
      <Box p={2}>
        <Box display="flex" mb={1}>
          <Box flexGrow={1} />
          <Box>
            <TextField
              label="Search product, invoice, dr"
              variant="outlined"
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <Search color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Product </TableCell>
                <TableCell> Generic </TableCell>
                <TableCell> Unit </TableCell>
                <TableCell> Msrp </TableCell>
                <TableCell> Srp </TableCell>
                <TableCell> Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {product.ready ? (
                searchable.length > 0 ? (
                  searchable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow key={index}>
                        <TableCell> {data.product_name} </TableCell>
                        <TableCell> {data.product_generic} </TableCell>
                        <TableCell> {data.unit} </TableCell>
                        <TableCell align="right"> {data.msrp} </TableCell>
                        <TableCell align="right"> {data.srp} </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Brand Monitoring">
                            <IconButton
                              color="primary"
                              onClick={() =>
                                history.push(
                                  `/sph/app/accounting/product/${data.product_id}`
                                )
                              }
                            >
                              <ArrowForward />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography color="secondary">
                        no product added.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography color="primary"> processing... </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={searchable.length}
            rowsPerPageOptions={[10, 50, 100]}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="List"
          />
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default List;
