import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  CardActions,
  CircularProgress,
  Button,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";
import SorologyRef from "src/laboratory/references/Ref_Sorology";

const SeroEditForm = ({ info, close }) => {
  const { users } = useContext(UsersData);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", info.data.trace_number);
    formdata.set("order_id", info.data.order_id);
    setResultSubmitting(true);
    axios
      .post("laboratory/serology/edit-result", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("order result edit successful");
          close();
          setSavebtnDisabled(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setResultSubmitting(false));
  };

  return (
    <>
      <Fragment>
        <form onSubmit={handleSaveResult}>
          {info.ready && (
            <>
              <Box display="flex" my={2}>
                <Box flexGrow={1}>
                  <Typography variant="body1" align="center">
                    <b> SEROLOGY </b>
                  </Typography>
                </Box>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b> EXAMINATION REQUESTED </b>
                      </TableCell>
                      <TableCell align="center">
                        <b> NORMAL </b>
                      </TableCell>
                      <TableCell align="center">
                        <b> TEST RESULT </b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hidden={info.data.hbsag === null}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          Hepatitis B surface Antigen (HBsAg)
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {SorologyRef.normal()}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="hbsag"
                            label="Result"
                            defaultValue={
                              info.data.hbsag === null ? "" : info.data.hbsag
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={info.data.hav === null}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          HAV (Hepatitis A Virus) lgG/lgM
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {SorologyRef.normal()}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="hav"
                            label="Result"
                            defaultValue={
                              info.data.hav === null ? "" : info.data.hav
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={info.data.vdrl_rpr === null}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          VDRL/RPR
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        {SorologyRef.normal()}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="vdrl_rpr"
                            label="Result"
                            defaultValue={
                              info.data.vdrl_rpr === null
                                ? ""
                                : info.data.vdrl_rpr
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={info.data.anti_hbc_igm === null}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          ANTI-HBC IGM
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        {/* reference not set */}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="anti_hbc_igm"
                            label="Result"
                            defaultValue={info.data.anti_hbc_igm}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={info.data.hcv === null}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          HCV (Hepatitis C Virus)
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        {SorologyRef.normal()}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="hcv"
                            label="Result"
                            defaultValue={info.data.hcv}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={info.data.beta_hcg_quali === null}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          BETA HCG (QUALI)
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        {/* reference not set */}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="beta_hcg_quali"
                            label="Result"
                            defaultValue={info.data.beta_hcg_quali}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={info.data.h_pylori === null}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          H. PYLORI
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        {/* reference not set */}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="h_pylori"
                            label="Result"
                            defaultValue={info.data.h_pylori}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={info.data.typhidot === null}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          TYPHIDOT
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        {/* reference not set */}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="typhidot"
                            label="Result"
                            defaultValue={info.data.typhidot}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      hidden={!Boolean(parseInt(info.data.syphilis_test))}
                    >
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          VDRL / SYPHILIS TEST
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        {/* reference not set */}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="syphilis_test_result"
                            label="Result"
                            defaultValue={info.data.syphilis_test_result}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={info.data.hact === null}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          HACT
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        {/* reference not set */}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="hact"
                            label="Result"
                            defaultValue={info.data.hact}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow hidden={info.data.ana === null}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          ANA
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        {/* reference not set */}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="ana"
                            label="Result"
                            defaultValue={info.data.ana}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      hidden={!Boolean(parseInt(info.data.dengue_test))}
                    >
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={`gtc-uppercase`}
                        >
                          DENGUE TEST
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {/* reference not set */}
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          margin="dense"
                          fullWidth
                          name="dengue_test_result"
                          label="Result"
                          defaultValue={info.data.dengue_test_result}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display="flex">
                <Box flexGrow={1} />
                <Box>
                  <CardActions>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                      disabled={savebtnDisabled}
                      startIcon={
                        resultSubmitting && (
                          <CircularProgress size={20} color="inherit" />
                        )
                      }
                    >
                      Save Result
                    </Button>
                  </CardActions>
                </Box>
              </Box>
            </>
          )}
        </form>
      </Fragment>
    </>
  );
};

export default SeroEditForm;
