import React, { Fragment, useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  Card,
  CardContent,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  Typography,
  IconButton,
  Box,
  Dialog,
  TablePagination,
} from "@material-ui/core";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import { UsersData } from "src/ContextAPI";
import { Print } from "@material-ui/icons";
import Notify from "src/notification/Notify";
import BMCDCMedCert from "../medical-certificate/BMCDCMedCert";

const NSPatientMedCertList = ({ patient_id }) => {
  const [medCert, setMedCert] = useState([]);
  const mounted = IsMountedRef();
  const { users } = useContext(UsersData);
  const [dialog, setDialog] = useState({ data: null, open: false });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getCompletedMedCert = React.useCallback(async () => {
    let response = await axios.get(
      "nurse/get/certificates/by-id/medicalcert-allcompleted",
      {
        params: {
          management_id: users.management_id,
          patient_id,
        },
      }
    );

    if (mounted.current) {
      setMedCert(response.data);
    }
  }, [mounted, users, patient_id]);

  useEffect(() => {
    getCompletedMedCert();
  }, [getCompletedMedCert, patient_id]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Card>
            {/* <CardHeader
              title={
                <Box display="flex">
                  <Box flexGrow={1}>List</Box>

                  <Box>
                    <TextField
                      label="Search patient"
                      variant="outlined"
                      fullWidth
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <Search color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              }
            /> */}
            <CardContent>
              <Box mb={2}>
                <Typography color="textSecondary" variant="h6">
                  <strong>Medical Certificate</strong>
                </Typography>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Date</strong>
                      </TableCell>
                      {/* <TableCell> Patient </TableCell> */}
                      <TableCell>
                        <strong>Diagnosis</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Action</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {medCert.length > 0 ? (
                      medCert
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data, key) => (
                          <TableRow key={key}>
                            <TableCell>
                              {Notify.createdAt(data.issued_at)}
                            </TableCell>
                            {/* <TableCell style={{ textTransform: "capitalize" }}>
                              {`${data.lastname}, ${data.firstname}`}
                            </TableCell> */}
                            <TableCell> {data.diagnosis_findings} </TableCell>
                            <TableCell align="center">
                              <IconButton
                                color="primary"
                                // onClick={() => handleSelectedMedCert(data)}
                                onClick={() =>
                                  setDialog({ data: data, open: true })
                                }
                              >
                                <Print />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <Typography color="secondary">
                            No record found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                <TablePagination
                  component={"div"}
                  count={medCert.length}
                  labelRowsPerPage="List"
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 50, 100]}
                />
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* print medical cert */}
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ data: null, open: false })}
        fullScreen
      >
        <BMCDCMedCert
          details={dialog.data}
          close={() => setDialog({ data: null, open: false })}
        />
      </Dialog>
    </Fragment>
  );
};

export default NSPatientMedCertList;
