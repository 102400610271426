import React, { Fragment } from "react";
import Container from "src/layout/Container";
import { Grid, Paper, Tab, Tabs, Box } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import ImagingTest from "./ImagingTest";
import ImagingSales from "./ImagingSales";
import ImagingPatientsPending from "./ImagingPatientsPending";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingUsd,
  faUserInjured,
  faXRay,
} from "@fortawesome/free-solid-svg-icons";

const HIMSImagingMonitor = () => {
  const [category, setCategory] = React.useState(0);

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "imaging monitoring",
          items: [{ name: "Dashboard", path: "/sph/app" }],
        }}
        title="Imaging Monitoring"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box component={Paper} variant="outlined" mb={2}>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab
                  wrapped
                  label="Imaging Test"
                  icon={<FontAwesomeIcon icon={faXRay} size={"2x"} />}
                />
                <Tab
                  wrapped
                  label="Sales"
                  icon={<FontAwesomeIcon icon={faHandHoldingUsd} size={"2x"} />}
                />
                <Tab
                  wrapped
                  label="Patients Pending"
                  icon={<FontAwesomeIcon icon={faUserInjured} size={"2x"} />}
                />
              </Tabs>
            </Box>

            <TabPanel value={category} index={0}>
              <ImagingTest />
            </TabPanel>

            <TabPanel value={category} index={1}>
              <ImagingSales />
            </TabPanel>

            <TabPanel value={category} index={2}>
              <ImagingPatientsPending />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default HIMSImagingMonitor;
