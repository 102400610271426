import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styles } from "../../styles/styles";
import Header from "./Header";
import axios from "axios";

export default function EditAddressAndContactInformation({
  patient,
  setValue,
  register,
  errors,
}) {
  const [index, setIndex] = useState(patient?.municipality?.id - 1);
  const [locations, setLocations] = useState([]);
  const [selectedBarangay, setSelectedBarangay] = useState(
    patient?.barangay?.id
  );
  const [selectedMunicipality, setSelectedMunicipality] = useState(
    patient?.municipality?.id
  );

  useEffect(() => {
    axios
      .get(`v1/locations`)
      .then(function (response) {
        setLocations(response.data);
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }, []);
  const data = locations[index];

  return (
    <>
      <Header title={"Address And Contact Information"} />
      <div className="px-4 mb-3">
        <div className="mx-auto" style={styles.parentDivContainerNoGap}>
          <div className={styles.borderRight}>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <TextField
                  label={<label style={styles.label}>Country</label>}
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.country}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("country")}
                >
                  <option value="Ph">Ph</option>
                </TextField>
              </div>
              <div style={styles.divContainer}>
                <TextField
                  label={<label style={styles.label}>Region</label>}
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.region}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("region")}
                >
                  <option value="Soccsksargen">Soccsksargen</option>
                </TextField>
              </div>
            </div>
            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Province</label>}
                variant="outlined"
                fullWidth
                defaultValue={patient?.province}
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("province")}
              >
                <option value="Sarangani">Sarangani</option>
              </TextField>
            </div>

            <div style={styles.divContainer}>
              <TextField
                label={
                  <label style={styles.label}>
                    City/Municipality
                    <span style={styles.astirisColor}>*</span>
                  </label>
                }
                variant="outlined"
                fullWidth
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("municipality", { required: true })}
                error={errors.municipality}
                helperText={
                  errors.municipality && "City/Municipality is required"
                }
                value={selectedMunicipality || ""}
                onChange={(e) => {
                  setSelectedMunicipality(e.target.value);
                  const selectedIndex = e.target.selectedIndex;
                  setIndex(selectedIndex - 1); // Update index
                }}
              >
                <option value="">---Select---</option>
                {locations.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </TextField>
            </div>
            <div style={styles.divContainer}>
              <TextField
                label={
                  <label style={styles.label}>
                    Barangay<span style={styles.astirisColor}>*</span>
                  </label>
                }
                variant="outlined"
                fullWidth
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("barangay", { required: true })}
                error={errors.barangay}
                helperText={errors.barangay && "Barangay is required"}
                value={selectedBarangay || ""}
                onChange={(e) => setSelectedBarangay(e.target.value)}
              >
                <option value="">---Select---</option>
                {data &&
                  data.barangays.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
              </TextField>
            </div>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>
                      Street/Zone
                      <span style={styles.astirisColor}>*</span>
                    </label>
                  }
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.street}
                  {...register("street", { required: true })}
                  error={errors.street}
                  helperText={errors.street && "Street/Zone is required"}
                />
              </div>
              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>
                      Zip Code<span style={styles.astirisColor}>*</span>
                    </label>
                  }
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.zip_code}
                  {...register("zip_code", { required: true })}
                  error={errors.zip_code}
                  helperText={errors.zip_code && "Zip Code is required"}
                />
              </div>
            </div>
          </div>
          <div className={styles.borderLeft}>
            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Email</label>}
                variant="outlined"
                fullWidth
                defaultValue={patient?.email}
                {...register("email", {
                  pattern: /^\S+@\S+$/i,
                })}
                error={errors.email}
                helperText={errors.email && "Invalid email format"}
              />
            </div>

            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Mobile</label>}
                variant="outlined"
                fullWidth
                defaultValue={patient?.mobile}
                {...register("mobile")}
              />
            </div>
            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Telephone</label>}
                variant="outlined"
                fullWidth
                defaultValue={patient?.telephone}
                {...register("telephone")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
