import React, { useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TextField,
  Grid,
  CardMedia,
} from "@material-ui/core";
import { CheckSquare } from "react-feather";
import { DateTimePicker } from "@material-ui/pickers";
import { calculateAgeV2 } from "src/helpers/utils";
import { useForm } from "react-hook-form";

const CisCreatePatientsHistory = ({ patient }) => {
  const [isProcess, setIsProcess] = useState(false);
  const printableRef = React.useRef();
  const [chartAttendingPhysician, setChartAttendingPhysician] = useState("");
  const [chartAdmissionDate, setChartAdmissionDate] = useState(null);
  const [chartDischargeDate, setChartDischargeDate] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(chartAdmissionDate);
    console.log(chartDischargeDate);
    console.log(data);
    let formData = new FormData();

    formData.append(
      "datetime_admission",
      moment(chartAdmissionDate).format("YYYY-MM-DD HH:mm:ss")
    );
    formData.append(
      "datetime_discharge",
      moment(chartDischargeDate).format("YYYY-MM-DD HH:mm:ss")
    );
    formData.append("Procedures", data?.Procedures || "");
    formData.append("admittingImpression", data?.admittingImpression || "");
    formData.append("attendingPhysician", data?.attendingPhysician || "");
    formData.append("chiefComplaint", data?.chiefComplaint || "");
    formData.append("conditionOnDischarge", data?.conditionOnDischarge || "");
    formData.append("courseInWard", data?.courseInWard || "");
    formData.append("familyHistory", data?.familyHistory || "");
    formData.append("finalDiagnosis", data?.finalDiagnosis || "");
    formData.append("laboratoryResults", data?.laboratoryResults || "");
    formData.append("medications", data?.medications || "");
    formData.append("pastMedicalHistory", data?.pastMedicalHistory || "");
    formData.append("pertinentPhysicalExam", data?.pertinentPhysicalExam || "");
    formData.append("recomendation", data?.recomendation || "");
  };

  return (
    <div>
      <Card ref={printableRef} elevation={3} className="p-4" component={Box}>
        <CardContent>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Box align="center">
                  <CardMedia
                    component="img"
                    src="/bmcdc_logo.png"
                    alt="bmcdc logo"
                  />
                </Box>
              </Grid>

              <Grid item xs={8}>
                <Box align="center">
                  <Typography variant="h3">
                    <b>GLOBAL TELEMEDICINE CORP, INC.</b>
                  </Typography>
                  <Typography>
                    <b>MATINA PANGI, DAVAO CITY</b>
                  </Typography>
                  <Typography>
                    <b>Tel. 000-0000 Telefax: 000-0000</b>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box align="center">
                  <CardMedia
                    component="img"
                    src="/bmcdc_medical.png"
                    alt="medical logo"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box my={1}>
            <Typography align="center">
              <b>HISTORY OF PRESENT ILLNESS</b>
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Box my={1}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                  mb={1}
                >
                  <Box ml={2}>
                    <Button
                      color="primary"
                      startIcon={
                        isProcess ? (
                          <CircularProgress size={22} color="inherit" />
                        ) : (
                          <CheckSquare />
                        )
                      }
                      type="submit"
                      disabled={isProcess}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box my={2}>
              <Table className="table-bordered">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography variant="caption">Name:</Typography>
                      <Typography>
                        {patient?.firstname + " " + patient?.lastname}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={4}>
                      <Typography variant="caption"> Age </Typography>
                      <Typography>
                        {calculateAgeV2(new Date(patient?.birthday))}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={4}>
                      <Typography variant="caption"> Gender </Typography>
                      <Typography>{patient?.gender}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography variant="caption">
                        Date/Time of Admission:
                      </Typography>
                      <Box>
                        <DateTimePicker
                          label=""
                          value={chartAdmissionDate}
                          onChange={(date) => setChartAdmissionDate(date)}
                          autoOk
                          fullWidth
                        />
                      </Box>
                    </TableCell>
                    <TableCell colSpan={6}>
                      <Typography variant="caption">
                        Date/Time of Discharge:
                      </Typography>
                      <Box>
                        <DateTimePicker
                          label=""
                          value={chartDischargeDate}
                          onChange={(date) => setChartDischargeDate(date)}
                          autoOk
                          fullWidth
                        />
                      </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography variant="caption">
                        Attending Physician:
                      </Typography>
                      <Box>
                        <TextField
                          fullWidth
                          name=""
                          {...register("attendingPhysician")}
                        />
                      </Box>
                    </TableCell>
                    <TableCell colSpan={6}>
                      <Typography variant="caption">
                        Admitting Impression:
                      </Typography>
                      <Box>
                        <TextField
                          fullWidth
                          name=""
                          {...register("admittingImpression")}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            <Box>
              <Box my={2}>
                <Typography variant="subtitle2">
                  Brief Clinical History / Chief Complaint
                </Typography>
                <TextField fullWidth name="" {...register("chiefComplaint")} />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">
                  Pertinent Physical Exam
                </Typography>
                <TextField
                  fullWidth
                  name=""
                  {...register("pertinentPhysicalExam")}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">
                  Past Medical History
                </Typography>
                <TextField
                  fullWidth
                  name=""
                  {...register("pastMedicalHistory")}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Family History</Typography>
                <TextField fullWidth name="" {...register("familyHistory")} />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Medications</Typography>
                <TextField fullWidth name="" {...register("medications")} />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Procedures</Typography>
                <TextField fullWidth name="" {...register("Procedures")} />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Laboratory Results</Typography>

                <TextField
                  fullWidth
                  name=""
                  {...register("laboratoryResults")}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Course in the Ward</Typography>
                <TextField fullWidth name="" {...register("courseInWard")} />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Recommendation</Typography>
                <TextField fullWidth name="" {...register("recomendation")} />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">
                  Condition on Discharge
                </Typography>

                <TextField
                  fullWidth
                  name=""
                  {...register("conditionOnDischarge")}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Final Diagnosis</Typography>
                <TextField fullWidth name="" {...register("finalDiagnosis")} />
              </Box>
            </Box>

            <Box my={3} display={"flex"} justifyContent={"flex-start"}>
              <Box align="center">
                <Box borderBottom={1} px={1}>
                  <Typography className="text-capitalize">
                    {chartAttendingPhysician}
                  </Typography>
                </Box>
                <Typography variant="caption">
                  Signature Over Printed Name of Physician
                </Typography>
              </Box>
            </Box>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default CisCreatePatientsHistory;
