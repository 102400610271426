import React from "react";
import Container from "src/layout/Container";
import RadiologistPatientReviewed from "./RadiologistPatientReviewed";

const RadiologistReport = () => {
  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "report",
        items: [{ name: "Dashboard", path: "/sph/app" }],
      }}
      title="Report"
    >
      <RadiologistPatientReviewed />;
    </Container>
  );
};

export default RadiologistReport;
