import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import LocationPicker from "react-leaflet-location-picker";
import PickMapLocation from "../PickMapLocation";
const center = [6.0498006, 125.15];

const PatientAddressForm = ({
	municipalities,
	patient,
	household,
	setMunicipalities,
	brgys,
	setBrgys,
	register,
	errors,
	setValue,
	watch,
	purokList,
	setPurokList,
}) => {
	const [pointVals, setPointVals] = useState([[6.0498006, 125.15]]);
	const [position, setPosition] = useState({
		lat: 6.0498006,
		lng: 125.15,
	});

	const pointMode = {
		banner: false,
		zoom: 10,
		control: {
			values: pointVals,
			onClick: (point) => {
				setPointVals([point]);
				setValue("lat", point[0]);
				setValue("lng", point[1]);
				console.log("I've just been clicked on the map!", point);
			},
			onRemove: (point) => {
				console.log("I've just been clicked for removal :(", point);
			},
		},
	};
	useEffect(() => {
		console.log("householdhouseholdzzz lattt", patient?.lat, patient?.lng);
		if (patient?.lat && patient?.lng) {
			// setPointVals([[household?.lat, household?.lng]]);
			setPosition({
				lat: patient?.lat,
				lng: patient?.lng,
			});

			setTimeout(() => {
				setValue("lat", patient?.lat);
				setValue("lng", patient?.lng);
			}, 1000);
		}
	}, [patient?.lat, patient?.lng]);

	useEffect(() => {
		let timeout = setTimeout(() => {
			console.log("getPurokList", watch("barangay"));
			if (watch("barangay")) {
				getPurokList(watch("barangay"));
			}
		}, 400);
		return () => {
			clearTimeout(timeout);
		};
	}, [watch("barangay")]);

	useEffect(() => {
		let timeout = setTimeout(() => {
			setValue("purok", patient?.purok?.id);
		}, 400);
		return () => {
			clearTimeout(timeout);
		};
	}, [purokList, patient?.id]);

	useEffect(() => {
		setValue("municipality", patient?.municipality?.id);
		municipalities?.map((x) => {
			if (x.id == patient?.municipality?.id) {
				setBrgys(x?.barangays);
			}
		});
	}, [patient?.municipality?.id]);
	useEffect(() => {
		setValue("barangay", household?.barangay?.id);
	}, [household?.barangay?.id]);
	const getPurokList = (brgy_id) => {
		axios.get(`v1/purok-by-barangay?barangay_id=${brgy_id}`).then((res) => {
			setPurokList(res.data);
		});
	};
	return (
		<div className="flex flex-col gap-y-8 patient-form">
			{/* <LocationPicker
				pointMode={pointMode}
				zoom={10}
				startPort={"auto"}
				showControls={true}
				precision={7}
			/>
			 */}
			{/* <PickMapLocation
				position={position}
				setPosition={(pos) => {
					setPosition({ lat: pos.lat, lng: pos.lng });
					setValue("lat", pos?.lat);
					setValue("lng", pos?.lng);
				}}
			/> */}
			{/* <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
				<TextField
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`Latitude:`}
					variant="outlined"
					{...register("lat", {
						required: true,
					})}
					error={errors?.lat}
					helperText={errors?.lat && "This field is required"}
				/>
				<TextField
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`Longitude:`}
					variant="outlined"
					{...register("lng", {
						required: true,
					})}
					error={errors?.lng}
					helperText={errors?.lng && "This field is required"}
				/>
			</div> */}
			<div className="flex lg:flex-row flex-col gap-6">
				<TextField
					className="w-full lg:w-1/3"
					label={"Province:"}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("province", {
						required: true,
					})}
					error={errors?.province}
					helperText={errors?.province && "This field is required"}
				>
					<option value=""> SELECT </option>
					<option value="sarangani"> Sarangani </option>
				</TextField>
				<TextField
					className="w-full lg:w-1/3"
					label={"City/Municipality:"}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						onChange: (e) => {
							setValue("barangay", "");
							if (e.target.value) {
								setBrgys(
									municipalities?.find(
										(x) => x.id == e.target.value
									)?.barangays
								);
							} else {
								setBrgys([]);
							}
						},
					}}
					{...register("municipality", {
						required: true,
					})}
					error={errors?.municipality}
					helperText={
						errors?.municipality && "This field is required"
					}
				>
					<option value=""> SELECT </option>
					{municipalities?.map((item) => (
						<option value={item.id}>{item?.name}</option>
					))}
				</TextField>
				<TextField
					className="w-full lg:w-1/3"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`Zone:`}
					variant="outlined"
					{...register("zone", {
						required: true,
					})}
					error={errors?.zone}
					helperText={errors?.zone && "This field is required"}
				/>
				<TextField
					className="w-full lg:w-1/3"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`Zip Code:`}
					variant="outlined"
					{...register("zip_code", {
						required: true,
					})}
					error={errors?.zip_code}
					helperText={errors?.zip_code && "This field is required"}
				/>
			</div>
			<div className="flex lg:flex-row flex-col gap-6">
				<TextField
					className="w-full lg:w-1/3"
					label={"Barangay:"}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("barangay", {
						required: true,
					})}
					InputProps={{
						onChange: (e) => {
							if (e.target.value) {
								getPurokList(e.target.value);
							} else {
							}
						},
					}}
					error={errors?.barangay}
					helperText={errors?.barangay && "This field is required"}
				>
					<option value=""> SELECT </option>
					{brgys.map((item) => {
						return <option value={item?.id}>{item?.name} </option>;
					})}
				</TextField>
				<TextField
					className="w-full lg:w-2/3"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`Purok/Sitio:`}
					variant="outlined"
					{...register("purok", {
						required: true,
					})}
					error={errors?.purok}
					helperText={errors?.purok && "This field is required"}
				>
					<option value=""> SELECT </option>
					{purokList.map((purok) => {
						return (
							<option value={purok?.id}>{purok?.name} </option>
						);
					})}
				</TextField>
			</div>
			<div className="flex lg:flex-row flex-col gap-6">
				<TextField
					className="w-full lg:w-1/2"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`Street:`}
					variant="outlined"
					{...register("street", {
						required: true,
					})}
					error={errors?.street}
					helperText={errors?.street && "This field is required"}
				/>
				<TextField
					className="w-full lg:w-1/2"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`House Number:`}
					variant="outlined"
					{...register("house_number", {
						required: true,
					})}
					error={errors?.house_number}
					helperText={
						errors?.house_number && "This field is required"
					}
				/>
			</div>
			<TextField
				className="w-full lg:w-2/3"
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={
					<>
						Household Identification Number{" "}
						<span className="text-danger ml-2">*</span>
					</>
				}
				variant="outlined"
				{...register("house_id", {
					required: true,
				})}
				error={errors?.house_id}
				helperText={errors?.house_id && "This field is required"}
			/>
		</div>
	);
};

export default PatientAddressForm;
