import React, { Fragment, useContext, useEffect, useState } from "react";
import Axios from "axios";
import Notify from "../../../notification/Notify";
import {
	Form,
	Col,
	FormGroup,
	FormControl,
	Row,
	Button,
	Badge,
} from "react-bootstrap";
import Select from "react-select";

import {
	Box,
	Dialog,
	DialogTitle,
	Typography,
	DialogContent,
	IconButton,
	TextField,
	CircularProgress,
} from "@material-ui/core";
import CheckInternet from "src/utils/CheckInternet";
import NoInternet from "src/NoInternet";
import CheckingConnection from "src/CheckInternet";
import UnsavePrescription from "./UnsavePrescription";
import {
	Keyboard,
	CheckCircleOutlined,
	HighlightOff,
} from "@material-ui/icons";
import { UsersData } from "src/ContextAPI";
import { grey } from "@material-ui/core/colors";

const PrescriptionVirtual = ({
	patient_id,
	displayUnsave,
	getUnsavePrescription,
	unsaveCount,
}) => {
	const management_id = localStorage.getItem("management_id");

	const [virtual, setVirtual] = useState({
		data: [],
		ready: false,
	});

	const [vproduct, setVProduct] = useState([]);
	const [selectedVirtualPharmacy, setSelectedVirtualPharmacy] = useState(null);
	const [selectedVirtualPharmacyCategory, setSelectedVirtualPharmacyCategory] =
		useState(null);

	const [isProcess, setIsProcess] = useState(false);
	const [online, setOnline] = useState("checking");

	// const [unsave_product_count, setunsave_product_count] = useState(0)
	const [selected_prod_type, setselected_prod_type] = useState("");
	const [selected_prod_name, setselected_prod_name] = useState("");
	const [selected_prod_amount, setselected_prod_amount] = useState(0);

	const [dialog, setDialog] = useState(false);

	const [isSearchable, setIsSearchable] = useState(false);
	const { users } = useContext(UsersData);

	const getVirtualPrescription = () => {
		var formdata = new FormData();
		formdata.set("connection", "online");
		Axios.post("prescription/doctor/virtual/virtuallist", formdata)
			.then((response) => {
				const data = response.data;
				setVirtual({ data: data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const handleSelectedProdByPharmacy = (e) => {
		// e.value

		var virtual_parmacy = e.value;
		var formdata = new FormData();
		formdata.set("connection", "online");
		formdata.set("user_id", users.user_id);
		formdata.set("pharmacy_id", virtual_parmacy);
		formdata.set("pharmacy_category", e.pharmacy_category);

		Axios.post("prescription/virtual/pharmacyproducts-list", formdata)
			.then((response) => {
				const data = response.data;
				setVProduct(data);
				setSelectedVirtualPharmacy(virtual_parmacy);
				setSelectedVirtualPharmacyCategory(e.pharmacy_category);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const handleSelectedProdByPharmacyDetails = (e) => {
		// e.value
		var formdata = new FormData();
		formdata.set("connection", "online");
		formdata.set("user_id", users.user_id);
		formdata.set("pharmacy_id", selectedVirtualPharmacy);
		formdata.set("pharmacy_category", selectedVirtualPharmacyCategory);
		formdata.set("product_id", e.value);

		Axios.post("prescription/virtual/pharmacyproductsdetails-list", formdata)
			.then((response) => {
				const data = response.data;
				if (data.length > 0) {
					setselected_prod_name(data[0].product_name);
					setselected_prod_amount(data[0].product_amount);
					setselected_prod_type(data[0].unit);
				}
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const addToPrecription = (e) => {
		e.preventDefault();
		e.persist();
		var formdata = new FormData(e.target);
		var error = [];
		formdata.set("connection", "online");
		formdata.set("user_id", users.user_id);
		formdata.set("management_id", management_id);
		formdata.set("patient_id", patient_id);
		formdata.set("prescription_type", "virtual");

		if (
			formdata.get("virtual_parmacy").length === 0 ||
			formdata.get("virtual_parmacy").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("virtual_parmacy");
		}

		if (
			formdata.get("prescription").length === 0 ||
			formdata.get("prescription").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("prescription");
		}

		if (
			formdata.get("order_qty").length === 0 ||
			formdata.get("order_qty").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("order qty");
		}

		if (
			formdata.get("dosage").length === 0 ||
			formdata.get("dosage").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("dosage");
		}

		// if(formdata.get('take_every').length === 0 || formdata.get('take_every').trim()===''){
		//     error = 'error';
		//     Notify.fieldRequired('every take');
		// }

		// if(formdata.get('take_times').length === 0 || formdata.get('take_times').trim()===''){
		//     error = 'error';
		//     Notify.fieldRequired('take times');
		// }

		if (error.length > 0) {
			Notify.consoleLog("virtual prescription");
		} else {
			setIsProcess(true);
			Axios.post("prescription/virtual/prescription-viruatladd", formdata)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						e.target.reset();
						getUnsavePrescription();
						Notify.successRequest("virtual prescription");
					} else {
						Notify.warnRequest("virtual prescription");
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => {
					setIsProcess(false);
				});
		}
	};

	const checkinternet = () => {
		CheckInternet.online()
			.then(() => setOnline("connected"))
			.catch(() => setOnline("disconnected"));
	};

	useEffect(() => {
		checkinternet();

		if (online === "connected") {
			getVirtualPrescription();
		}

		// eslint-disable-next-line
	}, [patient_id, online]);

	return (
		<Fragment>
			{online === "checking" && <CheckingConnection />}
			{online === "disconnected" && <NoInternet />}
			{online === "connected" && (
				<>
					<Box mb={2}>
						<Typography variant="h6" color="textSecondary">
							{" "}
							Create Virtual Prescription{" "}
						</Typography>
					</Box>
					{virtual.ready ? (
						virtual.data.length > 0 ? (
							<>
								<Form onSubmit={addToPrecription}>
									<Row>
										<Col sm={12}>
											<Box py={2}>
												<Box display="flex">
													<Box flexGrow={1}>
														<Typography variant="caption" color="textSecondary">
															{" "}
															VIRTUAL PHARMACY{" "}
														</Typography>
													</Box>

													<Box>
														<IconButton
															onClick={() => {
																setIsSearchable(!isSearchable);
															}}
															size="small"
															color={isSearchable ? "primary" : "default"}
														>
															<Keyboard />
														</IconButton>
													</Box>
												</Box>

												<Select
													name="virtual_parmacy"
													options={virtual.data}
													onChange={handleSelectedProdByPharmacy}
													isSearchable={isSearchable}
													styles={{
														menu: (provided) => ({
															...provided,
															zIndex: 9999,
															color: "black",
														}),
														control: (base) => ({
															...base,
															minHeight: 55,
														}),
													}}
												/>
											</Box>

											{selectedVirtualPharmacyCategory && (
												<Box mb={2}>
													<Typography variant="caption" color="textSecondary">
														{" "}
														VIRTUAL PHARMACY TYPE{" "}
													</Typography>
													<Box
														border={1}
														p={2}
														borderRadius={5}
														borderColor={grey[400]}
													>
														<Typography>
															{selectedVirtualPharmacyCategory}
														</Typography>
													</Box>
												</Box>
											)}
										</Col>
										<Col sm={12}>
											<FormGroup>
												<span className="gtc-small text-uppercase text-muted">
													{" "}
													Prescription{" "}
												</span>
												<Select
													name="prescription"
													options={vproduct}
													onChange={handleSelectedProdByPharmacyDetails}
													styles={{
														menu: (provided) => ({
															...provided,
															zIndex: 9999,
															color: "black",
														}),
														control: (base) => ({
															...base,
															minHeight: 55,
														}),
													}}
												/>
											</FormGroup>
										</Col>

										<Col sm={6}>
											<Box>
												<TextField
													variant={"outlined"}
													name="order_qty"
													label="Order Quantity"
													fullWidth
													type="number"
													InputProps={{
														inputProps: {
															min: 1,
															max: 10000,
															step: 1,
														},
													}}
												/>

												{/* hidden files */}
												<FormControl
													name="product_name"
													value={selected_prod_name}
													readOnly
													hidden
												/>
												<FormControl
													name="product_amount"
													value={selected_prod_amount}
													readOnly
													hidden
												/>
											</Box>
										</Col>

										<Col sm={6}>
											<Box>
												<TextField
													variant={"outlined"}
													name="type"
													label="Type"
													value={selected_prod_type}
													fullWidth
													InputProps={{
														readOnly: true,
													}}
												/>
											</Box>
										</Col>
									</Row>

									<Row>
										<Col sm={12}>
											<Box my={2}>
												<TextField
													variant={"outlined"}
													name="dosage"
													label="Dosage"
													fullWidth
												/>
											</Box>
										</Col>
										<Col sm={4} hidden>
											<FormGroup>
												<span className="gtc-small text-uppercase text-muted">
													{" "}
													Take Every{" "}
												</span>
												<FormControl name="take_every" />
											</FormGroup>
										</Col>
										<Col sm={4} hidden>
											<FormGroup>
												<span className="gtc-small text-uppercase text-muted">
													{" "}
													Times{" "}
												</span>
												<FormControl name="take_times" />
											</FormGroup>
										</Col>
									</Row>

									<Box my={2}>
										<TextField
											variant={"outlined"}
											name="remarks"
											label="Sig."
											fullWidth
										/>
									</Box>

									{/* <FormGroup>
                                            <span className="gtc-small text-uppercase text-muted"> brand </span>
                                            <FormControl name="brand" as="select">
                                                <option value=""> --Select-- </option>
                                                <option value="FK">FK</option>
                                                <option value="BBRAUN">BRAUN</option>
                                            </FormControl>
                                        </FormGroup> */}

									<p className="m-0 mb-2 text-muted">
										{" "}
										Are you sure to continue adding this item?{" "}
									</p>
									<Button type="submit" variant="success" disabled={isProcess}>
										{isProcess ? (
											<CircularProgress color="inherit" size={20} />
										) : (
											<CheckCircleOutlined />
										)}{" "}
										Add{" "}
									</Button>
									<Button type="reset" variant="secondary ml-2">
										{" "}
										<HighlightOff /> No{" "}
									</Button>
									<Button
										type="button"
										variant="primary ml-2"
										onClick={() => displayUnsave()}
									>
										{" "}
										<Badge variant="danger"> {unsaveCount} </Badge> Unsave
										Prescription{" "}
									</Button>
								</Form>
							</>
						) : (
							Notify.noRecord()
						)
					) : (
						Notify.loading()
					)}
				</>
			)}

			<Dialog
				open={dialog}
				onClose={() => setDialog(false)}
				maxWidth="sm"
				fullWidth
			>
				<Box display="flex">
					<Box flexGrow={1}>
						<DialogTitle>Virtual Unsave Prescription</DialogTitle>
					</Box>
					<Box>
						<IconButton onClick={() => setDialog(false)}>
							<HighlightOff color="secondary" />
						</IconButton>
					</Box>
				</Box>

				<DialogContent dividers>
					<UnsavePrescription connection="online" patient_id={patient_id} />
				</DialogContent>
			</Dialog>
		</Fragment>
	);
};

export default PrescriptionVirtual;
