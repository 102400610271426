import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "../styles/styles";
import ButtonV3 from "src/components/ButtonV3";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    fontSize: "18px",
    padding: "6px",
  },
}));

export default function CisNewAppointments() {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: 0,
      status: "pending",
      rescheduled: "no",
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    let formData = new FormData();
    formData.append("date", data?.date || "");
    formData.append("complaint", data?.complaint || "");
    formData.append("service", data?.service || "");
    formData.append("amount", data?.amount || "");
    formData.append("status", data?.status || "");
    formData.append("rescheduled", data?.rescheduled || "");
  };

  return (
    <div className="flex w-full flex-col">
      <form>
        <div>
          <div className="mx-auto" style={styles.parentContainerAdd}>
            <Card elevation={3} className="p-4" component={Box}>
              <div style={styles.divContainerColumn}>
                <div style={styles.divContainer}>
                  <TextField
                    label={
                      <label style={styles.label}>
                        Date<span style={styles.astirisColor}>*</span>
                      </label>
                    }
                    variant="outlined"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    q
                    {...register("date", { required: true })}
                    error={errors.date}
                    helperText={errors.date && "Date is required"}
                  />
                </div>
                <div style={styles.divContainer}>
                  <TextField
                    label={
                      <label style={styles.label}>
                        Complaint<span style={styles.astirisColor}>*</span>
                      </label>
                    }
                    variant="outlined"
                    fullWidth
                    {...register("complaint", { required: true })}
                    error={errors.complaint}
                    helperText={errors.complaint && "Complaint is required"}
                  />
                </div>
                <div style={styles.divContainer}>
                  <TextField
                    label={
                      <label style={styles.label}>
                        Service<span style={styles.astirisColor}>*</span>
                      </label>
                    }
                    variant="outlined"
                    fullWidth
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("service", { required: true })}
                    error={errors.service}
                    helperText={errors.service && "Service is required"}
                  >
                    <option value="">Select</option>
                    <option value="Physical">Physical</option>
                    <option value="Medical Certificate">
                      Medical Certificate
                    </option>
                    <option value="Consultation">Consultation</option>
                  </TextField>
                </div>
              </div>
              <div style={styles.divContainerColumn}>
                <div style={styles.divContainer}>
                  <TextField
                    label={<label style={styles.label}>Amount</label>}
                    variant="outlined"
                    fullWidth
                    type="number"
                    inputProps={{ step: "0" }}
                    {...register("amount", {
                      required: true,
                      pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                    })}
                    error={errors.amount}
                    helperText={errors.amount && "Amount is Invalid"}
                  />
                </div>

                <div style={styles.divContainer}>
                  <TextField
                    label={<label style={styles.label}>Status</label>}
                    variant="outlined"
                    fullWidth
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("status")}
                  >
                    <option value="pending">Pending</option>
                    <option value="completed">Completed</option>
                  </TextField>
                </div>
              </div>
              <div style={styles.divContainer}>
                <label style={styles.label}>Rescheduled?</label>
                <RadioGroup {...register("rescheduled")}>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </div>
            </Card>
          </div>
        </div>

        <div className="mx-auto" style={styles.button}>
          <ButtonV3 onClick={handleSubmit(onSubmit)}>Submit</ButtonV3>
        </div>
      </form>
    </div>
  );
}
