import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import SelectItemsSelector from "./SelectItemsSelector";
import Img from "src/components/Img";
import { UsersData } from "src/ContextAPI";
import {
	dataURItoBlob,
	dateYYYYMMDD,
	formatDateMMDDYYYYHHIIA,
} from "src/helpers/utils";
import ItemServiceList from "./ItemServiceList";
import SelectInputField from "src/components/forms/SelectInputField";
import SelectToUseItems from "./SelectToUseItems";
import ImagePicker from "src/components/forms/ImagePicker";

const symptoms = [
	{
		value: "cough_for_3_weeks_or_longer",
		label: "Cough for three weeks or longer",
	},
	{
		value: "coughing_up_blood_or_mucus",
		label: "Coughing up blood or mucus",
	},
	{ value: "chest_pain", label: "Chest pain" },
	{
		value: "pain_with_breathing_or_coughing",
		label: "Pain with breathing or coughing",
	},
	{ value: "fever", label: "Fever" },
	{ value: "chills", label: "Chills" },
	{ value: "night_sweats", label: "Night sweats" },
	{ value: "weight_loss", label: "Weight loss" },
	{ value: "not_wanting_to_eat", label: "Not wanting to eat" },
	{ value: "tiredness", label: "Tiredness" },
	{
		value: "not_feeling_well_in_general",
		label: "Not feeling well in general",
	},
];
const AddXrayResultModal = (props, ref) => {
	const { patient, refresh, onSuccess } = props;
	const { users } = useContext(UsersData);
	const { register: register1, watch: watch1 } = useForm();
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		trigger,
		control,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(0);
	const [loading, setLoading] = useState(false);
	const [xRayResult, setXRayResult] = useState(null);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const submit = (data) => {
		setLoading(true);
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
			// onUploadProgress: progressEvent => onProgress(progressEvent),
		};
		const file = dataURItoBlob(xRayResult);

		let formdata = new FormData();

		formdata.append("_method", "PATCH");
		formdata.append("xray", file);

		axios
			.post(
				`/v1/clinic/tb-xray-result/${appointment?.id}`,
				formdata,
				config
			)
			.then((res) => {
				setTimeout(() => {
					refresh();
					onSuccess();
					toast.success("Adding xray result success!");
				}, 200);
				hide();
				// setIsPositive(false);
			});
	};
	return (
		<Modal
			open={open}
			staticModal={true}
			size="lg"
			hide={hide}
			className=" w-full"
		>
			<ModalHeader
				title={`Add X-ray result`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="pb-3 flex items-center gap-4 px-2 w-full">
					<ImagePicker
						className="border w-full border-dashed rounded-lg mt-2 mb-4 flex items-center justify-center"
						onChange={(data) => {
							setXRayResult(data);
							// if (isBase64(data)) {
							// 	saveAvatar(data);
							// }
						}}
					>
						{({ imagePreview }) => {
							return (
								<>
									{imagePreview ? (
										<div className="relative w-full">
											<div className="pt-[45%]" />
											<img
												src={imagePreview}
												className="absolute object-contain bg-black top-0 left-0 w-full h-full"
											/>
										</div>
									) : (
										<div className="flex flex-col justify-center items-center gap-3 p-5 text-lg">
											<FlatIcon
												icon="rr-upload"
												className="text-lg text-slate-400"
											/>
											<span className=" text-slate-400">
												Upload x-ray result
											</span>
										</div>
									)}
								</>
							);
						}}
					</ImagePicker>
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center justify-between"}>
				<>
					<ActionBtn
						className="w-1/3"
						type="success"
						onClick={handleSubmit(submit)}
						loading={loading}
					>
						<FlatIcon icon="rr-disk" />
						Submit
					</ActionBtn>
				</>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(AddXrayResultModal);
