import {
	Box,
	Dialog,
	TextField,
	Typography,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Notify from "../../notification/Notify";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "../../utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import thyroidProfileRef from "../references/Ref_ThyroidProfile";
import Specimen from "./Specimen";

const ThyroidProfileOrderDetails = ({
	queue,
	formheader,
	order,
	getThyroidProfileTestOrder,
	resetDisplay,
	process_for,
}) => {
	const { users } = React.useContext(UsersData);
	const history = useHistory();
	const [processSubmitting, setProcessSubmitting] = useState(false);
	const [resultSubmitting, setResultSubmitting] = useState(false);
	const [savebtnDisabled, setSavebtnDisabled] = useState(true);
	const [orderItems, setOrderItems] = useState([]);
	const [processDialog, setProcessDialog] = useState({
		data: null,
		dialog: false,
	});

	const [orderDetails, setOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const getThyroidProfileOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order.trace_number };
		Axios.get("laboratory/order/ordernew-thyroidprofiletest/details", {
			params,
		})
			.then((response) => {
				const data = response.data;
				setOrderDetails({
					data: data,
					ready: true,
				});
				checkIfSaveBtnIsEnabled(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const checkIfSaveBtnIsEnabled = (data) => {
		for (let i = 0; i < data.length; i++) {
			if (Boolean(data[i].is_processed)) {
				setSavebtnDisabled(false);
			}
		}
	};

	const handleSaveResult = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("trace_number", order.trace_number);
		formdata.set("queue", queue);
		formdata.set("process_for", process_for);

		var error = [];
		setResultSubmitting(true);
		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			Axios.post(
				"laboratory/order/ordernew-thyroidprofile/save-process-result",
				formdata
			)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						Notify.successRequest("order result added. Redirect to receipt.");
						setTimeout(() => {
							history.push(
								`/sph/app/laboratory/record/print/order/${order.trace_number}`
							);
						}, 5000);
						setSavebtnDisabled(true);
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => setResultSubmitting(false));
		}
	};

	//gtanggal na daw ni
	//   const handlePendingOrder = (e) => {
	//     e.preventDefault();
	//     e.persist();

	//     var formdata = new FormData(e.target);
	//     formdata.set("user_id", users.user_id);
	//     formdata.set("username", users.username);
	//     formdata.set("order_id", order.order_id);

	//     var error = [];

	//     if (error.length > 0) {
	//       console.log("Form has an error.");
	//     } else {
	//       setPendingSubmitting(true);
	//       Axios.post("laboratory/order/ordernew-sorology/save-setpending", formdata)
	//         .then((response) => {
	//           const data = response.data;
	//           if (data === "pass-invalid") {
	//             Notify.fieldInvalid("password");
	//           }
	//           if (data === "success") {
	//             getThyroidProfileTestOrder();
	//             resetDisplay();
	//             setPendingDialog(false);
	//             Notify.successRequest("order pending");
	//           }
	//         })
	//         .catch((error) => {
	//           Notify.requestError(error);
	//         })
	//         .finally(() => setPendingSubmitting(false));
	//     }
	//   };

	const handleProcessOrder = async (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", processDialog.data.order_id);
		formdata.set("trace_number", processDialog.data.trace_number);

		var error = [];
		setProcessSubmitting(true);

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			let response = await Axios.post(
				"laboratory/thyroidprofiletest/order-setprocess/custom-qty",
				formdata
			);

			if (response.data.message === "reagent-error") {
				Notify.customToast("Reagent Not Error", "Reagent/items not available.");
			}

			if (response.data.message === "pass-invalid") {
				Notify.fieldInvalid("password");
			}

			if (response.data.message === "success") {
				Notify.successRequest("process");
				setProcessDialog({ data: null, dialog: false });
				getThyroidProfileOrderDetails();
			}
		}

		setTimeout(() => {
			setProcessSubmitting(false);
		}, 2000);
	};

	const handleProcessSelectedOrder = async (data) => {
		let response = await Axios.get(
			"laboratory/items/laborder/list-available-items",
			{
				params: {
					user_id: users.user_id,
					management_id: users.management_id,
					order_id: data.order_id,
				},
			}
		);

		setOrderItems(response.data);
		setProcessDialog({ data: data, dialog: true });
	};

	useEffect(() => {
		getThyroidProfileOrderDetails();
		// eslint-disable-next-line
	}, [order]);

	return (
		<>
			<form onSubmit={handleSaveResult}>
				<Card elevation={0}>
					<Box>
						<CardHeader
							component={Box}
							align="center"
							title={formheader && formheader.name}
							subheader={
								<Box>
									<Typography>{formheader && formheader.address}</Typography>
									<Typography>
										{formheader && formheader.contact_number}
									</Typography>
								</Box>
							}
						/>
					</Box>

					<CardContent>
						{/* paitent information */}
						<Box display="flex">
							<Box flexGrow={1} mb={2}>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT NAME:{" "}
										</Typography>
										{`${order.lastname}, ${order.firstname} ${
											order.middle === null ? "" : order.middle
										}`}
									</Typography>
								</Box>

								<Box display="flex" mb={2}>
									<Box>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												AGE:{" "}
											</Typography>
											{order.birthday === null
												? "none"
												: Notify.calculateAge(order.birthday)}
										</Typography>
									</Box>
									<Box ml={5}>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												GENDER:{" "}
											</Typography>
											{order.gender === null ? "none" : order.gender}
										</Typography>
									</Box>
								</Box>

								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT ADDRESS:{" "}
										</Typography>
										{`${order.street} ${order.barangay} ${order.city} `}
									</Typography>
								</Box>

								<Box>
									<Typography variant="caption" className="font-weight-bold">
										PATIENT CONDITION:{" "}
									</Typography>
									{order.patient_condition === null
										? " none"
										: order.patient_condition}
								</Box>
							</Box>

							<Box>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											DATE:{" "}
										</Typography>
										{Notify.createdAt(new Date().toLocaleString())}
									</Typography>
								</Box>
							</Box>
						</Box>
					</CardContent>

					<CardContent>
						<Specimen
							patient_id={order.patient_id}
							trace_number={order.trace_number}
						/>
					</CardContent>

					<CardContent>
						<Box>
							<Typography variant="h6" align="center">
								<b> THYROID PROFILE </b>
							</Typography>
						</Box>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell />
										<TableCell align="center">
											<b> RESULT </b>
										</TableCell>
										<TableCell align="center">
											<b> ACTION </b>
										</TableCell>
										<TableCell align="center">
											<b> REFERENCE VALUES </b>
										</TableCell>
										<TableCell align="center">
											<b> REMARKS </b>
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{orderDetails.ready ? (
										orderDetails.data.length > 0 ? (
											orderDetails.data.map((data, index) => (
												<Fragment key={index}>
													<TableRow hidden={data.t3 === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																T3
															</Typography>
														</TableCell>
														<TableCell align="center">
															{/* hidden inputs */}
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="order_id[]"
																	label="order_id"
																	hidden
																	value={data.order_id}
																/>
																<TextField
																	margin="dense"
																	fullWidth
																	name="patient_id"
																	label="Result"
																	hidden
																	value={data.patient_id}
																/>

																<TextField
																	margin="dense"
																	fullWidth
																	name="doctors_id"
																	label="doctor_id"
																	hidden
																	value={
																		data.doctor_id === null
																			? ""
																			: data.doctor_id
																	}
																/>
															</Box>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="t3[]"
																	label="Result"
																	type="number"
																	hidden={data.t3 === null}
																	required={data.t3 === null ? false : true}
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box> {thyroidProfileRef.t3()} </Box>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="t3_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.t4 === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																T4
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="t4[]"
																	required={data.t4 === null ? false : true}
																	label="Result"
																	type="number"
																	hidden={data.t4 === null}
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box> {thyroidProfileRef.t4()} </Box>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="t4_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.tsh === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																TSH
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="tsh[]"
																	label="Result"
																	required={data.tsh === null ? false : true}
																	type="number"
																	hidden={data.tsh === null}
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{thyroidProfileRef.tsh()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="tsh_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.ft4 === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																FT4
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="ft4[]"
																	label="Result"
																	required={data.ft4 === null ? false : true}
																	type="number"
																	hidden={data.ft4 === null}
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{thyroidProfileRef.ft4()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="ft4_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.ft3 === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																FT3
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="ft3[]"
																	required={data.ft3 === null ? false : true}
																	label="Result"
																	type="number"
																	hidden={data.ft3 === null}
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{thyroidProfileRef.ft3()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="ft3_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.t3t4 === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																t3t4
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="t3t4[]"
																	required={data.t3t4 === null ? false : true}
																	label="Result"
																	type="number"
																	hidden={data.t3t4 === null}
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box> none </Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="t3t4_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.fht === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																fht
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="fht[]"
																	required={data.fht === null ? false : true}
																	label="Result"
																	type="number"
																	hidden={data.fht === null}
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box> none </Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="fht_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>
												</Fragment>
											))
										) : (
											<TableRow>
												<TableCell align="center" colSpan={5}>
													<Typography color="secondary" variant="subtitle2">
														No record found.
													</Typography>
												</TableCell>
											</TableRow>
										)
									) : (
										<TableRow>
											<TableCell align="center" colSpan={5}>
												<Typography color="primary" variant="subtitle2">
													Please wait...
												</Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
					<Box display="flex" m={1}>
						<Box flexGrow={1} />
						<Box>
							<CardActions>
								<Button
									variant="contained"
									size="large"
									color="primary"
									type="submit"
									disabled={savebtnDisabled}
									startIcon={
										resultSubmitting && (
											<CircularProgress size={20} color="inherit" />
										)
									}
								>
									Save Result
								</Button>
							</CardActions>
						</Box>
					</Box>
				</Card>
			</form>

			{/* set as pending dialog */}
			{/* <Dialog
          open={pendingDialog}
          onClose={() => setPendingDialog(false)}
          disableBackdropClick
          PaperComponent={DraggableDialog}
        >
          <DialogTitle id="draggable-handle">Set as pending</DialogTitle>
          <form onSubmit={handlePendingOrder}>
            <DialogContent dividers>
              <Box mb={2}>
                <TextField
                  rows={5}
                  fullWidth
                  name="reason"
                  label={`Pending Reason`}
                  variant="outlined"
                  multiline
                />
              </Box>
  
              <Box>
                <TextField
                  fullWidth
                  name="password"
                  label={`Password`}
                  variant="outlined"
                  type="password"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setPendingDialog(false)}
                startIcon={<HighlightOffIcon />}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={pendingSubmitting}
                startIcon={
                  pendingSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <ErrorOutlineIcon />
                  )
                }
              >
                pending
              </Button>
            </DialogActions>
          </form>
        </Dialog> */}

			{/* set as process dialog */}
			<Dialog
				open={processDialog.dialog}
				onClose={() => setProcessDialog({ data: null, dialog: false })}
				disableBackdropClick
				PaperComponent={DraggableDialog}
				maxWidth={"xs"}
				fullWidth
			>
				<DialogTitle id="draggable-handle">Process order</DialogTitle>
				<form onSubmit={handleProcessOrder}>
					<DialogContent dividers>
						{orderItems.length > 0
							? orderItems.map((data, index) => (
									<Box key={index} mb={2}>
										<Box>
											<TextField
												fullWidth
												name="item_id[]"
												value={data.item_id}
												variant="outlined"
												hidden
											/>
										</Box>

										<Box>
											<TextField
												fullWidth
												required
												label={`${data.description} qty to process`}
												name="qty[]"
												variant="outlined"
												defaultValue={1}
												InputLabelProps={{
													shrink: true,
												}}
												// InputProps={{
												//   inputProps: {
												//     min: 0,
												//     max: data._total_qty_available,
												//   },
												// }}
												type="number"
											/>
										</Box>
									</Box>
							  ))
							: "No reagent/item found."}

						<Box mt={2}>
							<TextField
								fullWidth
								required
								defaultValue="ok"
								name="remarks"
								label={`Remarks`}
								variant="outlined"
							/>
						</Box>

						<Box mt={2}>
							<TextField
								fullWidth
								required
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setProcessDialog({ data: null, dialog: false })}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={processSubmitting}
							startIcon={
								processSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<CheckCircleIcon />
								)
							}
						>
							process
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default ThyroidProfileOrderDetails;
