const CardContainer = ({
	containerClassName,
	header,
	children,
	footer,
	className,
}) => {
	return (
		<div
			className={`rounded-xl flex flex-col ${
				containerClassName ? containerClassName : ""
			}`}
		>
			{header ? (
				<div className="flex gap-y-2 md:gap-y-0 md:items-center p-4 bg-white border-b rounded-t-xl flex-col md:flex-row">
					{header?.title ? (
						<div className="flex flex-col">
							<h5 className="text-base font-bold text-primary mb-0">
								{header?.title}
							</h5>{" "}
							{header?.subtitle ? (
								<p className="text-placeholder">
									{header?.subtitle}
								</p>
							) : (
								""
							)}{" "}
						</div>
					) : (
						""
					)}
					{header?.children ? header?.children : ""}
				</div>
			) : (
				""
			)}
			<div
				className={`last:rounded-b-xl first:rounded-t-xl bg-white transition-all duration-300 overflow-auto ${
					className ? className : " p-4"
				}`}
			>
				{children}
			</div>
			{footer ? (
				<div className="flex items-center bg-white last:rounded-b-xl ">
					{footer?.title ? (
						<div className="flex flex-col">
							<h5 className="text-base font-bold text-primary mb-0">
								{footer?.title}
							</h5>{" "}
							{footer?.subtitle ? (
								<p className="text-placeholder">
									{footer?.subtitle}
								</p>
							) : (
								""
							)}{" "}
						</div>
					) : (
						""
					)}
					{footer?.children ? footer?.children : ""}
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default CardContainer;
