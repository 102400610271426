import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import { v4 as uuidv4 } from "uuid";
import {
	faListUl,
	faTruckMoving,
	faUserInjured,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import HealthUnitDetails from "src/disease-treatment/components/HealthUnitDetails";
import { Pagination } from "react-bootstrap";
import { UsersData } from "src/ContextAPI";
import CisPatientListItem from "src/cis/components/CisPatientListItem";
import TabGroup from "src/components/TabGroup";
import ButtonV3 from "src/components/ButtonV3";
import ClaimForm2 from "./phic/ClaimForm2";
import PMRForm from "./phic/PMRForm";
import ClaimForm from "./phic/ClaimForm";
import ClaimForm3 from "./phic/ClaimForm3";
import ClaimForm4 from "./phic/ClaimForm4";
import { Fade } from "react-reveal";
let first_key = uuidv4();

const MenuTitle = ({ src, children, className }) => {
	return (
		<div
			className={`flex flex-col items-center justify-center gap-2 p-1 py-1  ${
				className || ""
			}`}
		>
			{/* <img src={src} className="h-[28px] w-11 object-contain" /> */}
			<span className="text-sm font-bold">{children}</span>
		</div>
	);
};

const PhoPHICClaims = () => {
	const { users } = useContext(UsersData);
	const {
		data,
		meta,
		setPage,
		loading,
		setPaginate,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
		page,
		keyword,
		setKeyword,
		paginate,
	} = useDataTable(`v1/patients`, null, {});
	const [key, setKey] = useState(first_key);
	const [list, setList] = useState([]);
	const [selected, setSelected] = useState(null);
	const [selectedClick, setSelectedClick] = useState(null);

	useEffect(() => {
		setList(data?.data);
	}, [data]);

	const updatePatient = (patient) => {
		console.log("updatePatientupdatePatient,", patient);
		setSelectedClick(patient);
		setList((prevList) =>
			prevList.map((p) =>
				patient?.id == p?.id
					? {
							...p,
							...patient,
					  }
					: p
			)
		);
	};

	const calcScreenDPI = () => {
		const el = document.createElement("div");
		el.style = "width: 1in;";
		document.body.appendChild(el);
		const dpi = el.offsetWidth;
		document.body.removeChild(el);

		return dpi;
	};

	// console.log(calcScreenDPI());

	const printPage = () => {
		let margin = calcScreenDPI() * 1;
		let w = calcScreenDPI() * 8.5;
		let h = calcScreenDPI() * 13;
		var prtContent = document.getElementById("phic-form-printable");
		var WinPrint = window.open(
			"",
			"",
			`left=${margin},top=${margin},width=${w},height=${h},toolbar=0,scrollbars=0,status=0`
		);

		WinPrint.document.write(
			`<html><head><title>Print it!</title><link rel="stylesheet" type="text/css" href="/tailwind.css"></head><body>`
		);
		WinPrint.document.write(prtContent.innerHTML);
		WinPrint.document.write("</body></html>");
		WinPrint.document.close();
		WinPrint.focus();
		setTimeout(() => {
			WinPrint.print();
			// WinPrint.close();
		}, 1000);
	};
	return (
		<>
			<LayoutContainer
				icon={
					<img
						src="/philhealth.png"
						alt=""
						className="h-11 w-11 bg-white rounded-xl"
					/>
				}
				title="PHIC Claim"
				// subtitle="List of consignment orders"
				titleChildren={<HealthUnitDetails />}
			>
				<div className="flex flex-col gap-2 h-[calc(100vh-204px)]">
					<div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
						<div className="lg:col-span-3 flex flex-col gap-4 h-[calc(100vh-204px)]">
							<div className="flex items-center gap-4">
								<h3 className="font-bold text-xl mb-0 mr-auto">
									Patient List
								</h3>
							</div>
							<div className="relative w-full flex items-center">
								<input
									type="text"
									placeholder="Search patient"
									className="bg-white rounded-xl !border !border-slate-300 py-2 pr-3 h-11 w-full pl-[48px] !duraton-200 text-sm"
									onChange={(e) => {
										setKeyword(e.target.value);
									}}
								/>
								<span className="absolute left-3 text-slate-300 text-base font-bold pr-2 !border-r flex items-center">
									<FlatIcon icon="rr-search" className=" " />
								</span>
							</div>

							<div className="flex flex-col gap-3 divide-y max-h-[calc(100vh-274)] overflow-auto">
								{loading ? (
									<div className="p-5 flex items-center justify-center bg-foreground animate-pulse rounded-xl">
										<b className="text-placeholder">
											Loading...
										</b>
									</div>
								) : list?.length == 0 ? (
									keyword?.length > 0 ? (
										<div className="p-5 flex items-center justify-center">
											<b className="text-placeholder">
												No results found for "{keyword}
												".
											</b>
										</div>
									) : (
										<div className="p-5 flex items-center justify-center">
											<b className="text-placeholder">
												No data to display.
											</b>
										</div>
									)
								) : (
									list?.map((x) => {
										return (
											<CisPatientListItem
												patient={x}
												onClick={() => {
													setTimeout(() => {
														setSelectedClick(x);
													}, 100);
												}}
											/>
										);
									})
								)}
							</div>
							<div className="">
								<Pagination
									page={page}
									setPage={setPage}
									pageCount={meta?.last_page}
									pageSize={paginate}
									setPageSize={setPaginate}
									paginationClassName="flex !flex-col !items-start"
								/>
							</div>
						</div>

						<div className="lg:col-span-9 ">
							{selectedClick ? (
								<Fade
									key={`selected-Click-${selectedClick?.id}`}
								>
									<TabGroup
										tabClassName={`py-1`}
										contentClassName="max-h-[13in] !p-0 bg-slate-600 !rounded-none"
										contents={[
											{
												title: (
													<MenuTitle>PMRF</MenuTitle>
												),
												content: (
													<div className="w-full min-h-[13in] -mx-1">
														<PMRForm
															patient={
																selectedClick
															}
														/>
													</div>
												),
											},
											{
												title: (
													<MenuTitle
														className={"w-[56px]"}
													>
														CSF
													</MenuTitle>
												),
												content: (
													<div className="w-full min-h-[13in] -mx-1">
														<ClaimForm
															patient={
																selectedClick
															}
														/>
													</div>
												),
											},
											{
												title: (
													<MenuTitle
														className={"w-[56px]"}
													>
														CF 2
													</MenuTitle>
												),
												content: (
													<div className="w-full min-h-[13in] -mx-1">
														<ClaimForm2
															patient={
																selectedClick
															}
														/>
													</div>
												),
											},
											{
												title: (
													<MenuTitle
														className={"w-[56px]"}
													>
														CF 3
													</MenuTitle>
												),
												content: (
													<div className="w-full min-h-[13in] -mx-1">
														<ClaimForm3
															patient={
																selectedClick
															}
														/>
													</div>
												),
											},
											{
												title: (
													<MenuTitle>CF 4</MenuTitle>
												),
												content: (
													<div className="w-full min-h-[13in] -mx-1">
														<ClaimForm4
															patient={
																selectedClick
															}
														/>
													</div>
												),
											},
										]}
										titleChildren={
											<div className="flex items-center justify-center gap-4">
												<ActionBtn
													className="!h-10"
													onClick={printPage}
												>
													<FlatIcon icon="rr-print" />
												</ActionBtn>{" "}
												<ActionBtn
													type="success"
													className="!h-10"
												>
													<FlatIcon icon="rr-share" />
													Process
												</ActionBtn>
											</div>
										}
									/>
								</Fade>
							) : (
								<div className="p-5 flex items-center justify-center text-xl text-slate-400">
									Select patient to generate forms.
								</div>
							)}
						</div>
					</div>
				</div>
			</LayoutContainer>
		</>
	);
};

export default PhoPHICClaims;
