import {
  Box,
  CardHeader,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
  Fab,
  createStyles,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  Zoom,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from "@material-ui/core";
import { Close, Print } from "@material-ui/icons";
import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  Fragment,
  useRef,
} from "react";
import { Edit } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const TemplateTableUTZ2 = ({ formheader, selectedImagingId, close }) => {
  const { users } = useContext(UsersData);
  const [printResult, setPrintResult] = useState({ data: [], ready: false });
  const classes = useStyles();
  let printRef = useRef();
  const [openEditResult, setOpenEditResult] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getAllPrintOrder = useCallback(async () => {
    var params = {
      managament_id: users.managament_id,
      user_id: users.user_id,
      main_mgmt_id: users.main_mgmt_id,
      imaging_center_id: selectedImagingId,
    };
    try {
      let response = await axios.get("imaging/get/print-order", { params });
      const data = response.data;
      setPrintResult({ data, ready: true });
    } catch (error) {
      console.log(error);
    }
  }, [users, selectedImagingId]);

  const handleEditSaveResult = (e) => {
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("management_id", users.management_id);
    formdata.append("imaging_center_id", printResult.data.imaging_center_id);

    var error = [];
    if (
      formdata.get("new_result").length === 0 ||
      formdata.get("new_result").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("new result");
    }
    if (
      formdata.get("new_impression").length === 0 ||
      formdata.get("new_impression").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("new impression");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("imaging/edit/confirmed/result", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getAllPrintOrder();
            setOpenEditResult(false);
            e.target.reset();
            Notify.successRequest("edit result");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    getAllPrintOrder();
  }, [selectedImagingId, getAllPrintOrder]);

  return (
    <>
      <Fragment>
        {printResult.ready ? (
          printResult.data && (
            <Fragment>
              <Box p={2} ref={printRef}>
                <Box display="flex" justifyContent="center">
                  <Box mt={2}>
                    {formheader && (
                      <CardMedia
                        style={{ width: 70 }}
                        component={"img"}
                        src={`${imageLocation}laboratory/logo/${formheader.logo}`}
                      />
                    )}
                  </Box>
                  <Box>
                    <CardHeader
                      component={Box}
                      align="center"
                      title={
                        <Typography variant="h5">
                          {formheader && formheader.name}
                        </Typography>
                      }
                      subheader={
                        <Box>
                          <Typography variant="h5">
                            {formheader && formheader.address}
                          </Typography>
                          <Typography variant="h5">
                            {formheader && formheader.contact_number}
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography className="font-weight-bold" variant="h6">
                          Name:{" "}
                          {`${printResult.data.lastname}, ${
                            printResult.data.firstname
                          } ${
                            printResult.data.middle === null
                              ? ""
                              : printResult.data.middle
                          }`}
                        </Typography>
                      </Box>

                      <Box display="flex">
                        <Box>
                          <Typography className="font-weight-bold" variant="h6">
                            Age:{" "}
                            {printResult.data.birthday === null
                              ? "None"
                              : Notify.calculateAge(printResult.data.birthday)}
                          </Typography>
                        </Box>
                        <Box ml={5}>
                          <Typography className="font-weight-bold" variant="h6">
                            Sex:{" "}
                            {printResult.data.gender === null
                              ? "None"
                              : printResult.data.gender}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography className="font-weight-bold" variant="h6">
                          Examination: {printResult.data.imaging_order}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography className="font-weight-bold" variant="h6">
                          Referring Physician:{" "}
                          {printResult.data.referring_physician}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography className="font-weight-bold" variant="h6">
                          Date: {Notify.createdAt(printResult.data.created_at)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="center">
                      <Box flexGrow={1} />
                      <Box>
                        {printResult.data.image === null ||
                        printResult.data.image.includes("no-image") ? null : (
                          <CardMedia
                            style={{ width: 120 }}
                            component={"img"}
                            src={`${imageLocation}patients/${printResult.data.image}`}
                          />
                        )}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box borderBottom={1} />
                    <Box>
                      <Typography style={{ fontSize: 17 }}>
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          This report is based on ultrasonographic findings and
                          should be correlated with clinical and laboratory data
                          and other imaging modalities.
                        </span>
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={3}
                      mb={2}
                    >
                      <Box>
                        <Typography
                          variant="h5"
                          style={{
                            fontWeight: "bolder",
                          }}
                        >
                          <span
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                          >
                            {printResult.data.imaging_type === "xray"
                              ? "ROENTGENOLOGIC INTERPRETATION"
                              : "SONOGRAPHIC REPORT"}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box mt={5}>
                      <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          Within the gravid uterus is a gestational sac with no
                          intense fetal echo noted yet at the time of the scan.
                          The following biometric parameters were taken:
                        </span>
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box border={1}>
                      <TableContainer>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={2}>
                                <span
                                  contentEditable={true}
                                  suppressContentEditableWarning={true}
                                >
                                  YOLK SAC-
                                </span>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                            </TableRow>
                            <TableRow>
                              <TableCell align="center" colSpan={2}>
                                <span
                                  contentEditable={true}
                                  suppressContentEditableWarning={true}
                                >
                                  GS= cm weeks days
                                </span>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                            </TableRow>
                            <TableRow>
                              <TableCell align="center" colSpan={2}>
                                <span
                                  contentEditable={true}
                                  suppressContentEditableWarning={true}
                                >
                                  CRL= cm weeks days
                                </span>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                            </TableRow>
                            <TableRow>
                              <TableCell align="right">
                                <span
                                  contentEditable={true}
                                  suppressContentEditableWarning={true}
                                >
                                  AVERAGE AOG
                                </span>
                              </TableCell>
                              <TableCell>
                                <span
                                  contentEditable={true}
                                  suppressContentEditableWarning={true}
                                >
                                  = weeks days
                                </span>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Typography
                        variant="h6"
                        style={{
                          whiteSpace: "pre-line",
                          fontWeight: "bolder",
                        }}
                      >
                        {printResult.data.imaging_result}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box mt={5}>
                      <Typography variant="h5" style={{ fontWeight: "bolder" }}>
                        IMPRESSION:
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="h5"
                        style={{
                          whiteSpace: "pre-line",
                          fontWeight: "bolder",
                        }}
                      >
                        {printResult.data.imaging_results_remarks}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Box my={4}>
                  <Grid
                    container
                    spacing={2}
                    // justify="flex-end"
                  >
                    <Grid item xs={12}>
                      <Box align="center">
                        <Box mt={10}>
                          <Box>
                            {printResult.data.imageSignature !== null ? (
                              <CardMedia
                                style={{
                                  width: 170,
                                  position: "relative",
                                  top: 7,
                                }}
                                component={"img"}
                                src={`${imageLocation}imaging/signatures/${printResult.data.imageSignature}`}
                              />
                            ) : null}
                          </Box>
                          <Typography className={`gtc-uppercase`} variant="h6">
                            <span
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              style={{ borderBottom: "2px solid" }}
                            >
                              {/* {formheader && formheader.radiologist} */}
                              {printResult.data.radiologistName !== null
                                ? printResult.data.radiologistName
                                : ""}{" "}
                            </span>
                          </Typography>
                          <Box
                          // borderTop={1}
                          // width={500}
                          >
                            <Typography variant="h6">
                              <b> RADIOLOGIST </b>
                            </Typography>
                          </Box>
                          {formheader && (
                            <Box>
                              <Typography
                                className={`gtc-uppercase`}
                                variant="h5"
                              >
                                {formheader.radiologist_lcn !== null
                                  ? formheader.radiologist_lcn
                                  : ""}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box className="d-print-none">
                  <Fab
                    aria-label={"Edit"}
                    className={classes.fab3}
                    color={"primary"}
                    onClick={() => close()}
                  >
                    <Edit />
                  </Fab>

                  <Fab
                    aria-label={"Close"}
                    className={classes.fab2}
                    color={"secondary"}
                    onClick={() => close()}
                  >
                    <Close />
                  </Fab>

                  <Fab
                    aria-label={"Print"}
                    className={classes.fab}
                    color={"primary"}
                    onClick={() => window.print()}
                  >
                    <Print />
                  </Fab>
                </Box>
              </Box>
            </Fragment>
          )
        ) : (
          <Box
            display="flex"
            height={250}
            justifyContent="center"
            alignItems="center"
          >
            {Notify.loading()}
          </Box>
        )}

        <Dialog
          open={openEditResult}
          onClose={() => setOpenEditResult(false)}
          TransitionComponent={Zoom}
          transitionDuration={900}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Edit Result</DialogTitle>
          <DialogContent>
            <form onSubmit={handleEditSaveResult}>
              <Box mb={2}>
                <TextField
                  name="new_result"
                  label="Edit Result"
                  variant="outlined"
                  fullWidth
                  rows={4}
                  defaultValue={
                    printResult.data.imaging_result === null
                      ? ""
                      : printResult.data.imaging_result
                  }
                  multiline
                />
              </Box>

              <Box mb={2}>
                <TextField
                  name="new_impression"
                  label="Edit Impression"
                  variant="outlined"
                  fullWidth
                  rows={4}
                  defaultValue={
                    printResult.data.imaging_results_remarks === null
                      ? ""
                      : printResult.data.imaging_results_remarks
                  }
                  multiline
                />
              </Box>

              <Box mb={3}>
                <TextField
                  type="password"
                  required
                  name="password"
                  label="Password"
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                />
              </Box>

              <Box my={2} display="flex">
                <Box flexGrow={1} />

                <Button
                  variant="contained"
                  color="default"
                  startIcon={<ClearIcon />}
                  onClick={() => setOpenEditResult(false)}
                >
                  Cancel
                </Button>

                <Box ml={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon />}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </Fragment>
    </>
  );
};

export default TemplateTableUTZ2;

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      width: "calc(100vw - 50px)",
      height: "calc(100vh - 90px)",
      justifyContent: "center",
      alignItems: "center",
    },
    idprint: {
      display: "block",
      width: "234px",
      height: "auto",
    },
    fab3: {
      position: "absolute",
      bottom: theme.spacing(18),
      right: theme.spacing(2),
    },
    fab2: {
      position: "absolute",
      bottom: theme.spacing(10),
      right: theme.spacing(2),
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    picturepatient: {
      width: 110,
      height: 110,
    },
    thumbnail: {
      height: 106,
    },
    infopatient: {
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
    },
    [`@media print`]: {
      body: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflow: "visible",
      },
      idprint: {
        width: 275,
        height: 410,
      },
      titleCity: {
        fontSize: 30,
        fontWeight: "bolder",
      },
      qrSize: {
        width: 150,
        height: 150,
      },
      picturepatient: {
        width: 120,
        height: 120,
      },
      thumbnail: {
        height: 116,
      },
      infopatient: {
        padding: 0,
        fontSize: 14,
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      },
      infopatient2: {
        fontSize: 11,
      },
    },
  })
);
