import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TextField,
  IconButton,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import React, { useState, useCallback, useEffect, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import { UsersData } from "src/ContextAPI";
import FormHeader from "./FormHeader";
import { CheckSquare, Edit, Printer, Square, XCircle } from "react-feather";
import Notify from "src/notification/Notify";
import CSQMMHCovid19CheckListP2 from "src/utils/PrintDetailsNoValue/CSQMMHCovid19CheckListP2";

const Covid19TriageChecklistForm = ({
  patient_id,
  details,
  info,
  doctorsList,
  allowEdit,
}) => {
  const printableRef = React.useRef();
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const { users } = useContext(UsersData);
  const [selectedSickness, setSelectedSickness] = useState([]);
  const [caseDefinition, setCaseDefinition] = useState("");
  const [severityClass, setSeverityClass] = useState("");

  const sickness = [
    "Fever",
    "Anorexia",
    "Cough",
    "Loss of smell",
    "Difficulty of breathing/Shortness of breath",
    "Loss of Taste",
    "Fatigue",
    "Nasal Congestion",
    "Myalgia",
    "Headache",
    "Sore Throat",
    "Nausea",
    "Diarrhea",
    "Vomiting",
    "Others:",
  ];

  const handleChangeSickness = (value) => {
    let test = selectedSickness;
    if (test.includes(value)) {
      let testx = test.filter((item) => item !== value);
      setSelectedSickness(testx);
    } else {
      setSelectedSickness(test.concat(value));
    }
  };

  const [ccact_id, setccact_id] = useState(null);

  const [existingCovid19ChecklistChart, setExistingCovid19CheckListChart] =
    useState({
      data: [],
      ready: false,
    });

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const getCovid19ChecklistChart = useCallback(async () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/covid19checklist", { params })
      .then((response) => {
        const data = response.data;
        setExistingCovid19CheckListChart({ data, ready: true });
        if (data.length > 0) {
          setccact_id(data[0].ccact_id);
          setSelectedSickness(
            data[0].sickness === null ? [] : data[0].sickness.split(",")
          );
          setCaseDefinition(
            data[0].case_definition === null ? "" : data[0].case_definition
          );
          setSeverityClass(
            data[0].severity_class === null ? "" : data[0].severity_class
          );
        }
      })
      .catch((error) => console.log(error));
  }, [patient_id, users, details]);

  const handleSubmitCovid19CheckListChart = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", details.trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("sickness", selectedSickness);
    formdata.set("case_definition", caseDefinition);
    formdata.set("severity_class", severityClass);
    formdata.set("status", ccact_id === null ? "for-new" : "for-update");
    formdata.set("ccact_id", ccact_id);

    setIsProcess(true);
    axios
      .post("or/update/chart/covid19checklist", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          getCovid19ChecklistChart();
          setEnableEdit(!enableEdit);
          setSelectedSickness([]);
          Notify.successRequest("edit covid19 checklist");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  useEffect(() => {
    getCovid19ChecklistChart();
  }, [patient_id, getCovid19ChecklistChart]);

  return (
    <Box>
      <Card ref={printableRef}>
        <Box m={2}>
          <Box>
            <FormHeader />
          </Box>
          <Box>
            <form onSubmit={handleSubmitCovid19CheckListChart}>
              <Box my={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          disabled={isProcess}
                          color="primary"
                          startIcon={<CheckSquare />}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            SET
                          </Button>
                        </Box>
                      )}

                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box align="center" mb={2}>
                <Typography component={"span"}>
                  <b> ASC COVID-19 TRIAGE CHECKLIST </b>
                </Typography>
              </Box>

              {existingCovid19ChecklistChart.ready && (
                <>
                  <Table size="small" className="table-bordered">
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography component={"span"} variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Name of Patient:
                            </span>{" "}
                            {!enableEdit &&
                              (info
                                ? `${info.lastname}, ${info.firstname} ${
                                    info.middle === null ? "" : info.middle
                                  }`
                                : "")}
                          </Typography>

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="name"
                                defaultValue={
                                  info
                                    ? `${info.lastname}, ${info.firstname} ${
                                        info.middle === null ? "" : info.middle
                                      }`
                                    : ""
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography component={"span"} variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Age/Sex:
                            </span>{" "}
                            {!enableEdit &&
                              (info
                                ? info.birthday === null
                                  ? ""
                                  : `${Notify.calculateAge(info.birthday)}/${
                                      info.gender !== null ? info.gender : ""
                                    }`
                                : "")}
                          </Typography>

                          {enableEdit && info && (
                            <>
                              <TextField
                                fullWidth
                                name="age"
                                defaultValue={
                                  info.birthday !== null
                                    ? `${Notify.calculateAge(info.birthday)}/${
                                        info.gender !== null ? info.gender : ""
                                      }`
                                    : ""
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography component={"span"} variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Rank/Area:
                            </span>{" "}
                            {!enableEdit
                              ? existingCovid19ChecklistChart.data.length > 0
                                ? existingCovid19ChecklistChart.data[0]
                                    .rank_area
                                : ""
                              : null}
                          </Typography>
                          {enableEdit && (
                            <TextField
                              fullWidth
                              name="rank_area"
                              defaultValue={
                                existingCovid19ChecklistChart.data.length > 0
                                  ? existingCovid19ChecklistChart.data[0]
                                      .rank_area
                                  : ""
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography component={"span"} variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Address:
                            </span>{" "}
                            {!enableEdit &&
                              (info
                                ? `${info.street}, ${info.barangay} ${info.city}`
                                : "")}
                          </Typography>

                          <Typography component={"span"}>
                            {enableEdit && (
                              <>
                                <TextField
                                  fullWidth
                                  name="address"
                                  defaultValue={
                                    info
                                      ? `${info.street}, ${info.barangay} ${info.city}`
                                      : ""
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <Box my={1}>
                    <Box display="flex">
                      <Box mt={1}>
                        <Typography
                          component={"span"}
                          style={{ fontWeight: "bold" }}
                        >
                          Case Definition:
                        </Typography>
                      </Box>
                      {!enableEdit &&
                        existingCovid19ChecklistChart.data.length > 0 && (
                          <>
                            <Box mt={1} mx={2}>
                              {existingCovid19ChecklistChart.data?.[0]
                                .case_definition === "noncovid" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                              Non-Covid
                            </Box>
                            <Box mt={1}>
                              {existingCovid19ChecklistChart.data?.[0]
                                .case_definition === "suspect" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                              Suspect
                            </Box>
                            <Box mt={1} mx={2}>
                              {existingCovid19ChecklistChart.data?.[0]
                                .case_definition === "probable" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                              Probable
                            </Box>
                            <Box mt={1}>
                              {existingCovid19ChecklistChart.data?.[0]
                                .case_definition === "confirmed" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                              Confirmed
                            </Box>
                          </>
                        )}

                      {enableEdit && (
                        <>
                          <Box>
                            <Typography
                              component={"span"}
                              variant={"subtitle1"}
                            >
                              <IconButton
                                onClick={() => setCaseDefinition("noncovid")}
                              >
                                {caseDefinition === "noncovid" ? (
                                  <CheckSquare />
                                ) : (
                                  <Square />
                                )}
                              </IconButton>
                              Non-Covid
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component={"span"}
                              variant={"subtitle1"}
                            >
                              <IconButton
                                onClick={() => setCaseDefinition("suspect")}
                              >
                                {caseDefinition === "suspect" ? (
                                  <CheckSquare />
                                ) : (
                                  <Square />
                                )}
                              </IconButton>
                              Suspect
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component={"span"}
                              variant={"subtitle1"}
                            >
                              <IconButton
                                onClick={() => setCaseDefinition("probable")}
                              >
                                {caseDefinition === "probable" ? (
                                  <CheckSquare />
                                ) : (
                                  <Square />
                                )}
                              </IconButton>
                              Probable
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component={"span"}
                              variant={"subtitle1"}
                            >
                              <IconButton
                                onClick={() => setCaseDefinition("confirmed")}
                              >
                                {caseDefinition === "confirmed" ? (
                                  <CheckSquare />
                                ) : (
                                  <Square />
                                )}
                              </IconButton>
                              Confirmed
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>

                    <Box display="flex">
                      <Box mt={1}>
                        <Typography
                          component={"span"}
                          style={{ fontWeight: "bold" }}
                        >
                          Severity Classification:
                        </Typography>
                      </Box>
                      {!enableEdit &&
                        existingCovid19ChecklistChart.data.length > 0 && (
                          <>
                            <Box mt={1} mx={2}>
                              {existingCovid19ChecklistChart.data?.[0]
                                .severity_class === "noncovid" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                              Non-Covid
                            </Box>

                            <Box mt={1}>
                              {existingCovid19ChecklistChart.data?.[0]
                                .severity_class === "asymptomatic" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                              Asymptomatic
                            </Box>

                            <Box mt={1} mx={2}>
                              {existingCovid19ChecklistChart.data?.[0]
                                .severity_class === "mild" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                              Mild
                            </Box>

                            <Box mt={1}>
                              {existingCovid19ChecklistChart.data?.[0]
                                .severity_class === "moderate" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                              Moderate
                            </Box>

                            <Box mt={1} mx={2}>
                              {existingCovid19ChecklistChart.data?.[0]
                                .severity_class === "severe" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                              Severe
                            </Box>

                            <Box mt={1}>
                              {existingCovid19ChecklistChart.data?.[0]
                                .severity_class === "critical" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                              Critical
                            </Box>
                          </>
                        )}

                      {enableEdit && (
                        <>
                          <Box>
                            <Typography
                              component={"span"}
                              variant={"subtitle1"}
                            >
                              <IconButton
                                onClick={() => setSeverityClass("noncovid")}
                              >
                                {severityClass === "noncovid" ? (
                                  <CheckSquare />
                                ) : (
                                  <Square />
                                )}
                              </IconButton>
                              Non-Covid
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              component={"span"}
                              variant={"subtitle1"}
                            >
                              <IconButton
                                onClick={() => setSeverityClass("asymptomatic")}
                              >
                                {severityClass === "asymptomatic" ? (
                                  <CheckSquare />
                                ) : (
                                  <Square />
                                )}
                              </IconButton>
                              Asymptomatic
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component={"span"}
                              variant={"subtitle1"}
                            >
                              <IconButton
                                onClick={() => setSeverityClass("mild")}
                              >
                                {severityClass === "mild" ? (
                                  <CheckSquare />
                                ) : (
                                  <Square />
                                )}
                              </IconButton>
                              Mild
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component={"span"}
                              variant={"subtitle1"}
                            >
                              <IconButton
                                onClick={() => setSeverityClass("moderate")}
                              >
                                {severityClass === "moderate" ? (
                                  <CheckSquare />
                                ) : (
                                  <Square />
                                )}
                              </IconButton>
                              Moderate
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component={"span"}
                              variant={"subtitle1"}
                            >
                              <IconButton
                                onClick={() => setSeverityClass("severe")}
                              >
                                {severityClass === "severe" ? (
                                  <CheckSquare />
                                ) : (
                                  <Square />
                                )}
                              </IconButton>
                              Severe
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component={"span"}
                              variant={"subtitle1"}
                            >
                              <IconButton
                                onClick={() => setSeverityClass("critical")}
                              >
                                {severityClass === "critical" ? (
                                  <CheckSquare />
                                ) : (
                                  <Square />
                                )}
                              </IconButton>
                              Critical
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>

                  <Box my={2}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box mb={1}>
                          <Typography component={"span"} variant="subtitle1">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Disposition/Plan:
                            </span>{" "}
                            {!enableEdit &&
                            existingCovid19ChecklistChart.data.length > 0
                              ? existingCovid19ChecklistChart.data[0]
                                  .disposition_plan
                              : ""}
                          </Typography>
                          {enableEdit && (
                            <TextField
                              fullWidth
                              name="disposition_plan"
                              defaultValue={
                                existingCovid19ChecklistChart.data.length > 0
                                  ? existingCovid19ChecklistChart.data[0]
                                      .disposition_plan
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography component={"span"} variant="subtitle1">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Physician:
                            </span>{" "}
                            {!enableEdit &&
                            existingCovid19ChecklistChart.data.length > 0
                              ? existingCovid19ChecklistChart.data[0].physician
                              : ""}
                          </Typography>
                          {enableEdit && (
                            <TextField
                              fullWidth
                              name="physician"
                              defaultValue={
                                existingCovid19ChecklistChart.data.length > 0
                                  ? existingCovid19ChecklistChart.data[0]
                                      .physician
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box my={1}>
                    <Grid container>
                      <Grid item xs={9}>
                        <Grid container>
                          <Box mb={2}>
                            <Typography component={"span"} variant="subtitle1">
                              <span
                                style={{ color: "red", fontWeight: "bold" }}
                              >
                                STEP 1. SYMPTOM CHECKLIST
                              </span>{" "}
                              <b>(To be filled up by Triage Nurse)</b>
                            </Typography>
                            <Typography
                              component={"span"}
                              variant="subtitle1"
                              style={{ fontWeight: "bold" }}
                            >
                              Any one of the following:
                            </Typography>
                          </Box>
                          {!enableEdit &&
                            existingCovid19ChecklistChart.data.length > 0 && (
                              <>
                                <Grid item xs={6}>
                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Fever") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Fever
                                  </Box>

                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Cough") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Cough
                                  </Box>

                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes(
                                        "Difficulty of breathing/Shortness of breath"
                                      ) ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Difficulty of breathing/Shortness of breath
                                  </Box>

                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Fatigue") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Fatigue
                                  </Box>

                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Myalgia") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Myalgia
                                  </Box>

                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Sore Throat") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Sore Throat
                                  </Box>

                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Diarrhea") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Diarrhea
                                  </Box>
                                </Grid>
                                <Grid item xs={6}>
                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Anorexia") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Anorexia
                                  </Box>

                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Loss of smell") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Loss of smell
                                  </Box>

                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Loss of Taste") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Loss of Taste
                                  </Box>

                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Nasal Congestion") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Nasal Congestion
                                  </Box>

                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Headache") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Headache
                                  </Box>

                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Nausea") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Nausea
                                  </Box>

                                  <Box mt={2}>
                                    {existingCovid19ChecklistChart.data[0]
                                      ?.sickness !== null &&
                                    existingCovid19ChecklistChart.data[0]?.sickness
                                      .replace(",", ", ")
                                      .includes("Vomiting") ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                    Vomiting
                                  </Box>
                                </Grid>
                              </>
                            )}

                          {!enableEdit &&
                            existingCovid19ChecklistChart.data.length > 0 && (
                              <Grid item xs={2}>
                                <Box mt={2}>
                                  {existingCovid19ChecklistChart.data[0]
                                    ?.sickness !== null &&
                                  existingCovid19ChecklistChart.data[0]?.sickness
                                    .replace(",", ", ")
                                    .includes("Others:") ? (
                                    <CheckSquare />
                                  ) : (
                                    <Square />
                                  )}
                                  Others:
                                </Box>
                              </Grid>
                            )}

                          {enableEdit &&
                            sickness.map((data, index) => (
                              <Grid key={index} item xs={index === 14 ? 2 : 6}>
                                <Box>
                                  <Typography
                                    component={"span"}
                                    variant={"subtitle1"}
                                  >
                                    <IconButton
                                      onClick={() => handleChangeSickness(data)}
                                    >
                                      {selectedSickness.includes(data) ? (
                                        <CheckSquare />
                                      ) : (
                                        <Square />
                                      )}
                                    </IconButton>
                                    {data}
                                  </Typography>
                                </Box>
                              </Grid>
                            ))}

                          {!enableEdit &&
                            existingCovid19ChecklistChart.data.length > 0 && (
                              <Grid item xs={10}>
                                <Box mt={2} width="98%">
                                  <Typography component={"span"}>
                                    {
                                      existingCovid19ChecklistChart.data?.[0]
                                        ?.others
                                    }
                                  </Typography>
                                </Box>
                              </Grid>
                            )}

                          {enableEdit && (
                            <Grid item xs={12}>
                              <Box width={"98%"}>
                                <TextField
                                  fullWidth
                                  name="others"
                                  defaultValue={
                                    existingCovid19ChecklistChart.data.length >
                                    0
                                      ? existingCovid19ChecklistChart.data?.[0]
                                          .others
                                      : ""
                                  }
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled:
                                      selectedSickness.includes("Others:") ===
                                      true
                                        ? false
                                        : true,
                                  }}
                                  required={selectedSickness.includes(
                                    "Others:"
                                  )}
                                />
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <Box>
                            <Table size="small" className="table-bordered">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">
                                    <Typography
                                      component={"span"}
                                      variant="subtitle1"
                                    >
                                      <b>Vital Signs</b>
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      component={"span"}
                                      variant="subtitle1"
                                    >
                                      <span
                                        style={{
                                          color: "#000",
                                        }}
                                      >
                                        Temp:
                                      </span>{" "}
                                      {!enableEdit &&
                                      existingCovid19ChecklistChart.data
                                        .length > 0
                                        ? existingCovid19ChecklistChart.data[0]
                                            .temp
                                        : ""}
                                    </Typography>
                                    {enableEdit && (
                                      <TextField
                                        fullWidth
                                        name="temp"
                                        type="number"
                                        defaultValue={
                                          existingCovid19ChecklistChart.data
                                            .length > 0
                                            ? existingCovid19ChecklistChart
                                                .data[0].temp
                                            : ""
                                        }
                                        InputProps={{
                                          disableUnderline: !enableEdit,
                                          disabled: !enableEdit,
                                          inputProps: {
                                            min: 1,
                                            max: 10000,
                                            step: 0.01,
                                          },
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      component={"span"}
                                      variant="subtitle1"
                                    >
                                      <span
                                        style={{
                                          color: "#000",
                                        }}
                                      >
                                        BP:
                                      </span>{" "}
                                      {!enableEdit &&
                                      existingCovid19ChecklistChart.data
                                        .length > 0
                                        ? existingCovid19ChecklistChart.data[0]
                                            .bp
                                        : ""}
                                    </Typography>
                                    {enableEdit && (
                                      <TextField
                                        fullWidth
                                        name="bp"
                                        defaultValue={
                                          existingCovid19ChecklistChart.data
                                            .length > 0
                                            ? existingCovid19ChecklistChart
                                                .data[0].bp
                                            : ""
                                        }
                                        InputProps={{
                                          disableUnderline: !enableEdit,
                                          disabled: !enableEdit,
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      component={"span"}
                                      variant="subtitle1"
                                    >
                                      <span
                                        style={{
                                          color: "#000",
                                        }}
                                      >
                                        RR:
                                      </span>{" "}
                                      {!enableEdit &&
                                      existingCovid19ChecklistChart.data
                                        .length > 0
                                        ? existingCovid19ChecklistChart.data[0]
                                            .rr
                                        : ""}
                                    </Typography>
                                    {enableEdit && (
                                      <TextField
                                        fullWidth
                                        name="rr"
                                        type="number"
                                        defaultValue={
                                          existingCovid19ChecklistChart.data
                                            .length > 0
                                            ? existingCovid19ChecklistChart
                                                .data[0].rr
                                            : ""
                                        }
                                        InputProps={{
                                          disableUnderline: !enableEdit,
                                          disabled: !enableEdit,
                                          inputProps: {
                                            min: 1,
                                            max: 10000,
                                            step: 0.01,
                                          },
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      component={"span"}
                                      variant="subtitle1"
                                    >
                                      <span
                                        style={{
                                          color: "#000",
                                        }}
                                      >
                                        HR:
                                      </span>{" "}
                                      {!enableEdit &&
                                      existingCovid19ChecklistChart.data
                                        .length > 0
                                        ? existingCovid19ChecklistChart.data[0]
                                            .hr
                                        : ""}
                                    </Typography>
                                    {enableEdit && (
                                      <TextField
                                        fullWidth
                                        name="hr"
                                        type="number"
                                        defaultValue={
                                          existingCovid19ChecklistChart.data
                                            .length > 0
                                            ? existingCovid19ChecklistChart
                                                .data[0].hr
                                            : ""
                                        }
                                        InputProps={{
                                          disableUnderline: !enableEdit,
                                          disabled: !enableEdit,
                                          inputProps: {
                                            min: 1,
                                            max: 10000,
                                            step: 0.01,
                                          },
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      component={"span"}
                                      variant="subtitle1"
                                    >
                                      <span
                                        style={{
                                          color: "#000",
                                        }}
                                      >
                                        O2 Sat:
                                      </span>{" "}
                                      {!enableEdit &&
                                      existingCovid19ChecklistChart.data
                                        .length > 0
                                        ? existingCovid19ChecklistChart.data[0]
                                            .o2_sat
                                        : ""}
                                    </Typography>
                                    {enableEdit && (
                                      <TextField
                                        fullWidth
                                        name="o2_sat"
                                        type="number"
                                        defaultValue={
                                          existingCovid19ChecklistChart.data
                                            .length > 0
                                            ? existingCovid19ChecklistChart
                                                .data[0].o2_sat
                                            : ""
                                        }
                                        InputProps={{
                                          disableUnderline: !enableEdit,
                                          disabled: !enableEdit,
                                          inputProps: {
                                            min: 1,
                                            max: 10000,
                                            step: 0.01,
                                          },
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      component={"span"}
                                      variant="subtitle1"
                                    >
                                      <span
                                        style={{
                                          color: "#000",
                                        }}
                                      >
                                        Weight:
                                      </span>{" "}
                                      {!enableEdit &&
                                      existingCovid19ChecklistChart.data
                                        .length > 0
                                        ? existingCovid19ChecklistChart.data[0]
                                            .weight
                                        : ""}
                                    </Typography>
                                    {enableEdit && (
                                      <TextField
                                        fullWidth
                                        name="weight"
                                        defaultValue={
                                          existingCovid19ChecklistChart.data
                                            .length > 0
                                            ? existingCovid19ChecklistChart
                                                .data[0].weight
                                            : ""
                                        }
                                        InputProps={{
                                          disableUnderline: !enableEdit,
                                          disabled: !enableEdit,
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      component={"span"}
                                      variant="subtitle1"
                                    >
                                      <span
                                        style={{
                                          color: "#000",
                                        }}
                                      >
                                        Height:
                                      </span>{" "}
                                      {!enableEdit &&
                                      existingCovid19ChecklistChart.data
                                        .length > 0
                                        ? existingCovid19ChecklistChart.data[0]
                                            .height
                                        : ""}
                                    </Typography>
                                    {enableEdit && (
                                      <TextField
                                        fullWidth
                                        name="height"
                                        defaultValue={
                                          existingCovid19ChecklistChart.data
                                            .length > 0
                                            ? existingCovid19ChecklistChart
                                                .data[0].height
                                            : ""
                                        }
                                        InputProps={{
                                          disableUnderline: !enableEdit,
                                          disabled: !enableEdit,
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      component={"span"}
                                      variant="subtitle1"
                                    >
                                      <span
                                        style={{
                                          color: "#000",
                                        }}
                                      >
                                        BMI:
                                      </span>{" "}
                                      {!enableEdit &&
                                      existingCovid19ChecklistChart.data
                                        .length > 0
                                        ? existingCovid19ChecklistChart.data[0]
                                            .bmi
                                        : ""}
                                    </Typography>
                                    {enableEdit && (
                                      <TextField
                                        fullWidth
                                        name="bmi"
                                        defaultValue={
                                          existingCovid19ChecklistChart.data
                                            .length > 0
                                            ? existingCovid19ChecklistChart
                                                .data[0].bmi
                                            : ""
                                        }
                                        InputProps={{
                                          disableUnderline: !enableEdit,
                                          disabled: !enableEdit,
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </form>
          </Box>

          <Box className="next-page" />

          <Box>
            <CSQMMHCovid19CheckListP2 />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default Covid19TriageChecklistForm;
