import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  Button,
  Dialog,
  Zoom,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Print from "@material-ui/icons/Print";
import PrintExpense from "./print/PrintExpense";
import { CancelOutlined, PlaylistAddCheck } from "@material-ui/icons";
import PrintExpenseLayout from "./print/PrintExpenseLayout";

const ExpenseDetailsList = ({ selectedVoucher, close }) => {
  const [expenseDetails, setExpenseDetails] = useState({
    data: [],
    ready: false,
  });
  const [printDialog, setPrintDialog] = useState(false);
  const [totalExpense, setTotalExpense] = useState(0);

  const [printLayoutDialog, setPrintLayoutDialog] = useState(false);

  const getDetailsVoucher = useCallback(async () => {
    var params = { expense_main_id: selectedVoucher };
    try {
      let response = await axios.get("accounting/get/expense-details-by-id", {
        params,
      });
      const data = response.data;
      setExpenseDetails({ data, ready: true });
      setTotalExpense(calculateData(data));
    } catch (error) {
      console.log("error: ", error);
    }
  }, [selectedVoucher]);

  const calculateData = (data) => {
    var total = 0;
    for (let i = 0; i < data.length; i++) {
      total += data[i].quantity * data[i].unit_price;
    }
    return total;
  };

  useEffect(() => {
    getDetailsVoucher();
  }, [getDetailsVoucher]);
  return (
    <Box>
      <Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box flexGrow={1}>
            <Typography
              color="primary"
              variant={"h4"}
              style={{ fontWeight: "bolder" }}
            >
              {expenseDetails.data.length > 0 && expenseDetails.data[0].voucher}
            </Typography>
          </Box>
          <Box mr={1}>
            <ReactHTMLTableToExcel
              className="btn btn-info"
              table="expense-report"
              filename="Expense Report"
              sheet="Expense Report"
              buttonText="Download Excel"
            />
          </Box>
          <Box mr={1}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Print />}
              style={{ height: 38 }}
              onClick={() => setPrintDialog(true)}
            >
              Print
            </Button>
          </Box>
          <Box mr={1}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlaylistAddCheck />}
              style={{ height: 38 }}
              onClick={() => setPrintLayoutDialog(true)}
            >
              Layout
            </Button>
          </Box>
          <Box>
            <IconButton color="secondary" onClick={() => close()}>
              <CancelOutlined />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <TableContainer>
            <Table id="expense-report">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    INVOICE DATE
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    VOUCHER
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    DESCRIPTION
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    TIN
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    QUANTITY
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    UNIT PRICE
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    TAX
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    AMOUNT
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expenseDetails.ready ? (
                  expenseDetails.data.length > 0 ? (
                    expenseDetails.data.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {Notify.createdAt(data.invoice_date)}
                        </TableCell>
                        <TableCell>{data.voucher}</TableCell>
                        <TableCell>{data.description}</TableCell>
                        <TableCell>{data.tin}</TableCell>
                        <TableCell align="right">{data.quantity}</TableCell>
                        <TableCell align="right">
                          {Notify.convertToNumber(data.unit_price)}
                        </TableCell>
                        <TableCell>{data.tax}</TableCell>
                        <TableCell align="right">
                          {Notify.convertToNumber(data.amount)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography color="primary">
                        Loading, please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7} />
                  <TableCell align="right">
                    {Notify.convertToNumber(totalExpense)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Dialog
        open={printDialog}
        onClose={() => setPrintDialog(false)}
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={800}
      >
        <PrintExpense
          expenseDetails={expenseDetails}
          close={() => setPrintDialog(false)}
        />
      </Dialog>

      <Dialog
        open={printLayoutDialog}
        onClose={() => setPrintLayoutDialog(false)}
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={800}
      >
        <PrintExpenseLayout close={() => setPrintLayoutDialog(false)} />
      </Dialog>
    </Box>
  );
};

export default ExpenseDetailsList;
