import { useEffect, useRef, useState } from "react";
import CisPatientAppointmentItem from "src/cis-doctor/components/CisPatientAppointmentItem";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import ButtonV3 from "src/components/ButtonV3";
import FlatIcon from "src/components/FlatIcon";
import CisAcceptPatientModal from "./modals/CisAcceptPatientModal";
import { HashLoader } from "react-spinners";
import MovePatientToPACUModal from "./modals/MovePatientToPACUModal";
import axios from "axios";
import { dateYYYYMMDD } from "src/helpers/utils";
import MovePatientToDoneModal from "./modals/MovePatientToDoneModal";

const Visits2 = ({ room_id }) => {
	const { clinic } = useClinicContext();
	const [rooms, setRooms] = useState([]);
	const [ORPatients, setORPatients] = useState({
		loading: false,
		data: [],
	});
	const [PACUPatients, setPACUPatients] = useState({
		loading: false,
		data: [],
	});
	const [DONEPatients, setDONEPatients] = useState({
		loading: false,
		data: [],
	});
	const move_to_pacu_modal = useRef(null);
	const move_to_done_modal = useRef(null);

	useEffect(() => {
		let interval = null;
		if (clinic?.id > 0) {
			getPatients("operating_room", setORPatients);
			getPatients("resu", setPACUPatients);
			getPatients("done", setDONEPatients);

			setORPatients((prevData) => ({
				...prevData,
				loading: true,
			}));
			setPACUPatients((prevData) => ({
				...prevData,
				loading: true,
			}));
			interval = setInterval(() => {
				getPatients("operating_room", setORPatients);
				getPatients("resu", setPACUPatients);
				getPatients("done", setDONEPatients);
			}, 4000);
		}
		return () => {
			if (interval) clearInterval(interval);
		};
	}, [clinic.id]);

	const getPatients = (type, setter) => {
		axios
			.get(
				`/v1/operating-room-chart/list?clinic_id=${
					clinic?.id
				}&status=${type}&date_from=${dateYYYYMMDD()}&date_to=${dateYYYYMMDD()}&time_from=00:00&time_to=24:00`
			)
			.then((res) => {
				console.log("resssss", res.data.data);
				setter({
					loading: false,
					data: res.data.data || [],
				});
			});
	};

	return (
		<>
			<div className="grid grid-cols-1 lg:grid-cols-12 gap-6 pb-4">
				<div className="lg:col-span-4 flex flex-col gap-y-4">
					<div className="rounded-xl bg-slate-300 text-darker p-3 text-lg font-bold flex items-center gap-2">
						<FlatIcon icon="rr-procedures" />
						<span className="mr-auto">Operating Room</span>
					</div>
					{ORPatients?.loading ? (
						<span className="flex items-center justify-center animate-pulse text-placeholder text-lg text-center w-full">
							<HashLoader
								size={16}
								className=" fill-slate-400 opacity-40 animate-spin animate-pulse mr-2"
							/>{" "}
							Loading...
						</span>
					) : (
						""
					)}
					{ORPatients?.data?.map((item) => {
						let patient = item?.relationships || null;
						return (
							<CisPatientAppointmentItem
								key={`orp-${item?.id}`}
								data={item}
								onClick={() => {
									move_to_pacu_modal.current.show(item);
								}}
								patient={{
									...patient?.patient,
									firstname: patient?.patient?.firstname,
									lastname: patient?.patient?.lastname,
									middle: patient?.patient?.middle,
									middlename: patient?.patient?.middle,
									birthday: patient?.patient?.birthday,
									gender: patient?.patient?.gender,
									reason: patient?.purpose,
								}}
							/>
						);
					})}

					{ORPatients?.loading == false &&
					ORPatients?.data?.length == 0 ? (
						<span className="text-placeholder text-lg text-center w-full">
							No patients to display.
						</span>
					) : (
						""
					)}
				</div>
				<div className="lg:col-span-4 flex flex-col gap-y-4">
					<div className="rounded-xl bg-blue-100 text-blue-700 p-3 text-lg font-bold flex items-center gap-2">
						<FlatIcon icon="rr-pulse" />
						<span className="mr-auto">
							PACU (Post Anesthesia Care Unit){" "}
						</span>
					</div>{" "}
					{PACUPatients?.loading ? (
						<span className="flex items-center justify-center animate-pulse text-placeholder text-lg text-center w-full">
							<HashLoader
								size={16}
								className=" fill-slate-400 opacity-40 animate-spin animate-pulse mr-2"
							/>{" "}
							Loading...
						</span>
					) : (
						""
					)}
					{PACUPatients?.data?.map((item) => {
						let patient = item?.relationships || null;
						return (
							<CisPatientAppointmentItem
								key={`orp-${item?.id}`}
								data={item}
								onClick={() => {
									move_to_done_modal.current.show(item);
								}}
								patient={{
									...patient?.patient,
									firstname: patient?.patient?.firstname,
									lastname: patient?.patient?.lastname,
									middle: patient?.patient?.middle,
									middlename: patient?.patient?.middle,
									birthday: patient?.patient?.birthday,
									gender: patient?.patient?.gender,
									reason: patient?.purpose,
								}}
							/>
						);
					})}
					{PACUPatients?.loading == false &&
					PACUPatients?.data?.length == 0 ? (
						<span className="text-placeholder text-lg text-center w-full">
							No patients to display.
						</span>
					) : (
						""
					)}
				</div>
				<div className="lg:col-span-4 flex flex-col gap-y-4">
					<div className="rounded-xl bg-green-100 text-green-700 p-3 text-lg font-bold flex items-center gap-2">
						<FlatIcon icon="rr-memo-circle-check" />
						<span className="mr-auto">Done (back to room)</span>
					</div>
					{DONEPatients?.loading ? (
						<span className="flex items-center justify-center animate-pulse text-placeholder text-lg text-center w-full">
							<HashLoader
								size={16}
								className=" fill-slate-400 opacity-40 animate-spin animate-pulse mr-2"
							/>{" "}
							Loading...
						</span>
					) : (
						""
					)}
					{DONEPatients?.data?.map((item) => {
						let patient = item?.relationships || null;
						return (
							<CisPatientAppointmentItem
								key={`orp-${item?.id}`}
								data={item}
								patient={{
									...patient?.patient,
									firstname: patient?.patient?.firstname,
									lastname: patient?.patient?.lastname,
									middle: patient?.patient?.middle,
									middlename: patient?.patient?.middle,
									birthday: patient?.patient?.birthday,
									gender: patient?.patient?.gender,
									reason: patient?.purpose,
								}}
							/>
						);
					})}
					{DONEPatients?.loading == false &&
					DONEPatients?.data?.length == 0 ? (
						<span className="text-placeholder text-lg text-center w-full">
							No patients to display.
						</span>
					) : (
						""
					)}
					{/* {clinic?.id == null ? (
						<span className="flex items-center justify-center animate-pulse text-placeholder text-lg text-center w-full">
							<HashLoader
								size={16}
								className=" fill-slate-400 opacity-40 animate-spin animate-pulse mr-2"
							/>{" "}
							Loading...
						</span>
					) : donePatients?.length == 0 ? (
						<span className="text-placeholder text-lg text-center w-full">
							No patients to display.
						</span>
					) : (
						""
					)} */}
				</div>
			</div>
			<MovePatientToPACUModal ref={move_to_pacu_modal} />
			<MovePatientToDoneModal ref={move_to_done_modal} />
		</>
	);
};

export default Visits2;
