import {
  Grid,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import IsMountedRef from "src/utils/IsMountedRef";

const BedsList = ({ details, setDialog, dialog }) => {
  const mounted = IsMountedRef();
  const [bedsList, setBedsList] = useState([]);
  const [bedsListRd, setBedsListRd] = useState(false);

  const getBedsList = React.useCallback(() => {
    setBedsList(false);
    axios
      .get(`admitting/rooms/get-beds-bynumber`, {
        params: {
          room_id: details?.room_id,
          room_number: details?.room_number,
          management_id: details?.management_id,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setBedsList(data);
        }
      })
      .catch((err) => console.log(`err:`, err))
      .finally(() => setBedsListRd(true));
  }, [details, mounted]);

  useEffect(() => {
    getBedsList();
  }, [getBedsList]);

  return (
    <>
      <DialogTitle> Select bed in list </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {bedsListRd ? (
            bedsList.length > 0 ? (
              bedsList.map((data, key) => (
                <Grid item xs={6} sm={4} md={3} key={key}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setDialog({
                        ...dialog,
                        selectedBedNo: data,
                        open: false,
                      });
                    }}
                    disabled={Boolean(data._use_room_count)}
                  >
                    <Typography> {data.bed_number}</Typography>
                  </Button>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography color="secondary"> No bed found. </Typography>
              </Grid>
            )
          ) : (
            <Grid item xs={12}>
              <Typography align="center" color="primary">
                Loading...
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </>
  );
};

export default BedsList;
