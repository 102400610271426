import React, {
	useEffect,
	useState,
	// Fragment
} from "react";
import {
	Paper,
	Box,
	Grid,
	Typography,
	Button,
	IconButton,
	// List,
	// ListItem,
	// ListItemIcon,
	// ListItemText,
	// Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	CircularProgress,
	// TablePagination,
	useTheme,
	TableContainer,
	TableBody,
	TableRow,
	TableCell,
	Table,
} from "@material-ui/core";
import axios from "axios";
import Notify from "src/notification/Notify";
// import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
// import InboxIcon from "@material-ui/icons/Inbox";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { UsersData } from "src/ContextAPI";
import Select from "react-select";

const CashierCreateLabOrder = ({
	patient_id,
	getPatientBillingDetails,
	trace_number,
}) => {
	const { users } = React.useContext(UsersData);

	const [deptDetails, setDeptDetails] = useState({
		data: [],
		ready: false,
	});

	const [unsaveOrder, setUnsaveOrder] = useState({
		data: [],
		ready: false,
	});

	const [cancelDialog, setCancelDialog] = useState({
		open: false,
		removeid: null,
	});

	const [addProcess, setAddProcess] = useState(null);

	const [cancelProcess, setCancelProcess] = useState(false);

	const [processLab, setProcessLab] = useState(false);

	const [labProcess, setLabProcess] = useState(false);

	// const [enableSpicemenFieldMCT, setEnableSpicemenFieldMCT] = useState(false);
	// const [enableSpicemenFieldCCT, setEnableSpicemenFieldCCT] = useState(false);

	const getDeptDetail = () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("management_id", users.management_id);

		axios
			.post("cashier/laboratory/new/order/department-details", formdata)
			.then((response) => {
				const data = response.data;
				setDeptDetails({
					data: data,
					ready: true,
				});
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getUnsaveOrder = () => {
		axios
			.get("admission/laboratory/new/order/unsave-orderlist", {
				params: {
					patient_id: patient_id,
					user_id: users.user_id,
					management_id: users.management_id,
				},
			})
			.then((response) => {
				const data = response.data;
				setUnsaveOrder({
					data: data,
					ready: true,
				});
				// setEnableSpicemenFieldMCT(Boolean(parseInt(checkCMOrder(data)))); // checkUnsaveIfContainsClinicalMicroscopyTestAndEnableSpicemenField
				// setEnableSpicemenFieldCCT(Boolean(parseInt(checkCCTOrder(data)))); // checkUnsaveIfContainsClinicalChemistryTestAndEnableSpicemenField
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const addLabOrder = (orderdetails) => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set("trace_number", trace_number);
		formdata.set("management_id", users.management_id);
		formdata.set("main_mgmt_id", users.main_mgmt_id);
		formdata.set("department", orderdetails.category);
		formdata.set("laboratory_test_id", orderdetails.order_id);
		formdata.set("laboratory_test", orderdetails.laborder);
		formdata.set("laboratory_rate", orderdetails.rate);
		setAddProcess(orderdetails.order_id);

		axios
			.post("cashier/laboratory/new/order/order-addtounsave", formdata)
			.then((response) => {
				const data = response.data;
				if (data === "success") {
					getUnsaveOrder();
					Notify.successRequest("Laboratory Order Added.");
				}
			})
			.catch((error) => {
				Notify.requestError(error);
			})
			.finally(() => setAddProcess(null));
	};

	const cancelOrder = (removeid) => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set("removeid", removeid);
		setCancelProcess(true);
		axios
			.post("cashier/laboratory/new/order/order-cancel", formdata)
			.then((response) => {
				const data = response.data;
				if (data === "success") {
					getUnsaveOrder();
					setCancelDialog({ open: false, removeid: null });
					Notify.successRequest("Laboratory Order Cancel.");
				}
			})
			.catch((error) => {
				Notify.requestError(error);
			})
			.finally(() => setCancelProcess(false));
	};

	// const checkCMOrder = (data) => {
	//   var count = 0;
	//   for (var i = 0; i < data.length; i++) {
	//     if (data[i].department === "clinical-microscopy") {
	//       count += 1;
	//     }
	//   }
	//   return count;
	// };

	// const checkCCTOrder = (data) => {
	//   var count = 0;
	//   for (var i = 0; i < data.length; i++) {
	//     if (data[i].department === "clinical-chemistry") {
	//       count += 1;
	//     }
	//   }
	//   return count;
	// };

	const handleProcessLab = (e) => {
		e.preventDefault();
		e.persist();

		// var trace_number = `trace-${Math.floor(
		//   Math.random() * 9999
		// )}-${new Date().getTime()}`;

		var formdata = new FormData(e.target);
		formdata.append("user_id", users.user_id);
		formdata.append("username", users.username);
		formdata.append("patient_id", patient_id);
		formdata.set("management_id", users.management_id);
		formdata.set("main_mgmt_id", users.main_mgmt_id);
		formdata.append("trace_number", trace_number);

		var error = [];

		// if (enableSpicemenFieldMCT) {
		//   if (
		//     formdata.get("mc_spicemen").length === 0 ||
		//     formdata.get("mc_spicemen").trim() === ""
		//   ) {
		//     error = "error";
		//     Notify.fieldRequired("Clinical Microscopy Specimen");
		//   }
		// }

		// if (enableSpicemenFieldCCT) {
		//   if (
		//     formdata.get("cc_specimen").length === 0 ||
		//     formdata.get("cc_specimen").trim() === ""
		//   ) {
		//     error = "error";
		//     Notify.fieldRequired("Clinical Chemistry Specimen");
		//   }
		// }

		if (
			formdata.get("password").length === 0 ||
			formdata.get("password").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("password");
		}

		if (
			formdata.get("remarks").length === 0 ||
			formdata.get("remarks").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("remarks");
		}

		if (error.length > 0) {
			console.warn("Form error. fix to continue");
		} else {
			setLabProcess(true);
			axios
				.post("cashier/laboratory/new/order/process-laborder", formdata)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						getUnsaveOrder();
						getPatientBillingDetails();
						setProcessLab(false);
						Notify.successRequest("Laboratory Order Process.");
					}
					if (data === "pass-invalid") {
						Notify.fieldInvalid("password");
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => setLabProcess(false));
		}
	};

	useEffect(() => {
		getDeptDetail();
		getUnsaveOrder();

		// eslint-disable-next-line
	}, [patient_id]);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} md={6} lg={4}>
					<Paper component={Box} elevation={2} p={2} mb={2}>
						<Box mb={2}>
							<Typography variant="h6" color="textSecondary">
								Select Order
							</Typography>
						</Box>

						<Box>
							<RenderAvailableTest
								addProcess={addProcess}
								deptDetails={deptDetails}
								addLabOrder={addLabOrder}
							/>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12} sm={12} md={6} lg={8}>
					<Paper component={Box} elevation={2} p={2} mb={2}>
						<Box mb={2}>
							<Typography variant="h6" color="textSecondary">
								Unsave Laboratory Order
							</Typography>
						</Box>

						<Box mb={1}>
							<TableContainer>
								<Table>
									<TableBody>
										{unsaveOrder.ready ? (
											unsaveOrder.data.length > 0 ? (
												unsaveOrder.data.map((data, index) => (
													<TableRow key={index}>
														<TableCell style={{ textTransform: "capitalize" }}>
															<b style={{ fontSize: 16 }}>
																{data.laboratory_test}
															</b>
															<br />
															{data.department}
														</TableCell>
														<TableCell
															align="right"
															style={{ fontWeight: "bolder" }}
														>
															&#8369;{" "}
															{Notify.convertToNumber(data.laboratory_rate)}
														</TableCell>
														<TableCell align="center">
															<IconButton
																color="secondary"
																onClick={() => {
																	setCancelDialog({
																		open: true,
																		removeid: data.id,
																	});
																}}
															>
																<DeleteSweepIcon />
															</IconButton>
														</TableCell>
													</TableRow>
												))
											) : (
												<TableRow>
													<TableCell colSpan={3}>{Notify.noRecord()}</TableCell>
												</TableRow>
											)
										) : (
											<TableRow>
												<TableCell colSpan={3}>{Notify.loading()}</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>

							{/* <List>
                {unsaveOrder.ready ? (
                  unsaveOrder.data.length > 0 ? (
                    unsaveOrder.data.map((data, index) => (
                      <Fragment key={index}>
                        <ListItem
                          divider={unsaveOrder.data.length !== index + 1}
                        >
                          <ListItemText
                            className={`gtc-capitalize`}
                            primary={
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <Typography>
                                    {data.laboratory_test}
                                  </Typography>
                                </Box>
                                <Box flexGrow={1}>
                                  <Typography>
                                    <b> &#8369; {data.laboratory_rate} </b>
                                  </Typography>
                                </Box>
                              </Box>
                            }
                            secondary={data.department}
                          />
                          <ListItemIcon>
                            <Tooltip arrow title="Cancel Order">
                              <IconButton
                                color="secondary"
                                onClick={() => {
                                  setCancelDialog({
                                    open: true,
                                    removeid: data.id,
                                  });
                                }}
                              >
                                <DeleteSweepIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemIcon>
                        </ListItem>
                      </Fragment>
                    ))
                  ) : (
                    <Box display="flex" justifyContent="center" align="center">
                      <Box>
                        <InboxIcon fontSize={"large"} color="secondary" />
                        <Typography variant="subtitle2" color="secondary">
                          No unsave order found.
                        </Typography>
                      </Box>
                    </Box>
                  )
                ) : (
                  <Box>{Notify.loading()}</Box>
                )}
              </List> */}
						</Box>
						<Box display={"flex"}>
							<Box flexGrow={1} />
							<Box>
								<Button
									hidden={unsaveOrder.data.length === 0}
									size="large"
									variant="contained"
									color="primary"
									onClick={() => setProcessLab(true)}
								>
									Process Order
								</Button>
							</Box>
						</Box>
					</Paper>
				</Grid>
			</Grid>

			{/* confirm cancel dialog render */}
			{cancelDialog.open && cancelDialog.removeid && (
				<CancelOrderConfirmation
					cancelOrder={cancelOrder}
					removeid={cancelDialog.removeid}
					open={cancelDialog.open}
					close={() => setCancelDialog({ open: false, removeid: null })}
					cancelProcess={cancelProcess}
				/>
			)}

			{/* process laboratory dialog */}
			<Dialog
				open={processLab}
				onClose={() => setProcessLab(false)}
				disableBackdropClick
				disableEscapeKeyDown
				maxWidth={"xs"}
				fullWidth
			>
				<form onSubmit={handleProcessLab}>
					<DialogTitle>Process Order</DialogTitle>
					<DialogContent dividers>
						{/* {enableSpicemenFieldMCT && (
              <Box>
                <TextField
                  label="Clinical Microscopy Specimen"
                  margin="normal"
                  variant="outlined"
                  name="mc_spicemen"
                  fullWidth
                />
              </Box>
            )}
            {enableSpicemenFieldCCT && (
              <Box>
                <TextField
                  label="Clinical Chemistry Specimen"
                  margin="normal"
                  variant="outlined"
                  name="cc_specimen"
                  fullWidth
                />
              </Box>
            )} */}
						<Box>
							<TextField
								defaultValue="Order is available, Now processing..."
								label="Order Remarks"
								margin="normal"
								variant="outlined"
								name="remarks"
								multiline
								fullWidth
								rows={4}
							/>
						</Box>
						<Box>
							<TextField
								label="Password"
								margin="normal"
								variant="outlined"
								name="password"
								type="password"
								fullWidth
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							color="default"
							variant="contained"
							onClick={() => setProcessLab(false)}
							startIcon={<HighlightOffIcon />}
						>
							No
						</Button>
						<Button
							color="primary"
							variant="contained"
							type="submit"
							disabled={labProcess}
							startIcon={
								labProcess ? (
									<CircularProgress color="inherit" size={20} />
								) : (
									<CheckCircleOutlineIcon />
								)
							}
						>
							Process
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default CashierCreateLabOrder;

const RenderAvailableTest = ({ deptDetails, addLabOrder, addProcess }) => {
	// const [search, setSearch] = useState("");
	const theme = useTheme();
	// const [page, setPage] = useState(0);
	// const [rowsPerPage, setRowsPerPage] = useState(5);

	// const handleChangePage = (event, newPage) => {
	//   event.persist();
	//   setPage(newPage);
	// };

	// const handleChangeRowsPerPage = (event) => {
	//   setRowsPerPage(parseInt(event.target.value, 10));
	//   setPage(0);
	// };

	// const searchable = deptDetails.data.filter((data) => {
	//   return (
	//     data.laborder.toLowerCase().indexOf(search.trim()) !== -1 ||
	//     data.category.toLowerCase().indexOf(search.trim()) !== -1
	//   );
	// });

	return (
		<>
			{/* <Box>
        <TextField
          label="Search Order"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          fullWidth
        />
      </Box> */}

			<Box>
				<Select
					options={deptDetails.data}
					onChange={(data) => addLabOrder(data)}
					styles={{
						menu: (provided) => ({
							...provided,
							zIndex: 99999,
							color: "#000",
						}),
						control: (base) => ({
							...base,
							minHeight: 55,
							backgroundColor: "transparent",
							color: theme.palette.type === "dark" ? "#fff" : "#fff",
						}),
						singleValue: () => ({
							color: theme.palette.type === "dark" ? "#fff" : "#000",
						}),
					}}
				/>
			</Box>

			{/* <List>
        {deptDetails.data.length > 0 ? (
          (rowsPerPage > 0
            ? searchable.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : searchable
          ).map((data, index) => (
            <Fragment key={index}>
              <ListItem divider={deptDetails.data.length !== index + 1}>
                <ListItemText
                  className={`gtc-capitalize`}
                  primary={data.laborder}
                  secondary={data.category}
                />
                <ListItemIcon>
                  <IconButton
                    color="primary"
                    disabled={addProcess === data.order_id}
                    onClick={() => {
                      addLabOrder(data);
                    }}
                  >
                    {addProcess === data.order_id ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <PlaylistAddIcon />
                    )}
                  </IconButton>
                </ListItemIcon>
              </ListItem>
            </Fragment>
          ))
        ) : (
          <Typography align="center" color="secondary" variant="subtitle2">
            No order found
          </Typography>
        )}
      </List>
      <TablePagination
        labelRowsPerPage="List"
        rowsPerPageOptions={[5, 20, 50, 100]}
        component="div"
        count={searchable.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
		</>
	);
};

const CancelOrderConfirmation = ({
	cancelProcess,
	cancelOrder,
	open,
	close,
	removeid,
}) => (
	<Dialog open={open} onClose={close}>
		<DialogContent>
			<Box>
				<Typography>Are you sure to cancel this order?</Typography>
			</Box>
		</DialogContent>

		<DialogActions>
			<Button
				variant="contained"
				color="default"
				onClick={close}
				startIcon={<HighlightOffIcon />}
			>
				No
			</Button>
			<Button
				variant="contained"
				color="secondary"
				onClick={() => cancelOrder(removeid)}
				disabled={cancelProcess}
				startIcon={
					cancelProcess ? (
						<CircularProgress color="inherit" size={20} />
					) : (
						<DeleteSweepIcon />
					)
				}
			>
				Yes
			</Button>
		</DialogActions>
	</Dialog>
);
