import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import {
	collectors,
	garbage_collection,
	often_options,
} from "src/helpers/responseChoices";

const WasteManagementForm = ({
	Controller,
	register,
	errors,
	setValue,
	control,
	watch,
}) => {
	const oftengarbage = [
		{ id: 1, name: "Daily" },

		{ id: 2, name: "Thrice a week" },

		{ id: 3, name: "Twice a week" },

		{ id: 4, name: "Once a week" },

		{ id: 5, name: "Others, specify" },
	];

	return (
		<div className="flex flex-col gap-y-8 patient-form">
			<FormControl className="w-full">
				<FormGroup>
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.garbage_disposal}
					>
						M1. What is the system of garbage disposal adopted by
						the household?
						<span className="text-danger ml-2">*</span>
					</FormLabel>

					{errors?.garbage_disposal && (
						<FormHelperText error={errors?.garbage_disposal}>
							This field is required.
						</FormHelperText>
					)}
					<div className="flex flex-col gap-y-3 py-3">
						{garbage_collection.map((data, index) => {
							return (
								<label className="flex items-center gap-3">
									<span className="scale-[1.15]">
										<input
											type="checkbox"
											value={data.value}
											{...register("garbage_disposal", {
												required: true,
											})}
										/>
									</span>
									<span className="text-sm form-label">
										{data.label}
									</span>
								</label>
							);
						})}
					</div>
				</FormGroup>
			</FormControl>
			<div className="flex flex-col gap-6">
				<FormControl className="">
					<FormGroup className="w-full">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.collector}
						>
							M3. Who collects the garbage?
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.collector && (
							<FormHelperText error={errors?.collector}>
								This field is required.
							</FormHelperText>
						)}

						<div className="flex flex-col gap-y-3 py-3">
							{collectors.map((data, index) => {
								return (
									<label className="flex items-center gap-3">
										<span className="scale-[1.15]">
											<input
												type="checkbox"
												value={data.value}
												{...register("collector", {
													required: true,
												})}
											/>
										</span>
										<span className="text-sm form-label">
											{data.label}
										</span>
									</label>
								);
							})}
						</div>
					</FormGroup>
				</FormControl>
				<FormControl className="">
					<FormGroup className="w-full">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.often_garbage}
						>
							M5. How often is the garbage collected?
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.often_garbage && (
							<FormHelperText error={errors?.often_garbage}>
								This field is required.
							</FormHelperText>
						)}
						<div className="flex flex-col gap-y-3 py-3">
							{often_options.map((data, index) => {
								return (
									<label className="flex items-center gap-3">
										<span className="scale-[1.15]">
											<input
												type="checkbox"
												value={data.value}
												{...register("often_garbage", {
													required: true,
												})}
											/>
										</span>
										<span className="text-sm form-label">
											{data.label}
										</span>
									</label>
								);
							})}
						</div>
					</FormGroup>
				</FormControl>
			</div>
			<TextField
				type="number"
				className="lg:w-1/2"
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={
					<>
						M7. Estimated Volume of Waste (Kg/Month){" "}
						<span className="text-danger ml-2">*</span>
					</>
				}
				variant="outlined"
				{...register("volume_waste", {
					required: true,
				})}
				error={errors?.volume_waste}
				helperText={errors?.volume_waste && "This field is required"}
			/>
		</div>
	);
};

export default WasteManagementForm;
