import { Popover, Menu, Transition } from "@headlessui/react";
import { TextField } from "@material-ui/core";
import axios from "axios";
import {
	forwardRef,
	Fragment,
	useImperativeHandle,
	useRef,
	useState,
	useEffect,
	useContext,
} from "react";
import { useForm } from "react-hook-form";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import VitalsForm from "src/clinic/components/VitalsForm";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import ActionBtn from "src/components/ActionBtn";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";

const MovePatientToDoneModal = (props, ref) => {
	const { clinic } = useClinicContext();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));
	const [open, setOpen] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const show = (patient) => {
		setData(patient);
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = async (formData) => {
		axios
			.post(`v1/operating-room-chart/done/${data?.id}`, {
				_method: "PATCH",
			})
			.then((res) => {
				setTimeout(() => {
					toast.success(
						"Patient has been successfully moved to DONE (Back to Room)!"
					);
				}, 100);
				hide();
			});
	};

	useEffect(() => {}, []);

	return (
		<Modal
			open={open}
			className={`w-[512px] min-w-[512px] max-w-[512px]`}
			staticModal={true}
		>
			<ModalHeader title="Move to PACU" hide={hide} />
			<ModalBody className={`p-4 bg-slate-50 !pb-6`}>
				<span className="text-sm font-medium text-black text-center w-full">
					Confirm move patient to PACU (Post Anesthesia Care Unit)?
				</span>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<ActionBtn
					type="success"
					onClick={handleSubmit(submit)}
					size="md"
					loading={loading}
				>
					Yes, Move patient to PACU
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(MovePatientToDoneModal);
