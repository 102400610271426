import React, {
  useContext,
  useState,
  useRef,
  Fragment,
  useEffect,
} from "react";
import {
  Box,
  IconButton,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  CardMedia,
  Divider,
  Grid,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import { UsersData, UsersHeader } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { Search } from "@material-ui/icons";
import axios from "axios";
import * as htmlToImage from "html-to-image";
import { PlusCircle } from "react-feather";
import IsMountedRef from "src/utils/IsMountedRef";
import SelectStyle from "src/utils/SelectStyle";
import Select from "react-select";

const PayrollPayslip = ({
  selectedEmail,
  employee_id,
  selectedName,
  close,
}) => {
  const { users } = useContext(UsersData);
  const { sidebarHeader } = useContext(UsersHeader);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [payslip, setPayslip] = useState({ data: [], ready: false });
  const samplePrintableDiv = useRef(null);
  const [deductions, setDeductions] = useState([]);
  const [bonusDialog, setBonusDialog] = useState(false);
  const [bonuses, setBonuses] = useState([]);
  const mounted = IsMountedRef();
  const selectStyle = SelectStyle();
  const [selectedHeaderCat, setSelectedHeaderCat] = useState(null);
  const [sendToEmailDialog, setSendToEmailDialog] = useState(false);
  const [headerList, setHeaderList] = useState({
    data: [],
    ready: false,
  });

  // const [ishidden, setIsHidden] = useState(false);
  var totalDeduction = 0;
  var totalPayment = 0;

  const getAllPayrollHeader = () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
    };
    axios
      .get("hr/payroll/header-list-bracket", { params })
      .then((res) => {
        const data = res.data;
        console.log("header", data);
        if (mounted.current) {
          setHeaderList({ data, ready: true });
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const handleSaveAsImage = () => {
    // setIsHidden(true);
    var node = samplePrintableDiv.current;
    htmlToImage
      .toJpeg(node, { backgroundColor: "#fff" })
      .then(function (dataUrl) {
        var a = document.createElement("a");
        a.href = `${dataUrl}`;
        a.download = `payslip-${selectedName}`;
        a.click();
        // setIsHidden(false);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    e.persist();

    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      employee_id,
      receivers_email: selectedEmail,
      covered_period_end: new Date(dateTo).toLocaleString(),
      covered_period_start: new Date(dateFrom).toLocaleString(),
      totalDays: Notify.convertToNumber(
        payslip.data.length > 0 &&
          trackCurrentPay(
            payslip.data[0].rate_class,
            payslip.data[0].daily_salary,
            payslip.data[0].mo_salary,
            payslip.data
          )
      ),
      totalDeduction: Notify.convertToNumber(parseFloat(totalDeduction)),
      totalPayment: Notify.convertToNumber(parseFloat(totalPayment)),
      totalNetPay: Notify.convertToNumber(
        parseFloat(totalPayment) - parseFloat(totalDeduction)
      ),
    };

    axios
      .get("hr/payroll/send-to-email", { params })
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest(`send payroll to email.`);
        }
      })
      .catch((error) => Notify.requestError(error));
  };

  const handleSelectedDate = () => {
    var params = {
      date_from: dateFrom,
      date_to: dateTo,
      employee_id,
      management_id: users.management_id,
    };
    var error = [];
    if (
      parseInt(new Date(dateFrom).getTime()) >
      parseInt(new Date(dateTo).getTime())
    ) {
      error = "error";
      Notify.fieldInvalid("date from");
    }
    if (error.length > 0) {
      console.log("form has an error");
    } else {
      axios
        .get("hmis/payroll/payslip/report-bydate", { params })
        .then((response) => {
          const data = response.data;
          setPayslip({ data, ready: true });
          getDeductionByCoveredDate();
          getBonusByCoveredDate();
        })
        .catch((error) => Notify.requestError(error));
    }
  };

  const trackCurrentPay = (rate, daily, monthly, days) => {
    var result = "";
    if (rate === "Monthly") {
      result = (monthly / 26) * days.length;
    }
    if (rate === "Daily") {
      result = daily * days.length;
    }
    return result;
  };

  const calculateTotalDeduction = (deduction) => {
    totalDeduction += parseFloat(deduction);
    return deduction;
  };

  const calculateTotalPayment = (payment) => {
    totalPayment += parseFloat(payment);
    return payment;
  };

  const handleAddBonus = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);

    formdata.append("user_id", users.user_id);
    formdata.append("management_id", users.management_id);
    formdata.append("employee_id", employee_id);
    formdata.append("header_cat", selectedHeaderCat);
    formdata.append("covered_period_end", new Date(dateTo).toLocaleString());
    formdata.append(
      "covered_period_start",
      new Date(dateFrom).toLocaleString()
    );

    var error = [];

    if (
      formdata.get("header").trim() === "" ||
      formdata.get("header").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("header");
    }

    if (
      formdata.get("amount").trim() === "" ||
      formdata.get("amount").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("amount");
    }

    if (error.length > 0) {
      console.log("There is something wrong with the form");
    } else {
      axios
        .post("hr/payroll/payslip/report/add", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("New added.");
            getBonusByCoveredDate();
            getDeductionByCoveredDate();
          }
          if (data === "db-error") {
            Notify.warnRequest("New Not Added.");
          }
        })
        .catch((error) => Notify.requestError(error));
    }
  };

  const getDeductionByCoveredDate = () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      employee_id,
      covered_period_end: new Date(dateTo).toLocaleString(),
      covered_period_start: new Date(dateFrom).toLocaleString(),
    };

    axios
      .get("hr/payroll/payslip/report/get-deductions", { params })
      .then((response) => {
        const data = response.data;
        setDeductions(data);
      })
      .catch((error) => Notify.requestError(error));
  };

  const getBonusByCoveredDate = () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      employee_id,
      covered_period_end: new Date(dateTo).toLocaleString(),
      covered_period_start: new Date(dateFrom).toLocaleString(),
    };

    axios
      .get("hr/payroll/payslip/report/get-bonus", { params })
      .then((response) => {
        const data = response.data;
        setBonuses(data);
      })
      .catch((error) => Notify.requestError(error));
  };

  useEffect(() => {
    getAllPayrollHeader();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <DialogContent dividers>
        <Box display="flex" className="d-print-none">
          <Box flexGrow={1} className="d-print-none" />
          <Box className="d-print-none">
            <TextField
              fullWidth
              required
              label="Date From"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setDateFrom(e.target.value)}
              margin="dense"
            />
          </Box>
          <Box ml={1} className="d-print-none">
            <TextField
              fullWidth
              required
              label="Date To"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setDateTo(e.target.value);
              }}
              margin="dense"
            />
          </Box>
          <Box
            className="d-print-none"
            mt={1}
            border={2}
            display="flex"
            width={38}
            height={38}
            ml={1}
            justifyContent="center"
            alignItems="center"
          >
            <IconButton color="primary" onClick={() => handleSelectedDate()}>
              <Search fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        {payslip.ready &&
          (payslip.data.length > 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              ref={samplePrintableDiv}
            >
              <Box width={800} border={2} borderRadius={6}>
                <Box
                  mb={3}
                  display={"flex"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box>
                    <CardMedia
                      component="img"
                      src="bmcdc_logo.png"
                      style={{ borderRadius: 5, width: 200 }}
                      alt=""
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box flexGrow={1} />
                  <Box>
                    <Button
                      startIcon={<PlusCircle />}
                      color="primary"
                      variant="contained"
                      onClick={() => setBonusDialog(true)}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
                <Divider />
                <Box m={1}>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    BMCDC ADDRESS
                  </Typography>
                </Box>
                <Divider />
                <Box m={1}>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    Contact Numbers: BMCDC CONTACT NUMBER
                  </Typography>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    Landline: BMCDC LANDLINE
                  </Typography>
                </Box>
                <Divider />
                <Box m={1}>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    Name of Employee: {payslip.data[0]._account_name}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    Period Covered: From: {Notify.createdAt(dateFrom)} To:{" "}
                    {Notify.createdAt(dateTo)}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    Rate Classification: {payslip.data[0].rate_class}
                  </Typography>
                </Box>
                <Divider />
                <Box m={1}>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    Salary
                  </Typography>
                </Box>
                <Divider />
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography style={{ fontWeight: "bolder" }} variant="h6">
                        Basic Rate
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography
                            style={{ fontWeight: "bolder" }}
                            variant="h6"
                          >
                            Basic Pay
                          </Typography>
                        </Box>
                        <Typography
                          style={{ fontWeight: "bolder" }}
                          variant="h6"
                        >
                          {payslip.data[0].rate_class === "Monthly" &&
                            Notify.convertToNumber(
                              payslip.data[0].mo_salary / 26
                            )}
                          {payslip.data[0].rate_class === "Daily" &&
                            Notify.convertToNumber(
                              payslip.data[0].daily_salary
                            )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography
                            style={{ fontWeight: "bolder" }}
                            variant="h6"
                          >
                            Total of Days ({payslip.data.length})
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <Box flexGrow={1} />
                        <Typography
                          style={{ fontWeight: "bolder" }}
                          variant="h6"
                        >
                          {Notify.convertToNumber(
                            calculateTotalPayment(
                              trackCurrentPay(
                                payslip.data[0].rate_class,
                                payslip.data[0].daily_salary,
                                payslip.data[0].mo_salary,
                                payslip.data
                              )
                            )
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {bonuses.length > 0 &&
                  bonuses.map((data, index) => {
                    return (
                      <Fragment key={index}>
                        <Divider />
                        <Box m={1}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <Typography
                                    style={{ fontWeight: "bolder" }}
                                    variant="h6"
                                  >
                                    {data.header_name}
                                  </Typography>
                                </Box>
                                <Typography
                                  style={{ fontWeight: "bolder" }}
                                  variant="h6"
                                >
                                  {Notify.convertToNumber(
                                    calculateTotalPayment(data.amount)
                                  )}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography />
                            </Grid>
                          </Grid>
                        </Box>
                      </Fragment>
                    );
                  })}

                {deductions.length > 0 &&
                  deductions.map((data, index) => {
                    return (
                      <Fragment key={index}>
                        <Divider />
                        <Box m={1}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <Typography
                                    style={{ fontWeight: "bolder" }}
                                    variant="h6"
                                  >
                                    {data.header_name}
                                  </Typography>
                                </Box>
                                <Typography
                                  style={{ fontWeight: "bolder" }}
                                  variant="h6"
                                >
                                  {Notify.convertToNumber(
                                    calculateTotalDeduction(data.amount)
                                  )}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography />
                            </Grid>
                          </Grid>
                        </Box>
                      </Fragment>
                    );
                  })}

                <Divider />
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography
                            style={{ fontWeight: "bolder" }}
                            variant="h6"
                          >
                            Total Earnings
                          </Typography>
                        </Box>
                        <Typography
                          style={{ fontWeight: "bolder" }}
                          variant="h6"
                        >
                          {/* {Notify.convertToNumber(totalPayment)} */}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <Box flexGrow={1} />
                        <Typography
                          style={{ fontWeight: "bolder" }}
                          variant="h6"
                        >
                          {Notify.convertToNumber(totalPayment)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography
                            style={{ fontWeight: "bolder" }}
                            variant="h6"
                          >
                            Total Deduction
                          </Typography>
                        </Box>
                        <Typography
                          style={{ fontWeight: "bolder" }}
                          variant="h6"
                        >
                          {/* {Notify.convertToNumber(totalDeduction)} */}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <Box flexGrow={1} />
                        <Typography
                          style={{ fontWeight: "bolder" }}
                          variant="h6"
                        >
                          {Notify.convertToNumber(totalDeduction)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box m={1}>
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <Typography style={{ fontWeight: "bolder" }} variant="h6">
                        NET PAY
                      </Typography>
                    </Box>
                    <Typography style={{ fontWeight: "bolder" }} variant="h6">
                      Php{" "}
                      {Notify.convertToNumber(totalPayment - totalDeduction)}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box m={1}>
                  <Typography variant="h6">
                    Note: This payslip serves as the official receipts for the
                    deductions made against your gross salary. Only items not
                    preprinted in this forms requires O.R. Please keep this for
                    your file.
                  </Typography>
                </Box>
                <Divider />
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography style={{ fontWeight: "bolder" }} variant="h6">
                        Received by:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontWeight: "bolder" }} variant="h6">
                        Certified by:
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        style={{ fontWeight: "bolder" }}
                        align="center"
                        variant="h6"
                      >
                        {payslip.data.length > 0 &&
                          `${payslip.data[0]._account_name}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        style={{ fontWeight: "bolder" }}
                        align="center"
                        variant="h6"
                      >
                        {sidebarHeader.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        style={{ fontWeight: "bolder" }}
                        align="center"
                        variant="h6"
                      >
                        Employee
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        style={{ fontWeight: "bolder" }}
                        align="center"
                        variant="h6"
                      >
                        Payroll Incharge
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={"100%"}
              height="calc(50vh - 180px)"
            >
              <Typography color="secondary" variant="h4">
                No record found for this dates.
              </Typography>
            </Box>
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => close()}
          variant="contained"
          color={"default"}
          className="d-print-none"
        >
          close
        </Button>

        <Button
          variant="contained"
          color={"primary"}
          onClick={handleSaveAsImage}
          className="d-print-none"
        >
          SaveAsImage
        </Button>
        <Button
          variant="contained"
          color={"primary"}
          onClick={() => setSendToEmailDialog(true)}
          className="d-print-none"
          disabled={selectedEmail === null}
        >
          Send to Email
        </Button>
      </DialogActions>

      <Dialog open={bonusDialog} onClose={() => setBonusDialog(false)}>
        <DialogTitle className="d-print-none">Add </DialogTitle>
        <form onSubmit={handleAddBonus}>
          <DialogContent dividers>
            <Box mb={2}>
              <Select
                options={headerList.data}
                name="header"
                onChange={(data) => {
                  setSelectedHeaderCat(data.category);
                }}
                menuPortalTarget={document.body}
                styles={selectStyle}
              />
            </Box>

            <Box mb={3}>
              <TextField
                label="Amount"
                name="amount"
                fullWidth
                variant="outlined"
              />
            </Box>

            <Box display="flex">
              <Box flexGrow={1} />

              <Box mr={1}>
                <Button
                  onClick={() => setBonusDialog(false)}
                  variant="contained"
                  color={"default"}
                  className="d-print-none"
                >
                  Close
                </Button>
              </Box>

              <Button
                variant="contained"
                color={"primary"}
                type="submit"
                className="d-print-none"
              >
                Submit
              </Button>
            </Box>
          </DialogContent>
        </form>
      </Dialog>

      <Dialog
        open={sendToEmailDialog}
        onClose={() => setSendToEmailDialog(false)}
      >
        <form onSubmit={handleSendEmail}>
          <DialogContent dividers>
            <Box mb={3}>
              <TextField
                label="Receiver's Email"
                fullWidth
                variant="outlined"
                value={selectedEmail !== null ? selectedEmail : ""}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>

            <Box display="flex">
              <Box flexGrow={1} />

              <Box mr={1}>
                <Button
                  onClick={() => setSendToEmailDialog(false)}
                  variant="contained"
                  color={"default"}
                  className="d-print-none"
                >
                  Close
                </Button>
              </Box>

              <Button
                variant="contained"
                color={"primary"}
                type="submit"
                className="d-print-none"
              >
                Submit
              </Button>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default PayrollPayslip;
