import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { Component, useEffect } from "react";
import { Slide, ToastContainer } from "react-toastify";
import Cookies from "universal-cookie";
import AccountTypeChecker from "./auth/AccountTypeChecker";
import CheckAuth from "./CheckAuth";
import { ThemeContext, UsersData } from "./ContextAPI";
import {
	BlueTheme,
	DarkTheme,
	GreenTheme,
	IndigoTheme,
	PurpleTheme,
	RedTheme,
	TealTheme,
} from "./HMISThemes";
import RouteAccounting from "./route/RouteAccounting";
import RouteAdmission from "./route/RouteAdmission";
import RouteBilling from "./route/RouteBilling";
import RouteCashier from "./route/RouteCashier";
import RouteDoctor from "./route/RouteDoctor";
import RouteHMIS from "./route/RouteHMIS";
import RouteHomepage from "./route/RouteHomepage";
import RouteImaging from "./route/RouteImaging";
import RouteIshihara from "./route/RouteIshihara";
import RouteLaboratory from "./route/RouteLaboratory";
import RoutePharmacy from "./route/RoutePharmacy";
import RouteRadiologist from "./route/RouteRadiologist";
import RouteTriage from "./route/RouteTriage";
import RouteWarehouse from "./route/RouteWarehouse";
// import SyncDataBMCDC from "./SyncDataBMCDC";
import RouteAdmitting from "./route/RouteAdmitting";
import RouteClinicNurse from "./route/RouteClinicNurse";
import RouteDocumentation from "./route/RouteDocumentation";
import RouteEndorsement from "./route/RouteEndorsement";
import RouteHaptech from "./route/RouteHaptech";
import RouteHR from "./route/RouteHR";
import RouteNurse from "./route/RouteNurse";
import RouteOM from "./route/RouteOM";
import RouteOthers from "./route/RouteOthers";
import RoutePho from "./route/RoutePho";
import RoutePhoFp from "./route/RoutePhoFp";
import RoutePsychology from "./route/RoutePsychology";
import RouteReceiving from "./route/RouteReceiving";
import RouteStockRoom from "./route/RouteStockRoom";
import RouteVanEndorsement from "./route/RouteVanEndorsement";
import RouteNurseClinic from "./route/RouteNurseClinic";
import RouteClinicDoctor from "./route/RouteClinicDoctor";
import RouteClinicRegistration from "./route/RouteClinicRegistration";
import RouteClinicInformationSystem from "./route/RouteClinicInformationSystem";
import RouteCISDoctor from "./route/RouteCISDoctor";
import { ClinicContextWrapper } from "./clinic/context/ClinicContext";
import RouteCISLaboratory from "./route/RouteCISLaboratory";
import RouteLMISWH from "./route/RouteLMISWH";
import RouteLMISCNOR from "./route/RouteLMISCNOR";
import RouteBHS from "./route/RouteBHS";
import RouteRHU from "./route/RouteRHU";
import { DiseaseTreatmentContextWrapper } from "./disease-treatment/context/DiseaseTreatmentContext";
import RouteRHUBilling from "./route/RouteRHUBilling";
import RouteCISAdmin from "./route/RouteCISAdmin";
const cookies = new Cookies();
require("dotenv").config();

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers[`Authorization`] = `Bearer ${localStorage.getItem(
	"hmis_token"
)}`;
export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: localStorage.getItem("hmis_token"),

			userInfo: {
				email: null,
				management_id: null,
				main_mgmt_id: null,
				ready: false,
				type: null,
				user_id: null,
				username: null,
				management_name: null,
			},

			darkmode: cookies.get("gtcTheme") === "dark" ? true : false,
			sidebarOpen: window.innerWidth > 900 ? true : false,
			gtctheme: BlueTheme,
		};
	}

	componentDidMount() {
		this.updateGTCTheme(cookies.get("gtctheme_mode"));
	}

	handleToggleSidebar = (state) => {
		this.setState({ sidebarOpen: state });
	};

	updateGTCTheme = (data) => {
		switch (data) {
			case "red":
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: RedTheme });
				return;
			case "purple":
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: PurpleTheme });
				return;
			case "green":
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: GreenTheme });
				return;
			case "indigo":
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: IndigoTheme });
				return;
			case "teal":
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: TealTheme });
				return;
			case "dark":
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: DarkTheme });
				return;
			default:
				cookies.set("gtctheme_mode", data);
				this.setState({ gtctheme: BlueTheme });
				return;
		}
	};

	handleUsersUpdate = (data) => {
		this.setState({
			userInfo: {
				...this.state.userInfo,
				email: data.email,
				management_id: data.management_id,
				main_mgmt_id: data.main_mgmt_id,
				ready: data.ready,
				type: data.type,
				user_id: data.user_id,
				username: data.username,
				management_name: data.management_name,
				...data,
			},
		});
	};

	render() {
		return (
			<>
				<ThemeContext.Provider
					value={{
						gtcThemeDark: this.state.darkmode,
						updategtcTheme: this.updateGTCTheme,
						sidebar: {
							open: this.state.sidebarOpen,
							toggle: this.handleToggleSidebar,
						},
					}}
				>
					<ThemeProvider theme={this.state.gtctheme}>
						<UsersData.Provider
							value={{
								users: this.state.userInfo,
								updateUsers: this.handleUsersUpdate,
							}}
						>
							<CssBaseline />
							{this.state.token ? (
								<CheckAuth>
									{this.state.userInfo.ready && (
										<CheckAcountRole
											role={this.state.userInfo.type}
											management_id={
												this.state.userInfo
													.management_id
											}
										/>
									)}
								</CheckAuth>
							) : (
								<RouteHomepage />
							)}

							{/* toastr container */}
							<ToastContainer
								position="bottom-right"
								autoClose={2000}
								hideProgressBar
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover={false}
								transition={Slide}
							/>
						</UsersData.Provider>
					</ThemeProvider>
				</ThemeContext.Provider>

				{/* enable sync */}
				{/* {window.location.href.includes("http://localhost/sph/app") && (
          <SyncDataBMCDC />
        )} */}
			</>
		);
	}
}
const CheckAcountRole = ({ role, management_id }) => {
	const theme = useTheme();

	useEffect(() => {
		document
			.querySelector('meta[name="theme-color"]')
			.setAttribute(
				"content",
				`${
					theme.palette.type === "dark"
						? "#525252"
						: theme.palette.primary.main
				}`
			);
		// eslint-disable-next-line
	}, []);
	if (role === "HMIS") return <RouteHMIS />;
	if (role === "HIS-Pharmacy") return <RoutePharmacy />;
	if (role === "HIS-Laboratory") return <RouteLaboratory />;
	if (role === "HIS-OM") return <RouteOM />;
	if (role === "CIS-ADMIN")
		return (
			<ClinicContextWrapper>
				<RouteCISAdmin />
			</ClinicContextWrapper>
		);

	if (
		role == "CIS-Nurse" ||
		role == "CIS-Registration" ||
		role == "CIS-Doctor"
	)
		return (
			<ClinicContextWrapper>
				<RouteClinicInformationSystem />
			</ClinicContextWrapper>
		);
	if (role == "CIS-Laboratory")
		return (
			<ClinicContextWrapper>
				<RouteCISLaboratory />
			</ClinicContextWrapper>
		);

	// if (role == "CIS-Doctor")
	// 	return (
	// 		<ClinicContextWrapper>
	// 			<RouteCISDoctor />
	// 		</ClinicContextWrapper>
	// 	);

	if (role == "HIS-Doctor" && management_id == null)
		return <RouteClinicDoctor />;
	if (role === "HIS-Doctor") return <RouteDoctor />;
	if (role === "HIS-Documentation") return <RouteDocumentation />;
	if (role == "HIS-Registration" && management_id == null)
		return <RouteClinicRegistration />;
	if (role === "HIS-Registration") return <RouteAdmission />;
	if (role === "HIS-Imaging") return <RouteImaging />;
	if (role === "HIS-Radiologist") return <RouteRadiologist />;
	if (role === "HIS-Cashier") return <RouteCashier />;
	if (role === "HIS-Accounting") return <RouteAccounting />;
	if (role === "HIS-Billing") return <RouteBilling />;
	if (role === "HIS-Triage") return <RouteTriage />;
	if (role === "HIS-Warehouse") return <RouteWarehouse />;
	if (role === "HIS-Ishihara") return <RouteIshihara />;
	if (role === "HIS-Stockroom") return <RouteStockRoom />;
	if (role === "HIS-Haptech") return <RouteHaptech />;
	if (role === "HIS-Hr") return <RouteHR />;
	if (role === "HIS-Psychology") return <RoutePsychology />;
	if (role === "HIS-Others") return <RouteOthers />;
	if (role == "HIS-Nurse" && management_id == null)
		return <RouteNurseClinic />;
	if (role === "HIS-Nurse") return <RouteNurse />;
	if (role === "HIS-Endorsement") return <RouteEndorsement />;
	if (role === "HIS-Receiving") return <RouteReceiving />;
	if (role === "Van-Endorsement") return <RouteVanEndorsement />;
	if (role === "HIS-Admitting") return <RouteAdmitting />;
	if (role === "PHO-HQ") {
		return (
			<DiseaseTreatmentContextWrapper>
				<RoutePho />
			</DiseaseTreatmentContextWrapper>
		);
	}
	if (role === "PHO-FQ") {
		return (
			<DiseaseTreatmentContextWrapper>
				<RoutePhoFp />
			</DiseaseTreatmentContextWrapper>
		);
	}
	if (
		role === "PHO" ||
		role === "PHO-HO" ||
		role === "MUNICIPAL-HO" ||
		role === "BARANGAY-HO" ||
		role === "PUROK-HO"
	)
		return (
			<DiseaseTreatmentContextWrapper>
				<RoutePho />
			</DiseaseTreatmentContextWrapper>
		);
	if (role === "PHO-FP") return <RoutePhoFp />;
	// if (role === "RHU-Doctor") return <RoutePhoFp />;
	// if (role === "RHU-Nurse") return <RoutePhoFp />;
	if (role === "RHU-Volunteer") return <RoutePhoFp />;
	if (role === "RHU-HW") return <RoutePhoFp />;

	if (role === "LMIS-WH")
		return (
			<DiseaseTreatmentContextWrapper>
				<RouteLMISWH />
			</DiseaseTreatmentContextWrapper>
		);
	if (role === "LMIS-RHU")
		return (
			<DiseaseTreatmentContextWrapper>
				<RouteLMISWH />
			</DiseaseTreatmentContextWrapper>
		);
	if (role === "LMIS-SPH")
		return (
			<DiseaseTreatmentContextWrapper>
				<RouteLMISWH />
			</DiseaseTreatmentContextWrapper>
		);
	if (role === "LMIS-BHS")
		return (
			<DiseaseTreatmentContextWrapper>
				<RouteLMISWH />
			</DiseaseTreatmentContextWrapper>
		);
	if (role === "LMIS-BHS")
		return (
			<DiseaseTreatmentContextWrapper>
				<RouteLMISWH />
			</DiseaseTreatmentContextWrapper>
		);
	if (role === "LMIS-CNOR")
		return (
			<DiseaseTreatmentContextWrapper>
				<RouteLMISCNOR />
			</DiseaseTreatmentContextWrapper>
		);
	// DiseaseTreatmentContextWrapper
	if (role === "BHS-BHW")
		return (
			<DiseaseTreatmentContextWrapper>
				<RouteBHS />
			</DiseaseTreatmentContextWrapper>
		);
	if (
		role.includes("RHU") &&
		!role.includes("BILLING") &&
		!role.includes("CASHIER")
	) {
		return (
			<DiseaseTreatmentContextWrapper>
				<RouteRHU />
			</DiseaseTreatmentContextWrapper>
		);
	}
	if (role == "SPH-BILLING" || role == "SPH-CASHIER") {
		return (
			<DiseaseTreatmentContextWrapper>
				<RouteRHUBilling />
			</DiseaseTreatmentContextWrapper>
		);
	}
	if (
		role.includes("SPH") &&
		!role.includes("BILLING") &&
		!role.includes("CASHIER")
	) {
		return (
			<DiseaseTreatmentContextWrapper>
				<RouteRHU />
			</DiseaseTreatmentContextWrapper>
		);
	}

	if (role == "Clinic-Doctor") return <RouteClinicDoctor />;

	return <AccountTypeChecker />;
};
