const ButtonV3 = ({ type = "primary", size = "md", children, ...rest }) => {
	return (
		<div
			className={`rounded-xl select-none flex !font-medium items-center justify-center gap-2 cursor-pointer hover:shadow duration-200 btn-${type} btn-v3-${size}`}
			{...rest}
		>
			{children}
		</div>
	);
};

export default ButtonV3;
