import { Box, Button, Card, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Notify from "src/notification/Notify";
import { styles } from "../styles/styles";
import ButtonV3 from "src/components/ButtonV3";
import TextInputField from "src/components/forms/TextInputField";
import ReactQuill from "react-quill";
import ReactQuillField from "src/components/forms/ReactQuillField";
import ActionBtn from "src/components/ActionBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
	button: {
		width: "100%",
		fontSize: "18px",
		padding: "6px",
	},
}));

export default function CisCreateNotes({ setShown, patient }) {
	const [dateAndTime, setDateAndTime] = useState("");
	const patientId = patient.id;
	const patient_id = patient.patient_id;
	const classes = useStyles();
	const {
		register,
		setValue,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		let t = setTimeout(() => {
			const currentDateTime = new Date();
			const formattedDate = currentDateTime.toISOString().split("T")[0];
			const formattedTime = currentDateTime.toLocaleTimeString("en-US", {
				hour12: false,
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
			});
			setDateAndTime(`${formattedDate} ${formattedTime}`);
			setValue("datetime", `${formattedDate} ${formattedTime}`);
		}, 300);
		return () => {
			clearTimeout(t);
		};
	}, []);

	const onSubmit = (data) => {
		console.log(data);
		let formdata = new FormData();
		formdata.append("notes", data?.notes || "");
		formdata.append("remarks", data?.remarks || "");
		formdata.append("added_by", patient_id);
		formdata.append("patient_id", patientId);
		formdata.append("datetime", `${data?.date} ${data?.time}`);
		formdata.append("status", data?.status);

		axios
			.post(`/v1/notes/store/${patientId}`, formdata)
			.then((response) => {
				let data = response.data;
				console.log(data);
				setTimeout(() => {
					Notify.successRequest("created");
				}, 200);
				setShown(true);
				// getAllIllnessPE();
				// close();
			})
			.catch((err) => console.log(err));
	};

	return (
		<div className="flex w-full lg:w-3/5 flex-col p-6">
			<div className="flex items-center justify-between border-y-2 p-2 mb-4">
				<h4 className="text-base font-bold mb-0">Create Notes</h4>

				<ButtonV3
					onClick={() => {
						setShown(true);
					}}
				>
					<div className="flex">
						<FontAwesomeIcon
							icon={faArrowLeft}
							color="primary"
							style={{ fontSize: 18 }}
							className="pr-2"
						/>
						back
					</div>
				</ButtonV3>
			</div>
			<form className="flex w-full flex-col">
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mb-4">
					<TextInputField
						className="lg:col-span-6"
						label="Date"
						type={"date"}
						key={`inputDate111`}
						// value={dateAndTime}
						// key={`dt-${dateAndTime}`}
						// defaultValue={dateAndTime}
						error={errors?.date?.message}
						{...register("date", {
							required: {
								value: true,
								message: "This field is required!",
							},
						})}
					/>
					<TextInputField
						className="lg:col-span-6"
						label="Time"
						type={"time"}
						key={`inputTime111`}
						// value={dateAndTime}
						// key={`dt-${dateAndTime}`}
						// defaultValue={dateAndTime}
						error={errors?.time?.message}
						{...register("time", {
							required: {
								value: true,
								message: "This field is required!",
							},
						})}
					/>
					<Controller
						name="notes"
						control={control}
						rules={{
							required: {
								value: true,
								message: "This field is required",
							},
						}}
						render={({
							field: { onChange, onBlur, value, name, ref },
							fieldState: { invalid, isTouched, isDirty, error },
						}) => (
							<ReactQuillField
								name={name}
								error={error}
								label="Notes"
								value={value}
								onChange={onChange}
								className="lg:col-span-12"
							/>
						)}
					/>
					<TextInputField
						className="lg:col-span-12"
						label="Remarks"
						placeHolder="Enter remarks here..."
						{...register("remarks")}
					/>
					{/* <TextInputField
						className="lg:col-span-6"
						label="Status"
						placeHolder="Enter status here..."
						{...register("status")}
					/> */}
				</div>

				<ActionBtn
					onClick={handleSubmit(onSubmit)}
					className="w-1/3 ml-auto"
				>
					Submit
				</ActionBtn>
			</form>
		</div>
	);
}
