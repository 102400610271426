import { TextField } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";

const SelectBHSLocation = ({
	setLocations,
	location,
	index,
	errors,
	municipalities,
	setMunicipalities,
	updateLocations,
	register,
}) => {
	const [municipalityId, setMunicipalityId] = useState(null);
	const [BHSData, setBHSData] = useState([]);

	const getBHSData = (municipality_id) => {
		axios
			.get(
				`/v1/health-unit/list?type=BHS&municipality_id=${municipality_id}`
			)
			.then((res) => {
				setBHSData(res.data.data);
			});
	};

	return (
		<>
			<div className="w-[244px]">
				<TextField
					className="w-full"
					SelectProps={{
						native: true,
						onChange: (e) => {
							console.log("SelectProps onChange", e.target.value);
							setMunicipalityId(e.target.value);
							getBHSData(e.target.value);
						},
					}}
					InputLabelProps={{
						shrink: true,
					}}
					label={`Municipality`}
					variant="outlined"
					select
				>
					<option value="" disabled selected>
						Select
					</option>
					{municipalities?.map((option) => {
						return (
							<option value={option?.id}>{option?.name}</option>
						);
					})}
				</TextField>
			</div>

			{municipalityId ? (
				<div className="w-[244px]">
					<TextField
						className="w-full"
						SelectProps={{
							native: true,
							onChange: (e) => {
								console.log(
									"SelectProps onChange",
									e.target.value
								);
								updateLocations({
									...location,
									location_id: e.target.value,
								});
							},
						}}
						InputLabelProps={{
							shrink: true,
						}}
						label={`Select BHS Location`}
						variant="outlined"
						select
					>
						<option value="" disabled selected>
							Select
						</option>
						{BHSData?.map((option) => {
							return (
								<option value={option?.id}>
									{option?.name}
								</option>
							);
						})}
					</TextField>
				</div>
			) : (
				""
			)}
		</>
	);
};

export default SelectBHSLocation;
