import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  XCircle as CloseIcon,
} from "react-feather";

const ZoomableImage = ({ open, close, title, image }) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      fullScreen
      disableEscapeKeyDown
      disableBackdropClick
    >
      <Box className="gtc-viewimage">
        <TransformWrapper defaultScale={1}>
          {({ zoomIn, zoomOut }) => (
            <>
              <Box display="flex">
                <Box flexGrow={1}>
                  <DialogTitle disableTypography>
                    <Typography
                      color="textSecondary"
                      variant="h6"
                      className={`gtc-capitalize`}
                    >
                      {title}
                    </Typography>
                  </DialogTitle>
                </Box>
                <Box>
                  <IconButton color="primary" onClick={zoomIn}>
                    <ZoomInIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={zoomOut}>
                    <ZoomOutIcon />
                  </IconButton>

                  <IconButton color="secondary" onClick={close}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>

              <DialogContent dividers>
                <Box>
                  <TransformComponent>
                    <img src={image} alt="" />
                  </TransformComponent>
                </Box>
              </DialogContent>
            </>
          )}
        </TransformWrapper>
      </Box>
    </Dialog>
  );
};

export default ZoomableImage;
