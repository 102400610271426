import React, { useState } from "react";
import {
  faEdit,
  faEye,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { formatDate } from "src/helpers/utils";
import { Box, Card, TablePagination } from "@material-ui/core";
import ButtonV3 from "src/components/ButtonV3";
import { styles } from "src/cis/components/styles/styles";
import AddModal from "src/cis-laboratory/utils/AddModal";
import CisAddLaboratoryRT_PCR from "./CisAddLaboratoryRT_PCR";

export default function CisLaboratoryRT_PCR() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpen, setOpen] = useState(false);
  const [name, setName] = useState();

  const data = [
    {
      id: 123,
      orderType: "X-ray ",
      orderNo: "022865",
      patientName: "Inting Kabisti",
      referredBy: "Doctor Doe",
      remarks: "remarks",
    },
    {
      id: 456,
      orderType: "X-ray",
      orderNo: "022832",
      patientName: "Juan dela Cruz",
      referredBy: "Doctor Doe",
      remarks: "remarks",
    },
    {
      id: 789,
      orderType: "X-ray ",
      orderNo: "022834",
      patientName: "Ricardo Dalisay",
      referredBy: "Doctor Doe",
      remarks: "remarks",
    },
  ];
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleOpenPopup = (patientName) => {
    setOpen(true);
    setName(patientName);
  };
  const handleClosePopup = () => {
    setOpen(false);
  };
  return (
    <div className="flex w-full flex-col">
      <TableContainer>
        <Card elevation={3} className="p-4" component={Box}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={styles.tableHeader}>
                  <strong>Order No.</strong>
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  <strong>Order Type</strong>
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  <strong>Patient Name</strong>
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  <strong>Referred By</strong>
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  <strong>Remarks</strong>
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(
                ({
                  id,
                  orderType,
                  orderNo,
                  patientName,
                  referredBy,
                  remarks,
                }) => (
                  <>
                    <TableRow className={styles.tableHover} key={id}>
                      <TableCell>{orderNo}</TableCell>
                      <TableCell>{orderType}</TableCell>
                      <TableCell>{patientName}</TableCell>
                      <TableCell>{referredBy}</TableCell>
                      <TableCell>{remarks}</TableCell>
                      <TableCell>
                        <div className="flex flex-row gap-2">
                          <ButtonV3
                            onClick={() => handleOpenPopup(patientName)}
                            type="primary-outline"
                          >
                            <div className="flex items-center">
                              <FontAwesomeIcon icon={faPlus} className="mr-2" />
                              Add Results
                            </div>
                          </ButtonV3>
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                )
              )}
            </TableBody>
          </Table>
          <TablePagination
            labelRowsPerPage="List"
            rowsPerPageOptions={[10, 50, 100, { label: "All", value: -1 }]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Card>
      </TableContainer>
      <AddModal
        open={isOpen}
        onClose={handleClosePopup}
        title={"Add Results RT PCR"}
        content={<CisAddLaboratoryRT_PCR name={name} />}
      />
    </div>
  );
}
