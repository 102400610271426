import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip,
  TableContainer,
  Card,
  CardHeader,
  CardContent,
  Button,
} from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import Notify from "src/notification/Notify";
import Label from "src/utils/Label";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import PerfectScrollbar from "react-perfect-scrollbar";
import CreateAppointment from "./CreateAppointment";
import AppointmentDetails from "./AppointmentDetails";
import RescheduleAppointment from "./RescheduleAppointment";
import { XCircle } from "react-feather";

const PatientQueueAppointment = ({
  patient_id,
  getAppointmentListLocal,
  appointment,
  trace_number,
}) => {
  const [createDialog, setCreateDialog] = useState(false);
  const [detailsDialog, setDetailsDialog] = useState({
    data: null,
    open: false,
  });
  const [reschedDialog, setReschedDialog] = useState({
    data: null,
    open: false,
  });

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Box display="flex">
              <Box flexGrow={1}>Appointment List</Box>

              <Box>
                <Button
                  style={{ borderRadius: 50 }}
                  onClick={() => setCreateDialog(true)}
                  variant="contained"
                  color="primary"
                  startIcon={<ControlPointIcon />}
                >
                  New
                </Button>
              </Box>
            </Box>
          }
        />
        <CardContent>
          <Box>
            <TableContainer>
              <PerfectScrollbar>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"> Date </TableCell>
                      <TableCell align="center"> Complaint </TableCell>
                      <TableCell align="center"> Service </TableCell>
                      <TableCell align="center"> Amount </TableCell>
                      <TableCell align="center"> Status </TableCell>
                      <TableCell align="center"> Rescheduled? </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {appointment.ready ? (
                      appointment.data.length > 0 ? (
                        appointment.data.map((data, index) => (
                          <TableRow key={index} hover>
                            <TableCell align="right">
                              {" "}
                              {Boolean(data.is_reschedule)
                                ? Notify.dateTimeConvert(
                                    data.is_reschedule_date
                                  )
                                : Notify.dateTimeConvert(data.app_date)}
                            </TableCell>
                            <TableCell> {data.app_reason} </TableCell>
                            <TableCell> {data.services} </TableCell>
                            <TableCell align="right"> {data.amount} </TableCell>
                            <TableCell align="center">
                              <Label
                                color={
                                  Boolean(data.is_complete)
                                    ? "success"
                                    : "error"
                                }
                              >
                                {" "}
                                {Boolean(data.is_complete)
                                  ? "Complete"
                                  : "Incomplete "}{" "}
                              </Label>
                            </TableCell>
                            <TableCell align="center">
                              <Label
                                color={
                                  Boolean(data.is_reschedule)
                                    ? "success"
                                    : "primary"
                                }
                              >
                                {" "}
                                {Boolean(data.is_reschedule)
                                  ? "Yes"
                                  : "No "}{" "}
                              </Label>
                            </TableCell>
                            <TableCell>
                              <Box display="flex">
                                <Tooltip arrow title="Reschedule Appointment">
                                  <Box>
                                    <IconButton
                                      onClick={() => {
                                        setReschedDialog({
                                          data: data,
                                          open: true,
                                        });
                                      }}
                                      disabled={Boolean(data.is_complete)}
                                    >
                                      <ScheduleIcon />
                                    </IconButton>
                                  </Box>
                                </Tooltip>
                                <Tooltip arrow title="Appointment Details">
                                  <IconButton
                                    onClick={() =>
                                      setDetailsDialog({
                                        data: data,
                                        open: true,
                                      })
                                    }
                                  >
                                    <ArrowRightAltIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={7}>
                            <Typography color="secondary">
                              No appointment found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Typography color="primary">
                            Please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </PerfectScrollbar>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>

      {/* add local appointment */}
      <Dialog
        open={createDialog}
        onClose={() => setCreateDialog(false)}
        disableBackdropClick
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Create Appointment</DialogTitle>

        <CreateAppointment
          patient_id={patient_id}
          close={() => setCreateDialog(false)}
          reload={getAppointmentListLocal}
          trace_number={trace_number}
        />
      </Dialog>

      {/* details appointment */}
      <Dialog
        open={detailsDialog.open}
        onClose={() => setDetailsDialog({ data: null, open: false })}
        disableBackdropClick
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle>
          <Box display="flex">
            <Box flexGrow={1}> Appointment Details </Box>
            <Box>
              <IconButton
                color={"secondary"}
                onClick={() => setDetailsDialog({ data: null, open: false })}
                size={"large"}
              >
                <XCircle />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        {detailsDialog.data && (
          <AppointmentDetails
            app={detailsDialog.data}
            close={() => setDetailsDialog({ data: null, open: false })}
          />
        )}
      </Dialog>

      {/* appointment reschedule */}
      <Dialog
        open={reschedDialog.open}
        onClose={() => setReschedDialog({ data: null, open: false })}
        disableBackdropClick
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>
          <Box display="flex">
            <Box flexGrow={1}> Appointment Reschedule </Box>
            <Box>
              <IconButton
                color={"secondary"}
                onClick={() => setReschedDialog({ data: null, open: false })}
              >
                <XCircle />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        {reschedDialog.data && (
          <RescheduleAppointment
            app={reschedDialog.data}
            close={() => setReschedDialog({ data: null, open: false })}
            reload={getAppointmentListLocal}
          />
        )}
      </Dialog>
    </>
  );
};

export default PatientQueueAppointment;
