import React, { Fragment } from "react";
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Grid,
} from "@material-ui/core";
import ThyroidProfileRef from "src/laboratory/references/Ref_ThyroidProfile";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const ThyroidProfilePrintAll = ({
  allowHeaderPatientInfo,
  patients,
  thyroidProfOrderDetails,
}) => {
  return (
    <>
      {thyroidProfOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                THYROID PROFILE
              </span>
            </Typography>
          </Box>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ color: "#000", fontWeight: "bolder" }}
                  >
                    TEST
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "#000", fontWeight: "bolder" }}
                  >
                    RESULT
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "#000", fontWeight: "bolder" }}
                  >
                    REFERENCE VALUES
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "#000", fontWeight: "bolder" }}
                  >
                    REMARKS
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {thyroidProfOrderDetails.data.map((data, index) => (
                  <Fragment key={index}>
                    {data.t3 && (
                      <TableRow>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            T3
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.t3}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {ThyroidProfileRef.t3()}
                        </TableCell>
                        <TableCell align="center">
                          <b style={{ color: "#000" }}>{data.t3_remarks}</b>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.t4 && (
                      <TableRow>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            T4
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.t4}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {ThyroidProfileRef.t4()}
                        </TableCell>
                        <TableCell align="center">
                          <b style={{ color: "#000" }}>{data.t4_remarks}</b>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.tsh && (
                      <TableRow>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            TSH
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.tsh}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {ThyroidProfileRef.tsh()}
                        </TableCell>
                        <TableCell align="center">
                          <b style={{ color: "#000" }}>{data.tsh_remarks}</b>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ft4 && (
                      <TableRow>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            FT4
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.ft4}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {ThyroidProfileRef.ft4()}
                        </TableCell>
                        <TableCell align="center">
                          <b style={{ color: "#000" }}>{data.ft4_remarks}</b>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ft3 && (
                      <TableRow>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            FT3
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.ft3}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {ThyroidProfileRef.ft3()}
                        </TableCell>
                        <TableCell align="center">
                          <b style={{ color: "#000" }}>{data.ft3_remarks}</b>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.t3t4 && (
                      <TableRow>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            T3T4
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.t3t4}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <b style={{ color: "#000" }}>none</b>
                        </TableCell>
                        <TableCell align="center">
                          <b style={{ color: "#000" }}>{data.t3t4_remarks}</b>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.fht && (
                      <TableRow>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            FHT
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.fht}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <b style={{ color: "#000" }}>none</b>
                        </TableCell>
                        <TableCell align="center">
                          <b style={{ color: "#000" }}>{data.fht_remarks}</b>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box my={1} align="center">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {thyroidProfOrderDetails.data.length > 0 &&
                        thyroidProfOrderDetails.data[0].pathologist}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Pathologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {thyroidProfOrderDetails.data.length > 0 &&
                          thyroidProfOrderDetails.data[0].pathologist_lcn}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xs={12} sm={4}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {thyroidProfOrderDetails.data.length > 0 &&
                        thyroidProfOrderDetails.data[0].chief_medtech}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Chief Med Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {thyroidProfOrderDetails.data.length > 0 &&
                          thyroidProfOrderDetails.data[0].chief_medtech_lci}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      <span
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                      >
                        {thyroidProfOrderDetails.data.length > 0 &&
                          thyroidProfOrderDetails.data[0].medtech}
                      </span>
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Medical Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {thyroidProfOrderDetails.data.length > 0 &&
                            thyroidProfOrderDetails.data[0].lic_no}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default ThyroidProfilePrintAll;
