import React, { Fragment, useEffect, useState } from "react";
import Notify from "../../../notification/Notify";
import Axios from "axios";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { UsersData } from "src/ContextAPI";

const ImagingUnprocess = ({ patient_id, imagingType }) => {
	const [imaging, setImaging] = useState({ data: [], ready: false });
	const { users } = React.useContext(UsersData);

	const getImagingOngoing = () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set(
			"connection",
			imagingType === "virtual-imaging" ? "online" : "local"
		);

		Axios.post("doctor/patient/imaging/imaging-unprocess", formdata)
			.then((response) => {
				const data = response.data;
				setImaging({ data: data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	useEffect(() => {
		getImagingOngoing();

		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Fragment>
				<Box>
					{imaging.ready ? (
						imaging.data.length > 0 ? (
							imaging.data.map((data, index) => {
								return (
									<Card
										component={Box}
										borderLeft={5}
										borderColor={`#17a2b8`}
										key={index}
										mb={2}
									>
										<CardContent>
											<Box>
												<Typography variant="subtitle1">
													{data.imaging_order}
												</Typography>
											</Box>

											<Box my={1}>
												<Typography variant="subtitle2" color="text-uppercase">
													{data.imaging_remarks}
												</Typography>
											</Box>

											<Box>
												<Typography
													variant="subtitle2"
													className={`gtc-uppercase`}
													color="text-uppercase"
												>
													order on {Notify.createdAt(data.created_at)}
												</Typography>
											</Box>
										</CardContent>
									</Card>
								);
							})
						) : (
							<Card>
								{" "}
								<CardContent> {Notify.noRecord()} </CardContent>{" "}
							</Card>
						)
					) : (
						<Card>
							{" "}
							<CardContent> {Notify.loading()} </CardContent>{" "}
						</Card>
					)}
				</Box>
			</Fragment>
		</>
	);
};

export default ImagingUnprocess;
