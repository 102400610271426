import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Edit } from "react-feather";
import { BranchListContext, UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import AddBranches from "./AddBranches";
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";
import Notify from "src/notification/Notify";

const Branches = () => {
  const { users } = useContext(UsersData);
  const { branchList, updateBranchList } = useContext(BranchListContext);
  const [selectedBrachName, setSelectedBranchName] = useState(null);
  const [selectedBrachId, setSelectedBranchId] = useState(null);
  const [branchDialog, setBranchDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEditBranchFunc = (e, data) => {
    setBranchDialog(true);
    setSelectedBranchName(data.name);
    setSelectedBranchId(data.m_id);
  };

  const handleEditBranch = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("management_id", users.management_id);
    formdata.append("m_id", selectedBrachId);

    var error = [];

    if (
      formdata.get("branch_name").length === 0 ||
      formdata.get("branch_name").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Date");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("hr/edit/existing-branch", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            setBranchDialog(false);
            updateBranchList();
            setSelectedBranchId(null);
            setSelectedBranchName(null);
            e.target.reset();
            Notify.successRequest("edit branch");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("Password");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "branches",
          items: [{ name: "Dashboard", path: "/sph/app" }],
        }}
        title="Branches"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell align="center">ID</TableCell> */}
                    <TableCell align="center">Branch Name</TableCell>
                    <TableCell align="center">Address</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {branchList.ready &&
                    branchList.data.length > 0 &&
                    branchList.data.map((data, index) => (
                      <TableRow key={index}>
                        {/* <TableCell>{data.management_id}</TableCell> */}
                        <TableCell>{data.name}</TableCell>
                        <TableCell>{data.address}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            color="primary"
                            onClick={(e) => handleEditBranchFunc(e, data)}
                          >
                            <Edit />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={4}>
            <AddBranches getAllBraches={() => updateBranchList()} />
          </Grid>
        </Grid>
        <Dialog
          open={branchDialog}
          onClose={() => setBranchDialog(false)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>Edit Branch</DialogTitle>
          <DialogContent>
            <form onSubmit={handleEditBranch}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box>
                    <TextField
                      fullWidth
                      name="branch_name"
                      label="Branch Name"
                      variant="outlined"
                      defaultValue={selectedBrachName}
                    />
                  </Box>
                </Grid>

                <Box m={1} mb={2} mt={1} display="flex">
                  <Box flexGrow={1} />
                  <Button
                    variant="contained"
                    color="inherit"
                    type="reset"
                    startIcon={<HighlightOff />}
                    onClick={() => setBranchDialog(false)}
                  >
                    Close
                  </Button>

                  <Box ml={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <CheckCircleOutline />
                        )
                      }
                      disabled={isSubmitting}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
};

export default Branches;
