import {
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  Button,
  TextField,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import {
  faArrowRight,
  faEdit,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Search } from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MedicalCertificateForm from "./MedicalCertificateForm";
import ButtonV3 from "src/components/ButtonV3";

const MedicalCertificateList = ({ setSelectedIndex, patient }) => {
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const [listShown, setlistShown] = useState(true);
  const [formShown, setformShown] = useState(false);

  const handleClickList = (event) => {
    setlistShown(true);
    setformShown(false);
  };

  const handleClickForm = (event) => {
    setformShown(true);
    setlistShown(false);
  };

  return (
    <div className="flex flex-col gap-y-6">
      {listShown && (
        <Fragment>
          <div className="flex flex-row justify-content-between gap-1">
            <div>
              <h4 className="text-lg font-bold mb-0">Medical Certificates</h4>
              <p className="text-sm">3 Total List of Medical Certificates</p>
            </div>
            <div className="flex align-items-center">
              <div className="flex flex-row align-items-center gap-3">
                <div>
                  <TextField
                    label="Search"
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Search color="default" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <ButtonV3 onClick={handleClickForm}>
                    <div className="flex">
                      <FontAwesomeIcon
                        icon={faPlus}
                        color="primary"
                        style={{ fontSize: 18 }}
                        className="pr-2"
                      />
                      Add New Med Cert.
                    </div>
                  </ButtonV3>
                </div>
              </div>
            </div>
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-uppercase">
                    <strong> Date </strong>
                  </TableCell>
                  <TableCell className="text-uppercase">
                    <strong> Diagnosis </strong>
                  </TableCell>
                  <TableCell className="text-uppercase">
                    <strong> Action </strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1" hover>
                  <TableCell>Apr 22, 2023</TableCell>
                  <TableCell>Arrhythmia or irregular heartbeat</TableCell>
                  <TableCell>
                    <div className="d-flex flex-row gap-2">
                      <div>
                        <ButtonV3 type="primary-outline">
                          <div className="flex items-center">
                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                            Edit
                          </div>
                        </ButtonV3>
                      </div>
                      <div>
                        <ButtonV3 type="tertiary-outline">
                          <div className="flex items-center">
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />
                            Delete
                          </div>
                        </ButtonV3>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow key="1" hover>
                  <TableCell>Mar 08, 2023</TableCell>
                  <TableCell>Inflammation of muscles and joints</TableCell>
                  <TableCell>
                    <div className="d-flex flex-row gap-2">
                      <div>
                        <ButtonV3 type="primary-outline">
                          <div className="flex items-center">
                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                            Edit
                          </div>
                        </ButtonV3>
                      </div>
                      <div>
                        <ButtonV3 type="tertiary-outline">
                          <div className="flex items-center">
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />
                            Delete
                          </div>
                        </ButtonV3>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow key="1" hover>
                  <TableCell>Dec 10, 2022</TableCell>
                  <TableCell>
                    Body cannot utilize insulin efficiently and thus fails to
                    regulate blood sugar levels.{" "}
                  </TableCell>
                  <TableCell>
                    <div className="d-flex flex-row gap-2">
                      <div>
                        <ButtonV3 type="primary-outline">
                          <div className="flex items-center">
                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                            Edit
                          </div>
                        </ButtonV3>
                      </div>
                      <div>
                        <ButtonV3 type="tertiary-outline">
                          <div className="flex items-center">
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />
                            Delete
                          </div>
                        </ButtonV3>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={5}
              rowsPerPageOptions={[10, 50, 100]}
              page={5}
              onChangePage={handleChangePage}
              rowsPerPage={5}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="List"
            />
          </TableContainer>
        </Fragment>
      )}

      {formShown && (
        <MedicalCertificateForm
          handleClickList={handleClickList}
          setSelectedIndex={setSelectedIndex}
          patient={patient}
        />
      )}
    </div>
  );
};

export default MedicalCertificateList;
