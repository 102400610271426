import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import { faUserInjured } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Fade } from "react-reveal";
import Img from "src/components/Img";
import { Link } from "react-router-dom";
import { replaceFname, replaceLname, replaceMname } from "src/helpers/utils";
import { UsersData } from "src/ContextAPI";
import ConfirmationModal from "src/components/modal/ConfirmationModal";
import { toast } from "react-toastify";
import UpdateCisPatientProfileModal from "src/disease-treatment/components/UpdateCisPatientProfileModal";

import { v4 as uuidv4 } from "uuid";
const CisPatientList = () => {
	const patient_profile_ref = useRef(null);
	const confirm_delete_ref = useRef(null);
	const updateProfileRef = useRef(null);

	const { users } = useContext(UsersData);
	const [view, setView] = useState("table");

	const [list, setList] = useState([]);
	const {
		data,
		meta,
		loading,
		setPage,
		setPaginate,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
		removeFromList,
	} = useDataTable("v1/patients", setList);
	const [patient, setPatient] = useState(null);
	const reason_for_edit_ref = useRef(null);
	const pho_form_ref = useRef(null);

	useEffect(() => {
		if (data?.data) {
			setList(data?.data || []);
		}
	}, [data]);

	const deleteData = (id) => {
		return axios.post(`/v1/pho/patients/delete/${id}`, {
			_method: "DELETE",
		});
	};

	const columns = useMemo(
		() => [
			{
				header: "",
				accessorKey: "patient",
				headerClassName: "min-w-[60px] w-[60px]",
				cell: function (data) {
					return (
						<div
							className="flex items-center gap-3 cursor-pointer"
							onClick={() => {
								patient_profile_ref.current.show(data);
							}}
						>
							<div className="w-11 h-11 rounded-full flex items-center justify-center">
								<Img
									type="user"
									src={data?.avatar}
									name={`${data?.lastname}-${data?.firstname}-${data?.middle}`}
									className="w-full  aspect-square object-cover rounded-full"
									alt=""
								/>
							</div>
						</div>
					);
				},
			},
			{
				header: "Lastname",
				accessorKey: "lastname",
				sortable: true,
			},
			{
				header: "Firstname",
				accessorKey: "firstname",
				sortable: true,
			},
			{
				header: "Middlename",
				accessorKey: "middle",
				sortable: true,
			},
			{
				header: "Municipality",
				accessorKey: "address",
				cell: (data) => {
					return data?.municipality;
				},
			},
			{
				header: "Barangay",
				accessorKey: "address",
				cell: (data) => {
					return data?.barangay;
				},
			},
			{
				header: "Purok",
				accessorKey: "address",
				cell: (data) => {
					return data?.purok;
				},
			},
			{
				header: "Action",
				accessorKey: "action",
				className: "w-[244px]",
				cell: (data) => {
					return (
						<div className="flex items-center gap-2">
							{/* <ActionBtn
								size="sm"
								type="foreground-light"
								onClick={() => {
									patient_profile_ref.current.show(data);
								}}
							>
								<FlatIcon
									icon="rr-eye"
									className="text-lg mr-2"
								/>
								View
							</ActionBtn> */}

							<ActionBtn
								size="sm"
								type="primary"
								onClick={() => {
									setPatient(data);
									setTimeout(() => {
										updateProfileRef.current.show();
									}, 300);
									// pho_form_ref.current.show(data);
									/* reason_for_edit_ref.current.show(
												data
											); */
								}}
								className="!no-underline"
							>
								<FlatIcon
									icon="rr-edit"
									className="text-lg mr-2"
								/>
								Edit
							</ActionBtn>

							<ActionBtn
								size="sm"
								type="danger"
								onClick={() => {
									confirm_delete_ref.current.show({
										title: "Delete patient",
										message:
											"Are you sure to delete patient?",
										footer: ({
											loadingBtn,
											setLoadingBtn,
										}) => {
											return (
												<div className="flex items-center gap-4 w-full justify-end">
													<ActionBtn
														size="sm"
														className="px-3"
														type="primary-gradient"
														onClick={() => {
															confirm_delete_ref.current.hide();
														}}
													>
														No
													</ActionBtn>
													<ActionBtn
														size="sm"
														className="px-3"
														type="danger"
														loading={loadingBtn}
														onClick={() => {
															setLoadingBtn(true);
															deleteData(
																data.id
															).then((res) => {
																setTimeout(
																	() => {
																		setLoadingBtn(
																			false
																		);
																		removeFromList(
																			data
																		);
																		toast.success(
																			"Patient successfully deleted!"
																		);
																	},
																	200
																);
																confirm_delete_ref.current.hide();
															});
														}}
													>
														Confirm delete
													</ActionBtn>
												</div>
											);
										},
									});
								}}
							>
								<FlatIcon
									icon="rr-trash"
									className="text-lg mr-2"
								/>
								Delete
							</ActionBtn>
						</div>
					);
				},
			},
		],
		[]
	);
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faUserInjured}
						color="white"
						size={"2x"}
					/>
				}
				title="Patient List"
				subtitle="View, Edit, and Delete     Patients"
			>
				{console.log("userrrr", users)}
				<CardContainer
					containerClassName={` border border- border-opacity-10`}
					header={{
						title: "Patient List Data",
						children: (
							<div className="md:ml-auto flex md:items-center flex-col md:flex-row md:justify-end flex-wrap gap-3 md:w-[calc(100%-160px)]">
								<div className="flex items-center flex-wrap gap-3"></div>
								<div className="flex flex-row lg:flex-col gap-3">
									<TextInputField
										inputClassName=" lg:pl-10"
										className="w-full"
										icon={
											<FlatIcon
												icon="rr-search"
												className="text-sm"
											/>
										}
										placeholder="Search patient"
										onChange={(e) => {
											console.log(
												"onChange",
												e.target.value
											);
											setFilters((prevFilters) => ({
												...prevFilters,
												keyword: e.target.value,
											}));
										}}
									/>
								</div>
							</div>
						),
					}}
					className="!p-0"
				>
					<TableV2
						paginationClassName="px-4"
						columns={columns}
						pagination={true}
						loading={loading}
						data={list}
						column={column}
						meta={meta}
						setColumn={setColumn}
						direction={direction}
						setDirection={setDirection}
						onTableChange={(data) => {
							setPage(data.pageIndex + 1);
							setPaginate(data.pageSize);
						}}
					/>
				</CardContainer>
			</LayoutContainer>

			<ConfirmationModal ref={confirm_delete_ref} />

			<UpdateCisPatientProfileModal
				ref={updateProfileRef}
				patient={patient}
				noRedirect={true}
				onSuccess={() => {
					setFilters((prevFils) => ({
						...prevFils,
						key: `patient-${uuidv4()}`,
					}));
					updateProfileRef.current.hide();
				}}
			/>
		</>
	);
};

export default CisPatientList;
