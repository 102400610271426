import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";

const CalamityForm = ({
	Controller,
	register,
	errors,
	setValue,
	control,
	watch,
}) => {
	const calamities = [
		{
			label: "1. Typhoon",
		},
		{
			label: "2. Flood",
		},
		{
			label: "3. Drought",
		},
		{
			label: "4. Earthquake",
		},
		{
			label: "5. Volcanic Eruption",
		},
		{
			label: "6. Landslide",
		},
		{
			label: "7. Tsunami",
		},
		{
			label: "8. Fire",
		},
		{
			label: "9. Forest Fire",
		},
		{
			label: "10. Armed Conflict",
		},
		{
			label: "11. Other Calamity",
		},
	];
	return (
		<div className="flex flex-col gap-y-8 patient-form">
			<div className="flex flex-col gap-6">
				<FormControl className="">
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						error={errors?.calamity_experienced}
					>
						O1. Have you experienced any calamity in the last 6
						months?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.calamity_experienced && (
						<FormHelperText error={errors?.calamity_experienced}>
							This field is required.
						</FormHelperText>
					)}
					<Controller
						rules={{ required: true }}
						name="calamity_experienced"
						control={control}
						render={({ field }) => (
							<RadioGroup
								{...field}
								key={`field-${field?.value}`}
							>
								<FormControlLabel
									className="!mb-0"
									value="yes"
									label="1 Yes"
									control={<Radio />}
								/>
								<FormControlLabel
									className="!mb-0"
									value="no"
									label="2 No"
									control={<Radio />}
								/>
							</RadioGroup>
						)}
					/>
				</FormControl>
				{watch("calamity_experienced") == "yes" ? (
					<FormGroup className="">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							error={errors?.calamity}
						>
							O2. Which calamity have you experienced?
							<span className="text-red-500">*</span>
						</FormLabel>
						{errors?.calamity && (
							<FormHelperText error={errors?.calamity}>
								This field is required.
							</FormHelperText>
						)}

						<div className="flex flex-col gap-y-3 py-3">
							{calamities.map((data, index) => {
								return (
									<label className="flex items-center gap-3">
										<span className="scale-[1.15]">
											<input
												type="checkbox"
												value={index + 1}
												{...register("calamity", {
													required: true,
												})}
											/>
										</span>
										<span className="text-sm form-label">
											{data.label}
										</span>
									</label>
								);
							})}
						</div>
					</FormGroup>
				) : (
					""
				)}
			</div>
			<TextField
				className="lg:w-2/3"
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={
					<>
						O3. What assistance did you receive from the government?
						<span className="text-danger ml-2">*</span>
					</>
				}
				variant="outlined"
				{...register("assistancecalamity", {
					requred: true,
				})}
				error={errors?.assistancecalamity}
				helperText={
					errors?.assistancecalamity && "This field is required"
				}
			/>
		</div>
	);
};

export default CalamityForm;
