import {
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableCell,
  TableBody,
  Typography,
  TableRow,
  TextField,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FormHeader from "./FormHeader";
import { useReactToPrint } from "react-to-print";
import { CheckSquare, Edit, Printer, Square, XCircle } from "react-feather";
import Notify from "src/notification/Notify";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import moment from "moment";
import IsMountedRef from "src/utils/IsMountedRef";

const FormOne = ({
  patient_id,
  trace_number,
  info,
  doctorsList,
  allowEdit,
}) => {
  const [enableEdit, setEnableEdit] = React.useState(false);
  const printableRef = React.useRef();
  const [admittingDx, setAdmittingDx] = useState("");
  const [admittingDoctor, setAdmittingDoctor] = useState("");
  const [admissionDate, setAdmissionDate] = useState(null);
  const [dischargedDate, setDischargedDate] = useState(null);
  const { users } = React.useContext(UsersData);
  const [isProcess, setIsProcess] = useState(false);

  const [chartRd, setChartRd] = useState(false);
  const [chartType, setChartType] = useState("for-save");

  const mounted = IsMountedRef();

  // checklist
  const [checkList, setCheckList] = useState({
    fontSheet: false,
    ascCovidForm: false,
    surgicalConsent: false,
    cardioClearance: false,
    laboratory: false,
    perioperativeRecord: false,
    medicationTreatmentSheet: false,

    doctorConsultationForm: false,
    whoSurgicalChecklist: false,
    operativeRecord: false,
    anesthesiaRecord: false,

    doctorsOrder: false,
    pacuNurseNotes: false,
    dischargedInstruction: false,
    medicalAbstract: false,
    nurseBedsideNotes: false,
    phicCF4: false,
    billing: false,
  });

  const [nurseInCharge, setNurseInCharge] = useState("");
  const [medRecordAssociates, setMedRecordAssociates] = useState("");

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  // const checkSelectedDoctor = (selDoctor) => {
  //   if (doctorsList.length > 0) {
  //     let xx = doctorsList.filter((x) => x.doctors_id === selDoctor);
  //     if (xx.length > 0) {
  //       return xx[0].name;
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  const handleSaveFrontPage = (e) => {
    e.preventDefault();
    e.persist();

    var err = [];
    var form = new FormData(e.target);
    form.append("management_id", users.management_id);
    form.append("main_mgmt_id", users.main_mgmt_id);
    form.append("user_id", users.user_id);
    form.append("patient_id", patient_id);
    form.append("trace_number", trace_number);
    form.append("checklist_front_sheet", checkList.fontSheet ? 1 : 0);
    form.append("checklist_covid_form", checkList.ascCovidForm ? 1 : 0);
    form.append(
      "checklist_surgical_consent",
      checkList.surgicalConsent ? 1 : 0
    );
    form.append(
      "checklist_cardio_clearance",
      checkList.cardioClearance ? 1 : 0
    );
    form.append("checklist_laboratory", checkList.laboratory ? 1 : 0);
    form.append(
      "checklist_periorative_record",
      checkList.perioperativeRecord ? 1 : 0
    );
    form.append(
      "checklist_medication_treatment_sheet",
      checkList.medicationTreatmentSheet ? 1 : 0
    );
    form.append(
      "checklist_doctor_consultation_form",
      checkList.doctorConsultationForm ? 1 : 0
    );
    form.append(
      "checklist_who_surgical_checklist",
      checkList.whoSurgicalChecklist ? 1 : 0
    );
    form.append(
      "checklist_operative_record",
      checkList.operativeRecord ? 1 : 0
    );
    form.append(
      "checklist_anesthesia_record",
      checkList.anesthesiaRecord ? 1 : 0
    );
    form.append("checklist_doctors_order", checkList.doctorsOrder ? 1 : 0);
    form.append("checklist_pacu_nursenotes", checkList.pacuNurseNotes ? 1 : 0);
    form.append(
      "checklist_discharged_instruction",
      checkList.dischargedInstruction ? 1 : 0
    );
    form.append(
      "checklist_medical_abstract",
      checkList.medicalAbstract ? 1 : 0
    );
    form.append(
      "checklist_nurse_bedsidenotes",
      checkList.nurseBedsideNotes ? 1 : 0
    );
    form.append("checklist_phic_cf4", checkList.phicCF4 ? 1 : 0);
    form.append("checklist_billing", checkList.billing ? 1 : 0);

    form.append("admitting_dx", admittingDx);
    form.append("admitting_doctors", admittingDoctor);
    form.append(
      "discharged_datetime",
      dischargedDate === null
        ? ""
        : moment(dischargedDate).format("YYYY-MM-DD HH:mm:ss")
    );
    form.append(
      "admission_datetime",
      admissionDate === null
        ? ""
        : moment(admissionDate).format("YYYY-MM-DD HH:mm:ss")
    );

    form.append("nurse_incharge", nurseInCharge);
    form.append("med_record_associates", medRecordAssociates);

    form.append("chart_type", chartType);

    if (
      form.get("admitting_dx").trim() === "" ||
      form.get("admitting_dx").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("admitting dx");
    }

    if (
      form.get("admitting_doctors").trim() === "" ||
      form.get("admitting_doctors").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("admitting doctor");
    }

    if (err.length > 0) {
      console.log("form updated");
    } else {
      setIsProcess(true);
      axios
        .post("or/charts/patient/patient-newfrontpage", form)
        .then((response) => {
          let data = response.data;
          if (data.message === "success") {
            setEnableEdit(false);
            getChartFrontPageData();
            Notify.successRequest("Chart updated");
          }
        })
        .catch((err) => console.log(err.message))
        .finally(() => setIsProcess(false));
    }
  };

  const getChartFrontPageData = React.useCallback(() => {
    axios
      .get("or/charts/patient/patient-getfrontpage", {
        params: {
          patient_id,
          trace_number,
        },
      })
      .then((response) => {
        let data = response.data;
        if (mounted.current) {
          if (data.length) {
            let data = response.data[0];
            setChartType("for-update");
            setAdmittingDx(data.admitting_dx === null ? "" : data.admitting_dx);
            setAdmittingDoctor(
              data.admitting_doctors === null ? "" : data.admitting_doctors
            );
            setAdmissionDate(
              data.admission_datetime === null ? "" : data.admission_datetime
            );
            setDischargedDate(
              data.discharged_datetime === null ? "" : data.discharged_datetime
            );

            setCheckList({
              fontSheet: Boolean(
                parseInt(data.checklist_front_sheet) ? true : false
              ),
              ascCovidForm: Boolean(
                parseInt(data.checklist_covid_form) ? true : false
              ),
              surgicalConsent: Boolean(
                parseInt(data.checklist_surgical_consent) ? true : false
              ),
              cardioClearance: Boolean(
                parseInt(data.checklist_cardio_clearance) ? true : false
              ),
              laboratory: Boolean(
                parseInt(data.checklist_laboratory) ? true : false
              ),
              perioperativeRecord: Boolean(
                parseInt(data.checklist_periorative_record) ? true : false
              ),
              medicationTreatmentSheet: Boolean(
                parseInt(data.checklist_medication_treatment_sheet)
                  ? true
                  : false
              ),

              doctorConsultationForm: Boolean(
                parseInt(data.checklist_doctor_consultation_form) ? true : false
              ),
              whoSurgicalChecklist: Boolean(
                parseInt(data.checklist_who_surgical_checklist) ? true : false
              ),
              operativeRecord: Boolean(
                parseInt(data.checklist_operative_record) ? true : false
              ),
              anesthesiaRecord: Boolean(
                parseInt(data.checklist_anesthesia_record) ? true : false
              ),

              doctorsOrder: Boolean(
                parseInt(data.checklist_doctors_order) ? true : false
              ),
              pacuNurseNotes: Boolean(
                parseInt(data.checklist_pacu_nursenotes) ? true : false
              ),
              dischargedInstruction: Boolean(
                parseInt(data.checklist_discharged_instruction) ? true : false
              ),
              medicalAbstract: Boolean(
                parseInt(data.checklist_medical_abstract) ? true : false
              ),
              nurseBedsideNotes: Boolean(
                parseInt(data.checklist_nurse_bedsidenotes) ? true : false
              ),
              phicCF4: Boolean(
                parseInt(data.checklist_phic_cf4) ? true : false
              ),
              billing: Boolean(parseInt(data.checklist_billing) ? true : false),
            });

            setNurseInCharge(
              data.nurse_incharge === null ? "" : data.nurse_incharge
            );
            setMedRecordAssociates(
              data.med_record_associates === null
                ? ""
                : data.med_record_associates
            );
          }
        }
      })
      .catch((err) => console.log("error: ", err.message))
      .finally(() => setChartRd(true));
  }, [patient_id, trace_number, mounted]);

  useEffect(() => {
    getChartFrontPageData();
  }, [getChartFrontPageData]);

  return (
    <Card ref={printableRef}>
      <CardContent>
        <Box>
          <FormHeader />
        </Box>

        <form onSubmit={handleSaveFrontPage}>
          <Box>
            <Box my={3}>
              <Box
                display="flex"
                justifyContent="flex-end"
                className="d-print-none"
                mb={1}
              >
                {enableEdit ? (
                  <>
                    <Box>
                      <Button
                        color="secondary"
                        onClick={() => setEnableEdit(false)}
                        startIcon={<XCircle />}
                      >
                        Close
                      </Button>
                    </Box>

                    <Box ml={2}>
                      <Button
                        color="primary"
                        startIcon={
                          isProcess ? (
                            <CircularProgress size={22} color="inherit" />
                          ) : (
                            <CheckSquare />
                          )
                        }
                        type="submit"
                        disabled={isProcess}
                      >
                        Save
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    {allowEdit && (
                      <Box mr={2}>
                        <Button
                          color="primary"
                          onClick={() => setEnableEdit(true)}
                          startIcon={<Edit />}
                        >
                          Edit
                        </Button>
                      </Box>
                    )}

                    <Box>
                      <Button
                        color="primary"
                        onClick={handlePrint}
                        startIcon={<Printer />}
                      >
                        Print
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            {chartRd ? (
              <Box>
                <Table className="table-bordered">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Box>
                          <Typography variant="subtitle2">
                            Last Name:
                          </Typography>
                          <TextField
                            fullWidth
                            name=""
                            multiline
                            value={info ? info.lastname : ""}
                            InputProps={{
                              disableUnderline: true,
                              disabled: enableEdit,
                            }}
                          />
                        </Box>
                      </TableCell>

                      <TableCell colSpan={4}>
                        <Box>
                          <Typography variant="subtitle2">
                            First Name:
                          </Typography>
                          <TextField
                            fullWidth
                            name=""
                            value={info ? info.firstname : ""}
                            InputProps={{
                              disableUnderline: true,
                              disabled: enableEdit,
                            }}
                          />
                        </Box>
                      </TableCell>

                      <TableCell colSpan={4}>
                        <Box>
                          <Typography variant="subtitle2">
                            Middle Name:{" "}
                          </Typography>
                          <TextField
                            fullWidth
                            name=""
                            value={
                              info
                                ? info.middle === null
                                  ? ""
                                  : info.middle
                                : ""
                            }
                            InputProps={{
                              disableUnderline: true,
                              disabled: enableEdit,
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={12}>
                        <Box>
                          <Typography variant="subtitle2">
                            Admitting Dx:
                          </Typography>
                          {!enableEdit ? (
                            <Typography className="gtc-capitalize">
                              {admittingDx}
                            </Typography>
                          ) : (
                            <TextField
                              fullWidth
                              name=""
                              value={admittingDx}
                              InputProps={{
                                disableUnderline: !enableEdit,
                                readOnly: !enableEdit,
                              }}
                              onChange={(e) => setAdmittingDx(e.target.value)}
                              multiline
                            />
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={12}>
                        <Box className="charts-select">
                          <Typography variant="subtitle2">
                            Admitting Doctor:
                          </Typography>
                          {!enableEdit ? (
                            <Typography className="gtc-capitalize">
                              {/* {checkSelectedDoctor(admittingDoctor)} */}
                              {admittingDoctor}
                            </Typography>
                          ) : (
                            <TextField
                              fullWidth
                              name=""
                              value={admittingDoctor}
                              InputProps={{
                                disableUnderline: !enableEdit,
                                readOnly: !enableEdit,
                              }}
                              onChange={(e) =>
                                setAdmittingDoctor(e.target.value)
                              }
                              multiline
                            />

                            // <TextField
                            //   fullWidth
                            //   name=""
                            //   value={admittingDoctor}
                            //   InputProps={{
                            //     disableUnderline: !enableEdit,
                            //     style: {
                            //       textTransform: "capitalize",
                            //     },
                            //   }}
                            //   select
                            //   SelectProps={{ native: true }}
                            //   InputLabelProps={{ shrink: true }}
                            //   readOnly={!enableEdit}
                            //   onChange={(e) =>
                            //     setAdmittingDoctor(e.target.value)
                            //   }
                            // >
                            //   <option value=""> Select </option>
                            //   {doctorsList.length > 0 ? (
                            //     doctorsList.map((data, key) => (
                            //       <option key={key} value={data.doctors_id}>
                            //         {data.name}
                            //       </option>
                            //     ))
                            //   ) : (
                            //     <option value=""> No doctor found. </option>
                            //   )}
                            // </TextField>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">
                            Admission Date:
                          </Typography>

                          <DatePicker
                            format="dd/MM/yyyy"
                            label=""
                            views={["year", "month", "date"]}
                            value={admissionDate}
                            onChange={(date) => setAdmissionDate(date)}
                            readOnly={!enableEdit}
                            InputProps={{
                              disableUnderline: !enableEdit,
                            }}
                            autoOk
                          />
                        </Box>
                      </TableCell>

                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">
                            Admission Time:
                          </Typography>
                          <TimePicker
                            autoOk
                            label=""
                            value={admissionDate}
                            onChange={(date) => setAdmissionDate(date)}
                            readOnly={!enableEdit}
                            InputProps={{
                              disableUnderline: !enableEdit,
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">
                            Discharge Date:
                          </Typography>

                          <DatePicker
                            format="dd/MM/yyyy"
                            label=""
                            views={["year", "month", "date"]}
                            value={dischargedDate}
                            onChange={(date) => setDischargedDate(date)}
                            readOnly={!enableEdit}
                            autoOk
                            InputProps={{
                              disableUnderline: !enableEdit,
                            }}
                          />
                        </Box>
                      </TableCell>

                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">
                            Discharge Time:
                          </Typography>
                          <TimePicker
                            autoOk
                            label=""
                            value={dischargedDate}
                            onChange={(date) => setDischargedDate(date)}
                            readOnly={!enableEdit}
                            InputProps={{
                              disableUnderline: !enableEdit,
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Box my={5}>
                  <Box mb={3}>
                    <Typography align="center">
                      <b> Document Checklist </b>
                    </Typography>
                  </Box>

                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  fontSheet: !checkList.fontSheet,
                                })
                              }
                            >
                              {checkList.fontSheet ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Front Sheet
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  ascCovidForm: !checkList.ascCovidForm,
                                })
                              }
                            >
                              {checkList.ascCovidForm ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            ASC COVID-19 Triage form
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  surgicalConsent: !checkList.surgicalConsent,
                                })
                              }
                            >
                              {checkList.surgicalConsent ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Informed Consent to Medical and Surgical Procedure
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  cardioClearance: !checkList.cardioClearance,
                                })
                              }
                            >
                              {checkList.cardioClearance ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Cardio Pulmonary Clearance
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  laboratory: !checkList.laboratory,
                                })
                              }
                            >
                              {checkList.laboratory ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Laboratory
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  perioperativeRecord:
                                    !checkList.perioperativeRecord,
                                })
                              }
                            >
                              {checkList.perioperativeRecord ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Perioperative Record
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  medicationTreatmentSheet:
                                    !checkList.medicationTreatmentSheet,
                                })
                              }
                            >
                              {checkList.medicationTreatmentSheet ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Medication/Treatment Sheet
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  doctorConsultationForm:
                                    !checkList.doctorConsultationForm,
                                })
                              }
                            >
                              {checkList.doctorConsultationForm ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Doctor Consultation Form
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  whoSurgicalChecklist:
                                    !checkList.whoSurgicalChecklist,
                                })
                              }
                            >
                              {checkList.whoSurgicalChecklist ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            WHO Surgical Checklist
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  operativeRecord: !checkList.operativeRecord,
                                })
                              }
                            >
                              {checkList.operativeRecord ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Operative Record
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  anesthesiaRecord: !checkList.anesthesiaRecord,
                                })
                              }
                            >
                              {checkList.anesthesiaRecord ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Anesthesia Record
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  doctorsOrder: !checkList.doctorsOrder,
                                })
                              }
                            >
                              {checkList.doctorsOrder ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Doctor's Order
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  pacuNurseNotes: !checkList.pacuNurseNotes,
                                })
                              }
                            >
                              {checkList.pacuNurseNotes ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            PACU Nurses Notes
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  dischargedInstruction:
                                    !checkList.dischargedInstruction,
                                })
                              }
                            >
                              {checkList.dischargedInstruction ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Discharged Instructions
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  medicalAbstract: !checkList.medicalAbstract,
                                })
                              }
                            >
                              {checkList.medicalAbstract ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Medical Abstract
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  nurseBedsideNotes:
                                    !checkList.nurseBedsideNotes,
                                })
                              }
                            >
                              {checkList.nurseBedsideNotes ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Nurse Bedside Notes
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  phicCF4: !checkList.phicCF4,
                                })
                              }
                            >
                              {checkList.phicCF4 ? <CheckSquare /> : <Square />}
                            </IconButton>
                            PHIC CF4
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  billing: !checkList.billing,
                                })
                              }
                            >
                              {checkList.billing ? <CheckSquare /> : <Square />}
                            </IconButton>
                            Billing
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box mt={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box align="center">
                        <Box>
                          {enableEdit ? (
                            <TextField
                              fullWidth
                              multiline
                              value={nurseInCharge}
                              onChange={(e) => setNurseInCharge(e.target.value)}
                            />
                          ) : (
                            <Box borderBottom={1}>
                              <Typography className="text-capitalize">
                                {nurseInCharge}
                              </Typography>
                            </Box>
                          )}
                        </Box>

                        <Typography align="center">Nurse In Charge</Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <Box align="center">
                        <Box>
                          {enableEdit ? (
                            <TextField
                              fullWidth
                              multiline
                              value={medRecordAssociates}
                              onChange={(e) =>
                                setMedRecordAssociates(e.target.value)
                              }
                            />
                          ) : (
                            <Box borderBottom={1}>
                              <Typography className="text-capitalize">
                                {medRecordAssociates}
                              </Typography>
                            </Box>
                          )}
                        </Box>

                        <Typography align="center">
                          Medical Records Associate
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ) : (
              Notify.loading()
            )}
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default FormOne;
