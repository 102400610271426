import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import MagicInputFields from "src/components/forms/MagicInputFields";
import { calculateAgeV2 } from "src/helpers/utils";
import CollapseDiv from "../../CollapseDiv";
import {
	ethnics,
	immunizations,
	relationship_to_hh_options,
	disabilities,
	crimes,
	where_crimes,
	vax_status_list,
	types_disease,
	marital_status_lists,
	tribes,
	ip_tribes,
	non_ip_tribes,
	choices_yes_no,
	phic_member_types,
	direct_contributor_types,
	indirect_contributor_types,
	nutritional_status,
	nutritional_status_categories,
	children_nutrition_for_wt,
	children_nutrition_for_age,
	religion_choices,
} from "./patient_choices";

const Demography = ({
	register,
	patient,
	errors,
	control,
	setValue,
	watch,
	diseases,
}) => {
	return (
		<div className="flex flex-col gap-y-6">
			<div className="grid grid-cols-1 lg:grid-cols-7 gap-6">
				<TextField
					className="lg:col-span-2"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={
						<>
							LAST NAME of the household member?
							<span className="text-danger ml-2">*</span>
						</>
					}
					variant="outlined"
					error={errors?.lastname}
					helperText={errors?.lastname && "This field is required"}
					{...register("lastname", {
						required: true,
					})}
				/>
				<TextField
					className="lg:col-span-2"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={
						<>
							FIRST NAME of the household member?
							<span className="text-danger ml-2">*</span>
						</>
					}
					variant="outlined"
					error={errors?.firstname}
					helperText={errors?.firstname && "This field is required"}
					{...register("firstname", {
						required: true,
					})}
				/>
				<TextField
					className="lg:col-span-2"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`MIDDLE NAME of the household member?`}
					variant="outlined"
					{...register("middle")}
				/>
				<TextField
					className="lg:col-span-1"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`Suffix of the household member?`}
					variant="outlined"
					{...register("suffix")}
				/>
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
				<TextField
					className="lg:col-span-1"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`Mother's First Name`}
					variant="outlined"
					{...register("mother_firstname")}
				/>
				<TextField
					className="lg:col-span-1"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`Mother's Middle Name`}
					variant="outlined"
					{...register("mother_middlename")}
				/>
				<TextField
					className="lg:col-span-1"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`Mother's Last Name`}
					variant="outlined"
					{...register("mother_lastname")}
				/>
				<TextField
					className="lg:col-span-2"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`Mother's Maiden Name`}
					variant="outlined"
					{...register("mother_maiden_name")}
				/>
				<TextField
					className="lg:col-span-1"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`Contact Number`}
					variant="outlined"
					{...register("mobile")}
				/>
			</div>
			<div className="flex gap-6">
				<FormControl>
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.head_relation}
					>
						D5. What is {watch("firstname")}'s relationship to the
						head of the household?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.head_relation && (
						<FormHelperText error={errors?.head_relation}>
							This field is required.
						</FormHelperText>
					)}
					<Controller
						rules={{ required: true }}
						name="head_relation"
						control={control}
						render={({ field }) => (
							<RadioGroup
								{...field}
								key={`head_relation-${field?.value}`}
								error={errors?.head_relation}
							>
								{relationship_to_hh_options.map((option) => {
									return (
										<FormControlLabel
											value={option.value}
											control={<Radio />}
											label={option.label}
										/>
									);
								})}
							</RadioGroup>
						)}
					/>
				</FormControl>
			</div>
			<div className="flex flex-col gap-y-6">
				<FormControl className="w-full">
					<FormLabel
						error={errors?.gender}
						id="demo-controlled-radio-buttons-group"
						className="flex"
					>
						D7. Is {watch("firstname")} male or female?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.gender && (
						<FormHelperText error={errors?.gender}>
							This field is required.
						</FormHelperText>
					)}
					<Controller
						rules={{ required: true }}
						name="gender"
						control={control}
						render={({ field }) => (
							<RadioGroup
								{...field}
								key={`gender-${field?.value}`}
							>
								<FormControlLabel
									value={"Male"}
									control={<Radio />}
									label={"Male"}
								/>
								<FormControlLabel
									value={"Female"}
									control={<Radio />}
									label={"Female"}
								/>
							</RadioGroup>
						)}
					/>
				</FormControl>
				{watch("gender") == "Female" ? (
					<FormControl className="w-full">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.pregnant}
						>
							D8. Is pregnant?
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.pregnant && (
							<FormHelperText error={errors?.pregnant}>
								This field is required.
							</FormHelperText>
						)}
						<Controller
							rules={{ required: true }}
							name="pregnant"
							control={control}
							render={({ field }) => (
								<RadioGroup
									{...field}
									key={`pregnant-${field?.value}`}
								>
									<FormControlLabel
										control={<Radio />}
										value={"Yes"}
										label={"Yes"}
									/>
									<FormControlLabel
										control={<Radio />}
										value={"No"}
										label={"No"}
									/>
								</RadioGroup>
							)}
						/>
					</FormControl>
				) : (
					""
				)}
				<FormControl className="w-full">
					<FormLabel
						error={errors?.citizenship}
						id="demo-controlled-radio-buttons-group"
						className="flex"
					>
						D7.A {watch("firstname")} CitizenShip?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.citizenship && (
						<FormHelperText error={errors?.citizenship}>
							This field is required.
						</FormHelperText>
					)}
					<Controller
						rules={{ required: true }}
						name="citizenship"
						control={control}
						render={({ field }) => (
							<RadioGroup
								{...field}
								key={`gender-${field?.value}`}
							>
								<FormControlLabel
									value={"fil"}
									control={<Radio />}
									label={"Filipino"}
								/>
								<FormControlLabel
									value={"dual"}
									control={<Radio />}
									label={"Dual Citizen"}
								/>
								<FormControlLabel
									value={"foreign"}
									control={<Radio />}
									label={"Foreign National"}
								/>
							</RadioGroup>
						)}
					/>
				</FormControl>
			</div>

			{watch("gender") == "Female" && (
				<div className="flex flex-col gap-y-7">
					<>
						{watch("pregnant") == "Yes" ? (
							<>
								<TextField
									className=" lg:w-1/3"
									SelectProps={{ native: true }}
									InputLabelProps={{ shrink: true }}
									label={`D11. Date of prenatal/clinic visit`}
									variant="outlined"
									type="date"
									error={errors?.date}
									helperText={
										errors?.date && "This field is required"
									}
									{...register("prenatal", {
										required: true,
									})}
								/>
								<FormControl className="w-full">
									<FormLabel
										error={errors?.counceling}
										id="demo-controlled-radio-buttons-group"
										className="flex"
									>
										Received Family Planning Counseling
										during prenatal visit?
										<span className="text-danger ml-2">
											*
										</span>
									</FormLabel>
									<FormHelperText error={errors?.counceling}>
										This field is required.
									</FormHelperText>
									<Controller
										rules={{ required: true }}
										name="counceling"
										control={control}
										render={({ field }) => (
											<RadioGroup
												{...field}
												key={`counceling-${field?.value}`}
											>
												<FormControlLabel
													control={<Radio />}
													value={"yes"}
													label={"Yes"}
												/>
												<FormControlLabel
													control={<Radio />}
													value={"no"}
													label={"No"}
												/>
											</RadioGroup>
										)}
									/>
								</FormControl>
								<CollapseDiv
									defaultOpen={true}
									headerClassName="bg-slate-100"
									bodyClassName="bg-white"
									title="OB History"
								>
									<div className="flex flex-col lg:flex-  flex-wrap gap-6">
										<TextField
											className="lg:w-[256px]"
											SelectProps={{ native: true }}
											InputLabelProps={{ shrink: true }}
											label={`D12. Gravida`}
											variant="outlined"
											type="number"
											error={errors?.ob_gravida}
											helperText={
												errors?.ob_gravida &&
												"This field is required"
											}
											{...register("ob_gravida", {
												required: true,
											})}
										/>

										<TextField
											className="lg:w-[256px]"
											SelectProps={{ native: true }}
											InputLabelProps={{ shrink: true }}
											label={`D13. Parity`}
											variant="outlined"
											type="number"
											error={errors?.ob_parity}
											helperText={
												errors?.ob_parity &&
												"This field is required"
											}
											{...register("ob_parity", {
												required: true,
											})}
										/>
										<TextField
											className="lg:w-[256px]"
											SelectProps={{ native: true }}
											InputLabelProps={{ shrink: true }}
											label={`D14. Abortion`}
											variant="outlined"
											type="number"
											error={errors?.ob_abortion}
											helperText={
												errors?.ob_abortion &&
												"This field is required"
											}
											{...register("ob_abortion", {
												required: true,
											})}
										/>
										<TextField
											className="lg:w-[256px]"
											SelectProps={{ native: true }}
											InputLabelProps={{ shrink: true }}
											label={`D15. Living`}
											variant="outlined"
											type="number"
											error={errors?.ob_living}
											helperText={
												errors?.ob_living &&
												"This field is required"
											}
											{...register("ob_living", {
												required: true,
											})}
										/>
									</div>
								</CollapseDiv>
							</>
						) : (
							<TextField
								className="lg:w-1/2"
								SelectProps={{ native: true }}
								InputLabelProps={{ shrink: true }}
								label={`D9. Last Menstrual Period (First day of your last period)`}
								variant="outlined"
								type="date"
								error={errors?.lastmensperiod}
								helperText={
									errors?.lastmensperiod &&
									"This field is required"
								}
								{...register("lastmensperiod", {
									required: true,
								})}
							/>
						)}
					</>
				</div>
			)}
			<div className="flex flex-col gap-6 gap-y-6 w-full">
				<TextField
					className="lg:w-[384px]"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`D17. In what month, day and year was born?`}
					variant="outlined"
					type="date"
					error={errors?.birthdate}
					helperText={errors?.birthdate && "This field is required"}
					{...register("birthdate", {
						required: true,
					})}
				/>
				<TextField
					className="lg:w-[384px]"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`D18. Age of HH member:`}
					variant="outlined"
					type="number"
					readOnly
					value={`${calculateAgeV2(new Date(watch("birthdate")))}`}
				/>
				<TextField
					className="lg:w-[384px]"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`D19. Weight of HH member (in kg):`}
					variant="outlined"
					type="number"
					error={errors?.height_cm}
					helperText={errors?.height_cm && "This field is required"}
					{...register("weight_kg", {
						required: true,
					})}
				/>
				<TextField
					className="lg:w-[384px]"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`D20. Height of HH member (in ft):`}
					variant="outlined"
					type="number"
					error={errors?.height_cm}
					helperText={errors?.height_cm && "This field is required"}
					{...register("height_cm", {
						required: true,
					})}
				/>
			</div>

			<div className="flex gap-6">
				<FormControl className="w-full">
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.registered_civil}
					>
						D22. Was {watch("firstname")}'s birth registered with
						the civil registry office?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.registered_civil && (
						<FormHelperText error={errors?.registered_civil}>
							This field is required.
						</FormHelperText>
					)}
					<Controller
						rules={{ required: true }}
						name="registered_civil"
						control={control}
						render={({ field }) => (
							<RadioGroup
								{...field}
								key={`registered_civil-${field?.value}`}
							>
								<FormControlLabel
									control={<Radio />}
									value={"yes"}
									label={"Yes"}
								/>
								<FormControlLabel
									control={<Radio />}
									value={"no"}
									label={"No"}
								/>
							</RadioGroup>
						)}
					/>
				</FormControl>
			</div>
			<div className="flex flex-col gap-y-4">
				<FormControl className="w-full">
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.civil_status}
					>
						D23. What is {watch("firstname")}'s marital (civil)
						status?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.civil_status && (
						<FormHelperText error={errors?.civil_status}>
							This field is required.
						</FormHelperText>
					)}
					<Controller
						rules={{ required: true }}
						name="civil_status"
						control={control}
						render={({ field }) => (
							<RadioGroup
								{...field}
								key={`civil_status-${field?.value}`}
							>
								{marital_status_lists.map((option) => {
									return (
										<FormControlLabel
											control={<Radio />}
											value={option.value}
											label={option.label}
										/>
									);
								})}
							</RadioGroup>
						)}
					/>
				</FormControl>

				<FormControl className="w-full">
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.tribe}
					>
						D24. What is {watch("firstname")}'s Tribe?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.tribe && (
						<FormHelperText error={errors?.tribe}>
							This field is required.
						</FormHelperText>
					)}
					<Controller
						rules={{ required: true }}
						name="tribe"
						control={control}
						render={({ field }) => (
							<RadioGroup
								{...field}
								key={`tribe-${field?.value}`}
							>
								{tribes.map((option) => {
									return (
										<FormControlLabel
											control={<Radio />}
											value={option.value}
											label={option.label}
										/>
									);
								})}
							</RadioGroup>
						)}
					/>
				</FormControl>
				{watch("tribe") == "others" && (
					<MagicInputFields
						control={control}
						label={`D28.Specify Other Tribe`}
						type="text"
						error={errors?.otherTribe}
						{...register("otherTribe", {
							required: true,
						})}
					/>
				)}
				{watch("tribe") == "ip" && (
					<MagicInputFields
						control={control}
						label={`D26.  Select IP's Tribe`}
						type="radio"
						name="ip_tribe"
						options={ip_tribes}
						error={errors?.ip_tribe}
						rules={{ required: true }}
					/>
				)}
				{watch("ip_tribe") == "others" && (
					<MagicInputFields
						control={control}
						label={`Other IP's Tribe`}
						type="text"
						error={errors?.otherIPtribe}
						{...register("otherIPtribe", {
							required: true,
						})}
					/>
				)}
				{watch("tribe") == "non_ip" && (
					<MagicInputFields
						control={control}
						label={`Select NON-IP's Tribe`}
						type="radio"
						name="noneIP"
						options={non_ip_tribes}
						error={errors?.noneIP}
						rules={{ required: true }}
					/>
				)}
				{watch("noneIP") == "others" && (
					<MagicInputFields
						control={control}
						label={`Specify NON-IP's Tribe`}
						type="text"
						error={errors?.specifyNONip}
						{...register("specifyNONip", {
							required: true,
						})}
					/>
				)}

				<MagicInputFields
					control={control}
					label={`D30. What is ${watch("firstname")}'s Religion?`}
					type="radio"
					name="religion"
					options={religion_choices}
					error={errors?.religion}
					rules={{ required: true }}
				/>
			</div>
			{/* <CollapseDiv
				defaultOpen={true}
				headerClassName="bg-slate-100"
				bodyClassName="bg-white"
				title="Salaries/wages receieved by per month?"
			>
				<div className="grid grid-cols-1 gap-7">
					<TextField
						className="w-full"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`Salaries/wages receieved by per month? (CASH)`}
						variant="outlined"
						type="number"
						error={errors?.month_cash}
						helperText={
							errors?.month_cash && "This field is required"
						}
						{...register("month_cash", {
							required: true,
						})}
					/>
					<FormControl className="w-full">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.philhealth}
						>
							Current status of PhilHealth membership
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.philhealth && (
							<FormHelperText error={errors?.philhealth}>
								This field is required.
							</FormHelperText>
						)}
						<Controller
							rules={{ required: true }}
							name="philhealth"
							control={control}
							render={({ field }) => (
								<RadioGroup
									{...field}
									key={`philhealth-stat-${field?.value}`}
								>
									<FormControlLabel
										control={<Radio />}
										value={"1"}
										label={"Direct contributor"}
									/>
									<FormControlLabel
										control={<Radio />}
										value={"2"}
										label={"Indirect contributor"}
									/>
								</RadioGroup>
							)}
						/>
					</FormControl>
				</div>
			</CollapseDiv> */}
			{console.log("errors?.phylsis", errors?.phylsis)}
			{/* <TextField
				className="lg:w-1/2"
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={`D34. PHILSYS ID Number`}
				variant="outlined"
				error={errors?.philsys}
				{...register("philsys", {
					required: true,
				})}
			/> */}

			<MagicInputFields
				control={control}
				label={`D36. Are you a member of PhilHealth?`}
				type="radio"
				name="philhealth"
				options={choices_yes_no}
				error={errors?.philhealth}
				rules={{ required: true }}
			/>

			{watch("philhealth") == "yes" && (
				<>
					<TextField
						className="lg:w-1/2"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`D36.a PHIC No.`}
						variant="outlined"
						error={errors?.phic_no}
						{...register("phic_no", {
							required: true,
						})}
					/>
					<MagicInputFields
						control={control}
						label={`D37. PHIC Member Type`}
						type="radio"
						name="phic"
						options={phic_member_types}
						error={errors?.phic}
						rules={{ required: true }}
					/>
					{watch("phic") == "direct" && (
						<MagicInputFields
							control={control}
							label={`D38. PHIC Direct Contributor`}
							type="radio"
							name="phicdirect"
							options={direct_contributor_types}
							error={errors?.phicdirect}
							rules={{ required: true }}
						/>
					)}
					{watch("phic") == "indirect" && (
						<MagicInputFields
							control={control}
							label={`D39. PHIC Indirect Contributor`}
							type="radio"
							name="phicindirect"
							options={indirect_contributor_types}
							error={errors?.phicindirect}
							rules={{ required: true }}
						/>
					)}
				</>
			)}
			{/* <CollapseDiv
				defaultOpen={true}
				headerClassName="bg-slate-100"
				bodyClassName="bg-white"
				title="H. HEALTH AND OTHER CHARACTERISTICS OF HOUSEHOLD MEMBERS"
			>
				<div className="flex flex-col gap-y-6">
					<FormGroup className="lg:w-1/2">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							error={errors?.immunization}
						>
							H1. Imunization Status
						</FormLabel>
						{errors?.immunization && (
							<FormHelperText error={errors?.immunization}>
								This field is required.
							</FormHelperText>
						)}

						<div className="flex flex-col gap-y-1 py-3">
							{immunizations.map((data, index) => {
								return (
									<label className="flex items-center gap-3">
										<span className="scale-[1.15]">
											<input
												type="checkbox"
												value={index + 1}
												{...register("immunization", {
													required: true,
												})}
											/>
										</span>
										<span className="text-sm form-label">
											{data.label}
										</span>
									</label>
								);
							})}
						</div>
					</FormGroup>
					{watch("immunization") &&
						watch("immunization").includes("4") && (
							<FormControl className="w-full">
								<FormLabel
									id="demo-controlled-radio-buttons-group"
									className="flex"
									error={errors?.selectoralpolio}
								>
									H2. Select Oral Polio Vaccine (IPV) Done
									<span className="text-danger ml-2">*</span>
								</FormLabel>
								{errors?.selectoralpolio && (
									<FormHelperText
										error={errors?.selectoralpolio}
									>
										This field is required.
									</FormHelperText>
								)}
								<Controller
									rules={{ required: true }}
									name="selectoralpolio"
									control={control}
									render={({ field }) => (
										<RadioGroup
											{...field}
											key={`selectoralpolio-${field?.value}`}
										>
											<FormControlLabel
												control={<Radio />}
												value={"opv1"}
												label={"OPV1"}
											/>
											<FormControlLabel
												control={<Radio />}
												value={"opv2"}
												label={"OPV2"}
											/>
											<FormControlLabel
												control={<Radio />}
												value={"opv3"}
												label={"OPV3"}
											/>
										</RadioGroup>
									)}
								/>
							</FormControl>
						)}
					{watch("immunization") &&
						watch("immunization").includes("3") && (
							<FormControl className="w-full">
								<FormLabel
									id="demo-controlled-radio-buttons-group"
									className="flex"
									error={errors?.specifypenta}
								>
									H3. Select Pentavalent Vaccine
									(DPT-HepB-Hib) Done
									<span className="text-danger ml-2">*</span>
								</FormLabel>
								{errors?.specifypenta && (
									<FormHelperText
										error={errors?.specifypenta}
									>
										This field is required.
									</FormHelperText>
								)}
								<Controller
									rules={{ required: true }}
									name="specifypenta"
									control={control}
									render={({ field }) => (
										<RadioGroup
											{...field}
											key={`specifypenta-${field?.value}`}
										>
											<FormControlLabel
												control={<Radio />}
												value={"penta1"}
												label={"Penta1"}
											/>
											<FormControlLabel
												control={<Radio />}
												value={"penta2"}
												label={"Penta2"}
											/>
											<FormControlLabel
												control={<Radio />}
												value={"penta3"}
												label={"Penta3"}
											/>
										</RadioGroup>
									)}
								/>
							</FormControl>
						)}

					{watch("immunization") &&
						watch("immunization").includes("7") && (
							<FormControl className="w-full">
								<FormLabel
									id="demo-controlled-radio-buttons-group"
									className="flex"
									error={errors?.selectmmr}
								>
									H4. Select Measles, Mumps, Rubella (MMR)
									Done
									<span className="text-danger ml-2">*</span>
								</FormLabel>
								{errors?.selectmmr && (
									<FormHelperText error={errors?.selectmmr}>
										This field is required.
									</FormHelperText>
								)}
								<Controller
									rules={{ required: true }}
									name="selectmmr"
									control={control}
									render={({ field }) => (
										<RadioGroup
											{...field}
											key={`selectmmr-${field?.value}`}
										>
											<FormControlLabel
												control={<Radio />}
												value={"mcv1"}
												label={"MCV1"}
											/>
											<FormControlLabel
												control={<Radio />}
												value={"mcv2"}
												label={"MCV2"}
											/>
										</RadioGroup>
									)}
								/>
							</FormControl>
						)}
					{watch("immunization") &&
						watch("immunization").includes("10") && (
							<FormControl className="w-full">
								<FormLabel
									id="demo-controlled-radio-buttons-group"
									className="flex"
									error={errors?.selecthpv}
								>
									H5. Select Human Papilloma Vaccine Done
									<span className="text-danger ml-2">*</span>
								</FormLabel>
								{errors?.selecthpv && (
									<FormHelperText error={errors?.selecthpv}>
										This field is required.
									</FormHelperText>
								)}
								<Controller
									rules={{ required: true }}
									name="selecthpv"
									control={control}
									render={({ field }) => (
										<RadioGroup
											{...field}
											key={`selecthpv-${field?.value}`}
										>
											<FormControlLabel
												control={<Radio />}
												value={"hpv1"}
												label={"HPV1"}
											/>
											<FormControlLabel
												control={<Radio />}
												value={"hpv2"}
												label={"HPV2"}
											/>
										</RadioGroup>
									)}
								/>
							</FormControl>
						)}

					<FormControl className="w-full">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.covid_vaccinated}
						>
							H6. Have you been vaccinated for COVID-19?
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.covid_vaccinated && (
							<FormHelperText error={errors?.covid_vaccinated}>
								This field is required.
							</FormHelperText>
						)}
						<Controller
							rules={{ required: true }}
							name="covid_vaccinated"
							control={control}
							render={({ field }) => (
								<RadioGroup
									{...field}
									key={`covid_vaccinated-${field?.value}`}
								>
									<FormControlLabel
										control={<Radio />}
										value={"yes"}
										label={"Yes"}
									/>
									<FormControlLabel
										control={<Radio />}
										value={"no"}
										label={"No"}
									/>
								</RadioGroup>
							)}
						/>
					</FormControl>
					{watch("covid_vaccinated") == "yes" ? (
						<FormControl className="w-full">
							<FormLabel
								id="demo-controlled-radio-buttons-group"
								className="flex"
								error={errors?.vax_status}
							>
								H7. Vaccination Status
								<span className="text-danger ml-2">*</span>
							</FormLabel>
							{errors?.vax_status && (
								<FormHelperText error={errors?.vax_status}>
									This field is required.
								</FormHelperText>
							)}
							<Controller
								rules={{ required: true }}
								name="vax_status"
								control={control}
								render={({ field }) => (
									<RadioGroup
										{...field}
										key={`vax_status-${field?.value}`}
									>
										{vax_status_list.map((option) => {
											return (
												<FormControlLabel
													control={<Radio />}
													value={option?.value}
													label={option?.label}
												/>
											);
										})}
									</RadioGroup>
								)}
							/>
						</FormControl>
					) : (
						""
					)}
					<FormControl className="w-full">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.dental}
						>
							H8. Have you been seen by a Dentist?
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.dental && (
							<FormHelperText error={errors?.dental}>
								This field is required.
							</FormHelperText>
						)}
						<Controller
							rules={{ required: true }}
							name="dental"
							control={control}
							render={({ field }) => (
								<RadioGroup
									{...field}
									key={`dental-${field?.value}`}
								>
									<FormControlLabel
										control={<Radio />}
										value={"1x"}
										label={"1x"}
									/>
									<FormControlLabel
										control={<Radio />}
										value={"2x"}
										label={"2x"}
									/>
									<FormControlLabel
										control={<Radio />}
										value={"3x"}
										label={"3x"}
									/>
									<FormControlLabel
										control={<Radio />}
										value={"none"}
										label={"none"}
									/>
								</RadioGroup>
							)}
						/>
					</FormControl>
					<FormControl className="w-full">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.donateblood}
						>
							H10. Have you ever donated blood?
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.donateblood && (
							<FormHelperText error={errors?.donateblood}>
								This field is required.
							</FormHelperText>
						)}
						<Controller
							rules={{ required: true }}
							name="donateblood"
							control={control}
							render={({ field }) => (
								<RadioGroup
									{...field}
									key={`donateblood-${field?.value}`}
								>
									<FormControlLabel
										control={<Radio />}
										value={"yes"}
										label={"Yes"}
									/>
									<FormControlLabel
										control={<Radio />}
										value={"no"}
										label={"No"}
									/>
								</RadioGroup>
							)}
						/>
					</FormControl>
					<FormControl className="w-full">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.solo_parent}
						>
							{" "}
							Is a solo parent taking care of a child/children?
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.solo_parent && (
							<FormHelperText error={errors?.solo_parent}>
								This field is required.
							</FormHelperText>
						)}
						<Controller
							rules={{ required: true }}
							name="solo_parent"
							control={control}
							render={({ field }) => (
								<RadioGroup
									{...field}
									key={`solo_parent-${field?.value}`}
								>
									<FormControlLabel
										control={<Radio />}
										value={"yes"}
										label={"Yes"}
									/>
									<FormControlLabel
										control={<Radio />}
										value={"no"}
										label={"No"}
									/>
								</RadioGroup>
							)}
						/>
					</FormControl>
					<FormControl className="w-full">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.physical_mental}
						>
							H12. Does {watch("firstname")} have any physical or
							mental disability?
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.physical_mental && (
							<FormHelperText error={errors?.physical_mental}>
								This field is required.
							</FormHelperText>
						)}
						<Controller
							rules={{ required: true }}
							name="physical_mental"
							control={control}
							render={({ field }) => (
								<RadioGroup
									{...field}
									key={`physical_mental-${field?.value}`}
								>
									<FormControlLabel
										control={<Radio />}
										value={"yes"}
										label={"Yes"}
									/>
									<FormControlLabel
										control={<Radio />}
										value={"no"}
										label={"No"}
									/>
								</RadioGroup>
							)}
						/>
					</FormControl>
					{console.log(
						'watch("physical_mental") ',
						watch("physical_mental")
					)}
					{watch("physical_mental") == "yes" && (
						<FormGroup className="lg:w-1/2">
							<FormLabel
								id="demo-controlled-radio-buttons-group"
								error={errors?.dismember}
							>
								H12A. What type of disability do you have?
							</FormLabel>
							{errors?.dismember && (
								<FormHelperText error={errors?.dismember}>
									This field is required.
								</FormHelperText>
							)}

							<div className="flex flex-col gap-y-1 py-3">
								{disabilities.map((data, index) => {
									return (
										<label className="flex items-center gap-3">
											<span className="scale-[1.15]">
												<input
													type="checkbox"
													value={data.value}
													{...register("dismember")}
												/>
											</span>
											<span className="text-sm form-label">
												{data.label}
											</span>
										</label>
									);
								})}
							</div>
						</FormGroup>
					)}
					<FormControl className="w-full">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.disease}
						>
							H13. Does have a disease?
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.disease && (
							<FormHelperText error={errors?.disease}>
								This field is required.
							</FormHelperText>
						)}
						<Controller
							rules={{ required: true }}
							name="disease"
							control={control}
							render={({ field }) => (
								<RadioGroup
									{...field}
									key={`disease-${field?.value}`}
								>
									<FormControlLabel
										control={<Radio />}
										value={"yes"}
										label={"Yes"}
									/>
									<FormControlLabel
										control={<Radio />}
										value={"no"}
										label={"No"}
									/>
								</RadioGroup>
							)}
						/>
					</FormControl>

					{console.log("dismember", watch("dismember"))}
					{watch("disease") == "yes" ? (
						<>
							<FormGroup className="lg:w-1/2">
								<FormLabel
									id="demo-controlled-radio-buttons-group"
									error={errors?.comnondisease}
								>
									H14. What type of communicable disease?
								</FormLabel>
								{errors?.comnondisease && (
									<FormHelperText
										error={errors?.comnondisease}
									>
										This field is required.
									</FormHelperText>
								)}
								<div className="flex flex-col !gap-y-0 py-3">
									{diseases?.map((item) => {
										if (item?.type == "communicable") {
											return (
												<label
													className="flex items-center gap-3 !mb-2 cursor-pointer"
													key={`communicable-${item?.id}`}
												>
													<span className="scale-[1.15]">
														<input
															type="checkbox"
															value={item?.id}
															{...register(
																"comnondisease"
															)}
														/>
													</span>
													<span className="text-sm form-label">
														{item?.name}
													</span>
												</label>
											);
										}
									})}
								</div>
							</FormGroup>
							<FormGroup className="lg:w-1/2">
								<FormLabel
									id="demo-controlled-radio-buttons-group"
									error={errors?.nondisease}
								>
									H15. What type of non-communicable disease?
								</FormLabel>
								{errors?.nondisease && (
									<FormHelperText error={errors?.nondisease}>
										This field is required.
									</FormHelperText>
								)}
								<div className="flex flex-col gap-y-1 py-3">
									{diseases?.map((item) => {
										if (item?.type != "communicable") {
											return (
												<label
													className="flex items-center !mb-2 gap-3 cursor-pointer"
													key={`noncommunicable-${item?.id}`}
												>
													<span className="scale-[1.15]">
														<input
															type="checkbox"
															value={item?.id}
															{...register(
																"nondisease"
															)}
														/>
													</span>
													<span className="text-sm form-label">
														{item?.name}
													</span>
												</label>
											);
										}
									})}
								</div>
							</FormGroup>
						</>
					) : (
						""
					)}
					<FormControl className="w-full">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.hhpwd}
						>
							H12C. Does have a PWD's ID?
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.hhpwd && (
							<FormHelperText error={errors?.hhpwd}>
								This field is required.
							</FormHelperText>
						)}
						<Controller
							rules={{ required: true }}
							name="hhpwd"
							control={control}
							render={({ field }) => (
								<RadioGroup
									{...field}
									key={`hhpwd-${field?.value}`}
								>
									<FormControlLabel
										control={<Radio />}
										value={"yes"}
										label={"Yes"}
									/>
									<FormControlLabel
										control={<Radio />}
										value={"no"}
										label={"No"}
									/>
								</RadioGroup>
							)}
						/>
					</FormControl>
				</div>
			</CollapseDiv> */}

			<CollapseDiv
				defaultOpen={true}
				headerClassName="bg-slate-100"
				bodyClassName="bg-white"
				title="I. CRIME"
			>
				<div className="flex flex-col gap-y-8">
					<FormControl className="w-full">
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.past_victim}
						>
							I1. Has {watch("firstname")} been a victim of crime
							in the past 12 months?
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.past_victim && (
							<FormHelperText error={errors?.past_victim}>
								This field is required.
							</FormHelperText>
						)}
						<Controller
							rules={{ required: true }}
							name="past_victim"
							control={control}
							render={({ field }) => (
								<RadioGroup
									{...field}
									key={`past_victim-${field?.value}`}
								>
									<FormControlLabel
										control={<Radio />}
										value={"yes"}
										label={"Yes"}
									/>
									<FormControlLabel
										control={<Radio />}
										value={"no"}
										label={"No"}
									/>
								</RadioGroup>
							)}
						/>
					</FormControl>
					{watch("past_victim") == "yes" ? (
						<>
							<FormGroup className="lg:w-1/2">
								<FormLabel
									id="demo-controlled-radio-buttons-group"
									error={errors?.crime_victim}
								>
									What crime/s was/were became a victim of?
								</FormLabel>
								{errors?.crime_victim && (
									<FormHelperText
										error={errors?.crime_victim}
									>
										This field is required.
									</FormHelperText>
								)}

								<div className="flex flex-col gap-y-1 py-3">
									{crimes.map((data, index) => {
										return (
											<label className="flex items-center gap-3">
												<span className="scale-[1.15]">
													<input
														type="checkbox"
														value={data.value}
														{...register(
															"crime_victim"
														)}
													/>
												</span>
												<span className="text-sm form-label">
													{data.label}
												</span>
											</label>
										);
									})}
								</div>
							</FormGroup>
							<FormGroup className="lg:w-1/2">
								<FormLabel
									id="demo-controlled-radio-buttons-group"
									error={errors?.crime_locations}
								>
									Where did the crime happen?
								</FormLabel>
								{errors?.crime_locations && (
									<FormHelperText
										error={errors?.crime_locations}
									>
										This field is required.
									</FormHelperText>
								)}

								<div className="flex flex-col gap-y-1 py-3">
									{where_crimes.map((data, index) => {
										return (
											<label className="flex items-center gap-3">
												<span className="scale-[1.15]">
													<input
														type="checkbox"
														value={data.value}
														{...register(
															"crime_locations"
														)}
													/>
												</span>
												<span className="text-sm form-label">
													{data.label}
												</span>
											</label>
										);
									})}
								</div>
							</FormGroup>
						</>
					) : (
						""
					)}
				</div>
			</CollapseDiv>
			{calculateAgeV2(new Date(watch("birthdate"))) <= 6 && (
				<CollapseDiv
					defaultOpen={true}
					headerClassName="bg-slate-100"
					bodyClassName="bg-white"
					title="J. NUTRITION"
				>
					<div className="flex flex-col lg:flex-col  flex-wrap gap-6">
						<MagicInputFields
							control={control}
							label={`J1. Nutritional Status of Childrent 0-6 Years Old (Wt. for Age)`}
							type="radio"
							name="children_nutrition_age"
							options={nutritional_status}
							error={errors?.children_nutrition_age}
							rules={{ required: true }}
						/>

						<MagicInputFields
							control={control}
							label={`J2. Category of Nutritional Status`}
							type="radio"
							name="children_nutrition_age_old_new"
							options={nutritional_status_categories}
							error={errors?.children_nutrition_age_old_new}
							rules={{ required: true }}
						/>
						<MagicInputFields
							control={control}
							label={`J3. Nutritional Status of Children 0-6 Years Old (Wt. for Ht.)`}
							type="radio"
							name="children_nutrition_wt"
							options={children_nutrition_for_wt}
							error={errors?.children_nutrition_wt}
							rules={{ required: true }}
						/>
						<MagicInputFields
							control={control}
							label={`J4. Category of Nutritional Status`}
							type="radio"
							name="childrennutritionwtoldnew"
							options={nutritional_status_categories}
							error={errors?.childrennutritionwtoldnew}
							rules={{ required: true }}
						/>
						{/* 
						<MagicInputFields
							control={control}
							label={`J5. Nutritional Status of Children 0-6 Years Old (Ht. for Age.)`}
							type="radio"
							name="childrennutritionht"
							options={children_nutrition_for_age}
							error={errors?.childrennutritionht}
							rules={{ required: true }}
						/>
						<MagicInputFields
							control={control}
							label={`J6. Category of Nutritional Status`}
							type="radio"
							name="childrennutritionhtoldnew"
							options={nutritional_status_categories}
							error={errors?.childrennutritionhtoldnew}
							rules={{ required: true }}
						/>
						<MagicInputFields
							control={control}
							className={`lg:w-1/2`}
							label={`J7. Date of record of barangay nutrition scholar`}
							type="date"
							name="datenutrition"
							error={errors?.phic_no}
							{...register("datenutrition", {
								required: true,
							})}
						/>

						<MagicInputFields
							control={control}
							label={`J8. Enrolled in Any Feeding Program`}
							type="radio"
							name="feedingprogram"
							options={choices_yes_no}
							error={errors?.feedingprogram}
							rules={{ required: true }}
						/>
						<MagicInputFields
							control={control}
							label={`J9. Enrolled in Day Care Center`}
							type="radio"
							name="carecenter"
							options={choices_yes_no}
							error={errors?.carecenter}
							rules={{ required: true }}
						/>
						<MagicInputFields
							control={control}
							label={`J10. With Dental Carries?`}
							type="radio"
							name="dentalcarries"
							options={choices_yes_no}
							error={errors?.dentalcarries}
							rules={{ required: true }}
						/>
						<MagicInputFields
							control={control}
							label={`J11. Dewormed (in the last 6 Mo)`}
							type="radio"
							name="deworm"
							options={choices_yes_no}
							error={errors?.deworm}
							rules={{ required: true }}
						/>
						<MagicInputFields
							control={control}
							label={`J12. With STH (Soil transmitted helminthiasis)`}
							type="radio"
							name="sth"
							options={choices_yes_no}
							error={errors?.sth}
							rules={{ required: true }}
						/> */}
					</div>
				</CollapseDiv>
			)}
		</div>
	);
};

export default Demography;
