import { Paper, Tabs, Tab } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import PrescriptionList from "./PrescriptionList";
import TabPanel from "src/utils/TabPanel";
import PrescriptionForm from "./PrescriptionForm";
import UnsavedPrescription from "./UnsavedPrescription";

const PrescriptionMainTab = ({ patient }) => {
	const [category, setCategory] = useState("list");
	const [dataFromUnsaved, setDataFromUnsaved] = useState([]);
	const [formValues, setFormValues] = useState({
		id: "",
		prescription: "",
		quantity: "",
		type: "",
		reamrks: "",
	});

	const [unsavedList, setUnsavedList] = useState([
		// {
		// 	prescription: "Med 1",
		// 	quantity: "7",
		// 	type: "Tablet",
		// 	remarks: "1 per day",
		// },
		// {
		// 	prescription: "Med 2",
		// 	quantity: "1",
		// 	type: "Box",
		// 	remarks: "take every 4 hours interval",
		// },
		// {
		// 	prescription: "Med 3",
		// 	quantity: "21",
		// 	type: "Capsule",
		// 	remarks: "take 3 time a day for 7 days",
		// },
	]);

	const handleChange = (event, item) => {
		setDataFromUnsaved("");
		setFormValues({});
		setCategory(item);
	};

	const handleDataUnsaved = (data) => {
		setDataFromUnsaved(data);
		handleFormValues();
		setCategory("presc-form");
	};

	const handleFormValues = (data) => {
		setFormValues(data);
		console.log(formValues);
	};

	return (
		<Fragment>
			<Paper>
				<Tabs
					value={category}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="on"
				>
					<Tab wrapped value="list" label="Prescription List" />
					<Tab
						wrapped
						value="presc-form"
						label="Create Prescription"
					/>
					{/* <Tab wrapped value="unsaved-presc" label="Unsaved Prescriptions" /> */}
				</Tabs>
			</Paper>

			<TabPanel value={category} index="list">
				<PrescriptionList
					patient_id={patient?.id}
					displayForm={() => setCategory("presc-form")}
					setFormValues={setFormValues}
					formValues={formValues}
				/>
			</TabPanel>

			<TabPanel value={category} index="presc-form">
				<PrescriptionForm
					patient_id={patient?.id}
					displayList={() => setCategory("list")}
					displayUnsave={() => setCategory("unsaved-presc")}
					dataFromUnsaved={dataFromUnsaved}
					formValues={formValues}
				/>
			</TabPanel>

			<TabPanel value={category} index="unsaved-presc">
				<UnsavedPrescription
					patient_id={patient?.id}
					displayList={() => setCategory("list")}
					displayForm={() => setCategory("presc-form")}
					onDataFromUnsaved={handleDataUnsaved}
					unsavedList={unsavedList}
				/>
			</TabPanel>
		</Fragment>
	);
};

export default PrescriptionMainTab;
