import {
  Box,
  Typography,
  Grid,
  CardContent,
  // TextField,
  Card,
  CardMedia,
  CardHeader,
} from "@material-ui/core";
// import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import React, { Fragment } from "react";
import Footer from "./Footer";
import Header from "./Header";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const StooltestOrder = ({ formheader, stooltestOrderDetails }) => {
  return (
    <Box>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        {/* <CardContent>
          <Header details={stooltestOrderDetails} />
        </CardContent> */}

        <CardContent>
          <Box>
            <Header details={stooltestOrderDetails} />
          </Box>
          {stooltestOrderDetails.data.map((data, index) => (
            <Fragment key={index}>
              {/* fecalysis order */}
              <Box hidden={!Boolean(parseInt(data.fecalysis))}>
                <Box mb={2} display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="h6" align="center">
                      <b style={{ color: "#000" }}> FECALYSIS </b>
                    </Typography>
                  </Box>
                </Box>

                <Box hidden={!Boolean(parseInt(data.is_processed))}>
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box
                          width="100%"
                          style={{
                            backgroundColor: "#AEAEAE",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#000",
                              marginLeft: 20,
                            }}
                          >
                            <b>MACROSCOPIC</b>
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box display="flex">
                          <Box mr={10}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Color :
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.color}
                            </Typography>
                          </Box>
                        </Box>
                        {/* <Box>
                          <TextField
                            label={
                              <strong style={{ color: "#000" }}>Color</strong>
                            }
                            fullWidth
                            defaultValue={data.color}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box> */}
                      </Grid>

                      <Grid item xs={6}>
                        <Box display="flex">
                          <Box mr={10}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Consistency :
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.consistency}
                            </Typography>
                          </Box>
                        </Box>

                        {/* <Box>
                          <TextField
                            label={
                              <strong style={{ color: "#000" }}>
                                Consistency
                              </strong>
                            }
                            defaultValue={data.consistency}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box> */}
                      </Grid>

                      <Grid item xs={12}>
                        <Box display={"flex"}>
                          <Box
                            width="50%"
                            style={{
                              backgroundColor: "#AEAEAE",
                            }}
                          >
                            <Typography
                              style={{
                                color: "#000",
                                marginLeft: 20,
                              }}
                            >
                              <b>MICROSCOPIC</b>
                            </Typography>
                          </Box>

                          <Box
                            width="50%"
                            style={{
                              backgroundColor: "#AEAEAE",
                            }}
                          >
                            <Typography
                              style={{
                                color: "#000",
                                marginLeft: 20,
                              }}
                            >
                              <b>PARASITE</b>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Pus Cells
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.pus_cells}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              RBC
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.reb_blood_cells}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Bacteria
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.bacteria}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Yeast Cells
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.yeast_cells}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Others
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.others}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        {/* DFS NA TANAN */}
                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Ascaris lumbricoides
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.dfs_ascaris}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Hookworm
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.dfs_hookworm}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Trichuris trichura
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.dfs_trichusris_trichuira}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Enterobius vermicularis
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.enterobius_vermicularis}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Entamoeba coli
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.entamoeba_coli}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Entamoeba Histolytica
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.entamoeba_histolytica}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box display={"flex"}>
                          <Box
                            width="50%"
                            style={{
                              backgroundColor: "#AEAEAE",
                            }}
                          >
                            <Typography
                              style={{
                                color: "#000",
                                marginLeft: 20,
                              }}
                            >
                              <b>RAPID IMMUNOASSAY</b>
                            </Typography>
                          </Box>

                          <Box
                            width="50%"
                            style={{
                              backgroundColor: "#AEAEAE",
                            }}
                          >
                            <Typography
                              style={{
                                color: "#000",
                                marginLeft: 20,
                              }}
                            >
                              <b>OTHERS</b>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Occult Blood
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.occult_blood_result}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              H. Pylori Stool
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.hpylori_stool}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box display="flex">
                          <Box mr={1} flexGrow={1}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              Remarks
                            </Typography>
                          </Box>

                          <Box align="center" width={200}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.remarks}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      {/* <Grid item xs={4}>
                        <Box>
                          <TextField
                            label={
                              <strong style={{ color: "#000" }}>
                                Occult Blood
                              </strong>
                            }
                            defaultValue={data.occult_blood_result}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>
                      </Grid> */}
                    </Grid>
                  </Box>

                  {/* <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Box mt={2}>
                          <Typography>
                            <strong style={{ color: "#000" }}>
                              Microscopy
                            </strong>
                          </Typography>
                        </Box>

                        <Box mt={3}>
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            Ascaris Lumbricoides:
                          </Typography>
                        </Box>

                        <Box>
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            Hookworm:
                          </Typography>
                        </Box>

                        <Box mt={2}>
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            Blastocystis Hominis:
                          </Typography>
                        </Box>

                        <Box mt={2.1}>
                          <Typography>
                            <strong style={{ color: "#000" }}>
                              Giardia Lamblia
                            </strong>
                          </Typography>
                        </Box>

                        <Box>
                          <Typography
                            align="right"
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            Cyst:
                          </Typography>
                        </Box>

                        <Box mt={1}>
                          <Typography
                            align="right"
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            Trophozoite:
                          </Typography>
                        </Box>

                        <Box mt={1}>
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            Trichuris Trichiura
                          </Typography>
                        </Box>

                        <Box mt={1.3}>
                          <Typography>
                            <strong style={{ color: "#000" }}>
                              Entamoeba Histolytica
                            </strong>
                          </Typography>
                        </Box>

                        <Box mt={2}>
                          <Typography
                            align="right"
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            Cyst:
                          </Typography>
                        </Box>

                        <Box mt={1}>
                          <Typography
                            align="right"
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            Trophozoite:
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={3}>
                        <Box align="center" mt={3.2}>
                          {data.dfs ? (
                            <CheckBox color="primary" />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )}
                          <strong style={{ color: "#000" }}>
                            Direct Fecal Smear
                          </strong>
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            fullWidth
                            defaultValue={data.dfs_ascaris}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            fullWidth
                            defaultValue={data.dfs_hookworm}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            defaultValue={data.dfs_blastocystis}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>

                        <Box mt={4}>
                          <TextField
                            label=""
                            defaultValue={data.dfs_giardia_lamblia_cyst}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            defaultValue={data.dfs_giardia_lamblia_trophozoite}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            name="dfs_trichuris[]"
                            fullWidth
                            defaultValue={data.dfs_trichusris_trichuira}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>

                        <Box mt={4}>
                          <TextField
                            label=""
                            name="dfs_estamoeba_cyst[]"
                            fullWidth
                            defaultValue={data.dfs_entamoeba_lamblia_cyst}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            name="dfs_estamoeba_trophozoite[]"
                            fullWidth
                            defaultValue={
                              data.dfs_entamoeba_lamblia_trophozoite
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={3}>
                        <Box mt={3.2}>
                          {data.kt ? (
                            <CheckBox color="primary" />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )}
                          Kato-Thick
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            name="kt_ascaris[]"
                            fullWidth
                            defaultValue={data.kt_ascaris}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            name="kt_hookworm[]"
                            fullWidth
                            defaultValue={data.kt_hookworm}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            name="kt_blasto[]"
                            fullWidth
                            defaultValue={data.kt_blastocystis}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>

                        <Box mt={4}>
                          <TextField
                            label=""
                            name="kt_giadia_cyst[]"
                            defaultValue={data.kt_giardia_lamblia_cyst}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            name="kt_giadia_trophozoite[]"
                            defaultValue={data.kt_giardia_lamblia_trophozoite}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            name="kt_trichuris[]"
                            defaultValue={data.kt_trichusris_trichuira}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>

                        <Box mt={4}>
                          <TextField
                            label=""
                            name="kt_estamoeba_cyst[]"
                            defaultValue={data.kt_entamoeba_lamblia_cyst}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            name="kt_estamoeba_trophozoite[]"
                            defaultValue={data.kt_entamoeba_lamblia_trophozoite}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={3}>
                        <Box mt={3.2}>
                          {data.kk ? (
                            <CheckBox color="primary" />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )}
                          <strong></strong>
                          Kato-Katz
                        </Box>

                        <Box align="center">
                          <TextField
                            label=""
                            name="kk_ascaris[]"
                            defaultValue={data.kk_ascaris}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            defaultValue={data.kk_hookworm}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>

                        <Box>
                          <TextField
                            defaultValue={data.kk_blastocystis}
                            InputProps={{
                              readOnly: true,
                            }}
                            label=""
                            name="kk_blasto[]"
                            fullWidth
                          />
                        </Box>

                        <Box mt={4}>
                          <TextField
                            label=""
                            name="kk_giadia_cyst[]"
                            defaultValue={data.kk_giardia_lamblia_cyst}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            name="kk_giadia_trophozoite[]"
                            defaultValue={data.kk_giardia_lamblia_trophozoite}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>
                        <Box>
                          <TextField
                            label=""
                            name="kk_trichuris[]"
                            defaultValue={data.kk_trichusris_trichuira}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>
                        <Box mt={4}>
                          <TextField
                            label=""
                            name="kk_estamoeba_cyst[]"
                            defaultValue={data.kk_entamoeba_lamblia_cyst}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>

                        <Box>
                          <TextField
                            disabled={!data.kk}
                            label=""
                            defaultValue={data.kk_entamoeba_lamblia_trophozoite}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box> */}

                  {/* <Box>
                    <TextField
                      label={<strong style={{ color: "#000" }}>Other</strong>}
                      multiline
                      fullWidth
                      defaultValue={data.others}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box> */}

                  {/* <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Box>
                          <TextField
                            label={
                              <strong style={{ color: "#000" }}>
                                Pus Cells
                              </strong>
                            }
                            fullWidth
                            defaultValue={data.pus_cells}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>

                        <Box>
                          <TextField
                            label={
                              <strong style={{ color: "#000" }}>
                                Red Blood Cells
                              </strong>
                            }
                            fullWidth
                            defaultValue={data.reb_blood_cells}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>

                        <Box>
                          <TextField
                            label={
                              <strong style={{ color: "#000" }}>
                                Fat Globules
                              </strong>
                            }
                            fullWidth
                            defaultValue={data.fat_globules}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <Box>
                          <TextField
                            label={
                              <strong style={{ color: "#000" }}>
                                Yeast Cells
                              </strong>
                            }
                            fullWidth
                            defaultValue={data.yeast_cells}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>
                        <Box>
                          <TextField
                            label={
                              <strong style={{ color: "#000" }}>
                                Bacteria
                              </strong>
                            }
                            fullWidth
                            defaultValue={data.bacteria}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>
                        <Box>
                          <TextField
                            label={
                              <strong style={{ color: "#000" }}>
                                Oil Droplets
                              </strong>
                            }
                            fullWidth
                            defaultValue={data.oil_droplets}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <Box>
                          <TextField
                            label={
                              <strong style={{ color: "#000" }}>
                                Undigested Food Particles
                              </strong>
                            }
                            fullWidth
                            defaultValue={data.undigested_foods_paticles}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box> */}

                  {/* <Box my={2}>
                    <TextField
                      label={<strong style={{ color: "#000" }}>Remarks</strong>}
                      multiline
                      fullWidth
                      defaultValue={data.order_remarks}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box> */}
                </Box>
              </Box>
              {/* fecalysis order end */}
            </Fragment>
          ))}
        </CardContent>

        <Box align="center">
          <Footer
            formheader={formheader}
            orderDetails={stooltestOrderDetails}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default StooltestOrder;
