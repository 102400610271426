import {
	Box,
	Grid,
	Typography,
	TextField,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
// import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";

const MedicalExamOrder = ({ order_id, patient_id }) => {
	const { users } = React.useContext(UsersData);

	const [details, setDetails] = useState([]);

	const getpaidLaboratoryOrderDetails = async () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set("trace_number", order_id);
		formdata.set("table", "laboratory_medical_exam");
		formdata.set("connection", "local");

		try {
			let response = await axios.post(
				"doctor/patient/laboratory/order/paid-detailsbytable",
				formdata
			);
			const data = response.data;

			setDetails(data);
		} catch (error) {
			console.log("lab form order laboratory_hematology table error", error);
		}
	};

	//   const checkResult = (order) => {
	//     return order === "new-order" ? (
	//       <Label color="error"> no result </Label>
	//     ) : order === "refund" ? (
	//       <Label color="warning"> {order} </Label>
	//     ) : (
	//       <Label color="success"> {order} </Label>
	//     );
	//   };

	useEffect(() => {
		getpaidLaboratoryOrderDetails();

		// eslint-disable-next-line
	}, []);

	const checkIFnull = (data) => {
		if (data === null) return "";
		return data;
	};

	return (
		<>
			{details.length > 0 && (
				<>
					<Box mb={2}>
						<Typography variant="h6" align="center">
							Medical Examimination Report
						</Typography>
					</Box>

					{details.map((data, index) => (
						<Box key={index}>
							{/* hidden inputs */}
							<Box display="flex">
								<Box mt={1.3}> Nature of Examimination: </Box>
								<Box mx={2}>
									<FormControlLabel
										label="Pre-employment"
										control={
											<Checkbox
												color="primary"
												checked={
													data.nature_of_exam
														? data.nature_of_exam.includes("Pre-employment")
															? true
															: false
														: false
												}
											/>
										}
									/>
								</Box>
								<Box mx={2}>
									<FormControlLabel
										label="Annual PE"
										control={
											<Checkbox
												color="primary"
												checked={
													data.nature_of_exam
														? data.nature_of_exam.includes("Annual PE")
															? true
															: false
														: false
												}
											/>
										}
									/>
								</Box>
								<Box mx={2}>
									<FormControlLabel
										label="Other"
										control={
											<Checkbox
												color="primary"
												checked={
													data.nature_of_exam &&
													!data.nature_of_exam.includes("Pre-employment") &&
													!data.nature_of_exam.includes("Annual PE")
												}
											/>
										}
									/>
								</Box>
								{data.nature_of_exam &&
									!data.nature_of_exam.includes("Pre-employment") &&
									!data.nature_of_exam.includes("Annual PE") && (
										<Box mt={1}>
											<Typography style={{ textTransform: "capitalize" }}>
												<u> {data.nature_of_exam} </u>
											</Typography>
										</Box>
									)}
							</Box>

							<Box my={2}>
								<Typography variant="subtitle1">I. MEDICAL HISTORY</Typography>
							</Box>

							<Box my={1}>
								<Box my={2}>
									<Typography variant="caption">
										PAST MEDICAL HISTORY
									</Typography>
								</Box>
								<Box display="flex">
									<Box>
										<FormControlLabel
											label="Hypertension"
											control={
												<Checkbox
													color="primary"
													checked={
														data.pmh && data.pmh.includes("hypertension")
													}
												/>
											}
										/>
									</Box>
									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={
														data.pmh && data.pmh.includes("diabetes_melitus")
													}
												/>
											}
											label="Diabetes Melitus"
										/>
									</Box>
									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={data.pmh && data.pmh.includes("ptb")}
												/>
											}
											label="PTB"
										/>
									</Box>
									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={data.pmh && data.pmh.includes("others")}
												/>
											}
											label="Others"
										/>
									</Box>
									{data.pmh && data.pmh.includes("others") && (
										<Box mt={1.2}>
											<Typography
												style={{
													textTransform: "capitalize",
												}}
											>
												<u> {data.pmh_other_specify} </u>
											</Typography>
										</Box>
									)}
								</Box>
							</Box>

							<Box my={1}>
								<Box my={2}>
									<Typography variant="caption">FAMILY HISTORY</Typography>
								</Box>
								<Box display="flex">
									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={
														data.fam_history &&
														data.fam_history.includes("hypertension")
													}
												/>
											}
											label="Hypertension"
										/>
									</Box>
									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={
														data.fam_history &&
														data.fam_history.includes("asthma")
													}
												/>
											}
											label="Asthma"
										/>
									</Box>
									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={
														data.fam_history &&
														data.fam_history.includes("cancers")
													}
												/>
											}
											label="Cancers"
										/>
									</Box>
								</Box>

								<Box display="flex">
									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={
														data.fam_history &&
														data.fam_history.includes("cardiac_disease")
													}
												/>
											}
											label="Cardiac Disease"
										/>
									</Box>
									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={
														data.fam_history && data.fam_history.includes("ptb")
													}
												/>
											}
											label="PTB"
										/>
									</Box>
									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={
														data.fam_history &&
														data.fam_history.includes("hyperthyroidism")
													}
												/>
											}
											label="Hyperthyroidism"
										/>
									</Box>
									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={
														data.fam_history &&
														data.fam_history.includes("others")
													}
												/>
											}
											label="Others"
										/>
									</Box>

									<Box>
										{data.fam_history && data.fam_history.includes("others") && (
											<Box mt={1.2}>
												<Typography
													style={{
														textTransform: "capitalize",
													}}
												>
													<u>{data.fam_history_other_specify}</u>
												</Typography>
											</Box>
										)}
									</Box>
								</Box>
							</Box>

							<Box my={2}>
								<TextField
									margin="dense"
									label="Previous Operation/Hospitalization"
									name="prev_operation_hospital"
									fullWidth
									variant="outlined"
									value={checkIFnull(data.prev_operation)}
									InputProps={{ readOnly: true }}
									InputLabelProps={{ shrink: true }}
								/>
							</Box>

							<Box>
								<Box mb={2}>
									<Typography variant="subtitle1">
										II. PERSONAL HISTORY
									</Typography>
								</Box>

								<Box display="flex">
									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={data.personal_history_smoke === "yes"}
												/>
											}
											label="Smoking"
										/>
									</Box>

									<Box mx={3} mt={1.2}>
										<Typography>
											Qty per day: <u> {data.personal_history_smoke_qty} </u>
										</Typography>
									</Box>

									<Box mx={3} mt={1.2}>
										<Typography>
											# of years :<u> {data.personal_history_smoke_no_year} </u>
										</Typography>
									</Box>

									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={data.personal_history_alchohol === "yes"}
												/>
											}
											label="Alcohol"
										/>
									</Box>

									<Box>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={data.personal_history_drug === "yes"}
												/>
											}
											label="Drug Abuse"
										/>
									</Box>
								</Box>
							</Box>

							<Box my={2}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<Box my={2}>
											<TextField
												margin="dense"
												label="Allergies of Food"
												name="allergies_of_foods"
												fullWidth
												variant="outlined"
												multiline
												value={checkIFnull(data.allergy_foods)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={6}>
										<Box my={2}>
											<TextField
												margin="dense"
												label="Allergies of Drugs"
												name="allergies_of_drugs"
												fullWidth
												variant="outlined"
												multiline
												value={checkIFnull(data.allergy_drugs)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>
								</Grid>
							</Box>

							<Box my={2}>
								<Box mb={2}>
									<Typography variant="caption">
										MENSTRUAL/OBSTETRICAL HISTORY
									</Typography>
								</Box>

								<Box mb={2}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={4}>
											<TextField
												margin="dense"
												fullWidth
												variant="outlined"
												label="LMP"
												name="menstrual_lmp"
												value={checkIFnull(data.menstrual_hist_lmp)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<TextField
												margin="dense"
												fullWidth
												variant="outlined"
												label="PMP"
												name="menstrual_pmp"
												value={checkIFnull(data.menstrual_hist_pmp)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<TextField
												margin="dense"
												fullWidth
												variant="outlined"
												label="G"
												name="menstrual_g"
												value={checkIFnull(data.menstrual_hist_g)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Grid>
									</Grid>
								</Box>

								<Box mb={2}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6}>
											<TextField
												margin="dense"
												variant="outlined"
												label="P"
												fullWidth
												name="menstrual_p"
												value={checkIFnull(data.menstrual_hist_p)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												margin="dense"
												variant="outlined"
												label="Other"
												fullWidth
												name="menstrual_other"
												value={checkIFnull(data.menstrual_hist_other)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Grid>
									</Grid>
								</Box>
							</Box>

							<Box my={2}>
								<TextField
									margin="dense"
									label="Medication"
									name="medication"
									fullWidth
									variant="outlined"
									value={checkIFnull(data.medication)}
									InputLabelProps={{ shrink: true }}
									InputProps={{ readOnly: true }}
								/>
							</Box>

							<Box my={2}>
								<Box mb={2}>
									<Typography variant="subtitle1">
										III. PHYSICAL EXAMINATION
									</Typography>
								</Box>

								<Grid container spacing={2}>
									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="BP"
												name="bp"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_bp)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="PR"
												name="pr"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_pr)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="HT"
												name="ht"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_ht)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="WT"
												name="wt"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_wt)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="BMI"
												name="bmi"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_bmi)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Range"
												name="range"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_range)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>
								</Grid>
							</Box>

							<Box my={2}>
								<Box mb={2}>
									<Typography variant="caption">VISUAL ACUITY</Typography>
								</Box>

								<Box my={2}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6}>
											<Box>
												<TextField
													margin="dense"
													label="OD NEAR"
													name="od_near"
													fullWidth
													variant="outlined"
													value={checkIFnull(data.visual_acuity_od_near)}
													InputLabelProps={{ shrink: true }}
													InputProps={{ readOnly: true }}
												/>
											</Box>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Box>
												<TextField
													margin="dense"
													label="OD FAR"
													name="od_far"
													fullWidth
													variant="outlined"
													value={checkIFnull(data.visual_acuity_od_far)}
													InputLabelProps={{ shrink: true }}
													InputProps={{ readOnly: true }}
												/>
											</Box>
										</Grid>
									</Grid>
								</Box>

								<Box my={2}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6}>
											<Box>
												<TextField
													margin="dense"
													label="OS NEAR"
													name="os_near"
													fullWidth
													variant="outlined"
													value={checkIFnull(data.visual_acuity_os_near)}
													InputLabelProps={{ shrink: true }}
													InputProps={{ readOnly: true }}
												/>
											</Box>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Box>
												<TextField
													margin="dense"
													label="OS FAR"
													name="os_far"
													fullWidth
													variant="outlined"
													value={checkIFnull(data.visual_acuity_os_far)}
													InputLabelProps={{ shrink: true }}
													InputProps={{ readOnly: true }}
												/>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Box>

							<Box my={2}>
								<Box mb={1}>
									<Typography variant="caption"> DENTAL SURVEY </Typography>
								</Box>

								<Box mb={1}>
									<Typography variant="subtitle2">
										UPPER: &nbsp;8 &nbsp; 7 &nbsp; 6 &nbsp; 5 &nbsp; 4 &nbsp; 3
										&nbsp; 2 &nbsp; 1 &nbsp; &nbsp; 1 &nbsp; 2 &nbsp; 3 &nbsp; 4
										&nbsp; 5 &nbsp; 6 &nbsp; 7 &nbsp; 8
									</Typography>
								</Box>

								<Box mb={1}>
									<Typography variant="subtitle2">
										LOWER: &nbsp;8 &nbsp; 7 &nbsp; 6 &nbsp; 5 &nbsp; 4 &nbsp; 3
										&nbsp; 2 &nbsp; 1 &nbsp; &nbsp; 1 &nbsp; 2 &nbsp; 3 &nbsp; 4
										&nbsp; 5 &nbsp; 6 &nbsp; 7 &nbsp; 8
									</Typography>
								</Box>

								<Box>
									<Typography variant="subtitle2">
										SUGGEST: (&nbsp;&nbsp;&nbsp;&nbsp;) PROPHY:
										(&nbsp;&nbsp;&nbsp;&nbsp;) EXO: (&nbsp;&nbsp;&nbsp;&nbsp;)
										RESTO, INDICATE NO:
									</Typography>
								</Box>
							</Box>

							<Box my={2}>
								<Box mb={2}>
									<Typography variant="caption">HEARING</Typography>
								</Box>

								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<Box>
											<TextField
												margin="dense"
												label="HEARING AD"
												name="hearing_ad"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_hearing_ad)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={6}>
										<Box>
											<TextField
												margin="dense"
												label="HEARING AS"
												name="hearing_as"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_hearing_as)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>
								</Grid>
							</Box>

							<Box>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Skin"
												name="skin"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_skin)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Heent"
												name="heent"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_heent)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Neck"
												name="neck"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_neck)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Chest/Lungs"
												name="chest"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_chest)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Abdomen"
												name="abdomen"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_cardio)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Cardiovascular"
												name="cardio"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_abdomen)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Genitourinary Tract"
												name="genitourinary"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_genitourinary)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Genitalia"
												name="genitalia"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_genitalia)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Inguinal"
												name="inguinal"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_linguinal)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Extremities"
												name="extremities"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_extremities)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Reflexes"
												name="reflexes"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_reflexes)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="dense"
												label="Neurology"
												name="neuro"
												fullWidth
												variant="outlined"
												value={checkIFnull(data.pe_neurology)}
												InputLabelProps={{ shrink: true }}
												InputProps={{ readOnly: true }}
											/>
										</Box>
									</Grid>
								</Grid>
							</Box>

							<Box my={2}>
								<Box>
									<Typography variant="subtitle1">
										III. CLASSIFICATION
									</Typography>
								</Box>

								<Box>
									<FormControlLabel
										control={
											<Checkbox
												name="classification_a"
												color="primary"
												checked={data.classification_a === "yes"}
											/>
										}
										label="Physically fit to work"
									/>
								</Box>

								<Box>
									<FormControlLabel
										control={
											<Checkbox
												name="classification_b"
												color="primary"
												checked={data.classification_b === "yes"}
											/>
										}
										label="Physically to work with findings but offers no handicap to the job applied."
									/>
								</Box>

								<Box>
									<FormControlLabel
										control={
											<Checkbox
												name="classification_c"
												color="primary"
												checked={data.classification_c === "yes"}
											/>
										}
										label="With abnormal findings (employment upon descretion of employer)."
									/>
								</Box>

								<Box>
									<FormControlLabel
										control={
											<Checkbox
												name="classification_d"
												color="primary"
												checked={data.classification_d === "yes"}
											/>
										}
										label="Unfit for any type of employment"
									/>
								</Box>

								<Box>
									<FormControlLabel
										control={
											<Checkbox
												name="classification_e"
												color="primary"
												checked={data.classification_e === "yes"}
											/>
										}
										label="Classification pending due to"
									/>
								</Box>
							</Box>

							<Box my={2}>
								<TextField
									margin="dense"
									label="Impression/Diagnosis"
									name="impression"
									fullWidth
									variant="outlined"
									value={checkIFnull(data.impression)}
									InputLabelProps={{ shrink: true }}
									InputProps={{ readOnly: true }}
								/>
							</Box>

							<Box my={2}>
								<TextField
									margin="dense"
									label="Recommendations"
									name="recommendations"
									fullWidth
									variant="outlined"
									value={checkIFnull(data.recommendations)}
									InputLabelProps={{ shrink: true }}
									InputProps={{ readOnly: true }}
								/>
							</Box>

							<Box>
								<Box>
									<Typography variant="subtitle1">
										EXAMINING PHYSISCIAN
									</Typography>
								</Box>

								<Box
									border={1}
									borderColor="#ccc"
									p={4}
									borderRadius={2}
									my={2}
								>
									<Grid container spacing={2}>
										<Grid item sm={6}>
											<Box align="center" mt={2}>
												<Box borderTop={1}>MD</Box>
												<Box>
													<Typography> License No. __________ </Typography>
												</Box>
											</Box>
										</Grid>

										<Grid item sm={6}>
											<Box borderTop={1} align="center" mt={2}>
												<Typography>Patient's Signature</Typography>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Box>
						</Box>
					))}
				</>
			)}
		</>
	);
};

export default MedicalExamOrder;
