import React, { useEffect, useState, useContext } from "react";
import {
	Box,
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	TablePagination,
	TableFooter,
} from "@material-ui/core";
import Axios from "axios";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";

const DoctorWageGeneratedRec = ({ doctors_id }) => {
	const { users } = useContext(UsersData);
	const [totalAmountDoctor, setTotalAmountDoctor] = useState(0);
	const [totalAmountCompany, setTotalAmountCompany] = useState(0);
	const [totalAmountOrig, setTotalAmountOrig] = useState(0);
	const [info, setInfo] = useState({
		data: null,
		ready: false,
	});
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);

	const getAllMyGeneratedWage = () => {
		var params = {
			doctors_id: doctors_id,
			management_id: users.management_id,
			main_mgmt_id: users.main_mgmt_id,
		};
		Axios.get("cashier/get/all/doctors/gen-record", { params })
			.then((response) => {
				const data = response.data;
				setInfo({
					data: data,
					ready: true,
				});
				setTotalAmountDoctor(calculateTotal(data, "doctor"));
				setTotalAmountCompany(calculateTotal(data, "company"));
				setTotalAmountOrig(calculateTotal(data, "original"));
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 5));
		setPage(0);
	};

	const calculateTotal = (data, cat) => {
		var total = 0;
		if (cat === "doctor") {
			for (let i = 0; i < data.length; i++) {
				total += parseFloat(data[i].doctor_share) * 1;
			}
			return total;
		}
		if (cat === "company") {
			for (let i = 0; i < data.length; i++) {
				total += parseFloat(data[i].company_share) * 1;
			}
			return total;
		}
		if (cat === "original") {
			for (let i = 0; i < data.length; i++) {
				total += parseFloat(data[i].original_amount) * 1;
			}
			return total;
		}
	};

	useEffect(() => {
		getAllMyGeneratedWage();

		// eslint-disable-next-line
	}, [doctors_id]);

	return (
		<>
			{info.ready ? (
				<Box>
					<Box component={Paper} elevation={1} p={2}>
						<Box>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell align="center"> Date </TableCell>
											<TableCell align="center"> Type </TableCell>
											<TableCell align="center"> Status </TableCell>
											<TableCell align="center"> Doctor's Share </TableCell>
											<TableCell align="center"> Company's Share </TableCell>
											<TableCell align="center"> Original Amount </TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{info.ready ? (
											info.data.length > 0 ? (
												info.data.map((data, index) => (
													<TableRow key={index}>
														<TableCell>
															From: {Notify.createdAt(data.date_from)} <br />
															To: {Notify.createdAt(data.date_to)}
														</TableCell>
														<TableCell>
															<Typography className="text-capitalize">
																{data.type}
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Typography
																color={
																	Boolean(parseFloat(data.is_received))
																		? "primary"
																		: "secondary"
																}
															>
																{Boolean(parseFloat(data.is_received))
																	? "Confirmed"
																	: "Not Confirmed"}
															</Typography>
														</TableCell>

														<TableCell align="right">
															{data.doctor_share}
														</TableCell>
														<TableCell align="right">
															{data.company_share}
														</TableCell>
														<TableCell align="right">
															{data.original_amount}
														</TableCell>
													</TableRow>
												))
											) : (
												<TableRow>
													<TableCell colSpan={6} align="center">
														<Typography color="secondary">
															No record found.
														</Typography>
													</TableCell>
												</TableRow>
											)
										) : (
											<TableRow>
												<TableCell colSpan={6} align="center">
													<Typography color="primary">
														Please wait...
													</Typography>
												</TableCell>
											</TableRow>
										)}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TableCell colSpan={3} align="right">
												<strong>Total:</strong>
											</TableCell>
											<TableCell align="right">
												{Notify.convertToNumber(totalAmountDoctor)}
											</TableCell>
											<TableCell align="right">
												{Notify.convertToNumber(totalAmountCompany)}
											</TableCell>
											<TableCell align="right">
												{Notify.convertToNumber(totalAmountOrig)}
											</TableCell>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
						</Box>
						<TablePagination
							labelRowsPerPage="List"
							rowsPerPageOptions={[10, 20, 50, 100]}
							component="div"
							count={info.data.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</Box>
				</Box>
			) : (
				Notify.loading()
			)}
		</>
	);
};

export default DoctorWageGeneratedRec;
