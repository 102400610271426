import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Img from "src/components/Img";
import ImagePicker from "src/components/forms/ImagePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { dataURItoBlob } from "src/helpers/utils";
const SupplierModalForm = (props, ref) => {
	const [mapKey, setMapKey] = useState(`map-${uuidv4()}`);
	const { staticModal, updateInList, addToList } = props;
	const [open, setOpen] = useState(false);
	const [data, setData] = useState(null);
	const [avatar, setAvatar] = useState("");
	const [loading, setLoading] = useState(false);

	
	const btnRef = useRef(null);

	

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	
	const show = (showData) => {
		console.log("showDatashowData", showData);
		if (showData?.id) {
			setData(showData);
			setValue("name", showData?.name);
			
			
		} else {
			reset({
				name: "",
				description: "",
				unit_measurement: "",
				type: "",
			});
		}
	
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = (form) => {
		setLoading(true);
		
		let url = `/v1/management/supplier`;
		let formData = form
		if (data?.id) {
			formData = { ...formData, _method: "PATCH" };
			url = url + `/${data?.id}`;
		}

		axios
			.post(url, formData)
			.then((res) => {
				setTimeout(() => {
					toast.success("Supplier record updated successfully!");
					if (data?.id) {
						updateInList(res.data);
					} else {
						addToList(res.data);
					}
				}, 200);

				hide();
			})
			.finally(() => {
				setLoading(false);
			});
	};
	
	return (
		<Modal
			open={open}
			hide={hide}
			className={`w-[768px] min-w-[768px] max-w-[768px]`}
			staticModal={staticModal}
		>
			<ModalHeader
				title={(data?.id ? "Edit " : "Add ") + `Supplier Form`}
				hide={hide}
			/>
			<ModalBody className={`p- bg-white !pb-6`}>
				<div className="p-4 flex flex-col gap-y-5">
					
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-5 w-full">
						<TextField
							label={
								<>
									Name
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							className="lg:col-span-2"
							variant="outlined"
							{...register("name", {
								required: true,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.name}
							helperText={
								errors?.name && "This field is required"
							}
						/>
						
						
					</div>
					
		
				</div>
				
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button
					onClick={handleSubmit(submit)}
					loading={loading}
					ref={btnRef}
				>
					Submit
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(SupplierModalForm);
