import { Box, Button, Card, TextField } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { CloudUpload } from "@material-ui/icons";
import { styles } from "../../styles/styles";
import ButtonV3 from "src/components/ButtonV3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    fontSize: "18px",
    padding: "6px",
  },
}));

export default function CisAddAttachments() {
  const classes = useStyles();
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    let formData = new FormData();
    formData.append("password", data?.password || "");
    console.log(formData);
  };

  return (
    <div className="flex w-full flex-col">
      <form>
        <div className="mx-auto" style={styles.parentContainerAdd}>
          <Card elevation={3} className="p-4" component={Box}>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                <div className="border-2 p-2 h-full mb-3">
                  {imagePreview ? (
                    <label className="w-full" htmlFor="contained-button-file">
                      <img
                        className="mx-auto"
                        src={imagePreview}
                        alt="Uploaded"
                        style={{
                          maxWidth: "70%",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      />
                    </label>
                  ) : (
                    <label className="w-full" htmlFor="contained-button-file">
                      <img
                        className="mx-auto "
                        src="/no-image.png"
                        alt="no image"
                        style={{
                          maxWidth: "70%",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      />
                    </label>
                  )}
                </div>
              </div>
              <div>
                <div style={styles.divContainer}>
                  <TextField
                    label={<label style={styles.label}>Password</label>}
                    variant="outlined"
                    fullWidth
                    {...register("password", { required: true })}
                    error={errors.password}
                    helperText={errors.password && "Password is required"}
                  />
                </div>
                <div className="w-full flex justify-center items-center">
                  <label className="mx-auto" htmlFor="contained-button-file">
                    <ButtonV3 type="success">
                      <div className="flex">
                        <FontAwesomeIcon
                          icon={faCloud}
                          color="primary"
                          style={{ fontSize: 18 }}
                          className="pr-2"
                        />
                        Upload Image
                      </div>
                    </ButtonV3>
                  </label>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="mx-auto" style={styles.button}>
          <ButtonV3 onClick={handleSubmit(onSubmit)}>Submit</ButtonV3>
        </div>
      </form>
    </div>
  );
}
