import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  FormHelperText,
  Button,
  IconButton,
} from "@material-ui/core";
import Axios from "axios";
import React, { useState, useContext } from "react";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Edit as EditIcon,
  HighlightOff as ClearIcon,
  CheckCircleOutline as CheckIcon,
} from "@material-ui/icons";
import { UsersData } from "src/ContextAPI";
import { isMobile } from "react-device-detect";

const PatientDetailsProfile = ({
  patient_id,
  getIncompleteReg,
  getPatientInformation,
  info,
}) => {
  const { users } = useContext(UsersData);
  const [edit, setEdit] = useState(false);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Box component={Paper} variant="outlined" mt={2} p={2}>
      <Box className={edit ? "" : `gtc-textfield-noborder`}>
        {info.ready ? (
          info.data ? (
            <>
              <Formik
                enableReinitialize
                initialValues={{
                  username: users.username,
                  user_id: users.user_id,
                  // password: "",
                  patient_id: info.data.patient_id,
                  lastname: info.data.lastname,
                  firstname: info.data.firstname,
                  middlename: info.data.middle === null ? "" : info.data.middle,
                  gender: info.data.gender === null ? "" : info.data.gender,
                  birthday:
                    info.data.birthday === null ? "" : info.data.birthday,
                  birthplace:
                    info.data.birthplace === null ? "" : info.data.birthplace,
                  street: info.data.street === null ? "" : info.data.street,
                  barangay:
                    info.data.barangay === null ? "" : info.data.barangay,
                  city: info.data.city === null ? "" : info.data.city,
                  occupation:
                    info.data.occupation === null ? "" : info.data.occupation,
                  civil_status:
                    info.data.civil_status === null
                      ? ""
                      : info.data.civil_status,
                  religion:
                    info.data.religion === null ? "" : info.data.religion,
                  mobile: info.data.mobile === null ? "" : info.data.mobile,
                  telephone:
                    info.data.telephone === null ? "" : info.data.telephone,
                  email: info.data.email === null ? "" : info.data.email,
                  philhealth:
                    info.data.philhealth === null ? "" : info.data.philhealth,
                  company: info.data.company === null ? "" : info.data.company,
                }}
                validationSchema={Yup.object().shape({
                  // password: Yup.string()
                  //   .trim()
                  //   .required("Password is required."),
                  lastname: Yup.string()
                    .trim()
                    .required("Lastname is required."),
                  firstname: Yup.string()
                    .trim()
                    .required("Firstname is required."),
                  gender: Yup.string().trim().required("Gender is required."),
                  birthday: Yup.date().required("Birth date is required."),
                  street: Yup.string().trim().required("Street is required."),
                  barangay: Yup.string()
                    .trim()
                    .required("Barangay is required."),
                  city: Yup.string().trim().required("City is required."),
                  occupation: Yup.string()
                    .trim()
                    .required("Occupation is required."),
                  civil_status: Yup.string()
                    .trim()
                    .required("Civil status is required."),
                  religion: Yup.string()
                    .trim()
                    .required("Religion is required."),
                  mobile: Yup.string().trim().required("Mobile is required."),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setSubmitting, resetForm }
                ) => {
                  try {
                    const request = await Axios.post(
                      "triage/patients/edit-patient",
                      getFormData(values)
                    );
                    // if (request.data === "pass-invalid") {
                    //   setErrors({ password: "Password is invalid." });
                    //   Notify.fieldInvalid("password");
                    // }
                    if (request.data === "success") {
                      getIncompleteReg();
                      getPatientInformation();
                      Notify.successRequest("update patient");
                      resetForm();
                      setSubmitting(true);
                      setEdit(false);
                    }
                  } catch (error) {
                    const message = error.message || "Something went wrong";
                    setErrors({ submit: message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Box
                      mb={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box flexGrow={1}>
                        <Typography color="primary" variant="subtitle2">
                          PATIENT INFORMATION
                        </Typography>
                      </Box>
                      <Box hidden={edit}>
                        {isMobile ? (
                          <IconButton
                            color="primary"
                            onClick={() => setEdit(true)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                            onClick={() => setEdit(true)}
                          >
                            Edit
                          </Button>
                        )}
                      </Box>
                    </Box>

                    <Box my={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(
                                touched.lastname && errors.lastname
                              )}
                              helperText={touched.lastname && errors.lastname}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.lastname}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="lastname"
                              label="Lastname"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(
                                touched.firstname && errors.firstname
                              )}
                              helperText={touched.firstname && errors.firstname}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.firstname}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="firstname"
                              label="Firstname"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(
                                touched.middlename && errors.middlename
                              )}
                              helperText={
                                touched.middlename && errors.middlename
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.middlename}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              name="middlename"
                              label="Middle Name"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box my={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(touched.gender && errors.gender)}
                              helperText={touched.gender && errors.gender}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.gender}
                              fullWidth
                              required
                              name="gender"
                              label="Gender"
                              variant="outlined"
                              select
                              SelectProps={{
                                native: true,
                                disabled: !edit,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value={""}>Select</option>
                              <option value={"Male"}>Male</option>
                              <option value={"Female"}>Female</option>
                            </TextField>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(
                                touched.birthday && errors.birthday
                              )}
                              helperText={touched.birthday && errors.birthday}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.birthday}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="birthday"
                              label="Birthday"
                              variant="outlined"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(
                                touched.birthplace && errors.birthplace
                              )}
                              helperText={
                                touched.birthplace && errors.birthplace
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.birthplace}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="birthplace"
                              label="Birth Place"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box my={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(touched.street && errors.street)}
                              helperText={touched.street && errors.street}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.street}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="street"
                              label="Street"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(
                                touched.barangay && errors.barangay
                              )}
                              helperText={touched.barangay && errors.barangay}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.barangay}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="barangay"
                              label="Barangay"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(touched.city && errors.city)}
                              helperText={touched.city && errors.city}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.city}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="city"
                              label="City"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box my={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(
                                touched.occupation && errors.occupation
                              )}
                              helperText={
                                touched.occupation && errors.occupation
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.occupation}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="occupation"
                              label="Occupation"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(
                                touched.civil_status && errors.civil_status
                              )}
                              helperText={
                                touched.civil_status && errors.civil_status
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.civil_status}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="civil_status"
                              label="Civil Status"
                              variant="outlined"
                              select
                              SelectProps={{
                                native: true,
                                disabled: !edit,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value={""}>Select</option>
                              <option value={"Single"}>Single</option>
                              <option value={"Married"}>Married</option>
                              <option value={"Divorced"}>Divorced</option>
                            </TextField>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(
                                touched.religion && errors.religion
                              )}
                              helperText={touched.religion && errors.religion}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.religion}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="religion"
                              label="Religion"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box my={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(touched.mobile && errors.mobile)}
                              helperText={touched.mobile && errors.mobile}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.mobile}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="mobile"
                              label="Mobile"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(
                                touched.telephone && errors.telephone
                              )}
                              helperText={touched.telephone && errors.telephone}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.telephone}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              name="telephone"
                              label="Telephone"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                              required
                              name="email"
                              label="Email"
                              variant="outlined"
                            />
                            <FormHelperText error>
                              Email address not editable
                            </FormHelperText>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box my={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                          <Box>
                            <TextField
                              error={Boolean(
                                touched.philhealth && errors.philhealth
                              )}
                              helperText={
                                touched.philhealth && errors.philhealth
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.philhealth}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="philhealth"
                              label="Philhealth"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Box>
                            <TextField
                              error={Boolean(touched.company && errors.company)}
                              helperText={touched.company && errors.company}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.company}
                              fullWidth
                              InputProps={{
                                readOnly: !edit,
                              }}
                              required
                              name="company"
                              label="Company"
                              variant="outlined"
                              hidden
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box hidden={!edit}>
                      {/* <Box my={1}>
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="password"
                          type="password"
                          label="Password"
                          variant="outlined"
                        />
                      </Box> */}

                      {errors.submit && (
                        <Box mt={2}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}

                      <Box mb={1} mt={1} display="flex">
                        <Box flexGrow={1} />

                        <Button
                          variant="contained"
                          color="default"
                          onClick={() => setEdit(false)}
                          startIcon={<ClearIcon />}
                        >
                          Cancel
                        </Button>

                        <Box ml={2}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={
                              isSubmitting ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <CheckIcon />
                              )
                            }
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}
      </Box>
    </Box>
  );
};

export default PatientDetailsProfile;
