import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Montserrat-Regular.ttf";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import * as serviceWorker from "./serviceWorker";
//css
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./doctor/Doctor.css";
import "react-quill/dist/quill.snow.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "cropperjs/dist/cropper.css";
import "chartist/dist/chartist.css";
import "nprogress/nprogress.css";
import "./index.css";
import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<MuiPickersUtilsProvider utils={DateFnsUtils}>
		<App />
	</MuiPickersUtilsProvider>
);
/* 
ReactDOM.render(
	<MuiPickersUtilsProvider utils={DateFnsUtils}>
		<App />
	</MuiPickersUtilsProvider>,
	document.getElementById("root")
);
 */
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.register();
