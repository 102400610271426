import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState } from "react";
import { CheckSquare, XCircle } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const AdditionalQueueForOrder = ({ patient_id, close }) => {
  const [openDialog, setOpenDialog] = useState(true);
  const { users } = useContext(UsersData);

  const handleSendQueue = () => {
    var formdata = new FormData();
    formdata.append("user_id", users.user_id);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("patient_id", patient_id);
    formdata.append(
      "trace_number",
      `trace-${Math.floor(Math.random() * 9999)}-${new Date().getTime()}`
    );

    axios
      .post("admission/create/new-queue-to-cashier", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setOpenDialog(false);
          close();
          Notify.successRequest("set as done");
        } else {
          Notify.customToast("Something went wrong", "Please try again...");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        disableBackdropClick
      >
        <DialogContent>
          <DialogContentText>
            Are you sure to send another queue to cashier for additional?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            startIcon={<XCircle />}
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>

          <Button
            variant="contained"
            color="primary"
            startIcon={<CheckSquare />}
            onClick={handleSendQueue}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdditionalQueueForOrder;
