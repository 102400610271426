import { useLocation, useHistory } from "react-router-dom";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import Img from "src/components/Img";
import { calculateAgeV3 } from "src/helpers/utils";
import { patientFullName } from "src/helpers/utils";

const CisPatientTBTreatmentProgram = ({
	clickable = false,
	patient,
	onClick,
}) => {
	const history = useHistory();
	return (
		<div
			className="!border !border-slate-100 bg-white hover:bg-slate-200 duration-300 cursor-pointer rounded-[12px] bg-opacity-50 p-[16px] gap-3 flex items-start"
			onClick={() => {
				if (clickable) {
					onClick();
				}
				// history.push(`/sph/app/cis-doctor/patients/1`);
			}}
		>
			<div className="relative w-11 aspect-square rounded-lg min-w-[44px]">
				<Img
					name={patientFullName(patient)}
					src={patient?.src}
					type="user"
					className="absolute top-0 left-0 w-full h-full rounded-lg !border !border-white object-contain bg-slate-200"
				/>
			</div>
			<div className="flex flex-col gap-1 w-full">
				<div className="flex items-center w-full">
					<span className={`font-bold text-sm `}>
						{patientFullName(patient)}
					</span>
					{patient?.status == "pending" ? (
						<ActionBtn
							type="primary"
							size="xs"
							className="ml-auto px-2"
							onClick={onClick}
						>
							<FlatIcon icon="rr-check" /> Approve
						</ActionBtn>
					) : (
						""
					)}
					{patient?.status == "approved" ? (
						<ActionBtn
							type="success"
							onClick={onClick}
							size="xs"
							className="ml-auto px-2"
						>
							<FlatIcon
								icon="rr-check-circle"
								className="-mt-[1px]"
							/>{" "}
							Done
						</ActionBtn>
					) : (
						""
					)}
				</div>
				<div className="flex items-center gap-4 divide-x !text-xs text-slate-600 group-hover:text-white">
					<span className="text-xs flex items-center gap-2">
						<FlatIcon
							icon="rr-venus-mars"
							className="mb-[-2px] text-xs"
						/>
						<span className="">{patient?.gender}</span>
					</span>
					<span className="text-xs flex items-center gap-2 pl-3">
						<FlatIcon
							icon="rr-calendar"
							className="mb-[-2px] text-xs"
						/>
						<span className="">
							{calculateAgeV3(patient?.birthday)}
						</span>
					</span>
				</div>
				{patient?.reason ? (
					<div className="relative !border-l-2 !border-slate-100 pl-2">
						<span className="mt-1 text-sm text-black group-hover:text-white">
							<i>{patient?.reason}</i>
						</span>
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default CisPatientTBTreatmentProgram;
