import { Popover, Menu, Transition } from "@headlessui/react";
import { TextField } from "@material-ui/core";
import axios from "axios";
import {
	forwardRef,
	Fragment,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useForm } from "react-hook-form";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import ActionBtn from "src/components/ActionBtn";
import Button from "src/components/Button";
import FlatIcon from "src/components/FlatIcon";
import Img from "src/components/Img";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { calculateAge } from "src/helpers/utils";
import VitalsForm from "./VitalsForm";

const submitMenu = [
	{
		name: "Insights",
		description: "Measure actions your users take",
		href: "##",
		icon: () => {
			<FlatIcon icon="rr-plus" />;
		},
	},
];

const ReceivePatientProfileModal = (props, ref) => {
	const { clinic, queue_number_modal, getQueue } = props;
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [send_to, setSendTo] = useState("");
	const btnRef = useRef(null);

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm();
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (patient) => {
		setData(patient);
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = (formData) => {
		setLoading(true);
		axios
			.post(`v1/clinic-receiving`, {
				purpose: formData?.purpose,
				send_to: send_to,
				clinic_id: clinic?.id,
				patient_id: data?.id,
				temperature: formData?.temperature,
				blood_systolic: formData?.blood_systolic,
				blood_diastolic: formData?.blood_diastolic,
				weight: formData?.weight,
				height: formData?.height,
				respiratory: formData?.respiratory,
				uric_acid: formData?.uric_acid,
				cholesterol: formData?.cholesterol,
				glucose: formData?.glucose,
				pulse: formData?.pulse,
				// to_intended_id: formData?.to_intended_id, #DOCTOR ID NI
			})
			.then((res) => {
				console.log("resssresss", res.data);
				toast.success(`Patient is now send to queue!`);
				setTimeout(() => {
					queue_number_modal.current.show({
						patient: data,
						number: res.data?.data?.number,
					});
					if (getQueue) getQueue();
				}, 200);
				hide();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Modal
			open={open}
			className={`w-[768px] min-w-[768px] max-w-[768px]`}
			staticModal={true}
		>
			<ModalBody className={`p- bg-slate-50 !pb-6 !rounded-t-xl`}>
				<div className="px-4 pt-4 pb-0 flex flex-col relative">
					<div
						className="absolute  top-6  right-6 ml-auto w-11 h-11 rounded-full bg-red-600 flex items-center justify-center hover:cursor-pointer hover:shadow-lg hover:scale-[1.5] duration-200 text-white"
						onClick={hide}
					>
						<FiX className=" font-bold" />
					</div>
					<div className="flex flex-col lg:flex-row items-center gap-3 mb-4">
						<div className="w-[128px] h-[128px] rounded-full bg-slate-200 border relative overflow-hidden">
							<Img
								type="user"
								src={data?.avatar}
								name={`${data?.lastname}, ${data?.firstname}, ${data?.middle}`}
								alt=""
								className="absolute top-0 left-0 w-full h-full object-contain"
							/>
						</div>
						<div className="flex flex-col">
							<h2 className="text-2xl font-bold m-0">
								{`${data?.lastname}, ${data?.firstname}, ${data?.middle}`}
							</h2>
							<p className="text-base mb-1 gap-2 flex items-center">
								<FlatIcon icon="rr-venus-mars" />{" "}
								<span>{data?.gender}</span>
							</p>
							<p className="text-base mb-1 gap-2 flex items-center">
								<FlatIcon icon="rr-calendar-clock" />{" "}
								<span>{calculateAge(data?.birthday)}</span>
							</p>
						</div>
					</div>
					<div className="flex flex-col gap-y-4 w-full">
						{/* <TextField
							className="bg-white w-full"
							label={
								<>
									Purpose of visit
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("purpose", {
								required: true,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.purpose}
							placeholder="Write short description of patient's purpose of visit"
							helperText={
								errors?.purpose && "This field is required"
							}
						/> */}
						<div className="border rounded-xl p-3 bg-white">
							<VitalsForm register={register} errors={errors} />
						</div>
						<div className="flex items-center justify-end">
							<div className="flex flex-col ">
								<select
									className="p-[10px] rounded-xl border bg-white text-base"
									onChange={(e) => {
										setSendTo(e.target.value);
									}}
								>
									<option value="" disabled selected>
										Send to
									</option>
									<option value={`doctor`}>
										Dr. Calipusan - Pediatrics - RM 1
									</option>
									<option value={`doctor`}>
										Dr. Miguel - OBGYN - RM 2
									</option>
								</select>
							</div>
							<ActionBtn
								className="ml-4"
								disabled={send_to.length == 0}
								onClick={handleSubmit(submit)}
							>
								Send {send_to}
							</ActionBtn>
						</div>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default forwardRef(ReceivePatientProfileModal);
