import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import {
  Avatar,
  Dialog,
  Zoom,
  IconButton,
  makeStyles,
  Button,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { Paper } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { CancelOutlined } from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import QRCode from "qrcode.react";
// import { SRLWrapper } from "simple-react-lightbox";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 30px",
    width: "100%",
  },
  image: {
    marginTop: 25,
    marginBottom: 55,
    height: isMobile ? 250 : 400,
    border: "1px solid #aaa",
    margin: "auto",
    display: "block",
  },
  tag: {
    zIndex: 1,
    position: "absolute",
    paddingTop: 26,
    marginLeft: 38,
  },
  tag_label: {
    backgroundColor: "#00000094",
    color: "#fff",
    padding: 2,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
  },
}));

const PatientDetailsHeader = ({ patient_id, info }) => {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const classes = useStyles();
  const [current, setCurrent] = useState(0);

  // const options = {
  //   buttons: {
  //     backgroundColor: "#000",
  //     iconColor: "#fff",
  //     showFullscreenButton: true,
  //     showThumbnailsButton: true,
  //     showDownloadButton: false,
  //   },
  //   settings: {
  //     disablePanzoom: true,
  //   },
  //   thumbnails: {
  //     showThumbnails: false,
  //   },
  // };

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${info.data.lastname}_${info.data.firstname}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <Box component={Paper} variant="outlined" borderRadius={4} p={2}>
        {info.ready ? (
          info.data ? (
            <div>
              <Box display={"flex"}>
                <Box flexGrow={1}>
                  <div className="clearfix">
                    <div className="float-left">
                      {/* <SRLWrapper options={options}> */}
                      <Avatar
                        style={{
                          width: "120px",
                          height: "120px",
                          cursor: "pointer",
                        }}
                        src={
                          info.data.image !== null
                            ? imageLocation + "patients/" + info.data.image
                            : ""
                        }
                        onClick={() => {
                          setSelectedImage(info.data.image);
                          setOpenLightbox(true);
                        }}
                      />
                      {/* </SRLWrapper> */}
                    </div>
                    <div className="ml-sm-4 float-left">
                      <p className="h5 header-label text-capitalize m-0 my-2 font-weight-bold">
                        {info.data.lastname}, {info.data.firstname}{" "}
                        {info.data.middle}
                      </p>
                      <p className="m-0 my-2">
                        <span className="text-muted gtc-small text-uppercase">
                          {" "}
                          Age:{" "}
                        </span>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        {Notify.calculateAge(info.data.birthday)} years old
                      </p>
                      <p className="m-0 my-2">
                        <span className="text-muted gtc-small text-uppercase">
                          {" "}
                          Gender:{" "}
                        </span>{" "}
                        &nbsp;&nbsp; {info.data.gender}
                      </p>
                      <p className="m-0 my-2">
                        <span className="text-muted gtc-small text-uppercase">
                          {" "}
                          Birthday:{" "}
                        </span>{" "}
                        {Notify.birthday(info.data.birthday)}
                      </p>
                    </div>
                  </div>
                </Box>

                <Box textAlign={"center"}>
                  <Box>
                    <QRCode
                      id="qr-gen"
                      value={JSON.stringify({
                        patient_id: info.data.patient_id,
                        user_id: info.data.user_id,
                        philhealth: info.data.philhealth,
                      })}
                      level="H"
                      size={130}
                    />
                  </Box>

                  <Box>
                    <Button
                      onClick={downloadQRCode}
                      color="primary"
                      size="small"
                      variant="contained"
                    >
                      {" "}
                      Download QR Core{" "}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </div>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}
      </Box>

      <Dialog
        open={openLightbox}
        onClose={() => setOpenLightbox(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="sm"
        fullWidth
      >
        <Box display="flex">
          <Box flexGrow={1} />
          <IconButton
            color="secondary"
            onClick={() => {
              setOpenLightbox(false);
            }}
          >
            <CancelOutlined />
          </IconButton>
        </Box>
        <Box>
          <SwipeableViews
            className={classes.root}
            slideStyle={{ padding: "0 10px" }}
            enableMouseEvents
            index={current}
            onChangeIndex={(e) => setCurrent(e)}
          >
            <Box>
              <img
                alt="attach-file-pt"
                src={`${imageLocation}/patients/${selectedImage}`}
                className={classes.image}
              />
            </Box>
          </SwipeableViews>
        </Box>
      </Dialog>
    </>
  );
};

export default PatientDetailsHeader;
