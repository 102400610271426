// 11-19-2021-updates-jhomar
import {
	Box,
	TextField,
	Typography,
	Button,
	CircularProgress,
	Grid,
	FormControlLabel,
	Checkbox,
	TableRow,
	TableBody,
	TableCell,
	Table,
	Dialog,
	DialogContent,
	DialogActions,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Notify from "../../notification/Notify";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
// import { useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import { CheckSquare, XCircle } from "react-feather";

const DocuPatientDetailsPEFormDetails = ({
	order,
	getPEOrderToProcess,
	updatePatientList,
}) => {
	const { users } = React.useContext(UsersData);
	const [orderDetails, setOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const [resultSubmitting, setResultSubmitting] = useState(false);
	const [savebtnDisabled, setSavebtnDisabled] = useState(true);

	const [natureOfExam, setNatureOfExam] = useState("");

	const [pmh, setPMH] = useState([]);

	const [famHistory, setFamHistory] = useState([]);
	const [phSmoking, setPHSmoking] = useState("");

	const [classification, setClassification] = useState([]);

	let saveButtonRef = useRef(null);
	const [saveDialog, setSaveDialog] = useState(false);

	const getMedicalExamOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order.trace_number };
		Axios.get("laboratory/order/ordernew-medicalexam/details", { params })
			.then((response) => {
				const data = response.data;
				setOrderDetails({
					data: data,
					ready: true,
				});

				setSavebtnDisabled(false);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const handleSaveResult = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("trace_number", order.trace_number);

		for (let i = 0; i < pmh.length; i++) {
			formdata.append("pmh[]", pmh[i]);
		}

		for (let i = 0; i < famHistory.length; i++) {
			formdata.append("fam_history[]", famHistory[i]);
		}

		for (let i = 0; i < classification.length; i++) {
			formdata.append(classification[i], "yes");
		}

		var error = [];

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setResultSubmitting(true);
			Axios.post("van/order/ordernew-medicalexam/save-process-result", formdata)
				.then((response) => {
					const data = response.data;

					if (data.message === "success") {
						setSavebtnDisabled(true);
						updatePatientList();
						Notify.successRequest("order result added.");
						getPEOrderToProcess();
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				});
		}
	};

	const handleSelectedPMHValue = (data) => {
		let xx = pmh.filter((x) => x === data);
		if (xx.length > 0) {
			return true;
		}
		return false;
	};

	const handleSelectedPMH = (data) => {
		let xx = pmh.filter((x) => x === data);

		if (xx.length > 0) {
			let y = pmh.filter((x) => x !== data);
			setPMH(y);
		} else {
			setPMH((pmh) => pmh.concat(data));
		}
	};

	const checkPMHOtherValue = (data) => {
		let xx = pmh.filter((x) => x === data);
		if (xx.length > 0) {
			return true;
		}
	};

	const handleSelectedFamHistValue = (data) => {
		let xx = famHistory.filter((x) => x === data);
		if (xx.length > 0) {
			return true;
		}
		return false;
	};

	const handleSelectedFamHist = (data) => {
		let xx = famHistory.filter((x) => x === data);

		if (xx.length > 0) {
			let y = famHistory.filter((x) => x !== data);
			setFamHistory(y);
		} else {
			setFamHistory((famHistory) => famHistory.concat(data));
		}
	};

	const checkFamHistOtherValue = (data) => {
		let xx = famHistory.filter((x) => x === data);
		if (xx.length > 0) {
			return true;
		}
	};

	const handleSelectedClassificationValue = (data) => {
		let xx = classification.filter((x) => x === data);
		if (xx.length > 0) {
			return true;
		}
		return false;
	};

	const handleSelectedClassification = (data) => {
		let xx = classification.filter((x) => x === data);

		if (xx.length > 0) {
			let y = classification.filter((x) => x !== data);
			setClassification(y);
		} else {
			setClassification((classification) => classification.concat(data));
		}
	};

	useEffect(() => {
		getMedicalExamOrderDetails();

		// eslint-disable-next-line
	}, [order]);

	return (
		<>
			<form onSubmit={handleSaveResult}>
				<Card elevation={0}>
					<CardContent>
						{orderDetails.ready
							? orderDetails.data.length > 0
								? orderDetails.data.map((data, index) => (
										<Fragment key={index}>
											{/* medical_exam order */}
											<Box hidden={!Boolean(parseInt(data.medical_exam))}>
												<Box mb={2} display="flex">
													<Box flexGrow={1} mt={2}>
														<Typography variant="h6" align="center">
															<b> Physical Examination </b>
														</Typography>
													</Box>
												</Box>

												<Box>
													{/* hidden inputs */}
													<Box>
														<TextField
															margin="dense"
															fullWidth
															name="order_id"
															label="order_id"
															value={data.order_id}
															hidden
														/>

														<TextField
															margin="dense"
															fullWidth
															name="patient_id"
															label="patient_id"
															value={data.patient_id}
															hidden
														/>

														<TextField
															margin="dense"
															fullWidth
															name="doctors_id"
															label="doctor_id"
															value={
																data.doctor_id === null ? "" : data.doctor_id
															}
															hidden
														/>
													</Box>

													<Box>
														<TextField
															fullWidth
															label="Nature of examination"
															name="nature_of_examination"
															select
															SelectProps={{
																native: true,
															}}
															InputLabelProps={{
																shrink: true,
															}}
															variant="outlined"
															value={natureOfExam}
															onChange={(e) => setNatureOfExam(e.target.value)}
														>
															<option value=""> Select </option>
															<option value="Pre-employment">
																Pre-employment
															</option>
															<option value="Annual PE"> Annual PE </option>
															<option value="Others"> Others </option>
														</TextField>
													</Box>

													{natureOfExam === "Others" && (
														<Box my={2}>
															<TextField
																fullWidth
																label="Specify"
																name="nature_of_examination_other_specify"
																variant="outlined"
															/>
														</Box>
													)}

													<Box my={2}>
														<Typography variant="subtitle1">
															I. MEDICAL HISTORY
														</Typography>
													</Box>

													<Box my={1}>
														<Box my={2}>
															<Typography variant="caption">
																PAST MEDICAL HISTORY
															</Typography>
														</Box>
														<Box display="flex">
															<Box>
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			checked={handleSelectedPMHValue(
																				"hypertension"
																			)}
																			onChange={() =>
																				handleSelectedPMH("hypertension")
																			}
																		/>
																	}
																	label="Hypertension"
																/>
															</Box>
															<Box>
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			checked={handleSelectedPMHValue(
																				"diabetes_melitus"
																			)}
																			onChange={() =>
																				handleSelectedPMH("diabetes_melitus")
																			}
																		/>
																	}
																	label="Diabetes Melitus"
																/>
															</Box>
															<Box>
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			checked={handleSelectedPMHValue("ptb")}
																			onChange={() => handleSelectedPMH("ptb")}
																		/>
																	}
																	label="PTB"
																/>
															</Box>
															<Box>
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			checked={handleSelectedPMHValue("others")}
																			onChange={() =>
																				handleSelectedPMH("others")
																			}
																		/>
																	}
																	label="Others"
																/>
															</Box>
															{checkPMHOtherValue("others") && (
																<Box>
																	<TextField
																		label="Specify"
																		name="pmh_other_specify"
																		fullWidth
																	/>
																</Box>
															)}
														</Box>
													</Box>

													<Box my={1}>
														<Box my={2}>
															<Typography variant="caption">
																FAMILY HISTORY
															</Typography>
														</Box>
														<Box display="flex">
															<Box>
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			checked={handleSelectedFamHistValue(
																				"hypertension"
																			)}
																			onChange={() =>
																				handleSelectedFamHist("hypertension")
																			}
																		/>
																	}
																	label="Hypertension"
																/>
															</Box>
															<Box>
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			checked={handleSelectedFamHistValue(
																				"asthma"
																			)}
																			onChange={() =>
																				handleSelectedFamHist("asthma")
																			}
																		/>
																	}
																	label="Asthma"
																/>
															</Box>
															<Box>
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			checked={handleSelectedFamHistValue(
																				"cancers"
																			)}
																			onChange={() =>
																				handleSelectedFamHist("cancers")
																			}
																		/>
																	}
																	label="Cancers"
																/>
															</Box>
														</Box>

														<Box display="flex">
															<Box>
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			checked={handleSelectedFamHistValue(
																				"cardiac_disease"
																			)}
																			onChange={() =>
																				handleSelectedFamHist("cardiac_disease")
																			}
																		/>
																	}
																	label="Cardiac Disease"
																/>
															</Box>
															<Box>
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			checked={handleSelectedFamHistValue(
																				"ptb"
																			)}
																			onChange={() =>
																				handleSelectedFamHist("ptb")
																			}
																		/>
																	}
																	label="PTB"
																/>
															</Box>
															<Box>
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			checked={handleSelectedFamHistValue(
																				"hyperthyroidism"
																			)}
																			onChange={() =>
																				handleSelectedFamHist("hyperthyroidism")
																			}
																		/>
																	}
																	label="Hyperthyroidism"
																/>
															</Box>
															<Box>
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			checked={handleSelectedFamHistValue(
																				"others"
																			)}
																			onChange={() =>
																				handleSelectedFamHist("others")
																			}
																		/>
																	}
																	label="Others"
																/>
															</Box>

															{checkFamHistOtherValue("others") && (
																<Box>
																	<TextField
																		label="Specify"
																		name="fam_hist_other_specify"
																		fullWidth
																	/>
																</Box>
															)}
														</Box>
													</Box>

													<Box my={2}>
														<TextField
															label="Previous Operation/Hospitalization"
															name="prev_operation_hospital"
															fullWidth
															variant="outlined"
														/>
													</Box>

													<Box>
														<Box mb={2}>
															<Typography variant="subtitle1">
																II. PERSONAL HISTORY
															</Typography>
														</Box>

														<Box>
															<TextField
																label="Smoking"
																name="ph_smoking"
																fullWidth
																select
																variant="outlined"
																SelectProps={{ native: true }}
																InputLabelProps={{ shrink: true }}
																value={phSmoking}
																onChange={(e) => setPHSmoking(e.target.value)}
															>
																<option value=""> Select </option>
																<option value="yes"> Yes </option>
																<option value="no"> No </option>
															</TextField>
														</Box>

														{phSmoking === "yes" && (
															<>
																<Box my={2}>
																	<TextField
																		label="Qty per day"
																		name="ph_smoking_perday"
																		fullWidth
																		variant="outlined"
																	/>
																</Box>

																<Box my={2}>
																	<TextField
																		label="# of years"
																		name="ph_smoking_no_of_year"
																		fullWidth
																		variant="outlined"
																	/>
																</Box>
															</>
														)}
													</Box>

													<Box my={2}>
														<TextField
															label="Alcohol"
															name="ph_alcohol"
															fullWidth
															select
															variant="outlined"
															SelectProps={{ native: true }}
															InputLabelProps={{ shrink: true }}
														>
															<option value=""> Select </option>
															<option value="yes"> Yes </option>
															<option value="no"> No </option>
														</TextField>
													</Box>

													<Box my={2}>
														<TextField
															label="Drug Abuse"
															name="ph_drug_abuse"
															fullWidth
															select
															variant="outlined"
															SelectProps={{ native: true }}
															InputLabelProps={{ shrink: true }}
														>
															<option value=""> Select </option>
															<option value="yes"> Yes </option>
															<option value="no"> No </option>
														</TextField>
													</Box>

													<Box my={2}>
														<Grid container spacing={2}>
															<Grid item xs={12} sm={6}>
																<Box my={2}>
																	<TextField
																		label="Allergies of Food"
																		name="allergies_of_foods"
																		fullWidth
																		variant="outlined"
																		multiline
																	/>
																</Box>
															</Grid>
															<Grid item xs={12} sm={6}>
																<Box my={2}>
																	<TextField
																		label="Allergies of Drugs"
																		name="allergies_of_drugs"
																		fullWidth
																		variant="outlined"
																		multiline
																	/>
																</Box>
															</Grid>
														</Grid>
													</Box>

													<Box my={2}>
														<Box mb={2}>
															<Typography variant="caption">
																MENSTRUAL/OBSTETRICAL HISTORY
															</Typography>
														</Box>

														<Box mb={2}>
															<Grid container spacing={2}>
																<Grid item xs={12} sm={4}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="LMP"
																		name="menstrual_lmp"
																	/>
																</Grid>
																<Grid item xs={12} sm={4}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="PMP"
																		name="menstrual_pmp"
																	/>
																</Grid>
																<Grid item xs={12} sm={4}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="G"
																		name="menstrual_g"
																	/>
																</Grid>
															</Grid>
														</Box>

														<Box mb={2}>
															<Grid container spacing={2}>
																<Grid item xs={12} sm={6}>
																	<TextField
																		variant="outlined"
																		label="P"
																		fullWidth
																		name="menstrual_p"
																	/>
																</Grid>
																<Grid item xs={12} sm={6}>
																	<TextField
																		variant="outlined"
																		label="Other"
																		fullWidth
																		name="menstrual_other"
																	/>
																</Grid>
															</Grid>
														</Box>
													</Box>

													<Box my={2}>
														<TextField
															label="Medication"
															name="medication"
															fullWidth
															variant="outlined"
														/>
													</Box>

													<Box my={2}>
														<Box>
															<Typography variant="subtitle1">
																III. PHYSICAL EXAMINATION
															</Typography>
														</Box>

														<Grid container spacing={2}>
															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="BP"
																		name="bp"
																		fullWidth
																		variant="outlined"
																	/>
																</Box>
															</Grid>
															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="PR"
																		name="pr"
																		fullWidth
																		variant="outlined"
																	/>
																</Box>
															</Grid>
															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="HT"
																		name="ht"
																		fullWidth
																		variant="outlined"
																	/>
																</Box>
															</Grid>
															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="WT"
																		name="wt"
																		fullWidth
																		variant="outlined"
																	/>
																</Box>
															</Grid>
															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="BMI"
																		name="bmi"
																		fullWidth
																		variant="outlined"
																	/>
																</Box>
															</Grid>
															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Range"
																		name="range"
																		fullWidth
																		variant="outlined"
																	/>
																</Box>
															</Grid>
														</Grid>
													</Box>

													<Box my={2}>
														<Box mb={2}>
															<Typography variant="caption">
																VISUAL ACUITY
															</Typography>
														</Box>

														<Box my={2}>
															<Grid container spacing={2}>
																<Grid item xs={12} sm={6}>
																	<Box>
																		<TextField
																			label="OD NEAR"
																			name="od_near"
																			fullWidth
																			variant="outlined"
																		/>
																	</Box>
																</Grid>
																<Grid item xs={12} sm={6}>
																	<Box>
																		<TextField
																			label="OD FAR"
																			name="od_far"
																			fullWidth
																			variant="outlined"
																		/>
																	</Box>
																</Grid>
															</Grid>
														</Box>

														<Box my={2}>
															<Grid container spacing={2}>
																<Grid item xs={12} sm={6}>
																	<Box>
																		<TextField
																			label="OS NEAR"
																			name="os_near"
																			fullWidth
																			variant="outlined"
																		/>
																	</Box>
																</Grid>
																<Grid item xs={12} sm={6}>
																	<Box>
																		<TextField
																			label="OS FAR"
																			name="os_far"
																			fullWidth
																			variant="outlined"
																		/>
																	</Box>
																</Grid>
															</Grid>
														</Box>
													</Box>

													<Box my={2}>
														<Box mb={2}>
															<Typography variant="caption">HEARING</Typography>
														</Box>

														<Grid container spacing={2}>
															<Grid item xs={12} sm={6}>
																<Box>
																	<TextField
																		label="HEARING AD"
																		name="hearing_ad"
																		fullWidth
																		variant="outlined"
																	/>
																</Box>
															</Grid>

															<Grid item xs={12} sm={6}>
																<Box>
																	<TextField
																		label="HEARING AS"
																		name="hearing_as"
																		fullWidth
																		variant="outlined"
																	/>
																</Box>
															</Grid>
														</Grid>
													</Box>

													<Box>
														<Grid container spacing={2}>
															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Skin"
																		name="skin"
																		fullWidth
																		variant="outlined"
																		select
																		SelectProps={{ native: true }}
																		InputLabelProps={{ shrink: true }}
																	>
																		<option value="normal"> Normal </option>
																		<option value="abnormal"> Abnormal </option>
																	</TextField>
																</Box>
															</Grid>

															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Heent"
																		name="heent"
																		fullWidth
																		variant="outlined"
																		select
																		SelectProps={{ native: true }}
																		InputLabelProps={{ shrink: true }}
																	>
																		<option value="normal"> Normal </option>
																		<option value="abnormal"> Abnormal </option>
																	</TextField>
																</Box>
															</Grid>

															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Neck"
																		name="neck"
																		fullWidth
																		variant="outlined"
																		select
																		SelectProps={{ native: true }}
																		InputLabelProps={{ shrink: true }}
																	>
																		<option value="normal"> Normal </option>
																		<option value="abnormal"> Abnormal </option>
																	</TextField>
																</Box>
															</Grid>

															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Chest/Lungs"
																		name="chest"
																		fullWidth
																		variant="outlined"
																		select
																		SelectProps={{ native: true }}
																		InputLabelProps={{ shrink: true }}
																	>
																		<option value="normal"> Normal </option>
																		<option value="abnormal"> Abnormal </option>
																	</TextField>
																</Box>
															</Grid>

															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Abdomen"
																		name="abdomen"
																		fullWidth
																		variant="outlined"
																		select
																		SelectProps={{ native: true }}
																		InputLabelProps={{ shrink: true }}
																	>
																		<option value="normal"> Normal </option>
																		<option value="abnormal"> Abnormal </option>
																	</TextField>
																</Box>
															</Grid>

															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Cardiovascular"
																		name="cardio"
																		fullWidth
																		variant="outlined"
																		select
																		SelectProps={{ native: true }}
																		InputLabelProps={{ shrink: true }}
																	>
																		<option value="normal"> Normal </option>
																		<option value="abnormal"> Abnormal </option>
																	</TextField>
																</Box>
															</Grid>

															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Genitourinary Tract"
																		name="genitourinary"
																		fullWidth
																		variant="outlined"
																		select
																		SelectProps={{ native: true }}
																		InputLabelProps={{ shrink: true }}
																	>
																		<option value="normal"> Normal </option>
																		<option value="abnormal"> Abnormal </option>
																	</TextField>
																</Box>
															</Grid>

															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Genitalia"
																		name="genitalia"
																		fullWidth
																		variant="outlined"
																		select
																		SelectProps={{ native: true }}
																		InputLabelProps={{ shrink: true }}
																	>
																		<option value="normal"> Normal </option>
																		<option value="abnormal"> Abnormal </option>
																	</TextField>
																</Box>
															</Grid>

															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Inguinal"
																		name="inguinal"
																		fullWidth
																		variant="outlined"
																		select
																		SelectProps={{ native: true }}
																		InputLabelProps={{ shrink: true }}
																	>
																		<option value="normal"> Normal </option>
																		<option value="abnormal"> Abnormal </option>
																	</TextField>
																</Box>
															</Grid>

															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Extremities"
																		name="extremities"
																		fullWidth
																		variant="outlined"
																		select
																		SelectProps={{ native: true }}
																		InputLabelProps={{ shrink: true }}
																	>
																		<option value="normal"> Normal </option>
																		<option value="abnormal"> Abnormal </option>
																	</TextField>
																</Box>
															</Grid>

															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Reflexes"
																		name="reflexes"
																		fullWidth
																		variant="outlined"
																		select
																		SelectProps={{ native: true }}
																		InputLabelProps={{ shrink: true }}
																	>
																		<option value="normal"> Normal </option>
																		<option value="abnormal"> Abnormal </option>
																	</TextField>
																</Box>
															</Grid>

															<Grid item xs={12} sm={4}>
																<Box>
																	<TextField
																		label="Neurology"
																		name="neuro"
																		fullWidth
																		variant="outlined"
																		select
																		SelectProps={{ native: true }}
																		InputLabelProps={{ shrink: true }}
																	>
																		<option value="normal"> Normal </option>
																		<option value="abnormal"> Abnormal </option>
																	</TextField>
																</Box>
															</Grid>
														</Grid>
													</Box>

													<Box my={2}>
														<Box>
															<Typography variant="subtitle1">
																III. CLASSIFICATION
															</Typography>
														</Box>

														<Box>
															<FormControlLabel
																control={
																	<Checkbox
																		name="classification_a"
																		color="primary"
																		value={handleSelectedClassificationValue(
																			"classification_a"
																		)}
																		onChange={() =>
																			handleSelectedClassification(
																				"classification_a"
																			)
																		}
																	/>
																}
																label="Physically fit to work"
															/>
														</Box>

														<Box>
															<FormControlLabel
																control={
																	<Checkbox
																		name="classification_b"
																		color="primary"
																		value={handleSelectedClassificationValue(
																			"classification_b"
																		)}
																		onChange={() =>
																			handleSelectedClassification(
																				"classification_b"
																			)
																		}
																	/>
																}
																label="Physically to work with findings but offers no handicap to the job applied."
															/>
														</Box>

														<Box>
															<FormControlLabel
																control={
																	<Checkbox
																		name="classification_c"
																		color="primary"
																		value={handleSelectedClassificationValue(
																			"classification_c"
																		)}
																		onChange={() =>
																			handleSelectedClassification(
																				"classification_c"
																			)
																		}
																	/>
																}
																label="With abnormal findings (employment upon descretion of employer)."
															/>
														</Box>

														<Box>
															<FormControlLabel
																control={
																	<Checkbox
																		name="classification_d"
																		color="primary"
																		value={handleSelectedClassificationValue(
																			"classification_d"
																		)}
																		onChange={() =>
																			handleSelectedClassification(
																				"classification_d"
																			)
																		}
																	/>
																}
																label="Unfit for any type of employment"
															/>
														</Box>

														<Box>
															<FormControlLabel
																control={
																	<Checkbox
																		name="classification_e"
																		color="primary"
																		value={handleSelectedClassificationValue(
																			"classification_e"
																		)}
																		onChange={() =>
																			handleSelectedClassification(
																				"classification_e"
																			)
																		}
																	/>
																}
																label="Classification pending due to"
															/>
														</Box>
													</Box>

													<Box my={2}>
														<TextField
															label="Impression/Diagnosis"
															name="impression"
															fullWidth
															variant="outlined"
														/>
													</Box>

													<Box my={2}>
														<TextField
															label="Recommendations"
															name="recommendations"
															fullWidth
															variant="outlined"
														/>
													</Box>

													<Box my={2}>
														<Box mb={1}>
															<Typography variant="subtitle1">
																ANNUAL P.E PROCEDURE
															</Typography>
														</Box>

														<Table>
															<TableBody>
																<TableRow>
																	<TableCell> Step 1 </TableCell>
																	<TableCell>
																		Registration (P.E Form )
																	</TableCell>
																	<TableCell>
																		<TextField
																			label="Time"
																			name="registration_time"
																		/>
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell> Step 2 </TableCell>
																	<TableCell> Vital Signs Taking </TableCell>
																	<TableCell>
																		<TextField
																			label="Time"
																			name="vital_signs_time"
																		/>
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell> Step 3 </TableCell>
																	<TableCell> CBC/Blood Extraction </TableCell>
																	<TableCell>
																		<TextField label="Time" name="cbc_time" />
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell> Step 4 </TableCell>
																	<TableCell> Urinalysis </TableCell>
																	<TableCell>
																		<TextField
																			label="Time"
																			name="urinalysis_time"
																		/>
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell> Step 5 </TableCell>
																	<TableCell> Fecalysis </TableCell>
																	<TableCell>
																		<TextField
																			label="Time"
																			name="fecalysis_time"
																		/>
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell> Step 6 </TableCell>
																	<TableCell> X-ray</TableCell>
																	<TableCell>
																		<TextField label="Time" name="xray_time" />
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell> Step 7 </TableCell>
																	<TableCell> ECG </TableCell>
																	<TableCell>
																		<TextField label="Time" name="ecg_time" />
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell> Step 8 </TableCell>
																	<TableCell> Pap Smear </TableCell>
																	<TableCell>
																		<TextField
																			label="Time"
																			name="papsmear_time"
																		/>
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell> Step 9 </TableCell>
																	<TableCell> Eye Checkup </TableCell>
																	<TableCell>
																		<TextField
																			label="Time"
																			name="eye_checkup_time"
																		/>
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell> Step 10 </TableCell>
																	<TableCell> Dental Checkup </TableCell>
																	<TableCell>
																		<TextField
																			label="Time"
																			name="dental_checkup_time"
																		/>
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell> Step 11 </TableCell>
																	<TableCell> Physical Examination </TableCell>
																	<TableCell>
																		<TextField label="Time" name="pe_time" />
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell> Step 12 </TableCell>
																	<TableCell> Exit Signature </TableCell>
																	<TableCell>
																		<TextField
																			label="Time"
																			name="exit_signature_time"
																		/>
																	</TableCell>
																</TableRow>
															</TableBody>
														</Table>
													</Box>
												</Box>
											</Box>
											{/* ecg order end */}
										</Fragment>
								  ))
								: Notify.noRecord()
							: Notify.loading()}
					</CardContent>

					<Box display="flex" m={1}>
						<Box flexGrow={1} />
						<Box>
							<CardActions>
								<Button
									hidden
									variant="contained"
									size="large"
									color="primary"
									type="submit"
									ref={saveButtonRef}
									disabled={!saveDialog}
									startIcon={
										resultSubmitting && (
											<CircularProgress size={20} color="inherit" />
										)
									}
								>
									Save Result
								</Button>

								<Button
									variant="contained"
									size="large"
									color="primary"
									disabled={savebtnDisabled}
									startIcon={
										resultSubmitting && (
											<CircularProgress size={20} color="inherit" />
										)
									}
									onClick={() => {
										setSaveDialog(true);
									}}
								>
									Save Result
								</Button>
							</CardActions>
						</Box>
					</Box>
				</Card>
			</form>

			{/* configm dialog */}

			<Dialog open={saveDialog} onClose={() => setSaveDialog(false)}>
				<DialogContent>
					<Typography>Are you sure to continue and save your input?</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						color="default"
						variant="contained"
						onClick={() => setSaveDialog(false)}
						startIcon={<XCircle />}
					>
						No
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={() => saveButtonRef.current.click()}
						startIcon={<CheckSquare />}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DocuPatientDetailsPEFormDetails;
