import { TextField } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import ContactTracingForm from "./ContactTracingForm";

const PatientForm = ({
	register,
	errors,
	setValue,
	selectedSickness,
	setSelectedSickness,
	accreditedCompany = [],
}) => {
	const [locations, setLocations] = useState([]);
	const [brgys, setBrgys] = useState([]);

	useEffect(() => {
		getLocations();
	}, []);
	const getLocations = () => {
		axios.get("/v1/locations").then((res) => {
			console.log("resss", res.data);
			setLocations(res.data);
		});
	};
	return (
		<div className="p-4 flex flex-col">
			<h5 className="text-base font-semibold text-tertiary pb-1  mb-4 border-b w-2/3">
				Personal Information
			</h5>
			<div className="grid grid-cols-3 gap-5 mb-4 w-full">
				<TextField
					label={
						<>
							Lastname
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					{...register("lastname", {
						required: true,
					})}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.lastname}
					helperText={errors?.lastname && "This field is required"}
				/>
				<TextField
					label={
						<>
							Firstname
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					{...register("firstname", {
						required: true,
					})}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.firstname}
					helperText={errors?.firstname && "This field is required"}
				/>
				<TextField
					label={<>Middlename</>}
					variant="outlined"
					{...register("middlename")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.middlename}
					helperText={errors?.middlename && "This field is required"}
				/>
			</div>
			<div className="grid grid-cols-3 gap-5 mb-4">
				<TextField
					label={"Gender"}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("gender", {
						required: true,
					})}
					error={errors?.gender}
					helperText={errors?.gender && "This field is required"}
				>
					<option value=""> SELECT </option>
					<option value="Male"> Male </option>
					<option value="Female"> Female </option>
				</TextField>
				<TextField
					label={
						<>
							Birth Date
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					type="date"
					variant="outlined"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("birthday", {
						required: true,
					})}
					error={errors?.birthday}
					helperText={errors?.birthday && "This field is required"}
				/>
				<TextField label={`Birth Place`} variant="outlined" />
			</div>
			<div className="grid grid-cols-12 gap-6 mb-4">
				<TextField
					className="col-span-3"
					label={
						<>
							City/Municipality
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("city", {
						required: true,
					})}
					handle
					error={errors?.city}
					helperText={errors?.city && "This field is required"}
					onChange={(e) => {
						console.log(
							"eee",
							e.target.value,
							locations.find((x) => x.id == e.target.value).barangays
						);
						setValue("barangay", "");
						setBrgys(locations.find((x) => x.id == e.target.value).barangays);
					}}
				>
					<option value=""> SELECT </option>
					{locations?.map((location) => {
						return (
							<option key={`location-${location?.id}`} value={location?.id}>
								{location?.name}{" "}
							</option>
						);
					})}
				</TextField>
				<TextField
					className="col-span-3"
					label={
						<>
							Barangay
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("barangay", {
						required: true,
					})}
					error={errors?.barangay}
					helperText={errors?.barangay && "This field is required"}
				>
					<option value=""> SELECT </option>
					{brgys?.map((brgy) => {
						return <option value={`brgy-${brgy?.id}`}>{brgy?.name} </option>;
					})}
				</TextField>
				<TextField
					className="col-span-6"
					label={
						<>
							House/Lot & Block No. and Street
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					{...register("street", {
						required: true,
					})}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.street}
					helperText={errors?.street && "This field is required"}
				/>
				<TextField
					className="col-span-5"
					label={
						<>
							Occupation
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					{...register("occupation", {
						required: true,
					})}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.occupation}
					helperText={errors?.occupation && "This field is required"}
				/>
				<TextField
					className="col-span-4"
					label={
						<>
							Religion
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					{...register("religion", {
						required: true,
					})}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.religion}
					helperText={errors?.religion && "This field is required"}
				/>
				<TextField
					className="col-span-3"
					label={
						<>
							Civil Status
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("civil_status", {
						required: true,
					})}
					error={errors?.civil_status}
					helperText={errors?.civil_status && "This field is required"}
				>
					<option value=""> SELECT </option>
					<option value="Single"> Single </option>
					<option value="Maried"> Maried </option>
					<option value="Divorced"> Divorced </option>
				</TextField>
				<TextField
					className="col-span-4"
					label={
						<>
							Mobile
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					{...register("mobile", {
						required: true,
					})}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.mobile}
					helperText={errors?.mobile && "This field is required"}
				/>
				<TextField
					className="col-span-4"
					label={<>Telephone</>}
					variant="outlined"
					{...register("telephone")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.telephone}
					helperText={errors?.telephone && "This field is required"}
				/>
				<TextField
					className="col-span-4"
					label={<>Email</>}
					variant="outlined"
					{...register("email")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.email}
					helperText={errors?.email && "This field is required"}
				/>
			</div>
			<div className="grid grid-cols-2 gap-5 mb-4">
				<TextField
					label={<>Philhealth</>}
					variant="outlined"
					{...register("philhealth")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.philhealth}
					helperText={errors?.philhealth && "This field is required"}
				/>
				<TextField
					label={
						<>
							Temperature
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					{...register("temp", {
						required: true,
					})}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.temp}
					helperText={errors?.temp && "This field is required"}
				/>
			</div>
			<ContactTracingForm
				className={`p-0`}
				register={register}
				errors={errors}
				setValue={setValue}
				selectedSickness={selectedSickness}
				setSelectedSickness={setSelectedSickness}
				accreditedCompany={accreditedCompany}
			/>
		</div>
	);
};

export default PatientForm;
