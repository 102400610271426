import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TableHead,
} from "@material-ui/core";
import FormHeader from "./FormHeader";
import { useReactToPrint } from "react-to-print";
import { Printer } from "react-feather";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import moment from "moment";

const FormTwentySix = ({ patient_id, trace_number, info }) => {
  const mounted = IsMountedRef();
  const [chartRd, setChartRd] = useState(false);
  const printableRef = React.useRef();

  const { users } = React.useContext(UsersData);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const [billingList, setBillingList] = useState([]);

  const getCurrentBill = React.useCallback(() => {
    axios
      .get("or/charts/patient/patient-getchartbilling", {
        params: {
          patient_id: patient_id,
          trace_number: trace_number,
          user_id: users.user_id,
        },
      })
      .then((response) => {
        let data = response.data;
        if (mounted.current) {
          setBillingList(data);
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setChartRd(true));
  }, [trace_number, patient_id, mounted, users]);

  useEffect(() => {
    getCurrentBill();
  }, [getCurrentBill]);

  let total = 0;

  return (
    <div>
      <Card ref={printableRef}>
        <CardContent>
          <Box>
            <FormHeader />
          </Box>

          <Box my={1}>
            <Typography align="center">
              <b> STATEMENT OF ACCOUNT </b>
            </Typography>
          </Box>

          <Box my={1}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  <Typography> Patient No. : </Typography>
                </Box>
                <Box>
                  <Typography>
                    Patient Name :
                    {info
                      ? `${info.lastname}, ${info.firstname} ${
                          info.middle === null ? "" : info.middle
                        }`
                      : ""}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    Patient Address :
                    {info
                      ? `${info.street}, ${info.barangay}, ${info.city}`
                      : ""}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box>
                  <Typography>
                    Age/RM/NS :
                    {info
                      ? info.birthday === null
                        ? ""
                        : Notify.calculateAge(info.birthday)
                      : ""}
                    /OPD/0.00
                  </Typography>
                </Box>

                <Box>
                  <Typography> Admitted : 11/26/2021 </Typography>
                </Box>

                <Box>
                  <Typography> Discharged : 11/26/2021 </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {chartRd ? (
            <form>
              <Box my={3}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                  mb={1}
                >
                  <>
                    <Box>
                      <Button
                        color="primary"
                        onClick={handlePrint}
                        startIcon={<Printer />}
                      >
                        Print
                      </Button>
                    </Box>
                  </>
                </Box>
              </Box>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> Bill </TableCell>
                    <TableCell> Type </TableCell>
                    <TableCell> Amount </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {billingList.length > 0 ? (
                    billingList.map((data, key) => (
                      <TableRow key={key}>
                        <TableCell> {data.bill_name} </TableCell>
                        <TableCell> {data.bill_from} </TableCell>
                        <TableCell align="right">
                          <span hidden>
                            {(total += parseFloat(data.bill_amount))}
                          </span>
                          {data.bill_amount}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell> </TableCell>
                    <TableCell>
                      <b> Amount to pay </b>
                    </TableCell>
                    <TableCell align="right">
                      <b> {Notify.convertToNumber(total)} </b>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell> </TableCell>
                    <TableCell>
                      <b> Philhealth Amount </b>
                    </TableCell>
                    <TableCell align="right">
                      <b>
                        {Notify.convertToNumber(
                          billingList?.[0]?.philhealth_amount ?? 0
                        )}
                      </b>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell> </TableCell>
                    <TableCell>
                      <b> Amount Payment </b>
                    </TableCell>
                    <TableCell align="right">
                      <b>
                        {Notify.convertToNumber(
                          billingList?.[0]?.payment_amount ?? 0
                        )}
                      </b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Box my={3}>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography>Prepared by:</Typography>

                      <Box align="center" mt={2}>
                        <Typography> Billing In Charge </Typography>
                        <Box borderTop={1}>
                          <Typography variant="caption">
                            Billing Personel
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box>
                      <Typography>Certified Correct:</Typography>

                      <Box align="center" mt={2}>
                        <Typography> Accounting In Charge </Typography>
                        <Box borderTop={1}>
                          <Typography variant="caption">
                            Accountant II
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Box mb={2}>
                  <Typography>
                    Billing Date/Time :
                    {moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}
                  </Typography>
                </Box>

                <Box>
                  <Typography>
                    Print Date/Time :
                    {moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}
                  </Typography>
                </Box>
              </Box>
            </form>
          ) : (
            Notify.loading()
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default FormTwentySix;
