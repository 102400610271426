import React, { useState, Fragment, useEffect, useContext } from "react";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import ReactQuill from "react-quill";
import Diagnosis from "../../utils/Diagnosis";
import Axios from "axios";
import Notify from "../../notification/Notify";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import { grey } from "@material-ui/core/colors";
import { UsersData } from "src/ContextAPI";
import CreatableSelect from "react-select/creatable";

const NotesForm = (props) => {
  const [isProcess, setIsProcess] = useState(false);
  const [diagnosisNotes, setDiagnosisNotes] = useState("");
  const [editDiagnosisNotes, seteditDiagnosisNotes] = useState("");
  //   const [isSearchable, setIsSearchable] = useState(false);
  const { users } = useContext(UsersData);

  const handleNewNotes = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", props.patient_id);
    formdata.set("diagnosis_notes", diagnosisNotes.replace("<p><br></p>", ""));

    var error = [];
    if (
      formdata.get("diagnosis[]").length === 0 ||
      formdata.get("diagnosis[]").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("diagnosis");
    }

    if (
      formdata.get("diagnosis_notes").length === 0 ||
      formdata.get("diagnosis_notes").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("notes");
    }
    if (error.length > 0) {
      console.warn("Form has an error. Please check.");
    } else {
      setIsProcess(true);
      // Axios.post('doctor/patient/diagnosis/newdiagnosis', formdata )
      Axios.post("doctor/patient/notes/new-notes", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            props.closeForm();
            props.getNotes();
            e.target.reset();
            setDiagnosisNotes("");
            Notify.successRequest("notes");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsProcess(false);
        });
    }
  };

  const handleEditNotes = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("notes_id", props.editNotes.id);
    formdata.set(
      "diagnosis_notes",
      editDiagnosisNotes.replace("<p><br></p>", "")
    );

    var error = [];
    if (
      formdata.get("diagnosis[]").length === 0 ||
      formdata.get("diagnosis[]").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("diagnosis");
    }

    if (
      formdata.get("diagnosis_notes").length === 0 ||
      formdata.get("diagnosis_notes").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("notes");
    }
    if (error.length > 0) {
      console.warn("Form has an error. Please check.");
    } else {
      setIsProcess(true);
      // Axios.post('doctor/patient/diagnosis/newdiagnosis', formdata )
      Axios.post("doctor/patient/notes/edit-notes", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            props.closeForm();
            props.getNotes();
            e.target.reset();
            seteditDiagnosisNotes("");
            Notify.successRequest("notes");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsProcess(false);
        });
    }
  };

  const gdvalue = (value) => {
    let newvalue = [];
    var sample = value.split(", ");
    for (let i = 0; i < sample.length; i++) {
      newvalue.push({ label: sample[i], value: sample[i] });
    }

    return newvalue;
  };

  useEffect(() => {
    seteditDiagnosisNotes(props.editNotes.notes);
  }, [props.editNotes.notes]);

  return (
    <Fragment>
      <form
        hidden={props.category === "add-notes" ? false : true}
        onSubmit={handleNewNotes}
        className="d-newdiagnosis-form"
      >
        <Box my={3} py={2}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="caption" color="textSecondary">
                {" "}
                SELECT DIAGNOSIS{" "}
              </Typography>
            </Box>

            {/* <Box>
              <IconButton
                size="small"
                color={isSearchable ? "primary" : "default"}
                onClick={() => {
                  setIsSearchable(!isSearchable);
                }}
              >
                <Keyboard />
              </IconButton>
            </Box> */}
          </Box>

          {/* <Select
            options={Diagnosis.list()}
            placeholder="Initial Diagnosis"
            name="diagnosis[]"
            isMulti={true}
            isSearchable={isSearchable}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                color: "black",
              }),
              control: (base) => ({
                ...base,
                minHeight: 55,
              }),
            }}
          /> */}

          <CreatableSelect
            isMulti
            isClearable={false}
            options={Diagnosis.list()}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                color: "black",
              }),
              control: (base) => ({
                ...base,
                minHeight: 55,
              }),
            }}
            name="diagnosis[]"
          />
        </Box>

        <Box mt={2}>
          <ReactQuill
            name="diagnosis_notes"
            theme="snow"
            value={diagnosisNotes}
            onChange={(e) => setDiagnosisNotes(e)}
            placeholder="Enter you notes here."
            style={{
              backgroundColor: grey[50],
              color: "#000",
            }}
          />
        </Box>
        <Box display="flex" mt={1} mb={3}>
          <Button
            startIcon={
              isProcess ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                <CheckCircleOutline />
              )
            }
            disabled={isProcess}
            color="primary"
            variant="contained"
            type="submit"
          >
            Save
          </Button>
          <Box ml={2}>
            <Button
              onClick={() => props.closeForm()}
              startIcon={<HighlightOffIcon />}
              color="inherit"
              variant="contained"
            >
              No
            </Button>
          </Box>
        </Box>
      </form>

      {/* edit notes form */}
      <form
        hidden={props.category === "edit-notes" ? false : true}
        onSubmit={handleEditNotes}
        className="d-newdiagnosis-form"
      >
        {props.editNotes.diagnosis !== null ? (
          <>
            <CreatableSelect
              isMulti
              isClearable={false}
              options={Diagnosis.list()}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: "black",
                }),
                control: (base) => ({
                  ...base,
                  minHeight: 55,
                }),
              }}
              name="diagnosis[]"
              placeholder="Edit Initial Diagnosis"
              defaultValue={gdvalue(props.editNotes.diagnosis)}
            />
          </>
        ) : null}

        <Box mt={2}>
          <ReactQuill
            name="diagnosis_notes"
            theme="snow"
            value={editDiagnosisNotes}
            placeholder="Enter you notes here."
            onChange={(e) => seteditDiagnosisNotes(e)}
            style={{
              backgroundColor: grey[50],
              color: "#000",
            }}
          />
        </Box>

        <Box display="flex" mt={1} mb={3}>
          <Button
            startIcon={
              isProcess ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                <CheckCircleOutline />
              )
            }
            disabled={isProcess}
            color="primary"
            variant="contained"
            type="submit"
          >
            Save
          </Button>
          <Box ml={2}>
            <Button
              onClick={() => props.closeForm()}
              startIcon={<HighlightOffIcon />}
              color="default"
              variant="contained"
            >
              No
            </Button>
          </Box>
        </Box>
      </form>
    </Fragment>
  );
};

export default NotesForm;
