import { faVirus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import { localArraySearch } from "src/helpers/utils";
import AddPhoUserModal from "./pho-accounts/AddPhoUserModal";

const ManagePhoUsers = () => {
	const add_user_ref = useRef(null);
	const [list, setList] = useState([]);
	const [keyword, setKeyword] = useState("");

	const [municipalities, setMunicipalities] = useState([]);
	const [brgys, setBrgys] = useState([]);
	const [purokList, setPurokList] = useState([]);

	const { data, loading, addToList, updateInList } = useDataTable(
		"v1/pho/users",
		setList,
		{}
	);
	useEffect(() => {
		if (data) {
			setList(data?.data);
		}
	}, [data]);
	useEffect(() => {
		getLocations();
	}, []);

	const getLocations = () => {
		axios.get("/v1/locations").then((res) => {
			setMunicipalities(res.data);
		});
	};
	const columns = [
		{
			header: "username",
			accessorKey: "username",
			headerClassName: "text-left min-w-[328px]",
			className: "text-left",
		},
		{
			header: "Email",
			accessorKey: "email",
			headerClassName: "text-left min-w-[328px]",
			className: "text-left",
		},
		{
			header: "User type",
			accessorKey: "type",
			headerClassName: "text-left min-w-[328px]",
			className: "text-left",
		},
		{
			header: "Action",
			accessorKey: "action",
			className: "w-[244px]",
			cell: (data) => {
				return (
					<div className="flex items-center gap-2">
						<ActionBtn
							size="sm"
							type="primary-light"
							onClick={() => {
								console.log("datadata", data);
								add_user_ref.current.show(data);
							}}
						>
							<FlatIcon icon="rr-edit" className="text-lg mr-2" />
							Edit
						</ActionBtn>
					</div>
				);
			},
		},
	];
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon icon={faVirus} color="white" size={"2x"} />
				}
				title="Health Officer Accounts"
				subtitle="Manage health officers accounts"
			>
				<CardContainer
					containerClassName={` border border- border-opacity-10 w-full`}
					header={{
						title: "Health Officers Accounts List",
						children: (
							<div className="ml-auto flex items-center gap-3">
								<ActionBtn
									size="sm"
									type="primary"
									onClick={() => {
										add_user_ref.current.show();
									}}
								>
									<FlatIcon
										icon="rr-plus"
										className="text-lg mr-2"
									/>
									Add record
								</ActionBtn>
								<TextInputField
									inputClassName=" pl-10"
									className="w-full lg:w-[320px]"
									onChange={(e) => {
										setKeyword(e.target.value);
									}}
									icon={
										<FlatIcon
											icon="rr-search"
											className="text-sm"
										/>
									}
									placeholder="Search"
								/>
							</div>
						),
					}}
					className="!p-0"
				>
					<div className="max-h-[calc(100vh-328px)] overflow-auto">
						<TableV2
							paginationClassName="px-4"
							columns={columns}
							pagination={true}
							loading={loading}
							data={list}
							onTableChange={(data) => {}}
							theadClassName={`sticky top-[-1px]`}
						/>
					</div>
				</CardContainer>
			</LayoutContainer>
			<AddPhoUserModal
				addToList={addToList}
				ref={add_user_ref}
				municipalities={municipalities}
			/>
		</>
	);
};

export default ManagePhoUsers;
