import React, {
  useEffect,
  // useEffect,
  useState,
  Fragment,
} from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  //   Grid,
  Button,
  CircularProgress,
  //   TextField,
  //   Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import FormHeader from "./FormHeader";
import { useReactToPrint } from "react-to-print";
import { XCircle, Printer, CheckSquare } from "react-feather";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import Notify from "src/notification/Notify";
import { Edit } from "@material-ui/icons";

const FormMedicalTreatmentSheetStat = ({
  patient_id,
  trace_number,
  info,
  doctorsList,
  allowEdit,
}) => {
  const printableRef = React.useRef();
  const { users } = React.useContext(UsersData);
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [medsList, setMedsList] = useState({ data: [], ready: false });
  const [isProcess, setIsProcess] = useState(false);
  const [ccmts_id, setccmts_id] = useState(null);
  const [chartPhysician, setChartPhysician] = useState("");
  const mounted = IsMountedRef();
  const [newMeds, setNewMeds] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  //not okay
  const handleNewMedicationsStat = (e) => {
    e.preventDefault();
    e.persist();

    let err = [];
    let form = new FormData(e.target);

    form.append("user_id", users.user_id);
    form.append("main_mgmt_id", users.main_mgmt_id);
    form.append("management_id", users.management_id);
    form.append("patient_id", patient_id);
    form.append("trace_number", trace_number);
    form.append("physician", chartPhysician);

    if (medsList.data.length > 0) {
      form.append("room_no", medsList.data[0].room_no);
      form.append("physician", medsList.data[0].physician);
      form.append("allergic_to", medsList.data[0].allergic_to);
      form.append("year", medsList.data[0].year);
    }

    if (err.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcess(true);
      axios
        .post("or/charts/patient/patient-addressograph", form)
        .then((response) => {
          let data = response.data;

          if (data.message === "success") {
            getMedicalSheetStat();
            Notify.successRequest("Chart updated");
            setNewMeds(false);
            setEnableEdit(false);
          }
        })
        .catch((err) => console.log(err.message))
        .finally(() => setIsProcess(false));
    }
  };

  const getMedicalSheetStat = React.useCallback(() => {
    axios
      .get("or/get/charts/patient/patient-medicalsheet-stat", {
        params: {
          patient_id,
          trace_number,
        },
      })
      .then((response) => {
        let data = response.data;
        if (mounted.current) {
          setMedsList({ data, ready: true });
          if (data.length > 0) {
            setccmts_id(data[0].ccmts_id);
          } else {
            setccmts_id(null);
          }
        }
      })
      .catch((err) => console.log("error: ", err.message));
  }, [patient_id, trace_number, mounted]);

  const handleSubmitMedicalSheetStatChart = (e) => {
    e.preventDefault();
    e.persist();
    setMedsList({ data: [], ready: false });
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("ccmts_id", ccmts_id);
    formdata.set("status", ccmts_id !== null ? "for-update" : "for-create");

    setIsProcess(true);
    axios
      .post("or/create/charts/patient/patient-medicalsheet-stat", formdata)
      .then((response) => {
        const data = response.data;
        if (data.message === "success") {
          e.target.reset();
          getMedicalSheetStat();
          setEnableEdit(!enableEdit);
          Notify.successRequest("medical sheet stat created");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  useEffect(() => {
    getMedicalSheetStat();
  }, [getMedicalSheetStat]);

  return (
    <Card ref={printableRef}>
      <CardContent>
        <Box>
          <FormHeader />
        </Box>
        <form onSubmit={handleSubmitMedicalSheetStatChart}>
          <Box my={3}>
            <Box
              display="flex"
              justifyContent="flex-end"
              className="d-print-none"
              mb={1}
            >
              {enableEdit ? (
                <>
                  <Box>
                    <Button
                      color="secondary"
                      onClick={() => setEnableEdit(false)}
                      startIcon={<XCircle />}
                    >
                      Close
                    </Button>
                  </Box>

                  <Box ml={2}>
                    <Button
                      color="primary"
                      startIcon={
                        isProcess ? (
                          <CircularProgress size={22} color="inherit" />
                        ) : (
                          <CheckSquare />
                        )
                      }
                      type="submit"
                      disabled={isProcess}
                    >
                      Save
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Box mr={2}>
                    <Button
                      color="primary"
                      onClick={() => setEnableEdit(true)}
                      startIcon={<Edit />}
                    >
                      Edit
                    </Button>
                  </Box>

                  {/* {allowEdit && (
                      <Box mr={2}>
                        <Button
                          color="primary"
                          startIcon={<Edit />}
                          onClick={() => setNewMeds(true)}
                        >
                          New
                        </Button>
                      </Box>
                    )} */}

                  <Box>
                    <Button
                      color="primary"
                      onClick={handlePrint}
                      startIcon={<Printer />}
                    >
                      Print
                    </Button>
                  </Box>
                </>
              )}
            </Box>

            <Box my={3} align="center">
              <Typography variant="subtitle2">
                <b> MEDICATION / TREATMENT SHEET (STAT) </b>
              </Typography>
            </Box>
            {medsList.ready && (
              <>
                <Box>
                  <Table className="table-bordered" size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography>Patients Name:</Typography>
                          <Typography>
                            {info
                              ? ` ${info.lastname}, ${info.firstname} ${
                                  info.middle === null ? "" : info.middle
                                }`
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={4}>
                          <Typography>Room No.:</Typography>

                          <Typography>
                            {!enableEdit
                              ? medsList.data.length > 0
                                ? medsList.data[0].room_no
                                : ""
                              : null}
                          </Typography>

                          {enableEdit && (
                            <TextField
                              fullWidth
                              name="room_no"
                              defaultValue={
                                medsList.data.length > 0
                                  ? medsList.data[0].room_no
                                  : ""
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell colSpan={4}>
                          <Typography>Physician:</Typography>

                          <Typography>
                            {!enableEdit
                              ? medsList.data.length > 0
                                ? medsList.data[0].physician
                                : ""
                              : null}
                          </Typography>

                          {enableEdit && (
                            <TextField
                              fullWidth
                              name="physician"
                              defaultValue={
                                medsList.data.length > 0
                                  ? medsList.data[0].physician
                                  : ""
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={12}>
                          <Typography>Allergic To:</Typography>
                          <Typography>
                            {!enableEdit
                              ? medsList.data.length > 0
                                ? medsList.data[0].allergic_to
                                : ""
                              : null}
                          </Typography>
                          {enableEdit && (
                            <TextField
                              fullWidth
                              name="allergic_to"
                              defaultValue={
                                medsList.data.length > 0
                                  ? medsList.data[0].allergic_to
                                  : ""
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>

                <Box align="center" mt={2}>
                  <Box my={2} mb={3}>
                    <TableContainer>
                      <Table
                        className="table-bordered custom-padding-table"
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={4}>
                              <b> MEDICATIONS </b>
                            </TableCell>
                            <TableCell colSpan={14}>
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <b>INITIAL DATES GIVEN</b>
                                </Box>
                                <Box mr={15}>
                                  <b>
                                    YEAR{" "}
                                    {medsList.data.length > 0
                                      ? medsList.data[0].year
                                      : ""}{" "}
                                  </b>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="center">Date Order</TableCell>
                            <TableCell align="center">Renewal Date</TableCell>
                            <TableCell>
                              *Medication <br /> *Dosage <br /> *Route of
                              Admission Frequency
                            </TableCell>
                            <TableCell align="center">Hour</TableCell>
                            <TableCell colSpan={2} align="center">
                              Date
                            </TableCell>
                            <TableCell colSpan={2} align="center">
                              Date
                            </TableCell>
                            <TableCell colSpan={2} align="center">
                              Date
                            </TableCell>
                            <TableCell colSpan={2} align="center">
                              Date
                            </TableCell>
                            <TableCell colSpan={2} align="center">
                              Date
                            </TableCell>
                            <TableCell colSpan={2} align="center">
                              Date
                            </TableCell>
                            <TableCell colSpan={2} align="center">
                              Date
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell rowSpan={5}>
                              {!enableEdit
                                ? medsList.data.length > 0
                                  ? medsList.data[0].date_order !== null
                                    ? Notify.createdAt(
                                        medsList.data[0].date_order
                                      )
                                    : ""
                                  : ""
                                : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  name="date_order"
                                  type="date"
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].date_order !== null
                                        ? medsList.data[0].date_order
                                        : ""
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell rowSpan={5}>
                              {!enableEdit
                                ? medsList.data.length > 0
                                  ? medsList.data[0].renewal_date !== null
                                    ? Notify.createdAt(
                                        medsList.data[0].renewal_date
                                      )
                                    : ""
                                  : ""
                                : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  name="renewal_date"
                                  type="date"
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].renewal_date !== null
                                        ? medsList.data[0].renewal_date
                                        : ""
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell rowSpan={5}>
                              {!enableEdit ? (
                                medsList.data.length > 0 ? (
                                  <Typography
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {medsList.data[0].medication}
                                  </Typography>
                                ) : (
                                  ""
                                )
                              ) : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].medication
                                      : ""
                                  }
                                  name="medication"
                                  multiline
                                  rows={6}
                                />
                              )}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell rowSpan={5}>
                              {!enableEdit
                                ? medsList.data.length > 0
                                  ? medsList.data[0].date_order2 !== null
                                    ? Notify.createdAt(
                                        medsList.data[0].date_order2
                                      )
                                    : ""
                                  : ""
                                : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  name="date_order2"
                                  type="date"
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].date_order2 !== null
                                        ? medsList.data[0].date_order2
                                        : ""
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell rowSpan={5}>
                              {!enableEdit
                                ? medsList.data.length > 0
                                  ? medsList.data[0].renewal_date2 !== null
                                    ? Notify.createdAt(
                                        medsList.data[0].renewal_date2
                                      )
                                    : ""
                                  : ""
                                : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  name="renewal_date2"
                                  type="date"
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].renewal_date2 !== null
                                        ? medsList.data[0].renewal_date2
                                        : ""
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell rowSpan={5}>
                              {!enableEdit ? (
                                medsList.data.length > 0 ? (
                                  <Typography
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {medsList.data[0].medication2}
                                  </Typography>
                                ) : (
                                  ""
                                )
                              ) : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].medication2
                                      : ""
                                  }
                                  name="medication2"
                                  multiline
                                  rows={6}
                                />
                              )}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell rowSpan={5}>
                              {!enableEdit
                                ? medsList.data.length > 0
                                  ? medsList.data[0].date_order3 !== null
                                    ? Notify.createdAt(
                                        medsList.data[0].date_order3
                                      )
                                    : ""
                                  : ""
                                : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  name="date_order3"
                                  type="date"
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].date_order3 !== null
                                        ? medsList.data[0].date_order3
                                        : ""
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell rowSpan={5}>
                              {!enableEdit
                                ? medsList.data.length > 0
                                  ? medsList.data[0].renewal_date3 !== null
                                    ? Notify.createdAt(
                                        medsList.data[0].renewal_date3
                                      )
                                    : ""
                                  : ""
                                : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  name="renewal_date3"
                                  type="date"
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].renewal_date3 !== null
                                        ? medsList.data[0].renewal_date3
                                        : ""
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell rowSpan={5}>
                              {!enableEdit ? (
                                medsList.data.length > 0 ? (
                                  <Typography
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {medsList.data[0].medication3}
                                  </Typography>
                                ) : (
                                  ""
                                )
                              ) : null}

                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].medication3
                                      : ""
                                  }
                                  name="medication3"
                                  multiline
                                  rows={6}
                                />
                              )}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell rowSpan={5}>
                              {!enableEdit
                                ? medsList.data.length > 0
                                  ? medsList.data[0].date_order4 !== null
                                    ? Notify.createdAt(
                                        medsList.data[0].date_order4
                                      )
                                    : ""
                                  : ""
                                : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  name="date_order4"
                                  type="date"
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].date_order4 !== null
                                        ? medsList.data[0].date_order4
                                        : ""
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell rowSpan={5}>
                              {!enableEdit
                                ? medsList.data.length > 0
                                  ? medsList.data[0].renewal_date4 !== null
                                    ? Notify.createdAt(
                                        medsList.data[0].renewal_date4
                                      )
                                    : ""
                                  : ""
                                : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  name="renewal_date4"
                                  type="date"
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].renewal_date4 !== null
                                        ? medsList.data[0].renewal_date4
                                        : ""
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell rowSpan={5}>
                              {!enableEdit ? (
                                medsList.data.length > 0 ? (
                                  <Typography
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {medsList.data[0].medication4}
                                  </Typography>
                                ) : (
                                  ""
                                )
                              ) : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].medication4
                                      : ""
                                  }
                                  name="medication4"
                                  multiline
                                  rows={6}
                                />
                              )}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell rowSpan={5}>
                              {!enableEdit
                                ? medsList.data.length > 0
                                  ? medsList.data[0].date_order5 !== null
                                    ? Notify.createdAt(
                                        medsList.data[0].date_order5
                                      )
                                    : ""
                                  : ""
                                : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  name="date_order5"
                                  type="date"
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].date_order5 !== null
                                        ? medsList.data[0].date_order5
                                        : ""
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell rowSpan={5}>
                              {!enableEdit
                                ? medsList.data.length > 0
                                  ? medsList.data[0].renewal_date5 !== null
                                    ? Notify.createdAt(
                                        medsList.data[0].renewal_date5
                                      )
                                    : ""
                                  : ""
                                : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  name="renewal_date5"
                                  type="date"
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].renewal_date5 !== null
                                        ? medsList.data[0].renewal_date5
                                        : ""
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell rowSpan={5}>
                              {!enableEdit ? (
                                medsList.data.length > 0 ? (
                                  <Typography
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {medsList.data[0].medication5}
                                  </Typography>
                                ) : (
                                  ""
                                )
                              ) : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].medication5
                                      : ""
                                  }
                                  name="medication5"
                                  multiline
                                  rows={6}
                                />
                              )}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell rowSpan={5}>
                              {!enableEdit
                                ? medsList.data.length > 0
                                  ? medsList.data[0].date_order6 !== null
                                    ? Notify.createdAt(
                                        medsList.data[0].date_order6
                                      )
                                    : ""
                                  : ""
                                : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  name="date_order6"
                                  type="date"
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].date_order6 !== null
                                        ? medsList.data[0].date_order6
                                        : ""
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell rowSpan={5}>
                              {!enableEdit
                                ? medsList.data.length > 0
                                  ? medsList.data[0].renewal_date6 !== null
                                    ? Notify.createdAt(
                                        medsList.data[0].renewal_date6
                                      )
                                    : ""
                                  : ""
                                : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  name="renewal_date6"
                                  type="date"
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].renewal_date6 !== null
                                        ? medsList.data[0].renewal_date6
                                        : ""
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell rowSpan={5}>
                              {!enableEdit ? (
                                medsList.data.length > 0 ? (
                                  <Typography
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {medsList.data[0].medication6}
                                  </Typography>
                                ) : (
                                  ""
                                )
                              ) : null}
                              {enableEdit && (
                                <TextField
                                  fullWidth
                                  defaultValue={
                                    medsList.data.length > 0
                                      ? medsList.data[0].medication6
                                      : ""
                                  }
                                  name="medication6"
                                  multiline
                                  rows={6}
                                />
                              )}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <Table className="table-bordered" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell> INITIAL </TableCell>
                        <TableCell> PRINT NAME AND TITLE </TableCell>
                        <TableCell> INITIAL </TableCell>
                        <TableCell> PRINT NAME AND TITLE </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </>
            )}
          </Box>
        </form>
      </CardContent>

      {/* dialog new meds dialog */}
      <Dialog
        open={newMeds}
        onClose={() => setNewMeds(false)}
        maxWidth={"xs"}
        fullWidth
        scroll="body"
      >
        <DialogTitle> New Medications </DialogTitle>

        <form onSubmit={handleNewMedicationsStat}>
          <DialogContent dividers>
            {medsList.data.length === 0 && (
              <>
                <Box>
                  <TextField
                    fullWidth
                    name="room_no"
                    variant={"outlined"}
                    label="Room No."
                  />
                </Box>

                <Box my={2}>
                  <TextField
                    name="physician"
                    variant={"outlined"}
                    label="Physician"
                    fullWidth
                    InputProps={{
                      style: {
                        textTransform: "capitalize",
                      },
                    }}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    value={chartPhysician}
                    onChange={(e) => setChartPhysician(e.target.value)}
                  >
                    <option value=""> Select </option>
                    {doctorsList.length > 0 ? (
                      doctorsList.map((data, key) => (
                        <option key={key} value={data.doctors_id}>
                          {data.name}
                        </option>
                      ))
                    ) : (
                      <option value=""> No doctor found. </option>
                    )}
                  </TextField>
                </Box>

                <Box my={2}>
                  <TextField
                    fullWidth
                    name="allergic_to"
                    variant={"outlined"}
                    label="Allergic to"
                    multiline
                  />
                </Box>

                <Box my={2}>
                  <TextField
                    fullWidth
                    name="year"
                    variant={"outlined"}
                    label="Year"
                  />
                </Box>
              </>
            )}

            <Box my={2}>
              <TextField
                fullWidth
                name="date_order"
                variant={"outlined"}
                label="Date Order"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>

            <Box my={2}>
              <TextField
                fullWidth
                name="renewal_date"
                variant={"outlined"}
                label="Renewal Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>

            <Box my={2}>
              <TextField
                fullWidth
                name="medication"
                variant={"outlined"}
                label="Medication"
                multiline
              />
            </Box>

            <Box my={2}>
              <TextField
                fullWidth
                name="dosage"
                variant={"outlined"}
                label="Dosage"
                multiline
              />
            </Box>

            <Box my={2}>
              <TextField
                fullWidth
                name="route_ofadmission"
                variant={"outlined"}
                label="Route of admission"
                multiline
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                name="frequency"
                variant={"outlined"}
                label="Frequency"
                multiline
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              variant="contained"
              startIcon={<XCircle />}
              onClick={() => setNewMeds(false)}
            >
              No
            </Button>

            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={
                isProcess ? (
                  <CircularProgress size={22} color="inherit" />
                ) : (
                  <CheckSquare />
                )
              }
              disabled={isProcess}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Card>
  );
};

export default FormMedicalTreatmentSheetStat;
