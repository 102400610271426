import React, { useState } from "react";
import { Tab, Paper, Box, Tabs, Grid } from "@material-ui/core";
import Container from "src/layout/Container";
import TabPanel from "src/utils/TabPanel";
import PackageList from "./PackageList";
import NewPackage from "./NewPackage";
import PackageSales from "./PackageSales";

const Packages = () => {
  const [category, setCategory] = useState("sales");

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Packages",
        items: [{ name: "Dashboard", path: "/sph/app/accounting" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Packages</Box>
          <Box maxWidth={450}>
            <Paper>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab value="sales" label="Sales" />
                <Tab value="package" label="Package List" />
                <Tab value="new-package" label="Add Package" />
              </Tabs>
            </Paper>
          </Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TabPanel value={category} index="sales">
            <PackageSales />
          </TabPanel>
          <TabPanel value={category} index="package">
            <PackageList />
          </TabPanel>
          <TabPanel value={category} index="new-package">
            <NewPackage />
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Packages;
