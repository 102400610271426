import {
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  CardActions,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const DocuPatientDetailsECGDetails = ({
  selectedBranch,
  patient_id,
  details,
  getECGTest,
  medTechList,
  updatePatientList,
}) => {
  const { users } = React.useContext(UsersData);
  const [savebtnStoolDisabled, setSavebtnStoolDisabled] = useState(false);
  const handleSaveECGResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    var error = [];
    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setSavebtnStoolDisabled(true);
      axios
        .post("van/order/ordernew-ecg/save-process-result", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getECGTest();
            updatePatientList();
            Notify.successRequest("order result added.");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setSavebtnStoolDisabled(false);
        });
    }
  };

  React.useEffect(() => {}, [patient_id]);

  return (
    <Fragment>
      <form onSubmit={handleSaveECGResult}>
        <Card elevation={0}>
          {details && (
            <Fragment>
              <CardContent>
                <Box hidden={!Boolean(parseInt(details.ecg_test))}>
                  <Box>
                    <Box>
                      <TextField
                        margin="dense"
                        fullWidth
                        name="order_id[]"
                        label="order_id"
                        value={details.order_id}
                        hidden
                      />
                      <TextField
                        margin="dense"
                        fullWidth
                        name="patient_id"
                        label="patient_id"
                        value={details.patient_id}
                        hidden
                      />

                      <TextField
                        margin="dense"
                        fullWidth
                        name="doctors_id"
                        label="doctor_id"
                        value={
                          details.doctor_id === null ? "" : details.doctor_id
                        }
                        hidden
                      />
                    </Box>

                    <Box>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Box>
                            <TextField
                              label="Atrial ventricular Rate"
                              name="atrial_ventricular_rate[]"
                              multiline
                              fullWidth
                            />
                          </Box>
                          <Box>
                            <TextField
                              label="Rhythm"
                              name="rhythm[]"
                              multiline
                              fullWidth
                            />
                          </Box>
                          <Box>
                            <TextField
                              label="Axis"
                              name="axis[]"
                              multiline
                              fullWidth
                            />
                          </Box>
                          <Box>
                            <TextField
                              label="P wave"
                              name="p_wave[]"
                              multiline
                              fullWidth
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            <TextField
                              label="PR interval"
                              name="pr_interval[]"
                              multiline
                              fullWidth
                            />
                          </Box>

                          <Box>
                            <TextField
                              label="QRS"
                              name="qrs[]"
                              multiline
                              fullWidth
                            />
                          </Box>

                          <Box>
                            <TextField
                              label="QT interval"
                              name="qt_interval[]"
                              multiline
                              fullWidth
                            />
                          </Box>

                          <Box>
                            <TextField
                              label="QRS complex"
                              name="qrs_complex[]"
                              multiline
                              fullWidth
                            />
                          </Box>

                          <Box>
                            <TextField
                              label="ST segment"
                              name="st_segment[]"
                              multiline
                              fullWidth
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <TextField
                              label="Others:"
                              name="others[]"
                              multiline
                              fullWidth
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <TextField
                              label="Interpretation (Note: this interpretation will be added in our clinical summary)"
                              name="interpretation[]"
                              multiline
                              fullWidth
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
              <Box display="flex" m={1}>
                <Box flexGrow={1} />
                <Box>
                  <CardActions>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                      disabled={savebtnStoolDisabled}
                    >
                      Save Result
                    </Button>
                  </CardActions>
                </Box>
              </Box>
            </Fragment>
          )}
        </Card>
      </form>
    </Fragment>
  );
};

export default DocuPatientDetailsECGDetails;
