import {
	Box,
	Dialog,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Button,
	DialogContent,
	DialogActions,
	CircularProgress,
	Grid,
	IconButton,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "src/utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { LinearScale } from "@material-ui/icons";

const AudiometryOrderDetails = ({
	queue,
	formheader,
	order,
	getPsycAudioOrder,
	resetDisplay,
	getPatientWithOrder,
	order_count,
	process_for,
}) => {
	const { users } = React.useContext(UsersData);
	const history = useHistory();
	const [savebtnDisabled, setSavebtnDisabled] = useState(true);
	const [processSubmitting, setProcessSubmitting] = useState(false);
	const [resultSubmitting, setResultSubmitting] = useState(false);
	const [orderDetails, setOrderDetails] = useState({
		data: null,
		ready: false,
	});

	const [processDialog, setProcessDialog] = useState({
		data: null,
		dialog: false,
	});

	const getLabOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order.trace_number };
		Axios.get("psychology/order/ordernew-audiometry/details", { params })
			.then((response) => {
				const data = response.data;
				setOrderDetails({
					data: data,
					ready: true,
				});

				checkIfSaveBtnIsEnabled(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const checkIfSaveBtnIsEnabled = (data) => {
		for (let i = 0; i < data.length; i++) {
			if (Boolean(data[i].is_processed)) {
				setSavebtnDisabled(false);
			}
		}
	};

	useEffect(() => {
		getLabOrderDetails();

		// eslint-disable-next-line
	}, [order]);

	const handleSaveResult = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("trace_number", order.trace_number);
		formdata.set("order_count", parseFloat(order_count));
		formdata.set("queue", queue);
		formdata.set("process_for", process_for);

		var error = [];
		setResultSubmitting(true);
		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			Axios.post(
				"psychology/order/ordernew-audiology/save-process-result",
				formdata
			)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						Notify.successRequest("order result added. Redirect to receipt.");
						setTimeout(() => {
							history.push(
								`/sph/app/psychology/record/print/order/${order.trace_number}`
							);
						}, 5000);

						getPatientWithOrder();
						setSavebtnDisabled(true);
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => setResultSubmitting(false));
		}
	};

	const handleProcessOrder = async (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", processDialog.data.order_id);
		formdata.set("trace_number", processDialog.data.trace_number);

		var error = [];
		setProcessSubmitting(true);

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			let response = await Axios.post(
				"psychology/audiometry/order-setprocess",
				formdata
			);

			if (response.data.message === "pass-invalid") {
				Notify.fieldInvalid("password");
			}

			if (response.data.message === "success") {
				Notify.successRequest("process");
				setProcessDialog({ data: null, dialog: false });
				getLabOrderDetails();
			}
		}

		setTimeout(() => {
			setProcessSubmitting(false);
		}, 2000);
	};

	const handleProcessSelectedOrder = async (data) => {
		setProcessDialog({ data: data, dialog: true });
	};

	return (
		<>
			<form onSubmit={handleSaveResult}>
				<Card elevation={0}>
					<Box>
						<CardHeader
							component={Box}
							align="center"
							title={formheader && formheader.name}
							subheader={
								<Box>
									<Typography>{formheader && formheader.address}</Typography>
									<Typography>
										{formheader && formheader.contact_number}
									</Typography>
								</Box>
							}
						/>
					</Box>

					<CardContent>
						{/* paitent information */}
						<Box display="flex">
							<Box flexGrow={1} mb={2}>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT NAME:{" "}
										</Typography>
										{`${order.lastname}, ${order.firstname} ${
											order.middle !== null ? order.middle : null
										}`}
									</Typography>
								</Box>

								<Box display="flex" mb={2}>
									<Box>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												AGE:{" "}
											</Typography>
											{order.birthday === null
												? "none"
												: Notify.calculateAge(order.birthday)}
										</Typography>
									</Box>
									<Box ml={5}>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												GENDER:{" "}
											</Typography>
											{order.gender === null ? "none" : order.gender}
										</Typography>
									</Box>
								</Box>

								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT ADDRESS:{" "}
										</Typography>
										{`${order.street} ${order.barangay} ${order.city} `}
									</Typography>
								</Box>
							</Box>

							<Box>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											DATE:{" "}
										</Typography>
										{Notify.createdAt(new Date().toLocaleString())}
									</Typography>
								</Box>
							</Box>
						</Box>
					</CardContent>

					<CardContent>
						{orderDetails.ready
							? orderDetails.data.length > 0
								? orderDetails.data.map((data, index) => (
										<Fragment key={index}>
											<Box>
												<Box mb={2} display="flex">
													<Box flexGrow={1} />
													<Box>
														<Button
															disabled={Boolean(parseInt(data.is_processed))}
															size="small"
															variant="contained"
															color="primary"
															onClick={() => {
																handleProcessSelectedOrder(data);
															}}
														>
															Process
														</Button>
													</Box>
												</Box>

												<Box
													mb={2}
													display="flex"
													borderBottom={1}
													borderColor={"#AEAEAE"}
												>
													<Box flexGrow={1}>
														<Typography variant="h6" align="center">
															<b> AUDIOMETRIC REPORT </b>
														</Typography>
													</Box>
												</Box>

												<Box hidden={!Boolean(parseInt(data.is_processed))}>
													<TableContainer>
														<Table>
															<TableHead>
																<TableRow>
																	<TableCell> Frequency in hertz </TableCell>
																	<TableCell align="center">
																		<b> 8000 </b>
																	</TableCell>
																	<TableCell align="center">
																		<b> 4000 </b>
																	</TableCell>
																	<TableCell align="center">
																		<b> 2000 </b>
																	</TableCell>
																	<TableCell align="center">
																		<b> 1000 </b>
																	</TableCell>
																	<TableCell align="center">
																		<b> 500 </b>
																	</TableCell>
																	<TableCell align="center">
																		<b> 250 </b>
																	</TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																<TableRow
																	hidden={data.audiometry_test === null}
																>
																	<TableCell>
																		<Typography
																			variant="subtitle2"
																			className={`gtc-uppercase`}
																		>
																			LEFT EAR
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="order_id[]"
																				label="order_id"
																				hidden
																				value={data.order_id}
																			/>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="patient_id"
																				label="Result"
																				hidden
																				value={data.patient_id}
																			/>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="doctors_id"
																				label="doctor_id"
																				hidden
																				value={
																					data.doctor_id === null
																						? ""
																						: data.doctor_id
																				}
																			/>
																		</Box>
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="left_8000[]"
																				label="Result"
																				type="number"
																				required={
																					data.left_8000 === null ? true : false
																				}
																				InputProps={{
																					inputProps: {
																						min: 1,
																						max: 1000,
																						step: 0.01,
																					},
																					readOnly: !Boolean(
																						parseInt(data.is_processed)
																					),
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="left_4000[]"
																				label="Result"
																				type="number"
																				required={
																					data.left_4000 === null ? true : false
																				}
																				InputProps={{
																					inputProps: {
																						min: 1,
																						max: 1000,
																						step: 0.01,
																					},
																					readOnly: !Boolean(
																						parseInt(data.is_processed)
																					),
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="left_2000[]"
																				label="Result"
																				type="number"
																				required={
																					data.left_2000 === null ? true : false
																				}
																				InputProps={{
																					inputProps: {
																						min: 1,
																						max: 1000,
																						step: 0.01,
																					},
																					readOnly: !Boolean(
																						parseInt(data.is_processed)
																					),
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="left_1000[]"
																				label="Result"
																				type="number"
																				required={
																					data.left_1000 === null ? true : false
																				}
																				InputProps={{
																					inputProps: {
																						min: 1,
																						max: 1000,
																						step: 0.01,
																					},
																					readOnly: !Boolean(
																						parseInt(data.is_processed)
																					),
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="left_500[]"
																				label="Result"
																				type="number"
																				required={
																					data.left_500 === null ? true : false
																				}
																				InputProps={{
																					inputProps: {
																						min: 1,
																						max: 1000,
																						step: 0.01,
																					},
																					readOnly: !Boolean(
																						parseInt(data.is_processed)
																					),
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="left_250[]"
																				label="Result"
																				type="number"
																				required={
																					data.left_250 === null ? true : false
																				}
																				InputProps={{
																					inputProps: {
																						min: 1,
																						max: 1000,
																						step: 0.01,
																					},
																					readOnly: !Boolean(
																						parseInt(data.is_processed)
																					),
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow
																	hidden={data.audiometry_test === null}
																>
																	<TableCell>
																		<Typography
																			variant="subtitle2"
																			className={`gtc-uppercase`}
																		>
																			RIGHT EAR
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="right_8000[]"
																				label="Result"
																				type="number"
																				required={
																					data.right_8000 === null
																						? true
																						: false
																				}
																				InputProps={{
																					inputProps: {
																						min: 1,
																						max: 1000,
																						step: 0.01,
																					},
																					readOnly: !Boolean(
																						parseInt(data.is_processed)
																					),
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="right_4000[]"
																				label="Result"
																				type="number"
																				required={
																					data.right_4000 === null
																						? true
																						: false
																				}
																				InputProps={{
																					inputProps: {
																						min: 1,
																						max: 1000,
																						step: 0.01,
																					},
																					readOnly: !Boolean(
																						parseInt(data.is_processed)
																					),
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="right_2000[]"
																				label="Result"
																				type="number"
																				required={
																					data.right_2000 === null
																						? true
																						: false
																				}
																				InputProps={{
																					inputProps: {
																						min: 1,
																						max: 1000,
																						step: 0.01,
																					},
																					readOnly: !Boolean(
																						parseInt(data.is_processed)
																					),
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="right_1000[]"
																				label="Result"
																				type="number"
																				required={
																					data.right_1000 === null
																						? true
																						: false
																				}
																				InputProps={{
																					inputProps: {
																						min: 1,
																						max: 1000,
																						step: 0.01,
																					},
																					readOnly: !Boolean(
																						parseInt(data.is_processed)
																					),
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="right_500[]"
																				label="Result"
																				type="number"
																				required={
																					data.right_500 === null ? true : false
																				}
																				InputProps={{
																					inputProps: {
																						min: 1,
																						max: 1000,
																						step: 0.01,
																					},
																					readOnly: !Boolean(
																						parseInt(data.is_processed)
																					),
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="right_250[]"
																				label="Result"
																				type="number"
																				required={
																					data.right_250 === null ? true : false
																				}
																				InputProps={{
																					inputProps: {
																						min: 1,
																						max: 1000,
																						step: 0.01,
																					},
																					readOnly: !Boolean(
																						parseInt(data.is_processed)
																					),
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>
															</TableBody>
														</Table>
													</TableContainer>
												</Box>

												<Box
													m={5}
													hidden={!Boolean(parseInt(data.is_processed))}
												>
													<Box display="flex">
														<Box flexGrow={1}>
															<Typography variant="h6">
																<b> INTERPRETATION </b>
															</Typography>
														</Box>
													</Box>
													<Box>
														<Grid container spacing={2}>
															<Grid item xs={4} sm={4}>
																<Box mt={4} align="center" display="flex">
																	<IconButton color={"primary"}>
																		<LinearScale />
																	</IconButton>
																	<Box mt={1.2}>
																		<Typography variant="subtitle1">
																			<b> LEFT EAR </b>
																		</Typography>
																	</Box>
																</Box>
															</Grid>

															<Grid item xs={8} sm={8}>
																<Box mt={2}>
																	<TextField
																		margin="dense"
																		fullWidth
																		name="left_ear_interpret[]"
																		label="Result"
																		required={
																			data.left_ear_interpret === null
																				? false
																				: true
																		}
																		InputProps={{
																			inputProps: {
																				min: 1,
																				max: 1000,
																				step: 0.01,
																			},
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																	/>
																</Box>
															</Grid>

															<Grid item xs={4} sm={4}>
																<Box mt={4} align="center" display="flex">
																	<IconButton color={"secondary"}>
																		<LinearScale />
																	</IconButton>
																	<Box mt={1.2}>
																		<Typography variant="subtitle1">
																			<b> RIGHT EAR </b>
																		</Typography>
																	</Box>
																</Box>
															</Grid>

															<Grid item xs={8} sm={8}>
																<Box mt={2}>
																	<TextField
																		margin="dense"
																		fullWidth
																		name="right_ear_interpret[]"
																		label="Result"
																		required={
																			data.right_ear_interpret === null
																				? false
																				: true
																		}
																		InputProps={{
																			inputProps: {
																				min: 1,
																				max: 1000,
																				step: 0.01,
																			},
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																	/>
																</Box>
															</Grid>
														</Grid>
													</Box>
												</Box>
											</Box>
										</Fragment>
								  ))
								: Notify.noRecord()
							: Notify.loading()}
					</CardContent>
					<Box display="flex" m={1}>
						<Box flexGrow={1} />
						<Box>
							<CardActions>
								<Button
									variant="contained"
									size="large"
									color="primary"
									type="submit"
									disabled={savebtnDisabled}
									startIcon={
										resultSubmitting && (
											<CircularProgress size={20} color="inherit" />
										)
									}
								>
									Save Result
								</Button>
							</CardActions>
						</Box>
					</Box>
				</Card>
			</form>

			<Dialog
				open={processDialog.dialog}
				onClose={() => setProcessDialog({ data: null, dialog: false })}
				disableBackdropClick
				PaperComponent={DraggableDialog}
				maxWidth={"xs"}
				fullWidth
			>
				<form onSubmit={handleProcessOrder}>
					<DialogContent dividers>
						<Box mb={2}>
							<Typography>Are you sure to process?</Typography>
						</Box>

						<Box>
							<TextField
								fullWidth
								required
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setProcessDialog({ data: null, dialog: false })}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={processSubmitting}
							startIcon={
								processSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<CheckCircleIcon />
								)
							}
						>
							process
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default AudiometryOrderDetails;
