import {
	Typography,
	Grid,
	Box,
	Paper,
	// Badge,
	List,
	TextField,
	InputAdornment,
	ListItem,
	ListItemIcon,
	Avatar,
	ListItemText,
	TablePagination,
	CardMedia,
	makeStyles,
	Divider,
	IconButton,
} from "@material-ui/core";
import React, { Fragment, useState, useEffect } from "react";
// import { useHistory } from "react-router";
import {
	// PharmacyRoleAndId,
	UsersData,
	UsersHeader,
} from "src/ContextAPI";
import Container from "src/layout/Container";
// import WifiIcon from "@material-ui/icons/Wifi";
// import WifiOffIcon from "@material-ui/icons/WifiOff";
import SearchIcon from "@material-ui/icons/Search";
// import { blue, red } from "@material-ui/core/colors";
// import Alert from "@material-ui/lab/Alert";
// import CheckInternet from "src/utils/CheckInternet";
// import CheckConnection from "src/CheckConnection";
import axios from "axios";
import Notify from "src/notification/Notify";
import OrderDetails from "./OrderDetails";
import { Search } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
	primary: {
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
}));

const ImagingPatients = () => {
	const classes = useStyles();
	const { users } = React.useContext(UsersData);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [search, setSearch] = useState("");
	const [selectedPatient, setSelectedPatient] = useState("new-patient");
	const {
		queue: { patient, getPatientsOnQueue },
	} = React.useContext(UsersHeader);
	// const userRandI = React.useContext(PharmacyRoleAndId);
	// const history = useHistory();
	// const [online, setOnline] = useState("checking");
	const [searchDisplay, setSearchDisplay] = useState(false);
	const [allLocalRad, setAllLocalRad] = React.useState({
		data: [],
		ready: false,
	});

	const [allTeleRad, setAllTeleRad] = React.useState({
		data: [],
		ready: false,
	});

	// const checkinternet = () => {
	//   CheckInternet.online()
	//     .then(() => setOnline("connected"))
	//     .catch(() => setOnline("disconnected"));
	// };

	// const getAllPatientForImaging = () => {
	//   var params = { user_id: users.user_id, management_id: users.management_id };
	//   axios
	//     .get("imaging/get/patient/xray/forimaging", { params })
	//     .then((response) => {
	//       const data = response.data;
	//       setPatientList({ data: data, ready: true });
	//     })
	//     .catch((error) => {
	//       Notify.requestError(error);
	//     });
	// };

	const getAllLocalRadiologist = () => {
		var params = { user_id: users.user_id, management_id: users.management_id };
		axios
			.get("imaging/get-all-localrad-list", { params })
			.then((response) => {
				const data = response.data;
				setAllLocalRad({ data: data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getAllTeleRadiologist = () => {
		var params = { user_id: users.user_id, management_id: users.management_id };
		axios
			.get("imaging/get-all-telerad-list", { params })
			.then((response) => {
				const data = response.data;
				setAllTeleRad({ data: data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	useEffect(() => {
		// checkinternet();
		getAllLocalRadiologist();
		getAllTeleRadiologist();
		// eslint-disable-next-line
	}, []);

	const searchable = patient.data.filter((data) => {
		return data.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
	});

	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: true,
					current: "Patients",
					items: [{ path: "/sph/app", name: "Dashboard" }],
				}}
				title={"Patient"}
			>
				<Grid container>
					<Grid item xs={12} sm={12}>
						<Grid container spacing={1}>
							<Grid item sm={4} xs={12}>
								<Grid container>
									{/* <Grid item xs={12}>
                    {online === "checking" && <CheckConnection />}
                    {online === "disconnected" && (
                      <Fragment>
                        <Box border={1} borderRadius={4} borderColor={red[100]}>
                          <Alert
                            icon={<WifiOffIcon style={{ fontSize: "2.2em" }} />}
                            severity="error"
                          >
                            <Typography variant="subtitle2">
                              Offline.
                            </Typography>
                            <Typography variant="body2">
                              The system cannot receive orders from virtual
                              doctors.
                            </Typography>
                          </Alert>
                        </Box>
                      </Fragment>
                    )}
                    {online === "connected" && (
                      <Box
                        display="flex"
                        border={1}
                        borderRadius={4}
                        borderColor={blue[100]}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push("/sph/app/imaging/virtual")
                        }
                      >
                        <Box flexGrow={1}>
                          <Alert
                            icon={<WifiIcon style={{ fontSize: "2.2em" }} />}
                            severity="info"
                          >
                            <Typography variant="subtitle1">Online</Typography>
                            <Typography variant="subtitle2">
                              The system can receive orders from virtual
                              doctors.
                            </Typography>
                          </Alert>
                        </Box>
                        <Badge
                          badgeContent={userRandI.badgeCount.length}
                          color="secondary"
                        />
                      </Box>
                    )}
                  </Grid> */}
									<Grid item xs={12}>
										<Paper component={Box}>
											<Box>
												<Box p={1} borderRadius={4}>
													<Box
														display="flex"
														justifyContent={"center"}
														alignItems={"center"}
													>
														<Box flexGrow={1}>
															<Typography variant="h5" color="textSecondary">
																<strong>New Patient</strong>
															</Typography>
														</Box>
														<Box>
															<IconButton
																color={"primary"}
																onClick={() => setSearchDisplay(!searchDisplay)}
															>
																<Search />
															</IconButton>
														</Box>
													</Box>
												</Box>
												<Divider light />
												<Box p={1}>
													{patient.ready ? (
														patient.data.length > 0 ? (
															<List component="div">
																<Box mb={1} hidden={!searchDisplay}>
																	<TextField
																		label="Search patient"
																		variant="outlined"
																		fullWidth
																		value={search}
																		onChange={(e) => setSearch(e.target.value)}
																		margin="dense"
																		InputProps={{
																			endAdornment: (
																				<InputAdornment>
																					<SearchIcon />
																				</InputAdornment>
																			),
																		}}
																	/>
																</Box>
																<>
																	{searchable.map((data, index) => {
																		return (
																			<ListItem
																				key={index}
																				button
																				className="mb-2"
																				onClick={() => {
																					setSelectedPatient(data.patients_id);
																				}}
																				selected={
																					selectedPatient === data.patients_id
																				}
																			>
																				<ListItemIcon>
																					{data.image === null ? (
																						<Avatar className={classes.primary}>
																							{data.firstname
																								.charAt()
																								.toUpperCase()}
																						</Avatar>
																					) : (
																						<Avatar
																							className={classes.primary}
																							src={`${imageLocation}patients/${data.image}`}
																							alt=""
																						/>
																					)}
																				</ListItemIcon>
																				<ListItemText
																					className={`gtc-capitalize`}
																					primary={`${data.lastname}, ${data.firstname}`}
																				/>
																			</ListItem>
																		);
																	})}
																</>
															</List>
														) : (
															<Box> {Notify.noRecord()} </Box>
														)
													) : (
														<Box> {Notify.loading()} </Box>
													)}

													<TablePagination
														component="div"
														count={patient.data.length}
														rowsPerPageOptions={[10, 50, 100]}
														page={page}
														onChangePage={handleChangePage}
														rowsPerPage={rowsPerPage}
														onChangeRowsPerPage={handleChangeRowsPerPage}
														labelRowsPerPage="List"
													/>
												</Box>
											</Box>
										</Paper>
									</Grid>
								</Grid>
							</Grid>
							<Grid item sm={8} xs={12}>
								{selectedPatient === "new-patient" && <RenderSelectPatient />}
								{selectedPatient !== "new-patient" && (
									<OrderDetails
										patient_id={selectedPatient}
										allLocalRad={allLocalRad}
										allTeleRad={allTeleRad}
										onClose={() => {
											setSelectedPatient("new-patient");
										}}
										getAllPatientForImaging={() => getPatientsOnQueue()}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Fragment>
	);
};

export default ImagingPatients;

const RenderSelectPatient = () => (
	<Box align="center">
		<CardMedia
			component="img"
			src={"/gtc-logo.png"}
			alt=""
			style={{ width: 270 }}
		/>
		<Typography color="secondary" variant="h3">
			NO PATIENT SELECTED
		</Typography>

		<Typography color="secondary" variant="h6">
			Select patient in the list to view details.
		</Typography>
	</Box>
);
