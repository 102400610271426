import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";

import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import VitalsForm from "src/clinic/components/VitalsForm";
import MagicInputFields from "src/components/forms/MagicInputFields";
import { calculateBMI, calculateBPMeasurement } from "src/helpers/utils";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";

const inputFields = [
	{
		label: "Body Temperature",
		name: "temperature",
		placeholder: "°C",
		className: "lg:col-span-4",
		type: "text",
		required: {
			value: true,
			message: "This field is required.",
		},
	},
	{
		name: "filler",
		className: "lg:col-span-8",
	},

	{
		label: "Blood Pressure (SYSTOLIC)",
		name: "blood_systolic",
		placeholder: "SYSTOLIC",
		className: "lg:col-span-4",
		type: "text",
		required: {
			value: true,
			message: "This field is required.",
		},
	},
	{
		label: "Blood Pressure (DIASTOLIC)",
		name: "blood_diastolic",
		placeholder: "DIASTOLIC",
		className: "lg:col-span-4",
		type: "text",
		required: {
			value: true,
			message: "This field is required.",
		},
	},
	{
		label: "BP Measurement",
		name: "bp_measurement",
		placeholder: "BP Measurement",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Pulse Rate",
		name: "pulse",
		placeholder: "Enter Pulse Rate",
		className: "lg:col-span-4",
		type: "text",
	},

	{
		label: "Respiratory Rate",
		name: "respiratory",
		placeholder: "Enter Respiratory Rate",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		name: "filler",
		className: "lg:col-span-4",
	},
	{
		label: "Patient height in cm",
		name: "height",
		placeholder: "Enter Patient height in CM",
		className: "lg:col-span-4",
		type: "text",
		required: {
			value: true,
			message: "This field is required.",
		},
	},
	{
		label: "Patient weight in KG",
		name: "weight",
		placeholder: "Enter Patient weight in KG",
		className: "lg:col-span-4",
		type: "text",
		required: {
			value: true,
			message: "This field is required.",
		},
	},
	{
		label: "BMI",
		name: "bmi",
		placeholder: "Enter BMI",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Height for Age",
		name: "height_for_age",
		placeholder: "Enter Height for Age",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Weight for Age",
		name: "weight_for_age",
		placeholder: "Enter Weight for Age",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		name: "filler",
		className: "lg:col-span-4",
	},
	{
		label: "Blood Type",
		name: "blood_type",
		placeholder: "Enter Blood Type",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Oxygen saturation",
		name: "oxygen_saturation",
		placeholder: "Enter Oxygen saturation",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Heart Rate",
		name: "heart_rate",
		placeholder: "Enter Heart Rate",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Regular Rhythm",
		name: "regular_rhythm",
		placeholder: "Enter Regular Rhythm",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Covid 19",
		name: "covid_19",
		placeholder: "Enter Covid 19",
		className: "lg:col-span-4",
		type: "select",
		options: [
			{
				label: "Positive",
				value: "positive",
			},
			{
				label: "Negative",
				value: "negative",
			},
		],
	},
	{
		label: "TB",
		name: "tb",
		placeholder: "Enter TB",
		className: "lg:col-span-4",
		type: "select",
		options: [
			{
				label: "Positive",
				value: "positive",
			},
			{
				label: "Negative",
				value: "negative",
			},
		],
	},
	{
		label: "Glucose",
		name: "glucose",
		placeholder: "Enter Glucose",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Cholesterol",
		name: "cholesterol",
		placeholder: "Enter Cholesterol",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Uric Acid",
		name: "uric_acid",
		placeholder: "Enter Uric Acid",
		className: "lg:col-span-4",
		type: "text",
	},
];
const AppointmentCreateVitalsModal = (props, ref) => {
	const { onSuccess, patient } = props;
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		watch,
		control,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(null);
	const [callBack, setCallBack] = useState(null);
	const [loading, setLoading] = useState(false);
	const [prescriptionItems, setPrescriptionItems] = useState([]);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data, callback = null) => {
		setAppointment(data);
		if (callback?.fn) {
			setCallBack({
				fn: callback?.fn,
			});
		}
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};

	const submit = (data) => {
		if (appointment == null) {
			callBack?.fn(data);
			hide();
			return;
		}
		console.log("dataaaa", data);
		// return;
		setLoading(true);
		console.log("prescriptionItems", prescriptionItems);
		let formData = new FormData();
		formData.append("appointment_id", appointment?.id);
		// formData.append("_method", "PATCH");
		formData.append("patient_id", patient?.id);
		formData.append("temperature", data?.temperature);
		formData.append("pulse", data?.pulse);
		formData.append("blood_systolic", data?.blood_systolic);
		formData.append("blood_diastolic", data?.blood_diastolic);
		formData.append("respiratory", data?.respiratory);
		formData.append("height", data?.height);
		formData.append("weight", data?.weight);
		formData.append("glucose", data?.glucose);
		formData.append("uric_acid", data?.uric_acid);
		formData.append("cholesterol", data?.cholesterol);
		formData.append("bmi", data?.bmi);
		formData.append("height_for_age", data?.height_for_age);
		formData.append("weight_for_age", data?.weight_for_age);
		formData.append("bloody_type", data?.bloody_type);
		formData.append("oxygen_saturation", data?.oxygen_saturation);
		formData.append("heart_rate", data?.heart_rate);
		formData.append("regular_rhythm", data?.regular_rhythm);
		formData.append("covid_19", data?.covid_19);
		formData.append("tb", data?.tb);
		axios
			.post(`/v1/patient-vitals/store`, formData)
			.then((res) => {
				// addToList(data);
				setTimeout(() => {
					setLoading(false);
					onSuccess();
					toast.success("Prescription added successfully!");
				}, 400);
				hide();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Modal open={open} size="lg" hide={hide} className=" w-full">
			<ModalHeader
				title={`Update vitals`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				{/* {JSON.stringify(errors)} */}
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
					{inputFields?.map((data) => {
						if (data?.name == "bmi") {
							// calculateBMI
							let bmi_ =
								watch("height")?.length &&
								watch("weight")?.length
									? calculateBMI(
											watch("height"),
											watch("weight")
									  )
									: {};
							console.log("bmi_bmi_bmi_", bmi_);
							return (
								<TextInputField
									type={"text"}
									inputClassName={`${bmi_?.bmi_color}`}
									className={`${data?.className} lg:!w-full ${bmi_?.bmi_color}`}
									label={<>BMI</>}
									value={`${parseFloat(
										bmi_?.bmi || 0
									).toFixed(2)} - ${bmi_?.status || ""}`}
									placeholder={data?.placeholder}
									error={errors[data?.name]?.message}
									helperText={""}
									{...register("bmi", {
										// required: true,
									})}
								/>
							);
						}
						//

						if (data?.name == "bp_measurement") {
							// calculateBMI
							let bp_measurement =
								watch("blood_systolic")?.length &&
								watch("blood_diastolic")?.length
									? calculateBPMeasurement(
											watch("blood_systolic"),
											watch("blood_diastolic")
									  )
									: {};
							console.log("bp_measurement", bp_measurement);
							return (
								<TextInputField
									type={"text"}
									inputClassName={`${bp_measurement?.color}`}
									className={`${data?.className} lg:!w-full ${bp_measurement?.color}`}
									label={<>BP Measurement</>}
									value={`${bp_measurement?.result}`}
									placeholder={data?.placeholder}
									error={errors[data?.name]?.message}
									helperText={""}
									{...register("bp_measurement", {
										// required: true,
									})}
								/>
							);
						}
						if (data?.name == "filler") {
							return <div className={data?.className}></div>;
						}
						if (data?.type == "select") {
							return (
								<div className={data?.className}>
									<Controller
										name={data?.name}
										control={control}
										rules={{
											required: data?.required
												? data?.required
												: false,
										}}
										onChange={(data) => {}}
										render={({
											field: {
												onChange,
												onBlur,
												value,
												name,
												ref,
											},
											fieldState: {
												invalid,
												isTouched,
												isDirty,
												error,
											},
										}) => (
											<ReactSelectInputField
												isClearable={false}
												label={<>{data?.label}</>}
												inputClassName=" "
												ref={ref}
												value={value}
												onChange={(val) => {
													onChange(val);
												}} // send value to hook form
												onBlur={onBlur} // notify when input is touched
												error={error?.message}
												placeholder={data?.label}
												options={data?.options?.map(
													(option) => ({
														label: option?.label,
														value: option?.value,
													})
												)}
											/>
										)}
									/>
								</div>
							);
						}
						return (
							<TextInputField
								type={data?.type}
								className={`${data?.className} lg:!w-full`}
								label={
									<>
										{data?.label}:{""}
									</>
								}
								placeholder={data?.placeholder}
								options={data?.options}
								error={errors[data?.name]?.message}
								helperText={""}
								{...register(data?.name, {
									required: data?.required
										? data?.required
										: false,
								})}
							/>
						);
					})}
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center"}>
				<ActionBtn
					className="ml-auto"
					onClick={handleSubmit(submit)}
					loading={loading}
				>
					Update
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(AppointmentCreateVitalsModal);
