import {
  Box,
  Collapse,
  Paper,
  Typography,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "src/notification/Notify";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue } from "@material-ui/core/colors";
import DocuPatientDetailsECGDetails from "./DocuPatientDetailsECGDetails";

const DocuPatientDetailsECG = ({
  patient_id,
  selectedBranch,
  updatePatientList,
}) => {
  const [newECGOrder, setNewECGOrder] = useState([]);
  const [newECGOrderRd, setNewECGOrderRd] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const getECGOrderToProcess = () => {
    axios
      .get("documentation/van/patient/other/get-neworderECG", {
        params: {
          patient_id,
        },
      })
      .then((response) => {
        const data = response.data;
        setNewECGOrder(data);
      })
      .catch((error) => console.log("error:", error.message))
      .finally(() => {
        setNewECGOrderRd(true);
      });
  };

  useEffect(() => {
    getECGOrderToProcess();
    // eslint-disable-next-line
  }, [patient_id]);

  return (
    <>
      <Box my={1}>
        <Typography color="textSecondary" variant="h6">
          ECG
        </Typography>
      </Box>
      {newECGOrderRd
        ? newECGOrder.length > 0
          ? newECGOrder.map((data, index) => {
              return (
                <Fragment key={index}>
                  <Paper component={Box} my={2} p={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Box display="flex">
                          <Box mt={1} mr={2}>
                            <ListAltIcon
                              style={{ fontSize: "2rem", color: blue[500] }}
                            />
                          </Box>

                          <Box>
                            <Box>
                              <Typography
                                variant="subtitle1"
                                className={`gtc-uppercase`}
                              >
                                ECG
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                className={`gtc-uppercase`}
                              >
                                {Notify.dateTimeConvert(data.created_at)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={() =>
                            setSelectedOrder(
                              selectedOrder &&
                                selectedOrder.trace_number === data.trace_number
                                ? null
                                : data
                            )
                          }
                        >
                          {selectedOrder &&
                          selectedOrder.order_id === data.order_id ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                    <>
                      {/* order result form */}
                      {selectedOrder && (
                        <Collapse in={selectedOrder.order_id === data.order_id}>
                          <Box mt={2}>
                            <DocuPatientDetailsECGDetails
                              selectedBranch={selectedBranch}
                              patient_id={patient_id}
                              details={selectedOrder}
                              getECGTest={() => {
                                getECGOrderToProcess();
                                setSelectedOrder(null);
                              }}
                              updatePatientList={() => updatePatientList()}
                            />
                          </Box>
                        </Collapse>
                      )}
                    </>
                  </Paper>
                </Fragment>
              );
            })
          : Notify.noRecord()
        : Notify.loading()}
    </>
  );
};

export default DocuPatientDetailsECG;
