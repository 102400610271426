import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";

const PayrollHeaders = () => {
  const { users } = useContext(UsersData);
  const [header, setHeader] = useState({ data: [], ready: false });
  const mounted = IsMountedRef();

  const getPayrollHeader = () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    axios
      .get("hr/payroll/header-list", { params })
      .then((res) => {
        const data = res.data;
        if (mounted.current) {
          setHeader({ data, ready: true });
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const handleNewPayrollHeader = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("username", users.username);
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);

    let response = await axios.post("hr/payroll/new-header", formdata);
    if (response.data === "success") {
      getPayrollHeader();
      e.target.reset();
      Notify.successRequest("new header");
    }
  };

  useEffect(() => {
    getPayrollHeader();

    // eslint-disable-next-line
  }, []);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Payroll Header",
        items: [{ name: "Dashboard", path: "/sph/app" }],
      }}
      title={`Payroll Header`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Card>
            <CardHeader title="List of Headers" />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"> Header </TableCell>
                      <TableCell align="center"> Category </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    {header.ready ? (
                      header.data.length > 0 ? (
                        header.data.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell> {data.header} </TableCell>
                            <TableCell> {data.category} </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={2}>
                            <Typography color="secondary">
                              No record found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={2}>
                          <Typography color="primary">
                            Loading, Please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card>
            <form onSubmit={handleNewPayrollHeader}>
              <CardHeader title="Create Header" />
              <CardContent>
                <Box mb={2}>
                  <TextField
                    required
                    name="header"
                    label="Header"
                    variant="outlined"
                    fullWidth
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    fullWidth
                    required
                    name="category"
                    label="Header Category"
                    variant="outlined"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value={""}>Select</option>
                    <option value={"Earning"}>Earning Bracket</option>
                    <option value={"Deduction"}>Deduction Bracket</option>
                  </TextField>
                </Box>

                <Box display="flex">
                  <Box flexGrow={1} />
                  <Box mr={1}>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box>
                    <Button size="large" variant="contained" color="default">
                      No
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PayrollHeaders;
