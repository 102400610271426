import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Grid,
  TextField,
  FormHelperText,
  Button,
  Divider,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const EditEmployeeDoctorsInfo = ({
  close,
  selectedEmployee,
  selectedDepartmentType,
  getAllUsersAccount,
}) => {
  const { users } = useContext(UsersData);
  const [info, setInfo] = useState({ data: [], ready: false });

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const getUsersInfo = useCallback(async () => {
    var params = {
      user_id: selectedEmployee,
      type: selectedDepartmentType,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    try {
      let response = await axios.get("hr/get/for-edit/doctor/specific-info", {
        params,
      });
      const data = response.data;
      setInfo({ data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, selectedDepartmentType, selectedEmployee]);

  useEffect(() => {
    getUsersInfo();
  }, [selectedEmployee, selectedDepartmentType, getUsersInfo]);
  return (
    <>
      {info.ready ? (
        info.data ? (
          <Formik
            enableReinitialize
            initialValues={{
              username: users.username,
              user_id: users.user_id,
              password: "",
              users_user_id: selectedEmployee,
              users_user_type: selectedDepartmentType,
              date_birth: info.data.birthday !== null ? info.data.birthday : "",
              contact:
                info.data.contact_no !== null ? info.data.contact_no : "",
              email: info.data.email !== null ? info.data.email : "",
              fullname: info.data.name !== null ? info.data.name : "",
              address: info.data.address !== null ? info.data.address : "",
              gender: info.data.gender !== null ? info.data.gender : "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().trim().required("Password is required."),
              date_birth: Yup.date().required("Birth date is required."),
              contact: Yup.string().trim().required("Contact # is required"),
              fullname: Yup.string().trim().required("Fullname is required"),
              address: Yup.string().trim().required("Address is required"),
              gender: Yup.string().trim().required("Gender is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setSubmitting, resetForm }
            ) => {
              try {
                const request = await axios.post(
                  "hr/users/doctors/edit-users",
                  getFormData(values)
                );
                if (request.data === "success") {
                  getAllUsersAccount();
                  Notify.successRequest("update user's info");
                  resetForm();
                  close();
                }
                if (request.data === "pass-invalid") {
                  Notify.fieldInvalid("password");
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box mb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(
                            touched.date_birth && errors.date_birth
                          )}
                          helperText={touched.date_birth && errors.date_birth}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.date_birth}
                          fullWidth
                          required
                          name="date_birth"
                          label="Birth Date"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.contact && errors.contact)}
                          helperText={touched.contact && errors.contact}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.contact}
                          fullWidth
                          required
                          name="contact"
                          label="Contact #"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          fullWidth
                          required
                          name="email"
                          label="Email"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.fullname && errors.fullname)}
                          helperText={touched.fullname && errors.fullname}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.fullname}
                          fullWidth
                          required
                          name="fullname"
                          label="Fullname"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address}
                          fullWidth
                          required
                          name="address"
                          label="Address"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.gender && errors.gender)}
                          helperText={touched.gender && errors.gender}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gender}
                          fullWidth
                          required
                          name="gender"
                          label="Gender"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </TextField>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          name="password"
                          label="Enter your password"
                          variant="outlined"
                          type="password"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}

                      <Divider />

                      <Box my={2} display="flex">
                        <Box flexGrow={1} />

                        <Button
                          variant="contained"
                          color="default"
                          startIcon={<ClearIcon />}
                          onClick={() => close()}
                        >
                          Close
                        </Button>

                        <Box ml={2}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={<CheckIcon />}
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          Notify.noRecord()
        )
      ) : (
        Notify.loading()
      )}
    </>
  );
};

export default EditEmployeeDoctorsInfo;
