import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
import PickMapLocation from "src/pho/components/Forms/PickMapLocation";
import { v4 as uuidv4 } from "uuid";
import Img from "src/components/Img";
import ImagePicker from "src/components/forms/ImagePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { dataURItoBlob, getErrors } from "src/helpers/utils";
import TextInputField from "src/components/forms/TextInputField";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";
import { geolocations, locations } from "src/helpers/locations";
const ClinicPersonnelFormModal = (props, ref) => {
	const [mapKey, setMapKey] = useState(`map-${uuidv4()}`);
	const { staticModal, updateInList, addToList } = props;
	const [open, setOpen] = useState(false);
	const [data, setData] = useState({
		id: 1,
	});
	const [avatar, setAvatar] = useState("");
	const [loading, setLoading] = useState(false);

	const [provinceList, setProvinceList] = useState([]);
	const [municipalityList, setMunicipalityList] = useState([]);
	const [municipalities, setMunicipalities] = useState([]);
	const [brgys, setBrgys] = useState([]);
	const [purokList, setPurokList] = useState([]);

	const [pointVals, setPointVals] = useState([[6.0498006, 125.15]]);
	const [position, setPosition] = useState({
		lat: 6.0498006,
		lng: 125.15,
	});
	const btnRef = useRef(null);

	useEffect(() => {
		getLocations().then((res) => {
			setMunicipalities(res.data);
		});
	}, []);

	useEffect(() => {
		console.log("municipality_id", data?.municipality_id);
		let t = setTimeout(() => {
			setValue("municipality", data?.municipality_id);
			if (data?.municipality_id) {
				let found = municipalities.find(
					(x) => x.id == data?.municipality_id
				);
				if (found?.barangays?.length > 0) {
					setBrgys(found?.barangays);
				}
			}
		}, 200);
		return () => {
			clearTimeout(t);
		};
	}, [municipalities, data?.municipality?.id]);

	useEffect(() => {
		let t = setTimeout(() => {
			setValue("barangay", data?.barangay_id);
		}, 200);
		return () => {
			clearTimeout(t);
		};
	}, [brgys, data?.barangay?.id]);

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		control,
		setError,
		formState: { errors },
	} = useForm();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const getLocations = () => {
		return axios.get("/v1/locations");
	};
	const show = (showData) => {
		console.log("showDatashowData", showData);
		if (showData?.id) {
			setData(showData);
			setValue("avatar", showData?.avatar);
			setAvatar(showData?.avatar);
			setValue("username", showData?.username);
			setValue("email", showData?.email);
			setValue("type", showData?.type);
			setValue("name", showData?.name);
			setValue("title", showData?.title);
			setValue("gender", showData?.personnel?.gender);
			setValue("birthdate", showData?.personnel?.birthdate);
			setValue("contact_number", showData?.personnel?.contact_number);
			setTimeout(async () => {
				setValue("region", showData?.region, {
					shouldDirty: true,
				});
			}, 300);
			setTimeout(async () => {
				setValue("province", showData?.street, {
					shouldDirty: true,
				});
			}, 600);
			setTimeout(async () => {
				setValue("barangay", showData?.barangay, {
					shouldDirty: true,
				});
			}, 800);
			setTimeout(async () => {
				setValue("municipality", showData?.municipality, {
					shouldDirty: true,
				});
			}, 1200);
			setTimeout(async () => {
				setValue("purok", showData?.purok);
			}, 1400);
			setTimeout(async () => {
				setValue("street", showData?.street);
			}, 1600);
		} else {
			setData(null);
			reset({
				name: "",
				color: "",
				type: "",
				radius: "",
			});
		}
		setTimeout(() => {
			setMapKey(`map-${uuidv4()}`);
		}, 500);
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = (form) => {
		setLoading(true);
		let formData = new FormData();
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
			// onUploadProgress: progressEvent => onProgress(progressEvent),
		};

		const file = dataURItoBlob(avatar);
		formData.append("avatar", file);

		formData.append("username", form?.username);
		formData.append("email", form?.email);
		formData.append("password", form?.password);
		formData.append("region", form?.region);
		formData.append("province", form?.province);
		formData.append("municipality", form?.municipality);
		formData.append("barangay", form?.barangay);
		formData.append("purok", form?.purok);
		formData.append("street", form?.street);
		formData.append("type", form?.type);
		formData.append("name", form?.name);
		formData.append("gender", form?.gender);
		formData.append("birthdate", form?.birthdate);
		formData.append("contact_number", form?.contact_number);
		formData.append("title", form?.title);
		formData.append("password_confirmation", form?.password_confirmation);

		let url = `/v1/management/personnel`;

		if (data?.id) {
			formData.append("_method", "PATCH");
			url = url + `/${data?.id}`;
		}

		axios
			.post(url, formData, config)
			.then((res) => {
				setTimeout(() => {
					toast.success("Clinic record updated successfully!");
					if (data?.id) {
						updateInList(res.data.data);
					} else {
						addToList(res.data.data);
					}
				}, 200);

				hide();
			})
			.catch((err) => {
				console.log("errerr", err?.response?.data?.errors);
				if (err?.response?.data?.errors)
					getErrors(err?.response?.data?.errors, setError);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	// useEffect(() => {
	// 	let timeout = setTimeout(() => {
	// 		if (watch("barangay")) {
	// 			getPurokList(watch("barangay"));
	// 		}
	// 	}, 400);
	// 	return () => {
	// 		clearTimeout(timeout);
	// 	};
	// }, [watch("barangay")]);
	// useEffect(() => {
	// 	setValue("purok", data?.purok_id);
	// }, [purokList]);
	// const getPurokList = (brgy_id) => {
	// 	axios.get(`v1/purok-by-barangay?barangay_id=${brgy_id}`).then((res) => {
	// 		setPurokList(res.data);
	// 	});
	// };
	return (
		<Modal
			open={open}
			hide={hide}
			className={`w-[768px] min-w-[768px] max-w-[768px]`}
			staticModal={staticModal}
		>
			<ModalHeader
				title={(data?.id ? "Edit " : "Add ") + `Clinic Personnel Form`}
				hide={hide}
			/>
			<ModalBody className={`p- bg-white !pb-6`}>
				{console.log("errorserrors", errors)}
				<div className="p-4 flex flex-col gap-y-5">
					<div className="group relative h-36 w-36 min-h-[144px] min-w-[144px] rounded-full aspect-square bg-background mx-auto">
						<Img
							type="user"
							name={watch("name") || "CP"}
							src={avatar}
							className="min-h-[144px] min-w-[144px] aspect-square object-cover rounded-full"
							alt=""
							id="user-image-sample1"
							key={`key-avataru1`}
						/>

						<ImagePicker
							className="absolute -bottom-0 -right-0 w-11 h-11 bg-primary bg-opacity-40 group-hover:bg-primary group-hover:bg-opacity-100 duration-200 rounded-full flex items-center justify-center"
							onChange={(data) => {
								setAvatar(data);
								/* setPatient((prevData) => ({
											...prevData,
											avatar: data,
										}));
										if (isBase64(data)) {
											saveAvatar(data);
										} */
							}}
						>
							{() => {
								return (
									<FontAwesomeIcon
										title="Click to upload camera"
										icon={faCamera}
										color="white"
										size={"2x"}
									/>
								);
							}}
						</ImagePicker>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-5 w-full">
						<TextField
							label={<>Name</>}
							className="lg:col-span-2"
							variant="outlined"
							{...register("name", {
								required: "This field is required",
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.name}
							helperText={errors?.name?.message}
						/>
						<TextField
							label={<>Username</>}
							variant="outlined"
							{...register("username", {
								required: false,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.username}
							helperText={
								errors?.username && "This field is required"
							}
						/>
						<TextField
							label={<>Email</>}
							type="email"
							variant="outlined"
							{...register("email", {
								required: false,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.email}
							helperText={
								errors?.email?.message ||
								"This field is required"
							}
						/>
						{data?.id ? (
							""
						) : (
							<>
								<TextField
									label={
										<>
											Password
											<b className="text-red-500 ml-1">
												*
											</b>
										</>
									}
									type="password"
									variant="outlined"
									{...register("password", {
										required: "This field is required.",
										validate: (val) => {
											if (watch("password") != val) {
												return "Passwords does not match";
											}
										},
									})}
									SelectProps={{ native: true }}
									InputLabelProps={{ shrink: true }}
									error={errors?.password}
									helperText={
										errors?.password &&
										"This field is required"
									}
								/>
								<TextField
									label={
										<>
											Confirm Password
											<b className="text-red-500 ml-1">
												*
											</b>
										</>
									}
									type="password"
									variant="outlined"
									{...register("password_confirmation", {
										required: "This field is required.",
										validate: (val) => {
											if (watch("password") != val) {
												return "Passwords does not match";
											}
										},
									})}
									SelectProps={{ native: true }}
									InputLabelProps={{ shrink: true }}
									error={errors?.password_confirmation}
									helperText={
										errors?.password_confirmation &&
										"This field is required"
									}
								/>
							</>
						)}
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-5 w-full">
						<TextField
							label={<>Birthdate</>}
							type="date"
							variant="outlined"
							{...register("birthdate", {
								required: "This field is required",
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.birthdate?.message}
							helperText={errors?.birthdate?.message}
						/>
						<TextField
							label={<>Personnel Type</>}
							variant="outlined"
							{...register("type", {
								required: false,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.type}
							helperText={
								errors?.type && "This field is required"
							}
							select
						>
							<option value={""}>SELECT</option>
							<option value="CIS-Doctor">Clinic-Doctor</option>
							<option value="CIS-Registration">
								Clinic-Registration
							</option>
							<option value="CIS-Laboratory">
								Clinic-Laboratory
							</option>
							<option value="CIS-Nurse">Clinic-Nurse</option>
							{/* <option value="CIS-Med-tech">
								Clinic-Med-tech
							</option>
							<option value={"CIS-Voluntee"}>
								Clinic-Voluntee
							</option> */}
						</TextField>
						<TextField
							label={<>Title</>}
							type="text"
							variant="outlined"
							{...register("title", {
								required: false,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.title?.message}
							helperText={errors?.title?.message}
						/>
						<TextField
							label={<>Contact No.</>}
							variant="outlined"
							{...register("contact_number", {
								required: false,
							})}
							placeholder="Enter contact number information "
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.contact_number}
							helperText={
								errors?.contact_number &&
								"This field is required"
							}
						/>
						<TextField
							label={<>Messenger</>}
							variant="outlined"
							{...register("messenger", {
								required: false,
							})}
							placeholder="Enter messenger contact information "
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.messenger}
							helperText={
								errors?.messenger && "This field is required"
							}
						/>
						<TextField
							label={<>Viber</>}
							placeholder="Enter viber contact information "
							variant="outlined"
							{...register("viber", {
								required: false,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.viber}
							helperText={
								errors?.viber && "This field is required"
							}
						/>
						<TextField
							label={<>Gender</>}
							variant="outlined"
							{...register("gender", {
								required: "This field is required",
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.gender}
							helperText={errors?.gender?.message}
							select
						>
							<option value={""}>SELECT</option>
							<option value={"Male"}>Male</option>
							<option value={"Female"}>Female</option>
						</TextField>
					</div>
				</div>
				<div className="flex flex-col gap-y-8 patient-form px-6">
					{/* <LocationPicker
				pointMode={pointMode}
				zoom={10}
				startPort={"auto"}
				showControls={true}
				precision={7}
			/>
			 */}
					<div className="grid lg:grid-cols-12 grid-cols-1 gap-6">
						<div className="lg:col-span-4">
							<Controller
								name="region"
								control={control}
								rules={{
									required: {
										value: true,
										message: "This field is required",
									},
								}}
								render={({
									field: {
										onChange,
										onBlur,
										value,
										name,
										ref,
									},
									fieldState: {
										invalid,
										isTouched,
										isDirty,
										error,
									},
								}) => (
									<ReactSelectInputField
										isClearable={false}
										label={
											<>
												Select Region
												<span className="text-danger ml-1">
													*
												</span>
											</>
										}
										inputClassName=" "
										ref={ref}
										value={value}
										onChange={onChange}
										onChangeGetData={(data) => {
											setProvinceList(
												Object.keys(
													data.province_list
												).map((key) => {
													return {
														name: key,
														...data.province_list[
															key
														],
													};
												})
											);
										}} // send value to hook form
										onBlur={onBlur} // notify when input is touched
										error={error?.message}
										placeholder="Select Province"
										options={Object.values(
											geolocations
										).map((loc) => ({
											value: loc?.region_name,
											label: loc?.region_name,
											province_list: loc?.province_list,
										}))}
									/>
								)}
							/>
						</div>
						<div className="lg:col-span-4">
							<Controller
								name="province"
								control={control}
								rules={{
									required: {
										value: true,
										message: "This field is required",
									},
								}}
								render={({
									field: {
										onChange,
										onBlur,
										value,
										name,
										ref,
									},
									fieldState: {
										invalid,
										isTouched,
										isDirty,
										error,
									},
								}) => (
									<ReactSelectInputField
										isClearable={false}
										label={
											<>
												Select Province
												<span className="text-danger ml-1">
													*
												</span>
											</>
										}
										inputClassName=" "
										ref={ref}
										value={value}
										onChange={onChange} // send value to hook form
										onChangeGetData={(data) => {
											setMunicipalityList(
												Object.keys(
													data.municipality_list
												).map((key) => {
													return {
														name: key,
														...data
															.municipality_list[
															key
														],
													};
												})
											);
										}}
										onBlur={onBlur} // notify when input is touched
										error={error?.message}
										placeholder="Select Province"
										options={provinceList.map(
											(province) => ({
												label: province?.name,
												value: province?.name,
												municipality_list:
													province?.municipality_list,
											})
										)}
									/>
								)}
							/>
						</div>
						<div className="lg:col-span-4">
							<Controller
								name="municipality"
								control={control}
								rules={{
									required: {
										value: true,
										message: "This field is required",
									},
								}}
								render={({
									field: {
										onChange,
										onBlur,
										value,
										name,
										ref,
									},
									fieldState: {
										invalid,
										isTouched,
										isDirty,
										error,
									},
								}) => (
									<ReactSelectInputField
										isClearable={false}
										label={
											<>
												Select Municipality
												<span className="text-danger ml-1">
													*
												</span>
											</>
										}
										placeholder="Select Municipality"
										inputClassName="normal-case"
										ref={ref}
										value={value}
										onChange={(data) => {
											let selected_ = locations?.find(
												(x) =>
													String(
														x.name
													).toLowerCase() ==
													String(data).toLowerCase()
											);
											console.log(
												"selected_selected_",
												String(data).toLowerCase(),
												selected_
											);
											// setBrgys(
											// 	selected_?.barangays
											// );
											setValue(
												"zip_code",
												selected_?.zipcode || ""
											);
											onChange(data);
										}} // send value to hook form
										onChangeGetData={(data) => {
											setBrgys(
												data.barangay_list.map(
													(key) => {
														return {
															name: key,
														};
													}
												)
											);
										}}
										onBlur={onBlur} // notify when input is touched
										error={error?.message}
										options={municipalityList.map(
											(municipality) => ({
												label: municipality?.name,
												value: municipality?.name,
												barangay_list:
													municipality?.barangay_list,
											})
										)}
									/>
								)}
							/>
						</div>

						<div className="lg:col-span-4">
							<Controller
								name="barangay"
								control={control}
								rules={{
									required: {
										value: true,
										message: "This field is required",
									},
								}}
								onChange={(data) => {}}
								render={({
									field: {
										onChange,
										onBlur,
										value,
										name,
										ref,
									},
									fieldState: {
										invalid,
										isTouched,
										isDirty,
										error,
									},
								}) => (
									<ReactSelectInputField
										isClearable={false}
										label={
											<>
												Select Barangay
												<span className="text-danger ml-1">
													*
												</span>
											</>
										}
										inputClassName=" "
										ref={ref}
										value={value}
										onChange={(data) => {
											let selected_ = brgys?.find(
												(x) => x.name == data
											);
											setPurokList(selected_?.puroks);
											onChange(data);
										}} // send value to hook form
										onBlur={onBlur} // notify when input is touched
										error={error?.message}
										placeholder="Select Barangay"
										options={brgys.map((data) => ({
											label: data?.name,
											value: data?.name,
										}))}
									/>
								)}
							/>
						</div>
						<TextInputField
							className="lg:col-span-4"
							label={<>Purok</>}
							placeholder="Input purok"
							{...register("purok", {
								required: {
									value: false,
								},
							})}
							error={errors?.purok?.message}
						/>
						<TextInputField
							className="lg:col-span-4"
							label={<>Street</>}
							placeholder="Input street"
							{...register("street", {
								required: {
									value: false,
									// message: "This field is required",
								},
							})}
							error={errors?.street?.message}
						/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button
					onClick={handleSubmit(submit)}
					loading={loading}
					ref={btnRef}
				>
					Submit
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(ClinicPersonnelFormModal);
