import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import PatientProfileOverview from "src/cis-doctor/components/PatientProfileOverview";
import CisPatientListItem from "src/cis/components/CisPatientListItem";
import PatientProfilePage from "src/cis/components/PatientProfilePage";
import ButtonV3 from "src/components/ButtonV3";
import FlatIcon from "src/components/FlatIcon";
import LayoutContainer from "src/components/LayoutContainer";
import { v4 as uuidv4 } from "uuid";
import useDataTable from "src/helpers/useDataTable";
import BhsPatientProfile from "./BhsPatientProfile";
import HealthUnitDetails from "./components/HealthUnitDetails";
import axios from "axios";
import Pagination from "src/components/table/Pagination";
import PrivacyStatementModal from "./components/PrivacyStatementModal";
import CreateNewPatientFormModal from "./components/CreateNewPatientFormModal";

const modalKey = uuidv4();

const BhsPatientViewProfile = () => {
	const history = useHistory();
	const location = useLocation();
	const viewPrivacyRef = useRef(null);
	const newPatientFormRef = useRef(null);

	const { id } = useParams();
	const [modalKeys, setModalKeys] = useState(`${modalKey}`);
	const [patient, setPatient] = useState(null);
	const [patientSelfie, setPatientSelfie] = useState(null);
	const {
		data,
		page,
		setPage,
		meta,
		loading,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
		keyword,
		setKeyword,
		paginate,
		setPaginate,
	} = useDataTable(`v1/patients`, null, {});

	const [list, setList] = useState([]);
	const [selected, setSelected] = useState(null);
	const [selectedClick, setSelectedClick] = useState(null);
	const [pendings, setPendings] = useState(null);

	const getPendingForMedsRelease = () => {
		axios.get(`/v1/clinic/bhw-pending-for-medicine-release`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	useEffect(() => {
		// let t = setInterval(() => {
		// 	getPendingForMedsRelease();
		// }, 5000);
		// return () => {
		// 	clearInterval(t);
		// };
	}, []);

	const getPatient = () => {
		axios.get(`/v1/patients/${id}`).then((res) => {
			setPatient(res.data.data);
		});
	};
	useEffect(() => {
		let t = setTimeout(() => {
			console.log("historyhistory", location);
			if (id) {
				getPatient(id);
			}
		}, 300);
		return () => {
			clearTimeout(t);
		};
	}, [id]);
	useEffect(() => {
		getPendingForMedsRelease();
		// setFilters((prevFilters) => ({
		// 	...prevFilters,
		// 	// clinic_id: 1,
		// }));
		// setPaginate(10);
	}, []);

	const updatePatient = (patient) => {
		console.log("updatePatientupdatePatient,", patient);
		setSelectedClick(patient);
	};

	return (
		<LayoutContainer
			icon={<FontAwesomeIcon icon={faHome} color="white" size={"2x"} />}
			title={
				<Typography variant={window.innerWidth > 500 ? "h4" : "h5"}>
					Patients Profile
				</Typography>
			}
			subtitle={`View patient profile`}
			titleChildren={<HealthUnitDetails />}
			className={`relative`}
		>
			<div className="flex flex-col gap-2 h-[calc(100vh-204px)]">
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
					<div className="lg:col-span-12">
						{patient ? (
							<BhsPatientProfile
								key={`bhs-${patient?.id}`}
								patient={patient}
								openAppointmentForm={location?.search?.includes(
									"new_appointment=true"
								)}
								skipPrivacyStatement={true}
								setPatient={updatePatient}
							/>
						) : patient == null ? (
							<div className="p-5 flex items-center justify-center text-placeholder-dark text-lg">
								{loading ? "Loading, please wait..." : ``}
							</div>
						) : (
							<PatientProfileOverview patient={selected} />
						)}
					</div>
				</div>
			</div>{" "}
			<CreateNewPatientFormModal
				patientSelfie={patientSelfie}
				key={`CreateNewPatientFormModal-${modalKeys}`}
				ref={newPatientFormRef}
			/>
			<PrivacyStatementModal
				newPatient={true}
				key={`PrivacyStatementModal-${modalKeys}`}
				onSuccess={(data) => {
					newPatientFormRef.current.show(null);
				}}
				patient={patient}
				ref={viewPrivacyRef}
				patientSelfie={patientSelfie}
				setPatientSelfie={setPatientSelfie}
			/>
		</LayoutContainer>
	);
};

export default BhsPatientViewProfile;
