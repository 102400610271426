import { Box, Button, Card, TextField } from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "../styles/styles";
import axios from "axios";
import Notify from "src/notification/Notify";
import ButtonV3 from "src/components/ButtonV3";
import ReactQuillField from "src/components/forms/ReactQuillField";

export default function CisNewTreatmentPlan({ setShown, patientId }) {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		console.log(data);
		let formData = new FormData();
		formData.append("patient_id", patientId);
		formData.append("schedule", data?.schedule || "");
		formData.append("description", data?.treatment || "");

		axios
			.post(`v1/patient/treatment-plan/store`, formData)
			.then((response) => {
				Notify.successRequest("Added");
				setShown(true);
			})
			.catch((err) => console.log(err));
	};

	return (
		<div className="flex w-full flex-col">
			<form>
				<div>
					<div className="mx-auto" style={styles.parentContainerAdd}>
						<Card elevation={3} className="p-4" component={Box}>
							<div className="flex flex-col gap-y-5">
								<div>
									<TextField
										label={
											<label style={styles.label}>
												Schedule
												<span
													style={styles.astirisColor}
												>
													*
												</span>
											</label>
										}
										variant="outlined"
										fullWidth
										type="date"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("schedule", {
											required: true,
										})}
										error={errors.schedule}
										helperText={
											errors.schedule &&
											"Schedule is required"
										}
									/>
								</div>

								<Controller
									name="treatment"
									control={control}
									rules={{
										required: {
											value: true,
											message: "This field is required",
										},
									}}
									render={({
										field: {
											onChange,
											onBlur,
											value,
											name,
											ref,
										},
										fieldState: {
											invalid,
											isTouched,
											isDirty,
											error,
										},
									}) => (
										<ReactQuillField
											name={name}
											error={error}
											label="Treatment Plan"
											value={value}
											onChange={onChange}
											className="lg:col-span-12"
										/>
									)}
								/>
							</div>
						</Card>
					</div>
				</div>
				<div className="mx-auto" style={styles.button}>
					<ButtonV3 onClick={handleSubmit(onSubmit)}>Submit</ButtonV3>
				</div>
			</form>
		</div>
	);
}
