import React, { Fragment } from "react";
import Container from "src/layout/Container";
import { Grid, Paper, Tab, Tabs, Box } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
// import PharmacyOR from "./PharmacyOR";
import LaboratoryOR from "./LaboratoryOR";
import ImagingOR from "./ImagingOR";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBrain,
  faUserMd,
  faVials,
  faXRay,
} from "@fortawesome/free-solid-svg-icons";
import DoctorOR from "./DoctorOR";
import PsychologyOR from "./PsychologyOR";

const HIMSCashierMonitor = () => {
  const [category, setCategory] = React.useState(0);

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "cashier monitoring",
          items: [{ name: "Dashboard", path: "/sph/app" }],
        }}
        title="Cashier Monitoring"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box component={Paper} variant="outlined" mb={2}>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                {/* <Tab
                  wrapped
                  label="Pharmacy"
                  icon={<FontAwesomeIcon icon={faCapsules} size={"2x"} />}
                /> */}

                <Tab
                  wrapped
                  label="Doctor"
                  icon={<FontAwesomeIcon icon={faUserMd} size={"2x"} />}
                />

                <Tab
                  wrapped
                  label="Laboratory"
                  icon={<FontAwesomeIcon icon={faVials} size={"2x"} />}
                />

                <Tab
                  wrapped
                  label="Imaging"
                  icon={<FontAwesomeIcon icon={faXRay} size={"2x"} />}
                />

                <Tab
                  wrapped
                  label="Psychology"
                  icon={<FontAwesomeIcon icon={faBrain} size={"2x"} />}
                />
              </Tabs>
            </Box>

            {/* <TabPanel value={category} index={0}>
              <PharmacyOR />
            </TabPanel> */}

            <TabPanel value={category} index={0}>
              <DoctorOR />
            </TabPanel>

            <TabPanel value={category} index={1}>
              <LaboratoryOR />
            </TabPanel>

            <TabPanel value={category} index={2}>
              <ImagingOR />
            </TabPanel>

            <TabPanel value={category} index={3}>
              <PsychologyOR />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default HIMSCashierMonitor;
