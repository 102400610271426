import {
	Box,
	Dialog,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Button,
	DialogContent,
	DialogActions,
	CircularProgress,
	Radio,
	Grid,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "src/utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const NeurologyOrderDetails = ({
	queue,
	formheader,
	order,
	getPsyNeurologyOrder,
	resetDisplay,
	getPatientWithOrder,
	order_count,
	process_for,
}) => {
	const { users } = React.useContext(UsersData);
	const history = useHistory();
	const [savebtnDisabled, setSavebtnDisabled] = useState(true);
	const [processSubmitting, setProcessSubmitting] = useState(false);
	const [resultSubmitting, setResultSubmitting] = useState(false);
	const [selectedIntelLevel, setSelectedIntelLevel] = useState(null);
	const [selectedPerseverance, setSelectedPerseverance] = useState(null);
	const [selectedObedience, setSelectedObedience] = useState(null);
	const [selectedSelfDiscipline, setSelectedSelfDiscipline] = useState(null);
	const [selectedEnthusiasm, setSelectedEnthusiasm] = useState(null);
	const [selectedInitiative, setSelectedInitiative] = useState(null);
	const [selectedCWBAWA, setSelectedCWBAWA] = useState(null);
	const [selectedTTSPAI, setSelectedTTSPAI] = useState(null);
	const [selectedFacesReality, setSelectedFacesReality] = useState(null);
	const [selectedConfidence, setSelectedConfidence] = useState(null);
	const [selectedRelaxed, setSelectedRelaxed] = useState(null);
	const [selectedToughMindedness, setSelectedToughMindedness] = useState(null);
	const [selectedAdaptability, setSelectedAdaptability] = useState(null);
	const [selectedPracticality, setSelectedPracticality] = useState(null);
	const [selectedAssertiveness, setSelectedAssertiveness] = useState(null);
	const [selectedIndependence, setSelectedIndependence] = useState(null);
	const [selectedResourcefulness, setSelectedResourcefulness] = useState(null);
	const [selectedRWPACTem, setSelectedRWPACTem] = useState(null);
	const [selectedRWPACDef, setSelectedRWPACDef] = useState(null);
	const [selectedSelfEsteem, setSelectedSelfEsteem] = useState(null);
	const [selectedAggressive, setSelectedAggressive] = useState(null);
	const [selectedDOETCCO, setSelectedDOETCCO] = useState(null);

	const [orderDetails, setOrderDetails] = useState({
		data: null,
		ready: false,
	});

	const [processDialog, setProcessDialog] = useState({
		data: null,
		dialog: false,
	});

	const getLabOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order.trace_number };
		Axios.get("psychology/order/ordernew-neurology/details", { params })
			.then((response) => {
				const data = response.data;
				setOrderDetails({
					data: data,
					ready: true,
				});

				checkIfSaveBtnIsEnabled(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const checkIfSaveBtnIsEnabled = (data) => {
		for (let i = 0; i < data.length; i++) {
			if (Boolean(data[i].is_processed)) {
				setSavebtnDisabled(false);
			}
		}
	};

	const handleChangeRadio = (e, radio) => {
		if (radio === "intel_level") {
			setSelectedIntelLevel(e.target.value);
		}
		if (radio === "perseverance") {
			setSelectedPerseverance(e.target.value);
		}
		if (radio === "obedience") {
			setSelectedObedience(e.target.value);
		}
		if (radio === "self_discipline") {
			setSelectedSelfDiscipline(e.target.value);
		}
		if (radio === "enthusiasm") {
			setSelectedEnthusiasm(e.target.value);
		}
		if (radio === "initiative") {
			setSelectedInitiative(e.target.value);
		}
		if (radio === "cwbawa") {
			setSelectedCWBAWA(e.target.value);
		}
		if (radio === "ttspai") {
			setSelectedTTSPAI(e.target.value);
		}
		if (radio === "faces_reality") {
			setSelectedFacesReality(e.target.value);
		}
		if (radio === "confidence") {
			setSelectedConfidence(e.target.value);
		}
		if (radio === "relaxed") {
			setSelectedRelaxed(e.target.value);
		}
		if (radio === "tough_mindedness") {
			setSelectedToughMindedness(e.target.value);
		}
		if (radio === "adaptability") {
			setSelectedAdaptability(e.target.value);
		}
		if (radio === "practicality") {
			setSelectedPracticality(e.target.value);
		}
		if (radio === "assertiveness") {
			setSelectedAssertiveness(e.target.value);
		}
		if (radio === "independence") {
			setSelectedIndependence(e.target.value);
		}
		if (radio === "resourcefulness") {
			setSelectedResourcefulness(e.target.value);
		}
		if (radio === "rwpac_temmanship") {
			setSelectedRWPACTem(e.target.value);
		}
		if (radio === "rwseaa_deference") {
			setSelectedRWPACDef(e.target.value);
		}
		if (radio === "self_esteem") {
			setSelectedSelfEsteem(e.target.value);
		}
		if (radio === "aggressive_tendencies") {
			setSelectedAggressive(e.target.value);
		}
		if (radio === "doetcco") {
			setSelectedDOETCCO(e.target.value);
		}
	};

	useEffect(() => {
		getLabOrderDetails();

		// eslint-disable-next-line
	}, [order]);

	const handleSaveResult = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("trace_number", order.trace_number);
		formdata.set("queue", queue);
		formdata.set("order_count", parseFloat(order_count));
		formdata.set("process_for", process_for);

		var error = [];
		if (selectedIntelLevel === null) {
			error = "error";
			Notify.fieldRequired("intellectual level");
		}
		if (selectedPerseverance === null) {
			error = "error";
			Notify.fieldRequired("perseverance");
		}
		if (selectedObedience === null) {
			error = "error";
			Notify.fieldRequired("obedience");
		}
		if (selectedSelfDiscipline === null) {
			error = "error";
			Notify.fieldRequired("self discipline");
		}
		if (selectedEnthusiasm === null) {
			error = "error";
			Notify.fieldRequired("enthusiasm");
		}
		if (selectedInitiative === null) {
			error = "error";
			Notify.fieldRequired("initiative");
		}
		if (selectedCWBAWA === null) {
			error = "error";
			Notify.fieldRequired("CAN WITHSTAND BOREDOM AND WORK ALONE");
		}
		if (selectedTTSPAI === null) {
			error = "error";
			Notify.fieldRequired("TOLERANCE TO STRESS, PRESSURE AND INCOVENIENCES");
		}
		if (selectedFacesReality === null) {
			error = "error";
			Notify.fieldRequired("faces reality");
		}
		if (selectedConfidence === null) {
			error = "error";
			Notify.fieldRequired("confidence");
		}
		if (selectedRelaxed === null) {
			error = "error";
			Notify.fieldRequired("relaxed");
		}
		if (selectedToughMindedness === null) {
			error = "error";
			Notify.fieldRequired("tough mindedness");
		}
		if (selectedAdaptability === null) {
			error = "error";
			Notify.fieldRequired("adaptability");
		}
		if (selectedPracticality === null) {
			error = "error";
			Notify.fieldRequired("practicality");
		}
		if (selectedAssertiveness === null) {
			error = "error";
			Notify.fieldRequired("assertiveness");
		}
		if (selectedIndependence === null) {
			error = "error";
			Notify.fieldRequired("independence");
		}
		if (selectedResourcefulness === null) {
			error = "error";
			Notify.fieldRequired("resourcefulness");
		}
		if (selectedRWPACTem === null) {
			error = "error";
			Notify.fieldRequired("RELATIONSHIP WITH PEERS AND CO-WORKERS");
		}
		if (selectedRWPACDef === null) {
			error = "error";
			Notify.fieldRequired(
				"RELATIONSHIP WITH SUPERIORS, EMPLOYERS AND AUTHORITY FIGURES"
			);
		}
		if (selectedSelfEsteem === null) {
			error = "error";
			Notify.fieldRequired("self esteem");
		}
		if (selectedAggressive === null) {
			error = "error";
			Notify.fieldRequired("aggressive tendencies");
		}
		if (selectedDOETCCO === null) {
			error = "error";
			Notify.fieldRequired("DIRECT ONE'S EFFORT TOWARD CLEAR CUT OBJECTIVES");
		}
		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setResultSubmitting(true);
			Axios.post(
				"psychology/order/ordernew-neurology/save-process-result",
				formdata
			)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						Notify.successRequest("order result added. Redirect to receipt.");
						setTimeout(() => {
							history.push(
								`/sph/app/psychology/record/print/order/${order.trace_number}`
							);
						}, 5000);
						getPatientWithOrder();
						setSavebtnDisabled(true);
					}
					if (data === "pass-invalid") {
						console.log("tae mali");
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => setResultSubmitting(false));
		}
	};

	const handleProcessOrder = async (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", processDialog.data.order_id);
		formdata.set("trace_number", processDialog.data.trace_number);

		var error = [];
		setProcessSubmitting(true);

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			let response = await Axios.post(
				"psychology/neurology/order-setprocess",
				formdata
			);

			if (response.data.message === "pass-invalid") {
				Notify.fieldInvalid("password");
			}

			if (response.data.message === "success") {
				Notify.successRequest("process");
				setProcessDialog({ data: null, dialog: false });
				getLabOrderDetails();
			}
		}

		setTimeout(() => {
			setProcessSubmitting(false);
		}, 2000);
	};

	const handleProcessSelectedOrder = async (data) => {
		setProcessDialog({ data: data, dialog: true });
	};

	return (
		<>
			<form onSubmit={handleSaveResult}>
				<Card elevation={0}>
					<Box>
						<CardHeader
							component={Box}
							align="center"
							title={formheader && formheader.name}
							subheader={
								<Box>
									<Typography>{formheader && formheader.address}</Typography>
									<Typography>
										{formheader && formheader.contact_number}
									</Typography>
								</Box>
							}
						/>
					</Box>

					<CardContent>
						{/* paitent information */}
						<Box display="flex">
							<Box flexGrow={1} mb={2}>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT NAME:{" "}
										</Typography>
										{`${order.lastname}, ${order.firstname} ${
											order.middle !== null ? order.middle : null
										}`}
									</Typography>
								</Box>

								<Box display="flex" mb={2}>
									<Box>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												AGE:{" "}
											</Typography>
											{order.birthday === null
												? "none"
												: Notify.calculateAge(order.birthday)}
										</Typography>
									</Box>
									<Box ml={5}>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												GENDER:{" "}
											</Typography>
											{order.gender === null ? "none" : order.gender}
										</Typography>
									</Box>
								</Box>

								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT ADDRESS:{" "}
										</Typography>
										{`${order.street} ${order.barangay} ${order.city} `}
									</Typography>
								</Box>
							</Box>

							<Box>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											DATE:{" "}
										</Typography>
										{Notify.createdAt(new Date().toLocaleString())}
									</Typography>
								</Box>
							</Box>
						</Box>
					</CardContent>

					<CardContent>
						{orderDetails.ready
							? orderDetails.data.length > 0
								? orderDetails.data.map((data, index) => (
										<Fragment key={index}>
											<Box>
												<Box mb={2} display="flex">
													<Box flexGrow={1} />
													<Box>
														<Button
															disabled={Boolean(parseInt(data.is_processed))}
															size="small"
															variant="contained"
															color="primary"
															onClick={() => {
																handleProcessSelectedOrder(data);
															}}
														>
															Process
														</Button>
													</Box>
												</Box>

												<Box
													mb={2}
													display="flex"
													borderBottom={1}
													borderColor={"#AEAEAE"}
												>
													<Box flexGrow={1}>
														<Typography variant="h6" align="center">
															<b> TABULATED PSYCHOLOGICAL EVALUATION FORM </b>
														</Typography>
													</Box>
												</Box>

												<Box hidden={!Boolean(parseInt(data.is_processed))}>
													<Box>
														<Typography variant="subtitle1">
															<b>I. INTELLECTUAL LEVEL:</b>
														</Typography>
													</Box>

													<Box>
														<Grid container spacing={2}>
															<Grid item xs={4} sm={4}>
																<Box align="left">
																	<Radio
																		checked={selectedIntelLevel === "very_high"}
																		onChange={(e) =>
																			handleChangeRadio(e, "intel_level")
																		}
																		value="very_high"
																		name="intel_level"
																		inputProps={{ "aria-label": "very_high" }}
																	/>
																	<b>Very High</b>
																</Box>
															</Grid>

															<Grid item xs={4} sm={4}>
																<Box align="left">
																	<Radio
																		checked={selectedIntelLevel === "average"}
																		onChange={(e) =>
																			handleChangeRadio(e, "intel_level")
																		}
																		value="average"
																		name="intel_level"
																		inputProps={{ "aria-label": "average" }}
																	/>
																	<b>Average</b>
																</Box>
															</Grid>

															<Grid item xs={4} sm={4}>
																<Box align="left">
																	<Radio
																		checked={selectedIntelLevel === "very_low"}
																		onChange={(e) =>
																			handleChangeRadio(e, "intel_level")
																		}
																		value="very_low"
																		name="intel_level"
																		inputProps={{ "aria-label": "very_low" }}
																	/>
																	<b>Very Low</b>
																</Box>
															</Grid>

															<Grid item xs={4} sm={4}>
																<Box align="left">
																	<Radio
																		checked={
																			selectedIntelLevel === "high_average"
																		}
																		onChange={(e) =>
																			handleChangeRadio(e, "intel_level")
																		}
																		value="high_average"
																		name="intel_level"
																		inputProps={{
																			"aria-label": "high_average",
																		}}
																	/>
																	<b>High Average</b>
																</Box>
															</Grid>

															<Grid item xs={4} sm={4}>
																<Box align="left">
																	<Radio
																		checked={
																			selectedIntelLevel === "below_average"
																		}
																		onChange={(e) =>
																			handleChangeRadio(e, "intel_level")
																		}
																		value="below_average"
																		name="intel_level"
																		inputProps={{
																			"aria-label": "below_average",
																		}}
																	/>
																	<b>Below Average</b>
																</Box>
															</Grid>
															<Grid item xs={4} sm={4} />

															<Grid item xs={4} sm={4}>
																<Box align="left">
																	<Radio
																		checked={
																			selectedIntelLevel === "above_average"
																		}
																		onChange={(e) =>
																			handleChangeRadio(e, "intel_level")
																		}
																		value="above_average"
																		name="intel_level"
																		inputProps={{
																			"aria-label": "above_average",
																		}}
																	/>
																	<b>Above Average</b>
																</Box>
															</Grid>

															<Grid item xs={4} sm={4}>
																<Box align="left">
																	<Radio
																		checked={selectedIntelLevel === "low"}
																		onChange={(e) =>
																			handleChangeRadio(e, "intel_level")
																		}
																		value="low"
																		name="intel_level"
																		inputProps={{ "aria-label": "low" }}
																	/>
																	<b>Low</b>
																</Box>
															</Grid>
														</Grid>
													</Box>

													<Box>
														<Typography variant="subtitle1">
															<b>II. PERSONAL TRAITS AND CHARACTERISTICS:</b>
														</Typography>
													</Box>
													<TableContainer>
														<Table size="small">
															<TableHead>
																<TableRow>
																	<TableCell align="center">
																		<b>SENSE OF RESPONSIBILITY</b>
																	</TableCell>
																	<TableCell align="center">
																		<b>
																			1 <br /> Very Low
																		</b>
																	</TableCell>
																	<TableCell align="center">
																		<b>
																			2 <br /> Low
																		</b>
																	</TableCell>
																	<TableCell align="center">
																		<b>
																			3 <br /> Low Average
																		</b>
																	</TableCell>
																	<TableCell align="center">
																		<b>
																			4 <br /> Average
																		</b>
																	</TableCell>

																	<TableCell align="center">
																		<b>
																			5 <br /> High Average
																		</b>
																	</TableCell>

																	<TableCell align="center">
																		<b>
																			6 <br /> High
																		</b>
																	</TableCell>

																	<TableCell align="center">
																		<b>
																			7 <br /> Very High
																		</b>
																	</TableCell>
																</TableRow>
															</TableHead>

															<TableBody>
																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Perseverance
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="order_id[]"
																				label="order_id"
																				hidden
																				value={data.order_id}
																			/>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="patient_id"
																				label="Result"
																				hidden
																				value={data.patient_id}
																			/>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="doctors_id"
																				label="doctor_id"
																				hidden
																				value={
																					data.doctor_id === null
																						? ""
																						: data.doctor_id
																				}
																			/>
																		</Box>
																		<Box>
																			<Radio
																				checked={
																					selectedPerseverance ===
																					"perseverance_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "perseverance")
																				}
																				value="perseverance_1"
																				name="perseverance"
																				inputProps={{
																					"aria-label": "perseverance_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPerseverance ===
																					"perseverance_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "perseverance")
																				}
																				value="perseverance_2"
																				name="perseverance"
																				inputProps={{
																					"aria-label": "perseverance_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPerseverance ===
																					"perseverance_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "perseverance")
																				}
																				value="perseverance_3"
																				name="perseverance"
																				inputProps={{
																					"aria-label": "perseverance_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPerseverance ===
																					"perseverance_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "perseverance")
																				}
																				value="perseverance_4"
																				name="perseverance"
																				inputProps={{
																					"aria-label": "perseverance_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPerseverance ===
																					"perseverance_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "perseverance")
																				}
																				value="perseverance_5"
																				name="perseverance"
																				inputProps={{
																					"aria-label": "perseverance_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPerseverance ===
																					"perseverance_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "perseverance")
																				}
																				value="perseverance_6"
																				name="perseverance"
																				inputProps={{
																					"aria-label": "perseverance_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPerseverance ===
																					"perseverance_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "perseverance")
																				}
																				value="perseverance_7"
																				name="perseverance"
																				inputProps={{
																					"aria-label": "perseverance_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Obedience
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedObedience === "obedience_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "obedience")
																				}
																				value="obedience_1"
																				name="obedience"
																				inputProps={{
																					"aria-label": "obedience_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedObedience === "obedience_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "obedience")
																				}
																				value="obedience_2"
																				name="obedience"
																				inputProps={{
																					"aria-label": "obedience_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedObedience === "obedience_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "obedience")
																				}
																				value="obedience_3"
																				name="obedience"
																				inputProps={{
																					"aria-label": "obedience_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedObedience === "obedience_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "obedience")
																				}
																				value="obedience_4"
																				name="obedience"
																				inputProps={{
																					"aria-label": "obedience_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedObedience === "obedience_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "obedience")
																				}
																				value="obedience_5"
																				name="obedience"
																				inputProps={{
																					"aria-label": "obedience_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedObedience === "obedience_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "obedience")
																				}
																				value="obedience_6"
																				name="obedience"
																				inputProps={{
																					"aria-label": "obedience_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedObedience === "obedience_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "obedience")
																				}
																				value="obedience_7"
																				name="obedience"
																				inputProps={{
																					"aria-label": "obedience_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Self-discipline
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfDiscipline ===
																					"self_discipline_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"self_discipline"
																					)
																				}
																				value="self_discipline_1"
																				name="self_discipline"
																				inputProps={{
																					"aria-label": "self_discipline_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfDiscipline ===
																					"self_discipline_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"self_discipline"
																					)
																				}
																				value="self_discipline_2"
																				name="self_discipline"
																				inputProps={{
																					"aria-label": "self_discipline_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfDiscipline ===
																					"self_discipline_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"self_discipline"
																					)
																				}
																				value="self_discipline_3"
																				name="self_discipline"
																				inputProps={{
																					"aria-label": "self_discipline_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfDiscipline ===
																					"self_discipline_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"self_discipline"
																					)
																				}
																				value="self_discipline_4"
																				name="self_discipline"
																				inputProps={{
																					"aria-label": "self_discipline_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfDiscipline ===
																					"self_discipline_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"self_discipline"
																					)
																				}
																				value="self_discipline_5"
																				name="self_discipline"
																				inputProps={{
																					"aria-label": "self_discipline_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfDiscipline ===
																					"self_discipline_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"self_discipline"
																					)
																				}
																				value="self_discipline_6"
																				name="self_discipline"
																				inputProps={{
																					"aria-label": "self_discipline_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfDiscipline ===
																					"self_discipline_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"self_discipline"
																					)
																				}
																				value="self_discipline_7"
																				name="self_discipline"
																				inputProps={{
																					"aria-label": "self_discipline_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Enthusiasm
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedEnthusiasm === "enthusiasm_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "enthusiasm")
																				}
																				value="enthusiasm_1"
																				name="enthusiasm"
																				inputProps={{
																					"aria-label": "enthusiasm_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedEnthusiasm === "enthusiasm_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "enthusiasm")
																				}
																				value="enthusiasm_2"
																				name="enthusiasm"
																				inputProps={{
																					"aria-label": "enthusiasm_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedEnthusiasm === "enthusiasm_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "enthusiasm")
																				}
																				value="enthusiasm_3"
																				name="enthusiasm"
																				inputProps={{
																					"aria-label": "enthusiasm_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedEnthusiasm === "enthusiasm_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "enthusiasm")
																				}
																				value="enthusiasm_4"
																				name="enthusiasm"
																				inputProps={{
																					"aria-label": "enthusiasm_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedEnthusiasm === "enthusiasm_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "enthusiasm")
																				}
																				value="enthusiasm_5"
																				name="enthusiasm"
																				inputProps={{
																					"aria-label": "enthusiasm_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedEnthusiasm === "enthusiasm_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "enthusiasm")
																				}
																				value="enthusiasm_6"
																				name="enthusiasm"
																				inputProps={{
																					"aria-label": "enthusiasm_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedEnthusiasm === "enthusiasm_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "enthusiasm")
																				}
																				value="enthusiasm_7"
																				name="enthusiasm"
																				inputProps={{
																					"aria-label": "enthusiasm_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Initiative
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedInitiative === "initiative_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "initiative")
																				}
																				value="initiative_1"
																				name="initiative"
																				inputProps={{
																					"aria-label": "initiative_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedInitiative === "initiative_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "initiative")
																				}
																				value="initiative_2"
																				name="initiative"
																				inputProps={{
																					"aria-label": "initiative_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedInitiative === "initiative_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "initiative")
																				}
																				value="initiative_3"
																				name="initiative"
																				inputProps={{
																					"aria-label": "initiative_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedInitiative === "initiative_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "initiative")
																				}
																				value="initiative_4"
																				name="initiative"
																				inputProps={{
																					"aria-label": "initiative_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedInitiative === "initiative_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "initiative")
																				}
																				value="initiative_5"
																				name="initiative"
																				inputProps={{
																					"aria-label": "initiative_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedInitiative === "initiative_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "initiative")
																				}
																				value="initiative_6"
																				name="initiative"
																				inputProps={{
																					"aria-label": "initiative_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedInitiative === "initiative_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "initiative")
																				}
																				value="initiative_7"
																				name="initiative"
																				inputProps={{
																					"aria-label": "initiative_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="subtitle2"
																			className={`gtc-uppercase`}
																		>
																			<strong>
																				<b>EMOTIONAL STABILITY</b>
																			</strong>
																		</Typography>
																	</TableCell>
																	<TableCell align="center">1</TableCell>
																	<TableCell align="center">2</TableCell>
																	<TableCell align="center">3</TableCell>
																	<TableCell align="center">4</TableCell>
																	<TableCell align="center">5</TableCell>
																	<TableCell align="center">6</TableCell>
																	<TableCell align="center">7</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Can withstand boredom and work alone
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedCWBAWA === "cwbawa_1"}
																				onChange={(e) =>
																					handleChangeRadio(e, "cwbawa")
																				}
																				value="cwbawa_1"
																				name="cwbawa"
																				inputProps={{
																					"aria-label": "cwbawa_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedCWBAWA === "cwbawa_2"}
																				onChange={(e) =>
																					handleChangeRadio(e, "cwbawa")
																				}
																				value="cwbawa_2"
																				name="cwbawa"
																				inputProps={{
																					"aria-label": "cwbawa_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedCWBAWA === "cwbawa_3"}
																				onChange={(e) =>
																					handleChangeRadio(e, "cwbawa")
																				}
																				value="cwbawa_3"
																				name="cwbawa"
																				inputProps={{
																					"aria-label": "cwbawa_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedCWBAWA === "cwbawa_4"}
																				onChange={(e) =>
																					handleChangeRadio(e, "cwbawa")
																				}
																				value="cwbawa_4"
																				name="cwbawa"
																				inputProps={{
																					"aria-label": "cwbawa_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedCWBAWA === "cwbawa_5"}
																				onChange={(e) =>
																					handleChangeRadio(e, "cwbawa")
																				}
																				value="cwbawa_5"
																				name="cwbawa"
																				inputProps={{
																					"aria-label": "cwbawa_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedCWBAWA === "cwbawa_6"}
																				onChange={(e) =>
																					handleChangeRadio(e, "cwbawa")
																				}
																				value="cwbawa_6"
																				name="cwbawa"
																				inputProps={{
																					"aria-label": "cwbawa_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedCWBAWA === "cwbawa_7"}
																				onChange={(e) =>
																					handleChangeRadio(e, "cwbawa")
																				}
																				value="cwbawa_7"
																				name="cwbawa"
																				inputProps={{
																					"aria-label": "cwbawa_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Tolerance to stress, pressure and
																			<br />
																			incoveniences
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedTTSPAI === "ttspai_1"}
																				onChange={(e) =>
																					handleChangeRadio(e, "ttspai")
																				}
																				value="ttspai_1"
																				name="ttspai"
																				inputProps={{
																					"aria-label": "ttspai_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedTTSPAI === "ttspai_2"}
																				onChange={(e) =>
																					handleChangeRadio(e, "ttspai")
																				}
																				value="ttspai_2"
																				name="ttspai"
																				inputProps={{
																					"aria-label": "ttspai_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedTTSPAI === "ttspai_3"}
																				onChange={(e) =>
																					handleChangeRadio(e, "ttspai")
																				}
																				value="ttspai_3"
																				name="ttspai"
																				inputProps={{
																					"aria-label": "ttspai_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedTTSPAI === "ttspai_4"}
																				onChange={(e) =>
																					handleChangeRadio(e, "ttspai")
																				}
																				value="ttspai_4"
																				name="ttspai"
																				inputProps={{
																					"aria-label": "ttspai_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedTTSPAI === "ttspai_5"}
																				onChange={(e) =>
																					handleChangeRadio(e, "ttspai")
																				}
																				value="ttspai_5"
																				name="ttspai"
																				inputProps={{
																					"aria-label": "ttspai_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedTTSPAI === "ttspai_6"}
																				onChange={(e) =>
																					handleChangeRadio(e, "ttspai")
																				}
																				value="ttspai_6"
																				name="ttspai"
																				inputProps={{
																					"aria-label": "ttspai_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={selectedTTSPAI === "ttspai_7"}
																				onChange={(e) =>
																					handleChangeRadio(e, "ttspai")
																				}
																				value="ttspai_7"
																				name="ttspai"
																				inputProps={{
																					"aria-label": "ttspai_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Faces reality
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedFacesReality ===
																					"faces_reality_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "faces_reality")
																				}
																				value="faces_reality_1"
																				name="faces_reality"
																				inputProps={{
																					"aria-label": "faces_reality_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedFacesReality ===
																					"faces_reality_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "faces_reality")
																				}
																				value="faces_reality_2"
																				name="faces_reality"
																				inputProps={{
																					"aria-label": "faces_reality_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedFacesReality ===
																					"faces_reality_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "faces_reality")
																				}
																				value="faces_reality_3"
																				name="faces_reality"
																				inputProps={{
																					"aria-label": "faces_reality_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedFacesReality ===
																					"faces_reality_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "faces_reality")
																				}
																				value="faces_reality_4"
																				name="faces_reality"
																				inputProps={{
																					"aria-label": "faces_reality_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedFacesReality ===
																					"faces_reality_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "faces_reality")
																				}
																				value="faces_reality_5"
																				name="faces_reality"
																				inputProps={{
																					"aria-label": "faces_reality_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedFacesReality ===
																					"faces_reality_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "faces_reality")
																				}
																				value="faces_reality_6"
																				name="faces_reality"
																				inputProps={{
																					"aria-label": "faces_reality_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedFacesReality ===
																					"faces_reality_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "faces_reality")
																				}
																				value="faces_reality_7"
																				name="faces_reality"
																				inputProps={{
																					"aria-label": "faces_reality_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Confidence
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedConfidence === "confidence_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "confidence")
																				}
																				value="confidence_1"
																				name="confidence"
																				inputProps={{
																					"aria-label": "confidence_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedConfidence === "confidence_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "confidence")
																				}
																				value="confidence_2"
																				name="confidence"
																				inputProps={{
																					"aria-label": "confidence_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedConfidence === "confidence_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "confidence")
																				}
																				value="confidence_3"
																				name="confidence"
																				inputProps={{
																					"aria-label": "confidence_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedConfidence === "confidence_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "confidence")
																				}
																				value="confidence_4"
																				name="confidence"
																				inputProps={{
																					"aria-label": "confidence_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedConfidence === "confidence_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "confidence")
																				}
																				value="confidence_5"
																				name="confidence"
																				inputProps={{
																					"aria-label": "confidence_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedConfidence === "confidence_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "confidence")
																				}
																				value="confidence_6"
																				name="confidence"
																				inputProps={{
																					"aria-label": "confidence_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedConfidence === "confidence_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "confidence")
																				}
																				value="confidence_7"
																				name="confidence"
																				inputProps={{
																					"aria-label": "confidence_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Relaxed
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRelaxed === "relaxed_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "relaxed")
																				}
																				value="relaxed_1"
																				name="relaxed"
																				inputProps={{
																					"aria-label": "relaxed_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRelaxed === "relaxed_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "relaxed")
																				}
																				value="relaxed_2"
																				name="relaxed"
																				inputProps={{
																					"aria-label": "relaxed_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRelaxed === "relaxed_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "relaxed")
																				}
																				value="relaxed_3"
																				name="relaxed"
																				inputProps={{
																					"aria-label": "relaxed_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRelaxed === "relaxed_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "relaxed")
																				}
																				value="relaxed_4"
																				name="relaxed"
																				inputProps={{
																					"aria-label": "relaxed_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRelaxed === "relaxed_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "relaxed")
																				}
																				value="relaxed_5"
																				name="relaxed"
																				inputProps={{
																					"aria-label": "relaxed_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRelaxed === "relaxed_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "relaxed")
																				}
																				value="relaxed_6"
																				name="relaxed"
																				inputProps={{
																					"aria-label": "relaxed_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRelaxed === "relaxed_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "relaxed")
																				}
																				value="relaxed_7"
																				name="relaxed"
																				inputProps={{
																					"aria-label": "relaxed_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>
																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="subtitle2"
																			className={`gtc-uppercase`}
																		>
																			<strong>
																				<b>OBJECTIVITY</b>
																			</strong>
																		</Typography>
																	</TableCell>
																	<TableCell align="center">1</TableCell>
																	<TableCell align="center">2</TableCell>
																	<TableCell align="center">3</TableCell>
																	<TableCell align="center">4</TableCell>
																	<TableCell align="center">5</TableCell>
																	<TableCell align="center">6</TableCell>
																	<TableCell align="center">7</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Tough-mindedness
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedToughMindedness ===
																					"tough_mindedness_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"tough_mindedness"
																					)
																				}
																				value="tough_mindedness_1"
																				name="tough_mindedness"
																				inputProps={{
																					"aria-label": "tough_mindedness_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedToughMindedness ===
																					"tough_mindedness_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"tough_mindedness"
																					)
																				}
																				value="tough_mindedness_2"
																				name="tough_mindedness"
																				inputProps={{
																					"aria-label": "tough_mindedness_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedToughMindedness ===
																					"tough_mindedness_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"tough_mindedness"
																					)
																				}
																				value="tough_mindedness_3"
																				name="tough_mindedness"
																				inputProps={{
																					"aria-label": "tough_mindedness_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedToughMindedness ===
																					"tough_mindedness_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"tough_mindedness"
																					)
																				}
																				value="tough_mindedness_4"
																				name="tough_mindedness"
																				inputProps={{
																					"aria-label": "tough_mindedness_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedToughMindedness ===
																					"tough_mindedness_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"tough_mindedness"
																					)
																				}
																				value="tough_mindedness_5"
																				name="tough_mindedness"
																				inputProps={{
																					"aria-label": "tough_mindedness_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedToughMindedness ===
																					"tough_mindedness_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"tough_mindedness"
																					)
																				}
																				value="tough_mindedness_6"
																				name="tough_mindedness"
																				inputProps={{
																					"aria-label": "tough_mindedness_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedToughMindedness ===
																					"tough_mindedness_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"tough_mindedness"
																					)
																				}
																				value="tough_mindedness_7"
																				name="tough_mindedness"
																				inputProps={{
																					"aria-label": "tough_mindedness_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Adaptability
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAdaptability ===
																					"adaptability_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "adaptability")
																				}
																				value="adaptability_1"
																				name="adaptability"
																				inputProps={{
																					"aria-label": "adaptability_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAdaptability ===
																					"adaptability_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "adaptability")
																				}
																				value="adaptability_2"
																				name="adaptability"
																				inputProps={{
																					"aria-label": "adaptability_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAdaptability ===
																					"adaptability_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "adaptability")
																				}
																				value="adaptability_3"
																				name="adaptability"
																				inputProps={{
																					"aria-label": "adaptability_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAdaptability ===
																					"adaptability_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "adaptability")
																				}
																				value="adaptability_4"
																				name="adaptability"
																				inputProps={{
																					"aria-label": "adaptability_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAdaptability ===
																					"adaptability_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "adaptability")
																				}
																				value="adaptability_5"
																				name="adaptability"
																				inputProps={{
																					"aria-label": "adaptability_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAdaptability ===
																					"adaptability_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "adaptability")
																				}
																				value="adaptability_6"
																				name="adaptability"
																				inputProps={{
																					"aria-label": "adaptability_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAdaptability ===
																					"adaptability_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "adaptability")
																				}
																				value="adaptability_7"
																				name="adaptability"
																				inputProps={{
																					"aria-label": "adaptability_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Practically
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPracticality ===
																					"practicality_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "practicality")
																				}
																				value="practicality_1"
																				name="practicality"
																				inputProps={{
																					"aria-label": "practicality_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPracticality ===
																					"practicality_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "practicality")
																				}
																				value="practicality_2"
																				name="practicality"
																				inputProps={{
																					"aria-label": "practicality_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPracticality ===
																					"practicality_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "practicality")
																				}
																				value="practicality_3"
																				name="practicality"
																				inputProps={{
																					"aria-label": "practicality_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPracticality ===
																					"practicality_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "practicality")
																				}
																				value="practicality_4"
																				name="practicality"
																				inputProps={{
																					"aria-label": "practicality_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPracticality ===
																					"practicality_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "practicality")
																				}
																				value="practicality_5"
																				name="practicality"
																				inputProps={{
																					"aria-label": "practicality_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPracticality ===
																					"practicality_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "practicality")
																				}
																				value="practicality_6"
																				name="practicality"
																				inputProps={{
																					"aria-label": "practicality_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedPracticality ===
																					"practicality_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "practicality")
																				}
																				value="practicality_7"
																				name="practicality"
																				inputProps={{
																					"aria-label": "practicality_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>
																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="subtitle2"
																			className={`gtc-uppercase`}
																		>
																			<strong>
																				<b>MOTIVATION</b>
																			</strong>
																		</Typography>
																	</TableCell>
																	<TableCell align="center">1</TableCell>
																	<TableCell align="center">2</TableCell>
																	<TableCell align="center">3</TableCell>
																	<TableCell align="center">4</TableCell>
																	<TableCell align="center">5</TableCell>
																	<TableCell align="center">6</TableCell>
																	<TableCell align="center">7</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Assertiveness
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAssertiveness ===
																					"assertiveness_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "assertiveness")
																				}
																				value="assertiveness_1"
																				name="assertiveness"
																				inputProps={{
																					"aria-label": "assertiveness_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAssertiveness ===
																					"assertiveness_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "assertiveness")
																				}
																				value="assertiveness_2"
																				name="assertiveness"
																				inputProps={{
																					"aria-label": "assertiveness_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAssertiveness ===
																					"assertiveness_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "assertiveness")
																				}
																				value="assertiveness_3"
																				name="assertiveness"
																				inputProps={{
																					"aria-label": "assertiveness_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAssertiveness ===
																					"assertiveness_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "assertiveness")
																				}
																				value="assertiveness_4"
																				name="assertiveness"
																				inputProps={{
																					"aria-label": "assertiveness_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAssertiveness ===
																					"assertiveness_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "assertiveness")
																				}
																				value="assertiveness_5"
																				name="assertiveness"
																				inputProps={{
																					"aria-label": "assertiveness_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAssertiveness ===
																					"assertiveness_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "assertiveness")
																				}
																				value="assertiveness_6"
																				name="assertiveness"
																				inputProps={{
																					"aria-label": "assertiveness_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAssertiveness ===
																					"assertiveness_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "assertiveness")
																				}
																				value="assertiveness_7"
																				name="assertiveness"
																				inputProps={{
																					"aria-label": "assertiveness_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Independence
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedIndependence ===
																					"independence_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "independence")
																				}
																				value="independence_1"
																				name="independence"
																				inputProps={{
																					"aria-label": "independence_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedIndependence ===
																					"independence_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "independence")
																				}
																				value="independence_2"
																				name="independence"
																				inputProps={{
																					"aria-label": "independence_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedIndependence ===
																					"independence_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "independence")
																				}
																				value="independence_3"
																				name="independence"
																				inputProps={{
																					"aria-label": "independence_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedIndependence ===
																					"independence_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "independence")
																				}
																				value="independence_4"
																				name="independence"
																				inputProps={{
																					"aria-label": "independence_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedIndependence ===
																					"independence_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "independence")
																				}
																				value="independence_5"
																				name="independence"
																				inputProps={{
																					"aria-label": "independence_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedIndependence ===
																					"independence_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "independence")
																				}
																				value="independence_6"
																				name="independence"
																				inputProps={{
																					"aria-label": "independence_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedIndependence ===
																					"independence_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "independence")
																				}
																				value="independence_7"
																				name="independence"
																				inputProps={{
																					"aria-label": "independence_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Resourcefulness
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedResourcefulness ===
																					"resourcefulness_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"resourcefulness"
																					)
																				}
																				value="resourcefulness_1"
																				name="resourcefulness"
																				inputProps={{
																					"aria-label": "resourcefulness_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedResourcefulness ===
																					"resourcefulness_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"resourcefulness"
																					)
																				}
																				value="resourcefulness_2"
																				name="resourcefulness"
																				inputProps={{
																					"aria-label": "resourcefulness_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedResourcefulness ===
																					"resourcefulness_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"resourcefulness"
																					)
																				}
																				value="resourcefulness_3"
																				name="resourcefulness"
																				inputProps={{
																					"aria-label": "resourcefulness_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedResourcefulness ===
																					"resourcefulness_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"resourcefulness"
																					)
																				}
																				value="resourcefulness_4"
																				name="resourcefulness"
																				inputProps={{
																					"aria-label": "resourcefulness_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedResourcefulness ===
																					"resourcefulness_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"resourcefulness"
																					)
																				}
																				value="resourcefulness_5"
																				name="resourcefulness"
																				inputProps={{
																					"aria-label": "resourcefulness_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedResourcefulness ===
																					"resourcefulness_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"resourcefulness"
																					)
																				}
																				value="resourcefulness_6"
																				name="resourcefulness"
																				inputProps={{
																					"aria-label": "resourcefulness_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedResourcefulness ===
																					"resourcefulness_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"resourcefulness"
																					)
																				}
																				value="resourcefulness_7"
																				name="resourcefulness"
																				inputProps={{
																					"aria-label": "resourcefulness_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="subtitle2"
																			className={`gtc-uppercase`}
																		>
																			<strong>
																				<b>
																					INTERPERSONAL AND PERSONAL ADJUSTMENT
																				</b>
																			</strong>
																		</Typography>
																	</TableCell>
																	<TableCell align="center">1</TableCell>
																	<TableCell align="center">2</TableCell>
																	<TableCell align="center">3</TableCell>
																	<TableCell align="center">4</TableCell>
																	<TableCell align="center">5</TableCell>
																	<TableCell align="center">6</TableCell>
																	<TableCell align="center">7</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Relationship with Peers and Co-workers
																			<br />
																			(Teammanship)
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACTem ===
																					"rwpac_temmanship_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwpac_temmanship"
																					)
																				}
																				value="rwpac_temmanship_1"
																				name="rwpac_temmanship"
																				inputProps={{
																					"aria-label": "rwpac_temmanship_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACTem ===
																					"rwpac_temmanship_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwpac_temmanship"
																					)
																				}
																				value="rwpac_temmanship_2"
																				name="rwpac_temmanship"
																				inputProps={{
																					"aria-label": "rwpac_temmanship_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACTem ===
																					"rwpac_temmanship_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwpac_temmanship"
																					)
																				}
																				value="rwpac_temmanship_3"
																				name="rwpac_temmanship"
																				inputProps={{
																					"aria-label": "rwpac_temmanship_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACTem ===
																					"rwpac_temmanship_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwpac_temmanship"
																					)
																				}
																				value="rwpac_temmanship_4"
																				name="rwpac_temmanship"
																				inputProps={{
																					"aria-label": "rwpac_temmanship_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACTem ===
																					"rwpac_temmanship_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwpac_temmanship"
																					)
																				}
																				value="rwpac_temmanship_5"
																				name="rwpac_temmanship"
																				inputProps={{
																					"aria-label": "rwpac_temmanship_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACTem ===
																					"rwpac_temmanship_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwpac_temmanship"
																					)
																				}
																				value="rwpac_temmanship_6"
																				name="rwpac_temmanship"
																				inputProps={{
																					"aria-label": "rwpac_temmanship_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACTem ===
																					"rwpac_temmanship_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwpac_temmanship"
																					)
																				}
																				value="rwpac_temmanship_7"
																				name="rwpac_temmanship"
																				inputProps={{
																					"aria-label": "rwpac_temmanship_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Relationship with Superiors, Employers and
																			<br />
																			Authority Figures (Defence)
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACDef ===
																					"rwseaa_deference_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwseaa_deference"
																					)
																				}
																				value="rwseaa_deference_1"
																				name="rwseaa_deference"
																				inputProps={{
																					"aria-label": "rwseaa_deference_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACDef ===
																					"rwseaa_deference_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwseaa_deference"
																					)
																				}
																				value="rwseaa_deference_2"
																				name="rwseaa_deference"
																				inputProps={{
																					"aria-label": "rwseaa_deference_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACDef ===
																					"rwseaa_deference_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwseaa_deference"
																					)
																				}
																				value="rwseaa_deference_3"
																				name="rwseaa_deference"
																				inputProps={{
																					"aria-label": "rwseaa_deference_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACDef ===
																					"rwseaa_deference_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwseaa_deference"
																					)
																				}
																				value="rwseaa_deference_4"
																				name="rwseaa_deference"
																				inputProps={{
																					"aria-label": "rwseaa_deference_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACDef ===
																					"rwseaa_deference_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwseaa_deference"
																					)
																				}
																				value="rwseaa_deference_5"
																				name="rwseaa_deference"
																				inputProps={{
																					"aria-label": "rwseaa_deference_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACDef ===
																					"rwseaa_deference_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwseaa_deference"
																					)
																				}
																				value="rwseaa_deference_6"
																				name="rwseaa_deference"
																				inputProps={{
																					"aria-label": "rwseaa_deference_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedRWPACDef ===
																					"rwseaa_deference_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"rwseaa_deference"
																					)
																				}
																				value="rwseaa_deference_7"
																				name="rwseaa_deference"
																				inputProps={{
																					"aria-label": "rwseaa_deference_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Self-Esteem
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfEsteem === "self_esteem_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "self_esteem")
																				}
																				value="self_esteem_1"
																				name="self_esteem"
																				inputProps={{
																					"aria-label": "self_esteem_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfEsteem === "self_esteem_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "self_esteem")
																				}
																				value="self_esteem_2"
																				name="self_esteem"
																				inputProps={{
																					"aria-label": "self_esteem_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfEsteem === "self_esteem_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "self_esteem")
																				}
																				value="self_esteem_3"
																				name="self_esteem"
																				inputProps={{
																					"aria-label": "self_esteem_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfEsteem === "self_esteem_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "self_esteem")
																				}
																				value="self_esteem_4"
																				name="self_esteem"
																				inputProps={{
																					"aria-label": "self_esteem_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfEsteem === "self_esteem_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "self_esteem")
																				}
																				value="self_esteem_5"
																				name="self_esteem"
																				inputProps={{
																					"aria-label": "self_esteem_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfEsteem === "self_esteem_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "self_esteem")
																				}
																				value="self_esteem_6"
																				name="self_esteem"
																				inputProps={{
																					"aria-label": "self_esteem_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedSelfEsteem === "self_esteem_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "self_esteem")
																				}
																				value="self_esteem_7"
																				name="self_esteem"
																				inputProps={{
																					"aria-label": "self_esteem_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Aggressive Tendencies
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAggressive ===
																					"aggressive_tendencies_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"aggressive_tendencies"
																					)
																				}
																				value="aggressive_tendencies_1"
																				name="aggressive_tendencies"
																				inputProps={{
																					"aria-label":
																						"aggressive_tendencies_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAggressive ===
																					"aggressive_tendencies_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"aggressive_tendencies"
																					)
																				}
																				value="aggressive_tendencies_2"
																				name="aggressive_tendencies"
																				inputProps={{
																					"aria-label":
																						"aggressive_tendencies_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAggressive ===
																					"aggressive_tendencies_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"aggressive_tendencies"
																					)
																				}
																				value="aggressive_tendencies_3"
																				name="aggressive_tendencies"
																				inputProps={{
																					"aria-label":
																						"aggressive_tendencies_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAggressive ===
																					"aggressive_tendencies_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"aggressive_tendencies"
																					)
																				}
																				value="aggressive_tendencies_4"
																				name="aggressive_tendencies"
																				inputProps={{
																					"aria-label":
																						"aggressive_tendencies_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAggressive ===
																					"aggressive_tendencies_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"aggressive_tendencies"
																					)
																				}
																				value="aggressive_tendencies_5"
																				name="aggressive_tendencies"
																				inputProps={{
																					"aria-label":
																						"aggressive_tendencies_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAggressive ===
																					"aggressive_tendencies_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"aggressive_tendencies"
																					)
																				}
																				value="aggressive_tendencies_6"
																				name="aggressive_tendencies"
																				inputProps={{
																					"aria-label":
																						"aggressive_tendencies_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedAggressive ===
																					"aggressive_tendencies_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(
																						e,
																						"aggressive_tendencies"
																					)
																				}
																				value="aggressive_tendencies_7"
																				name="aggressive_tendencies"
																				inputProps={{
																					"aria-label":
																						"aggressive_tendencies_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="subtitle2"
																			className={`gtc-uppercase`}
																		>
																			<strong>
																				<b>Goal - Orientation</b>
																			</strong>
																		</Typography>
																	</TableCell>
																	<TableCell align="center">1</TableCell>
																	<TableCell align="center">2</TableCell>
																	<TableCell align="center">3</TableCell>
																	<TableCell align="center">4</TableCell>
																	<TableCell align="center">5</TableCell>
																	<TableCell align="center">6</TableCell>
																	<TableCell align="center">7</TableCell>
																</TableRow>

																<TableRow hidden={data.neuroexam_test === null}>
																	<TableCell align="center">
																		<Typography
																			variant="caption"
																			className={`gtc-uppercase`}
																		>
																			Direct one's effort toward clear cut
																			<br /> objectives
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedDOETCCO === "doetcco_1"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "doetcco")
																				}
																				value="doetcco_1"
																				name="doetcco"
																				inputProps={{
																					"aria-label": "doetcco_1",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedDOETCCO === "doetcco_2"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "doetcco")
																				}
																				value="doetcco_2"
																				name="doetcco"
																				inputProps={{
																					"aria-label": "doetcco_2",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedDOETCCO === "doetcco_3"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "doetcco")
																				}
																				value="doetcco_3"
																				name="doetcco"
																				inputProps={{
																					"aria-label": "doetcco_3",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedDOETCCO === "doetcco_4"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "doetcco")
																				}
																				value="doetcco_4"
																				name="doetcco"
																				inputProps={{
																					"aria-label": "doetcco_4",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedDOETCCO === "doetcco_5"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "doetcco")
																				}
																				value="doetcco_5"
																				name="doetcco"
																				inputProps={{
																					"aria-label": "doetcco_5",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedDOETCCO === "doetcco_6"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "doetcco")
																				}
																				value="doetcco_6"
																				name="doetcco"
																				inputProps={{
																					"aria-label": "doetcco_6",
																				}}
																			/>
																		</Box>
																	</TableCell>
																	<TableCell align="center">
																		<Box>
																			<Radio
																				checked={
																					selectedDOETCCO === "doetcco_7"
																				}
																				onChange={(e) =>
																					handleChangeRadio(e, "doetcco")
																				}
																				value="doetcco_7"
																				name="doetcco"
																				inputProps={{
																					"aria-label": "doetcco_7",
																				}}
																			/>
																		</Box>
																	</TableCell>
																</TableRow>
															</TableBody>
														</Table>
													</TableContainer>
												</Box>
											</Box>
										</Fragment>
								  ))
								: Notify.noRecord()
							: Notify.loading()}
					</CardContent>
					<Box display="flex" m={1}>
						<Box flexGrow={1} />
						<Box>
							<CardActions>
								<Button
									variant="contained"
									size="large"
									color="primary"
									type="submit"
									disabled={savebtnDisabled}
									startIcon={
										resultSubmitting && (
											<CircularProgress size={20} color="inherit" />
										)
									}
								>
									Save Result
								</Button>
							</CardActions>
						</Box>
					</Box>
				</Card>
			</form>

			<Dialog
				open={processDialog.dialog}
				onClose={() => setProcessDialog({ data: null, dialog: false })}
				disableBackdropClick
				PaperComponent={DraggableDialog}
				maxWidth={"xs"}
				fullWidth
			>
				<form onSubmit={handleProcessOrder}>
					<DialogContent dividers>
						<Box mb={2}>
							<Typography>Are you sure to process?</Typography>
						</Box>

						<Box>
							<TextField
								fullWidth
								required
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setProcessDialog({ data: null, dialog: false })}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={processSubmitting}
							startIcon={
								processSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<CheckCircleIcon />
								)
							}
						>
							process
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default NeurologyOrderDetails;
