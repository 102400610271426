import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const CBCOrder = ({ formheader, cbcHemaOrderDetails }) => {
  return (
    <>
      {cbcHemaOrderDetails.ready ? (
        cbcHemaOrderDetails.data.length > 0 ? (
          cbcHemaOrderDetails.data.map((data, key) => (
            <Fragment key={key}>
              <Card
                variant="outlined"
                component={Box}
                className="pagebreaker-before"
              >
                <Box display="flex" justifyContent="center">
                  <Box mt={2}>
                    {formheader && (
                      <CardMedia
                        style={{ width: 70 }}
                        component={"img"}
                        src={`${imageLocation}laboratory/logo/${formheader.logo}`}
                      />
                    )}
                  </Box>

                  <Box>
                    <CardHeader
                      component={Box}
                      align="center"
                      title={
                        formheader && (
                          <strong
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {formheader.name}
                          </strong>
                        )
                      }
                      subheader={
                        <Box>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {formheader && formheader.address}
                          </Typography>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {formheader && formheader.contact_number}
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                </Box>

                {/* <CardContent>
                  <Header details={cbcHemaOrderDetails} />
                </CardContent> */}

                <CardContent>
                  <Box>
                    <Header details={cbcHemaOrderDetails} />
                  </Box>

                  <Box>
                    {Boolean(parseInt(data.cbc)) && (
                      <Box my={1}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography variant="h6" align="center">
                              <span
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                HEMATOLOGY
                              </span>
                            </Typography>

                            <Typography variant="body1" align="center">
                              <span
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                COMPLETE BLOOD COUNT
                              </span>
                            </Typography>
                          </Box>
                        </Box>

                        <Box hidden={!Boolean(parseInt(data.is_processed))}>
                          <Grid container spacing={2}>
                            {/* <Grid item xs={6}>
                              <TableContainer>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell colSpan={2} align="center">
                                        RESULT
                                      </TableCell>
                                      <TableCell>NV SI Units</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>Hemoglobin</TableCell>
                                      <TableCell>{data.rbc}</TableCell>
                                      <TableCell>
                                        M: 140-170 gms/L
                                        <br />
                                        F: 120-140gms/L
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid> */}

                            <Grid item xs={4}>
                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    WBC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.wbc}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    LYM :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.lym}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MID :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mid}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    NEUT :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.neut}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    RBC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rbc}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={4}>
                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    HGB :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    {data.hgb}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    HTC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.hct}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MCV :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mcv}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MCH :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mch}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MCHC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mchc}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={4}>
                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    RDW-SD :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rdw_sd}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    RDW-CV :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rdw_cv}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MPV :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mpv}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    PDW :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.pdw}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    PCT :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.pct}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Box mt={2} display="flex">
                            <Box width={90}>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                P-LCR :{" "}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                align="center"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.p_lcr}
                              </Typography>
                            </Box>
                          </Box>

                          <Box mt={2} display="flex">
                            <Box width={90}>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                REMARKS :{" "}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                align="center"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.remarks}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {Boolean(parseInt(data.cbc_platelet)) && (
                      <Box mt={1}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography
                              variant="h6"
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              HEMATOLOGY
                            </Typography>

                            {/* <Typography
                              variant="body1"
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              COMPLETE BLOOD COUNT WITH PLATELET
                            </Typography> */}
                          </Box>
                        </Box>

                        <Box
                          mt={1}
                          hidden={!Boolean(parseInt(data.is_processed))}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Box width="100%">
                                <TableContainer>
                                  <Table
                                    size="small"
                                    className="table-bordered"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell colSpan={2} align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            RESULT
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            NV SI Units
                                          </b>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            Hemoglobin
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.hgb}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            contentEditable
                                            suppressContentEditableWarning
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>
                                              M: 140-170 gms/L; F: 120-140gms/L
                                            </b>
                                            {/* <br />
                                            F: 120-140gms/L */}
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            Hematocrit
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.hct}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            contentEditable
                                            suppressContentEditableWarning
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>M: 0.40-0.50; F: 0.38-0.48</b>
                                            {/* <br />
                                            F: 0.38-0.48 */}
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            RBC count
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.rbc}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            contentEditable
                                            suppressContentEditableWarning
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>
                                              M: 4.5-6.0x10 12/L; F: 4-5x10 12/L
                                            </b>
                                            {/* <br />
                                            F: 4-5x10 12/L */}
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            WBC count
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.wbc}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            contentEditable
                                            suppressContentEditableWarning
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>5 - 10x10&#8313;/L</b>
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            Neutrophils
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.neut}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            contentEditable
                                            suppressContentEditableWarning
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>0.50-0.70</b>
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            Lymphocytes
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.lym}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            contentEditable
                                            suppressContentEditableWarning
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>0.20-0.40</b>
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            Monocytes
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.monocytes}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            contentEditable
                                            suppressContentEditableWarning
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>0.02-0.07</b>
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            Eosinophils
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.eosinophils}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            contentEditable
                                            suppressContentEditableWarning
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>0.01-0.05</b>
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            Basophils
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.basophils}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            contentEditable
                                            suppressContentEditableWarning
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>0-0.01</b>
                                          </span>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Grid>

                            <Grid item xs={6}>
                              <Box width="100%">
                                <TableContainer>
                                  <Table
                                    size="small"
                                    className="table-bordered"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell colSpan={2} align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            RESULT
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            NV SI Units
                                          </b>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            Platelet count
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.plt}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            suppressContentEditableWarning
                                            contentEditable
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>150 - 400 x 10&#179;/L</b>
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            Reticulocyte
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.reticulocyte}
                                          </b>
                                        </TableCell>
                                        <TableCell />
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            ESR
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.esr_male}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            suppressContentEditableWarning
                                            contentEditable
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>M: 0-10mm/hr</b>
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell />
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.esr_female}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            suppressContentEditableWarning
                                            contentEditable
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>F: 0-20mm/hr</b>
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            Clotting time
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.clotting_time}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            suppressContentEditableWarning
                                            contentEditable
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>2-6 minutes</b>
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            Bleeding Time
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.bleeding_time}
                                          </b>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            suppressContentEditableWarning
                                            contentEditable
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            <b>1-3 minutes</b>
                                          </span>
                                        </TableCell>
                                      </TableRow>

                                      {/* <TableRow>
                                        <TableCell>.</TableCell>
                                        <TableCell />
                                        <TableCell />
                                      </TableRow> */}

                                      <TableRow>
                                        <TableCell>
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            Bloodtype
                                          </b>
                                        </TableCell>
                                        <TableCell align="center">
                                          <b
                                            style={{
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {data.blood_type}
                                          </b>
                                        </TableCell>
                                        <TableCell />
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <Box mt={2} display="flex">
                                  <Box width={60}>
                                    <Typography
                                      style={{
                                        fontWeight: "bolder",
                                        color: "#000",
                                      }}
                                    >
                                      Others :{" "}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      align="center"
                                      style={{
                                        fontWeight: "bolder",
                                        color: "#000",
                                      }}
                                    >
                                      {data.remarks}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>

                            {/* <Grid item xs={4}>
                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    WBC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.wbc}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    LYM :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.lym}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MID :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mid}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    NEUT :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.neut}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    RBC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rbc}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    HGB :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.hgb}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={4}>
                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    HTC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.hct}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MCV :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    {data.mcv}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MCH :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mch}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MCHC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mchc}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    RDW-SD :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rdw_sd}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    RDW-CV :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rdw_cv}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={4}>
                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MPV :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mpv}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    PDW :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.pdw}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    PCT :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.pct}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box mt={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    PLT :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.plt}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mt={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    P-LCR :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.p_lcr}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid> */}
                          </Grid>

                          {/* <Box mt={2} display="flex">
                            <Box width={90}>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                REMARKS :{" "}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                align="center"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.remarks}
                              </Typography>
                            </Box>
                          </Box> */}
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <Box align="center">
                    <Footer
                      formheader={formheader}
                      orderDetails={cbcHemaOrderDetails}
                    />
                  </Box>
                </CardContent>
              </Card>

              <div className="pagebreaker-before" />
            </Fragment>
          ))
        ) : (
          <Typography color="secondary" align="center">
            No record found.
          </Typography>
        )
      ) : (
        <Typography color="primary" align="center">
          Loading, Please wait...
        </Typography>
      )}
    </>
  );
};

export default CBCOrder;
