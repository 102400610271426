import {
  Box,
  Button,
  Collapse,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState, useCallback } from "react";

import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Notify from "../../notification/Notify";
import Axios from "axios";
import HealthDietForm from "./HealthDietForm";
import { UsersData } from "src/ContextAPI";

const HealthDiet = ({ patient_id, connection }) => {
  const { users } = React.useContext(UsersData);

  const [newdiet, setNewDiet] = useState(false);

  const [personalDietByDate, setPersonalDietByDate] = useState([]);
  const [personalDietByDateReady, setPersonalDietByDateReady] = useState([]);

  const [personalDiet, setPersonalDiet] = useState([]);

  const [suggestedDietByDate, setSuggestedDietByDate] = useState([]);
  const [suggestedDietByDateReady, setSuggestedDietByDateReady] = useState([]);

  const [suggestedDiet, setSuggestedDiet] = useState([]);

  const [selectedpid, setSelectedPid] = useState(null);

  const [suggestedpid, setSuggestedPid] = useState(null);

  const getPersonalDietByDate = useCallback(() => {
    var formdata = new FormData();

    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);

    Axios.post(
      "doctor/patient/diet/monitoring/personaldiet-listbydate",
      formdata
    )
      .then((response) => {
        const data = response.data;
        setPersonalDietByDate(data);
        setPersonalDietByDateReady(true);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users, patient_id, connection]);

  const getPersonalDiet = useCallback(() => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);

    Axios.post("doctor/patient/diet/monitoring/personaldiet-list", formdata)
      .then((response) => {
        const data = response.data;
        setPersonalDiet(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users, patient_id, connection]);

  const getSuggestedDietByDate = useCallback(() => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);

    Axios.post(
      "doctor/patient/diet/monitoring/suggesteddiet-listbydate",
      formdata
    )
      .then((response) => {
        const data = response.data;
        setSuggestedDietByDate(data);
        setSuggestedDietByDateReady(true);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users, patient_id, connection]);

  const getSuggestedDiet = useCallback(() => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);

    Axios.post("doctor/patient/diet/monitoring/suggesteddiet-list", formdata)
      .then((response) => {
        const data = response.data;
        setSuggestedDiet(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users, patient_id, connection]);

  useEffect(() => {
    getPersonalDietByDate();
    getPersonalDiet();

    getSuggestedDiet();
    getSuggestedDietByDate();
  }, [
    getPersonalDietByDate,
    getPersonalDiet,
    getSuggestedDiet,
    getSuggestedDietByDate,
  ]);

  return (
    <Fragment>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Box p={2} component={Paper} elevation={2}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography variant="h6" color="textSecondary">
                    <strong>Suggested Diet</strong>
                  </Typography>
                </Box>
                <Box>
                  <Button
                    color="primary"
                    onClick={() => setNewDiet(true)}
                    style={{ top: "-7px" }}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Update
                  </Button>
                </Box>
              </Box>

              <Box p={1}>
                {suggestedDietByDateReady ? (
                  suggestedDietByDate.length > 0 ? (
                    suggestedDietByDate.map((d, d_index) => {
                      return (
                        <Fragment key={d_index}>
                          <Box mb={2}>
                            <Box id={d.id}>
                              <Button
                                onClick={() =>
                                  setSuggestedPid(
                                    suggestedpid === d.id ? null : d.id
                                  )
                                }
                                variant="text"
                                color="primary"
                              >
                                {suggestedpid === d.id ? (
                                  <KeyboardArrowDown />
                                ) : (
                                  <KeyboardArrowRight />
                                )}

                                {Notify.createdAt(d.created_at)}
                              </Button>
                            </Box>

                            <Collapse in={suggestedpid === d.id ? true : false}>
                              <Box ml={2}>
                                {suggestedDiet.length > 0
                                  ? suggestedDiet.map((data, index) => (
                                      <Fragment key={index}>
                                        <Box
                                          hidden={
                                            Notify.createdAt(
                                              data.created_at
                                            ) === Notify.createdAt(d.created_at)
                                              ? false
                                              : true
                                          }
                                        >
                                          <Box>
                                            <Typography
                                              color="textSecondary"
                                              variant="subtitle1"
                                            >
                                              {" "}
                                              {data.meals} on{" "}
                                              {Notify.createdTime(
                                                data.created_at
                                              )}{" "}
                                            </Typography>
                                          </Box>
                                          <Box>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: data.description,
                                              }}
                                            />
                                          </Box>
                                        </Box>
                                      </Fragment>
                                    ))
                                  : "No diet found."}
                              </Box>
                            </Collapse>
                          </Box>
                        </Fragment>
                      );
                    })
                  ) : (
                    <Box mt={2}>
                      <Typography color="secondary">
                        No suggested diet
                      </Typography>
                    </Box>
                  )
                ) : (
                  Notify.loading()
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Box p={2} component={Paper} elevation={2}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography variant="h6" color="textSecondary">
                    <strong>Patient Diet</strong>
                  </Typography>
                </Box>
              </Box>

              <Box p={1} mt={2}>
                {personalDietByDateReady ? (
                  personalDietByDate.length > 0 ? (
                    personalDietByDate.map((d, d_index) => {
                      return (
                        <Fragment key={d_index}>
                          <Box mb={2}>
                            <Box
                              id={d.id}
                              onClick={() =>
                                setSelectedPid(
                                  selectedpid === d.id ? null : d.id
                                )
                              }
                            >
                              <Typography color="primary">
                                {selectedpid === d.id ? (
                                  <KeyboardArrowDown fontSize={"small"} />
                                ) : (
                                  <KeyboardArrowRight fontSize={"small"} />
                                )}

                                {Notify.createdAt(d.created_at)}
                              </Typography>
                            </Box>

                            <Collapse in={selectedpid === d.id ? true : false}>
                              <Box ml={2}>
                                {personalDiet.length > 0
                                  ? personalDiet.map((data, index) => (
                                      <Fragment key={index}>
                                        <Box
                                          hidden={
                                            Notify.createdAt(
                                              data.created_at
                                            ) === Notify.createdAt(d.created_at)
                                              ? false
                                              : true
                                          }
                                        >
                                          <Box>
                                            <Typography
                                              color="textSecondary"
                                              variant="subtitle1"
                                            >
                                              {" "}
                                              {data.meals} on{" "}
                                              {Notify.createdTime(
                                                data.created_at
                                              )}{" "}
                                            </Typography>
                                          </Box>
                                          <Box>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: data.description,
                                              }}
                                            />
                                          </Box>
                                        </Box>
                                      </Fragment>
                                    ))
                                  : "No diet found."}
                              </Box>
                            </Collapse>
                          </Box>
                        </Fragment>
                      );
                    })
                  ) : (
                    <Typography color="secondary">No personal diet</Typography>
                  )
                ) : (
                  Notify.loading()
                )}
              </Box>
            </Box>

            {/* diet form */}
            <HealthDietForm
              patient_id={patient_id}
              connection={connection}
              open={newdiet}
              close={() => setNewDiet(false)}
              getSuggestedDiet={getSuggestedDiet}
              getSuggestedDietByDate={getSuggestedDietByDate}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default HealthDiet;
