import {
  faCheckCircle,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, TextField, Typography } from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Button,
  Badge,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import Notify from "../../../notification/Notify";
import NoAppointmentDetected from "../NoAppointmentDetected";

const PrescriptionDoctor = ({
  patient_id,
  displayUnsave,
  unsaveCount,
  getUnsavePrescription,
}) => {
  const { users } = React.useContext(UsersData);
  const { tracenumber } = useParams();
  const [isProcess, setIsProcess] = useState(false);

  const addToPrecription = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    var error = [];
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.set("patient_id", patient_id);
    formdata.set("prescription_type", "doctor");
    formdata.set("pharmacy_id", "");
    formdata.set("prescription", "");

    if (
      formdata.get("product_name").length === 0 ||
      formdata.get("product_name").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("product_name");
    }

    if (
      formdata.get("order_qty").length === 0 ||
      formdata.get("order_qty").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("order qty");
    }

    if (error.length > 0) {
      Notify.consoleLog("add product");
    } else {
      setIsProcess(true);
      Axios.post("prescription/local/product-add", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            e.target.reset();
            getUnsavePrescription();
            Notify.successRequest("add product");
          } else {
            Notify.warnRequest("add product");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsProcess(false);
        });
    }
  };

  useEffect(() => {}, [patient_id]);

  return (
    <Fragment>
      {tracenumber === "tracenumber" ? (
        <NoAppointmentDetected title={"Prescription order"} />
      ) : (
        <>
          <Box mb={3}>
            <Typography variant="h6" color="textSecondary">
              Create Doctor Prescription
            </Typography>
          </Box>

          <form onSubmit={addToPrecription}>
            <Row>
              <Col sm={12}>
                <Box mb={2}>
                  <TextField
                    variant={"outlined"}
                    name="product_name"
                    label="Prescription"
                    fullWidth
                  />
                </Box>
              </Col>

              <Col sm={6}>
                <TextField
                  variant={"outlined"}
                  name="order_qty"
                  label="Quantity"
                  fullWidth
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 10000,
                      step: 1,
                    },
                  }}
                />
              </Col>
              <Col sm={6}>
                <Box className="mt-4 mt-sm-0">
                  <TextField
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth
                    name="type"
                    label="Type"
                    variant="outlined"
                  >
                    <option value=""> -- Select -- </option>
                    <option value="Bottle"> Bottle </option>
                    <option value="Box"> Box </option>
                    <option value="Can"> Can </option>
                    <option value="Capsule"> Capsule </option>
                    <option value="Liniment"> Liniment </option>
                    <option value="Sachet"> Sachet </option>
                    <option value="Suspension"> Suspension </option>
                    <option value="Powder"> Powder </option>
                    <option value="Tablet"> Tablet </option>
                  </TextField>
                </Box>
              </Col>
            </Row>

            <Row>
              {/* <Col sm={12}> 
                        <Box my={2}>
                            <TextField
                                variant={'outlined'} 
                                name="dosage"
                                label="Dosage"
                                fullWidth 
                            />
                        </Box>
                    </Col> */}
              <Col sm={4} hidden>
                <FormGroup>
                  <span className="gtc-small text-uppercase text-muted">
                    Take Every
                  </span>
                  <FormControl name="take_every" />
                </FormGroup>
              </Col>
              <Col sm={4} hidden>
                <FormGroup>
                  <span className="gtc-small text-uppercase text-muted">
                    Times
                  </span>
                  <FormControl name="take_times" />
                </FormGroup>
              </Col>
            </Row>

            <Box my={2}>
              <TextField
                variant={"outlined"}
                name="remarks"
                label="Sig."
                fullWidth
                rows={3}
                multiline
              />
            </Box>

            <p className="m-0 mb-2 text-muted">
              Are you sure to continue adding this item?
            </p>
            <Button type="submit" variant="success" disabled={isProcess}>
              <FontAwesomeIcon
                icon={isProcess ? faSpinner : faCheckCircle}
                spin={isProcess}
              />
              Add
            </Button>
            <Button type="reset" variant="secondary ml-2">
              <FontAwesomeIcon icon={faTimesCircle} /> No
            </Button>
            <Button
              type="button"
              variant="primary ml-2"
              onClick={displayUnsave}
            >
              <Badge variant="danger"> {unsaveCount} </Badge> Unsave
              Prescription
            </Button>
          </form>
        </>
      )}
    </Fragment>
  );
};

export default PrescriptionDoctor;
