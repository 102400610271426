import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { useForm } from "react-hook-form";
import Img from "src/components/Img";
import FlatIcon from "src/components/FlatIcon";
import ActionBtn from "src/components/ActionBtn";

const ListItem = ({ personnel, onRemove }) => {
	return (
		<div className="flex items-center gap-4 p-3 rounded-lg bg-foreground bg-opacity-40">
			<Img
				type={"user"}
				name={personnel?.name}
				src={personnel?.avatar}
				className="w-11 h-11 rounded-full"
			/>
			<div className="flex flex-col">
				<span className="text-sm text-primary font-semibold">
					{personnel?.name}
				</span>
				<span className="text-xs text-placeholder font-semibold">
					{personnel?.type.replace("Clinic-", "")}
				</span>
			</div>

			<div
				className="text-danger bg-red-50 flex items-center justify-center ml-auto h- rounded-md gap-2 cursor-pointer p-2"
				onClick={onRemove}
			>
				<FlatIcon icon="rr-trash" />
				Remove
			</div>
		</div>
	);
};
const ManageClinicPersonnelModal = (props, ref) => {
	const {
		staticModal,
		refreshData,
		assign_personel_ref,
		clinic_personnel,
		setClinicPersonnel,
	} = props;
	const [open, setOpen] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const btnRef = useRef(null);

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));
	const show = (showData) => {
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const save = (form) => {
		let formData = new FormData();
		formData.append("_method", "PATCH");
		clinic_personnel.map((x) => {
			formData.append("user_id[]", x.id);
		});
		axios
			.post(`v1/management/clinic-personnel/${params?.id}`, formData)
			.then((res) => {
				setTimeout(() => {
					refreshData();
					toast.success("Clinic personnel updated successfully!");
				}, 200);
				hide();
			});
	};
	return (
		<Modal
			open={open}
			hide={hide}
			className={`w-[768px] min-w-[768px] max-w-[768px]`}
			staticModal={staticModal}
		>
			<ModalHeader title={"Manage personnel"} hide={hide} />
			<ModalBody className={`!pt-6 !px-0 bg-white `}>
				<div className="flex items-center gap-4 pb-2 border-b px-6">
					<span className="mr-auto text-base font-bold">
						Assigned Personnel{" "}
					</span>
					<ActionBtn
						size="md"
						className="gap-2"
						onClick={() => {
							hide();
							assign_personel_ref.current.show(clinic_personnel);
						}}
					>
						<FlatIcon icon="rr-plus" /> Assign
					</ActionBtn>
				</div>
				<div className="max-h-[384px] overflow-auto flex flex-col gap-y-4 p-6">
					{clinic_personnel?.map((personnel) => {
						return (
							<ListItem
								personnel={personnel}
								key={`p-${personnel?.id}`}
								onRemove={() => {
									setClinicPersonnel((currentList) =>
										currentList.filter(
											(x) => x.id != personnel?.id
										)
									);
								}}
							/>
						);
					})}
					{clinic_personnel?.length == 0 && (
						<p className="text-placeholder">
							No personnel assigned yet.
						</p>
					)}
				</div>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button
					onClick={save}
					loading={loading}
					ref={btnRef}
					type="success"
				>
					Save changes
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(ManageClinicPersonnelModal);
