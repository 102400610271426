import {
  Box,
  Button,
  Card,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "src/cis/components/styles/styles";
import ButtonV3 from "src/components/ButtonV3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud } from "@fortawesome/free-solid-svg-icons";

export default function CisAddLaboratoryImaging(name) {
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    // let formData = new FormData();
    // formData.append("DateAndTime", data?.DateAndTime || "");
    // formData.append("description", data?.remarks || "");
    // formData.append("image", data?.notes || "");
    // console.log(formData);
  };
  return (
    <div className="flex w-full flex-col">
      <form>
        <div className="mx-auto" style={styles.parentContainerAdd}>
          <Card elevation={3} className="p-4" component={Box}>
            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Name</label>}
                variant="outlined"
                fullWidth
                value={name.name}
                {...register("name")}
              />
            </div>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                <div className="border-2 p-2 h-full mb-3">
                  <label htmlFor="contained-button-file">
                    {imagePreview ? (
                      <img
                        className="mx-auto"
                        src={imagePreview}
                        alt="Uploaded"
                        style={{
                          maxWidth: "70%",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      />
                    ) : (
                      <img
                        className="mx-auto"
                        src="/no-image.png"
                        alt="no image"
                        style={{
                          maxWidth: "70%",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      />
                    )}
                  </label>
                </div>
              </div>
              <div className="flex items-center">
                <label className="mx-auto" htmlFor="contained-button-file">
                  <ButtonV3 type="success">
                    <div className="flex">
                      <FontAwesomeIcon
                        icon={faCloud}
                        color="primary"
                        style={{ fontSize: 18 }}
                        className="pr-2"
                      />
                      Upload Image
                    </div>
                  </ButtonV3>
                </label>
              </div>
            </div>
          </Card>
        </div>
        <div className="mx-auto" style={styles.button}>
          <ButtonV3 onClick={handleSubmit(onSubmit)}>Submit</ButtonV3>
        </div>
      </form>
    </div>
  );
}
