import { Box, Grid, makeStyles, Tabs, Tab, Paper } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import React, { useEffect, useState } from "react";
import CisLaboratoryList from "./laboratoryList/CisLaboratoryList";
import CisSharedImages from "./sharedImages/CisSharedImages";
import CisAttachments from "./attachments/CisAttachments";

const useStyle = makeStyles({
	indicatorLeft: {
		left: "0px",
	},
});

export default function CisPatientLaboratory({
	pageIcon,
	pageTitle,
	labType,
	patient,
	patient_id,
}) {
	const classes = useStyle();
	const [category, setCategory] = useState("laboratory-list");

	const handleChange = (event, cat) => {
		event.persist();
		setCategory(cat);
	};

	useEffect(() => {}, [patient_id]);

	return (
		<Grid container spacing={1} className="p-1">
			<CisLaboratoryList
				pageIcon={pageIcon}
				pageTitle={pageTitle}
				labType={labType}
				patient={patient}
			/>
			{/* <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
				<Grid item xs={12} sm={12} md={9}>
					<TabPanel value={category} index={"laboratory-list"}>
						<CisLaboratoryList patient={patient} />
					</TabPanel>

					<TabPanel value={category} index={"shared-image"}>
						<CisSharedImages patient={patient} />
					</TabPanel>

					<TabPanel value={category} index={"doc-attachment"}>
						<CisAttachments patient={patient} />
					</TabPanel>
				</Grid>
			</Box> */}

			{/* <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
				<Grid item xs={12} sm={12} md={3}>
					<Box component={Paper} elevation={3} mb={2}>
						<Tabs
							value={category}
							onChange={handleChange}
							scrollButtons="on"
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							orientation={
								window.innerWidth > 959
									? "vertical"
									: "horizontal"
							}
							classes={{
								indicator: classes.indicatorLeft,
							}}
						>
							<Tab
								wrapped
								value="laboratory-list"
								label={"Laboratory List"}
							/>
							<Tab
								wrapped
								value="shared-image"
								label="Shared Images"
							/>
							<Tab
								wrapped
								value="doc-attachment"
								label=" Attachments"
							/>
						</Tabs>
					</Box>
				</Grid>
			</Box> */}
		</Grid>
	);
}
