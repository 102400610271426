import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { dateYYYYMMDD } from "src/helpers/utils";
import { v4 as uuidv4 } from "uuid";

const ClinicContext = createContext();

export function ClinicContextWrapper({ children }) {
	const [clinic, setClinic] = useState({ id: null });
	const [queue, setQueue] = useState({ loading: false, data: [] });

	const [registeringPatients, setRegisteringPatients] = useState([]);
	const [inServicePatients, setInServicePatients] = useState([]);
	const [donePatients, setDonePatients] = useState([]);
	const [referrals, setReferralsPatients] = useState({
		loading: false,
		data: [],
	});
	const [inServiceReferrals, setInServiceReferralsPatients] = useState({
		loading: false,
		data: [],
	});
	const [inDoneReferrals, setDoneReferralsPatients] = useState({
		loading: false,
		data: [],
	});
	const [fetchKey, setFetchKey] = useState(11111);

	const [appointments, setAppointments] = useState({
		loading: false,
		data: [],
	});

	useEffect(() => {
		getClinic();
	}, []);

	const getClinic = () => {
		axios.get(`/v1/my-clinic`).then((res) => {
			setClinic(res.data.data);
		});
	};
	useEffect(() => {
		if (clinic?.id) {
			setQueue({
				loading: true,
				data: [],
			});
			// getAppointments();
			// getQueue();
			if (window.Echo) {
				console.log("initRealTime 00 datadata ", window.Echo);
				// initRealTime();
				// fetchRegistering();
				// fetchInService();
				// fetchDoneVisits();
				// getRerrals("pending", setReferralsPatients);
				// getRerrals("in-service", setInServiceReferralsPatients);
				// getRerrals("done", setDoneReferralsPatients);
			}
		}

		return () => {
			if (window.Echo && clinic?.id) {
				console.log("initRealTime 22 leave ");
				window.Echo.leave(`clinic.${clinic?.id}`);
			}
		};
	}, [clinic.id]);

	useEffect(() => {
		console.log("fetchKey, clinic.id", fetchKey, clinic.id);
		let t = setTimeout(() => {
			if (clinic?.id) {
				fetchRegistering();
				fetchInService();
				fetchDoneVisits();
			}
		}, 400);
		return () => {
			clearTimeout(t);
		};
	}, [fetchKey, clinic.id]);
	const initRealTime = async () => {
		console.log("initRealTime 11 datadata ", window.Echo);
		await window.Echo.private(`clinic.${clinic.id}`).listen(
			".clinic.queue",
			(data) => {
				console.log("initRealTime 33 response ", data);
				// fetchRegistering();
				// fetchInService();
				// fetchDoneVisits();
				setFetchKey(uuidv4());
			}
		);
	};

	const getQueue = () => {
		axios.get(`v1/clinic-queueing/${clinic?.id}`).then((res) => {
			setTimeout(() => {
				console.log("res.data.data", res.data.data);
				setQueue({
					loading: false,
					data: res.data.data,
				});
			}, 200);
		});
	};

	const getRerrals = (status, setter) => {
		axios
			.get(
				`v1/clinic/referral-list?status=${status}&to_clinic_id=${clinic?.id}`
			)
			.then((res) => {
				setTimeout(() => {
					setter({
						loading: false,
						data: res.data.data,
					});
				}, 200);
			});
	};

	const getAppointments = () => {
		setAppointments((currentData) => ({
			...currentData,
			loading: true,
		}));
		axios
			.get(
				`v1/clinic/appointments?clinic_id=${
					clinic?.id
				}&date=${dateYYYYMMDD(new Date())}`
			)
			.then((res) => {
				setAppointments({
					loading: false,
					data: res.data.data,
				});
				console.log("getAppointments res.data.data", res.data.data);
			});
	};

	const fetchRegistering = async () => {
		try {
			await axios
				.get(`/v1/clinic-queueing/registering/list/${clinic?.id}`)
				.then((res) => {
					setRegisteringPatients(res.data.data);
				}); // clinic_id is temporarily set to 1
		} catch (error) {
			console.error("Error fetching registering patients:", error);
		}
	};

	const fetchInService = async () => {
		try {
			const response = await axios.get(
				`/v1/clinic-queueing/in-service/list/${clinic?.id}`
			); // clinic_id is temporarily set to 1
			setInServicePatients(response.data.data);
		} catch (error) {
			console.error("Error fetching in-service patients:", error);
		}
	};

	const fetchDoneVisits = async () => {
		try {
			const response = await axios.get(
				`/v1/clinic-queueing/done/list/${clinic?.id}`
			); // clinic_id is temporarily set to 1
			setDonePatients(response.data.data);
		} catch (error) {
			console.error("Error fetching in done patients:", error);
		}
	};

	return (
		<ClinicContext.Provider
			value={{
				clinic,
				setClinic,
				queue,
				setQueue,
				getQueue,
				appointments,
				setAppointments,
				getAppointments,
				fetchRegistering,
				fetchInService,
				fetchDoneVisits,
				registeringPatients,
				setRegisteringPatients,
				inServicePatients,
				setInServicePatients,
				donePatients,
				setDonePatients,

				referrals,
				setReferralsPatients,
				inServiceReferrals,
				setInServiceReferralsPatients,
				inDoneReferrals,
				setDoneReferralsPatients,
				getRerrals,
			}}
		>
			{children}
		</ClinicContext.Provider>
	);
}

export function useClinicContext() {
	return useContext(ClinicContext);
}
