import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import PhoPatientForm from "./PhoPatientForm";
import { useForm } from "react-hook-form";
import usePatientHook from "src/admission/hooks/usePatientHook";
import { TextField } from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";

const ReasonForEditModal = (props, ref) => {
	const { pho_form_ref } = props;
	const [open, setOpen] = useState(false);
	const [patient, setPatient] = useState(null);
	const [loading, setLoading] = useState(false);
	const { users } = useContext(UsersData);
	const history = useHistory();
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const { saveEditReason, getPatientInfo, getKoboToolboxEditApi } =
		usePatientHook();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		reset();
		setPatient(data);
		getPatientInfo(data?.id).then((res) => {
			setPatient(res.data.data);
		});
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};
	console.log("users", users);
	const submit = (data) => {
		setLoading(true);
		console.log("submit data", data);
		saveEditReason({
			...data,
			patient_id: patient?.patient_id,
		}).then((res) => {
			setTimeout(() => {
				setLoading(false);
				pho_form_ref.current.show(patient);
				hide();
			}, 2000);
			// getKoboToolboxEditApi(patient?.kobotools_id, patient?.form_id);
			// let url = res.data.kobotools.url;
			/* .replace(
        "return_url=false",
        `return_url=${window.location.href}`
      ); 
			window.location.href = url;*/
		});
	};
	return (
		<Modal open={open} hide={hide} size="md2" className="max-w-xl">
			<ModalHeader
				title={"Reason for editing"}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p- bg-white`}>
				<div className="w-full p-4">
					<TextField
						className="w-full"
						label={
							<>
								Reason for editing
								<b className="text-red-500 ml-1">*</b>
							</>
						}
						variant="outlined"
						{...register("remark", {
							required: true,
						})}
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						error={errors?.remark}
						helperText={errors?.remark && "This field is required"}
					/>
				</div>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button onClick={handleSubmit(submit)} loading={loading}>
					Submit
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(ReasonForEditModal);
