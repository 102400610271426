import { useEffect, useState } from "react";
import { useDiseaseTreatmentContext } from "../context/DiseaseTreatmentContext";

const HealthUnitDetails = ({ className = "" }) => {
	const { healthUnit } = useDiseaseTreatmentContext();
	const [data, setData] = useState(null);

	useEffect(() => {
		console.log("healthUnit?.id", healthUnit?.id);
		if (healthUnit?.id) setData(healthUnit);
	}, [healthUnit?.id]);
	const renderType = () => {
		switch (data?.type) {
			case "SPH":
				return "Sarangani Provincial Hospital";

			case "RHU":
				return "Rural Health Unit";

			case "BHS":
				return "Barangay Health Station";
		}
	};
	return (
		<div className={`flex flex-col ml-auto ${className}`}>
			<h3 className="text-lg font-bold text-right mb-0 w-full">
				{data?.name}
			</h3>
			<span className="text-sm capitalize text-right w-full">
				{renderType()}
			</span>
		</div>
	);
};

export default HealthUnitDetails;
