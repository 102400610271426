import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	IconButton,
	Collapse,
	Button,
	Dialog,
	DialogTitle,
	TableContainer,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DischargeForm from "./DischargeForm";
import { Printer } from "react-feather";
import PrintDischargeSlip from "./PrintDischargeSlip";

const Details = ({ details }) => {
	const mounted = IsMountedRef();
	const [isLoading, setIsLoading] = useState(true);
	const [tracenumberList, setTracenumberList] = useState([]);
	const [selectedPatient, setSelectedPatient] = useState(null);

	const [dischargedSlipDialog, setDischargeSlipDialog] = useState({
		open: false,
	});

	const [dischargedSlip, setDischargedSlip] = useState([]);
	const [printDialog, setPrintDialog] = useState({
		open: false,
		details: null,
	});

	const getPatientsTraceNumbers = React.useCallback(() => {
		axios
			.get(`billing/discharged/patient/get-patientlist-tracenumber`, {
				params: {
					patient_id: details?.patient_id,
				},
			})
			.then(({ data }) => {
				if (mounted.current) {
					setTracenumberList(data);
				}
			})
			.catch((err) => console.log("error:", err.message))
			.finally(() => setIsLoading(false));
	}, [details, mounted]);

	const getDischargedSlipList = async (c) => {
		if (selectedPatient?.id === c.id) return;

		let { data } = await axios.get(
			`cashier/discharged/patient/get-charge-slip`,
			{
				params: {
					trace_number: c?.trace_number,
					patient_id: c?.patient_id,
				},
			}
		);

		setDischargedSlip(data);
		setSelectedPatient(c);
	};

	useEffect(() => {
		getPatientsTraceNumbers();
	}, [getPatientsTraceNumbers]);

	return (
		<>
			<Box>
				<Card>
					<CardHeader title={"Transactions List"} />
					<CardContent>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell />
										<TableCell> Date </TableCell>
										<TableCell> Trace Number </TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{!isLoading ? (
										tracenumberList.length > 0 ? (
											tracenumberList.map((data, key) => (
												<React.Fragment key={key}>
													<TableRow>
														<TableCell>
															<IconButton
																aria-label="expand row"
																size="small"
																onClick={() => {
																	getDischargedSlipList(
																		data
																	);
																}}
															>
																{selectedPatient?.id ===
																data.id ? (
																	<KeyboardArrowUpIcon />
																) : (
																	<KeyboardArrowDownIcon />
																)}
															</IconButton>
														</TableCell>
														<TableCell>
															{Notify.createdAt(
																data.created_at
															)}
														</TableCell>
														<TableCell>
															{" "}
															{
																data.trace_number
															}{" "}
														</TableCell>
													</TableRow>

													{selectedPatient?.id ===
														data.id && (
														<TableRow>
															<TableCell
																colSpan={3}
															>
																<Collapse
																	in={
																		selectedPatient?.id ===
																		data.id
																	}
																	timeout="auto"
																	unmountOnExit
																>
																	<Box>
																		<Box
																			display={
																				"flex"
																			}
																			mb={
																				1
																			}
																		>
																			<Box
																				flexGrow={
																					1
																				}
																			>
																				<Typography>
																					{" "}
																					Discharge
																					Slip{" "}
																				</Typography>
																			</Box>
																			<Box>
																				<Button
																					color="primary"
																					variant="contained"
																					size="small"
																					onClick={() =>
																						setDischargeSlipDialog(
																							{
																								open: true,
																							}
																						)
																					}
																				>
																					New
																				</Button>
																			</Box>
																		</Box>

																		<Box>
																			<Table size="small">
																				<TableHead>
																					<TableRow>
																						<TableCell>
																							Date
																							Admitted
																						</TableCell>
																						<TableCell>
																							Discharged
																							Date
																						</TableCell>
																						<TableCell>
																							Discharged
																							MD
																						</TableCell>
																						<TableCell>
																							Action
																						</TableCell>
																					</TableRow>
																				</TableHead>

																				<TableBody>
																					{dischargedSlip.length >
																					0 ? (
																						dischargedSlip.map(
																							(
																								c,
																								k
																							) => (
																								<TableRow
																									key={
																										k
																									}
																								>
																									<TableCell>
																										{Notify.createdAt(
																											c.date_admitted
																										)}
																									</TableCell>
																									<TableCell>
																										{Notify.createdAt(
																											c.discharged_date
																										)}
																									</TableCell>
																									<TableCell>
																										{
																											c.discharged_md
																										}
																									</TableCell>
																									<TableCell>
																										<IconButton
																											color="primary"
																											onClick={() =>
																												setPrintDialog(
																													{
																														open: true,
																														details:
																															c,
																													}
																												)
																											}
																										>
																											<Printer />
																										</IconButton>
																									</TableCell>
																								</TableRow>
																							)
																						)
																					) : (
																						<TableRow>
																							<TableCell
																								colSpan={
																									4
																								}
																							>
																								<Typography color="secondary">
																									No
																									record
																									found.{" "}
																								</Typography>
																							</TableCell>
																						</TableRow>
																					)}
																				</TableBody>
																			</Table>
																		</Box>
																	</Box>
																</Collapse>
															</TableCell>
														</TableRow>
													)}
												</React.Fragment>
											))
										) : (
											<TableRow>
												<TableCell colSpan={3}>
													<Typography color="secondary">
														No record found.
													</Typography>
												</TableCell>
											</TableRow>
										)
									) : (
										<TableRow>
											<TableCell colSpan={3}>
												<Typography color="primary">
													Loading, please wait...
												</Typography>
											</TableCell>
										</TableRow>
									)}
									<TableRow />
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card>

				<Dialog
					open={dischargedSlipDialog.open}
					onClose={() => setDischargeSlipDialog({ open: false })}
					fullWidth
					maxWidth={"sm"}
				>
					<DialogTitle> New Discharge Slip</DialogTitle>
					<DischargeForm
						patient_id={selectedPatient?.patient_id}
						trace_number={selectedPatient?.trace_number}
						onClose={() => {
							setDischargeSlipDialog({ open: false });
							setSelectedPatient(null);
						}}
					/>
				</Dialog>

				<Dialog
					open={printDialog.open}
					onClose={() =>
						setPrintDialog({ open: false, details: null })
					}
					fullScreen
					scroll="body"
				>
					<DialogTitle> Print Discharge Slip</DialogTitle>
					{printDialog.details && (
						<PrintDischargeSlip
							details={printDialog.details}
							onClose={() =>
								setPrintDialog({ open: false, details: null })
							}
						/>
					)}
				</Dialog>
			</Box>
		</>
	);
};

export default Details;
