import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import FormHeader from "./FormHeader";
import { useReactToPrint } from "react-to-print";
import { XCircle, CheckSquare, Printer, Edit } from "react-feather";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import moment from "moment";

const FormMedicalAbstract = ({ patient_id, trace_number, info, allowEdit }) => {
  const [isProcess, setIsProcess] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const mounted = IsMountedRef();
  const printableRef = React.useRef();

  const { users } = React.useContext(UsersData);

  const [chartChiefComplaint, setChartChiefComplaint] = useState("");
  const [chartHistory, setChartHistory] = useState("");
  const [chartPE, setChartPE] = useState("");
  const [chartLaboratories, setChartLaboratories] = useState("");
  const [chartAdmittingDiagnosis, setChartAdmittingDiagnosis] = useState("");
  const [chartProcedure, setChartProcedure] = useState("");
  const [chartAttendingPhysician, setChartAttendingPhysician] = useState("");

  const [chartRd, setChartRd] = useState(false);
  const [chartType, setChartType] = useState("for-save");

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const handleMedicalAbstract = (e) => {
    e.preventDefault();
    e.persist();

    var form = new FormData(e.target);
    form.append("user_id", users.user_id);
    form.append("main_mgmt_id", users.main_mgmt_id);
    form.append("management_id", users.management_id);
    form.append("patient_id", patient_id);
    form.append("trace_number", trace_number);

    form.append("chief_complaint", chartChiefComplaint);
    form.append("history_of_present_illness", chartHistory);
    form.append("physical_examination", chartPE);
    form.append("laboratories", chartLaboratories);
    form.append("admitting_dignosis", chartAdmittingDiagnosis);
    form.append("procedure_done", chartProcedure);
    form.append("attending_physician", chartAttendingPhysician);
    form.append("chart_type", chartType);

    let err = [];

    if (form.get("chief_complaint") === null) {
      err = "error";
      Notify.fieldRequired(" chief complaint ");
    }

    if (err.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcess(true);
      axios
        .post("or/charts/patient/patient-medicalabstract", form)
        .then((response) => {
          let data = response.data;

          if (data.message === "success") {
            setEnableEdit(false);
            getMedicalAbstract();
            Notify.successRequest("chart updated");
          }
        })
        .catch((err) => console.log(err.message))
        .finally(() => setIsProcess(false));
    }
  };

  const getMedicalAbstract = React.useCallback(() => {
    axios
      .get("or/charts/patient/patient-getmedicalabstract", {
        params: {
          patient_id: patient_id,
          trace_number: trace_number,
        },
      })
      .then((response) => {
        let data = response.data;
        if (mounted.current) {
          if (mounted.current) {
            if (data.length) {
              let data = response.data[0];
              setChartType("for-update");

              generateMedicalAbstractList(data);
            }
          }
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setChartRd(true));
  }, [trace_number, patient_id, mounted]);

  const generateMedicalAbstractList = (data) => {
    setChartChiefComplaint(
      data.chief_complaint === null ? "" : data.chief_complaint
    );
    setChartHistory(
      data.history_of_present_illness === null
        ? ""
        : data.history_of_present_illness
    );
    setChartPE(
      data.physical_examination === null ? "" : data.physical_examination
    );
    setChartLaboratories(data.laboratories === null ? "" : data.laboratories);
    setChartAdmittingDiagnosis(
      data.admitting_dignosis === null ? "" : data.admitting_dignosis
    );
    setChartProcedure(data.procedure_done === null ? "" : data.procedure_done);
    setChartAttendingPhysician(
      data.attending_physician === null ? "" : data.attending_physician
    );
  };

  useEffect(() => {
    getMedicalAbstract();
  }, [getMedicalAbstract]);

  return (
    <div>
      <Card ref={printableRef}>
        <CardContent>
          <Box>
            <FormHeader />
          </Box>

          <Box my={1}>
            <Typography align="center">
              <b> MEDICAL ABSTRACT </b>
            </Typography>
          </Box>
          {chartRd ? (
            <form onSubmit={handleMedicalAbstract}>
              <Box>
                <Box my={1}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    className="d-print-none"
                    mb={1}
                  >
                    {enableEdit ? (
                      <>
                        <Box>
                          <Button
                            color="secondary"
                            onClick={() => setEnableEdit(false)}
                            startIcon={<XCircle />}
                          >
                            Close
                          </Button>
                        </Box>

                        <Box ml={2}>
                          <Button
                            color="primary"
                            startIcon={
                              isProcess ? (
                                <CircularProgress size={22} color="inherit" />
                              ) : (
                                <CheckSquare />
                              )
                            }
                            type="submit"
                            disabled={isProcess}
                          >
                            Save
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <>
                        {allowEdit && (
                          <Box mr={2}>
                            <Button
                              color="primary"
                              onClick={() => setEnableEdit(true)}
                              startIcon={<Edit />}
                            >
                              Edit
                            </Button>
                          </Box>
                        )}
                        <Box>
                          <Button
                            color="primary"
                            onClick={handlePrint}
                            startIcon={<Printer />}
                          >
                            Print
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>

                <Box my={1}>
                  <Typography>
                    Date :{" "}
                    {Notify.createdAt(
                      moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    )}
                  </Typography>
                </Box>

                <Box>
                  <Table className="table-bordered" size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Patient's Name:
                          </Typography>
                          <Typography>
                            {info
                              ? `${info.lastname}, ${info.firstname} ${
                                  info.middle === null ? "" : info.middle
                                }`
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption"> Age </Typography>
                          <Typography>
                            {info
                              ? info.birthday === null
                                ? ""
                                : Notify.calculateAge(info.birthday)
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption"> Gender </Typography>
                          <Typography>{info ? info.gender : ""}</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            {" "}
                            Date of Birth{" "}
                          </Typography>
                          <Typography>
                            {info
                              ? info.birthday === null
                                ? ""
                                : Notify.createdAt(info.birthday)
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell> </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {" "}
                            Mobile No.{" "}
                          </Typography>
                          <Typography>{info ? info.mobile : ""}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>

                <Box my={2}>
                  <Typography style={{ fontWeight: 600, fontSize: "13px" }}>
                    {" "}
                    Chief Complaint :{" "}
                  </Typography>
                  {enableEdit ? (
                    <Box>
                      <TextField
                        fullWidth
                        value={chartChiefComplaint}
                        onChange={(e) => setChartChiefComplaint(e.target.value)}
                        multiline
                      />
                    </Box>
                  ) : (
                    <Typography> {chartChiefComplaint} </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography style={{ fontWeight: 600, fontSize: "13px" }}>
                    {" "}
                    History of Present Illness :{" "}
                  </Typography>
                  {enableEdit ? (
                    <Box>
                      <TextField
                        fullWidth
                        value={chartHistory}
                        onChange={(e) => setChartHistory(e.target.value)}
                        multiline
                      />
                    </Box>
                  ) : (
                    <Typography> {chartHistory} </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography style={{ fontWeight: 600, fontSize: "13px" }}>
                    {" "}
                    Physical Examination :{" "}
                  </Typography>
                  {enableEdit ? (
                    <Box>
                      <TextField
                        fullWidth
                        value={chartPE}
                        onChange={(e) => setChartPE(e.target.value)}
                        multiline
                      />
                    </Box>
                  ) : (
                    <Typography> {chartPE} </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography style={{ fontWeight: 600, fontSize: "13px" }}>
                    {" "}
                    Laboratory/ies :{" "}
                  </Typography>
                  {enableEdit ? (
                    <Box>
                      <TextField
                        fullWidth
                        value={chartLaboratories}
                        onChange={(e) => setChartLaboratories(e.target.value)}
                        multiline
                      />
                    </Box>
                  ) : (
                    <Typography> {chartLaboratories} </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography style={{ fontWeight: 600, fontSize: "13px" }}>
                    {" "}
                    Admitting Diagnosis :{" "}
                  </Typography>
                  {enableEdit ? (
                    <Box>
                      <TextField
                        fullWidth
                        value={chartAdmittingDiagnosis}
                        onChange={(e) =>
                          setChartAdmittingDiagnosis(e.target.value)
                        }
                        multiline
                      />
                    </Box>
                  ) : (
                    <Typography> {chartAdmittingDiagnosis} </Typography>
                  )}
                </Box>

                <Box>
                  <Typography style={{ fontWeight: 600, fontSize: "13px" }}>
                    {" "}
                    Procedure Done :{" "}
                  </Typography>
                  {enableEdit ? (
                    <Box>
                      <TextField
                        fullWidth
                        value={chartProcedure}
                        onChange={(e) => setChartProcedure(e.target.value)}
                        multiline
                      />
                    </Box>
                  ) : (
                    <Typography> {chartProcedure} </Typography>
                  )}
                </Box>
              </Box>

              <Box my={3} display={"flex"} justifyContent={"flex-end"}>
                <Box align="center">
                  <Box borderBottom={1} px={1}>
                    {enableEdit ? (
                      <Box style={{ width: "calc(40vw - 200px)" }}>
                        <TextField
                          fullWidth
                          value={chartAttendingPhysician}
                          onChange={(e) =>
                            setChartAttendingPhysician(e.target.value)
                          }
                          multiline
                        />
                      </Box>
                    ) : (
                      <Box>
                        <Typography className="text-capitalize">
                          {chartAttendingPhysician}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Typography variant="caption">Attending Physician</Typography>
                </Box>
              </Box>
            </form>
          ) : (
            Notify.loading()
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default FormMedicalAbstract;
