import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  InputAdornment,
  TextField,
  TablePagination,
  CardMedia,
} from "@material-ui/core";
import { Payment, Search } from "@material-ui/icons";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import Label from "src/utils/Label";
import PayablePaymentDialog from "./PayablePaymentDialog";

const Payable = () => {
  const [list, setList] = useState({ data: [], ready: false });
  const mounted = IsMountedRef();
  const { users } = useContext(UsersData);
  const [dialog, setDialog] = useState({ data: "", open: false });

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const getPayableList = React.useCallback(async () => {
    let response = await axios.get("/accounting/payable/list", {
      params: {
        management_id: users.management_id,
        type: "unpaid",
      },
    });
    const data = response.data;
    if (mounted.current) {
      setList({ data, ready: true });
    }
  }, [users, mounted]);

  useEffect(() => {
    if (users.management_name === "BMCDC HQ") {
      getPayableList();
    }
    // eslint-disable-next-line
  }, [getPayableList]);

  const searchable = list.data.filter((data) => {
    return (
      data.invoice_number.toLowerCase().indexOf(search.trim().toLowerCase()) !==
      -1
    );
  });

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Payable",
        items: [{ name: "Dashboard", path: "/sph/app" }],
      }}
      title={"Payable Invoice"}
    >
      {list.ready ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper component={Box} my={2}>
                <Box p={2}>
                  <Box display="flex">
                    <Box flexGrow={1} mt={1}>
                      <Typography variant="h6" color="textSecondary">
                        Invoice for payment
                      </Typography>
                    </Box>
                    <Box>
                      <TextField
                        label="Search invoice number"
                        variant="outlined"
                        fullWidth
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell> Date </TableCell>
                          <TableCell> Invoice </TableCell>
                          <TableCell> Amount </TableCell>
                          <TableCell> Status </TableCell>
                          <TableCell> Action </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {searchable.length > 0 ? (
                          searchable
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((data, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {Notify.createdAt(data.created_at)}
                                </TableCell>
                                <TableCell> {data.invoice_number} </TableCell>
                                <TableCell> {data.invoice_amount} </TableCell>
                                <TableCell>
                                  <Label
                                    color={
                                      data.invoice_status === "unpaid"
                                        ? "error"
                                        : data.invoice_status === "partial"
                                        ? "warning"
                                        : "success"
                                    }
                                  >
                                    {data.invoice_status}
                                  </Label>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      setDialog({ data: data, open: true })
                                    }
                                    startIcon={<Payment />}
                                  >
                                    Payment
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={5}>
                              <Typography color="secondary">
                                No record found.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>

                    <TablePagination
                      component="div"
                      count={searchable.length}
                      rowsPerPageOptions={[10, 50, 100]}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      labelRowsPerPage="List"
                    />
                  </TableContainer>
                </Box>
              </Paper>
            </Grid>
          </Grid>

          {/* dialog payment */}
          <PayablePaymentDialog
            open={dialog.open}
            close={() => setDialog({ data: "", open: false })}
            selectedInvoice={dialog.data}
            action={() => getPayableList()}
          />
        </>
      ) : (
        <RenderSelectReceivable />
      )}
    </Container>
  );
};

export default Payable;

const RenderSelectReceivable = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 270 }}
    />

    <Typography color="secondary" variant="h3">
      HQ BRANCH MUST BE SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select HQ branch in the list to view details.
    </Typography>
  </Box>
);
