import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Trash } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const TempList = ({ list, rerender }) => {
  const { users } = useContext(UsersData);
  const [loading, setLoading] = useState(false);
  const [remove, setRemove] = useState({
    dialog: false,
    id: null,
  });

  const [process, setProcess] = useState(false);

  const handleRemoveItem = async () => {
    var formdata = new FormData();
    formdata.set("remove_id", remove.id);
    let response = await axios.post(
      "accounting/test/items/delivery-tempremove",
      formdata
    );

    if (response.data.message === "success") {
      Notify.successRequest("remove");
      rerender();
      setRemove({
        dialog: false,
        id: null,
      });
    }
  };

  const handleProcessDelivery = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.set("username", users.username);

    var error = [];

    if (formdata.get("dr_number").trim() === "") {
      error = "error";
      Notify.fieldInvalid("dr_number");
    }

    if (formdata.get("password").trim() === "") {
      error = "error";
      Notify.fieldInvalid("password");
    }

    if (error.length > 0) {
      console.log("form has an error..");
    } else {
      setLoading(true);
      let response = await axios.post(
        "accounting/test/items/delivery-tempprocess",
        formdata
      );

      if (response.data.message === "pass-invalid") {
        Notify.fieldInvalid("password");
      }

      if (response.data.message === "success") {
        Notify.successRequest("process");
        rerender();
        setProcess(false);
      }
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader title="Temp Delivery Items" />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Brand </TableCell>
                <TableCell> Description </TableCell>
                <TableCell> Supplier </TableCell>
                <TableCell> Unit </TableCell>
                <TableCell> Mfg. Date </TableCell>
                <TableCell> Exp. Date </TableCell>
                <TableCell> Batch Number </TableCell>
                <TableCell> Qty </TableCell>
                <TableCell> Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.ready ? (
                list.data.length > 0 ? (
                  list.data.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell> {data.item} </TableCell>
                      <TableCell> {data.description} </TableCell>
                      <TableCell> {data.supplier} </TableCell>
                      <TableCell> {data.unit} </TableCell>
                      <TableCell> {data.mfg_date} </TableCell>
                      <TableCell> {data.expiration_date} </TableCell>
                      <TableCell> {data.batch_number} </TableCell>
                      <TableCell align="center">{data.qty}</TableCell>
                      <TableCell>
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            setRemove({ dialog: true, id: data.temp_id })
                          }
                        >
                          <Trash />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={9}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={9}>
                    <Typography color="primary">
                      loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={9} align="right">
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => setProcess(true)}
                    disabled={list.data.length === 0}
                  >
                    Process Delivery
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      {/* remove dialog */}
      {remove.dialog && (
        <RemoveItem
          open={remove.dialog}
          close={() => setRemove({ dialog: false, id: null })}
          action={() => handleRemoveItem()}
        />
      )}

      {process && (
        <ProcessItem
          open={process}
          close={() => setProcess(false)}
          action={handleProcessDelivery}
          loading={loading}
        />
      )}
    </Card>
  );
};

export default TempList;

const RemoveItem = ({ open, close, action }) => (
  <Dialog open={open} onClose={close}>
    <DialogContent>Are you sure to remove this item?</DialogContent>
    <DialogActions>
      <Button color="default" variant="contained" onClick={close}>
        No
      </Button>
      <Button color="secondary" variant="contained" onClick={action}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

const ProcessItem = ({ open, close, action, loading }) => (
  <Dialog open={open} onClose={close} maxWidth={"xs"}>
    <DialogTitle> Process Delivery </DialogTitle>
    <form onSubmit={action}>
      <DialogContent dividers>
        <Box mb={2}>
          <TextField
            label="Delivery Number"
            name="dr_number"
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box>
          <TextField
            label="Password"
            variant="outlined"
            name="password"
            type="password"
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          color="default"
          variant="contained"
          onClick={close}
        >
          No
        </Button>
        <Button
          size="large"
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          Yes
        </Button>
      </DialogActions>
    </form>
  </Dialog>
);
