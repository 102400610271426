import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  TextField,
  TableBody,
  TableCell,
  Table,
  TableRow,
  IconButton,
} from "@material-ui/core";
import FormHeader from "./FormHeader";
import { useReactToPrint } from "react-to-print";
import { XCircle, Printer, Edit, CheckSquare, Square } from "react-feather";
import Notify from "src/notification/Notify";
import moment from "moment";
import { DatePicker, TimePicker, DateTimePicker } from "@material-ui/pickers";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";

const FormThree = ({
  patient_id,
  trace_number,
  info,
  doctorsList,
  allowEdit,
}) => {
  const printableRef = React.useRef();
  const [enableEdit, setEnableEdit] = useState(false);
  const { users } = React.useContext(UsersData);

  const [isProcess, setIsProcess] = useState(false);

  const [chartRd, setChartRd] = useState(false);
  const [chartType, setChartType] = useState("for-save");

  // chats state
  const [chartDateNow, setChartDateNow] = useState(null);
  const [chartOpPermission, setChartOpPermission] = useState("");
  const [chartOpDoctor, setChartOpDoctor] = useState("");
  const [chartOp4DoctorConsent, setChartOp4DoctorConsent] = useState("");
  const [chartOpDoctorIncharge, setChartOpDoctorIncharge] = useState("");
  const [chartOpProcedure, setChartOpProcedure] = useState("");
  const [chartOpProcedureDesc, setChartOpProcedureDesc] = useState("");
  const [chartOpProcedureCompl, setChartOpProcedureComplication] = useState("");
  const [chartOpSedationAnesthesia, setChartOpSedationAnesthesia] =
    useState("");
  const [chartOpSedationAnesthesiaDesc, setChartOpSedationAnesthesiaDesc] =
    useState("");

  const [chartOpBloodAdmin, setChartOpBloodAdmin] = useState("");

  const [chartOpPnpPro, setChartOpPnpPro] = useState("");

  const [chartHealthProfession, setChartHealthProfession] = useState("");

  const [chartOpDateTime, setChartOpDateTime] = useState(null);

  const [chartOpInterpreter, setChartOpInterpreter] = useState(null);
  const [chartOpInterpreterDateTime, setChartOpInterpreterDateTime] =
    useState(null);

  const [chartLegalRep, setChartLegalRep] = useState("");
  const [chartLegalRepAge, setChartLegalRepAge] = useState("");

  const [chartLegalRepRelation, setChartLegalRepRelation] = useState("");
  const [chartLegalRepRelationDate, setChartLegalRepRelationDate] =
    useState(null);

  const mounted = IsMountedRef();

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  // const checkSelectedDoctor = (selDoctor) => {
  //   if (doctorsList.length > 0) {
  //     let xx = doctorsList.filter((x) => x.doctors_id === selDoctor);
  //     if (xx.length > 0) {
  //       return xx[0].name;
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  const getSurgerConsent = React.useCallback(() => {
    axios
      .get("or/charts/patient/patient-getsurgeryconsentsheet", {
        params: {
          patient_id,
          trace_number,
        },
      })
      .then((response) => {
        let data = response.data;
        if (mounted.current) {
          if (data.length) {
            let data = response.data[0];
            setChartType("for-update");
            handleUpdateDefaultState(data);
          }
        }
      })
      .catch((err) => console.log("error: ", err.message))
      .finally(() => setChartRd(true));
  }, [patient_id, trace_number, mounted]);

  const handleUpdateDefaultState = (data) => {
    setChartDateNow(data.date_now);
    setChartOpPermission(
      Boolean(data.op_permission) ? "authorized" : "unauthorized"
    );
    setChartOpDoctor(data.op_doctor === null ? "" : data.op_doctor);

    setChartOp4DoctorConsent(data.doctors_id === null ? "" : data.doctors_id);
    setChartOpDoctorIncharge(
      data.op_physician_incharge === null ? "" : data.op_physician_incharge
    );
    setChartOpProcedure(data.op_procedure === null ? "" : data.op_procedure);
    setChartOpProcedureDesc(
      data.op_procedure_desc === null ? "" : data.op_procedure_desc
    );
    setChartOpProcedureComplication(
      data.op_procedure_complications === null
        ? ""
        : data.op_procedure_complications
    );
    setChartOpSedationAnesthesia(
      parseInt(data.op_anesthesia) === 1
        ? "consent"
        : parseInt(data.op_anesthesia) === 2
        ? "no-sedation"
        : "not-applicable"
    );
    setChartOpSedationAnesthesiaDesc(
      parseInt(data.op_anesthesia) === 1
        ? data.op_anesthesia_desc === null
          ? ""
          : data.op_anesthesia_desc
        : ""
    );
    setChartOpBloodAdmin(
      Boolean(data.op_blood_admin) ? "authorized" : "unauthorized"
    );

    setChartOpPnpPro(
      Boolean(data.op_under_pnp_pro) ? "voluntary" : "unvoluntary"
    );

    setChartHealthProfession(
      Boolean(data.op_health_profession) ? "agree" : "disagree"
    );

    setChartOpDateTime(data.op_physician_incharge_datetime);
    setChartOpInterpreter(
      data.op_interpreter === null ? "" : data.op_interpreter
    );
    setChartOpInterpreterDateTime(data.op_interpreter_datetime);
    setChartLegalRep(data.op_legal_rep === null ? "" : data.op_legal_rep);
    setChartLegalRepAge(
      data.op_legal_rep_age === null ? "" : data.op_legal_rep_age
    );
    setChartLegalRepRelation(
      data.op_legal_rep_relation === null ? "" : data.op_legal_rep_relation
    );
    setChartLegalRepRelationDate(data.op_legal_rep_datetime);
  };

  const handleNewConsent = (e) => {
    e.preventDefault();
    e.persist();

    var form = new FormData(e.target);
    form.set("user_id", users.user_id);
    form.set("management_id", users.management_id);
    form.set("main_mgmt_id", users.main_mgmt_id);
    form.set("patient_id", patient_id);
    form.set("doctors_id", chartOp4DoctorConsent);
    form.set("trace_number", trace_number);
    form.set(
      "date_now",
      chartDateNow !== null ? moment(chartDateNow).format("YYYY-MM-DD") : ""
    );
    form.set("op_doctor", chartOpDoctor);
    form.set("op_permission", chartOpPermission === "authorized" ? 1 : 0);
    form.set("op_procedure", chartOpProcedure);
    form.set("op_procedure_desc", chartOpProcedureDesc);
    form.set("op_procedure_complications", chartOpProcedureCompl);
    form.set(
      "op_anesthesia",
      chartOpSedationAnesthesia === "consent"
        ? 1
        : chartOpSedationAnesthesia === "no-sedation"
        ? 2
        : 0
    );
    form.set("op_anesthesia_desc", chartOpSedationAnesthesiaDesc);
    form.set("op_blood_admin", chartOpBloodAdmin === "authorized" ? 1 : 0);
    form.set("op_under_pnp_pro", chartOpPnpPro === "voluntary" ? 1 : 0);
    form.set("op_health_profession", chartHealthProfession === "agree" ? 1 : 0);

    form.set("op_physician_incharge", chartOpDoctorIncharge);
    form.set(
      "op_physician_incharge_datetime",
      chartOpDateTime !== null
        ? moment(chartOpDateTime).format("YYYY-MM-DD HH:mm:ss")
        : ""
    );

    form.set(
      "op_interpreter",
      chartOpInterpreter === null ? "" : chartOpInterpreter
    );
    form.set(
      "op_interpreter_datetime",
      chartOpInterpreterDateTime !== null
        ? moment(chartOpInterpreterDateTime).format("YYYY-MM-DD HH:mm:ss")
        : ""
    );
    form.set("op_legal_rep", chartLegalRep);
    form.set("op_legal_rep_age", chartLegalRepAge);
    form.set("op_legal_rep_relation", chartLegalRepRelation);
    form.set(
      "op_legal_rep_datetime",
      chartLegalRepRelationDate !== null
        ? moment(chartLegalRepRelationDate).format("YYYY-MM-DD HH:mm:ss")
        : ""
    );
    form.set("chart_type", chartType);

    let err = [];

    if (err.length > 0) {
    } else {
      setIsProcess(true);
      axios
        .post("or/charts/patient/patient-surgeryconsentsheet", form)
        .then((response) => {
          let data = response.data;

          if (data.message === "success") {
            setEnableEdit(false);
            getSurgerConsent();
            Notify.successRequest("Chart updated");
          }
        })
        .catch((err) => console.log(err.message))
        .finally(() => setIsProcess(false));
    }
  };

  useEffect(() => {
    getSurgerConsent();
  }, [getSurgerConsent]);

  return (
    <Card ref={printableRef}>
      <CardContent>
        <Box>
          <FormHeader />
        </Box>

        <Box my={3} align="center">
          <Typography variant="subtitle2">
            <b> INFORMED CONSENT TO MEDICAL AND SURGICAL PROCEDURE </b>
          </Typography>
          <Typography variant="caption">
            <i> Please read carefully before signing </i>
          </Typography>
        </Box>

        {chartRd ? (
          <form onSubmit={handleNewConsent}>
            <Box>
              <Box my={3}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                  mb={1}
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          color="primary"
                          startIcon={
                            isProcess ? (
                              <CircularProgress size={22} color="inherit" />
                            ) : (
                              <CheckSquare />
                            )
                          }
                          type="submit"
                          disabled={isProcess}
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            Edit
                          </Button>
                        </Box>
                      )}

                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box mb={3}>
                <Table className="table-bordered">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography variant="caption"> Patient : </Typography>
                        <Typography>
                          {info
                            ? `${info.lastname}, ${info.firstname} ${
                                info.middle === null ? "" : info.middle
                              }`
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="caption">
                          {" "}
                          Date of Birth :{" "}
                        </Typography>
                        <Typography>
                          {info
                            ? info.birthday === null
                              ? ""
                              : Notify.birthday(info.birthday)
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="caption">
                          {" "}
                          Age/Gender :{" "}
                        </Typography>
                        <Typography>
                          {info
                            ? `${Notify.calculateAge(info.birthday)} / ${
                                info.gender
                              }`
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="caption"> Date : </Typography>

                        {enableEdit ? (
                          <Box>
                            <DatePicker
                              format="dd/MM/yyyy"
                              label=""
                              views={["year", "month", "date"]}
                              value={chartDateNow}
                              onChange={(date) => setChartDateNow(date)}
                              readOnly={!enableEdit}
                              InputProps={{
                                disableUnderline: !enableEdit,
                              }}
                              autoOk
                            />
                          </Box>
                        ) : (
                          <Typography
                          // align="center"
                          >
                            {chartDateNow === null
                              ? ""
                              : Notify.createdAt(
                                  moment(chartDateNow).format("YYYY-MM-DD")
                                )}{" "}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>

              <Box my={3}>
                <Typography component={Box}>
                  1. I
                  <IconButton
                    className={"text-dark"}
                    style={{ padding: "6px" }}
                    onClick={() => setChartOpPermission("authorized")}
                  >
                    {chartOpPermission === "authorized" ? (
                      <CheckSquare size={18} />
                    ) : (
                      <Square size={18} />
                    )}
                  </IconButton>
                  <b> AUTHORIZE </b>
                  <IconButton
                    className={"text-dark"}
                    style={{ padding: "6px" }}
                    onClick={() => setChartOpPermission("unauthorized")}
                  >
                    {chartOpPermission === "unauthorized" ? (
                      <CheckSquare size={18} />
                    ) : (
                      <Square size={18} />
                    )}
                  </IconButton>
                  <b> REFUSE TO AUTHORIZE </b> Dr.(s)
                  {enableEdit ? (
                    <>
                      <TextField
                        fullWidth
                        value={chartOpDoctor}
                        onChange={(e) => setChartOpDoctor(e.target.value)}
                        multiline
                      />
                    </>
                  ) : (
                    <span className="charts-textunderlined text-capitalize">
                      {/* {checkSelectedDoctor(chartOpDoctor)} */}
                      {chartOpDoctor}
                    </span>
                  )}
                  and his resident physician(s) to perform the contemplated
                  surgical or medical procedure known as (name and/ or
                  description)
                </Typography>

                <Box>
                  {enableEdit ? (
                    <Box>
                      <TextField
                        fullWidth
                        value={chartOpProcedure}
                        onChange={(e) => setChartOpProcedure(e.target.value)}
                        multiline
                      />
                    </Box>
                  ) : (
                    <Box borderBottom={1} mt={1}>
                      <Typography> {chartOpProcedure} </Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box my={3}>
                <Typography>
                  2. I understand that this procedure is expected to (describe
                  purpose):
                </Typography>

                <Box>
                  {enableEdit ? (
                    <Box>
                      <TextField
                        fullWidth
                        value={chartOpProcedureDesc}
                        onChange={(e) =>
                          setChartOpProcedureDesc(e.target.value)
                        }
                        multiline
                      />
                    </Box>
                  ) : (
                    <Box borderBottom={1} mt={1}>
                      <Typography> {chartOpProcedureDesc} </Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box my={3}>
                <Typography>
                  3. I understand that this procedure carries with it possible
                  complications such as, but not limited to:
                </Typography>

                <Box>
                  {enableEdit ? (
                    <Box>
                      <TextField
                        fullWidth
                        value={chartOpProcedureCompl}
                        onChange={(e) =>
                          setChartOpProcedureComplication(e.target.value)
                        }
                        multiline
                      />
                    </Box>
                  ) : (
                    <Box borderBottom={1} mt={1}>
                      <Typography> {chartOpProcedureCompl} </Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box my={3}>
                <Typography>
                  4. <b>CONSENT FOR SEDATION AND ANESTHESIA:</b>
                </Typography>

                <Box pl={6}>
                  <Box mb={2}>
                    <Typography>
                      A.
                      <IconButton
                        className={"text-dark"}
                        style={{ padding: "6px" }}
                        onClick={() => setChartOpSedationAnesthesia("consent")}
                      >
                        {chartOpSedationAnesthesia === "consent" ? (
                          <CheckSquare size={18} />
                        ) : (
                          <Square size={18} />
                        )}
                      </IconButton>
                      <b> I CONSENT </b>
                      to the administration of sedation/anesthesia under the
                      direction and supervision of Dr.{" "}
                      {enableEdit ? (
                        <>
                          <TextField
                            fullWidth
                            value={chartOp4DoctorConsent}
                            onChange={(e) =>
                              setChartOp4DoctorConsent(e.target.value)
                            }
                            multiline
                          />
                        </>
                      ) : (
                        <span className="charts-textunderlined text-capitalize">
                          {chartOp4DoctorConsent}
                        </span>
                      )}
                      who may or may not be assisted by his resident physician.
                      I consent to the use of such anesthesia and other drugs as
                      may be advisable, with the exception of (none or specify).
                    </Typography>

                    <Box>
                      {enableEdit ? (
                        <Box>
                          <TextField
                            fullWidth
                            value={chartOpSedationAnesthesiaDesc}
                            onChange={(e) =>
                              setChartOpSedationAnesthesiaDesc(e.target.value)
                            }
                            multiline
                            InputProps={{
                              readOnly:
                                chartOpSedationAnesthesia === "consent"
                                  ? false
                                  : true,
                            }}
                          />
                        </Box>
                      ) : (
                        <Box borderBottom={1} mt={1}>
                          <Typography>
                            {chartOpSedationAnesthesiaDesc}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Box display="flex">
                    <Box flexGrow={1}>
                      <Typography>
                        B.
                        <IconButton
                          className={"text-dark"}
                          style={{ padding: "6px" }}
                          onClick={() =>
                            setChartOpSedationAnesthesia("no-sedation")
                          }
                        >
                          {chartOpSedationAnesthesia === "no-sedation" ? (
                            <CheckSquare size={18} />
                          ) : (
                            <Square size={18} />
                          )}
                        </IconButton>
                        <b> NO SEDATION </b>
                      </Typography>
                    </Box>

                    <Box flexGrow={1}>
                      <Typography>
                        C.
                        <IconButton
                          className={"text-dark"}
                          style={{ padding: "6px" }}
                          onClick={() =>
                            setChartOpSedationAnesthesia("not-applicable")
                          }
                        >
                          {chartOpSedationAnesthesia === "not-applicable" ? (
                            <CheckSquare size={18} />
                          ) : (
                            <Square size={18} />
                          )}
                        </IconButton>
                        <b> NOT APPLICABLE </b>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box my={3}>
                <Typography>
                  5. <b> CONSENT FOR BLOOD ADMINISTRATION </b>{" "}
                </Typography>

                <Box pl={6}>
                  <Box my={1}>
                    <Typography>
                      A.
                      <IconButton
                        className={"text-dark"}
                        style={{ padding: "6px" }}
                        onClick={() => setChartOpBloodAdmin("authorized")}
                      >
                        {chartOpBloodAdmin === "authorized" ? (
                          <CheckSquare size={18} />
                        ) : (
                          <Square size={18} />
                        )}
                      </IconButton>
                      <b> I AUTHORIZE </b>
                      the administration of blood and blood products to me as
                      deemed necessary by my physucian(s). I understand that
                      this involved risk of hepatitis, HIV and other adverese
                      reaction againts which PNP PRO-11 ASC cannot give absolute
                      asurance.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      B.
                      <IconButton
                        className={"text-dark"}
                        style={{ padding: "6px" }}
                        onClick={() => setChartOpBloodAdmin("unauthorized")}
                      >
                        {chartOpBloodAdmin === "unauthorized" ? (
                          <CheckSquare size={18} />
                        ) : (
                          <Square size={18} />
                        )}
                      </IconButton>
                      <b> I REFUSE TO AUTHORIZE </b> the use of blood or blood
                      products after having been informed of the consequence of
                      this refusal.
                    </Typography>
                  </Box>{" "}
                </Box>
              </Box>

              <Box my={3}>
                <Typography>
                  6. I recognize that during the course of the procedure,
                  unforeseen conditions may necessitate additional procedures. I
                  further, authorize and request that the above named doctors
                  perform such procedures as are, in his/her professional
                  judgement, appropriate. This authority granted shall extend to
                  remedying conditions that are not known at the time the
                  procedure begun.
                </Typography>
              </Box>

              <Box my={3}>
                <Typography>
                  7. I understand that PNP PRO-11 ASC has training and research
                  programs.
                </Typography>

                <Box pl={2}>
                  <Box my={1}>
                    <Typography>
                      <IconButton
                        className={"text-dark"}
                        style={{ padding: "6px" }}
                        onClick={() => setChartOpPnpPro("voluntary")}
                      >
                        {chartOpPnpPro === "voluntary" ? (
                          <CheckSquare size={18} />
                        ) : (
                          <Square size={18} />
                        )}
                      </IconButton>
                      <b> I VOLUNTARILY AGREE </b> to allow my medical records
                      and parts/organs removed me during this procedure to be
                      released for research and teaching purposes provided that
                      my privacy and confidentiality shall be respected.
                    </Typography>
                  </Box>

                  <Box>
                    <Typography>
                      <IconButton
                        className={"text-dark"}
                        style={{ padding: "6px" }}
                        onClick={() => setChartOpPnpPro("unvoluntary")}
                      >
                        {chartOpPnpPro === "unvoluntary" ? (
                          <CheckSquare size={18} />
                        ) : (
                          <Square size={18} />
                        )}
                      </IconButton>
                      <b> I DO NOT AGREE </b>
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <div className="gtc-page-breaker" />

              <Box my={3}>
                <Typography>
                  8. I understand that there may be health profession students,
                  residents, and other physicians who may be present during my
                  procedures.
                </Typography>

                <Box pl={2}>
                  <Box my={1}>
                    <Typography>
                      <IconButton
                        className={"text-dark"}
                        style={{ padding: "6px" }}
                        onClick={() => setChartHealthProfession("agree")}
                      >
                        {chartHealthProfession === "agree" ? (
                          <CheckSquare size={18} />
                        ) : (
                          <Square size={18} />
                        )}
                      </IconButton>
                      <b> I AGREE </b> to their presence as approved by hospital
                      and department policy.
                    </Typography>
                  </Box>

                  <Box>
                    <Typography>
                      <IconButton
                        className={"text-dark"}
                        style={{ padding: "6px" }}
                        onClick={() => setChartHealthProfession("disagree")}
                      >
                        {chartHealthProfession === "disagree" ? (
                          <CheckSquare size={18} />
                        ) : (
                          <Square size={18} />
                        )}
                      </IconButton>

                      <b> I DO NOT AGREE </b>
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box my={3}>
                <Typography>
                  <b> PHYSICIAN'S DECLARATION </b>{" "}
                </Typography>
                <Box my={1}>
                  <Typography>
                    I have explaine the procedure/operation, risks, and
                    alternatives to the patient/legal representative and have
                    answered the patient's questions, and to the best of my
                    knowledge affirm that the patient has been adequately
                    informed.
                  </Typography>
                </Box>
              </Box>

              <Box my={3} mt={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box
                      align="center"
                      mt={enableEdit && chartOpDoctorIncharge === "" ? 3.8 : 0}
                    >
                      {enableEdit ? (
                        <>
                          <TextField
                            fullWidth
                            value={chartOpDoctorIncharge}
                            onChange={(e) =>
                              setChartOpDoctorIncharge(e.target.value)
                            }
                            multiline
                          />
                        </>
                      ) : (
                        <Typography className="text-capitalize">
                          {chartOpDoctorIncharge}
                        </Typography>
                        // <span className="charts-textunderlined text-capitalize">
                        //   {chartOpDoctorIncharge}
                        // </span>
                      )}
                      <Box borderTop={1}>
                        <Typography variant="caption">
                          Signature Over Printed Name of Informing <br />
                          Physician and/or Resident-in-charge
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box align="center">
                      {enableEdit ? (
                        <Box>
                          <DatePicker
                            format="dd/MM/yyyy"
                            label=""
                            views={["year", "month", "date"]}
                            value={chartOpDateTime}
                            onChange={(date) => setChartOpDateTime(date)}
                            readOnly={!enableEdit}
                            InputProps={{
                              disableUnderline: !enableEdit,
                            }}
                            autoOk
                          />
                        </Box>
                      ) : (
                        <Typography align="center">
                          {chartOpDateTime === null
                            ? ""
                            : Notify.createdAt(
                                moment(chartOpDateTime).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              )}{" "}
                        </Typography>
                      )}
                      <Box borderTop={1}>
                        <Typography variant="caption">Date</Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box align="center">
                      {enableEdit ? (
                        <Box>
                          <TimePicker
                            autoOk
                            label=""
                            value={chartOpDateTime}
                            onChange={(date) => setChartOpDateTime(date)}
                            readOnly={!enableEdit}
                            InputProps={{
                              disableUnderline: !enableEdit,
                            }}
                          />
                        </Box>
                      ) : (
                        <Box>
                          <Typography align="center">
                            {chartOpDateTime === null
                              ? ""
                              : Notify.createdTime(
                                  moment(chartOpDateTime).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )}{" "}
                          </Typography>
                        </Box>
                      )}

                      <Box borderTop={1}>
                        <Typography variant="caption"> Time </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box my={3} mt={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box align="center">
                      {enableEdit ? (
                        <Box>
                          <TextField
                            fullWidth
                            value={chartOpInterpreter}
                            onChange={(e) =>
                              setChartOpInterpreter(e.target.value)
                            }
                          />
                        </Box>
                      ) : (
                        <Typography className="text-capitalize">
                          {chartOpInterpreter}
                        </Typography>
                      )}
                      <Box borderTop={1}>
                        <Typography variant="caption">
                          Interpreter's Signature Over Printed Name
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box align="center">
                      {enableEdit ? (
                        <Box>
                          <DatePicker
                            format="dd/MM/yyyy"
                            label=""
                            views={["year", "month", "date"]}
                            value={chartOpInterpreterDateTime}
                            onChange={(date) =>
                              setChartOpInterpreterDateTime(date)
                            }
                            readOnly={!enableEdit}
                            InputProps={{
                              disableUnderline: !enableEdit,
                            }}
                            autoOk
                          />
                        </Box>
                      ) : (
                        <Typography align="center">
                          {chartOpInterpreterDateTime === null
                            ? ""
                            : Notify.createdAt(
                                moment(chartOpInterpreterDateTime).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              )}{" "}
                        </Typography>
                      )}

                      <Box borderTop={1}>
                        <Typography variant="caption"> Date </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box align="center">
                      {enableEdit ? (
                        <Box>
                          <TimePicker
                            autoOk
                            label=""
                            value={chartOpInterpreterDateTime}
                            onChange={(date) =>
                              setChartOpInterpreterDateTime(date)
                            }
                            readOnly={!enableEdit}
                            InputProps={{
                              disableUnderline: !enableEdit,
                            }}
                          />
                        </Box>
                      ) : (
                        <Typography align="center">
                          {chartOpInterpreterDateTime === null
                            ? ""
                            : Notify.createdTime(
                                moment(chartOpInterpreterDateTime).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              )}{" "}
                        </Typography>
                      )}

                      <Box borderTop={1}>
                        <Typography variant="caption"> Time </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box my={3}>
                <Typography>
                  I hereby state that I have read and understand this consent
                  and that all blanks were filled out prior to my signature
                </Typography>
              </Box>

              <Box my={5}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Box display="flex">
                      <Typography> Patient / Legal Representative :</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box align="center">
                      <Box>
                        {enableEdit ? (
                          <Box>
                            <TextField
                              fullWidth
                              value={chartLegalRep}
                              onChange={(e) => setChartLegalRep(e.target.value)}
                              multiline
                            />
                          </Box>
                        ) : (
                          <Typography> {chartLegalRep} </Typography>
                        )}
                      </Box>
                      <Box borderTop={1}>
                        <Typography variant="caption">
                          Signature over printed name
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box align="center">
                      <Box>
                        {enableEdit ? (
                          <Box>
                            <TextField
                              fullWidth
                              value={chartLegalRepAge}
                              onChange={(e) =>
                                setChartLegalRepAge(e.target.value)
                              }
                              multiline
                            />
                          </Box>
                        ) : (
                          <Typography> {chartLegalRepAge} </Typography>
                        )}
                      </Box>

                      <Box borderTop={1}>
                        <Typography variant="caption"> Age </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box my={5}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Box display="flex">
                      <Typography>
                        {" "}
                        Relationship (if not the patient):
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box align="center">
                      <Box>
                        {enableEdit ? (
                          <Box>
                            <TextField
                              fullWidth
                              value={chartLegalRepRelation}
                              onChange={(e) =>
                                setChartLegalRepRelation(e.target.value)
                              }
                              multiline
                            />
                          </Box>
                        ) : (
                          <Typography> {chartLegalRepRelation} </Typography>
                        )}
                      </Box>

                      <Box borderTop={1}></Box>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box align="center">
                      <Box>
                        {enableEdit ? (
                          <Box>
                            <DateTimePicker
                              label=""
                              value={chartLegalRepRelationDate}
                              onChange={(date) =>
                                setChartLegalRepRelationDate(date)
                              }
                              readOnly={!enableEdit}
                              InputProps={{
                                disableUnderline: !enableEdit,
                              }}
                              autoOk
                            />
                          </Box>
                        ) : (
                          <Typography>
                            {chartLegalRepRelationDate === null
                              ? ""
                              : Notify.dateTimeConvert(
                                  moment(chartLegalRepRelationDate).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )}
                          </Typography>
                        )}
                      </Box>

                      <Box borderTop={1}>
                        <Typography variant="caption"> Date/Time </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        ) : (
          Notify.loading()
        )}
      </CardContent>
    </Card>
  );
};

export default FormThree;
