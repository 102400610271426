import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import React from "react";
import DateTimeClock from "src/clinic/components/DateTimeClock";
import LayoutContainer from "src/components/LayoutContainer";
import CisLaboratoryImaging from "./components/services/imaging/CisLaboratoryImaging";

export default function CisServicesImaging() {
  return (
    <LayoutContainer
      icon={<FontAwesomeIcon icon={faImage} color="white" size={"2x"} />}
      title={<h2 className="text-2xl font-bold mb-0">Imaging</h2>}
      subtitle={""}
      titleChildren={
        <div className="ml-auto flex items-center ">
          <div className="px-4 mr-auto">
            <DateTimeClock />
          </div>
        </div>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={12}>
          <CisLaboratoryImaging />
        </Grid>
      </Grid>
    </LayoutContainer>
  );
}
