import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Box,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { Formik } from "formik";
import React, { useContext } from "react";
import { CheckSquare, XCircle } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import * as Yup from "yup";

const CreateRoom = ({ onClose, getAllRoom }) => {
  const { users } = useContext(UsersData);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <>
      <Formik
        initialValues={{
          management_id: users.management_id,
        }}
        validationSchema={Yup.object().shape({
          room_name: Yup.string().trim().required("Room name is required."),
          no_of_rooms: Yup.string().trim().required("No of room is required."),
          type: Yup.string().trim().required("Room type is required."),
        })}
        onSubmit={async (
          values,
          { setErrors, setSubmitting, resetForm, setFieldValue }
        ) => {
          try {
            const request = await axios.post(
              "accounting/room/new-room",
              getFormData(values)
            );

            if (request.data.message === "success") {
              Notify.successRequest("room");
              resetForm();
              onClose();
              getAllRoom();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box>
                <TextField
                  error={Boolean(touched.room_name && errors.room_name)}
                  helperText={touched.room_name && errors.room_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.room_name}
                  fullWidth
                  required
                  name="room_name"
                  label="Room Name"
                  variant="outlined"
                  margin="normal"
                />
              </Box>

              <Box>
                <TextField
                  error={Boolean(touched.no_of_rooms && errors.no_of_rooms)}
                  helperText={touched.no_of_rooms && errors.no_of_rooms}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.no_of_rooms}
                  fullWidth
                  required
                  name="no_of_rooms"
                  label="No. of room"
                  variant="outlined"
                  margin="normal"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                />
              </Box>

              <Box>
                <TextField
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.type}
                  fullWidth
                  required
                  name="type"
                  label=" Room Type"
                  variant="outlined"
                  margin="normal"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value=""> Select </option>
                  <option value={"ward"}> Ward </option>
                  <option value={"private"}> Private </option>
                  <option value={"semi-private"}> Semi-Private </option>
                  <option value={"vip"}> VIP </option>
                </TextField>
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckSquare />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>

              <Button
                variant="contained"
                color="default"
                onClick={() => onClose()}
                startIcon={<XCircle />}
              >
                No
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </>
  );
};

export default CreateRoom;
