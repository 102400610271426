import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  CardContent,
  CardActions,
  CircularProgress,
  Button,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";

const MiscellaneousEditForm = ({ info, close }) => {
  const { users } = useContext(UsersData);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", info.data.trace_number);
    formdata.set("order_id", info.data.order_id);
    setResultSubmitting(true);
    axios
      .post("laboratory/miscellaneous/edit-result", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("order result edit successful");
          close();
          setSavebtnDisabled(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setResultSubmitting(false));
  };

  return (
    <>
      <CardContent>
        <Fragment>
          <form onSubmit={handleSaveResult}>
            {info.ready && (
              <>
                <Box hidden={!Boolean(parseInt(info.data.miscellaneous_test))}>
                  <Box mb={2} display="flex">
                    <Box flexGrow={1}>
                      <Typography>
                        <b> MISCELLANEOUS </b>
                      </Typography>
                    </Box>
                  </Box>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle2">
                              SPICEMEN :
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box>
                              <TextField
                                margin="dense"
                                fullWidth
                                name="speciment"
                                defaultValue={
                                  info.data.speciment === null
                                    ? ""
                                    : info.data.speciment
                                }
                              />
                            </Box>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle2">TEST :</Typography>
                          </TableCell>
                          <TableCell>
                            <Box>
                              <TextField
                                margin="dense"
                                fullWidth
                                name="test"
                                defaultValue={
                                  info.data.test === null ? "" : info.data.test
                                }
                              />
                            </Box>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle2">
                              RESULT :
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box>
                              <TextField
                                margin="dense"
                                fullWidth
                                name="result"
                                defaultValue={
                                  info.data.result === null
                                    ? ""
                                    : info.data.result
                                }
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box
                  mb={2}
                  hidden={!Boolean(parseInt(info.data.pregnancy_test_urine))}
                >
                  <Box mb={2} display="flex">
                    <Box flexGrow={1}>
                      <Typography>
                        <b> PREGNANCY TEST (URINE) </b>
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <TextField
                      fullWidth
                      label="Result"
                      name="pregnancy_test_urine_result"
                      variant="outlined"
                      multiline
                      defaultValue={
                        info.data.pregnancy_test_urine_result === null
                          ? ""
                          : info.data.pregnancy_test_urine_result
                      }
                    />
                  </Box>
                </Box>

                <Box
                  mb={2}
                  hidden={!Boolean(parseInt(info.data.pregnancy_test_serum))}
                >
                  <Box mb={2} display="flex">
                    <Box flexGrow={1}>
                      <Typography>
                        <b> PREGNANCY TEST (SERUM) </b>
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <TextField
                      fullWidth
                      label="Result"
                      name="pregnancy_test_serum_result"
                      variant="outlined"
                      multiline
                      defaultValue={
                        info.data.pregnancy_test_serum_result === null
                          ? ""
                          : info.data.pregnancy_test_serum_result
                      }
                    />
                  </Box>
                </Box>

                <Box
                  mb={2}
                  hidden={!Boolean(parseInt(info.data.papsmear_test))}
                >
                  <Box mb={2} display="flex">
                    <Box flexGrow={1}>
                      <Typography>
                        <b> PAPSMEAR TEST </b>
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <TextField
                      fullWidth
                      label="Result"
                      name="papsmear_test_result"
                      variant="outlined"
                      multiline
                      defaultValue={
                        info.data.papsmear_test_result === null
                          ? ""
                          : info.data.papsmear_test_result
                      }
                    />
                  </Box>
                </Box>

                <Box
                  mb={2}
                  hidden={
                    !Boolean(parseInt(info.data.papsmear_test_with_gramstain))
                  }
                >
                  <Box mb={2} display="flex">
                    <Box flexGrow={1}>
                      <Typography>
                        <b> PAPSMEAR WITH GRAMSTAIN TEST </b>
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <TextField
                      fullWidth
                      label="Result"
                      name="papsmear_test_with_gramstain_result"
                      variant="outlined"
                      multiline
                      defaultValue={
                        info.data.papsmear_test_with_gramstain_result === null
                          ? ""
                          : info.data.papsmear_test_with_gramstain_result
                      }
                    />
                  </Box>
                </Box>
              </>
            )}

            <Box display="flex">
              <Box flexGrow={1} />
              <Box>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={savebtnDisabled}
                    startIcon={
                      resultSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Save Result
                  </Button>
                </CardActions>
              </Box>
            </Box>
          </form>
        </Fragment>
      </CardContent>
    </>
  );
};

export default MiscellaneousEditForm;
