import React, { useEffect, Fragment, useContext, useState } from "react";
import Axios from "axios";
import Notify from "src/notification/Notify";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "src/utils/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faDatabase,
	faHeartbeat,
	faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import VanPatientDetailsHeader from "./VanPatientDetailsHeader";
import VanPatientProfile from "./VanPatientProfile";
import VanPatientVitals from "./VanPatientVitals";
import VanPatientCreateOrder from "./VanPatientCreateOrder";
import { UsersData } from "src/ContextAPI";

const VanPatientDetails = ({
	selectedBranch,
	accreditedCompany,
	patient_id,
	getDoctorsPatient,
}) => {
	const { users } = useContext(UsersData);
	const [category, setCategory] = React.useState(0);
	const [info, setInfo] = React.useState({
		data: null,
		ready: false,
	});
	const [branchList, setBranchList] = useState({ data: [], ready: false });

	const getPatientInformation = () => {
		setInfo({ data: [], ready: false });
		var params = { patient_id: patient_id };
		Axios.get("van/patient/patient-information", { params })
			.then((response) => {
				const data = response.data;
				setInfo({
					data: data,
					ready: true,
				});
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getAllBranches = React.useCallback(async () => {
		var params = {
			management_id: users.management_id,
			main_management_id: users.main_mgmt_id,
		};
		try {
			let response = await Axios.get("hr/get/all-branches", { params });
			const data = response.data;
			setBranchList({ data, ready: true });
		} catch (error) {
			console.log("error: ", error);
		}
	}, [users]);

	const handleChange = (event, newCategory) => {
		event.persist();
		setCategory(newCategory);
	};

	useEffect(() => {
		getPatientInformation();

		getAllBranches();
		// eslint-disable-next-line
	}, [patient_id]);

	return (
		<>
			{info.ready ? (
				<Fragment>
					<VanPatientDetailsHeader patient_id={patient_id} info={info} />

					<Box mt={2} m={0} p={0}>
						<Box component={Paper} elevation={1} mb={2}>
							<Tabs
								value={category}
								onChange={handleChange}
								indicatorColor="primary"
								textColor="primary"
								variant="scrollable"
								scrollButtons="on"
							>
								<Tab
									wrapped
									label="Profile"
									icon={<FontAwesomeIcon icon={faUserShield} size={"2x"} />}
								/>

								<Tab
									wrapped
									label="Vitals"
									icon={<FontAwesomeIcon icon={faHeartbeat} size={"2x"} />}
								/>

								<Tab
									wrapped
									label="Order"
									icon={<FontAwesomeIcon icon={faDatabase} size={"2x"} />}
								/>
							</Tabs>
						</Box>

						<TabPanel value={category} index={0}>
							<VanPatientProfile
								accreditedCompany={accreditedCompany}
								patient_id={patient_id}
								getDoctorsPatient={() => getDoctorsPatient()}
								getPatientInformation={getPatientInformation}
								info={info}
							/>
						</TabPanel>

						<TabPanel value={category} index={1}>
							<VanPatientVitals
								accreditedCompany={accreditedCompany}
								patient_id={patient_id}
								getDoctorsPatient={() => getDoctorsPatient()}
								getPatientInformation={getPatientInformation}
								info={info}
							/>
						</TabPanel>

						<TabPanel value={category} index={2}>
							<VanPatientCreateOrder
								selectedBranch={selectedBranch}
								branchList={branchList}
								getAllBranches={() => getAllBranches()}
								accreditedCompany={accreditedCompany}
								patient_id={patient_id}
								getDoctorsPatient={() => getDoctorsPatient()}
								getPatientInformation={getPatientInformation}
								info={info}
							/>
						</TabPanel>
					</Box>
				</Fragment>
			) : (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					height={200}
				>
					{Notify.loading()}
				</Box>
			)}
		</>
	);
};

export default VanPatientDetails;
