import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import {
  Card,
  //   CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  Dialog,
  Box,
  Zoom,
  //   Divider,
  //   TextField,
  //   Button,
  //   Menu,
  //   MenuItem,
} from "@material-ui/core";
import { Print } from "@material-ui/icons";
import ImagingPrintOrder from "src/imaging/ImagingPrintOrder";

const ImagingTestListByPatient = () => {
  const { patient_id, management_id } = useParams();
  const [patientList, setPatientList] = useState({ data: [], ready: false });
  const [printOpen, setPrintOpen] = useState(false);
  const [selectedImagingId, setSelectedImagingId] = useState(null);

  const [formHeader, setFormHeader] = useState({
    data: [],
    ready: false,
  });

  const getAllPatientTestList = useCallback(() => {
    var params = { patient_id, management_id };
    axios
      .get("van/get/all/patient/record/imaging/for-print-van", { params })
      .then((response) => {
        const data = response.data;
        setPatientList({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [patient_id, management_id]);

  const getImagingFormHeader = () => {
    var params = { management_id };
    axios
      .get("imaging/order/formheader-details", { params })
      .then((response) => {
        const data = response.data;
        setFormHeader(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getAllPatientTestList();
    getImagingFormHeader();
    // eslint-disable-next-line
  }, [getAllPatientTestList]);

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Imaging Record For Print",
          items: [{ name: "Dashboard", path: "/sph/app" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Imaging Record For Print</Box>
          </Box>
        }
      >
        <Card>
          <CardContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> Date </TableCell>
                    <TableCell> Patient </TableCell>
                    <TableCell> Order </TableCell>
                    <TableCell align="center"> Amount </TableCell>
                    <TableCell align="center"> Action </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patientList.ready ? (
                    patientList.data.length > 0 ? (
                      patientList.data.map((data, index) => (
                        <TableRow key={index}>
                          {/* <TableCell>
                            {Notify.createdAt(data.created_at)}
                          </TableCell>
                          <TableCell>
                            {data.lastname}, {data.firstname}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                            //   onClick={(e) => handleSelectedAction(e, data)}
                            >
                              <Print fontSize="small" />
                            </IconButton>
                          </TableCell> */}

                          <TableCell>
                            {Notify.dateTimeConvert(data.created_at)}
                          </TableCell>
                          <TableCell>
                            {`${data.lastname}, ${data.firstname}`}
                          </TableCell>
                          <TableCell>
                            {data.imaging_order}{" "}
                            {data.orderDescPackage !== null &&
                              `(${data.orderDescPackage})`}
                          </TableCell>
                          <TableCell align="right">
                            {Notify.convertToNumber(
                              data.imagingCostPackage === null
                                ? data.imagingCostReg
                                : data.imagingCostPackage
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Box>
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    setSelectedImagingId(
                                      data.imaging_center_id
                                    );
                                    setPrintOpen(true);
                                  }}
                                >
                                  <Print />
                                </IconButton>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Typography color="secondary">
                            No result to print.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography color="primary">
                          Loading, please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>

        <Dialog
          open={printOpen}
          onClose={() => setPrintOpen(false)}
          TransitionComponent={Zoom}
          transitionDuration={900}
          fullScreen
        >
          <ImagingPrintOrder
            selectedImagingId={selectedImagingId}
            formheader={formHeader}
            close={() => {
              setPrintOpen(false);
            }}
          />
        </Dialog>
      </Container>
    </>
  );
};

export default ImagingTestListByPatient;
