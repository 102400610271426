import React, { useEffect, useMemo, useRef, useState } from "react";
import {
	faArrowLeft,
	faEdit,
	faEye,
	faPencilAlt,
	faPlus,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
	formatDate,
	formatDateTime,
	formatDateYYYYMMDD,
} from "src/helpers/utils";
import CisAddLaboratoryList from "./CisAddLaboratoryList";
import { styles } from "../../styles/styles";
import { Box, Button, Card } from "@material-ui/core";
import ButtonV3 from "src/components/ButtonV3";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import Notify from "src/notification/Notify";
import { v4 as uuidv4 } from "uuid";
import PreviewImageModal from "../../PreviewImageModal";
import axios from "axios";

export default function CisLaboratoryList({
	pageIcon,
	pageTitle,
	labType = "laboratory-test",
	patient,
}) {
	const previewImageRef = useRef(null);
	const [shown, setShown] = useState(true);
	const [testTypes, setTestTypes] = useState([]);
	// const data = [
	// 	{
	// 		id: 123,
	// 		date: "12/23/2022 ",
	// 		time: "06:30 am",
	// 		image: "/mona.jpg",
	// 		laboratoryName: "Laboratory Name1",
	// 	},
	// 	{
	// 		id: 456,
	// 		date: "12/28/2022",
	// 		time: "03:30 am",
	// 		image: "/icons/female.png",
	// 		laboratoryName: "Laboratory Name2",
	// 	},
	// 	{
	// 		id: 789,
	// 		date: "12/31/2022 ",
	// 		time: "09:30 am",
	// 		image: "/icons/male.png",
	// 		laboratoryName: "Laboratory Name3",
	// 	},
	// ];

	let patientId = patient?.id;

	const {
		data,
		loading: dataLoading,
		setFilters,
	} = useDataTable(`v1/laboratory/results/list`, null, {
		patient_id: patientId,
		laboratory_type: labType,
		laboratory_order_type: ["sputum", "rt-pcr"],
	});
	useEffect(() => {
		let t = setTimeout(() => {
			getLabtests();
		}, 300);
		return () => {
			clearTimeout(t);
		};
	}, []);
	const refreshData = () => {
		setFilters((pevFils) => ({
			...setFilters,
			patient_id: patientId,
			laboratory_type: labType,
			key: uuidv4(),
		}));
	};
	const columns = useMemo(
		() => [
			{
				header: "Laboratory Test Name",
				accessorKey: "laboratory_order_type",
				headerClassName: "text-center",
				className: "text-left",
				cell: (data) => {
					return (
						<div className="flex flex-col">
							<span className="text-sm text-black font-semibold">
								{data?.relationships?.laboratoryTest?.name}
							</span>
							<span className="text-xs text-slate-500 font-light max-w-[256px] whitespace-wrap">
								{
									data?.relationships?.laboratoryTest
										?.description
								}
							</span>
						</div>
					);
				},
			},
			{
				header: "Attachment",
				accessorKey: "image",
				headerClassName: "text-center",
				className: "text-left",
				cell: (data) => {
					return (
						<img
							src={data?.image}
							key={`img-${data?.image}`}
							className="h-20 w-20 object-contain bg-slate-400 cursor-pointer"
							onClick={() => {
								previewImageRef.current.show({
									image: data?.image,
								});
							}}
						/>
					);
				},
			},
			{
				header: "Date",
				accessorKey: "created_at",
				headerClassName: "text-center",
				className: "text-left",
				cell: (data) => {
					return formatDateTime(new Date(data?.created_at));
				},
			},
		],
		[]
	);
	const getLabtests = () => {
		axios.get(`v1/laboratory/tests/list?type=${labType}`).then((res) => {
			setTestTypes(res.data.data);
		});
	};
	return (
		<div className="flex w-full flex-col pr-2">
			<div className="flex flex-col w-full mb-3">
				<h5 className="text-sm font-semibold text-indigo-700 w-2/3 flex items-center gap-2">
					<span>
						<img src={pageIcon} className="h-8 object-contain" />
					</span>
					<div>{pageTitle}</div>
					{shown ? (
						<ActionBtn
							size="sm"
							className="text-sm px-2 ml-"
							type="success"
							onClick={() => {
								setShown(false);
							}}
						>
							<FlatIcon icon="rr-plus" /> Create
						</ActionBtn>
					) : (
						<ActionBtn
							size="sm"
							className="text-sm px-2 ml-"
							type="primary"
							onClick={() => {
								setShown(true);
							}}
						>
							<FlatIcon icon="rr-arrow-left" /> back
						</ActionBtn>
					)}
				</h5>
				<div className="w-2/3 h-[1px] bg-indigo-600 mb-[2px]"></div>
				<div className="w-1/2 h-[1px] bg-indigo-400"></div>
			</div>

			{shown ? (
				<>
					{!dataLoading ? (
						<TableV2
							paginationClassName="px-4"
							columns={columns}
							pagination={true}
							loading={dataLoading}
							data={data?.data}
							onTableChange={(data) => {}}
						/>
					) : (
						Notify.loading()
					)}
				</>
			) : (
				<CisAddLaboratoryList
					labType={labType}
					typeOptions={testTypes?.map((type) => {
						return {
							value: type?.id,
							label: type?.name,
							description: type?.description,
						};
					})}
					refreshData={refreshData}
					patient={patient}
					setShown={setShown}
				/>
			)}
			<PreviewImageModal ref={previewImageRef} />
		</div>
	);
}
