import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
  Divider,
} from "@material-ui/core";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  CheckBoxOutlineBlankOutlined,
} from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import FormHeader from "./FormHeader";
import { CheckSquare, Edit, Printer, XCircle } from "react-feather";
import axios from "axios";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import FormNine from "./FormNine";
import FormTen from "./FormTen";

const FormEight = ({ patient_id, details, allowEdit, info, doctorsList }) => {
  const printableRef = React.useRef();
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const { users } = useContext(UsersData);
  const [existingOperativeChart, setExistingOperativeChart] = useState({
    data: [],
    ready: false,
  });
  const [dateAdmitted, setDateAdmitted] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  const [surgicalDateYear, setSurgicalDateYear] = useState(null);
  const [dateTimeOfVisit, setDateTimeOfVisit] = useState(null);
  const [orDate, setOrDate] = useState(null);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const [checkList, setCheckList] = useState({
    case_class_clean: false,
    case_class_dirty: false,
    case_elective: false,
    case_stat: false,
    consent_sign: "",

    //psychosocial status
    psychosocial_calm: false,
    psychosocial_apprehensive: false,
    psychosocial_restless: false,
    psychosocial_crying: false,
    psychosocial_others: false,

    //level of consciousness
    level_alert: false,
    level_asleep: false,
    level_drowsy: false,
    level_unresponsive: false,
    level_sedated: false,
    level_others: false,

    //pattern of coping adaptation
    pattern_communicative: false,
    pattern_quiet: false,
    pattern_others: false,
    //understanding
    patient: "",
    family: "",

    //
    pre_op_visit: "",
    //or
    patient_verbal: false,
    patient_chart: false,
    patient_name_band: false,

    or_calm: false,
    or_apprehensive: false,
    or_restless: false,
    or_crying: false,
    or_talkative: false,
    or_others_current: false,

    or_alert: false,
    or_asleep: false,
    or_drowsy: false,
    or_unresponsive: false,
    or_sedated: false,
    or_others_level: false,

    or_consent_verified: false,
    or_npo_verified: false,
    or_jewelry_verified: false,
    or_nail_verified: false,
    or_undergarments_verified: false,
    or_others_verified: false,

    or_none_devices: false,
    or_ngt_devices: false,
    or_et_devices: false,
    or_ivf_devices: false,

    or_bt_devices: false,
    or_urine_devices: false,
    or_others_devices: false,
  });

  const getPeriOperativeChart = () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/perioperative", { params })
      .then((response) => {
        const data = response.data;
        setExistingOperativeChart({ data, ready: true });
        if (data.length > 0) {
          setDateAdmitted(data[0].date_admitted);
          setDateTime(data[0].date_time.replace(" ", "T"));
          setSurgicalDateYear(data[0].surgical_date_year);
          setDateTimeOfVisit(data[0].date_time_of_visit.replace(" ", "T"));

          setOrDate(data[0].or_date);
          setCheckList({
            ...checkList,
            case_class_clean:
              parseInt(data[0].case_class_clean) === 1 ? true : false,
            case_class_dirty:
              parseInt(data[0].case_class_dirty) === 1 ? true : false,
            case_elective: parseInt(data[0].case_elective) === 1 ? true : false,
            case_stat: parseInt(data[0].case_stat) === 1 ? true : false,
            consent_sign:
              parseInt(data[0].case_stat) === 1
                ? true
                : parseInt(data[0].case_stat) === 0
                ? false
                : "",
            psychosocial_calm:
              parseInt(data[0].psychosocial_calm) === 1 ? true : false,
            psychosocial_apprehensive:
              parseInt(data[0].psychosocial_apprehensive) === 1 ? true : false,
            psychosocial_restless:
              parseInt(data[0].psychosocial_restless) === 1 ? true : false,
            psychosocial_crying:
              parseInt(data[0].psychosocial_crying) === 1 ? true : false,
            psychosocial_others:
              parseInt(data[0].psychosocial_others) === 1 ? true : false,
            level_alert: parseInt(data[0].level_alert) === 1 ? true : false,
            level_asleep: parseInt(data[0].level_asleep) === 1 ? true : false,
            level_drowsy: parseInt(data[0].level_drowsy) === 1 ? true : false,
            level_unresponsive:
              parseInt(data[0].level_unresponsive) === 1 ? true : false,
            level_sedated: parseInt(data[0].level_sedated) === 1 ? true : false,
            level_others: parseInt(data[0].level_others) === 1 ? true : false,
            pattern_communicative:
              parseInt(data[0].pattern_communicative) === 1 ? true : false,
            pattern_quiet: parseInt(data[0].pattern_quiet) === 1 ? true : false,
            pattern_others:
              parseInt(data[0].pattern_others) === 1 ? true : false,
            patient:
              parseInt(data[0].pattern_adatation_surgical_patient) === 1
                ? true
                : parseInt(data[0].pattern_adatation_surgical_patient) === 0
                ? false
                : "",
            family:
              parseInt(data[0].pattern_adatation_surgical_family) === 1
                ? true
                : parseInt(data[0].pattern_adatation_surgical_family) === 0
                ? false
                : "",
            pre_op_visit:
              parseInt(data[0].pre_op_visit) === 1
                ? true
                : parseInt(data[0].pre_op_visit) === 0
                ? false
                : "",
            patient_verbal:
              parseInt(data[0].patient_verbal) === 1 ? true : false,
            patient_chart: parseInt(data[0].patient_chart) === 1 ? true : false,
            patient_name_band:
              parseInt(data[0].patient_name_band) === 1 ? true : false,
            or_calm: parseInt(data[0].or_calm) === 1 ? true : false,
            or_apprehensive:
              parseInt(data[0].or_apprehensive) === 1 ? true : false,
            or_restless: parseInt(data[0].or_restless) === 1 ? true : false,
            or_crying: parseInt(data[0].or_crying) === 1 ? true : false,
            or_talkative: parseInt(data[0].or_talkative) === 1 ? true : false,
            or_others_current:
              parseInt(data[0].or_others_current) === 1 ? true : false,
            or_alert: parseInt(data[0].or_alert) === 1 ? true : false,
            or_asleep: parseInt(data[0].or_asleep) === 1 ? true : false,
            or_drowsy: parseInt(data[0].or_drowsy) === 1 ? true : false,
            or_unresponsive:
              parseInt(data[0].or_unresponsive) === 1 ? true : false,
            or_sedated: parseInt(data[0].or_sedated) === 1 ? true : false,
            or_others_level:
              parseInt(data[0].or_others_level) === 1 ? true : false,
            or_consent_verified:
              parseInt(data[0].or_consent_verified) === 1 ? true : false,
            or_npo_verified:
              parseInt(data[0].or_npo_verified) === 1 ? true : false,
            or_jewelry_verified:
              parseInt(data[0].or_jewelry_verified) === 1 ? true : false,
            or_nail_verified:
              parseInt(data[0].or_nail_verified) === 1 ? true : false,
            or_undergarments_verified:
              parseInt(data[0].or_undergarments_verified) === 1 ? true : false,
            or_others_verified:
              parseInt(data[0].or_others_verified) === 1 ? true : false,
            or_none_devices:
              parseInt(data[0].or_none_devices) === 1 ? true : false,
            or_ngt_devices:
              parseInt(data[0].or_ngt_devices) === 1 ? true : false,
            or_et_devices: parseInt(data[0].or_et_devices) === 1 ? true : false,
            or_ivf_devices:
              parseInt(data[0].or_ivf_devices) === 1 ? true : false,
            or_bt_devices: parseInt(data[0].or_bt_devices) === 1 ? true : false,
            or_urine_devices:
              parseInt(data[0].or_urine_devices) === 1 ? true : false,
            or_others_devices:
              parseInt(data[0].or_others_devices) === 1 ? true : false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSubmitPeriOperativeChart = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", details.trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    formdata.set("case_class_clean", checkList.case_class_clean ? 1 : 0);
    formdata.set("case_class_dirty", checkList.case_class_dirty ? 1 : 0);
    formdata.set("case_elective", checkList.case_elective ? 1 : 0);
    formdata.set("case_stat", checkList.case_stat ? 1 : 0);
    formdata.set(
      "consent_sign",
      checkList.consent_sign ? 1 : checkList.consent_sign === false ? 0 : ""
    );

    formdata.set("psychosocial_calm", checkList.psychosocial_calm ? 1 : 0);
    formdata.set(
      "psychosocial_apprehensive",
      checkList.psychosocial_apprehensive ? 1 : 0
    );
    formdata.set(
      "psychosocial_restless",
      checkList.psychosocial_restless ? 1 : 0
    );
    formdata.set("psychosocial_crying", checkList.psychosocial_crying ? 1 : 0);
    formdata.set("psychosocial_others", checkList.psychosocial_others ? 1 : 0);

    formdata.set("level_alert", checkList.level_alert ? 1 : 0);
    formdata.set("level_asleep", checkList.level_asleep ? 1 : 0);
    formdata.set("level_drowsy", checkList.level_drowsy ? 1 : 0);
    formdata.set("level_unresponsive", checkList.level_unresponsive ? 1 : 0);
    formdata.set("level_sedated", checkList.level_sedated ? 1 : 0);
    formdata.set("level_others", checkList.level_others ? 1 : 0);

    formdata.set(
      "pattern_communicative",
      checkList.pattern_communicative ? 1 : 0
    );
    formdata.set("pattern_quiet", checkList.pattern_quiet ? 1 : 0);
    formdata.set("pattern_others", checkList.pattern_others ? 1 : 0);
    formdata.set(
      "pattern_adatation_surgical_patient",
      checkList.patient ? 1 : checkList.patient === false ? 0 : ""
    );
    formdata.set(
      "pattern_adatation_surgical_family",
      checkList.family ? 1 : checkList.family === false ? 0 : ""
    );

    formdata.set(
      "pre_op_visit",
      checkList.pre_op_visit ? 1 : checkList.pre_op_visit === false ? 0 : ""
    );

    formdata.set(
      "patient_verbal",
      checkList.patient_verbal ? 1 : checkList.patient_verbal === false ? 0 : ""
    );
    formdata.set(
      "patient_chart",
      checkList.patient_chart ? 1 : checkList.patient_chart === false ? 0 : ""
    );
    formdata.set(
      "patient_name_band",
      checkList.patient_name_band
        ? 1
        : checkList.patient_name_band === false
        ? 0
        : ""
    );

    formdata.set(
      "or_calm",
      checkList.or_calm ? 1 : checkList.or_calm === false ? 0 : ""
    );
    formdata.set(
      "or_apprehensive",
      checkList.or_apprehensive
        ? 1
        : checkList.or_apprehensive === false
        ? 0
        : ""
    );
    formdata.set(
      "or_restless",
      checkList.or_restless ? 1 : checkList.or_restless === false ? 0 : ""
    );
    formdata.set(
      "or_crying",
      checkList.or_crying ? 1 : checkList.or_crying === false ? 0 : ""
    );
    formdata.set(
      "or_talkative",
      checkList.or_talkative ? 1 : checkList.or_talkative === false ? 0 : ""
    );

    formdata.set(
      "or_others_current",
      checkList.or_others_current
        ? 1
        : checkList.or_others_current === false
        ? 0
        : ""
    );

    formdata.set(
      "or_alert",
      checkList.or_alert ? 1 : checkList.or_alert === false ? 0 : ""
    );
    formdata.set(
      "or_asleep",
      checkList.or_asleep ? 1 : checkList.or_asleep === false ? 0 : ""
    );
    formdata.set(
      "or_drowsy",
      checkList.or_drowsy ? 1 : checkList.or_drowsy === false ? 0 : ""
    );
    formdata.set(
      "or_unresponsive",
      checkList.or_unresponsive
        ? 1
        : checkList.or_unresponsive === false
        ? 0
        : ""
    );
    formdata.set(
      "or_sedated",
      checkList.or_sedated ? 1 : checkList.or_sedated === false ? 0 : ""
    );
    formdata.set(
      "or_others_level",
      checkList.or_others_level
        ? 1
        : checkList.or_others_level === false
        ? 0
        : ""
    );

    formdata.set(
      "or_consent_verified",
      checkList.or_consent_verified
        ? 1
        : checkList.or_consent_verified === false
        ? 0
        : ""
    );
    formdata.set(
      "or_npo_verified",
      checkList.or_npo_verified
        ? 1
        : checkList.or_npo_verified === false
        ? 0
        : ""
    );
    formdata.set(
      "or_jewelry_verified",
      checkList.or_jewelry_verified
        ? 1
        : checkList.or_jewelry_verified === false
        ? 0
        : ""
    );
    formdata.set(
      "or_nail_verified",
      checkList.or_nail_verified
        ? 1
        : checkList.or_nail_verified === false
        ? 0
        : ""
    );
    formdata.set(
      "or_undergarments_verified",
      checkList.or_undergarments_verified
        ? 1
        : checkList.or_undergarments_verified === false
        ? 0
        : ""
    );
    formdata.set(
      "or_others_verified",
      checkList.or_others_verified
        ? 1
        : checkList.or_others_verified === false
        ? 0
        : ""
    );

    formdata.set(
      "or_none_devices",
      checkList.or_none_devices
        ? 1
        : checkList.or_none_devices === false
        ? 0
        : ""
    );
    formdata.set(
      "or_ngt_devices",
      checkList.or_ngt_devices ? 1 : checkList.or_ngt_devices === false ? 0 : ""
    );
    formdata.set(
      "or_et_devices",
      checkList.or_et_devices ? 1 : checkList.or_et_devices === false ? 0 : ""
    );
    formdata.set(
      "or_ivf_devices",
      checkList.or_ivf_devices ? 1 : checkList.or_ivf_devices === false ? 0 : ""
    );
    formdata.set(
      "or_bt_devices",
      checkList.or_bt_devices ? 1 : checkList.or_bt_devices === false ? 0 : ""
    );
    formdata.set(
      "or_urine_devices",
      checkList.or_urine_devices
        ? 1
        : checkList.or_urine_devices === false
        ? 0
        : ""
    );
    formdata.set(
      "or_others_devices",
      checkList.or_others_devices
        ? 1
        : checkList.or_others_devices === false
        ? 0
        : ""
    );

    setIsProcess(true);
    axios
      .post("or/update/chart/perioperative", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          getPeriOperativeChart();
          setEnableEdit(!enableEdit);
          Notify.successRequest("");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  useEffect(() => {
    getPeriOperativeChart();

    // eslint-disable-next-line
  }, [patient_id, details]);

  return (
    <Box>
      <Card ref={printableRef}>
        <Box m={2}>
          <Box>
            <FormHeader />
          </Box>
          <Box>
            <form onSubmit={handleSubmitPeriOperativeChart}>
              <Box my={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          disabled={isProcess}
                          color="primary"
                          startIcon={<CheckSquare />}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            Edit
                          </Button>
                        </Box>
                      )}

                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box align="center" mb={2}>
                <Typography>
                  <b> PERIOPERATIVE FORM </b>
                </Typography>
              </Box>

              {existingOperativeChart.ready && (
                <Box>
                  <Table size="small" className="table-bordered">
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Name:
                              </span>{" "}
                              {!enableEdit &&
                                (info
                                  ? `${info.lastname}, ${info.firstname} ${
                                      info.middle === null ? "" : info.middle
                                    }`
                                  : "")}
                            </Typography>

                            {enableEdit && (
                              <>
                                <TextField
                                  fullWidth
                                  name="name"
                                  defaultValue={
                                    info
                                      ? `${info.lastname}, ${info.firstname} ${
                                          info.middle === null
                                            ? ""
                                            : info.middle
                                        }`
                                      : ""
                                  }
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    readOnly: true,
                                  }}
                                />
                                <TextField
                                  fullWidth
                                  name="ccpf_id"
                                  defaultValue={
                                    existingOperativeChart.data.length > 0
                                      ? existingOperativeChart.data[0].ccpf_id
                                      : ""
                                  }
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  hidden
                                />
                              </>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Age/Sex/Status:
                              </span>{" "}
                              {!enableEdit &&
                                (info
                                  ? `${
                                      info.birthday === null
                                        ? ""
                                        : Notify.calculateAge(info.birthday)
                                    } / ${info.gender} / ${info.civil_status}`
                                  : "")}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="age_sex_status"
                                defaultValue={
                                  info
                                    ? `${
                                        info.birthday === null
                                          ? ""
                                          : Notify.calculateAge(info.birthday)
                                      } / ${info.gender} / ${info.civil_status}`
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  // disabled: !enableEdit,
                                  readOnly: true,
                                }}
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Address:
                              </span>{" "}
                              {!enableEdit &&
                                (info
                                  ? `${info.street}, ${info.barangay}, ${info.city}`
                                  : "")}
                              {/* {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].address
                                  : ""
                                : null} */}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="address"
                                defaultValue={
                                  info
                                    ? `${info.street}, ${info.barangay}, ${info.city}`
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  // disabled: !enableEdit,
                                  readOnly: true,
                                }}
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={4}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Date Admitted:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0]
                                      .date_admitted !== null
                                    ? Notify.createdAt(
                                        existingOperativeChart.data[0]
                                          .date_admitted
                                      )
                                    : ""
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="date_admitted"
                                type="date"
                                value={
                                  dateAdmitted !== null ? dateAdmitted : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                onChange={(e) => {
                                  setDateAdmitted(e.target.value);
                                }}
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={4}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Religion:
                              </span>{" "}
                              {!enableEdit && (info ? info.religion : "")}
                              {/* {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].religion
                                  : ""
                                : null} */}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="religion"
                                defaultValue={info ? info.religion : ""}
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  // disabled: !enableEdit,
                                  readOnly: true,
                                }}
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Pre-oprative Diagnosis:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].pre_operative
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="pre_operative"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0]
                                        .pre_operative
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Proposed Surgery:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0]
                                      .proposed_surgery
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="proposed_surgery"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0]
                                        .proposed_surgery
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Date/Time:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].date_time !==
                                    null
                                    ? `${Notify.createdAt(
                                        existingOperativeChart.data[0].date_time
                                      )} ${Notify.createdTime(
                                        existingOperativeChart.data[0].date_time
                                      )}`
                                    : ""
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="date_time"
                                type="datetime-local"
                                value={dateTime !== null ? dateTime : ""}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                onChange={(e) => {
                                  setDateTime(e.target.value);
                                }}
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={6}>
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="subtitle2"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Case Classification :
                            </Typography>
                            <Box hidden={enableEdit} display="flex">
                              <Box>
                                {existingOperativeChart.data.length > 0 ? (
                                  Boolean(
                                    parseInt(
                                      existingOperativeChart.data[0]
                                        .case_class_clean
                                    )
                                  ) ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                                Clean
                              </Box>
                              <Box>
                                {existingOperativeChart.data.length > 0 ? (
                                  Boolean(
                                    parseInt(
                                      existingOperativeChart.data[0]
                                        .case_class_dirty
                                    )
                                  ) ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                                Dirty
                              </Box>
                            </Box>
                            <Box hidden={!enableEdit} display="flex">
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        case_class_clean:
                                          !checkList.case_class_clean,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.case_class_clean === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Clean
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        case_class_dirty:
                                          !checkList.case_class_dirty,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.case_class_dirty === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Dirty
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="subtitle2"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Case :
                            </Typography>
                            <Box hidden={enableEdit} display="flex">
                              <Box>
                                {existingOperativeChart.data.length > 0 ? (
                                  Boolean(
                                    parseInt(
                                      existingOperativeChart.data[0]
                                        .case_elective
                                    )
                                  ) ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                                Elective
                              </Box>
                              <Box>
                                {existingOperativeChart.data.length > 0 ? (
                                  Boolean(
                                    parseInt(
                                      existingOperativeChart.data[0].case_stat
                                    )
                                  ) ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                                Stat
                              </Box>
                            </Box>
                            <Box hidden={!enableEdit} display="flex">
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        case_elective: !checkList.case_elective,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.case_elective === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Elective
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        case_stat: !checkList.case_stat,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.case_stat === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Stat
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell colSpan={6}>
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="subtitle2"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Consent Signed :
                            </Typography>
                            <Box hidden={enableEdit} display="flex">
                              <Box>
                                {existingOperativeChart.data.length > 0 &&
                                  (parseInt(
                                    existingOperativeChart.data[0].consent_sign
                                  ) === 1 ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  ))}
                                Yes
                              </Box>
                              <Box>
                                {existingOperativeChart.data.length > 0 &&
                                  (parseInt(
                                    existingOperativeChart.data[0].consent_sign
                                  ) === 0 ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  ))}
                                No
                              </Box>
                            </Box>
                            <Box hidden={!enableEdit} display="flex">
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        consent_sign: true,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.consent_sign === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Yes
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        consent_sign: false,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.consent_sign === false ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={9}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Sugical History:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0]
                                      .surgical_history
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="surgical_history"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0]
                                        .surgical_history
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Date/Year:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0]
                                      .surgical_date_year !== null
                                    ? Notify.createdAt(
                                        existingOperativeChart.data[0]
                                          .surgical_date_year
                                      )
                                    : ""
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="surgical_date_year"
                                type="date"
                                value={
                                  surgicalDateYear !== null
                                    ? surgicalDateYear
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                onChange={(e) => {
                                  setSurgicalDateYear(e.target.value);
                                }}
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Height:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].height
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="height"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].height
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Weight:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].weight
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="weight"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].weight
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Smoker:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].smoker
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="smoker"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].smoker
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Renal:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].renal
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="renal"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].renal
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Alcoholic:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].alcoholic
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="alcoholic"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].alcoholic
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Neuro:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].neuro
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="neuro"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].neuro
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Respiratory:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].respiratory
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="respiratory"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].respiratory
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Cancer:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].cancer
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="cancer"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].cancer
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Hematology:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].hematology
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="hematology"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].hematology
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Cardiac:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].cardiac
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="cardiac"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].cardiac
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Hypertension:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].hypertension
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="hypertension"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0]
                                        .hypertension
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Hepatic:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].hepatic
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="hepatic"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].hepatic
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Ortho restriction:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0]
                                      .ortho_restriction
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="ortho_restriction"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0]
                                        .ortho_restriction
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={6}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Diabetes:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].diabetes
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="diabetes"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].diabetes
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={12}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Others:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0]
                                      .others_medications
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="others_medications"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0]
                                        .others_medications
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Box mt={2} mb={2} px={3}>
                    <Grid container spacing={1}>
                      <Grid xs={4} item component={Box}>
                        <Typography
                          style={{
                            fontWeight: "bolder",
                          }}
                        >
                          PSYCHOSOCIAL STATUS
                        </Typography>

                        <Box hidden={enableEdit}>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .psychosocial_calm
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Calm/Relaxed
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .psychosocial_apprehensive
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Apprehensive/Anxious
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .psychosocial_restless
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Restless
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .psychosocial_crying
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Crying
                          </Box>

                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .psychosocial_others
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Others:
                          </Box>
                        </Box>

                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    psychosocial_calm:
                                      !checkList.psychosocial_calm,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.psychosocial_calm === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Calm/Relaxed
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    psychosocial_apprehensive:
                                      !checkList.psychosocial_apprehensive,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.psychosocial_apprehensive ===
                                true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Apprehensive/Anxious
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    psychosocial_restless:
                                      !checkList.psychosocial_restless,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.psychosocial_restless === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Restless
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    psychosocial_crying:
                                      !checkList.psychosocial_crying,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.psychosocial_crying === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Crying
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    psychosocial_others:
                                      !checkList.psychosocial_others,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.psychosocial_others === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Others
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid xs={4} item component={Box}>
                        <Typography style={{ fontWeight: "bolder" }}>
                          LEVEL OF CONSCIOUSNESS
                        </Typography>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].level_alert
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Alert/Oriented
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].level_asleep
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Asleep
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].level_drowsy
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Drowsy
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .level_unresponsive
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Unresponsive
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].level_sedated
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Sedated
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].level_others
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Others
                          </Box>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    level_alert: !checkList.level_alert,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.level_alert === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Alert/Oriented
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    level_asleep: !checkList.level_asleep,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.level_asleep === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Asleep
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    level_drowsy: !checkList.level_drowsy,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.level_drowsy === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Drowsy
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    level_unresponsive:
                                      !checkList.level_unresponsive,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.level_unresponsive === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Unresponsive
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    level_sedated: !checkList.level_sedated,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.level_sedated === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Sedated
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    level_others: !checkList.level_others,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.level_others === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Others
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid xs={4} item component={Box}>
                        <Typography style={{ fontWeight: "bolder" }}>
                          PATTERN OF COPING ADAPTATION
                        </Typography>
                        <Box hidden={enableEdit}>
                          <Box display="flex">
                            <Box>
                              {existingOperativeChart.data.length > 0 ? (
                                Boolean(
                                  parseInt(
                                    existingOperativeChart.data[0]
                                      .pattern_communicative
                                  )
                                ) ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )
                              ) : (
                                <CheckBoxOutlineBlank />
                              )}
                              Communicative
                            </Box>
                            <Box>
                              {existingOperativeChart.data.length > 0 ? (
                                Boolean(
                                  parseInt(
                                    existingOperativeChart.data[0]
                                      .pattern_others
                                  )
                                ) ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )
                              ) : (
                                <CheckBoxOutlineBlank />
                              )}
                              Others
                            </Box>
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].pattern_quiet
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Quiet/Withdrawn
                          </Box>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: "bolder" }}
                          >
                            Understanding of Surgical Event:
                          </Typography>
                          <Box display="flex">
                            <Box mr={2}>
                              <Typography
                                variant="subtitle2"
                                style={{ fontWeight: "bolder" }}
                              >
                                Patient:
                              </Typography>
                            </Box>

                            <Box>
                              {existingOperativeChart.data.length > 0 &&
                                (parseInt(
                                  existingOperativeChart.data[0]
                                    .pattern_adatation_surgical_patient
                                ) === 1 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                ))}
                              Yes
                            </Box>
                            <Box>
                              {existingOperativeChart.data.length > 0 &&
                                (parseInt(
                                  existingOperativeChart.data[0]
                                    .pattern_adatation_surgical_patient
                                ) === 0 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                ))}
                              No
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mr={2.5}>
                              <Typography
                                variant="subtitle2"
                                style={{ fontWeight: "bolder" }}
                              >
                                Family:
                              </Typography>
                            </Box>

                            <Box>
                              {existingOperativeChart.data.length > 0 &&
                                (parseInt(
                                  existingOperativeChart.data[0]
                                    .pattern_adatation_surgical_family
                                ) === 1 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                ))}
                              Yes
                            </Box>
                            <Box>
                              {existingOperativeChart.data.length > 0 &&
                                (parseInt(
                                  existingOperativeChart.data[0]
                                    .pattern_adatation_surgical_family
                                ) === 0 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                ))}
                              No
                            </Box>
                          </Box>

                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Comments:
                            </span>{" "}
                            {!enableEdit
                              ? existingOperativeChart.data.length > 0
                                ? existingOperativeChart.data[0].comments
                                : ""
                              : null}
                          </Typography>
                        </Box>

                        <Box hidden={!enableEdit}>
                          <Box display="flex">
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      pattern_communicative:
                                        !checkList.pattern_communicative,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.pattern_communicative === true ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                Communicative
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      pattern_others: !checkList.pattern_others,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.pattern_others === true ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                Others
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    pattern_quiet: !checkList.pattern_quiet,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.pattern_quiet === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Quiet/Withdrawn
                            </Typography>
                          </Box>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: "bolder" }}
                          >
                            Understanding of Surgical Event:
                          </Typography>
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="subtitle2"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Patient :
                            </Typography>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      patient: true,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.patient === true ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                Yes
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      patient: false,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.patient === false ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                No
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="subtitle2"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Family :
                            </Typography>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      family: true,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.family === true ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                Yes
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      family: false,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.family === false ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                No
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Comments:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].comments
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="comments"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].comments
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="subtitle2"
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            Pre-po Visit:
                          </Typography>
                          <Box hidden={enableEdit} display="flex">
                            <Box>
                              {existingOperativeChart.data.length > 0 &&
                                (parseInt(
                                  existingOperativeChart.data[0].pre_op_visit
                                ) === 1 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                ))}
                              Yes
                            </Box>
                            <Box>
                              {existingOperativeChart.data.length > 0 &&
                                (parseInt(
                                  existingOperativeChart.data[0].pre_op_visit
                                ) === 0 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                ))}
                              No
                            </Box>
                          </Box>
                          <Box hidden={!enableEdit} display="flex">
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      pre_op_visit: true,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.pre_op_visit === true ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                Yes
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      pre_op_visit: false,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.pre_op_visit === false ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                No
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Pre-op NOD:
                            </span>{" "}
                            {!enableEdit
                              ? existingOperativeChart.data.length > 0
                                ? existingOperativeChart.data[0]
                                    .pre_op_visit_nod
                                : ""
                              : null}
                          </Typography>
                          {enableEdit && (
                            <TextField
                              fullWidth
                              name="pre_op_visit_nod"
                              defaultValue={
                                existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0]
                                      .pre_op_visit_nod
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Date/Time of Visit:
                            </span>{" "}
                            {!enableEdit
                              ? existingOperativeChart.data.length > 0
                                ? existingOperativeChart.data[0]
                                    .date_time_of_visit !== null
                                  ? `${Notify.createdAt(
                                      existingOperativeChart.data[0]
                                        .date_time_of_visit
                                    )} ${Notify.createdTime(
                                      existingOperativeChart.data[0]
                                        .date_time_of_visit
                                    )}`
                                  : ""
                                : ""
                              : null}
                          </Typography>
                          {enableEdit && (
                            <TextField
                              name="date_time_of_visit"
                              type="datetime-local"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={
                                dateTimeOfVisit !== null ? dateTimeOfVisit : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              onChange={(e) => {
                                setDateTimeOfVisit(e.target.value);
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider light />

                  <Box mt={2} mb={2} px={3}>
                    <Box align="center">
                      <Typography style={{ fontWeight: "bolder" }}>
                        O.R PREOPERATIVE ASSESSMENT
                      </Typography>
                    </Box>
                    <Grid container spacing={1}>
                      <Grid xs={12} item>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Date:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0]
                                      .date_time_of_visit !== null
                                    ? Notify.createdAt(
                                        existingOperativeChart.data[0].or_date
                                      )
                                    : ""
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="or_date"
                                type="date"
                                value={orDate !== null ? orDate : ""}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                onChange={(e) => {
                                  setOrDate(e.target.value);
                                }}
                              />
                            )}
                          </Box>
                          <Box mx={3}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                O.R Suite No.:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].or_suite_no
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="or_suite_no"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].or_suite_no
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            )}
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Box mr={2}>
                              <Typography
                                variant="subtitle2"
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Patient Identified:
                              </Typography>
                            </Box>

                            <Box hidden={enableEdit} display="flex">
                              <Box>
                                {existingOperativeChart.data.length > 0 ? (
                                  Boolean(
                                    parseInt(
                                      existingOperativeChart.data[0]
                                        .patient_verbal
                                    )
                                  ) ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                                Verbal
                              </Box>
                              <Box>
                                {existingOperativeChart.data.length > 0 ? (
                                  Boolean(
                                    parseInt(
                                      existingOperativeChart.data[0]
                                        .patient_chart
                                    )
                                  ) ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                                Chart
                              </Box>
                              <Box>
                                {existingOperativeChart.data.length > 0 ? (
                                  Boolean(
                                    parseInt(
                                      existingOperativeChart.data[0]
                                        .patient_name_band
                                    )
                                  ) ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                                Name Band
                              </Box>
                            </Box>
                            <Box hidden={!enableEdit} display="flex">
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        patient_verbal:
                                          !checkList.patient_verbal,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.patient_verbal === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Verbal
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        patient_chart: !checkList.patient_chart,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.patient_chart === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Chart
                                </Typography>
                              </Box>

                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        patient_name_band:
                                          !checkList.patient_name_band,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.patient_name_band === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Name Band
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid xs={3} item>
                        <Typography
                          variant={"subtitle2"}
                          style={{ fontWeight: "bolder" }}
                        >
                          CURRENT BEHAVIOR
                        </Typography>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(existingOperativeChart.data[0].or_calm)
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Calm/Relaxed
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_apprehensive
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Apprehesive/Anxious
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_restless
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Restless
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_crying
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Crying
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_talkative
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Talkative
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .or_others_current
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Others
                          </Box>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_calm: !checkList.or_calm,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_calm === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Calm/Relaxed
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_apprehensive: !checkList.or_apprehensive,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_apprehensive === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Apprehensive/Anxious
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_restless: !checkList.or_restless,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_restless === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Restless
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_crying: !checkList.or_crying,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_crying === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Crying
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_talkative: !checkList.or_talkative,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_talkative === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Talkative
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_others_current:
                                      !checkList.or_others_current,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_others_current === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Others:
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid xs={3} item>
                        <Typography
                          variant={"subtitle2"}
                          style={{ fontWeight: "bolder" }}
                        >
                          LEVEL OF CONSCIOUSNESS
                        </Typography>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_alert
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Alert/Oriented
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_asleep
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Asleep
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_drowsy
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Drowsy
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_unresponsive
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Unresponsive
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_sedated
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Sedated
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_others_level
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Others
                          </Box>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_alert: !checkList.or_alert,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_alert === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Alert/Oriented
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_asleep: !checkList.or_asleep,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_asleep === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Asleep
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_drowsy: !checkList.or_drowsy,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_drowsy === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Drowsy
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_unresponsive: !checkList.or_unresponsive,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_unresponsive === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Unresponsive
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_sedated: !checkList.or_sedated,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_sedated === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Sedated
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_others_level: !checkList.or_others_level,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_others_level === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Others
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid xs={3} item>
                        <Typography
                          variant={"subtitle2"}
                          style={{ fontWeight: "bolder" }}
                        >
                          VERIFIED THE FF:
                        </Typography>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .or_consent_verified
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Consent
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_npo_verified
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            NPO
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .or_jewelry_verified
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Jewelry/Dentures
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .or_nail_verified
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Nail Polish
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .or_undergarments_verified
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Undergarments
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .or_others_verified
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Others
                          </Box>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_consent_verified:
                                      !checkList.or_consent_verified,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_consent_verified === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Consent
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_npo_verified: !checkList.or_npo_verified,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_npo_verified === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              NPO
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_jewelry_verified:
                                      !checkList.or_jewelry_verified,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_jewelry_verified === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Jewelry/Dentures
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_nail_verified:
                                      !checkList.or_nail_verified,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_nail_verified === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Nail Polish
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_undergarments_verified:
                                      !checkList.or_undergarments_verified,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_undergarments_verified ===
                                true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Undergarments
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_others_verified:
                                      !checkList.or_others_verified,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_others_verified === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Others:
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid xs={3} item>
                        <Typography
                          variant={"subtitle2"}
                          style={{ fontWeight: "bolder" }}
                        >
                          DEVICES PRESENT
                        </Typography>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_none_devices
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            None
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_ngt_devices
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            NGT
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_et_devices
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            ET
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_ivf_devices
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            IVF
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Site:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].site
                                  : ""
                                : null}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Fluid:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].level
                                  : ""
                                : null}
                            </Typography>
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0].or_bt_devices
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            BT: Type: SN:
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .or_urine_devices
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Urine Catheter
                          </Box>
                          <Box>
                            {existingOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingOperativeChart.data[0]
                                    .or_others_devices
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Others
                          </Box>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_none_devices: !checkList.or_none_devices,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_none_devices === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              None
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_ngt_devices: !checkList.or_ngt_devices,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_ngt_devices === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              NGT
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_et_devices: !checkList.or_et_devices,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_et_devices === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              ET
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_ivf_devices: !checkList.or_ivf_devices,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_ivf_devices === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              IVF
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Site:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].site
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="site"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].site
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            )}
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Fluid:
                              </span>{" "}
                              {!enableEdit
                                ? existingOperativeChart.data.length > 0
                                  ? existingOperativeChart.data[0].level
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="level"
                                defaultValue={
                                  existingOperativeChart.data.length > 0
                                    ? existingOperativeChart.data[0].level
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            )}
                          </Box>

                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_bt_devices: !checkList.or_bt_devices,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_bt_devices === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              BT
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_urine_devices:
                                      !checkList.or_urine_devices,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_urine_devices === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Urine Catheter
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    or_others_devices:
                                      !checkList.or_others_devices,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.or_others_devices === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Others:
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </form>
          </Box>
        </Box>

        <div className="gtc-page-breaker" />

        <Box>
          <FormNine
            patient_id={patient_id}
            details={details}
            allowEdit={allowEdit}
          />
        </Box>

        <div className="gtc-page-breaker" />

        <Box>
          <FormTen
            patient_id={patient_id}
            details={details}
            allowEdit={allowEdit}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default FormEight;
