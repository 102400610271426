import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import { faHouseUser, faUserInjured } from "@fortawesome/free-solid-svg-icons";
import PatientProfileModal from "../admission/components/PatientProfileModal";
import AddPatientFormModal from "../admission/components/AddPatientFormModal";
import axios from "axios";
import ViewPatientProfile from "../admission/components/ViewPatientProfile";
import { Fade } from "react-reveal";
import Img from "src/components/Img";
import PhoPatientFormModal from "./components/PhoPatientFormModal";
import PhoPatientProfileModal from "./components/PhoPatientProfileModal";
import PhoViewPatientProfile from "./components/PhoViewPatientProfile";
import { Link } from "react-router-dom";
import { replaceFname, replaceLname, replaceMname } from "src/helpers/utils";
import ReasonForEditModal from "./components/ReasonForEditModal";
import { UsersData } from "src/ContextAPI";
import PhoFormModal from "./components/PhoFormModal";
import HouseholdFormModal from "./components/HouseholdFormModal";
import ViewHouseHoldModal from "./components/ViewHouseHoldModal";

const PatientItem = ({ data, onClick }) => {
	return (
		<div
			className="flex items-center rounded-xl gap-3 p-2 bg-background mb-3 last:mb-0 !rounded-xl hover:bg-blue-50 cursor-pointer border"
			onClick={onClick}
		>
			<div className="w-11 h-11 rounded-full flex items-center justify-center p-2 bg-white">
				<img
					src="/patient.png"
					className="w-full  aspect-square object-cover"
					alt=""
				/>
			</div>
			<div className="flex flex-col items-center lg:items-start xl:items-start w-full text-left">
				<h6
					className={`text-left text-sm font-semibold ${
						data?.gender == "Male"
							? "text-blue-700"
							: "text-pink-700"
					} mb-0`}
				>
					{`${replaceLname(data?.lastname)}, ${replaceFname(
						data?.firstname
					)} ${replaceMname(data?.middle)}`}
				</h6>
				<p className="mb-0 text-placeholder">{`${
					data?.municipality ? data?.municipality + ", " : ""
				} ${data?.city ? data?.city + ", " : ""} ${
					data?.barangay ? data?.barangay + ", " : ""
				} ${data?.street}`}</p>
			</div>
		</div>
	);
};

const PhoHouseholds = () => {
	const patient_profile_ref = useRef(null);
	const add_patient_ref = useRef(null);
	const { users } = useContext(UsersData);
	const [view, setView] = useState("table");
	const { data, loading, setPage, setPaginate, setFilters } =
		useDataTable("v1/households");
	const [patient, setPatient] = useState(null);
	const form_modal = useRef(null);
	const pho_form_ref = useRef(null);
	const view_household = useRef(null);

	useEffect(() => {
		getPatientInfo(3);
	}, []);

	const getPatientInfo = (id) => {
		axios.get(`v1/households/${id}`).then((res) => {
			setPatient(res.data.data);
		});
	};
	const columns = useMemo(
		() => [
			{
				header: "Household ID",
				accessorKey: "house_id",
			},
			{
				header: "Respondent",
				accessorKey: "respondent",
			},
			{
				header: "Province",
				accessorKey: "province",
			},
			{
				header: "Province",
				accessorKey: "province",
			},
			{
				header: "Municipality",
				accessorKey: "municipality",
				cell: (data) => {
					return data?.municipality?.name;
				},
			},
			{
				header: "Barangay",
				accessorKey: "barangay",
				cell: (data) => {
					return data?.barangay?.name;
				},
			},
			{
				header: "Purok",
				accessorKey: "purok",
				cell: (data) => {
					return data?.purok?.name;
				},
			},
			{
				header: "Street",
				accessorKey: "street",
			},
			{
				header: "Action",
				accessorKey: "action",
				className: "w-[244px]",
				cell: (data) => {
					return (
						<div className="flex items-center gap-2">
							<ActionBtn
								size="sm"
								type="foreground-light"
								onClick={() => {
									view_household.current.show(data);
								}}
							>
								<FlatIcon
									icon="rr-eye"
									className="text-lg mr-2"
								/>
								View
							</ActionBtn>
							<Link
								className=" !no-underline"
								to={`households/${data?.id}`}
								size="sm"
								type="primary-light"
								onClick={() => {}}
							>
								<FlatIcon
									icon="rr-edit"
									className="text-lg mr-2"
								/>
								Edit
							</Link>
							{/*<ActionBtn size="sm" type="danger-light">
								<FlatIcon icon="rr-trash" className="text-lg mr-2" />
								Delete
							</ActionBtn> */}
						</div>
					);
				},
			},
		],
		[]
	);
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faHouseUser}
						color="white"
						size={"2x"}
					/>
				}
				title="Households"
				subtitle="List of households registered in system"
			>
				<CardContainer
					containerClassName={` border border- border-opacity-10`}
					header={{
						title: "Households Data",
						children: (
							<div className="ml-auto flex lg:items-center flex-col lg:flex-row gap-3">
								<a
									href={`https://ee.kobotoolbox.org/x/eqGTE1D2?d[surveyor]=${users?.username.replace(
										"-",
										" "
									)}&d[surveyor_id]=${users?.user_id}`}
									target="_blank"
									rel="noreferrer"
									className="!no-underline"
								>
									<ActionBtn
										type="background-gradient"
										size="sm"
										className="px-3 !h-[42px]"
									>
										<FlatIcon
											icon="rr-plus"
											className="text-xl mr-2"
										/>
										<span className="!no-underline">
											Kobotoolbox Form
										</span>
									</ActionBtn>
								</a>
								<Link
									to={`create-household`}
									size="sm"
									className=" !no-underline"
									onClick={() => {}}
								>
									<ActionBtn
										className="!no-underline !h-[42px]"
										size="sm"
									>
										<FlatIcon
											icon="rr-plus"
											className="mr-2"
										/>
										<span className="!no-underline">
											Add household
										</span>
									</ActionBtn>
								</Link>
								<TextInputField
									inputClassName=" pl-10"
									className="w-full lg:w-[320px]"
									icon={
										<FlatIcon
											icon="rr-search"
											className="text-sm"
										/>
									}
									placeholder="Search"
									onChange={(e) => {
										console.log("onChange", e.target.value);
										setFilters((prevFilters) => ({
											...prevFilters,
											keyword: e.target.value,
											search: e.target.value,
											query: e.target.value,
										}));
									}}
								/>
							</div>
						),
					}}
					className="!p-0"
				>
					<TableV2
						paginationClassName="px-4"
						columns={columns}
						pagination={true}
						loading={loading}
						data={data?.data}
						onTableChange={(data) => {
							setPage(data.pageIndex + 1);
							setPaginate(data.pageSize);
						}}
					/>
				</CardContainer>
			</LayoutContainer>
			<HouseholdFormModal ref={form_modal} />
			<ViewHouseHoldModal ref={view_household} />
		</>
	);
};

export default PhoHouseholds;
