import {
  Box,
  // Paper,
  // Tabs,
  // Tab,
  Grid,
  Card,
  CardHeader,
  Typography,
  TextField,
  InputAdornment,
  TableContainer,
  CardMedia,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  FormHelperText,
  DialogActions,
  CircularProgress,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  Divider,
  Tooltip,
  TablePagination,
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import Container from "src/layout/Container";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import { Check, Clear, Edit, Search } from "@material-ui/icons";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const HMOListAccounting = () => {
  const { users } = useContext(UsersData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [edit, setEdit] = useState({
    data: null,
    open: false,
  });
  const [search, setSearch] = useState("");
  // const [category, setCategory] = useState("test-person");
  // const handleChange = (event, xcat) => {
  //   setCategory(xcat);
  // };
  const [hmoList, setHMOList] = useState({ data: [], ready: false });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getHMOList = async () => {
    try {
      var params = {
        main_mgmt_id: users.main_mgmt_id,
      };
      const request = await axios.get(
        "accounting/get/getHMOList-by-main-mgmt",
        { params }
      );
      setHMOList({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      console.log("error : ", error);
      // Notify.requestError(error)
    }
  };

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleEditHMO = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("username", users.username);
    formdata.append("main_mgmt_id", users.main_mgmt_id);

    setIsSubmitting(true);

    axios
      .post("accounting/update/specific-hmo", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "pass-invalid") {
          Notify.fieldInvalid("password");
        }
        if (data === "success") {
          Notify.successRequest("update hmo");
          getHMOList();
          setEdit({ data: null, open: true });
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    getHMOList();
    // eslint-disable-next-line
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const searchable = hmoList.data.filter((data) => {
    return data.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
  });

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "HMO",
        items: [{ name: "Dashboard", path: "/sph/app/accounting" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>HMO</Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9} md={9}>
          <Card>
            <CardHeader
              title={
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="h5">HMO List</Typography>
                  </Box>
                  <Box>
                    <TextField
                      label="Search hmo"
                      variant="outlined"
                      margin="dense"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <Search color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              }
            />

            <CardMedia>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <strong> Name </strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong> Action </strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {hmoList.ready ? (
                      hmoList.data.length > 0 ? (
                        searchable.map((data, index) => (
                          <TableRow key={index} hover>
                            <TableCell> {data.name} </TableCell>
                            <TableCell align="center">
                              <Tooltip arrow title="Edit HMO">
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    setEdit({
                                      data: data,
                                      open: true,
                                    })
                                  }
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            <Typography variant="subtitle2" color="secondary">
                              No imaging order added.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Typography variant="subtitle2" color="primary">
                            Please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={searchable.length}
                rowsPerPageOptions={[10, 50, 100]}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="List"
              />
            </CardMedia>
          </Card>
        </Grid>

        <Grid item xs={12} sm={3} md={3}>
          <Formik
            initialValues={{
              username: users.username,
              user_id: users.user_id,
              management_id: users.management_id,
              main_mgmt_id: users.main_mgmt_id,
              name: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().trim().required(),
              password: Yup.string().required(),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, resetForm }
            ) => {
              try {
                const request = await axios.post(
                  "accounting/create/new-hmo",
                  getFormData(values)
                );
                if (request.data === "pass-invalid") {
                  setErrors({ password: "Password is invalid." });
                  Notify.fieldInvalid("password");
                }
                if (request.data === "success") {
                  Notify.successRequest("add new hmo");
                  resetForm();
                  setSubmitting(true);
                  getHMOList();
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values,
              resetForm,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Card>
                  <CardHeader
                    title={
                      <Typography color="textPrimary" variant="h5">
                        New HMO
                      </Typography>
                    }
                  />

                  <CardMedia>
                    <Box m={1}>
                      <Box my={2} className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          fullWidth
                          required
                          name="name"
                          label="Name of HMO"
                          variant="outlined"
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          fullWidth
                          required
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          name="password"
                          label="Enter your password"
                          variant="outlined"
                          type="password"
                        />
                      </Box>

                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}

                      <Divider />

                      <Box my={2} display="flex">
                        <Box flexGrow={1} />

                        <Button
                          variant="contained"
                          color="default"
                          type="reset"
                          startIcon={<Clear />}
                          onClick={() => resetForm()}
                        >
                          Clear
                        </Button>

                        <Box ml={2}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </CardMedia>
                </Card>
              </form>
            )}
          </Formik>
        </Grid>

        {/* edit dialog */}
        {edit.data && edit.open && (
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={edit.open}
            onClose={() => setEdit({ data: null, open: true })}
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle> Edit HMO </DialogTitle>
            <form onSubmit={handleEditHMO}>
              <DialogContent dividers>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    required
                    name="hl_id"
                    label="Order Id"
                    variant="outlined"
                    hidden
                    defaultValue={edit.data.hl_id}
                  />

                  <TextField
                    fullWidth
                    required
                    name="name"
                    label="HMO"
                    variant="outlined"
                    defaultValue={edit.data.name === null ? "" : edit.data.name}
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    fullWidth
                    required
                    name="password"
                    label="Enter your password"
                    variant="outlined"
                    type="password"
                  />
                </Box>
              </DialogContent>

              <DialogActions>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => setEdit({ data: null, open: true })}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting && (
                      <CircularProgress size={20} color="inherit" />
                    )
                  }
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
      </Grid>
    </Container>
  );
};

export default HMOListAccounting;
