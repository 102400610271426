import {
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  Typography,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import ConsultationForm from "./ConsultationForm";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonV3 from "src/components/ButtonV3";

const ProfileConsultation = ({ setSelectedIndex, patient }) => {
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const [listShown, setlistShown] = useState(true);
  const [formShown, setformShown] = useState(false);

  const handleClickList = (event) => {
    setlistShown(true);
    setformShown(false);
  };

  const handleClickForm = (event) => {
    setformShown(true);
    setlistShown(false);
  };

  return (
    <div className="flex flex-col gap-y-6">
      {listShown && (
        <Fragment>
          <div className="flex flex-row justify-content-between gap-1">
            <div>
              <h4 className="text-lg font-bold mb-0">Consultations</h4>
              <p className="text-sm">3 Total List of Consultations</p>
            </div>
            <div className="flex align-items-center">
              <div className="flex flex-row align-items-center gap-3">
                <div>
                  <TextField
                    label="Search"
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Search color="default" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <ButtonV3 onClick={handleClickForm}>
                    <div className="flex">
                      <FontAwesomeIcon
                        icon={faPlus}
                        color="primary"
                        style={{ fontSize: 18 }}
                        className="pr-2"
                      />
                      Add New Med Cert.
                    </div>
                  </ButtonV3>
                </div>
              </div>
            </div>
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="text-uppercase">
                    <strong> No </strong>
                  </TableCell>
                  <TableCell align="center" className="text-uppercase">
                    <strong> Options </strong>
                  </TableCell>
                  <TableCell align="center" className="text-uppercase">
                    <strong> Date </strong>
                  </TableCell>
                  <TableCell align="center" className="text-uppercase">
                    <strong> Time </strong>
                  </TableCell>
                  <TableCell align="center" className="text-uppercase">
                    <strong> Type of Consultation </strong>
                  </TableCell>
                  <TableCell align="center" className="text-uppercase">
                    <strong> Purpose </strong>
                  </TableCell>
                  <TableCell align="center" className="text-uppercase">
                    <strong> Chief </strong>
                  </TableCell>
                  <TableCell align="center" className="text-uppercase">
                    <strong> Complaint </strong>
                  </TableCell>
                  <TableCell align="center" className="text-uppercase">
                    <strong> Consent </strong>
                  </TableCell>
                  <TableCell align="center" className="text-uppercase">
                    <strong> Age in Years </strong>
                  </TableCell>
                  <TableCell align="center" className="text-uppercase">
                    <strong> Age in Months </strong>
                  </TableCell>
                  <TableCell align="center" className="text-uppercase">
                    <strong> Phie </strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1" hover>
                  <TableCell>1 </TableCell>
                  <TableCell> Option 1 </TableCell>
                  <TableCell> Apr 22, 2023 </TableCell>
                  <TableCell> 10:57 AM </TableCell>
                  <TableCell> Type A </TableCell>
                  <TableCell> Check Up </TableCell>
                  <TableCell> Chief 1 </TableCell>
                  <TableCell> Skin Irritation </TableCell>
                  <TableCell> Consent A </TableCell>
                  <TableCell> 11 </TableCell>
                  <TableCell> 120 </TableCell>
                  <TableCell> Test </TableCell>
                </TableRow>
                <TableRow key="2" hover>
                  <TableCell>2 </TableCell>
                  <TableCell> Option 2 </TableCell>
                  <TableCell> Apr 22, 2023 </TableCell>
                  <TableCell> 10:57 AM </TableCell>
                  <TableCell> Type A </TableCell>
                  <TableCell> Check Up </TableCell>
                  <TableCell> Chief 1 </TableCell>
                  <TableCell> Skin Irritation </TableCell>
                  <TableCell> Consent A </TableCell>
                  <TableCell> 11 </TableCell>
                  <TableCell> 120 </TableCell>
                  <TableCell> Test </TableCell>
                </TableRow>
                <TableRow key="3" hover>
                  <TableCell>3 </TableCell>
                  <TableCell> Option 3 </TableCell>
                  <TableCell> Apr 22, 2023 </TableCell>
                  <TableCell> 10:57 AM </TableCell>
                  <TableCell> Type A </TableCell>
                  <TableCell> Check Up </TableCell>
                  <TableCell> Chief 1 </TableCell>
                  <TableCell> Skin Irritation </TableCell>
                  <TableCell> Consent A </TableCell>
                  <TableCell> 11 </TableCell>
                  <TableCell> 120 </TableCell>
                  <TableCell> Test </TableCell>
                </TableRow>
                {/* <TableRow>
									<TableCell colSpan={12} align="center">
										<Typography variant="subtitle2" color="secondary">
										No consultations added.
										</Typography>
									</TableCell>
								</TableRow> */}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={5}
              rowsPerPageOptions={[10, 50, 100]}
              page={5}
              onChangePage={handleChangePage}
              rowsPerPage={5}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="List"
            />
          </TableContainer>
        </Fragment>
      )}

      {formShown && (
        <ConsultationForm
          handleClickList={handleClickList}
          setSelectedIndex={setSelectedIndex}
          patient={patient}
        />
      )}
    </div>
  );
};

export default ProfileConsultation;
