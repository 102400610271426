import React, { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
	ListItemText,
	Box,
	Avatar,
	Collapse,
	IconButton,
} from "@material-ui/core";
import { ThemeContext, UsersData } from "../ContextAPI";
import { useHistory } from "react-router-dom";
import Topbar from "./Topbar";
import {
	ChevronRight as ExpandLess,
	ChevronDown as ExpandMore,
} from "react-feather";
import CloseIcon from "@material-ui/icons/Close";
import scrollDetector from "scroll-detector";
import { isMobile } from "react-device-detect";
import Img from "src/components/Img";

const windowWidth = window.innerWidth;
const drawerWidth = windowWidth > 600 ? 280 : windowWidth;
const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	appBar: {
		paddingTop: windowWidth > 500 ? "auto" : 10,
		height: windowWidth > 500 ? "auto" : 80,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},

	appBarPrimaryColor: {
		backgroundColor: theme.palette.primary.background,
		color: theme.palette.common.white,
	},

	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},

	appBarShiftColor: {
		// backgroundColor: '#f5f7f8',
		color: theme.palette.common.black,
	},

	menuButton: {
		marginRight: 36,
	},

	hide: {
		display: "none",
	},

	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},

	drawerPrimaryColor: {
		border: "none",
		backgroundColor: theme.palette.primary.background,
		color: theme.palette.common.white,
	},

	drawerScrollable: {
		height: window.innerHeight - 200,
		overflowY: "auto",
	},

	drawerScrollablePrimaryColor: {
		backgroundColor: theme.palette.primary.background_light,
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.easeIn,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},

	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: 0,
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},

	nested: {
		paddingLeft: theme.spacing(5),
	},

	profileImg: {
		width: theme.spacing(8),
		height: theme.spacing(8),
		border: theme.palette.primary.dark,
		color: "#fff",
		fontSize: 25,
	},
	selectedTab: {
		color: "#fff",
		border: "1px solid #fff",
		borderRight: "4px solid #000",
	},
}));

export default function Sidebar({
	avatar,
	header,
	routes,
	module,
	notification,
}) {
	const page = localStorage.getItem("hmis_page");
	const userContext = useContext(UsersData);
	const classes = useStyles();
	const themeContext = useContext(ThemeContext);
	const history = useHistory();
	const [isscroll, setIsScroll] = useState(true);
	const accountTitle = module === "hmis" ? "COO" : module;
	const [subitem, setSubItem] = useState({
		open: false,
		tag: null,
	});

	const handleCloseSidebar = (data) => {
		if (data.subitem.length === 0) {
			if (isMobile) {
				themeContext.sidebar.toggle(false);
			}
			localStorage.setItem("hmis_page", data.name);
			history.push(`${data.path}`);
		}
		setSubItem({
			open: data.subitem.length > 0 ? !subitem.open : false,
			tag: data.name,
		});
	};

	scrollDetector.on("scroll:down", () => {
		setIsScroll(false);
	});

	scrollDetector.on("at:top", () => {
		setIsScroll(true);
	});

	useEffect(() => {}, [page]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				color="default"
				position="fixed"
				elevation={themeContext.sidebar.open ? (isscroll ? 0 : 4) : 2}
				className={
					clsx(classes.appBar, {
						[classes.appBarPrimaryColor]:
							!themeContext.sidebar.open &&
							!themeContext.gtcThemeDark,
						[classes.appBarShift]: themeContext.sidebar.open,
						[classes.appBarShiftColor]:
							themeContext.sidebar.open &&
							!themeContext.gtcThemeDark,
					}) + ` border-b`
				}
			>
				<Topbar
					header={header}
					module={accountTitle}
					notification={notification}
				/>
			</AppBar>

			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: themeContext.sidebar.open,
					[classes.drawerClose]: !themeContext.sidebar.open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerPrimaryColor]:
							!themeContext.gtcThemeDark,
						[classes.drawerOpen]: themeContext.sidebar.open,
						[classes.drawerClose]: !themeContext.sidebar.open,
					}),
				}}
			>
				{/* <Box display="flex">
					<Box flexGrow={1} />
					<Box mt={1} mr={1}>
						<IconButton
							size="small"
							onClick={() => themeContext.sidebar.toggle(false)}
						>
							<CloseIcon style={{ color: "white" }} />
						</IconButton>
					</Box>
				</Box> */}

				<Box display="flex" p={1}>
					<Box
						flexGrow={1}
						align="center"
						style={{ textTransform: "uppercase" }}
					>
						<Box
							onClick={() => {
								history.push(`/sph/app/${module}/account`);
								themeContext.sidebar.toggle(false);
							}}
						>
							{avatar ? (
								<Img
									type="user"
									name={avatar?.name}
									src={avatar?.src}
									style={{ cursor: "pointer" }}
								/>
							) : Boolean(header.image) ? (
								<Avatar
									className={classes.profileImg}
									src={`${imageLocation}${
										module === "haptech"
											? "warehouse"
											: module
									}/${header.image}`}
									style={{ cursor: "pointer" }}
								/>
							) : (
								<Avatar
									className={classes.profileImg}
									style={{ cursor: "pointer" }}
								>
									{Boolean(header.name)
										? header.name.charAt()
										: userContext.users.username.charAt()}
								</Avatar>
							)}
						</Box>
						<Box mt={1}>
							<Typography variant="caption">
								{" "}
								{header.name}{" "}
							</Typography>
						</Box>
					</Box>
				</Box>

				<Divider />

				<Box
					className={clsx(classes.drawerScrollable, {
						[classes.drawerScrollablePrimaryColor]:
							!themeContext.gtcThemeDark,
					})}
				>
					<PerfectScrollbar>
						<List>
							{routes.map((data, index) => (
								<React.Fragment key={index}>
									<ListItem
										button={data.show}
										className={`${
											!data.show ? classes.hide : ""
										}`}
										style={{
											paddingBottom: 15,
											paddingTop: 15,
										}}
										onClick={() => handleCloseSidebar(data)}
										selected={
											data.name === page ? true : false
										}
									>
										<ListItemIcon>
											{/* <ChevronsRight style={{ marginTop: 5, marginRight: 5 }} size={15} color="white"/>  */}
											{data.icon}
										</ListItemIcon>

										<ListItemText primary={data.name} />
										{data?.children || ""}
										{data.subitem &&
											data.subitem.length > 0 &&
											(subitem.tag === data.name &&
											subitem.open ? (
												<ExpandMore
													size={15}
													color="white"
												/>
											) : (
												<ExpandLess
													size={15}
													color="white"
												/>
											))}
									</ListItem>

									{data.subitem &&
										data.subitem.length > 0 &&
										data.subitem.map((item, key) => (
											<Collapse
												key={key}
												in={
													subitem.tag === data.name &&
													subitem.open
												}
												timeout="auto"
												unmountOnExit
											>
												<List
													component="div"
													disablePadding
													className={
														!item.show
															? classes.hide
															: ""
													}
												>
													<ListItem
														button
														className={
															classes.nested
														}
														onClick={() => {
															history.push(
																`${item.path}`
															);
															localStorage.setItem(
																"hmis_page",
																item.name
															);
															if (isMobile) {
																themeContext.sidebar.toggle(
																	false
																);
															}
														}}
														selected={
															item.name === page
														}

														// button
														// className={classes.nested}
														// onClick={() => {
														//   history.push(`${item.path}`);
														//   localStorage.setItem("hmis_page", item.name);
														//   if (isMobile) {
														//     themeContext.sidebar.toggle(false);
														//   }
														// }}
														// selected={
														//   item.name === page
														// }
													>
														<ListItemIcon>
															{item.icon}
														</ListItemIcon>
														<ListItemText
															primary={item.name}
														/>
													</ListItem>
												</List>
											</Collapse>
										))}
								</React.Fragment>
							))}
						</List>
					</PerfectScrollbar>
				</Box>

				<Divider />

				<Box align={"center"} py={2} mt={2}>
					<Typography component={Box} mb={2}>
						{" "}
						Global Telemedicine Corp{" "}
					</Typography>
					<Typography> &copy; {new Date().getFullYear()} </Typography>
				</Box>
			</Drawer>
		</div>
	);
}
