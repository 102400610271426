// 11-19-2021-updates-jhomar

import React, { Fragment, useEffect, useState } from "react";
import {
	Box,
	Grid,
	List,
	makeStyles,
	Collapse,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography,
	TextField,
	InputAdornment,
	Avatar,
	CardMedia,
	TablePagination,
	Divider,
} from "@material-ui/core";
import Notify from "../notification/Notify";
import Axios from "axios";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import HemathologyOrder from "./HemathologyOrder";
import SerologyOrder from "./SerologyOrder";
import MicroscopyOrder from "./MicroscopyOrder";
import FecalOrder from "./FecalOrder";
import ChemistryOrder from "./ChemistryOrder";
import SearchIcon from "@material-ui/icons/Search";
import { blue } from "@material-ui/core/colors";
import Container from "../layout/Container";
import { UsersData, UsersHeader } from "../ContextAPI";
import StoolTest from "./StoolTest";
import PapsmearTest from "./PapsmearTest";
import UrinalysisOrder from "./UrinalysisOrder";
import EcgOrder from "./EcgOrder";
import MedicalExam from "./MedicalExam";
import OralGlucoseTest from "./OralGlucoseTest";
import ThyroidProfileTest from "./ThyroidProfileTest";
import ImmunologyTest from "./ImmunologyTest";
import MiscellaneousTest from "./MiscellaneousTest";
import HepatitisProfileTest from "./HepatitisProfileTest";
import Covid19Test from "./Covid19Test";
import TumorMaker from "./TumorMaker";
import DrugTestOrder from "./DrugTestOrder";

// import CheckInternet from 'src/utils/CheckInternet';
// import CheckConnection from 'src/CheckConnection';
// import Alert from '@material-ui/lab/Alert';
// import WifiIcon from '@material-ui/icons/Wifi';
// import WifiOffIcon from '@material-ui/icons/WifiOff';
// import { useHistory } from 'react-router';

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	primary: {
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
}));

const LaboratoryPatientsMobileVan = () => {
	const classes = useStyles();
	const { users } = React.useContext(UsersData);
	const [search, setSearch] = useState("");
	const [formheader, setFormHeader] = useState([]);
	const {
		van_queue: { vaPatient },
	} = React.useContext(UsersHeader);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [selectedPatient, setSelectedPatient] = useState({
		patient_id: null,
		category: "",
	});

	const getLabFormHeader = () => {
		var params = { management_id: users.management_id };
		Axios.get("laboratory/order/formheader-details", { params })
			.then((response) => {
				const data = response.data;
				setFormHeader(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	// const checkinternet = () => {
	//     CheckInternet.online().then(() =>
	//         setOnline('connected')
	//     ).catch(() =>
	//         setOnline('disconnected')
	//     )
	// }

	useEffect(() => {
		getLabFormHeader();
		// checkinternet();

		// eslint-disable-next-line
	}, []);

	const searchable = vaPatient.data.filter((data) => {
		return (
			data.patient_name.toLowerCase().indexOf(search.trim().toLowerCase()) !==
			-1
		);
	});

	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: true,
					current: "Patients",
					items: [{ name: "Dashboard", path: "/sph/app" }],
				}}
				title={"Mobile Van Patients"}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={3}>
						{/* <Box mb={2}>
                            { online === 'checking' && <CheckConnection /> }
                            { online === 'disconnected' && (
                                <Fragment>
                                    <Box
                                        border={1}
                                        borderRadius={4}
                                        borderColor={red[100]}
                                    >
                                        <Alert
                                            icon={<WifiOffIcon style={{ fontSize: '2.2em' }} />}
                                            severity="error"
                                        >
                                            <Typography variant="subtitle2"> Offline. </Typography>
                                            <Typography variant="body2"> The system cannot receive orders from virtual doctors. </Typography>
                                        </Alert>
                                    </Box>
                                </Fragment>
                            )}
                            {online === 'connected' && (
                                <Box
                                    display="flex"
                                    border={1}
                                    borderRadius={4}
                                    borderColor={blue[100]}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => history.push('/sph/app/laboratory/virtual')}
                                >
                                    <Box flexGrow={1}>
                                        <Alert
                                            icon={
                                                <WifiIcon style={{ fontSize: '2.2em' }} />
                                            }
                                            severity="info"
                                        >
                                            <Typography variant="subtitle1"> Online </Typography>
                                            <Typography variant="subtitle2"> The system can receive orders from virtual doctors. </Typography>
                                        </Alert>
                                    </Box>
                                    <Badge
                                        badgeContent={ 150 }
                                        color="secondary"
                                    />
                                </Box>
                            )}
                        </Box> */}

						<Paper component={Box} variant="outlined" p={2}>
							<Box mb={1}>
								<Typography color="textPrimary" variant="h5">
									<strong>Patient List</strong>
								</Typography>
							</Box>
							<Divider light />
							<Box>
								{vaPatient.ready ? (
									vaPatient.data.length > 0 ? (
										<List component="div">
											<>
												<Box mb={1}>
													<TextField
														label="Search"
														variant="outlined"
														fullWidth
														margin="dense"
														value={search}
														onChange={(e) => setSearch(e.target.value)}
														InputProps={{
															endAdornment: (
																<InputAdornment>
																	<SearchIcon />
																</InputAdornment>
															),
														}}
													/>
												</Box>

												{searchable.map((data, index) => {
													return (
														<Fragment key={index}>
															<ListItem
																button
																hidden={!Boolean(parseInt(data.order_count))}
																onClick={() => {
																	setSelectedPatient({
																		...selectedPatient,
																		patient_id: data.pid,
																	});
																}}
																selected={
																	selectedPatient.patient_id === data.pid
																}
															>
																<ListItemIcon>
																	{data.image === null ? (
																		<Avatar className={classes.primary}>
																			{data.lastname.charAt().toUpperCase()}
																		</Avatar>
																	) : (
																		<Avatar
																			className={classes.primary}
																			src={`${imageLocation}patients/${data.patient_image}`}
																			alt=""
																		/>
																	)}
																</ListItemIcon>
																{/* <ListItemIcon>
                                  <AssignmentIcon
                                    color={
                                      selectedPatient.patient_id === data.pid
                                        ? "primary"
                                        : "inherit"
                                    }
                                  />
                                </ListItemIcon> */}

																<ListItemText
																	className={`gtc-capitalize`}
																	primary={data.patient_name}
																	primaryTypographyProps={{
																		color:
																			selectedPatient.patient_id === data.pid
																				? "primary"
																				: "inherit",
																	}}
																/>
															</ListItem>
															<Collapse
																in={selectedPatient.patient_id === data.pid}
																timeout="auto"
																unmountOnExit
															>
																<List component="div" disablePadding>
																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(
																				parseInt(data.count_hema) +
																					parseInt(data.count_hema_cbc)
																			)
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "hemathology",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"hemathology"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>

																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"hemathology"
																						? blue[500]
																						: "",
																			}}
																			primary={"Hemathology"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={!Boolean(parseInt(data.count_reso))}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "serology",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"serology"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"serology"
																						? blue[500]
																						: "",
																			}}
																			primary={"Serology"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(parseInt(data.count_micro))
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "microscopy",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"microscopy"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"microscopy"
																						? blue[500]
																						: "",
																			}}
																			primary={"Clinical Microscopy"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(parseInt(data.count_fecal))
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "fecal",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category === "fecal"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category === "fecal"
																						? blue[500]
																						: "",
																			}}
																			primary={"Fecal Analysis"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={!Boolean(parseInt(data.count_chem))}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "chemistry",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"chemistry"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"chemistry"
																						? blue[500]
																						: "",
																			}}
																			primary={"Blood Chemistry"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(parseInt(data.count_stool))
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "stool-test",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"stool-test"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"stool-test"
																						? blue[500]
																						: "",
																			}}
																			primary={"Stool Test"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(parseInt(data.count_papsmear))
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "papsmear-test",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"papsmear-test"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"papsmear-test"
																						? blue[500]
																						: "",
																			}}
																			primary={"Papsmear Test"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(parseInt(data.count_urinalysis))
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "urinalysis",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"urinalysis"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"urinalysis"
																						? blue[500]
																						: "",
																			}}
																			primary={"Urinalysis"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={!Boolean(parseInt(data.count_ecg))}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "ecg",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category === "ecg"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category === "ecg"
																						? blue[500]
																						: "",
																			}}
																			primary={"Electrocardiogram (ECG)"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(
																				parseInt(data.count_medical_exam)
																			)
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "medical-exam",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"medical-exam"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"medical-exam"
																						? blue[500]
																						: "",
																			}}
																			primary={"Medical Examination Report"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={!Boolean(parseInt(data.count_oral))}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "oral-glucose",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"oral-glucose"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"oral-glucose"
																						? blue[500]
																						: "",
																			}}
																			primary={"Oral Glucose Tolerance"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(parseInt(data.count_thyroid))
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "thyroid-profile",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"thyroid-profile"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"thyroid-profile"
																						? blue[500]
																						: "",
																			}}
																			primary={"Thyroid Profile"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(parseInt(data.count_immunology))
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "immunology",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"immunology"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"immunology"
																						? blue[500]
																						: "",
																			}}
																			primary={"Immunology"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(
																				parseInt(data.count_miscellaneous)
																			)
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "miscellaneous",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"miscellaneous"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"miscellaneous"
																						? blue[500]
																						: "",
																			}}
																			primary={"Miscellaneous"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(parseInt(data.count_hepatitis))
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "hepatitis-profile",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"hepatitis-profile"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"hepatitis-profile"
																						? blue[500]
																						: "",
																			}}
																			primary={"Hepatitis Profile"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(parseInt(data.count_covid_test))
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "covid-19",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"covid-19"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"covid-19"
																						? blue[500]
																						: "",
																			}}
																			primary={"Covid-19"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(parseInt(data.count_tumor_maker))
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "tumor-maker",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"tumor-maker"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"tumor-maker"
																						? blue[500]
																						: "",
																			}}
																			primary={"Tumor Maker"}
																		/>
																	</ListItem>

																	<ListItem
																		button
																		className={classes.nested}
																		hidden={
																			!Boolean(parseInt(data.count_drug_test))
																		}
																		onClick={() =>
																			setSelectedPatient({
																				...selectedPatient,
																				category: "drug-test",
																			})
																		}
																	>
																		<ListItemIcon>
																			<DoubleArrowIcon
																				color={
																					selectedPatient.category ===
																					"drug-test"
																						? "primary"
																						: "inherit"
																				}
																				style={{ fontSize: "1rem" }}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color:
																					selectedPatient.category ===
																					"drug-test"
																						? blue[500]
																						: "",
																			}}
																			primary={"Drug Test"}
																		/>
																	</ListItem>
																</List>
															</Collapse>
														</Fragment>
													);
												})}
											</>
										</List>
									) : (
										<Box mt={2}>{Notify.noRecord()}</Box>
									)
								) : (
									<Box mt={2}>{Notify.loading()}</Box>
								)}
							</Box>

							<TablePagination
								component="div"
								count={vaPatient.data.length}
								rowsPerPageOptions={[10, 50, 100]}
								page={page}
								onChangePage={handleChangePage}
								rowsPerPage={rowsPerPage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								labelRowsPerPage="List"
							/>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={12} md={9}>
						<Box>
							{selectedPatient.category === "" && <RenderSelectPatient />}
							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "hemathology" && (
									<HemathologyOrder
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}
							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "serology" && (
									<SerologyOrder
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}
							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "microscopy" && (
									<MicroscopyOrder
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}
							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "fecal" && (
									<FecalOrder
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}
							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "chemistry" && (
									<ChemistryOrder
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "stool-test" && (
									<StoolTest
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "urinalysis" && (
									<UrinalysisOrder
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "papsmear-test" && (
									<PapsmearTest
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "ecg" && (
									<EcgOrder
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "medical-exam" && (
									<MedicalExam
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "oral-glucose" && (
									<OralGlucoseTest
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "thyroid-profile" && (
									<ThyroidProfileTest
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "immunology" && (
									<ImmunologyTest
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "miscellaneous" && (
									<MiscellaneousTest
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "hepatitis-profile" && (
									<HepatitisProfileTest
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "covid-19" && (
									<Covid19Test
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "tumor-maker" && (
									<TumorMaker
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}

							{selectedPatient.patient_id !== null &&
								selectedPatient.category === "drug-test" && (
									<DrugTestOrder
										queue={"mobile_van_queue"}
										patient_id={selectedPatient.patient_id}
										formheader={formheader}
										process_for={"mobile-van"}
									/>
								)}
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Fragment>
	);
};

export default LaboratoryPatientsMobileVan;

const RenderSelectPatient = () => (
	<Box align="center">
		<CardMedia
			component="img"
			src={"/gtc-logo.png"}
			alt=""
			style={{ width: 270 }}
		/>
		<Typography color="secondary" variant="h3">
			NO PATIENT SELECTED
		</Typography>

		<Typography color="secondary" variant="h6">
			Select patient in the list to view details.
		</Typography>
	</Box>
);
