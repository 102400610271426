import React, { Fragment, useContext, useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Container from "src/layout/Container";
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Avatar,
  useTheme,
  Typography,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";
import Notify from "src/notification/Notify";
import Details from "./Details";

const PatientDischarged = () => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const theme = useTheme();
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();

  const [isLoading, setIsLoading] = useState(true);

  const [patientList, setPatientList] = useState([]);

  const getDischargedPatientList = React.useCallback(async () => {
    axios
      .get(`cashier/discharged/patient/get-patientlist-groupby-patientid`, {
        params: {
          management_id: users.management_id,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setPatientList(data);
        }
      })
      .catch((err) => console.log(`error:`, err.message))
      .finally(() => setIsLoading(false));
  }, [users, mounted]);

  useEffect(() => {
    getDischargedPatientList();
  }, [getDischargedPatientList]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Discharge",
          items: [
            {
              name: "Dashboard",
              path: "/sph/app/doctor",
            },
          ],
        }}
        title={"Discharge Patients "}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <CardHeader title="Patients" />
              <CardContent>
                <List>
                  {!isLoading ? (
                    patientList.length > 0 ? (
                      patientList.map((data, index) => (
                        <ListItem
                          key={index}
                          button
                          onClick={() => setSelectedPatient(data)}
                          selected={
                            selectedPatient?.patient_id === data.patient_id
                          }
                        >
                          <ListItemIcon>
                            <Avatar
                              style={{
                                backgroundColor: theme.palette.primary.main,
                                color: "#fff",
                              }}
                            >
                              {index++ + 1}
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText
                            primary={`${data.lname}, ${data.fname}`}
                            primaryTypographyProps={{
                              style: {
                                textTransform: "capitalize",
                              },
                            }}
                            secondary={Notify.createdAt(data.created_at)}
                          />
                        </ListItem>
                      ))
                    ) : (
                      <>
                        <Typography color="secondary" align="center">
                          No order found.
                        </Typography>
                      </>
                    )
                  ) : (
                    <>
                      <Typography color="primary" align="center">
                        Loading...
                      </Typography>
                    </>
                  )}
                </List>
              </CardContent>
            </Card>
          </Grid>

          {selectedPatient && (
            <Grid item xs={12} md={7} lg={8}>
              <Details details={selectedPatient} />
            </Grid>
          )}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default PatientDischarged;
