import { faHome, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import LayoutContainer from "src/components/LayoutContainer";
import BhsPatientProfile from "./BhsPatientProfile";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ViewRhuPatient from "./ViewRhuPatient";

const RhuViewPatient = () => {
	const [patient, setPatient] = useState(null);
	const { id } = useParams();
	useEffect(() => {
		if (id) {
			getPatient(id).then((res) => {
				setPatient(res.data.data);
			});
		}
	}, [id]);
	const getPatient = (id) => {
		return axios.get(`/v1/patients/${id}`);
	};
	return (
		<LayoutContainer
			icon={<FontAwesomeIcon icon={faUser} color="white" size={"2x"} />}
			title={
				<Typography variant={window.innerWidth > 500 ? "h4" : "h5"}>
					View Patient
				</Typography>
			}
			titleChildren={<></>}
			className={`relative`}
		>
			{patient ? (
				<ViewRhuPatient
					disableNewAppointment={true}
					patient={patient}
					setPatient={setPatient}
				/>
			) : (
				""
			)}
		</LayoutContainer>
	);
};

export default RhuViewPatient;
