import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  TableBody,
  IconButton,
  DialogTitle,
  Dialog,
  DialogContent,
  Zoom,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";
import NewPackageForm from "./NewPackageForm";
import { Trash2 } from "react-feather";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";

const NewPackage = () => {
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [dialogFinalSave, setDialogFinalSave] = useState(false);
  const { users } = useContext(UsersData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [unsavePackage, setUnsavePackage] = useState({
    data: [],
    ready: false,
  });

  const getPackageUnsave = async () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    try {
      const request = await axios.get("accounting/get/all/unsave-package", {
        params,
      });
      setUnsavePackage({ data: request.data, ready: true });
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveUnsaveFackage = (data) => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.set("pck_temp_id", data);

    axios
      .post("accounting/charge/remove-package", formdata)
      .then((res) => {
        if (res.data.message === "success") {
          getPackageUnsave();
          Notify.successRequest("remove package");
        }
        if (res.data.message === "db-error") {
          Notify.warnRequest("remove package");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleFinalSavePackage = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    var error = [];

    if (
      formdata.get("package_name").length === 0 ||
      formdata.get("package_name").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Package Name");
    }

    if (
      formdata.get("order_amount").length === 0 ||
      formdata.get("order_amount").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Package Amount");
    }

    if (error.length > 0) {
      console.warn("Form has an error, observe please");
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/finalized/confirm-package", formdata)
        .then((res) => {
          if (res.data.message === "success") {
            getPackageUnsave();
            setDialogFinalSave(false);
            Notify.successRequest("confirm package");
          }
          if (res.data.message === "db-error") {
            Notify.warnRequest("confirm package");
          }
          setIsSubmitting(false);
        })
        .catch((error) => {
          setIsSubmitting(false);
          Notify.requestError(error);
        });
    }
  };

  useEffect(() => {
    getPackageUnsave();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Box>
            <Card>
              <CardHeader title="Package Unsave" />
              <CardContent>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Department</TableCell>
                        <TableCell align="center"> Service/Order </TableCell>
                        <TableCell align="center"> Action </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {unsavePackage.ready &&
                        (unsavePackage.data.length > 0 ? (
                          unsavePackage.data.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell>{data.department}</TableCell>
                              <TableCell>{data.order_name}</TableCell>
                              <TableCell align="center">
                                <IconButton
                                  color="secondary"
                                  onClick={() =>
                                    handleRemoveUnsaveFackage(data.pck_temp_id)
                                  }
                                >
                                  <Trash2 />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3} align="center">
                              <Typography color="secondary" variant="subtitle2">
                                No unsave package found.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {unsavePackage.data.length > 0 && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={1}
                  >
                    <Box flexGrow={1} />
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CheckCircleOutline />}
                      onClick={() => setDialogFinalSave(true)}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardHeader title="New Package" />
            <CardContent>
              <Box mb={2}>
                <TextField
                  onChange={(e) =>
                    setSelectedDepartment(
                      e.target.value === "" ? null : e.target.value
                    )
                  }
                  fullWidth
                  required
                  name="department"
                  label="Category"
                  variant="outlined"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value={""}>Select</option>
                  {/* <option value={"doctor"}>Doctor</option> */}
                  <option value={"imaging"}>Imaging</option>
                  <option value={"laboratory"}>Laboratory</option>
                  <option value={"psychology"}>Psychology</option>
                  <option value={"others"}>Others</option>
                </TextField>
              </Box>

              <NewPackageForm
                selectedDepartment={selectedDepartment}
                getPackageUnsave={() => getPackageUnsave()}
              />
            </CardContent>
          </Card>
        </Grid>
        <Dialog
          open={dialogFinalSave}
          onClose={() => setDialogFinalSave(false)}
          TransitionComponent={Zoom}
          transitionDuration={800}
        >
          <DialogTitle>Package Save and Confirm</DialogTitle>
          <DialogContent>
            <form onSubmit={handleFinalSavePackage}>
              <Box mb={1}>
                <TextField
                  fullWidth
                  required
                  name="package_name"
                  label="Package Name"
                  variant="outlined"
                />
              </Box>
              <Box mb={1}>
                <TextField
                  fullWidth
                  required
                  name="order_amount"
                  label="Package Amount"
                  variant="outlined"
                />
              </Box>
              <Box mt={2} display="flex">
                <Box flexGrow={1} />
                <Button
                  onClick={() => setDialogFinalSave(false)}
                  variant="contained"
                  size="large"
                  color="default"
                >
                  Close
                </Button>
                <Box ml={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    startIcon={
                      isSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                    disabled={isSubmitting}
                  >
                    Confirm
                  </Button>
                </Box>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

export default NewPackage;
