import React, { useEffect, useMemo, useState } from "react";
import {
	faArrowLeft,
	faEye,
	faPencilAlt,
	faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import CisCreateNotes from "./CisCreateNotes";
import { formatDateTime } from "src/helpers/utils";
import axios from "axios";
import Notify from "src/notification/Notify";
import { Box, Button, Card, TablePagination } from "@material-ui/core";
import { styles } from "../styles/styles";
import ButtonV3 from "src/components/ButtonV3";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import CardContainer from "src/components/CardContainer";
import TextInputField from "src/components/forms/TextInputField";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import { v4 as uuidv4 } from "uuid";
import CisCreateNotes from "../notes/CisCreateNotes";
import CisCreatePatientsHistory from "./CisCreatePatienstsHistory";

export default function CisPatientHistory({ patient }) {
	const [shown, setShown] = useState(true);
	const patientId = patient.id;
	const [patientNotes, setPatientNotes] = useState();
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const {
		data,
		loading: dataLoading,
		setFilters,
	} = useDataTable(`v1/notes/${patientId}`);

	const refreshData = () => {
		setFilters((pevFils) => ({
			...setFilters,
			key: uuidv4(),
		}));
	};
	const columns = useMemo(
		() => [
			{
				header: "Date and Time",
				accessorKey: "datetime",
				headerClassName: "text-center",
				className: "text-left",
				cell: (data) => {
					return `${data?.date} ${data?.time}`;
				},
			},
			{
				header: "Notes",
				accessorKey: "notes",
				headerClassName: "text-center",
				className: "text-left",
				cell: (data) => {
					return (
						<div
							dangerouslySetInnerHTML={{ __html: data?.notes }}
						></div>
					);
				},
			},
			{
				header: "Status",
				accessorKey: "status",
				headerClassName: "text-center",
				className: "text-left",
				cell: (data) => {
					return data?.status;
				},
			},
		],
		[]
	);

	// useEffect(() => {
	// 	if (patientId)
	// 		axios
	// 			.get(`v1/notes/${patientId}`)
	// 			.then(function (response) {
	// 				// const notes = response.data;
	// 				setPatientNotes(response.data);
	// 				setLoading(true);
	// 			})
	// 			.catch(function (error) {
	// 				// handle error
	// 				console.log(error);
	// 			})
	// 			.finally(function () {
	// 				// always executed
	// 			});
	// }, [shown, patientId]);

	return (
		<div className="flex w-full flex-col">
			<div className="flex flex-col w-full mb-3">
				<h5 className="text-sm font-semibold text-indigo-700 w-2/3 flex items-center gap-2">
					<span>
						<img
							src="/vitals/history.png"
							className="h-8 object-contain"
						/>
					</span>
					<div>History</div>
					<ActionBtn
						size="sm"
						className="text-sm px-2 ml-"
						type="success"
						onClick={() => {
							setShown(false);
						}}
					>
						<FlatIcon icon="rr-plus" /> Create
					</ActionBtn>
				</h5>
				<div className="w-2/3 h-[1px] bg-indigo-600 mb-[2px]"></div>
				<div className="w-1/2 h-[1px] bg-indigo-400"></div>
			</div>
			{shown ? (
				<>
					{!dataLoading ? (
						<TableV2
							paginationClassName="px-4"
							columns={columns}
							pagination={true}
							loading={dataLoading}
							data={data?.data}
							onTableChange={(data) => {}}
						/>
					) : (
						Notify.loading()
					)}
				</>
			) : (
				<CisCreatePatientsHistory
					setShown={(val) => {
						refreshData();
						setShown(val);
					}}
					patient={patient}
				/>
			)}
		</div>
	);
}
