import {
	Table,
	TableCell,
	TableRow,
	TableBody,
	TableContainer,
	Typography,
	Box,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
// import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";
// import Footer from "src/laboratory/print/Footer";

const MiscellaneousOrder = ({ order_id, patient_id, formheader }) => {
	const { users } = React.useContext(UsersData);
	const [details, setDetails] = useState([]);

	const getpaidLaboratoryOrderDetails = async () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set("trace_number", order_id);
		formdata.set("table", "laboratory_miscellaneous");
		formdata.set("connection", "local");

		try {
			let response = await axios.post(
				"doctor/patient/laboratory/order/paid-detailsbytable",
				formdata
			);
			const data = response.data;

			setDetails(data);
		} catch (error) {
			console.log("lab form order laboratory_miscellaneous table error", error);
		}
	};

	// const checkResult = (order) => {
	//   return order === null ? (
	//     <Label color="error"> no result </Label>
	//   ) : (
	//     <Label color="success"> {order} </Label>
	//   );
	// };

	useEffect(() => {
		getpaidLaboratoryOrderDetails();

		// eslint-disable-next-line
	}, [order_id]);

	return (
		<>
			{details.length > 0 && (
				<>
					<Box my={2} p={2}>
						<Box>
							<Typography variant="h6" align="center">
								<b> MISCELLANEOUS </b>
							</Typography>
						</Box>

						<TableContainer>
							<Table>
								<TableBody>
									{details.length > 0 ? (
										details.map((data, index) => (
											<Fragment key={index}>
												{Boolean(parseInt(data.miscellaneous_test)) && (
													<Box>
														<Table>
															<TableBody>
																<TableRow>
																	<TableCell>
																		<Typography variant="subtitle2">
																			SPICEMEN :
																		</Typography>
																	</TableCell>
																	<TableCell>
																		<Box>
																			<Typography>{data.speciment}</Typography>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell>
																		<Typography variant="subtitle2">
																			TEST :
																		</Typography>
																	</TableCell>
																	<TableCell>
																		<Box>
																			<Typography>{data.test}</Typography>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell>
																		<Typography variant="subtitle2">
																			RESULT :
																		</Typography>
																	</TableCell>
																	<TableCell>
																		<Box>
																			<Typography>{data.result}</Typography>
																		</Box>
																	</TableCell>
																</TableRow>
															</TableBody>
														</Table>
													</Box>
												)}

												{Boolean(parseInt(data.pregnancy_test_urine)) && (
													<Box mt={2}>
														<Box display="flex">
															<Box flexGrow={1}>
																<Typography variant="subtitle1">
																	<b> PREGNANCY TEST (URINE) </b>
																</Typography>
															</Box>
														</Box>

														{Boolean(parseInt(data.is_processed)) && (
															<Box mt={1}>
																<Box>
																	<Typography variant="caption">
																		RESULT
																	</Typography>
																	<Typography
																		style={{
																			whiteSpace: "pre-line",
																		}}
																	>
																		{data.pregnancy_test_urine_result}
																	</Typography>
																</Box>
															</Box>
														)}
													</Box>
												)}

												{Boolean(parseInt(data.pregnancy_test_serum)) && (
													<Box mt={2}>
														<Box display="flex">
															<Box flexGrow={1}>
																<Typography variant="subtitle1">
																	<b> PREGNANCY TEST (SERUM) </b>
																</Typography>
															</Box>
														</Box>

														{Boolean(parseInt(data.is_processed)) && (
															<Box mt={1}>
																<Box>
																	<Typography variant="caption">
																		RESULT
																	</Typography>
																	<Typography
																		style={{
																			whiteSpace: "pre-line",
																		}}
																	>
																		{data.pregnancy_test_serum_result}
																	</Typography>
																</Box>
															</Box>
														)}
													</Box>
												)}

												{Boolean(parseInt(data.papsmear_test)) && (
													<Box mt={2}>
														<Box display="flex">
															<Box flexGrow={1}>
																<Typography variant="subtitle1">
																	<b> PAPSMEAR TEST </b>
																</Typography>
															</Box>
														</Box>

														{Boolean(parseInt(data.is_processed)) && (
															<Box mt={1}>
																<Box>
																	<Typography variant="caption">
																		RESULT
																	</Typography>
																	<Typography
																		style={{
																			whiteSpace: "pre-line",
																		}}
																	>
																		{data.papsmear_test_result}
																	</Typography>
																</Box>
															</Box>
														)}
													</Box>
												)}

												{Boolean(
													parseInt(data.papsmear_test_with_gramstain)
												) && (
													<Box mt={2}>
														<Box display="flex">
															<Box flexGrow={1}>
																<Typography variant="subtitle1">
																	<b> PAPSMEAR WITH GRAMSTAIN TEST </b>
																</Typography>
															</Box>
														</Box>

														{Boolean(parseInt(data.is_processed)) && (
															<Box mt={1}>
																<Box>
																	<Typography variant="caption">
																		RESULT
																	</Typography>
																	<Typography
																		style={{
																			whiteSpace: "pre-line",
																		}}
																	>
																		{data.papsmear_test_with_gramstain_result}
																	</Typography>
																</Box>
															</Box>
														)}
													</Box>
												)}
											</Fragment>
										))
									) : (
										<TableRow>
											<TableCell colSpan={3}>
												<Typography> No record found. </Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>

					{/* <Footer formheader={formheader} /> */}
				</>
			)}
		</>
	);
};

export default MiscellaneousOrder;
