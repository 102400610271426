import { Box, Grid, Tab, Tabs, Paper } from "@material-ui/core";
import React, { useState } from "react";
import Container from "src/layout/Container";
import TabPanel from "src/utils/TabPanel";
import LaboratoryDelivery from "src/accounting/laboratory/delivery";
import LaboratoryInventory from "src/accounting/laboratory/inventory";
import LaboratoryMonitoring from "src/accounting/laboratory/monitoring";
import LaboratoryItems from "src/accounting/laboratory/items";
import LaboratoryCreateOrder from "src/accounting/laboratory/orders";
import SalesLaboratory from "src/accounting/laboratory/sales";

const AccountingLaboratory = () => {
  const [category, setCategory] = useState("sales");

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Laboratory",
        items: [{ name: "Dashboard", path: "/sph/app/accounting" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Laboratory</Box>
          <Box maxWidth={450}>
            <Paper>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab value="sales" label="Sales" />
                <Tab value="delivery" label="Delivery" />
                <Tab value="inventory" label="Inventory" />
                <Tab value="monitoring" label="Monitoring" />
                <Tab value="item" label="Item" />
                <Tab value="test" label="Order" />
              </Tabs>
            </Paper>
          </Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TabPanel value={category} index="sales">
            <SalesLaboratory />
          </TabPanel>
          <TabPanel value={category} index="delivery">
            <LaboratoryDelivery />
          </TabPanel>
          <TabPanel value={category} index="inventory">
            <LaboratoryInventory />
          </TabPanel>
          <TabPanel value={category} index="monitoring">
            <LaboratoryMonitoring />
          </TabPanel>
          <TabPanel value={category} index="item">
            <LaboratoryItems />
          </TabPanel>
          <TabPanel value={category} index="test">
            <LaboratoryCreateOrder />
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccountingLaboratory;
