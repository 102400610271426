import {
  Box,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
  TableFooter,
  Button,
  Divider,
  useTheme,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { Send } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import Select from "react-select";
import IsMountedRef from "src/utils/IsMountedRef";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const VanPatientCreateOrder = ({
  selectedBranch,
  branchList,
  getAllBranches,
  accreditedCompany,
  patient_id,
  getDoctorsPatient,
  getPatientInformation,
  info,
}) => {
  const theme = useTheme();
  const { users } = useContext(UsersData);
  const [packageList, setPackageList] = useState([]);
  const [laboratoryList, setLaboratoryList] = useState([]);
  const [imagingList, setImagingList] = useState([]);
  const [psychologyList, setPsychologyList] = useState([]);
  const [otherList, setOtherList] = useState([]);
  const mounted = IsMountedRef();
  const [isprocessCancel, setIsProcessCancel] = useState(false);
  // const [selectedBranch, setSelectedBranch] = useState(null);
  const [cancelOrder, setCancelOrder] = useState({
    data: null,
    dialog: false,
  });
  const [billingDetails, setBillingDetails] = useState({
    data: [],
    ready: false,
  });
  const [doctorList, setDoctorList] = useState({
    data: [],
    ready: false,
  });

  const [hmoList, setHmoList] = useState({ data: [], ready: false });

  var totalorder = 0;

  const [paidDialog, setPaidDialog] = useState({
    data: null,
    open: false,
  });

  const [paymentTypeWalkIn, setPaymentTypeWalkIn] = useState("");
  const [isprocessPaid, setIsProcessPaid] = useState(false);
  const [category, setCategory] = useState("");

  const calculateOrder = (amount) => {
    totalorder += parseFloat(amount) * 1;
    return parseFloat(amount);
  };

  const gethandlechangeByBranch = useCallback(async () => {
    var params = { management_id: selectedBranch };
    let response = await axios.get("cashier/get/package-list", {
      params,
    });
    if (mounted.current) {
      setPackageList(response.data);
    }
  }, [mounted, selectedBranch]);

  const gethandleChangeByBranchLab = useCallback(async () => {
    var params = { management_id: selectedBranch };
    let response = await axios.get("cashier/get/laboratory-list", {
      params,
    });
    if (mounted.current) {
      setLaboratoryList(response.data);
    }
  }, [mounted, selectedBranch]);

  const gethandleChangeByBranchImaging = useCallback(async () => {
    var params = { management_id: selectedBranch };
    let response = await axios.get("van/imaging/local/imaging-orderlist", {
      params,
    });
    if (mounted.current) {
      setImagingList(response.data);
    }
  }, [mounted, selectedBranch]);

  const gethandleChangeByBranchPsy = useCallback(async () => {
    var params = { management_id: selectedBranch };
    let response = await axios.get("van/get/psychology-list", {
      params,
    });

    if (mounted.current) {
      setPsychologyList(response.data);
    }
  }, [mounted, selectedBranch]);

  const gethandleChangeByBranchOther = useCallback(async () => {
    var params = { management_id: selectedBranch };
    let response = await axios.get("van/get/other-list", {
      params,
    });

    if (mounted.current) {
      setOtherList(response.data);
    }
  }, [mounted, selectedBranch]);

  const gethandleGetPatientBillingDetails = useCallback(() => {
    var params = {
      user_id: users.user_id,
      management_id: selectedBranch,
      patient_id: patient_id,
    };
    axios
      .get("cashier/get/patient/biling-details", { params })
      .then((response) => {
        const data = response.data;
        setBillingDetails({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [patient_id, users.user_id, selectedBranch]);

  const gethandlefetchAllDoctors = useCallback(() => {
    var params = { user_id: users.user_id, management_id: selectedBranch };
    axios
      .get("nurse/get-all-doctors", { params })
      .then((response) => {
        const data = response.data;
        setDoctorList({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users.user_id, selectedBranch]);

  const handleSetHMO = useCallback(
    async (data, company) => {
      setPaymentTypeWalkIn(data.value);
      if (data.value !== "cash") {
        var params = {
          company_id: company,
          management_id: selectedBranch,
        };
        try {
          let response = await axios.get("cashier/get/hmo-by-company-id", {
            params,
          });
          const data = response.data;
          setHmoList({ data, ready: true });
        } catch (error) {
          console.log("error: ", error);
        }
      }
    },
    [selectedBranch]
  );

  //package
  const handleSelectOrderPackage = async (packageinfo) => {
    var trace_number = `trace-${Math.floor(
      Math.random() * 9999
    )}-${new Date().getTime()}`;

    var formdata = new FormData();
    formdata.set("patient_id", patient_id);
    formdata.set("management_id", selectedBranch);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("billname", packageinfo.package_name);
    formdata.set("package_id", packageinfo.package_id);
    formdata.set("rate", packageinfo.order_amount);
    formdata.set("order_id", packageinfo.order_id);
    formdata.append("trace_number", trace_number);

    let response = await axios.post("van/add/package-savetemp", formdata);

    if (response.data.message === "success") {
      gethandleGetPatientBillingDetails();
      Notify.successRequest("Package Order Added.");
    }
  };

  //laboratory
  const handleSelectOrderLaboratory = (orderdetails) => {
    var trace_number = `trace-${Math.floor(
      Math.random() * 9999
    )}-${new Date().getTime()}`;

    var formdata = new FormData();
    formdata.set("patient_id", patient_id);
    formdata.set("management_id", selectedBranch);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("department", orderdetails.category);
    formdata.set("laboratory_test_id", orderdetails.order_id);
    formdata.set("laboratory_test", orderdetails.laborder);
    formdata.set("laboratory_rate", orderdetails.rate);
    formdata.append("trace_number", trace_number);

    // setAddProcess(orderdetails.order_id);

    axios
      .post("van/add/laboratory-savetemp", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          gethandleGetPatientBillingDetails();
          Notify.successRequest("Laboratory Order Added.");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  //imaging
  const handleSelectOrderImaging = (orderdetails) => {
    var trace_number = `trace-${Math.floor(
      Math.random() * 9999
    )}-${new Date().getTime()}`;

    var formdata = new FormData();
    formdata.set("patient_id", patient_id);
    formdata.set("management_id", selectedBranch);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("imaging_order_id", orderdetails.order_id);
    formdata.set("order", orderdetails.order_desc);
    formdata.set("remarks", "ok");
    formdata.set("amount", orderdetails.order_cost);
    formdata.set("imaging_center", selectedBranch);
    formdata.append("trace_number", trace_number);

    axios
      .post("van/add/imaging-savetemp", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          gethandleGetPatientBillingDetails();
          Notify.successRequest("Imaging Order Added.");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  //psychology
  const handleSelectOrderPsychology = (orderdetails) => {
    var trace_number = `trace-${Math.floor(
      Math.random() * 9999
    )}-${new Date().getTime()}`;

    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("management_id", selectedBranch);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("department", orderdetails.department);
    formdata.set("psychology_test_id", orderdetails.ptl_id);
    formdata.set("psychology_test", orderdetails.test);
    formdata.set("psychology_rate", orderdetails.rate);
    formdata.append("trace_number", trace_number);

    axios
      .post("van/add/psychology-savetemp", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          gethandleGetPatientBillingDetails();
          Notify.successRequest("Psychology Order Added.");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleSelectOrderOther = (orderdetails) => {
    var trace_number = `trace-${Math.floor(
      Math.random() * 9999
    )}-${new Date().getTime()}`;

    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("management_id", selectedBranch);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("department", orderdetails.department);
    formdata.set("order_id", orderdetails.order_id);
    formdata.set("order_name", orderdetails.order_name);
    formdata.set("order_amount", orderdetails.order_amount);
    formdata.append("trace_number", trace_number);

    axios
      .post("van/add/other-savetemp", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          gethandleGetPatientBillingDetails();
          Notify.successRequest("Other Order Added.");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleCancelOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    var error = [];
    formdata.set("patient_id", patient_id);
    formdata.set("username", users.username);

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      Notify.consoleLog("form error.");
    } else {
      setIsProcessCancel(true);
      axios
        .post("van/billing/cancel-bill", formdata)
        .then((response) => {
          const data = response.data;

          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            e.target.reset();
            setCancelOrder({ data: null, dialog: false });
            gethandleGetPatientBillingDetails();
            Notify.successRequest("billing cancel");
          }
          if (data === "db-error") {
            Notify.warnRequest("billing cancel");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsProcessCancel(false));
    }
  };

  const handleSetAsPaid = (e) => {
    e.preventDefault();
    e.persist();

    var receipt_number = `receipt-${Math.floor(
      100000 + Math.random() * new Date().getTime()
    )}`;

    var formdata = new FormData(e.target);
    var error = [];
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("username", users.username);
    formdata.set("receipt_number", receipt_number);
    formdata.set("management_id", selectedBranch);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    if (
      formdata.get("amountto_pay").length === 0 ||
      formdata.get("amountto_pay").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("amountto_pay");
    }

    if (paymentTypeWalkIn === "hmo") {
      if (
        formdata.get("hmo").length === 0 ||
        formdata.get("hmo").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("hmo");
      }
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      Notify.consoleLog("form error.");
    } else {
      setIsProcessPaid(true);
      axios
        .post("van/billing/setaspaid-bill", formdata)
        .then((response) => {
          const data = response.data;

          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            e.target.reset();
            setPaidDialog({ data: null, open: false });
            gethandleGetPatientBillingDetails();
            getDoctorsPatient();
            Notify.successRequest("billing save");
          }
          if (data === "db-error") {
            Notify.warnRequest("billing save");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsProcessPaid(false));
    }
  };

  useEffect(() => {
    gethandlechangeByBranch();
    gethandleChangeByBranchLab();
    gethandleChangeByBranchImaging();
    gethandleChangeByBranchPsy();
    gethandleChangeByBranchOther();
    gethandleGetPatientBillingDetails();
    gethandlefetchAllDoctors();
  }, [
    patient_id,
    gethandlechangeByBranch,
    gethandleChangeByBranchLab,
    gethandleChangeByBranchImaging,
    gethandleChangeByBranchPsy,
    gethandleChangeByBranchOther,
    gethandleGetPatientBillingDetails,
    gethandlefetchAllDoctors,
    handleSetHMO,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={selectedBranch === null ? 12 : 8}>
        <Box component={Paper} variant="outlined" p={2}>
          <Box className={`gtc-textfield-noborder`} mb={2}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box flexGrow={1}>
                <Typography
                  color="primary"
                  variant="subtitle1"
                  style={{ fontWeight: "bolder" }}
                >
                  ORDER LIST
                </Typography>
              </Box>
              {/* <Box width={200}>
                <Select
                  options={branchList.data}
                  onChange={(data) => {
                    setSelectedBranch(data.management_id);
                    handlechangeByBranch(data.management_id);
                    handleChangeByBranchLab(data.management_id);
                    handleChangeByBranchImaging(data.management_id);
                    handleChangeByBranchPsy(data.management_id);
                    handleGetPatientBillingDetails(data.management_id);
                    handlefetchAllDoctors(data.management_id);
                  }}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 99999,
                      color: "#000",
                    }),
                    control: (base) => ({
                      ...base,
                      minHeight: 55,
                      backgroundColor: "transparent",
                      color: theme.palette.type === "dark" ? "#fff" : "#fff",
                    }),
                    singleValue: () => ({
                      color: theme.palette.type === "dark" ? "#fff" : "#000",
                    }),
                  }}
                />
              </Box> */}
            </Box>
          </Box>
          <Divider light />
          <Box hidden={selectedBranch === null ? true : false}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Order</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Order Rate</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Action</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billingDetails.ready ? (
                    billingDetails.data.length > 0 ? (
                      billingDetails.data.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography className="text-capitalize">
                              {data.bill_name}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {Notify.convertToNumber(
                              calculateOrder(data.bill_amount)
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip arrow title="Cancel Order">
                              <IconButton
                                color="secondary"
                                hidden={data.bill_from === "appointment"}
                                onClick={() =>
                                  setCancelOrder({ data: data, dialog: true })
                                }
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow align="center">
                        <TableCell colSpan={3} align="center">
                          <Typography variant="subtitle1" color="secondary">
                            No record found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow align="center">
                      <TableCell colSpan={3} align="center">
                        <Typography variant="subtitle1" color="primary">
                          Please wait, Loading...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={3} align="right">
                      <Button
                        variant="outlined"
                        color="primary"
                        // onClick={() => console.log("sample click")}
                        onClick={() =>
                          setPaidDialog({ data: totalorder, open: true })
                        }
                        startIcon={<Send />}
                      >
                        Confirm
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={selectedBranch === null ? 12 : 4}>
        <Box
          component={Paper}
          variant="outlined"
          p={2}
          hidden={selectedBranch === null ? true : false}
        >
          <Box className={`gtc-textfield-noborder`} mb={2}>
            <Box display="flex">
              <Typography
                color="primary"
                variant="subtitle1"
                style={{ fontWeight: "bolder" }}
              >
                CREATE ORDER
              </Typography>
            </Box>
          </Box>
          <Divider light />
          <Box mt={1}>
            <Box>
              <Typography variant="caption">
                <strong>SELECT CATEGORY</strong>
              </Typography>
            </Box>

            <TextField
              fullWidth
              name="nature_of_examination"
              select
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select...</option>
              <option value="LABORATORY">Laboratory</option>
              <option value="IMAGING">Imaging</option>
              <option value="PSYCHOLOGY">Psychology</option>
              <option value="OTHERS">Others</option>
              <option value="PACKAGES">Packages</option>
            </TextField>
          </Box>

          {category === "LABORATORY" && (
            <Box mt={1}>
              <Box>
                <Typography variant="caption">
                  <strong>SELECT LAB TEST</strong>
                </Typography>
              </Box>
              <Select
                options={laboratoryList}
                onChange={(data) => handleSelectOrderLaboratory(data)}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 99999,
                    color: "#000",
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: 55,
                    backgroundColor: "transparent",
                    color: theme.palette.type === "dark" ? "#fff" : "#fff",
                  }),
                  singleValue: () => ({
                    color: theme.palette.type === "dark" ? "#fff" : "#000",
                  }),
                }}
              />
            </Box>
          )}

          {category === "IMAGING" && (
            <Box mt={1}>
              <Box>
                <Typography variant="caption">
                  <strong>SELECT IMAGING TEST</strong>
                </Typography>
              </Box>
              <Select
                options={imagingList}
                onChange={(data) => handleSelectOrderImaging(data)}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 99999,
                    color: "#000",
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: 55,
                    backgroundColor: "transparent",
                    color: theme.palette.type === "dark" ? "#fff" : "#fff",
                  }),
                  singleValue: () => ({
                    color: theme.palette.type === "dark" ? "#fff" : "#000",
                  }),
                }}
              />
            </Box>
          )}

          {category === "PSYCHOLOGY" && (
            <Box mt={1}>
              <Box>
                <Typography variant="caption">
                  <strong>SELECT PSYCHOLOGY TEST</strong>
                </Typography>
              </Box>
              <Select
                options={psychologyList}
                onChange={(data) => handleSelectOrderPsychology(data)}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 99999,
                    color: "#000",
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: 55,
                    backgroundColor: "transparent",
                    color: theme.palette.type === "dark" ? "#fff" : "#fff",
                  }),
                  singleValue: () => ({
                    color: theme.palette.type === "dark" ? "#fff" : "#000",
                  }),
                }}
              />
            </Box>
          )}

          {category === "OTHERS" && (
            <Box mt={1}>
              <Box>
                <Typography variant="caption">
                  <strong>SELECT OTHER TEST</strong>
                </Typography>
              </Box>
              <Select
                options={otherList}
                onChange={(data) => handleSelectOrderOther(data)}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 99999,
                    color: "#000",
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: 55,
                    backgroundColor: "transparent",
                    color: theme.palette.type === "dark" ? "#fff" : "#fff",
                  }),
                  singleValue: () => ({
                    color: theme.palette.type === "dark" ? "#fff" : "#000",
                  }),
                }}
              />
            </Box>
          )}

          {category === "PACKAGES" && (
            <Box mt={1}>
              <Box>
                <Typography variant="caption">
                  <strong>SELECT PACKAGE</strong>
                </Typography>
              </Box>
              <Select
                options={packageList}
                onChange={(data) => handleSelectOrderPackage(data)}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 99999,
                    color: "#000",
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: 55,
                    backgroundColor: "transparent",
                    color: theme.palette.type === "dark" ? "#fff" : "#fff",
                  }),
                  singleValue: () => ({
                    color: theme.palette.type === "dark" ? "#fff" : "#000",
                  }),
                }}
              />
            </Box>
          )}
        </Box>

        <Dialog
          open={cancelOrder.dialog}
          onClose={() => setCancelOrder({ data: null, dialog: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          {cancelOrder.data && cancelOrder.dialog && (
            <form onSubmit={handleCancelOrder}>
              <DialogTitle>Verify cancel</DialogTitle>
              <DialogContent dividers>
                <Box mb={2}>
                  <TextField
                    label={"Trace Number"}
                    variant="outlined"
                    name="trace_number"
                    defaultValue={cancelOrder.data.trace_number}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    hidden
                  />

                  <TextField
                    label={"Department"}
                    variant="outlined"
                    name="bill_department"
                    defaultValue={cancelOrder.data.bill_department}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    hidden
                  />

                  <TextField
                    label={"Order ID"}
                    variant="outlined"
                    name="order_id"
                    defaultValue={cancelOrder.data.order_id}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    hidden
                  />

                  <TextField
                    label={"Id"}
                    variant="outlined"
                    name="cancel_id"
                    defaultValue={cancelOrder.data.cpb_id}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    hidden
                  />

                  <TextField
                    label={"Name"}
                    variant="outlined"
                    name="bill_name"
                    defaultValue={cancelOrder.data.bill_name}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Box>
                <Box>
                  <TextField
                    label={"Enter password"}
                    variant="outlined"
                    name="password"
                    type="password"
                    fullWidth
                  />
                </Box>
              </DialogContent>

              <DialogActions>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => setCancelOrder({ data: null, dialog: false })}
                >
                  No
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isprocessCancel}
                  startIcon={
                    isprocessCancel && (
                      <CircularProgress size={20} color="inherit" />
                    )
                  }
                >
                  Cancel Order
                </Button>
              </DialogActions>
            </form>
          )}
        </Dialog>

        <Dialog
          open={paidDialog.open}
          onClose={() => setPaidDialog({ data: null, open: false })}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          fullWidth
        >
          <form onSubmit={handleSetAsPaid}>
            <DialogTitle>Bills Payment Dialog</DialogTitle>

            <DialogContent dividers>
              <Box mb={2}>
                <TextField
                  required
                  fullWidth
                  label="Amount to pay"
                  variant="outlined"
                  value={paidDialog.data !== null ? paidDialog.data : ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  required
                  fullWidth
                  label="Amount to pay"
                  variant="outlined"
                  value={paidDialog.data !== null ? paidDialog.data : ""}
                  InputProps={{
                    readOnly: true,
                  }}
                  name="amountto_pay"
                  hidden
                />

                <TextField
                  required
                  fullWidth
                  label=""
                  variant="outlined"
                  value={
                    billingDetails.data.length > 0
                      ? billingDetails.data[0].companyyy
                      : ""
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  name="patient_company"
                  hidden
                />
              </Box>

              {billingDetails.data.length > 0 &&
                billingDetails.data[0].totalOtherToDoctor > 0 && (
                  <Box mb={2}>
                    <TextField
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name="doctor"
                      label="Doctor"
                      variant="outlined"
                      required={
                        parseFloat(billingDetails.data[0].totalOtherToDoctor) >
                        0
                          ? true
                          : false
                      }
                    >
                      <option value={""}> Select </option>
                      {doctorList.data.length > 0 &&
                        doctorList.data &&
                        doctorList.data.map((data, index) => {
                          return (
                            <option key={index} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                    </TextField>
                  </Box>
                )}

              <Box mb={2}>
                <TextField
                  required
                  fullWidth
                  label="Payment Type"
                  variant="outlined"
                  name="payment_type"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                  value={paymentTypeWalkIn}
                  onChange={(e) =>
                    handleSetHMO(e.target, billingDetails.data[0].companyyy)
                  }
                >
                  <option value=""> Select </option>
                  <option value="hmo"> HMO </option>
                  <option value="direct">
                    Direct to{" "}
                    {billingDetails.data.length > 0
                      ? billingDetails.data[0].accredited_company
                      : ""}
                  </option>
                </TextField>
              </Box>

              {paymentTypeWalkIn === "hmo" && (
                <Box mb={2}>
                  <TextField
                    required
                    fullWidth
                    label="HMO"
                    variant="outlined"
                    name="hmo"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> Select </option>
                    {hmoList.ready &&
                      hmoList.data.map((data, index) => (
                        <option value={data.mach_id} key={index}>
                          {data.hmo}
                        </option>
                      ))}
                  </TextField>
                </Box>
              )}

              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Note"
                  variant="outlined"
                  name="note"
                  defaultValue="Service must be availed within 7 days"
                  hidden
                />
              </Box>

              <Box>
                <TextField
                  required
                  fullWidth
                  label="Enter password"
                  variant="outlined"
                  type="password"
                  name="password"
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setPaidDialog({ data: null, open: false })}
              >
                No
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isprocessPaid}
                startIcon={
                  isprocessPaid && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                Yes
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default VanPatientCreateOrder;
