import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  useTheme,
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import Select from "react-select";

const New = ({ rerender }) => {
  const theme = useTheme();
  const { users } = useContext(UsersData);
  // const [selectItem, setSelectedItem] = useState(null);
  const [selectedItemCategory, setSelectedCategory] = useState(null);

  const [list, setList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const getFormData = (object) => {
    const formData = new FormData();
    formData.append("item", selectedItemCategory.brand);
    formData.append("product_id", selectedItemCategory.product_id);
    formData.append("description", selectedItemCategory.product_generic);
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const getItemLab = React.useCallback(async () => {
    let response = await axios.get("accounting/get/haptech/product-list", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
        user_id: users.user_id,
      },
    });
    setList(response.data);
  }, [users]);

  const handleSelectedItem = async (data) => {
    let response = await axios.get("accounting/get/haptech/product-desc", {
      params: {
        item_id: data.value,
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
        user_id: users.user_id,
      },
    });
    setCategoryList(response.data);
  };

  const handleSelectedDesc = async (data) => {
    setSelectedCategory(data);
  };

  useEffect(() => {
    getItemLab();
  }, [getItemLab]);

  return (
    <Card>
      <CardHeader title="New Reagent" />
      <CardContent>
        <Formik
          enableReinitialize
          initialValues={{
            username: users.username,
            management_id: users.management_id,
            user_id: users.user_id,
            supplier: "",
            unit: "",
            msrp: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            supplier: Yup.string().trim().required(),
            unit: Yup.string().trim().required(),
            msrp: Yup.number().required(),
            password: Yup.string().trim().required(),
          })}
          onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);
              const response = await axios.post(
                "accounting/test/items/new-item",
                getFormData(values)
              );

              if (response.data.message === "pass-invalid") {
                setErrors({ password: "Password invalid." });
              }

              if (response.data.message === "product-existed") {
                setErrors({ password: "Item already added." });
              }

              if (response.data.message === "success") {
                Notify.successRequest("item added");
                rerender();
                resetForm();
              }

              setSubmitting(false);
            } catch (e) {
              console.log("error", e);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Box>
                <Select
                  options={list}
                  onChange={(data) => handleSelectedItem(data)}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 99999,
                      color: "#000",
                    }),
                    control: (base) => ({
                      ...base,
                      minHeight: 55,
                      backgroundColor: "transparent",
                    }),
                    singleValue: () => ({
                      color: theme.palette.type === "dark" ? "#fff" : "#000",
                    }),
                  }}
                />
              </Box>

              <Box mt={2}>
                <Select
                  options={categoryList}
                  onChange={(data) => handleSelectedDesc(data)}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 99999,
                      color: "#000",
                    }),
                    control: (base) => ({
                      ...base,
                      minHeight: 55,
                      backgroundColor: "transparent",
                    }),
                    singleValue: () => ({
                      color: theme.palette.type === "dark" ? "#fff" : "#000",
                    }),
                  }}
                />
              </Box>

              <Box mt={2}>
                <TextField
                  error={Boolean(touched.supplier && errors.supplier)}
                  helperText={touched.supplier && errors.supplier}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.supplier}
                  fullWidth
                  required
                  multiline
                  name="supplier"
                  label="Item Supplier"
                  variant="outlined"
                />
              </Box>

              <Box mt={2}>
                <TextField
                  error={Boolean(touched.unit && errors.unit)}
                  helperText={touched.unit && errors.unit}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.unit}
                  fullWidth
                  required
                  multiline
                  name="unit"
                  label="Item Unit"
                  variant="outlined"
                />
              </Box>

              <Box mt={2}>
                <TextField
                  error={Boolean(touched.msrp && errors.msrp)}
                  helperText={touched.msrp && errors.msrp}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.msrp}
                  fullWidth
                  required
                  name="msrp"
                  label="Item Msrp"
                  variant="outlined"
                />
              </Box>
              <Box mt={3}>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  fullWidth
                  required
                  name="password"
                  type="password"
                  label="Password"
                  variant="outlined"
                />
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Box mt={3} display="flex">
                <Box mr={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    startIcon={
                      isSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    size="large"
                    color="default"
                    onClick={resetForm}
                  >
                    Clear
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default New;
