import React, { useContext, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import {
	Avatar,
	Dialog,
	Zoom,
	IconButton,
	makeStyles,
	Badge,
	CircularProgress,
	Button,
	Paper,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import SwipeableViews from "react-swipeable-views";
import { CancelOutlined, Send, CameraAlt } from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Cropper from "react-cropper";
import { calculateAgeV2, calculateAgeV3, formatDate } from "src/helpers/utils";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import CreateAppointmentFormModal from "../patientlist/components/CreateAppointmentFormModal";
import AddPatientToQueueModal from "../patientlist/components/AddPatientToQueueModal";
// import { SRLWrapper } from "simple-react-lightbox";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "0 30px",
		width: "100%",
	},
	image: {
		marginTop: 25,
		marginBottom: 55,
		height: isMobile ? 250 : 400,
		border: "1px solid #aaa",
		margin: "auto",
		display: "block",
	},
	tag: {
		zIndex: 1,
		position: "absolute",
		paddingTop: 26,
		marginLeft: 38,
	},
	tag_label: {
		backgroundColor: "#00000094",
		color: "#fff",
		padding: 2,
		paddingLeft: 10,
		paddingRight: 10,
		borderRadius: 10,
	},
}));

const PatientHeaderDetails = ({ getPatientInformation, patient_id, info }) => {
	const create_appointment_ref = useRef(null);
	const add_patient_to_queue_ref = useRef(null);

	const [openLightbox, setOpenLightbox] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const classes = useStyles();
	const [current, setCurrent] = useState(0);
	const { users } = useContext(UsersData);
	const [_uploadprocess, setUploadprocess] = useState(false);
	const [imgUploadTemp, setImgUploadTemp] = useState(null);
	const [openCropImage, setOpenCropImage] = useState(false);
	const [image, setImage] = useState(null);

	var profileimage = useRef();
	var cropperRef = useRef();

	const handleClickupload = () => {
		profileimage.click();
	};

	const handleUploadImage = (event) => {
		var file = event.target.files[0];
		if (file !== undefined) {
			var reader = new FileReader();
			reader.onloadend = function () {
				var b64 = reader.result;
				setImgUploadTemp(b64);
			};
			reader.readAsDataURL(file);
			setOpenCropImage(true);
		}
	};

	const handleUpdatePicture = () => {
		var formdata = new FormData();
		formdata.append("user_id", users.user_id);
		formdata.append("managament_id", users.managament_id);
		formdata.append("main_mgmt_id", users.main_mgmt_id);
		formdata.append("patient_id", patient_id);
		formdata.append("image", image);
		axios
			.post("nurse/update/patient-prof-pic", formdata)
			.then((response) => {
				const data = response.data;
				if (data === "success") {
					Notify.successRequest("profile");
					getPatientInformation();
					setOpenCropImage(false);
					setImage(null);
				}
			})
			.catch((error) => {
				Notify.requestError(error);
			})
			.finally(() => {
				setUploadprocess(false);
			});
	};

	return (
		<>
			<Box component={Paper} variant="outlined" borderRadius={4} p={2}>
				{info.ready ? (
					info.data ? (
						<div className="clearfix">
							<div className="float-left">
								{/* <SRLWrapper options={options}> */}
								{/* <Badge
									overlap="circle"
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
									badgeContent={
										_uploadprocess ? (
											<CircularProgress color="inherit" />
										) : (
											<Box
											// hidden={info.data.image !== null}
											>
												<CameraAlt
													style={{
														width: isMobile
															? 30
															: window.innerWidth >=
																	899 &&
															  window.innerWidth <=
																	959
															? 60
															: window.innerWidth <=
															  898
															? 50
															: 40,
														height: isMobile
															? 30
															: window.innerWidth >=
																	899 &&
															  window.innerWidth <=
																	959
															? 60
															: window.innerWidth <=
															  898
															? 50
															: 40,
														cursor: "pointer",
													}}
													onClick={handleClickupload}
												/>
											</Box>
										)
									}
								> */}
								<Avatar
									style={{
										width: "120px",
										height: "120px",
										cursor: "pointer",
									}}
									alt="profile-pic"
									src={
										info.data.image !== null
											? imageLocation +
											  "patients/" +
											  info.data.image
											: ""
									}
									onClick={() => {
										setSelectedImage(info.data.image);
										setOpenLightbox(true);
									}}
								>
									{info.data.image === null ? "No Image" : ""}
								</Avatar>
								<input
									type="file"
									onChange={handleUploadImage}
									className="d-none"
									ref={(ref) => (profileimage = ref)}
								/>
								{/* </Badge> */}
								{/* </SRLWrapper> */}
							</div>
							<div className="ml-sm-4 float-left">
								<p className="h5 header-label text-capitalize m-0 my-2 font-weight-bold">
									{info.data.lastname}, {info.data.firstname}{" "}
									{info.data.middle}
								</p>
								<p className="m-0 my-2">
									<span className="text-muted gtc-small text-uppercase">
										{" "}
										Age:{" "}
									</span>{" "}
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
									{/* {Notify.calculateAge(info?.data?.birthday)} years old */}
									{info?.data?.birthday &&
										calculateAgeV3(
											new Date(info?.data?.birthday)
										)}{" "}
									old
								</p>
								<p className="m-0 my-2">
									<span className="text-muted gtc-small text-uppercase">
										{" "}
										Gender:{" "}
									</span>{" "}
									&nbsp;&nbsp;{" "}
									{info?.data?.gender?.includes("fem") ||
									info?.data?.gender?.includes("Fem")
										? "Female"
										: "Male"}
								</p>
								<p className="m-0 my-2">
									<span className="text-muted gtc-small text-uppercase">
										{" "}
										Birthday:{" "}
									</span>{" "}
									{formatDate(info?.data?.birthday)}
									{/* {Notify.birthday(info?.data?.birthday)} */}
								</p>
							</div>
							<div className="ml-sm-4 float-right flex items-center gap-4">
								<ActionBtn
									type="primary"
									onClick={() => {
										create_appointment_ref.current.show(
											info?.data
										);
									}}
								>
									<FlatIcon icon="rr-calendar-plus" /> Create
									Appointment
								</ActionBtn>
								<ActionBtn
									type="success"
									onClick={() => {
										add_patient_to_queue_ref.current.show({
											patient: info?.data,
										});
									}}
								>
									<FlatIcon icon="rr-layer-plus" />
									Add patient to Queue
								</ActionBtn>
							</div>
						</div>
					) : (
						Notify.noRecord()
					)
				) : (
					Notify.loading()
				)}
			</Box>

			<Dialog
				open={openLightbox}
				onClose={() => setOpenLightbox(false)}
				TransitionComponent={Zoom}
				transitionDuration={800}
				maxWidth="sm"
				fullWidth
			>
				<Box display="flex">
					<Box flexGrow={1} />
					<IconButton
						color="secondary"
						onClick={() => {
							setOpenLightbox(false);
						}}
					>
						<CancelOutlined />
					</IconButton>
				</Box>
				<Box>
					<SwipeableViews
						className={classes.root}
						slideStyle={{ padding: "0 10px" }}
						enableMouseEvents
						index={current}
						onChangeIndex={(e) => setCurrent(e)}
					>
						<Box>
							<img
								alt="attach-file-pt"
								src={`${imageLocation}/patients/${selectedImage}`}
								className={classes.image}
							/>
						</Box>
					</SwipeableViews>
				</Box>
			</Dialog>

			{/* for copping */}
			<Dialog
				open={openCropImage}
				fullScreen
				onClose={() => setOpenCropImage(false)}
				TransitionComponent={Zoom}
				transitionDuration={900}
			>
				<Box
					display="flex"
					width="100%"
					height="80vh"
					justifyContent="center"
					alignItems="center"
				>
					<Cropper
						style={{ height: "100%", width: "100%" }}
						zoomTo={0.5}
						aspectRatio={1}
						src={image === null ? imgUploadTemp : image}
						viewMode={1}
						guides={false}
						background={false}
						responsive={true}
						dragMode={"move"}
						cropBoxResizable={true}
						checkOrientation={false}
						rotatable
						scalable
						ref={cropperRef}
					/>
				</Box>
				<Box
					display="flex"
					width="100%"
					height={"20vh"}
					justifyContent="center"
					alignItems="center"
				>
					<Box mr={2}>
						<Button
							color="primary"
							variant="contained"
							startIcon={<Send />}
							disabled={imgUploadTemp === null}
							onClick={() => {
								const imageElement = cropperRef.current;
								const cropper = imageElement.cropper;
								setImage(
									cropper.getCroppedCanvas().toDataURL()
								);
								setImgUploadTemp(null);
								// setOpenCropImage(false);
								// handleUpdatePicture();
							}}
						>
							Confirm
						</Button>
					</Box>

					<Box mr={2}>
						<Button
							color="primary"
							variant="contained"
							startIcon={<Send />}
							disabled={image === null}
							onClick={handleUpdatePicture}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Dialog>
			<CreateAppointmentFormModal ref={create_appointment_ref} />
			<AddPatientToQueueModal ref={add_patient_to_queue_ref} />
		</>
	);
};

export default PatientHeaderDetails;
