import React, { useRef } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormHelperText,
  Paper,
  useTheme,
} from "@material-ui/core";
import Axios from "axios";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import Select from "react-select";
import LaboratoryOrder from "src/utils/LaboratoryOrder";
import { UsersData } from "src/ContextAPI";

const CreateLaboratoryOrder = ({ getTempOrderList }) => {
  const { users } = React.useContext(UsersData);
  const theme = useTheme();
  const orderRef = useRef(null);

  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const filetedOrder = (department) => {
    const orderlist = LaboratoryOrder.list();

    let test = orderlist.filter((data) => {
      return data.department === department;
    });

    return test;
  };
  return (
    <Box component={Paper} p={2}>
      <Box>
        <Typography variant="h6" color="textSecondary">
          Create Order
        </Typography>
      </Box>
      <Box>
        <Formik
          initialValues={{
            username: users.username,
            management_id: users.management_id,
            user_id: users.user_id,
            test: "",
            dept: "",
            can_be_discounted: "",
            rate: "",
            mobile_rate: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            test: Yup.string().required("Laboratort test is required field"),
            can_be_discounted: Yup.string().required(
              "Order can be discounted or not is required field"
            ),
            password: Yup.string().required("Password is required field"),
            dept: Yup.string().required("Department rate is required field"),
            rate: Yup.number()
              .min(1)
              .required("Laboratory rate is required field"),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            try {
              const request = await Axios.post(
                "accounting/items/laborder/create-tempordernoitem",
                getFormData(values)
              );
              if (request.data.message === "pass-invalid") {
                setErrors({ password: "Password is invalid." });
              }
              if (request.data.message === "success") {
                Notify.successRequest("new test");
                resetForm();
                setSubmitting(true);
                getTempOrderList();
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            touched,
            resetForm,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Box my={2} className={`labselect`}>
                <Select
                  options={LaboratoryOrder.dept()}
                  defaultValue={LaboratoryOrder.dept().filter(
                    (x) => x.value === values.dept
                  )}
                  onChange={(data) => setFieldValue("dept", data.value)}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 99999,
                      color: "#000",
                    }),
                    control: (base) => ({
                      ...base,
                      minHeight: 55,
                      backgroundColor: "transparent",
                    }),
                    singleValue: () => ({
                      color: theme.palette.type === "dark" ? "#fff" : "#000",
                    }),
                  }}
                />
                {touched.dept && errors.dept && (
                  <FormHelperText error> {errors.dept} </FormHelperText>
                )}
              </Box>

              <Box my={2} className={`labselect`}>
                <Select
                  options={filetedOrder(values.dept)}
                  ref={orderRef}
                  onChange={(data) => setFieldValue("test", data.value)}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 99999,
                      color: "#000",
                    }),
                    control: (base) => ({
                      ...base,
                      minHeight: 55,
                      backgroundColor: "transparent",
                    }),
                    singleValue: () => ({
                      color: theme.palette.type === "dark" ? "#fff" : "#000",
                    }),
                  }}
                />
                {touched.test && errors.test && (
                  <FormHelperText error> {errors.test} </FormHelperText>
                )}
              </Box>

              <Box mb={2} className={`labselect2`}>
                <TextField
                  error={Boolean(
                    touched.can_be_discounted && errors.can_be_discounted
                  )}
                  helperText={
                    touched.can_be_discounted && errors.can_be_discounted
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.can_be_discounted}
                  select
                  label="Order can be discounted?"
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  name="can_be_discounted"
                >
                  <option value=""> Select </option>
                  <option value={1}> Yes </option>
                  <option value={0}> No </option>
                </TextField>
              </Box>

              <Box mb={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.rate && errors.rate)}
                  helperText={touched.rate && errors.rate}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.rate}
                  fullWidth
                  required
                  name="rate"
                  label="Laboratory Rate"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 10000,
                      step: 0.01,
                    },
                  }}
                />
              </Box>

              <Box mb={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.mobile_rate && errors.mobile_rate)}
                  helperText={touched.mobile_rate && errors.mobile_rate}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mobile_rate}
                  fullWidth
                  required
                  name="mobile_rate"
                  label="Mobile Rate"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 10000,
                      step: 0.01,
                    },
                  }}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Box my={2} display="flex">
                <Box mr={2}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon />}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="default"
                    size="large"
                    onClick={resetForm}
                    startIcon={<ClearIcon />}
                  >
                    Clear
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default CreateLaboratoryOrder;
