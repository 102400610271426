import {
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Radio,
	RadioGroup,
	TextField,
	FormControl,
	FormLabel,
} from "@material-ui/core";

import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
const MagicInputFields = ({
	label = "",
	name = `key-${uuidv4()}`,
	type = "text",
	className,
	SelectProps,
	InputLabelProps,
	variant = "outlined",
	options,
	control,
	InputProps,
	error,
	rules,
	selectPlaceholder,
	helperText = "This field is required.",
	...rest
}) => {
	switch (type) {
		case "checkbox":
			return (
				<FormGroup className={className}>
					<FormLabel error={error} id={`checkbox-${name}`}>
						{label}
					</FormLabel>
					{error && (
						<FormHelperText error={error}>
							{helperText}
						</FormHelperText>
					)}

					<div className="flex flex-col gap-y-1 py-3">
						{options.map((option, index) => {
							return (
								<label className="flex items-center gap-3">
									<span className="scale-[1.15]">
										<input
											type="checkbox"
											value={index + 1}
											{...rest}
										/>
									</span>
									<span className="text-sm form-label">
										{option.label}
									</span>
								</label>
							);
						})}
					</div>
				</FormGroup>
			);
		case "radio":
			return (
				<FormControl className={className}>
					<FormLabel
						id={`radio-${name}`}
						className="flex"
						error={Boolean(error)}
					>
						{label}
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{Boolean(error) && (
						<FormHelperText error={Boolean(error)}>
							{helperText}
						</FormHelperText>
					)}
					<Controller
						rules={rules}
						name={name}
						control={control}
						render={({ field }) => (
							<RadioGroup {...field}>
								{options.map((option, index) => {
									return (
										<FormControlLabel
											className="!mb-1"
											control={<Radio />}
											value={option.value}
											label={option.label}
										/>
									);
								})}
							</RadioGroup>
						)}
					/>
				</FormControl>
			);
		case "select":
			return (
				<TextField
					id={`select-${name}`}
					className={`w-full lg:w-1/3 ${className}`}
					label={label}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					InputProps={InputProps}
					error={error ? true : false}
					helperText={helperText}
					{...rest}
				>
					{selectPlaceholder ? (
						selectPlaceholder
					) : (
						<option value=""> SELECT </option>
					)}
					{options.map((option) => (
						<option
							value={option.id}
							disabled={option?.disabled ? "disabled" : ""}
						>
							{option?.name}
						</option>
					))}
				</TextField>
			);
		default:
			return (
				<TextField
					id={`TextField-${name}`}
					className={className}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={label}
					variant={variant}
					type={type}
					error={error ? true : false}
					helperText={error && helperText}
					name={name}
					{...rest}
				/>
			);
	}
};

export default MagicInputFields;
