import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  TextField,
  Card,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { styles } from "../../styles/styles";
import axios from "axios";
import Notify from "src/notification/Notify";
import ButtonV3 from "src/components/ButtonV3";

export default function EditModalDiet({
  open,
  onClose,
  meals,
  date,
  description,
  id,
}) {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    let formData = new FormData();
    formData.append("meals", data?.meals || "");
    formData.append("date", data?.date || "");
    formData.append("description", data?.description || "");

    axios
      .patch(`v1/patient/diet/update/${id}`, formData)
      .then((response) => {
        Notify.edit("Diet");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setValue("date", date);
    setValue("description", description);
    setValue("meals", meals);
  }, [date, description, meals, setValue]);

  return (
    <Dialog open={open} onClose={onClose}>
      <form>
        <DialogTitle>
          <div className="flex justify-between items-center">
            <Box>
              <Typography variant="h5" color="textSecondary">
                <strong>Edit Diet</strong>
              </Typography>
            </Box>
            <button
              className="text-red-600 hover:bg-red-300 py-2 px-3  rounded-lg "
              onClick={onClose}
              type="button"
            >
              CLOSE
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <Card elevation={3} className="pt-4  px-2" component={Box}>
            <div className="w-[500px]">
              <div style={styles.divContainerColumn}>
                <div style={styles.divContainer}>
                  <TextField
                    label={
                      <label style={styles.label}>
                        Meals<span style={styles.astirisColor}>*</span>
                      </label>
                    }
                    variant="outlined"
                    fullWidth
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("meals", { required: true })}
                    error={errors.meals}
                    helperText={errors.meals && "Meals is required"}
                    defaultValue={meals}
                  >
                    <option value="">Select</option>
                    <option value="breakfast">Breakfast</option>
                    <option value="lunch">Lunch</option>
                    <option value="dinner">Dinner</option>
                  </TextField>
                </div>

                <div style={styles.divContainer}>
                  <TextField
                    label={
                      <label style={styles.label}>
                        Date<span style={styles.astirisColor}>*</span>
                      </label>
                    }
                    variant="outlined"
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("date", { required: true })}
                    error={errors.date}
                    helperText={errors.date && "Date is required"}
                    defaultValue={date}
                  />
                </div>
              </div>
              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>
                      Description<span style={styles.astirisColor}>*</span>
                    </label>
                  }
                  variant="outlined"
                  fullWidth
                  {...register("description", { required: true })}
                  error={errors.description}
                  helperText={errors.description && "Description is required"}
                  defaultValue={description}
                />
              </div>
            </div>
          </Card>
          <div className="py-3 mt-2">
            <ButtonV3 onClick={handleSubmit(onSubmit)}>Submit</ButtonV3>
          </div>
        </DialogContent>
      </form>
    </Dialog>
  );
}
