import {
  Box,
  Grid,
  TextField,
  Button,
  FormHelperText,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useContext } from "react";
import Notify from "src/notification/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { UsersData } from "src/ContextAPI";
import { CheckSquare, XCircle } from "react-feather";

const LoginInfo = () => {
  const userData = useContext(UsersData);
  const history = useHistory();

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title={"Change Username"} />
            <CardContent>
              <Formik
                initialValues={{
                  user_id: userData.users.user_id,
                  username: userData.users.username,
                  management_id: userData.users.management_id,
                  new_username: "",
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  new_username: Yup.string().required(),
                  password: Yup.string().required(),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setSubmitting, resetForm }
                ) => {
                  try {
                    const request = await axios.post(
                      "admission/update-username",
                      getFormData(values)
                    );
                    if (request.data === "pass-invalid") {
                      setErrors({ password: "Password doesn't matched" });
                      Notify.customToast(
                        "Invalid Password",
                        "Password doesn't matched"
                      );
                    }
                    if (request.data === "success") {
                      Notify.successRequest("update username");
                      resetForm();
                      setSubmitting(true);
                      history.go();
                    }
                  } catch (error) {
                    const message = error.message || "Something went wrong";
                    setErrors({ submit: message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  resetForm,
                  setFieldValue,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid xs={12} item>
                        <Box mb={1}>
                          <TextField
                            error={Boolean(touched.username && errors.username)}
                            helperText={touched.username && errors.username}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.username}
                            fullWidth
                            required
                            multiline
                            label="Current Username"
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid xs={12} item>
                        <Box mb={1}>
                          <TextField
                            error={Boolean(
                              touched.new_username && errors.new_username
                            )}
                            helperText={
                              touched.new_username && errors.new_username
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.new_username}
                            fullWidth
                            required
                            multiline
                            name="new_username"
                            label="New Username"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid xs={12} item>
                        <Box mb={1}>
                          <TextField
                            fullWidth
                            required
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            label="Enter your password"
                            variant="outlined"
                            type="password"
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    {errors.submit && (
                      <Box mt={2}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}

                    <Box mt={2} display="flex">
                      <Box flexGrow={1} />

                      <Button
                        variant="contained"
                        color="default"
                        onClick={() => resetForm()}
                        startIcon={<XCircle />}
                      >
                        Reset
                      </Button>

                      <Box ml={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          startIcon={<CheckSquare />}
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title={"Change Password"} />
            <CardContent>
              <Formik
                initialValues={{
                  user_id: userData.users.user_id,
                  username: userData.users.username,
                  management_id: userData.users.management_id,
                  new_password: "",
                  confirm_password: "",
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  new_password: Yup.string().required(),
                  confirm_password: Yup.string().required(),
                  password: Yup.string().required(),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setSubmitting, resetForm }
                ) => {
                  if (values.new_password !== values.confirm_password) {
                    setErrors({
                      confirm_password:
                        "New password and confirm password doesn't matched",
                    });
                  } else {
                    try {
                      const request = await axios.post(
                        "admission/update-password",
                        getFormData(values)
                      );
                      if (request.data === "pass-invalid") {
                        setErrors({ password: "Password doesn't matched" });
                        Notify.customToast(
                          "Invalid Password",
                          "Password doesn't matched"
                        );
                      }
                      if (request.data === "success") {
                        Notify.successRequest("update password");
                        resetForm();
                        setSubmitting(true);
                        history.go();
                      }
                    } catch (error) {
                      const message = error.message || "Something went wrong";
                      setErrors({ submit: message });
                      setSubmitting(false);
                    }
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  resetForm,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Box flexGrow={1}>
                      <Grid container spacing={2}>
                        <Grid xs={12} item>
                          <Box mb={1}>
                            <TextField
                              fullWidth
                              required
                              error={Boolean(
                                touched.new_password && errors.new_password
                              )}
                              helperText={
                                touched.new_password && errors.new_password
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.new_password}
                              name="new_password"
                              label="New password"
                              variant="outlined"
                              type="password"
                            />
                          </Box>
                        </Grid>
                        <Grid xs={12} item>
                          <Box mb={1}>
                            <TextField
                              fullWidth
                              required
                              error={Boolean(
                                touched.confirm_password &&
                                  errors.confirm_password
                              )}
                              helperText={
                                touched.confirm_password &&
                                errors.confirm_password
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.confirm_password}
                              name="confirm_password"
                              label="Confirm your new password"
                              variant="outlined"
                              type="password"
                            />
                          </Box>
                        </Grid>

                        <Grid xs={12} item>
                          <Box mb={1}>
                            <TextField
                              fullWidth
                              required
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              helperText={touched.password && errors.password}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.password}
                              name="password"
                              label="Current password"
                              variant="outlined"
                              type="password"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    {errors.submit && (
                      <Box mt={2}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}

                    <Box mt={2} display="flex">
                      <Box flexGrow={1} />

                      <Button
                        variant="contained"
                        color="default"
                        onClick={() => resetForm()}
                        startIcon={<XCircle />}
                      >
                        Reset
                      </Button>

                      <Box ml={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          startIcon={<CheckSquare />}
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default LoginInfo;
