import { Card, Grid, useTheme, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
	selectEmpty: {
		"&:before": {
			borderBottom: "none !important",
		},
		"&:after": {
			borderBottom: "none !important",
		},
		fontSize: 16,
		fontWeight: 800,
	},
}));

const styles = {
	cardContainer: "flex flex-col w-full h-[175px] bg-white ",
	cardHeader:
		"flex justify-between w-full h-2/6 px-3 text-white text-lg items-center",
	cardContent: "flex w-full h-4/6 px-3",
	cardItem1: "flex  items-center w-4/6 h-full ",
	cardItem2: "flex w-2/6 h-full items-center justify-end",
};

export default function PatientCards() {
	const theme = useTheme();
	const classes = useStyles();
	const [cardValue2, setCardValue2] = useState("");
	const [cardValue3, setCardValue3] = useState("");
	const [countSurvey, setCountSurvey] = useState(0);
	const [countTreated, setCountTreated] = useState(0);
	const [appointment,setAppointment] = useState(0);
	const [todayAppointment,setTodayAppointment] = useState(0);
	const [tuberculosis,setTuberculosis] = useState(0);
	const [tuberculosisCount,setTuberculosisCount] = useState(0);
	const [donePercentage,setDonePercentage] = useState(0);
	const [doneToday,setDoneToday] = useState(0);
	const [totalPopulation, setTotalPopulation] = useState(0);

	useEffect(() => {
		getCensus();
	}, []);
	const handleCard2 = (event) => {
		setCardValue2(event.target.value);
	};

	const handleCard3 = (event) => {
		setCardValue3(event.target.value);
	};

	const getCensus = () => {
		axios.get(`/v1/census/census-summary`).then((res) => {
			setTotalPopulation(res.data?.data?.total_population);
			setCountSurvey(res.data?.data?.survey_percentage);
			setAppointment(res.data?.data?.appointment_data);
			setTuberculosis(res.data?.data?.tuberculosis_percentage);
			setTodayAppointment(res.data?.data?.today_appointment);
			setTuberculosisCount(res.data?.data?.tuberculosis)
			setDonePercentage(res.data?.data?.done_percentage)
			setDoneToday(res.data?.data?.done_today_appointment)
		});
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={4} sm={4} lg={4}>
					<Card elevation={3} className={"pointer"} component={Box}>
						<div className={styles.cardContainer}>
							<div className={styles.cardContent}>
								<div className={styles.cardItem1}>
									<DateRangeOutlinedIcon
										style={{
											color: theme.palette.warning.light,
											fontSize:
												window.innerWidth > 600
													? "4em"
													: "2.5em",
										}}
									/>
									
									<div className="flex flex-col">
										<b className="text-3xl text-warning-light">
											{totalPopulation.toLocaleString()}
										</b>
										<b>TOTAL POPULATION</b>
									</div>
								</div>
								<div className={styles.cardItem2}>
									<div className="flex flex-col justify-end">
										<div className="flex justify-end text-3xl text-warning-light ">
											{`${countSurvey}%`}
										</div>
										<b>SURVEYED</b>
									</div>
								</div>
							</div>
							<div
								className={`${styles.cardHeader} bg-warning-light`}
							>
								<b>PATIENT</b>
								<b>12.8%</b>
							</div>
						</div>
					</Card>
				</Grid>

				<Grid item xs={4} sm={4} lg={4}>
					<Card elevation={3} className={"pointer"} component={Box}>
						<div className={styles.cardContainer}>
							<div className={styles.cardContent}>
								<div className={styles.cardItem1}>
									<DateRangeOutlinedIcon
										className="text-primary"
										style={{
											fontSize:
												window.innerWidth > 600
													? "4em"
													: "2.5em",
										}}
									/>
									<div className="flex flex-col">
										<b className="text-3xl text-primary">
										{appointment}
										</b>
									</div>
								</div>
								<div className={styles.cardItem2}>
									<div className="flex flex-col justify-end">
										<div className="flex justify-end text-3xl text-primary ">
										{todayAppointment}
										</div>
										<b>APPOINTMENT</b>
									</div>
								</div>
							</div>
							<div className={`${styles.cardHeader} bg-primary`}>
								<Select
									value={cardValue2}
									onChange={handleCard2}
									displayEmpty
									className={`${classes.selectEmpty} text-white`}
									inputProps={{
										"aria-label": "Without label",
									}}
								>
									<MenuItem value="">TUBERCULOSIS</MenuItem>
								</Select>

								<b>{`${tuberculosis}%`}</b>
							</div>
						</div>
					</Card>
				</Grid>

				<Grid item xs={4} sm={4} lg={4}>
					<Card elevation={3} className={"pointer"} component={Box}>
						<div className={styles.cardContainer}>
							<div className={styles.cardContent}>
								<div className={styles.cardItem1}>
									<DateRangeOutlinedIcon
										className="text-success"
										style={{
											fontSize:
												window.innerWidth > 600
													? "4em"
													: "2.5em",
										}}
									/>
									<div className="flex flex-col">
										<b className="text-3xl text-success">
											{tuberculosisCount}
										</b>
									</div>
								</div>
								<div className={styles.cardItem2}>
									<div className="flex flex-col justify-end">
										<div className="flex justify-end text-3xl text-success ">
											{doneToday}
										</div>
										<b>APPOINTMENT</b>
									</div>
								</div>
							</div>
							<div className={`${styles.cardHeader} bg-success`}>
								<Select
									value={cardValue3}
									onChange={handleCard3}
									displayEmpty
									className={`${classes.selectEmpty} text-white`}
									inputProps={{
										"aria-label": "Without label",
									}}
								>
									<MenuItem value="">TREATED</MenuItem>
									<MenuItem value={10}>LIST2</MenuItem>
									<MenuItem value={20}>LIST3</MenuItem>
									<MenuItem value={30}>LIST4</MenuItem>
								</Select>
								<b>{`${donePercentage}%`}</b>
							</div>
						</div>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}
