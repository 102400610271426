import React, { useContext, useEffect, useRef, useState } from "react";
import {
	Grid,
	Typography,
	Box,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Paper,
	Button,
} from "@material-ui/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FormInformation, UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import ReactToPrint from "react-to-print";
import { Print, Send } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import {
	calculateAge,
	formatCurrency,
	getSumObj,
	patientAddress,
	patientFullName,
} from "src/helpers/utils";
import { toast } from "react-toastify";

import QRCode from "qrcode.react";
const billing_data = [
	{
		particulars: "Drugs and Medicine (GF)",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Laboratory Examination",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Miscellaneous",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "NonDrugs / Supplies",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "PHIC",
		debit: 0.0,
		discount: 0.0,
		credit: 0.0,
		balance: 0.0,
	},
	{
		particulars: "Radiology",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Room and Board",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
];

const SPHPatientSoa = () => {
	const { users } = useContext(UsersData);
	const { formInfo } = useContext(FormInformation);
	const { patient_id } = useParams();
	const mounted = IsMountedRef();
	const [details, setDetails] = useState({ data: [], ready: false });
	const [info, setInfo] = useState({ data: [], ready: false });
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(0);
	const [appointment, setAppointment] = useState(null);
	let printRef = useRef();
	const history = useHistory();

	const getPatientsTransaction = React.useCallback(async () => {
		let response = await axios.get(
			"billing/soa/get-patients-transactions",
			{
				params: {
					management_id: users.management_id,
					main_mgmt_id: users.main_mgmt_id,
					patient_id: patient_id,
				},
			}
		);

		if (mounted.current) {
			setDetails({ data: response.data, ready: true });
			setTotal(calculateTotalChargeAmount(response.data));
		}
	}, [users, patient_id, mounted]);

	const getPatientInfo = React.useCallback(async () => {
		let response = await axios.get(
			`/v1/clinic/get-pending-cashier/${patient_id}`
		);

		// if (mounted.current) {
		setAppointment(response.data.data);
		// }
	}, [patient_id, mounted]);

	const handleUpdateSetAsPaid = () => {
		var formdata = new FormData();
		formdata.append("management_id", users.management_id);
		formdata.append("main_mgmt_id", users.main_mgmt_id);
		formdata.append("patient_id", patient_id);

		axios
			.post("accounting/set-as-paid/soa-by-patient", formdata)
			.then((response) => {
				const data = response.data;
				if (data === "success") {
					Notify.successRequest("as as paid");
					history.push(`/sph/app/billing/soa`);
				}
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	useEffect(() => {
		getPatientsTransaction();
		getPatientInfo();
	}, [getPatientsTransaction, getPatientInfo]);

	const calculateTotalChargeAmount = (data) => {
		let x = 0;

		for (let i = 0; i < data.length; i++) {
			x += parseFloat(data[i].bill_amount);
		}

		return x;
	};

	const markAsPaid = (data) => {
		setLoading(true);
		let formData = new FormData();
		formData.append("appointment_id", appointment?.id);
		formData.append("_method", "PATCH");

		axios
			.post(`/v1/clinic/mark-as-paid/${appointment?.id}`, formData)
			.then((res) => {
				// addToList(data);
				setTimeout(() => {
					setLoading(false);
					getPatientInfo();
					// onSuccess();
					toast.success("Patient is now paid!!");
				}, 400);
				// hide();
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<Container
			breadcrumbs={{
				enable: false,
				current: "eryery",
				items: [],
			}}
			title={""}
		>
			<Paper component={Box} p={2} ref={printRef}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							{/* <Box align="center" mb={2}>
								<Typography variant="h5">
									{Boolean(formInfo)
										? formInfo.subtitle1 !== null
											? formInfo.subtitle1
											: ""
										: ""}
								</Typography>
								<Typography variant="subtitle1">
									{Boolean(formInfo)
										? formInfo.subtitle2 !== null
											? formInfo.subtitle2
											: ""
										: ""}
								</Typography>
								<Typography variant="subtitle1">
									{Boolean(formInfo)
										? formInfo.subtitle3 !== null
											? formInfo.subtitle3
											: ""
										: ""}
								</Typography>
							</Box> */}

							<Box display="flex" p={2}>
								<Box flexGrow={1}>
									<Box mb={1}>
										<Typography variant="subtitle1">
											<b> PATIENT INFORMATION </b>
										</Typography>
									</Box>
									<Box display={"flex"} mb={1}>
										<Box mb={1} className="flex-1">
											<Typography variant="subtitle1">
												Name :{" "}
												<b>
													{patientFullName(
														appointment?.patient
													)}
												</b>
											</Typography>
										</Box>

										<Box mb={1} className="flex-1">
											<Typography variant="subtitle1">
												Address :{" "}
												<b>
													{patientAddress(
														appointment?.patient
													)}
												</b>
											</Typography>
										</Box>

										<Box mb={1} className="flex-1">
											<Typography variant="subtitle1">
												Gender :{" "}
												<b>
													{
														appointment?.patient
															?.gender
													}
												</b>
											</Typography>
										</Box>

										<Box mb={1} className="flex-1">
											<Typography variant="subtitle1">
												Age :{" "}
												<b>
													{calculateAge(
														appointment?.patient
															?.birthday
													)}{" "}
													yrs. old
												</b>
											</Typography>
										</Box>
										{appointment?.patient && (
											<Box mb={1} className="flex-1">
												<QRCode
													value={JSON.stringify({
														patient_id: `p-id${appointment?.patient}`,
														user_id: `u-id${users?.id}`,
														philhealth:
															"philhealth",
													})}
													level="H"
													size={88}
												/>
											</Box>
										)}
									</Box>
								</Box>
							</Box>

							<Box mb={2}>
								<Typography variant="h5" align="center">
									<b> STATEMENT OF ACCOUNT </b>
								</Typography>

								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<b> Particulars </b>
												</TableCell>
												<TableCell align="right">
													<b> Debit </b>
												</TableCell>
												<TableCell align="right">
													<b> Credit </b>
												</TableCell>
												<TableCell align="right">
													<b> Discount </b>
												</TableCell>
												<TableCell align="right">
													<b> Balance </b>
												</TableCell>
											</TableRow>
										</TableHead>

										<TableBody>
											{true ? (
												true ? (
													billing_data.map(
														(data, index) => (
															<TableRow
																key={index}
															>
																<TableCell>
																	{
																		data?.particulars
																	}
																</TableCell>
																<TableCell align="right">
																	{formatCurrency(
																		data?.debit
																	)}
																</TableCell>
																<TableCell align="right">
																	{formatCurrency(
																		data?.credit
																	)}
																</TableCell>
																<TableCell align="right">
																	{formatCurrency(
																		data?.discount
																	)}
																</TableCell>
																<TableCell align="right">
																	{formatCurrency(
																		data?.balance
																	)}
																</TableCell>
															</TableRow>
														)
													)
												) : (
													<TableRow>
														<TableCell colSpan={4}>
															<Typography color="secondary">
																No record found.
															</Typography>
														</TableCell>
													</TableRow>
												)
											) : (
												<TableRow>
													<TableCell colSpan={4}>
														<Typography color="primary">
															loading, please
															wait...
														</Typography>
													</TableCell>
												</TableRow>
											)}
											<TableRow>
												<TableCell> </TableCell>
												<TableCell> </TableCell>
												<TableCell> </TableCell>
												<TableCell align="right">
													<b>
														{" "}
														Total Charge Amount :
													</b>
												</TableCell>
												<TableCell align="right">
													<b>
														{" "}
														{getSumObj(
															billing_data,
															"balance"
														)}
													</b>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Box>

							<Box my={2} mt={5}>
								<Box display="flex">
									<Box flexGrow={1} width={"50%"}>
										<Typography> Prepared by: </Typography>
										<Box align="center">
											{Boolean(formInfo)
												? formInfo.prepared_by !== null
													? formInfo.prepared_by
													: ""
												: ""}
										</Box>
										<Box align="center">
											<Box
												style={{
													borderTop: "1px solid #888",
												}}
												maxWidth={350}
											>
												Cashier
											</Box>
										</Box>
									</Box>

									<Box width={"50%"}>
										<Typography> Verified by: </Typography>
										<Box align="center">
											{Boolean(formInfo)
												? formInfo.verified_by !== null
													? formInfo.verified_by
													: ""
												: ""}
										</Box>
										<Box align="center">
											<Box
												style={{
													borderTop: "1px solid #888",
												}}
												maxWidth={350}
											>
												Accounting in Charge
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
							<Box>
								<Box display="flex">
									<Box flexGrow={1} width={"50%"}>
										<Typography> Checked by: </Typography>
										<Box align="center">
											{Boolean(formInfo)
												? formInfo.checked_by !== null
													? formInfo.checked_by
													: ""
												: ""}
										</Box>
										<Box align="center">
											<Box
												style={{
													borderTop: "1px solid #888",
												}}
												maxWidth={350}
											>
												Clinic Coordinator
											</Box>
										</Box>
									</Box>

									<Box width={"50%"}>
										<Typography> Noted by: </Typography>
										<Box align="center">
											{Boolean(formInfo)
												? formInfo.noted_by !== null
													? formInfo.noted_by
													: ""
												: ""}
										</Box>
										<Box align="center">
											<Box
												style={{
													borderTop: "1px solid #888",
												}}
												maxWidth={350}
											>
												Accounting Head
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>

						<Box display="flex" mt={2}>
							{appointment?.status != "done" ? (
								<Box mr={2}>
									<Button
										className={"d-print-none"}
										variant="contained"
										color="primary"
										// hidden={details.length === 0}
										startIcon={<Send />}
										loading={loading}
										onClick={markAsPaid}
									>
										Set as paid
									</Button>
								</Box>
							) : (
								""
							)}
							<ReactToPrint
								trigger={() => {
									// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
									// to the root node of the returned component as it will be overwritten.
									return (
										<Button
											className={"d-print-none"}
											variant="contained"
											color="primary"
											// hidden={details.data.length === 0}
											startIcon={<Print />}
										>
											Print
										</Button>
									);
								}}
								content={() => printRef.current}
							/>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</Container>
	);
};

export default SPHPatientSoa;
