import { useEffect, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import Button from "../Button";
import FlatIcon from "../FlatIcon";
import SelectInputField from "../forms/SelectInputField";
// import SelectInputField from "../forms/SelectInputField";
import TextInputField from "../forms/TextInputField";

const TableV2 = (props) => {
	const {
		columns,
		/* 
		className,
		cellClassName,
		text,
		id,
		*/
		className = "",
		pagination = false,
		meta,
		data,
		loading,
		rowClick = false,
		rowHighlight = false,
		tableClassName = "",
		onTableChange,
		show = [10, 20, 50, 100],
		loadingMessage = "Gathering data...",
		emptyMessage = "No data available",
		paginationClassName = "",
		theadClassName = "",
		column,
		setColumn,
		direction,
		setDirection,
	} = props;
	const [paginationState, setPaginationState] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const [pageCount, setPageCount] = useState(1);
	/* 	const table = createTable({
		data,
		columns,
		pageCount: meta?.last_page || 1,
		manualPagination: true,
		getPaginationRowModel: getPaginationRowModel(),
		onPaginationChange: (data) => {
			if (data) {
				console.log("TABLETABLE onTableChange 000", data);
				setPagination(data);
			}
		},
		getCoreRowModel: getCoreRowModel(),
	}); */
	useEffect(() => {
		console.log("TABLETABLE meta11", meta);
		if (meta) {
			console.log("TABLETABLE meta222", meta?.last_page);
			setPageCount(meta?.last_page);
		}
	}, [meta]);
	useEffect(() => {
		console.log(
			"TABLETABLE onTableChange 111",
			paginationState.pageIndex,
			paginationState.pageSize
		);
		if (onTableChange) {
			onTableChange({
				pageIndex: paginationState.pageIndex,
				pageSize: paginationState.pageSize,
			});
		}
	}, [paginationState]);

	const setPageIndex = (index) => {
		setPaginationState((prev) => ({
			...prev,
			pageIndex: index,
		}));
	};
	return (
		<div className="table relative max-w-full overflow-x-auto">
			<table>
				<thead className={theadClassName}>
					<tr>
						{columns.map((col, i) => {
							return (
								<th
									key={`col-${
										col?.accessorKey || col?.id
									}-${i}`}
									className={col?.headerClassName}
								>
									<div
										className={`flex w-full ${
											col?.sortable
												? "cursor-pointer"
												: ""
										}`}
										title={
											col?.sortable
												? "Click to sort"
												: col?.accessorKey
										}
										onClick={() => {
											if (col?.sortable) {
												setDirection(
													direction == "asc"
														? "desc"
														: "asc"
												);
												setColumn(col?.accessorKey);
											}
										}}
									>
										{col?.header}

										{col?.sortable ? (
											<div className="flex flex-col items-center justify-center ml-1">
												<FlatIcon
													icon="rr-caret-up"
													className={`-mb-[5px] -mt-[2px] ${
														column ==
														col?.accessorKey
															? direction == "asc"
																? "opacity-100"
																: direction ==
																  "desc"
																? "opacity-25"
																: "opacity-25"
															: "opacity-25"
													}`}
												/>
												<FlatIcon
													icon="rr-caret-down"
													className={`-mt-[7px] -mb-[5px] ${
														column ==
														col?.accessorKey
															? direction == "asc"
																? "opacity-25"
																: direction ==
																  "desc"
																? "opacity-100"
																: "opacity-25"
															: "opacity-25"
													}`}
												/>
											</div>
										) : (
											""
										)}
									</div>
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody className="bg-background">
					{loading ? (
						<tr>
							<td colSpan={999} className="text-center">
								<div className="w-full flex items-center justify-start lg:items-center lg:justify-center py-4 text-placeholder">
									{loadingMessage}
								</div>
							</td>
						</tr>
					) : data?.length == 0 ? (
						<tr>
							<td colSpan={999} className="text-center">
								<div className="w-full flex items-center justify-start lg:items-center lg:justify-center py-4 text-placeholder">
									{emptyMessage}
								</div>
							</td>
						</tr>
					) : (
						data?.map((item, index) => {
							return (
								<tr
									key={`tr-${index}-${item?.id}`}
									className=""
								>
									{columns.map((col, i) => {
										return (
											<td
												key={`td-${
													col?.accessorKey || col?.id
												}-${i}`}
												className={col?.className + " "}
											>
												{typeof col?.cell == "function"
													? col?.cell(item)
													: item[col?.accessorKey]}
											</td>
										);
									})}
								</tr>
							);
						})
					)}
				</tbody>
			</table>
			{pagination ? (
				<div
					className={`flex flex-col-reverse lg:flex-row items-start lg:items-center gap-6 pt-4  ${paginationClassName}`}
				>
					<div className="flex items-center text-dark text-sm">
						<label className="mb-0">Show:</label>
						<SelectInputField
							value={paginationState.pageSize}
							onChange={(e) => {
								// table.setPageSize(Number(e.target.value));
								setPaginationState((data) => ({
									...data,
									pageSize: Number(e.target.value),
								}));
							}}
							options={show.map((x) => ({
								label: x,
								value: x,
							}))}
							className="ml-4 w-16 m-0"
							inputClassName=" !p-2 !py-1 !text-center"
						/>
					</div>
					<div className="flex items-center text-dark text-sm">
						<label className="mb-0">Go to page:</label>
						<TextInputField
							min={1}
							max={pageCount}
							type={"number"}
							className="ml-4 w-[80px] m-0"
							inputClassName=" !px-1 !py-1 !text-center text-sm"
							// defaultValue={table.getState().pagination.pageIndex + 1}
							onChange={(e) => {
								const page = e.target.value
									? Number(e.target.value) - 1
									: 0;
								setPageIndex(page);
							}}
						/>
					</div>
					<div className="flex gap-1">
						<Button
							type="foreground"
							size="sm"
							disabled={paginationState.pageIndex <= 0}
							onClick={() => setPageIndex(0)}
						>
							<FlatIcon icon="rs-angle-double-left" />
						</Button>
						<Button
							type="foreground"
							size="sm"
							disabled={paginationState.pageIndex <= 0}
							onClick={() =>
								setPageIndex(paginationState.pageIndex - 1)
							}
						>
							<FlatIcon icon="rs-angle-left" />
						</Button>
						<div className="flex items-center gap-2 mx-2 text-xs lg:text-sm">
							Page <b>{paginationState.pageIndex + 1}</b> of{" "}
							<b>{pageCount}</b>
						</div>
						<Button
							type="foreground"
							size="sm"
							disabled={
								paginationState.pageIndex == pageCount - 1
							}
							onClick={() =>
								setPageIndex(paginationState.pageIndex + 1)
							}
						>
							<FlatIcon icon="rs-angle-right" />
						</Button>
						<Button
							type="foreground"
							size="sm"
							disabled={
								paginationState.pageIndex == pageCount - 1
							}
							onClick={() => setPageIndex(pageCount - 1)}
						>
							<FlatIcon icon="rs-angle-double-right" />
						</Button>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};

/* 
META FORMAT 

current_page
from
last_page
per_page
to
total
*/
export default TableV2;
