import {
  Box,
  TextField,
  Button,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import { CheckSquare, XCircle } from "react-feather";

const DocuPatientDetailsMedJabeliDetails = ({
  selectedBranch,
  patient_id,
  details,
  getCompletedMedCert,
  updatePatientList,
}) => {
  const { users } = React.useContext(UsersData);
  console.log("detailsqweqwe", details);
  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  React.useEffect(() => {}, [patient_id]);

  return (
    <Fragment>
      {details && (
        <Formik
          initialValues={{
            lmc_id: details.lmc_id !== null ? details.lmc_id : "",
            trace_number:
              details.trace_number !== null ? details.trace_number : "",
            patient_id: details.patient_id !== null ? details.patient_id : "",
            user_id: users.user_id,
            username: users.username,
            management_id: users.management_id,
            main_mgmt_id: users.main_mgmt_id,
            diagnosis: "",
            recommendation: "",
            issued_at: "",
            password: "",
            remarks: "",
          }}
          validationSchema={Yup.object().shape({
            diagnosis: Yup.string().required("Diagnosis is a required field."),
            issued_at: Yup.string().required("Issued at is a required field."),
            password: Yup.string().required("Password is a required field."),
          })}
          onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
            try {
              const request = await axios.post(
                "van/patient/certificates/medicalcert-setcomplete",
                getFormData(values)
              );
              console.log("request", request);
              if (request.data.message === "pass-invalid") {
                setErrors({ password: "Password invalid." });
              }
              if (request.data.message === "success") {
                getCompletedMedCert();
                updatePatientList();
                resetForm();
                setSubmitting(true);
                Notify.successRequest("certificate added");
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <Box>
                    <TextField
                      fullWidth
                      required
                      error={Boolean(touched.diagnosis && errors.diagnosis)}
                      helperText={touched.diagnosis && errors.diagnosis}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.diagnosis}
                      name="diagnosis"
                      label="Diagnosis"
                      variant="outlined"
                      rows={2}
                      multiline
                    />
                  </Box>
                </Grid>

                <Grid xs={12} item>
                  <Box>
                    <TextField
                      fullWidth
                      error={Boolean(
                        touched.recommendation && errors.recommendation
                      )}
                      helperText={
                        touched.recommendation && errors.recommendation
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.recommendation}
                      name="recommendation"
                      label="Recommendation"
                      rows={2}
                      multiline
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid xs={12} item>
                  <Box>
                    <TextField
                      fullWidth
                      error={Boolean(touched.remarks && errors.remarks)}
                      helperText={touched.remarks && errors.remarks}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.remarks}
                      name="remarks"
                      rows={2}
                      multiline
                      label="Remarks"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid xs={12} item>
                  <Box>
                    <TextField
                      fullWidth
                      required
                      error={Boolean(touched.issued_at && errors.issued_at)}
                      helperText={touched.issued_at && errors.issued_at}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.issued_at}
                      name="issued_at"
                      label="Issued At"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Grid>

                <Grid xs={12} item>
                  <Box>
                    <TextField
                      fullWidth
                      required
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      label="Password"
                      variant="outlined"
                      type="password"
                    />
                  </Box>
                </Grid>

                <Grid xs={12} item>
                  {errors.submit && (
                    <Box mt={2}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                  <Box display="flex">
                    <Box flexGrow={1} />
                    <Box mx={1}>
                      <Button
                        size="large"
                        variant="contained"
                        color="default"
                        onClick={() => {
                          resetForm();
                        }}
                        startIcon={<XCircle />}
                      >
                        No
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<CheckSquare />}
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </Fragment>
  );
};

export default DocuPatientDetailsMedJabeliDetails;
