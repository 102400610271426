import { faColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PatientQueueDetail from "src/clinic/components/PatientQueueDetail";
import QueueNumberModal from "src/clinic/components/QueueNumberModal";
import ReceivePatientModal from "src/clinic/components/ReceivePatientModal";
import ReceivePatientProfileModal from "src/clinic/components/ReceivePatientProfileModal";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import useClinicHook from "src/clinic/services/useClinicHook";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import Img from "src/components/Img";
import LayoutContainer from "src/components/LayoutContainer";
import ConfirmationModal from "src/components/modal/ConfirmationModal";
import { UsersData } from "src/ContextAPI";

const CisQueue = () => {
	const userContext = useContext(UsersData);
	const { servePatient, doneServePatient, clearClinicQueue } =
		useClinicHook();
	const { users } = userContext;
	const name = users?.name;
	const receive_patient_ref = useRef(null);
	const receive_patient_profile_ref = useRef(null);
	const queue_number_modal = useRef(null);
	const confirmation_modal = useRef(null);
	const { clinic, queue, getQueue } = useClinicContext();

	useEffect(() => {
		if (clinic?.id) {
			// getQueue();
		}
	}, []);
	const confirmServePatient = (patient_id) => {
		confirmation_modal.current.show({
			title: "Serve patient",
			message: (
				<div className="py-6 px-2 text-xl  w-full flex flex-col items-center justify-center gap-4">
					<div className=" aspect-square p-3 rounded-full border border-success text-success text-3xl flex items-center justify-center">
						<FlatIcon
							icon="rr-list-check"
							className="text-[40px]"
						/>
					</div>
					<p>
						Patient will now be added to <b>NOW SERVING</b>.
					</p>
				</div>
			),
			footer: ({ loadingBtn, setLoadingBtn }) => {
				return (
					<div className="flex items-center gap-4 w-full justify-end">
						<ActionBtn
							size="md"
							type="primary-gradient"
							onClick={() => {
								confirmation_modal.current.hide();
							}}
						>
							Cancel
						</ActionBtn>
						<ActionBtn
							size="md"
							type="success"
							loading={loadingBtn}
							onClick={() => {
								setLoadingBtn(true);
								servePatient(patient_id).then((res) => {
									setTimeout(() => {
										setLoadingBtn(false);

										toast.success(
											"Patient is now added to now serving!"
										);
									}, 200);
									getQueue();
									confirmation_modal.current.hide();
								});
							}}
						>
							Okay
						</ActionBtn>
					</div>
				);
			},
		});
	};
	const confirmDoneServePatient = (patient_id) => {
		confirmation_modal.current.show({
			title: "Mark patient as done",
			message: (
				<div className="py-6 px-2 text-xl  w-full flex flex-col items-center justify-center gap-4">
					<div className=" aspect-square p-3 rounded-full border border-success text-success text-3xl flex items-center justify-center">
						<FlatIcon
							icon="rr-badge-check"
							className="text-[40px]"
						/>
					</div>
					<p>This will mark patient as done.</p>
				</div>
			),
			footer: ({ loadingBtn, setLoadingBtn }) => {
				return (
					<div className="flex items-center gap-4 w-full justify-end">
						<ActionBtn
							size="md"
							type="primary-gradient"
							onClick={() => {
								confirmation_modal.current.hide();
							}}
						>
							Cancel
						</ActionBtn>
						<ActionBtn
							size="md"
							type="success"
							loading={loadingBtn}
							onClick={() => {
								setLoadingBtn(true);
								doneServePatient(patient_id).then((res) => {
									setTimeout(() => {
										setLoadingBtn(false);

										toast.success("Done serving patient!");
									}, 200);
									getQueue();
									confirmation_modal.current.hide();
								});
							}}
						>
							Okay
						</ActionBtn>
					</div>
				);
			},
		});
	};

	const now_serving = queue?.data?.filter((x) => x.status == "served");
	const pending_list = queue?.data?.filter((x) => x.status == "pending");
	return (
		<LayoutContainer
			icon={
				<FontAwesomeIcon icon={faColumns} color="white" size={"2x"} />
			}
			title={clinic?.name}
			subtitle={`Welcome Back, ${name}! 👋`}
			titleChildren={
				<>
					<div
						className="p-3 ml-auto flex items-center justify-center lg:col-span-6 xl:col-span-4 rounded-xl border border-border gap-3 bg-green-500 text-white text-xl hover:bg-green-600 duration-200 cursor-pointer"
						onClick={() => {
							receive_patient_ref.current.show();
						}}
					>
						<FlatIcon icon="rr-layer-plus" />
						Receive patient
					</div>
				</>
			}
			className={`relative`}
		>
			<div className="absolute top-0 left-0 w-full bg-white min-h-[calc(100vh-175px)]">
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-6 w-full h-full top-0 left-0 z-[2000]">
					<div className="p-4 flex lg:col-span-12 flex-col rounded-xl">
						<div className="border-b mb-4 flex items-center ">
							<h4 className="text-3xl font-bold mb-2 mr-auto w-full flex items-center">
								Queue List
								<ActionBtn
									className="ml-6"
									type="accent"
									onClick={() => {
										confirmation_modal.current.show({
											title: "Clear clinic queue",
											message:
												"Clearing clinic queue, will remove all the patients in queue and will reset your queuing number. Do you still want to proceed?",
											footer: ({
												loadingBtn,
												setLoadingBtn,
											}) => {
												return (
													<div className="flex items-center gap-4 w-full justify-end">
														<ActionBtn
															size="md"
															type="primary-gradient"
															onClick={() => {
																confirmation_modal.current.hide();
															}}
														>
															No
														</ActionBtn>
														<ActionBtn
															size="md"
															type="danger"
															loading={loadingBtn}
															onClick={() => {
																setLoadingBtn(
																	true
																);
																clearClinicQueue().then(
																	(res) => {
																		setTimeout(
																			() => {
																				setLoadingBtn(
																					false
																				);
																				getQueue();
																				toast.success(
																					"Clinic queue reset successfully!"
																				);
																			},
																			200
																		);
																		confirmation_modal.current.hide();
																	}
																);
															}}
														>
															Confirm reset
														</ActionBtn>
													</div>
												);
											},
										});
									}}
								>
									<FlatIcon icon="rr-confetti" />
									Clear clinic queue
								</ActionBtn>
								<Link
									to="/sph/app/cis/queue"
									className="ml-auto"
								>
									<div className="w-11 h-11 rounded-full border flex items-center justify-center text-lg">
										<FlatIcon icon="rr-expand" />
									</div>
								</Link>
							</h4>
						</div>
						<div className="flex flex-col gap-y-5 h-full">
							<div className="grid grid-cols-12 gap-5 h-full">
								<div className="flex col-span-6 2xl:col-span-5 flex-col border-r">
									<h2 className="text-3xl font-bold text-success text-center">
										NOW SERVING
									</h2>
									{now_serving?.map((list) => {
										return (
											<Link
												to={`/sph/app/cis/patients/${list?.patient?.id}`}
												className="!no-underline"
											>
												<div className="!no-underline text-dark bg-white rounded-xl w-full mx-auto p-2 relative">
													<span className="absolute left-0 top-0 text-[100px] aspect-square text-center font-bold text-green-600 py-2 flex items-center justify-center px-4 rounded-xl border border-green-700">
														<span className="!text-2xl pt-1 mr-1">
															#
														</span>{" "}
														{list?.number}
													</span>
													<div className="flex flex-col items-center justify-center gap-y-1">
														<div className="bg-blue-200 rounded-full w-[144px] h-[144px] mb-2 overflow-hidden relative">
															<Img
																type="user"
																name={`${list?.patient?.lastname}, ${list?.patient?.firstname}, ${list?.patient?.middle}`}
																src={
																	list
																		?.patient
																		?.avatar
																}
																className="absolute top-0 left-0 w-full h-full object-contain"
															/>
														</div>
														<h5 className="text-3xl font-bold mb-1 !no-underline">
															{`${list?.patient?.lastname}, ${list?.patient?.firstname}, ${list?.patient?.middle}`}
														</h5>
														<div className="flex items-center text-xl gap-1 mb-0 !no-underline">
															<b>Patient ID:</b>
															{String(
																list?.patient
																	?.id
															).padStart(5, "0")}
														</div>
														<div className="flex items-center gap-1 mb-0 text-xl !no-underline">
															<FlatIcon icon="rr-map-marker" />

															{`${
																list?.patient
																	?.municipality
																	?.name || ""
															}, ${
																list?.patient
																	?.barangay
																	?.name || ""
															}, ${
																list?.patient
																	?.purok
																	?.name || ""
															}`}
														</div>
													</div>
													<div
														className="absolute top-3 right-4 text-blue-300  rounded-full flex items-center justify-center  cursor-pointer hover:text-blue-600 duration-200"
														title="Click to mark patient as done"
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															confirmDoneServePatient(
																list?.id
															);
														}}
													>
														<FlatIcon
															icon="rr-badge-check"
															className=" text-[50px]"
														/>
													</div>
												</div>
											</Link>
										);
									})}
									{now_serving?.length == 0 ? (
										<h3 className="text-placeholder text-center w-full mt-2 text-xl">
											No patients in now serving.
										</h3>
									) : (
										""
									)}
								</div>
								<div className=" col-span-6 xl:col-span-5 2xl:col-span-5 flex flex-col gap-y-2">
									<h2 className="text-3xl font-bold text-blue-600 text-let">
										PENDING
									</h2>
									{console.log("queuequeuequeue", queue)}
									{pending_list?.map((queueData, index) => {
										if (
											queueData?.status == "pending" &&
											index < 5
										)
											return (
												<PatientQueueDetail
													patient={queueData}
													key={`queu-${queueData?.number}`}
													confirmServePatient={
														confirmServePatient
													}
												/>
											);
									})}
									{pending_list?.length == 0 ? (
										<h3 className="text-placeholder text-xl">
											Nothing in queue.
										</h3>
									) : (
										""
									)}
								</div>
								<div className="hidden xl:col-span-1 2xl:flex flex-col gap-y-3 pl-3">
									<h2 className="text-xl font-bold text-warning text-let">
										&nbsp;
									</h2>
									{queue?.data?.map((queueData, index) => {
										if (
											queueData?.status == "pending" &&
											index >= 5
										)
											return (
												<div className="border rounded-xl p-3 w-full bg-white bg-opacity-50 flex items-center justify-center relative">
													<span className="text-2xl font-bold text-blue-600 ">
														<span className="!text-2xl pt-1 mr-1">
															#
														</span>
														{queueData?.number}
													</span>
												</div>
											);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ReceivePatientModal
				ref={receive_patient_ref}
				getQueue={getQueue}
				receive_patient_profile_ref={receive_patient_profile_ref}
			/>
			<ReceivePatientProfileModal
				clinic={clinic}
				ref={receive_patient_profile_ref}
				queue_number_modal={queue_number_modal}
				getQueue={getQueue}
			/>
			<QueueNumberModal ref={queue_number_modal} />
			<ConfirmationModal ref={confirmation_modal} />
		</LayoutContainer>
	);
};

export default CisQueue;
