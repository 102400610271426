import {
	Box,
	Card,
	CardContent,
	CardHeader,
	TableContainer,
	Table,
	TableCell,
	TableHead,
	TableBody,
	TableRow,
	Typography,
	IconButton,
	TextField,
	InputAdornment,
} from "@material-ui/core";
import { ArrowForward, Search } from "@material-ui/icons";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const PatientList = () => {
	const { users } = useContext(UsersData);
	const [list, setList] = useState({ data: [], ready: false });
	const history = useHistory();

	const getManagementPatientList = useCallback(async () => {
		let response = await axios.get("billing/soa/get-management-patients", {
			params: {
				management_id: users.management_id,
				main_mgmt_id: users.main_mgmt_id,
			},
		});

		setList({ data: response.data, ready: true });
	}, [users]);

	useEffect(() => {
		getManagementPatientList();
	}, [getManagementPatientList]);

	return (
		<Box>
			<Card>
				<CardHeader
					title={
						<Box
							display="flex"
							justifyContent={"center"}
							alignItems={"center"}
						>
							<Box flexGrow={1}>
								<Typography variant="h5" color="textSecondary">
									Patient List
								</Typography>
							</Box>
							<Box>
								<TextField
									label="Search name"
									variant="outlined"
									margin="dense"
									InputProps={{
										endAdornment: (
											<InputAdornment>
												<Search color="primary" />
											</InputAdornment>
										),
									}}
								/>
							</Box>
						</Box>
					}
				/>

				<CardContent>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<b> Name </b>
									</TableCell>
									<TableCell>
										<b> Address </b>
									</TableCell>
									<TableCell>
										<b> Age </b>
									</TableCell>
									<TableCell>
										<b> Gender </b>
									</TableCell>
									<TableCell align="center">
										<b> Action </b>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{list.ready ? (
									list.data?.length > 0 ? (
										list.data?.map((data, index) => (
											<TableRow key={index}>
												<TableCell>
													{`${data?.lastname}, ${data?.firstname}`}
												</TableCell>
												<TableCell>
													{`${data?.street}, ${data?.barangay}, ${data?.city}`}
												</TableCell>
												<TableCell>
													{Notify.calculateAge(
														data?.birthday
													)}
												</TableCell>
												<TableCell>
													{" "}
													{data?.gender}{" "}
												</TableCell>
												<TableCell align="center">
													<IconButton
														color="primary"
														onClick={() =>
															history.push(
																`/sph/app/billing/soa/patient/${data?.patient_id}`
															)
														}
													>
														<ArrowForward />
													</IconButton>
												</TableCell>
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell
												colSpan={5}
												align="center"
											>
												<Typography color="secondary">
													No record found.
												</Typography>
											</TableCell>
										</TableRow>
									)
								) : (
									<TableRow>
										<TableCell colSpan={5} align="center">
											<Typography color="primary">
												Loading, Please wait...
											</Typography>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Card>
		</Box>
	);
};

export default PatientList;
