import { Box, Paper, Tabs, Tab } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import Container from "src/layout/Container";
import TabPanel from "src/utils/TabPanel";
import DoctorListShare from "./doctorlist/DoctorListShare";
import DoctorSalesss from "./sales/DoctorSalesss";
import DoctorsServices from "./test/DoctorsServices";
// import ImagingTest from "./test/ImagingTest";
// import ImagingSales from "./sales/sales";
// import TestPerson from "./test-person";

const AccountingDoctor = () => {
  const [category, setCategory] = useState("sales");

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Doctor",
        items: [{ name: "Dashboard", path: "/sph/app/accounting" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Doctor</Box>
          <Box width={350}>
            <Paper>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab value="sales" label="Sales" />
                <Tab value="test" label="Doctor's Services" />
                <Tab value="doctor" label="Doctor's List" />
              </Tabs>
            </Paper>
          </Box>
        </Box>
      }
    >
      <Box>
        <TabPanel value={category} index="sales">
          <DoctorSalesss />
        </TabPanel>
        <TabPanel value={category} index="test">
          <DoctorsServices />
        </TabPanel>
        <TabPanel value={category} index="doctor">
          <DoctorListShare />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default AccountingDoctor;
