import L from "leaflet";
import { useRef, useState } from "react";

import {
	MapContainer,
	Marker,
	TileLayer,
	useMapEvents,
	GeoJSON,
	Popup,
} from "react-leaflet";

import { features } from "../../json_data/sarangani.json";

var markerIcon = L.icon({
	iconUrl: "/marker-icon.png",
	iconSize: [25, 41], // size of the icon
	iconAnchor: [12.8, 40.8],
});

const mapStyle = {
	fillColor: "#fff",
	weight: 2,
	opacity: 1,
	color: "#808080",
	fillOpacity: 0.1,
	dashArray: "3",
};

const LocationDisplay = ({ position = null, setPosition }) => {
	const map_container_ref = useRef(null);

	var prevLayerClicked = null;

	const mapOnEachFeature = (feature, layer) => {
		layer.bindPopup(
			`${feature.properties.name}, ${feature.properties.city}`
		);
		layer.on({
			click: (e) => {
				// const map = mapRef.current.contextValue.map;
				var layer = e.target;
				// map.fitBounds(e.target.getBounds());
				if (prevLayerClicked !== null) {
					prevLayerClicked.setStyle({
						fillColor: "#FFF",
						weight: 2,
						opacity: 0.5,
						color: "#808080",
						fillOpacity: 0.1,
						dashArray: "3",
					});
				}
				layer.setStyle({
					weight: 4,
					color: "blue",
					dashArray: "",
					fillOpacity: 0.3,
				});
				if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
					layer.bringToFront();
				}
				prevLayerClicked = layer;
			},
		});
	};

	return (
		<div>
			<div className="h-[352px]">
				{position ? (
					<MapContainer
						center={position}
						zoom={15}
						scrollWheelZoom={true}
						measureControl={true}
						ref={map_container_ref}
					>
						<GeoJSON
							style={mapStyle}
							data={features}
							onEachFeature={mapOnEachFeature}
						/>

						{position && (
							<Marker
								position={position}
								draggable={false}
								icon={markerIcon}
							/>
						)}
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
					</MapContainer>
				) : (
					""
				)}
			</div>
		</div>
	);
};
export default LocationDisplay;
