import {
  Card,
  // CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  IconButton,
  TableHead,
  TableBody,
  Typography,
  Collapse,
  Box,
  TablePagination,
  TextField,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import { ArrowForward, Search } from "@material-ui/icons";
import axios from "axios";
import React, { Fragment, useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { ArrowUp } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const LabOrderTest = () => {
  const { users } = useContext(UsersData);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [listItem, setListItem] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [labOrderList, setLabOrderList] = useState({
    data: [],
    ready: false,
  });
  const mounted = IsMountedRef();

  const getLaboratoryOrderList = React.useCallback(async () => {
    let response = await axios.get("laboratory/items/laborder/list-available", {
      params: {
        management_id: users.management_id,
        user_id: users.user_id,
      },
    });

    if (mounted.current) {
      setLabOrderList({ data: response.data, ready: true });
    }
  }, [mounted, users]);

  const handleSelectedOrder = async (data) => {
    if (selectedOrder) {
      if (selectedOrder.order_id === data.order_id) {
        console.log("id is currently selected");
        return;
      }
    }

    let response = await axios.get(
      "laboratory/items/laborder/list-available-items",
      {
        params: {
          user_id: users.user_id,
          management_id: users.management_id,
          order_id: data.order_id,
        },
      }
    );
    setSelectedOrder(data);
    setListItem({ data: response.data, ready: true });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const searchable = labOrderList.data.filter((data) => {
    return (
      data.laborder.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
    );
  });

  useEffect(() => {
    // getTempOrderList();
    getLaboratoryOrderList();
  }, [
    // getTempOrderList,
    getLaboratoryOrderList,
  ]);

  return (
    <Card>
      {/* <CardHeader
        title={
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h5">Laboratory Order List</Typography>
            </Box>
            <Box>
              <TextField
                label="Search Lab Order"
                variant="outlined"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        }
      /> */}
      <CardContent>
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          mb={1}
        >
          <Box flexGrow={1}>
            <Typography variant="h5" color="textSecondary">
              <strong>Laboratory Order List</strong>
            </Typography>
          </Box>
          <Box>
            <TextField
              label="Search Lab Order"
              variant="outlined"
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Divider light />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Category</b>
                </TableCell>
                <TableCell>
                  <b>Lab Order</b>
                </TableCell>
                <TableCell>
                  <b>Rate</b>
                </TableCell>
                <TableCell align="center">
                  <b>Action</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {labOrderList.ready ? (
                searchable.length > 0 ? (
                  searchable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => {
                      return (
                        <Fragment key={index}>
                          <TableRow>
                            <TableCell>
                              {data.category === "clinical-chemistry"
                                ? "blood-chemistry"
                                : data.category}
                            </TableCell>
                            <TableCell> {data.laborder}</TableCell>
                            <TableCell align="right">
                              {Notify.convertToNumber(data.rate)}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                color="primary"
                                onClick={() => handleSelectedOrder(data)}
                                disabled={
                                  selectedOrder &&
                                  selectedOrder.order_id === data.order_id
                                }
                              >
                                {selectedOrder &&
                                selectedOrder.order_id === data.order_id ? (
                                  <ArrowUp />
                                ) : (
                                  <ArrowForward />
                                )}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              {selectedOrder && (
                                <Collapse
                                  in={data.order_id === selectedOrder.order_id}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box margin={1}>
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      component="div"
                                    >
                                      Item List
                                    </Typography>

                                    <Box>
                                      <Table size="small">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>
                                              <b> Item </b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                maxWidth: 250,
                                              }}
                                            >
                                              <b> Description </b>
                                            </TableCell>
                                            <TableCell>
                                              <b> Supplier </b>
                                            </TableCell>
                                            <TableCell>
                                              <b> Qty </b>
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {listItem.ready ? (
                                            listItem.data.length > 0 ? (
                                              listItem.data.map(
                                                (datax, xindex) => (
                                                  <TableRow key={xindex}>
                                                    <TableCell>
                                                      {datax.item}
                                                    </TableCell>
                                                    <TableCell>
                                                      {datax.description}
                                                    </TableCell>
                                                    <TableCell>
                                                      {datax.supplier}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                      {
                                                        datax._total_qty_available
                                                      }
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                              )
                                            ) : (
                                              <TableRow>
                                                <TableCell colSpan={5}>
                                                  <Typography color="secondary">
                                                    No record found.
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                            )
                                          ) : (
                                            <TableRow>
                                              <TableCell colSpan={5}>
                                                <Typography color="primary">
                                                  loading, please wait...
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Box>
                                </Collapse>
                              )}
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <Typography color="primary">
                      Loading, Please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={searchable.length}
            rowsPerPageOptions={[5, 50, 100]}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="List"
          />
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default LabOrderTest;
