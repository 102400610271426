import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useMemo, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import { faUserInjured } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import PatientProfileModal from "./components/PatientProfileModal";
import AddPatientFormModal from "./components/AddPatientFormModal";
import TabGroup from "src/components/TabGroup";
import axios from "axios";
import ViewPatientProfile from "./components/ViewPatientProfile";
import { Fade } from "react-reveal";
import Img from "src/components/Img";
import { UsersData } from "src/ContextAPI";
import { Link } from "react-router-dom";

const PatientItem = ({ data, onClick }) => {
	return (
		<div
			className="flex items-center rounded-xl gap-3 p-2 bg-background mb-3 last:mb-0 !rounded-xl hover:bg-blue-50 cursor-pointer border"
			onClick={onClick}
		>
			<div className="w-11 h-11 rounded-full flex items-center justify-center bg-white">
				<Img
					type="user"
					name={`${data?.lastname}-${data?.firstname}-${data?.middle}`}
					className="w-full rounded-full aspect-square object-cover"
					alt=""
				/>
			</div>
			<div className="flex flex-col items-center lg:items-start xl:items-start w-full text-left">
				<h6
					className={`text-left text-sm font-semibold ${
						data?.gender == "Male"
							? "text-blue-700"
							: "text-blue-700"
					} mb-0`}
				>
					{`${data?.lastname}, ${data?.firstname} ${data?.middle}`}
				</h6>
				<p className="mb-0 text-placeholder text-xs">Click to view</p>
			</div>
		</div>
	);
};

const PhoPatientList = () => {
	const patient_profile_ref = useRef(null);
	const add_patient_ref = useRef(null);
	const { users } = useContext(UsersData);

	const [view, setView] = useState("table");
	const [key, setKey] = useState(`key-${Math.random(1000)}`);
	const { data, loading } = useDataTable(
		"admission/patients/getpatient-list",
		null,
		{
			user_id: users.user_id,
			management_id: users.management_id,
			main_mgmt_id: users.main_mgmt_id,
			key: key,
		}
	);
	const [patient, setPatient] = useState(null);
	const [loadingPatientData, setLoadingPatientData] = useState(false);

	const getPatientInfo = (patient) => {
		setLoadingPatientData(true);
		var params = { patient_id: patient?.patient_id };
		axios
			.get("admission/patient/patient-information", { params })
			.then((res) => {
				setPatient(patient);
				setTimeout(() => {
					setLoadingPatientData(false);
				}, 500);
			});

		/* axios.get(`v1/patients/${id}`).then((res) => {
			setPatient(res.data.data);
		}); */
	};

	/* 
	const getDoctorsPatient = useCallback(() => {
		var params = {
			user_id: users.user_id,
			management_id: users.management_id,
			main_mgmt_id: users.main_mgmt_id,
		};
		Axios.get("admission/patients/getpatient-list", { params })
			.then((response) => {
				const data = response.data;
				setPatient({ data: data, ready: true });
			})
			.catch((error) => {
				console.log("error: ", error);
				// Notify.requestError(error);
			});
	}, [users]); */
	const columns = useMemo(
		() => [
			{
				header: "",
				accessorKey: "patient",
				className: "w-11",
				headerClassName: "w-11",
				cell: function (data) {
					return (
						<div
							className="flex items-center cursor-pointer"
							onClick={() => {
								patient_profile_ref.current.show(data);
							}}
						>
							<div className="w-11 h-11 rounded-full flex items-center justify-center">
								<Img
									type="user"
									name={`${data?.lastname}-${data?.firstname}-${data?.middle}`}
									className="w-full  aspect-square object-cover rounded-full"
									alt=""
								/>
							</div>
						</div>
					);
				},
			},
			{
				header: "Lastname",
				accessorKey: "lastname",
			},
			{
				header: "Firstname",
				accessorKey: "firstname",
			},
			{
				header: "Middle",
				accessorKey: "middle",
			},
			{
				header: "Action",
				accessorKey: "action",
				className: "w-[244px]",
				cell: (data) => {
					return (
						<div className="flex items-center gap-2">
							<ActionBtn
								size="sm"
								type="foreground-light"
								onClick={() => {
									patient_profile_ref.current.show(data);
								}}
							>
								<FlatIcon
									icon="rr-eye"
									className="text-lg mr-2"
								/>
								View
							</ActionBtn>
							<ActionBtn
								size="sm"
								type="primary-light"
								onClick={() => {
									add_patient_ref.current.show(data);
								}}
							>
								<FlatIcon
									icon="rr-edit"
									className="text-lg mr-2"
								/>
								Edit
							</ActionBtn>
							{/* <ActionBtn size="sm" type="danger-light">
								<FlatIcon icon="rr-trash" className="text-lg mr-2" />
								Delete
							</ActionBtn> */}
						</div>
					);
				},
			},
		],
		[]
	);
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faUserInjured}
						color="white"
						size={"2x"}
					/>
				}
				title="Patient List"
				subtitle="View, Register, and Update Patients"
			>
				<CardContainer
					containerClassName={` border border- border-opacity-10`}
					header={{
						title: "Patient List Data",
						children: (
							<div className="ml-auto flex items-center gap-3">
								<ActionBtn
									size="sm"
									className="px-3 !h-[42px]"
									onClick={() => {
										add_patient_ref.current.show();
									}}
								>
									<FlatIcon
										icon="br-user-add"
										className="text-xl mr-2"
									/>
									Add patient
								</ActionBtn>
								{view == "table" ? (
									<>
										<ActionBtn
											size="sm"
											className="px-3 !h-[42px]"
											type="background-gradient"
											onClick={() => {
												setView("list");
											}}
										>
											<FlatIcon
												icon="rr-ballot"
												className="text-xl mr-2"
											/>
											List view
										</ActionBtn>
										<TextInputField
											inputClassName=" pl-10"
											className="w-full lg:w-[320px]"
											icon={
												<FlatIcon
													icon="rr-search"
													className="text-sm"
												/>
											}
											placeholder="Search patient"
										/>
									</>
								) : (
									<ActionBtn
										size="sm"
										className="px-3 !h-[42px]"
										type="background-gradient"
										onClick={() => {
											setView("table");
										}}
									>
										<FlatIcon
											icon="rr-grid"
											className="text-xl mr-2"
										/>
										Table view
									</ActionBtn>
								)}
							</div>
						),
					}}
					className="!p-0"
				>
					{view == "table" ? (
						<TableV2
							paginationClassName="px-4"
							columns={columns}
							pagination={true}
							loading={loading}
							data={data}
							onTableChange={(data) => {
								console.log("onTableChange", data);
							}}
						/>
					) : (
						<div
							className={`grid grid-cols-12 transition-all duration-1000 ${
								view == "list" ? "opacity-100" : "opacity-0 "
							}`}
						>
							<div className="flex flex-col xl:col-span-3 lg:col-span-4 border-r p-3">
								<TextInputField
									inputClassName=" pl-10"
									className="w-full mb-4"
									icon={
										<FlatIcon
											icon="rr-search"
											className="text-sm"
										/>
									}
									placeholder="Search patient"
								/>
								{data?.map((data) => {
									return (
										<PatientItem
											data={data}
											onClick={() => {
												getPatientInfo(data);
											}}
										/>
									);
								})}
							</div>
							<div className="flex flex-col xl:col-span-9 lg:col-span-8 border-r">
								{patient == null ? (
									<p className="text-placeholder py-11 text-center">
										Select patient to view.
									</p>
								) : loadingPatientData ? (
									<p className="text-placeholder py-11 text-center">
										Loading patient info...
									</p>
								) : (
									<ViewPatientProfile
										patient={patient}
										add_patient_ref={add_patient_ref}
									/>
								)}
							</div>
						</div>
					)}
				</CardContainer>
			</LayoutContainer>
			<PatientProfileModal
				ref={patient_profile_ref}
				add_patient_ref={add_patient_ref}
			/>
			<AddPatientFormModal ref={add_patient_ref} setKey={setKey} />
		</>
	);
};

export default PhoPatientList;
