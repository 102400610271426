import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
} from "@material-ui/core";

export default function AddModal({ title, content, open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <form>
        <DialogTitle>
          <div className="flex justify-between items-center">
            <Box>
              <Typography variant="h5" color="textSecondary">
                <strong>{title}</strong>
              </Typography>
            </Box>
            <button
              className="text-red-600 hover:bg-red-300 py-2 px-3  rounded-lg "
              onClick={onClose}
              type="button"
            >
              CLOSE
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="w-[500px] mb-3">{content}</div>
        </DialogContent>
      </form>
    </Dialog>
  );
}
