import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";

import { useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import { TextField } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
import Img from "src/components/Img";
import FlatIcon from "src/components/FlatIcon";
import {
	calculateAgeV3,
	formatDateMMDDYYYYHHIIA,
	patientFullName,
} from "src/helpers/utils";
import PreviewImageModal from "src/cis/components/PreviewImageModal";
import BhsPatientVitals from "./BhsPatientVitals";

const RenderAppointmentStatus = ({ vitals, prescriptions, status = "" }) => {
	// pending
	// pending-for-doctor-
	switch (status) {
		case "pending":
			return vitals == null ? (
				<span className="text-orange-500 font-bold">
					Pending for get vitals
				</span>
			) : (
				<span className="text-orange-500 font-bold">
					Pending for service
				</span>
			);

		case "pending-doctor-consultation":
			return (
				<span className="text-orange-600 font-bold">
					Pending for doctor consultation
				</span>
			);
		case "pending-for-bhw-release":
			return (
				<span className="text-orange-600 font-bold">
					Pending for medicine release
				</span>
			);
		case "pending-doctor-confirmation":
			return (
				<span className="text-orange-600 font-bold">
					pending for doctor's confirmation
				</span>
			);
		case "pending-for-pharmacy-release":
			return prescriptions?.length == 0 ? (
				<span className="text-orange-600 font-bold">
					pending for doctor's prescription
				</span>
			) : (
				<span className="text-orange-500 font-bold">
					pending for pharmacy release
				</span>
			);

		default:
			return status;
	}
};

const ViewAppointmentModal = (props, ref) => {
	const { appointmentVitalsRef, serviceModal, referToRHURef } = props;
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(null);
	const [loading, setLoading] = useState(false);
	const previewImgRef = useRef(null);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		setAppointment(null);
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const submit = (data) => {
		setLoading(true);
		let formData = new FormData();
		formData.append("appointment_id", appointment?.id);
		formData.append("_method", "PATCH");

		// axios
		// 	.post(`/v1/clinic/send-to-cashier/${appointment?.id}`, formData)
		// 	.then((res) => {
		// 		setTimeout(() => {
		// 			setLoading(false);
		// 			onSuccess();
		// 			toast.success("Patient successfully sent to cashier!");
		// 		}, 400);
		// 		hide();
		// 	})
		// 	.finally(() => {
		// 		setLoading(false);
		// 	});
	};

	return (
		<>
			<Modal open={open} hide={hide} className="min-w-[869px] w-full">
				<ModalHeader
					title={`View Appointment`}
					subtitle="You are viewing appointment details"
					hide={hide}
				/>
				<ModalBody className={`p-4 bg-white`}>
					<div className="flex flex-col">
						<div className="flex items-center gap-3 pb-3">
							<div className="w-[144px] h-[144px] overflow-hidden relative rounded-lg">
								<Img
									className="object-contain w-full h-full absolute top-0 left-0"
									src={appointment?.patient_selfie}
									type="user"
									onClick={() => {
										if (appointment?.patient_selfie)
											previewImgRef.current.show({
												image: appointment?.patient_selfie,
												title: patientFullName(
													appointment?.patient
												),
											});
									}}
									name={patientFullName(appointment?.patient)}
								/>
							</div>
							<div className="flex flex-col">
								<h6 className="font-bold mb-2 text-left text-xl">
									{patientFullName(appointment?.patient)}
								</h6>
								<div className="flex flex-col text-sm gap-2">
									<span className="flex gap-1">
										<FlatIcon icon="rr-venus-mars" />
										<span className="font-light capitalize">
											{appointment?.patient?.gender}
										</span>
									</span>
									<span className="flex gap-1">
										<FlatIcon icon="rr-calendar" />
										<span className="font-light">
											{calculateAgeV3(
												appointment?.patient?.birthday
											)}{" "}
											yrs. old
										</span>
									</span>
								</div>
							</div>
							<div className="ml-auto flex flex-col gap-2">
								{appointment?.vitals == null ? (
									<ActionBtn
										size="lg"
										className="px-2"
										type="accent"
										onClick={() => {
											appointmentVitalsRef.current.show(
												appointment
											);
										}}
									>
										<FlatIcon icon="rr-upload" /> Update
										Vitals
									</ActionBtn>
								) : (
									""
								)}
								{appointment?.vitals != null &&
								appointment?.serviced_by == null ? (
									<>
										<ActionBtn
											size="sm"
											className=""
											onClick={() => {
												serviceModal.current.show(
													appointment
												);
											}}
										>
											<FlatIcon icon="rr-plus" /> Add
											service
										</ActionBtn>
										<ActionBtn
											size="sm"
											type="danger"
											className=""
											onClick={() => {
												referToRHURef.current.show(
													appointment
												);
											}}
										>
											<FlatIcon icon="rr-paper-plane" />{" "}
											Refer to RHU
										</ActionBtn>
									</>
								) : (
									""
								)}
							</div>
						</div>

						<div className="square-table bordered-table-2 border-b text-sm w-full">
							<table className="w-full">
								<tbody>
									<tr>
										<th>Date:</th>
										<td className="">
											{formatDateMMDDYYYYHHIIA(
												new Date(
													appointment?.created_at
												)
											)}
										</td>
										<th>Status:</th>
										<td className="">
											<RenderAppointmentStatus
												{...appointment}
											/>
										</td>
									</tr>
									<tr>
										<th>Impression:</th>
										<td colSpan={3}>
											{appointment?.impression || (
												<i className="text-xs font-light text-slate-400 ">
													N/A
												</i>
											)}
										</td>
									</tr>
									<tr>
										<th>Initial Diagnosis:</th>
										<td colSpan={3}>
											{appointment?.post_notes || (
												<i className="text-xs font-light text-slate-400 ">
													N/A
												</i>
											)}
										</td>
									</tr>
									<tr>
										<th colSpan={1}>
											History:
											<br />
											<i className="text-xs font-light text-slate-400 ">
												*Brief Clinical History and{" "}
												<br />
												Pertinent Physical Examination*
											</i>
										</th>
										<td colSpan={3}>
											{appointment?.clinical_history || (
												<i className="text-xs font-light text-slate-400 ">
													N/A
												</i>
											)}
										</td>
									</tr>
									<tr>
										<th colSpan={1}>
											Laboratory Findings:
											<br />
											<i className="text-xs font-light text-slate-400 ">
												(Including ECG, X-ray, and{" "}
												<br /> other diagnostic
												procedures)
											</i>
										</th>
										<td colSpan={3}>
											{appointment?.lab_findings || (
												<i className="text-xs font-light text-slate-400 ">
													N/A
												</i>
											)}
										</td>
									</tr>
									<tr>
										<th colSpan={1}>Action Taken:</th>
										<td colSpan={3}>
											{appointment?.action_taken || (
												<i className="text-xs font-light text-slate-400 ">
													N/A
												</i>
											)}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						{appointment?.vitals ? (
							<div className="mt-4 flex flex-col rounded-sm">
								<span className="font-bold text-sm mb-1">
									Vital Signs
								</span>

								<BhsPatientVitals
									vitals={appointment?.vitals}
								/>
							</div>
						) : (
							""
						)}
					</div>
				</ModalBody>
				<ModalFooter className={"flex items-center"}>
					<ActionBtn
						className="!py-2 ml-auto"
						type="danger"
						onClick={hide}
						disabled={loading}
					>
						Close
					</ActionBtn>
				</ModalFooter>
			</Modal>
			<PreviewImageModal ref={previewImgRef} />
		</>
	);
};

export default forwardRef(ViewAppointmentModal);
