import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { Trash } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import CreateDoctorServiceOrder from "./CreateDoctorServiceOrder";
// import CreateOrder from "./CreateOrder";

const AddDoctorService = ({
  patient_id,
  getPatientBillingDetails,
  trace_number,
}) => {
  const [list, setList] = useState({ data: null, ready: false });
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();

  const getDoctorServiceUnpaidList = useCallback(async () => {
    let response = await axios.get("cashier/add-on/othertest-unpaid", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
        department: "doctor",
      },
    });

    if (mounted.current) {
      setList({ data: response.data, ready: true });
    }
  }, [mounted, users]);

  const handleRemoveOrderTest = async (cpb_id) => {
    var formdata = new FormData();
    formdata.set("cpb_id", cpb_id);

    let response = await axios.post(
      "cashier/other/order/remove-othertest",
      formdata
    );

    const data = response.data;
    if (data.message === "success") {
      Notify.successRequest("Order remove");
      getDoctorServiceUnpaidList();
    }
  };

  useEffect(() => {
    getDoctorServiceUnpaidList();
  }, [getDoctorServiceUnpaidList]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <CreateDoctorServiceOrder
          trace_number={trace_number}
          patient_id={patient_id}
          getPatientBillingDetails={() => getPatientBillingDetails()}
          getDoctorServiceUnpaidList={() => getDoctorServiceUnpaidList()}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Card>
          <CardHeader title="Unpaid Doctor Order" />
          <CardContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> Service </TableCell>
                    <TableCell> Service Rate </TableCell>
                    <TableCell> Action </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {list.ready ? (
                    list.data.length > 0 ? (
                      list.data.map((data, key) => (
                        <TableRow key={key}>
                          <TableCell> {data.bill_name} </TableCell>
                          <TableCell align="right">
                            {Notify.convertToNumber(data.bill_amount)}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              color="secondary"
                              onClick={() => handleRemoveOrderTest(data.cpb_id)}
                            >
                              <Trash />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          <Typography color="secondary">
                            No record found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        <Typography color="primary">
                          Loading, please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddDoctorService;
