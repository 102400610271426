import { Box, Typography, Grid } from "@material-ui/core";
// import axios from "axios";
import React, {
  Fragment,
  //  useContext, useState, useEffect
} from "react";
// import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import HeaderPrintAll from "../printall/HeaderPrintAll";
import PatientInfoPrintAll from "../printall/PatientInfoPrintAll";

const MedicalCertificatePrintAll = ({
  allowHeaderPatientInfo,
  patient_id,
  trace_number,
  patients,
  medCertOrderDetails,
}) => {
  // const { users } = useContext(UsersData);

  // const [medCertOrderDetails, setMedicalCertOrderDetails] = useState({
  //   data: [],
  //   ready: false,
  // });

  // const getMedicalCertOrderDetails = () => {
  //   var params = {
  //     user_id: users.user_id,
  //     trace_number: trace_number,
  //     patient_id: patient_id,
  //   };
  //   axios
  //     .get("laboratory/order/ordernew-medicalcert/complete/medicalcert-print", {
  //       params,
  //     })
  //     .then((response) => {
  //       const data = response.data;
  //       setMedicalCertOrderDetails({ data: data, ready: true });
  //     })
  //     .catch((error) => {
  //       Notify.requestError(error);
  //     });
  // };

  // useEffect(() => {
  //   getMedicalCertOrderDetails();
  //   // eslint-disable-next-line
  // }, []);

  return (
    <>
      {medCertOrderDetails.ready && medCertOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box align="center" mt={2} mb={2}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bolder", textDecoration: "underline" }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                MEDICAL CERTIFICATE
              </span>
            </Typography>
          </Box>
          <Box mt={5}>
            <Grid container>
              <Grid item xs={8}>
                <Box display={"flex"} mb={2}>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                      variant="h6"
                      noWrap
                    >
                      <span
                        style={{
                          marginLeft: 50,
                        }}
                      ></span>
                      This is to certify that
                    </Typography>
                  </Box>
                  <Box align="center" borderBottom={1} width={"100%"}>
                    {medCertOrderDetails.data[0] &&
                    medCertOrderDetails.data[0].firstname !== null
                      ? `${medCertOrderDetails.data[0].firstname} ${medCertOrderDetails.data[0].lastname}`
                      : ""}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box display={"flex"} mb={2}>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                      variant="h6"
                      noWrap
                    >
                      , a
                    </Typography>
                  </Box>
                  <Box align="center" borderBottom={1} width={"100%"}>
                    {medCertOrderDetails.data[0] &&
                    medCertOrderDetails.data[0].birthday !== null
                      ? Notify.calculateAge(
                          medCertOrderDetails.data[0].birthday
                        )
                      : ""}
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                      variant="h6"
                      noWrap
                    >
                      year old,
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"} mb={2}>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                      variant="h6"
                      noWrap
                    >
                      presently residing at
                    </Typography>
                  </Box>
                  <Box align="center" borderBottom={1} width={"100%"}>
                    {medCertOrderDetails.data[0] &&
                    medCertOrderDetails.data[0].city !== null
                      ? `${
                          medCertOrderDetails.data[0].street !== null
                            ? medCertOrderDetails.data[0].street
                            : ""
                        }, ${medCertOrderDetails.data[0].barangay}, ${
                          medCertOrderDetails.data[0].city
                        }`
                      : ""}
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                      variant="h6"
                    >
                      ,
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"} mb={2}>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                      variant="h6"
                      noWrap
                    >
                      was examined on
                    </Typography>
                  </Box>
                  <Box align="center" borderBottom={1} width={"100%"}>
                    {medCertOrderDetails.data[0] &&
                    medCertOrderDetails.data[0].created_at !== null
                      ? new Date(
                          medCertOrderDetails.data[0].created_at
                        ).toLocaleString("en-US", {
                          day: "numeric",
                        })
                      : ""}
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                      variant="h6"
                      noWrap
                    >
                      day of
                    </Typography>
                  </Box>
                  <Box align="center" borderBottom={1} width={"100%"}>
                    {medCertOrderDetails.data[0] &&
                    medCertOrderDetails.data[0].created_at !== null
                      ? new Date(
                          medCertOrderDetails.data[0].created_at
                        ).toLocaleString("en-US", {
                          month: "long",
                        })
                      : ""}
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                      variant="h6"
                      noWrap
                    >
                      .
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Box>
                <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                  Diagnosis/Laboratory findings:
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">
                  {medCertOrderDetails.data[0] &&
                  medCertOrderDetails.data[0].diagnosis_findings !== null
                    ? medCertOrderDetails.data[0].diagnosis_findings
                    : ""}
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Box>
                <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                  Recommendations:
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">
                  {medCertOrderDetails.data[0] &&
                  medCertOrderDetails.data[0].recommendation !== null
                    ? medCertOrderDetails.data[0].recommendation
                    : ""}
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Box>
                <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                  Remarks:
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">
                  {medCertOrderDetails.data[0] &&
                  medCertOrderDetails.data[0].remarks !== null
                    ? medCertOrderDetails.data[0].remarks
                    : ""}
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Box display={"flex"} mb={2}>
                <Box>
                  <Typography
                    style={{
                      fontWeight: "bolder",
                    }}
                    variant="h6"
                    noWrap
                  >
                    Issued on the
                  </Typography>
                </Box>
                <Box align="center" borderBottom={1} width={"100%"}>
                  {medCertOrderDetails.data[0] &&
                  medCertOrderDetails.data[0].issued_at !== null
                    ? new Date(
                        medCertOrderDetails.data[0].issued_at
                      ).toLocaleString("en-US", {
                        day: "numeric",
                      })
                    : ""}
                </Box>
                <Box>
                  <Typography
                    style={{
                      fontWeight: "bolder",
                    }}
                    variant="h6"
                    noWrap
                  >
                    day of
                  </Typography>
                </Box>
                <Box align="center" borderBottom={1} width={"100%"}>
                  {medCertOrderDetails.data[0] &&
                  medCertOrderDetails.data[0].issued_at !== null
                    ? new Date(
                        medCertOrderDetails.data[0].issued_at
                      ).toLocaleString("en-US", {
                        month: "long",
                      })
                    : ""}
                </Box>
                <Box>
                  <Typography
                    style={{
                      fontWeight: "bolder",
                    }}
                    variant="h6"
                    noWrap
                  >
                    at Davao City, Philippines.
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box mt={5}>
              <Box display={"flex"}>
                <Box flexGrow={1} />
                <Box width={400} display={"flex"}>
                  <Box align="center" width={"100%"}>
                    <Typography variant="h6" noWrap>
                      {medCertOrderDetails.data[0] &&
                      medCertOrderDetails.data[0].doctor_name !== null
                        ? medCertOrderDetails.data[0].doctor_name
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box display={"flex"}>
                <Box flexGrow={1} />
                <Box width={400} display={"flex"}>
                  <Box align="center" width={"100%"}>
                    <Typography variant="h6" noWrap>
                      {medCertOrderDetails.data[0] &&
                      medCertOrderDetails.data[0].doctor_specialization !== null
                        ? medCertOrderDetails.data[0].doctor_specialization
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box display={"flex"}>
                <Box flexGrow={1} />
                <Box width={400} display={"flex"}>
                  <Box align="center" width={"100%"}>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                      variant="h6"
                      noWrap
                    >
                      Lic. No.{" "}
                      {medCertOrderDetails.data[0] &&
                      medCertOrderDetails.data[0].doctor_lic !== null
                        ? medCertOrderDetails.data[0].doctor_lic
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default MedicalCertificatePrintAll;
