import React, { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	Box,
	Typography,
	TextField,
	Card,
} from "@material-ui/core";
import { styles } from "../styles/styles";
import { useForm } from "react-hook-form";
import Img from "src/components/Img";
import { calculateAgeV2 } from "src/helpers/utils";
import { formatDate } from "src/helpers/utils";
import axios from "axios";
import { toast } from "react-toastify";

export default function MarkAsDone({ data: propData, open, onClose, patient }) {
	const [loading, setLoading] = useState(false);
	const [date, setDate] = useState("");
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		console.log(data);
		setLoading(true);
		axios
			.patch(`v1/clinic-queueing/done/${propData?.id}`)
			.then((response) => {
				setLoading(false);
				let data = response.data;
				console.log(data);
				toast.success("Patient marked as done!");
				onClose();
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	useEffect(() => {
		const currentDateTime = new Date();
		const formattedDate = currentDateTime.toISOString().split("T")[0];

		setDate(`${formattedDate}`);
	}, [open]);

	return (
		<Dialog open={open} onClose={onClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogTitle>
					<div className="flex justify-between items-center">
						<Box>
							<Typography variant="h5" color="textSecondary">
								<strong>Mark As Done</strong>
							</Typography>
						</Box>
						<button
							className="text-red-600 hover:bg-red-300 py-2 px-3  rounded-lg "
							onClick={onClose}
							type="button"
						>
							CLOSE
						</button>
					</div>
				</DialogTitle>
				<DialogContent>
					<Card elevation={3} className="py-4  px-3" component={Box}>
						<div className="w-[500px]">
							<div className="p-2 flex items-center justify-center">
								<Typography variant="h6">
									<b>Please Click Done to Proceed</b>
								</Typography>
							</div>
						</div>
					</Card>
					<div className="py-3 mt-2">
						<Button
							fullWidth
							style={{ fontSize: "18px" }}
							variant="contained"
							color="primary"
							type="submit"
							disabled={loading}
						>
							{loading ? "Loading..." : "Done"}
						</Button>
					</div>
				</DialogContent>
			</form>
		</Dialog>
	);
}
