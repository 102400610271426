import React, { Fragment, useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Axios from "axios";
import Notify from "src/notification/Notify";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button, IconButton } from "@material-ui/core";
import { ArrowLeft, ArrowRight, PlusCircle } from "react-feather";
import { UsersData } from "src/ContextAPI";
import NSPatientHistoryNew from "./history/NSPatientHistoryNew";
import NSPatientHistoryDetails from "./history/NSPatientHistoryDetails";
// import PDHistoryNew from "./PDHistoryNew";
// import PDHistoryDetails from "./PDHistoryDetails";

function NSPatientHistoryIllness({ patient_id, info }) {
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);
	const [selectedView, setSelectedView] = useState("historylist");
	const { users } = useContext(UsersData);

	const [patientCardList, setPatientCardList] = useState({
		data: null,
		dialog: false,
	});

	const [selectHistoryIllnessInList, setSelectHistoryIllnessInList] = useState(
		[]
	);

	const getAllIllnessPE = () => {
		var params = {
			patient_id,
			//   type,
			management_id: users.management_id,
			main_mgmt_id: users.main_mgmt_id,
		};
		Axios.get("nurse/get/history-illness/patient-id", { params })
			.then((response) => {
				const data = response.data;
				setPatientCardList({ data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		getAllIllnessPE();
		// eslint-disable-next-line
	}, [patient_id]);

	return (
		<Fragment>
			<Grid item xs={12} sm={12}>
				{selectedView === "historylist" ? (
					<Box component={Paper} elevation={2} p={2}>
						<TableContainer>
							<PerfectScrollbar>
								<Box
									display="flex"
									justifyContent={"center"}
									alignItems={"center"}
								>
									<Box flexGrow={1}>
										<Typography variant="h6" color="textSecondary">
											<strong>History of Illness</strong>
										</Typography>
									</Box>
									<Box
									// hidden={user === "nurse" ? true : false}
									>
										<Button
											startIcon={<PlusCircle fontSize={"small"} />}
											onClick={() => setSelectedView("newhistory")}
											color="primary"
											variant="outlined"
										>
											New
										</Button>
									</Box>
								</Box>

								{patientCardList.ready ? (
									patientCardList.data.length > 0 ? (
										<>
											<Table aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>
															<strong>Date</strong>
														</TableCell>
														<TableCell>
															<strong>Final Diagnosis</strong>
														</TableCell>
														<TableCell>
															<strong>Action</strong>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{patientCardList.data.map((data, index) => (
														<TableRow key={index}>
															<TableCell>
																{Notify.createdAt(data.created_at)}
															</TableCell>
															<TableCell>{data.final_diagnosis}</TableCell>
															<TableCell>
																<IconButton
																	onClick={() => {
																		setSelectHistoryIllnessInList(data);
																		setSelectedView("patientcarddetails");
																	}}
																	color={"primary"}
																>
																	<ArrowRight />
																</IconButton>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>

											<TablePagination
												labelRowsPerPage="List"
												rowsPerPageOptions={[5, 20, 50, 100]}
												component="div"
												count={patientCardList.data.length}
												rowsPerPage={rowsPerPage}
												page={page}
												onChangePage={handleChangePage}
												onChangeRowsPerPage={handleChangeRowsPerPage}
											/>
										</>
									) : (
										Notify.noRecord()
									)
								) : (
									Notify.loading()
								)}
							</PerfectScrollbar>
						</TableContainer>
					</Box>
				) : selectedView === "newhistory" ? (
					<>
						<Box mb={1}>
							<Button
								color={"primary"}
								variant="contained"
								startIcon={<ArrowLeft />}
								onClick={() => {
									setSelectedView("historylist");
								}}
							>
								Back
							</Button>
						</Box>
						<NSPatientHistoryNew
							patient_id={patient_id}
							info={info}
							getAllIllnessPE={() => getAllIllnessPE()}
							close={() => setSelectedView("historylist")}
						/>
					</>
				) : (
					<>
						<Box mb={1}>
							<Button
								color={"primary"}
								variant="contained"
								startIcon={<ArrowLeft />}
								onClick={() => {
									setSelectedView("historylist");
									setSelectHistoryIllnessInList([]);
								}}
							>
								Back
							</Button>
						</Box>
						<NSPatientHistoryDetails
							patient_id={patient_id}
							info={info}
							details={selectHistoryIllnessInList}
							chi_id={selectHistoryIllnessInList.chi_id}
							getAllIllnessPE={() => getAllIllnessPE()}
						/>
					</>
				)}
			</Grid>
		</Fragment>
	);
}

export default NSPatientHistoryIllness;
