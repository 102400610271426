import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState } from "react";
import { CheckSquare, XCircle } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const PatientSetAsDone = ({
  patient_id,
  getNurseOnQueue,
  info,
  closeSetAsDone,
  onClose,
}) => {
  const [openDialog, setOpenDialog] = useState(true);
  const { users } = useContext(UsersData);

  const handleSetAsDone = () => {
    var formdata = new FormData();
    formdata.append("user_id", users.user_id);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("patient_id", patient_id);

    axios
      .post("nurse/update/new-queue-to-cashier", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setOpenDialog(false);
          getNurseOnQueue();
          onClose();
          Notify.successRequest("set as done");
        } else {
          Notify.customToast("Something went wrong", "Please try again...");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          closeSetAsDone();
        }}
      >
        <DialogContent>
          <Box hidden={info.data.image !== null}>
            <Typography color={"secondary"}>You cannot proceed.</Typography>
            <Typography color={"secondary"}>
              Please attach photo for the patient.
            </Typography>
          </Box>
          <Box hidden={info.data.image === null}>
            <Typography>
              Are you sure to set this patient done and send queue
            </Typography>
            <Typography>to cashier for payment?</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            startIcon={<XCircle />}
            onClick={() => {
              setOpenDialog(false);
              closeSetAsDone();
            }}
          >
            Close
          </Button>

          <Button
            variant="contained"
            color="primary"
            startIcon={<CheckSquare />}
            onClick={handleSetAsDone}
            disabled={info.data.image === null}
          >
            Set
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PatientSetAsDone;
