import { faColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ActionBtn from "src/components/ActionBtn";
import Button from "src/components/Button";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import Img from "src/components/Img";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import AddToQueueModal from "./components/AddToQueueModal";
import EditClinicDoctorModal from "./components/EditClinicDoctorModal";
import { useClinicContext } from "./context/ClinicContext";

const ClinicDoctors = () => {
	const edit_form_modal = useRef(null);
	const { clinic } = useClinicContext();
	const [list, setList] = useState([]);
	const columns = [
		{
			header: "Doctor Name",
			accessorKey: "name",
			headerClassName: "text-left min-w-[328px]",
			className: "text-left",
			cell: (data) => {
				return (
					<div className="flex items-center gap-3">
						<div className="bg-gray-200 mb-2 w-[32px] h-[32px] lg:w-[32px] lg:h-[32px]  rounded-full relative flex items-center justify-center overflow-hidden">
							<Img
								type="user"
								name={`${data?.lastname}, ${data?.firstname}, ${data?.middle}`}
								src={data?.avatar}
								className="absolute top-0 left-0 w-full h-full object-contain"
							/>
						</div>

						<Link
							to={`/sph/app/cis/patients/${data?.id}`}
						>{`${data?.lastname}, ${data?.firstname}, ${data?.middle}`}</Link>
					</div>
				);
			},
		},
		{
			header: "Room Number",
			accessorKey: "room_number",
			headerClassName: "text-left",
			className: "text-left",
			cell: (data) => {
				return "1";
			},
		},
		{
			header: "Specialty",
			accessorKey: "specialty",
			headerClassName: "text-left",
			className: "text-left",
			cell: (data) => {
				return "surgeon";
			},
		},
		{
			header: "",
			accessorKey: "action",
			headerClassName: "text-left",
			className: "text-left",
			cell: (data) => {
				return (
					<div className="flex items-center justify-center">
						<ActionBtn
							size="sm"
							onClick={() => {
								edit_form_modal.current.show();
							}}
						>
							<FlatIcon icon="rr-edit" />{" "}
						</ActionBtn>
					</div>
				);
			},
		},
	];

	const {
		data,
		setPage,
		loading,
		setPaginate,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
		keyword,
		setKeyword,
	} = useDataTable(`v1/clinic/patients?clinic_id=${clinic?.id}`);

	useEffect(() => {
		if (data?.data) {
			setList(data?.data);
		}
	}, [data]);
	return (
		<LayoutContainer
			icon={
				<FontAwesomeIcon icon={faColumns} color="white" size={"2x"} />
			}
			title="Clinic Doctors"
		>
			<CardContainer
				containerClassName={` border border- border-opacity-10 w-full`}
				header={{
					title: "Doctors list data",
					children: (
						<div className="ml-auto flex items-center gap-3">
							<TextInputField
								inputClassName=" pl-10"
								className="w-full lg:w-[320px]"
								onChange={(e) => {
									setKeyword(e.target.value);
								}}
								icon={
									<FlatIcon
										icon="rr-search"
										className="text-sm"
									/>
								}
								placeholder="Search"
							/>
						</div>
					),
				}}
				className="!p-0"
			>
				<div className="max-h-[calc(100vh-328px)] overflow-auto">
					<TableV2
						paginationClassName="px-4"
						columns={columns}
						pagination={false}
						loading={loading}
						data={list}
						onTableChange={(data) => {}}
						theadClassName={`sticky top-[-1px]`}
					/>
				</div>
			</CardContainer>
			<EditClinicDoctorModal ref={edit_form_modal} />
		</LayoutContainer>
	);
};

export default ClinicDoctors;
