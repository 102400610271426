import {
	faBoxes,
	faVirus,
	faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import ConfirmationModal from "src/components/modal/ConfirmationModal";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import { localArraySearch } from "src/helpers/utils";
import ItemModalForm from "./components/ItemModalForm";
import axios from "axios";
import AddItemStockModal from "./components/AddItemStockModal";
import { UsersData } from "src/ContextAPI";
import HealthUnitDetails from "src/disease-treatment/components/HealthUnitDetails";

const ItemInventory = () => {
	const [list, setList] = useState([]);

	const { users } = useContext(UsersData);
	const [keyword, setKeyword] = useState("");
	const item_form_modal = useRef(null);
	const add_stock_modal = useRef(null);
	console.log("usersusers", users);
	const confirm_delete_ref = useRef(null);
	const {
		data,
		loading,
		updateInList,
		addToList,
		removeFromList,
		column,
		setColumn,
		direction,
		setDirection,
	} = useDataTable("/v1/item-inventory", setList, {});
	useEffect(() => {
		if (data?.data) {
			setList(data?.data || []);
		}
	}, [data]);
	const columns = [
		{
			header: "Item Code",
			accessorKey: "code",
			headerClassName: "text-left min-w-[128px]",
			className: "text-left",
			sortable: true,
			cell: (data) => {
				return data?.item?.code;
			},
		},
		{
			header: "Item Name",
			accessorKey: "name",
			headerClassName: "text-left min-w-[328px]",
			className: "text-left",
			sortable: true,
			cell: (data) => {
				return data?.item?.name;
			},
		},
		{
			header: "Description",
			accessorKey: "description",
			headerClassName: "text-left",
			className: "text-left",
			cell: (data) => {
				return data?.item?.description;
			},
		},
		{
			header: "Unit of Measurement",
			accessorKey: "unit_measurement",
			headerClassName: "text-left",
			className: "text-left",
			cell: (data) => {
				return data?.item?.unit_measurement;
			},
		},
		{
			header: "Price",
			accessorKey: "price",
			headerClassName: "flex items-end justifty-end text-right",
			className: "text-right",
			cell: (data) => {
				return parseFloat(data?.price || 0).toFixed(2);
			},
		},
		{
			header: "Stock",
			accessorKey: "quantity",
			headerClassName: "text-right",
			className: "text-right font-bold",
		},
		{
			header: "Action",
			accessorKey: "action",

			headerClassName: `${users?.type == "LMIS-CNOR" ? "" : "!hidden"}`,
			className: `${users?.type == "LMIS-CNOR" ? "" : "!hidden"}`,
			cell: (data) => {
				return (
					<div className="flex items-center gap-2">
						<ActionBtn
							className="!no-underline !h-[42px]"
							size="sm"
							onClick={() => {
								add_stock_modal.current.show(data?.item);
							}}
						>
							<FlatIcon icon="rr-plus" className="mr-2" />
							<span className="!no-underline">Add stock</span>
						</ActionBtn>
					</div>
				);
			},
		},
	];
	const deleteitem = (id) => {
		return axios.post(`/v1/management/items/${id}`, {
			_method: "DELETE",
		});
	};
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faWarehouse}
						color="white"
						size={"2x"}
					/>
				}
				title="Item Inventory"
				// titleChildren={<HealthUnitDetails />}
				subtitle="View list of items and current inventory"
			>
				<CardContainer
					containerClassName={` border border- border-opacity-10 w-full`}
					header={{
						title: "Item list data",
						children: (
							<div className="ml-auto flex items-center gap-3">
								<TextInputField
									inputClassName=" pl-10"
									className="w-full lg:w-[320px]"
									onChange={(e) => {
										setKeyword(e.target.value);
									}}
									icon={
										<FlatIcon
											icon="rr-search"
											className="text-sm"
										/>
									}
									placeholder="Search"
								/>
							</div>
						),
					}}
					className="!p-0"
				>
					<div className="max-h-[calc(100vh-328px)] overflow-auto">
						<TableV2
							paginationClassName="px-4"
							columns={columns}
							pagination={false}
							loading={loading}
							column={column}
							setColumn={setColumn}
							direction={direction}
							setDirection={setDirection}
							data={
								keyword?.length > 0
									? localArraySearch(list, keyword)
									: list
							}
							onTableChange={(data) => {}}
							theadClassName={`sticky top-[-1px]`}
						/>
					</div>
				</CardContainer>
			</LayoutContainer>
			<AddItemStockModal
				ref={add_stock_modal}
				addToList={addToList}
				updateInList={updateInList}
			/>
			<ConfirmationModal ref={confirm_delete_ref} />
		</>
	);
};

export default ItemInventory;
