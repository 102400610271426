import React, { useEffect, useState } from "react";
import {
	faArrowLeft,
	faPencilAlt,
	faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { formatDate } from "src/helpers/utils";
import CisNewTreatmentPlan from "./CisNewTreatmentPlan";
import { styles } from "../styles/styles";
import { Box, Button, Card, TablePagination } from "@material-ui/core";
import axios from "axios";
import Notify from "src/notification/Notify";
import ButtonV3 from "src/components/ButtonV3";

export default function CisTreatmentPlan({ patient }) {
	const [shown, setShown] = useState(true);
	const patientId = patient.id;
	const [patientTreatment, setPatientTreatment] = useState();
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		let t = setTimeout(() => {
			getData();
		}, 500);
		return () => {
			clearTimeout(t);
		};
	}, [shown]);
	const getData = () => {
		axios
			.get(`v1/patient/treatment-plan/${patientId}`)
			.then(function (response) {
				setPatientTreatment(response.data);
				setLoading(true);
				console.log(patientTreatment);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
				setLoading(true);
			})
			.finally(function () {
				// always executed
			});
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	return (
		<div className="flex w-full flex-col">
			<div className="flex w-full mb-2 items-center">
				<div style={styles.headerContainer}>
					<b className="text-2xl">Treatment Plan</b>
				</div>
				{shown ? (
					<ButtonV3
						onClick={() => {
							setShown(false);
						}}
					>
						<div className="flex">
							<FontAwesomeIcon
								icon={faPlus}
								color="primary"
								style={{ fontSize: 18 }}
								className="pr-2"
							/>
							Add
						</div>
					</ButtonV3>
				) : (
					<ButtonV3
						onClick={() => {
							setShown(true);
						}}
					>
						<div className="flex">
							<FontAwesomeIcon
								icon={faArrowLeft}
								color="primary"
								style={{ fontSize: 18 }}
								className="pr-2"
							/>
							back
						</div>
					</ButtonV3>
				)}
			</div>
			{shown ? (
				<>
					{loading ? (
						patientTreatment.data.length > 0 ? (
							<Card elevation={3} className="p-4" component={Box}>
								<TableContainer style={styles.parentContainer}>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell
													style={styles.tableHeader}
												>
													<strong>Schedule</strong>
												</TableCell>
												<TableCell
													style={styles.tableHeader}
												>
													<strong>
														Treatment Plan
													</strong>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{patientTreatment.data.map(
												({
													id,
													schedule,
													description,
												}) => (
													<>
														<TableRow key={id}>
															<TableCell>
																{formatDate(
																	new Date(
																		schedule
																	)
																)}
															</TableCell>
															<TableCell>
																<div
																	dangerouslySetInnerHTML={{
																		__html: description,
																	}}
																></div>
															</TableCell>
														</TableRow>
													</>
												)
											)}
										</TableBody>
									</Table>
									<TablePagination
										component="div"
										count={patientTreatment.data.length}
										rowsPerPageOptions={[
											10,
											50,
											100,
											{ label: "All", value: -1 },
										]}
										page={page}
										onChangePage={handleChangePage}
										rowsPerPage={rowsPerPage}
										onChangeRowsPerPage={
											handleChangeRowsPerPage
										}
										labelRowsPerPage="List"
									/>
								</TableContainer>
							</Card>
						) : (
							Notify.noRecord()
						)
					) : (
						Notify.loading()
					)}
				</>
			) : (
				<div className="w-2/3">
					<CisNewTreatmentPlan
						setShown={setShown}
						patientId={patientId}
					/>
				</div>
			)}
		</div>
	);
}
