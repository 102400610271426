import {
	Typography,
	Grid,
	Box,
	List,
	TextField,
	InputAdornment,
	ListItem,
	ListItemText,
	Avatar,
	TablePagination,
	ListItemIcon,
	makeStyles,
	CardMedia,
	IconButton,
	Card,
	CardHeader,
	CardContent,
} from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UsersHeader } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import SearchIcon from "@material-ui/icons/Search";
import Container from "src/layout/Container";
import PatientQueueDetails from "./PatientQueueDetails";
import { Search, XCircle } from "react-feather";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import AddPatientToQueueModal from "../patientlist/components/AddPatientToQueueModal";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import { patientFullName, formatDate } from "src/helpers/utils";
import DateTimeClock from "src/clinic/components/DateTimeClock";
import ConfirmationModal from "src/components/modal/ConfirmationModal";
import Tippy from "@tippyjs/react";
import useClinicHook from "src/clinic/services/useClinicHook";
import { toast } from "react-toastify";
const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
	primary: {
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
}));

const DoctorClinicQueue = () => {
	const classes = useStyles();
	const {
		queue: { patient, getPatientsOnQueue },
	} = useContext(UsersHeader);
	const { doneServePatient, servePatient } = useClinicHook();
	const { appointments, getQueue, setAppointments, queue, getAppointments } =
		useClinicContext();

	const add_patient_to_queue_ref = useRef(null);
	const confirmation_modal = useRef(null);

	useEffect(() => {
		// getQueue();
		// getAppointments();
	}, []);
	const confirmDoneServePatient = (patient_id) => {
		confirmation_modal.current.show({
			title: "Mark patient as done",
			message: (
				<div className="py-6 px-2 text-xl  w-full flex flex-col items-center justify-center gap-4">
					<div className=" aspect-square p-3 rounded-full border border-success text-success text-3xl flex items-center justify-center">
						<FlatIcon
							icon="rr-badge-check"
							className="text-[40px]"
						/>
					</div>
					<p>This will mark patient as done.</p>
				</div>
			),
			footer: ({ loadingBtn, setLoadingBtn }) => {
				return (
					<div className="flex items-center gap-4 w-full justify-end">
						<ActionBtn
							size="md"
							type="primary-gradient"
							onClick={() => {
								confirmation_modal.current.hide();
							}}
						>
							Cancel
						</ActionBtn>
						<ActionBtn
							size="md"
							type="success"
							loading={loadingBtn}
							onClick={() => {
								setLoadingBtn(true);
								doneServePatient(patient_id).then((res) => {
									setTimeout(() => {
										setLoadingBtn(false);

										toast.success("Done serving patient!");
									}, 200);
									getQueue();
									confirmation_modal.current.hide();
								});
							}}
						>
							Okay
						</ActionBtn>
					</div>
				);
			},
		});
	};

	const confirmServePatient = (patient_id) => {
		confirmation_modal.current.show({
			title: "Serve patient",
			message: (
				<div className="py-6 px-2 text-xl  w-full flex flex-col items-center justify-center gap-4">
					<div className=" aspect-square p-3 rounded-full border border-success text-success text-3xl flex items-center justify-center">
						<FlatIcon
							icon="rr-list-check"
							className="text-[40px]"
						/>
					</div>
					<p>
						Patient will now be added to <b>NOW SERVING</b>.
					</p>
				</div>
			),
			footer: ({ loadingBtn, setLoadingBtn }) => {
				return (
					<div className="flex items-center gap-4 w-full justify-end">
						<ActionBtn
							size="md"
							type="primary-gradient"
							onClick={() => {
								confirmation_modal.current.hide();
							}}
						>
							Cancel
						</ActionBtn>
						<ActionBtn
							size="md"
							type="success"
							loading={loadingBtn}
							onClick={() => {
								setLoadingBtn(true);
								servePatient(patient_id).then((res) => {
									setTimeout(() => {
										setLoadingBtn(false);

										toast.success(
											"Patient is now added to now serving!"
										);
									}, 200);
									getQueue();
									confirmation_modal.current.hide();
								});
							}}
						>
							Okay
						</ActionBtn>
					</div>
				);
			},
		});
	};

	return (
		<Container
			breadcrumbs={{
				enable: true,
				current: "Patient Queue",
				items: [{ name: "Dashboard", path: "/sph/app" }],
			}}
			title={"Patient Queue"}
		>
			<div className="w-full pb-4">
				<DateTimeClock />
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-12 gap-6 w-full">
				<div className="col-span-7 border rounded-xl p-6 flex flex-col gap-y-4 bg-green-500 bg-opacity-5  !border-success">
					<h3 className="text-lg font-bold border-b pb-3 text-success">
						Patient Queue Today
					</h3>
					<ul className="flex flex-col gap-y-3 divide-y">
						{queue?.data?.map((data) => {
							return (
								<li
									className="flex items-center gap-4 pt-2"
									key={`queue-tdy-${data?.id}`}
								>
									<span
										className={`rounded-full w-11 h-11 flex items-center justify-center  text-lg font-bold ${
											data?.priority > 0
												? "bg-red-600 text-white font-bold"
												: "bg-slate-200"
										}`}
									>
										#{data?.number}
									</span>
									<span
										className={`text-2xl font-semibold  ${
											data?.priority > 0
												? " text-red-600 font-bold"
												: ""
										}`}
									>
										{patientFullName(data?.patient)}
									</span>
									{data?.priority > 0 && (
										<div className="py-1 px-3 bg-red-700 text-red-700 bg-opacity-5 rounded-2xl text-md font-bold">
											Priority
										</div>
									)}
									{data?.status == "pending" ? (
										<div className="py-1 px-3 border border-warning text-warning bg-opacity-15 rounded-2xl text-md font-bold">
											Pending
										</div>
									) : (
										<div className="py-1 px-3 bg-green-700 text-success bg-opacity-5 rounded-2xl text-md font-bold">
											Now Serving
										</div>
									)}
									{data?.status == "pending" ? (
										<Tippy
											content={
												<div className="bg-black py-1 px-3 rounded-xl text-white font-bold">
													Serve Patient now?
												</div>
											}
										>
											<div
												className="border border-warning rounded-full h-11 w-11 flex items-center justify-center cursor-pointer text-warning hover:bg-white
									  duration-200 ml-auto text-xl hover:shadow-lg"
												onClick={() => {
													confirmServePatient(
														data?.id
													);
												}}
											>
												<FlatIcon
													icon="rr-hand-holding-box"
													className="text-xl"
												/>
											</div>
										</Tippy>
									) : (
										<Tippy
											content={
												<div className="bg-black py-1 px-3 rounded-xl text-white font-bold">
													Mark patient as done
												</div>
											}
										>
											<div
												className="border border-success rounded-full h-11 w-11 flex items-center justify-center cursor-pointer text-success hover:bg-success
									 hover:!text-white duration-200 ml-auto text-xl hover:shadow-lg"
												onClick={() => {
													confirmDoneServePatient(
														data?.id
													);
												}}
											>
												<FlatIcon
													icon="rr-check"
													className="text-xl"
												/>
											</div>
										</Tippy>
									)}
								</li>
							);
						})}

						{/* <li className="flex items-center gap-4 pt-2">
							<span className="rounded-full w-11 h-11 flex items-center justify-center bg-slate-200 text-lg font-bold">
								#2
							</span>
							<span className="text-2xl font-semibold ">
								Patient Name here
							</span>
							<div className="py-1 px-4 bg-red-700 text-danger bg-opacity-5 rounded-2xl text-md font-bold">
								Priority
							</div>
						</li>
						<li className="flex items-center gap-4 pt-2">
							<span className="rounded-full w-11 h-11 flex items-center justify-center bg-slate-200 text-lg font-bold">
								#3
							</span>
							<span className="text-2xl font-semibold ">
								Patient Name here
							</span>
							<div className="py-1 px-4 bg-orange-700 text-warning bg-opacity-5 rounded-2xl text-md font-bold">
								Pending
							</div>
						</li> */}
					</ul>
				</div>
				<div className="col-span-5 border rounded-xl p-6 flex flex-col gap-y-4 bg-indigo-700 bg-opacity-5  !border-indigo-500">
					<h3 className="text-lg font-bold border-b pb-3 text-indigo-700">
						Appointments Today <br />
					</h3>
					<ul className="flex flex-col gap-y-3 divide-y">
						{appointments?.data?.map((appointment) => {
							return (
								<li
									className="gap-4 flex items-center pt-2"
									key={`apt-${appointment?.id}`}
								>
									<span className="text-lg text-blue-700 font-bold uppercase">
										{appointment?.time_formatted}
									</span>
									<span className="text-2xl">-</span>
									<span className="text-2xl items-center font-semibold text-indigo-700 mr-auto">
										{patientFullName(appointment?.patient)}
									</span>

									{appointment?.status == "pending" && (
										<ActionBtn
											type="primary"
											size="sm"
											onClick={() => {
												add_patient_to_queue_ref.current.show(
													appointment
												);
											}}
										>
											<FlatIcon icon="rr-layer-plus" />
											Add patient to Queue
										</ActionBtn>
									)}
								</li>
							);
						})}
					</ul>
				</div>
			</div>

			<AddPatientToQueueModal
				ref={add_patient_to_queue_ref}
				callBackProp={() => {
					getQueue();
					getAppointments();
				}}
			/>

			<ConfirmationModal ref={confirmation_modal} />
		</Container>
	);
};

export default DoctorClinicQueue;
