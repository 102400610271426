import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CheckSquare, XCircle } from "react-feather";
import { useParams } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const SentToOperation = ({ patient_id, setCategory }) => {
  const { users } = React.useContext(UsersData);
  const mounted = IsMountedRef();
  const [isProcess, setIsProcess] = useState(false);
  const [orders, setOrders] = useState("");
  const { tracenumber } = useParams();

  const [patientExist, setPatientExist] = useState(false);

  const handleSentForOperation = () => {
    var formdata = new FormData();
    formdata.append(`patient_id`, patient_id);
    formdata.append(`user_id`, users.user_id);
    formdata.append(`management_id`, users.management_id);
    formdata.append(`doctors_order`, orders);
    formdata.append(`trace_number`, tracenumber);

    setIsProcess(true);
    axios
      .post(`doctor/patient/for-operation`, formdata)
      .then(({ data }) => {
        if (data.message === "patient-exist") {
          Notify.customToast(
            "Patient exist in operation room",
            "Patient is currently in operation room."
          );
        }

        if (data.message === "success") {
          setCategory("appointment");
          Notify.successRequest("patient admit");
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsProcess(false));
  };

  const checkPatientIfExistInOperation = React.useCallback(async () => {
    let { data } = await axios.get(`doctor/patient/for-operation-check`, {
      params: {
        patient_id,
        trace_number: tracenumber,
      },
    });

    if (mounted.current) {
      if (data.message === "patient-exist") {
        setPatientExist(true);
      }
    }
  }, [mounted, tracenumber, patient_id]);

  useEffect(() => {
    checkPatientIfExistInOperation();
  }, [checkPatientIfExistInOperation]);

  return (
    <div>
      {patientExist ? (
        <Paper>
          <Box p={2}>
            <Typography align="center" color="secondary">
              Patient is currently in room operations.
            </Typography>
          </Box>
        </Paper>
      ) : (
        <Card>
          <CardContent>
            <Box>
              <TextField
                label={`Order`}
                fullWidth
                multiline
                variant="outlined"
                rows={6}
                value={orders}
                onChange={(e) => setOrders(e.target.value)}
              />
            </Box>

            <Box mt={3} display={"flex"}>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={
                    isProcess ? (
                      <CircularProgress size={25} color="inherit" />
                    ) : (
                      <CheckSquare />
                    )
                  }
                  disabled={isProcess}
                  onClick={() => handleSentForOperation()}
                >
                  Yes
                </Button>
              </Box>

              <Box ml={2}>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<XCircle />}
                  onClick={() => setCategory("appointment")}
                >
                  No
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default SentToOperation;
