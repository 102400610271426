import {
	faBox,
	faBoxes,
	faChartBar,
	faClock,
	faHome,
	faListAlt,
	faPenAlt,
	faPenFancy,
	faPenSquare,
	faPencilAlt,
	faTruckLoading,
	faUser,
	faUserInjured,
	faUserLock,
	faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ClinicDoctors from "src/clinic/ClinicDoctors";
import ClinicNurseDashboard from "src/clinic/ClinicNurseDashboard";
import ClinicNursePatients from "src/clinic/ClinicNursePatients";
import ClinicPatientProfile from "src/clinic/ClinicPatientProfile";
import ClinicPatientsReceiving from "src/clinic/ClinicPatientsReceiving";
import {
	ClinicContextWrapper,
	useClinicContext,
} from "src/clinic/context/ClinicContext";
import PatientQueuePage from "src/clinic/PatientQueuePage";
import Logout from "../auth/Logout";
import { UsersData, UsersHeader } from "../ContextAPI";
import Sidebar from "../layout/Sidebar";
import PageNotFound from "../PageNotFound";
import CisPatients from "src/cis/CisPatients";
import CisPatientProfile from "src/cis/CisPatientProfile";
import CisDashboard from "src/cis/CisDashboard";
import CisQueue from "src/cis/CisQueue";
import CisPatientsForm from "src/cis/components/profile/CisPatientsForm";
import CisEditPatientsForm from "src/cis/components/profile/CisEditPatientsForm ";
import CisPatientHistory from "src/cis/components/history/CisPatientHistory";
import CisCreatePatientsHistory from "src/cis/components/history/CisCreatePatienstsHistory";
import CisVisits from "src/cis/CisVisits";
import CisTBTreatmentModule from "src/cis/CisTBTreatmentModule";
import CisMyAccount from "src/cis/CisMyAccount";
import PhoHouseholdMemberFormPage from "src/pho/PhoHouseholdMemberPage";
import Pmrf from "src/pho/printable-forms/Pmrf";
import CisLMIS from "src/cis/CisLMIS";
import CisLMISView from "src/cis/CisLMISView";
import CisInventory from "src/cis/CisInventory";
import NurseDashboard from "src/nurse/NurseDashboard";

const RouteClinicInformationSystem = () => {
	const { clinic } = useClinicContext();
	const [sidebarHeader, setSidebarHeader] = useState([]);
	const userContext = useContext(UsersData);
	// console.log("userContextuserContext", userContext);
	const { users } = userContext;
	const getSidebarHeaderInfo = async () => {
		var params = { user_id: userContext.users.user_id };
		const { data } = await axios.get(
			"admitting/sidebar/header-infomartion",
			{
				params,
			}
		);
		setSidebarHeader(data);
	};

	const sidebarRoute = [
		{
			name: "Dashboard",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faHome}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/cis/dashboard",
			subitem: [],
		},

		// (clinic?.type == "hospital" || clinic?.type == "municipality") && {
		// 	name: "Anesthesia Program",
		// 	show: true,
		// 	icon: (
		// 		<FontAwesomeIcon
		// 			icon={faUsers}
		// 			color="white"
		// 			style={{ fontSize: 18 }}
		// 		/>
		// 	),
		// 	path: "/sph/app/cis/tb-treatment-program",
		// 	subitem: [],
		// },
		// (clinic?.type == "municipality" || clinic?.type == "barangay") && {
		// 	name: "Consignments",
		// 	show: true,
		// 	icon: (
		// 		<FontAwesomeIcon
		// 			icon={faTruckLoading}
		// 			color="white"
		// 			style={{ fontSize: 18 }}
		// 		/>
		// 	),
		// 	path: "/sph/app/cis/lmis",
		// 	subitem: [],
		// },

		// (clinic?.type == "municipality" || clinic?.type == "barangay") && {
		// 	name: "Items Inventory",
		// 	show: true,
		// 	icon: (
		// 		<FontAwesomeIcon
		// 			icon={faBoxes}
		// 			color="white"
		// 			style={{ fontSize: 18 }}
		// 		/>
		// 	),
		// 	path: "/sph/app/cis/inventory",
		// 	subitem: [],
		// },

		{
			name: "Patient List",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserInjured}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/cis/patients",
			subitem: [],
		},

		{
			name: "Patiet Queue",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faListAlt}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/cis/patient-queue",
			subitem: [],
		},

		{
			name: "My Account",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserLock}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/cis/user",
			subitem: [],
		},
	];

	const handleRenderInfo = () => {
		getSidebarHeaderInfo();
	};

	useEffect(() => {
		getSidebarHeaderInfo();
		//eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<UsersHeader.Provider
				value={{
					sidebarHeader: sidebarHeader,
					renderPharmacyInfo: handleRenderInfo,
				}}
			>
				<BrowserRouter>
					<Sidebar
						notification={{
							enable: true,
							owner: "pho",
						}}
						header={{
							...sidebarHeader,
							name: users?.name,
							avatar: {
								name: users?.name,
								src:
									users?.avatar ||
									`https://avatars.dicebear.com/api/initials/${users?.name}.svg`,
							},
						}}
						routes={sidebarRoute}
						module={users?.type.replace("-", " ")}
					/>
					<Switch>
						<Route
							exact
							path="/sph/app"
							component={() => (
								<Redirect to="/sph/app/cis/dashboard" />
							)}
						/>
						<Route
							exact
							path="/sph/app/cis/dashboard"
							component={NurseDashboard}
						/>
						<Route
							exact
							path="/sph/app/cis/patients"
							component={CisPatients}
						/>
						<Route
							exact
							path="/sph/app/cis/pmrf/:id"
							component={Pmrf}
						/>
						<Route
							exact
							path="/sph/app/cis/tb-treatment-program"
							component={CisTBTreatmentModule}
						/>
						<Route
							exact
							path="/sph/app/cis/lmis"
							component={CisLMIS}
						/>
						<Route
							exact
							path="/sph/app/cis/lmis/view/:id"
							component={CisLMISView}
						/>
						<Route
							exact
							path="/sph/app/cis/inventory"
							component={CisInventory}
						/>
						<Route
							exact
							path="/sph/app/cis/items"
							component={CisTBTreatmentModule}
						/>
						<Route
							exact
							path="/sph/app/cis/patients/:id"
							component={CisPatientProfile}
						/>

						<Route
							exact
							path="/sph/app/cis/create-patient"
							component={PhoHouseholdMemberFormPage}
						/>
						<Route
							exact
							path="/sph/app/cis/edit-patient/:id"
							component={PhoHouseholdMemberFormPage}
						/>
						<Route
							exact
							path="/sph/app/cis/clinic-queue"
							component={CisQueue}
						/>
						<Route
							exact
							path="/sph/app/cis/patient-queue"
							component={CisVisits}
						/>
						<Route
							exact
							path="/sph/app/cis/user"
							component={CisMyAccount}
						/>

						<Route
							exact
							path="/sph/app/logout"
							component={Logout}
						/>

						<Route
							render={() => (
								<PageNotFound title="Page not found" />
							)}
						/>
					</Switch>
				</BrowserRouter>
			</UsersHeader.Provider>
		</Fragment>
	);
};

export default RouteClinicInformationSystem;
