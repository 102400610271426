import Grid from "@material-ui/core/Grid";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import New from "./New";
import List from "./List";

const Items = () => {
  const { users } = useContext(UsersData);
  const [list, setList] = useState({ data: [], ready: false });

  const getItemLab = React.useCallback(async () => {
    let response = await axios.get("accounting/test/items/get-itemlist", {
      params: {
        management_id: users.management_id,
        user_id: users.user_id,
      },
    });

    setList({ data: response.data, ready: true });
  }, [users]);

  useEffect(() => {
    getItemLab();
  }, [getItemLab]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <List list={list} rerender={() => getItemLab()} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <New rerender={() => getItemLab()} />
      </Grid>
    </Grid>
  );
};

export default Items;
