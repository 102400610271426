import { Popover, Menu, Transition } from "@headlessui/react";
import { TextField } from "@material-ui/core";
import axios from "axios";
import {
	forwardRef,
	Fragment,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useForm } from "react-hook-form";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import ActionBtn from "src/components/ActionBtn";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";

const CreateAppointmentFormModal = (props, ref) => {
	const { queue_number_modal, getQueue } = props;
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [send_to, setSendTo] = useState("");
	const btnRef = useRef(null);
	const { clinic } = useClinicContext();

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm();
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (patient) => {
		console.log("showshowshow", patient);
		setData(patient);
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = async (formData) => {
		setLoading(true);
		console.log("formData", formData);
		await axios
			.post("/v1/clinic/appointments", {
				date: formData?.date,
				time: formData?.time,
				purpose: formData?.purpose,
				doctor_id: formData?.doctor_id,
				room_number: formData?.room_number,
				patient_id: data?.id,
				clinic_id: clinic?.id,
				type_service: formData?.type_service,
			})
			.then((res) => {
				setTimeout(() => {
					toast.success("Success! Patient appointment added.");
				}, 100);
			})
			.finally(() => {
				setLoading(false);
				hide();
			});
	};

	return (
		<Modal
			open={open}
			className={`w-[512px] min-w-[512px] max-w-[512px]`}
			staticModal={true}
		>
			<ModalHeader title="Create appointment form x" hide={hide} />
			<ModalBody className={`p- bg-slate-50 !pb-6`}>
				<div className="px-4 pt-4 pb-0 flex flex-col relative gap-y-6">
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
						<TextField
							label={
								<>
									Date
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("date", {
								required: true,
							})}
							InputLabelProps={{ shrink: true }}
							error={errors?.date}
							helperText={
								errors?.date && "This field is required"
							}
							type="date"
						/>
						<TextField
							label={
								<>
									Time
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("time", {
								required: true,
							})}
							InputLabelProps={{ shrink: true }}
							error={errors?.time}
							helperText={
								errors?.time && "This field is required"
							}
							value={data?.time}
							type="time"
						/>
					</div>
					<TextField
						label={
							<>
								Purpose
								<b className="text-red-500 ml-1">*</b>
							</>
						}
						variant="outlined"
						{...register("purpose", {
							required: true,
						})}
						InputLabelProps={{ shrink: true }}
						error={errors?.purpose}
						helperText={errors?.purpose && "This field is required"}
					/>
					<TextField
						label={
							<>
								Select Doctor
								<b className="text-red-500 ml-1">*</b>
							</>
						}
						variant="outlined"
						{...register("doctor_id", {
							required: true,
						})}
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						error={errors?.doctor_id}
						helperText={
							errors?.doctor_id && "This field is required"
						}
						select
					>
						<option value="" disabled selected>
							Select doctor
						</option>
						{clinic?.doctors?.map((doctor) => {
							return (
								<option value={doctor?.id}>
									{doctor?.name}
								</option>
							);
						})}
					</TextField>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
						<TextField
							label={
								<>
									Room Number
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("room_number", {
								required: true,
							})}
							InputLabelProps={{ shrink: true }}
							error={errors?.room_number}
							helperText={
								errors?.room_number && "This field is required"
							}
						/>
						<TextField
							label={
								<>
									Type of service
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("type_service", {
								required: true,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.type_service}
							helperText={
								errors?.type_service && "This field is required"
							}
							select
						>
							<option>Physical</option>
							<option>Medical Certificate</option>
							<option>Consultation</option>
						</TextField>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<ActionBtn onClick={handleSubmit(submit)} loading={loading}>
					Save appointment
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(CreateAppointmentFormModal);
