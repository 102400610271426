import { forwardRef, useImperativeHandle, useState } from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { useForm } from "react-hook-form";
import usePatientHook from "src/admission/hooks/usePatientHook";
import StaticModal from "src/components/modal/StaticModal";
import FlatIcon from "src/components/FlatIcon";
import IdentificationForm from "./Forms/IdentificationForm";
import HousingForm from "./Forms/HousingForm";
import HouseholdCharacteristicsForm from "./Forms/HouseholdCharacteristicsForm";
import CalamityForm from "./Forms/CalamityForm";
import WasteManagementForm from "./Forms/WasteManagementForm";
import HouseForm from "./Forms/HouseForm";
import IncomeForm from "./Forms/IncomeForm";
import WaterAndSanitation from "./Forms/WaterAndSanitation";
import ImagePicker from "src/components/forms/ImagePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Img from "src/components/Img";
import {
	months,
	formatDate,
	calculateAge,
	localArraySearch,
	dataURItoBlob,
	isBase64,
} from "src/helpers/utils";
import axios from "axios";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import PhoPatientForm from "./PhoPatientForm";
import { TextField } from "@material-ui/core";

const TheForm = ({ register, errors, patient }) => {
	return (
		<div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
			<TextField
				label={
					<>
						Lastname
						<b className="text-red-500 ml-1">*</b>
					</>
				}
				variant="outlined"
				{...register("lastname", {
					required: true,
				})}
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				error={errors?.lastname}
				helperText={errors?.lastname && "This field is required"}
				className="col-span-4"
			/>
			<TextField
				label={
					<>
						Firstname
						<b className="text-red-500 ml-1">*</b>
					</>
				}
				variant="outlined"
				{...register("firstname", {
					required: true,
				})}
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				error={errors?.firstname}
				helperText={errors?.firstname && "This field is required"}
				className="col-span-4"
			/>
			<TextField
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={<>Middlename</>}
				variant="outlined"
				{...register("middlename")}
				className="col-span-4"
			/>

			<TextField
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={<>Birthday</>}
				variant="outlined"
				{...register("birthdate")}
				className="col-span-4"
			/>
			<TextField
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={<>Civil Status</>}
				variant="outlined"
				{...register("civil_status")}
				className="col-span-4"
			/>
			<TextField
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={<>Gender</>}
				variant="outlined"
				{...register("gender")}
				className="col-span-4"
			/>
			<TextField
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={<>City</>}
				variant="outlined"
				{...register("city")}
				className="col-span-6"
			/>
			<TextField
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={<>Municipality</>}
				variant="outlined"
				{...register("municipality")}
				className="col-span-6"
			/>
			<TextField
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={<>Zone</>}
				variant="outlined"
				{...register("zone")}
				className="col-span-6"
			/>
			<TextField
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={<>Street</>}
				variant="outlined"
				{...register("street")}
				className="col-span-6"
			/>
			<TextField
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={<>Philhealth</>}
				variant="outlined"
				{...register("philhealth")}
				className="col-span-8"
			/>
		</div>
	);
};
const CollapseDiv = ({ title, children }) => {
	const [open, setOpen] = useState(true);
	return (
		<div
			className={`flex flex-col border rounded-xl w-full overflow-hidden duration-200 group ${
				open ? "max-h-[9999px]" : "max-h-[60px]"
			}`}
		>
			<div
				className="flex items-center bg-slate-100 cursor-pointer p-3"
				onClick={() => {
					setOpen((open) => !open);
				}}
			>
				<h3 className="mb-0 text-base font-bold text-primary capitalize">
					{title}
				</h3>
				<div
					className={`rounded-full aspect-square duration-200 bg-opacity-60 group-hover:bg-opacity-100 text-secondary ml-auto flex items-center justify-center ${
						open ? "rotate-180" : "rotate-0"
					}`}
				>
					<FlatIcon icon="rr-caret-down" className="text-2xl" />
				</div>
			</div>
			<div
				className={`bg-white p-4 duration-200  ${
					open ? "max-h-[9999px]" : "max-h-[0px] h-0 overflow-hidden"
				}`}
			>
				{children}
			</div>
		</div>
	);
};

const PhoFormModal = (props, ref) => {
	const [open, setOpen] = useState(false);
	const [patient, setPatient] = useState(null);

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const getPatientInfo = (id) => {
		return axios.get(`v1/patients/${id}`);
	};
	const show = (data) => {
		reset();
		setPatient(data);
		getPatientInfo(data?.id).then((res) => {
			setPatient(res.data.data);
			let p = res.data.data;
			setTimeout(() => {
				reset({
					...p,
					birthdate: p.birthday,
					middlename: p.middle,
				});
			}, 500);
		});
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = (data) => {
		console.log("submit data", data);
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
			// onUploadProgress: progressEvent => onProgress(progressEvent),
		};
		const form = new FormData();
		form.append("_method", "PATCH");
		Object.keys(data).map((key) => {
			if (typeof data[key] != "object" && key != undefined) {
				form.append(key, data[key]);
			}
		});
		axios
			.post(`v1/pho/patients/${patient?.id}`, form, config)
			.then((res) => {
				hide();
			});
	};

	const saveAvatar = (avatar) => {
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
			// onUploadProgress: progressEvent => onProgress(progressEvent),
		};
		console.log("avataravatar", avatar);
		const form = new FormData();
		const file = dataURItoBlob(avatar);
		setValue("avatar", file);
		form.append("_method", "PATCH");
		form.append("avatar", file);

		axios.post(`v1/patient-profile/${patient?.id}`, form, config);
	};
	return (
		<StaticModal staticModal={true} open={open} hide={hide} size="lg">
			<ModalHeader
				title={"Edit Patient"}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4 bg-white flex flex-col gap-y-5`}>
				<div className="flex flex-col lg:flex-row gap-4 items-center px-0 pt- justify-center md:justify-start pb-4">
					<div className="group relative h-36 w-36 min-h-[144px] min-w-[144px] rounded-full aspect-square bg-background">
						<Img
							type="user"
							name={`${patient?.lastname}-${patient?.firstname}-${patient?.middle}`}
							src={patient?.avatar || ""}
							className="min-h-[144px] min-w-[144px] aspect-square object-cover rounded-full"
							alt=""
							key={`key-${patient?.id}-${patient?.avatar}`}
						/>

						<ImagePicker
							className="absolute -bottom-0 -right-0 w-11 h-11 bg-primary bg-opacity-40 group-hover:bg-primary group-hover:bg-opacity-100 duration-200 rounded-full flex items-center justify-center"
							onChange={(data) => {
								setPatient((prevData) => ({
									...prevData,
									avatar: data,
								}));
								if (isBase64(data)) {
									saveAvatar(data);
								}
							}}
						>
							{() => {
								return (
									<FontAwesomeIcon
										icon={faCamera}
										color="white"
										size={"2x"}
									/>
								);
							}}
						</ImagePicker>
					</div>
					<div className="flex flex-col pl-4">
						<h6
							className={`text-left text-2xl mb-1 font-semibold flex items-center ${
								patient?.gender == "Male"
									? "text-blue-800"
									: "text-pink-800"
							} mb-0`}
						>
							{`${patient?.lastname}, ${patient?.firstname} ${patient?.middle}`}
						</h6>
						<div className="flex gap-6 mb-2">
							<div className="flex items-center gap-2 text-base">
								<FlatIcon
									icon="rr-calendar-clock"
									className="text-lg"
								/>
								<span>
									{calculateAge(patient?.birthday)} yrs. old
								</span>
							</div>
							<div className="flex items-center gap-2 text-base">
								<FlatIcon
									icon="rr-calendar"
									className="text-lg"
								/>
								<span>{formatDate(patient?.birthday)}</span>
							</div>
						</div>
						<div className="flex gap-4 mb-2">
							<div className="flex items-center gap-2 text-base">
								<FlatIcon
									icon="rr-venus-mars"
									className="text-lg"
								/>
								{patient?.gender == "Male" ? (
									<span className="text-blue-700">Male</span>
								) : (
									<span className="text-pink-700">
										Female
									</span>
								)}
							</div>
						</div>
					</div>
				</div>
				<TheForm
					register={register}
					errors={errors}
					patient={patient}
					key={`patient-${patient?.id}`}
				/>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button onClick={handleSubmit(submit)}>Submit</Button>
			</ModalFooter>
		</StaticModal>
	);
};

export default forwardRef(PhoFormModal);
