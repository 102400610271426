import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import { styles } from "../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListUl } from "@fortawesome/free-solid-svg-icons";
import Popup from "./PopUp";
import { SettingsEthernet } from "@material-ui/icons";
import Cards from "./Cards";

export default function CisPatientBmi() {
  const [bmi, setBmi] = useState(0);
  const [heightMeasure, setHeightMeasure] = useState("");
  const [weightMeasure, setWeightMeasure] = useState("");
  const [errorHeight, setErrorHeight] = useState(false);
  const [errorWeight, setErrorWeight] = useState(false);
  const [resultAverage, setResultAverage] = useState("");
  const [textColor, setTextColor] = useState("");
  const [weight, setWeight] = useState("kilograms");
  const [height, setHeight] = useState("feet");
  const [result, setResult] = useState("ASSESSMENT");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [img, setImg] = useState("/cards/hello.png");
  const [bg, setBg] = useState(" bg-white");
  const [assessment, setAssessment] = useState("Welcome! ");

  const handleChangeWeight = (event) => {
    //This is to validate the value of weight
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-digit and non-decimal point characters

    // Ensure the input does not start with a decimal point
    if (sanitizedValue.startsWith(".")) {
      setWeightMeasure(sanitizedValue.slice(1));
    } else {
      setWeightMeasure(sanitizedValue);
    }

    // Check if the input is a valid number
    setErrorWeight(isNaN(parseFloat(sanitizedValue)));
  };

  const handleChangeHeight = (event) => {
    //This is to validate the value of height
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-digit and non-decimal point characters

    // Ensure the input does not start with a decimal point
    if (sanitizedValue.startsWith(".")) {
      setHeightMeasure(sanitizedValue.slice(1));
    } else {
      setHeightMeasure(sanitizedValue);
    }

    // Check if the input is a valid number
    setErrorHeight(isNaN(parseFloat(sanitizedValue)));
  };

  const handleHeightChange = (event) => {
    setHeight(event.target.value);
  };
  const handleWeightChange = (event) => {
    setWeight(event.target.value);
  };
  const handleResultChange = (event) => {
    setResult(event.target.value);
  };

  const handleCalculateBMI = (e) => {
    // This is for Calculation of BMI
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    const h = parseFloat(formdata.get("height"));
    const w = parseFloat(formdata.get("weight"));
    if (weight === "kilograms" && height === "feet") {
      const meters = h * 0.304;
      const meterSqured = meters * meters;
      const Average = w / meterSqured;
      setBmi(Average.toFixed(2));
    } else if (weight === "kilograms" && height === "inches") {
      const meters = h * 0.0254;
      const meterSqured = meters * meters;
      const Average = w / meterSqured;
      setBmi(Average.toFixed(2));
    } else if (weight === "kilograms" && height === "centimeter") {
      const meters = h * 0.01;
      const meterSqured = meters * meters;
      const Average = w / meterSqured;
      setBmi(Average.toFixed(2));
    } else if (weight === "pounds" && height === "feet") {
      const meters = h * 0.304;
      const meterSqured = meters * meters;
      const kg = w * 0.453592;
      const Average = kg / meterSqured;
      setBmi(Average.toFixed(2));
    } else if (weight === "pounds" && height === "inches") {
      const meters = h * 0.0254;
      const meterSqured = meters * meters;
      const kg = w * 0.453592;
      const Average = kg / meterSqured;
      setBmi(Average.toFixed(2));
    } else if (weight === "pounds" && height === "centimeter") {
      const meters = h * 0.01;
      const meterSqured = meters * meters;
      const kg = w * 0.453592;
      const Average = kg / meterSqured;
      setBmi(Average.toFixed(2));
    }
  };

  useEffect(() => {
    if (bmi >= 0 && bmi <= 100) {
      setResultAverage(bmi);
      if (bmi > 0 && bmi < 18.5) {
        // Underweight
        setBg("bg-yellow-500");
        setImg("/cards/underweight.png");
        setAssessment("Underweight");
        setTextColor("text-white");
      } else if (bmi >= 18.5 && bmi <= 25) {
        // Healthy
        setBg("bg-green-500");
        setImg("/cards/healthy.png");
        setAssessment("Healthy");
        setTextColor("text-white");
      } else if (bmi > 25 && bmi <= 30) {
        // Overweight
        setBg("bg-pink-500");
        setImg("/cards/body.png");
        setAssessment("Overweight");
        setTextColor("text-white");
      } else if (bmi > 30 && bmi <= 40) {
        // Obese
        setBg("bg-violet-500");
        setImg("/cards/obesity.png");
        setAssessment("Obese");
        setTextColor("text-white");
      } else if (bmi > 40) {
        // Morbidly Obese
        setBg("bg-green-500");
        setImg("/cards/healthy.png");
        setAssessment("Morbidly Obese");
        setTextColor("text-white");
      }
    } else {
      // Error
      setAssessment("Error!");
      setResultAverage("Error!");
      setBg("bg-red-500");
      setImg("/cards/warning.png");
    }
  }, [bmi]);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };
  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  return (
    <>
      <div className="flex w-full flex-col pr-2">
        <div className="flex w-full mb-2 ">
          <div style={styles.headerContainer}>
            <b className="text-2xl">BMI Calculator</b>
          </div>
        </div>
        <div className="flex w-full flex-col justify-center">
          <form onSubmit={handleCalculateBMI}>
            <div className="mx-auto" style={styles.parentContainerAdd}>
              <Card elevation={3} className="p-4" component={Box}>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <TextField
                      select
                      value={result}
                      onChange={handleResultChange}
                    >
                      <MenuItem value="AVERAGE">Average</MenuItem>
                      <MenuItem value="ASSESSMENT">Assessment</MenuItem>
                    </TextField>
                  </div>

                  <div className="flex items-center">
                    <button type="button" onClick={handleOpenPopup}>
                      <FontAwesomeIcon
                        icon={faListUl}
                        style={{ fontSize: 18 }}
                        className="pr-2"
                      />
                    </button>
                    <Popup open={isPopupOpen} onClose={handleClosePopup} />
                  </div>
                </div>
                <div>
                  <Box align="center">
                    {result === "ASSESSMENT" ? (
                      <>
                        {bmi === 0 ? (
                          <Cards
                            textColor={textColor}
                            bg={bg}
                            img={img}
                            assessment={assessment}
                          />
                        ) : (
                          <Cards
                            textColor={textColor}
                            bg={bg}
                            img={img}
                            assessment={assessment}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {bmi === 0 ? (
                          <Cards
                            textColor={textColor}
                            bg={bg}
                            img={img}
                            assessment={assessment}
                          />
                        ) : (
                          <div className="flex">
                            <Card
                              elevation={3}
                              className=" my-4 mx-auto "
                              component={Box}
                            >
                              <div
                                className={
                                  resultAverage === "Error!"
                                    ? `flex ${bg} ${textColor} p-4 items-center`
                                    : `flex p-4 items-center`
                                }
                              >
                                {resultAverage === "Error!" && (
                                  <img
                                    src={img}
                                    className="w-14 h-14 mr-4  object-contain"
                                  />
                                )}
                                <Typography variant="h3">
                                  <b>{resultAverage}</b>
                                </Typography>
                                {resultAverage !== "Error!" && (
                                  <div className="flex items-end h-14 ">
                                    ave.
                                  </div>
                                )}
                              </div>
                            </Card>
                          </div>
                        )}
                      </>
                    )}
                    <Typography variant="subtitle1" color="primary">
                      <b>{` BODY MASS INDEX ${result}`}</b>
                    </Typography>
                  </Box>

                  <div style={styles.divContainer}>
                    <TextField
                      label={`Weight "(${weight})"`}
                      fullWidth
                      margin="normal"
                      name="weight"
                      variant="outlined"
                      value={weightMeasure}
                      onChange={handleChangeWeight}
                      error={errorWeight}
                      helperText={
                        errorWeight ? "Please enter a valid number" : ""
                      }
                      inputProps={{
                        pattern: "^[0-9]*[.]?[0-9]*$",
                      }}
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div className="flex items-center">
                              <TextField
                                select
                                value={weight}
                                onChange={handleWeightChange}
                              >
                                <MenuItem value="kilograms">kilograms</MenuItem>
                                <MenuItem value="pounds">Pounds</MenuItem>
                              </TextField>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div style={styles.divContainer}>
                    <TextField
                      label={`Height "(${height})"`}
                      fullWidth
                      margin="normal"
                      name="height"
                      variant="outlined"
                      value={heightMeasure}
                      onChange={handleChangeHeight}
                      error={errorHeight}
                      helperText={
                        errorHeight ? "Please enter a valid number" : ""
                      }
                      inputProps={{
                        pattern: "^[0-9]*[.]?[0-9]*$",
                      }}
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div className="flex items-center">
                              <TextField
                                select
                                value={height}
                                onChange={handleHeightChange}
                              >
                                <MenuItem value="feet">Feet</MenuItem>
                                <MenuItem value="inches">Inches</MenuItem>
                                <MenuItem value="centimeter">
                                  Centimeter
                                </MenuItem>
                              </TextField>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                    >
                      Calculate
                    </Button>
                  </Box>
                </div>
              </Card>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
