import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Collapse,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { ArrowRight, ArrowDown } from "react-feather";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import Notify from "src/notification/Notify";

const DrForApproval = () => {
  const [products, setProducts] = useState({ data: [], ready: false });
  const [selectedProduct, setSelectedProduct] = useState("");
  const mounted = IsMountedRef();
  const { users } = useContext(UsersData);
  const [approved, setApproved] = useState({ data: "", dialog: false });

  const [invoiceDetails, setInvoiceDetails] = useState({
    data: [],
    ready: true,
  });

  const getDrForApproval = React.useCallback(async () => {
    let response = await axios.get("accounting/dr/for-approval", {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setProducts({ data: response.data, ready: true });
    }
  }, [users, mounted]);

  const handleSelectedDr = async (xinfo) => {
    let response = await axios.get("accounting/dr/for-approval-details", {
      params: {
        management_id: users.management_id,
        dr_number: xinfo.dr_number,
      },
    });
    setInvoiceDetails({ data: response.data, ready: true });
    setSelectedProduct(xinfo);
  };

  const handleDRApproved = async () => {
    var formdata = new FormData();
    formdata.set("management_id", users.management_id);
    formdata.set("dr_number", approved.data.dr_number);

    let response = await axios.post(
      "accounting/dr/approved-byaccounting",
      formdata
    );

    if (response.data.message === "success") {
      Notify.successRequest("Delivery Approved");
      getDrForApproval();
      setApproved({ data: "", dialog: false });
    }
  };

  useEffect(() => {
    getDrForApproval();
  }, [getDrForApproval]);

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h6" color="textSecondary">
          Delivery for approval
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell> Date </TableCell>
                <TableCell> DR Number </TableCell>
                <TableCell> Delivered to </TableCell>
                <TableCell> Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {products.ready ? (
                products.data.length > 0 ? (
                  products.data.map((data, index) => (
                    <Fragment key={index}>
                      <TableRow>
                        <TableCell>
                          <IconButton
                            disabled={
                              selectedProduct.dr_number === data.dr_number
                            }
                            color="primary"
                            onClick={() => handleSelectedDr(data)}
                          >
                            {selectedProduct.dr_number === data.dr_number ? (
                              <ArrowDown />
                            ) : (
                              <ArrowRight />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {Notify.dateTimeConvert(data.created_at)}
                        </TableCell>
                        <TableCell> {data.dr_number} </TableCell>
                        <TableCell> {data.dr_accountname} </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              setApproved({ data: data, dialog: true })
                            }
                          >
                            Approve dr
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={5} style={{ border: 0 }}>
                          <Collapse
                            in={selectedProduct.dr_number === data.dr_number}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box margin={1}>
                              <Typography variant="subtitle1" gutterBottom>
                                <strong> Invoice Products </strong>
                              </Typography>

                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell> Product </TableCell>
                                    <TableCell> Generic </TableCell>
                                    <TableCell> Batch </TableCell>
                                    <TableCell> Mfg. Date </TableCell>
                                    <TableCell> Exp. Date </TableCell>
                                    <TableCell> Qty </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {invoiceDetails.ready ? (
                                    invoiceDetails.data.length > 0 ? (
                                      invoiceDetails.data.map((data, index) => (
                                        <TableRow key={index}>
                                          <TableCell>
                                            {data.product_name}
                                          </TableCell>
                                          <TableCell>
                                            {data.product_generic}
                                          </TableCell>
                                          <TableCell>
                                            {data.batch_number}
                                          </TableCell>
                                          <TableCell>
                                            {data.manufactured_date}
                                          </TableCell>
                                          <TableCell>
                                            {data.expiration_date}
                                          </TableCell>
                                          <TableCell align="right">
                                            {data.qty}
                                          </TableCell>
                                        </TableRow>
                                      ))
                                    ) : (
                                      <TableRow>
                                        <TableCell colSpan={6}>
                                          <Typography color="secondary">
                                            No product added.
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={6}>
                                        <Typography color="primary">
                                          loading, please wait...
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography color="secondary">
                        No product added.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography color="primary">
                      loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* approved dr dialog */}
      <Dialog
        open={approved.dialog}
        onClose={() => setApproved({ data: null, dialog: false })}
      >
        <DialogContent>Are you sure to approve this delivery?</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setApproved({ data: null, dialog: false })}
          >
            No
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDRApproved()}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default DrForApproval;
