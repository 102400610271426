import { faCalendarDay, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
//import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
import { Typography } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import LayoutContainer from "src/components/LayoutContainer";
import { UsersData } from "src/ContextAPI";
import CisPatientAppointmentListItem from "./components/CisPatientAppointmentListItem";
import ActionBtn from "src/components/ActionBtn";
import CisCreateAppointmentFormModal from "./components/CisCreateAppointmentFormModal";
import { dateYYYYMMDD, getDateCurrentTimeHHII } from "src/helpers/utils";
import MagicInputFields from "src/components/forms/MagicInputFields";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import ClinicInfo from "src/cis/components/ClinicInfo";

const events = [
	{
		title: "Mona Lisa",
		start: getDate("YEAR-MONTH-14"),
		onClick: () => {
			alert(1);
		},
	},
	{ title: "John Doe", start: getDate("YEAR-MONTH-14") },
	{ title: "Carla Polares", start: getDate("YEAR-MONTH-14") },
	{ title: "Maria Clara", start: getDate("YEAR-MONTH-14") },
];

function getDate(dayString) {
	const today = new Date();
	const year = today.getFullYear().toString();
	let month = (today.getMonth() + 1).toString();

	if (month.length === 1) {
		month = "0" + month;
	}

	return dayString.replace("YEAR", year).replace("MONTH", month);
}

const CisDoctorDashboard = () => {
	const userContext = useContext(UsersData);
	const { users } = userContext;
	const name = users?.name;
	const [view, setView] = useState("dayGridMonth");
	const appointment_ref = useRef(null);

	const { clinic, fetchRegistering, registeringPatients } =
		useClinicContext();

	useEffect(() => {
		if (clinic.id) {
			fetchRegistering();
		}
	}, [clinic.id]);

	return (
		<LayoutContainer
			icon={<FontAwesomeIcon icon={faHome} color="white" size={"2x"} />}
			title={"Dashboard"}
			subtitle={"Your clinic dashboard schedule"}
			titleClassName="text-base"
			className={`relative`}
			titleChildren={
				<>
					<ClinicInfo />
				</>
			}
		>
			<div className="w-full grid grid-cols-1 lg:grid-cols-12 gap-4">
				<div className="lg:col-span-4 2xl:col-span-3">
					<h4 className="font-bold text-lg text-primary-dark">
						Clinic Queue
					</h4>

					<div className="flex flex-col gap-y-3">
						{registeringPatients?.map((patient) => {
							return (
								<CisPatientAppointmentListItem
									key={`c-p-a-l-i-${patient?.id}`}
									patient={{
										...patient?.patient,
										firstname: patient?.patient?.firstname,
										lastname: patient?.patient?.lastname,
										middle: patient?.patient?.middle,
										middlename: patient?.patient?.middle,
										birthday: patient?.patient?.birthday,
										gender: patient?.patient?.gender,
										reason: patient?.purpose,
									}}
								/>
							);
						})}
						{registeringPatients?.length == 0 ? (
							<span className="text-placeholder text-lg text-center w-full">
								No patients to display.
							</span>
						) : (
							""
						)}
					</div>
				</div>
				<div className="lg:col-span-8 2xl:col-span-9 flex flex-col w-full gap-3">
					<div className="flex gap-3 items-center">
						<span className="text-xs font-semibold">
							Switch Dashboard Views
						</span>
						<span
							className={`text-xs py-1 px-3 rounded-xl hover:bg-primary cursor-pointer duration-200 ${
								view == "dayGridMonth"
									? "bg-primary text-white"
									: "bg-slate-400 text-white"
							}`}
							onClick={(e) => {
								setView("dayGridMonth");
							}}
						>
							Month View
						</span>
						<span
							className={`text-xs py-1 px-3 rounded-xl hover:bg-primary cursor-pointer duration-200 ${
								view == "timeGridWeek"
									? "bg-primary text-white"
									: "bg-slate-400 text-white"
							}`}
							onClick={(e) => {
								setView("timeGridWeek");
							}}
						>
							Weekly View
						</span>
						<span
							className={`text-xs py-1 px-3 rounded-xl hover:bg-primary cursor-pointer duration-200 ${
								view == "timeGridDay"
									? "bg-primary text-white"
									: "bg-slate-400 text-white"
							}`}
							onClick={(e) => {
								setView("timeGridDay");
							}}
						>
							Day View
						</span>
					</div>
					<div className="bg-white rounded-xl p-4">
						{view == "dayGridMonth" ? (
							<FullCalendar
								key={`view-dayGridMonth`}
								type={"dayGridMonth"}
								defaultView={"dayGridMonth"}
								initialView={"dayGridMonth"}
								header={{
									left: "prev,next",
									center: "title",
									right: "dayGridMonth,timeGridWeek,timeGridDay",
								}}
								themeSystem="Simplex"
								plugins={[
									dayGridPlugin,
									timeGridPlugin,
									interactionPlugin,
								]}
								events={events}
								dateClick={(data) => {
									console.log("dateClick", data);
									let today = new Date();
									let date = new Date(data?.dateStr);

									if (today <= date) {
										appointment_ref.current.show({
											date: data?.dateStr,
											time: getDateCurrentTimeHHII(),
										});
									} else {
										alert(
											`You cannot add appointment on previous dates.`
										);
									}
								}}
								eventClick={(data) => {
									console.log(
										"dateClick eventClick",
										data.event.title
									);
									appointment_ref.current.show();
								}}
							/>
						) : (
							""
						)}
						{view == "timeGridWeek" ? (
							<FullCalendar
								key={`view-timeGridWeek`}
								type={"timeGridWeek"}
								initialView={"timeGridWeek"}
								defaultView={"timeGridWeek"}
								header={{
									left: "prev,next",
									center: "title",
									right: "dayGridMonth,timeGridWeek,timeGridDay",
								}}
								themeSystem="Simplex"
								plugins={[dayGridPlugin, timeGridPlugin]}
								events={events}
							/>
						) : (
							""
						)}
						{view == "timeGridDay" ? (
							<FullCalendar
								key={`view-timeGridDay`}
								type={"timeGridDay"}
								defaultView={"timeGridDay"}
								initialView={"timeGridDay"}
								header={{
									left: "prev,next",
									center: "title",
									right: "dayGridMonth,timeGridWeek,timeGridDay",
								}}
								themeSystem="Simplex"
								plugins={[dayGridPlugin, timeGridPlugin]}
								events={events}
							/>
						) : (
							""
						)}
					</div>
				</div>
			</div>
			<CisCreateAppointmentFormModal ref={appointment_ref} />
		</LayoutContainer>
	);
};

export default CisDoctorDashboard;
