import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
} from "@material-ui/core";
import React from "react";
import Header from "./Header";
//   import ChemistryRef from "../references/Ref_Chemistry";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const PapsmearOrder = ({ formheader, papsmearOrderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        {/* <CardContent>
          <Header details={papsmearOrderDetails} />
        </CardContent> */}

        <CardContent>
          <Box>
            <Header details={papsmearOrderDetails} />
          </Box>
          <Box borderBottom={1} borderColor={"#AEAEAE"}>
            <Box flexGrow={1}>
              <Typography
                variant="h6"
                align="center"
                style={{ fontWeight: "bolder", color: "#000" }}
              >
                <b> CERVICAL VAGINAL CYTOLOGY REPORT </b>
              </Typography>
              <Typography
                variant="h6"
                align="center"
                style={{ fontWeight: "bolder", color: "#000" }}
              >
                (THE BETHESDA SYSTEM)
              </Typography>
            </Box>
          </Box>

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      SPECIMEN
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      FIXED SLIDES FROM:
                    </Typography>
                  </Box>
                  <Box>
                    <Box align="left">
                      {parseFloat(papsmearOrderDetails.data[0].cervix) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        CERVIX
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(papsmearOrderDetails.data[0].vagina) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        VAGINA
                      </span>
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bolder",
                          color: "#000",
                        }}
                      >
                        Adequacy of the specimen
                      </Typography>
                    </Box>

                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].satisfactory_evaluation
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Satisfactory for evaluation
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].satisfactory_no_lmp
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Satisfactory for evaluation but limited by no LMP
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].unsatisfactory
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Unsatisfactory for evaluation due to
                      </span>
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bolder",
                          color: "#000",
                        }}
                      >
                        General Categorization
                      </Typography>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].with_normal_limits
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Within Normal Limits
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].benign_cell_changes
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Benign Cellular Changes
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].epithelial_cell_abno
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Epithelial Cell Abnormalities
                      </span>
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        Benign Cellular Changes
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bolder",
                          color: "#000",
                        }}
                      >
                        Infection
                      </Typography>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].infection_trichomonas
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Trichomonas vaginalis
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].infection_fungi
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Fungi consistent with Candida spp.
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].infection_predominance
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Predominance of cocobacilli consistent with shift of
                        vaginal flora
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].infection_cellular
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Cellular changes associated with hempes simples virus
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].infection_others
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Others
                      </span>
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bolder",
                          color: "#000",
                        }}
                      >
                        Reactive
                      </Typography>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].reactive_inflammation
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Inflammation (includes typical repair)
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].reactive_atrophy
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Atrophy with inflammation
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].reactive_follicular
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Follicular cervicitis
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].reactive_radiation
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Radiation effect
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(papsmearOrderDetails.data[0].reactive_iud) ===
                      1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        IUD effect
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(papsmearOrderDetails.data[0].reactive_des) ===
                      1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        DES changes
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].reactive_others
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Other Presence of RBC
                      </span>
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        Maturation Index
                      </Typography>
                    </Box>
                    <Box>
                      <Box display="flex">
                        <Typography
                          variant="subtitle2"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> Suggest: </b>
                        </Typography>
                        <Box ml={1}>
                          <Typography
                            variant="subtitle2"
                            style={{
                              textDecoration: "underline",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {papsmearOrderDetails.data[0].maturation_suggest}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle2"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bolder",
                        color: "#000",
                      }}
                    >
                      CLINICAL DATE
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                      <Box display="flex">
                        <Typography
                          variant="subtitle2"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          AGE:
                        </Typography>
                        <Box ml={1}>
                          <Typography
                            variant="subtitle2"
                            style={{
                              textDecoration: "underline",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {papsmearOrderDetails.data[0].age}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Box display="flex">
                        <Typography
                          variant="subtitle2"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          LMP:
                        </Typography>
                        <Box ml={1}>
                          <Typography
                            variant="subtitle2"
                            style={{
                              textDecoration: "underline",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {papsmearOrderDetails.data[0].lmp}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box display="flex">
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      HORMONES:
                    </Typography>
                    <Box ml={1}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bolder",
                          color: "#000",
                        }}
                      >
                        {papsmearOrderDetails.data[0].hormones}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      IUD:
                    </Typography>
                    <Box ml={1}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bolder",
                          color: "#000",
                        }}
                      >
                        {papsmearOrderDetails.data[0].iud}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box mt={4} mb={1}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        Epithelial Cell Abnormalities
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        Aquamous Cells
                      </Typography>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].squamous_typical
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        A typical squamous cells of undetermined significance
                        ASCUC: qualify favor reactive
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(papsmearOrderDetails.data[0].squamous_low) ===
                      1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Low grade squamous intraepithelial lesion
                      </span>
                    </Box>
                    <Box ml={5}>
                      <Box align="left">
                        {parseFloat(
                          papsmearOrderDetails.data[0].squamous_low_hpv
                        ) === 1 ? (
                          <IconButton>
                            <CheckBox color="primary" />
                          </IconButton>
                        ) : (
                          <IconButton>
                            <CheckBoxOutlineBlank />
                          </IconButton>
                        )}
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          HPV associated changes
                        </span>
                      </Box>
                      <Box align="left">
                        {parseFloat(
                          papsmearOrderDetails.data[0].squamous_low_mild
                        ) === 1 ? (
                          <IconButton>
                            <CheckBox color="primary" />
                          </IconButton>
                        ) : (
                          <IconButton>
                            <CheckBoxOutlineBlank />
                          </IconButton>
                        )}
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          Mild Dysplasia
                        </span>
                      </Box>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].squamous_high
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        High grade squamous intraepithelial lesion
                      </span>
                    </Box>
                    <Box ml={5}>
                      <Box align="left">
                        {parseFloat(
                          papsmearOrderDetails.data[0].squamous_high_moderate
                        ) === 1 ? (
                          <IconButton>
                            <CheckBox color="primary" />
                          </IconButton>
                        ) : (
                          <IconButton>
                            <CheckBoxOutlineBlank />
                          </IconButton>
                        )}
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          Moderate Dysplasia
                        </span>
                      </Box>
                      <Box align="left">
                        {parseFloat(
                          papsmearOrderDetails.data[0].squamous_high_severe
                        ) === 1 ? (
                          <IconButton>
                            <CheckBox color="primary" />
                          </IconButton>
                        ) : (
                          <IconButton>
                            <CheckBoxOutlineBlank />
                          </IconButton>
                        )}
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          Severe Dysplasia
                        </span>
                      </Box>
                      <Box align="left">
                        {parseFloat(
                          papsmearOrderDetails.data[0].squamous_high_carcinoma
                        ) === 1 ? (
                          <IconButton>
                            <CheckBox color="primary" />
                          </IconButton>
                        ) : (
                          <IconButton>
                            <CheckBoxOutlineBlank />
                          </IconButton>
                        )}
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          Carcinoma in situ
                        </span>
                      </Box>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].squamous_cell
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Squamous cell carcinoma
                      </span>
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        Giandulare cells
                      </Typography>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].giandulare_endomentrial
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Endometrial cells cytologically benign in a
                        postmenopausal
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].giandulare_typical
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        A typical giandulae cells of undetermined significance
                        (AGUS): quality favor reactive or pre-or malignant
                        process
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].giandulare_endocervical
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Endocervical adenocarcinoma
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].giandulare_endometrial
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Endometrial adenocarcinoma
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].giandulare_extraiterine
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Extralterine adenocarcinoma
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].giandulare_adenocarcinoma
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Adenocarcinoma no otherwise specified :{" "}
                        <b style={{ textDecoration: "underline" }}>
                          {papsmearOrderDetails.data[0]
                            .giandulare_adeno_specify !== null &&
                            papsmearOrderDetails.data[0]
                              .giandulare_adeno_specify}
                        </b>
                      </span>
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        Other malignant neoplasms:{" "}
                        <b
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {" "}
                          {papsmearOrderDetails.data[0].other_malignant !==
                            null &&
                            papsmearOrderDetails.data[0].other_malignant}
                        </b>
                      </Typography>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          Hormonal evaluation (applies to vaginal smear only)
                        </Typography>
                      </Box>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].hormonal_compatible
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Compatible with age and history
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].hormonal_incompatible
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Incompatible with age and history:{" "}
                        <b
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {" "}
                          {papsmearOrderDetails.data[0]
                            .hormonal_incompatible_spec !== null &&
                            papsmearOrderDetails.data[0]
                              .hormonal_incompatible_spec}
                        </b>
                      </span>
                    </Box>
                    <Box align="left">
                      {parseFloat(
                        papsmearOrderDetails.data[0].hormonal_non_possible
                      ) === 1 ? (
                        <IconButton>
                          <CheckBox color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Incompatible with age and history:{" "}
                        <b
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {" "}
                          {papsmearOrderDetails.data[0].non_possible_specify !==
                            null &&
                            papsmearOrderDetails.data[0].non_possible_specify}
                        </b>
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Box display="flex" my={3}>
              <Box flexGrow={1} />
              <Box>
                <Box align="center" width={250}>
                  <Box mt={5}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      OSCAR P. GRAGEDA, M.D, FPSP, APCP
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}>
                        Anatomic and Clinic Pathologist
                      </b>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default PapsmearOrder;
