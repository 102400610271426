import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Zoom from "@material-ui/core/Zoom";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import React, { Fragment, useCallback, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ImageAspectRatioOutlined from "@material-ui/icons/ImageAspectRatioOutlined";

const OrderDetailsUltraSound = ({
  patient_id,
  allLocalRad,
  onClose,
  getAllPatientForImaging,
}) => {
  const { users } = React.useContext(UsersData);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedImagingId, setSelectedImagingId] = React.useState(null);
  const [selectedImagingOrder, setSelectedImagingOrder] = React.useState(null);
  const [countOfOrderDetails, setCountOfOrderDetails] = useState(0);
  const [orderDetails, setOrderDetails] = React.useState({
    data: [],
    ready: false,
  });

  const getPatientInformation = useCallback(async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
        patient_id: patient_id,
        type: "ultra-sound",
      };
      const response = await axios.get("imaging/patients/information", {
        params,
      });
      setOrderDetails({ data: response.data, ready: true });
      setCountOfOrderDetails(response.data.length);
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, patient_id]);

  const convertToFormdata = (object) => {
    const formData = new FormData();
    formData.append("user_id", users.user_id);
    formData.append("management_id", users.management_id);
    formData.append("username", users.username);
    formData.append("patient_id", patient_id);
    formData.append("imaging_center_id", selectedImagingId);
    formData.append("order_count", countOfOrderDetails);

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleCustomOnchange = (setFieldValue, value) => {
    setFieldValue("radiologist_type", value === "" ? "" : value);
    // if (value === "In-House") {
    //   set_radiologist_click(true);
    //   set_telerad_click(false);
    // } else {
    //   set_radiologist_click(false);
    //   set_telerad_click(true);
    // }
  };

  const handleAddAttachment = (e) => {
    setSelectedImagingId(e.currentTarget.id);
    setSelectedImagingOrder(e.currentTarget.getAttribute("order"));
    setOpenDialog(true);
  };

  const getBackToDefault = () => {
    setOpenDialog(false);
    setSelectedImagingId(null);
    setSelectedImagingOrder(null);
  };

  React.useEffect(() => {
    getPatientInformation();
  }, [getPatientInformation]);

  return (
    <Fragment>
      <Paper component={Box}>
        <Box borderRadius={4}>
          <Box display="flex" justifyContent="center">
            <Box mt={1} flexGrow={1}>
              <Typography noWrap color="textSecondary" variant="h6">
                <IconButton color="primary" onClick={() => onClose()}>
                  <ArrowBack />
                </IconButton>
                Order Details
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box m={2}>
          {orderDetails.ready &&
            (orderDetails.data.length > 0 ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box mb={1}>
                    <TextField
                      required
                      fullWidth
                      label="Fullname"
                      name="fullname"
                      variant="outlined"
                      value={
                        orderDetails.data[0].fname !== null &&
                        orderDetails.data[0].lname !== null
                          ? `${orderDetails.data[0].fname} ${orderDetails.data[0].lname}`
                          : ""
                      }
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mb={1}>
                    <TextField
                      required
                      fullWidth
                      label="Contact"
                      name="contact"
                      variant="outlined"
                      value={
                        orderDetails.data[0].mobile !== null ||
                        orderDetails.data[0].telephone
                          ? orderDetails.data[0].mobile !== null
                            ? orderDetails.data[0].mobile
                            : orderDetails.data[0].telephone
                          : "none"
                      }
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box mb={1}>
                    <TextField
                      required
                      fullWidth
                      label="Gender"
                      name="gender"
                      variant="outlined"
                      value={
                        orderDetails.data[0].gender !== null
                          ? orderDetails.data[0].gender
                          : ""
                      }
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box mb={1}>
                    <TextField
                      required
                      fullWidth
                      label="Age"
                      name="age"
                      variant="outlined"
                      value={
                        orderDetails.data[0].birthday !== null
                          ? Notify.calculateAge(orderDetails.data[0].birthday)
                          : ""
                      }
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mb={1}>
                    <TextField
                      required
                      fullWidth
                      label="Order Date"
                      name="order_date"
                      variant="outlined"
                      value={
                        orderDetails.data[0].updated_at !== null
                          ? Notify.dateTimeConvert(
                              orderDetails.data[0].updated_at
                            )
                          : ""
                      }
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box mb={1}>
                    <TextField
                      required
                      fullWidth
                      label="Address"
                      name="address"
                      variant="outlined"
                      value={
                        orderDetails.data[0].barangay !== null ||
                        orderDetails.data[0].city !== null
                          ? `${orderDetails.data[0].barangay}, ${orderDetails.data[0].city}`
                          : ""
                      }
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>

                {orderDetails.data[0].stitch_order_request === null && (
                  <>
                    {orderDetails.data.map((data, index) => {
                      return (
                        <Grid item xs={12} sm={12} key={index}>
                          <Box>
                            <Typography variant="subtitle1">
                              <strong> ORDER # {index + 1} </strong>
                            </Typography>
                          </Box>
                          <Box p={2} display="flex">
                            <Box flexGrow={1}>
                              <Typography>
                                &bull; {data.imaging_order}
                              </Typography>
                            </Box>
                            <Button
                              id={data.imaging_center_id}
                              order={data.imaging_order}
                              variant="outlined"
                              color="primary"
                              onClick={handleAddAttachment}
                              startIcon={<ImageAspectRatioOutlined />}
                            >
                              ORDER # {index + 1} SEND TO DOCTOR
                            </Button>
                          </Box>
                        </Grid>
                      );
                    })}
                  </>
                )}

                <Dialog
                  open={openDialog}
                  TransitionComponent={Zoom}
                  transitionDuration={800}
                >
                  <DialogTitle>ORDER SEND TO DOCTOR</DialogTitle>
                  <DialogContent dividers>
                    <Formik
                      initialValues={{
                        imaging_order: selectedImagingOrder,

                        referring_physician: "",
                        file_no: "",

                        radiologist_type: "",
                        radiologist: "",
                        password: "",
                      }}
                      validationSchema={Yup.object().shape({
                        radiologist_type: Yup.string()
                          .trim()
                          .required("Radiologist type is required."),
                        radiologist: Yup.string()
                          .trim()
                          .required("Radiologist is required."),
                        password: Yup.string().required(
                          "Password is required."
                        ),
                      })}
                      onSubmit={async (
                        values,
                        { setErrors, setStatus, setSubmitting, resetForm }
                      ) => {
                        try {
                          const request = await axios.post(
                            "imaging/patient/order/ultra-sound/addresult",
                            convertToFormdata(values)
                          );
                          //   imaging/patient/order/addresult
                          if (request.data === "pass-invalid") {
                            setErrors({ submit: "Password is invalid." });
                            Notify.fieldInvalid("password");
                          }
                          if (request.data === "success") {
                            if (parseFloat(countOfOrderDetails) > 1) {
                              getPatientInformation();
                            }
                            if (parseFloat(countOfOrderDetails) === 1) {
                              onClose();
                            }
                            setSubmitting(true);
                            getBackToDefault();
                            getAllPatientForImaging();
                            resetForm();
                            Notify.successRequest("new attachment");
                          }
                        } catch (error) {
                          const message =
                            error.message || "Something went wrong";
                          setStatus({ success: false });
                          setErrors({ submit: message });
                          setSubmitting(false);
                        }
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Box mb={2}>
                            <TextField
                              error={Boolean(
                                touched.referring_physician &&
                                  errors.referring_physician
                              )}
                              helperText={
                                touched.referring_physician &&
                                errors.referring_physician
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              value={values.referring_physician}
                              required
                              name="referring_physician"
                              label="Referring Physician"
                              variant="outlined"
                            />
                          </Box>

                          <Box mb={2}>
                            <TextField
                              error={Boolean(touched.file_no && errors.file_no)}
                              helperText={touched.file_no && errors.file_no}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              value={values.file_no}
                              required
                              name="file_no"
                              label="File No"
                              variant="outlined"
                            />
                          </Box>

                          <Box mb={2}>
                            <TextField
                              error={Boolean(
                                touched.radiologist_type &&
                                  errors.radiologist_type
                              )}
                              helperText={
                                touched.radiologist_type &&
                                errors.radiologist_type
                              }
                              onBlur={handleBlur}
                              onChange={(e) =>
                                handleCustomOnchange(
                                  setFieldValue,
                                  e.target.value
                                )
                              }
                              value={values.radiologist_type}
                              fullWidth
                              required
                              name="radiologist_type"
                              label="Select Radiologist Type"
                              variant="outlined"
                              select
                              SelectProps={{
                                native: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value={""}>Select</option>
                              <option value={"In-House"}>In-House</option>
                              <option hidden value={"Telerad"}>
                                Telerad
                              </option>
                            </TextField>
                          </Box>

                          <Box mb={2}>
                            <TextField
                              error={Boolean(
                                touched.radiologist && errors.radiologist
                              )}
                              helperText={
                                touched.radiologist && errors.radiologist
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.radiologist}
                              fullWidth
                              required
                              name="radiologist"
                              label="Select Radiologist"
                              variant="outlined"
                              select
                              SelectProps={{
                                native: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value={""}>Select</option>
                              {allLocalRad.data.length > 0 ? (
                                allLocalRad.data.map((data1, index1) => {
                                  return (
                                    <option key={index1} value={data1.values}>
                                      {data1.label}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value={""}>No result</option>
                              )}
                            </TextField>
                          </Box>

                          <Box mb={2}>
                            <TextField
                              fullWidth
                              required
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              helperText={touched.password && errors.password}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.password}
                              name="password"
                              label="Password"
                              variant="outlined"
                              type="password"
                            />
                          </Box>

                          {errors.submit && (
                            <Box mt={3}>
                              <FormHelperText error>
                                {errors.submit}
                              </FormHelperText>
                            </Box>
                          )}

                          <Box display="flex">
                            <Button
                              variant="contained"
                              color="default"
                              startIcon={<ClearIcon />}
                              size="large"
                              onClick={() => setOpenDialog(false)}
                            >
                              Close
                            </Button>
                            <Box ml={2}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                startIcon={<CheckIcon />}
                                size="large"
                                disabled={isSubmitting}
                              >
                                Save
                              </Button>
                            </Box>
                          </Box>
                        </form>
                      )}
                    </Formik>
                    <Box></Box>
                  </DialogContent>
                </Dialog>
              </Grid>
            ) : (
              Notify.noRecord()
            ))}
        </Box>
      </Paper>
    </Fragment>
  );
};

export default OrderDetailsUltraSound;
