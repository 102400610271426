import React, { Fragment } from "react";
import {
  Box,
  Paper,
  FormHelperText,
  Divider,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const HospitalAddAccount = ({ getAllUsersAccount }) => {
  const { users } = React.useContext(UsersData);

  const accounts = [
    { name: "accounting" },
    { name: "billing" },
    { name: "cashier" },
    { name: "doctor" },
    { name: "haptech" },
    { name: "hr" },
    { name: "imaging" },
    { name: "isihihara" },
    { name: "laboratory" },
    { name: "pharmacy" },
    { name: "radiologist" },
    { name: "registration" },
    { name: "stockroom" },
    { name: "triage" },
    { name: "warehouse" },
  ];

  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Fragment>
      <Box component={Paper} variant="outlined" p={2}>
        <Formik
          initialValues={{
            username: users.username,
            user_id: users.user_id,
            management_id: users.management_id,
            main_mgmt_id: users.main_mgmt_id,
            generateuser_id: "u-" + Date.now(),
            department: "",
            fullname: "",
            address: "",
            gender: "",
            rate_classification: "",
            monthly_salary: "",
            daily_salary: "",
            user_username: "",
            user_pass: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            department: Yup.string().trim().required("Department is required"),
            fullname: Yup.string().trim().required("Fullname is required"),
            address: Yup.string().trim().required("Address is required"),
            gender: Yup.string().trim().required("Gender is required"),
            rate_classification: Yup.string()
              .trim()
              .required("Rate classification is required"),
            user_username: Yup.string()
              .trim()
              .required("User's username is required"),
            user_pass: Yup.string()
              .trim()
              .required("User's password is required"),
            password: Yup.string().required("Your password is required"),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            var error = [];
            if (
              values.rate_classification === "Daily" &&
              values.daily_salary.length === 0
            ) {
              error = "error";
              setErrors({ daily_salary: "Daily Salary is required." });
            }
            if (
              values.rate_classification === "Monthly" &&
              values.monthly_salary.length === 0
            ) {
              error = "error";
              setErrors({ monthly_salary: "Monthly Salary is required." });
            }
            if (error.length > 0) {
              Notify.consoleLog("form error.");
            } else {
              try {
                const request = await axios.post(
                  "hims/add/new-account",
                  getFormData(values)
                );
                if (request.data === "pass-invalid") {
                  setErrors({ password: "Password is invalid." });
                  Notify.fieldInvalid("password");
                }
                if (request.data === "success") {
                  Notify.successRequest("add new imaging test");
                  resetForm();
                  setSubmitting(true);
                  getAllUsersAccount();
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Box mb={2}>
                <Typography color="textPrimary" variant="subtitle2">
                  NEW ACCOUNT
                </Typography>
              </Box>

              <Divider />

              <Box my={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.department && errors.department)}
                  helperText={touched.department && errors.department}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.department}
                  fullWidth
                  required
                  name="department"
                  label="Department"
                  variant="outlined"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value={""}>Select</option>
                  {accounts.length > 0 &&
                    accounts.map((data, index) => (
                      <option key={index} value={data.name}>
                        {data.name}
                      </option>
                    ))}
                </TextField>
              </Box>

              <Box my={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.fullname && errors.fullname)}
                  helperText={touched.fullname && errors.fullname}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fullname}
                  fullWidth
                  required
                  name="fullname"
                  label="Fullname"
                  variant="outlined"
                />
              </Box>

              <Box mb={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  fullWidth
                  required
                  name="address"
                  label="Address"
                  variant="outlined"
                />
              </Box>

              <Box mb={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.gender && errors.gender)}
                  helperText={touched.gender && errors.gender}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.gender}
                  fullWidth
                  required
                  name="gender"
                  label="Gender"
                  variant="outlined"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value={""}>Select</option>
                  <option value={"Male"}>Male</option>
                  <option value={"Female"}>Female</option>
                </TextField>
              </Box>

              <Box mb={2} className={`labselect2`}>
                <TextField
                  error={Boolean(
                    touched.rate_classification && errors.rate_classification
                  )}
                  helperText={
                    touched.rate_classification && errors.rate_classification
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.rate_classification}
                  fullWidth
                  required
                  name="rate_classification"
                  label="Rate Classification"
                  variant="outlined"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value={""}>Select</option>
                  <option value={"Daily"}>Daily</option>
                  <option value={"Monthly"}>Monthly</option>
                </TextField>
              </Box>

              {values.rate_classification === "Daily" && (
                <Box mb={2} className={`labselect2`}>
                  <TextField
                    error={Boolean(touched.daily_salary && errors.daily_salary)}
                    helperText={touched.daily_salary && errors.daily_salary}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.daily_salary}
                    fullWidth
                    required
                    name="daily_salary"
                    label="Daily Salary"
                    variant="outlined"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 100000000000,
                        step: 0.01,
                      },
                    }}
                  />
                </Box>
              )}

              {values.rate_classification === "Monthly" && (
                <Box mb={2} className={`labselect2`}>
                  <TextField
                    error={Boolean(
                      touched.monthly_salary && errors.monthly_salary
                    )}
                    helperText={touched.monthly_salary && errors.monthly_salary}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.monthly_salary}
                    fullWidth
                    required
                    name="monthly_salary"
                    label="Monthly Salary"
                    variant="outlined"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 100000000000,
                        step: 0.01,
                      },
                    }}
                  />
                </Box>
              )}

              <Box mb={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.user_username && errors.user_username)}
                  helperText={touched.user_username && errors.user_username}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.user_username}
                  fullWidth
                  required
                  name="user_username"
                  label="User's username"
                  variant="outlined"
                />
              </Box>

              <Box mb={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.user_pass && errors.user_pass)}
                  helperText={touched.user_pass && errors.user_pass}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.user_pass}
                  fullWidth
                  required
                  name="user_pass"
                  label="User's password"
                  variant="outlined"
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Divider />

              <Box my={2} display="flex">
                <Box flexGrow={1} />

                <Button
                  variant="contained"
                  color="default"
                  type="reset"
                  startIcon={<ClearIcon />}
                >
                  Clear
                </Button>

                <Box ml={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon />}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Fragment>
  );
};

export default HospitalAddAccount;
