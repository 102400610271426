import React, { Fragment, useContext, useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import Axios from "axios";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Button,
  CircularProgress,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import {
  faArrowRight,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import IsMountedRef from "src/utils/IsMountedRef";
import { UsersData } from "src/ContextAPI";

const UnsavedPrescription = ({
  patient_id,
  getUnsavePrescription,
  displayList,
  onDataFromUnsaved,
  unsavedList,
}) => {
  const [unsave, setUnsave] = useState({ data: [], ready: false });
  const [removeSelectedId, setRemoveSelectedId] = useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const mounted = IsMountedRef();
  const { users } = useContext(UsersData);

  const [doctorList, setDoctorList] = useState({
    data: [],
    ready: false,
  });

  const unsavePrescription = () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", "local");

    Axios.post("prescription/local/product-unsave", formdata)
      .then((response) => {
        const data = response.data;
        if (mounted.current) {
          setUnsave({ data, ready: true });
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const sendDataToForm = (item) => {
    const data = {
      prescription: item.prescription,
      quantity: item.quantity,
      type: item.type,
      remarks: item.remarks,
    };
    onDataFromUnsaved(data);
  };

  const fetchAllDoctors = () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    Axios.get("nurse/get-all-doctors", { params })
      .then((response) => {
        const data = response.data;
        setDoctorList({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const closeRemovedialog = () => {
    setRemoveSelectedId(null);
    setRemoveDialogOpen(false);
  };

  const handleSelectedProd = (e) => {
    setRemoveDialogOpen(true);
    //setRemoveSelectedId(e.currentTarget.id);
  };

  const handleRemove = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("remove_id", removeSelectedId);
    formdata.set("connection", "local");

    var error = [];
    if (error.length > 0) {
      Notify.consoleLog("remove presc");
    } else {
      setIsProcess(true);
      Axios.post("prescription/local/product-unsave-remove", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            setRemoveDialogOpen(false);
            getUnsavePrescription();
            unsavePrescription();
            Notify.successRequest("remove presc");
          } else {
            Notify.warnRequest("remove presc");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsProcess(false);
        });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", "local");

    var error = [];
    if (error.length > 0) {
      console.warn("Unable to process sample, Form has an error.");
    } else {
      setIsSaving(true);
      Axios.post("prescription/nurse/local/prescriptionsaveallUnsave", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            setSaveDialogOpen(false);
            unsavePrescription();
            getUnsavePrescription();
            Notify.successRequest("prescription save");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  useEffect(() => {
    unsavePrescription();
    fetchAllDoctors();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div className="flex flex-row justify-content-between gap-1 mb-4 mt-4">
        <h4 className="text-lg font-bold mb-0">Unsaved Prescriptions</h4>
        <Button variant="outlined" size="medium" onClick={displayList}>
          View List <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </Button>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="text-uppercase">
                <strong> Prescription </strong>
              </TableCell>
              <TableCell className="text-uppercase">
                <strong> Quantity </strong>
              </TableCell>
              <TableCell className="text-uppercase">
                <strong> Type </strong>
              </TableCell>
              <TableCell className="text-uppercase">
                <strong> Sig./Remarks </strong>
              </TableCell>
              <TableCell className="text-uppercase">
                <strong> Action </strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unsavedList.length > 0 && (
              <Fragment>
                {unsavedList.map((item, index) => (
                  <Fragment key={index}>
                    <TableRow hover>
                      <TableCell>{item.prescription}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>{item.remarks}</TableCell>
                      <TableCell>
                        <div className="d-flex flex-row gap-2">
                          <div>
                            <Tooltip arrow title="Edit">
                              <Button
                                variant="contained"
                                size="small"
                                className="p-2"
                                onClick={() => sendDataToForm(item)}
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="mr-2"
                                />
                                Edit
                              </Button>
                            </Tooltip>
                          </div>
                          <div>
                            <Tooltip arrow title="Delete">
                              <Button
                                variant="contained"
                                size="small"
                                color="secondary"
                                className="p-2"
                                onClick={() => handleSelectedProd(index)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="mr-2"
                                />
                                Delete
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </Fragment>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={1}
          rowsPerPageOptions={[10, 50, 100]}
          page={2}
          rowsPerPage={10}
          labelRowsPerPage="List"
        />
      </TableContainer>

      <Dialog open={removeDialogOpen} onClose={closeRemovedialog}>
        <form onSubmit={handleRemove}>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this prescription?
            </Typography>

            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                variant="contained"
                color="default"
                onClick={closeRemovedialog}
                startIcon={<HighlightOffIcon />}
              >
                No
              </Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isProcess}
                  startIcon={
                    isProcess ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <CheckCircleOutlineIcon />
                    )
                  }
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default UnsavedPrescription;
