import React, { useContext } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  FormHelperText,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import { UsersData } from "src/ContextAPI";
import Axios from "axios";
import { CheckCircleOutline as CheckIcon } from "@material-ui/icons";

const PatientDetailsContactTracing = ({
  patient_id,
  getDoctorsPatient,
  getPatientInformation,
  info,
  setSelectedPatient,
}) => {
  const { users } = useContext(UsersData);

  const sickness = [
    "Dry cough",
    "Fever",
    "Shortness of breath",
    "Difficulty of breathing",
    "Sore throat",
    "Headache",
    "Nasal congestions",
    "Diarrhea",
    "Colds",
    "Fatigue or body malaise",
  ];

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Box component={Paper} variant="outlined" mt={2} p={2}>
      <Box>
        {info.ready ? (
          info.data ? (
            <Formik
              enableReinitialize
              initialValues={{
                username: users.username,
                user_id: users.user_id,
                // password: "",
                patient_id: info.data.patient_id,
                temperature: info.data.latestCCTemp,

                purpose_ofvisit: "",
                allergies: "",
                last_xray_check: "",
                last_xray_result: "",
                history_oftravel_date: "",
                history_oftravel_days: "",
                history_oftravel_place: "",
                contact_withpuipum: "",
                contact_withpositive: "",
                crt_purpose: "",
                requested_by: "",
                complete_company: "",
                contact_information: "",
                quarantine_ifpositive: "",

                triage_staff: info.data.triageName,
                pct_id:
                  info.data.contactTracingID !== null
                    ? info.data.contactTracingID
                    : "",
              }}
              validationSchema={Yup.object().shape({
                // password: Yup.string().trim().required(),
              })}
              onSubmit={async (
                values,
                { setErrors, setSubmitting, resetForm }
              ) => {
                try {
                  const request = await Axios.post(
                    "admission/patients/edit-contact-tracing",
                    getFormData(values)
                  );
                  // if (request.data === "pass-invalid") {
                  //   setErrors({ password: "Password is invalid." });
                  //   Notify.fieldInvalid("password");
                  // }
                  if (request.data === "success") {
                    setSelectedPatient();
                    getDoctorsPatient();
                    getPatientInformation();
                    Notify.successRequest("update patient contact tracing");
                    resetForm();
                    setSubmitting(true);
                  }
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Box
                    mb={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box flexGrow={1}>
                      <Typography color="primary" variant="subtitle2">
                        CONTACT TRACING INFORMATION
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <Grid container spacing={1}>
                      {info.data.sickness !== null &&
                        sickness.map((data, index) =>
                          info.data.sickness.split(",").map(
                            (data2, index2) =>
                              Boolean(data2 === data) && (
                                <Grid key={index2} item xs={12} sm={6} md={4}>
                                  <Box>
                                    <FormControl component="fieldset">
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name="gilad"
                                              defaultChecked={true}
                                            />
                                          }
                                          label={data}
                                        />
                                      </FormGroup>
                                    </FormControl>
                                  </Box>
                                </Grid>
                              )
                          )
                        )}
                    </Grid>
                  </Box>

                  <Box mb={2}>
                    <TextField
                      label={"Temperature"}
                      variant="outlined"
                      fullWidth
                      error={Boolean(touched.temperature && errors.temperature)}
                      helperText={touched.temperature && errors.temperature}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.temperature}
                      name="temperature"
                      InputProps={{
                        readOnly: true,
                        disabled: true,
                      }}
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      label={"Purpose of Visit/Medical Concerns/issues"}
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        touched.purpose_ofvisit && errors.purpose_ofvisit
                      )}
                      helperText={
                        touched.purpose_ofvisit && errors.purpose_ofvisit
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.purpose_ofvisit}
                      name="purpose_ofvisit"
                    />
                  </Box>

                  <Box mb={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <TextField
                            label={"Any Allergies"}
                            fullWidth
                            variant="outlined"
                            error={Boolean(
                              touched.allergies && errors.allergies
                            )}
                            helperText={touched.allergies && errors.allergies}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.allergies}
                            name="allergies"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <TextField
                            label={"Last Check Xray Taken"}
                            fullWidth
                            variant="outlined"
                            error={Boolean(
                              touched.last_xray_check && errors.last_xray_check
                            )}
                            helperText={
                              touched.last_xray_check && errors.last_xray_check
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.last_xray_check}
                            name="last_xray_check"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <TextField
                            label={"Result of Cxray"}
                            fullWidth
                            variant="outlined"
                            error={Boolean(
                              touched.last_xray_result &&
                                errors.last_xray_result
                            )}
                            helperText={
                              touched.last_xray_result &&
                              errors.last_xray_result
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.last_xray_result}
                            name="last_xray_result"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mb={2}>
                    <Box mb={1}>
                      <Typography>History of Travel: When and where</Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.history_oftravel_date &&
                                errors.history_oftravel_date
                            )}
                            helperText={
                              touched.history_oftravel_date &&
                              errors.history_oftravel_date
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.history_oftravel_date}
                            fullWidth
                            name="history_oftravel_date"
                            label="Date"
                            variant="outlined"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.history_oftravel_days &&
                                errors.history_oftravel_days
                            )}
                            helperText={
                              touched.history_oftravel_days &&
                              errors.history_oftravel_days
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.history_oftravel_days}
                            fullWidth
                            name="history_oftravel_days"
                            label="Days"
                            variant="outlined"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: 10000,
                                step: 1,
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <TextField
                            label={"Place"}
                            fullWidth
                            variant="outlined"
                            multiline
                            error={Boolean(
                              touched.history_oftravel_place &&
                                errors.history_oftravel_place
                            )}
                            helperText={
                              touched.history_oftravel_place &&
                              errors.history_oftravel_place
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.history_oftravel_place}
                            name="history_oftravel_place"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mb={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box>
                          <TextField
                            label={"Any contact with PUI to PUM"}
                            fullWidth
                            variant="outlined"
                            select
                            SelectProps={{ native: true }}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(
                              touched.contact_withpuipum &&
                                errors.contact_withpuipum
                            )}
                            helperText={
                              touched.contact_withpuipum &&
                              errors.contact_withpuipum
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.contact_withpuipum}
                            name="contact_withpuipum"
                          >
                            <option value=""> SELECT </option>
                            <option value="No"> No </option>
                            <option value="Yes"> Yes </option>
                          </TextField>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box>
                          <TextField
                            label={"Any contact with positive covid patient"}
                            fullWidth
                            variant="outlined"
                            select
                            SelectProps={{ native: true }}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(
                              touched.contact_withpositive &&
                                errors.contact_withpositive
                            )}
                            helperText={
                              touched.contact_withpositive &&
                              errors.contact_withpositive
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.contact_withpositive}
                            name="contact_withpositive"
                          >
                            <option value=""> SELECT </option>
                            <option value="No"> No </option>
                            <option value="Yes"> Yes </option>
                          </TextField>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Typography>FOR CRT Clients: </Typography>
                  </Box>

                  <Box my={2}>
                    <TextField
                      label={"Purpose of your request for CRT."}
                      fullWidth
                      variant="outlined"
                      multiline
                      error={Boolean(touched.crt_purpose && errors.crt_purpose)}
                      helperText={touched.crt_purpose && errors.crt_purpose}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.crt_purpose}
                      name="crt_purpose"
                    />
                  </Box>

                  <Box my={2}>
                    <TextField
                      label={"Requested by"}
                      fullWidth
                      variant="outlined"
                      multiline
                      error={Boolean(
                        touched.requested_by && errors.requested_by
                      )}
                      helperText={touched.requested_by && errors.requested_by}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.requested_by}
                      name="requested_by"
                    />
                  </Box>

                  <Box my={2}>
                    <TextField
                      label={"If for return to work, complete company name"}
                      fullWidth
                      variant="outlined"
                      multiline
                      error={Boolean(
                        touched.complete_company && errors.complete_company
                      )}
                      helperText={
                        touched.complete_company && errors.complete_company
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.complete_company}
                      name="complete_company"
                    />
                  </Box>

                  <Box my={2}>
                    <TextField
                      label={"Complete contact person, designation & numbers"}
                      fullWidth
                      variant="outlined"
                      multiline
                      error={Boolean(
                        touched.contact_information &&
                          errors.contact_information
                      )}
                      helperText={
                        touched.contact_information &&
                        errors.contact_information
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.contact_information}
                      name="contact_information"
                    />
                  </Box>

                  <Box>
                    <Typography>
                      If found to be positive of either IgG or IgM, are you
                      willing to undergo quarantine or Swab testing testing
                      (RT-PCR)?
                    </Typography>
                  </Box>

                  <Box my={2}>
                    <TextField
                      label={"Select"}
                      fullWidth
                      variant="outlined"
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(
                        touched.quarantine_ifpositive &&
                          errors.quarantine_ifpositive
                      )}
                      helperText={
                        touched.quarantine_ifpositive &&
                        errors.quarantine_ifpositive
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.quarantine_ifpositive}
                      name="quarantine_ifpositive"
                    >
                      <option value=""> SELECT </option>
                      <option value="No"> No </option>
                      <option value="Yes"> Yes </option>
                    </TextField>
                  </Box>

                  <Box my={3}>
                    <Typography color="textSecondary">
                      I hereby authorize Global Telemidicine Corp Medical
                      Station Co. Inc. to collect and process the data indicated
                      herein for the purpose of effecting conroil of the COVID
                      19 infection. I understand that my personal information is
                      protected by RA 10173, Data PrivacyAct of 2012 and that I
                      am required by RA 11469 Bayanihan to Heal as One Act. to
                      provide truthful information.
                    </Typography>
                  </Box>

                  <Box my={2}>
                    <TextField
                      label={"Triage staff"}
                      fullWidth
                      variant="outlined"
                      multiline
                      error={Boolean(
                        touched.triage_staff && errors.triage_staff
                      )}
                      helperText={touched.triage_staff && errors.triage_staff}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.triage_staff}
                      name="triage_staff"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>

                  <Box>
                    {/* <Box my={1}>
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        fullWidth
                        required
                        name="password"
                        type="password"
                        label="Password"
                        variant="outlined"
                      />
                    </Box> */}

                    {errors.submit && (
                      <Box mt={2}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}

                    <Box mb={1} mt={1} display="flex">
                      <Box flexGrow={1} />
                      <Box ml={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              <CheckIcon />
                            )
                          }
                          disabled={
                            isSubmitting || info.data.latestCCRecord === null
                          }
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}
      </Box>
    </Box>
  );
};

export default PatientDetailsContactTracing;
