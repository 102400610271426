import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  TextField,
  Card,
} from "@material-ui/core";
import { styles } from "../styles/styles";
import { useForm } from "react-hook-form";
import axios from "axios";
import Notify from "src/notification/Notify";

export default function TbProgramReferral({ open, onClose, patientName, id }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);

    let formdata = new FormData();
    formdata.append("rhu_refferal_date", data?.date || "");
    formdata.append("rhu_notes", data?.notes || "");
    formdata.append("hospital_id", "6");

    axios
      .patch(`v1/tuberculosis-program/rhu-to-sph-referral/${id}`, formdata)
      .then((response) => {
        let data = response.data;
        console.log(data);
        Notify.successRequest("TB Program Referral");
      })
      .catch((err) => console.log(err));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <div className="flex justify-between items-center">
            <Box>
              <Typography variant="h5" color="textSecondary">
                <strong>Tb Program Referral</strong>
              </Typography>
            </Box>
            <button
              className="text-red-600 hover:bg-red-300 py-2 px-3  rounded-lg "
              onClick={onClose}
              type="button"
            >
              CLOSE
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <Card elevation={3} className="pt-4  px-2" component={Box}>
            <div className="w-[500px]">
              <div style={styles.divContainer}>
                <TextField
                  label={<label style={styles.label}>Name</label>}
                  variant="outlined"
                  fullWidth
                  value={patientName}
                  {...register("name")}
                />
              </div>
              <div style={styles.divContainer}>
                <TextField
                  label={<label style={styles.label}>Date</label>}
                  variant="outlined"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("date", { required: true })}
                  error={errors.date}
                  helperText={errors.date && "Date is required"}
                />
              </div>
              <div style={styles.divContainer}>
                <TextField
                  label={<label style={styles.label}>Notes</label>}
                  variant="outlined"
                  fullWidth
                  {...register("notes", { required: true })}
                  error={errors.notes}
                  helperText={errors.notes && "Notes is required"}
                />
              </div>
            </div>
          </Card>
          <div className="py-3 mt-2">
            <Button
              fullWidth
              style={{ fontSize: "18px" }}
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </form>
    </Dialog>
  );
}
