import {
  Typography,
  Box,
  Grid,
  // TextField,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const UrinalysisOrder = ({ formheader, urinalysicOrderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        {/* <CardContent>
          <Header details={urinalysicOrderDetails} />
        </CardContent> */}

        {/* // 11-19-2021-updates-jhomar */}
        <CardContent className="darken-inputvalue">
          <Box>
            <Header details={urinalysicOrderDetails} />
          </Box>
          <Box>
            <Typography variant="h6" align="center">
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
                style={{ fontWeight: "bold", color: "#000" }}
              >
                URINALYSIS
              </span>
            </Typography>
          </Box>

          {urinalysicOrderDetails.data.map((data, index) => (
            <Grid container key={index}>
              <Grid item xs={12}>
                <Box display={"flex"}>
                  <Box
                    width="50%"
                    style={{
                      backgroundColor: "#AEAEAE",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        marginLeft: 20,
                      }}
                    >
                      <b>MACROSCOPIC EXAMINATION</b>
                    </Typography>
                  </Box>

                  <Box
                    width="50%"
                    style={{
                      backgroundColor: "#AEAEAE",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        marginLeft: 20,
                      }}
                    >
                      <b>CHEMICAL EXAMINATION</b>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      color :
                    </Typography>
                  </Box>

                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.color}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" mb={5.4}>
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      transparency :
                    </Typography>
                  </Box>

                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.transparency}
                    </Typography>
                  </Box>
                </Box>

                {/* <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      <span className="text-capitalize">sp gravity :</span>
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.sp_gravity}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      albumin (negative) :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.albumin}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      sugar (negative) :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.sugar}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      pus cells :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.pus_cell}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      R.B.C:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.rbc}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      epithelial cells :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.epithelial_cell}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      mucus threads :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.mucus_threads}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      Color :renal cells :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.renal_cell}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      Color :yeast cells :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.yeast_cell}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      hyaline :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.hyaline}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      rbc cast :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.rbc_cast}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      wbc cast :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.wbc_cast}
                    </Typography>
                  </Box>
                </Box> */}
              </Grid>

              <Grid item xs={6}>
                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      <span className="text-capitalize">
                        Specific Gravity :
                      </span>
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.sp_gravity}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      <span className="text-capitalize">Glucose :</span>
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.glucose}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      <span className="text-capitalize">Protein :</span>
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.protein}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                      }}
                    >
                      <span>pH :</span>
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.ph}
                    </Typography>
                  </Box>
                </Box>

                {/* <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      coarse granular cast :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.coarse_granular_cast}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      fine granular cast :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.fine_granular_cast}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      pus in clumps :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.pus_in_clumps}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      rbc in clumps :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.rbc_in_clumps}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      calcium oxalate :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.calcium_oxalate}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      uric acid :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.uricacid}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      amorphous urate :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.amorphous_urate}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      amorphous phosphate :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.amorphous_phosphate}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      calcium carbonate :
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.calcium_carbonate}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      ammonium biurate:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.ammonium_biurate}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      triple phosphate:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.triple_phosphate}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      spermatozoa:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.spermatozoa}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      trichomonas vaginalis:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.trichomonas_vaginalis}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      micral test:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.micral_test}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      urine ketone:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.urine_ketone}
                    </Typography>
                  </Box>
                </Box> */}
              </Grid>

              <Grid item xs={12}>
                <Box
                  style={{
                    backgroundColor: "#AEAEAE",
                  }}
                >
                  <Typography
                    style={{
                      color: "#000",
                      marginLeft: 20,
                    }}
                  >
                    <b>MICROSCOPIC EXAMINATION</b>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      <span className="text-capitalize">Pus Cells :</span>
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.pus_cell}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      <span className="text-capitalize">Red Blood Cells :</span>
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.rbc}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      <span className="text-capitalize">
                        Epithelial Cells :
                      </span>
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.epithelial_cell}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      <span className="text-capitalize">Mucus Threads :</span>
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.mucus_threads}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      <span className="text-capitalize">Bacteria :</span>
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.bacteria}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      <span className="text-capitalize">Hyaline :</span>
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.hyaline}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      Yeast Cells:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.yeast_cell}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      Calcium Oxalate:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.calcium_oxalate}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      Uric Acid:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.uric_acid}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      Amorphous Phosphate:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.amorphous_phosphate}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      Amorphous Urates:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.amorphous_urate}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      Trichomonas Vaginalis:
                    </Typography>
                  </Box>
                  <Box align="center" width={200}>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.trichomonas_vaginalis}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                {/* <Box display="flex">
                  <Box width={90}>
                    <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                      Others :{" "}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.others}
                    </Typography>
                  </Box>
                </Box> */}

                <Box display={"flex"}>
                  <Box width={90}>
                    <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                      Others :{" "}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      align="center"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {data.remarks}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ))}

          <Box align="center">
            <Footer
              formheader={formheader}
              orderDetails={urinalysicOrderDetails}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default UrinalysisOrder;
