import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TabGroup from "src/components/TabGroup";
import QRCode from "qrcode.react";
import Img from "src/components/Img";
import InfoText from "src/components/InfoText";
import {
	calculateAge,
	formatDate,
	replaceFname,
	replaceLname,
	replaceMname,
} from "src/helpers/utils";
import CollapseContainer from "src/components/CollapseContainer";
import { useContext, useRef, useState } from "react";
import AddContactTracingModal from "./AddContactTracingModal";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";
import { Fade } from "react-reveal";

const PatientProfile = ({ patient }) => {
	return (
		<div className="flex flex-col gap-y-4 items-start">
			<h5 className="text-base font-semibold text-tertiary mb-0 border-b w-2/3">
				Peronal Information
			</h5>
			<div className="grid grid-cols-1 lg:grid-cols-12 gap-3 w-full">
				<InfoText
					icon="rr-cursor-text"
					className="col-span-3"
					title="Lastname "
					value={replaceLname(patient?.lastname)}
				/>
				<InfoText
					icon="rr-cursor-text"
					className="col-span-3"
					title="Firstname "
					value={replaceFname(patient?.firstname)}
				/>
				<InfoText
					icon="rr-cursor-text"
					className="col-span-3"
					title="Middle Name"
					value={replaceMname(patient?.middle)}
				/>
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-12 gap-3 w-full">
				<InfoText
					icon="rr-venus-mars"
					title="Gender"
					className="col-span-3"
					value={
						<>
							{patient?.gender == "Male" ? (
								<span className="text-blue-700">Male</span>
							) : (
								<span className="text-pink-700">Female</span>
							)}
						</>
					}
				/>
				<InfoText
					icon="rr-calendar-clock"
					title="Birthday"
					value={formatDate(patient?.birthday)}
					className="col-span-3"
				/>
				<InfoText
					icon="rr-following"
					title="Civil Status"
					value={patient?.civil_status}
					className="col-span-3"
				/>
				<InfoText
					icon="rr-file"
					title="Philhealth"
					value={patient?.philhealth}
					className="col-span-3"
				/>
			</div>
			<h5 className="text-base font-semibold text-tertiary mb-0 border-b w-2/3">
				Address Information
			</h5>

			<div className="grid grid-cols-1 lg:grid-cols-12 gap-3 w-full">
				<InfoText
					icon="rr-map-marker"
					title="Municipality"
					value={patient?.municipality}
					className="col-span-3"
				/>
				<InfoText
					icon="rr-map-marker"
					title="City"
					value={patient?.city}
					className="col-span-3"
				/>
				<InfoText
					icon="rr-map-marker"
					title="Barangay"
					value={patient?.barangay}
					className="col-span-3"
				/>
				<InfoText
					icon="rr-map-marker"
					title="Zone"
					value={patient?.zone}
					className="col-span-3"
				/>
				<InfoText
					icon="rr-map-marker"
					title="Street"
					value={patient?.street}
					className="col-span-3"
				/>
			</div>
			{patient?.income?.length > 0 ? (
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-3 w-full">
					<div className="table col-span-6">
						<table>
							<thead>
								<tr>
									<th>Job/Source of Income</th>
									<th className="text-right">Amount</th>
								</tr>
							</thead>
							<tbody>
								{patient?.income?.map((income) => (
									<tr>
										<td>{income?.name}</td>
										<td className="text-right">{income?.amount?.toFixed(2)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};

const ContactTracing = ({ patient, add_contact_tracing }) => {
	return (
		<div className="flex flex-col items-start">
			<h5 className="text-base font-semibold text-tertiary mb-0 pt-2 pb-3 flex items-center w-full">
				Contact Tracing Information
				<ActionBtn
					className="ml-auto"
					size="sm"
					onClick={() => {
						add_contact_tracing.current.show();
					}}
				>
					<FlatIcon icon="rr-calendar-plus" className="mr-2" />
					Add contact tracing
				</ActionBtn>
			</h5>
			<div className="table w-full rounded-xl overflow-hidden">
				<table>
					<thead>
						<tr>
							<th>Date</th>
							<th>Purpose</th>
							<th>Type</th>
							<th>Symptoms</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>October 10, 2022</td>
							<td>Check-up</td>
							<td>Cash</td>
							<td>Headache</td>
						</tr>
						<tr>
							<td>October 11, 2022</td>
							<td>Check-up</td>
							<td>Cash</td>
							<td>Toothache</td>
						</tr>
						<tr>
							<td>October 12, 2022</td>
							<td>Check-up</td>
							<td>Cash</td>
							<td>Headache</td>
						</tr>
						<tr>
							<td>October 13, 2022</td>
							<td>Check-up</td>
							<td>Cash</td>
							<td>Toothache</td>
						</tr>
						<tr>
							<td>October 14, 2022</td>
							<td>Sample</td>
							<td>Cash</td>
							<td>Headache</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

const WalkInPatient = ({ patient }) => {
	const { users } = useContext(UsersData);
	const [loading, setLoading] = useState(false);
	const handleSendQueue = () => {
		setLoading(true);
		var formdata = new FormData();
		formdata.append("user_id", users.user_id);
		formdata.append("management_id", users.management_id);
		formdata.append("main_mgmt_id", users.main_mgmt_id);
		formdata.append("patient_id", patient?.patient_id);
		formdata.append(
			"trace_number",
			`trace-${Math.floor(Math.random() * 9999)}-${new Date().getTime()}`
		);

		axios
			.post("admission/create/new-queue-to-cashier", formdata)
			.then((response) => {
				const data = response.data;
				setLoading(false);
				if (data === "success") {
					Notify.successRequest("set as done");
				} else {
					Notify.customToast("Something went wrong", "Please try again...");
				}
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	return (
		<div className="flex items-center justify-center p-11">
			<ActionBtn
				type="background"
				loading={loading}
				className="text-xl flex border border-primary !text-primary items-center justify-center"
				onClick={handleSendQueue}
			>
				<FlatIcon icon="rr-layer-plus" className="text-3xl mr-2 text-primary" />
				<span className="text-primary">
					Click send another queue to cashier for additional
				</span>
			</ActionBtn>
		</div>
	);
};
const ViewPatientProfile = ({
	patient,
	editable = true,
	className = "",
	profileDisplayClassName,
	add_patient_ref,
}) => {
	const add_contact_tracing = useRef(null);

	const { users } = useContext(UsersData);
	const downloadQRCode = () => {
		// Generate download with use canvas and stream
		const canvas = document.getElementById("qr-gen");
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = `${patient.lastname}_${patient.firstname}.png`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	return (
		<>
			<CardContainer className={`!p-0 rounded-xl ${className}`}>
				<div className="flex items-center px-4 pt-4 border-b pb-4">
					<div className="relative h-36 rounded-full overflow-hidden aspect-square bg-background mr-4">
						<Img
							type="user"
							name={`${replaceLname(patient?.lastname)}-${replaceFname(
								patient?.firstname
							)}-${replaceMname(patient?.middle)}`}
							className="w-full aspect-square object-cover"
							alt=""
						/>
					</div>
					<div className="flex flex-col pl-4">
						<h6
							className={`text-left text-2xl mb-1 font-semibold flex items-center ${
								patient?.gender == "Male" ? "text-blue-800" : "text-pink-800"
							} mb-0`}
						>
							{`${replaceLname(patient?.lastname)}, ${replaceFname(
								patient?.firstname
							)} ${replaceMname(patient?.middle)}`}
							{editable && (
								<ActionBtn
									type="primary-light"
									size="sm"
									className="ml-4"
									onClick={() => {
										add_patient_ref.current.show(patient);
									}}
								>
									<FlatIcon icon="rr-edit" className="text-lg mr-2" />
									Edit patient profile
								</ActionBtn>
							)}
						</h6>
						<div className="flex gap-6 mb-2">
							<div className="flex items-center gap-2 text-base">
								<FlatIcon icon="rr-calendar-clock" className="text-lg" />
								<span>{calculateAge(patient?.birthday)} yrs. old</span>
							</div>
							<div className="flex items-center gap-2 text-base">
								<FlatIcon icon="rr-calendar" className="text-lg" />
								<span>{formatDate(patient?.birthday)}</span>
							</div>
						</div>
						<div className="flex gap-4 mb-2">
							<div className="flex items-center gap-2 text-base">
								<FlatIcon icon="rr-venus-mars" className="text-lg" />
								{patient?.gender == "Male" ? (
									<span className="text-blue-700">Male</span>
								) : (
									<span className="text-pink-700">Female</span>
								)}
							</div>
						</div>
						{/* <div className="flex items-center gap-2 text-base">
						<FlatIcon icon="rr-map-marker" className="text-lg" />
						<span className="">{`${
							patient?.municipality ? patient?.municipality + ", " : ""
						} ${patient?.city ? patient?.city + ", " : ""} ${
							patient?.barangay ? patient?.barangay + ", " : ""
						} ${patient?.street}`}</span>
					</div> */}
					</div>
				</div>
				<div className="flex pb-4">
					<div className="px-4 border-r pt-4">
						<h6 className="text-sm font-bold mb-0 text-center !text-secondary">
							Patient QRCode
						</h6>
						<div className="flex items-center justify-center pt-1 pb-2 w-full">
							<QRCode
								id="qr-gen"
								value={JSON.stringify({
									patient_id: patient?.patient_id,
									user_id: users?.user_id,
									philhealth: patient?.philhealth,
								})}
								level="H"
								size={144}
							/>
						</div>
						<ActionBtn size="sm" onClick={downloadQRCode}>
							Download QR
						</ActionBtn>
					</div>
					<div className="xl:col-span-9 lg:col-span-8 w-full">
						<TabGroup
							contents={[
								{
									title: (
										<>
											<FlatIcon icon="rr-mode-portrait" className="mr-1" />
											Profile
										</>
									),
									content: <PatientProfile patient={patient} />,
								},
								{
									title: (
										<>
											<FlatIcon icon="rr-share" className="mr-1" />
											Contact Tracing
										</>
									),
									content: (
										<ContactTracing add_contact_tracing={add_contact_tracing} />
									),
								},
								{
									title: (
										<>
											<FlatIcon icon="rr-running" className="mr-1" />
											Walk-In additional
										</>
									),
									content: <WalkInPatient />,
								},
							]}
						/>
					</div>
				</div>
			</CardContainer>
			<AddContactTracingModal ref={add_contact_tracing} />
		</>
	);
};

export default ViewPatientProfile;
