import { Popover, Menu, Transition } from "@headlessui/react";
import { TextField } from "@material-ui/core";
import axios from "axios";
import {
	forwardRef,
	Fragment,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useForm } from "react-hook-form";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import ActionBtn from "src/components/ActionBtn";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import Notify from "src/notification/Notify";

const AddConsultationModal = (props, ref) => {
	const { callBackProp, clinic, doctors, patient } = props;
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm();
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (showData) => {
		if (showData) {
			setData(showData);
			if (showData?.doctor?.id) {
				setValue("doctor_id", showData?.doctor?.id);
			}

			if (showData?.purpose) {
				setValue("purpose", showData?.purpose);
			}

			if (showData?.room_number) {
				setValue("room_number", showData?.room_number);
			}
		} else {
			setData(null);
		}
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};
	const submit = (formData) => {
		setLoading(true);
		if (data?.id) {
			axios
				.post(`v1/clinic-receiving`, {
					appointment_id: data?.id,
					purpose: formData?.purpose,
					doctor_id: formData?.doctor_id,
					clinic_id: 1,
					patient_id: patient?.id,
					purpose: formData?.purpose,
					type_service: formData?.type_service,
					room_number: formData?.room_number,
				})
				.then((res) => {
					setTimeout(() => {
						toast.success("Success! Patient added to queue.");
					}, 100);
					hide();
				})
				.catch(() => {
					setTimeout(() => {
						toast.error("Error! Patient already in queue.");
					}, 100);
					hide();
				})
				.finally(() => {
					if (typeof callBackProp == "function") {
						callBackProp();
					}
					setLoading(false);
				});
		} else {
			axios
				.post(`v1/clinic-receiving`, {
					purpose: formData?.purpose,
					doctor_id: formData?.doctor_id,
					clinic_id: 1,
					patient_id: patient?.id,
					purpose: formData?.purpose,
					type_service: formData?.type_service,
					room_number: formData?.room_number,
				})
				.then((res) => {
					setTimeout(() => {
						toast.success("Success! Patient added to queue.");
					}, 100);
					hide();
				})
				.catch(() => {
					setTimeout(() => {
						toast.error("Error! Patient already in queue.");
					}, 100);
					hide();
				})
				.finally(() => {
					if (typeof callBackProp == "function") {
						callBackProp();
					}
					setLoading(false);
				});
		}
	};

	return (
		<Modal
			open={open}
			className={`w-[512px] min-w-[512px] max-w-[512px]`}
			staticModal={true}
		>
			<ModalHeader
				title="Add patient to queue"
				subtitle={
					"Patient will be added directly to the queue for today"
				}
				hide={hide}
			/>
			<ModalBody className={`p- bg-slate-50 !pb-6`}>
				<div className="px-4 pt-4 pb-0 flex flex-col relative gap-y-6">
					<TextField
						label={
							<>
								Select Doctor
								<b className="text-red-500 ml-1">*</b>
							</>
						}
						variant="outlined"
						{...register("doctor_id", {
							required: true,
						})}
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						error={errors?.purpose}
						helperText={errors?.purpose && "This field is required"}
						select
					>
						<option value="" disabled selected>
							Select doctor
						</option>
						{doctors?.map((doctor) => {
							return (
								<option value={doctor?.id}>
									{doctor?.name}
								</option>
							);
						})}
					</TextField>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
						<TextField
							label={
								<>
									Room Number
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("room_number", {
								required: true,
							})}
							InputLabelProps={{ shrink: true }}
							error={errors?.room_number}
							helperText={
								errors?.room_number && "This field is required"
							}
						/>
						<TextField
							label={
								<>
									Type of service
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("type_service", {
								required: true,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.type_service}
							helperText={
								errors?.type_service && "This field is required"
							}
							select
						>
							<option>Consultation</option>
							<option>Physical</option>
							<option>Medical Certificate</option>
						</TextField>
					</div>

					<TextField
						label={
							<>
								Purpose
								<b className="text-red-500 ml-1">*</b>
							</>
						}
						variant="outlined"
						{...register("purpose", {
							required: true,
						})}
						InputLabelProps={{ shrink: true }}
						error={errors?.purpose}
						helperText={errors?.purpose && "This field is required"}
					/>
				</div>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<ActionBtn
					type="success"
					onClick={handleSubmit(submit)}
					loading={loading}
				>
					Add patient to queue
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(AddConsultationModal);
