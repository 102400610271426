import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  Box,
  List,
  TextField,
  Avatar,
  ListItem,
  TablePagination,
  ListItemText,
  makeStyles,
  ListItemIcon,
} from "@material-ui/core";
import Container from "../../layout/Container";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import Notify from "src/notification/Notify";
import { Search } from "react-feather";
import Details from "./Details";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const DischargedPatient = () => {
  const classes = useStyles();

  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [patientList, setPatientList] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [search, setSearch] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const getDischargedPatientsForBillout = React.useCallback(async () => {
    axios
      .get(`billing/discharged/patient/get-patientlist-groupby-patientid`, {
        params: {
          management_id: users.management_id,
          main_mgmt_id: users.main_mgmt_id,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setPatientList(data);
        }
      })
      .catch((err) => console.log(`error:`, err.message))
      .finally(() => setIsLoading(false));
  }, [users, mounted]);

  useEffect(() => {
    getDischargedPatientsForBillout();
  }, [getDischargedPatientsForBillout]);

  const searchable = patientList.filter((data) => {
    return (
      data.fname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Discharged",
          items: [{ name: "Dashboard", path: "/sph/app" }],
        }}
        title="Discharged Patient List"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={5} lg={4}>
            <Card>
              <CardHeader title={`Discharged Patient`} />

              <CardContent>
                <List component="div">
                  <Box mb={2}>
                    <TextField
                      label="Search Patient"
                      variant="outlined"
                      fullWidth
                      // margin="dense"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  <Box>
                    {!isLoading
                      ? searchable.length > 0
                        ? searchable
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((data, index) => (
                              <ListItem
                                key={index}
                                button
                                className="mb-2"
                                onClick={() => {
                                  setSelectedPatient(data);
                                }}
                                selected={
                                  selectedPatient?.patient_id ===
                                  data.patient_id
                                }
                              >
                                <ListItemIcon>
                                  {data.image === null ? (
                                    <Avatar className={classes.primary}>
                                      {data.fname.charAt().toUpperCase()}
                                    </Avatar>
                                  ) : (
                                    <Avatar
                                      className={classes.primary}
                                      src={`${imageLocation}patients/${data.image}`}
                                      alt=""
                                    />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  className={`gtc-capitalize`}
                                  primary={`${data.lname}, ${data.fname} ${
                                    Boolean(data.mname) ? data.mname : ``
                                  } `}
                                />
                              </ListItem>
                            ))
                        : Notify.noRecord()
                      : Notify.loading()}
                  </Box>
                </List>

                <TablePagination
                  component="div"
                  count={searchable.length}
                  rowsPerPageOptions={[
                    10,
                    50,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="List"
                />
              </CardContent>
            </Card>
          </Grid>

          {selectedPatient && (
            <Grid item xs={12} md={7} lg={8}>
              <Details details={selectedPatient} />
            </Grid>
          )}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default DischargedPatient;
