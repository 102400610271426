import React, { Fragment, useState } from "react";
import Container from "src/layout/Container";
import {
	Grid,
	Paper,
	Box,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TablePagination,
	Chip,
	TextField,
	Typography,
	InputAdornment,
	IconButton,
	Menu,
	MenuItem,
	Dialog,
	Button,
	FormHelperText,
	Divider,
	DialogContent,
	Zoom,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import HospitalAddAccount from "./HospitalAddAccount";
import { Search } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Formik } from "formik";
import * as Yup from "yup";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const HIMSHospAccounts = () => {
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState("");
	const { users } = React.useContext(UsersData);
	const [userList, setUserList] = useState({ data: [], ready: false });
	const [anchorEl, setAnchorEl] = React.useState(null);

	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(null);

	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedDailyRate, setSelectedDailyRate] = useState(null);
	const [selectedMonthRate, setSelectedMonthRate] = useState(null);

	const [editRateDiag, setEditRateDiag] = useState(false);
	const [viewRateDiag, setViewRateDiag] = useState(false);

	const handleSelectedAction = (event, data) => {
		setAnchorEl(event.currentTarget);
		setSelectedEmployee(data.user_id);
		setSelectedStatus(data.status);

		setSelectedCategory(data.employee_class);
		setSelectedDailyRate(data.daily_salary);
		setSelectedMonthRate(data.monthly_salary);
	};

	const handleCloseAction = () => {
		setAnchorEl(null);
		setSelectedEmployee(null);
		setSelectedStatus(null);

		setSelectedCategory(null);
		setSelectedDailyRate(null);
		setSelectedMonthRate(null);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const getFormData = (object) => {
		const formData = new FormData();
		Object.keys(object).forEach((key) => formData.append(key, object[key]));
		return formData;
	};

	const getAllUsersAccount = React.useCallback(() => {
		var params = { user_id: users.user_id, management_id: users.management_id };
		axios
			.get("hims/get/all-users-account", { params })
			.then((response) => {
				const data = response.data;
				setUserList({ data: data, ready: true });
			})
			.catch((err) => {
				Notify.requestError(err);
			});
	}, [users]);

	const handleInactiveAccount = (e) => {
		e.preventDefault();
		e.persist();
		var formdata = new FormData();
		formdata.set("users_id", selectedEmployee);
		formdata.set("status", selectedStatus);
		formdata.set("management_id", users.management_id);

		axios
			.post("hmis/update/account-inactive", formdata)
			.then((response) => {
				const data = response.data;
				if (data === "success") {
					getAllUsersAccount();
					Notify.successRequest("update");
					handleCloseAction();
				}
			})
			.catch((err) => {
				console.log("error : ", err);
				// Notify.requestError(err);
			});
	};

	React.useEffect(() => {
		getAllUsersAccount();

		// eslint-disable-next-line
	}, [getAllUsersAccount]);

	const searchable = userList.data.filter((data) => {
		return data._account_name.toLowerCase().indexOf(search.trim()) !== -1;
	});

	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: true,
					current: "hospital accounts",
					items: [{ name: "Dashboard", path: "/sph/app" }],
				}}
				title="Hospital Accounts"
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={8}>
						<Box component={Paper} variant="outlined">
							<Box display="flex" p={2}>
								<Box flexGrow={1} />
								<Box>
									<TextField
										label="Search name"
										variant="outlined"
										value={search}
										onChange={(e) => setSearch(e.target.value)}
										margin="dense"
										InputProps={{
											endAdornment: (
												<InputAdornment>
													<Search />
												</InputAdornment>
											),
										}}
									/>
								</Box>
							</Box>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell
												align="center"
												style={{ fontWeight: "bolder" }}
											>
												Name
											</TableCell>

											<TableCell
												align="center"
												style={{ fontWeight: "bolder" }}
											>
												Department
											</TableCell>

											<TableCell
												align="center"
												style={{ fontWeight: "bolder" }}
											>
												Status
											</TableCell>

											<TableCell
												align="center"
												style={{ fontWeight: "bolder" }}
											>
												Action
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{userList.ready ? (
											searchable.length > 0 ? (
												searchable
													.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage
													)
													.map((data, index) => (
														<TableRow key={index}>
															<TableCell>{data._account_name}</TableCell>
															<TableCell align="center">{data.type}</TableCell>
															<TableCell align="center">
																<Chip
																	size="small"
																	label={
																		parseFloat(data.status) === 1
																			? "Active"
																			: "Inactive"
																	}
																	style={{
																		backgroundColor:
																			parseFloat(data.status) === 1
																				? "green"
																				: "red",
																		color: "#fff",
																		cursor: "pointer",
																	}}
																/>
															</TableCell>
															<TableCell align="center">
																<IconButton
																	onClick={(e) => handleSelectedAction(e, data)}
																>
																	<MoreVertIcon fontSize="small" />
																</IconButton>
																<Menu
																	id="simple-menu"
																	anchorEl={anchorEl}
																	keepMounted
																	open={Boolean(anchorEl)}
																	onClose={handleCloseAction}
																>
																	<MenuItem onClick={handleInactiveAccount}>
																		Disable/Enable
																	</MenuItem>
																	<MenuItem
																		onClick={() => setEditRateDiag(true)}
																	>
																		Edit Rate
																	</MenuItem>
																	<MenuItem
																		onClick={() => setViewRateDiag(true)}
																	>
																		Rate Info
																	</MenuItem>
																</Menu>
															</TableCell>
														</TableRow>
													))
											) : (
												<TableRow>
													<TableCell colSpan={7} align="center">
														<Typography variant="subtitle2" color="secondary">
															{" "}
															No accounts added{" "}
														</Typography>
													</TableCell>
												</TableRow>
											)
										) : (
											<TableRow>
												<TableCell colSpan={7} align="center">
													<Typography variant="subtitle2" color="primary">
														{" "}
														Loading...{" "}
													</Typography>
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								component={"div"}
								rowsPerPageOptions={[5, 10, 25]}
								colSpan={3}
								count={searchable.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} sm={4}>
						<HospitalAddAccount
							getAllUsersAccount={() => getAllUsersAccount()}
						/>
					</Grid>
				</Grid>
			</Container>

			<Dialog
				open={editRateDiag}
				onClose={() => setEditRateDiag(false)}
				TransitionComponent={Zoom}
				transitionDuration={800}
			>
				<DialogContent>
					<Formik
						initialValues={{
							username: users.username,
							user_id: users.user_id,
							management_id: users.management_id,

							rate_classification: selectedCategory,
							monthly_salary: selectedMonthRate,
							daily_salary: selectedDailyRate,
							users_user_id: selectedEmployee,

							password: "",
						}}
						validationSchema={Yup.object().shape({
							rate_classification: Yup.string()
								.trim()
								.required("Rate classification is required"),

							password: Yup.string().required("Your password is required"),
						})}
						onSubmit={async (
							values,
							{ setErrors, setStatus, setSubmitting, resetForm }
						) => {
							var error = [];
							if (
								values.rate_classification === "Daily" &&
								values.daily_salary.length === 0
							) {
								error = "error";
								setErrors({ daily_salary: "Daily Salary is required." });
							}
							if (
								values.rate_classification === "Monthly" &&
								values.monthly_salary.length === 0
							) {
								error = "error";
								setErrors({ monthly_salary: "Monthly Salary is required." });
							}
							if (error.length > 0) {
								Notify.consoleLog("form error.");
							} else {
								try {
									const request = await axios.post(
										"hmis/update/account-rate-class",
										getFormData(values)
									);
									if (request.data === "pass-invalid") {
										setErrors({ password: "Password is invalid." });
										Notify.fieldInvalid("password");
									}
									if (request.data === "success") {
										Notify.successRequest("add new imaging test");
										resetForm();
										setSubmitting(true);
										getAllUsersAccount();
										setEditRateDiag(false);
									}
								} catch (error) {
									const message = error.message || "Something went wrong";
									setErrors({ submit: message });
									setSubmitting(false);
								}
							}
						}}
					>
						{({
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
							isSubmitting,
							setFieldValue,
							touched,
							values,
						}) => (
							<form noValidate onSubmit={handleSubmit}>
								<Box mb={2}>
									<Typography color="textPrimary" variant="h6">
										EDIT RATE CLASSIFICATION
									</Typography>
								</Box>

								<Divider />

								<Box mb={2} className={`labselect2`}>
									<TextField
										error={Boolean(
											touched.rate_classification && errors.rate_classification
										)}
										helperText={
											touched.rate_classification && errors.rate_classification
										}
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.rate_classification}
										fullWidth
										required
										name="rate_classification"
										label="Rate Classification"
										variant="outlined"
										select
										SelectProps={{
											native: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
									>
										<option value={""}>Select</option>
										<option value={"Daily"}>Daily</option>
										<option value={"Monthly"}>Monthly</option>
									</TextField>
								</Box>

								{values.rate_classification === "Daily" && (
									<Box mb={2} className={`labselect2`}>
										<TextField
											error={Boolean(
												touched.daily_salary && errors.daily_salary
											)}
											helperText={touched.daily_salary && errors.daily_salary}
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.daily_salary}
											fullWidth
											required
											name="daily_salary"
											label="Daily Salary"
											variant="outlined"
											type="number"
											InputProps={{
												inputProps: {
													min: 1,
													max: 100000000000,
													step: 0.01,
												},
											}}
										/>
									</Box>
								)}

								{values.rate_classification === "Monthly" && (
									<Box mb={2} className={`labselect2`}>
										<TextField
											error={Boolean(
												touched.monthly_salary && errors.monthly_salary
											)}
											helperText={
												touched.monthly_salary && errors.monthly_salary
											}
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.monthly_salary}
											fullWidth
											required
											name="monthly_salary"
											label="Monthly Salary"
											variant="outlined"
											type="number"
											InputProps={{
												inputProps: {
													min: 1,
													max: 100000000000,
													step: 0.01,
												},
											}}
										/>
									</Box>
								)}

								<Box mb={2}>
									<TextField
										fullWidth
										required
										error={Boolean(touched.password && errors.password)}
										helperText={touched.password && errors.password}
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.password}
										name="password"
										label="Enter your password"
										variant="outlined"
										type="password"
									/>
								</Box>

								{errors.submit && (
									<Box mt={3}>
										<FormHelperText error>{errors.submit}</FormHelperText>
									</Box>
								)}

								<Divider />

								<Box my={2} display="flex">
									<Box flexGrow={1} />

									<Button
										variant="contained"
										color="default"
										startIcon={<ClearIcon />}
										onClick={() => setEditRateDiag(false)}
									>
										Close
									</Button>

									<Box ml={2}>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											startIcon={<CheckIcon />}
											disabled={isSubmitting}
										>
											Save
										</Button>
									</Box>
								</Box>
							</form>
						)}
					</Formik>
				</DialogContent>
			</Dialog>
			<Dialog
				open={viewRateDiag}
				onClose={() => setViewRateDiag(false)}
				TransitionComponent={Zoom}
				transitionDuration={800}
			>
				<DialogContent>
					<Box mb={2}>
						<Typography color="textPrimary" variant="h6">
							VIEW RATE INFO
						</Typography>
					</Box>
					<Box mb={2}>
						<TextField
							fullWidth
							value={selectedCategory}
							label=""
							variant="outlined"
							InputProps={{
								readOnly: true,
							}}
						/>
					</Box>
					{selectedCategory === "Monthly" ? (
						<Box mb={2}>
							<TextField
								fullWidth
								value={Notify.convertToNumber(selectedMonthRate)}
								label=""
								variant="outlined"
								InputProps={{
									readOnly: true,
								}}
							/>
						</Box>
					) : (
						<Box mb={2}>
							<TextField
								fullWidth
								value={Notify.convertToNumber(selectedDailyRate)}
								label=""
								variant="outlined"
								InputProps={{
									readOnly: true,
								}}
							/>
						</Box>
					)}
				</DialogContent>
			</Dialog>
		</Fragment>
	);
};

export default HIMSHospAccounts;
