import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import SelectItemsSelector from "./SelectItemsSelector";
import Img from "src/components/Img";
import { UsersData } from "src/ContextAPI";
import {
	dataURItoBlob,
	dateYYYYMMDD,
	formatDateMMDDYYYYHHIIA,
} from "src/helpers/utils";
import ItemServiceList from "./ItemServiceList";
import SelectInputField from "src/components/forms/SelectInputField";
import SelectToUseItems from "./SelectToUseItems";

const symptoms = [
	{
		value: "cough_for_3_weeks_or_longer",
		label: "Cough for three weeks or longer",
	},
	{
		value: "coughing_up_blood_or_mucus",
		label: "Coughing up blood or mucus",
	},
	{ value: "chest_pain", label: "Chest pain" },
	{
		value: "pain_with_breathing_or_coughing",
		label: "Pain with breathing or coughing",
	},
	{ value: "fever", label: "Fever" },
	{ value: "chills", label: "Chills" },
	{ value: "night_sweats", label: "Night sweats" },
	{ value: "weight_loss", label: "Weight loss" },
	{ value: "not_wanting_to_eat", label: "Not wanting to eat" },
	{ value: "tiredness", label: "Tiredness" },
	{
		value: "not_feeling_well_in_general",
		label: "Not feeling well in general",
	},
];
const CreateLabOrderModal = (props, ref) => {
	const { patient, refresh, onSuccess } = props;
	const { users } = useContext(UsersData);
	const { register: register1, watch: watch1 } = useForm();
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		trigger,
		control,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(0);
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const [refreshKey, setRefreshKey] = useState(1);
	const [selected, setSelected] = useState(null);
	const [selectedRHU, setSelectedRHU] = useState(null);
	const [selectedClick, setSelectedClick] = useState(null);
	const [isPositive, setIsPositive] = useState(false);
	const [itemsUsed, setItemsUsed] = useState(false);
	const [items, setItems] = useState([]);

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};

	const submitCreateLabOrder = (data) => {
		axios
			.post(`/v1/clinic/tb-${data?.lab_order}/${appointment?.id}`, {
				_method: "PATCH",
				lab_order: data?.lab_order,
			})
			.then((res) => {
				setTimeout(() => {
					onSuccess();
					toast.success(`Patient laboratory order created!`);
				}, 200);
				refresh();
				hide();
				// setIsPositive(true);
			});
	};
	return (
		<Modal
			open={open}
			staticModal={true}
			size="md"
			hide={hide}
			className=" max-w-[512px]"
		>
			<ModalHeader
				title={`Create Laboratory Order`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="grid grid-cols-1 gap-4">
					<div className="flex flex-col">
						<label className="text-sm">
							Select Laboratory Test
						</label>
						<select
							className={`border py-2.5 border-transparent text-sm text-dark sm:text-sm rounded-lg focus:ring-1 focus:shadow-lg duration-100 shadow-blue-300 focus:ring-blue-500 block w-full p-2.5 ${
								errors?.lab_order?.message
									? "border-red-500 text-red-500"
									: ""
							}`}
							{...register("lab_order", {
								required: "This field is required.",
							})}
						>
							<option value="" selected disabled>
								Select laboratory test
							</option>
							<option value={"xray-order"}>X-Ray</option>
							<option value={"lab-order"}>Laboratory Test</option>
						</select>
						{errors?.lab_order?.message ? (
							<span className="text-danger text-sm">
								{errors?.lab_order?.message}
							</span>
						) : (
							""
						)}
					</div>
					{/* <SelectInputField
						labelClassName="font-medium"
						label="Select Laboratory Test"
						placeholder="Select"
						className="!mb-0"
						value={selected}
						// disabled={!isPositive}
						{...register("lab_order", {
							required: "This field is required.",
						})}
						error={errors?.lab_order?.message}
						onChange={(e) => {
							// console.log("setSelected", data);
							setSelected(e.target.value);
						}}
						options={[
							{
								label: `X-Ray`,
								value: "xray-order",
							},
							{
								label: `Laboratory Test`,
								value: "lab-order",
							},
						]}
					/> */}
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center justify-between"}>
				<>
					<ActionBtn className="w-1/3" type="danger" onClick={hide}>
						<FlatIcon icon="rr-cross-small" />
						Cancel
					</ActionBtn>
					<ActionBtn
						className={`ml-auto`}
						type="success"
						onClick={handleSubmit(submitCreateLabOrder)}
					>
						<FlatIcon icon="rr-disk" />
						Submit
					</ActionBtn>
				</>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(CreateLabOrderModal);
