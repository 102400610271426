import { useEffect, useMemo, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import HealthcareProfessionals from "./HealthcareProfessionals";
import {
	dateMMDDYYYY,
	dateYYYYMMDD,
	isEvenNumber,
	tConvert,
	timeHHII,
	formatDate,
} from "src/helpers/utils";
import axios from "axios";
import { useClinicContext } from "src/clinic/context/ClinicContext";

const HistoryCard = ({ data }) => {
	console.log("HistoryCard", data);

	const doctors = data?.relationships?.healthcare_professionals || [];
	return (
		<div className="block">
			<div className="flex justify-between mb-4">
				<span className="font-bold duration-300 transition ease-in-out text-sm">
					{formatDate(data?.date)}
				</span>
				<span>
					{tConvert(data?.time)} -{tConvert(data?.time, 2)}
				</span>
			</div>

			<h5 className="w-full font-bold text-orange-600 hover:text-orange-700 focus:text-orange-800 duration-300 transition ease-in-out text-sm mb-1">
				{data?.relationships?.room?.name} - [
				{data?.relationships?.room?.type}]
			</h5>
			<h5 className="w-full font-bold text-indigo-600 hover:text-indigo-700 focus:text-indigo-800 duration-300 transition ease-in-out text-sm">
				{data?.procedure}
			</h5>
			<span className="font-medium text-blue-600 w-full hover:text-blue-700 focus:text-blue-800 duration-300 transition ease-in-out text-sm">
				Healthcare Professionals
			</span>
			<HealthcareProfessionals doctors={doctors} />
			{/* <button
				type="button"
				className="inline-block px-3.5 py-1 border-2 border-purple-600 text-purple-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
				data-mdb-ripple="true"
			>
				ADMIT patient
			</button>
			<button
				type="button"
				className="inline-block px-3.5 py-1 border-2 border-purple-600 text-purple-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 ml-4 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
				data-mdb-ripple="true"
			>
				OPD patient
			</button> */}
		</div>
	);
};
const PatientChartsHistory = ({ type, room_id, patient_id }) => {
	const { clinic } = useClinicContext();
	console.log("patient_id", patient_id);
	const [data, setData] = useState([
		// {
		// 	date: "June 21, 2023",
		// 	procedure: "Appendicitis",
		// 	doctors: [
		// 		{
		// 			type: "Surgeon",
		// 			name: "Dr. Lui, Cynthia",
		// 		},
		// 		{
		// 			type: "Anesthesiologist",
		// 			name: "Dr. Gaurino, John",
		// 		},
		// 	],
		// },
		// {
		// 	date: "June 21, 2023",
		// 	procedure: "Appendicitis",
		// 	doctors: [
		// 		{
		// 			type: "Surgeon",
		// 			name: "Dr. Lui, Cynthia",
		// 		},
		// 		{
		// 			type: "Anesthesiologist",
		// 			name: "Dr. Gaurino, John",
		// 		},
		// 	],
		// },
		// {
		// 	date: "June 21, 2023",
		// 	procedure: "Appendicitis",
		// 	doctors: [
		// 		{
		// 			type: "Surgeon",
		// 			name: "Dr. Lui, Cynthia",
		// 		},
		// 		{
		// 			type: "Anesthesiologist",
		// 			name: "Dr. Gaurino, John",
		// 		},
		// 	],
		// },
	]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		let t = setTimeout(() => {
			if (patient_id > 0) {
				setData({
					loading: true,
					data: [],
				});
				getPatients(setData);
			}
		}, 300);
		return () => {
			clearTimeout(t);
		};
	}, [type, patient_id]);

	const getPatients = (setter) => {
		axios
			.get(
				`/v1/operating-room-chart/list?patient_id=${patient_id}&time_from=00:00&time_to=24:00`
			)
			.then((res) => {
				console.log("resssss", res.data.data);
				setter({
					loading: false,
					data: res.data.data || [],
				});
			});
	};
	return (
		<div className="">
			<div className="container mx-auto w-full h-full">
				<div className="relative wrap overflow-hidden p-10 h-full">
					<div
						className="border-2-2 absolute border-opacity-20 border-gray-700 h-full border"
						style={{ left: "50%" }}
					/>
					{data?.data?.map((item, index) => {
						let i = index + 1;
						return (
							<div
								className={`mb-8 flex justify-between items-center w-full ${
									isEvenNumber(i)
										? "right-timeline"
										: " flex-row-reverse left-timeline"
								}`}
							>
								<div className="order-1 w-5/12" />
								<div className="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
									<h1 className="mx-auto font-semibold text-lg text-white !mb-0">
										{i}
									</h1>
								</div>
								<div className="order-1 rounded-lg shadow-xl w-5/12 px-6 py-4 bg-slate-50">
									<HistoryCard data={item} />
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default PatientChartsHistory;
