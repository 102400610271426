import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TextField,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TableContainer,
} from "@material-ui/core";
import FormHeader from "./FormHeader";
import { useReactToPrint } from "react-to-print";
import { XCircle, CheckSquare, Printer, Edit } from "react-feather";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
// import moment from "moment";
// import { DateTimePicker } from "@material-ui/pickers";
import {
  AddCircleOutline,
  Cancel,
  BorderColor,
  Delete,
  Add,
} from "@material-ui/icons";

const FormTwentyFive = ({ patient_id, trace_number, info, allowEdit }) => {
  const [isProcess, setIsProcess] = useState(false);
  // const [notesDate, setNotesDate] = useState(null);
  const mounted = IsMountedRef();
  // const [newOpen, setNewOpen] = useState(false);
  const printableRef = React.useRef();
  const [isprocessCancel, setIsProcessCancel] = React.useState(false);
  const [enableEdit, setEnableEdit] = React.useState(false);

  const [_inputNewBedsideNote, setInputNewBedsideNote] = React.useState({
    data: [],
    ready: true,
  });

  const { users } = React.useContext(UsersData);
  const [addToTable, setAddToTable] = React.useState(false);

  const [cancelOrder, setCancelOrder] = React.useState({
    data: null,
    dialog: false,
  });

  const [editOrder, setEditOrder] = React.useState({
    data: null,
    dialog: false,
  });

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const [bedsideNotes, setBedsideNotes] = useState([]);
  const [bedsideNotesTable, setBedsideNotesTable] = useState([]);
  const [cnb_id, setcnb_id] = useState(null);

  // const handleNewBediseNotes = (e) => {
  //   e.preventDefault();
  //   e.persist();

  //   var form = new FormData(e.target);
  //   form.append("user_id", users.user_id);
  //   form.append("main_mgmt_id", users.main_mgmt_id);
  //   form.append("management_id", users.management_id);
  //   form.append("patient_id", patient_id);
  //   form.append("trace_number", trace_number);

  //   form.append(
  //     "notes_datetime",
  //     moment(notesDate).format("YYYY-MM-DD HH:mm:ss")
  //   );

  //   let err = [];

  //   if (form.get("notes_datetime") === null) {
  //     err = "error";
  //     Notify.fieldRequired("date");
  //   }

  //   if (err.length > 0) {
  //     console.log("form has an error");
  //   } else {
  //     setIsProcess(true);
  //     axios
  //       .post("or/charts/patient/patient-bedsidenotes", form)
  //       .then((response) => {
  //         let data = response.data;

  //         if (data.message === "success") {
  //           setNewOpen(false);
  //           getCurrentBill();
  //           Notify.successRequest("chart updated");
  //         }
  //       })
  //       .catch((err) => console.log(err.message))
  //       .finally(() => setIsProcess(false));
  //   }
  // };

  const handleSubmitNewBedsideNotes = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    formdata.set("status", cnb_id === null ? "for-new" : "for-update");
    formdata.set("cnb_id", cnb_id);

    setIsProcess(true);
    axios
      .post("or/create/patient/patient-bedsidenotes", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          getCurrentBill();
          getCurrentBillTable();
          e.target.reset();
          Notify.successRequest("add data");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  const getCurrentBill = React.useCallback(() => {
    axios
      .get("or/charts/patient/patient-getbedsidenotes", {
        params: {
          patient_id: patient_id,
          trace_number: trace_number,
        },
      })
      .then((response) => {
        let data = response.data;
        if (mounted.current) {
          setBedsideNotes(data);
          setcnb_id(data[0].cnb_id);
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsProcess(false));
  }, [trace_number, patient_id, mounted]);

  const getCurrentBillTable = React.useCallback(() => {
    axios
      .get("or/charts/patient/patient-getbedsidenotestable", {
        params: {
          patient_id: patient_id,
          trace_number: trace_number,
        },
      })
      .then((response) => {
        let data = response.data;
        if (mounted.current) {
          setBedsideNotesTable(data);
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsProcess(false));
  }, [trace_number, patient_id, mounted]);

  const handleRemoveNote = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);

    setIsProcessCancel(true);
    axios
      .post("or/remove/chart/bedsidenote", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setCancelOrder({ data: null, dialog: false });
          getCurrentBillTable();
          Notify.successRequest("note cancel");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  const handleEditNote = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);

    setIsProcessCancel(true);
    axios
      .post("or/edit/chart/bedsidenote", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setEditOrder({ data: null, dialog: false });
          getCurrentBillTable();
          Notify.successRequest("note edit");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  const addField = () => {
    setInputNewBedsideNote({
      data: _inputNewBedsideNote.data.concat({
        notes_datetime: "notes_datetime[]",
        notes: "notes[]",
      }),
      ready: true,
    });
  };

  const removeField = (index, length) => {
    const list = _inputNewBedsideNote.data;
    setInputNewBedsideNote({
      ..._inputNewBedsideNote,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputNewBedsideNote({
      data: list,
      ready: true,
    });
  };

  const handleAddNote = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("cnb_id", cnb_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);
    formdata.set("trace_number", trace_number);

    setIsProcessCancel(true);
    axios
      .post("or/create/chart/bedsidenotestable", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setAddToTable(false);
          getCurrentBillTable();
          Notify.successRequest("note created");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  useEffect(() => {
    getCurrentBill();
    getCurrentBillTable();
  }, [patient_id, getCurrentBill, getCurrentBillTable]);

  return (
    <div>
      <Card ref={printableRef}>
        <CardContent>
          <Box>
            <FormHeader />
          </Box>
          <Box>
            <form onSubmit={handleSubmitNewBedsideNotes}>
              <Box my={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          disabled={isProcess}
                          color="primary"
                          startIcon={<CheckSquare />}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            SET
                          </Button>
                        </Box>
                      )}
                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              <Box mb={4}>
                <Box my={1}>
                  <Typography align="center">
                    <b> NURSES BEDSIDE NOTES </b>
                  </Typography>
                </Box>

                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box>
                        <Typography variant="subtitle1">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Patient Name:
                          </span>{" "}
                        </Typography>

                        {!enableEdit && (
                          <Typography variant="subtitle2">
                            {info
                              ? `${info.lastname}, ${info.firstname} ${
                                  info.middle === null ? "" : info.middle
                                }`
                              : ""}
                          </Typography>
                        )}

                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="patient_name"
                              defaultValue={
                                info
                                  ? `${info.lastname}, ${info.firstname} ${
                                      info.middle === null ? "" : info.middle
                                    }`
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box>
                        <Typography variant="subtitle1">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Attending Physician:
                          </span>{" "}
                        </Typography>

                        {!enableEdit && (
                          <Typography variant="subtitle2">
                            {!enableEdit
                              ? bedsideNotes.length > 0
                                ? bedsideNotes[0].attending_physician
                                : ""
                              : null}
                          </Typography>
                        )}

                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="attending_physician"
                              defaultValue={
                                bedsideNotes.length > 0
                                  ? bedsideNotes[0].attending_physician
                                  : ""
                              }
                            />
                          </>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      {/* <Box>
                        <Typography> Case No : </Typography>
                      </Box> */}
                      <Box>
                        <Typography variant="subtitle1">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Case No:
                          </span>{" "}
                        </Typography>

                        {!enableEdit && (
                          <Typography variant="subtitle2">
                            {!enableEdit
                              ? bedsideNotes.length > 0
                                ? bedsideNotes[0].case_no
                                : ""
                              : null}
                          </Typography>
                        )}

                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="case_no"
                              defaultValue={
                                bedsideNotes.length > 0
                                  ? bedsideNotes[0].case_no
                                  : ""
                              }
                            />
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box align="center" mb={2}>
                  <Box display={"flex"} hidden={!enableEdit}>
                    <Box flexGrow={1} />
                    <Box mx={1}>
                      <Button
                        color="primary"
                        onClick={addField}
                        startIcon={<AddCircleOutline />}
                      >
                        ADD
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        color="secondary"
                        onClick={
                          _inputNewBedsideNote.data.length > 0
                            ? () =>
                                removeField(1, _inputNewBedsideNote.data.length)
                            : (e) => e.preventDefault()
                        }
                        startIcon={<Cancel />}
                      >
                        REMOVE
                      </Button>
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Box
                    display={"flex"}
                    className="d-print-none"
                    hidden={enableEdit}
                  >
                    <Box flexGrow={1} />
                    <Box>
                      <IconButton
                        color={"primary"}
                        onClick={() => setAddToTable(true)}
                      >
                        <Add fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table size="small" className="table-bordered">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <strong>DATE</strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong>TIME</strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong>
                              Please fill all notations <br />
                              <Typography variant="caption">
                                (Notes should be informative and meaningful to
                                patient total case)
                              </Typography>
                            </strong>
                          </TableCell>
                          <TableCell
                            align="center"
                            className="d-print-none"
                            hidden={enableEdit}
                          >
                            <strong>ACTION</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {enableEdit &&
                          _inputNewBedsideNote.ready &&
                          _inputNewBedsideNote.data.map((data, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell colSpan={2}>
                                  <TextField
                                    name={data.notes_datetime}
                                    type="datetime-local"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    InputProps={{
                                      disableUnderline: !enableEdit,
                                      disabled: !enableEdit,
                                    }}
                                    fullWidth
                                    required
                                  />
                                </TableCell>
                                <TableCell colSpan={2}>
                                  <TextField
                                    fullWidth
                                    name={data.notes}
                                    InputProps={{
                                      disableUnderline: !enableEdit,
                                      disabled: !enableEdit,
                                    }}
                                    multiline
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}

                        {!enableEdit &&
                          bedsideNotesTable.length > 0 &&
                          bedsideNotesTable.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {`${Notify.createdAt(data.notes_datetime)}`}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="subtitle2">
                                  {`${Notify.createdTime(data.notes_datetime)}`}
                                </Typography>
                              </TableCell>
                              <TableCell>{data.notes}</TableCell>
                              <TableCell
                                align="center"
                                className="d-print-none"
                              >
                                <Box display={"flex"} justifyContent={"center"}>
                                  <Box>
                                    <IconButton
                                      color={"primary"}
                                      onClick={() =>
                                        setEditOrder({
                                          data: data,
                                          dialog: true,
                                        })
                                      }
                                    >
                                      <BorderColor fontSize={"small"} />
                                    </IconButton>
                                  </Box>
                                  <Box>
                                    <IconButton
                                      color={"secondary"}
                                      onClick={() =>
                                        setCancelOrder({
                                          data: data,
                                          dialog: true,
                                        })
                                      }
                                    >
                                      <Delete fontSize={"small"} />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </form>
          </Box>

          {/* <Box>
            <Box my={3}>
              <Box
                display="flex"
                justifyContent="flex-end"
                className="d-print-none"
                mb={1}
              >
                <>
                  {allowEdit && (
                    <Box mr={2}>
                      <Button
                        color="primary"
                        onClick={() => setNewOpen(true)}
                        startIcon={<Edit />}
                      >
                        New
                      </Button>
                    </Box>
                  )}

                  <Box>
                    <Button
                      color="primary"
                      onClick={handlePrint}
                      startIcon={<Printer />}
                    >
                      Print
                    </Button>
                  </Box>
                </>
              </Box>
            </Box>
            <TableContainer>
              <Table className="table-bordered" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Time</TableCell>
                    <TableCell align="center">
                      Please fill all notations <br />
                      <Typography variant="caption">
                        (Notes should be informative and meaningful to patient
                        total case)
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className="d-print-none">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {bedsideNotes.length > 0 ? (
                    bedsideNotes.map((data, key) => (
                      <TableRow key={key}>
                        <TableCell>
                          {Notify.createdAt(data.notes_datetime)}
                        </TableCell>
                        <TableCell>
                          {Notify.createdTime(data.notes_datetime)}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "pre-line" }}>
                          {data.notes}
                        </TableCell>
                        <TableCell align="center" className="d-print-none">
                          <Box display={"flex"} justifyContent={"center"}>
                            <Box>
                              <IconButton
                                color={"primary"}
                                onClick={() =>
                                  setEditOrder({
                                    data: data,
                                    dialog: true,
                                  })
                                }
                              >
                                <BorderColor fontSize={"small"} />
                              </IconButton>
                            </Box>
                            <Box>
                              <IconButton
                                color={"secondary"}
                                onClick={() =>
                                  setCancelOrder({ data: data, dialog: true })
                                }
                              >
                                <Delete fontSize={"small"} />
                              </IconButton>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box> */}
        </CardContent>
      </Card>

      {/* dialog new bedside notes */}
      {/* <Dialog
        open={newOpen}
        onClose={() => setNewOpen(false)}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle> Notes </DialogTitle>
        <form onSubmit={handleNewBediseNotes}>
          <DialogContent dividers>
            {console.log("notesDate:", notesDate)}
            <Box mb={2}>
              <DateTimePicker
                label="Date Timer"
                value={notesDate}
                onChange={(date) => setNotesDate(date)}
                autoOk
                inputVariant="outlined"
                fullWidth
              />
            </Box>

            <Box>
              <TextField
                name="notes"
                variant="outlined"
                label="Notes"
                fullWidth
                multiline
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="color"
              onClick={() => setNewOpen(false)}
              startIcon={<XCircle />}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isProcess}
              startIcon={
                isProcess ? (
                  <CircularProgress size={23} color="inherit" />
                ) : (
                  <CheckSquare />
                )
              }
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog> */}

      <Dialog
        open={cancelOrder.dialog}
        onClose={() => setCancelOrder({ data: null, dialog: false })}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
      >
        {cancelOrder.data && cancelOrder.dialog && (
          <form onSubmit={handleRemoveNote}>
            <DialogTitle>Are you sure to remove this data?</DialogTitle>
            <TextField
              label={"NOTE ID"}
              variant="outlined"
              name="cnbt_id"
              defaultValue={cancelOrder.data.cnbt_id}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              hidden
            />
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setCancelOrder({ data: null, dialog: false })}
              >
                No
              </Button>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isprocessCancel}
                startIcon={
                  isprocessCancel && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                Yes
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      <Dialog
        open={editOrder.dialog}
        onClose={() => setEditOrder({ data: null, dialog: false })}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
      >
        {editOrder.data && editOrder.dialog && (
          <form onSubmit={handleEditNote}>
            <DialogTitle>Edit note</DialogTitle>
            <DialogContent>
              <Box mb={2}>
                <TextField
                  label={"NOTE ID"}
                  variant="outlined"
                  name="cnbt_id"
                  defaultValue={editOrder.data.cnbt_id}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  hidden
                />

                <TextField
                  label={"Date/Time"}
                  name="notes_datetime"
                  defaultValue={
                    editOrder.data.notes_datetime !== null
                      ? editOrder.data.notes_datetime.replace(" ", "T")
                      : ""
                  }
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Box>

              <Box mb={2}>
                <TextField
                  label={"Note"}
                  variant="outlined"
                  name="notes"
                  defaultValue={
                    editOrder.data.notes !== null ? editOrder.data.notes : ""
                  }
                  fullWidth
                  multiline
                  required
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setEditOrder({ data: null, dialog: false })}
              >
                No
              </Button>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isprocessCancel}
                startIcon={
                  isprocessCancel && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                Yes
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      <Dialog
        open={addToTable}
        onClose={() => setAddToTable(false)}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
      >
        <form onSubmit={handleAddNote}>
          <DialogTitle>Add note</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <TextField
                label={"Date/Time"}
                name="notes_datetime"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                variant="outlined"
              />
            </Box>
            <Box mb={2}>
              <TextField
                label={"Note"}
                variant="outlined"
                name="notes"
                fullWidth
                multiline
                required
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setAddToTable(false)}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isprocessCancel}
              startIcon={
                isprocessCancel && (
                  <CircularProgress size={20} color="inherit" />
                )
              }
            >
              Yes
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default FormTwentyFive;
