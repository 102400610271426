import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import {
	faListUl,
	faTruckMoving,
	faUserInjured,
} from "@fortawesome/free-solid-svg-icons";
import SelectInputField from "src/components/forms/SelectInputField";
import LMISViewItemsList from "src/pho/lmis/LMISViewItemsList";

const CisLMISView = () => {
	const [locations, setLocations] = useState([
		{
			id: 1,
			type: "SPH", //SPH, RHU, BHS
			location: {
				id: "",
			},
		},
	]);

	const addAction = () => {
		setLocations((items) => [
			...items,
			{
				id: 1,
				type: "SPH", //SPH, RHU, BHS
				location: {
					id: "",
				},
			},
		]);
	};
	const removeAction = (item) => {
		if (locations.length > 1)
			setLocations((items) => items.filter((x) => x.id != item?.id));
	};
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faTruckMoving}
						color="white"
						size={"2x"}
					/>
				}
				title="View Consignment Order"
				// subtitle="View Kobotoolbox respondents"
			>
				<CardContainer
					containerClassName={` border border- border-opacity-10`}
					header={{
						title: "Consignment Order Form",
					}}
				>
					<div className="grid grid-cols-12 gap-4 mb-6 w-full">
						<div className="col-span-4">
							<b>Date:</b>
						</div>
						<div className="col-span-4">
							<b>COF Number:</b>
						</div>
						<div className="col-span-4">
							<b>Consignor:</b>
						</div>
						<div className="col-span-6">
							<b>Term:</b>
						</div>
						<div className="col-span-6">
							<b>Address:</b>
						</div>
						<div className="col-span-6">
							<b>HCI Name:</b>
						</div>
						<div className="col-span-6">
							<b>HCI Number:</b>
						</div>
					</div>
					<div className="flex flex-col gap-y-4">
						<LMISViewItemsList />
					</div>
				</CardContainer>
			</LayoutContainer>
		</>
	);
};

export default CisLMISView;
