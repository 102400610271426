import axios from "axios";
import { useEffect, useState } from "react";
import FlatIcon from "src/components/FlatIcon";
import { formatDateMMDDYYYYHHIIA } from "src/helpers/utils";
const Card = ({ title, children, icon, color }) => {
	return (
		<div className="shadow-sm rounded-xl flex items-center p-3 w-1/2 2xl:w-[calc(100%/3-24px)] border border-red-300">
			<div className="flex flex-col pb-3">
				<h3
					className="text-xl font-bold text-gray-900 mb-0 text-opacity-75"
					style={{ color: color }}
				>
					{title}
				</h3>
				<div className="h-[3px] w-4/5 bg-blue-300 mb-[1px]" />
				<div className="h-[2px] w-2/5 bg-red-300 mb-3" />
				{children}
			</div>
			{icon ? (
				<div className="p-1 bg-white bg-opacity-5 rounded-xl ml-auto">
					<img
						src={`/vitals/${icon}.png`}
						className="w-14 object-contain"
					/>
				</div>
			) : (
				""
			)}
		</div>
	);
};
const ClinicProfileVitals = ({ updateVitals, patient }) => {
	const {
		blood_systolic,
		blood_diastolic,
		temperature,
		height,
		weight,
		pulse,
		respiratory,
	} = patient;
	const [vitals, setVitals] = useState(null);
	useEffect(() => {
		if (patient?.id) {
			getPatientVitals();
		}
	}, [patient?.id]);
	const getPatientVitals = () => {
		axios
			.get(`v1/patient-vitals/vital-signs/${patient?.id}`)
			.then((res) => {
				setVitals(res.data.data || null);
			});
	};
	return vitals?.updated_at ? (
		<>
			<p className=" px-4">
				Last updated as of
				<b>{formatDateMMDDYYYYHHIIA(new Date(vitals?.updated_at))}</b>
			</p>
			<div className="flex items-start justify-start flex-wrap gap-6 pb-11 w-full px-4">
				<Card
					color="black"
					title="Blood Pressure"
					icon="blood-pressure"
				>
					<div className="flex items-center gap-2">
						<b className="text-2xl text-darker">
							{vitals?.blood_systolic}
						</b>
						<span className="text-base text-placeholder">/</span>
						<b className="text-2xl text-darker">
							{vitals?.blood_diastolic}
						</b>
						<span className="text-placeholder text-base">mmHG</span>
					</div>
				</Card>
				<Card color="red" title="Heart Rate" icon="heart-rate">
					<div className="flex items-center gap-2">
						<b className="text-2xl text-darker">{vitals?.pulse}</b>
						<span className="text-placeholder text-base">bpm</span>
					</div>
				</Card>
				<Card color="blue" title="Respiratory Rate" icon="respiration">
					<div className="flex items-center gap-2">
						<b className="text-2xl text-darker">
							{vitals?.respiratory}
						</b>
						<span className="text-placeholder text-base">bpm</span>
					</div>
				</Card>
				<Card
					color="darkorange"
					title="Temperature"
					icon="temperature-celcius"
				>
					<div className="flex items-center gap-2">
						<b className="text-2xl text-darker">
							{vitals?.temperature}
						</b>
						<span className="text-placeholder text-base">°C</span>
					</div>
				</Card>
				<Card color="green" title="Height" icon="height">
					<div className="flex items-center gap-2">
						<b className="text-2xl text-darker">{vitals?.height}</b>
						<span className="text-placeholder text-base">cm</span>
					</div>
				</Card>
				<Card color="brown" title="Weight" icon="weight">
					<div className="flex items-center gap-2">
						<b className="text-2xl text-darker">{vitals?.weight}</b>
						<span className="text-placeholder text-base">kg</span>
					</div>
				</Card>

				<Card color="blue" title="BMI" icon="weight">
					<div className="flex items-center gap-2">
						<b className="text-xl text-darker">{vitals?.bmi}</b>
						<span className="text-placeholder text-base"></span>
					</div>
				</Card>
				<Card color="red" title="Covid 19">
					<div className="flex items-center gap-2">
						<b className="text-xl text-darker">
							{vitals?.covid_19}
						</b>
						<span className="text-placeholder text-base"></span>
					</div>
				</Card>
				<Card color="orange" title="Tubercolosis">
					<div className="flex items-center gap-2">
						<b className="text-xl text-darker">{vitals?.tb}</b>
						<span className="text-placeholder text-base"></span>
					</div>
				</Card>
				<Card color="red" title="Blood Type">
					<div className="flex items-center gap-2">
						<b className="text-xl text-darker">
							{vitals?.bloody_type || "-"}
						</b>
						<span className="text-placeholder text-base"></span>
					</div>
				</Card>
			</div>
		</>
	) : (
		<div className="p-6  flex items-center justify-center">
			<p>
				No vitals found.{" "}
				<span
					className="text-blue-500 cursor-pointer"
					onClick={updateVitals}
				>
					Please update vitals.
				</span>
			</p>
		</div>
	);
};

export default ClinicProfileVitals;
