import Tippy from "@tippyjs/react";
import FlatIcon from "src/components/FlatIcon";
import Img from "src/components/Img";

const PatientQueueDetail = ({ patient, confirmServePatient }) => {
	return (
		<div className="border rounded-xl p-3 w-full bg-white bg-opacity-50 relative">
			<div className="flex items-center gap-3 w-full">
				<span className="text-[50px] font-bold text-blue-600 px-2 flex items-center justify-center">
					<span className="!text-2xl mr-1">#</span>
					{patient?.number}
				</span>
				<div className="hidden xl:block bg-slate-200 rounded-full w-[72px] h-[72px] mb-2 overflow-hidden relative">
					<Img
						type="user"
						name={`${patient?.patient?.lastname}, ${patient?.patient?.firstname}, ${patient?.patient?.middle}`}
						src={patient?.patient?.avatar}
						className="absolute top-0 left-0 w-full h-full object-contain"
					/>
				</div>
				<div className="flex flex-col">
					<h5 className="text-lg font-bold mb-1">
						{`${patient?.patient?.lastname}, ${patient?.patient?.firstname}, ${patient?.patient?.middle}`}
					</h5>
					<div className="flex text-base items-center gap-1 mb-0">
						<b>Patient ID:</b>
						{patient?.patient?.id}
					</div>
					<div className="flex text-base items-center gap-1 mb-0">
						<FlatIcon icon="rr-map-marker" />
						{`${patient?.patient?.municipality?.name || ""}, ${
							patient?.patient?.barangay?.name || ""
						}, ${patient?.patient?.purok?.name || ""}`}
					</div>
				</div>
			</div>
			<div
				className="absolute top-3 right-3 border border-success text-success  rounded-full w-11 h-11 flex items-center justify-center text-2xl cursor-pointer hover:bg-success hover:!text-white duration-200"
				title="Click to serve patient"
				onClick={() => {
					confirmServePatient(patient?.id);
				}}
			>
				<FlatIcon
					icon="rr-clipboard-list-check"
					className=" text-2xl"
				/>
			</div>
		</div>
	);
};

export default PatientQueueDetail;
