import { faUserMd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { useLocation, useHistory } from "react-router-dom";
import FlatIcon from "src/components/FlatIcon";
import Img from "src/components/Img";
import { calculateAgeV3 } from "src/helpers/utils";
import { patientFullName } from "src/helpers/utils";

const CisPatientAppointmentListItem = ({ patient, doctor }) => {
	const history = useHistory();
	const location = useLocation();
	console.log("location.pathname");
	return (
		<div
			className="!border !border-slate-100 bg-white hover:!bg-blue-500 group duration-300 cursor-pointer rounded-[12px] bg-opacity-50 p-[16px] gap-3 flex items-start"
			onClick={() => {
				history.push(
					`/sph/app/${location.pathname.split("/")[3]}/patients/${
						patient?.id
					}`
				);
			}}
		>
			<div className="relative w-11 aspect-square rounded-lg min-w-[44px]">
				<Img
					name={patientFullName(patient)}
					src={patient?.src}
					type="user"
					className="absolute top-0 left-0 w-full h-full rounded-lg !border !border-white object-contain bg-slate-200"
				/>
			</div>
			<div className="flex flex-col gap-1 w-full">
				<span className={`font-bold text-sm group-hover:text-white`}>
					{patientFullName(patient)}
				</span>
				<div className="flex items-center gap-4 divide-x !text-xs text-slate-600 group-hover:text-white">
					<span className="text-xs flex items-center gap-2">
						<FlatIcon
							icon="rr-venus-mars"
							className="mb-[-2px] text-xs"
						/>
						<span className="">{patient?.gender}</span>
					</span>
					<span className="text-xs flex items-center gap-2 pl-3">
						<FlatIcon
							icon="rr-calendar"
							className="mb-[-2px] text-xs"
						/>
						<span className="">
							{calculateAgeV3(patient?.birthday)}
						</span>
					</span>
				</div>
				<div className="flex items-center gap-4 mt-1 divide-x !text-xs text-slate-600 group-hover:text-white">
					<Tippy content="Attending physician">
						<span className="text-sm flex items-center gap-2">
							<FontAwesomeIcon
								icon={faUserMd}
								className=" text-xs"
							/>
							<span className="">{doctor?.name}</span>
						</span>
					</Tippy>
				</div>
				<div className="relative !border-l-2 !border-slate-100 pl-2">
					<span className="mt-1 text-sm text-black group-hover:text-white">
						<i>{patient?.reason}</i>
					</span>
				</div>
			</div>
		</div>
	);
};

export default CisPatientAppointmentListItem;
