import React, { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	Box,
	Typography,
	TextField,
	Card,
} from "@material-ui/core";
import { styles } from "../styles/styles";
import { useForm } from "react-hook-form";
import Img from "src/components/Img";
import { calculateAgeV2 } from "src/helpers/utils";
import { formatDate } from "src/helpers/utils";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Notify from "src/notification/Notify";

export default function AcceptPatient({
	data: propData,
	open,
	onClose,
	patient,
}) {
	const [date, setDate] = useState("");
	const patientId = patient?.id;
	const [loading, setLoading] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		console.log(data);
		setLoading(true);

		axios
			.patch(`v1/clinic-queueing/serve/${propData?.id}`)
			.then((response) => {
				let data = response.data;
				setLoading(false);
				console.log(data);
				Notify.successRequest("Accept Patient");
				onClose();
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	useEffect(() => {
		const currentDateTime = new Date();
		const formattedDate = currentDateTime.toISOString().split("T")[0];

		setDate(`${formattedDate}`);
	}, [open]);

	return (
		<Dialog open={open} onClose={onClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogTitle>
					<div className="flex justify-between items-center">
						<Box>
							<Typography variant="h5" color="textSecondary">
								<strong>Accept Patient</strong>
							</Typography>
						</Box>
						<button
							className="text-red-600 hover:bg-red-300 py-2 px-3  rounded-lg "
							onClick={onClose}
							type="button"
						>
							CLOSE
						</button>
					</div>
				</DialogTitle>
				<DialogContent>
					<Card elevation={3} className="py-4  px-3" component={Box}>
						<div className="w-[500px]">
							<div className="flex gap-3 items-center">
								<div className="bg-gray-200 mb-2 w-[80px] h-[80px] lg:w-[144px] lg:h-[144px]  rounded-full relative flex items-center justify-center overflow-hidden">
									<Img
										type="user"
										name={`${patient?.lastname}, ${patient?.firstname}, ${patient?.middle}`}
										src={patient?.avatar}
										className="absolute top-0 left-0 w-full h-full object-contain"
									/>
								</div>
								<div>
									<Typography variant="h6">
										{`${patient?.lastname}  ${patient?.firstname} ${patient?.middle}.`}
									</Typography>
									<Typography variant="h6">
										{patient?.gender
											.charAt(0)
											.toUpperCase() +
											patient?.gender.slice(1)}
									</Typography>
									<Typography variant="h6">
										{formatDate(
											new Date(patient?.birthday)
										)}
									</Typography>
									<Typography variant="h6">
										{calculateAgeV2(
											new Date(patient?.birthday)
										)}{" "}
										yrs old
									</Typography>
								</div>
							</div>
							<div className="p-2">
								<Typography variant="h6">
									<b>Vitals</b>
								</Typography>
								<div className="flex justify-between">
									<div>
										<Typography variant="h6">
											<b className="text-gray-500">
												Blood Pressure:
											</b>
											{patient?.blood_systolic}/
											{patient?.blood_diastolic} mmHG
										</Typography>
										<Typography variant="h6">
											<b className="text-gray-500">
												Respiratory Rate:
											</b>
											{patient?.respiratory} bpm
										</Typography>
										<Typography variant="h6">
											<b className="text-gray-500">
												Height:
											</b>
											{patient?.height} cm
										</Typography>
									</div>
									<div>
										<Typography variant="h6">
											<b className="text-gray-500">
												Heart Rate:
											</b>{" "}
											{patient?.pulse}bpm
										</Typography>
										<Typography variant="h6">
											<b className="text-gray-500">
												Temperature:
											</b>
											{patient?.temperature} °C
										</Typography>
										<Typography variant="h6">
											<b className="text-gray-500">
												Weight:
											</b>{" "}
											{patient?.weight}
											kg
										</Typography>
									</div>
								</div>
							</div>
							<div className="p-2">
								<Typography variant="h6">
									<b>Notes:</b>
								</Typography>
								<Typography variant="h6" color="textSecondary">
									<b>Severe Tuberculosis</b>
								</Typography>
							</div>
						</div>
					</Card>
					<div className="py-3 mt-2">
						<Button
							fullWidth
							style={{ fontSize: "18px" }}
							variant="contained"
							color="primary"
							type="submit"
							disabled={loading}
						>
							{loading ? "Loading..." : "Accept"}
						</Button>
					</div>
				</DialogContent>
			</form>
		</Dialog>
	);
}
