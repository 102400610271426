import { Fade } from "react-reveal";
import ViewBhsPatient from "./ViewBhsPatient";

const BhsPatientProfile = ({
	skipPrivacyStatement,
	disableNewAppointment,
	patient,
	setPatient,
	openAppointmentForm,
}) => {
	return (
		<Fade>
			<ViewBhsPatient
				skipPrivacyStatement={skipPrivacyStatement}
				disableNewAppointment={disableNewAppointment}
				patient={patient}
				openAppointmentForm={openAppointmentForm}
				setPatient={setPatient}
				className="!bg-transparent"
				// add_patient_ref={add_patient_ref}
			/>
		</Fade>
	);
};

export default BhsPatientProfile;
