import React, { useState } from "react";
import DoctorsWageDetails from "./DoctorsWageDetails";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import TabPanel from "../../utils/TabPanel";
import Paper from "@material-ui/core/Paper";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LocalHospital from "@material-ui/icons/LocalHospital";
import DoctorWageGeneratedRec from "./DoctorWageGeneratedRec";

const DoctorWageViewTab = ({
  doctors_id,
  getAllDoctors,
  setStateOpenDetails,
}) => {
  const [category, setCategory] = useState("doc-record");

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <Box m={0} p={0}>
      <Box component={Paper} elevation={1} mb={2}>
        <Tabs
          value={category}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab
            wrapped
            value="doc-record"
            label="Doctor's Record"
            icon={<AccountCircle />}
          />

          <Tab
            wrapped
            value="gen-record"
            label="Generated Record"
            icon={<LocalHospital />}
          />
        </Tabs>
      </Box>

      <TabPanel value={category} index={"doc-record"}>
        <DoctorsWageDetails
          doctors_id={doctors_id}
          getAllDoctors={() => getAllDoctors()}
          setStateOpenDetails={() => setStateOpenDetails()}
        />
      </TabPanel>

      <TabPanel value={category} index={"gen-record"}>
        <DoctorWageGeneratedRec doctors_id={doctors_id} />
      </TabPanel>
    </Box>
  );
};

export default DoctorWageViewTab;
