import {
	Box,
	Grid,
	Paper,
	TextField,
	Button,
	Badge,
	CircularProgress,
	Avatar,
	Divider,
} from "@material-ui/core";
import axios from "axios";
import React, {
	Fragment,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { isMobile } from "react-device-detect";
import { UsersData, UsersHeader } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import {
	CancelOutlined,
	CheckBoxOutlineBlankOutlined,
} from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const BasicInfo = () => {
	var profileimage = useRef();
	const { users } = useContext(UsersData);
	const [enableEdit, setEnableEdit] = useState(false);
	const [_is_process, set_is_process] = useState(false);
	const [_uploadprocess, setUploadprocess] = useState(false);
	const radiologistHeadInfo = useContext(UsersHeader);

	const handleClickupload = () => {
		profileimage.click();
	};

	const [radiologistInfo, setRadiologistInfo] = useState({
		data: [],
		ready: false,
	});

	const fetchRadiologistInfo = async () => {
		try {
			var params = { user_id: users.user_id };
			const request = await axios.get("radiologist/get-personal-info-by-id", {
				params,
			});
			setRadiologistInfo({
				data: request.data,
				ready: true,
			});
		} catch (error) {
			Notify.requestError(error);
		}
	};

	const handleUploadImage = (event) => {
		var ext = event.target.files[0].type.split("/")[1];
		var maxfile = event.target.files[0].size / 1024 / 1024;
		var extArray = ["png", "jpeg", "jpg"];
		var resetUpload = event.target;
		if (extArray.indexOf(ext) === -1) {
			Notify.customToast("Image Not Allowed", "Upload PNG and JPEG only.");
			resetUpload.value = "";
		} else if (maxfile.toFixed(2) > 2) {
			Notify.customToast(
				"Image Not Allowed",
				"Image size too big, Upload 2mb or less."
			);
			resetUpload.value = "";
		} else {
			var formdata = new FormData();
			formdata.set("user_id", users.user_id);
			formdata.set("profile", event.target.files[0]);
			formdata.set(
				"current_profile",
				radiologistInfo.data.length > 0
					? radiologistInfo.data[0].image !== null
						? radiologistInfo.data[0].image
						: ""
					: ""
			);

			setUploadprocess(true);
			axios
				.post("radiologist/information/personal-uploadnewprofile", formdata)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						Notify.successRequest("profile");
						fetchRadiologistInfo();
						radiologistHeadInfo.renderPharmacyInfo();
						resetUpload.value = "";
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => {
					setUploadprocess(false);
				});
		}
	};

	const handleEditPersonalInfo = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);

		var error = [];

		if (
			formdata.get("fullname").length === 0 ||
			formdata.get("fullname").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("fullname");
		}
		if (
			formdata.get("address").length === 0 ||
			formdata.get("address").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("address");
		}
		if (
			formdata.get("email").length === 0 ||
			formdata.get("email").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("email");
		}
		if (
			formdata.get("password").length === 0 ||
			formdata.get("password").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("password");
		}
		if (error.length > 0) {
			console.warn("Form has an error, observe please");
		} else {
			set_is_process(true);
			axios
				.post("radiologist/information/personal-update", formdata)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						setEnableEdit(false);
						fetchRadiologistInfo();
						radiologistHeadInfo.renderPharmacyInfo();
						Notify.successRequest("personal information");
					}
					if (data === "pass-invalid") {
						Notify.fieldInvalid("password");
					}
					if (data === "db-error") {
						Notify.warnRequest("personal information");
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => {
					set_is_process(false);
				});
		}
	};

	useEffect(() => {
		fetchRadiologistInfo();
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={12}>
					<Box display="flex" mb={2}>
						<Box flexGrow={1} />
						<Box>
							<Button
								variant="text"
								color="primary"
								hidden={enableEdit ? true : false}
								onClick={() => setEnableEdit(true)}
								startIcon={<AddCircleOutlineIcon />}
							>
								Update
							</Button>
						</Box>
					</Box>
					<Box component={Paper} variant="outlined">
						{radiologistInfo.ready && (
							<Box
								component={Paper}
								variant="outlined"
								className={enableEdit ? "" : "gtc-textfield-noborder"}
								p={2}
							>
								<form onSubmit={handleEditPersonalInfo}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6} md={4}>
											<Box
												width={"100%"}
												height={
													isMobile
														? 200
														: window.innerWidth <= 959
														? 200
														: window.innerWidth <= 898
														? 200
														: 194
												}
												display="flex"
												justifyContent="center"
												alignItems="center"
											>
												<Badge
													overlap="circle"
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "right",
													}}
													badgeContent={
														_uploadprocess ? (
															<CircularProgress color="inherit" />
														) : (
															<CameraAltIcon
																style={{
																	width: isMobile
																		? 30
																		: window.innerWidth >= 899 &&
																		  window.innerWidth <= 959
																		? 60
																		: window.innerWidth <= 898
																		? 50
																		: 40,
																	height: isMobile
																		? 30
																		: window.innerWidth >= 899 &&
																		  window.innerWidth <= 959
																		? 60
																		: window.innerWidth <= 898
																		? 50
																		: 40,
																	cursor: "pointer",
																}}
																onClick={handleClickupload}
															/>
														)
													}
												>
													<Avatar
														style={{
															width: isMobile
																? 180
																: window.innerWidth <= 959
																? 180
																: window.innerWidth <= 898
																? 180
																: 160,
															height: isMobile
																? 180
																: window.innerWidth <= 959
																? 180
																: window.innerWidth <= 898
																? 180
																: 160,
														}}
														alt="profile-pic"
														src={
															radiologistInfo.data[0].image !== null
																? imageLocation +
																  "radiologist/" +
																  radiologistInfo.data[0].image
																: ""
														}
													>
														{radiologistInfo.data[0].image === null
															? "No Image"
															: ""}
													</Avatar>
													<input
														type="file"
														onChange={handleUploadImage}
														className="d-none"
														ref={(ref) => (profileimage = ref)}
													/>
												</Badge>
											</Box>
										</Grid>
										<Grid item xs={12} sm={6} md={8}>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={12}>
													<Box>
														<TextField
															required
															fullWidth
															label="Fullname"
															name="fullname"
															variant="outlined"
															defaultValue={radiologistInfo.data[0].name}
															InputProps={{ readOnly: !enableEdit }}
														/>
													</Box>
												</Grid>
												<Grid item xs={12} sm={12}>
													<Box>
														<TextField
															required
															fullWidth
															label="Address"
															name="address"
															variant="outlined"
															defaultValue={radiologistInfo.data[0].address}
															InputProps={{ readOnly: !enableEdit }}
														/>
													</Box>
												</Grid>
												<Grid item xs={12} sm={12}>
													<Box>
														<TextField
															required
															fullWidth
															label="Email"
															name="email"
															variant="outlined"
															defaultValue={radiologistInfo.data[0].email}
															InputProps={{ readOnly: !enableEdit }}
														/>
													</Box>
												</Grid>
											</Grid>
										</Grid>

										{enableEdit ? (
											<Fragment>
												<Grid item xs={12} sm={12}>
													<TextField
														required
														fullWidth
														label="Password"
														name="password"
														variant="outlined"
														type="password"
													/>
												</Grid>

												<Grid item xs={12} sm={12} md={12}>
													<Box mb={1}>
														<Divider />
													</Box>
													<Box display="flex">
														<Box flexGrow={1} />
														<Button
															variant="contained"
															onClick={() => setEnableEdit(false)}
															startIcon={<CancelOutlined />}
															color="default"
														>
															No
														</Button>
														<Box ml={2}>
															<Button
																variant="contained"
																type="submit"
																color="primary"
																disabled={_is_process}
																startIcon={<CheckBoxOutlineBlankOutlined />}
															>
																Save
															</Button>
														</Box>
													</Box>
												</Grid>
											</Fragment>
										) : null}
									</Grid>
								</form>
							</Box>
						)}
					</Box>
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default BasicInfo;
