import {
	faSpinner,
	faArrowRight,
	faPlus,
	faBan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Badge } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";

const PrescriptionForm = ({
	patient_id,
	displayList,
	displayUnsave,
	dataFromUnsaved,
	formValues,
}) => {
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const { users } = React.useContext(UsersData);
	const [isProcess, setIsProcess] = useState(false);

	const onSubmit = (data) => {
		let formData = new FormData();
		formData.append("prescription", data?.prescription || "");
		formData.append("quantity", data?.quantity || "");
		formData.append("type", data?.type || "");
		formData.append("remarks", data?.remarks || "");
		formData.append("patient_id", patient_id);
		console.log("onSubmit data", formData);

		axios
			.post(`/v1/patient-prescription/store`, formData)
			.then((response) => {
				setTimeout(() => {
					Notify.successRequest(response.message);
				}, 200);
				displayList();
			})
			.catch((err) => console.log(err));
	};

	return (
		<Fragment>
			<div className="flex flex-row justify-content-between gap-1 mb-4 mt-4">
				<h4 className="text-lg font-bold mb-0">
					Create Doctor's Prescription
				</h4>
				<Button variant="outlined" size="medium" onClick={displayList}>
					View List{" "}
					<FontAwesomeIcon icon={faArrowRight} className="ml-2" />
				</Button>
			</div>
			<form>
				<Row>
					<Col sm={12}>
						<Box mb={2}>
							<TextField
								variant={"outlined"}
								name="prescription"
								label="Prescription"
								value={formValues?.data?.prescription}
								fullWidth
								{...register("prescription", {
									required: {
										value: true,
										message: "This field is required",
									},
								})}
								error={errors?.prescription?.message}
								helperText={errors?.prescription?.message}
							/>
						</Box>
					</Col>
					<Col sm={6}>
						<TextField
							variant={"outlined"}
							name="quantity"
							label="Quantity"
							fullWidth
							type="number"
							{...register("quantity", {
								required: {
									value: true,
									message: "This field is required",
								},
							})}
							error={errors?.quantity?.message}
							helperText={errors?.quantity?.message}
							value={formValues?.data?.quantity}
							InputProps={{
								inputProps: {
									min: 1,
									max: 10000,
									step: 1,
								},
							}}
						/>
					</Col>
					<Col sm={6}>
						<Box className="mt-4 mt-sm-0">
							<TextField
								select
								SelectProps={{ native: true }}
								InputLabelProps={{ shrink: true }}
								required
								fullWidth
								name="type"
								label="Type"
								value={formValues?.data?.type}
								variant="outlined"
								{...register("type", {
									required: {
										value: true,
										message: "This field is required",
									},
								})}
								error={errors?.type?.message}
								helperText={errors?.type?.message}
							>
								<option value=""> -- Select -- </option>
								<option value="Bottle"> Bottle </option>
								<option value="Box"> Box </option>
								<option value="Can"> Can </option>
								<option value="Capsule"> Capsule </option>
								<option value="Liniment"> Liniment </option>
								<option value="Sachet"> Sachet </option>
								<option value="Suspension"> Suspension </option>
								<option value="Powder"> Powder </option>
								<option value="Tablet"> Tablet </option>
							</TextField>
						</Box>
					</Col>
				</Row>
				<Box my={2}>
					<TextField
						variant={"outlined"}
						name="remarks"
						label="Sig."
						value={formValues?.data?.remarks}
						fullWidth
						rows={3}
						multiline
						{...register("remarks", {
							required: {
								value: true,
								message: "This field is required",
							},
						})}
						error={errors?.remarks?.message}
						helperText={errors?.remarks?.message}
					/>
				</Box>

				<p className="m-0 mb-2 text-muted">
					Are you sure to continue adding this item?
				</p>
				<Button
					variant="contained"
					color="primary"
					className="mr-2"
					disabled={isProcess}
					onClick={handleSubmit(onSubmit)}
				>
					<FontAwesomeIcon
						icon={isProcess ? faSpinner : faPlus}
						spin={isProcess}
						className="mr-2"
					/>{" "}
					Save
				</Button>
				{/* <Button type="reset" variant="contained" className="mr-2">
					<FontAwesomeIcon icon={faBan} className="mr-2" />
					No
				</Button> */}
				{/* <Button
          type="button"
          variant="contained"
          className="mr-2"
          color="secondary"
          onClick={displayUnsave}
        >
          <Badge variant="light" text="dark" className="mr-2 px-1">
            {" "}
            {0}{" "}
          </Badge>
          Unsaved Prescription
        </Button> */}
			</form>
		</Fragment>
	);
};

export default PrescriptionForm;
