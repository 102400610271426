import React, { useContext, useState, useRef, Fragment } from "react";
import {
  Box,
  IconButton,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  CardMedia,
  Divider,
  Grid,
} from "@material-ui/core";
import { UsersData, UsersHeader } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { Search } from "@material-ui/icons";
import axios from "axios";
import * as htmlToImage from "html-to-image";

const PayrollPayslip = ({ employee_id, selectedName, close }) => {
  const { users } = useContext(UsersData);
  const { sidebarHeader } = useContext(UsersHeader);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [payslip, setPayslip] = useState([]);
  const [contribution, setContribution] = useState(false);
  const samplePrintableDiv = useRef(null);
  // const [ishidden, setIsHidden] = useState(false);
  var totalDeduction = 0;

  const handleSaveAsImage = () => {
    // setIsHidden(true);
    var node = samplePrintableDiv.current;
    htmlToImage
      .toJpeg(node, { backgroundColor: "#fff" })
      .then(function (dataUrl) {
        var a = document.createElement("a");
        a.href = `${dataUrl}`;
        a.download = `payslip-${selectedName}`;
        a.click();
        // setIsHidden(false);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const checkSSSBracket = (salary) => {
    var amount;

    if (salary >= 1 && salary <= 3249.99) {
      amount = 135.0;
    }
    if (salary >= 3250 && salary <= 3749.99) {
      amount = 157.5;
    }
    if (salary >= 3750 && salary <= 4249.99) {
      amount = 180.0;
    }
    if (salary >= 4250 && salary <= 4749.99) {
      amount = 202.5;
    }
    if (salary >= 4750 && salary <= 5249.99) {
      amount = 225.0;
    }
    if (salary >= 5250 && salary <= 5749.99) {
      amount = 247.5;
    }
    if (salary >= 5750 && salary <= 6249.99) {
      amount = 270.0;
    }
    if (salary >= 6250 && salary <= 6749.99) {
      amount = 292.5;
    }
    if (salary >= 6750 && salary <= 7249.99) {
      amount = 315.0;
    }
    if (salary >= 7250 && salary <= 7749.99) {
      amount = 337.5;
    }
    if (salary >= 7750 && salary <= 8249.99) {
      amount = 360.0;
    }
    if (salary >= 8250 && salary <= 8749.99) {
      amount = 382.5;
    }
    if (salary >= 8750 && salary <= 9249.99) {
      amount = 405.0;
    }
    if (salary >= 9250 && salary <= 9749.99) {
      amount = 427.5;
    }
    if (salary >= 9750 && salary <= 10249.99) {
      amount = 450.0;
    }
    if (salary >= 10250 && salary <= 10749.99) {
      amount = 472.5;
    }
    if (salary >= 10750 && salary <= 11249.99) {
      amount = 495.0;
    }
    if (salary >= 11250 && salary <= 11749.99) {
      amount = 517.5;
    }
    if (salary >= 11750 && salary <= 12249.99) {
      amount = 540.0;
    }
    if (salary >= 12250 && salary <= 12749.99) {
      amount = 562.5;
    }
    if (salary >= 12750 && salary <= 13249.99) {
      amount = 585.0;
    }
    if (salary >= 13250 && salary <= 13749.99) {
      amount = 607.5;
    }
    if (salary >= 13750 && salary <= 14249.99) {
      amount = 630.0;
    }
    if (salary >= 14250 && salary <= 14749.99) {
      amount = 652.5;
    }
    if (salary >= 14750 && salary <= 15249.99) {
      amount = 675.0;
    }
    if (salary >= 15250 && salary <= 15749.99) {
      amount = 675.0;
    }
    if (salary >= 15750 && salary <= 16249.99) {
      amount = 720.0;
    }
    if (salary >= 16250 && salary <= 16749.99) {
      amount = 742.5;
    }
    if (salary >= 16750 && salary <= 17249.99) {
      amount = 765.0;
    }
    if (salary >= 17250 && salary <= 17749.99) {
      amount = 787.5;
    }
    if (salary >= 17750 && salary <= 18249.99) {
      amount = 810.0;
    }
    if (salary >= 18250 && salary <= 18749.99) {
      amount = 832.5;
    }
    if (salary >= 18750 && salary <= 19249.99) {
      amount = 855.0;
    }
    if (salary >= 19250 && salary <= 19749.99) {
      amount = 877.5;
    }
    if (salary >= 19750 && salary <= 20249.99) {
      amount = 900.0;
    }
    if (salary >= 20250 && salary <= 20749.99) {
      amount = 922.5;
    }
    if (salary >= 20750 && salary <= 21249.99) {
      amount = 945.0;
    }
    if (salary >= 21250 && salary <= 21749.99) {
      amount = 967.5;
    }
    if (salary >= 21750 && salary <= 22249.99) {
      amount = 990.0;
    }
    if (salary >= 22250 && salary <= 22749.99) {
      amount = 1012.5;
    }
    if (salary >= 22750 && salary <= 23249.99) {
      amount = 1035.0;
    }
    if (salary >= 23250 && salary <= 23749.99) {
      amount = 1057.05;
    }
    if (salary >= 23750 && salary <= 24249.99) {
      amount = 1080.0;
    }
    if (salary >= 24250 && salary <= 24749.99) {
      amount = 1102.05;
    }
    if (salary >= 24750) {
      amount = 1125.0;
    }
    return amount;
  };

  const handleSelectedDate = () => {
    var params = {
      date_from: dateFrom,
      date_to: dateTo,
      employee_id,
      management_id: users.management_id,
    };
    var error = [];
    if (
      parseInt(new Date(dateFrom).getTime()) >
      parseInt(new Date(dateTo).getTime())
    ) {
      error = "error";
      Notify.fieldInvalid("date from");
    }
    if (error.length > 0) {
      console.log("form has an error");
    } else {
      axios
        .get("hmis/payroll/payslip/report-bydate", { params })
        .then((response) => {
          const data = response.data;
          setPayslip({ data, ready: true });
        })
        .catch((error) => Notify.requestError(error));
    }
  };

  const trackCurrentPay = (rate, daily, monthly, days) => {
    var result = "";
    if (rate === "Monthly") {
      result = (monthly / 26) * days.length;
    }
    if (rate === "Daily") {
      result = daily * days.length;
    }
    return result;
  };

  const calculateTotalDeduction = (deduction) => {
    totalDeduction += parseFloat(deduction);
    return deduction;
  };

  return (
    <Fragment>
      <DialogContent dividers>
        <Box display="flex" className="d-print-none">
          <Box flexGrow={1} className="d-print-none" />
          <Box className="d-print-none">
            <TextField
              fullWidth
              required
              label="Date From"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setDateFrom(e.target.value)}
              margin="dense"
            />
          </Box>
          <Box ml={1} className="d-print-none">
            <TextField
              fullWidth
              required
              label="Date To"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setDateTo(e.target.value);
                setContribution(
                  parseFloat(new Date(e.target.value).getDate()) === 15
                );
              }}
              margin="dense"
            />
          </Box>
          <Box
            className="d-print-none"
            mt={1}
            border={2}
            display="flex"
            width={38}
            height={38}
            ml={1}
            justifyContent="center"
            alignItems="center"
          >
            <IconButton color="primary" onClick={() => handleSelectedDate()}>
              <Search fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        {payslip.ready &&
          (payslip.data.length > 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              ref={samplePrintableDiv}
            >
              <Box width={800} border={2} borderRadius={6}>
                <Box
                  mb={3}
                  display={"flex"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box>
                    <CardMedia
                      style={{ borderRadius: 5, width: 200 }}
                      component="img"
                      src="../../../bmcdc.png"
                    />
                  </Box>
                </Box>
                <Divider />
                <Box m={1}>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    BMCDC ADDRESS
                  </Typography>
                </Box>
                <Divider />
                <Box m={1}>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    Contact Numbers: BMCDC CONTACT NUMBER
                  </Typography>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    Landline: BMCDC LANDLINE
                  </Typography>
                </Box>
                <Divider />
                <Box m={1}>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    Name of Employee: {payslip.data[0]._account_name}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    Period Covered: From: {Notify.createdAt(dateFrom)} To:{" "}
                    {Notify.createdAt(dateTo)}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    Rate Classification: {payslip.data[0].rate_class}
                  </Typography>
                </Box>
                <Divider />
                <Box m={1}>
                  <Typography style={{ fontWeight: "bolder" }} variant="h6">
                    Salary
                  </Typography>
                </Box>
                <Divider />
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography style={{ fontWeight: "bolder" }} variant="h6">
                        Basic Rate
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography
                            style={{ fontWeight: "bolder" }}
                            variant="h6"
                          >
                            Basic Pay
                          </Typography>
                        </Box>
                        <Typography
                          style={{ fontWeight: "bolder" }}
                          variant="h6"
                        >
                          {payslip.data[0].rate_class === "Monthly" &&
                            Notify.convertToNumber(
                              payslip.data[0].mo_salary / 26
                            )}
                          {payslip.data[0].rate_class === "Daily" &&
                            Notify.convertToNumber(
                              payslip.data[0].daily_salary
                            )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography
                            style={{ fontWeight: "bolder" }}
                            variant="h6"
                          >
                            Total of Days
                          </Typography>
                        </Box>
                        <Typography
                          style={{ fontWeight: "bolder" }}
                          variant="h6"
                        >
                          {payslip.data.length}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <Box flexGrow={1} />
                        <Typography
                          style={{ fontWeight: "bolder" }}
                          variant="h6"
                        >
                          {Notify.convertToNumber(
                            trackCurrentPay(
                              payslip.data[0].rate_class,
                              payslip.data[0].daily_salary,
                              payslip.data[0].mo_salary,
                              payslip.data
                            )
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {contribution && (
                  <Fragment>
                    <Divider />
                    <Box m={1}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box display="flex">
                            <Box flexGrow={1}>
                              <Typography
                                style={{ fontWeight: "bolder" }}
                                variant="h6"
                              >
                                Pag-IBIG
                              </Typography>
                            </Box>
                            <Typography
                              style={{ fontWeight: "bolder" }}
                              variant="h6"
                            >
                              {payslip.data[0].rate_class === "Monthly" &&
                                Notify.convertToNumber(
                                  calculateTotalDeduction(
                                    payslip.data[0].mo_salary * 0.02
                                  )
                                )}
                              {payslip.data[0].rate_class === "Daily" &&
                                Notify.convertToNumber(
                                  calculateTotalDeduction(
                                    trackCurrentPay(
                                      payslip.data[0].rate_class,
                                      payslip.data[0].daily_salary,
                                      payslip.data[0].mo_salary,
                                      payslip.data
                                    ) * 0.02
                                  )
                                )}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography />
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                    <Box m={1}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box display="flex">
                            <Box flexGrow={1}>
                              <Typography
                                style={{ fontWeight: "bolder" }}
                                variant="h6"
                              >
                                SSS
                              </Typography>
                            </Box>
                            <Typography
                              style={{ fontWeight: "bolder" }}
                              variant="h6"
                            >
                              {payslip.data[0].rate_class === "Monthly" &&
                                Notify.convertToNumber(
                                  calculateTotalDeduction(
                                    checkSSSBracket(payslip.data[0].mo_salary)
                                  )
                                )}
                              {payslip.data[0].rate_class === "Daily" &&
                                Notify.convertToNumber(
                                  calculateTotalDeduction(
                                    checkSSSBracket(
                                      trackCurrentPay(
                                        payslip.data[0].rate_class,
                                        payslip.data[0].daily_salary,
                                        payslip.data[0].mo_salary,
                                        payslip.data
                                      )
                                    )
                                  )
                                )}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography />
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                    <Box m={1}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box display="flex">
                            <Box flexGrow={1}>
                              <Typography
                                style={{ fontWeight: "bolder" }}
                                variant="h6"
                              >
                                PHIC
                              </Typography>
                            </Box>
                            <Typography
                              style={{ fontWeight: "bolder" }}
                              variant="h6"
                            >
                              {payslip.data[0].rate_class === "Monthly" &&
                                Notify.convertToNumber(
                                  calculateTotalDeduction(
                                    payslip.data[0].mo_salary * 0.03
                                  )
                                )}
                              {payslip.data[0].rate_class === "Daily" &&
                                Notify.convertToNumber(
                                  calculateTotalDeduction(
                                    trackCurrentPay(
                                      payslip.data[0].rate_class,
                                      payslip.data[0].daily_salary,
                                      payslip.data[0].mo_salary,
                                      payslip.data
                                    ) * 0.03
                                  )
                                )}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography />
                        </Grid>
                      </Grid>
                    </Box>
                  </Fragment>
                )}

                {/* <Divider />
              <Box m={1}>
                <IconButton
                  variant="contained"
                  color={"secondary"}
                  className={ishidden ? "d-none" : "d-block"}
                  onClick={() => setDeductionDialog(true)}
                >
                  <AddCircle fontSize="small" />
                </IconButton>
              </Box> */}

                <Divider />
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography
                            style={{ fontWeight: "bolder" }}
                            variant="h6"
                          >
                            Total Deduction
                          </Typography>
                        </Box>
                        <Typography
                          style={{ fontWeight: "bolder" }}
                          variant="h6"
                        >
                          {Notify.convertToNumber(totalDeduction)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <Box flexGrow={1} />
                        <Typography
                          style={{ fontWeight: "bolder" }}
                          variant="h6"
                        >
                          {Notify.convertToNumber(totalDeduction)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box m={1}>
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <Typography style={{ fontWeight: "bolder" }} variant="h6">
                        NET PAY
                      </Typography>
                    </Box>
                    <Typography style={{ fontWeight: "bolder" }} variant="h6">
                      Php{" "}
                      {Notify.convertToNumber(
                        trackCurrentPay(
                          payslip.data[0].rate_class,
                          payslip.data[0].daily_salary,
                          payslip.data[0].mo_salary,
                          payslip.data
                        ) - totalDeduction
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box m={1}>
                  <Typography variant="h6">
                    Note: This payslip serves as the official receipts for the
                    deductions made against your gross salary. Only items not
                    preprinted in this forms requires O.R. Please keep this for
                    your file.
                  </Typography>
                </Box>
                <Divider />
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography style={{ fontWeight: "bolder" }} variant="h6">
                        Received by:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontWeight: "bolder" }} variant="h6">
                        Certified by:
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        style={{ fontWeight: "bolder" }}
                        align="center"
                        variant="h6"
                      >
                        {payslip.data.length > 0 &&
                          `${payslip.data[0]._account_name}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        style={{ fontWeight: "bolder" }}
                        align="center"
                        variant="h6"
                      >
                        {sidebarHeader.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        style={{ fontWeight: "bolder" }}
                        align="center"
                        variant="h6"
                      >
                        Employee
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        style={{ fontWeight: "bolder" }}
                        align="center"
                        variant="h6"
                      >
                        Payroll Incharge
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={"100%"}
              height="calc(50vh - 180px)"
            >
              <Typography color="secondary" variant="h4">
                No record found for this dates.
              </Typography>
            </Box>
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => close()}
          variant="contained"
          color={"default"}
          className="d-print-none"
        >
          close
        </Button>

        <Button
          variant="contained"
          color={"primary"}
          onClick={handleSaveAsImage}
          className="d-print-none"
        >
          SaveAsImage
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default PayrollPayslip;
