import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
  Fab,
  createStyles,
} from "@material-ui/core";
import { Close, Print } from "@material-ui/icons";
import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  Fragment,
} from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ImagingPrintOrder = ({ formheader, selectedImagingId, close }) => {
  const { users } = useContext(UsersData);
  const [printResult, setPrintResult] = useState({ data: [], ready: false });
  const classes = useStyles();

  const getAllPrintOrder = useCallback(async () => {
    var params = {
      managament_id: users.managament_id,
      user_id: users.user_id,
      main_mgmt_id: users.main_mgmt_id,
      imaging_center_id: selectedImagingId,
    };
    try {
      let response = await axios.get("imaging/get/print-order", { params });
      const data = response.data;
      setPrintResult({ data, ready: true });
    } catch (error) {
      console.log(error);
    }
  }, [users, selectedImagingId]);

  useEffect(() => {
    getAllPrintOrder();
  }, [selectedImagingId, getAllPrintOrder]);

  return (
    <>
      <Fragment>
        {printResult.ready ? (
          printResult.data && (
            <Fragment>
              <Card elevation={0}>
                <Box display="flex" justifyContent="center">
                  <Box mt={2}>
                    {formheader && (
                      <CardMedia
                        style={{ width: 70 }}
                        component={"img"}
                        src={`${imageLocation}laboratory/logo/${formheader.logo}`}
                      />
                    )}
                  </Box>
                  <Box>
                    <CardHeader
                      component={Box}
                      align="center"
                      title={formheader && formheader.name}
                      subheader={
                        <Box>
                          <Typography>
                            {formheader && formheader.address}
                          </Typography>
                          <Typography>
                            {formheader && formheader.contact_number}
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                </Box>

                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Box mb={1}>
                          <Typography>
                            <Typography
                              variant="caption"
                              className="font-weight-bold"
                            >
                              PATIENT NAME:{" "}
                            </Typography>
                            <span className="text-capitalize">
                              {`${printResult.data.lastname}, ${
                                printResult.data.firstname
                              } ${
                                printResult.data.middle === null
                                  ? ""
                                  : printResult.data.middle
                              }`}
                            </span>
                          </Typography>
                        </Box>

                        <Box display="flex" mb={1}>
                          <Box>
                            <Typography>
                              <Typography
                                variant="caption"
                                className="font-weight-bold"
                              >
                                AGE:{" "}
                              </Typography>
                              {printResult.data.birthday === null
                                ? "None"
                                : Notify.calculateAge(
                                    printResult.data.birthday
                                  )}
                            </Typography>
                          </Box>
                          <Box ml={5}>
                            <Typography>
                              <Typography
                                variant="caption"
                                className="font-weight-bold"
                              >
                                GENDER:{" "}
                              </Typography>
                              {printResult.data.gender === null
                                ? "None"
                                : printResult.data.gender}
                            </Typography>
                          </Box>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <Typography
                              variant="caption"
                              className="font-weight-bold"
                            >
                              PATIENT ADDRESS:{" "}
                            </Typography>
                            <span className="text-capitalize">
                              {`${printResult.data.street} ${printResult.data.barangay} ${printResult.data.city} `}
                            </span>
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography>
                            <Typography
                              variant="caption"
                              className="font-weight-bold"
                            >
                              DATE:{" "}
                            </Typography>
                            {Notify.createdAt(printResult.data.created_at)}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box display="flex" justifyContent="center">
                        <Box flexGrow={1} />
                        <Box>
                          {printResult.data.image !== null ? (
                            <CardMedia
                              style={{ width: 120 }}
                              component={"img"}
                              src={`${imageLocation}patients/${printResult.data.image}`}
                            />
                          ) : (
                            <CardMedia
                              style={{ width: 110 }}
                              component={"img"}
                              src={`${imageLocation}doctor/no-image.jpg`}
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            style={{ fontWeight: "bolder" }}
                          >
                            {printResult.data.imaging_order}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Box display="flex" m={5}>
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bolder" }}
                              >
                                RESULT:
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={9}>
                          <Box display="flex" m={5}>
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bolder" }}
                              >
                                {printResult.data.imaging_result}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Box display="flex" ml={5} mr={5}>
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bolder" }}
                              >
                                IMPRESSION:
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={9}>
                          <Box display="flex" ml={5} mr={5}>
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bolder" }}
                              >
                                {printResult.data.imaging_results_remarks}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Box my={4} align="center">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Box align="center">
                          <Box mt={5}>
                            <Typography
                              className={`gtc-uppercase`}
                              variant="subtitle2"
                            >
                              {formheader && formheader.radiologist}
                            </Typography>
                            <Box borderTop={1}>
                              <b> RADIOLOGIST </b>
                            </Box>
                            <Box>
                              <Typography
                                className={`gtc-uppercase`}
                                variant="subtitle2"
                              >
                                {formheader && formheader.radiologist_lcn}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box className="d-print-none">
                    <Fab
                      aria-label={"Print"}
                      className={classes.fab2}
                      color={"secondary"}
                      onClick={() => close()}
                    >
                      <Close />
                    </Fab>
                    <Fab
                      aria-label={"Print"}
                      className={classes.fab}
                      color={"primary"}
                      onClick={() => window.print()}
                    >
                      <Print />
                    </Fab>
                  </Box>
                </CardContent>
              </Card>
              <Box className="d-print-none"></Box>
            </Fragment>
          )
        ) : (
          <Box
            display="flex"
            height={250}
            justifyContent="center"
            alignItems="center"
          >
            {Notify.loading()}
          </Box>
        )}
      </Fragment>
    </>
  );
};

export default ImagingPrintOrder;

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      width: "calc(100vw - 50px)",
      height: "calc(100vh - 90px)",
      justifyContent: "center",
      alignItems: "center",
    },
    idprint: {
      display: "block",
      width: "234px",
      height: "auto",
    },
    fab2: {
      position: "absolute",
      bottom: theme.spacing(10),
      right: theme.spacing(2),
    },

    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    picturepatient: {
      width: 110,
      height: 110,
    },
    thumbnail: {
      height: 106,
    },
    infopatient: {
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
    },
    [`@media print`]: {
      body: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflow: "visible",
      },
      idprint: {
        width: 275,
        height: 410,
      },
      titleCity: {
        fontSize: 30,
        fontWeight: "bolder",
      },
      qrSize: {
        width: 150,
        height: 150,
      },
      picturepatient: {
        width: 120,
        height: 120,
      },
      thumbnail: {
        height: 116,
      },
      infopatient: {
        padding: 0,
        fontSize: 14,
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      },
      infopatient2: {
        fontSize: 11,
      },
    },
  })
);
