import {
	Box,
	Button,
	Card,
	TextField,
	TextareaAutosize,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "../styles/styles";
import ButtonV3 from "src/components/ButtonV3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import ImagePicker from "src/components/forms/ImagePicker";
import Img from "src/components/Img";
import TextInputField from "src/components/forms/TextInputField";
import axios from "axios";
import { toast } from "react-toastify";
import { dataURItoBlob } from "src/helpers/utils";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";

const useStyles = makeStyles((theme) => ({
	button: {
		width: "100%",
		fontSize: "18px",
		padding: "6px",
	},
}));

export default function CisAddPatientsImaging({
	refreshData,
	setShown,
	patient,
}) {
	const classes = useStyles();
	const [dateAndTime, setDateAndTime] = useState("");
	const [imagePreview, setImagePreview] = useState(null);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		const date = new Date();
		const mins = date.getMinutes().toString().padStart(2, "0");
		const hh = date.getHours();
		const mm = date.getMonth();
		const dd = date.getDate();
		const yyyy = date.getFullYear();

		const time = () => {
			if (hh > 12) {
				const hour = hh - 12;
				setDateAndTime(`${mm}/${dd}/${yyyy} - ${hour}:${mins}  PM`);
			} else {
				setDateAndTime(`${mm}/${dd}/${yyyy} - ${hh}:${mins}  AM`);
			}
		};

		return time();
	}, []);

	const onSubmit = (data) => {
		console.log(data);
		let formData = new FormData();
		// formData.append("DateAndTime", data?.DateAndTime || "");
		formData.append("description", data?.description || "");
		formData.append("type", data?.type || "");
		formData.append("patient_id", patient?.id || "");

		if (imagePreview) {
			const file = dataURItoBlob(imagePreview);
			formData.append("image", file);
		}
		// console.log(formData);
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		let url = "/v1/laboratory/imaging/store";
		axios
			.post(url, formData, config)
			.then((res) => {
				setTimeout(() => {
					refreshData();
					toast.success("Imaging successfully added!");
				}, 200);
				setShown(true);
				// hide();
			})
			.catch((err) => {
				toast.error("Error adding image, please check your input!");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className="flex w-full flex-col">
			<div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
				<div className="lg:col-span-7">
					<Controller
						name="image"
						control={control}
						rules={{
							required: {
								value: true,
								message: "This field is required",
							},
						}}
						render={({
							field: { onChange, onBlur, value, name, ref },
							fieldState: { invalid, isTouched, isDirty, error },
						}) => (
							<ImagePicker
								className={`rounded-full w-full bg-primary bg-opacity-40 group-hover:bg-primary group-hover:bg-opacity-100 duration-200 aspect-[3/2] ${
									errors?.image?.message
										? " border !border-danger"
										: ""
								}`}
								onChange={(data) => {
									setImagePreview(data);
									onChange(data);
									// setUser((prevData) => ({
									// 	...prevData,
									// 	avatar: data,
									// }));
									// setAvatar(data);
								}}
							>
								{() => {
									return (
										<Img
											key={`imgng-${patient?.id}`}
											src={
												imagePreview
													? imagePreview
													: `/no-image.png`
											}
											name={patient?.name}
											// type="user"
											alt=""
											className="object-contain bg-slate-300 w-full h-full rounded-lg"
										/>
									);
								}}
							</ImagePicker>
						)}
					/>

					{errors?.image?.message ? (
						<p className="-mt-2 text-sm text-red-600 dark:text-red-500 mb-0">
							{errors?.image?.message}
						</p>
					) : (
						""
					)}
				</div>
				<div className="lg:col-span-5 flex flex-col gap-y-6">
					<Controller
						name="type"
						control={control}
						rules={{
							required: {
								value: true,
								message: "This field is required",
							},
						}}
						render={({
							field: { onChange, onBlur, value, name, ref },
							fieldState: { invalid, isTouched, isDirty, error },
						}) => (
							<ReactSelectInputField
								isClearable={false}
								label={
									<>
										Select Laboratory Result Type
										<span className="text-danger ml-1">
											*
										</span>
									</>
								}
								inputClassName=" "
								ref={ref}
								value={value}
								onChange={onChange}
								onChangeGetData={(data) => {
									onChange(data?.value);
								}} // send value to hook form
								onBlur={onBlur} // notify when input is touched
								error={error?.message}
								placeholder="Select type"
								options={[
									{
										value: "x-ray",
										label: "X-ray",
									},
								]}
							/>
						)}
					/>
					<TextInputField
						label="Image Description"
						placeholder="Enter imagedescription"
						{...register("description", {
							required: {
								value: true,
								message: "This field is required.",
							},
						})}
						error={errors?.description?.message}
					/>
					<ButtonV3 onClick={handleSubmit(onSubmit)}>Submit</ButtonV3>
				</div>
			</div>
			{/* <form>
				<div className="mx-auto" style={styles.parentContainerAdd}>
					<Card elevation={3} className="p-4" component={Box}>
						<div style={styles.divContainerColumn}>
							<div style={styles.divContainer}>
								<input
									accept="image/*"
									id="contained-button-file"
									multiple
									type="file"
									style={{ display: "none" }}
									onChange={handleImageChange}
								/>
								<div className="border-2 p-2 h-full mb-3">
									{imagePreview ? (
										<img
											className="mx-auto"
											src={imagePreview}
											alt="Uploaded"
											style={{
												maxWidth: "70%",
												marginTop: 10,
												marginBottom: 10,
											}}
										/>
									) : (
										<img
											className="mx-auto"
											src="/no-image.png"
											alt="no image"
											style={{
												maxWidth: "70%",
												marginTop: 10,
												marginBottom: 10,
											}}
										/>
									)}
								</div>
								<label
									className="mx-auto"
									htmlFor="contained-button-file"
								>
									<ButtonV3 type="success">
										<div className="flex">
											<FontAwesomeIcon
												icon={faCloud}
												color="primary"
												style={{ fontSize: 18 }}
												className="pr-2"
											/>
											Upload Image
										</div>
									</ButtonV3>
								</label>
							</div>
							<div>
								<div style={styles.divContainer}>
									<TextField
										label={
											<label style={styles.label}>
												Description
											</label>
										}
										variant="outlined"
										fullWidth
										{...register("description")}
									/>
								</div>
								<div style={styles.divContainer}>
									<TextField
										label={
											<label style={styles.label}>
												Date And Time
											</label>
										}
										variant="outlined"
										value={dateAndTime}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										{...register("DateAndTime", {
											required: true,
										})}
									/>
								</div>
							</div>
						</div>
					</Card>
				</div>
				<div className="mx-auto" style={styles.button}>
					<ButtonV3 onClick={handleSubmit(onSubmit)}>Submit</ButtonV3>
				</div>
			</form> */}
		</div>
	);
}
