import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  TableBody,
  Dialog,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { Send, X, Check } from "react-feather";
import axios from "axios";
import { UsersData } from "src/ContextAPI";

const CashierSOATempDetails = ({ selectedBranch, branches }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { users } = useContext(UsersData);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [total, setTotal] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [report, setReport] = useState({
    data: [],
    ready: false,
  });

  console.log("selectedBranch", selectedBranch);

  const getSOATemp = useCallback(() => {
    var params = {
      management_id: selectedBranch.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    axios
      .get("cashier/get/soa-temp/list", { params })
      .then((response) => {
        const data = response.data;
        setReport({ data: data, ready: true });
        setTotal(calculateAmount(data));
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users, selectedBranch.management_id]);

  const calculateAmount = (data) => {
    var total = 0;
    for (let x = 0; x < data.length; x++) {
      total += data[x].bill_amount * 1;
    }
    return total;
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleDeleteSOATemp = () => {
    var formdata = new FormData();
    formdata.set("csat_id", selectedData.csat_id);
    formdata.set("trace_number", selectedData.trace_number);
    formdata.set("cpr_id", selectedData.cpr_id);

    axios
      .post("cashier/remove/soa-temp/list", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          getSOATemp();
          setOpenDialog(false);
          setSelectedData(null);
          Notify.successRequest("delete SOA");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleAddToSOA = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    setIsSubmitting(true);
    axios
      .post("cashier/create/add-all-temp/to-soa-list", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "pass-invalid") {
          Notify.fieldInvalid("password");
        }
        if (data === "success") {
          getSOATemp();
          setConfirmDialog(false);
          Notify.successRequest("create SOA");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    getSOATemp();
    // eslint-disable-next-line
  }, [getSOATemp]);

  return (
    <>
      <Box component={Paper} p={2}>
        <Box mb={1}>
          <Typography variant="h6" color="textSecondary">
            <b>SOA Temporary</b>
          </Typography>
        </Box>

        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <b> Date </b>
                </TableCell>
                <TableCell align="center">
                  <b> Control No. </b>
                </TableCell>
                <TableCell align="center">
                  <b> Patient </b>
                </TableCell>
                <TableCell align="center">
                  <b> Amount </b>
                </TableCell>
                <TableCell align="center">
                  <b> Action </b>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {report.ready ? (
                report.data.length > 0 ? (
                  (limit > 0
                    ? report.data.slice(page * limit, page * limit + limit)
                    : report.data
                  ).map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>{Notify.createdAt(data.created_at)}</TableCell>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>
                        {data.lname}, {data.fname}
                        <br />
                        <span style={{ marginLeft: 5 }}>
                          <b>&bull;{data.bill_name}</b>
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        {Notify.convertToNumber(data.bill_amount)}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            setOpenDialog(true);
                            setSelectedData(data);
                            // handleDeleteSOATemp(data)
                          }}
                        >
                          <X size={15} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography color="secondary">No data added.</Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography color="primary">
                      Loading, Please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={3} align={"right"}>
                  <Typography>
                    <b>Total:</b>
                  </Typography>
                </TableCell>
                <TableCell align={"right"}>
                  {Notify.convertToNumber(total)}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>

          <Box
            display="flex"
            mt={1}
            hidden={report.data.length > 0 ? false : true}
          >
            <Box flexGrow={1} />
            <Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<Send size={15} />}
                onClick={() => setConfirmDialog(true)}
              >
                CONFIRM
              </Button>
            </Box>
          </Box>

          <TablePagination
            component={Box}
            count={report.data.length}
            labelRowsPerPage="List"
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[
              10,
              20,
              50,
              { value: report.data.length, label: "All" },
            ]}
          />
        </TableContainer>
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <Box>
            <Typography>
              Are you sure to remove this data from SOA temp list?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Check style={{ width: 15 }} />}
            onClick={handleDeleteSOATemp}
          >
            Yes
          </Button>

          <Button
            variant="contained"
            color="default"
            startIcon={<X style={{ width: 15 }} />}
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogContent>
          <form onSubmit={handleAddToSOA}>
            <Box>
              <Typography>Confirm SOA</Typography>
            </Box>
            <Box my={2}>
              <TextField
                fullWidth
                required
                name="bill_out_branch"
                label="Select Branch"
                variant="outlined"
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <option value={""}>Select</option>
                {branches.length > 0 &&
                  branches.map((item) => (
                    <option
                      key={item.id}
                      value={item.management_id}
                      disabled={item.management_id === users.management_id}
                    >
                      {item.name}
                    </option>
                  ))}
              </TextField>
            </Box>

            <Box mb={2}>
              <TextField
                fullWidth
                required
                name="password"
                label="Enter your password"
                variant="outlined"
                type="password"
              />
            </Box>

            <Box display={"flex"}>
              <Box flexGrow={1} />

              <Box mr={1}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => setConfirmDialog(false)}
                >
                  Cancel
                </Button>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting && (
                      <CircularProgress size={20} color="inherit" />
                    )
                  }
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CashierSOATempDetails;
