import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useContext, useCallback } from "react";
import { useReactToPrint } from "react-to-print";
import FormHeader from "./FormHeader";
import { CheckSquare, Edit, Printer, XCircle } from "react-feather";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const FormTwelve = ({ patient_id, details, info, doctorsList, allowEdit }) => {
  const printableRef = React.useRef();
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [isProcess, setIsProcess] = React.useState(false);
  const { users } = useContext(UsersData);
  const [existingOperativeRecordChart, setExistingOperativeRecordChart] =
    React.useState({
      data: [],
      ready: false,
    });

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const getOperativeRecordChart = useCallback(async () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/operativerecord", { params })
      .then((response) => {
        const data = response.data;
        setExistingOperativeRecordChart({ data, ready: true });
      })
      .catch((error) => console.log(error));
  }, [patient_id, users, details]);

  const handleSubmitOperativeRecordChart = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", details.trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    setIsProcess(true);
    axios
      .post("or/update/chart/operativerecord", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          getOperativeRecordChart();
          setEnableEdit(!enableEdit);
          Notify.successRequest("");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  useEffect(() => {
    getOperativeRecordChart();
  }, [patient_id, getOperativeRecordChart]);

  return (
    <Box>
      <Card ref={printableRef}>
        <Box m={2}>
          <Box>
            <FormHeader />
          </Box>
          <Box>
            <form onSubmit={handleSubmitOperativeRecordChart}>
              <Box my={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          disabled={isProcess}
                          color="primary"
                          startIcon={<CheckSquare />}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            Edit
                          </Button>
                        </Box>
                      )}
                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              <Box align="center" mb={2}>
                <Typography>
                  <b> OPERATIVE RECORD </b>
                </Typography>
              </Box>

              <Box display={"flex"} mb={1}>
                <Box flexGrow={1} />
                <Box>
                  <Typography variant="subtitle2">
                    <span style={{ color: "#000", fontWeight: "bolder" }}>
                      Date:
                    </span>{" "}
                    {!enableEdit
                      ? existingOperativeRecordChart.data.length > 0
                        ? Notify.createdAt(
                            existingOperativeRecordChart.data[0].operative_date
                          )
                        : `_______________`
                      : null}
                  </Typography>
                  {enableEdit && (
                    <>
                      <TextField
                        fullWidth
                        name="operative_date"
                        type="date"
                        defaultValue={
                          existingOperativeRecordChart.data.length > 0
                            ? existingOperativeRecordChart.data[0]
                                .operative_date
                            : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          disableUnderline: !enableEdit,
                          disabled: !enableEdit,
                        }}
                      />

                      <TextField
                        fullWidth
                        name="ccor_id"
                        defaultValue={
                          existingOperativeRecordChart.data.length > 0
                            ? existingOperativeRecordChart.data[0].ccor_id
                            : ""
                        }
                        InputProps={{
                          disableUnderline: !enableEdit,
                          disabled: !enableEdit,
                        }}
                        hidden
                      />
                    </>
                  )}
                </Box>
              </Box>
              <Table className="table-bordered" size="small">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2} style={{ height: 60 }}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Name:
                          </span>
                        </Typography>

                        <Typography>
                          {!enableEdit &&
                            (info
                              ? `${info.lastname}, ${info.firstname} ${
                                  info.middle === null ? "" : info.middle
                                }`
                              : "")}
                        </Typography>

                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="name"
                              defaultValue={
                                info
                                  ? `${info.lastname}, ${info.firstname} ${
                                      info.middle === null ? "" : info.middle
                                    }`
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell colSpan={2} style={{ height: 60 }}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Age:
                          </span>
                        </Typography>
                        <Typography>
                          {!enableEdit &&
                            (info
                              ? `${
                                  info.birthday === null
                                    ? ""
                                    : Notify.calculateAge(info.birthday)
                                }`
                              : "")}
                        </Typography>

                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="age"
                              defaultValue={
                                info
                                  ? `${
                                      info.birthday === null
                                        ? ""
                                        : Notify.calculateAge(info.birthday)
                                    }`
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell colSpan={2} style={{ height: 60 }}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Gender:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0].gender
                              : ""
                            : null}
                        </Typography>

                        <Typography>
                          {!enableEdit && (info ? `${info.gender}` : "")}
                        </Typography>

                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="gender"
                              defaultValue={info ? `${info.gender}` : ""}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} style={{ height: 60 }}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Civil Status:
                          </span>
                        </Typography>

                        <Typography>
                          {!enableEdit && (info ? `${info.civil_status}` : "")}
                        </Typography>

                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="civil_status"
                              defaultValue={info ? `${info.civil_status}` : ""}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell colSpan={2} style={{ height: 60 }}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Room No.:
                          </span>
                        </Typography>

                        <Typography>
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0].room_no
                              : ""
                            : null}
                        </Typography>

                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="room_no"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0].room_no
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell colSpan={2} style={{ height: 60 }}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Admission No.:
                          </span>
                        </Typography>

                        <Typography>
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0]
                                  .admission_no
                              : ""
                            : null}
                        </Typography>

                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="admission_no"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .admission_no
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Preoperative Diagnosis:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0]
                                  .preoperative_diagnosis
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="preoperative_diagnosis"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .preoperative_diagnosis
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Preoperative Condition of the Patient
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0]
                                  .preoperative_condition
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="preoperative_condition"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .preoperative_condition
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Surgeon:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0].surgeon
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="surgeon"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0].surgeon
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Scrub Nurse:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0].scrub_nurse
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="scrub_nurse"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .scrub_nurse
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Anesthesiologist:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0]
                                  .anesthesiologist
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="anesthesiologist"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .anesthesiologist
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Circulating Nurse:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0]
                                  .circulating_nurse
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="circulating_nurse"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .circulating_nurse
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Technique:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0].technique
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="technique"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .technique
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Anesthetics:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0].anesthetics
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="anesthetics"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .anesthetics
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Transfusions:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0].transfusion
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="transfusion"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .transfusion
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Name of Operation:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0]
                                  .name_operation
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="name_operation"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .name_operation
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            RVS Code #:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0].rvs_code
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="rvs_code"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .rvs_code
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Operation Started:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0]
                                  .operation_started
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="operation_started"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .operation_started
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Operation Ended:
                          </span>{" "}
                          {!enableEdit
                            ? existingOperativeRecordChart.data.length > 0
                              ? existingOperativeRecordChart.data[0]
                                  .operation_ended
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="operation_ended"
                              defaultValue={
                                existingOperativeRecordChart.data.length > 0
                                  ? existingOperativeRecordChart.data[0]
                                      .operation_ended
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box mt={2}>
                <Box>
                  <Typography variant="subtitle2">
                    <span style={{ color: "#000", fontWeight: "bolder" }}>
                      Operative Findings:
                    </span>
                  </Typography>
                  {enableEdit && (
                    <>
                      <TextField
                        fullWidth
                        name="operative_finding"
                        defaultValue={
                          existingOperativeRecordChart.data.length > 0
                            ? existingOperativeRecordChart.data[0]
                                .operative_finding
                            : ""
                        }
                        InputProps={{
                          disableUnderline: !enableEdit,
                          disabled: !enableEdit,
                        }}
                        multiline
                      />
                    </>
                  )}
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {!enableEdit
                      ? existingOperativeRecordChart.data.length > 0
                        ? existingOperativeRecordChart.data[0].operative_finding
                        : ""
                      : null}
                  </Typography>
                </Box>
              </Box>
              <Box mt={2}>
                <Box>
                  <Typography variant="subtitle2">
                    <span style={{ color: "#000", fontWeight: "bolder" }}>
                      Technique of Operation:
                    </span>
                  </Typography>
                  {enableEdit && (
                    <>
                      <TextField
                        fullWidth
                        name="technique_operation"
                        defaultValue={
                          existingOperativeRecordChart.data.length > 0
                            ? existingOperativeRecordChart.data[0]
                                .technique_operation
                            : ""
                        }
                        InputProps={{
                          disableUnderline: !enableEdit,
                          disabled: !enableEdit,
                        }}
                        multiline
                      />
                    </>
                  )}
                </Box>
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  {!enableEdit
                    ? existingOperativeRecordChart.data.length > 0
                      ? existingOperativeRecordChart.data[0].technique_operation
                      : ""
                    : null}
                </Typography>
              </Box>
              <Box mt={2}>
                <Box>
                  <Typography variant="subtitle2">
                    <span style={{ color: "#000", fontWeight: "bolder" }}>
                      Post-operative Diagnosis:
                    </span>
                  </Typography>
                  {enableEdit && (
                    <>
                      <TextField
                        fullWidth
                        name="post_operative_diagnosis"
                        defaultValue={
                          existingOperativeRecordChart.data.length > 0
                            ? existingOperativeRecordChart.data[0]
                                .post_operative_diagnosis
                            : ""
                        }
                        InputProps={{
                          disableUnderline: !enableEdit,
                          disabled: !enableEdit,
                        }}
                        multiline
                      />
                    </>
                  )}
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {!enableEdit
                      ? existingOperativeRecordChart.data.length > 0
                        ? existingOperativeRecordChart.data[0]
                            .post_operative_diagnosis
                        : ""
                      : null}
                  </Typography>
                </Box>
              </Box>
              <Box mt={2}>
                <Box>
                  <Typography variant="subtitle2">
                    <span style={{ color: "#000", fontWeight: "bolder" }}>
                      Post-operative Condition of the Patient:
                    </span>
                  </Typography>
                  {enableEdit && (
                    <>
                      <TextField
                        fullWidth
                        name="post_operative_condition"
                        defaultValue={
                          existingOperativeRecordChart.data.length > 0
                            ? existingOperativeRecordChart.data[0]
                                .post_operative_condition
                            : ""
                        }
                        InputProps={{
                          disableUnderline: !enableEdit,
                          disabled: !enableEdit,
                        }}
                        multiline
                      />
                    </>
                  )}
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {!enableEdit
                      ? existingOperativeRecordChart.data.length > 0
                        ? existingOperativeRecordChart.data[0]
                            .post_operative_condition
                        : ""
                      : null}
                  </Typography>
                </Box>
              </Box>

              <Box mt={2}>
                <Box>
                  <Typography variant="subtitle2">
                    <span style={{ color: "#000", fontWeight: "bolder" }}>
                      Hispatology:
                    </span>{" "}
                    {!enableEdit
                      ? existingOperativeRecordChart.data.length > 0
                        ? existingOperativeRecordChart.data[0].hispatology
                        : ""
                      : null}
                  </Typography>
                  {enableEdit && (
                    <>
                      <TextField
                        fullWidth
                        name="hispatology"
                        defaultValue={
                          existingOperativeRecordChart.data.length > 0
                            ? existingOperativeRecordChart.data[0].hispatology
                            : ""
                        }
                        InputProps={{
                          disableUnderline: !enableEdit,
                          disabled: !enableEdit,
                        }}
                        multiline
                      />
                    </>
                  )}
                </Box>
              </Box>

              <Box mt={2}>
                <Box>
                  <Typography variant="subtitle2">
                    <span style={{ color: "#000", fontWeight: "bolder" }}>
                      Sponge Count:
                    </span>{" "}
                    {!enableEdit
                      ? existingOperativeRecordChart.data.length > 0
                        ? existingOperativeRecordChart.data[0].sponge_count
                        : ""
                      : null}
                  </Typography>
                  {enableEdit && (
                    <>
                      <TextField
                        fullWidth
                        name="sponge_count"
                        defaultValue={
                          existingOperativeRecordChart.data.length > 0
                            ? existingOperativeRecordChart.data[0].sponge_count
                            : ""
                        }
                        InputProps={{
                          disableUnderline: !enableEdit,
                          disabled: !enableEdit,
                        }}
                        multiline
                      />
                    </>
                  )}
                </Box>
              </Box>

              <Box mt={12}>
                <Box display={"flex"}>
                  <Box flexGrow={1} />
                  <Box align="center">
                    <Box borderBottom={1}>
                      <Typography variant="subtitle2">
                        {!enableEdit
                          ? existingOperativeRecordChart.data.length > 0
                            ? existingOperativeRecordChart.data[0]
                                .signature_physician
                            : ""
                          : null}
                      </Typography>
                      {enableEdit && (
                        <Box style={{ width: "calc(40vw - 200px)" }}>
                          <TextField
                            fullWidth
                            name="signature_physician"
                            defaultValue={
                              existingOperativeRecordChart.data.length > 0
                                ? existingOperativeRecordChart.data[0]
                                    .signature_physician
                                : ""
                            }
                            InputProps={{
                              disableUnderline: !enableEdit,
                              disabled: !enableEdit,
                            }}
                            multiline
                          />
                        </Box>
                      )}
                    </Box>
                    <Box> Signature Over Printed Name of Physician </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default FormTwelve;
