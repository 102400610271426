import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  CircularProgress,
  CardContent,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import {
  CheckCircleOutline,
  HighlightOff,
  AddCircleOutline,
  Cancel,
} from "@material-ui/icons";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import axios from "axios";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import BankContactList from "./BankContactList";
import BankInformation from "./BankInformation";
import BankRemoveAccount from "./BankRemoveAccount";

const BankAccounts = () => {
  const { users } = useContext(UsersData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [selectedBankName, setSelectedBankName] = useState(null);
  const [selectedBankAddress, setSelectedBankAddress] = useState(null);
  const [selectedBankAccountNo, setSelectedBankAccountNo] = useState(null);
  const [viewContactsDialog, setViewContactsDialog] = useState(false);
  const [viewBankInformation, setViewBankInformation] = useState(false);
  const [viewBankRemoveAccount, setViewBankRemoveAccount] = useState(false);

  const [bank, setBank] = useState({
    data: [],
    ready: true,
  });

  const [_inputNewBankContacts, setInputNewBankContacts] = useState({
    data: [],
    ready: true,
  });

  const handleSelectedAction = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedBankId(data.bank_id);
    setSelectedBankName(data.bank_name);
    setSelectedBankAddress(data.bank_address);
    setSelectedBankAccountNo(data.bank_account_no);
  };

  const handleCloseAction = () => {
    setAnchorEl(null);
    setSelectedBankId(null);
    setSelectedBankName(null);
    setSelectedBankAddress(null);
    setSelectedBankAccountNo(null);
  };

  const getBankAccountList = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    try {
      const request = await axios.get("accounting/get/bank-list", { params });
      const data = request.data;
      setBank({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users]);

  const addField = () => {
    setInputNewBankContacts({
      data: _inputNewBankContacts.data.concat({
        contact_person: "contact_person[]",
        contact_number: "contact_number[]",
        contact_position: "contact_position[]",
      }),
      ready: true,
    });
  };

  const removeField = (index, length) => {
    const list = _inputNewBankContacts.data;
    setInputNewBankContacts({
      ..._inputNewBankContacts,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputNewBankContacts({
      data: list,
      ready: true,
    });
  };

  const handleSubmitNewBankAccount = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);

    var error = [];

    if (
      formdata.get("bank_name").length === 0 ||
      formdata.get("bank_name").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Bank name");
    }
    if (
      formdata.get("bank_address").length === 0 ||
      formdata.get("bank_address").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Bank Address");
    }
    if (
      formdata.get("bank_account_no").length === 0 ||
      formdata.get("bank_account_no").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Account Number");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/add_new_bank_account", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getBankAccountList();
            e.target.reset();
            Notify.successRequest("add bank account");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (users.management_name === "BMCDC HQ") {
      getBankAccountList();
    }
    // eslint-disable-next-line
  }, [getBankAccountList]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmitNewBankAccount}>
                <Box>
                  <Box>
                    <Box>
                      <Typography
                        color="primary"
                        variant={"subtitle1"}
                        style={{ fontWeight: "bolder" }}
                      >
                        CREATE BANK
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <Box>
                          <TextField
                            fullWidth
                            name="bank_name"
                            label="Bank name"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box>
                          <TextField
                            fullWidth
                            name="bank_address"
                            label="Bank Address"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box>
                          <TextField
                            fullWidth
                            name="bank_account_no"
                            label="Account Number"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box flexGrow={1}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        style={{ fontWeight: "bolder" }}
                      >
                        Contact info
                      </Typography>
                    </Box>

                    <Box>
                      <IconButton
                        color="secondary"
                        onClick={
                          _inputNewBankContacts.data.length > 0
                            ? () =>
                                removeField(
                                  1,
                                  _inputNewBankContacts.data.length
                                )
                            : (e) => e.preventDefault()
                        }
                      >
                        <Cancel fontSize={isMobile ? "small" : "default"} />
                      </IconButton>
                    </Box>

                    <Box>
                      <IconButton color="primary" onClick={addField}>
                        <AddCircleOutline
                          fontSize={isMobile ? "small" : "default"}
                        />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box>
                    {_inputNewBankContacts.ready &&
                      _inputNewBankContacts.data.map((data, index) => {
                        return (
                          <Box
                            key={index}
                            border={1}
                            borderRadius={4}
                            borderColor={"#AEAEAE"}
                            mb={1}
                          >
                            <Grid component={Box} p={1} container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name={data.contact_person}
                                    label="Person"
                                    variant="outlined"
                                    required
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name={data.contact_number}
                                    label="Number"
                                    variant="outlined"
                                    required
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name={data.contact_position}
                                    label="Position"
                                    variant="outlined"
                                    required
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                  </Box>

                  <Box my={1}>
                    <TextField
                      fullWidth
                      name="password"
                      type="password"
                      label="Password"
                      variant="outlined"
                    />
                  </Box>

                  <Box mb={1} display="flex">
                    <Box flexGrow={1} />

                    <Button
                      variant="contained"
                      color="default"
                      type="reset"
                      startIcon={<HighlightOff />}
                    >
                      Clear
                    </Button>

                    <Box ml={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={
                          isSubmitting ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <CheckCircleOutline />
                          )
                        }
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Card>
            <CardContent>
              <Box>
                <Box>
                  <Typography
                    color="primary"
                    variant={"subtitle1"}
                    style={{ fontWeight: "bolder" }}
                  >
                    BANK ACCOUNTS
                  </Typography>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Bank</TableCell>
                        <TableCell align="center">Address</TableCell>
                        <TableCell align="center">Account Number</TableCell>
                        <TableCell align="center">Balance</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bank.ready ? (
                        bank.data.length > 0 ? (
                          bank.data.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell>{data.bank_name}</TableCell>
                              <TableCell> {data.bank_address} </TableCell>
                              <TableCell> {data.bank_account_no} </TableCell>
                              <TableCell align="right">
                                {Notify.convertToNumber(
                                  data.total_bank_balance
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={(e) => handleSelectedAction(e, data)}
                                >
                                  <MoreVertIcon fontSize="small" />
                                </IconButton>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleCloseAction}
                                >
                                  <MenuItem
                                    onClick={() => setViewContactsDialog(true)}
                                  >
                                    Contacts
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => setViewBankInformation(true)}
                                  >
                                    Edit Info
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      setViewBankRemoveAccount(true)
                                    }
                                  >
                                    Remove Bank
                                  </MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={5}>
                              <Typography color="secondary">
                                No record found.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Typography color="primary">
                              Loading, please wait...
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Dialog
          open={viewContactsDialog}
          fullWidth
          maxWidth="sm"
          onClose={() => setViewContactsDialog(false)}
        >
          <DialogTitle>Contant List for {selectedBankName}</DialogTitle>
          <DialogContent>
            <BankContactList bankID={selectedBankId} />
          </DialogContent>
        </Dialog>

        <Dialog
          open={viewBankInformation}
          fullWidth
          maxWidth="xs"
          onClose={() => setViewBankInformation(false)}
        >
          <DialogTitle>Edit Bank Information</DialogTitle>
          <DialogContent>
            <BankInformation
              selectedBankAddress={selectedBankAddress}
              selectedBankName={selectedBankName}
              selectedBankId={selectedBankId}
              selectedBankAccountNo={selectedBankAccountNo}
              getBankAccountList={() => getBankAccountList()}
              close={() => {
                setViewBankInformation(false);
              }}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={viewBankRemoveAccount}
          fullWidth
          maxWidth="xs"
          onClose={() => setViewBankRemoveAccount(false)}
        >
          <DialogTitle>Remove Bank Account</DialogTitle>
          <DialogContent>
            <BankRemoveAccount
              selectedBankAddress={selectedBankAddress}
              selectedBankName={selectedBankName}
              selectedBankId={selectedBankId}
              selectedBankAccountNo={selectedBankAccountNo}
              getBankAccountList={() => getBankAccountList()}
              close={() => {
                setViewBankRemoveAccount(false);
              }}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

export default BankAccounts;
