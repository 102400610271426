import React, { useCallback, useEffect, useState } from "react";
import {
	Grid,
	TextField,
	CircularProgress,
	Typography,
	Box,
} from "@material-ui/core";
import axios from "axios";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";

var imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL;

const VirtualOrderReportDetails = ({ selectedAttachmentID }) => {
	var { users } = React.useContext(UsersData);

	const [details, setDetails] = useState([{ data: [], ready: false }]);

	const getImagingDetails = useCallback(() => {
		var params = {
			user_id: users.user_id,
			selectedAttachmentID: selectedAttachmentID,
		};
		axios
			.get("imaging/virtual/order/reports-details", { params })
			.then((response) => {
				const data = response.data;
				setDetails({ data: data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	}, [selectedAttachmentID, users]);

	useEffect(() => {
		getImagingDetails();
		// eslint-disable-next-line
	}, [getImagingDetails]);

	return (
		<>
			<Box>
				{details.ready ? (
					details.data && (
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Box>
									<TextField
										label="Name"
										variant="outlined"
										fullWidth
										multiline
										defaultValue={details.data[0].name}
										InputProps={{
											readOnly: true,
										}}
									/>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box>
									<TextField
										label="Contact"
										variant="outlined"
										fullWidth
										multiline
										defaultValue={
											details.data[0].mobile === null
												? details.data[0].telephone
												: details.data[0].mobile
										}
										InputProps={{
											readOnly: true,
										}}
									/>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box>
									<TextField
										label="Gender"
										variant="outlined"
										fullWidth
										multiline
										defaultValue={details.data[0].gender}
										InputProps={{
											readOnly: true,
										}}
									/>
								</Box>
							</Grid>

							<Grid item xs={6}>
								<Box>
									<TextField
										label="Age"
										variant="outlined"
										fullWidth
										multiline
										defaultValue={
											new Date().toLocaleString("en-US", { year: "numeric" }) -
												new Date(details.data[0].birthday).toLocaleString(
													"en-US",
													{ year: "numeric" }
												) >
											0
												? new Date().toLocaleString("en-US", {
														year: "numeric",
												  }) -
												  new Date(details.data[0].birthday).toLocaleString(
														"en-US",
														{ year: "numeric" }
												  ) +
												  " years old"
												: "months"
										}
										InputProps={{
											readOnly: true,
										}}
									/>
								</Box>
							</Grid>

							<Grid item xs={12}>
								<Box>
									<TextField
										label="Result"
										variant="outlined"
										fullWidth
										multiline
										defaultValue={
											details.data[0].imaging_result === null
												? "Still reading..."
												: details.data[0].imaging_result
										}
										InputProps={{
											readOnly: true,
										}}
									/>
								</Box>
							</Grid>

							<Grid item xs={12}>
								<Box>
									<TextField
										label="Order"
										variant="outlined"
										fullWidth
										multiline
										defaultValue={details.data[0].imaging_order}
										InputProps={{
											readOnly: true,
										}}
									/>
								</Box>
							</Grid>

							<Grid item xs={12}>
								<Box>
									<Typography>Attachments</Typography>
								</Box>
								<Box>
									{details.data[0].imaging_result_attachment
										.split(",")
										.map(function (data, index) {
											return (
												<div className="float-left text-center" key={index}>
													<img
														src={imageLocation + "imaging/" + data}
														className="img-fluid border ml-2"
														style={{ height: "150px", width: "auto" }}
														alt=""
													/>
												</div>
											);
										})}
								</Box>
							</Grid>
						</Grid>
					)
				) : (
					<Box width="100%" mb={3}>
						<Box display="flex" justifyContent="center" alignItems="center">
							<CircularProgress />
						</Box>
						<Box display="flex" justifyContent="center" alignItems="center">
							<Typography variant="h5" color="primary">
								Please wait...
							</Typography>
						</Box>
					</Box>
				)}
			</Box>
		</>
	);
};

export default VirtualOrderReportDetails;
