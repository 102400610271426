import React, { Fragment, useState } from "react";
import Container from "src/layout/Container";
import {
	Grid,
	Box,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	List,
	ListItem,
	Avatar,
	ListItemText,
	TablePagination,
	TextField,
	InputAdornment,
	Typography,
	ListItemIcon,
	makeStyles,
	Button,
	Dialog,
	Zoom,
	DialogTitle,
	CardHeader,
	CardContent,
	Card,
	IconButton,
	Slide,
} from "@material-ui/core";
// import EditIcon from '@material-ui/icons/Edit';
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";
// import PersonIcon from '@material-ui/icons/Person';
import { ArrowBack, Search } from "@material-ui/icons";
import PayrollEmployeesInfo from "./PayrollEmployeesInfo";
import PayrollPayslip from "./PayrollPayslip";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import PayrollSummary from "./PayrollSummary";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
	primary: {
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
}));

const PayrollDashboard = () => {
	const classes = useStyles();
	const [list, setList] = useState({ data: [], ready: false });
	const [employee, setEmployee] = useState({ data: [], ready: false });
	const [search, setSearch] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [selectedEmployee, setSelectedEmployee] = useState("all-payroll");
	const [selectedName, setSelectedName] = useState(null);
	const [selectedType, setSelectedType] = useState(null);
	const [selectedEmail, setSelectedEmail] = useState(null);
	const [payrollDialog, setPayrollDialog] = useState(false);
	const [payrollSummaryDialog, setPayrollSummaryDialog] = useState(false);
	const [branchList, setBranchList] = useState({ data: [], ready: false });
	const [selectedBranch, setSelectedBranch] = useState({
		name: "All",
		id: "All",
	});

	const { users } = React.useContext(UsersData);

	const getAllActiveEmployee = React.useCallback(() => {
		var params = {
			user_id: users.user_id,
			management_id: users.management_id,
			branch: selectedBranch.id,
			main_mgmt_id: users.main_mgmt_id,
		};
		axios
			.get("hmis/get-all-active", { params })
			.then((res) => {
				const data = res.data;
				setList({ data: data, ready: true });
			})
			.catch((err) => {
				console.log("error : ", err);
				// Notify.requestError(err);
			});
	}, [users, selectedBranch]);

	const getAllEmployee = React.useCallback(() => {
		var params = {
			user_id: users.user_id,
			management_id: users.management_id,
			branch: selectedBranch.id,
			main_mgmt_id: users.main_mgmt_id,
		};
		axios
			.get("hmis/get-all-employee", { params })
			.then((res) => {
				const data = res.data;
				setEmployee({ data: data, ready: true });
			})
			.catch((err) => {
				console.log("error : ", err);
				// Notify.requestError(err);
			});
	}, [users, selectedBranch]);

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const searchable = employee.data.filter((data) => {
		return data._account_name.toLowerCase().indexOf(search.trim()) !== -1;
	});

	const getAllBranches = React.useCallback(async () => {
		var params = {
			management_id: users.management_id,
			main_management_id: users.main_mgmt_id,
		};
		try {
			let response = await axios.get("hr/get/all-branches", { params });
			const data = response.data;
			setBranchList({ data, ready: true });
		} catch (error) {
			console.log("error: ", error);
		}
	}, [users]);

	React.useEffect(() => {
		getAllActiveEmployee();
		getAllEmployee();
		getAllBranches();

		// eslint-disable-next-line
	}, [getAllActiveEmployee, getAllEmployee, getAllBranches]);

	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: true,
					current: "payroll",
					items: [{ name: "Dashboard", path: "/sph/app" }],
				}}
				title={
					<Box display="flex">
						<Box flexGrow={1}>Payroll</Box>
						<Box display="flex" flexDirection="row">
							<Box>
								<TextField
									fullWidth
									required
									name="branch"
									label="Branch"
									variant="outlined"
									select
									SelectProps={{
										native: true,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									defaultValue={selectedBranch.id}
									onChange={(e) => {
										let xx = e.nativeEvent.target.selectedIndex;
										setSelectedBranch({
											name: e.nativeEvent.target[xx].text,
											id: e.target.value,
										});
									}}
									margin="dense"
								>
									<option value="All">All</option>
									{branchList.data.length > 0 &&
										branchList.data.map((data, index) => (
											<option
												key={index}
												value={data.management_id}
												label={data.name}
											>
												{data.name}
											</option>
										))}
								</TextField>
							</Box>

							<Box ml={2} mt={0.5}>
								<Button
									color="primary"
									variant="contained"
									startIcon={<RecentActorsIcon />}
									onClick={() => setPayrollSummaryDialog(true)}
									size="large"
								>
									Summary
								</Button>
							</Box>
						</Box>
					</Box>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4} md={4}>
						<Card>
							<CardHeader title="Employee List" />
							<CardContent>
								{employee.ready ? (
									<>
										<Box>
											<TextField
												label="Search for employee"
												variant="outlined"
												fullWidth
												value={search}
												onChange={(e) => setSearch(e.target.value)}
												InputProps={{
													endAdornment: (
														<InputAdornment>
															<Search color="primary" />
														</InputAdornment>
													),
												}}
											/>
										</Box>

										<List component="div">
											{employee.data.length > 0
												? searchable
														.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map((data, index) => (
															<ListItem
																key={index}
																button
																className="mb-2"
																onClick={() => {
																	setSelectedName(data._account_name);
																	setSelectedEmployee(data.user_id);
																	setSelectedType(data.type);
																	setSelectedEmail(data._account_email);
																}}
																selected={selectedEmployee === data.user_id}
															>
																<ListItemIcon>
																	<Avatar
																		className={classes.primary}
																		src={
																			data.type === "HIS-Accounting"
																				? data._image_accounting !== null
																					? `${imageLocation}accounting/${data._image_accounting}`
																					: `No image`
																				: data.type === "HIS-Billing"
																				? data._image_billing !== null
																					? `${imageLocation}billing/${data._image_billing}`
																					: `No image`
																				: data.type === "HIS-Cashier"
																				? data._image_cashier !== null
																					? `${imageLocation}cashier/${data._image_cashier}`
																					: `No image`
																				: data.type === "HIS-Doctor"
																				? data._image_doctors !== null
																					? `${imageLocation}doctor/${data._image_doctors}`
																					: `No image`
																				: data.type === "HIS-Haptech"
																				? data._image_haptech !== null
																					? `${imageLocation}haptech/${data._image_haptech}`
																					: `No image`
																				: data.type === "HIS-Hr"
																				? data._image_hr !== null
																					? `${imageLocation}hr/${data._image_hr}`
																					: `No image`
																				: data.type === "HIS-Imaging"
																				? data._image_imaging !== null
																					? `${imageLocation}imaging/${data._image_imaging}`
																					: `No image`
																				: data.type === "HIS-Laboratory"
																				? data._image_laboratory !== null
																					? `${imageLocation}laboratory/${data._image_laboratory}`
																					: `No image`
																				: data.type === "HIS-Pharmacy"
																				? data._image_pharmacy !== null
																					? `${imageLocation}pharmacy/${data._image_pharmacy}`
																					: `No image`
																				: data.type === "HIS-Psychology"
																				? data._image_psychology !== null
																					? `${imageLocation}psychology/${data._image_psychology}`
																					: `No image`
																				: data.type === "HIS-Radiologist"
																				? data._image_radiologist !== null
																					? `${imageLocation}radiologist/${data._image_radiologist}`
																					: `No image`
																				: data.type === "HIS-Registration"
																				? data._image_registration !== null
																					? `${imageLocation}registration/${data._image_registration}`
																					: `No image`
																				: data.type === "HIS-Stockroom"
																				? data._image_stockroom !== null
																					? `${imageLocation}stockroom/${data._image_stockroom}`
																					: `No image`
																				: data.type === "HIS-Triage"
																				? data._image_triage !== null
																					? `${imageLocation}triage/${data._image_triage}`
																					: `No image`
																				: data.type === "HIS-Warehouse"
																				? data._image_warehouse !== null
																					? `${imageLocation}warehouse/${data._image_warehouse}`
																					: `No image`
																				: "No image"
																		}
																		alt=""
																	/>
																</ListItemIcon>
																<ListItemText
																	className={`gtc-capitalize`}
																	primary={`${data._account_name}`}
																/>
															</ListItem>
														))
												: Notify.noRecord()}
										</List>
										<TablePagination
											component={"div"}
											rowsPerPageOptions={[5, 10, 25]}
											colSpan={3}
											count={searchable.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onChangePage={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</>
								) : (
									<Typography color="primary">
										loading, please wait...
									</Typography>
								)}
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} sm={8} md={8}>
						{selectedEmployee === "all-payroll" ? (
							<Card>
								<CardHeader title={"Daily Time Record"} />
								<CardContent>
									<TableContainer>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell align="center">Name</TableCell>
													<TableCell align="center">Time In</TableCell>
													<TableCell align="center">Time Out</TableCell>
												</TableRow>
											</TableHead>

											<TableBody>
												{list.ready &&
													(list.data.length > 0 ? (
														list.data
															.slice(
																page * rowsPerPage,
																page * rowsPerPage + rowsPerPage
															)
															.map((data, index) => {
																return (
																	<TableRow key={index}>
																		<TableCell>{data._account_name}</TableCell>
																		<TableCell align="center">
																			{Notify.dateTimeConvert(data.timein)}
																		</TableCell>
																		<TableCell align="center">
																			{data.timeout !== null
																				? Notify.dateTimeConvert(data.timeout)
																				: ""}
																		</TableCell>
																	</TableRow>
																);
															})
													) : (
														<TableRow>
															<TableCell align="center" colSpan={3}>
																<Typography color="secondary">
																	No record found.
																</Typography>
															</TableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>

										<TablePagination
											component={"div"}
											rowsPerPageOptions={[5, 10, 25]}
											colSpan={3}
											count={list.data.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onChangePage={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</TableContainer>
								</CardContent>
							</Card>
						) : (
							<Card>
								<CardHeader
									title={
										<Box
											mb={2}
											display="flex"
											justifyContent="center"
											alignItems="center"
										>
											<Box flexGrow={1} style={{ textTransform: "capitalize" }}>
												<IconButton
													onClick={() => setSelectedEmployee("all-payroll")}
													color="primary"
												>
													<ArrowBack />
												</IconButton>
												{selectedName} Daily Time Record
											</Box>
										</Box>
									}
								/>
								<CardContent>
									<PayrollEmployeesInfo
										employee_id={selectedEmployee}
										type={selectedType}
									/>
								</CardContent>
							</Card>
						)}
					</Grid>
				</Grid>

				<Dialog
					open={payrollDialog}
					onClose={() => setPayrollDialog(false)}
					fullScreen
					TransitionComponent={Zoom}
					transitionDuration={800}
				>
					<DialogTitle className="d-print-none">Payslip Generate</DialogTitle>
					<PayrollPayslip
						selectedEmail={selectedEmail}
						employee_id={selectedEmployee}
						selectedName={selectedName}
						close={() => setPayrollDialog(false)}
					/>
				</Dialog>

				<Dialog
					open={payrollSummaryDialog}
					onClose={() => setPayrollSummaryDialog(false)}
					TransitionComponent={Slide}
					fullScreen
				>
					<PayrollSummary
						close={() => setPayrollSummaryDialog(false)}
						selectedBranch={selectedBranch}
					/>
				</Dialog>
			</Container>
		</Fragment>
	);
};

export default PayrollDashboard;

// const RenderSelectBranch = () => (
//   <Box align="center">
//     <CardMedia
//       component="img"
//       src={"/gtc-logo.png"}
//       alt=""
//       style={{ width: 270 }}
//     />

//     <Typography color="secondary" variant="h3">
//       NO BRANCH SELECTED
//     </Typography>

//     <Typography color="secondary" variant="h6">
//       Select branch in the list to view details.
//     </Typography>
//   </Box>
// );
