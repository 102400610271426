import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

class thyroidProfileRef {
  t3 = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ color: "#000", fontWeight: "bolder" }}
        >
          0.8 - 2.0 ng/mL
        </Typography>
      </Box>
    </Box>
  );

  t4 = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ color: "#000", fontWeight: "bolder" }}
        >
          78.51 - 157.01 nmol/L
        </Typography>
      </Box>
    </Box>
  );

  tsh = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ color: "#000", fontWeight: "bolder" }}
        >
          0.36 - 5.6 uIU/mL
        </Typography>
      </Box>
    </Box>
  );

  ft4 = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ color: "#000", fontWeight: "bolder" }}
        >
          7.64 - 16.03 pmol/L
        </Typography>
      </Box>
    </Box>
  );

  ft3 = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ color: "#000", fontWeight: "bolder" }}
        >
          2.8 - 7.1 pmol/L
        </Typography>
      </Box>
    </Box>
  );
}

const Ref_ThyroidProfile = new thyroidProfileRef();
export default Ref_ThyroidProfile;
