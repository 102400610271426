import { forwardRef, useImperativeHandle, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import QRCode from "qrcode.react";
import axios from "axios";
import PhoViewPatientProfile from "./PhoViewPatientProfile";
const PhoPatientProfileModal = (props, ref) => {
	const { add_patient_ref } = props;
	const [open, setOpen] = useState(false);
	const [patient, setPatient] = useState(null);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const getPatientInfo = (id) => {
		return axios.get(`v1/patients/${id}`);
	};
	const show = (data) => {
		setPatient(data);
		getPatientInfo(data?.id).then((res) => {
			setPatient(res.data.data);
		});
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const formatDate = (dateToFormat) => {
		if (dateToFormat) {
			let date = new Date(dateToFormat);
			return (
				months[date.getMonth()] +
				" " +
				date.getDate() +
				", " +
				date.getFullYear()
			);
		}
		return "";
	};

	const formatTime = (dateToFormat) => {
		if (dateToFormat) {
			let date = new Date(dateToFormat);
			let h = date.getHours() - 12;
			let min = date.getMinutes();
			return "" + Math.abs(h) + ":" + min + " " + (h > 0 ? "AM" : "PM");
		}
		return "";
	};
	let total = 0;
	return (
		<Modal open={open} hide={hide} size="xl">
			<ModalHeader title={"Patient Profile"} hide={hide} />
			<ModalBody className={`!p-0 !bg-background`}>
				{open ? (
					<PhoViewPatientProfile
						patient={patient}
						setPatient={setPatient}
						className="!bg-transparent"
						add_patient_ref={add_patient_ref}
					/>
				) : (
					""
				)}
			</ModalBody>
		</Modal>
	);
};

export default forwardRef(PhoPatientProfileModal);
