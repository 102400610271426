import React, { Fragment } from "react";
import {
  Avatar,
  Card,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import Container from "../../layout/Container";
import { useHistory } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const Account = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "account",
          items: [{ name: "Dashboard", path: "/sph/app" }],
        }}
        title="Account"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card className={classes.root}>
              <CardHeader
                avatar={
                  <Avatar aria-label="My Basic Info" className={classes.avatar}>
                    BI
                  </Avatar>
                }
                action={
                  <IconButton
                    aria-label="accountSettings"
                    onClick={() => {
                      history.push("/sph/app/pharmacy/account/basic-info");
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
                title="Basic Info"
              />
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card className={classes.root}>
              <CardHeader
                avatar={
                  <Avatar aria-label="My Login Info" className={classes.avatar}>
                    LI
                  </Avatar>
                }
                action={
                  <IconButton
                    aria-label="accountSettings"
                    onClick={() => {
                      history.push("/sph/app/pharmacy/account/login-info");
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
                title="Login Info"
              />
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card className={classes.root}>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="Pharmacy QR Code"
                    className={classes.avatar}
                  >
                    QR
                  </Avatar>
                }
                action={
                  <IconButton
                    aria-label="accountSettings"
                    onClick={() => {
                      history.push("/sph/app/pharmacy/account/pharmacy-qr");
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
                title="Pharmacy QR Code"
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default Account;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },
  avatar: {
    backgroundColor: blue[600],
  },
}));
