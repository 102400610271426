import {
	Grid,
	Typography,
	Box,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Button,
	CardMedia,
} from "@material-ui/core";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import ReactToPrint from "react-to-print";
import { Print } from "@material-ui/icons";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ClinicMgmtSOADetails = ({ selectedBranch, selectedSOA, printRef }) => {
	const { users } = useContext(UsersData);
	const [details, setDetails] = useState({ data: [], ready: false });
	const [formHeader, setFormHeader] = useState({
		data: [],
		ready: false,
	});

	let totalorder = 0;

	const calculateOrder = (amount) => {
		totalorder += parseFloat(amount !== null ? amount : 0);
		return Notify.convertToNumber(amount !== null ? amount : 0);
	};

	const getSOADetails = useCallback(() => {
		var params = {
			user_id: users.user_id,
			management_id: selectedSOA.management_id,
			soa_id: selectedSOA.soa_id,
		};
		axios
			.get("cashier/get/soa/details-by-id", { params })
			.then((response) => {
				const data = response.data;
				setDetails({ data: data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	}, [users.user_id, selectedSOA.soa_id, selectedSOA.management_id]);

	const getLabFormHeader = () => {
		var params = { management_id: selectedSOA.management_id };
		axios
			.get("laboratory/order/formheader-details", { params })
			.then((response) => {
				const data = response.data;
				setFormHeader({ data, ready: true });
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	useEffect(() => {
		getSOADetails();
		getLabFormHeader();
		// eslint-disable-next-line
	}, [getSOADetails]);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box>
						<Box display="flex" justifyContent="center" mb={4}>
							<Box mr={2}>
								{formHeader.ready
									? formHeader.data && (
											<CardMedia
												style={{ width: 70 }}
												component={"img"}
												src={`${imageLocation}laboratory/logo/${formHeader.data.logo}`}
											/>
									  )
									: null}
							</Box>
							<Box align="center">
								<Box>
									<Typography variant="h6">
										{formHeader.data && formHeader.data.name}
									</Typography>

									<Typography>
										{formHeader.data && formHeader.data.address}
									</Typography>
									<Typography>
										{formHeader.data && formHeader.data.contact_number}
									</Typography>
								</Box>
							</Box>
						</Box>

						<Box align="center" mb={2}>
							<Box mb={1}>
								<Typography variant="h6" className="textUppercase">
									<b> STATEMENT OF ACCOUNT </b>
								</Typography>
							</Box>
						</Box>

						<Box>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Grid container spacing={0}>
										<Grid item xs={6}>
											<Box>
												<Typography style={{ fontWeight: "bolder" }}>
													THE MANAGER
												</Typography>
											</Box>
											<Box>
												<Typography style={{ fontWeight: "bolder" }}>
													<span style={{ textTransform: "uppercase" }}>
														{details.data.length > 0 &&
															details.data[0].branch_name}
													</span>
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={6}>
											<Box display={"flex"}>
												<Box flexGrow={1} />
												<Typography style={{ fontWeight: "bolder" }}>
													Statement No:{" "}
													<span style={{ textTransform: "uppercase" }}>
														{details.data.length > 0 && details.data[0].soa_id}
													</span>
												</Typography>
											</Box>
											<Box display={"flex"}>
												<Box flexGrow={1} />
												<Typography style={{ fontWeight: "bolder" }}>
													Statement Date:{" "}
													<span style={{ textTransform: "uppercase" }}>
														{new Date().toLocaleString()}
													</span>
												</Typography>
											</Box>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>

						<Box mt={3}>
							<TableContainer>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell align="center">
												<b> Date </b>
											</TableCell>
											<TableCell align="center">
												<b> Control No. </b>
											</TableCell>
											<TableCell align="center">
												<b> Name </b>
											</TableCell>
											<TableCell align="center">
												<b> Amount </b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{details.ready ? (
											details.data.length > 0 ? (
												details.data.map((data, index) => (
													<TableRow key={index}>
														<TableCell>
															{Notify.createdAt(data.created_at)}
														</TableCell>
														<TableCell align="center">{index + 1}</TableCell>
														<TableCell>
															{data.lname}, {data.fname}
															<br />
															<span style={{ marginLeft: 5 }}>
																<b>&bull;{data.bill_name}</b>
															</span>
															<span hidden>
																{calculateOrder(data.bill_amount)}
															</span>
														</TableCell>
														<TableCell align="right">
															{Notify.convertToNumber(data.bill_amount)}
														</TableCell>
													</TableRow>
												))
											) : (
												<TableRow>
													<TableCell colSpan={4} align="center">
														No record
													</TableCell>
												</TableRow>
											)
										) : (
											<TableRow>
												<TableCell colSpan={4} align="center">
													Loading, Please wait...
												</TableCell>
											</TableRow>
										)}

										<TableRow>
											<TableCell colSpan={3} align="right">
												<b>GRAND TOTAL</b>
											</TableCell>
											<TableCell align="right">
												{Notify.convertToNumber(totalorder)}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>

							<Box my={2} mt={5}>
								<Box display="flex">
									<Box flexGrow={1} width={"50%"}>
										<Typography
											suppressContentEditableWarning={true}
											contentEditable={true}
										>
											<b>Prepared by:</b>
										</Typography>
										<Box align="center">
											{/* {Boolean(formInfo)
                        ? formInfo.prepared_by !== null
                          ? formInfo.prepared_by
                          : ""
                        : ""} */}
										</Box>
										<Box align="center">
											<Box
												style={{ borderTop: "1px solid #888" }}
												maxWidth={350}
											>
												<Typography
													suppressContentEditableWarning={true}
													contentEditable={true}
												>
													<b>Cashier</b>
												</Typography>
											</Box>
										</Box>
									</Box>

									<Box width={"50%"}>
										<Typography
											suppressContentEditableWarning={true}
											contentEditable={true}
										>
											<b>Verified by:</b>
										</Typography>
										<Box align="center">
											{/* {Boolean(formInfo)
                        ? formInfo.verified_by !== null
                          ? formInfo.verified_by
                          : ""
                        : ""} */}
										</Box>
										<Box align="center">
											<Box
												style={{ borderTop: "1px solid #888" }}
												maxWidth={350}
											>
												<Typography
													suppressContentEditableWarning={true}
													contentEditable={true}
												>
													<b>Accounting In-Charge:</b>
												</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
							<Box>
								<Box display="flex">
									<Box flexGrow={1} width={"50%"}>
										<Typography
											suppressContentEditableWarning={true}
											contentEditable={true}
										>
											<b>Checked by:</b>
										</Typography>
										<Box align="center">
											{/* {Boolean(formInfo)
                        ? formInfo.checked_by !== null
                          ? formInfo.checked_by
                          : ""
                        : ""} */}
										</Box>
										<Box align="center">
											<Box
												style={{ borderTop: "1px solid #888" }}
												maxWidth={350}
											>
												<Typography
													suppressContentEditableWarning={true}
													contentEditable={true}
												>
													<b>Clinic Coordinator</b>
												</Typography>
											</Box>
										</Box>
									</Box>

									<Box width={"50%"}>
										<Typography
											suppressContentEditableWarning={true}
											contentEditable={true}
										>
											<b>Approved by:</b>
										</Typography>
										<Box align="center">
											{/* {Boolean(formInfo)
                        ? formInfo.noted_by !== null
                          ? formInfo.noted_by
                          : ""
                        : ""} */}
										</Box>
										<Box align="center">
											<Box
												style={{ borderTop: "1px solid #888" }}
												maxWidth={350}
											>
												<Typography
													suppressContentEditableWarning={true}
													contentEditable={true}
												>
													<b>Operation Manager</b>
												</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>

							<Box display="flex" mt={3}>
								<Box flexGrow={1} />
								<ReactToPrint
									trigger={() => {
										// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
										// to the root node of the returned component as it will be overwritten.
										return (
											<Button
												className={"d-print-none"}
												variant="contained"
												color="primary"
												hidden={details.data.length === 0}
												startIcon={<Print />}
											>
												Print
											</Button>
										);
									}}
									content={() => printRef.current}
								/>
							</Box>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default ClinicMgmtSOADetails;
