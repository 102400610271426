import React, { Fragment, useContext, useEffect, useState } from "react";
import {
	Box,
	Typography,
	TextField,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	TablePagination,
	InputAdornment,
	Grid,
	Paper,
} from "@material-ui/core";
import Notify from "../../notification/Notify";
import Axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import { red, yellow, pink } from "@material-ui/core/colors";
import { UsersData } from "../../ContextAPI";
import Container from "../../layout/Container";

function Stock() {
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [stock, setStock] = useState([]);
	const [stockReady, setStockReady] = useState(false);
	const [search, setSearch] = useState("");
	const userData = useContext(UsersData);

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const fetchStock = () => {
		var params = {
			user_id: userData.users.user_id,
			management_id: userData.users.management_id,
		};
		Axios.get("pharmacy/get-stock-list", { params })
			.then((response) => {
				const data = response.data;
				setStock(data);
				setStockReady(true);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	useEffect(() => {
		fetchStock();
		// eslint-disable-next-line
	}, []);

	const searchable = stock.filter((data) => {
		return data.product.toLowerCase().indexOf(search.trim()) !== -1;
	});

	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: true,
					current: "stock",
					items: [{ name: "Dashboard", path: "/sph/app" }],
				}}
				title="Stock"
			>
				<Grid container>
					<Grid item sm={12} xs={12}>
						<Box display="flex">
							<Box flexGrow={1} />
							<Box mb={1}>
								<TextField
									label="Search brand"
									variant="outlined"
									margin="dense"
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									InputProps={{
										endAdornment: (
											<InputAdornment>
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>
							</Box>
						</Box>
						<Paper>
							<Box m={1}>
								<TableContainer component={Box}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell align="center"> Brand </TableCell>
												<TableCell align="center"> Generic </TableCell>
												<TableCell align="center"> Batch Number </TableCell>
												<TableCell align="center"> Status </TableCell>
												<TableCell align="center"> Expiry Date </TableCell>
												<TableCell align="center"> Available </TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{stockReady ? (
												stock.length > 0 ? (
													searchable
														.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map((data, index) => (
															<Fragment key={index}>
																{Date.parse(new Date(data.notification_date)) <=
																	Date.parse(new Date()) &&
																parseFloat(data.quantity) <= 50 ? (
																	<TableRow>
																		<TableCell bgcolor={red[600]}>
																			{" "}
																			{data.product}{" "}
																		</TableCell>
																		<TableCell bgcolor={red[600]}>
																			{data.description}
																		</TableCell>
																		<TableCell bgcolor={red[600]}>
																			{data.batch_no}
																		</TableCell>
																		<TableCell bgcolor={red[600]}>
																			&bull;Warning Exp <br />
																			&bull;Warning Qty <br />
																		</TableCell>
																		<TableCell bgcolor={red[600]} align="right">
																			{" "}
																			{Notify.createdAt(data.expiry_date)}{" "}
																		</TableCell>
																		<TableCell bgcolor={red[600]} align="right">
																			{data.quantity}
																		</TableCell>
																	</TableRow>
																) : Date.parse(
																		new Date(data.notification_date)
																  ) <= Date.parse(new Date()) &&
																  parseFloat(data.quantity) > 50 ? (
																	<TableRow>
																		<TableCell bgcolor={pink[200]}>
																			{" "}
																			{data.product}{" "}
																		</TableCell>
																		<TableCell bgcolor={pink[200]}>
																			{data.description}
																		</TableCell>
																		<TableCell bgcolor={pink[200]}>
																			{data.batch_no}
																		</TableCell>
																		<TableCell bgcolor={pink[200]}>
																			&bull;Warning Exp <br />
																			&bull;Normal Qty
																			<br />
																		</TableCell>
																		<TableCell
																			bgcolor={pink[200]}
																			align="right"
																		>
																			{" "}
																			{Notify.createdAt(data.expiry_date)}{" "}
																		</TableCell>
																		<TableCell
																			bgcolor={pink[200]}
																			align="right"
																		>
																			{data.quantity}
																		</TableCell>
																	</TableRow>
																) : Date.parse(
																		new Date(data.notification_date)
																  ) > Date.parse(new Date()) &&
																  parseFloat(data.quantity) >= 11 &&
																  parseFloat(data.quantity) <= 50 ? (
																	<TableRow>
																		<TableCell bgcolor={yellow[300]}>
																			{" "}
																			{data.product}{" "}
																		</TableCell>
																		<TableCell bgcolor={yellow[300]}>
																			{data.description}
																		</TableCell>
																		<TableCell bgcolor={yellow[300]}>
																			{data.batch_no}
																		</TableCell>
																		<TableCell bgcolor={yellow[300]}>
																			&bull;Warning Qty
																			<br />
																		</TableCell>
																		<TableCell
																			bgcolor={yellow[300]}
																			align="right"
																		>
																			{" "}
																			{Notify.createdAt(data.expiry_date)}{" "}
																		</TableCell>
																		<TableCell
																			bgcolor={yellow[300]}
																			align="right"
																		>
																			{data.quantity}
																		</TableCell>
																	</TableRow>
																) : Date.parse(
																		new Date(data.notification_date)
																  ) > Date.parse(new Date()) &&
																  parseFloat(data.quantity) >= 0 &&
																  parseFloat(data.quantity) <= 10 ? (
																	<TableRow>
																		<TableCell bgcolor={red[200]}>
																			{" "}
																			{data.product}{" "}
																		</TableCell>
																		<TableCell bgcolor={red[200]}>
																			{data.description}
																		</TableCell>
																		<TableCell bgcolor={red[200]}>
																			{data.batch_no}
																		</TableCell>
																		<TableCell bgcolor={red[200]}>
																			&bull;Danger Qty
																			<br />
																		</TableCell>
																		<TableCell bgcolor={red[200]} align="right">
																			{" "}
																			{Notify.createdAt(data.expiry_date)}{" "}
																		</TableCell>
																		<TableCell bgcolor={red[200]} align="right">
																			{data.quantity}
																		</TableCell>
																	</TableRow>
																) : (
																	<TableRow>
																		<TableCell> {data.product} </TableCell>
																		<TableCell>{data.description}</TableCell>
																		<TableCell>{data.batch_no}</TableCell>
																		<TableCell>
																			&bull;Normal
																			<br />
																		</TableCell>
																		<TableCell align="right">
																			{" "}
																			{Notify.createdAt(data.expiry_date)}{" "}
																		</TableCell>
																		<TableCell align="right">
																			{data.quantity}
																		</TableCell>
																	</TableRow>
																)}
															</Fragment>
														))
												) : (
													<TableRow>
														<TableCell colSpan={7} align="center">
															<Typography variant="subtitle2" color="secondary">
																{" "}
																No stock added{" "}
															</Typography>
														</TableCell>
													</TableRow>
												)
											) : (
												<TableRow>
													<TableCell colSpan={7} align="center">
														<Typography variant="subtitle2" color="primary">
															{" "}
															Loading...{" "}
														</Typography>
													</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>

									<TablePagination
										component={"div"}
										rowsPerPageOptions={[5, 10, 25]}
										colSpan={3}
										count={stock.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onChangePage={handleChangePage}
										onChangeRowsPerPage={handleChangeRowsPerPage}
									/>
								</TableContainer>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</Fragment>
	);
}

export default Stock;
