import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";

const NoAppointmentDetected = ({ title }) => {
  return (
    <div>
      <Paper elevation={0}>
        <Box p={2}>
          <Typography align="center" color="secondary">
            {title} is not available if no appointment.
          </Typography>
        </Box>
      </Paper>
    </div>
  );
};

export default NoAppointmentDetected;
