import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData, BranchListContext } from "../ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faCreditCard,
  faHeading,
  faHome,
  faLink,
  faUserCircle,
  faUserLock,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import HRDashboard from "src/hr/HRDashboard";
import AccountHr from "src/hr/account/Account";
import HRManpower from "src/hr/manpower/HRManpower";
import PayrollDashboard from "src/hr/payroll/PayrollDashboard";
import HRHospAccounts from "src/hr/users-account/HRHospAccounts";
import PayrollHeaders from "src/hr/payroll-header/PayrollHeaders";
import Branches from "src/hr/branches/Branches";
import LeaveForm from "src/hr/leave/LeaveForm";

const RouteHR = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);
  const [branchList, setBranchList] = useState({ data: [], ready: false });

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("hr/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const getAllBranches = React.useCallback(async () => {
    var params = {
      management_id: userContext.users.management_id,
      main_management_id: userContext.users.main_mgmt_id,
    };
    try {
      let response = await axios.get("hr/get/all-branches", { params });
      const data = response.data;
      setBranchList({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [userContext]);

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/sph/app/hr",
      subitem: [],
    },

    {
      name: "Manpower",
      show: true,
      icon: <FontAwesomeIcon icon={faUsers} color="white" size={"2x"} />,
      path: "/sph/app/hr/manpower",
      subitem: [],
    },

    {
      name: "Payroll",
      show: true,
      icon: <FontAwesomeIcon icon={faCreditCard} color="white" size={"2x"} />,
      path: "/sph/app/hr/payroll",
      subitem: [],
    },

    {
      name: "Payroll Header",
      show: true,
      icon: <FontAwesomeIcon icon={faHeading} color="white" size={"2x"} />,
      path: "/sph/app/hr/payroll-header",
      subitem: [],
    },

    {
      name: "Users Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserCircle} color="white" size={"2x"} />,
      path: "/sph/app/hr/users-account",
      subitem: [],
    },

    {
      name: "Branches",
      show: true,
      icon: <FontAwesomeIcon icon={faLink} color="white" size={"2x"} />,
      path: "/sph/app/hr/branches",
      subitem: [],
    },
    {
      name: "Leave Application",
      show: true,
      icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
      path: "/sph/app/hr/leave-application",
      subitem: [],
    },

    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/sph/app/hr/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  const handleRerenderBranch = () => {
    getAllBranches();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    getAllBranches();
    //eslint-disable-next-line
  }, [getAllBranches]);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
        }}
      >
        <BranchListContext.Provider
          value={{
            branchList: branchList,
            updateBranchList: handleRerenderBranch,
          }}
        >
          <BrowserRouter>
            <Sidebar
              notification={{
                enable: true,
                owner: "hr",
              }}
              header={sidebarHeader}
              routes={sidebarRoute}
              module={"hr"}
            />
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to="/sph/app/hr" />}
              />
              <Route
                exact
                path="/bmcdc"
                component={() => <Redirect to="/sph/app/hr" />}
              />
              <Route
                exact
                path="/sph/app"
                component={() => <Redirect to="/sph/app/hr" />}
              />
              <Route exact path="/sph/app/hr" component={HRDashboard} />
              <Route exact path="/sph/app/hr/manpower" component={HRManpower} />
              <Route
                exact
                path="/sph/app/hr/payroll"
                component={PayrollDashboard}
              />
              <Route
                exact
                path="/sph/app/hr/payroll-header"
                component={PayrollHeaders}
              />
              <Route
                exact
                path="/sph/app/hr/users-account"
                component={HRHospAccounts}
              />
              <Route exact path="/sph/app/hr/branches" component={Branches} />

              <Route
                exact
                path="/sph/app/hr/leave-application"
                component={LeaveForm}
              />

              <Route exact path="/sph/app/hr/account" component={AccountHr} />
              <Route exact path="/sph/app/logout" component={Logout} />

              <Route render={() => <PageNotFound title="Page not found" />} />
            </Switch>
          </BrowserRouter>
        </BranchListContext.Provider>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteHR;
