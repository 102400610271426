import React, {
	useEffect,
	Fragment,
	useContext,
	useState,
	useRef,
} from "react";
import PatientDetailsHeader from "./PatientDetailsHeader";
import PatientDetailsProfile from "./PatientDetailsProfile";
import Axios from "axios";
import Notify from "src/notification/Notify";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "src/utils/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFolder,
	faFolderPlus,
	faUserShield,
	faWalking,
} from "@fortawesome/free-solid-svg-icons";
import PatientRegNewCT from "./PatientRegNewCT";
import AdditionalQueueForOrder from "./walkin/additional";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import ResponseDetails from "../../pho/components/ReponseDetails";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Img from "src/components/Img";
import AddPatientToQueueModal from "src/nurse/patientlist/components/AddPatientToQueueModal";
import AddConsultationModal from "./components/AddConsultationModal";

const PatientDetails = ({
	updatePatientData,
	patient_id,
	getDoctorsPatient,
}) => {
	const { users } = useContext(UsersData);
	const [doctors, setDoctors] = useState(null);
	const [clinic, setClinic] = useState(null);
	const [category, setCategory] = React.useState(0);
	const [info, setInfo] = React.useState({
		data: null,
		ready: false,
	});
	const [contactTracing, setContactTracing] = React.useState({
		data: null,
		ready: false,
	});
	const add_to_queue_ref = useRef(null);

	const getPatientInformation = () => {
		setInfo({ data: [], ready: false });
		var params = { patient_id: patient_id };
		Axios.get("admission/patient/patient-information", { params })
			.then((response) => {
				const data = response.data;
				setInfo({
					data: data,
					ready: true,
				});
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const getPatientContactTracing = () => {
		setContactTracing({ data: [], ready: false });
		var params = { patient_id: patient_id };
		Axios.get("admission/patient/patient-contacttracing", { params })
			.then((response) => {
				const data = response.data;
				setContactTracing({
					data: data,
					ready: true,
				});
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const handleChange = (event, newCategory) => {
		event.persist();
		setCategory(newCategory);
	};

	useEffect(() => {
		getPatientInformation();

		getPatientContactTracing();
		// eslint-disable-next-line
	}, [patient_id]);
	useEffect(() => {
		getDoctors();
		getClinic();
	}, []);
	const getDoctors = () => {
		axios.get(`/v1/doctors`).then((res) => {
			setDoctors(res.data);
		});
	};
	const getClinic = () => {
		axios.get(`/v1/my-clinic`).then((res) => {
			setClinic(res.data.data);
		});
	};
	return (
		<>
			{info.ready ? (
				<Fragment>
					<PatientDetailsHeader
						updatePatientData={updatePatientData}
						patient_id={patient_id}
						info={info}
					/>

					<Box mt={2} m={0} p={0}>
						<Box component={Paper} elevation={1} mb={2}>
							<Tabs
								value={category}
								onChange={handleChange}
								indicatorColor="primary"
								textColor="primary"
								variant="scrollable"
								scrollButtons="on"
							>
								<Tab
									wrapped
									label="Profile"
									icon={
										<img
											src="/profile.png"
											alt=""
											className="w-10 object-contain"
										/>
									}
								/>

								<Tab
									wrapped
									label="Kobotoolbox Form"
									icon={
										<a
											// href={`https://ee.kobotoolbox.org/edit/OU7e65oc?instance_id=ae888cdf-b747-4d1f-813f-01d9543d40dc&return_url=false`}
											href={`https://ee.kobotoolbox.org/x/7rizFbWq?d[surveyor]=${users?.username.replace(
												"-",
												" "
											)}&d[surveyor_id]=${
												users?.user_id
											}`}
											target="_blank"
											rel="noreferrer"
											className="!no-underline w-[calc(100%-68px)] lg:w-[unset]"
										>
											<img
												src="/form-plus.png"
												alt=""
												className="w-10 object-contain"
											/>
										</a>
									}
								/>
								<Tab
									wrapped
									label="Contact Tracing"
									icon={
										<img
											src="/contact-tracing.png"
											alt=""
											className="w-10 object-contain"
										/>
									}
								/>

								{/* <Tab
                  wrapped
                  label="Appointment"
                  icon={<FontAwesomeIcon icon={faCalendarCheck} size={"2x"} />}
                /> */}

								<Tab
									wrapped
									label="Consultation"
									icon={
										<img
											src="/consult.png"
											alt=""
											className="w-10 object-contain"
										/>
									}
								/>
							</Tabs>
						</Box>

						<TabPanel value={category} index={0}>
							<PatientDetailsProfile
								patient_id={patient_id}
								getDoctorsPatient={() => getDoctorsPatient()}
								getPatientInformation={getPatientInformation}
								info={info}
							/>
						</TabPanel>
						<TabPanel value={category} index={1}>
							<div className="flex flex-col gap-y-2">
								<ResponseDetails patient={info?.data} />
							</div>
						</TabPanel>
						<TabPanel value={category} index={2}>
							<PatientRegNewCT
								patient_id={patient_id}
								getDoctorsPatient={() => getDoctorsPatient()}
								getPatientInformation={getPatientInformation}
								info={contactTracing}
								patientinfo={info}
							/>
						</TabPanel>

						<TabPanel value={category} index={3}>
							<div className="flex flex-col gap-3 p-3">
								{doctors?.map((doctor) => {
									return (
										<div className="flex items-center gap-4 bg-white p-3">
											<Img
												type="user"
												src={doctor?.avatar}
												name={doctor?.name}
												className="w-14 h-14 object-contain border border-gray-200 bg-slate-50 rounded-full"
											/>
											<span className="text-lg font-semibold">
												{doctor?.name}
											</span>
											<ActionBtn
												type="success"
												size="sm"
												className="p-1 !aspect-square !rounded-full"
												onClick={() => {
													add_to_queue_ref.current.show(
														{ doctor: doctor }
													);
												}}
											>
												<FlatIcon icon="rr-plus" />
											</ActionBtn>
										</div>
									);
								})}
							</div>
						</TabPanel>
					</Box>
				</Fragment>
			) : (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					height={200}
				>
					{Notify.loading()}
				</Box>
			)}
			{console.log("infoinfoinfo", info)}
			<AddConsultationModal
				ref={add_to_queue_ref}
				clinic={clinic}
				doctors={doctors}
				patient={{ id: info?.data?.id }}
			/>
			{/* <AddPatientToQueueModal ref={add_to_queue_ref} /> */}
		</>
	);
};

export default PatientDetails;
