import React, {
	Fragment,
	useEffect,
	useState,
	useContext,
	useCallback,
} from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { FormInformation, UsersData, UsersHeader } from "../ContextAPI";
import Laboratory from "../laboratory/Dashboard";
import Account from "../laboratory/account";
import PrintOrder from "src/laboratory/PrintOrder";
import LaboratoryPatients from "src/laboratory/LaboratoryPatients";
import { Badge } from "@material-ui/core";
import LaboratoryVirtualOrder from "src/laboratory/virtual_order";
import LaboratoryTest from "src/laboratory/test";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAddressCard,
	faFolderOpen,
	faHome,
	faScroll,
	faUserInjured,
	faUserLock,
	faVial,
	faReplyAll,
} from "@fortawesome/free-solid-svg-icons";
import LeaveForm from "src/laboratory/leave/LeaveForm";
import IsMountedRef from "src/utils/IsMountedRef";
import PrintLayout from "src/laboratory/printlayout";
import LaboratoryReport from "src/laboratory/LaboratoryReport";
import EditConfirmedResult from "src/laboratory/EditConfirmedResult";
import LaboratoryPatientsMobileVan from "src/laboratory/LaboratoryPatientsMobileVan";
import LaboratoryRequestItem from "src/laboratory/request/LaboratoryRequestItem";

const RouteLaboratory = () => {
	const [sidebarHeader, setSidebarHeader] = useState([]);
	const userContext = useContext(UsersData);
	var interval = null;
	const [newPatientCount, setNewPatientCount] = useState(0);
	const [newPatientVanCount, setNewPatientVanCount] = useState(0);

	const [patient, setPatients] = useState({ data: [], ready: false });
	const [vaPatient, setVanPatients] = useState({ data: [], ready: false });

	const mounted = IsMountedRef();
	const [formInfo, setFormInfo] = useState([]);

	const getSidebarHeaderInfo = useCallback(async () => {
		try {
			var params = { user_id: userContext.users.user_id };
			const response = await axios.get(
				"laboratory/sidebar/header-infomartion",
				{ params }
			);
			if (mounted.current) {
				setSidebarHeader(response.data);
			}
		} catch (error) {
			console.log("Unable to get sidebar header info.", error);
		}
	}, [userContext.users, mounted]);

	const getPatientsWithOrder = useCallback(async () => {
		var params = {
			user_id: userContext.users.user_id,
			management_id: userContext.users.management_id,
		};
		const response = await axios.get(
			"laboratory/order/getpatientswith-neworder",
			{ params }
		);
		const data = response.data;
		if (mounted.current) {
			setPatients({ data, ready: true });
			if (data.length > 0) {
				let count = data.reduce((x, data) => {
					return Boolean(parseInt(data.order_count)) ? x + 1 : x + 0;
				}, 0);
				setNewPatientCount(count);
			} else {
				setNewPatientCount(0);
			}
		}
	}, [userContext.users, mounted]);

	const getMobileVanPatientsWithOrder = useCallback(async () => {
		var params = {
			user_id: userContext.users.user_id,
			management_id: userContext.users.management_id,
		};
		const response = await axios.get("van/laboratory/patient-withneworder", {
			params,
		});
		const data = response.data;

		if (mounted.current) {
			setVanPatients({ data, ready: true });
			if (data.length > 0) {
				let count = data.reduce((x, data) => {
					return Boolean(parseInt(data.order_count)) ? x + 1 : x + 0;
				}, 0);
				setNewPatientVanCount(count);
			} else {
				setNewPatientVanCount(0);
			}
		}
	}, [userContext.users, mounted]);

	const getCurrentFormInfoForExpense = async () => {
		try {
			var params = {
				user_id: userContext.users.user_id,
				management_id: userContext.users.management_id,
				main_mgmt_id: userContext.users.main_mgmt_id,
			};
			const response = await axios.get("laboratory/get/result/print-layout", {
				params,
			});
			setFormInfo(response.data);
		} catch (error) {
			console.log(error);
		}
	};

	const handleRenderInfo = () => {
		getSidebarHeaderInfo();
	};

	const sidebarRoute = [
		{
			name: "Dashboard",
			show: true,
			icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
			path: "/sph/app/laboratory",
			subitem: [],
		},

		{
			name: "Patients",
			show: true,
			icon: (
				<Badge
					color="error"
					badgeContent={newPatientCount + newPatientVanCount}
				>
					<FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
				</Badge>
			),
			path: "/sph/app/laboratory/patients",
			subitem: [],
		},

		{
			name: "Laboratory Test",
			show: true,
			icon: <FontAwesomeIcon icon={faVial} color="white" size={"2x"} />,
			path: "/sph/app/laboratory/test",
			subitem: [],
		},

		{
			name: "Report",
			show: true,
			icon: <FontAwesomeIcon icon={faFolderOpen} color="white" size={"2x"} />,
			path: "/sph/app/laboratory/lab-report",
			subitem: [],
		},

		{
			name: "Print Layout",
			show: true,
			icon: <FontAwesomeIcon icon={faScroll} color="white" size={"2x"} />,
			path: "/sph/app/laboratory/print-layout",
			subitem: [],
		},

		{
			name: "Leave Application",
			show: true,
			icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
			path: "/sph/app/laboratory/leave-application",
			subitem: [],
		},

		{
			name: "Request For Reagent",
			show: true,
			icon: <FontAwesomeIcon icon={faReplyAll} color="white" size={"2x"} />,
			path: "/sph/app/laboratory/request-for-item",
			subitem: [],
		},

		{
			name: "Account",
			show: true,
			icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
			path: "/sph/app/laboratory/account",
			subitem: [],
		},
	];

	const initializeInterval = () => {
		interval = setInterval(() => {
			getPatientsWithOrder();
			getMobileVanPatientsWithOrder();
		}, 20000);
	};

	useEffect(() => {
		getSidebarHeaderInfo();
		getPatientsWithOrder();
		getMobileVanPatientsWithOrder();
	}, [
		getSidebarHeaderInfo,
		getPatientsWithOrder,
		getMobileVanPatientsWithOrder,
	]);

	useEffect(() => {
		initializeInterval();
		getCurrentFormInfoForExpense();
		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<UsersHeader.Provider
				value={{
					sidebarHeader: sidebarHeader,
					renderPharmacyInfo: handleRenderInfo,
					queue: {
						patient: patient,
						getPatientsOnQueue: () => getPatientsWithOrder(),
					},
					van_queue: {
						vaPatient: vaPatient,
						getVanPatientsOnQueue: () => getMobileVanPatientsWithOrder(),
					},
				}}
			>
				<FormInformation.Provider
					value={{
						formInfo: formInfo,
						renderFormInfo: () => getCurrentFormInfoForExpense(),
					}}
				>
					<BrowserRouter>
						<Sidebar
							notification={{
								enable: true,
								owner: "laboratory",
							}}
							header={sidebarHeader}
							routes={sidebarRoute}
							module={"laboratory"}
						/>

						<Switch>
							<Route
								exact
								path="/"
								component={() => <Redirect to="/sph/app/laboratory" />}
							/>

							<Route
								exact
								path="/bmcdc"
								component={() => <Redirect to="/sph/app/laboratory" />}
							/>

							<Route
								exact
								path="/sph/app"
								component={() => <Redirect to="/sph/app/laboratory" />}
							/>

							<Route exact path="/sph/app/laboratory" component={Laboratory} />

							<Route
								exact
								path="/sph/app/laboratory/patients"
								component={LaboratoryPatients}
							/>

							<Route
								exact
								path="/sph/app/laboratory/mobile/van/patients"
								component={LaboratoryPatientsMobileVan}
							/>

							<Route
								exact
								path="/sph/app/laboratory/virtual"
								component={LaboratoryVirtualOrder}
							/>

							{/* <Route exact path="/sph/app/laboratory/test" component={TestAdd} /> */}

							<Route
								exact
								path="/sph/app/laboratory/test"
								component={LaboratoryTest}
							/>

							{/* <Route
                exact
                path="/sph/app/laboratory/report"
                component={Report}
              /> */}

							<Route
								exact
								path="/sph/app/laboratory/record/print/order/:order_id"
								component={PrintOrder}
							/>

							<Route
								exact
								path="/sph/app/laboratory/lab-report/edit/order/:order_id"
								component={EditConfirmedResult}
							/>

							<Route
								exact
								path="/sph/app/laboratory/print-layout"
								component={PrintLayout}
							/>

							<Route
								exact
								path="/sph/app/laboratory/leave-application"
								component={LeaveForm}
							/>

							<Route
								exact
								path="/sph/app/laboratory/lab-report"
								component={LaboratoryReport}
							/>

							<Route
								exact
								path="/sph/app/laboratory/request-for-item"
								component={LaboratoryRequestItem}
							/>

							<Route
								exact
								path="/sph/app/laboratory/account"
								component={Account}
							/>

							<Route exact path="/sph/app/logout" component={Logout} />

							<Route render={() => <PageNotFound title="Page not found" />} />
						</Switch>
					</BrowserRouter>
				</FormInformation.Provider>
			</UsersHeader.Provider>
		</Fragment>
	);
};

export default RouteLaboratory;
