import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import { useClinicContext } from "../context/ClinicContext";
import VitalsForm from "./VitalsForm";
import { UsersData } from "src/ContextAPI";
import TextInputField from "src/components/forms/TextInputField";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";
import { calculateBMI, calculateBPMeasurement } from "src/helpers/utils";

const inputFields = [
	{
		label: "Body Temperature",
		name: "temperature",
		placeholder: "°C",
		className: "lg:col-span-3",
		type: "text",
		required: {
			value: true,
			message: "This field is required.",
		},
	},

	{
		label: "Blood Pressure (SYSTOLIC)",
		name: "blood_systolic",
		placeholder: "SYSTOLIC",
		className: "lg:col-span-3",
		type: "text",
		required: {
			value: true,
			message: "This field is required.",
		},
	},
	{
		label: "Blood Pressure (DIASTOLIC)",
		name: "blood_diastolic",
		placeholder: "DIASTOLIC",
		className: "lg:col-span-3",
		type: "text",
		required: {
			value: true,
			message: "This field is required.",
		},
	},
	{
		label: "BP Measurement",
		name: "bp_measurement",
		placeholder: "BP Measurement",
		className: "lg:col-span-3",
		type: "text",
	},
	{
		label: "Pulse Rate",
		name: "pulse",
		placeholder: "Enter Pulse Rate",
		className: "lg:col-span-3",
		type: "text",
	},

	{
		label: "Respiratory Rate",
		name: "respiratory",
		placeholder: "Enter Respiratory Rate",
		className: "lg:col-span-3",
		type: "text",
	},
	{
		label: "Patient height in cm",
		name: "height",
		placeholder: "Enter Patient height in CM",
		className: "lg:col-span-3",
		type: "text",
		required: {
			value: true,
			message: "This field is required.",
		},
	},
	{
		label: "Patient weight in KG",
		name: "weight",
		placeholder: "Enter Patient weight in KG",
		className: "lg:col-span-3",
		type: "text",
		required: {
			value: true,
			message: "This field is required.",
		},
	},
	{
		label: "BMI",
		name: "bmi",
		placeholder: "Enter BMI",
		className: "lg:col-span-3",
		type: "text",
	},
	{
		label: "Height for Age",
		name: "height_for_age",
		placeholder: "Enter Height for Age",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Weight for Age",
		name: "weight_for_age",
		placeholder: "Enter Weight for Age",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		name: "filler",
		className: "lg:col-span-4",
	},
	{
		label: "Blood Type",
		name: "bloody_type",
		placeholder: "Enter Blood Type",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Oxygen saturation",
		name: "oxygen_saturation",
		placeholder: "Enter Oxygen saturation",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Heart Rate",
		name: "heart_rate",
		placeholder: "Enter Heart Rate",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Regular Rhythm",
		name: "regular_rhythm",
		placeholder: "Enter Regular Rhythm",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Covid 19",
		name: "covid_19",
		placeholder: "Enter Covid 19",
		className: "lg:col-span-4",
		type: "select",
		options: [
			{
				label: "Positive",
				value: "positive",
			},
			{
				label: "Negative",
				value: "negative",
			},
		],
	},
	{
		label: "TB",
		name: "tb",
		placeholder: "Enter TB",
		className: "lg:col-span-4",
		type: "select",
		options: [
			{
				label: "Positive",
				value: "positive",
			},
			{
				label: "Negative",
				value: "negative",
			},
		],
	},
	{
		label: "Glucose",
		name: "glucose",
		placeholder: "Enter Glucose",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Cholesterol",
		name: "cholesterol",
		placeholder: "Enter Cholesterol",
		className: "lg:col-span-4",
		type: "text",
	},
	{
		label: "Uric Acid",
		name: "uric_acid",
		placeholder: "Enter Uric Acid",
		className: "lg:col-span-4",
		type: "text",
	},
];

const ClinicProfileVitalsForm = ({
	setUpdateVitals,
	setSelectedIndex,
	patient,
}) => {
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		watch,
		control,
		formState: { errors },
	} = useForm();

	const userContext = useContext(UsersData);
	const [vitals, setVitals] = useState([]);
	const [loading, setLoading] = useState(false);
	const { id } = useParams();

	useEffect(() => {
		if (patient?.id) {
			setValue("temperature", patient?.temperature);
			setValue("blood_systolic", patient?.blood_systolic);
			setValue("blood_diastolic", patient?.blood_diastolic);
			setValue("weight", patient?.weight);
			setValue("height", patient?.height);
			setValue("respiratory", patient?.respiratory);
			setValue("uric_acid", patient?.uric_acid);
			setValue("cholesterol", patient?.cholesterol);
			setValue("glucose", patient?.glucose);
			setValue("pulse", patient?.pulse);
			getPatientVitals();
		}
	}, [patient?.id]);
	const submit = (data) => {
		setLoading(true);
		let formData = new FormData();
		formData.append("patient_id", patient?.id);

		formData.append("temperature", data?.temperature);
		formData.append("pulse", data?.pulse);
		formData.append("blood_systolic", data?.blood_systolic);
		formData.append("blood_diastolic", data?.blood_diastolic);
		formData.append("respiratory", data?.respiratory);
		formData.append("height", data?.height);
		formData.append("weight", data?.weight);
		formData.append("glucose", data?.glucose || 0);
		formData.append("uric_acid", data?.uric_acid || 0);
		formData.append("cholesterol", data?.cholesterol || 0);
		formData.append("bmi", data?.bmi);
		formData.append("height_for_age", data?.height_for_age);
		formData.append("weight_for_age", data?.weight_for_age);
		formData.append("bloody_type", data?.bloody_type);
		formData.append("oxygen_saturation", data?.oxygen_saturation);
		formData.append("heart_rate", data?.heart_rate);
		formData.append("regular_rhythm", data?.regular_rhythm);
		formData.append("covid_19", data?.covid_19);
		formData.append("tb", data?.tb);

		formData.append("added_by_id", userContext.users?.id);
		// console.log("submit", data);
		let last_vital_id = vitals?.id || 0;
		let url = `v1/patient-vitals/${vitals?.length == 0 ? "store" : ""}${
			last_vital_id ? `/last_vital_id` : ""
		}`;
		if (last_vital_id) {
			formData.append("_method", "PATCH");
		}
		axios.post(url, formData).then((res) => {
			setUpdateVitals(false);
			toast.success("Patient vitals updated successfully!");
			// setSelectedIndex(0);
		});
	};

	const getPatientVitals = () => {
		axios
			.get(`v1/patient-vitals/vital-signs/${patient?.id}`)
			.then((res) => {
				setVitals(res.data || []);
				console.log("ressss", res.data);
				let _vitals = res.data.data;
				if (_vitals) {
					console.log("ressss _vitals", _vitals);
					setValue("temperature", _vitals?.temperature);
					setValue("blood_systolic", _vitals?.blood_systolic);
					setValue("blood_diastolic", _vitals?.blood_diastolic);
					setValue("weight", _vitals?.weight);
					setValue("height", _vitals?.height);
					setValue("respiratory", _vitals?.respiratory);
					setValue("uric_acid", _vitals?.uric_acid);
					setValue("cholesterol", _vitals?.cholesterol);
					setValue("glucose", _vitals?.glucose);
					setValue("pulse", _vitals?.pulse);
				}
			});
	};
	return (
		<div className="flex flex-col gap-y-5">
			{/* <VitalsForm register={register} errors={errors} /> */}
			<h4 className="border-y-2 text-base font-bold p-2">Vitals Form</h4>
			<div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
				{inputFields?.map((data) => {
					if (data?.name == "bmi") {
						// calculateBMI
						let bmi_ =
							watch("height")?.length && watch("weight")?.length
								? calculateBMI(watch("height"), watch("weight"))
								: {};
						console.log("bmi_bmi_bmi_", bmi_);
						return (
							<TextInputField
								type={"text"}
								inputClassName={`${bmi_?.bmi_color}`}
								className={`${data?.className} lg:!w-full ${bmi_?.bmi_color}`}
								label={<>BMI</>}
								value={`${parseFloat(bmi_?.bmi || 0).toFixed(
									2
								)} - ${bmi_?.status || ""}`}
								placeholder={data?.placeholder}
								error={errors[data?.name]?.message}
								helperText={""}
								{...register("bmi", {
									// required: true,
								})}
							/>
						);
					}
					//

					if (data?.name == "bp_measurement") {
						// calculateBMI
						let bp_measurement =
							watch("blood_systolic")?.length &&
							watch("blood_diastolic")?.length
								? calculateBPMeasurement(
										watch("blood_systolic"),
										watch("blood_diastolic")
								  )
								: {};
						console.log("bp_measurement", bp_measurement);
						return (
							<TextInputField
								type={"text"}
								inputClassName={`${bp_measurement?.color}`}
								className={`${data?.className} lg:!w-full ${bp_measurement?.color}`}
								label={<>BP Measurement</>}
								value={`${bp_measurement?.result}`}
								placeholder={data?.placeholder}
								error={errors[data?.name]?.message}
								helperText={""}
								{...register("bp_measurement", {
									// required: true,
								})}
							/>
						);
					}
					if (data?.name == "filler") {
						return <div className={data?.className}></div>;
					}
					if (data?.type == "select") {
						return (
							<div className={data?.className}>
								<Controller
									name={data?.name}
									control={control}
									rules={{
										required: data?.required
											? data?.required
											: false,
									}}
									onChange={(data) => {}}
									render={({
										field: {
											onChange,
											onBlur,
											value,
											name,
											ref,
										},
										fieldState: {
											invalid,
											isTouched,
											isDirty,
											error,
										},
									}) => (
										<ReactSelectInputField
											isClearable={false}
											label={<>{data?.label}</>}
											inputClassName=" "
											ref={ref}
											value={value}
											onChange={(val) => {
												onChange(val);
											}} // send value to hook form
											onBlur={onBlur} // notify when input is touched
											error={error?.message}
											placeholder={data?.label}
											options={data?.options?.map(
												(option) => ({
													label: option?.label,
													value: option?.value,
												})
											)}
										/>
									)}
								/>
							</div>
						);
					}
					return (
						<TextInputField
							type={data?.type}
							className={`${data?.className} lg:!w-full`}
							label={
								<>
									{data?.label}:{""}
								</>
							}
							placeholder={data?.placeholder}
							options={data?.options}
							error={errors[data?.name]?.message}
							helperText={""}
							{...register(data?.name, {
								required: data?.required
									? data?.required
									: false,
							})}
						/>
					);
				})}
			</div>
			<div className="flex items-center gap-4">
				<ActionBtn
					type="accent"
					size="md"
					onClick={handleSubmit(submit)}
				>
					<FlatIcon icon="rr-disk" />
					Update vitals
				</ActionBtn>
				{/* <ActionBtn type="foreground" size="md">
					<FlatIcon icon="rr-cross-small" />
					Cancel
				</ActionBtn> */}
			</div>
		</div>
	);
};

export default ClinicProfileVitalsForm;
