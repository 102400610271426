import { forwardRef, useImperativeHandle, useState } from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import PhoPatientForm from "./PhoPatientForm";
import { useForm } from "react-hook-form";
import usePatientHook from "src/admission/hooks/usePatientHook";
const PhoPatientFormModal = (props, ref) => {
	const { staticModal } = props;
	const [open, setOpen] = useState(false);
	const [patient, setPatient] = useState(null);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const { savePHOPatient } = usePatientHook();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		reset();
		if (data) {
			setTimeout(() => {
				setPatient(data);
				reset({
					...data,
					birthdate: data?.birthday,
					middlename: data?.middle,
				});
			}, 100);
		} else {
			setTimeout(() => {
				reset();
				setPatient(null);
			}, 100);
		}
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = (data) => {
		console.log("submit data", data);
		savePHOPatient(data, patient?.id).then((res) => {
			console.log("saved", res);
		});
	};
	return (
		<Modal open={open} hide={hide} size="xl" staticModal={staticModal}>
			<ModalHeader
				title={"Add Patient Form"}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p- bg-white`}>
				<PhoPatientForm
					register={register}
					errors={errors}
					patient={patient}
					key={`patient-${patient?.id}`}
				/>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button onClick={handleSubmit(submit)}>Submit</Button>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(PhoPatientFormModal);
