import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardHeader, Typography, useTheme } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const GrandTotalPayable = () => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [grandPayable, setGrandPayable] = useState(0);
  const theme = useTheme();

  const getAllPayable = React.useCallback(async () => {
    let response = await axios.get("accounting/grand/payable-totalamount", {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      if (response.data.length > 0) {
        calculateTotal(response.data);
      }
    }
  }, [mounted, users]);

  const calculateTotal = (data) => {
    let x = 0;

    for (let i = 0; i < data.length; i++) {
      x += parseFloat(data[i]._total_available);
    }

    setGrandPayable(x);
  };

  useEffect(() => {
    getAllPayable();
  }, [getAllPayable]);

  return (
    <Card elevation={5}>
      <CardHeader
        avatar={
          <FontAwesomeIcon
            icon={faFileInvoiceDollar}
            size={"5x"}
            style={{ color: `${theme.palette.primary.main}` }}
          />
        }
        title={
          <Typography variant="h4" color="textSecondary">
            Payable
          </Typography>
        }
        // subheader={
        //   <Typography variant="h5">
        //     <strong> &#8369; {Notify.convertToNumber(grandPayable)}</strong>
        //   </Typography>
        // }
      />

      <Box mb={2}>
        <Typography variant="h4" align="center">
          <strong> &#8369; {Notify.convertToNumber(grandPayable)}</strong>
        </Typography>
      </Box>
    </Card>
  );
};

export default GrandTotalPayable;
