import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import SelectItemsSelector from "./SelectItemsSelector";
import Img from "src/components/Img";
import { UsersData } from "src/ContextAPI";
import {
	dataURItoBlob,
	dateYYYYMMDD,
	formatDateMMDDYYYYHHIIA,
} from "src/helpers/utils";
import ItemServiceList from "./ItemServiceList";
import SelectInputField from "src/components/forms/SelectInputField";
import SelectToUseItems from "./SelectToUseItems";
import ImagePicker from "src/components/forms/ImagePicker";

const symptoms = [
	{
		value: "cough_for_3_weeks_or_longer",
		label: "Cough for three weeks or longer",
	},
	{
		value: "coughing_up_blood_or_mucus",
		label: "Coughing up blood or mucus",
	},
	{ value: "chest_pain", label: "Chest pain" },
	{
		value: "pain_with_breathing_or_coughing",
		label: "Pain with breathing or coughing",
	},
	{ value: "fever", label: "Fever" },
	{ value: "chills", label: "Chills" },
	{ value: "night_sweats", label: "Night sweats" },
	{ value: "weight_loss", label: "Weight loss" },
	{ value: "not_wanting_to_eat", label: "Not wanting to eat" },
	{ value: "tiredness", label: "Tiredness" },
	{
		value: "not_feeling_well_in_general",
		label: "Not feeling well in general",
	},
];
const AddLabResultReadingModal = (props, ref) => {
	const { patient, refresh, onSuccess } = props;
	const { users } = useContext(UsersData);
	const { register: register1, watch: watch1 } = useForm();
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		trigger,
		control,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(0);
	const [loading, setLoading] = useState(false);
	const [xRayResult, setXRayResult] = useState(null);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const submit = (data) => {
		setLoading(true);

		let formdata = new FormData();

		formdata.append("_method", "PATCH");
		formdata.append("hemoglobin", data?.hemoglobin);
		formdata.append("hematocrit", data?.hematocrit);
		formdata.append("rcbc", data?.rcbc);
		formdata.append("wbc", data?.wbc);
		axios
			.post(`/v1/clinic/tb-lab-result/${appointment?.id}`, formdata)
			.then((res) => {
				setTimeout(() => {
					refresh();
					onSuccess();
					toast.success("Adding laboratory result success!");
				}, 200);
				hide();
				// setIsPositive(false);
			});
	};
	return (
		<Modal
			open={open}
			staticModal={true}
			size="md"
			hide={hide}
			className=" w-full max-w-[512px]"
		>
			<ModalHeader
				title={`Add X-ray result`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="w-full grid grid-cols-1 gap-4">
					<TextInputField
						label="Hemoglobin"
						placeholder="g/L"
						{...register("hemoglobin", {
							required: "The hemoglobin is required.",
						})}
						errors={errors?.hemoglobin?.message}
					/>
					<TextInputField
						label="Hematocrit"
						placeholder="L/L"
						{...register("hematocrit", {
							required: "The hematocrit is required.",
						})}
						errors={errors?.hematocrit?.message}
					/>
					<TextInputField
						label="RBC Count"
						placeholder="x10¹²/L"
						{...register("rcbc", {
							required: "The rcbc is required.",
						})}
						errors={errors?.rcbc?.message}
					/>
					<TextInputField
						label="WBC Count"
						placeholder="x10⁹/L"
						{...register("wbc", {
							required: "The wbc is required.",
						})}
						errors={errors?.wbc?.message}
					/>
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center justify-between"}>
				<>
					<ActionBtn
						className="w-1/3"
						type="success"
						onClick={handleSubmit(submit)}
						loading={loading}
					>
						<FlatIcon icon="rr-disk" />
						Submit
					</ActionBtn>
				</>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(AddLabResultReadingModal);
