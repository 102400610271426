import React, { useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Card,
  Box,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import axios from "axios";
import Notify from "src/notification/Notify";
import { styles } from "src/cis/components/styles/styles";
import ButtonV3 from "src/components/ButtonV3";

export default function CisAddLaboratoryRT_PCR(name) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    // let formData = new FormData();
    // formData.append("date", data?.date || "");
    // formData.append("description", data?.description || "");
  };

  return (
    <div className="flex w-full flex-col">
      <form>
        <div className="mx-auto" style={styles.parentContainerAdd}>
          <Card elevation={3} className="p-4" component={Box}>
            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Name</label>}
                variant="outlined"
                fullWidth
                value={name.name}
                {...register("name")}
              />
            </div>
            <div style={styles.divContainer} className="pl-3">
              <RadioGroup
                aria-label="results"
                name="results"
                value={watch("results")}
                onChange={(e) => setValue("results", e.target.value)}
                row
              >
                <FormControlLabel
                  value="positive"
                  control={<Radio color="primary" />}
                  label="POSITIVE"
                />
                <FormControlLabel
                  value="negative"
                  control={<Radio color="primary" />}
                  label="NEGATIVE"
                />
              </RadioGroup>
              {errors.results && <span>Results is required</span>}
            </div>
            <div style={styles.divContainer}>
              <TextField
                label={
                  <label style={styles.label}>
                    Description<span style={styles.astirisColor}>*</span>
                  </label>
                }
                variant="outlined"
                fullWidth
                {...register("description", { required: true })}
                error={errors.description}
                helperText={errors.description && "Description is required"}
              />
            </div>
          </Card>
        </div>

        <div className="mx-auto" style={styles.button}>
          <ButtonV3 onClick={handleSubmit(onSubmit)}>Submit</ButtonV3>
        </div>
      </form>
    </div>
  );
}
