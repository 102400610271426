import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Notify from "src/notification/Notify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ChartCustomTooltip from "./ChartCustomTooltip";
import IsMountedRef from "src/utils/IsMountedRef";
import { Tag as BoxIcon, Tag } from "react-feather";
import { Line } from "react-chartjs-2";
import {
	amber,
	blue,
	green,
	indigo,
	red,
	purple,
	teal,
	grey,
} from "@material-ui/core/colors";
import { UsersData } from "src/ContextAPI";

const GraphClinical = ({ type, patient_id, containerRef }) => {
	const { users } = useContext(UsersData);

	const [ready, setReady] = useState(false);
	const [label, setLabel] = useState([]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [pusCells, setPusCell] = useState([]);
	const [redBloodCells, setRedBloodCells] = useState([]);
	const [hyalineCast, setHyalineCast] = useState([]);
	const [wbcCast, setWbcCast] = useState([]);
	const [rbcCast, setRbcCast] = useState([]);
	const [fineGranularCast, setFineGranularCast] = useState([]);
	const [coarseGranularCast, setCoarseGranularCast] = useState([]);

	const chartRef = React.useRef();
	const ismounted = IsMountedRef();

	const getClinicalRecord = React.useCallback(async () => {
		try {
			var formdata = new FormData();
			formdata.set("user_id", users.user_id);
			formdata.set("patient_id", patient_id);

			const response = await axios.post(
				"doctor/patient/laboratory/clinicalmicroscopy/graph/get-clinicalmicroscopydata",
				formdata
			);
			if (ismounted.current) {
				pushDataToChart(response.data);
				setReady(true);
			}
		} catch (error) {
			console.log(error);
		}
	}, [users, ismounted, patient_id]);

	const pushDataToChart = (data) => {
		for (let i = 0; i < data.length; i++) {
			setPusCell((pusCells) =>
				pusCells.concat(
					data[i].microscopic_test_pus === null
						? 0
						: data[i].microscopic_test_pus
				)
			);
			setRedBloodCells((redBloodCells) =>
				redBloodCells.concat(
					data[i].microscopic_test_redblood === null
						? 0
						: data[i].microscopic_test_redblood
				)
			);
			setHyalineCast((hyalineCast) =>
				hyalineCast.concat(
					data[i].microscopic_test_hyaline === null
						? 0
						: data[i].microscopic_test_hyaline
				)
			);
			setWbcCast((wbcCast) =>
				wbcCast.concat(
					data[i].microscopic_test_wbc === null
						? 0
						: data[i].microscopic_test_wbc
				)
			);
			setRbcCast((rbcCast) =>
				rbcCast.concat(
					data[i].microscopic_test_rbc === null
						? 0
						: data[i].microscopic_test_rbc
				)
			);
			setFineGranularCast((fineGranularCast) =>
				fineGranularCast.concat(
					data[i].microscopic_test_fine_granular === null
						? 0
						: data[i].microscopic_test_fine_granular
				)
			);
			setCoarseGranularCast((coarseGranularCast) =>
				coarseGranularCast.concat(
					data[i].microscopic_test_coarse_granular === null
						? 0
						: data[i].microscopic_test_coarse_granular
				)
			);

			setLabel((label) =>
				label.concat(Notify.dateTimeConvert(data[i].created_at))
			);
		}
	};

	const initializeChartWidth = () => {
		chartRef.current.chartInstance.canvas.parentNode.style.width = `${
			label.length > 10 ? 2000 : containerRef.clientWidth - 47
		}px`;
		chartRef.current.chartInstance.canvas.parentNode.style.height = `calc(85vh - 150px)`;

		setInterval(() => {
			chartRef.current.chartInstance.canvas.parentNode.style.width = `${
				label.length > 10 ? 2000 : containerRef.clientWidth - 47
			}px`;
			chartRef.current.chartInstance.canvas.parentNode.style.height = `calc(85vh - 150px)`;
		}, 5000);
	};

	useEffect(() => {
		if (chartRef.current) {
			getClinicalRecord();

			initializeChartWidth(); //cavas width checker for sidebar open or close
		}

		// eslint-disable-next-line
	}, []);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleHideLegend = (type, key) => {
		if (type === "all") {
			chartRef.current.chartInstance.data.datasets.forEach(function (ds) {
				ds.hidden = !ds.hidden;
			});
		} else {
			chartRef.current.chartInstance.data.datasets[key].hidden =
				!chartRef.current.chartInstance.data.datasets[key].hidden;
		}
		chartRef.current.chartInstance.update();
	};

	const data = {
		labels: label,
		datasets: [
			{
				label: "Pus Cells",
				backgroundColor: "rgba(0, 123, 155, 0)",
				pointBackgroundColor: green[600],
				borderColor: green[600],
				pointHoverRadius: 5,
				borderWidth: 2,
				pointRadius: 5,
				lineTension: 0,
				data: pusCells,
			},
			{
				label: "Red Blood Cells",
				backgroundColor: "rgba(0, 123, 155, 0)",
				pointBackgroundColor: blue[600],
				borderColor: blue[600],
				pointHoverRadius: 5,
				borderWidth: 2,
				pointRadius: 5,
				lineTension: 0,
				data: redBloodCells,
			},
			{
				label: "Hyaline Cast",
				backgroundColor: "rgba(0, 123, 155, 0)",
				pointBackgroundColor: purple[600],
				borderColor: purple[600],
				pointHoverRadius: 5,
				borderWidth: 2,
				pointRadius: 5,
				lineTension: 0,
				data: hyalineCast,
			},
			{
				label: "WBC Cast",
				backgroundColor: "rgba(0, 123, 155, 0)",
				pointBackgroundColor: indigo[600],
				borderColor: indigo[600],
				pointHoverRadius: 5,
				borderWidth: 2,
				pointRadius: 5,
				lineTension: 0,
				data: wbcCast,
			},
			{
				label: "RBC Cast",
				backgroundColor: "rgba(0, 123, 155, 0)",
				pointBackgroundColor: red[600],
				borderColor: red[500],
				pointHoverRadius: 5,
				borderWidth: 2,
				pointRadius: 5,
				lineTension: 0,
				data: rbcCast,
			},
			{
				label: "Fine Granular  Cast",
				backgroundColor: "rgba(0, 123, 155, 0)",
				pointBackgroundColor: teal[600],
				borderColor: teal[600],
				pointHoverRadius: 5,
				borderWidth: 2,
				pointRadius: 5,
				lineTension: 0,
				data: fineGranularCast,
			},
			{
				label: "Coarse Granular Cast",
				backgroundColor: "rgba(0, 123, 155, 0)",
				pointBackgroundColor: amber[600],
				borderColor: amber[600],
				pointHoverRadius: 5,
				borderWidth: 2,
				pointRadius: 5,
				lineTension: 0,
				data: coarseGranularCast,
			},
		],
	};

	const options = {
		animationEnabled: true,
		maintainAspectRatio: false,
		legend: {
			position: "bottom",
			cursor: "pointer",
			display: false,
		},
		tooltips: {
			enabled: false,
			custom: (tooltipModel) => {
				ChartCustomTooltip.show(tooltipModel, chartRef);
			},
		},
		animation: {
			onComplete: () => {
				const copyWidth =
					chartRef.current.chartInstance.scales["y-axis-0"].width - 10;
				const copyHeight =
					chartRef.current.chartInstance.scales["y-axis-0"].height +
					chartRef.current.chartInstance.scales["y-axis-0"].top +
					10;
				const targetCtx = document
					.getElementById("myChartAxis")
					.getContext("2d");
				targetCtx.canvas.width = copyWidth;
				targetCtx.drawImage(
					chartRef.current.chartInstance.canvas,
					0,
					0,
					copyWidth,
					copyHeight,
					0,
					0,
					copyWidth,
					copyHeight
				);
			},
		},
	};

	return (
		<React.Fragment>
			<ChartHeader type={type} handleClick={handleClick} />

			<Box display="flex">
				<Box flexGrow={1}>
					{!ready && (
						<Box align="center">
							<Typography color="primary" variant="subtitle2">
								fetching microscopy records...
							</Typography>
						</Box>
					)}
					<div className="chartWrapper">
						<div
							style={{
								width: `${containerRef.clientWidth - 30}px`,
								overflowX: "auto",
							}}
						>
							<div>
								<Line
									ref={chartRef}
									data={chartRef && data}
									options={chartRef && options}
								/>
							</div>
						</div>
						<canvas id="myChartAxis" height="100%" width="0"></canvas>
					</div>
				</Box>

				<Box ml={2}>
					{chartRef && (
						<RenderHemaOptions
							anchorEl={anchorEl}
							handleClose={handleClose}
							handleClick={handleClick}
							chartJs={chartRef.current}
							handleHideLegend={handleHideLegend}
						/>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
};

export default GraphClinical;

const ChartHeader = ({ type, handleClick }) => (
	<Box display="flex" mb={2}>
		<Box flexGrow={1}>
			<Typography
				className={`gtc-capitalize`}
				variant={"h6"}
				color="textSecondary"
			>
				{type} Graph
			</Typography>
		</Box>

		<Box mx={2}>
			<Button
				startIcon={<Tag size={15} />}
				variant="outlined"
				size="large"
				color="primary"
				onClick={handleClick}
			>
				<Hidden xsDown> Graph </Hidden> LEgend
			</Button>
		</Box>
	</Box>
);

const RenderHemaOptions = ({
	anchorEl,
	handleClose,
	chartJs,
	handleHideLegend,
}) => {
	const [hideAll, setHideAll] = useState(false);

	return (
		<Menu
			id="simple-menu"
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={handleClose}
			PaperProps={{
				style: {
					maxHeight: 300,
					overflow: " none",
				},
			}}
		>
			<MenuItem>
				<FormControlLabel
					className={`gtc-uppercase`}
					control={
						<Checkbox
							checked={hideAll}
							onChange={() => {
								handleHideLegend("all", 0);
								setHideAll(!hideAll);
							}}
						/>
					}
					label={`Hide All`}
				/>
			</MenuItem>

			{chartJs &&
				chartJs.chartInstance &&
				chartJs.chartInstance.legend.legendItems.map((data, index) => (
					<MenuItem key={index}>
						<FormControlLabel
							onClick={() => handleHideLegend("byone", index)}
							className={`gtc-uppercase`}
							control={
								<Box ml={1} mr={2}>
									{" "}
									<BoxIcon
										color={data.hidden ? grey[200] : data.strokeStyle}
									/>{" "}
								</Box>
							}
							label={data.text}
						/>
					</MenuItem>
				))}
		</Menu>
	);
};
