let text_numbers = [
	"one",
	"two",
	"three",
	"four",
	"five",
	"six",
	"seven",
	"eight",
	"nine",
	"ten",
];

const renderValue = (index, preText, label) => {
	if (label.toLowerCase().includes("other")) {
		return "other";
	}
	return preText + text_numbers[index];
};

export const main_sources = [
	{
		value: "waterandsanone",
		label: "1 Own use faucet, community water system",
	},
	{
		value: "waterandsantwo",
		label: "2 Shared faucet, community water system",
	},
	{ value: "waterandsanthree", label: "3 Own use tubed/piped deep well" },
	{ value: "waterandsanfour", label: "4 Shared tubed/piped deep well" },
	{ value: "waterandsanfive", label: "5 Tubed/piped deep well" },
	{ value: "waterandsansix", label: "6 Dug well" },
	{ value: "waterandsanseven", label: "7 Protected spring" },
	{ value: "waterandsaneight", label: "8 Unprotected spring" },
	{ value: "waterandsannine", label: "9 Lake, river, rain and others" },
	{ value: "waterandsanten", label: "10 Peddler" },
	{ value: "waterandsaneleven", label: "11 Bottled Water" },
	{ value: "waterandsanother", label: "12 Others, specify" },
];

export const toilets = [
	{
		value: "totf_one",
		label: "1 Water-sealed, sewer septic tank, used exclusively by household",
	},

	{
		value: "totf_two",
		label: "2 Water-sealed, sewer septic tank, shared with other household",
	},

	{
		value: "totf_three",
		label: "3 Water-sealed, other depository, used exclusively by household",
	},

	{
		value: "totf_four",
		label: "4 Water-sealed, other depository, shared with other household",
	},

	{
		value: "totf_five",
		label: "5 Closed pit",
	},

	{
		value: "totf_six",
		label: "6 Open pit",
	},

	{
		value: "totf_others",
		label: "7 Others (pail system and others)",
	},

	{
		value: "totf_none",
		label: "8 None",
	},
];
const raw_stats = [
	{ label: "1 Own or owner-like possession of house and lot" },
	{ label: "2 Rent house/room including lot" },
	{ label: "3 Own house, rent lot" },
	{ label: "4 Own house, rent-free lot with consent of owner" },
	{ label: "5 Own house, rent-free lot without consent of owner" },
	{ label: "6 Rent-free house and lot with consent of owner" },
	{ label: "7 Rent-free house and lot without consent of owner" },
	{ label: "8 Living in a public space without rent" },
	{ label: "9 Other tenure status, specify" },
];

export const housing_status = raw_stats.map((val, index) => ({
	value: renderValue(index, "housing", val.label),
	label: val.label,
}));

const raw_electricity_sources = [
	{ label: "1 Electric company" },
	{ label: "2 Generator" },
	{ label: "3 Solar" },
	{ label: "4 Battery" },
	{ label: "5 Others, specify" },
];

export const housing_electricity_sources = raw_electricity_sources.map(
	(val, index) => ({
		value: renderValue(index, "soe_", val.label),
		label: val.label,
	})
);

const raw_garbage_collection = [
	{ label: "1 Garbage Collection" },
	{ label: "2 Burning" },
	{ label: "3 Composting" },
	{ label: "4 Recycling" },
	{ label: "5 Waste Segregation" },
	{ label: "6 Pit with cover" },
	{ label: "7 Pit without cover" },
	{ label: "8 Throwing of garbage in river, vacant lot, etc." },
	{ label: "9 Others, specify" },
];

export const garbage_collection = raw_garbage_collection.map((val, index) => ({
	value: renderValue(index, "gds_", val.label),
	label: val.label,
}));

const raw_collectors = [
	{
		label: "1 Municipality/city collector",
	},
	{
		label: "2 Barangay collector",
	},
	{
		label: "3 Private collector",
	},
	{
		label: "4 Others, specify",
	},
];

export const collectors = raw_collectors.map((val, index) => ({
	value: renderValue(index, "gc_", val.label),
	label: val.label,
}));

const rawOften = [
	{
		label: "1 Daily",
	},
	{
		label: "2 Thrice a week",
	},
	{
		label: "3 Twice a week",
	},
	{
		label: "4 Once a week",
	},
	{
		label: "5 Others, specify",
	},
];

export const often_options = rawOften.map((val, index) => ({
	value: renderValue(index, "gct_", val.label),
	label: val.label,
}));

const rawCalamities = [
	{ label: "1. Typhoon" },
	{ label: "2. Flood" },
	{ label: "3. Drought" },
	{ label: "4. Earthquake" },
	{ label: "5. Volcanic Eruption" },
	{ label: "6. Landslide" },
	{ label: "7. Tsunami" },
	{ label: "8. Fire" },
	{ label: "9. Forest Fire" },
	{ label: "10. Armed Conflict" },
	{ label: "11. Other Calamity" },
];
export const calamities = rawOften.map((val, index) => ({
	value: index + 1,
	label: val.label,
}));
