import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { CheckCircle, CheckSquare, PlusCircle, XCircle } from "react-feather";
import { useHistory } from "react-router";
import { PharmacyRoleAndId, UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const OrderDetails = ({
	patient_id,
	claim_id,
	patient_name,
	getLocalOrder,
	selectedTracenumber,
}) => {
	const [details, setDetails] = useState([]);
	const [addDialog, setAddDialog] = useState({
		open: false,
		data: null,
	});

	const [processPaymentDialog, setProcessPaymentDialog] = useState({
		open: false,
		data: null,
	});

	const [addToBillingDialog, setAddToBillingDialog] = useState({
		open: false,
		data: null,
	});

	let amountToPay = 0;

	const getClaimIdDetails = async () => {
		var params = {
			claim_id: claim_id,
		};
		let response = await axios.get(
			"pharmacy/get/all-unclaimed-pres/details/byclaimid",
			{ params }
		);
		setDetails(response.data);
	};

	const calculateAmounToPay = (qty, amount) => {
		amountToPay += Number(amount) * parseInt(qty === null ? 0 : qty);

		return qty;
	};

	React.useEffect(() => {
		getClaimIdDetails();

		// eslint-disable-next-line
	}, [claim_id]);

	return (
		<Box>
			<Typography variant="h6" color="textSecondary">
				Rx Details
			</Typography>

			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell> Claim Id </TableCell>
							<TableCell> Products </TableCell>
							<TableCell> Qty </TableCell>
							<TableCell> Claimed Qty </TableCell>
							<TableCell> Order Qty </TableCell>
							<TableCell> Amount </TableCell>
							<TableCell> Action </TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{details.length > 0 ? (
							details.map((data, index) => (
								<TableRow key={index}>
									<TableCell>{data.claim_id?.replace("claim-", "")}</TableCell>
									<TableCell> {data.product_name} </TableCell>
									<TableCell align="right"> {data.quantity} </TableCell>
									<TableCell align="right"> {data.quantity_claimed} </TableCell>
									<TableCell align="right">
										{calculateAmounToPay(
											data.quantity_order,
											data.product_amount
										)}
									</TableCell>
									<TableCell align="right"> {data.product_amount} </TableCell>
									<TableCell>
										<IconButton
											color="primary"
											onClick={() => {
												setAddDialog({
													open: true,
													data: data,
												});
											}}
										>
											<PlusCircle />
										</IconButton>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={6}>
									<Typography color="secondary">No record found</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			<Box my={3} display="flex" justifyContent="flex-end">
				<Button
					color="primary"
					variant="contained"
					disabled={!Boolean(amountToPay)}
					onClick={() =>
						setProcessPaymentDialog({
							open: true,
							data: { amounttopay: amountToPay },
						})
					}
					style={{ marginRight: 10 }}
				>
					Direct Payment - &#8369; {Notify.convertToNumber(amountToPay)}
				</Button>

				<Button
					color="primary"
					variant="contained"
					disabled={!Boolean(amountToPay)}
					onClick={() =>
						setAddToBillingDialog({
							open: true,
							data: { amounttopay: amountToPay },
						})
					}
				>
					Add To Billing - &#8369; {Notify.convertToNumber(amountToPay)}
				</Button>
			</Box>

			{/* this is add order quantity */}
			{addDialog.open && (
				<AddOrderQtyDialog
					addDialog={addDialog}
					close={() => setAddDialog({ open: false, data: null })}
					getClaimIdDetails={() => getClaimIdDetails()}
				/>
			)}

			{/* process payment dialog */}
			{processPaymentDialog.open && (
				<ProcessPayment
					processDialog={processPaymentDialog}
					close={() => setProcessPaymentDialog({ open: false, data: null })}
					getClaimIdDetails={() => getClaimIdDetails()}
					claim_id={claim_id}
					patient_name={patient_name}
					getLocalOrder={getLocalOrder}
				/>
			)}

			{/* process payment dialog */}
			{addToBillingDialog.open && (
				<AddToBilling
					processDialog={addToBillingDialog}
					close={() => setAddToBillingDialog({ open: false, data: null })}
					getClaimIdDetails={() => getClaimIdDetails()}
					claim_id={claim_id}
					patient_name={patient_name}
					patient_id={patient_id}
					selectedTracenumber={selectedTracenumber}
					getLocalOrder={getLocalOrder}
				/>
			)}
		</Box>
	);
};

export default OrderDetails;

const AddOrderQtyDialog = ({ addDialog, close, getClaimIdDetails }) => {
	const [orderQty, setOrderQty] = useState(0);
	const quantityRem =
		parseInt(addDialog.data.quantity) -
		parseInt(addDialog.data.quantity_claimed);
	const [batches, setBatches] = useState([]);
	const [batchesDetails, setBatchesDetails] = useState([]);
	const [selectedBatchNo, setSelectedBatchNo] = useState(null);

	const handleNewQtyOrdered = async () => {
		if (selectedBatchNo === null) {
			Notify.customToast("Batch Number", "Batch number is a required field.");
			return;
		}

		if (orderQty > quantityRem) {
			Notify.customToast("Order Quantity Invalid", "Order not allowed.");
			return;
		}

		var avsQTy =
			batchesDetails.length > 0 ? parseInt(batchesDetails[0].totalAvsQty) : 0;

		if (parseInt(orderQty) > avsQTy) {
			Notify.customToast(
				"Order Quantity Invalid",
				"Order over in available qauntity."
			);
			return;
		}

		let response = await axios.post("/pharmacy/prescriptiom/new-ordered", {
			id: addDialog.data.id,
			orderQty: orderQty,
			batch_no: selectedBatchNo,
			orinal_avsqty: avsQTy,
		});

		if (response.data === "success") {
			close();
			getClaimIdDetails();
			Notify.customToast("Order Quantity", "Order succesfully add.");
		}
	};

	const getBatchesByProductId = async () => {
		let response = await axios.get(
			"/pharmacy/prescriptiom/product/batches-list",
			{
				params: { product_id: addDialog.data.prescription },
			}
		);
		setBatches(response.data);
	};

	const handleBatchAvsQty = async (batch_no) => {
		if (batch_no === "") {
			setBatchesDetails([]);
			return;
		}

		setSelectedBatchNo(batch_no);
		let response = await axios.get(
			"/pharmacy/prescriptiom/product/batch-avsqty",
			{
				params: { product_id: addDialog.data.prescription, batch_no: batch_no },
			}
		);
		setBatchesDetails(response.data);
	};

	React.useEffect(() => {
		getBatchesByProductId();

		// eslint-disable-next-line
	}, []);

	return (
		<Dialog open={addDialog.open} onClose={close}>
			<DialogTitle>Quantity Order</DialogTitle>

			<form>
				<DialogContent dividers>
					<Box>
						<TextField
							label="Max Quantity"
							variant="outlined"
							fullWidth
							defaultValue={quantityRem}
							disabled
						/>
					</Box>

					<Box my={2}>
						<TextField
							label="Select Batch No"
							variant="outlined"
							fullWidth
							select
							SelectProps={{
								native: true,
							}}
							InputLabelProps={{ shrink: true }}
							onChange={(e) => handleBatchAvsQty(e.target.value)}
						>
							<option value=""> Select</option>
							{batches.map((batch, index) => (
								<option key={index} value={batch.batch_no}>
									{batch.batch_no}
								</option>
							))}
						</TextField>
					</Box>

					{batchesDetails.length > 0 && (
						<TextField
							label="Product Available Qty"
							variant="outlined"
							type="number"
							defaultValue={batchesDetails[0].totalAvsQty}
							InputLabelProps={{ shrink: true }}
							fullWidth
							disabled
						/>
					)}

					<Box my={2}>
						<TextField
							label="Order Qty"
							variant="outlined"
							type="number"
							fullWidth
							onChange={(e) => setOrderQty(e.target.value)}
							InputProps={{
								inputProps: {
									min: 1,
									max: quantityRem,
								},
							}}
						/>
					</Box>
				</DialogContent>

				<DialogActions>
					<Button
						startIcon={<XCircle />}
						variant="contained"
						color="inherit"
						onClick={close}
					>
						No
					</Button>

					<Button
						startIcon={<CheckCircle />}
						variant="contained"
						color="primary"
						onClick={handleNewQtyOrdered}
					>
						Add
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

const ProcessPayment = ({
	processDialog,
	close,
	getClaimIdDetails,
	claim_id,
	patient_name,
	getLocalOrder,
}) => {
	const [data, setData] = useState({});
	const { users } = React.useContext(UsersData);
	const { userRoleAndId } = React.useContext(PharmacyRoleAndId);

	const recpt = new Date().getTime();

	const history = useHistory();

	const handleProcessPayment = async () => {
		let change = data.payment_amount
			? data.payment_amount > processDialog.data.amounttopay
				? data.payment_amount - processDialog.data.amounttopay
				: 0
			: 0;

		let response = await axios.post("/pharmacy/prescription/process/payment", {
			claim_id: claim_id,
			username: users.username,
			password: data.password,
			user_id: users.user_id,
			management_id: users.management_id,
			pharmacy_id: userRoleAndId.pharmacy_id,
			payment_amount: data.payment_amount,
			payment_change: change,
			recpt: recpt,
			patient_name: patient_name,
		});

		if (response.data === "pass-invalid") {
			Notify.fieldInvalid("password");
		}

		if (response.data === "success") {
			close();
			setData({});
			getClaimIdDetails();
			getLocalOrder();
			history.push(`/sph/app/pharmacy/receipt/${recpt}`);
			Notify.successRequest("Payment sucessful");
		}
	};

	const onInputchange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	return (
		<Dialog
			open={processDialog.open}
			onClose={close}
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth={"xs"}
			fullWidth
		>
			<DialogTitle>Process Payment</DialogTitle>

			<DialogContent dividers>
				<Box display="flex" align="center">
					<Box flexGrow={1}>
						<Typography variant="h4" color="secondary">
							{Notify.convertToNumber(processDialog.data.amounttopay)}
						</Typography>
						<Typography variant="caption" color="textSecondary">
							AMOUNT TO PAY
						</Typography>
					</Box>

					<Box flexGrow={1}>
						<Typography variant="h4" color="primary">
							{Notify.convertToNumber(
								Math.abs(
									data.payment_amount
										? data.payment_amount > processDialog.data.amounttopay
											? data.payment_amount - processDialog.data.amounttopay
											: 0
										: 0
								)
							)}
						</Typography>
						<Typography variant="caption" color="textSecondary">
							Amount To Change
						</Typography>
					</Box>
				</Box>

				<Box my={2}>
					<TextField
						label="Enter Payment Amount"
						fullWidth
						variant="outlined"
						type="number"
						name="payment_amount"
						onChange={onInputchange}
					/>
				</Box>

				<Box>
					<TextField
						label="Enter password"
						fullWidth
						variant="outlined"
						type="password"
						name="password"
						onChange={onInputchange}
					/>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button
					color="inherit"
					variant="contained"
					startIcon={<XCircle />}
					onClick={close}
				>
					No
				</Button>

				<Button
					color="primary"
					variant="contained"
					startIcon={<CheckCircle />}
					onClick={handleProcessPayment}
				>
					Process
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const AddToBilling = ({
	processDialog,
	close,
	getClaimIdDetails,
	claim_id,
	patient_name,
	getLocalOrder,
	patient_id,
	selectedTracenumber,
}) => {
	const [data, setData] = useState({});
	const { users } = React.useContext(UsersData);
	const { userRoleAndId } = React.useContext(PharmacyRoleAndId);

	const recpt = new Date().getTime();

	const history = useHistory();

	const handleProcessToAddBilling = async () => {
		let response = await axios.post(
			"/pharmacy/prescription/process/add-to-billing",
			{
				claim_id: claim_id,
				user_id: users.user_id,
				management_id: users.management_id,
				pharmacy_id: userRoleAndId.pharmacy_id,
				recpt: recpt,
				patient_name: patient_name,
				patient_id: patient_id,
				trace_number: selectedTracenumber,
			}
		);

		if (response.data === "pass-invalid") {
			Notify.fieldInvalid("password");
		}

		if (response.data === "success") {
			close();
			setData({});
			getClaimIdDetails();
			getLocalOrder();
			history.push(`/sph/app/pharmacy/receipt/${recpt}`);
			Notify.successRequest("Payment sucessful");
		}
	};

	console.log("data:", data);

	return (
		<Dialog
			open={processDialog.open}
			onClose={close}
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth={"xs"}
			fullWidth
		>
			<DialogContent>
				<DialogContentText>
					Are you sure to add this order to admitted patient bills?
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button
					color="inherit"
					variant="contained"
					startIcon={<XCircle />}
					onClick={close}
				>
					No
				</Button>

				<Button
					color="primary"
					variant="contained"
					startIcon={<CheckSquare />}
					onClick={handleProcessToAddBilling}
				>
					Add To Billing
				</Button>
			</DialogActions>
		</Dialog>
	);
};
