
import { blue, red, green, purple, indigo, teal } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export const DarkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        background: {
            default: "#212121"
        },
        primary: {
            light: blue[200],
            main: blue[500],
            dark: blue[700],
            background: '#525252',
            background_light: '#5a5a5a'
        },
        text: {
            primary: '#fff',
        }
    },
    typography: {
        htmlFontSize: 18,
        fontFamily: [
            'Montserrat',
        ].join(',')
    }
});

export const BlueTheme = createMuiTheme({
    palette: {
        type: 'light',
        background: {
            default: "#edf0f385"
        },
        primary: {
            light: blue[400],
            main: blue[700],
            dark: blue[900],
            background: blue[600],
            background_light: blue[500],
        },
    },
    typography: {
        htmlFontSize: 18,
        fontFamily: [
            'Montserrat',
        ].join(',')
    }
});

export const RedTheme = createMuiTheme({
    palette: {
        type: 'light',
        background: {
            default: "#edf0f385"
        },
        primary: {
            light: red[400],
            main: red[700],
            dark: red[900],
            background: red[600],
            background_light: red[500],
        },
    },
    typography: {
        htmlFontSize: 18,
        fontFamily: [
            'Montserrat',
        ].join(',')
    }
});

export const GreenTheme = createMuiTheme({
    palette: {
        type: 'light',
        background: {
            default: "#edf0f385"
        },
        primary: {
            light: green[400],
            main: green[700],
            dark: green[900],
            background: green[600],
            background_light: green[500],
        },
    },
    typography: {
        htmlFontSize: 18,
        fontFamily: [
            'Montserrat',
        ].join(',')
    }
});

export const PurpleTheme = createMuiTheme({
    palette: {
        type: 'light',
        background: {
            default: "#edf0f385"
        },
        primary: {
            light: purple[400],
            main: purple[700],
            dark: purple[900],
            background: purple[600],
            background_light: purple[500],
        },
    },
    typography: {
        htmlFontSize: 18,
        fontFamily: [
            'Montserrat',
        ].join(',')
    }
});

export const IndigoTheme = createMuiTheme({
    palette: {
        type: 'light',
        background: {
            default: "#edf0f385"
        },
        primary: {
            light: indigo[400],
            main: indigo[700],
            dark: indigo[900],
            background: indigo[600],
            background_light: indigo[500],
        },
    },
    typography: {
        htmlFontSize: 18,
        fontFamily: [
            'Montserrat',
        ].join(',')
    }
});

export const TealTheme = createMuiTheme({
    palette: {
        type: 'light',
        background: {
            default: "#edf0f385"
        },
        primary: {
            light: teal[400],
            main: teal[700],
            dark: teal[900],
            background: teal[600],
            background_light: teal[500],
        },
    },
    typography: {
        htmlFontSize: 18,
        fontFamily: [
            'Montserrat',
        ].join(',')
    }
});