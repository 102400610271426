import React, { useContext, useEffect, useState } from "react";
import Container from "src/layout/Container";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  Box,
  List,
  TextField,
  ListItem,
  TablePagination,
  ListItemText,
  Button,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import Notify from "src/notification/Notify";
import { Search } from "react-feather";
import Details from "./Details";
import CreateRoom from "./CreateRoom";

const HospitalRooms = () => {
  const { users } = useContext(UsersData);
  const [allRoom, setAllRoom] = useState([]);
  const mounted = IsMountedRef();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [search, setSearch] = useState("");

  const [dialog, setDialog] = useState({
    open: false,
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const getAllRoom = React.useCallback(async () => {
    let { data } = await axios.get(`accounting/room/get-roomlist`, {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setAllRoom(data);
    }
  }, [users, mounted]);

  useEffect(() => {
    getAllRoom();
  }, [getAllRoom]);

  const searchable = allRoom.filter((data) => {
    return (
      data.room_name.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.type.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Rooms",
          items: [{ name: "dashboard", path: "/sph/app" }],
        }}
        title={
          <Box display={"flex"}>
            <Box flexGrow={1}> Hospital Rooms </Box>
            <Box>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setDialog({ open: true })}
              >
                Create Room
              </Button>
            </Box>
          </Box>
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardHeader title={`Rooms`} />

              <CardContent>
                <List component="div">
                  <Box mb={2}>
                    <TextField
                      label="Search Room"
                      variant="outlined"
                      fullWidth
                      // margin="dense"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  <Box>
                    {searchable.length > 0
                      ? searchable
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((data, index) => (
                            <ListItem
                              key={index}
                              button
                              className="mb-2"
                              onClick={() => {
                                setSelectedRoom(data);
                              }}
                              selected={selectedRoom?.id === data.id}
                            >
                              <ListItemText
                                className={`gtc-capitalize`}
                                primary={data.room_name}
                                secondary={data.type}
                              />
                            </ListItem>
                          ))
                      : Notify.noRecord()}
                  </Box>
                </List>

                <TablePagination
                  component="div"
                  count={searchable.length}
                  rowsPerPageOptions={[
                    10,
                    50,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="List"
                />
              </CardContent>
            </Card>
          </Grid>

          {selectedRoom && (
            <Grid item xs={12} md={8}>
              <Details details={selectedRoom} />
            </Grid>
          )}
        </Grid>

        <Dialog open={dialog.open} onClose={() => setDialog({ open: false })}>
          <DialogTitle> Create Room </DialogTitle>
          <CreateRoom
            getAllRoom={getAllRoom}
            onClose={() => {
              setDialog({ open: false });
              setSelectedRoom(false);
            }}
          />
        </Dialog>
      </Container>
    </>
  );
};

export default HospitalRooms;
