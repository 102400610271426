import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const AccountingWaitingList = ({ request_id }) => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [tempProduct, setTempProduct] = useState({ data: [], ready: false });
  // let total = 0;

  const calculateTotal = (qty, srp) => {
    // total += parseInt(qty) * parseFloat(srp);
    return parseInt(qty) * parseFloat(srp);
  };

  const getTempDrProductsList = React.useCallback(async () => {
    try {
      let response = await axios.get(
        "accounting/warehouse/account/deliver/list-producttotemp",
        {
          params: {
            management_id: users.management_id,
            main_mgmt_id: users.main_mgmt_id,
            status: "waiting",
            request_id,
          },
        }
      );

      let result = response.data;
      if (mounted.current) {
        setTempProduct({ data: result, ready: true });
      }
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, mounted, request_id]);

  useEffect(() => {
    getTempDrProductsList();
  }, [getTempDrProductsList]);

  return (
    <Fragment>
      <Box component={Paper} variant="outlined" p={2}>
        <Box mb={2}>
          <Typography color="primary" variant="h5">
            <strong>Waiting Order</strong>
          </Typography>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Brand</b>
                </TableCell>
                <TableCell>
                  <b>Description</b>
                </TableCell>
                <TableCell>
                  <b>Unit</b>
                </TableCell>
                <TableCell>
                  <b>Batch</b>
                </TableCell>
                <TableCell>
                  <b>Exp. Date</b>
                </TableCell>
                <TableCell>
                  <b>Mfg. Date</b>
                </TableCell>
                <TableCell>
                  <b>Dr. Qty</b>
                </TableCell>
                <TableCell>
                  <b>Srp</b>
                </TableCell>
                <TableCell>
                  <b>Amount</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tempProduct.ready ? (
                tempProduct.data.length > 0 ? (
                  tempProduct.data.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell> {data.product_name} </TableCell>
                      <TableCell>{data.product_generic}</TableCell>
                      <TableCell> {data.unit} </TableCell>
                      <TableCell> {data.batch_number} </TableCell>
                      <TableCell>{data.expiration_date}</TableCell>
                      <TableCell>{data.manufactured_date}</TableCell>
                      <TableCell align="right">{data.qty}</TableCell>
                      <TableCell align="right">
                        {Notify.convertToNumber(data.srp)}
                      </TableCell>
                      <TableCell align="center">
                        {Notify.convertToNumber(
                          calculateTotal(data.qty, data.srp)
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={10}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={10}>
                    <Typography color="primary">
                      Loading, Please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Fragment>
  );
};

export default AccountingWaitingList;
