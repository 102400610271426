import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  Table,
  Typography,
  Box,
  TextField,
  Button,
  InputAdornment,
  TablePagination,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Grid,
  Divider,
  FormHelperText,
} from "@material-ui/core";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { Check, Clear, Edit, Search } from "@material-ui/icons";
import * as Yup from "yup";
import { Formik } from "formik";

const ImagingTest = () => {
  const { users } = useContext(UsersData);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [edit, setEdit] = useState({
    data: null,
    open: false,
  });

  const [imagingTest, setImagingTest] = useState({ data: [], ready: false });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const getImagingTest = async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
      };
      const request = await axios.get("imaging/test/getAllTest", { params });
      setImagingTest({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      console.log("error : ", error);
      // Notify.requestError(error)
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const searchable = imagingTest.data.filter((data) => {
    return (
      data.order_desc.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
    );
  });

  const handleEditTest = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("management_id", users.management_id);

    var error = [];
    if (
      formdata.get("type").trim() === "" ||
      formdata.get("type").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("Imaging type");
    }

    if (
      formdata.get("rate").trim() === "" ||
      formdata.get("rate").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("rate");
    }

    if (
      formdata.get("password").trim() === "" ||
      formdata.get("password").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsSubmitting(true);
      axios
        .post("imaging/test/edit-test", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            Notify.successRequest("new doctor");
            getImagingTest();
            setEdit({ data: null, open: true });
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    getImagingTest();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={9} md={9}>
        <Card>
          <CardHeader
            title={
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography variant="h5">Imaging Test</Typography>
                </Box>
                <Box>
                  <TextField
                    label="Search imaging test"
                    variant="outlined"
                    margin="dense"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Search color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            }
          />

          <CardMedia>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <strong> Order Desc </strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong> Type </strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong> Rate </strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong> Shots </strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong> Action </strong>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {imagingTest.ready ? (
                    imagingTest.data.length > 0 ? (
                      searchable.map((data, index) => (
                        <TableRow key={index} hover>
                          <TableCell> {data.order_desc} </TableCell>
                          <TableCell>
                            {data.type === "xray"
                              ? "X-ray"
                              : data.type === "ultra-sound"
                              ? "Ultra Sound"
                              : ""}
                          </TableCell>
                          <TableCell align="right">{data.order_cost}</TableCell>
                          <TableCell align="right">
                            {data.order_shots}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip arrow title="Edit Laboratory Test">
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  setEdit({
                                    data: data,
                                    open: true,
                                  })
                                }
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Typography variant="subtitle2" color="secondary">
                            No imaging order added.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography variant="subtitle2" color="primary">
                          Please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={searchable.length}
              rowsPerPageOptions={[10, 50, 100]}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="List"
            />
          </CardMedia>
        </Card>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Formik
          initialValues={{
            username: users.username,
            user_id: users.user_id,
            management_id: users.management_id,
            order: "",
            type: "",
            shots: "",
            rate: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            shots: Yup.number().min(1).required(),
            order: Yup.string().trim().required(),
            type: Yup.string().trim().required(),
            rate: Yup.number().min(1).required(),
            password: Yup.string().required(),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            try {
              const request = await axios.post(
                "imaging/test/newtest-save",
                getFormData(values)
              );
              if (request.data === "pass-invalid") {
                setErrors({ password: "Password is invalid." });
                Notify.fieldInvalid("password");
              }
              if (request.data === "success") {
                Notify.successRequest("add new imaging test");
                resetForm();
                setSubmitting(true);
                getImagingTest();
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Card>
                <CardHeader
                  title={
                    <Typography color="textPrimary" variant="h5">
                      New Imaging Test
                    </Typography>
                  }
                />

                <CardMedia>
                  <Box m={1}>
                    <Box my={2} className={`labselect2`}>
                      <TextField
                        error={Boolean(touched.order && errors.order)}
                        helperText={touched.order && errors.order}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.order}
                        fullWidth
                        required
                        name="order"
                        label="Order Description"
                        variant="outlined"
                      />
                    </Box>

                    <Box my={2} className={`labselect2`}>
                      <TextField
                        label="Image Type"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        select
                        SelectProps={{
                          native: true,
                        }}
                        error={Boolean(touched.type && errors.type)}
                        helperText={touched.type && errors.type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.type}
                        name="type"
                      >
                        <option value=""> Select </option>
                        <option value="xray"> X-ray </option>
                        <option value="ultra-sound"> Ultra Sound </option>
                      </TextField>
                    </Box>

                    <Box my={2} className={`labselect2`}>
                      <TextField
                        error={Boolean(touched.shots && errors.shots)}
                        helperText={touched.shots && errors.shots}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.shots}
                        fullWidth
                        required
                        name="shots"
                        label="Number of shots"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 1,
                            max: 100,
                            step: 1,
                          },
                        }}
                      />
                    </Box>

                    <Box mb={2} className={`labselect2`}>
                      <TextField
                        error={Boolean(touched.rate && errors.rate)}
                        helperText={touched.rate && errors.rate}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.rate}
                        fullWidth
                        required
                        name="rate"
                        label="Order Rate"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 1,
                            max: 100000,
                            step: 0.01,
                          },
                        }}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        fullWidth
                        required
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        label="Enter your password"
                        variant="outlined"
                        type="password"
                      />
                    </Box>

                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}

                    <Divider />

                    <Box my={2} display="flex">
                      <Box flexGrow={1} />

                      <Button
                        variant="contained"
                        color="default"
                        type="reset"
                        startIcon={<Clear />}
                      >
                        Clear
                      </Button>
                      <Box ml={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          startIcon={<Check />}
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </CardMedia>
              </Card>
            </form>
          )}
        </Formik>
      </Grid>

      {/* edit dialog */}
      {edit.data && edit.open && (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={edit.open}
          onClose={() => setEdit({ data: null, open: true })}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle> Edit Imaging Test </DialogTitle>
          <form onSubmit={handleEditTest}>
            <DialogContent dividers>
              <Box my={2}>
                <TextField
                  fullWidth
                  required
                  name="id"
                  label="Order Id"
                  variant="outlined"
                  hidden
                  defaultValue={edit.data.order_id}
                />
                <TextField
                  fullWidth
                  required
                  name="order_desc"
                  label="Order Description"
                  variant="outlined"
                  disabled
                  defaultValue={edit.data.order_desc}
                />
              </Box>

              <Box my={2}>
                {/* <TextField
                  defaultValue={edit.data.type}
                  fullWidth
                  required
                  variant="outlined"
                  select
                  label="Image Type"
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=""> Select </option>
                  <option value="xray"> Xray </option>
                  <option value="ultra-sound"> Ultra Sound </option>
                </TextField> */}

                <TextField
                  label="Image Type"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  defaultValue={edit.data.type}
                  name="type"
                >
                  <option value=""> Select </option>
                  <option value="xray"> X-ray </option>
                  <option value="ultra-sound"> Ultra Sound </option>
                </TextField>
              </Box>

              <Box my={2}>
                <TextField
                  fullWidth
                  required
                  name="order_shot"
                  label="Number of shots"
                  variant="outlined"
                  defaultValue={edit.data.order_shots}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="rate"
                  label="Order Rate"
                  variant="outlined"
                  type="number"
                  defaultValue={edit.data.order_cost}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 100000,
                      step: 0.01,
                    },
                  }}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setEdit({ data: null, open: true })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                startIcon={
                  isSubmitting && <CircularProgress size={20} color="inherit" />
                }
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Grid>
  );
};

export default ImagingTest;
