import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { Card, CardHeader, Typography, useTheme, Box } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const CardGrandInventory = () => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const theme = useTheme();
  const [invLab, setInvLab] = useState(0);
  const [invStckroom, setInvStckroom] = useState(0);
  const [category, setCategory] = useState("all");

  const getAllLaboratoryInventory = React.useCallback(async () => {
    let response = await axios.get(
      "accounting/grand/laboratory/inventory-totalamount",
      {
        params: {
          management_id: users.management_id,
        },
      }
    );

    if (mounted.current) {
      if (response.data.length > 0) {
        calculateLabInventory(response.data);
      }
    }
  }, [mounted, users]);

  const calculateLabInventory = (data) => {
    let xx = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].length > 0) {
        var data2 = data[i];
        for (let j = 0; j < data2.length; j++) {
          xx +=
            parseInt(data2[j]._qty_remaining) *
            parseFloat(data2[j].msrp === null ? 0 : data2[j].msrp);
        }
      }
    }

    setInvLab(xx);
  };

  const getAllStockroomInventory = React.useCallback(async () => {
    let response = await axios.get(
      "accounting/grand/stockroom/inventory-totalamount",
      {
        params: {
          management_id: users.management_id,
        },
      }
    );

    if (mounted.current) {
      if (response.data.length > 0) {
        calculateStockroomInventory(response.data);
      }
    }
  }, [mounted, users]);

  const calculateStockroomInventory = (data) => {
    let xxx = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].length > 0) {
        var data2 = data[i];
        for (let j = 0; j < data2.length; j++) {
          xxx +=
            parseInt(data2[j]._qty_remaining) *
            parseFloat(data2[j].msrp === null ? 0 : data2[j]._srp);
        }
      }
    }
    setInvStckroom(xxx);
  };

  useEffect(() => {
    getAllLaboratoryInventory();
    getAllStockroomInventory();
  }, [getAllLaboratoryInventory, getAllStockroomInventory]);

  return (
    <Card elevation={5}>
      <CardHeader
        avatar={
          <FontAwesomeIcon
            icon={faBoxes}
            size={"5x"}
            style={{ color: `${theme.palette.primary.main}` }}
          />
        }
        title={
          <Typography variant="h4" color="textSecondary">
            Inventory
          </Typography>
        }
        subheader={
          <>
            <Box my={1}>
              <span
                style={{
                  textTransform: "uppercase",
                  fontSize: 10,
                  color:
                    category === "all" ? theme.palette.primary.main : "#888",
                  cursor: "pointer",
                }}
                onClick={() => setCategory("all")}
              >
                All
              </span>{" "}
              |{" "}
              <span
                style={{
                  textTransform: "uppercase",
                  fontSize: 10,
                  color:
                    category === "stockroom"
                      ? theme.palette.primary.main
                      : "#888",
                  cursor: "pointer",
                }}
                onClick={() => setCategory("stockroom")}
              >
                Stockroom
              </span>{" "}
              |{" "}
              <span
                style={{
                  textTransform: "uppercase",
                  fontSize: 10,
                  color:
                    category === "laboratory"
                      ? theme.palette.primary.main
                      : "#888",
                  cursor: "pointer",
                }}
                onClick={() => setCategory("laboratory")}
              >
                Laboratory
              </span>
            </Box>
            <Typography variant="h4" color="textPrimary">
              <strong>
                &#8369;{" "}
                {Notify.convertToNumber(
                  category === "all"
                    ? invLab + invStckroom
                    : category === "laboratory"
                    ? invLab
                    : invStckroom
                )}{" "}
              </strong>
            </Typography>
          </>
        }
      />
    </Card>
  );
};

export default CardGrandInventory;
