import {
	faBuilding,
	faClock,
	faDoorOpen,
	faNotesMedical,
	faProcedures,
	faUserMd,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { useLocation, useHistory } from "react-router-dom";
import HealthcareProfessionals from "src/cis/components/HealthcareProfessionals";
import FlatIcon from "src/components/FlatIcon";
import Img from "src/components/Img";
import { calculateAgeV3, tConvert } from "src/helpers/utils";
import { patientFullName } from "src/helpers/utils";

const CisPatientAppointmentItem = ({ patient, data, onClick }) => {
	const history = useHistory();
	const location = useLocation();
	const doctors = data?.relationships?.healthcare_professionals || [];

	return (
		<div
			className="!border !border-slate-100 bg-white  duration-300 cursor-pointer rounded-[12px] bg-opacity-50 p-[16px] gap-3 flex items-start relative hover:shadow-lg"
			onClick={() => {
				history.push(
					`/sph/app/${location.pathname.split("/")[3]}/patients/${
						patient?.id
					}`
				);
			}}
		>
			<div className="relative w-11 aspect-square rounded-lg min-w-[44px]">
				<Img
					name={patientFullName(patient)}
					src={
						patient?.src?.length > 0
							? patient?.src
							: patient?.avatar?.length > 0
							? patient?.avatar
							: null
					}
					type="user"
					className="absolute top-0 left-0 w-full h-full rounded-lg !border !border-white object-contain bg-slate-200"
				/>
			</div>
			<div className="flex flex-col gap-1 w-full">
				<span className={`font-bold text-sm group-hover:text-white`}>
					{patientFullName(patient)}
				</span>
				<div className="flex items-center gap-4 divide-x !text-xs text-slate-600 group-hover:text-white">
					<span className="text-xs flex items-center gap-2">
						<FlatIcon
							icon="rr-venus-mars"
							className="mb-[-2px] text-xs"
						/>
						<span className="">{patient?.gender}</span>
					</span>
					<span className="text-xs flex items-center gap-2 pl-3">
						<FlatIcon
							icon="rr-calendar"
							className="mb-[-2px] text-xs"
						/>
						<span className="">
							{calculateAgeV3(patient?.birthday)}
						</span>
					</span>
				</div>
				<div className="flex items-center gap-4 divide-x !text-sm text-indigo-700 group-hover:text-white mt-2">
					<span className="text-sm flex items-center gap-2">
						<FontAwesomeIcon
							icon={faBuilding}
							className="mb-[-2px] text-xs"
						/>
						<span className="font-bold text-sm">
							{data?.relationships?.room?.name} - [
							{data?.relationships?.room?.type}]
						</span>
					</span>
				</div>
				<div className="flex items-center gap-4 divide-x !text-sm text-black group-hover:text-white mt-2">
					<span className="text-sm flex items-center gap-2">
						<FontAwesomeIcon
							icon={faNotesMedical}
							className="mb-[-2px] text-xs"
						/>
						<span className="font-bold text-sm">
							{data?.procedure}
						</span>
					</span>
				</div>
				<div className="flex items-center gap-4 divide-x !text-sm text-slate-600 group-hover:text-white">
					<span className="text-sm flex items-center gap-2">
						<FontAwesomeIcon
							icon={faClock}
							className="mb-[-2px] text-xs"
						/>
						<span className="">
							{tConvert(data?.time)} - {tConvert(data?.time, 2)}{" "}
							(2HR)
						</span>
					</span>
				</div>
				{doctors?.length > 0 ? (
					<div className="flex items-center gap-4 divide-x !text-xs text-slate-600 group-hover:text-white mt-2">
						<span className="text-xs flex items-center gap-2">
							<div className="flex flex-col gap-y-1">
								<span className="font-bold">
									Healthcare Professionals
								</span>
								<HealthcareProfessionals doctors={doctors} />
							</div>
						</span>
					</div>
				) : (
					""
				)}
			</div>
			{onClick ? (
				<div
					className={`absolute top-3 right-3 rounded-xl font-medium text-white py-1 px-2 text-xs flex items-center justify-center gap-1 hover:shadow-md ${
						data?.status == "resu" ? "bg-green-500" : "bg-blue-500"
					}`}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();

						if (onClick) {
							onClick();
						}
					}}
				>
					{data?.status == "resu"
						? "Move Back to Room"
						: "Move to PACU"}{" "}
					<FlatIcon icon="rr-arrow-small-right" />
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default CisPatientAppointmentItem;
