import {
	Box,
	Dialog,
	TextField,
	Typography,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState, useRef } from "react";
import Notify from "../../notification/Notify";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "../../utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import Specimen from "./Specimen";
import { CheckSquare, XCircle } from "react-feather";

const MiscellaneousOrderDetails = ({
	queue,
	formheader,
	order,
	getMiscellaneousTestOrder,
	resetDisplay,
	process_for,
}) => {
	const { users } = React.useContext(UsersData);
	const history = useHistory();
	const [orderDetails, setOrderDetails] = useState({
		data: [],
		ready: false,
	});
	const [processDialog, setProcessDialog] = useState({
		data: null,
		dialog: false,
	});
	const [processSubmitting, setProcessSubmitting] = useState(false);
	const [resultSubmitting, setResultSubmitting] = useState(false);
	const [savebtnDisabled, setSavebtnDisabled] = useState(true);
	const [orderItems, setOrderItems] = useState([]);

	const [confirmDialog, setConfirmDialog] = useState(false);
	const saveButtonRef = useRef();

	const getMiscellaneousOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order.trace_number };
		Axios.get("laboratory/order/ordernew-miscellaneoustest/details", {
			params,
		})
			.then((response) => {
				const data = response.data;
				setOrderDetails({
					data: data,
					ready: true,
				});

				checkIfSaveBtnIsEnabled(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const checkIfSaveBtnIsEnabled = (data) => {
		for (let i = 0; i < data.length; i++) {
			if (Boolean(data[i].is_processed)) {
				setSavebtnDisabled(false);
			}
		}
	};

	const handleSaveResult = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("trace_number", order.trace_number);
		formdata.set("queue", queue);
		formdata.set("process_for", process_for);

		var error = [];

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setResultSubmitting(true);
			Axios.post(
				"laboratory/order/ordernew-miscellaneous/save-process-result",
				formdata
			)
				.then((response) => {
					const data = response.data;
					if (data.message === "success") {
						setSavebtnDisabled(true);
						Notify.successRequest("order result added.");
						setTimeout(() => {
							history.push(
								`/sph/app/laboratory/record/print/order/${order.trace_number}`
							);
						}, 5000);
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				});
		}
	};

	const handleProcessOrder = async (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", processDialog.data.order_id);
		formdata.set("trace_number", processDialog.data.trace_number);

		var error = [];
		setProcessSubmitting(true);

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			let response = await Axios.post(
				"laboratory/miscellaneoustest/order-setprocess/custom-qty",
				formdata
			);

			if (response.data.message === "reagent-error") {
				Notify.customToast("Reagent Not Error", "Reagent/items not available.");
			}

			if (response.data.message === "pass-invalid") {
				Notify.fieldInvalid("password");
			}

			if (response.data.message === "success") {
				Notify.successRequest("process");
				setProcessDialog({ data: null, dialog: false });
				getMiscellaneousOrderDetails();
			}
		}

		setTimeout(() => {
			setProcessSubmitting(false);
		}, 2000);
	};

	const handleProcessSelectedOrder = async (data) => {
		let response = await Axios.get(
			"laboratory/items/laborder/list-available-items",
			{
				params: {
					user_id: users.user_id,
					management_id: users.management_id,
					order_id: data.order_id,
				},
			}
		);

		setOrderItems(response.data);
		setProcessDialog({ data: data, dialog: true });
	};

	useEffect(() => {
		getMiscellaneousOrderDetails();
		// eslint-disable-next-line
	}, [order]);

	return (
		<>
			<form onSubmit={handleSaveResult}>
				<Card elevation={0}>
					<Box>
						<CardHeader
							component={Box}
							align="center"
							title={formheader && formheader.name}
							subheader={
								<Box>
									<Typography>{formheader && formheader.address}</Typography>
									<Typography>
										{formheader && formheader.contact_number}
									</Typography>
								</Box>
							}
						/>
					</Box>

					<Box>
						<Typography variant="h6" align="center">
							<b> MISCELLANEOUS </b>
						</Typography>
					</Box>

					<CardContent>
						{/* paitent information */}
						<Box display="flex">
							<Box flexGrow={1} mb={2}>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT NAME:{" "}
										</Typography>
										{`${order.lastname}, ${order.firstname} ${
											order.middle === null ? "" : order.middle
										}`}
									</Typography>
								</Box>

								<Box display="flex" mb={2}>
									<Box>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												AGE:{" "}
											</Typography>
											{order.birthday === null
												? "none"
												: Notify.calculateAge(order.birthday)}
										</Typography>
									</Box>
									<Box ml={5}>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												GENDER:{" "}
											</Typography>
											{order.gender === null ? "none" : order.gender}
										</Typography>
									</Box>
								</Box>

								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT ADDRESS:{" "}
										</Typography>
										{`${order.street} ${order.barangay} ${order.city} `}
									</Typography>
								</Box>

								<Box>
									<Typography variant="caption" className="font-weight-bold">
										PATIENT CONDITION:{" "}
									</Typography>
									{order.patient_condition === null
										? " none"
										: order.patient_condition}
								</Box>
							</Box>

							<Box>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											DATE:{" "}
										</Typography>
										{Notify.createdAt(new Date().toLocaleString())}
									</Typography>
								</Box>
							</Box>
						</Box>
					</CardContent>

					<CardContent>
						<Specimen
							patient_id={order.patient_id}
							trace_number={order.trace_number}
						/>
					</CardContent>

					<CardContent>
						{/* order details */}
						{orderDetails.ready
							? orderDetails.data.length > 0
								? orderDetails.data.map((data, index) => (
										<Fragment key={index}>
											{/* miscellaneous order */}
											{/* hidden inputs */}
											<Box>
												<TextField
													margin="dense"
													fullWidth
													name="order_id[]"
													label="order_id"
													value={data.order_id}
													hidden
												/>
												<TextField
													margin="dense"
													fullWidth
													name="patient_id"
													label="patient_id"
													value={data.patient_id}
													hidden
												/>

												<TextField
													margin="dense"
													fullWidth
													name="doctors_id"
													label="doctor_id"
													value={data.doctor_id === null ? "" : data.doctor_id}
													hidden
												/>
											</Box>

											<Box
												mb={2}
												hidden={!Boolean(parseInt(data.miscellaneous_test))}
											>
												<Box>
													<Box mb={2} display="flex">
														<Box flexGrow={1}>
															<Typography>
																<b> MISCELLANEOUS </b>
															</Typography>
														</Box>

														<Box>
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</Box>
													</Box>

													<Box hidden={!Boolean(parseInt(data.is_processed))}>
														<Table>
															<TableBody>
																<TableRow>
																	<TableCell>
																		<Typography variant="subtitle2">
																			SPICEMEN :
																		</Typography>
																	</TableCell>
																	<TableCell>
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="specimen[]"
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell>
																		<Typography variant="subtitle2">
																			TEST :
																		</Typography>
																	</TableCell>
																	<TableCell>
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="test[]"
																			/>
																		</Box>
																	</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell>
																		<Typography variant="subtitle2">
																			RESULT :
																		</Typography>
																	</TableCell>
																	<TableCell>
																		<Box>
																			<TextField
																				margin="dense"
																				fullWidth
																				name="result[]"
																			/>
																		</Box>
																	</TableCell>
																</TableRow>
															</TableBody>
														</Table>
													</Box>
												</Box>
											</Box>

											<Box
												mb={2}
												hidden={!Boolean(parseInt(data.pregnancy_test_urine))}
											>
												<Box mb={2} display="flex">
													<Box flexGrow={1}>
														<Typography>
															<b> PREGNANCY TEST (URINE) </b>
														</Typography>
													</Box>

													<Box>
														<Button
															disabled={Boolean(parseInt(data.is_processed))}
															size="small"
															variant="contained"
															color="primary"
															onClick={() => {
																handleProcessSelectedOrder(data);
															}}
														>
															Process
														</Button>
													</Box>
												</Box>

												{Boolean(parseInt(data.is_processed)) && (
													<Box>
														<TextField
															fullWidth
															label="Result"
															name="pregnancy_test_urine_result[]"
															variant="outlined"
															multiline
														/>
													</Box>
												)}
											</Box>

											<Box
												mb={2}
												hidden={!Boolean(parseInt(data.pregnancy_test_serum))}
											>
												<Box mb={2} display="flex">
													<Box flexGrow={1}>
														<Typography>
															<b> PREGNANCY TEST (SERUM) </b>
														</Typography>
													</Box>

													<Box>
														<Button
															disabled={Boolean(parseInt(data.is_processed))}
															size="small"
															variant="contained"
															color="primary"
															onClick={() => {
																handleProcessSelectedOrder(data);
															}}
														>
															Process
														</Button>
													</Box>
												</Box>

												{Boolean(parseInt(data.is_processed)) && (
													<Box>
														<TextField
															fullWidth
															label="Result"
															name="pregnancy_test_serum_result[]"
															variant="outlined"
															multiline
														/>
													</Box>
												)}
											</Box>

											<Box
												mb={2}
												hidden={!Boolean(parseInt(data.papsmear_test))}
											>
												<Box mb={2} display="flex">
													<Box flexGrow={1}>
														<Typography>
															<b> PAPSMEAR TEST </b>
														</Typography>
													</Box>

													<Box>
														<Button
															disabled={Boolean(parseInt(data.is_processed))}
															size="small"
															variant="contained"
															color="primary"
															onClick={() => {
																handleProcessSelectedOrder(data);
															}}
														>
															Process
														</Button>
													</Box>
												</Box>

												{Boolean(parseInt(data.is_processed)) && (
													<Box>
														<TextField
															fullWidth
															label="Result"
															name="papsmear_test_result[]"
															variant="outlined"
															multiline
														/>
													</Box>
												)}
											</Box>

											<Box
												mb={2}
												hidden={
													!Boolean(parseInt(data.papsmear_test_with_gramstain))
												}
											>
												<Box mb={2} display="flex">
													<Box flexGrow={1}>
														<Typography>
															<b> PAPSMEAR WITH GRAMSTAIN TEST </b>
														</Typography>
													</Box>

													<Box>
														<Button
															disabled={Boolean(parseInt(data.is_processed))}
															size="small"
															variant="contained"
															color="primary"
															onClick={() => {
																handleProcessSelectedOrder(data);
															}}
														>
															Process
														</Button>
													</Box>
												</Box>

												{Boolean(parseInt(data.is_processed)) && (
													<Box>
														<TextField
															fullWidth
															label="Result"
															name="papsmear_test_with_gramstain_result[]"
															variant="outlined"
															multiline
														/>
													</Box>
												)}
											</Box>
											{/* miscellaneous order end */}
										</Fragment>
								  ))
								: Notify.noRecord()
							: Notify.loading()}
					</CardContent>
					<Box display="flex" m={1}>
						<Box flexGrow={1} />
						<Box>
							<CardActions>
								<Button
									variant="contained"
									size="large"
									color="primary"
									type="submit"
									hidden
									ref={saveButtonRef}
									disabled={!confirmDialog}
								>
									Save Result Button
								</Button>

								<Button
									variant="contained"
									size="large"
									color="primary"
									onClick={() => setConfirmDialog(true)}
									disabled={savebtnDisabled}
								>
									Save Result
								</Button>
							</CardActions>
						</Box>
					</Box>

					{/* confirmation dialog */}
					<Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
						<DialogContent>
							<Typography>Are you sure to continue and save result?</Typography>
						</DialogContent>
						<DialogActions>
							<Button
								variant="contained"
								color="default"
								startIcon={<XCircle />}
								onClick={() => setConfirmDialog(false)}
							>
								No
							</Button>
							<Button
								variant="contained"
								color="primary"
								disabled={resultSubmitting}
								startIcon={
									resultSubmitting ? (
										<CircularProgress size={20} color="inherit" />
									) : (
										<CheckSquare />
									)
								}
								onClick={() => saveButtonRef.current.click()}
							>
								Save
							</Button>
						</DialogActions>
					</Dialog>
				</Card>
			</form>

			{/* set as process dialog */}
			<Dialog
				open={processDialog.dialog}
				onClose={() => setProcessDialog({ data: null, dialog: false })}
				disableBackdropClick
				PaperComponent={DraggableDialog}
				maxWidth={"xs"}
				fullWidth
			>
				<DialogTitle id="draggable-handle">Process order</DialogTitle>
				<form onSubmit={handleProcessOrder}>
					<DialogContent dividers>
						{orderItems.length > 0
							? orderItems.map((data, index) => (
									<Box key={index} mb={2}>
										<Box>
											<TextField
												fullWidth
												name="item_id[]"
												value={data.item_id}
												variant="outlined"
												hidden
											/>
										</Box>

										<Box>
											<TextField
												fullWidth
												required
												label={`${data.description} qty to process`}
												name="qty[]"
												variant="outlined"
												defaultValue={1}
												InputLabelProps={{
													shrink: true,
												}}
												// InputProps={{
												//   inputProps: {
												//     min: 0,
												//     max: data._total_qty_available,
												//   },
												// }}
												type="number"
											/>
										</Box>
									</Box>
							  ))
							: "No reagent/item found."}

						<Box mt={2}>
							<TextField
								fullWidth
								required
								defaultValue="ok"
								name="remarks"
								label={`Remarks`}
								variant="outlined"
							/>
						</Box>

						<Box mt={2}>
							<TextField
								fullWidth
								required
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setProcessDialog({ data: null, dialog: false })}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={processSubmitting}
							startIcon={
								processSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<CheckCircleIcon />
								)
							}
						>
							process
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default MiscellaneousOrderDetails;
