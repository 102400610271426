import React, { Fragment } from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const DrugTestPrintAll = ({
  allowHeaderPatientInfo,
  patients,
  drugTestOrderDetails,
}) => {
  return (
    <>
      {drugTestOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                DRUG TEST
              </span>
            </Typography>
          </Box>
          <Box>
            {drugTestOrderDetails.data.map((data, key) => {
              return (
                <Box my={2} key={key}>
                  <Box mb={3} hidden={!Boolean(parseInt(data.two_panels))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          DRUG TEST (2 PANELS)
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box my={1}>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.two_panels_result}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box mb={3} hidden={!Boolean(parseInt(data.three_panels))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          DRUG TEST (3 PANELS)
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box my={1}>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.three_panels_result}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box mb={3} hidden={!Boolean(parseInt(data.five_panels))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          DRUG TEST (5 PANELS)
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box my={1}>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.five_panels_result}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box my={1} align="center">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {drugTestOrderDetails.data.length > 0 &&
                        drugTestOrderDetails.data[0].pathologist}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Pathologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {drugTestOrderDetails.data.length > 0 &&
                          drugTestOrderDetails.data[0].pathologist_lcn}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {drugTestOrderDetails.data.length > 0 &&
                        drugTestOrderDetails.data[0].chief_medtech}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Chief Med Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {drugTestOrderDetails.data.length > 0 &&
                          drugTestOrderDetails.data[0].chief_medtech_lci}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      <span
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                      >
                        {drugTestOrderDetails.data.length > 0 &&
                          drugTestOrderDetails.data[0].medtech}
                      </span>
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Medical Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {drugTestOrderDetails.data.length > 0 &&
                            drugTestOrderDetails.data[0].lic_no}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default DrugTestPrintAll;
