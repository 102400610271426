import axios from "axios";
import axios2 from "axios";

const usePatientHook = () => {
  const getPatientInformation = (params) => {
    return axios.get("admission/patient/patient-information", { params });
  };

  const getPatientContactTracing = (params) => {
    return axios.get("admission/patient/patient-contacttracing", { params });
  };
  const getKoboToolboxEditApi = (kobotools_id, form_id) => {
    return axios.get(
      `https://kf.kobotoolbox.org/api/v2/assets/${form_id}/data/${kobotools_id}/enketo/edit/?return_url=false`,
      {
        headers: {
          Authorization: "Token ec827f3affe8042be262037d9c93471190ad2d53",
          Referer: "https://kf.kobotoolbox.org/",
        },
      }
    );
  };
  const savePatient = (formData, patient_id) => {
    if (patient_id) {
      return axios.post(`v1/patients/${patient_id}`, {
        ...formData,
        _method: "PATCH",
      });
    } else {
      return axios.post(`v1/patients`, {
        ...formData,
      });
    }
  };
  const savePHOPatient = (formData, patient_id) => {
    if (patient_id) {
      return axios.post(`v1/pho/patients/${patient_id}`, {
        ...formData,
        _method: "PATCH",
      });
    } else {
      return axios.post(`v1/pho/patients`, formData);
    }
  };

  const saveEditReason = (formData) => {
    return axios.post(`v1/pho/patient-remarks`, formData);
  };

  const getPatientInfo = (id) => {
    return axios.get(`v1/patients/${id}`);
  };
  return {
    getPatientInfo,
    getPatientInformation,
    getPatientContactTracing,
    savePatient,
    saveEditReason,
    savePHOPatient,
    getKoboToolboxEditApi,
  };
};

export default usePatientHook;
