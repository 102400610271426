import {
  Grid,
  Card,
  Box,
  Typography,
  CardHeader,
  useTheme,
  CardContent,
  Hidden,
} from "@material-ui/core";
import React, {
  useCallback,
  // useState,
  useEffect,
  useState,
  // useContext
} from "react";
import Label from "../../utils/Label";
import { useHistory } from "react-router";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import axios from "axios";
import IsMountedRef from "../../utils/IsMountedRef";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
// import { UsersData } from '../../ContextAPI';

const IncomeCounts = () => {
  const ismounted = IsMountedRef();
  const theme = useTheme();
  const history = useHistory();

  const { users } = React.useContext(UsersData);

  const [income, setIncome] = useState({ data: [], ready: false });

  const getAllIncome = useCallback(async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
      };
      const response = await axios.get("hmis/dashboard/get-all-income", {
        params,
      });
      const data = response.data;
      if (ismounted.current) {
        setIncome({ data: data, ready: true });
      }
    } catch (error) {
      console.log(error);
    }
  }, [users, ismounted]);

  // const userData = useContext(UsersData)

  // const [countNew, setCountNew] = useState(0)
  // const [countApproved, setCountApproved] = useState(0)
  // const [countCompleted, setCountCompleted] = useState(0)

  // const getNewAppointmentCount = async () => {
  //     try {
  //         var params = { user_id: userData.users.user_id, status: 'new' }
  //         const response = await axios.get('doctors/fullcalendar/appointment/counts-bystatus', { params })
  //         const data = response.data;
  //         if (ismounted.current) {
  //             setCountNew(data.length)
  //         }

  //     } catch (error) {
  //         console.log(error)
  //     }
  // }

  // const getApprovedAppointmentCount = async () => {
  //     try {
  //         var params = { user_id: userData.users.user_id, status: 'approved' }
  //         const response = await axios.get('doctors/fullcalendar/appointment/counts-bystatus', { params })
  //         const data = response.data;
  //         if (ismounted.current) {
  //             setCountApproved(data.length)
  //         }
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }

  // const getCompletedAppointmentCount = async () => {
  //     try {
  //         var params = { user_id: userData.users.user_id, status: 'completed' }
  //         const response = await axios.get('doctors/fullcalendar/appointment/counts-bystatus', { params })
  //         const data = response.data;
  //         if (ismounted.current) {
  //             setCountCompleted(data.length)
  //         }
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }

  useEffect(() => {
    getAllIncome();
    // getNewAppointmentCount()
    // getApprovedAppointmentCount()
    // getCompletedAppointmentCount()
  }, [ismounted, getAllIncome]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Card
            elevation={3}
            className={"pointer"}
            component={Box}
            borderLeft={7}
            borderColor={theme.palette.warning.light}
            onClick={() => history.push(`/sph/app/hmis/doctor`)}
          >
            <CardHeader
              style={{ padding: theme.spacing(1) }}
              title={<Label color="warning"> Doctor </Label>}
            />

            <CardContent style={{ padding: theme.spacing(1) }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow={1}>
                  <MonetizationOnIcon
                    style={{
                      color: theme.palette.warning.light,
                      fontSize: window.innerWidth > 600 ? "4em" : "2.5em",
                    }}
                  />
                </Box>
                <Box width="85%">
                  <Box align="right">
                    <Typography variant="h4" color="textSecondary">
                      {income.data.length > 0
                        ? Notify.convertToNumber(income.data[0].incomeDoctor)
                        : 0.0}
                    </Typography>
                  </Box>
                  <Box align="center">
                    <Hidden xsDown>
                      <Typography variant="subtitle2" color="textSecondary">
                        INCOME
                      </Typography>
                    </Hidden>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Card
            elevation={2}
            className={"pointer"}
            component={Box}
            borderLeft={7}
            borderColor={theme.palette.primary.light}
            onClick={() => history.push(`/sph/app/hmis/laboratory`)}
          >
            <CardHeader
              style={{ padding: theme.spacing(1) }}
              title={<Label color="primary"> Laboratory </Label>}
            />

            <CardContent style={{ padding: theme.spacing(1) }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow={1}>
                  <MonetizationOnIcon
                    style={{
                      color: theme.palette.primary.light,
                      fontSize: window.innerWidth > 600 ? "4em" : "2.5em",
                    }}
                  />
                </Box>
                <Box width="85%">
                  <Box align="right">
                    <Typography variant="h4" color="textSecondary">
                      {income.data.length > 0
                        ? Notify.convertToNumber(
                            income.data[0].incomeLaboratory
                          )
                        : 0.0}
                    </Typography>
                  </Box>
                  <Box align="center">
                    <Hidden xsDown>
                      <Typography variant="subtitle2" color="textSecondary">
                        INCOME
                      </Typography>
                    </Hidden>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Card
            elevation={2}
            className={"pointer"}
            component={Box}
            borderLeft={7}
            borderColor={theme.palette.success.light}
            onClick={() => history.push(`/sph/app/hmis/imaging`)}
          >
            <CardHeader
              style={{ padding: theme.spacing(1) }}
              title={<Label color="success"> Imaging </Label>}
            />

            <CardContent style={{ padding: theme.spacing(1) }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow={1}>
                  <MonetizationOnIcon
                    style={{
                      color: theme.palette.success.light,
                      fontSize: window.innerWidth > 600 ? "4em" : "2.5em",
                    }}
                  />
                </Box>
                <Box width="85%">
                  <Box align="right">
                    <Typography variant="h4" color="textSecondary">
                      {income.data.length > 0
                        ? Notify.convertToNumber(income.data[0].incomeImaging)
                        : 0.0}
                    </Typography>
                  </Box>
                  <Box align="center">
                    <Hidden xsDown>
                      <Typography variant="subtitle2" color="textSecondary">
                        INCOME
                      </Typography>
                    </Hidden>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Card
            elevation={2}
            className={"pointer"}
            component={Box}
            borderLeft={7}
            borderColor={theme.palette.error.light}
            onClick={() => history.push(`/sph/app/hmis/psychology`)}
          >
            <CardHeader
              style={{ padding: theme.spacing(1) }}
              title={<Label color="error"> Psychology </Label>}
            />

            <CardContent style={{ padding: theme.spacing(1) }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow={1}>
                  <MonetizationOnIcon
                    style={{
                      color: theme.palette.error.light,
                      fontSize: window.innerWidth > 600 ? "4em" : "2.5em",
                    }}
                  />
                </Box>
                <Box width="85%">
                  <Box align="right">
                    <Typography variant="h4" color="textSecondary">
                      {income.data.length > 0
                        ? Notify.convertToNumber(
                            income.data[0].incomePsychology
                          )
                        : 0.0}
                    </Typography>
                  </Box>
                  <Box align="center">
                    <Hidden xsDown>
                      <Typography variant="subtitle2" color="textSecondary">
                        INCOME
                      </Typography>
                    </Hidden>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Card
            elevation={2}
            className={"pointer"}
            component={Box}
            borderLeft={7}
            borderColor={theme.palette.common.black}
            onClick={() => history.push(`/sph/app/hmis/psychology`)}
          >
            <CardHeader
              style={{ padding: theme.spacing(1) }}
              title={<Label style={{ color: "#000" }}> Others Test </Label>}
            />

            <CardContent style={{ padding: theme.spacing(1) }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow={1}>
                  <MonetizationOnIcon
                    style={{
                      color: theme.palette.common.black,
                      fontSize: window.innerWidth > 600 ? "4em" : "2.5em",
                    }}
                  />
                </Box>
                <Box width="85%">
                  <Box align="right">
                    <Typography variant="h4" color="textSecondary">
                      {income.data.length > 0
                        ? Notify.convertToNumber(income.data[0].incomeOthers)
                        : 0.0}
                    </Typography>
                  </Box>
                  <Box align="center">
                    <Hidden xsDown>
                      <Typography variant="subtitle2" color="textSecondary">
                        INCOME
                      </Typography>
                    </Hidden>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Card
            elevation={2}
            className={"pointer"}
            component={Box}
            borderLeft={7}
            borderColor={theme.palette.grey[600]}
            onClick={() => history.push(`/sph/app/hmis/laboratory`)}
          >
            <CardHeader
              style={{ padding: theme.spacing(1) }}
              title={<Label> Packages </Label>}
            />

            <CardContent style={{ padding: theme.spacing(1) }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow={1}>
                  <MonetizationOnIcon
                    style={{
                      color: theme.palette.grey[600],
                      fontSize: window.innerWidth > 600 ? "4em" : "2.5em",
                    }}
                  />
                </Box>
                <Box width="85%">
                  <Box align="right">
                    <Typography variant="h4" color="textSecondary">
                      {income.data.length > 0
                        ? Notify.convertToNumber(income.data[0].incomePackages)
                        : 0.0}
                    </Typography>
                  </Box>
                  <Box align="center">
                    <Hidden xsDown>
                      <Typography variant="subtitle2" color="textSecondary">
                        INCOME
                      </Typography>
                    </Hidden>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default IncomeCounts;
