import React from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Card } from "@material-ui/core";
import EditPersonalInformation from "./editPatientProfile/EditPersonalInfomation";
import EditAddressAndContactInformation from "./editPatientProfile/EditAddressAndContactInformation";
import EditOtherInformation from "./editPatientProfile/EditOtherInformation";
import { styles } from "../styles/styles";
import axios from "axios";
import Notify from "src/notification/Notify";
import ButtonV3 from "src/components/ButtonV3";

export default function CisEditPatientsForm({ patient }) {
  const patientId = patient.id;

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    // const formData = new FormData();

    // formData.append("suffix", data?.suffix || "");
    // formData.append("prefix", data?.prefix || "");
    // formData.append("firstname", data?.firstname || "");
    // formData.append("lastname", data?.lastname || "");
    // formData.append("middlename", data?.middle || "");
    // formData.append("birthdate", data?.birthday || "");
    // formData.append("civil_status", data?.civil_status || "");
    // formData.append("street", data?.streetName || "");
    // formData.append("birthplace", data?.birthPlace || "");
    // formData.append("education_attainment", data?.education_attainment || "");
    // formData.append("employment_status", data?.employment_status || "");
    // formData.append("mother_firstname", data?.mother_firstname || "");
    // formData.append("mother_lastname", data?.mother_lastname || "");
    // formData.append("mother_middlename", data?.mother_middlename || "");
    // formData.append("mother_birthdate", data?.mother_birthdate || "");
    // formData.append("family_member", data?.family_member || "");
    // formData.append("indigenous", data?.indigenous || "");
    // formData.append("blood_systolic", "");
    // formData.append("blood_diastolic", "");
    // formData.append("blood_type", data?.bloodType || "");
    // formData.append("country", data?.country || "");
    // formData.append("region", data?.region || "");
    // formData.append("province", data?.province || "");
    // formData.append("municipality", data?.city || "");
    // formData.append("barangay", data?.barangay || "");
    // formData.append("telephone", data?.landline || "");
    // formData.append("mobile", data?.mobile || "");
    // formData.append("email", data?.email || "");
    // formData.append("purok", "");
    // formData.append("family_serial_no", data?.family_serial_no || "");
    // formData.append(
    //   "phil_health_category_type",
    //   data?.phil_health_category_type || ""
    // );
    // formData.append("enlistment_date", data?.enlistment_date || "");
    // formData.append(
    //   "phil_health_status_type",
    //   data?.phil_health_status_type || ""
    // );
    // formData.append("pcb_eligble", data?.pcb_eligble || "");
    // formData.append("phil_health_member", data?.phil_health_member || "");
    // formData.append("dswd_nhts", data?.dswd_nhts || "");
    // formData.append("city", data?.city || "");
    // formData.append("zip_code", data?.remarks || "");
    // formData.append("gender", data?.gender || "");

    const formData = {
      suffix: data?.suffix || "",
      prefix: data?.prefix || "",
      firstname: data?.firstname || "",
      lastname: data?.lastname || "",
      middlename: data?.middle || "",
      birthdate: data?.birthday || "",
      civil_status: data?.civil_status || "",
      street: data?.street || "",
      birthplace: data?.birthPlace || "",
      education_attainment: data?.education_attainment || "",
      employment_status: data?.employment_status || "",
      mother_firstname: data?.mother_firstname || "",
      mother_lastname: data?.mother_lastname || "",
      mother_middlename: data?.mother_middlename || "",
      mother_birthdate: data?.mother_birthdate || "",
      family_member: data?.family_member || "",
      indigenous: data?.indigenous || "",
      blood_systolic: null,
      blood_diastolic: null,
      blood_type: data?.bloodType || "",
      country: data?.country || "",
      region: data?.region || "",
      province: data?.province || "",
      municipality: data?.municipality || "",
      barangay: data?.barangay || "",
      telephone: data?.telephone || "",
      mobile: data?.mobile || "",
      email: data?.email || "",
      purok: "",
      family_serial_no: data?.family_serial_no || "",
      phil_health_category_type: data?.phil_health_category_type || "",
      enlistment_date: data?.enlistment_date || "",
      phil_health_status_type: data?.phil_health_status_type || "",
      pcb_eligble: data?.pcb_eligble || "",
      phil_health_member: data?.phil_health_member || "",
      dswd_nhts: data?.dswd_nhts || "",
      city: data?.municipality || "",
      zip_code: data?.zip_code || "",
      gender: data?.gender || "",
      religion: data?.religion || "",
    };

    axios
      .patch(`v1/patient-profile-update/${patientId}`, formData)
      .then((response) => {
        let data = response.data;
        console.log(data);
        Notify.successRequest("Profile Update");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="flex w-full flex-col">
      <form>
        <div className="mx-auto " style={styles.parentContainer}>
          <Card elevation={3} className="p-4 mb-10 " component={Box}>
            <EditPersonalInformation
              setValue={setValue}
              patient={patient}
              register={register}
              errors={errors}
            />
            <EditAddressAndContactInformation
              setValue={setValue}
              patient={patient}
              register={register}
              errors={errors}
            />
            <EditOtherInformation
              setValue={setValue}
              patient={patient}
              register={register}
              errors={errors}
            />
          </Card>
        </div>
        <div className="mx-auto" style={styles.button}>
          <ButtonV3 onClick={handleSubmit(onSubmit)}>Submit</ButtonV3>
        </div>
      </form>
    </div>
  );
}
