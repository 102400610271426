import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  CardContent,
  CardActions,
  CircularProgress,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";
import thyroidProfileRef from "src/laboratory/references/Ref_ThyroidProfile";

const ThyroidEditForm = ({ info, close }) => {
  const { users } = useContext(UsersData);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", info.data.trace_number);
    formdata.set("order_id", info.data.order_id);
    setResultSubmitting(true);
    axios
      .post("laboratory/thyroid-prof/edit-result", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("order result edit successful");
          close();
          setSavebtnDisabled(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setResultSubmitting(false));
  };

  return (
    <>
      <CardContent>
        <Fragment>
          <form onSubmit={handleSaveResult}>
            {info.ready && (
              <>
                <Box display="flex" my={2}>
                  <Box flexGrow={1}>
                    <Typography variant="body1" align="center">
                      <b> THYROID PROFILE </b>
                    </Typography>
                  </Box>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell align="center">
                          <b> RESULT </b>
                        </TableCell>
                        <TableCell align="center">
                          <b> REFERENCE VALUES </b>
                        </TableCell>
                        <TableCell align="center">
                          <b> REMARKS </b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow hidden={info.data.t3 === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            T3
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="t3"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.t3 === null ? "" : info.data.t3
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box> {thyroidProfileRef.t3()} </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="t3_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.t3_remarks === null
                                  ? ""
                                  : info.data.t3_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.t4 === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            T4
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="t4"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.t4 === null ? "" : info.data.t4
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box> {thyroidProfileRef.t4()} </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="t4_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.t4_remarks === null
                                  ? ""
                                  : info.data.t4_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.tsh === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            TSH
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="tsh"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.tsh === null ? "" : info.data.tsh
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{thyroidProfileRef.tsh()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="tsh_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.tsh_remarks === null
                                  ? ""
                                  : info.data.tsh_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.ft4 === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            FT4
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ft4"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.ft4 === null ? "" : info.data.ft4
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{thyroidProfileRef.ft4()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ft4_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.ft4_remarks === null
                                  ? ""
                                  : info.data.ft4_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.ft3 === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            FT3
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ft3"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.ft3 === null ? "" : info.data.ft3
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{thyroidProfileRef.ft3()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ft3_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.ft3_remarks === null
                                  ? ""
                                  : info.data.ft3_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.t3t4 === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            t3t4
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="t3t4"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.t3t4 === null ? "" : info.data.t3t4
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box> none </Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="t3t4_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.t3t4_remarks === null
                                  ? ""
                                  : info.data.t3t4_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.fht === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            fht
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="fht"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.fht === null ? "" : info.data.fht
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box> none </Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="fht_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.fht_remarks === null
                                  ? ""
                                  : info.data.fht_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}

            <Box display="flex">
              <Box flexGrow={1} />
              <Box>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={savebtnDisabled}
                    startIcon={
                      resultSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Save Result
                  </Button>
                </CardActions>
              </Box>
            </Box>
          </form>
        </Fragment>
      </CardContent>
    </>
  );
};

export default ThyroidEditForm;
