import Axios from "axios";
import Echo from "laravel-echo";
import { useContext, useEffect, useState } from "react";
import { UsersData } from "./ContextAPI";
import SlashScreen from "./utils/SlashScreen";
import Pusher from "pusher-js";

window.Pusher = Pusher;

const CheckAuth = ({ children }) => {
	const userContext = useContext(UsersData);
	const [loading, setLoading] = useState(true);

	const getUsersInformationOnReload = async () => {
		console.log("getUsersInformationOnReload");
		try {
			let response = await Axios.get("my/information");
			userContext.updateUsers(response.data);
			const token = window.localStorage.getItem("hmis_token");

			console.log(
				"getUsersInformationOnReload window.Echo22",
				window.Echo
			);
			window.Echo = new Echo({
				broadcaster: "pusher",
				authEndpoint: `${process.env.REACT_APP_API}broadcasting/auth`,
				key: process.env.REACT_APP_PUSHER_KEY,
				auth: {
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + token,
					},
				},
				cluster: "ap1",
				wsHost: process.env.REACT_APP_PUSHER_HOST,
				disableStats: process.env.REACT_APP_PUSHER_SCHEME === "https",
				forceTLS: process.env.REACT_APP_PUSHER_SCHEME === "https",
				enabledTransports: ["ws", "wss"],
				encrypted: true,
			});
			console.log("window.Echo22", window.Echo);
			setTimeout(() => {
				setLoading(false);
			}, 2000);
		} catch (error) {
			console.log(
				"getUsersInformationOnReload window.Echoerrorerror",
				error
			);
			if (error.message === "Request failed with status code 401") {
				localStorage.removeItem("hmis_token");
				localStorage.removeItem("hmis_page");
				window.location.href = "/";
			}
		}
	};

	useEffect(() => {
		getUsersInformationOnReload();
		//eslint-disable-next-line
	}, []);

	if (loading) {
		return <SlashScreen />;
	}

	return children;
};

export default CheckAuth;
