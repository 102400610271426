import { TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "./Header";
import { styles } from "../../styles/styles";

export default function EditPersonalInformation({
  patient,
  setValue,
  register,
  errors,
}) {
  return (
    <>
      <Header title={"Personal Information"} />
      <div className="px-4 mb-3">
        <div style={styles.parentDivContainerNoGap}>
          <div className={styles.borderRight}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: " 100px auto",
                gap: "25px",
              }}
            >
              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>
                      Prefix<span style={styles.astirisColor}>*</span>
                    </label>
                  }
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.prefix}
                  {...register("prefix", { required: true })}
                  error={errors.prefix}
                  helperText={errors.prefix && "Prefix is required"}
                />
              </div>
              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>
                      Last Name<span style={styles.astirisColor}>*</span>
                    </label>
                  }
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.lastname}
                  {...register("lastname", { required: true })}
                  error={errors.lastName}
                  helperText={errors.lastName && "Last Name is required"}
                />
              </div>
            </div>
            <div style={styles.divContainer}>
              <TextField
                label={
                  <label style={styles.label}>
                    First Name<span style={styles.astirisColor}>*</span>
                  </label>
                }
                variant="outlined"
                fullWidth
                defaultValue={patient?.firstname}
                {...register("firstname", { required: true })}
                error={errors.firstName}
                helperText={errors.firstName && "Last Name is required"}
              />
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto 100px",
                gap: "25px",
              }}
            >
              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>
                      Middle Name<span style={styles.astirisColor}>*</span>
                    </label>
                  }
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.middle}
                  {...register("middle", { required: true })}
                  error={errors.middleName}
                  helperText={errors.middleName && "Middle Name is required"}
                />
              </div>
              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>
                      Suffix<span style={styles.astirisColor}>*</span>
                    </label>
                  }
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.suffix}
                  {...register("suffix", { required: true })}
                  error={errors.suffix}
                  helperText={errors.suffix && "Suffix is required"}
                />
              </div>
            </div>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>
                      Sex<span style={styles.astirisColor}>*</span>
                    </label>
                  }
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.gender}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("gender", { required: true })}
                  error={errors.gender}
                  helperText={errors.gender && "Sex is required"}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </TextField>
              </div>
              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>
                      Birth Date<span style={styles.astirisColor}>*</span>
                    </label>
                  }
                  variant="outlined"
                  type="date"
                  fullWidth
                  defaultValue={patient?.birthday}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("birthday", { required: true })}
                  error={errors.birthday}
                  helperText={errors.birthday && "Birth Date is required"}
                />
              </div>
            </div>
            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Birth Place</label>}
                variant="outlined"
                fullWidth
                defaultValue={patient?.birthplace}
                {...register("birthPlace")}
              />
            </div>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <TextField
                  label={<label style={styles.label}>Civil Status</label>}
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.civil_status}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("civil_status")}
                >
                  <option value="">Select</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widowed">Widowed</option>
                </TextField>
              </div>
              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>Educational Attainment</label>
                  }
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.education_attainment}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("education_attainment")}
                >
                  <option value="">Select</option>
                  <option value="Elementary">Elementary</option>
                  <option value="HighSchool">High School</option>
                  <option value="College">College</option>
                  <option value="Masteral">Masteral</option>
                  <option value="Doctorate">Doctorate</option>
                </TextField>
              </div>
            </div>
          </div>

          <div className={styles.borderLeft}>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <TextField
                  label={<label style={styles.label}>Employment Status</label>}
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.employment_status}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("employment_status")}
                >
                  <option value="">Select</option>
                  <option value="Employed">Employed</option>
                  <option value="Unemployed">Unemployed</option>
                </TextField>
              </div>
              <div style={styles.divContainer}>
                <TextField
                  label={<label style={styles.label}>Religion</label>}
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.religion}
                  {...register("religion")}
                />
              </div>
            </div>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <TextField
                  label={<label style={styles.label}>Indigenous</label>}
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.indigenous}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("indigenous")}
                >
                  <option value="">Select</option>
                  <option value="true">Yes</option>
                  <option value="false">NO</option>
                </TextField>
              </div>
              <div style={styles.divContainer}>
                <TextField
                  label={<label style={styles.label}>Blood Type</label>}
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.blood_type}
                  {...register("bloodType")}
                />
              </div>
            </div>
            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Mother First Name:</label>}
                variant="outlined"
                fullWidth
                defaultValue={patient?.mother_firstname}
                {...register("mother_firstname")}
              />
            </div>
            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Mother Last Name:</label>}
                variant="outlined"
                fullWidth
                defaultValue={patient?.mother_lastname}
                {...register("mother_lastname")}
              />
            </div>
            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Mother Middle Name:</label>}
                variant="outlined"
                fullWidth
                defaultValue={patient?.mother_middlename}
                {...register("mother_middlename")}
              />
            </div>

            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Mother Birth Date:</label>}
                variant="outlined"
                type="date"
                fullWidth
                defaultValue={patient?.mother_birthdate}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("mother_birthdate")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
