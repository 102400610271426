import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import { UsersData } from "src/ContextAPI";
import ActionBtn from "src/components/ActionBtn";
import CollapseContainer from "src/components/CollapseContainer";
import CollapseDiv from "src/pho/components/CollapseDiv";
import SelectInputField from "src/components/forms/SelectInputField";
import { v4 as uuidv4 } from "uuid";
import { locations, geolocations } from "src/helpers/locations";
import PickMapLocation from "src/pho/components/Forms/PickMapLocation";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";
import {
	dataURItoBlob,
	formatDate,
	formatDateYYYYMMDD,
} from "src/helpers/utils";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
const relationshipOptions = [
	{ label: "Head", value: "Head" },
	{ label: "Spouse", value: "Spouse" },
	{ label: "Son/Daughter", value: "Son/Daughter" },
	{ label: "Brother-in-law", value: "Brother-in-law" },
	{ label: "Sister-in-law", value: "Sister-in-law" },
	{
		label: "Son-in-law/Daughter-in-law",
		value: "Son-in-law/Daughter-in-law",
	},
	{ label: "Grandchildren", value: "Grandchildren" },
	{ label: "Parents", value: "Parents" },
	{ label: "Relatives", value: "Relatives" },
	{ label: "Housemaid/boy", value: "Housemaid/boy" },
];
const civilStatusOptions = [
	{
		label: "Single",
		value: "single",
	},
	{
		label: "Married",
		value: "married",
	},
	{
		label: "Divorced",
		value: "divorced",
	},
	{
		label: "Widowed",
		value: "widowed",
	},
];

const indirectOptions = [
	{
		label: "Listahanan",
		value: "Listahanan",
	},
	{
		label: "4P'S/MCCT",
		value: "4P'S/MCCT",
	},
	{
		label: "Senior Citizen",
		value: "Senior Citizen",
	},
	{
		label: "PAMANA",
		value: "PAMANA",
	},
	{
		label: "KIA/KIPO",
		value: "KIA/KIPO",
	},
	{
		label: "Bangsamoro Normalization",
		value: "Bangsamoro Normalization",
	},
	{
		label: "LGU-sponsored",
		value: "LGU-sponsored",
	},
	{
		label: "NGA-sponsored",
		value: "NGA-sponsored",
	},
	{
		label: "Private-sponsored",
		value: "Private-sponsored",
	},
	{
		label: "PWD",
		value: "PWD",
	},
];
const directOptions = [
	{
		label: "Employed Private",
		value: "Employed Private",
	},
	{
		label: "Emploved Gov't",
		value: "Emploved Gov't",
	},
	{
		label: "Professional Practitioner",
		value: "Professional Practitioner",
	},
	{
		label: "Self-Earning Individual",
		value: "Self-Earning Individual",
	},
	{
		label: "Kasambahay",
		value: "Kasambahay",
	},
	{
		label: "Family Driver",
		value: "Family Driver",
	},
	{
		label: "Migrant worker",
		value: "Migrant worker",
	},
	{
		label: "Lifetime Member",
		value: "Lifetime Member",
	},
];
const CreateNewPatientFormModal = (props, ref) => {
	const {
		patient,
		noRedirect = false,
		refresh,
		setPatientSelfie,
		patientSelfie,
		onSuccess,
	} = props;
	const { users } = useContext(UsersData);
	const history = useHistory();
	const { register: register1, watch: watch1 } = useForm();
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		trigger,
		control,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [displayDelayed, setDisplayDelayed] = useState(false);
	const [loading, setLoading] = useState(false);
	const [provinceList, setProvinceList] = useState([]);
	const [municipalityList, setMunicipalityList] = useState([]);
	const [brgys, setBrgys] = useState([]);
	const [puroks, setPuroks] = useState([]);

	const [position, setPosition] = useState({
		lat: 6.0498006,
		lng: 125.15,
	});

	const [dependents, setDependents] = useState([
		{
			id: uuidv4(),
			lastname: "",
			firstname: "",
			middlename: "",
			suffix: "",
			relationship: "",
		},
	]);
	const updateItem = (id, field, value) => {
		setDependents((prevData) => {
			return prevData.map((data) => {
				let dep = data;
				if (data?.id == id) {
					dep[field] = value;
					return dep;
				}
				return data;
			});
		});
	};
	const removeItem = (id) => {
		setDependents((prevData) => prevData.filter((x) => x.id !== id));
	};

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setOpen(true);
		setTimeout(() => {
			setDisplayDelayed(true);
		}, 200);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setDisplayDelayed(false);
		setOpen(false);
	};

	const submitForm = (data) => {
		setLoading(true);
		console.log("submitform", dependents, data);
		let formdata = new FormData();
		const file = dataURItoBlob(patientSelfie);
		formdata.append("avatar", file);
		formdata.append("prefix", data?.prefix || " ");
		formdata.append("suffix", data?.suffix || " ");
		formdata.append("firstname", data?.firstname || " ");
		formdata.append("lastname", data?.lastname || " ");
		formdata.append("middlename", data?.middlename || " ");
		formdata.append("gender", data?.gender || " ");
		formdata.append(
			"birthdate",
			// data?.birthday
			formatDateYYYYMMDD(new Date(data?.birthdate))
		);
		formdata.append("birthplace", data?.birthplace || " ");
		formdata.append("barangay", data?.barangay || " ");
		formdata.append("city", data?.municipality || " ");
		formdata.append("civil_status", data?.civil_status || " ");
		formdata.append("philhealth", data?.philhealth || " ");
		formdata.append("religion", data?.religion || " ");
		formdata.append("mother_firstname", data?.mother_firstname || " ");
		formdata.append("mother_lastname", data?.mother_lastname || " ");
		formdata.append("mother_middlename", data?.mother_middlename || " ");
		formdata.append("country", "PH");
		// formdata.append("region", "SOCSKSARGEN Region");
		formdata.append("province", data?.province || " ");
		formdata.append("municipality", data?.municipality || " ");
		formdata.append("zip_code", data?.zip_code || "");
		formdata.append("street", data?.street || " ");
		formdata.append("floor", data?.floor || " ");
		formdata.append("subdivision", data?.subdivision || " ");
		formdata.append("house_number", data?.house_number || " ");
		formdata.append("purok", data?.purok || " ");
		formdata.append("mobile", data?.mobile || " ");
		formdata.append("lat", data?.lat || " ");
		formdata.append("lng", data?.lng || " ");
		formdata.append("tin", data?.tin || " ");
		formdata.append("region", data?.region || " ");
		formdata.append("unit", data?.unit || " ");
		formdata.append("profession", data?.profession || " ");
		formdata.append("salary", data?.salary || " ");
		formdata.append("direct_contributor", data?.direct_contributor || " ");
		formdata.append(
			"indirect_contributor",
			data?.indirect_contributor || " "
		);

		dependents.map((dependent, index) => {
			// if (dependent?.firstname?.length > 0) {
			formdata.append(
				`patientDependents[${index}][firstname]`,
				dependent?.firstname || " "
			);
			formdata.append(
				`patientDependents[${index}][lastname]`,
				dependent?.lastname || " "
			);

			formdata.append(
				`patientDependents[${index}][middlename]`,
				dependent?.middlename || " "
			);
			formdata.append(
				`patientDependents[${index}][suffix]`,
				dependent?.suffix || " "
			);
			formdata.append(
				`patientDependents[${index}][relationship]`,
				dependent?.relationship || " "
			);
			// formdata.append(`patientDependents[]`, JSON.stringify(dependent));
			// formdata.append(`patientDependents[${index}]`, dependent);
			// }
		});
		axios
			.post(`v1/pho/patients`, formdata, {})
			.then((res) => {
				// return resolve(res.data);
				setTimeout(() => {
					toast.success("Patient added successfully!");
				}, 300);
				if (!noRedirect) {
					history.push(
						`/sph/app/bhs/patients/${res?.data?.data?.id}?new_appointment=true`
					);
				}
				if (onSuccess) {
					onSuccess();
				}
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				// return reject(err);
			});
	};

	return (
		<Modal
			open={open}
			staticModal={true}
			size="md"
			hide={hide}
			className=" max-w-[768px] w-full"
		>
			<ModalHeader
				title={`Create new patient form`}
				subtitle="Complete form to create new patient"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
					<div className="col-span-12 flex items-center justify-center">
						<div className="bg-slate-200 rounded relative w-1/3 aspect-square overflow-hidden">
							<img
								src={patientSelfie}
								alt=""
								className="absolute top-0 left-0 w-full h-full"
							/>
						</div>
					</div>
					<div className="flex flex-col lg:col-span-12 gap-6">
						<CollapseDiv
							defaultOpen={true}
							title="I. PERSONAL DETAILS"
						>
							<div className="w-full grid grid-cols-1 lg:grid-cols-12 gap-4">
								<TextInputField
									label={
										<>
											Members Pin
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									placeholder="Members Pin"
									className="lg:col-span-6"
									error={errors?.philhealth?.message}
									{...register("philhealth", {
										required: "This field is required",
									})}
								/>
								<TextInputField
									label={
										<>
											TIN
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									placeholder="TIN"
									className="lg:col-span-6"
									error={errors?.tin?.message}
									{...register("tin", {
										required: "This field is required",
									})}
								/>
								<TextInputField
									label={
										<>
											Lastname
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									placeholder="Lastname"
									className="lg:col-span-4"
									error={errors?.lastname?.message}
									{...register("lastname", {
										required: "This field is required",
									})}
								/>
								<TextInputField
									label={
										<>
											Firstname
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									placeholder="Firstname"
									className="lg:col-span-4"
									error={errors?.firstname?.message}
									{...register("firstname", {
										required: "This field is required",
									})}
								/>
								<TextInputField
									label="Middle Initial"
									placeholder="M.I."
									className="lg:col-span-2"
									{...register("middlename", {
										required: false,
									})}
								/>
								<TextInputField
									label="Suffix"
									placeholder="Suffix"
									className="lg:col-span-2"
									{...register("suffix", {
										required: false,
									})}
								/>

								<TextInputField
									label={
										<>
											Place of birth
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									placeholder="Place of birth"
									className="lg:col-span-6"
									error={errors?.birthplace?.message}
									{...register("birthplace", {
										required: "This field is required",
									})}
								/>
								<TextInputField
									label={
										<>
											Birthdate
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									placeholder="Birthdate"
									className="lg:col-span-6"
									type="date"
									error={errors?.birthdate?.message}
									{...register("birthdate", {
										required: "This field is required",
									})}
								/>
								<div className="lg:col-span-4">
									<FormControl>
										<FormLabel
											id="demo-controlled-radio-buttons-group"
											className="flex"
											error={errors?.gender}
										>
											<b className="text-black font-semibold">
												Gender
											</b>
											<span className="text-danger ml-2">
												*
											</span>
										</FormLabel>
										{errors?.gender && (
											<FormHelperText
												error={errors?.gender}
											>
												This field is required.
											</FormHelperText>
										)}
										<Controller
											rules={{ required: true }}
											name="gender"
											control={control}
											render={({ field }) => (
												<RadioGroup
													{...field}
													key={`gender-${field?.value}`}
													error={errors?.gender}
													row
												>
													<FormControlLabel
														value={"Male"}
														control={<Radio />}
														label={"Male"}
													/>
													<FormControlLabel
														value={"Female"}
														control={<Radio />}
														label={"Female"}
													/>
												</RadioGroup>
											)}
										/>
									</FormControl>
								</div>
								<div className="lg:col-span-8">
									<FormControl>
										<FormLabel
											id="demo-controlled-radio-buttons-group"
											className="flex"
											error={errors?.civil_status}
										>
											<b className="text-black font-semibold">
												Civil Status
											</b>
											<span className="text-danger ml-2">
												*
											</span>
										</FormLabel>
										{errors?.civil_status && (
											<FormHelperText
												error={errors?.civil_status}
											>
												This field is required.
											</FormHelperText>
										)}
										<Controller
											rules={{ required: true }}
											name="civil_status"
											control={control}
											render={({ field }) => (
												<RadioGroup
													{...field}
													key={`civil_status-${field?.value}`}
													error={errors?.civil_status}
													row
												>
													{civilStatusOptions?.map(
														(stat) => {
															return (
																<FormControlLabel
																	key={`cso-${stat?.value}`}
																	value={
																		stat?.value
																	}
																	control={
																		<Radio />
																	}
																	label={
																		stat?.label
																	}
																/>
															);
														}
													)}
												</RadioGroup>
											)}
										/>
									</FormControl>
								</div>
								<div className="grid grid-cols-1 border p-2 rounded-md bg-slate-50 lg:grid-cols-12 gap-2 col-span-12">
									<div className="col-span-12 text-md font-bold -mb-2 text-black">
										MOTHER'S MAIDEN NAME
									</div>
									<TextInputField
										inputClassName="bg-white"
										label={
											<>
												Lastname
												<span className="text-danger ml-1">
													*
												</span>
											</>
										}
										placeholder="Lastname"
										className="lg:col-span-4"
										error={errors?.mother_lastname?.message}
										{...register("mother_lastname", {
											required: "This field is required",
										})}
									/>
									<TextInputField
										inputClassName="bg-white"
										label={
											<>
												Firstname
												<span className="text-danger ml-1">
													*
												</span>
											</>
										}
										placeholder="Firstname"
										className="lg:col-span-4"
										error={
											errors?.mother_firstname?.message
										}
										{...register("mother_firstname", {
											required: "This field is required",
										})}
									/>
									<TextInputField
										inputClassName="bg-white"
										label="Middlename"
										placeholder="Middlename"
										className="lg:col-span-4"
										{...register("mother_middlename", {
											required: false,
										})}
									/>
								</div>
							</div>
						</CollapseDiv>
						<CollapseDiv
							defaultOpen={true}
							title="II. ADDRESS and CONTACT DETAIlS"
						>
							<div className="w-full grid grid-cols-1 lg:grid-cols-12 gap-4">
								<div className="lg:col-span-12">
									{displayDelayed ? (
										<PickMapLocation
											position={position}
											setPosition={(pos) => {
												setPosition({
													lat: pos.lat,
													lng: pos.lng,
												});
												setValue("lat", pos?.lat);
												setValue("lng", pos?.lng);
											}}
										/>
									) : (
										""
									)}
								</div>
								<TextInputField
									label={
										<>
											Latitude
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									placeholder="Latitude"
									className="lg:col-span-6"
									error={errors?.latitude?.message}
									{...register("lat", {
										required: "This field is required",
									})}
								/>
								<TextInputField
									label={
										<>
											Longitude
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									placeholder="Longitude"
									className="lg:col-span-6"
									error={errors?.longitude?.message}
									{...register("lng", {
										required: "This field is required",
									})}
								/>

								<div className="lg:col-span-4">
									<Controller
										name="region"
										control={control}
										rules={{
											required: {
												value: true,
												message:
													"This field is required",
											},
										}}
										render={({
											field: {
												onChange,
												onBlur,
												value,
												name,
												ref,
											},
											fieldState: {
												invalid,
												isTouched,
												isDirty,
												error,
											},
										}) => (
											<ReactSelectInputField
												isClearable={false}
												label={
													<>
														Select Region
														<span className="text-danger ml-1">
															*
														</span>
													</>
												}
												inputClassName=" "
												ref={ref}
												value={value}
												onChange={onChange}
												onChangeGetData={(data) => {
													setProvinceList(
														Object.keys(
															data.province_list
														).map((key) => {
															return {
																name: key,
																...data
																	.province_list[
																	key
																],
															};
														})
													);
												}} // send value to hook form
												onBlur={onBlur} // notify when input is touched
												error={error?.message}
												placeholder="Select Province"
												options={Object.values(
													geolocations
												).map((loc) => ({
													value: loc?.region_name,
													label: loc?.region_name,
													province_list:
														loc?.province_list,
												}))}
											/>
										)}
									/>
								</div>
								<div className="lg:col-span-4">
									<Controller
										name="province"
										control={control}
										rules={{
											required: {
												value: true,
												message:
													"This field is required",
											},
										}}
										render={({
											field: {
												onChange,
												onBlur,
												value,
												name,
												ref,
											},
											fieldState: {
												invalid,
												isTouched,
												isDirty,
												error,
											},
										}) => (
											<ReactSelectInputField
												isClearable={false}
												label={
													<>
														Select Province
														<span className="text-danger ml-1">
															*
														</span>
													</>
												}
												inputClassName=" "
												ref={ref}
												value={value}
												onChange={onChange} // send value to hook form
												onChangeGetData={(data) => {
													setMunicipalityList(
														Object.keys(
															data.municipality_list
														).map((key) => {
															return {
																name: key,
																...data
																	.municipality_list[
																	key
																],
															};
														})
													);
												}}
												onBlur={onBlur} // notify when input is touched
												error={error?.message}
												placeholder="Select Province"
												options={provinceList.map(
													(province) => ({
														label: province?.name,
														value: province?.name,
														municipality_list:
															province?.municipality_list,
													})
												)}
											/>
										)}
									/>
								</div>
								<div className="lg:col-span-4">
									<Controller
										name="municipality"
										control={control}
										rules={{
											required: {
												value: true,
												message:
													"This field is required",
											},
										}}
										render={({
											field: {
												onChange,
												onBlur,
												value,
												name,
												ref,
											},
											fieldState: {
												invalid,
												isTouched,
												isDirty,
												error,
											},
										}) => (
											<ReactSelectInputField
												isClearable={false}
												label={
													<>
														Select Municipality
														<span className="text-danger ml-1">
															*
														</span>
													</>
												}
												placeholder="Select Municipality"
												inputClassName="normal-case"
												ref={ref}
												value={value}
												onChange={(data) => {
													let selected_ =
														locations?.find(
															(x) =>
																String(
																	x.name
																).toLowerCase() ==
																String(
																	data
																).toLowerCase()
														);
													console.log(
														"selected_selected_",
														String(
															data
														).toLowerCase(),
														selected_
													);
													// setBrgys(
													// 	selected_?.barangays
													// );
													setValue(
														"zip_code",
														selected_?.zipcode || ""
													);
													onChange(data);
												}} // send value to hook form
												onChangeGetData={(data) => {
													setBrgys(
														data.barangay_list.map(
															(key) => {
																return {
																	name: key,
																};
															}
														)
													);
												}}
												onBlur={onBlur} // notify when input is touched
												error={error?.message}
												options={municipalityList.map(
													(municipality) => ({
														label: municipality?.name,
														value: municipality?.name,
														barangay_list:
															municipality?.barangay_list,
													})
												)}
											/>
										)}
									/>
								</div>
								<div className="lg:col-span-4">
									<Controller
										name="barangay"
										control={control}
										rules={{
											required: {
												value: true,
												message:
													"This field is required",
											},
										}}
										onChange={(data) => {}}
										render={({
											field: {
												onChange,
												onBlur,
												value,
												name,
												ref,
											},
											fieldState: {
												invalid,
												isTouched,
												isDirty,
												error,
											},
										}) => (
											<ReactSelectInputField
												isClearable={false}
												label={
													<>
														Select Barangay
														<span className="text-danger ml-1">
															*
														</span>
													</>
												}
												inputClassName=" "
												ref={ref}
												value={value}
												onChange={(data) => {
													let selected_ = brgys?.find(
														(x) => x.name == data
													);
													setPuroks(
														selected_?.puroks
													);
													onChange(data);
												}} // send value to hook form
												onBlur={onBlur} // notify when input is touched
												error={error?.message}
												placeholder="Select Barangay"
												options={brgys.map((data) => ({
													label: data?.name,
													value: data?.name,
												}))}
											/>
										)}
									/>
								</div>
								{/* <div className="lg:col-span-4">
									<Controller
										name="purok"
										control={control}
										rules={{
											required: {
												value: true,
												message:
													"This field is required",
											},
										}}
										onChange={(data) => {}}
										render={({
											field: {
												onChange,
												onBlur,
												value,
												name,
												ref,
											},
											fieldState: {
												invalid,
												isTouched,
												isDirty,
												error,
											},
										}) => (
											<ReactSelectInputField
												isClearable={false}
												label={
													<>
														Select Purok
														<span className="text-danger ml-1">
															*
														</span>
													</>
												}
												inputClassName=" "
												ref={ref}
												value={value}
												onChange={(data) => {
													onChange(data);
												}} // send value to hook form
												onBlur={onBlur} // notify when input is touched
												error={error?.message}
												placeholder="Select Purok"
												options={puroks.map((data) => ({
													label: data?.name,
													value: data?.name,
												}))}
											/>
										)}
									/>
								</div> */}
								<TextInputField
									label={<>Purok</>}
									placeholder="Enter Purok"
									className="lg:col-span-4"
									error={errors?.zip_code?.message}
									{...register("purok", {
										required: "This field is required",
									})}
								/>
								<TextInputField
									label={<>ZIPCODE</>}
									placeholder="ZIPCODE"
									className="lg:col-span-4"
									error={errors?.zip_code?.message}
									{...register("zip_code", {
										required: "This field is required",
									})}
								/>
								<TextInputField
									label={<>UNIT/Room No./Floor</>}
									placeholder="UNIT/Room No./Floor"
									className="lg:col-span-6"
									error={errors?.unit?.message}
									{...register("unit", {
										required: "This field is required",
									})}
								/>
								<TextInputField
									label={<>Lot/Blk/phase/House No.</>}
									placeholder="Lot/Blk/phase/House No."
									className="lg:col-span-6"
									error={errors?.house_number?.message}
									{...register("house_number", {
										required: "This field is required",
									})}
								/>

								<TextInputField
									label={<>Street</>}
									placeholder="Street"
									className="lg:col-span-6"
									error={errors?.street?.message}
									{...register("street", {
										required: "This field is required",
									})}
								/>

								<TextInputField
									label={<>Subdivision</>}
									placeholder="Subdivision"
									className="lg:col-span-6"
									error={errors?.subdivision?.message}
									{...register("subdivision", {
										required: "This field is required",
									})}
								/>

								<TextInputField
									label={<>State in / Country (if abroad)</>}
									placeholder="State in / Country"
									className="lg:col-span-6"
									defaultValue={"Philippines"}
									error={errors?.country?.message}
									{...register("country", {
										required: "This field is required",
									})}
								/>
							</div>
						</CollapseDiv>
						<CollapseDiv
							defaultOpen={true}
							title="III. DECLARATION OF DEPENDENTS"
						>
							<div className="w-full grid grid-cols-1 lg:grid-cols-12 gap-4">
								{dependents?.map((data) => {
									return (
										<div className="col-span-12 border rounded-md p-3 gap-4 grid grid-cols-1 lg:grid-cols-12 relative">
											<div
												className="bg-red-500 text-white py-1 px-2 rounded absolute top-0 right-0 text-xs cursor-pointer"
												onClick={() => {
													removeItem(data?.id);
												}}
											>
												Remove
											</div>
											<TextInputField
												label={
													<>
														Lastname
														<span className="text-danger ml-1">
															*
														</span>
													</>
												}
												placeholder="Lastname"
												className="lg:col-span-4"
												onChange={(e) => {
													updateItem(
														data?.id,
														"lastname",
														e.target.value
													);
												}}
											/>
											<TextInputField
												label={
													<>
														Firstname
														<span className="text-danger ml-1">
															*
														</span>
													</>
												}
												placeholder="Firstname"
												className="lg:col-span-4"
												onChange={(e) => {
													updateItem(
														data?.id,
														"firstname",
														e.target.value
													);
												}}
											/>
											<TextInputField
												label="Middle Initial"
												placeholder="M.I."
												className="lg:col-span-2"
												onChange={(e) => {
													updateItem(
														data?.id,
														"middlename",
														e.target.value
													);
												}}
											/>
											<TextInputField
												label="Suffix"
												placeholder="Suffix"
												className="lg:col-span-2"
												onChange={(e) => {
													updateItem(
														data?.id,
														"suffix",
														e.target.value
													);
												}}
											/>
											<div className="lg:col-span-4">
												<ReactSelectInputField
													isClearable={false}
													label={<>Relationship</>}
													inputClassName=" "
													onChange={(valData) => {
														console.log(
															"onChangeonChange",
															valData
														);
														updateItem(
															data?.id,
															"relationship",
															valData
														);
													}} // send value to hook form
													value={data?.relationship}
													placeholder="Select..."
													options={relationshipOptions.map(
														(data) => ({
															label: data?.label,
															value: data?.value,
														})
													)}
												/>
											</div>
										</div>
									);
								})}
								<div className="lg:col-span-12 flex items-center justify-center">
									<ActionBtn
										type="accent"
										onClick={() => {
											setDependents((prevDependents) => [
												...prevDependents,
												{
													id: uuidv4(),
													lastname: "",
													firstname: "",
													middlename: "",
													suffix: "",
													relationship: "",
												},
											]);
										}}
									>
										Add dependents
									</ActionBtn>
								</div>
							</div>
						</CollapseDiv>

						<CollapseDiv defaultOpen={true} title="IV. MEMBER TYPE">
							<div className="w-full grid grid-cols-1 lg:grid-cols-12 gap-4">
								<div className="lg:col-span-6">
									<Controller
										name="direct_contributor"
										control={control}
										rules={{
											required: {
												value: true,
												message:
													"This field is required",
											},
										}}
										onChange={(data) => {}}
										render={({
											field: {
												onChange,
												onBlur,
												value,
												name,
												ref,
											},
											fieldState: {
												invalid,
												isTouched,
												isDirty,
												error,
											},
										}) => (
											<ReactSelectInputField
												isClearable={false}
												label={
													<>
														Direct Contributor
														<span className="text-danger ml-1">
															*
														</span>
													</>
												}
												inputClassName=" "
												ref={ref}
												value={value}
												onChange={(data) => {
													let selected_ = brgys?.find(
														(x) => x.name == data
													);
													setPuroks(
														selected_?.puroks
													);
													onChange(data);
												}} // send value to hook form
												onBlur={onBlur} // notify when input is touched
												error={error?.message}
												placeholder="Select"
												options={directOptions?.map(
													(data) => ({
														label: data?.label,
														value: data?.value,
													})
												)}
											/>
										)}
									/>
								</div>
								<div className="lg:col-span-6">
									<Controller
										name="indirect_contributor"
										control={control}
										rules={{
											required: {
												value: true,
												message:
													"This field is required",
											},
										}}
										onChange={(data) => {}}
										render={({
											field: {
												onChange,
												onBlur,
												value,
												name,
												ref,
											},
											fieldState: {
												invalid,
												isTouched,
												isDirty,
												error,
											},
										}) => (
											<ReactSelectInputField
												isClearable={false}
												label={
													<>
														Indirect Contributor
														<span className="text-danger ml-1">
															*
														</span>
													</>
												}
												inputClassName=" "
												ref={ref}
												value={value}
												onChange={(data) => {
													let selected_ = brgys?.find(
														(x) => x.name == data
													);
													setPuroks(
														selected_?.puroks
													);
													onChange(data);
												}} // send value to hook form
												onBlur={onBlur} // notify when input is touched
												error={error?.message}
												placeholder="Select"
												options={indirectOptions?.map(
													(data) => ({
														label: data?.label,
														value: data?.value,
													})
												)}
											/>
										)}
									/>
								</div>
								<TextInputField
									label={
										<>
											Profession
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									placeholder="Profession"
									className="lg:col-span-6"
									error={errors?.profession?.message}
									{...register("profession", {
										required: "This field is required",
									})}
								/>

								<TextInputField
									label={
										<>
											Monthly income
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									placeholder="Monthly income"
									className="lg:col-span-6"
									error={errors?.salary?.message}
									{...register("salary", {
										required: "This field is required",
									})}
								/>
							</div>
						</CollapseDiv>
					</div>
				</div>
				<ActionBtn className="mt-4" onClick={handleSubmit(submitForm)}>
					<FlatIcon icon="rr-disk" />
					<b>Submit</b>
				</ActionBtn>
			</ModalBody>
			{/* <ModalFooter className={"flex items-center justify-between"}>
				Test
			</ModalFooter> */}
		</Modal>
	);
};

export default forwardRef(CreateNewPatientFormModal);
