import React, { Fragment, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "src/layout/Container";
import GTCNewsFull from "src/GTCNewsFull";
import { UsersHeader } from "src/ContextAPI";
import FlatIcon from "src/components/FlatIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Img from "src/components/Img";
import { getWeekDay, patientFullName, dateToday } from "src/helpers/utils";

const AdmissionNews = () => {
	const { sidebarHeader } = React.useContext(UsersHeader);
	const [stats, setStats] = useState(null);
	const [patients, setPatients] = useState([]);

	useEffect(() => {
		getClinicDashboard();
	}, []);

	const getClinicDashboard = () => {
		axios.get("/v1/clinic/dashboard").then((res) => {
			console.log("getClinicDashboard", res.data);
			setStats(res.data.stats);
			setPatients(res.data.patients);
		});
	};
	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: false,
					current: "SAMOPLPERLS PAERL PSE",
					items: [],
				}}
				title={
					<>
						<Typography
							variant={window.innerWidth > 500 ? "h4" : "h5"}
						>
							{" "}
							Dashboard{" "}
						</Typography>
						<Typography variant="subtitle2">
							{" "}
							Welcome back, {sidebarHeader.name}! 👋{" "}
						</Typography>
					</>
				}
			>
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mb-4">
					<div className="col-span-4">
						<div className="rounded-lg !border-l-4 p-3 gap-3 shadow-md border-warning border-t border-b border-r text-warning bg-orange-50 bg-opacity-10 flex flex-col">
							<h4 className="text-lg font-semibold">PATIENT</h4>
							<div className="flex items-center justify-between">
								<FlatIcon
									icon="rs-calendar"
									className="!text-[48px] w-[144px]"
								/>
								<div className="flex flex-col items-center justify-center">
									<h1>{stats?.other_service || 0}</h1>
									<span>APPOINTMENT</span>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-4">
						<div className="rounded-lg !border-l-4 p-3 gap-3 shadow-md border-primary border-t border-b border-r text-primary bg-blue-50 bg-opacity-10 flex flex-col">
							<h4 className="text-lg font-semibold">
								CONSULTATION
							</h4>
							<div className="flex items-center justify-between">
								<FlatIcon
									icon="rr-calendar-lines"
									className="!text-[48px] w-[144px]"
								/>
								<div className="flex flex-col items-center justify-center">
									<h1>{stats?.consultation || 0}</h1>
									<span>APPOINTMENT</span>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-4">
						<div className="rounded-lg !border-l-4 p-3 gap-3 shadow-md border-success border-t border-b border-r text-success bg-green-50 bg-opacity-10 flex flex-col">
							<h4 className="text-lg font-semibold">COMPLETED</h4>
							<div className="flex items-center justify-between">
								<FlatIcon
									icon="rs-calendar"
									className="!text-[48px] w-[144px]"
								/>
								<div className="flex flex-col items-center justify-center">
									<h1>{stats?.completed || 0}</h1>
									<span>APPOINTMENT</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col">
					<h5 className="text-lg font-bold">Today's Appointment</h5>
					<div className="flex flex-col border border-warning">
						<div className="p-3 border-b border-warning font-bold">
							{getWeekDay()} - {dateToday()}
						</div>
						<div className="flex flex-col gap-4 p-3 bg-orange-100 bg-opacity-50">
							{patients?.map((patient) => {
								return (
									<div className="flex items-center gap-2 p-2">
										<Img
											type="user"
											name={patientFullName(
												patient?.patient
											)}
											src={patient?.patient?.avatar}
											alt=""
											className="w-[44px] h-[44px] rounded-full bg-primary object-contain"
										/>
										<span className="font-bold text-lg">
											{patientFullName(patient?.patient)}
										</span>
										{patient?.type_service ==
											"Consultation" &&
										patient?.status == "pending" ? (
											<div className="w-2 h-2 bg-primary rounded-full" />
										) : patient?.status == "pending" ? (
											<div className="w-2 h-2 bg-warning rounded-full" />
										) : patient?.status == "completed" ? (
											<div className="w-2 h-2 bg-success rounded-full" />
										) : (
											<div className="w-2 h-2 bg-primary rounded-full" />
										)}
									</div>
								);
							})}

							{/* <div className="flex items-center gap-2 p-2">
								<img
									src="/user.jpg"
									alt=""
									className="w-[44px] h-[44px] rounded-full bg-primary object-contain"
								/>
								<span className="font-bold text-lg">
									Mary Jane Doe
								</span>
								<div className="w-2 h-2 bg-warning rounded-full" />
								<div className="w-2 h-2 bg-primary rounded-full" />
								<div className="w-2 h-2 bg-success rounded-full" />
							</div>

							<div className="flex items-center gap-2 p-2">
								<img
									src="/2.png"
									alt=""
									className="w-[44px] h-[44px] rounded-full bg-primary object-contain"
								/>
								<span className="font-bold text-lg">
									John Doe
								</span>
								<div className="w-2 h-2 bg-warning rounded-full" />
								<div className="w-2 h-2 bg-primary rounded-full" />
								<div className="w-2 h-2 bg-success rounded-full" />
							</div> */}
						</div>
					</div>
				</div>
			</Container>
		</Fragment>
	);
};

export default AdmissionNews;
