const HealthcareProfessionals = ({ doctors }) => {
	return (
		<div className="flex flex-col mb-6 border-l-2 border-l-blue-700 border-opacity-10 ml-2 pl-2">
			{doctors?.map((doctor) => {
				console.log("doctordoctordoctor", doctor);
				return (
					<div className="flex flex-col relative">
						<span className="h-2 w-2 rounded-full bg-blue-400 absolute -left-[13px] top-[6px]" />
						<b className="text-sm">
							{doctor?.relationships?.doctor?.name}
						</b>
						<span class="text-xs">{doctor?.title}</span>
					</div>
				);
			})}
		</div>
	);
};

export default HealthcareProfessionals;
