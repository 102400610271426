import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FormInformation, UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import ReactToPrint from "react-to-print";
import { Print, Send } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const PatientSoa = () => {
  const { users } = useContext(UsersData);
  const { formInfo } = useContext(FormInformation);
  const { patient_id } = useParams();
  const mounted = IsMountedRef();
  const [details, setDetails] = useState({ data: [], ready: false });
  const [info, setInfo] = useState({ data: [], ready: false });
  const [total, setTotal] = useState(0);
  let printRef = useRef();
  const history = useHistory();

  const getPatientsTransaction = React.useCallback(async () => {
    let response = await axios.get("billing/soa/get-patients-transactions", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
        patient_id: patient_id,
      },
    });

    if (mounted.current) {
      setDetails({ data: response.data, ready: true });
      setTotal(calculateTotalChargeAmount(response.data));
    }
  }, [users, patient_id, mounted]);

  const getPatientInfo = React.useCallback(async () => {
    let response = await axios.get("billing/soa/get-management-patients-info", {
      params: {
        patient_id: patient_id,
      },
    });

    if (mounted.current) {
      setInfo({ data: response.data, ready: true });
    }
  }, [patient_id, mounted]);

  const handleUpdateSetAsPaid = () => {
    var formdata = new FormData();
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("patient_id", patient_id);

    axios
      .post("accounting/set-as-paid/soa-by-patient", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("as as paid");
          history.push(`/sph/app/billing/soa`);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getPatientsTransaction();
    getPatientInfo();
  }, [getPatientsTransaction, getPatientInfo]);

  const calculateTotalChargeAmount = (data) => {
    let x = 0;

    for (let i = 0; i < data.length; i++) {
      x += parseFloat(data[i].bill_amount);
    }

    return x;
  };

  return (
    <Container
      breadcrumbs={{
        enable: false,
        current: "eryery",
        items: [],
      }}
      title={""}
    >
      <Paper component={Box} p={2} ref={printRef}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Box align="center" mb={2}>
                <Typography variant="h5">
                  {Boolean(formInfo)
                    ? formInfo.subtitle1 !== null
                      ? formInfo.subtitle1
                      : ""
                    : ""}
                </Typography>
                <Typography variant="subtitle1">
                  {Boolean(formInfo)
                    ? formInfo.subtitle2 !== null
                      ? formInfo.subtitle2
                      : ""
                    : ""}
                </Typography>
                <Typography variant="subtitle1">
                  {Boolean(formInfo)
                    ? formInfo.subtitle3 !== null
                      ? formInfo.subtitle3
                      : ""
                    : ""}
                </Typography>
              </Box>

              <Box display="flex" p={2}>
                <Box flexGrow={1}>
                  <Box mb={1}>
                    <Typography variant="subtitle1">
                      <b> PATIENT INFORMATION </b>
                    </Typography>
                  </Box>
                  {info.ready ? (
                    info.data.length > 0 ? (
                      <Box mb={1}>
                        <Box mb={1}>
                          <Typography variant="subtitle1">
                            Name : {info.data[0].lastname},{" "}
                            {info.data[0].firstname} {info.data[0].middle}
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography variant="subtitle1">
                            Address : {info.data[0].street},{" "}
                            {info.data[0].barangay}, {info.data[0].city}
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography variant="subtitle1">
                            Gender : {info.data[0].gender}
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography variant="subtitle1">
                            Age : {Notify.calculateAge(info.data[0].birthday)}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Typography color="secondary">
                        Patient not found.
                      </Typography>
                    )
                  ) : (
                    <Typography color="primary">
                      loading, please wait...
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box mb={2}>
                <Typography variant="h5" align="center">
                  <b> STATEMENT OF ACCOUNT </b>
                </Typography>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b> Date </b>
                        </TableCell>
                        <TableCell>
                          <b> Transaction </b>
                        </TableCell>
                        <TableCell>
                          <b> Details </b>
                        </TableCell>
                        <TableCell>
                          <b> Amount </b>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {details.ready ? (
                        details.data.length > 0 ? (
                          details.data.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {Notify.dateTimeConvert(data.created_at)}
                              </TableCell>
                              <TableCell> {data.bill_from} </TableCell>
                              {/* <TableCell> {data.trace_number} </TableCell> */}
                              <TableCell> {data.bill_name} </TableCell>
                              <TableCell align="right">
                                {Notify.convertToNumber(data.bill_amount)}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <Typography color="secondary">
                                No record found.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Typography color="primary">
                              loading, please wait...
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell align="right">
                          <b> Total Charge Amount :</b>
                        </TableCell>
                        <TableCell align="right">
                          <b> {Notify.convertToNumber(total)}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box my={2} mt={5}>
                <Box display="flex">
                  <Box flexGrow={1} width={"50%"}>
                    <Typography> Prepared by: </Typography>
                    <Box align="center">
                      {Boolean(formInfo)
                        ? formInfo.prepared_by !== null
                          ? formInfo.prepared_by
                          : ""
                        : ""}
                    </Box>
                    <Box align="center">
                      <Box
                        style={{ borderTop: "1px solid #888" }}
                        maxWidth={350}
                      >
                        Cashier
                      </Box>
                    </Box>
                  </Box>

                  <Box width={"50%"}>
                    <Typography> Verified by: </Typography>
                    <Box align="center">
                      {Boolean(formInfo)
                        ? formInfo.verified_by !== null
                          ? formInfo.verified_by
                          : ""
                        : ""}
                    </Box>
                    <Box align="center">
                      <Box
                        style={{ borderTop: "1px solid #888" }}
                        maxWidth={350}
                      >
                        Accounting in Charge
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box display="flex">
                  <Box flexGrow={1} width={"50%"}>
                    <Typography> Checked by: </Typography>
                    <Box align="center">
                      {Boolean(formInfo)
                        ? formInfo.checked_by !== null
                          ? formInfo.checked_by
                          : ""
                        : ""}
                    </Box>
                    <Box align="center">
                      <Box
                        style={{ borderTop: "1px solid #888" }}
                        maxWidth={350}
                      >
                        Clinic Coordinator
                      </Box>
                    </Box>
                  </Box>

                  <Box width={"50%"}>
                    <Typography> Noted by: </Typography>
                    <Box align="center">
                      {Boolean(formInfo)
                        ? formInfo.noted_by !== null
                          ? formInfo.noted_by
                          : ""
                        : ""}
                    </Box>
                    <Box align="center">
                      <Box
                        style={{ borderTop: "1px solid #888" }}
                        maxWidth={350}
                      >
                        Accounting Head
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box display="flex" mt={2}>
              <Box mr={2}>
                <Button
                  className={"d-print-none"}
                  variant="contained"
                  color="primary"
                  hidden={details.length === 0}
                  startIcon={<Send />}
                  onClick={handleUpdateSetAsPaid}
                >
                  Set as paid
                </Button>
              </Box>
              <ReactToPrint
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return (
                    <Button
                      className={"d-print-none"}
                      variant="contained"
                      color="primary"
                      hidden={details.data.length === 0}
                      startIcon={<Print />}
                    >
                      Print
                    </Button>
                  );
                }}
                content={() => printRef.current}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default PatientSoa;
