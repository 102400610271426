import React, { useState } from "react";
import { faArrowLeft, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card } from "@material-ui/core";
import CisEditPatientsForm from "./CisEditPatientsForm ";
import { makeStyles } from "@material-ui/core/styles";
import PersonalInformation from "./patientProfileForm/PersonalInfomation";
import AddressAndContactInformation from "./patientProfileForm/AddressAndContactInformation";
import OtherInformation from "./patientProfileForm/OtherInformation";
import { styles } from "../styles/styles";
import ButtonV3 from "src/components/ButtonV3";

export default function CisPatientsForm({ patient }) {
  const [shown, setShown] = useState(true);
  console.log(patient);
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full mb-2 items-center ">
        <div style={styles.headerContainer}>
          <b className="text-2xl">Profile</b>
        </div>
        {shown ? (
          <ButtonV3
            onClick={() => {
              setShown(false);
            }}
          >
            <div className="flex">
              <FontAwesomeIcon
                icon={faPencilAlt}
                color="primary"
                style={{ fontSize: 18 }}
                className="pr-2"
              />
              Edit
            </div>
          </ButtonV3>
        ) : (
          <ButtonV3
            onClick={() => {
              setShown(true);
            }}
          >
            <div className="flex">
              <FontAwesomeIcon
                icon={faArrowLeft}
                color="primary"
                style={{ fontSize: 18 }}
                className="pr-2"
              />
              back
            </div>
          </ButtonV3>
        )}
      </div>
      {shown ? (
        <div className="flex w-full flex-col">
          <div className="m-2" style={styles.parentContainer}>
            <Card elevation={3} className="p-4 mb-10 " component={Box}>
              <PersonalInformation patient={patient} />
              <AddressAndContactInformation patient={patient} />
              <OtherInformation patient={patient} />
            </Card>
          </div>
        </div>
      ) : (
        <CisEditPatientsForm patient={patient} />
      )}
    </div>
  );
}
