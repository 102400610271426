import {
  Box,
  CardMedia,
  Grid,
  Typography,
  makeStyles,
  TextField,
  IconButton,
  Button,
  CircularProgress,
  Badge,
} from "@material-ui/core";
import React, { useState, useRef, useContext, useCallback } from "react";
import axios from "axios";
import Notify from "src/notification/Notify";
import { grey } from "@material-ui/core/colors";
import { PatientsUnRead, UsersData } from "src/ContextAPI";
import Edit from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/HighlightOff";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import * as Yup from "yup";
import { Formik } from "formik";
import { isMobile } from "react-device-detect";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  profile: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    maxWidth: theme.spacing(23),
    maxHeight: theme.spacing(23),
    borderRadius: "50%",
    border: `1px solid ${grey[200]}`,
  },
}));

const BasicInfo = () => {
  const doctorHeadNotif = useContext(PatientsUnRead);
  const { users } = useContext(UsersData);
  const classes = useStyles();
  const [details, setDetails] = useState({ data: [], ready: false });
  const [edit, setEdit] = useState(false);
  var profileimage = useRef();
  const [_uploadprocess, setUploadprocess] = useState(false);

  const handleClickupload = () => {
    profileimage.click();
  };

  const getBasinInfo = useCallback(async () => {
    try {
      const params = {
        user_id: users.user_id,
      };
      const response = await axios.get("doctor/sidebar/header-infomartion", {
        params,
      });
      setDetails({ data: response.data, ready: true });
    } catch (error) {
      console.log("unable to get sdiebar header info.", error);
    }
  }, [users]);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleUploadImage = (event) => {
    var ext = event.target.files[0].type.split("/")[1];
    var maxfile = event.target.files[0].size / 1024 / 1024;
    var extArray = ["png", "jpeg", "jpg"];
    var resetUpload = event.target;
    if (extArray.indexOf(ext) === -1) {
      Notify.customToast("Image Not Allowed", "Upload PNG and JPEG only.");
      resetUpload.value = "";
    } else if (maxfile.toFixed(2) > 2) {
      Notify.customToast(
        "Image Not Allowed",
        "Image size too big, Upload 2mb or less."
      );
      resetUpload.value = "";
    } else {
      var formdata = new FormData();
      formdata.set("user_id", users.user_id);
      formdata.set("profile", event.target.files[0]);
      formdata.set(
        "current_profile",
        details.data.length > 0
          ? details.data.image !== null
            ? details.data.image
            : ""
          : ""
      );
      setUploadprocess(true);
      axios
        .post("doctor/information/personal-uploadnewprofile", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("profile");
            getBasinInfo();
            doctorHeadNotif.renderDoctorInfo();
            resetUpload.value = "";
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setUploadprocess(false);
        });
    }
  };

  React.useEffect(() => {
    getBasinInfo();
  }, [getBasinInfo]);

  return (
    <>
      <Box mb={3}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6" color="textSecondary">
              Personal Information
            </Typography>
          </Box>
          <Box hidden={edit}>
            <IconButton color="primary" onClick={() => setEdit(true)}>
              <Edit />
            </IconButton>
          </Box>
        </Box>

        {/* <Typography variant="caption" color="textSecondary"> 
            Only administrator can update/change your information and profile picture.
        </Typography> */}
      </Box>

      {details.ready ? (
        details.data && Object.keys(details.data).length > 0 ? (
          <Formik
            enableReinitialize
            initialValues={{
              user_id: users.user_id,
              username: users.username,
              management_id: users.management_id,

              fullname: details.data.name !== null ? details.data.name : "",
              gender: details.data.gender !== null ? details.data.gender : "",
              birthday:
                details.data.birthday !== null ? details.data.birthday : "",
              contact_no:
                details.data.contact_no !== null ? details.data.contact_no : "",
              specialization:
                details.data.specialization !== null
                  ? details.data.specialization
                  : "",
              license_no:
                details.data.cil_umn !== null ? details.data.cil_umn : "",
              dea_no: details.data.ead_mun !== null ? details.data.ead_mun : "",
              address:
                details.data.address !== null ? details.data.address : "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              fullname: Yup.string().trim().required("Name is required"),
              gender: Yup.string().trim().required("Gender is required"),
              birthday: Yup.date().required("Birth Date is required"),
              contact_no: Yup.string()
                .trim()
                .required("Contact number is required"),
              address: Yup.string().trim().required("Address is required"),
              password: Yup.string().trim().required("Password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setSubmitting, resetForm }
            ) => {
              try {
                const request = await axios.post(
                  "doctor/get/update/personal-info",
                  getFormData(values)
                );
                if (request.data === "pass-invalid") {
                  setErrors({ password: "Password is invalid." });
                  Notify.fieldInvalid("password");
                }
                if (request.data === "success") {
                  Notify.successRequest("update patient");
                  resetForm();
                  setSubmitting(true);
                  setEdit(false);
                  getBasinInfo();
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              resetForm,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5} md={5} lg={4}>
                    <Box display="flex" justifyContent="center" mt={2}>
                      <Box>
                        <Badge
                          overlap="circle"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            _uploadprocess ? (
                              <CircularProgress color="inherit" />
                            ) : (
                              <CameraAltIcon
                                style={{
                                  width: isMobile
                                    ? 30
                                    : window.innerWidth >= 899 &&
                                      window.innerWidth <= 959
                                    ? 60
                                    : window.innerWidth <= 898
                                    ? 50
                                    : 40,
                                  height: isMobile
                                    ? 30
                                    : window.innerWidth >= 899 &&
                                      window.innerWidth <= 959
                                    ? 60
                                    : window.innerWidth <= 898
                                    ? 50
                                    : 40,
                                  cursor: "pointer",
                                }}
                                onClick={handleClickupload}
                              />
                            )
                          }
                        >
                          <CardMedia
                            component="img"
                            className={classes.profile}
                            src={`${imageLocation}doctor/${
                              details.data.image === null
                                ? "no-image.jpg"
                                : details.data.image
                            }`}
                            alt=""
                          />
                        </Badge>

                        <input
                          type="file"
                          onChange={handleUploadImage}
                          className="d-none"
                          ref={(ref) => (profileimage = ref)}
                        />
                      </Box>
                    </Box>
                    <Box align="center" mt={2}>
                      <Typography color="textSecondary" variant="subtitle1">
                        Profile Picture
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={7} md={7} lg={8}>
                    <Box>
                      <Box>
                        <TextField
                          error={Boolean(touched.fullname && errors.fullname)}
                          helperText={touched.fullname && errors.fullname}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.fullname}
                          fullWidth
                          required
                          name="fullname"
                          label="Fullname"
                          variant="outlined"
                          InputProps={{ readOnly: !edit }}
                        />
                      </Box>

                      <Box my={3}>
                        <TextField
                          error={Boolean(touched.gender && errors.gender)}
                          helperText={touched.gender && errors.gender}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gender}
                          fullWidth
                          required
                          name="gender"
                          label="Gender"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{ readOnly: !edit }}
                        >
                          <option value={""}>Select</option>
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </TextField>
                      </Box>

                      <Box my={3}>
                        <TextField
                          error={Boolean(touched.birthday && errors.birthday)}
                          helperText={touched.birthday && errors.birthday}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.birthday}
                          fullWidth
                          required
                          name="birthday"
                          label="Birthday"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{ readOnly: !edit }}
                        />
                      </Box>

                      <Box my={3}>
                        <TextField
                          error={Boolean(
                            touched.contact_no && errors.contact_no
                          )}
                          helperText={touched.contact_no && errors.contact_no}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.contact_no}
                          fullWidth
                          required
                          name="contact_no"
                          label="Contact Number"
                          variant="outlined"
                          InputProps={{ readOnly: !edit }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box p={2}>
                      <Box mb={3}>
                        <TextField
                          error={Boolean(
                            touched.specialization && errors.specialization
                          )}
                          helperText={
                            touched.specialization && errors.specialization
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.specialization}
                          fullWidth
                          required
                          name="specialization"
                          label="Specialization"
                          variant="outlined"
                          InputProps={{ readOnly: !edit }}
                        />
                      </Box>

                      <Box mb={3}>
                        <TextField
                          error={Boolean(
                            touched.license_no && errors.license_no
                          )}
                          helperText={touched.license_no && errors.license_no}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.license_no}
                          fullWidth
                          required
                          name="license_no"
                          label="License Number"
                          variant="outlined"
                          InputProps={{ readOnly: !edit }}
                        />
                      </Box>

                      <Box mb={3}>
                        <TextField
                          error={Boolean(touched.dea_no && errors.dea_no)}
                          helperText={touched.dea_no && errors.dea_no}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.dea_no}
                          fullWidth
                          required
                          name="dea_no"
                          label="PDEA Number"
                          variant="outlined"
                          InputProps={{ readOnly: !edit }}
                        />
                      </Box>

                      <Box mb={3}>
                        <TextField
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address}
                          fullWidth
                          required
                          name="address"
                          label="Address"
                          variant="outlined"
                          InputProps={{ readOnly: !edit }}
                        />
                      </Box>

                      <Box hidden={!edit}>
                        <Box my={1}>
                          <TextField
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            fullWidth
                            required
                            name="password"
                            type="password"
                            label="Password"
                            variant="outlined"
                          />
                        </Box>

                        <Box mb={2} display="flex">
                          <Box flexGrow={1} />
                          <Button
                            variant="contained"
                            color="default"
                            onClick={() => setEdit(false)}
                            startIcon={<ClearIcon />}
                          >
                            Cancel
                          </Button>

                          <Box ml={2}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              startIcon={
                                isSubmitting ? (
                                  <CircularProgress size={20} color="inherit" />
                                ) : (
                                  <CheckIcon />
                                )
                              }
                              disabled={isSubmitting}
                            >
                              Save
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        ) : (
          Notify.noRecord()
        )
      ) : (
        Notify.loading()
      )}
    </>
  );
};

export default BasicInfo;
