import ViewBhsPatient from "./ViewBhsPatient";
import ViewRhuPatient from "./ViewRhuPatient";

const RhuPatientProfile = ({ disableNewAppointment, patient, setPatient }) => {
	return (
		<ViewRhuPatient
			disableNewAppointment={disableNewAppointment}
			patient={patient}
			setPatient={setPatient}
			className="!bg-transparent"
			// add_patient_ref={add_patient_ref}
		/>
	);
};

export default RhuPatientProfile;
