import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";

import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import HealthUnitDetails from "./HealthUnitDetails";

const CreatePrescriptionModal = (props, ref) => {
	const { onSuccess } = props;
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(null);
	const [appointment_id, setAppointmentId] = useState(0);
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState("");
	const [prescriptionItems, setPrescriptionItems] = useState([]);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data, typeData) => {
		console.log("data, typeData", data, typeData);
		setType(typeData);
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const submit = (data) => {
		setLoading(true);
		console.log("prescriptionItems", prescriptionItems);
		let formData = new FormData();
		formData.append("appointment_id", appointment?.id);
		formData.append("_method", "PATCH");
		prescriptionItems.map((data) => {
			formData.append("inventory_id[]", data.id);
			formData.append("quantity[]", data.qty);
			formData.append("items[]", data?.item?.id);
			formData.append("sig[]", data?.sig);
		});
		if (type != "") {
			formData.append("type", type);
		}
		axios
			.post(`/v1/clinic/tb-prescribe/${appointment?.id}`, formData)
			.then((res) => {
				// addToList(data);
				setTimeout(() => {
					setLoading(false);
					onSuccess();
					toast.success("Prescription added successfully!");
				}, 400);
				hide();
			})
			.finally(() => {
				setLoading(false);
			});
		// Object.keys(data).map((key) => {
		// 	formData.append(key, data[key]);
		// });
		// if (user?.id) {
		// 	formData.append("_method", "PATCH");
		// }
		// axios
		// 	.post(`/v1/pho/users${user?.id ? `/${user?.id}` : ""}`, formData)
		// 	.then(() => {
		// 		addToList(data);
		// 		setTimeout(() => {
		// 			if (user?.id) {
		// 				Notify.customToast(
		// 					"success",
		// 					"User updated successfully!"
		// 				);
		// 			} else {
		// 				Notify.customToast(
		// 					"success",
		// 					"User created successfully!"
		// 				);
		// 			}
		// 		}, 400);
		// 		hide();
		// 	})
		// 	.finally(() => {
		// 		setLoading(false);
		// 	});
	};

	return (
		<Modal open={open} size="lg" hide={hide} className=" w-full">
			<ModalHeader
				title={`Create prescription for ${
					type == "bhs" ? "BHS Patient" : "RHU Patient"
				}`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="flex items-center gap-2 text-sm mb-3 font-bold">
					<span>
						<Clock
							format={"MMMM D, YYYY"}
							ticking={true}
							timezone={"ASIA/Manila"}
						/>
					</span>
					<span>
						<Clock
							format={"hh:mm:ss A"}
							ticking={true}
							timezone={"ASIA/Manila"}
						/>
					</span>
				</div>
				<div>
					{appointment?.id ? (
						<SelectPrescriptionItems
							key={`SelectPrescriptionItems-${appointment?.id}`}
							type={type}
							appointment={appointment}
							setSelectedItems={setPrescriptionItems}
						/>
					) : (
						""
					)}
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center"}>
				<ActionBtn
					className="ml-auto"
					onClick={handleSubmit(submit)}
					loading={loading}
				>
					Submit
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(CreatePrescriptionModal);
