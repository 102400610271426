import Img from "src/components/Img";
import { makeStyles, Button, Checkbox } from "@material-ui/core";
import { Print } from "@material-ui/icons";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

const BoxInput = ({ className = "", inputClassName = "", value = "" }) => {
	return (
		<>
			<label
				className={`flex items-center border-l last:border-r border-b border-black ${className}`}
			>
				<input
					type="text"
					className={`w-4 p-0 leading-none text-center border-0 !text-xs h-3 ${inputClassName}`}
					maxLength={1}
					value={value}
				/>
			</label>
		</>
	);
};

const BoxInputGroup = ({
	children,
	label = "",
	className = "",
	labelClassName = "",
}) => {
	return (
		<div
			className={`flex flex-col items-center relative pt-1 ${className}`}
		>
			<div className="flex items-center">{children}</div>
			{label && (
				<label className={`absolute bottom-3 ${labelClassName}`}>
					{label}
				</label>
			)}
		</div>
	);
};
const UnderlineInput = ({
	label = "",
	className = "",
	labelClassName = "",
	inputClassName = "",
	value = "",
}) => {
	return (
		<label className={`flex flex-col items-center gap-0 ${className}`}>
			<input
				type="text"
				className={`${inputClassName}`}
				style={{ borderBottom: "1px solid black", width: "100%" }}
				value={value}
			/>
			<label className={`!text-[10px] ${labelClassName}`}>{label}</label>
		</label>
	);
};
const RowInput = ({
	label,
	subLabel,
	className = "",
	labelClassName = "",
	inputClassName = "",
	value = "",
}) => {
	return (
		<div className={`flex flex-col w-full ${className}`}>
			{label && (
				<span
					className={`font-bold text-left w-full mb-[2px] ${labelClassName}`}
				>
					{label}
				</span>
			)}
			<input
				className={`flex border-forms h-5 ${inputClassName}`}
				value={value}
			/>
			{subLabel && (
				<span className="font-bold text-left w-full text-[6px] mt-[2px] mb-[2px]">
					{subLabel}
				</span>
			)}
		</div>
	);
};
const TextInput = ({
	label = "",
	className = "",
	labelClassName = "",
	inputClassName = "",
	value = "",
}) => {
	return (
		<label className={`flex items-center gap-0 ${className}`}>
			<label className={`text-xs ${labelClassName}`}>{label}</label>
			<input
				type="text"
				className={`pr-6 ${inputClassName}`}
				style={{ borderBottom: "1px solid black", width: "90%" }}
				value={value}
			/>
		</label>
	);
};

const FormValue = ({ className = "", text = "" }) => {
	return (
		<div
			className={`font-italic text-uppercase text-gray-500 ${className}`}
		>
			{text}
		</div>
	);
};

const CheckBox = ({
	label,
	className = "",
	inputClassName = "",
	isChecked = false,
}) => {
	return (
		<label
			className={`flex items-start text-xxs gap-1 font-normal ${className}`}
		>
			<input
				type="checkbox"
				className={inputClassName}
				checked={isChecked}
			/>
			{label}
		</label>
	);
};

const useStyle = makeStyles((theme) => ({
	sectionTitle: {
		backgroundColor: "#dbe2c6",
		borderLeft: "1px solid black",
		borderRight: "1px solid black",
	},
}));

const Pmrf = () => {
	const classes = useStyle();
	let printRef = useRef();
	const params = useParams();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);

	useEffect(() => {
		setLoading(true);
		let t = setTimeout(() => {
			if (params?.id) {
				getPMRFDetails(params?.id);
			}
		}, 800);

		return () => {
			clearTimeout(t);
		};
	}, [params?.id]);

	const getPMRFDetails = (id) => {
		axios
			.get(`/v1/pmrf/show/${id}`)
			.then((res) => {
				console.log(res);
				setData(res.data.data);
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 200);
			});
	};

	const getBirthdate = (index = 0, date = "") => {
		const birthdate = date ? date.replace(/-/g, "") : "";
		return birthdate.charAt(index);
	};

	const boxInputValue = (index = 0, val = "") => {
		const data = val ? val.replace(/-/g, "") : "";
		return data.charAt(index);
	};

	return (
		<>
			<div className="bg-white p-11 h-screen overflow-auto">
				<div className="w-[8.5in]" style={{ margin: "auto" }}>
					<div
						className="mb-6 flex justify-end items-end"
						style={{ marginTop: "8vh" }}
					>
						<ReactToPrint
							trigger={() => {
								// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
								// to the root node of the returned component as it will be overwritten.
								return (
									<Button
										className={"d-print-none"}
										variant="contained"
										color="primary"
										startIcon={<Print />}
									>
										Print
									</Button>
								);
							}}
							content={() => printRef.current}
						/>
					</div>
					<div className="">
						<div
							ref={printRef}
							className="bg-white min-h-[13in]"
							style={{ margin: "auto" }}
						>
							<div className="bg-white flex flex-col gap-y-6">
								<div className="flex flex-col align-center">
									<div className="flex items-center p-1 border-forms">
										<div className="flex flex-col mr-auto w-3/5">
											<Img
												type="user"
												src="/philhealth-2.png"
												className="w-[144px]"
											/>
											<u className="font-bold text-xs mb-1">
												REMINDERS:
											</u>
											<table className="text-xs">
												<tr>
													<td className="d-flex align-items-start mr-2">
														1.
													</td>
													<td>
														{" "}
														Your PhilHealth
														Identification Number
														(PIN) is your unique and
														permanent number.
													</td>
												</tr>
												<tr>
													<td className="d-flex align-items-start mr-2">
														2.
													</td>
													<td>
														Always use your PIN in
														all transactions with
														PhilHealth.
													</td>
												</tr>
												<tr>
													<td className="d-flex align-items-start mr-2">
														3.
													</td>
													<td>
														For Updating/Amendment
														check the appropriate
														box and provide details
														to be accomplished and
														submit corresponding
														supporting documents.
													</td>
												</tr>
												<tr>
													<td className="d-flex align-items-start mr-2">
														4.
													</td>
													<td>
														Please read instructions
														at the back before
														filling-out this form.
													</td>
												</tr>
											</table>
										</div>
										<div className="flex flex-col items-center justify-center">
											<h3 className="text-3xl font-bold">
												PMRF
											</h3>
											<b className="text-xs">
												PHILHEALTH MEMBER REGISTRATION
												FORM
											</b>
											<small className="text-xs font-bold">
												UHC v.1 January 2020
											</small>
											<div className="flex items-center">
												<BoxInput
													className="border-forms"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border-forms"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border-forms"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border-forms  mr-1"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border-forms"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border-forms"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border-forms"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border-forms mr-1"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border-forms"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border-forms"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border-forms"
													inputClassName="w-3 h-5"
												/>
												<BoxInput
													className="border-forms"
													inputClassName="w-3 h-5"
												/>
											</div>

											<div
												className="font-bold"
												style={{ fontSize: "8px" }}
											>
												PHILHEALTH IDENTIFICATION NUMBER
												(PIN)
											</div>
											<div className="flex flex-col">
												<span className="font-bold text-xs pt-2">
													PURPOSE:
												</span>
												<div className="flex gap-4">
													<CheckBox
														label={`REGISTRATION`}
													/>
													<CheckBox
														label={`UPDATING/AMENDMENT`}
													/>
												</div>
											</div>
											<div className="flex flex-col w-full">
												<span className="font-bold text-left w-full text-8px pt-2 mb-[2px]">
													Preferred KonSulTa Provider
												</span>
												<div className="flex border-forms p-2"></div>
											</div>
										</div>
									</div>
									<div
										className={`flex items-center border-y p-1 justify-center ${classes.sectionTitle}`}
									>
										<b className="text-sm">
											I. PERSONAL DETAILS
										</b>
									</div>
									<div className="border-y">
										<table className="bordered-table text-xs w-full">
											<tr>
												<th
													rowSpan={2}
													className="w-[128px]"
												></th>
												<th rowSpan={2}>LAST NAME</th>
												<th rowSpan={2}>FIRST NAME</th>
												<th
													rowSpan={2}
													className="w-[62px]"
												>
													<div className="text-center text-8px">
														NAME
														<br />
														EXTENSION
														<br />
														(Jr./Sr./III)
													</div>
												</th>
												<th rowSpan={2}>MIDDLE NAME</th>
												<th className="w-[44px]">
													<div className="text-center text-8px">
														NO
														<br />
														MIDDLE
														<br />
														NAME
													</div>
												</th>
												<th className="w-[44px] text-8px">
													MONONYM
												</th>
											</tr>
											<tr>
												<th
													colSpan={2}
													className=" text-[7px]"
												>
													(Check if applicable only)
												</th>
											</tr>
											<tr>
												<th className="h-[32px] text-left">
													MEMBER
												</th>
												<td>
													<FormValue
														text={data?.lastname}
													/>
												</td>
												<td>
													<FormValue
														text={data?.firstname}
													/>
												</td>
												<td>
													<FormValue
														text={data?.extension}
													/>
												</td>
												<td>
													<FormValue
														text={data?.middle}
													/>
												</td>
												<td>
													<div className="flex items-center justify-center">
														<CheckBox
															isChecked={
																data &&
																data?.firstname &&
																!data?.middle
															}
														/>
													</div>
												</td>
												<td className="">
													<div className="flex items-center justify-center">
														<CheckBox
															isChecked={
																data &&
																data?.firstname &&
																!data?.middle &&
																!data.lastname
															}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<th className="h-[32px] text-left">
													MOTHER’s <br /> MAIDEN NAME
												</th>
												<td>
													<FormValue
														text={
															data?.mother_lastname
														}
													/>
												</td>
												<td>
													<FormValue
														text={
															data?.mother_firstname
														}
													/>
												</td>
												<td></td>
												<td>
													<FormValue
														text={
															data?.mother_middlename
														}
													/>
												</td>
												<td>
													<div className="flex items-center justify-center">
														<CheckBox
															isChecked={
																data &&
																data?.mother_firstname &&
																!data?.mother_middlename
															}
														/>
													</div>
												</td>
												<td className="">
													<div className="flex items-center justify-center">
														<CheckBox
															isChecked={
																data &&
																data?.mother_firstname &&
																!data?.mother_middlename &&
																!data.mother_lastname
															}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<th className="h-[32px] text-left">
													SPOUSE <br />
													<small className="text-8px">
														(If Married)
													</small>
												</th>
												<td>
													<FormValue
														text={
															data?.spouse_last_name
														}
													/>
												</td>
												<td>
													<FormValue
														text={
															data?.spouse_first_name
														}
													/>
												</td>
												<td>
													<FormValue
														text={
															data?.spouse_name_ext
														}
													/>
												</td>
												<td>
													<FormValue
														text={
															data?.spouse_middle_name
														}
													/>
												</td>
												<td>
													<div className="flex items-center justify-center">
														<CheckBox
															isChecked={
																data &&
																data?.spouse_first_name &&
																!data?.spouse_middle_name
															}
														/>
													</div>
												</td>
												<td className="">
													<div className="flex items-center justify-center">
														<CheckBox
															isChecked={
																data &&
																data?.spouse_first_name &&
																!data?.spouse_middle_name &&
																!data.spouse_last_name
															}
														/>
													</div>
												</td>
											</tr>
										</table>
										<table
											className="bordered-table text-xs w-full"
											style={{
												borderBottom: "1px solid black",
											}}
										>
											<tr>
												<td
													colSpan={2}
													style={{
														minWidth: "230px",
													}}
												>
													<div className="flex flex-col pb-3 px-1">
														<b className="text-xs">
															DATE OF BIRTH
														</b>
														<div className="flex items-center">
															<BoxInputGroup>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={getBirthdate(
																		0,
																		data?.date_of_birth
																	)}
																/>
															</BoxInputGroup>
															<BoxInputGroup className="mr-1">
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={getBirthdate(
																		1,
																		data?.date_of_birth
																	)}
																/>
															</BoxInputGroup>
															<BoxInputGroup>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={getBirthdate(
																		2,
																		data?.date_of_birth
																	)}
																/>
															</BoxInputGroup>
															<BoxInputGroup className="mr-1">
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={getBirthdate(
																		3,
																		data?.date_of_birth
																	)}
																/>
															</BoxInputGroup>
															<BoxInputGroup>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={getBirthdate(
																		4,
																		data?.date_of_birth
																	)}
																/>
															</BoxInputGroup>
															<BoxInputGroup>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={getBirthdate(
																		5,
																		data?.date_of_birth
																	)}
																/>
															</BoxInputGroup>
															<BoxInputGroup>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={getBirthdate(
																		6,
																		data?.date_of_birth
																	)}
																/>
															</BoxInputGroup>
															<BoxInputGroup>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={getBirthdate(
																		7,
																		"09098765"
																	)}
																/>
															</BoxInputGroup>
														</div>
														<div className="flex items-center">
															<div className="px-1">
																m
															</div>
															<div className="pr-1">
																m
															</div>
															<div className="ml-1 px-2">
																d
															</div>
															<div className="pr-2">
																d
															</div>
															<div className="px-2">
																y
															</div>
															<div className="pr-2">
																y
															</div>
															<div className="pr-2">
																y
															</div>
															<div className="pr-2">
																y
															</div>
														</div>
													</div>
												</td>
												<td className="px-1">
													<div className="flex flex-col px-1 pb-1">
														<div className="mb-0">
															<b className="text-xs mr-1">
																PLACE OF BIRTH
															</b>
															<span className="text-8px">
																(City/
																Municipality/
																Province/
																Country)
															</span>
														</div>
														<span className="text-8px">
															(Please indicate
															country if born
															outside the
															Philippines)
														</span>
														<span>&nbsp;</span>
													</div>
													<FormValue
														text={data?.birthplace}
													/>
												</td>
												<td rowSpan={2}>
													<div className="flex flex-col p-1 gap-3">
														<div className="flex flex-col">
															<b className="text-xs">
																PHILSYS ID
																NUMBER{" "}
																<small className="">
																	{" "}
																	(Optional)
																</small>
															</b>
															<div className="flex items-center">
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		0,
																		data?.philsys_id_no
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		1,
																		data?.philsys_id_no
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		2,
																		data?.philsys_id_no
																	)}
																/>
																<BoxInput
																	className="border-forms mr-1"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		3,
																		data?.philsys_id_no
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		4,
																		data?.philsys_id_no
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		5,
																		data?.philsys_id_no
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		6,
																		data?.philsys_id_no
																	)}
																/>
																<BoxInput
																	className="border-forms mr-1"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		7,
																		data?.philsys_id_no
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		8,
																		data?.philsys_id_no
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		9,
																		data?.philsys_id_no
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		10,
																		data?.philsys_id_no
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		11,
																		data?.philsys_id_no
																	)}
																/>
															</div>
														</div>
														<div className="flex flex-col">
															<b className="text-xs">
																TAX PAYER
																IDENTIFICATION
																NUMBER
																<small className="">
																	(TIN)
																	(Optional)
																</small>
															</b>
															<div className="flex items-center">
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		0,
																		data?.tin
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		1,
																		data?.tin
																	)}
																/>
																<BoxInput
																	className="border-forms mr-1"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		2,
																		data?.tin
																	)}
																/>

																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		3,
																		data?.tin
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		4,
																		data?.tin
																	)}
																/>
																<BoxInput
																	className="border-forms mr-1"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		5,
																		data?.tin
																	)}
																/>

																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		6,
																		data?.tin
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		7,
																		data?.tin
																	)}
																/>
																<BoxInput
																	className="border-forms"
																	inputClassName="w-3 h-5"
																	value={boxInputValue(
																		8,
																		data?.tin
																	)}
																/>
															</div>
														</div>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<div className="d-flex align-items-start flex-col px-1">
														<div className="font-bold text-xs">
															SEX
														</div>
														<div className="d-flex flex-col">
															<CheckBox
																label="Male"
																isChecked={
																	data.gender &&
																	data?.gender.toLowerCase() ==
																		"male"
																}
															/>
															<CheckBox
																label="Female"
																isChecked={
																	data.gender &&
																	data?.gender.toLowerCase() ==
																		"female"
																}
															/>
														</div>
													</div>
												</td>
												<td>
													<div className="d-flex align-items-start flex-col px-1">
														<b className="text-xs">
															CIVIL STATUS
														</b>
														<div className="grid grid-cols-2">
															<CheckBox
																label="Single"
																isChecked={
																	data.civil_status &&
																	data?.civil_status.toLowerCase() ==
																		"single"
																}
															/>
															<CheckBox
																label="Annulled"
																isChecked={
																	data.civil_status &&
																	data?.civil_status.toLowerCase() ==
																		"annulled"
																}
															/>
															<CheckBox
																label="Married"
																isChecked={
																	data.civil_status &&
																	data?.civil_status.toLowerCase() ==
																		"married"
																}
															/>
															<CheckBox
																label="Widow/er"
																isChecked={
																	data.civil_status &&
																	data?.civil_status.toLowerCase() ==
																		"widow/er"
																}
															/>
															<CheckBox
																label="Legally Separated"
																className="col-span-2"
																isChecked={
																	data.civil_status &&
																	data?.civil_status.toLowerCase() ==
																		"legally separated"
																}
															/>
														</div>
													</div>
												</td>
												<td>
													<div className="d-flex align-items-start flex-col px-1">
														<b className="text-xs">
															CITIZENSHIP
														</b>
														<div className="grid grid-cols-2">
															<CheckBox
																label="FILIPINO"
																isChecked={
																	data.citizenship &&
																	data?.citizenship.toLowerCase() ==
																		"fil"
																}
															/>
															<CheckBox
																label="FOREIGN NATIONAL"
																isChecked={
																	data.citizenship &&
																	data?.citizenship.toLowerCase() ==
																		"foreign"
																}
															/>
															<CheckBox
																label="DUAL CITIZEN"
																isChecked={
																	data.citizenship &&
																	data?.citizenship.toLowerCase() ==
																		"dual"
																}
															/>
														</div>
													</div>
												</td>
											</tr>
										</table>
									</div>

									<div
										className={`flex items-center border-y p-1 justify-center ${classes.sectionTitle}`}
									>
										<b className="text-sm">
											II. ADDRESS and CONTACT DETAILS
										</b>
									</div>
									<div className="border-y">
										<table
											className="bordered-table text-xs w-full"
											style={{
												borderBottom: "1px solid black",
											}}
										>
											<tr>
												<td>
													<div className="flex flex-col px-1 my-1">
														<b className="text-xs">
															PERMANENT HOME
															ADDRESS
														</b>
														<div className="w-full grid grid-cols-4 gap-3">
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Unit/Room
																	No./Floor
																</div>
																<FormValue
																	text={
																		data?.unit
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Building
																	Name
																</div>
																<FormValue
																	text={
																		data?.building_name
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Lot/Block/Phase/{" "}
																	<br />
																	House Number
																</div>
																<FormValue
																	text={
																		data?.house_number
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Street Name
																</div>
																<FormValue
																	text={
																		data?.street
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
														</div>
													</div>
												</td>
												<td
													rowSpan={4}
													className="w-1/4"
												>
													<div className="flex flex-col w-full gap-2 px-1 py-2">
														<RowInput
															label="Home Phone Number"
															subLabel={`(COUNTRY CODE + AREA CODE + TELEPHONE NUM BER)`}
															value={
																data?.telephone
															}
														/>
														<RowInput
															label="Mobile Number (Required)"
															value={data?.mobile}
														/>
														<RowInput
															label="Business (Direct Line)"
															value={
																data?.business_direct_line
															}
														/>
														<RowInput
															label="E-mail Address (Required for OFW)"
															value={data?.email}
														/>
													</div>
												</td>
											</tr>

											<tr>
												<td className="">
													<div className="flex flex-col pb-2 px-1 pt-1">
														<div className="w-full flex items-start justify-between gap-3">
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Subdivision
																</div>
																<FormValue
																	text={
																		data?.subdivision
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Barangay
																</div>
																<FormValue
																	text={
																		data?.barangay
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Municipality/City
																</div>
																<FormValue
																	text={
																		data?.municipality
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Province/State/Country
																	(If abroad)
																</div>
																<FormValue
																	text={
																		data?.province
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	ZIP Code
																</div>
																<FormValue
																	text={
																		data?.zip
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
														</div>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<div className="flex flex-col pb-2 px-1 pt-1">
														<div className="flex flex-row">
															<b className="text-xs">
																MAILING ADDRESS
															</b>
															<b className="text-xs ml-6 mr-4">
																<CheckBox
																	className="mr-2 font-bold"
																	label={
																		"SAME AS ABOVE"
																	}
																	isChecked={
																		data?.is_mail_address
																	}
																/>
															</b>
														</div>
														<div className="w-full grid grid-cols-4 gap-3">
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Unit/Room
																	No./Floor
																</div>
																<FormValue
																	text={
																		data?.mail_unit
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Building
																	Name
																</div>
																<FormValue
																	text={
																		data?.mail_building_name
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Lot/Block/Phase/{" "}
																	<br />
																	House Number
																</div>
																<FormValue
																	text={
																		data?.mail_address_block
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Street Name
																</div>
																<FormValue
																	text={
																		data?.mail_street
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
														</div>
													</div>
												</td>
											</tr>

											<tr>
												<td className="">
													<div className="flex flex-col pb-2 px-1 pt-1">
														<div className="w-full flex items-start justify-between gap-3">
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Subdivision
																</div>
																<FormValue
																	text={
																		data?.subdivision
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Barangay
																</div>
																<FormValue
																	text={
																		data?.mail_barangay
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Municipality/City
																</div>
																<FormValue
																	text={
																		data?.mail_city
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	Province/State/Country
																	(If abroad)
																</div>
																<FormValue
																	text={
																		data?.mail_province
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
															<div className="flex flex-col content-between">
																<div className="font-weight-bold text-8px">
																	ZIP Code
																</div>
																<FormValue
																	text={
																		data?.mail_zip_code
																	}
																	className={
																		"mt-2"
																	}
																/>
															</div>
														</div>
													</div>
												</td>
											</tr>
										</table>
									</div>
									<div
										className={`flex items-center border-y p-1 justify-center ${classes.sectionTitle}`}
									>
										<b className="text-sm">
											III. DECLARATION OF DEPENDENTS
										</b>
									</div>
									<div className="border-y">
										<table
											className="bordered-table text-xs w-full"
											style={{
												borderBottom: "1px solid black",
											}}
										>
											<tr>
												<th rowSpan={2}>LAST NAME</th>
												<th rowSpan={2}>FIRST NAME</th>
												<th
													rowSpan={2}
													className="w-[62px]"
												>
													<div className="text-center text-8px">
														NAME
														<br />
														EXTENSION
														<br />
														(Jr./Sr./III)
													</div>
												</th>
												<th rowSpan={2}>MIDDLE NAME</th>
												<th rowSpan={2}>
													<span className="text-8px">
														RELATIONSHIP
													</span>
												</th>
												<th
													rowSpan={2}
													className="w-[62px]"
												>
													<div className="text-center text-8px">
														DATE OF
														<br />
														BIRTH
														<br />
														(mm-dd-yyyy)
													</div>
												</th>
												<th rowSpan={2}>
													<span className="text-8px">
														CITIZENSHIP
													</span>
												</th>
												<th className="w-[44px]">
													<div className="text-center text-8px">
														NO
														<br />
														MIDDLE
														<br />
														NAME
													</div>
												</th>
												<th className="w-[44px] text-8px">
													MONONYM
												</th>
												<th
													rowSpan={2}
													className="w-11 text-8px"
												>
													Check if with Permanent
													Disability
												</th>
											</tr>
											<tr>
												<td colSpan={2}>
													<span className="text-[6px] flex items-center justify-center text-center font-bold">
														(Check if applicable
														only)
													</span>
												</td>
											</tr>
											<tr>
												<td className="!h-8"></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td>
													<div className="flex items-center justify-center p-[2px]">
														<CheckBox />
													</div>
												</td>
												<td>
													<div className="flex items-center justify-center p-[2px]">
														<CheckBox />
													</div>
												</td>
												<td>
													<div className="flex items-center justify-center p-[2px]">
														<CheckBox />
													</div>
												</td>
											</tr>
											<tr>
												<td className="!h-8"></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td>
													<div className="flex items-center justify-center p-[2px]">
														<CheckBox />
													</div>
												</td>
												<td>
													<div className="flex items-center justify-center p-[2px]">
														<CheckBox />
													</div>
												</td>
												<td>
													<div className="flex items-center justify-center p-[2px]">
														<CheckBox />
													</div>
												</td>
											</tr>
											<tr>
												<td className="!h-8"></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td>
													<div className="flex items-center justify-center p-[2px]">
														<CheckBox />
													</div>
												</td>
												<td>
													<div className="flex items-center justify-center p-[2px]">
														<CheckBox />
													</div>
												</td>
												<td>
													<div className="flex items-center justify-center p-[2px]">
														<CheckBox />
													</div>
												</td>
											</tr>
											<tr>
												<td className="!h-8"></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td>
													<div className="flex items-center justify-center p-[2px]">
														<CheckBox />
													</div>
												</td>
												<td>
													<div className="flex items-center justify-center p-[2px]">
														<CheckBox />
													</div>
												</td>
												<td>
													<div className="flex items-center justify-center p-[2px]">
														<CheckBox />
													</div>
												</td>
											</tr>
										</table>
									</div>

									<div
										className={`flex items-center border-y p-1 justify-center ${classes.sectionTitle}`}
									>
										<b className="text-sm">
											IV. MEMBER TYPE
										</b>
									</div>
									<div className="border-y">
										<table
											className="bordered-table text-xs w-full"
											style={{
												borderBottom: "1px solid black",
											}}
										>
											<tr>
												<td colSpan={3}>
													<div className="w-full flex flex-col items-start">
														<div className="w-full text-center font-bold text-xs">
															DIRECT CONTRIBUTOR
														</div>
														<div className="flex flex-row gap-2">
															<div className="flex flex-col p-1 gap-1 col-span-2">
																<CheckBox
																	label="Employed Private"
																	isChecked={
																		data?.employed_private
																	}
																/>
																<CheckBox
																	label="Employed Government"
																	isChecked={
																		data?.employed_government
																	}
																/>
																<CheckBox
																	label="Professional Practitioner"
																	isChecked={
																		data?.professional_practitioner
																	}
																/>
																<CheckBox
																	label="Self-Earning Individual"
																	isChecked={
																		data?.self_earning_individual
																	}
																/>
																<div className="flex flex-col gap-1 pl-4">
																	<CheckBox
																		label="Individual"
																		isChecked={
																			data?.individual
																		}
																	/>
																	<CheckBox
																		label="Sole Proprietor"
																		isChecked={
																			data?.sole_proprietor
																		}
																	/>
																	<CheckBox
																		label="Group Enrollment Scheme"
																		isChecked={
																			data?.group_enrollment_scheme
																		}
																	/>
																</div>
																<TextInput
																	className="pl-8"
																	labelClassName="whitespace-pre"
																	value={
																		data?.others_self_earning
																	}
																/>
															</div>

															<div className="flex flex-col p-1 col-span-3 gap-1">
																<div className="flex items-center gap-11">
																	<CheckBox
																		label="Kasambahay"
																		isChecked={
																			data?.kasambahay
																		}
																	/>
																	<CheckBox
																		label="Family Driver"
																		isChecked={
																			data?.family_driver
																		}
																	/>
																</div>
																<CheckBox
																	label="Migrant Worker"
																	isChecked={
																		data?.migrant_worker
																	}
																/>
																<div className="flex items-center gap-4 pl-4">
																	<CheckBox
																		label="Land-Based"
																		isChecked={
																			data?.land_based
																		}
																	/>
																	<CheckBox
																		label="Sea-Based"
																		isChecked={
																			data?.sea_based
																		}
																	/>
																</div>
																<CheckBox
																	label="Lifetime Member"
																	isChecked={
																		data?.lifetime_member
																	}
																/>
																<CheckBox
																	label="Filipinos with Dual Citizenship / Living Abroad"
																	isChecked={
																		data?.dual_citizenship
																	}
																/>
																<CheckBox
																	label="Foreign National"
																	isChecked={
																		data?.foreign_national
																	}
																/>
																<TextInput
																	label="PRA SRRV No."
																	className="pl-5 pr-5"
																	value={
																		data?.pra_srrv_no
																	}
																/>
																<TextInput
																	label="ACR I-Card No."
																	className="pl-5 pr-5"
																	value={
																		data?.acr_card_no
																	}
																/>
															</div>
														</div>
													</div>
												</td>

												<td>
													<div className="w-full flex flex-col p-1 items-start">
														<div className="w-full text-center font-bold text-xs mb-2">
															INDIRECT CONTRIBUTOR
														</div>
														<div className="flex flex-row gap-2">
															<div className="flex flex-col gap-1">
																<CheckBox
																	label="Listahan"
																	isChecked={
																		data?.listahan
																	}
																/>
																<CheckBox
																	label="4Ps/MCCT"
																	isChecked={
																		data?.fourPs_or_mcct
																	}
																/>
																<CheckBox
																	label="Senior Citizen"
																	isChecked={
																		data?.senior_citizen
																	}
																/>
																<CheckBox
																	label="PAMANA"
																	isChecked={
																		data?.pamana
																	}
																/>
																<CheckBox
																	label="KIA/KIPO"
																	isChecked={
																		data?.kia_kipo
																	}
																/>
																<CheckBox
																	label="Bangsamoro/ Normalization"
																	isChecked={
																		data?.bangsamoro
																	}
																/>
															</div>
															<div className="flex flex-col gap-1">
																<CheckBox
																	label="LGU-sponsored"
																	isChecked={
																		data?.lgu_sponsored
																	}
																/>
																<CheckBox
																	label="NGA-sponsored"
																	isChecked={
																		data?.nga_sponsored
																	}
																/>
																<CheckBox
																	label="Private-sponsored"
																	isChecked={
																		data?.private_sponsored
																	}
																/>
																<CheckBox
																	label="Person with Disability"
																	isChecked={
																		data?.pwd
																	}
																/>
																<TextInput
																	label="PWD ID No."
																	className="pl-4"
																	labelClassName="whitespace-pre"
																	value={
																		data?.pwd_id_no
																	}
																/>
															</div>
														</div>
													</div>
												</td>
											</tr>
											<tr>
												<td className="!align-top p-1 text-[10px]">
													<div className="flex w-[200px] gap-2">
														<b>PROFESSION:</b>
														<span className="text-[6px]">
															(Except Employed,
															Lifetime Members and
															Sea-based Migrant
															Worker)
														</span>
													</div>
													<FormValue
														text={data?.profession}
													/>
												</td>
												<td className="!align-top p-1 text-[10px]">
													<div className="flex">
														<b className="whitespace-pre">
															MONTHLY INCOME:
														</b>
													</div>
													<FormValue
														text={
															data?.monthly_income
														}
													/>
												</td>
												<td className="!align-top p-1 text-[10px]">
													<div className="flex">
														<b className="whitespace-pre">
															PROOF OF INCOME:
														</b>
													</div>
													<FormValue
														text={
															data?.proof_of_income
														}
													/>
												</td>
												<td className="!align-top">
													<div className="w-full flex flex-col p-1">
														<div className="w-full text-center font-bold text-xs mb-2">
															For PhilHealth Use
															only:
														</div>
														<div className="grid grid-cols-1 gap-1">
															<CheckBox
																label="Point of Service (POS) Financially Incapable"
																isChecked={
																	data?.point_of_service
																}
															/>
															<CheckBox
																label="Financially Incapable"
																isChecked={
																	data?.financially_incapable
																}
															/>
														</div>
													</div>
												</td>
											</tr>
											<tr>
												<td colSpan={4}>
													<div className="flex pt-3">
														<span className="text-8px pr-6">
															This form may be
															reproduced and is
															not for sale
														</span>
														<span className="font-weight-bold ml-6">
															Continue at the back
														</span>
													</div>
												</td>
											</tr>
										</table>
									</div>
								</div>

								<div className="bg-white flex flex-col h-[13in] align-center">
									<div
										className={`flex items-center border-y p-1 justify-center ${classes.sectionTitle}`}
										style={{ borderTop: "1px solid black" }}
									>
										<b className="text-sm">
											V. UPDATING/AMENDMENT
										</b>
									</div>
									<div className="border-y">
										<table className="bordered-table text-xs w-full border-forms">
											<tr>
												<th className="w-1/4 text-left py-2 px-2">
													Please check:
												</th>
												<th className="w-1/4 text-center py-2 px-2">
													FROM
												</th>
												<th className="w-1/4 text-center py-2 px-2">
													TO
												</th>
											</tr>
											<tr>
												<td className="w-1/3 text-left px-1 py-3">
													<CheckBox
														label={
															<div className="flex flex-col">
																<span>
																	Change/Correction
																	of Name
																</span>
																<span className="text-[6px]">
																	(Last Name,
																	First Name,
																	Name
																	Extension
																	(Jr./Sr./III)
																	Middle Name)
																</span>
															</div>
														}
														isChecked={
															data?.is_name_corrected
														}
													/>
												</td>
												<td className="w-1/3 text-left">
													<FormValue
														text={
															data?.name_corrected_from
														}
													/>
												</td>
												<td className="w-1/3 text-left">
													<FormValue
														text={
															data?.name_corrected_to
														}
													/>
												</td>
											</tr>
											<tr>
												<td className="w-1/3 text-left px-1 py-3">
													<CheckBox
														label={`Correction of Date of Birth`}
														isChecked={
															data?.is_birthday_corrected
														}
													/>
												</td>
												<td className="w-1/3 text-left">
													<FormValue
														text={
															data?.birthday_corrected_from
														}
													/>
												</td>
												<td className="w-1/3 text-left">
													<FormValue
														text={
															data?.birthday_corrected_to
														}
													/>
												</td>
											</tr>
											<tr>
												<td className="w-1/3 text-left px-1 py-3">
													<CheckBox
														label={`Correction of Sex`}
														isChecked={
															data?.is_sex_corrected
														}
													/>
												</td>
												<td className="w-1/3 text-left">
													<FormValue
														text={
															data?.sex_corrected_from
														}
													/>
												</td>
												<td className="w-1/3 text-left">
													<FormValue
														text={
															data?.sex_corrected_to
														}
													/>
												</td>
											</tr>
											<tr>
												<td className="w-1/3 text-left px-1 py-3">
													<CheckBox
														label={`Change of Civil Status`}
														isChecked={
															data?.is_changed_civil_status
														}
													/>
												</td>
												<td className="w-1/3 text-left">
													<FormValue
														text={
															data?.changed_status_from
														}
													/>
												</td>
												<td className="w-1/3 text-left">
													<FormValue
														text={
															data?.changed_status_to
														}
													/>
												</td>
											</tr>
											<tr>
												<td className="w-1/3 text-left px-1">
													<CheckBox
														label={`Updating of Personal Information/Address/
														Telephone Number/Mobile Number/e-mail
														Address`}
														isChecked={
															data?.is_updated_info
														}
													/>
												</td>
												<td className="w-1/3 text-left">
													<FormValue
														text={
															data?.updated_info_from
														}
													/>
												</td>
												<td className="w-1/3 text-left">
													<FormValue
														text={
															data?.updated_info_to
														}
													/>
												</td>
											</tr>
										</table>
									</div>

									<div className="border-y mt-1">
										<table className="bordered-table text-xs w-full border-forms">
											<tr>
												<td
													className="w-2/3"
													rowSpan={2}
												>
													<div className="flex flex-col p-2 text-justify">
														<p className="text-sm leading-tight">
															Under penalty of
															law, I hereby attest
															that the information
															provided, including
															the documents I have
															attached to this
															form, are true and
															accurate to the best
															of my knowledge. I
															agree and authorize
															PhilHealth for the
															subsequent
															validation,
															verification and for
															other data sharing
															purposes only under
															the following
															circumstances:
														</p>
														<ul className="text-sm pl-4 pt-2 list-disc ml-4">
															<li>
																As necessary for
																the proper
																execution of
																processes
																related to the
																legitimate and
																declared
																purpose;
															</li>
															<li>
																The use or
																disclosure is
																reasonably
																necessary,
																required or
																authorized by or
																under the law;
																and,
															</li>
															<li>
																Adequate
																security
																measures are
																employed to
																protect my
																information.
															</li>
														</ul>
														<div className="flex items-end gap-6">
															<div className="grid grid-cols-12 gap-4 w-full">
																<UnderlineInput
																	label="Member’s Signature over Printed Name"
																	labelClassName="font-bold pt-1"
																	className="col-span-8"
																/>
																<UnderlineInput
																	label="Date"
																	labelClassName="font-bold pt-1"
																	className="col-span-4"
																	value={
																		data?.date_signed
																	}
																/>
															</div>
															<div className="flex flex-col">
																<div className="border-forms rounded-xl w-24 h-20"></div>
																<span className="text-8px text-center whitespace-pre pt-1">
																	Please affix
																	right <br />
																	thumbmark if
																	unable to
																	write
																</span>
															</div>
														</div>
													</div>
												</td>
												<td className="bg-[#dbe2c6]">
													<div className="flex flex-col">
														<div className="flex items-left p-1">
															<b className="text-base">
																FOR PHILHEALTH
																USE ONLY
															</b>
														</div>
													</div>
												</td>
											</tr>
											<tr>
												<td className="bg-[#f5f5f5]">
													<div className="flex flex-col">
														<div className="flex text-sm items-left p-1 mb-3">
															<b>RECEIVED BY:</b>
														</div>
														<div className="flex text-xs items-left p-1 mb-0">
															Full Name:
														</div>
														<div className="flex text-sm items-left p-1 mb-3">
															<TextInput
																inputClassName="bg-[#f5f5f5]"
																className="w-full"
																value={
																	data?.received_by
																}
															/>
														</div>
														<div className="flex text-xs items-left p-1 mb-0">
															PRO/LHIO/Branch:
														</div>
														<div className="flex text-sm items-left p-1 mb-3">
															<TextInput
																inputClassName="bg-[#f5f5f5]"
																className="w-full"
																value={
																	data?.branch_received
																}
															/>
														</div>
														<div className="flex text-xs items-left p-1 mb-0">
															Date & Time:
														</div>
														<div className="flex text-sm items-left p-1 mb-3">
															<TextInput
																inputClassName="bg-[#f5f5f5]"
																className="w-full"
																value={
																	data?.date_received
																}
															/>
														</div>
													</div>
												</td>
											</tr>
										</table>
									</div>

									<div className="border-y my-1 flex flex-col p-2 border-forms">
										<h4 className="font-bold text-center">
											INSTRUCTIONS
										</h4>
										<table>
											<tr>
												<td className="d-flex align-items-start mr-2">
													1.
												</td>
												<td>
													All information should be
													written in UPPER
													CASE/CAPITAL LETTERS. If the
													information is not
													applicable, write “N/A.”
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													2.
												</td>
												<td>
													All fields are mandatory
													unless indicated as
													optional. By affixing your
													signature, you certify the
													truthfulness and accuracy of
													all information provided.
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													3.
												</td>
												<td>
													A properly accomplished PMRF
													shall be accompanied by a
													valid proof of identity for
													first time registrants, and
													supporting documents to
													establish relationship
													between member and
													dependent/s for updating or
													request for amendment.
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													4.
												</td>
												<td>
													On the PURPOSE, check the
													appropriate box if for{" "}
													<u className="font-bold">
														Registration
													</u>{" "}
													or for{" "}
													<u className="font-bold">
														Updating/Amendment
													</u>{" "}
													of information.
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													5.
												</td>
												<td>
													Indicate preferred KonSulTa
													provider near the place of
													work or residence.
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													6.
												</td>
												<td>
													<p>
														For PERSONAL DETAILS,
														all name entries should
														follow the format given
														below. Check the
														appropriate box if
														registrant has no middle
														name and/or with single
														name (mononym).
													</p>
													<div className="flex text-center justify-between py-2 px-11">
														<div className="flex flex-col">
															<b>LAST NAME</b>
															<span>SANTOS</span>
														</div>
														<div className="flex flex-col">
															<b>FIRST NAME</b>
															<span>
																JUAN ANDRES
															</span>
														</div>
														<div className="flex flex-col">
															<b>
																NAME EXTENSION
																(Jr./Sr./III)
															</b>
															<span>III</span>
														</div>
														<div className="flex flex-col">
															<b>MIDDLE NAME</b>
															<span>
																DELA CRUZ
															</span>
														</div>
													</div>
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													7.
												</td>
												<td>
													Indicate
													registrant’s/member’s name
													as it appears in the birth
													certificate.
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													8.
												</td>
												<td>
													The full mother’s maiden
													name of registrant/member
													must be indicated as it
													appears in the birth
													certificate
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													9.
												</td>
												<td>
													Indicate the full name of
													spouse if registrant/member
													is married.
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													10.
												</td>
												<td>
													Indicate the complete
													permanent and mailing
													addresses and contact
													numbers.
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													11.
												</td>
												<td>
													For updating/amendment,
													check the appropriate box to
													be updated/amended and
													indicate the correct data.
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													12.
												</td>
												<td>
													For MEMBER TYPE, check the
													appropriate box which best
													describes your current
													membership status.
												</td>
											</tr>{" "}
											<tr>
												<td className="d-flex align-items-start mr-2">
													13.
												</td>
												<td>
													For Direct Contributors,
													except employed, sea-based
													migrant workers and lifetime
													members, indicate the
													profession, monthly income
													and proof of income to be
													submitted
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													14.
												</td>
												<td>
													For Self-earning
													individuals, Kasambahays and
													Family Drivers, indicate the
													actual monthly income in the
													space provided.
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													15.
												</td>
												<td>
													In declaring dependents,
													provide the full name of the
													living spouse, children
													below 21 years old, and
													parents who are 60 years old
													and above totally dependent
													to the member.
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													16.
												</td>
												<td>
													Dependents with disability
													shall be registered as
													principal members in
													accordance with Republic Act
													11228 on mandatory
													PhilHealth coverage for all
													persons with disability
													(PWD).
												</td>
											</tr>
											<tr>
												<td className="d-flex align-items-start mr-2">
													17.
												</td>
												<td>
													The registrant must affix
													his/her signature over
													printed name (or right
													thumbmark if unable to
													write) and indicate the date
													when the PMRF was signed.
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Pmrf;
