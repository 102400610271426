import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
  TableContainer,
  DialogContent,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Cancel,
  BorderColor,
  Delete,
  Add,
} from "@material-ui/icons";
import axios from "axios";
import React, { useState, useCallback, useEffect, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import { UsersData } from "src/ContextAPI";
import FormHeader from "./FormHeader";
import { CheckSquare, Edit, Printer, XCircle } from "react-feather";
import Notify from "src/notification/Notify";

const FormEleven = ({ patient_id, details, info, doctorsList, allowEdit }) => {
  const printableRef = React.useRef();
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [isprocessCancel, setIsProcessCancel] = React.useState(false);
  const { users } = useContext(UsersData);
  const [addToTable, setAddToTable] = useState(false);

  const [ccdcf_id, setccdcf_id] = useState(null);

  const [
    existingDoctorsConsultationChart,
    setExistingDoctorsConsultationChart,
  ] = useState({
    data: [],
    ready: false,
  });

  const [
    existingDoctorsConsultationChartTable,
    setExistingDoctorsConsultationChartTable,
  ] = useState({
    data: [],
    ready: false,
  });

  const [cancelOrder, setCancelOrder] = React.useState({
    data: null,
    dialog: false,
  });

  const [editOrder, setEditOrder] = React.useState({
    data: null,
    dialog: false,
  });

  const [_inputNewDoctorsConsultation, setInputNewDoctorsConsultation] =
    React.useState({
      data: [],
      ready: true,
    });

  const [_addNewDocConsultation, setAddNewDocConsultation] = React.useState({
    data: [],
    ready: true,
  });

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const getDoctorsConsultationChart = useCallback(async () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/doctorsconsultation", { params })
      .then((response) => {
        const data = response.data;
        setExistingDoctorsConsultationChart({ data, ready: true });
        setccdcf_id(data[0].ccdcf_id);
      })
      .catch((error) => console.log(error));
  }, [patient_id, users, details]);

  const getDoctorsConsultationChartTable = useCallback(async () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/doctorsconsultationtable", { params })
      .then((response) => {
        const data = response.data;
        setExistingDoctorsConsultationChartTable({ data, ready: true });
      })
      .catch((error) => console.log(error));
  }, [patient_id, users, details]);

  const addField = () => {
    setInputNewDoctorsConsultation({
      data: _inputNewDoctorsConsultation.data.concat({
        date: "date[]",
        consultation_notes: "consultation_notes[]",
      }),
      ready: true,
    });
  };

  const removeField = (index, length) => {
    const list = _inputNewDoctorsConsultation.data;
    setInputNewDoctorsConsultation({
      ..._inputNewDoctorsConsultation,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputNewDoctorsConsultation({
      data: list,
      ready: true,
    });
  };

  const addAdditional = () => {
    setAddNewDocConsultation({
      data: _addNewDocConsultation.data.concat({
        date: "date[]",
        consultation_notes: "consultation_notes[]",
      }),
      ready: true,
    });
  };

  const removeAdditional = (index, length) => {
    const list = _addNewDocConsultation.data;
    setAddNewDocConsultation({
      ..._addNewDocConsultation,
      ready: false,
    });
    list.splice(length - 1, index);
    setAddNewDocConsultation({
      data: list,
      ready: true,
    });
  };

  const handleSubmitDoctorsConsultationChart = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", details.trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("status", ccdcf_id === null ? "for-new" : "for-update");
    formdata.set("ccdcf_id", ccdcf_id);

    setIsProcess(true);
    axios
      .post("or/update/chart/doctorsconsultation", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          getDoctorsConsultationChart();
          getDoctorsConsultationChartTable();
          setEnableEdit(!enableEdit);
          Notify.successRequest("edit doctors consultation_notes");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  const handleRemoveDoctorsConsultation = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);

    setIsProcessCancel(true);
    axios
      .post("or/remove/chart/doctorsconsultation", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setCancelOrder({ data: null, dialog: false });
          getDoctorsConsultationChartTable();
          Notify.successRequest("doctor consultation cancel");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  const handleEditDoctorsConsultation = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);

    setIsProcessCancel(true);
    axios
      .post("or/edit/chart/doctorsconsultation", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setEditOrder({ data: null, dialog: false });
          getDoctorsConsultationChartTable();
          Notify.successRequest("doctor consultation cancel");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  const handleAddDoctorsConsultation = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("ccdcf_id", ccdcf_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);
    formdata.set("trace_number", details.trace_number);

    setIsProcessCancel(true);
    axios
      .post("or/create/chart/doctorsconsultationtable", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setAddToTable(false);
          getDoctorsConsultationChartTable();
          Notify.successRequest("doctor consultation created");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  useEffect(() => {
    getDoctorsConsultationChart();
    getDoctorsConsultationChartTable();
  }, [
    patient_id,
    getDoctorsConsultationChart,
    getDoctorsConsultationChartTable,
  ]);

  return (
    <Box>
      <Card ref={printableRef}>
        <Box m={2}>
          <Box>
            <FormHeader />
          </Box>
          <Box>
            <form onSubmit={handleSubmitDoctorsConsultationChart}>
              <Box my={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          disabled={isProcess}
                          color="primary"
                          startIcon={<CheckSquare />}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            SET
                          </Button>
                        </Box>
                      )}

                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box align="center" mb={2}>
                <Typography>
                  <b> DOCTOR'S CONSULTATION </b>
                </Typography>
              </Box>

              {existingDoctorsConsultationChart.ready && (
                <>
                  <Table size="small" className="table-bordered">
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Name of Patient:
                            </span>{" "}
                            {!enableEdit &&
                              (info
                                ? `${info.lastname}, ${info.firstname} ${
                                    info.middle === null ? "" : info.middle
                                  }`
                                : "")}
                          </Typography>

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="name"
                                defaultValue={
                                  info
                                    ? `${info.lastname}, ${info.firstname} ${
                                        info.middle === null ? "" : info.middle
                                      }`
                                    : ""
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                              />

                              <TextField
                                fullWidth
                                name="ccdc_id"
                                defaultValue={
                                  existingDoctorsConsultationChart.data.length >
                                  0
                                    ? existingDoctorsConsultationChart.data[0]
                                        .ccdc_id
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                hidden
                              />
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Age:
                            </span>{" "}
                            {!enableEdit &&
                              (info
                                ? info.birthday === null
                                  ? ""
                                  : Notify.calculateAge(info.birthday)
                                : "")}
                          </Typography>

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="age"
                                defaultValue={
                                  info
                                    ? info.birthday === null
                                      ? ""
                                      : Notify.calculateAge(info.birthday)
                                    : ""
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Address:
                            </span>{" "}
                            {!enableEdit &&
                              (info
                                ? `${info.street}, ${info.barangay} ${info.city}`
                                : "")}
                          </Typography>

                          <Typography>
                            {enableEdit && (
                              <>
                                <TextField
                                  fullWidth
                                  name="address"
                                  defaultValue={
                                    info
                                      ? `${info.street}, ${info.barangay} ${info.city}`
                                      : ""
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </>
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Sex:
                            </span>{" "}
                            {!enableEdit && (info ? `${info.gender}` : "")}
                          </Typography>

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="sex"
                                defaultValue={info ? `${info.gender}` : ""}
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Telephone/Mobile number:
                            </span>{" "}
                            {!enableEdit && (info ? `${info.mobile}` : "")}
                          </Typography>

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="telephone"
                                defaultValue={info ? `${info.mobile}` : ""}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Diagnosis:
                            </span>{" "}
                            {!enableEdit
                              ? existingDoctorsConsultationChart.data.length > 0
                                ? existingDoctorsConsultationChart.data[0]
                                    .diagnosis
                                : ""
                              : null}
                          </Typography>
                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="diagnosis"
                                defaultValue={
                                  existingDoctorsConsultationChart.data.length >
                                  0
                                    ? existingDoctorsConsultationChart.data[0]
                                        .diagnosis
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Operation:
                            </span>{" "}
                            {!enableEdit
                              ? existingDoctorsConsultationChart.data.length > 0
                                ? existingDoctorsConsultationChart.data[0]
                                    .operation
                                : ""
                              : null}
                          </Typography>
                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="operation"
                                defaultValue={
                                  existingDoctorsConsultationChart.data.length >
                                  0
                                    ? existingDoctorsConsultationChart.data[0]
                                        .operation
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Referred by:
                            </span>{" "}
                            {!enableEdit
                              ? existingDoctorsConsultationChart.data.length > 0
                                ? existingDoctorsConsultationChart.data[0]
                                    .referred_by
                                : ""
                              : null}
                          </Typography>
                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="referred_by"
                                defaultValue={
                                  existingDoctorsConsultationChart.data.length >
                                  0
                                    ? existingDoctorsConsultationChart.data[0]
                                        .referred_by
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Allergy:{" "}
                              <span style={{ color: "red" }}>
                                (Record in Red)
                              </span>
                            </span>{" "}
                            {!enableEdit
                              ? existingDoctorsConsultationChart.data.length > 0
                                ? existingDoctorsConsultationChart.data[0]
                                    .allergy
                                : ""
                              : null}
                          </Typography>
                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="allergy"
                                defaultValue={
                                  existingDoctorsConsultationChart.data.length >
                                  0
                                    ? existingDoctorsConsultationChart.data[0]
                                        .allergy
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <Box
                    mt={1}
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    {!enableEdit &&
                      existingDoctorsConsultationChart.data.length > 0 && (
                        <Typography variant="subtitle2">
                          <span style={{ fontWeight: "bold" }}>
                            &bull;{" "}
                            {existingDoctorsConsultationChart.data[0]
                              .blank_text !== null
                              ? existingDoctorsConsultationChart.data[0]
                                  .blank_text
                              : ""}
                          </span>
                        </Typography>
                      )}
                    {enableEdit && (
                      <TextField
                        fullWidth
                        name="blank_text"
                        defaultValue={
                          existingDoctorsConsultationChart.data.length > 0
                            ? existingDoctorsConsultationChart.data[0]
                                .blank_text
                            : ""
                        }
                        InputProps={{
                          disableUnderline: !enableEdit,
                          disabled: !enableEdit,
                        }}
                      />
                    )}
                  </Box>

                  <Box mt={1} />
                  <Box display={"flex"} hidden={!enableEdit}>
                    <Box flexGrow={1} />
                    <Box mx={1}>
                      <Button
                        color="primary"
                        onClick={addField}
                        startIcon={<AddCircleOutline />}
                      >
                        ADD
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        color="secondary"
                        onClick={
                          _inputNewDoctorsConsultation.data.length > 0
                            ? () =>
                                removeField(
                                  1,
                                  _inputNewDoctorsConsultation.data.length
                                )
                            : (e) => e.preventDefault()
                        }
                        startIcon={<Cancel />}
                      >
                        REMOVE
                      </Button>
                    </Box>
                  </Box>

                  <Box
                    display={"flex"}
                    className="d-print-none"
                    hidden={enableEdit}
                  >
                    <Box flexGrow={1} />
                    <Box>
                      <IconButton
                        color={"primary"}
                        onClick={() => setAddToTable(true)}
                      >
                        <Add fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table className="table-bordered" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <strong>DATE</strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong>CONSULTATION NOTES</strong>
                          </TableCell>
                          <TableCell
                            align="center"
                            className="d-print-none"
                            hidden={enableEdit}
                          >
                            <strong>ACTION</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {enableEdit &&
                          _inputNewDoctorsConsultation.ready &&
                          _inputNewDoctorsConsultation.data.map(
                            (data, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>
                                    <TextField
                                      name={data.date}
                                      type="date"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      fullWidth
                                      required
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      fullWidth
                                      name={data.consultation_notes}
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}

                        {!enableEdit &&
                          existingDoctorsConsultationChartTable.ready &&
                          existingDoctorsConsultationChartTable.data.map(
                            (data, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Typography variant="subtitle2">
                                    {existingDoctorsConsultationChartTable.data
                                      .length > 0
                                      ? `${Notify.createdAt(data.date)}`
                                      : ""}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {data.consultation_notes}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="d-print-none"
                                >
                                  <Box
                                    display={"flex"}
                                    justifyContent={"center"}
                                  >
                                    <Box>
                                      <IconButton
                                        color={"primary"}
                                        onClick={() =>
                                          setEditOrder({
                                            data: data,
                                            dialog: true,
                                          })
                                        }
                                      >
                                        <BorderColor fontSize={"small"} />
                                      </IconButton>
                                    </Box>
                                    <Box>
                                      <IconButton
                                        color={"secondary"}
                                        onClick={() =>
                                          setCancelOrder({
                                            data: data,
                                            dialog: true,
                                          })
                                        }
                                      >
                                        <Delete fontSize={"small"} />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </form>
          </Box>
        </Box>
      </Card>

      <Dialog
        open={cancelOrder.dialog}
        onClose={() => setCancelOrder({ data: null, dialog: false })}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
      >
        {cancelOrder.data && cancelOrder.dialog && (
          <form onSubmit={handleRemoveDoctorsConsultation}>
            <DialogTitle>Are you sure to remove this data?</DialogTitle>
            <TextField
              label={"Monitor ID"}
              variant="outlined"
              name="ccdcft_id"
              defaultValue={cancelOrder.data.ccdcft_id}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              hidden
            />
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setCancelOrder({ data: null, dialog: false })}
              >
                No
              </Button>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isprocessCancel}
                startIcon={
                  isprocessCancel && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                Yes
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      <Dialog
        open={editOrder.dialog}
        onClose={() => setEditOrder({ data: null, dialog: false })}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
      >
        {editOrder.data && editOrder.dialog && (
          <form onSubmit={handleEditDoctorsConsultation}>
            <DialogTitle>Edit note</DialogTitle>
            <DialogContent>
              <Box mb={2}>
                <TextField
                  label={"Monitor ID"}
                  variant="outlined"
                  name="ccdcft_id"
                  defaultValue={editOrder.data.ccdcft_id}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  hidden
                />

                <TextField
                  label={"Date"}
                  name="date"
                  defaultValue={
                    editOrder.data.date !== null ? editOrder.data.date : ""
                  }
                  // name={data.date}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label={"Consultation Note"}
                  variant="outlined"
                  name="consultation_notes"
                  defaultValue={
                    editOrder.data.consultation_notes !== null
                      ? editOrder.data.consultation_notes
                      : ""
                  }
                  fullWidth
                  multiline
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setEditOrder({ data: null, dialog: false })}
              >
                No
              </Button>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isprocessCancel}
                startIcon={
                  isprocessCancel && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                Yes
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      <Dialog
        open={addToTable}
        onClose={() => setAddToTable(false)}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
      >
        <form onSubmit={handleAddDoctorsConsultation}>
          <DialogTitle>Add consultation note</DialogTitle>
          <DialogContent>
            <Box display={"flex"}>
              <Box flexGrow={1} />
              <Box mx={1}>
                <Button
                  color="primary"
                  onClick={addAdditional}
                  startIcon={<AddCircleOutline />}
                >
                  Add
                </Button>
              </Box>
              <Box>
                <Button
                  color="secondary"
                  onClick={
                    _addNewDocConsultation.data.length > 0
                      ? () =>
                          removeAdditional(
                            1,
                            _addNewDocConsultation.data.length
                          )
                      : (e) => e.preventDefault()
                  }
                  startIcon={<Cancel />}
                >
                  Remove
                </Button>
              </Box>
            </Box>
            {_addNewDocConsultation.ready &&
              _addNewDocConsultation.data.map((data, index) => {
                return (
                  <Box border={1} p={1} borderRadius={5} mb={2}>
                    <Box mb={1}>
                      <TextField
                        label={`New Date #${index + 1}`}
                        variant="outlined"
                        name={data.date}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          disableUnderline: !enableEdit,
                        }}
                        fullWidth
                        required
                      />
                    </Box>
                    <Box>
                      <TextField
                        label={`New Consultation Note #${index + 1}`}
                        variant="outlined"
                        name={data.consultation_notes}
                        fullWidth
                        InputProps={{
                          disableUnderline: !enableEdit,
                        }}
                        multiline
                        required
                      />
                    </Box>
                  </Box>
                );
              })}
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setAddToTable(false)}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isprocessCancel}
              startIcon={
                isprocessCancel && (
                  <CircularProgress size={20} color="inherit" />
                )
              }
            >
              Yes
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default FormEleven;
