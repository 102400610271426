import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PatientProfileOverview from "src/cis-doctor/components/PatientProfileOverview";
import CisPatientListItem from "src/cis/components/CisPatientListItem";
import PatientProfilePage from "src/cis/components/PatientProfilePage";
import ButtonV3 from "src/components/ButtonV3";
import FlatIcon from "src/components/FlatIcon";
import LayoutContainer from "src/components/LayoutContainer";
import useDataTable from "src/helpers/useDataTable";
import BhsPatientProfile from "./BhsPatientProfile";
import RhuPatientProfile from "./RhuPatientProfile";
import HealthUnitDetails from "./components/HealthUnitDetails";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Pagination from "src/components/table/Pagination";

import { v4 as uuidv4 } from "uuid";

let first_key = uuidv4();
const RhuPatients = () => {
	const history = useHistory();
	const { users } = useContext(UsersData);
	const {
		data,
		meta,
		setPage,
		loading,
		setPaginate,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
		page,
		keyword,
		setKeyword,
		paginate,
	} = useDataTable(`v1/patients`, null, {});
	const [key, setKey] = useState(first_key);
	const [list, setList] = useState([]);
	const [selected, setSelected] = useState(null);
	const [selectedClick, setSelectedClick] = useState(null);
	const [pendings, setPendings] = useState(null);
	const [pendings3, setPendings3] = useState(null);
	const [pendings4, setPendings4] = useState(null);

	const getPendingLabReading = () => {
		axios
			.get(`/v1/clinic/doctor-pending-for-read-lab-result`)
			.then((res) => {
				console.log("resssss", res.data.data);
				setPendings3(res.data);
			});
	};

	const getPendingForConfirmation = () => {
		axios.get(`/v1/clinic/doctor-pending-for-confirmation`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};
	const getPendingForAcceptance = () => {
		axios.get(`/v1/clinic/nurse-pending-for-acceptance`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	const getXrayPending = () => {
		axios.get(`/v1/clinic/xray-pending-result`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	const getLabPending = () => {
		axios.get(`/v1/clinic/lab-order-pending-result`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};
	const [pendings2, setPendings2] = useState(null);

	const getPendingForConsultation = () => {
		axios.get(`/v1/clinic/doctor-pending-for-consultation`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings2(res.data);
		});
	};

	const getPendingForCashier = () => {
		axios.get(`/v1/clinic/pending-cashier`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	const getPendingForBilling = () => {
		axios.get(`/v1/clinic/pending-billing`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	const getPharamaMedlRelease = () => {
		axios
			.get(`/v1/clinic/pharmacy-pending-medicine-release`)
			.then((res) => {
				console.log("resssss", res.data.data);
				setPendings4(res.data);
			});
	};

	useEffect(() => {
		if (users?.type == "RHU-DOCTOR" || users?.type == "SPH-DOCTOR") {
			getPendingForConfirmation();
			getPendingForConsultation();
			getPendingLabReading();
		}

		if (users?.type == "RHU-PHARMACY" || users?.type == "SPH-PHAR") {
			getPharamaSignalRelease();
			getPharamaMedlRelease();
		}
		if (users?.type == "RHU-NURSE" || users?.type == "SPH-NURSE") {
			getPendingForAcceptance();
		}
		if (users?.type == "RHU-LAB" || users?.type == "SPH-LAB") {
			getLabPending();
		}
		if (users?.type == "RHU-XRAY" || users?.type == "SPH-XRAY") {
			getXrayPending();
		}

		if (users?.type == "SPH-BILLING") {
			getPendingForBilling();
		}
		if (users?.type == "SPH-CASHIER") {
			getPendingForCashier();
		}
	}, []);

	const getPharamaSignalRelease = () => {
		axios
			.get(`/v1/clinic/pharmacy-pending-signal-for-release`)
			.then((res) => {
				console.log("resssss", res.data.data);
				setPendings(res.data);
			});
	};
	useEffect(() => {
		let t = setInterval(() => {
			if (users?.type == "RHU-DOCTOR" || users?.type == "SPH-DOCTOR") {
				getPendingForConfirmation();
				getPendingForConsultation();
				getPendingLabReading();
			}
			if (users?.type == "RHU-PHARMACY" || users?.type == "SPH-PHAR") {
				getPharamaSignalRelease();
				getPharamaMedlRelease();
			}
			if (users?.type == "RHU-NURSE" || users?.type == "SPH-NURSE") {
				getPendingForAcceptance();
			}
			if (users?.type == "RHU-LAB" || users?.type == "SPH-LAB") {
				getLabPending();
			}
			if (users?.type == "RHU-XRAY" || users?.type == "SPH-XRAY") {
				getXrayPending();
			}

			if (users?.type == "SPH-BILLING") {
				getPendingForBilling();
			}
			if (users?.type == "SPH-CASHIER") {
				getPendingForCashier();
			}
		}, 5000);
		return () => {
			clearInterval(t);
		};
	}, []);

	useEffect(() => {
		setList(data?.data);
	}, [data]);

	const updatePatient = (patient) => {
		console.log("updatePatientupdatePatient,", patient);
		setSelectedClick(patient);
		setList((prevList) =>
			prevList.map((p) =>
				patient?.id == p?.id
					? {
							...p,
							...patient,
					  }
					: p
			)
		);
	};
	return (
		<LayoutContainer
			icon={<FontAwesomeIcon icon={faHome} color="white" size={"2x"} />}
			title={
				<Typography variant={window.innerWidth > 500 ? "h4" : "h5"}>
					Patients
				</Typography>
			}
			subtitle={`List of patients, patients for consultations and confirmations`}
			titleChildren={<HealthUnitDetails />}
			className={`relative`}
		>
			<div className="flex flex-col gap-2 h-[calc(100vh-204px)]">
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
					<div className="lg:col-span-3 flex flex-col gap-4 h-[calc(100vh-204px)]">
						<div className="flex items-center gap-4">
							<h3 className="font-bold text-xl mb-0 mr-auto">
								Patient List
							</h3>

							<ButtonV3
								type="success"
								onClick={() => {
									history.push(`/sph/app/cis/create-patient`);
								}}
							>
								<FlatIcon icon="rr-plus" />
								Add
							</ButtonV3>
						</div>

						{pendings4?.count > 0 ? (
							<div className="flex flex-col bg-green-50 p-3 -mx-4 gap-y-2 mb-0 rounded-xl">
								<div className="text-orange-600 font-semibold text-sm flex items-center gap-3 ">
									<span>
										Patients pending for medicine release
									</span>
									<div
										className={`relative h-full ${
											pendings4?.count == 0 && hidden
										}`}
									>
										<div className="bg-red-500 rounded-full z-20 absolute text-white text-xs h-5  w-5 flex items-center justify-center">
											{pendings4?.count}
										</div>

										<div className="bg-red-500 rounded-full z-10 absolute text-white text-xs h-5  w-5 flex items-center justify-center animate-ping"></div>
									</div>
								</div>
								{pendings4?.data?.map((item) => {
									return (
										<CisPatientListItem
											key={`cpili-${item?.id}`}
											patient={item?.patient}
											onClick={() => {
												setSelectedClick(null);
												setTimeout(() => {
													setSelectedClick(
														item?.patient
													);
												}, 100);
											}}
											onMouseEnter={() => {
												setSelected(item?.patient);
											}}
											onMouseLeave={() => {
												setSelected(null);
											}}
										/>
									);
								})}
							</div>
						) : (
							""
						)}
						{pendings3?.count > 0 ? (
							<div className="flex flex-col bg-indigo-50 p-3 -mx-4 gap-y-2 mb-0 rounded-xl">
								<div className="text-orange-600 font-semibold text-sm flex items-center gap-3 ">
									<span>
										Patients pending for result reading
									</span>
									<div
										className={`relative h-full ${
											pendings3?.count == 0 && hidden
										}`}
									>
										<div className="bg-red-500 rounded-full z-20 absolute text-white text-xs h-5  w-5 flex items-center justify-center">
											{pendings3?.count}
										</div>

										<div className="bg-red-500 rounded-full z-10 absolute text-white text-xs h-5  w-5 flex items-center justify-center animate-ping"></div>
									</div>
								</div>
								{pendings3?.data?.map((item) => {
									return (
										<CisPatientListItem
											key={`cpili-${item?.id}`}
											patient={item?.patient}
											onClick={() => {
												setSelectedClick(item?.patient);
											}}
											onMouseEnter={() => {
												setSelected(item?.patient);
											}}
											onMouseLeave={() => {
												setSelected(null);
											}}
										/>
									);
								})}
							</div>
						) : (
							""
						)}
						{pendings?.count > 0 ? (
							<div className="flex flex-col bg-yellow-50 p-3 -mx-4 gap-y-2 mb-0 rounded-xl">
								<div className="text-orange-600 font-semibold text-sm flex items-center gap-3 ">
									<span>
										Patients pending for{" "}
										{users?.type == "RHU-DOCTOR"
											? "confirmation list"
											: ""}
										{users?.type == "RHU-PHARMACY"
											? "releasing medicine"
											: ""}
										{users?.type == "RHU-NURSE" ||
										users?.type == "SPH-NURSE"
											? "acceptance"
											: ""}
										{users?.type == "RHU-LAB"
											? "lab order"
											: ""}
										{users?.type == "RHU-XRAY"
											? "x-ray"
											: ""}
									</span>
									<div
										className={`relative h-full ${
											pendings?.count == 0 && hidden
										}`}
									>
										<div className="bg-red-500 rounded-full z-20 absolute text-white text-xs h-5  w-5 flex items-center justify-center">
											{pendings?.count}
										</div>

										<div className="bg-red-500 rounded-full z-10 absolute text-white text-xs h-5  w-5 flex items-center justify-center animate-ping"></div>
									</div>
								</div>
								{pendings?.data?.map((item) => {
									return (
										<CisPatientListItem
											key={`cpili-${item?.id}`}
											patient={item?.patient}
											onClick={() => {
												setSelectedClick(item?.patient);
											}}
											onMouseEnter={() => {
												setSelected(item?.patient);
											}}
											onMouseLeave={() => {
												setSelected(null);
											}}
										/>
									);
								})}
							</div>
						) : (
							""
						)}
						{pendings2?.count > 0 ? (
							<div className="flex flex-col bg-green-50 p-3 -mx-4 gap-y-2 mb-0 rounded-xl">
								<div className="text-orange-600 font-semibold text-sm flex items-center gap-3 ">
									<span>
										Patients pending for consultation
									</span>
									<div
										className={`relative h-full ${
											pendings2?.count == 0 && hidden
										}`}
									>
										<div className="bg-red-500 rounded-full z-20 absolute text-white text-xs h-5  w-5 flex items-center justify-center">
											{pendings2?.count}
										</div>

										<div className="bg-red-500 rounded-full z-10 absolute text-white text-xs h-5  w-5 flex items-center justify-center animate-ping"></div>
									</div>
								</div>
								{pendings2?.data?.map((item) => {
									return (
										<CisPatientListItem
											key={`cpili-${item?.id}`}
											patient={item?.patient}
											onClick={() => {
												setSelectedClick(item?.patient);
											}}
											onMouseEnter={() => {
												setSelected(item?.patient);
											}}
											onMouseLeave={() => {
												setSelected(null);
											}}
										/>
									);
								})}
							</div>
						) : (
							""
						)}

						<div className="relative w-full flex items-center">
							<input
								type="text"
								placeholder="Search patient"
								className="bg-white rounded-xl !border !border-slate-300 py-2 pr-3 h-11 w-full pl-[48px] !duraton-200 text-sm"
								onChange={(e) => {
									setKeyword(e.target.value);
								}}
							/>
							<span className="absolute left-3 text-slate-300 text-base font-bold pr-2 !border-r flex items-center">
								<FlatIcon icon="rr-search" className=" " />
							</span>
						</div>

						<div className="flex flex-col gap-3 divide-y max-h-[calc(100vh-274)] overflow-auto">
							{loading ? (
								<div className="p-5 flex items-center justify-center bg-foreground animate-pulse rounded-xl">
									<b className="text-placeholder">
										Loading...
									</b>
								</div>
							) : list?.length == 0 ? (
								keyword?.length > 0 ? (
									<div className="p-5 flex items-center justify-center">
										<b className="text-placeholder">
											No results found for "{keyword}".
										</b>
									</div>
								) : (
									<div className="p-5 flex items-center justify-center">
										<b className="text-placeholder">
											No data to display.
										</b>
									</div>
								)
							) : (
								list?.map((x) => {
									return (
										<CisPatientListItem
											patient={x}
											onClick={() => {
												setTimeout(() => {
													setSelectedClick(x);
												}, 100);
											}}
											onMouseEnter={() => {
												setSelected(x);
											}}
											onMouseLeave={() => {
												setSelected(null);
											}}
										/>
									);
								})
							)}
						</div>
						<div className="">
							<Pagination
								page={page}
								setPage={setPage}
								pageCount={meta?.last_page}
								pageSize={paginate}
								setPageSize={setPaginate}
								paginationClassName="flex !flex-col !items-start"
							/>
						</div>
					</div>
					<div className="lg:col-span-9">
						{console.log(`RhuPatientProfile-${selectedClick?.id}`)}
						{selectedClick ? (
							<RhuPatientProfile
								key={`RhuPatientProfile-${selectedClick?.id}`}
								patient={selectedClick}
								setPatient={updatePatient}
							/>
						) : selected == null ? (
							<div className="p-5 flex items-center justify-center text-placeholder-dark text-lg">
								{loading
									? "Loading, please wait..."
									: `Hover a patient to display overview.`}
							</div>
						) : (
							<PatientProfileOverview patient={selected} />
						)}
					</div>
				</div>
			</div>{" "}
		</LayoutContainer>
	);
};

export default RhuPatients;
