import React, { Fragment } from "react";
import Container from "src/layout/Container";
import PatientForAdmit from "./patient-foradmit";

const AdmittingDashboard = () => {
  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Patients For Admit",
          items: [{ name: "Dashboard", path: "/sph/app" }],
        }}
        title="Patients For Admit"
      >
        <PatientForAdmit />
      </Container>
    </Fragment>
  );
};

export default AdmittingDashboard;
