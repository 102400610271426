import { TextField, useForkRef } from "@material-ui/core";
import axios from "axios";
import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import { Plus, Trash2 } from "react-feather";
import { useForm } from "react-hook-form";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import Img from "src/components/Img";
import InfoText from "src/components/InfoText";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import Notify from "src/notification/Notify";
import { relationship_to_hh_options } from "./Forms/household/patient_choices";
import _ from "lodash";
const SelectRelationShip = ({ selected, register, errors, id }) => {
	return (
		<TextField
			className="lg:w-[256px]"
			SelectProps={{ native: true, disabled: !selected }}
			InputLabelProps={{ shrink: true }}
			label={<>Relation to Household Head:</>}
			variant="standard"
			{...register(`head_relation.${id}`)}
			error={errors?.head_relation}
			helperText={errors?.head_relation && "This field is required"}
			select
		>
			{relationship_to_hh_options.map((item) => {
				return <option value={item?.value}>{item?.label}</option>;
			})}
		</TextField>
	);
};
const MemberItem = ({
	register,
	errors,
	member,
	onSelect,
	selected,
	index,
}) => {
	return (
		<div
			className={`flex flex-col items-center lg:flex-row p-3 rounded-lg border bg-foreground gap-3 ${
				selected
					? "border border-primary bg-opacity-100"
					: "bg-opacity-0"
			}`}
		>
			<Img
				type="user"
				name={`${member?.lastname}-${member?.firstname}-${member?.middle}`}
				src={member?.avatar || ""}
				className=" h-[56px] w-[56px] min-h-[56px] min-w-[56px] rounded-xl"
				alt=""
				key={`key-${member?.id}-${member?.avatarBase64}`}
			/>
			<div className="flex flex-col w-full gap-2">
				<b className="text-base font-bold">{`${member?.lastname}, ${member?.firstname} ${member?.middle}`}</b>
				<SelectRelationShip
					register={register}
					errors={errors}
					index={index}
					selected={selected}
					id={member?.id}
				/>
			</div>
			<ActionBtn
				type={selected ? "danger" : "primary"}
				className="ml-auto"
				size="sm"
				onClick={onSelect}
			>
				{selected ? (
					<Trash2 className="w-4 mr-2" />
				) : (
					<Plus className="w-4 mr-2" />
				)}{" "}
				{selected ? "Unselect" : "Select"}
			</ActionBtn>
		</div>
	);
};

const SelectMemberModal = (props, ref) => {
	const { household, setHousehold } = props;
	const [open, setOpen] = useState(false);
	const [patients, setPatients] = useState([]);
	const [selectedPatients, setSelectedPatients] = useState([]);
	const [list, setList] = useState([]);
	const {
		control,
		register,
		setValue,
		formState: { errors },
		handleSubmit,
	} = useForm({
		defaultValues: {
			head_relation: [],
		},
	});

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));
	const getPatients = () => {
		axios.get("v1/patients?no_household=yes").then((res) => {
			setPatients(res.data.data);
		});
	};
	const show = (data = []) => {
		console.log("setslected", data);
		setSelectedPatients(data);
		setOpen(true);
		getPatients();
	};
	const hide = () => {
		setOpen(false);
	};
	const isSelected = (patient) => {
		return selectedPatients.find((x) => x.id == patient?.id) ? true : false;
	};
	const onSelect = (patient) => {
		if (isSelected(patient)) {
			setSelectedPatients((current) =>
				current.filter((x) => x.id != patient?.id)
			);
		} else {
			setSelectedPatients((current) => [...current, patient]);
		}
	};
	const save = (data) => {
		console.log("save datadata, ", data);
		const formData = new FormData();
		selectedPatients.map((item, index) => {
			formData.append("patient_ids[]", item?.id);
			formData.append("head_relation[]", data?.head_relation[item?.id]);
		});
		formData.append("_method", "PATCH");
		axios
			.post(`v1/household-members/${household?.id}`, formData)
			.then((res) => {
				setHousehold((current_household) => ({
					...current_household,
					members: [
						...selectedPatients.map((x, index) => ({
							...x,
							head_relation: data?.head_relation[x.id],
						})),
					],
				}));

				Notify.customToast(
					"success",
					"Household members updated successfully!"
				);
				hide();
			});
	};

	useEffect(() => {
		let timeout = setTimeout(() => {
			let _members = [...patients, ...household?.members];
			console.log("memberrrrr", _members);
			setList(_.uniqBy(_members, "id"));
			setTimeout(() => {
				_members.map((m, index) => {
					setValue(`head_relation.${m?.id}`, m?.head_relation);
				});
			}, 200);
		}, 100);
		return () => {
			clearTimeout(timeout);
		};
	}, [selectedPatients, patients]);
	return (
		<Modal open={open} hide={hide} size="md2" className="max-w-4xl">
			<ModalHeader
				title={"Select member to add"}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody
				className={`!p-4 flex flex-col gap-y-4 bg-white min-h-[448px] max-h-[448px] overflow-auto`}
			>
				{/* <MemberItem selected={true} />
				<MemberItem selected={false} />
				<MemberItem />
				<MemberItem /> */}
				{list?.map((patient, index) => {
					return (
						<MemberItem
							onSelect={() => {
								onSelect(patient);
							}}
							selected={isSelected(patient)}
							member={patient}
							key={`paitent-${patient?.id}`}
							register={register}
							errors={errors}
							index={index}
						/>
					);
				})}
			</ModalBody>
			<ModalFooter className={`flex justify-end`}>
				<ActionBtn
					className="ml-auto"
					type="success"
					onClick={handleSubmit(save)}
				>
					Select and continue
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(SelectMemberModal);
