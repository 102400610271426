import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";

import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import VitalsForm from "src/clinic/components/VitalsForm";
import { calculateAge } from "src/helpers/utils";

const billing_data = [
	{
		particulars: "Drugs and Medicine (GF)",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Laboratory Examination",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Miscellaneous",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "NonDrugs / Supplies",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "PHIC",
		debit: 0.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Radiology",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Room and Board",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
];

const PatientBillCashierModal = (props, ref) => {
	const { patient, onSuccess } = props;
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(null);
	const [loading, setLoading] = useState(false);
	const [prescriptionItems, setPrescriptionItems] = useState([]);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const submit = (data) => {
		setLoading(true);
		let formData = new FormData();
		formData.append("appointment_id", appointment?.id);
		formData.append("_method", "PATCH");

		axios
			.post(`/v1/clinic/mark-as-paid/${appointment?.id}`, formData)
			.then((res) => {
				// addToList(data);
				setTimeout(() => {
					setLoading(false);
					onSuccess();
					toast.success("Patient successfully referred to SPH!");
				}, 400);
				hide();
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const getSumObj = (objName) => {
		let total = 0;
		billing_data?.map((data) => {
			total += data[objName];
		});
		return total;
	};

	return (
		<Modal open={open} hide={hide} className="min-w-[768px] w-full">
			<ModalHeader
				title={`Patient Billing`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
					<div className="lg:col-span-2 grid grid-cols-1 lg:grid-cols-3">
						<div className="lg:col-span-3 font-medium mb-1">
							Patient name
						</div>
						<div className="font-semibold flex flex-col items-center justify-center text-center text-lg ">
							&nbsp;{patient?.lastname}&nbsp;
							<span className="border-t w-2/3 font-normal text-slate-400 text-sm">
								(Surname)
							</span>
						</div>
						<div className="font-semibold flex flex-col items-center justify-center text-center text-lg ">
							&nbsp;{patient?.firstname}&nbsp;
							<span className="border-t w-2/3 font-normal text-slate-400 text-sm">
								(First name)
							</span>
						</div>
						<div className="font-semibold flex flex-col items-center justify-center text-center text-lg ">
							&nbsp;{patient?.middlename}&nbsp;
							<span className="border-t w-2/3 font-normal text-slate-400 text-sm">
								(Middle name)
							</span>
						</div>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2">
						<div className="lg:col-span-3 font-medium mb-1">
							&nbsp; &nbsp;
						</div>
						<div className="font-semibold flex flex-col items-center justify-center text-center text-lg ">
							&nbsp;{calculateAge(patient?.birthday)}&nbsp;
							<span className="border-t w-2/3 font-normal text-slate-400 text-sm">
								Age
							</span>
						</div>
						<div className="font-semibold flex flex-col items-center justify-center text-center text-lg ">
							&nbsp;{patient?.gender}&nbsp;
							<span className="border-t w-2/3 font-normal text-slate-400 text-sm">
								Sex
							</span>
						</div>
					</div>
					<div className="lg:col-span-3 flex flex-col">
						<h3 className="font-medium text-sm">Patient Address</h3>
						<span className="font-bold pl-5 text-lg">
							{`${
								patient?.municipality
									? patient?.municipality + ", "
									: ""
							} 
						${patient?.city ? patient?.city + ", " : ""} 
						${patient?.barangay ? patient?.barangay + ", " : ""} 
						${patient?.street}`}
						</span>
					</div>
				</div>
				<div className="flex flex-col gap-y-4 w-full pt-3">
					<h3 className="text-lg font-bold">SUMMARY OF CHARGES</h3>
					<div className="table">
						<table>
							<thead>
								<tr>
									<th className="text-center">PARTICULARS</th>
									<th className="text-center">DEBIT</th>
									<th className="text-center">DISCOUNT</th>
									<th className="text-center">CREDIT</th>
									<th className="text-center">BALANCE</th>
								</tr>
							</thead>
							<tbody>
								{billing_data?.map((data) => {
									return (
										<tr
											key={`bill-data-${data?.particulars}`}
										>
											<td className="text-left">
												{data?.particulars}
											</td>
											<td className="text-right">
												{data?.debit}
											</td>
											<td className="text-right">
												{data?.discount}
											</td>
											<td className="text-right">
												{data?.credit}
											</td>
											<td className="text-right">
												{data?.balance}
											</td>
										</tr>
									);
								})}
							</tbody>
							<tfoot>
								<tr>
									<th className="text-right">
										<b>TOTAL:</b>
									</th>
									<th className="text-right">
										{getSumObj("debit")}
									</th>
									<th className="text-right">
										{getSumObj("discount")}
									</th>
									<th className="text-right">
										{getSumObj("credit")}
									</th>
									<th className="text-right">
										{getSumObj("balance")}
									</th>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center"}>
				<ActionBtn
					className="mr-auto"
					type="danger"
					onClick={hide}
					disabled={loading}
				>
					No
				</ActionBtn>
				<ActionBtn
					className="ml-auto"
					type="success"
					onClick={handleSubmit(submit)}
					loading={loading}
				>
					Mark patient as paid
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(PatientBillCashierModal);
