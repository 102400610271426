import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActionBtn from "src/components/ActionBtn";
import ButtonV3 from "src/components/ButtonV3";
import useDataTable from "src/helpers/useDataTable";
import LMISItemOrderList from "src/pho/lmis/LMISItemOrderList";
import ItemServiceList from "./ItemServiceList";
import SelectInputField from "src/components/forms/SelectInputField";
import { UsersData } from "src/ContextAPI";
import Clock from "react-live-clock";
import axios from "axios";
import {
	dateMMDDYYYY,
	dateMMDDYYYYHHIIA,
	dateYYYYMMDD,
	formatDateMMDDYYYYHHIIA,
} from "src/helpers/utils";
import { toast } from "react-toastify";
import PrescriptionForm from "src/cis/components/patient-profile/prescriptions/PrescriptionForm";
import PrescriptionList from "src/doctor/patients/prescription/PrescriptionList";
import DateTimeClock from "src/clinic/components/DateTimeClock";
import SelectItemsSelector from "./SelectItemsSelector";
import SelectPrescriptionItems from "./SelectPrescriptionItems";

const RhuPrescription = ({ patient }) => {
	const history = useHistory();

	const {
		data,
		setPage,
		loading,
		setPaginate,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
		keyword,
		setKeyword,
	} = useDataTable(`v1/clinic/appointments/${patient?.id}`, null, {});

	const { users } = useContext(UsersData);
	const [presciptionItems, setPrescriptionItems] = useState([]);
	console.log("presciptionItems", presciptionItems);
	return (
		<div className="bg-red- p-4">
			<div className="flex items-center mb-3">
				<h5 className="font-bold mb-0 mr-4">Prescriptions </h5>
			</div>

			<div className="flex flex-col gap-y-4 relative">
				<span className="border-l-4 border-l-slate-200 absolute left-[20px] h-full" />
				{data?.data?.map((item) => {
					if (item?.prescriptions?.length > 0)
						return (
							<div class="bg-white p-6 rounded-md shadow-lg shadow-gray-200 ml-5 relative">
								<span className="w-[12px] h-[12px] bg-blue-500 absolute -left-8" />
								<p class="text-sm font-semibold mb-2">
									{formatDateMMDDYYYYHHIIA(
										new Date(item?.created_at)
									)}
								</p>
								<ul
									className="pl-3"
									style={{ listStyleType: "disc !important" }}
								>
									{item.prescriptions?.map((prescription) => {
										return (
											<li className="!text-sm flex flex-col">
												<b>
													{prescription?.item?.name} -{" "}
													{prescription?.quantity}{" "}
													{
														prescription?.item
															?.unit_measurement
													}
												</b>
												<span className="italic">
													{prescription?.details}
												</span>
											</li>
										);
									})}
								</ul>
							</div>
						);
				})}
			</div>
		</div>
	);
};

export default RhuPrescription;
