import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAddressCard,
	faHome,
	faPersonBooth,
	faUsers,
	faBars,
	faUserLock,
	faMapMarked,
	faChartBar,
	faListUl,
	faUserInjured,
	faVirus,
	faHouseUser,
	faChartArea,
	faTruckMoving,
	faBoxes,
	faBuilding,
	faClinicMedical,
	faUserNurse,
	faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import PhoDashboard from "src/pho/PhoDashboard";
import PhoPatientList from "src/pho/PhoPatientList";
import PhoPatients from "src/pho/PhoPatients";
import PhoAnalyticsMap from "src/pho/PhoAnalyticsMap";
import Account from "src/pho/account/Account";
import PhoForm from "src/pho/components/PhoFormModal";
import PhoDiseases from "src/pho/PhoDiseases";
import PhoHouseholds from "src/pho/PhoHouseholds";
import HouseholdFormPage from "src/pho/HouseholdFormPage";
import Test from "./Test";
import PhoHouseholdMemberFormPage from "src/pho/PhoHouseholdMemberPage";
import ManagePhoUsers from "src/pho/ManagePhoUsers";
import PhoAccount from "src/pho/PhoAccount";
import PhoClinicManagement from "src/pho/clinic-management/PhoClinicManagement";
import PhoClinicPersonnel from "src/pho/clinic-personnel/PhoClinicPersonel";
import PhoClinicProfile from "src/pho/clinic-management/PhoClinicProfile";
import PhoPhilhealthForm from "src/pho/PhoPhilhealthForm";
import ItemManagement from "src/pho/items/ItemManagement";
import SupplierManagement from "src/pho/supplier/SupplierManagement";
import PhoCensus from "src/pho/PhoCensus";
import Pmrf from "src/pho/printable-forms/Pmrf";
import PhoLMISPage from "src/pho/PhoLMISPage";
import LMISConsignmentForm from "src/pho/lmis/LMISConsignmentForm";
import LMISConsignmentView from "src/pho/lmis/LMISConsignmentView";
import LMISConsignmentPrint from "src/pho/lmis/LMISConsignmentPrint";
import ItemInventory from "src/pho/items/ItemInventory";
import LMISConsigmentFormIndividual from "src/pho/lmis/LMISConsigmentFormIndividual";
import PhoPHICClaims from "src/pho/PhoPHICClaims";

const RoutePho = () => {
	const [sidebarHeader, setSidebarHeader] = useState([]);
	const userContext = useContext(UsersData);
	// console.log("userContextuserContext", userContext);
	const { users } = userContext;
	const [user, setUser] = useState(null);
	const getSidebarHeaderInfo = async () => {
		var params = { user_id: userContext.users.user_id };
		const { data } = await axios.get(
			"admitting/sidebar/header-infomartion",
			{
				params,
			}
		);
		setSidebarHeader(data);
	};

	useEffect(() => {
		getAllUsers();
	}, []);

	const getAllUsers = () => {
		axios.get("v1/pho/users").then((res) => {
			let users_ = res.data.data;
			let found = users_?.find((x) => x.user_id == users?.user_id);

			setUser(found);
		});
	};

	const forPHO =
		users?.type == "PHO-HO"
			? [
					{
						name: "Health Officers Accounts",
						show: true,
						icon: (
							<FontAwesomeIcon
								icon={faUsers}
								color="white"
								style={{ fontSize: 18 }}
							/>
						),
						path: "/sph/app/pho/users",
						subitem: [],
					},
			  ]
			: [];
	const forPhoHo =
		users?.type == "PHO-HO"
			? [
					{
						name: "PHIC Claims",
						show: true,
						icon: (
							<div className="relative h-10 w-10 -ml-[10px] flex items-center justify-center">
								<img
									src="/philhealth.png"
									className="h-[44px] w-[44px] absolute"
								/>
							</div>
						),
						path: "/sph/app/pho/phic-claims",
						subitem: [],
					},
			  ]
			: [];
	const sidebarRoute = [
		{
			name: "Dashboard",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faHome}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/dashboard",
			subitem: [],
		},

		{
			name: "Analytics",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faListUl}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/analytics",
			subitem: [
				{
					name: "List",
					path: "/sph/app/pho/analytics",
					show: true,
				},
				{
					name: "Census",
					path: "/sph/app/pho/census",
				},
			],
		},

		{
			name: "Patients",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserInjured}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/patients",
			subitem: [],
		},

		{
			name: "Map",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faMapMarked}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/analytics-map",
			subitem: [],
		},
		{
			name: "Consignment Orders",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faTruckMoving}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/lmis",
			subitem: [],
		},
		...forPHO,
		{
			name: "Households",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faHouseUser}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/households",
			subitem: [],
		},
		{
			name: "Item Management",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faBoxes}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/item-management",
			subitem: [],
		},
		{
			name: "Item Inventory",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faWarehouse}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/item-inventory",
			subitem: [],
		},
		{
			name: "Supplier Management",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faHouseUser}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/supplier-management",
			subitem: [],
		},
		{
			name: "Clinic Management",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faClinicMedical}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/clinic-management",
			subitem: [],
		},
		{
			name: "Clinic Personnel",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserNurse}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/clinic-personnel",
			subitem: [],
		},

		{
			name: "Diseases",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faVirus}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/diseases",
			subitem: [],
		},
		...forPhoHo,
		// {
		// 	name: "Census",
		// 	show: true,
		// 	icon: (
		// 		<FontAwesomeIcon
		// 			icon={faChartArea}
		// 			color="white"
		// 			style={{ fontSize: 18 }}
		// 		/>
		// 	),
		// 	path: "/sph/app/pho/census",
		// 	subitem: [],
		// },
		{
			name: "Users",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserLock}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/users",
			subitem: [],
		},
		{
			name: "Account",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserLock}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/pho/account",
			subitem: [],
		},
	];

	const handleRenderInfo = () => {
		getSidebarHeaderInfo();
	};

	useEffect(() => {
		getSidebarHeaderInfo();
		//eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<UsersHeader.Provider
				value={{
					sidebarHeader: sidebarHeader,
					renderPharmacyInfo: handleRenderInfo,
				}}
			>
				<BrowserRouter>
					<Sidebar
						notification={{
							enable: true,
							owner: "pho",
						}}
						header={{ ...sidebarHeader, name: "PHO HO" }}
						routes={sidebarRoute}
						module={users?.type?.replace("-", " ")}
					/>
					<Switch>
						<Route
							exact
							path="/"
							component={() => (
								<Redirect to="/sph/app/pho/dashboard" />
							)}
						/>
						<Route
							exact
							path="/sph/app"
							component={() => (
								<Redirect to="/sph/app/pho/dashboard" />
							)}
						/>
						<Route
							exact
							path="/sph/app/pho/dashboard"
							component={PhoDashboard}
						/>
						<Route
							exact
							path="/sph/app/pho/analytics"
							component={PhoPatientList}
						/>
						<Route
							exact
							path="/sph/app/pho/clinic-management"
							component={PhoClinicManagement}
						/>
						<Route
							exact
							path="/sph/app/pho/item-management"
							component={ItemManagement}
						/>
						<Route
							exact
							path="/sph/app/pho/item-inventory"
							component={ItemInventory}
						/>
						<Route
							exact
							path="/sph/app/pho/supplier-management"
							component={SupplierManagement}
						/>
						<Route
							exact
							path="/sph/app/pho/clinic-management/:id"
							component={PhoClinicProfile}
						/>
						<Route
							exact
							path="/sph/app/pho/clinic-personnel"
							component={PhoClinicPersonnel}
						/>
						<Route
							exact
							path="/sph/app/pho/analytics-map"
							component={PhoAnalyticsMap}
						/>
						<Route
							exact
							path="/sph/app/pho/lmis"
							component={PhoLMISPage}
						/>
						<Route
							exact
							path="/sph/app/pho/phic-claims"
							component={PhoPHICClaims}
						/>
						<Route
							exact
							path="/sph/app/pho/lmis/create"
							component={LMISConsigmentFormIndividual}
						/>
						<Route
							exact
							path="/sph/app/pho/lmis/view/:id"
							component={LMISConsignmentView}
						/>

						<Route
							exact
							path="/sph/app/pho/lmis/print/:id"
							component={LMISConsignmentPrint}
						/>

						<Route
							exact
							path="/sph/app/pho/patients"
							component={PhoPatients}
						/>

						<Route
							exact
							path="/sph/app/pho/patients/create"
							component={PhoHouseholdMemberFormPage}
						/>
						<Route
							exact
							path="/sph/app/pho/patients/:id"
							component={PhoHouseholdMemberFormPage}
						/>
						<Route
							exact
							path="/sph/app/pho/philhealth/:id"
							component={PhoPhilhealthForm}
						/>
						<Route
							exact
							path="/sph/app/pho/users"
							component={ManagePhoUsers}
						/>
						<Route
							exact
							path="/sph/app/pho/diseases"
							component={PhoDiseases}
						/>
						<Route
							exact
							path="/sph/app/pho/account"
							component={PhoAccount}
						/>
						<Route
							exact
							path="/sph/app/pho/households"
							component={PhoHouseholds}
						/>
						<Route
							exact
							path="/sph/app/pho/households/:id"
							component={HouseholdFormPage}
						/>
						<Route
							exact
							path="/sph/app/pho/create-household"
							component={HouseholdFormPage}
						/>
						<Route
							exact
							path="/sph/app/pho/census"
							component={PhoCensus}
						/>
						<Route
							exact
							path="/sph/app/pho/pmrf/:id"
							component={Pmrf}
						/>
						<Route
							exact
							path="/sph/app/pho/test"
							component={Test}
						/>
						<Route
							exact
							path="/sph/app/logout"
							component={Logout}
						/>

						<Route
							render={() => (
								<PageNotFound title="Page not found" />
							)}
						/>
					</Switch>
				</BrowserRouter>
			</UsersHeader.Provider>
		</Fragment>
	);
};

export default RoutePho;
