import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ZoomableImage from "src/utils/ZoomableImage";
import Axios from "axios";
import {
	Button,
	Card,
	CardActions,
	CardMedia,
	Grid,
	Typography,
} from "@material-ui/core";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const imageLocationOnline = process.env.REACT_APP_API_IMAGE_VIRTUAL;

const ImagingProcessedAttachment = ({ details, imagingType }) => {
	const [openLightbox, setOpenLightBox] = useState(false);
	const [imageToEnlarge, setImageToEnlarge] = useState(null);

	const token = localStorage.getItem("token");

	const setNotifAsView = async () => {
		var fd = new FormData();
		fd.append("token", token);
		fd.append("order_id", details.imaging_center_id);
		fd.append(
			"connection",
			imagingType === "local-imaging" ? "local" : "online"
		);

		await Axios.post("/doctor/setnotification/as-read", fd);
	};

	useEffect(() => {
		setNotifAsView();

		// eslint-disable-next-line
	}, []);

	const imageUrl =
		imagingType === "virtual-imaging" ? imageLocationOnline : imageLocation;
	return (
		<>
			<Row>
				<Col sm={12} className="mb-2">
					{details === null ? (
						<p className="m-0 text-danger"> No attachment </p>
					) : (
						<div className="row"></div>
					)}
				</Col>
			</Row>

			<Grid container spacing={2}>
				{details === null ? (
					<Typography color="secondary"> No attachment found. </Typography>
				) : (
					details.split(",").map((data, index) => {
						return (
							<Grid item xs={6} key={index} sm>
								<Card variant="outlined">
									<CardMedia
										component={"img"}
										src={imageUrl + "imaging/" + data}
										style={{ maxHeight: 120 }}
										alt=""
									/>
									<CardActions>
										<Button
											onClick={() => {
												setOpenLightBox(true);
												setImageToEnlarge(data);
											}}
											fullWidth
											color="primary"
										>
											view
										</Button>
									</CardActions>
								</Card>
							</Grid>
						);
					})
				)}
			</Grid>

			<ZoomableImage
				open={openLightbox}
				close={() => setOpenLightBox(false)}
				title={`Imaging Attachment`}
				image={`${imageUrl}imaging/${imageToEnlarge}`}
			/>
		</>
	);
};

export default ImagingProcessedAttachment;
