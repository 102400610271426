import {
  Typography,
  Grid,
  Box,
  Paper,
  List,
  Divider,
  TextField,
  InputAdornment,
  ListItem,
  ListItemText,
  Avatar,
  TablePagination,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useState, useCallback, useEffect } from "react";
import { UsersHeader, UsersData } from "src/ContextAPI";
import Container from "../layout/Container";
import Notify from "src/notification/Notify";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import NewPatientTriage from "./patient/NewPatientTriage";
import PatientTriageDetails from "./patient/PatientTriageDetails";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const TriageDashboard = () => {
  const classes = useStyles();
  const { sidebarHeader } = useContext(UsersHeader);
  const { users } = useContext(UsersData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPatient, setSelectedPatient] = useState("new-patient");

  const [patient, setPatient] = useState({
    data: [],
    ready: false,
  });

  const [search, setSearch] = useState("");

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const getIncompleteReg = useCallback(() => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    axios
      .get("triage/patients/getpatient-list", { params })
      .then((response) => {
        const data = response.data;
        setPatient({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users]);

  useEffect(() => {
    getIncompleteReg();
  }, [getIncompleteReg]);

  const searchable = patient.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Container
      breadcrumbs={{
        enable: false,
        current: "qwe",
        items: [],
      }}
      title={
        <>
          <Typography variant={"h4"}>Dashboard</Typography>
          <Typography variant="subtitle1">
            {" "}
            Welcome back, {sidebarHeader.name} 👋👋👋{" "}
          </Typography>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={3}>
          <Box component={Paper} variant="outlined" borderRadius={4} p={2}>
            <Box mb={2}>
              <Typography color="primary" variant="subtitle2">
                PATIENT ON TRIAGE
              </Typography>
            </Box>

            <Divider />

            <List component="div">
              <Box mb={1}>
                <TextField
                  label="Search for patient"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <ListItem
                button
                className="mb-2"
                onClick={() => setSelectedPatient("new-patient")}
                selected={selectedPatient === "new-patient"}
              >
                <ListItemIcon>
                  <Avatar>
                    <strong style={{ fontSize: 30 }}>+</strong>
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  className={`gtc-capitalize`}
                  primary={`Add New Patient`}
                />
              </ListItem>
              {patient.ready
                ? patient.data.length > 0
                  ? searchable
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <ListItem
                          key={index}
                          button
                          className="mb-2"
                          onClick={() => setSelectedPatient(data.patient_id)}
                          selected={selectedPatient === data.patient_id}
                        >
                          <ListItemIcon>
                            {data.image === null ? (
                              <Avatar className={classes.primary}>
                                {data.lastname.charAt().toUpperCase()}
                              </Avatar>
                            ) : (
                              <Avatar
                                className={classes.primary}
                                src={`${imageLocation}patients/${data.image}`}
                                alt=""
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            className={`gtc-capitalize`}
                            primary={`${data.lastname}, ${data.firstname} ${
                              Boolean(data.middle) ? data.middle : ``
                            } `}
                          />
                        </ListItem>
                      ))
                  : Notify.noRecord()
                : Notify.loading()}
            </List>
            <TablePagination
              component="div"
              count={patient.data.length}
              rowsPerPageOptions={[10, 50, 100, { label: "All", value: -1 }]}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="List"
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={8} lg={9}>
          {selectedPatient === "new-patient" && (
            <NewPatientTriage getIncompleteReg={getIncompleteReg} />
          )}
          {selectedPatient !== "new-patient" && (
            <PatientTriageDetails
              patient_id={selectedPatient}
              getIncompleteReg={getIncompleteReg}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default TriageDashboard;
