import { faColumns, faUserLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, TextField } from "@material-ui/core";
import axios from "axios";
import { set } from "nprogress";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import ImagePicker from "src/components/forms/ImagePicker";
import Img from "src/components/Img";
import LayoutContainer from "src/components/LayoutContainer";
import { UsersData } from "src/ContextAPI";
import { dataURItoBlob } from "src/helpers/utils";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import BasicInfo from "./account/BasicInfo";
import LoginInfo from "./account/LoginInfo";

const config = {
	headers: {
		"content-type": "multipart/form-data",
	},
};
const PhoAccount = () => {
	const userContext = useContext(UsersData);
	const { users: currentUserData } = userContext;

	const {
		register,
		setValue,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();
	const {
		register: password_register,
		setValue: password_setValue,
		handleSubmit: password_handleSubmit,
		reset: password_reset,
		watch: password_watch,
		formState: { errors: password_errors },
	} = useForm();
	const {
		register: username_register,
		setValue: username_setValue,
		handleSubmit: username_handleSubmit,
		reset: username_reset,
		watch: username_watch,
		formState: { errors: username_errors },
	} = useForm();
	const [avatar, setAvatar] = useState(null);
	const [user, setUser] = useState(null);
	const [savingProfile, setSavingProfile] = useState(false);
	const [savingPassword, setSavingPassword] = useState(false);
	const [savingUsername, setSavingUsername] = useState(false);

	useEffect(() => {
		getAllUsers();
	}, []);

	useEffect(() => {}, [user?.id]);

	const getAllUsers = () => {
		axios.get("v1/pho/users").then((res) => {
			let users_ = res.data.data;
			let found = users_?.find(
				(x) => x.user_id == currentUserData?.user_id
			);
			setValue("name", found?.name);
			setValue("email", found?.email);
			username_setValue("old_username", found?.username);

			setUser(found);
		});
	};
	const saveProfile = (data) => {
		setSavingProfile(true);
		console.log("saveProfile Data", data);
		let formData = new FormData();

		formData.append("_method", "PATCH");

		formData.append("name", data?.name);
		formData.append("email", data?.email);
		if (avatar) {
			const file = dataURItoBlob(avatar);
			formData.append("avatar", file);
		}

		axios
			.post("v1/profile", formData, config)
			.then((res) => {
				Notify.customToast("success", "Profile updated successfully!");
			})
			.finally(() => {
				setTimeout(() => {
					setSavingProfile(false);
				}, 400);
			});
	};
	const savePassword = (data) => {
		setSavingPassword(true);
		console.log("savePassword Data", data);

		let formData = new FormData();
		formData.append("_method", "PATCH");

		formData.append("current_password", data?.current_password);
		formData.append("password", data?.password);
		formData.append("password_confirmation", data?.password_confirmation);

		axios
			.post("v1/profile/password", formData, config)
			.then((res) => {
				Notify.customToast("success", "Password updated successfully!");
				password_reset({
					current_password: "",
					password: "",
					password_confirmation: "",
				});
			})
			.finally(() => {
				setTimeout(() => {
					setSavingPassword(false);
				}, 400);
			});
	};
	const saveUsername = (data) => {
		setSavingUsername(true);
		console.log("saveUsername Data", data);
		let formData = new FormData();
		formData.append("_method", "PATCH");

		formData.append("old_username", data?.old_username);
		formData.append("username", data?.username);
		formData.append("password", data?.password);

		axios
			.post("v1/profile/username", formData, config)
			.then((res) => {
				Notify.customToast("success", "Username updated successfully!");
				username_reset({
					username: "",
					password: "",
				});
			})
			.finally(() => {
				setTimeout(() => {
					setSavingUsername(false);
				}, 400);
			});
	};
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faUserLock}
						color="white"
						size={"2x"}
					/>
				}
				title="Account settings"
				subtitle={`Update your account information`}
				className="w-full grid grid-cols-1 lg:grid-cols-12 gap-6"
			>
				<CardContainer
					header={{ title: "Profile" }}
					containerClassName={`col-span-4`}
					className="flex flex-col justify-center items-center gap-6 p-6"
				>
					<div className="flex items-center justify-center">
						<ImagePicker
							className="rounded-full bg-primary bg-opacity-40 group-hover:bg-primary group-hover:bg-opacity-100 duration-200"
							onChange={(data) => {
								setUser((prevData) => ({
									...prevData,
									avatar: data,
								}));
								setAvatar(data);
							}}
						>
							{() => {
								return (
									<Img
										key={`avatar-${user?.avatar}`}
										src={user?.avatar}
										name={user?.name}
										type="user"
										alt=""
										className="w-[144px] h-[144px] rounded-full"
									/>
								);
							}}
						</ImagePicker>
					</div>
					<TextField
						className="w-full"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`Name`}
						variant="outlined"
						error={Boolean(errors?.name?.message)}
						helperText={errors?.name?.message}
						{...register("name", {
							required: "This field is required.",
						})}
					/>
					<TextField
						className="w-full"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`Email`}
						type="email"
						variant="outlined"
						error={Boolean(errors?.email?.message)}
						helperText={errors?.email?.message}
						{...register("email", {
							required: "This field is required.",
						})}
					/>
					<ActionBtn
						className="ml-auto"
						onClick={handleSubmit(saveProfile)}
						loading={savingProfile}
					>
						<FlatIcon icon="rr-check" className="mr-2" /> Save
						Changes
					</ActionBtn>
				</CardContainer>
				<CardContainer
					header={{ title: "Change password" }}
					containerClassName={`col-span-4`}
					className="flex flex-col justify-center items-center gap-6 p-6"
				>
					<TextField
						className="w-full"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`Current passsword`}
						variant="outlined"
						type="password"
						error={Boolean(
							password_errors?.current_password?.message
						)}
						helperText={password_errors?.current_password?.message}
						{...password_register("current_password", {
							required: "This field is required.",
						})}
					/>
					<TextField
						className="w-full"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`New password`}
						type="password"
						variant="outlined"
						error={Boolean(password_errors?.password?.message)}
						helperText={password_errors?.password?.message}
						{...password_register("password", {
							required: "This field is required.",
						})}
					/>
					<TextField
						className="w-full"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`Confirm New password`}
						type="password"
						variant="outlined"
						error={Boolean(errors?.password_confirmation?.message)}
						helperText={errors?.password_confirmation?.message}
						{...password_register("password_confirmation", {
							required: "This field is required.",
						})}
					/>
					<ActionBtn
						className="ml-auto"
						onClick={password_handleSubmit(savePassword)}
						loading={savingPassword}
					>
						<FlatIcon icon="rr-check" className="mr-2" /> Save new
						password
					</ActionBtn>
				</CardContainer>
				<CardContainer
					header={{ title: "Update username" }}
					containerClassName={`col-span-4`}
					className="flex flex-col justify-center items-center gap-6 p-6"
				>
					<TextField
						className="w-full"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`Current username`}
						variant="outlined"
						error={Boolean(username_errors?.old_username?.message)}
						helperText={username_errors?.old_username?.message}
						{...username_register("old_username", {
							required: "This field is required.",
						})}
					/>
					<TextField
						className="w-full"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`New username`}
						variant="outlined"
						error={Boolean(username_errors?.username?.message)}
						helperText={username_errors?.username?.message}
						{...username_register("username", {
							required: "This field is required.",
						})}
					/>
					<TextField
						className="w-full"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`Enter password`}
						type="password"
						variant="outlined"
						error={Boolean(username_errors?.password?.message)}
						helperText={username_errors?.password?.message}
						{...username_register("password", {
							required: "This field is required.",
						})}
					/>
					<ActionBtn
						className="ml-auto"
						onClick={username_handleSubmit(saveUsername)}
						loading={savingUsername}
					>
						<FlatIcon icon="rr-check" className="mr-2" /> Save
						username
					</ActionBtn>
				</CardContainer>
			</LayoutContainer>
		</>
	);
};

export default PhoAccount;
