import React, { useState, useEffect, Fragment, useContext } from "react";
import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Grid,
	CardMedia,
} from "@material-ui/core";
import Notify from "../../notification/Notify";
import axios from "axios";
import PrintIcon from "@material-ui/icons/Print";
import ClearIcon from "@material-ui/icons/HighlightOff";
import { UsersData } from "../../ContextAPI";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ReceiptPrintModal = ({ selectedReceipt, closeModal }) => {
	const userData = useContext(UsersData);

	const [details, setDetails] = useState({
		data: [],
		ready: false,
	});

	const getReceiptDetails = () => {
		var params = {
			user_id: userData.users.user_id,
			management_id: userData.users.management_id,
			receipt_number: selectedReceipt,
		};
		axios
			.get("pharmacy/get-receipt-info-print", { params })
			.then((response) => {
				const data = response.data;
				setDetails({
					data: data,
					ready: true,
				});
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	useEffect(() => {
		getReceiptDetails();
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			{details.ready && (
				<Box m={2} p={2}>
					<Grid container spacing={5}>
						<Grid item xs={12} sm={12}>
							<Box
								display="flex"
								justifyContent="center"
								align="center"
								alignItems="center"
								mb={1}
							>
								<Box mb={1}>
									<Typography variant="h4">
										<strong> OFFICIAL RECEIPT </strong>
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={4} sm={4}>
							<Box alignContent="center" justifyContent="center" align="center">
								{details.data[0].company_logo === null && (
									<Typography color="secondary"> No profile. </Typography>
								)}
								{details.data[0].company_logo && (
									<CardMedia
										style={{
											borderRadius: 5,
											width: 130,
											justifyContent: "center",
											alignItems: "center",
										}}
										component="img"
										src={`${imageLocation}pharmacy/${details.data[0].company_logo}`}
									/>
								)}
							</Box>
						</Grid>
						<Grid item xs={4} sm={4}>
							<Box display="flex" align="center" mb={1}>
								<Box>
									<Typography>
										Pharmacy: {details.data[0].pharmacy_name}
									</Typography>
									<Typography>Address: {details.data[0].address}</Typography>
									<Typography>TIN: {details.data[0].tin}</Typography>
									<Typography>
										Email: {details.data[0].pharmacy_email}
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={4} sm={4}>
							<Box display="flex" align="center" mb={1}>
								<Box>
									<Typography>Date: {details.data[0].created_at}</Typography>
									<Typography>Receipt: {details.data[0].dr_no}</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Box display="flex" mb={1}>
								<Box>
									<Typography>
										Sold To: {details.data[0].name_customer}
									</Typography>
									{details.data[0].address_customer !== null && (
										<Typography>
											Address: {details.data[0].address_customer}
										</Typography>
									)}
									{details.data[0].tin_customer !== null && (
										<Typography>
											TIN number: {details.data[0].tin_customer}
										</Typography>
									)}
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TableContainer component={Box}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell align="center"> Qty </TableCell>
											<TableCell align="center"> Unit </TableCell>
											<TableCell align="center"> Brand </TableCell>
											<TableCell align="center"> Unit Price </TableCell>
											<TableCell align="center"> Amount </TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{details.data.length > 0 ? (
											details.data.map((data, index) => (
												<TableRow key={index}>
													<TableCell align="right"> {data.quantity} </TableCell>
													<TableCell>{data.unit}</TableCell>
													<TableCell>{data.product}</TableCell>
													<TableCell align="right">
														{Notify.numberFormat(data.srp)}
													</TableCell>
													<TableCell align="right">
														{Notify.numberFormat(data.total)}
													</TableCell>
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell colSpan={5}>
													<Typography variant="subtitle2" color="secondary">
														{" "}
														No receipt added{" "}
													</Typography>
												</TableCell>
											</TableRow>
										)}
										<TableRow>
											<TableCell colSpan={4} align="right">
												Total:
											</TableCell>
											<TableCell align="right">
												{" "}
												{Notify.numberFormat(
													details.data[0].total_sum_spec_receipt
												)}{" "}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell colSpan={4} align="right">
												Amount Paid:
											</TableCell>
											<TableCell align="right">
												{" "}
												{Notify.numberFormat(details.data[0].amount_paid)}{" "}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell colSpan={4} align="right">
												Change:
											</TableCell>
											<TableCell align="right">
												{Notify.numberFormat(
													parseFloat(details.data[0].amount_paid) -
														parseFloat(details.data[0].total_sum_spec_receipt)
												)}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Box display="flex" mb={1}>
								<Box>
									<Typography>Received by</Typography>
									<Typography>_________________</Typography>
									<Typography>{details.data[0].pharmacyNameById}</Typography>
								</Box>
								<Box flexGrow={1} />
								<Box>
									<Button
										className={"d-print-none"}
										variant="contained"
										color="default"
										onClick={() => closeModal()}
										startIcon={<ClearIcon />}
									>
										Close
									</Button>
								</Box>
								<Box ml={2}>
									<Button
										className={"d-print-none"}
										variant="contained"
										color="primary"
										onClick={() => window.print()}
										startIcon={<PrintIcon />}
									>
										Print
									</Button>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>
			)}
		</Fragment>
	);
};

export default ReceiptPrintModal;
