import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import CisPatientAppointmentListItem from "src/cis-doctor/components/CisPatientAppointmentListItem";
import DateTimeClock from "src/clinic/components/DateTimeClock";
import ActionBtn from "src/components/ActionBtn";
import ButtonV3 from "src/components/ButtonV3";
import FlatIcon from "src/components/FlatIcon";
import LayoutContainer from "src/components/LayoutContainer";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import ConfirmationModal from "src/components/modal/ConfirmationModal";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import useClinicHook from "src/clinic/services/useClinicHook";
import CisPatientTBTreatmentProgram from "src/cis/components/CisPatientTBTreatmentProgram";
import ClinicInfo from "src/cis/components/ClinicInfo";
import { useHistory } from "react-router-dom";
import HealthUnitDetails from "./components/HealthUnitDetails";

const SphPatientReferrals = () => {
	const history = useHistory();
	const accept_patient_modal = useRef(null);
	const confirmation_modal = useRef(null);

	const [registeringPatients, setRegisteringPatients] = useState([]);
	const [inServicePatients, setInServicePatients] = useState([]);
	const [donePatients, setDonePatients] = useState([]);
	const [pendingList, setPendingList] = useState({
		loading: false,
		data: [],
	});
	const [approveList, setApproveList] = useState({
		loading: false,
		data: [],
	});

	useEffect(() => {
		getPendingList();
		getApproveList();
	}, []);
	const approveRhuReferral = (id) => {
		return axios.post(`/v1/clinic/approve-rhu-referrals/${id}`, {
			_method: "PATCH",
		});
	};
	const getPendingList = () => {
		setPendingList({
			loading: true,
			data: [],
		});
		axios
			.get(`/v1/clinic/sph-patient-referrals?status=pending`)
			.then((res) => {
				setPendingList({
					loading: true,
					data: res.data.data,
				});
			});
	};

	const getApproveList = () => {
		setApproveList({
			loading: true,
			data: [],
		});

		axios
			.get(`/v1/clinic/sph-patient-referrals?status=approved`)
			.then((res) => {
				setApproveList({
					loading: true,
					data: res.data.data,
				});
			});
	};

	return (
		<LayoutContainer
			icon={<FlatIcon icon="rr-ballot" className="text-xl" />}
			title={`Patient Referrals`}
			subtitle={`List of patients in queue.`}
			titleChildren={<HealthUnitDetails />}
		>
			<div className="grid grid-cols-1 lg:grid-cols-12 gap-6 pb-4">
				<div className="lg:col-span-6 flex flex-col gap-y-4">
					<div className="rounded-xl bg-slate-300 text-darker p-3 text-lg font-bold flex items-center gap-2">
						<FlatIcon icon="rr-form" />
						<span className="mr-auto">Referrals / Pending</span>
					</div>
					{pendingList?.data?.map((data) => {
						let patient = data;
						return (
							<CisPatientTBTreatmentProgram
								onClick={() => {
									confirmation_modal.current.show({
										title: "Approve patient",
										message: (
											<div className="py-6 px-2 text-xl  w-full flex flex-col items-center justify-center gap-4">
												<p className="font-bold">
													Patient will be added to in
													Approved list.
												</p>
											</div>
										),
										footer: ({
											loadingBtn,
											setLoadingBtn,
										}) => {
											return (
												<div className="flex items-center gap-4 w-full justify-end">
													<ActionBtn
														size="md"
														type="primary-gradient"
														onClick={() => {
															confirmation_modal.current.hide();
														}}
													>
														Cancel
													</ActionBtn>
													<ActionBtn
														size="md"
														type="success"
														loading={loadingBtn}
														onClick={() => {
															setLoadingBtn(true);
															approveRhuReferral(
																data?.id
															).then((res) => {
																setTimeout(
																	() => {
																		setLoadingBtn(
																			false
																		);

																		toast.success(
																			"Patient referral approved!"
																		);
																	},
																	200
																);
																getPendingList();
																getApproveList();
																confirmation_modal.current.hide();
															});
														}}
													>
														Okay
													</ActionBtn>
												</div>
											);
										},
									});
								}}
								doctor={data?.relationships?.doctor}
								patient={{
									...patient?.patient,
									firstname: patient?.patient?.firstname,
									lastname: patient?.patient?.lastname,
									middle: patient?.patient?.middle,
									middlename: patient?.patient?.middle,
									birthday: patient?.patient?.birthday,
									gender: patient?.patient?.gender,
									reason: data?.brgy_notes,
									status: "pending",
								}}
							/>
						);
					})}
					{pendingList?.data?.length == 0 ? (
						<span className="px-4 text-lg text-slate-400">
							No data found.
						</span>
					) : (
						""
					)}
				</div>
				<div className="lg:col-span-6 flex flex-col gap-y-4">
					<div className="rounded-xl bg-green-100 text-green-700 p-3 text-lg font-bold flex items-center gap-2">
						<FlatIcon icon="rr-hand-holding-heart" />
						Done
					</div>

					{approveList?.data?.map((data) => {
						let patient = data;
						return (
							<CisPatientTBTreatmentProgram
								clickable={true}
								onClick={() => {
									history.push(
										`/sph/app/rhu/patient/${patient?.patient?.id}`
									);
								}}
								doctor={data?.relationships?.doctor}
								patient={{
									...patient,
									firstname: patient?.patient?.firstname,
									lastname: patient?.patient?.lastname,
									middle: patient?.patient?.middle,
									middlename: patient?.patient?.middle,
									birthday: patient?.patient?.birthday,
									gender: patient?.patient?.gender,
									reason: data?.brgy_notes,
									status: "approve",
								}}
							/>
						);
					})}
					{approveList?.data?.length == 0 ? (
						<span className="px-4 text-lg text-slate-400">
							No data found.
						</span>
					) : (
						""
					)}
				</div>
			</div>
			<ConfirmationModal ref={confirmation_modal} />
		</LayoutContainer>
	);
};

export default SphPatientReferrals;
