import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import { dateYYYYMMDD } from "src/helpers/utils";
import { v4 as uuidv4 } from "uuid";

const DiseaseTreatmentContext = createContext();

export function DiseaseTreatmentContextWrapper({ children }) {
	const [healthUnit, setHealthUnit] = useState(null);
	const { users } = useContext(UsersData);
	useEffect(() => {
		let t = setTimeout(() => {
			getHealthUnit();
		}, 400);
		return () => {
			clearTimeout(t);
		};
	}, []);
	const getHealthUnit = () => {
		axios.get(`/v1/health-unit/get-user-health-unit`).then((res) => {
			setHealthUnit(res.data?.id ? res.data : res.data.data);
		});
	};
	return (
		<DiseaseTreatmentContext.Provider
			value={{
				healthUnit,
				setHealthUnit,
			}}
		>
			{children}
		</DiseaseTreatmentContext.Provider>
	);
}

export function useDiseaseTreatmentContext() {
	return useContext(DiseaseTreatmentContext);
}
