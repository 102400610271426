import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faHome,
  faPersonBooth,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import AdmittingDashboard from "src/admitting";
import AdmittingRooms from "src/admitting/rooms";
import RoomDetails from "src/admitting/rooms/deteails";
import AdmitPatient from "src/admitting/patient-foradmit/details/admit-patient";
import LeaveForm from "src/admitting/leave/LeaveForm";
import Account from "src/admitting/account/Account";

const RouteAdmitting = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);

  const getSidebarHeaderInfo = async () => {
    var params = { user_id: userContext.users.user_id };
    const { data } = await axios.get("admitting/sidebar/header-infomartion", {
      params,
    });
    setSidebarHeader(data);
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faHome} color="white" style={{ fontSize: 18 }} />
      ),
      path: "/sph/app/admitting",
      subitem: [],
    },

    {
      name: "Rooms",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faPersonBooth}
          color="white"
          style={{ fontSize: 18 }}
        />
      ),
      path: "/sph/app/admitting/rooms",
      subitem: [],
    },

    {
      name: "Leave Application",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faAddressCard}
          color="white"
          style={{ fontSize: 18 }}
        />
      ),
      path: "/sph/app/admitting/leave-application",
      subitem: [],
    },

    {
      name: "Account",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faUserLock}
          color="white"
          style={{ fontSize: 18 }}
        />
      ),
      path: "/sph/app/admitting/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
        }}
      >
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              owner: "admitting",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"admitting"}
          />
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/sph/app/admitting" />}
            />

            <Route
              exact
              path="/bmcdc"
              component={() => <Redirect to="/sph/app/admitting" />}
            />

            <Route
              exact
              path="/sph/app"
              component={() => <Redirect to="/sph/app/admitting" />}
            />

            <Route
              exact
              path="/sph/app/admitting"
              component={AdmittingDashboard}
            />

            <Route
              exact
              path="/sph/app/admitting/details/admit-patient/:trace_number/:patient_id"
              component={AdmitPatient}
            />

            <Route
              exact
              path="/sph/app/admitting/rooms"
              component={AdmittingRooms}
            />

            <Route
              exact
              path="/sph/app/admitting/rooms/:room_id"
              component={RoomDetails}
            />

            <Route
              exact
              path="/sph/app/admitting/leave-application"
              component={LeaveForm}
            />

            <Route
              exact
              path="/sph/app/admitting/account"
              component={Account}
            />

            <Route exact path="/sph/app/logout" component={Logout} />

            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteAdmitting;
