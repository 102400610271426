import { Box, TextField } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect } from "react";
import Notify from "src/notification/Notify";
import Label from "src/utils/Label";

const PatientAppointmentLocalDetails = ({ details }) => {
	const token = localStorage.getItem("token");

	const appointmentSetAsView = async () => {
		try {
			var formdata = new FormData();
			formdata.set("token", token);
			formdata.set("appointment_id", details.appointment_id);
			formdata.set("patient_id", details.patients_id);
			await Axios.post("appointment/doctors/setas-view", formdata);
		} catch (error) {
			console.log("unable to set as view local appointment");
		}
	};

	useEffect(() => {
		appointmentSetAsView();

		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Box mb={2}>
				{Boolean(parseInt(details.is_reschedule)) && (
					<>
						<Label color="error">Apointment is rescheduled</Label>

						<Box>
							<TextField
								InputProps={{
									readOnly: true,
								}}
								label="Appointment Reschedule Reason"
								fullWidth
								value={
									details.is_reschedule_reason === null
										? "None"
										: details.is_reschedule_reason
								}
								variant="outlined"
							/>
						</Box>
					</>
				)}
			</Box>

			<Box mb={2}>
				<TextField
					InputProps={{
						readOnly: true,
					}}
					label="Appointment Date"
					fullWidth
					value={
						Boolean(parseInt(details.is_reschedule))
							? Notify.dateTimeConvert(details.is_reschedule_date)
							: Notify.dateTimeConvert(details.app_date)
					}
					variant="outlined"
				/>
			</Box>

			<Box mb={2}>
				<TextField
					InputProps={{
						readOnly: true,
					}}
					label="Appointment Reason"
					fullWidth
					value={details.app_reason === null ? "None" : details.app_reason}
					variant="outlined"
				/>
			</Box>

			<Box mb={2}>
				<TextField
					InputProps={{
						readOnly: true,
					}}
					label="Appointment Services"
					fullWidth
					value={details.services}
					variant="outlined"
				/>
			</Box>

			<Box mb={2}>
				<TextField
					InputProps={{
						readOnly: true,
					}}
					label="Appointment Amount"
					fullWidth
					value={details.amount}
					variant="outlined"
				/>
			</Box>

			<Box mb={2}>
				<TextField
					InputProps={{
						readOnly: true,
					}}
					label="Appointment Done On"
					fullWidth
					value={
						details.app_date_end === null
							? "None"
							: Notify.dateTimeConvert(details.app_date_end)
					}
					variant="outlined"
				/>
			</Box>

			<Box mb={2}>
				<TextField
					InputProps={{
						readOnly: true,
					}}
					label="Appointment Status"
					fullWidth
					value={
						Boolean(parseInt(details.is_complete)) ? "Complete" : "Incomplete"
					}
					variant="outlined"
				/>
			</Box>
		</>
	);
};

export default PatientAppointmentLocalDetails;
