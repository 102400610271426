import React, { Fragment, useContext, useState } from "react";

import Container from "src/layout/Container";
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Avatar,
  useTheme,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  FormHelperText,
  TextField,
  DialogTitle,
  Box,
  Button,
  DialogActions,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import Medical from "../patients/certificates/Medical";
import axios from "axios";
import { PatientsUnRead, UsersData } from "src/ContextAPI";
import { XCircle, CheckSquare } from "react-feather";
import { Formik } from "formik";
import * as Yup from "yup";

const MedicalCertificate = () => {
  const { medicalCert } = useContext(PatientsUnRead);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const theme = useTheme();
  const { users } = useContext(UsersData);
  const [isProcess, setIsProcess] = useState({ data: null, open: false });

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Medical Certificate",
          items: [
            {
              name: "Dashboard",
              path: "/sph/app/doctor",
            },
          ],
        }}
        title={"Medical Certificate"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <CardHeader title="Queueing Patients" />
              <CardContent>
                <List>
                  {medicalCert.queueing.length > 0 ? (
                    medicalCert.queueing.map((data, index) => (
                      <ListItem
                        key={index}
                        button
                        onClick={() => setIsProcess({ data: data, open: true })}
                      >
                        <ListItemIcon>
                          <Avatar
                            style={{
                              backgroundColor: theme.palette.primary.main,
                              color: "#fff",
                            }}
                          >
                            {index++ + 1}
                          </Avatar>
                        </ListItemIcon>
                        <ListItemText
                          primary={`${data.lastname}, ${data.firstname}`}
                          primaryTypographyProps={{
                            style: {
                              textTransform: "capitalize",
                            },
                          }}
                          secondary={Notify.dateTimeConvert(
                            data.transaction_date
                          )}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <>
                      <Typography color="secondary" align="center">
                        No order found.
                      </Typography>
                    </>
                  )}
                </List>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            {selectedPatient !== null && (
              <Medical
                patient_id={selectedPatient}
                processFrom="med-tab"
                setSelectedPatient={() => setSelectedPatient(null)}
              />
            )}
          </Grid>

          {/* CreateMedical */}
          <Dialog
            open={isProcess.open}
            onClose={() => setIsProcess({ data: null, open: false })}
            scroll="body"
          >
            <DialogTitle> New Certificate </DialogTitle>
            <Formik
              initialValues={{
                lmc_id: isProcess.open && isProcess.data.lmc_id,
                user_id: users.user_id,
                username: users.username,
                management_id: users.management_id,
                main_mgmt_id: users.main_mgmt_id,
                diagnosis: "",
                recommendation: "",
                remarks: "",
                issued_at: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                diagnosis: Yup.string().required(),
                issued_at: Yup.string().required(
                  "Issued at is a required field."
                ),
                password: Yup.string().required(),
              })}
              onSubmit={async (
                values,
                { setErrors, setSubmitting, resetForm }
              ) => {
                try {
                  const request = await axios.post(
                    "doctors/patient/certificates/medicalcert-setcomplete",
                    getFormData(values)
                  );
                  if (request.data.message === "pass-invalid") {
                    setErrors({ password: "Password invalid." });
                  }
                  if (request.data.message === "success") {
                    Notify.successRequest("certificate added");
                    resetForm();
                    setSubmitting(true);
                    medicalCert.getMedcertOrder();
                    setIsProcess({ data: null, open: false });
                  }
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                resetForm,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <Box flexGrow={1}>
                      <Grid container spacing={2}>
                        <Grid xs={12} item>
                          <Box mb={1}>
                            <TextField
                              fullWidth
                              required
                              error={Boolean(
                                touched.diagnosis && errors.diagnosis
                              )}
                              helperText={touched.diagnosis && errors.diagnosis}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.diagnosis}
                              name="diagnosis"
                              label="Diagnosis"
                              variant="outlined"
                              multiline
                            />
                          </Box>
                        </Grid>

                        <Grid xs={12} item>
                          <Box mb={1}>
                            <TextField
                              fullWidth
                              error={Boolean(
                                touched.recommendation && errors.recommendation
                              )}
                              helperText={
                                touched.recommendation && errors.recommendation
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.recommendation}
                              name="recommendation"
                              label="Recommendation"
                              multiline
                              variant="outlined"
                            />
                          </Box>
                        </Grid>

                        <Grid xs={12} item>
                          <Box mb={1}>
                            <TextField
                              fullWidth
                              error={Boolean(touched.remarks && errors.remarks)}
                              helperText={touched.remarks && errors.remarks}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.remarks}
                              name="remarks"
                              multiline
                              label="Remarks"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>

                        <Grid xs={12} item>
                          <Box mb={1}>
                            <TextField
                              fullWidth
                              required
                              error={Boolean(
                                touched.issued_at && errors.issued_at
                              )}
                              helperText={touched.issued_at && errors.issued_at}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.issued_at}
                              name="issued_at"
                              label="Issued At"
                              variant="outlined"
                              type="date"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Box>
                        </Grid>

                        <Grid xs={12} item>
                          <Box mb={1}>
                            <TextField
                              fullWidth
                              required
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              helperText={touched.password && errors.password}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.password}
                              name="password"
                              label="Current password"
                              variant="outlined"
                              type="password"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    {errors.submit && (
                      <Box mt={2}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="large"
                      variant="contained"
                      color="default"
                      onClick={() => {
                        resetForm();
                        setIsProcess({ data: null, open: false });
                      }}
                      startIcon={<XCircle />}
                    >
                      No
                    </Button>
                    <Button
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<CheckSquare />}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </Dialog>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default MedicalCertificate;
