import {
  Grid,
  Box,
  Paper,
  List,
  Typography,
  ListItemText,
  ListItem,
  TextField,
  InputAdornment,
  Avatar,
  makeStyles,
  Collapse,
  ListItemIcon,
  CardMedia,
  Divider,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { UsersData, UsersHeader } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import { blue } from "@material-ui/core/colors";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import SearchIcon from "@material-ui/icons/Search";
import AudiometryOrder from "../order/AudiometryOrder";
import IshiharaOrder from "../order/IshiharaOrder";
import NeurologyOrder from "../order/NeurologyOrder";
import { Search } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const PsychologyPatients = () => {
  const classes = useStyles();
  const { users } = useContext(UsersData);
  const [search, setSearch] = useState("");
  const [formheader, setFormHeader] = useState([]);
  const [searchDisplay, setSearchDisplay] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState({
    patient_id: null,
    order_count: null,
    category: "",
  });
  const {
    queue: { patient, getPatientsOnQueue },
  } = React.useContext(UsersHeader);

  const getLabFormHeader = React.useCallback(() => {
    var params = { management_id: users.management_id };
    axios
      .get("laboratory/order/formheader-details", { params })
      .then((response) => {
        const data = response.data;
        setFormHeader(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users]);

  useEffect(() => {
    getLabFormHeader();
  }, [getLabFormHeader]);

  const searchable = patient.data.filter((data) => {
    return (
      data.patient_name.toLowerCase().indexOf(search.trim().toLowerCase()) !==
      -1
    );
  });

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Patients",
        items: [{ name: "Dashboard", path: "/sph/app" }],
      }}
      title={`Patients`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <Paper component={Box} variant="outlined" p={2}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              mb={1}
            >
              <Box flexGrow={1}>
                <Typography color="textSecondary" variant="h5">
                  <strong>Patient List</strong>
                </Typography>
              </Box>
              <Box>
                <IconButton
                  color="primary"
                  onClick={() => setSearchDisplay(!searchDisplay)}
                >
                  <Search />
                </IconButton>
              </Box>
            </Box>
            <Divider light />
            <Box>
              {patient.ready ? (
                patient.data.length > 0 ? (
                  <List component="div">
                    <>
                      <Box mb={1} hidden={!searchDisplay}>
                        <TextField
                          label="Search"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>

                      {searchable.map((data, index) => {
                        return (
                          <Fragment key={index}>
                            <ListItem
                              button
                              hidden={!Boolean(parseInt(data.order_count))}
                              onClick={() => {
                                setSelectedPatient({
                                  ...selectedPatient,
                                  patient_id: data.pid,
                                  order_count: data.order_count,
                                });
                              }}
                              selected={selectedPatient.patient_id === data.pid}
                            >
                              <ListItemIcon>
                                {data.patient_image === null ? (
                                  <Avatar className={classes.primary}>
                                    {data.patient_name.charAt().toUpperCase()}
                                  </Avatar>
                                ) : (
                                  <Avatar
                                    className={classes.primary}
                                    src={`${imageLocation}patients/${data.patient_image}`}
                                    alt=""
                                  />
                                )}
                              </ListItemIcon>

                              <ListItemText
                                className={`gtc-capitalize`}
                                primary={data.patient_name}
                                primaryTypographyProps={{
                                  color:
                                    selectedPatient.patient_id === data.pid
                                      ? "primary"
                                      : "inherit",
                                }}
                              />
                            </ListItem>

                            <Collapse
                              in={selectedPatient.patient_id === data.pid}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                <ListItem
                                  button
                                  className={classes.nested}
                                  hidden={!Boolean(parseInt(data.count_audio))}
                                  onClick={() =>
                                    setSelectedPatient({
                                      ...selectedPatient,
                                      category: "audiometry",
                                    })
                                  }
                                >
                                  <ListItemIcon>
                                    <DoubleArrowIcon
                                      color={
                                        selectedPatient.category ===
                                        "audiometry"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </ListItemIcon>

                                  <ListItemText
                                    style={{
                                      color:
                                        selectedPatient.category ===
                                        "audiometry"
                                          ? blue[500]
                                          : "",
                                    }}
                                    primary={"Audiometry"}
                                  />
                                </ListItem>

                                <ListItem
                                  button
                                  className={classes.nested}
                                  hidden={
                                    !Boolean(parseInt(data.count_ishihara))
                                  }
                                  onClick={() =>
                                    setSelectedPatient({
                                      ...selectedPatient,
                                      category: "ishihara",
                                    })
                                  }
                                >
                                  <ListItemIcon>
                                    <DoubleArrowIcon
                                      color={
                                        selectedPatient.category === "ishihara"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </ListItemIcon>

                                  <ListItemText
                                    style={{
                                      color:
                                        selectedPatient.category === "ishihara"
                                          ? blue[500]
                                          : "",
                                    }}
                                    primary={"Ishihara"}
                                  />
                                </ListItem>

                                <ListItem
                                  button
                                  className={classes.nested}
                                  hidden={!Boolean(parseInt(data.count_neuro))}
                                  onClick={() =>
                                    setSelectedPatient({
                                      ...selectedPatient,
                                      category: "neuro-exam",
                                    })
                                  }
                                >
                                  <ListItemIcon>
                                    <DoubleArrowIcon
                                      color={
                                        selectedPatient.category ===
                                        "neuro-exam"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </ListItemIcon>

                                  <ListItemText
                                    style={{
                                      color:
                                        selectedPatient.category ===
                                        "neuro-exam"
                                          ? blue[500]
                                          : "",
                                    }}
                                    primary={"Neuro Examination"}
                                  />
                                </ListItem>
                              </List>
                            </Collapse>
                          </Fragment>
                        );
                      })}
                    </>
                  </List>
                ) : (
                  <Box mt={2}>{Notify.noRecord()}</Box>
                )
              ) : (
                <Box mt={2}>{Notify.loading()}</Box>
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={9}>
          {selectedPatient.category === "" && <RenderSelectPatient />}
          {selectedPatient.patient_id !== null &&
            selectedPatient.category === "audiometry" && (
              <AudiometryOrder
                queue={"patient_queue"}
                patient_id={selectedPatient.patient_id}
                formheader={formheader}
                getPatientWithOrder={() => getPatientsOnQueue()}
                order_count={selectedPatient.order_count}
                process_for={"clinic"}
              />
            )}
          {selectedPatient.patient_id !== null &&
            selectedPatient.category === "ishihara" && (
              <IshiharaOrder
                queue={"patient_queue"}
                patient_id={selectedPatient.patient_id}
                formheader={formheader}
                getPatientWithOrder={() => getPatientsOnQueue()}
                order_count={selectedPatient.order_count}
                process_for={"clinic"}
              />
            )}

          {selectedPatient.patient_id !== null &&
            selectedPatient.category === "neuro-exam" && (
              <NeurologyOrder
                queue={"patient_queue"}
                patient_id={selectedPatient.patient_id}
                formheader={formheader}
                getPatientWithOrder={() => getPatientsOnQueue()}
                order_count={selectedPatient.order_count}
                process_for={"clinic"}
              />
            )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PsychologyPatients;

const RenderSelectPatient = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 270 }}
    />
    <Typography color="secondary" variant="h3">
      NO PATIENT SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select patient in the list to view details.
    </Typography>
  </Box>
);
