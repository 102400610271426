import SelectInput from "@material-ui/core/Select/SelectInput";
import Tippy from "@tippyjs/react";
import axios from "axios";
import { useEffect, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import SelectInputField from "src/components/forms/SelectInputField";
import TextInputField from "src/components/forms/TextInputField";
import { v4 as uuidv4 } from "uuid";
const first_id = uuidv4();
const ItemRow = (props) => {
	const { item, addRow, removeRow, updateItems, medicalItems } = props;
	const [selected, setSelected] = useState(null);
	return (
		<div className="flex flex-col">
			<div className="grid grid-cols-1 lg:flex  items-start gap-4 w-full">
				<SelectInputField
					label="Select item"
					placeholder="Select item"
					value={selected?.id}
					onChange={(e) => {
						let found = medicalItems.find(
							(x) => x.id == e.target.value
						);
						console.log("found", found);
						updateItems(item?.key, found);
						setSelected(found);
					}}
					options={medicalItems?.map((x) => {
						return {
							value: x.id,
							label: x.item?.name,
						};
					})}
					className=" flex-1 mb-2"
					inputClassName="font-bold"
				/>
				<TextInputField
					label="Unit"
					className="w-[88px]"
					placeholder="Unit"
					readOnly
					value={selected?.item?.unit_measurement}
				/>
				<TextInputField
					label="Quantity"
					className=" w-[88px]"
					inputClassName=" !bg-white !border-blue-300"
					value="1"
					readOnly
				/>
				<div className="flex items-center gap-1 pt-[28px]">
					<Tippy content="Click to remove item">
						<ActionBtn
							type="danger-light"
							size="sm"
							className="bg-red-100 !rounded-sm"
							onClick={() => {
								removeRow(item);
							}}
						>
							<FlatIcon
								icon="rr-cross"
								className="font-bold text-red-500 text-xl"
							/>
						</ActionBtn>
					</Tippy>
				</div>
			</div>
		</div>
	);
};
const SelectToUseItems = (props) => {
	const { setSelectedItems } = props;

	const [medicalItems, setMedicalItems] = useState([]);
	const [items, setItems] = useState([
		{
			key: first_id,
			qty: 1,
		},
	]);
	const addItemRow = () => {
		setItems((prevItems) => [...prevItems, { key: uuidv4(), qty: 1 }]);
	};
	const removeItemRow = (data) => {
		if (items?.length > 1) {
			setItems((prevItems) =>
				prevItems.filter((x) => x.key != data?.key)
			);
		}
	};

	useEffect(() => {
		setSelectedItems(items);
	}, [items]);

	useEffect(() => {
		getInventories();
	}, []);

	const getInventories = () => {
		axios.get(`v1/item-inventory`).then((res) => {
			setMedicalItems(res.data.data);
		});
	};

	const updateItems = (key, data) => {
		setItems((prevItems) =>
			prevItems.map((x) => (x.key == key ? { ...x, ...data } : x))
		);
	};

	return (
		<div className="flex flex-col w-full pt-3">
			<h3 className="font-bold text-base border-b pb-3">
				Select Items to use for testing
			</h3>
			{items?.map((item, index) => {
				return (
					<>
						<ItemRow
							item={item}
							addRow={addItemRow}
							removeRow={removeItemRow}
							updateItems={updateItems}
							medicalItems={medicalItems}
							key={`xitem-row-${index}`}
						/>
						<hr />
					</>
				);
			})}
			<Tippy content="Click to add more items">
				<ActionBtn
					type="transparent"
					size="sm"
					className="!bg-green-200 rounded-xl !p-3 !border !border-green-700 !text-green-700"
					onClick={addItemRow}
				>
					<FlatIcon icon="rr-plus" /> Add new item
				</ActionBtn>
			</Tippy>
		</div>
	);
};

export default SelectToUseItems;
