import axios from "axios";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import { formatDate, formatDateTime } from "src/helpers/utils";
import AddDiagnosisModal from "./AddDiagnosisModal";
import AddMedicationModal from "./AddMedicationModal";

const ClinicMedication = ({ patient }) => {
	const { id } = useParams();
	const add_form_modal = useRef(null);
	const [data, setData] = useState([]);
	useEffect(() => {
		if (id > 0) {
			getData();
		}
	}, [id]);
	const getData = () => {
		axios
			.get(`/v1/clinic/patient-medication?patient_id=${id}`)
			.then((res) => {
				let a = res.data.data || [];
				setData(a.reverse());
			});
	};
	return (
		<div className="py-2 px-4 flex flex-col">
			<div className="flex items-center gap-4 pb-2">
				<h2 className="text-lg font-bold text-darker mb-0">
					Patient Medication
				</h2>
				<ActionBtn
					onClick={() => {
						add_form_modal.current.show({
							id: id,
							name: `${patient?.lastname}, ${patient?.firstname}, ${patient?.middle}`,
						});
					}}
					size="sm"
				>
					<FlatIcon icon="rr-plus" />
					Add Medication
				</ActionBtn>
			</div>
			<div class=" mx-11">
				<ol class="relative border-l border-gray-200 dark:border-gray-700">
					{data?.length == 0 ? (
						<li className="text-placeholder pl-4">
							<i>No data to display.</i>
						</li>
					) : (
						""
					)}
					{data?.map((item) => {
						return (
							<li class="mb-10 ml-4">
								<div class="absolute w-3 h-3 bg-gray-200 rounded-full -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700" />
								<time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
									{formatDateTime(
										item?.date + " " + item?.time
									)}
								</time>
								<h3 class="text-lg font-semibold text-gray-900">
									{item?.title}
								</h3>
								<div
									class="mb-4 text-base font-normal text-darker bg-green-100 rounded-lg p-4 shadow-sm html-contents"
									dangerouslySetInnerHTML={{
										__html: item?.description,
									}}
								/>
							</li>
						);
					})}
				</ol>
			</div>

			<AddMedicationModal ref={add_form_modal} getData={getData} />
		</div>
	);
};

export default ClinicMedication;
