
import { DialogActions, DialogContent, Button, Box, TextField, CircularProgress, Dialog, Typography, Tooltip, Slider } from '@material-ui/core';
import { blue, grey, orange, red, green } from '@material-ui/core/colors';
import Axios from 'axios';
import React, { useState } from 'react'
import CanvasDraw from "react-canvas-draw";
import Notify from 'src/notification/Notify';
import { withStyles } from '@material-ui/core/styles';
import { UsersData } from 'src/ContextAPI';

function valueLabelFormat(value) {
    return `${Math.round(value)}`;
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const NotesCanvas = ({ getCanvasNotes, patient_id, close }) => { 
    const canvasRef = React.useRef(null)
    const finalCanvasRef = React.useRef(null)
    const [brushColor, setBrushColor] = useState('#000')
    const [isedit, setIsEdit] = useState(true)
    const [issavetodb, setIsSaveToDb] = useState(false)
    const [isprocess, setIsProcess] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState(false)
    const defaultWidth = window.innerWidth - 50;
    const defaultHeight = window.innerHeight - 200;

    const [alignment, setAlignment] = useState('DEFAULT');
    const [penWidth, setPenWidth] = React.useState(1);
    const [openTooltip, setOpenTooltip] = React.useState(false);

    const { users } = React.useContext(UsersData)

    const saveCanvas = () => {
        setIsEdit(false)
        setIsSaveToDb(true)

        let imageURL = canvasRef.current.canvasContainer.childNodes[1].toDataURL()
        renderImageINFinalCanvas(imageURL)
    }

    const uploadImage = () => {
        canvasRef.current.clear();
        if (document.querySelector('input[type=file]').files.length > 0) {
            const file = document.querySelector('input[type=file]').files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                var imahe = new Image();
                var kanbas = canvasRef.current.canvasContainer.childNodes[1];
                var seteeks = kanbas.getContext('2d');
                imahe.onload = function () {
                    if (alignment === 'CENTER') {
                        var wrh = imahe.width / imahe.height;
                        var newWidth = kanbas.width;
                        var newHeight = newWidth / wrh;
                        if (newHeight > kanbas.height) {
                            newHeight = kanbas.height;
                            newWidth = newHeight * wrh;
                        }
                        var xOffset = newWidth < kanbas.width ? ((kanbas.width - newWidth) / 2) : 0;
                        var yOffset = newHeight < kanbas.height ? ((kanbas.height - newHeight) / 2) : 0;
                        seteeks.drawImage(imahe, xOffset, yOffset, newWidth, newHeight);
                    } else {
                        seteeks.drawImage(imahe, 0, 0);
                    }
                }
                imahe.src = event.target.result;
            }
            reader.readAsDataURL(file);
        } else {
            console.log('Upload should have atleast 1 image selected.')
        }
    }

    const renderImageINFinalCanvas = (data) => {
        finalCanvasRef.current.width = defaultWidth;
        finalCanvasRef.current.height = defaultHeight;

        var ctx = finalCanvasRef.current.getContext('2d');

        var image = new Image();
        image.src = data

        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, finalCanvasRef.current.width, finalCanvasRef.current.height);

        image.onload = () => {
            ctx.drawImage(image, 0, 0);
        }
    }
    const handleCanvasSave = (e) => {
        e.preventDefault()
        e.persist()
        var dataURL = finalCanvasRef.current.toDataURL()

        var formdata = new FormData(e.target)
        formdata.append('image', dataURL)
        formdata.append('patient_id', patient_id) 
        formdata.append('management_id', users.management_id)
        formdata.append('user_id', users.user_id)
        formdata.append('username', users.username)
        var error = [];

        if (formdata.get('password').length === 0 || formdata.get('password').trim() === '') {
            Notify.fieldRequired('password')
            error = 'error';
        }
        if (error.length > 0) {
            console.warn("form has an error...")
        } else {
            setIsProcess(true)
            Axios.post('doctor/patient/notes/savecanvas-note', formdata)
                .then((res) => {
                    if (res.data === 'success') {
                        Notify.successRequest('canvas save')
                        close()
                        getCanvasNotes()
                    }
                    if (res.data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                }).catch((error) => Notify.requestError(error))
                .finally(() => setIsProcess(false))
        }

    }

    const handleChange = (event, newValue) => {
        setPenWidth(newValue);
    };

    return (
        <>
            <form onSubmit={handleCanvasSave}>
                <DialogContent dividers>
                    <Box hidden={!isedit} >
                        <Box display="flex">
                            <Box >
                                <TextField
                                    variant="outlined"
                                    select
                                    label="Alignment"
                                    onChange={(e) => {
                                        setAlignment(e.target.value)
                                    }}
                                    margin="dense"
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option value='DEFAULT'> Default </option>
                                    <option value='CENTER'> Center </option>
                                </TextField>
                            </Box>
                            <Box ml={2}>
                                <TextField
                                    variant="outlined"
                                    select
                                    label="Brush Color"
                                    onChange={(e) => {
                                        setBrushColor(e.target.value)
                                    }}
                                    margin="dense"
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option value={'#000'}> Black </option>
                                    <option value={blue[600]}> Blue </option>
                                    <option value={orange[600]}> Orange </option>
                                    <option value={red[600]}> Red </option>
                                </TextField>
                            </Box>
                            <Box flexGrow={1} ml={2}>
                                <LightTooltip interactive open={openTooltip} title={
                                    <Slider
                                        value={penWidth}
                                        min={1}
                                        max={100}
                                        getAriaValueText={valueLabelFormat}
                                        valueLabelFormat={valueLabelFormat}
                                        onChange={handleChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="non-linear-slider"
                                        style={{ width: 200 }}
                                    />
                                } arrow>
                                    <Button variant={'outlined'} style={{ borderColor: grey[600], color: grey[600], top: 8, height: 40 }} onClick={() => setOpenTooltip(!openTooltip)}> Pen width </Button>
                                </LightTooltip>
                            </Box>
                            <Box mr={2}>
                                <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    name="contained-button-file"
                                    type="file"
                                    multiple
                                    hidden
                                    onChange={uploadImage}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button variant={'outlined'}
                                        style={{ borderColor: green[600], color: green[600] }}
                                        component="span"
                                    >
                                        Upload
                                    </Button>
                                </label>
                            </Box>
                            <Box mr={2}>
                                <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    onClick={() => { canvasRef.current.undo(); }}
                                >
                                    Undo
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    variant={'outlined'}
                                    color={'secondary'}
                                    onClick={() => {
                                        setConfirmDialog(true)
                                    }}
                                >
                                    Clear
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                    <CanvasDraw
                        disabled={!isedit}
                        style={{
                            border: `1px solid ${grey[300]}`
                        }}
                        ref={canvasRef}
                        brushColor={brushColor}
                        catenaryColor={brushColor}
                        canvasWidth={defaultWidth}
                        canvasHeight={defaultHeight}
                        lazyRadius={1}
                        brushRadius={penWidth}
                    />

                    <canvas ref={finalCanvasRef} hidden />

                    <Box hidden={!issavetodb}>
                        <Box mt={2}>
                            <TextField
                                name="password"
                                label="Password" 
                                type="password"
                                variant="outlined"
                                fullWidth
                            />
                        </Box>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Box display="flex" hidden={!isedit}>
                        <Box flexGrow={1} mr={2}>
                            <Button
                                variant="contained"
                                color="default"
                                onClick={() => close()}
                            >
                                Cancel
                            </Button>
                        </Box>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={saveCanvas}
                        >
                            Set as done
                        </Button>
                    </Box>
                    <Box display="flex" hidden={!issavetodb}>
                        <Box flexGrow={1}>
                            <Button
                                variant="contained"
                                color="default"
                                onClick={() => {
                                    setIsSaveToDb(false)
                                    setIsEdit(true)
                                }}
                            >
                                back to edit
                            </Button>
                        </Box>
                        <Box ml={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isprocess}
                                startIcon={isprocess && <CircularProgress size={20} color="inherit" />}
                            >
                                Save Treatment
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </form>


            {/* clear dialog */}
            <Dialog
                open={confirmDialog}
                onClose={() => setConfirmDialog(false)}
            >
                <DialogContent>
                    <Typography>
                        Are you sure to empty your drawing?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="default" onClick={() => setConfirmDialog(false)}> No </Button>
                    <Button variant="contained" color="secondary" onClick={() => {
                        canvasRef.current.clear();
                        setConfirmDialog(false)
                    }}> Yes </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NotesCanvas;