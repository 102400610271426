import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import PhoPatientForm from "./PhoPatientForm";
import { useForm } from "react-hook-form";
import usePatientHook from "src/admission/hooks/usePatientHook";
import { TextField } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
const DiseasesFormModal = (props, ref) => {
	const { staticModal, updateInList } = props;
	const [open, setOpen] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const btnRef = useRef(null);

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (showData) => {
		if (showData) {
			setData(showData);
			setValue("name", showData?.name);
			setValue("color", showData?.color);
			setValue("type", showData?.type);
			setValue("radius", showData?.radius);
		} else {
			reset({
				name: "",
				color: "",
				type: "",
				radius: "",
			});
		}
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = (form) => {
		setLoading(true);
		let formData = new FormData();
		formData.append("name", form?.name);
		formData.append("color", form?.color);
		formData.append("type", form?.type);
		formData.append("radius", form?.radius);

		if (data?.id) {
			formData.append("_method", "PATCH");
		}

		axios
			.post(`/v1/diseases/` + data?.id || "", formData)
			.then((res) => {
				setTimeout(() => {
					toast.success("Disease record updated successfully!");
					updateInList(res.data);
				}, 200);
			})
			.finally(() => {
				setLoading(false);
				hide();
			});
	};
	return (
		<Modal
			open={open}
			hide={hide}
			className={`w-[444px] min-w-[444px] max-w-[444px]`}
			staticModal={staticModal}
		>
			<ModalHeader
				title={(data?.id ? "Edit " : "Add ") + `Disease Form`}
				hide={hide}
			/>
			<ModalBody className={`p- bg-white`}>
				<div className="p-4 flex flex-col">
					<div className="flex flex-col gap-y-5 w-full">
						<TextField
							label={
								<>
									Color
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("color", {
								required: true,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							type="color"
							error={errors?.color}
							helperText={
								errors?.color && "This field is required"
							}
						/>
						<TextField
							label={
								<>
									Name
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("name", {
								required: true,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.name}
							helperText={
								errors?.name && "This field is required"
							}
						/>
						<TextField
							label={
								<>
									Disease Radius
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("radius", {
								required: true,
								valueAsNumber: true,
								pattern: {
									value: /^(0|[1-9]\d*)(\.\d+)?$/,
								},
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.radius}
							helperText={
								errors?.radius && "This field is required"
							}
						/>
						<TextField
							label={
								<>
									Type
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("type", {
								required: true,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.type}
							helperText={
								errors?.type && "This field is required"
							}
							select
						>
							<option value={""}>SELECT</option>
							<option value={"communicable"}>Communicable</option>
							<option value={"non-communicable"}>
								Non-Communicable
							</option>
						</TextField>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button
					onClick={handleSubmit(submit)}
					loading={loading}
					ref={btnRef}
				>
					Submit
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(DiseasesFormModal);
