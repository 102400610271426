import React, { Fragment, useState, useEffect, useCallback } from "react";
import ReactQuill from "react-quill";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
  Paper,
  makeStyles,
} from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Notify from "../../notification/Notify";
import Axios from "axios";
import { grey } from "@material-ui/core/colors";
import { UsersData } from "src/ContextAPI";

const useStyles = makeStyles(() => ({
  primaryColor: {
    backgroundColor: grey[100],
    color: "#000",
  },
}));

const HealthAllergies = (props) => {
  const classes = useStyles();

  const [allergies, setAllergies] = useState("");

  const [isProcess, setIsProcess] = useState(false);
  const [editEnable, seteditEnable] = useState(false);

  const { users } = React.useContext(UsersData);

  const getAllergies = useCallback(() => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", props.patient_id);
    formdata.set("connection", props.connection);

    Axios.post("doctors/patient/information/getpatient-information", formdata)
      .then((response) => {
        const data = response.data;
        if (data.length > 0) {
          setAllergies(data[0].allergies);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users, props.patient_id, props.connection]);

  const handleAllergies = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("allergies", allergies.replace("<p><br></p>", ""));
    formdata.set("patient_id", props.patient_id);
    formdata.set("connection", props.connection);

    var error = [];

    if (
      formdata.get("allergies").length === 0 ||
      formdata.get("allergies").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("allergies");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      console.warn("Form has an error.");
    } else {
      setIsProcess(true);
      Axios.post("doctor/patient/patient-new-allergies", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            e.target.reset();
            seteditEnable(false);
            Notify.successRequest("new allergies");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsProcess(false);
        });
    }
  };

  useEffect(() => {
    getAllergies();
  }, [getAllergies]);

  return (
    <Fragment>
      <form onSubmit={handleAllergies}>
        <Box component={Paper} elevation={2} p={2}>
          <Box p={1} display="flex">
            <Box flexGrow={1}>
              <Typography variant="h6" color="textSecondary">
                <strong>Allergies</strong>
              </Typography>
            </Box>
            <Box>
              <Button
                startIcon={<AddCircle />}
                color="primary"
                onClick={() => seteditEnable(true)}
                disabled={isProcess}
                hidden={editEnable}
              >
                Update
              </Button>
            </Box>
          </Box>
          <Box my={1}>
            <ReactQuill
              name="sample"
              theme="snow"
              value={allergies}
              onChange={(e) => setAllergies(e)}
              readOnly={!editEnable}
              className={classes.primaryColor}
            />
          </Box>

          <Box hidden={!editEnable}>
            <Box mb={2}>
              <TextField
                fullWidth
                variant="outlined"
                label="Password"
                name="password"
                type="password"
              />
            </Box>

            <Box display="flex">
              <Button
                type="submit"
                variant="contained"
                startIcon={
                  isProcess ? (
                    <CircularProgress color="primary" size={15} />
                  ) : (
                    <CheckCircleIcon />
                  )
                }
                color="primary"
                disabled={isProcess}
              >
                Save
              </Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  startIcon={<CancelIcon />}
                  color="inherit"
                  onClick={() => seteditEnable(false)}
                >
                  No
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </Fragment>
  );
};

export default HealthAllergies;
