import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  Fragment,
  useContext,
} from "react";
import ReactToPrint from "react-to-print";
import {
  Box,
  makeStyles,
  Fab,
  createStyles,
  Typography,
  Grid,
  Divider,
  CardMedia,
  CardHeader,
} from "@material-ui/core";
import { Print } from "@material-ui/icons";
import Notify from "src/notification/Notify";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrescription } from "@fortawesome/free-solid-svg-icons";
import "src/utils/Rx/BMCDCRXDoc.css";
import { UsersData } from "src/ContextAPI";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const BMCDCRXDoc = ({ close, selectedPrescription }) => {
  const classes = useStyles();
  const componentRef = useRef(null);

  const [patientInfo, setPatientInfo] = useState({ data: [], ready: false });
  const [presDetails, setPresDetails] = useState({ data: [], ready: false });
  const [doctorsInfo, setDoctorsInfo] = useState({ data: [], ready: false });
  const [formHeader, setFormHeader] = useState({
    data: [],
    ready: false,
  });
  const { users } = useContext(UsersData);

  const getPatientInfo = useCallback(() => {
    const params = { patient_id: selectedPrescription.data.patients_id };

    Axios.get("doctor/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        setPatientInfo({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [selectedPrescription.data.patients_id]);

  const getPrescriptionDetails = useCallback(() => {
    var formdata = new FormData();
    formdata.set("claim_id", selectedPrescription.data.claim_id);
    formdata.set("patient_id", selectedPrescription.data.patients_id);
    formdata.set("user_id", selectedPrescription.data.doctors_id);

    Axios.post("patients/rx/printable/prescriptiondetails", formdata)
      .then((response) => {
        const data = response.data;
        setPresDetails({
          data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [
    selectedPrescription.data.claim_id,
    selectedPrescription.data.patients_id,
    selectedPrescription.data.doctors_id,
  ]);

  const getDoctorsInfo = useCallback(() => {
    const params = {
      doctors_id: selectedPrescription.data.doctors_id,
    };

    Axios.get("patients/rx/printable/doctorsdetails", { params })
      .then((response) => {
        const data = response.data;
        setDoctorsInfo({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [selectedPrescription.data.doctors_id]);

  const getLabFormHeader = () => {
    var params = { management_id: users.management_id };
    Axios.get("laboratory/order/formheader-details", { params })
      .then((response) => {
        const data = response.data;
        setFormHeader({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getPatientInfo();
    getPrescriptionDetails();
    getDoctorsInfo();
    getLabFormHeader();
    // eslint-disable-next-line
  }, [getPatientInfo, getPrescriptionDetails, getDoctorsInfo]);

  return (
    <>
      <Box component="div" ref={componentRef}>
        {/* header */}
        <Box position="fixed" style={{ top: 20, right: 10, left: 40 }}>
          {formHeader.ready && formHeader.data && (
            <Box display="flex" justifyContent="center">
              <Box mt={2}>
                {formHeader.ready
                  ? formHeader.data && (
                      <CardMedia
                        style={{ width: 70 }}
                        component={"img"}
                        src={`${imageLocation}laboratory/logo/${formHeader.data.logo}`}
                      />
                    )
                  : null}
              </Box>
              <Box>
                <CardHeader
                  component={Box}
                  align="center"
                  title={formHeader.data && formHeader.data.name}
                  subheader={
                    <Box>
                      <Typography>
                        {formHeader.data && formHeader.data.address}
                      </Typography>
                      <Typography>
                        {formHeader.data && formHeader.data.contact_number}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Box>
          )}

          {patientInfo.ready ? (
            patientInfo.data.length ? (
              <>
                <Box display="flex">
                  <Box className="patientinfo">
                    <Typography>
                      <b> Name: </b> {patientInfo.data[0].firstname}{" "}
                      {patientInfo.data[0].middle}{" "}
                      {patientInfo.data[0].firstname}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box className="patientinfo">
                    <Typography>
                      <b> Gender: </b> {patientInfo.data[0].gender}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box className="patientinfo">
                    <Typography>
                      <b> Date: </b>{" "}
                      {presDetails.data.length > 0 &&
                        Notify.dateTimeConvert(new Date().toLocaleDateString())}
                    </Typography>
                  </Box>
                </Box>
              </>
            ) : (
              Notify.noRecord()
            )
          ) : (
            Notify.loading()
          )}
          <Box mt={3}>
            <FontAwesomeIcon icon={faPrescription} size="5x" />
          </Box>
        </Box>
        {/* context */}
        <Box
          position="relative"
          style={{ top: 300, marginLeft: 40, marginRight: 40 }}
        >
          <Box m={2}>
            <Grid container component={Box}>
              <Grid item xs={5}>
                <b>PRODUCTS</b>
              </Grid>
              <Grid item xs={7}>
                <b>QUANTITY</b>
              </Grid>
            </Grid>
          </Box>
          <Divider light />
          {presDetails.ready
            ? presDetails.data.length > 0
              ? presDetails.data.map((rxitems, rxindex) => (
                  <Fragment key={rxindex}>
                    <Box m={2}>
                      <Box display="flex">
                        <Grid item xs={5}>
                          <Box>
                            <Typography variant="subtitle2" noWrap={false}>
                              <span className="text-uppercase">
                                {rxitems.product_name} {rxitems.type}{" "}
                                {rxitems.dosage}
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                              noWrap={false}
                            >
                              Sig. {rxitems.remarks}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={7}>
                          <Box
                            width={70}
                            display="flex"
                            justifyContent="right"
                            alignItems="center"
                          >
                            <Typography>{rxitems.quantity}</Typography>
                          </Box>
                        </Grid>
                      </Box>
                    </Box>
                    {parseInt(rxindex) >= 2 && (
                      <div className="page-break"></div>
                    )}
                    {parseInt(rxindex) >= 5 && (
                      <div className="page-break"></div>
                    )}
                    {parseInt(rxindex) >= 8 && (
                      <div className="page-break"></div>
                    )}
                  </Fragment>
                ))
              : Notify.noRecord()
            : Notify.loading()}
        </Box>
        {/* footer */}
        <Box position="fixed" style={{ bottom: 40, right: 10 }}>
          {doctorsInfo.ready ? (
            doctorsInfo.data.length > 0 ? (
              <>
                <Box display="flex">
                  <Box flexGrow={1} />
                  <Box>
                    <Box>
                      <Typography>
                        <b> {doctorsInfo.data[0].name} </b>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>
                        PRC No. {doctorsInfo.data[0].cil_umn}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              Notify.noRecord()
            )
          ) : (
            Notify.loading()
          )}
        </Box>
        <ReactToPrint
          trigger={() => {
            return (
              <Box className="d-print-none">
                <Fab
                  aria-label={"Print"}
                  className={classes.fab}
                  color={"primary"}
                >
                  <Print />
                </Fab>
              </Box>
            );
          }}
          content={() => componentRef.current}
        />
      </Box>
    </>
  );
};

export default BMCDCRXDoc;

const useStyles = makeStyles((theme) =>
  createStyles({
    fab2: {
      position: "absolute",
      bottom: theme.spacing(10),
      right: theme.spacing(2),
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);
