import { RiseLoader } from "react-spinners";

const LoadingTemplate = () => {
	return (
		<div className="p-11 flex flex-col gap-4 items-center justify-center animate-pulse text-base">
			<div className="opacity-50">
				<RiseLoader />
			</div>{" "}
			Loading... Please wait
		</div>
	);
};

export default LoadingTemplate;
