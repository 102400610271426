import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  Dialog,
  DialogContent,
  Zoom,
  Grid,
  DialogActions,
  Button,
  Card,
  CardHeader,
  CardContent,
  DialogTitle,
} from "@material-ui/core";
import Container from "../../layout/Container";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { Filter, Search, XCircle } from "react-feather";
import Print from "@material-ui/icons/Print";
import CensusPrint from "./CensusPrint";

const AdmissionCensus = () => {
  const { users } = React.useContext(UsersData);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [census, setCensus] = useState({ data: [], ready: false });
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [dialogPrint, setDialogPrint] = useState(false);
  const [filterByDate, setFilterByDate] = useState(false);

  const getPatientCount = useCallback(async () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    try {
      let response = await axios.get("admission/get/all-census", { params });
      const data = response.data;
      setCensus({ data, ready: true });
    } catch (error) {
      console.log("error: ,", error);
    }
  }, [users]);

  const handleFilteredReport = async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      date_from: dateFrom,
      date_to: dateTo,
    };
    if (dateFrom === null || dateTo === null) {
      Notify.customToast("Date Invalid", "Date is a required field.");
      return false;
    } else {
      try {
        let response = await axios.get("admission/get/filter-by-date", {
          params,
        });
        setCensus({ data: response.data, ready: true });
      } catch (error) {
        console.log("error: ", error);
      }
    }

    setFilterByDate(false);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getPatientCount();
  }, [getPatientCount]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "census",
          items: [{ name: "dashboard", path: "/sph/app/registration" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Census</Box>
          </Box>
        }
      >
        <Card>
          <CardHeader
            title={
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow={1}>Transaction List</Box>

                <Box ml={1}>
                  <IconButton
                    color={"primary"}
                    style={{
                      height: 40,
                      width: 40,
                    }}
                    onClick={() => setFilterByDate(true)}
                  >
                    <Filter />
                  </IconButton>
                </Box>

                <Box ml={1}>
                  <IconButton
                    color={"primary"}
                    disabled={dateFrom === null && dateTo === null}
                    style={{
                      height: 40,
                      width: 40,
                    }}
                    onClick={() => setDialogPrint(true)}
                  >
                    <Print />
                  </IconButton>
                </Box>
              </Box>
            }
          />
          <CardContent>
            <Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <strong>Date</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Patient</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Transaction Type</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Symptoms</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {census.ready ? (
                      census.data.length > 0 ? (
                        (rowsPerPage > 0
                          ? census.data.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : census.data
                        ).map((data, index) => (
                          <TableRow key={index} hover>
                            <TableCell>
                              {Notify.createdAt(data.created_at)}
                            </TableCell>
                            <TableCell>
                              {`${data.lastname}, ${data.firstname}`}
                            </TableCell>
                            <TableCell>{data.transaction_type}</TableCell>
                            <TableCell>
                              {data.sickness !== null
                                ? data.sickness.replace(/,/g, ", ")
                                : "None"}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={4}>
                            <Typography color="secondary">
                              No record found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={4}>
                          <Typography color="primary">
                            Please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                labelRowsPerPage="list"
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={census.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </CardContent>
        </Card>

        <Dialog
          open={dialogPrint}
          TransitionComponent={Zoom}
          transitionDuration={800}
          fullScreen
        >
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Box display="flex">
                  <Box flexGrow={1} mb={2}>
                    <Typography align="center" variant="h5">
                      Patients Census
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <CensusPrint
                census={census}
                close={() => setDialogPrint(false)}
              />
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={filterByDate}
          maxWidth={"xs"}
          fullWidth
          onClose={() => setFilterByDate(false)}
        >
          <DialogTitle>Filter By Date</DialogTitle>
          <DialogContent>
            <Box mb={3}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                variant="outlined"
                label="From"
                name="date_from"
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                variant="outlined"
                label="To"
                name="date_to"
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              startIcon={<XCircle />}
              variant="contained"
              onClick={() => setFilterByDate(false)}
              color="default"
            >
              Close
            </Button>

            <Button
              startIcon={<Search />}
              variant="contained"
              onClick={handleFilteredReport}
              color="primary"
            >
              Search
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Fragment>
  );
};

export default AdmissionCensus;
