import React, {
  useEffect,
  // useEffect,
  useState,
  Fragment,
} from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  //   Grid,
  Button,
  CircularProgress,
  //   TextField,
  //   Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import FormHeader from "./FormHeader";
import { useReactToPrint } from "react-to-print";
import {
  XCircle,
  Printer,
  Edit,
  CheckSquare,
  // Circle
} from "react-feather";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import Notify from "src/notification/Notify";

const FormTempAndPulse = ({
  patient_id,
  trace_number,
  info,
  doctorsList,
  allowEdit,
}) => {
  const printableRef = React.useRef();
  const { users } = React.useContext(UsersData);
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [medsList, setMedsList] = useState([]);
  const [isProcess, setIsProcess] = useState(false);
  const [chartPhysician, setChartPhysician] = useState("");
  const mounted = IsMountedRef();
  const [newMeds, setNewMeds] = useState(false);
  const [tempAndPulseChart, setTempAndPulseChart] = React.useState({
    data: [],
    ready: false,
  });
  const [cca_id, setcca_id] = useState(null);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  //   const checkSelectedDoctor = (selDoctor) => {
  //     if (doctorsList.length > 0) {
  //       let xx = doctorsList.filter((x) => x.doctors_id === selDoctor);
  //       if (xx.length > 0) {
  //         return xx[0].name;
  //       } else {
  //         return "";
  //       }
  //     } else {
  //       return "";
  //     }
  //   };

  const handleNewMedications = (e) => {
    e.preventDefault();
    e.persist();

    let err = [];
    let form = new FormData(e.target);

    form.append("user_id", users.user_id);
    form.append("main_mgmt_id", users.main_mgmt_id);
    form.append("management_id", users.management_id);
    form.append("patient_id", patient_id);
    form.append("trace_number", trace_number);
    form.append("physician", chartPhysician);

    if (medsList.length > 0) {
      form.append("room_no", medsList[0].room_no);
      form.append("physician", medsList[0].physician);
      form.append("allergic_to", medsList[0].allergic_to);
      form.append("year", medsList[0].year);
    }

    if (err.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcess(true);
      axios
        .post("or/charts/patient/patient-addressograph", form)
        .then((response) => {
          let data = response.data;

          if (data.message === "success") {
            getCardiopulmonary();
            Notify.successRequest("Chart updated");
            setNewMeds(false);
            setEnableEdit(false);
          }
        })
        .catch((err) => console.log(err.message))
        .finally(() => setIsProcess(false));
    }
  };

  const getCardiopulmonary = React.useCallback(() => {
    axios
      .get("or/charts/patient/patient-getaddressograph", {
        params: {
          patient_id,
          trace_number,
        },
      })
      .then((response) => {
        let data = response.data;
        if (mounted.current) {
          setMedsList(data);
          setTempAndPulseChart({ data, ready: true });
          setcca_id(data[0].cca_id);
        }
      })
      .catch((err) => console.log("error: ", err.message));
  }, [patient_id, trace_number, mounted]);

  const handleNewTempAndPulse = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    // new
    formdata.set("status", cca_id === null ? "for-new" : "for-update");
    formdata.set("cca_id", cca_id);

    setIsProcess(true);
    axios
      .post("or/update/chart/tempandpulsechart", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          getCardiopulmonary();
          setEnableEdit(!enableEdit);
          Notify.successRequest("");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  useEffect(() => {
    getCardiopulmonary();
  }, [getCardiopulmonary]);

  return (
    <Card ref={printableRef}>
      <CardContent>
        <Box>
          <FormHeader />
        </Box>
        <form onSubmit={handleNewTempAndPulse}>
          <Box my={3}>
            <Box
              display="flex"
              justifyContent="flex-end"
              className="d-print-none"
              mb={1}
            >
              {enableEdit ? (
                <>
                  <Box>
                    <Button
                      color="secondary"
                      onClick={() => setEnableEdit(false)}
                      startIcon={<XCircle />}
                    >
                      Close
                    </Button>
                  </Box>

                  <Box ml={2}>
                    <Button
                      disabled={isProcess}
                      color="primary"
                      startIcon={<CheckSquare />}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  {allowEdit && (
                    <Box mr={2}>
                      <Button
                        color="primary"
                        onClick={() => setEnableEdit(true)}
                        startIcon={<Edit />}
                      >
                        Edit
                      </Button>
                    </Box>
                  )}

                  <Box>
                    <Button
                      color="primary"
                      onClick={handlePrint}
                      startIcon={<Printer />}
                    >
                      Print
                    </Button>
                  </Box>
                </>
              )}

              {/* {!enableEdit && (
                <>
                  <Box>
                    <Button
                      color="primary"
                      onClick={handlePrint}
                      startIcon={<Printer />}
                    >
                      Print
                    </Button>
                  </Box>
                </>
              )} */}
            </Box>

            <Box align="center" mt={2}>
              <Box my={2} mb={3}>
                <TableContainer>
                  <Table
                    className="table-bordered no-padding-table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={28}>
                          <Typography variant="caption">
                            Patient's Name
                          </Typography>
                          <Typography>
                            {info
                              ? `${info.lastname}, ${info.firstname} ${
                                  info.middle === null ? "" : info.middle
                                }`
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={12}>
                          <Typography variant="caption">Physician</Typography>
                          <Typography>
                            <span
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                            >
                              {!enableEdit
                                ? tempAndPulseChart.data.length > 0
                                  ? tempAndPulseChart.data[0].physician
                                  : ""
                                : null}
                            </span>
                          </Typography>
                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="physician"
                                defaultValue={
                                  tempAndPulseChart.data.length > 0
                                    ? tempAndPulseChart.data[0].physician
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />

                              <TextField
                                fullWidth
                                name="cca_id"
                                defaultValue={
                                  tempAndPulseChart.data.length > 0
                                    ? tempAndPulseChart.data[0].cca_id
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                hidden
                              />
                            </>
                          )}
                          {/* <Typography>
                            <span
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                            >
                              physician here
                            </span>
                          </Typography> */}
                        </TableCell>
                        <TableCell colSpan={6}>
                          <Typography variant="caption"> Case No. </Typography>
                          <Typography>
                            <span
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                            >
                              {!enableEdit
                                ? tempAndPulseChart.data.length > 0
                                  ? tempAndPulseChart.data[0].case_no
                                  : ""
                                : null}
                            </span>
                          </Typography>
                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="case_no"
                                defaultValue={
                                  tempAndPulseChart.data.length > 0
                                    ? tempAndPulseChart.data[0].case_no
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            </>
                          )}
                          {/* <Typography>
                            <span
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                            >
                              # here
                            </span>
                          </Typography> */}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={10}>
                          <Typography> Day of Month</Typography>
                        </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={10}>
                          <Typography> Days in Hospital </Typography>
                        </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={10}>
                          <Typography> Days post operation </Typography>
                        </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                        <TableCell colSpan={6}> </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={4} width={80}></TableCell>
                        {[0, 1, 2, 3, 4, 5, 6].map((data, key) => (
                          <Fragment key={key}>
                            <TableCell
                              colSpan={3}
                              align="center"
                              style={{ fontSize: "10px" }}
                            >
                              AM
                            </TableCell>
                            <TableCell
                              colSpan={3}
                              align="center"
                              style={{ fontSize: "10px" }}
                            >
                              PM
                            </TableCell>
                          </Fragment>
                        ))}
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={4} width={80}></TableCell>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(
                          (data, key) => (
                            <Fragment key={key}>
                              <TableCell
                                // style={{ fontSize: "10px" }}
                                align="center"
                              >
                                12
                              </TableCell>
                              <TableCell
                                // style={{ fontSize: "10px" }}
                                align="center"
                              >
                                4
                              </TableCell>
                              <TableCell
                                // style={{ fontSize: "10px" }}
                                align="center"
                              >
                                8
                              </TableCell>
                            </Fragment>
                          )
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data, key) => (
                        <TableRow key={key}>
                          {key === 0 && (
                            <>
                              <TableCell
                                rowSpan={60}
                                colSpan={3}
                                align="center"
                                style={{
                                  fontSize: "15px",
                                }}
                                width={60}
                              >
                                T<br />
                                E<br />
                                M<br />
                                P<br />
                                E<br />
                                R<br />
                                A<br />
                                T<br />
                                U<br />
                                R<br />E
                              </TableCell>
                              <TableCell
                                rowSpan={10}
                                align="center"
                                style={{
                                  fontSize: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    position: "relative",
                                    bottom: "42px",
                                  }}
                                >
                                  41{" "}
                                </span>
                              </TableCell>
                            </>
                          )}

                          {[
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
                            42,
                          ].map((datax, keyx) => (
                            <TableCell
                              align="center"
                              style={{ padding: 0 }}
                              key={keyx}
                            ></TableCell>
                          ))}
                        </TableRow>
                      ))}

                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data, key) => (
                        <TableRow key={key}>
                          {key === 0 && (
                            <>
                              <TableCell
                                rowSpan={10}
                                align="center"
                                style={{
                                  fontSize: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    position: "relative",
                                    bottom: "42px",
                                  }}
                                >
                                  40
                                </span>
                              </TableCell>
                            </>
                          )}

                          {[
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
                            42,
                          ].map((datax, keyx) => (
                            <TableCell
                              align="center"
                              style={{ padding: 0 }}
                              key={keyx}
                            ></TableCell>
                          ))}
                        </TableRow>
                      ))}

                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data, key) => (
                        <TableRow key={key}>
                          {key === 0 && (
                            <>
                              <TableCell
                                rowSpan={10}
                                align="center"
                                style={{
                                  fontSize: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    position: "relative",
                                    bottom: "42px",
                                  }}
                                >
                                  39
                                </span>
                              </TableCell>
                            </>
                          )}

                          {[
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
                            42,
                          ].map((datax, keyx) => (
                            <TableCell
                              align="center"
                              style={{ padding: 0 }}
                              key={keyx}
                            ></TableCell>
                          ))}
                        </TableRow>
                      ))}

                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data, key) => (
                        <TableRow key={key}>
                          {key === 0 && (
                            <>
                              <TableCell
                                rowSpan={10}
                                align="center"
                                style={{
                                  fontSize: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    position: "relative",
                                    bottom: "42px",
                                  }}
                                >
                                  38
                                </span>
                              </TableCell>
                            </>
                          )}

                          {[
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
                            42,
                          ].map((datax, keyx) => (
                            <TableCell
                              align="center"
                              style={{ padding: 0 }}
                              key={keyx}
                            ></TableCell>
                          ))}
                        </TableRow>
                      ))}

                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data, key) => (
                        <TableRow key={key}>
                          {key === 0 && (
                            <>
                              <TableCell
                                rowSpan={10}
                                align="center"
                                style={{
                                  fontSize: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    position: "relative",
                                    bottom: "42px",
                                  }}
                                >
                                  37
                                </span>
                              </TableCell>
                            </>
                          )}

                          {[
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
                            42,
                          ].map((datax, keyx) => (
                            <TableCell
                              align="center"
                              style={{ padding: 0 }}
                              key={keyx}
                            ></TableCell>
                          ))}
                        </TableRow>
                      ))}

                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data, key) => (
                        <TableRow key={key}>
                          {key === 0 && (
                            <>
                              <TableCell
                                rowSpan={10}
                                align="center"
                                style={{
                                  fontSize: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    position: "relative",
                                    bottom: "42px",
                                  }}
                                >
                                  36
                                </span>
                              </TableCell>
                            </>
                          )}

                          {[
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
                            42,
                          ].map((datax, keyx) => (
                            <TableCell
                              align="center"
                              style={{ padding: 0 }}
                              key={keyx}
                            ></TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>

                    <TableBody>
                      {[160, 150, 140, 130, 120, 110, 100].map((data, key) => {
                        return (
                          <TableRow key={key}>
                            {key === 0 && (
                              <>
                                <TableCell
                                  rowSpan={60}
                                  colSpan={3}
                                  align="center"
                                  style={{
                                    fontSize: "10px",
                                  }}
                                >
                                  P<br />
                                  U<br />
                                  L<br />
                                  S<br />E
                                </TableCell>
                              </>
                            )}

                            <TableCell
                              align="center"
                              style={{
                                fontSize: "10px",
                              }}
                            >
                              {data}
                            </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    <TableBody>
                      {[90, 80, 70, 60, 50, 40, 30, 20].map((data, key) => {
                        return (
                          <TableRow key={key}>
                            {key === 0 && (
                              <>
                                <TableCell
                                  rowSpan={60}
                                  colSpan={3}
                                  align="center"
                                  style={{
                                    fontSize: "9px",
                                  }}
                                >
                                  R<br />E<br />S<br />P<br />I<br />R<br />A
                                  <br />T<br />O<br />R<br />Y
                                </TableCell>
                              </>
                            )}

                            <TableCell
                              align="center"
                              style={{
                                fontSize: "10px",
                              }}
                            >
                              {data}
                            </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    <TableBody>
                      {[1].map((data, key) => {
                        return (
                          <TableRow key={key}>
                            {key === 0 && (
                              <>
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  style={{
                                    fontSize: "10px",
                                  }}
                                >
                                  Stool
                                </TableCell>
                              </>
                            )}

                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                              16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
                              28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
                              40, 41, 42,
                            ].map((datax, keyx) => (
                              <TableCell
                                align="center"
                                style={{ padding: 0 }}
                                key={keyx}
                              ></TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    <TableBody>
                      {[1].map((data, key) => {
                        return (
                          <TableRow key={key}>
                            {key === 0 && (
                              <>
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  style={{
                                    fontSize: "10px",
                                  }}
                                >
                                  Urine
                                </TableCell>
                              </>
                            )}

                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                              16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
                              28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
                              40, 41, 42,
                            ].map((datax, keyx) => (
                              <TableCell
                                align="center"
                                style={{ padding: 0 }}
                                key={keyx}
                              ></TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    <TableBody>
                      {[1].map((data, key) => {
                        return (
                          <TableRow key={key}>
                            {key === 0 && (
                              <>
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  style={{
                                    fontSize: "10px",
                                  }}
                                >
                                  B.P
                                </TableCell>
                              </>
                            )}

                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                              16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
                              28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
                              40, 41, 42,
                            ].map((datax, keyx) => (
                              <TableCell
                                align="center"
                                style={{ padding: 0 }}
                                key={keyx}
                              ></TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    <TableBody>
                      {[1].map((data, key) => {
                        return (
                          <TableRow key={key}>
                            {key === 0 && (
                              <>
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  style={{
                                    fontSize: "10px",
                                  }}
                                >
                                  Weight
                                </TableCell>
                              </>
                            )}

                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                              16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
                              28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
                              40, 41, 42,
                            ].map((datax, keyx) => (
                              <TableCell
                                align="center"
                                style={{ padding: 0 }}
                                key={keyx}
                              ></TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </form>
      </CardContent>

      {/* dialog new meds dialog */}
      <Dialog
        open={newMeds}
        onClose={() => setNewMeds(false)}
        maxWidth={"xs"}
        fullWidth
        scroll="body"
      >
        <DialogTitle> New Medications </DialogTitle>

        <form onSubmit={handleNewMedications}>
          <DialogContent dividers>
            {medsList.length === 0 && (
              <>
                <Box>
                  <TextField
                    fullWidth
                    name="room_no"
                    variant={"outlined"}
                    label="Room No."
                  />
                </Box>

                <Box my={2}>
                  <TextField
                    name="physician"
                    variant={"outlined"}
                    label="Physician"
                    fullWidth
                    InputProps={{
                      style: {
                        textTransform: "capitalize",
                      },
                    }}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    value={chartPhysician}
                    onChange={(e) => setChartPhysician(e.target.value)}
                  >
                    <option value=""> Select </option>
                    {doctorsList.length > 0 ? (
                      doctorsList.map((data, key) => (
                        <option key={key} value={data.doctors_id}>
                          {data.name}
                        </option>
                      ))
                    ) : (
                      <option value=""> No doctor found. </option>
                    )}
                  </TextField>
                </Box>

                <Box my={2}>
                  <TextField
                    fullWidth
                    name="allergic_to"
                    variant={"outlined"}
                    label="Allergic to"
                    multiline
                  />
                </Box>

                <Box my={2}>
                  <TextField
                    fullWidth
                    name="year"
                    variant={"outlined"}
                    label="Year"
                  />
                </Box>
              </>
            )}

            <Box my={2}>
              <TextField
                fullWidth
                name="date_order"
                variant={"outlined"}
                label="Date Order"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>

            <Box my={2}>
              <TextField
                fullWidth
                name="renewal_date"
                variant={"outlined"}
                label="Renewal Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>

            <Box my={2}>
              <TextField
                fullWidth
                name="medication"
                variant={"outlined"}
                label="Medication"
                multiline
              />
            </Box>

            <Box my={2}>
              <TextField
                fullWidth
                name="dosage"
                variant={"outlined"}
                label="Dosage"
                multiline
              />
            </Box>

            <Box my={2}>
              <TextField
                fullWidth
                name="route_ofadmission"
                variant={"outlined"}
                label="Route of admission"
                multiline
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                name="frequency"
                variant={"outlined"}
                label="Frequency"
                multiline
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              variant="contained"
              startIcon={<XCircle />}
              onClick={() => setNewMeds(false)}
            >
              No
            </Button>

            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={
                isProcess ? (
                  <CircularProgress size={22} color="inherit" />
                ) : (
                  <CheckSquare />
                )
              }
              disabled={isProcess}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Card>
  );
};

export default FormTempAndPulse;
