import { forwardRef, useEffect, useState } from "react";

const SelectInputField = (props, ref) => {
	const {
		type = "text",
		label,
		className = "",
		labelClassName = "",
		inputClassName = "",
		register,
		options,
		disabled,
		error,
		placeholder,
		...rest
	} = props;
	const [selectOptions, setSelectOptions] = useState([]);
	useEffect(() => {
		let t = setTimeout(() => {
			if (JSON.stringify(selectOptions) != JSON.stringify(options)) {
				setSelectOptions(options);
			}
		}, 100);
		return () => {
			clearTimeout(t);
		};
	}, [JSON.stringify(options)]);
	return (
		<label
			className={`flex flex-col ${className || ""} ${
				disabled
					? "opacity-50 cursor-not-allowed pointer-events-none"
					: ""
			} ${error ? "text-danger" : ""}`}
		>
			{label ? (
				<label className={`text-sm font-medium mb-2 ${labelClassName}`}>
					{label}
				</label>
			) : (
				""
			)}
			<div className="w-full relative">
				<select
					ref={ref || register}
					{...rest}
					className={`border py-2.5 border-transparent text-sm text-dark sm:text-sm rounded-lg focus:ring-1 focus:shadow-lg duration-100 shadow-blue-300 focus:ring-blue-500 block w-full p-2.5 ${
						error ? "border-danger text-danger" : ""
					}  ${inputClassName ? inputClassName : ""}`}
				>
					{placeholder ? (
						<option selected diabled value="">
							{placeholder}
						</option>
					) : (
						" "
					)}
					{options.map((option, index) => {
						return (
							<option
								key={`option-${Math.random(100)}-${index}-${
									option.value
								}`}
								value={option.value}
								disabled={option?.disabled || false}
							>
								{option.label}
							</option>
						);
					})}
				</select>
			</div>
			{error ? <span className="text-danger text-sm">{error}</span> : ""}
		</label>
	);
};

export default forwardRef(SelectInputField);
