import { Grid, Box, Typography, Hidden } from "@material-ui/core";
import React, { useContext } from "react";
import Container from "../layout/Container";
import { UsersHeader } from "../ContextAPI";
import IncomeGraph from "./dashboard/IncomeGraph";
import IncomeCounts from "./dashboard/IncomeCounts";

const HMISDashboard = () => {
  const { sidebarHeader } = useContext(UsersHeader);

  return (
    <Container
      breadcrumbs={{
        enable: false,
        current: "qwe",
        items: [],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant={"h4"}>Dashboard</Typography>
            <Typography variant="subtitle1">
              Welcome back, {sidebarHeader.name} 👋
            </Typography>
          </Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <IncomeCounts />
          <Hidden>
            <IncomeGraph />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HMISDashboard;
