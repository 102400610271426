import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import {
	housing_electricity_sources,
	housing_status,
} from "../../../helpers/responseChoices";
const HouseForm = ({
	Controller,
	register,
	errors,
	setValue,
	control,
	watch,
}) => {
	const tenure_status = [
		{
			id: `housingone`,
			name: "Own or owner-like possession of house and lot",
		},
		{ id: `housingtwo`, name: "Rent house/room including lot" },
		{ id: `housingthree`, name: "Own house, rent lot" },
		{
			id: `housingfour`,
			name: "Own house, rent-free lot with consent of owner",
		},
		{
			id: `housingfive`,
			name: "Own house, rent-free lot without consent of owner",
		},
		{
			id: `housingsix`,
			name: "Rent-free house and lot with consent of owner",
		},
		{
			id: `housingseven`,
			name: "Rent-free house and lot without consent of owner",
		},
		{ id: `housingeight`, name: "Living in a public space without rent" },
		{ id: `housingnine`, name: "Other tenure status, specify" },
	];
	const yes_no = [
		{
			value: "yes",
			label: "1 Yes",
		},
		{
			value: "no",
			label: "2 No",
		},
	];
	const electricity_sources = [
		{ value: 1, label: `Electric company` },
		{ value: 2, label: `Generator` },
		{ value: 3, label: `Solar` },
		{ value: 4, label: `Battery` },
		{ value: 5, label: `Others, specify` },
	];
	const communications = [
		{ value: "internet", label: "Internet" },
		{ value: "tv", label: "TV" },
		{ value: "radio", label: "Radio" },
		{ value: "phone", label: "Phone" },
		{ value: "none", label: "None" },
	];
	return (
		<div className="flex flex-col gap-y-8 patient-form">
			<FormControl className="w-full">
				<FormLabel
					id="demo-controlled-radio-buttons-group"
					error={errors?.status}
				>
					L1. What is the tenure status of the housing unit and lot
					occupied by your household
					<span className="text-danger ml-2">*</span>
				</FormLabel>
				{errors?.status && (
					<FormHelperText error={errors?.status}>
						This field is required.
					</FormHelperText>
				)}
				<Controller
					rules={{ required: true }}
					name="status"
					control={control}
					render={({ field }) => (
						<RadioGroup {...field} key={`status-${field?.value}`}>
							{housing_status.map((data, index) => {
								return (
									<FormControlLabel
										key={`status-${data.value}`}
										className="!mb-0"
										value={data.value}
										control={<Radio />}
										label={`${data.label}`}
									/>
								);
							})}
						</RadioGroup>
					)}
				/>
			</FormControl>
			<TextField
				type="number"
				className="lg:w-1/2"
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={
					<>
						L3. How long have you been staying in your house?
						(Years)
						<span className="text-danger ml-2">*</span>
					</>
				}
				variant="outlined"
				{...register("residence_area", {
					required: true,
				})}
				error={errors?.residence_area}
				helperText={errors?.residence_area && "This field is required"}
			/>
			{/* <TextField
				type="number"
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={
					<>
						(63) In your own estimate, how much is the imputed rent
						per month for the house and/or lot?
						<span className="text-danger ml-2">*</span>
					</>
				}
				variant="outlined"
				{...register("housinggroup/residencearea", {
					 required: true,
				})}
			/> */}
			<FormControl className="w-full">
				<FormLabel
					id="demo-controlled-radio-buttons-group"
					error={errors?.electric}
				>
					L5. Is there any electricity in the dwelling place?
					<span className="text-danger ml-2">*</span>
				</FormLabel>
				{errors?.electric && (
					<FormHelperText error={errors?.electric}>
						This field is required.
					</FormHelperText>
				)}

				<Controller
					rules={{ required: true }}
					name="electric"
					control={control}
					render={({ field }) => (
						<RadioGroup {...field} key={`electric-${field?.value}`}>
							{yes_no.map((data, index) => {
								return (
									<FormControlLabel
										className="!mb-0"
										key={`electric-${data.value}`}
										value={data.value}
										control={<Radio />}
										label={data.label}
									/>
								);
							})}
						</RadioGroup>
					)}
				/>
			</FormControl>
			<FormControl className="w-full">
				<FormLabel
					id="demo-controlled-radio-buttons-group"
					error={errors?.electric_housing}
				>
					L6. What is the source of electricity in the dwelling place?
					<span className="text-danger ml-2">*</span>
				</FormLabel>
				{errors?.electric_housing && (
					<FormHelperText error={errors?.electric_housing}>
						This field is required.
					</FormHelperText>
				)}
				<Controller
					rules={{ required: true }}
					name="electric_housing"
					control={control}
					render={({ field }) => (
						<RadioGroup
							{...field}
							key={`electric_housing-${field?.value}`}
						>
							{housing_electricity_sources.map((data, index) => {
								return (
									<FormControlLabel
										className="!mb-0"
										key={`electric_housing-${data.value}`}
										value={data.value}
										control={<Radio />}
										label={data.label}
									/>
								);
							})}
						</RadioGroup>
					)}
				/>
			</FormControl>
			<FormControl className="w-full">
				<FormGroup>
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.com_channel}
					>
						L8. What communication channel do you get your primary
						means of information?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.com_channel && (
						<FormHelperText error={errors?.com_channel}>
							This field is required.
						</FormHelperText>
					)}

					<div className="flex flex-col gap-y-3 py-3">
						{communications.map((data, index) => {
							return (
								<label className="flex items-center gap-3">
									<span className="scale-[1.15]">
										<input
											type="checkbox"
											value={data.value}
											{...register("com_channel", {
												required: true,
											})}
										/>
									</span>
									<span className="text-sm form-label">
										{data.label}
									</span>
								</label>
							);
						})}
					</div>
				</FormGroup>
			</FormControl>
		</div>
	);
};

export default HouseForm;
