import {
  faCogs,
  faFlask,
  faHome,
  faImage,
  faUser,
  faUserInjured,
  faUserLock,
  faVial,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Logout from "../auth/Logout";
import { UsersData, UsersHeader } from "../ContextAPI";
import Sidebar from "../layout/Sidebar";
import PageNotFound from "../PageNotFound";
import CisDashboard from "src/cis-laboratory/CisDashboard";
import CisLaboratoryPatients from "src/cis-laboratory/CisLaboratoryPatients";
import CisServicesImaging from "src/cis-laboratory/CisServicesImaging";
import CisServicesSputum from "src/cis-laboratory/CisServicesSputum";
import CisServicesRT_PCR from "src/cis-laboratory/CisServicesRT_PCR";
import CisLaboratoryMyAccount from "src/cis-laboratory/CisLaboratoryMyAccount";

const RouteCISLaboratory = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);
  // console.log("userContextuserContext", userContext);
  const { users } = userContext;
  const getSidebarHeaderInfo = async () => {
    var params = { user_id: userContext.users.user_id };
    const { data } = await axios.get("admitting/sidebar/header-infomartion", {
      params,
    });
    setSidebarHeader(data);
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faHome} color="white" style={{ fontSize: 18 }} />
      ),
      path: "/sph/app/cis-laboratory/dashboard",
      subitem: [],
    },

    {
      name: "Services",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faCogs} color="white" style={{ fontSize: 18 }} />
      ),
      path: "/sph/app/cis-laboratory/services",
      subitem: [
        {
          name: "Imaging",
          show: true,
          icon: (
            <FontAwesomeIcon
              icon={faImage}
              color="white"
              style={{ fontSize: 18 }}
            />
          ),
          path: "/sph/app/cis-laboratory/services/imaging",
          subitem: [],
        },
        {
          name: "RT PCR",
          show: true,
          icon: (
            <FontAwesomeIcon
              icon={faVial}
              color="white"
              style={{ fontSize: 18 }}
            />
          ),
          path: "/sph/app/cis-laboratory/services/rt-pcr",
          subitem: [],
        },
        {
          name: "Sputum",
          show: true,
          icon: (
            <FontAwesomeIcon
              icon={faFlask}
              color="white"
              style={{ fontSize: 18 }}
            />
          ),
          path: "/sph/app/cis-laboratory/services/sputum",
          subitem: [],
        },
      ],
    },

    {
      name: "Patient List",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faUserInjured}
          color="white"
          style={{ fontSize: 18 }}
        />
      ),
      path: "/sph/app/cis-laboratory/patients",
      subitem: [],
    },
    {
      name: "My Account",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faUserLock}
          color="white"
          style={{ fontSize: 18 }}
        />
      ),
      path: "/sph/app/cis-laboratory/user",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
        }}
      >
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              owner: "pho",
            }}
            header={{
              ...sidebarHeader,
              name: users?.name,
              avatar: {
                name: users?.name,
                src:
                  users?.avatar ||
                  `https://avatars.dicebear.com/api/initials/${users?.name}.svg`,
              },
            }}
            routes={sidebarRoute}
            module={users?.type.replace("-", " ")}
          />
          <Switch>
            <Route
              exact
              path="/sph/app"
              component={() => (
                <Redirect to="/sph/app/cis-laboratory/dashboard" />
              )}
            />
            <Route
              exact
              path="/sph/app/cis-laboratory/dashboard"
              component={CisDashboard}
            />
            <Route
              exact
              path="/sph/app/cis-laboratory/services/imaging"
              component={CisServicesImaging}
            />
            <Route
              exact
              path="/sph/app/cis-laboratory/services/rt-pcr"
              component={CisServicesRT_PCR}
            />
            <Route
              exact
              path="/sph/app/cis-laboratory/services/sputum"
              component={CisServicesSputum}
            />
            <Route
              exact
              path="/sph/app/cis-laboratory/patients"
              component={CisLaboratoryPatients}
            />
            <Route
              exact
              path="/sph/app/cis-laboratory/user"
              component={CisLaboratoryMyAccount}
            />
            <Route exact path="/sph/app/logout" component={Logout} />
            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteCISLaboratory;
