import React, { useMemo, useRef, useState } from "react";
import LayoutContainer from "src/components/LayoutContainer";
import {
	faArrowLeft,
	faEdit,
	faPlus,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { formatDate } from "src/helpers/utils";
import CisAddPatientsImaging from "./CisAddPatientsImaging";
import { Box, Button, Card } from "@material-ui/core";
import { styles } from "../styles/styles";
import ButtonV3 from "src/components/ButtonV3";
import TableV2 from "src/components/table/TableV2";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import { v4 as uuidv4 } from "uuid";
import useDataTable from "src/helpers/useDataTable";
import Notify from "src/notification/Notify";
import ImagePicker from "src/components/forms/ImagePicker";
import Img from "src/components/Img";
import PreviewImageModal from "../PreviewImageModal";

export default function CisPatientImaging({ patient }) {
	const [shown, setShown] = useState(true);
	let patientId = patient?.id;
	const [image, setImage] = useState(null);
	const previewImageRef = useRef(null);

	const {
		data,
		loading: dataLoading,
		setFilters,
	} = useDataTable(`v1/laboratory/imaging/show-patient/${patientId}`);

	const refreshData = () => {
		setFilters((pevFils) => ({
			...setFilters,
			key: uuidv4(),
		}));
	};
	const columns = useMemo(
		() => [
			{
				header: "Image",
				accessorKey: "image",
				headerClassName: "text-center",
				className: "text-left",
				cell: (data) => {
					return (
						<img
							src={data?.image}
							key={`img-${data?.image}`}
							className="h-20 w-20 object-contain bg-slate-400 cursor-pointer"
							onClick={() => {
								previewImageRef.current.show({
									image: data?.image,
									title: data?.description,
								});
							}}
						/>
					);
				},
			},
			{
				header: "Description",
				accessorKey: "description",
				headerClassName: "text-center",
				className: "text-left",
			},
			{
				header: "Type",
				accessorKey: "type",
				headerClassName: "text-center",
				className: "text-left",
			},
		],
		[]
	);
	return (
		<div className="flex w-full flex-col">
			<div className="flex flex-col w-full mb-3">
				<h5 className="text-sm font-semibold text-indigo-700 w-2/3 flex items-center gap-2">
					<span>
						<img
							src="/landing-page.png"
							className="h-8 object-contain"
						/>
					</span>
					<div>Imaging</div>
					<ActionBtn
						size="sm"
						className="text-sm px-2 ml-"
						type="success"
						onClick={() => {
							setShown(false);
						}}
					>
						<FlatIcon icon="rr-plus" /> Create
					</ActionBtn>
				</h5>
				<div className="w-2/3 h-[1px] bg-indigo-600 mb-[2px]"></div>
				<div className="w-1/2 h-[1px] bg-indigo-400"></div>
			</div>
			<div className="flex w-full mb-2 items-center ">
				{shown ? (
					<></>
				) : (
					<ButtonV3
						onClick={() => {
							setShown(true);
						}}
					>
						<div className="flex">
							<FontAwesomeIcon
								icon={faArrowLeft}
								color="primary"
								style={{ fontSize: 18 }}
								className="pr-2"
							/>
							back
						</div>
					</ButtonV3>
				)}
			</div>

			{shown ? (
				<>
					{!dataLoading ? (
						<TableV2
							paginationClassName="px-4"
							columns={columns}
							pagination={true}
							loading={dataLoading}
							data={data?.data}
							onTableChange={(data) => {}}
						/>
					) : (
						Notify.loading()
					)}
				</>
			) : (
				<CisAddPatientsImaging
					refreshData={refreshData}
					setShown={setShown}
					patient={patient}
				/>
			)}

			<PreviewImageModal ref={previewImageRef} />
		</div>
	);
}
