import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";

import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Notify from "src/notification/Notify";

const AddItemStockModal = (props, ref) => {
	const { updateInList } = props;
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();
	const [item, setItem] = useState(null);
	const [qty, setQty] = useState(0);
	const [open, setOpen] = useState(false);

	const [loading, setLoading] = useState(false);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setItem(data);
		setQty(0);
		reset({ qty: 0 });
		setOpen(true);
	};
	const hide = () => {
		setItem(null);
		setQty(0);
		reset({ qty: 0 });
		setOpen(false);
	};
	const submit = (data) => {
		setLoading(true);
		let formData = new FormData();

		formData.append("qty", data.qty);
		formData.append("_method", "PATCH");
		axios
			.post(`/v1/item-inventory/stock-in/${item?.id}`, formData)
			.then((res) => {
				updateInList(res.data.data);
				setTimeout(() => {
					Notify.customToast(
						"success",
						"Stock updated successfully!"
					);
				}, 400);
				hide();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Modal open={open} hide={hide} className="max-w-[768px] !w-[512px]">
			<ModalHeader
				title={`Add stocks to item`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="flex flex-col lg:grid lg:grid-cols-3 gap-x-2 gap-y-3 mb-4">
					<span>Item Code:</span>
					<b className="lg:col-span-2">{item?.code}</b>
					<span>Item Name:</span>
					<b className="lg:col-span-2">{item?.name}</b>
					<span>Description:</span>
					<b className="lg:col-span-2">{item?.description}</b>
					<span>Unit:</span>
					<b className="lg:col-span-2">{item?.unit_measurement}</b>
				</div>
				<div className="flex flex-col lg:grid lg:grid-cols-3 gap-6">
					<TextField
						className="lg:col-span-3 !text-center"
						SelectProps={{ native: true }}
						InputProps={{
							className: "text-center",
						}}
						InputLabelProps={{
							shrink: true,
						}}
						label={`Stock to add`}
						type="number"
						variant="outlined"
						error={Boolean(errors?.qty?.message)}
						helperText={errors?.qty?.message}
						{...register("qty", {
							required: "This field is required.",
						})}
					/>
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center"}>
				<ActionBtn
					className="ml-auto"
					onClick={handleSubmit(submit)}
					loading={loading}
				>
					Submit
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(AddItemStockModal);
