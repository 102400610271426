import React from "react";
import Header from "../editPatientProfile/Header";
import { styles } from "../../styles/styles";
import { formatDate } from "src/helpers/utils";

export default function OtherInformation({ patient }) {
  return (
    <>
      <Header title={"Other Information"} />
      <div className="px-4 mb-3">
        <div style={styles.parentDivContainerNoGap}>
          <div className={styles.borderRight}>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Philhealth Category</label>
                <div className={styles.textContainer}>
                  {patient?.phil_health_category_type}
                </div>
              </div>
              <div style={styles.divContainer}>
                <label className={styles.tuiLabel}>Enlistment Date</label>
                <div className={styles.textContainer}>
                  {formatDate(new Date(patient?.enlistment_date))}
                </div>
              </div>
            </div>

            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>Philhealth Status Type</label>
              <div className={styles.textContainer}>
                {[patient?.phil_health_status_type]}
              </div>
            </div>

            <div className={styles.tuiSpaceBetween}>
              <label className={styles.tuiLabel}>PCB Eligible:</label>
              <div className={styles.checkContainer}>
                {patient?.pcb_eligble === true ? "Yes" : "No"}
              </div>
            </div>
            <div className={styles.tuiSpaceBetween}>
              <label className={styles.tuiLabel}>Philhealth Member:</label>
              <div className={styles.checkContainer}>
                {patient?.phil_health_member === true ? "Yes" : "No"}
              </div>
            </div>

            <div className={styles.tuiSpaceBetween}>
              <label className={styles.tuiLabel}>DSWD NHTS Member:</label>
              <div className={styles.checkContainer}>
                {patient?.dswd_nhts === true ? "Yes" : "No"}
              </div>
            </div>
          </div>

          <div className={styles.borderLeft}>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>Family Serial Number</label>
              <div className={styles.textContainer}>
                {patient?.family_serial_no}
              </div>
            </div>
            <div style={styles.divContainer}>
              <label className={styles.tuiLabel}>Family Member</label>
              <div className={styles.textContainer}>
                {patient?.family_member}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
