import React from "react";
import { Box, Typography, Grid, CardMedia } from "@material-ui/core";
import Notify from "src/notification/Notify";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const PatientInfoPrintAll = ({ details }) => {
  return (
    <>
      {details.ready && details.data ? (
        <Box mt={2}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box>
                <Box>
                  <Typography className="font-weight-bold" variant="subtitle1">
                    Name:{" "}
                    {`${details.data.lastname}, ${details.data.firstname} ${
                      details.data.middle === null ? "" : details.data.middle
                    }`}
                  </Typography>
                </Box>

                <Box display="flex">
                  <Box>
                    <Typography
                      className="font-weight-bold"
                      variant="subtitle1"
                    >
                      Age:{" "}
                      {details.data.birthday !== null
                        ? Notify.calculateAge(details.data.birthday)
                        : ""}
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Typography
                      className="font-weight-bold"
                      variant="subtitle1"
                    >
                      Sex:{" "}
                      {details.data.gender !== null ? details.data.gender : ""}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography className="font-weight-bold" variant="subtitle1">
                    Company:{" "}
                    {details.data.company_name !== null
                      ? details.data.company_name
                      : ""}
                  </Typography>
                </Box>

                <Box>
                  <Typography className="font-weight-bold" variant="subtitle1">
                    Birth Date:{" "}
                    {details.data.birthday !== null
                      ? Notify.createdAt(details.data.birthday)
                      : ""}
                  </Typography>
                </Box>

                <Box>
                  <Typography className="font-weight-bold" variant="subtitle1">
                    Date: {new Date().toDateString()}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center">
                <Box flexGrow={1} />
                <Box>
                  {details.data.image !== null ? (
                    <CardMedia
                      style={{ width: 120 }}
                      component={"img"}
                      src={`${imageLocation}patients/${details.data.image}`}
                    />
                  ) : (
                    <CardMedia
                      style={{ width: 110 }}
                      component={"img"}
                      src={`${imageLocation}doctor/no-image.jpg`}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
};

export default PatientInfoPrintAll;
