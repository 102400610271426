import React, {
	useCallback,
	useEffect,
	useState,
	Fragment,
	useContext,
} from "react";
import { CardHeader, makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
// import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import Notify from "src/notification/Notify";
// import SearchIcon from "@material-ui/icons/Search";
import Container from "../layout/Container";
import { UsersData } from "../ContextAPI";
import PatientDetails from "./patients/PatientDetails";
// import NewPatient from "./NewPatient";
import { Search, UserPlus, XCircle } from "react-feather";
import NewPatientForm from "./NewPatientForm";
import Img from "src/components/Img";
import Pagination from "src/components/table/Pagination";
import TextInputField from "src/components/forms/TextInputField";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
	primary: {
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
}));

const AdmissionPatientList = () => {
	const classes = useStyles();
	const { users } = useContext(UsersData);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [selectedPatient, setSelectedPatient] = useState("new-patient");
	const [searchDisplay, setSearchDisplay] = useState(false);
	const [loading, setLoading] = useState(false);
	const [keyword, setKeyword] = useState("");

	const [page, setPage] = useState(1);
	const [pageCount, setPageCount] = useState(12);
	const [pageSize, setPageSize] = useState(12);
	const [meta, setMeta] = useState(null);

	const [patient, setPatient] = useState({
		data: [],
		ready: false,
	});

	const [search, setSearch] = useState("");

	const getDoctorsPatient = useCallback(() => {
		var params = {
			user_id: users.user_id,
			management_id: users.management_id,
			main_mgmt_id: users.main_mgmt_id,
		};
		Axios.get("v1/patients", { params })
			.then((response) => {
				const data = response.data;
				console.log("datadatadata", data);
				setPatient({ data: data.data, ready: true });
				setMeta(data.meta);
				setPage(data.meta?.current_page);
				setPageCount(data.meta?.last_page);
				setPageSize(data.meta?.per_page);
			})
			.catch((error) => {
				console.log("error: ", error);
				// Notify.requestError(error);
			});
	}, [users]);

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	useEffect(() => {
		getDoctorsPatient();
	}, [getDoctorsPatient]);

	useEffect(() => {
		let t = setTimeout(() => {
			getPatientData();
		}, 700);

		return () => {
			clearTimeout(t);
			setLoading(false);
		};
	}, [page, pageSize, keyword]);
	const getPatientData = () => {
		setLoading(true);
		Axios.get(
			`v1/patients?page=${page}&paginate=${pageSize}&keyword=${keyword}`
		)
			.then((response) => {
				const data = response.data;
				console.log("datadatadata", data);
				setPatient({ data: data.data, ready: true });
				setMeta(data.meta);
				setPageCount(data.meta?.last_page);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const searchable = ""; /* patient.filter((data) => {
		return (
			data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
			data.lastname.toLowerCase().indexOf(search.trim()) !== -1
		);
	}); */
	const updatePatientData = (data) => {
		console.log("updatePatientData", data);
		setPatient((currentData) => ({
			...currentData,
			data: currentData?.data?.map((x) =>
				x.id == data?.id
					? {
							...x,
							avatar: data?.avatar,
					  }
					: x
			),
		}));
	};

	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: true,
					current: "patient list",
					items: [
						{ name: "dashboard", path: "/sph/app/registration" },
					],
				}}
				title={
					<Box display="flex">
						<Box flexGrow={1}>Patient List</Box>
						<Box>
							<Button
								color="primary"
								onClick={() =>
									setSelectedPatient("new-patient")
								}
								startIcon={<UserPlus />}
								variant="contained"
								hidden={selectedPatient === "new-patient"}
							>
								New Patient
							</Button>
						</Box>
					</Box>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} md={4} lg={3}>
						<div className="bg-white shadow-sm rounded-lg flex flex-col p-3 gap-3 mb-4">
							<h4 className="text-lg font-bold">Patient List</h4>
							<TextInputField
								placeholder="Search Patient"
								onChange={(e) => {
									setPage(1);
									setKeyword(e.target.value);
								}}
							/>
							<div className="flex flex-col gap-y-0">
								{loading ? (
									<b className="text-placeholder animate-pulse">
										Loading...
									</b>
								) : (
									<>
										{patient.data?.map((data) => {
											return (
												<div
													className="flex items-center gap-4 hover:bg-slate-200 duration-200 cursor-pointer p-2"
													onClick={() => {
														setSelectedPatient(
															data.patient_id
														);
													}}
												>
													<Img
														src=""
														type="user"
														name={`${
															data.lastname
														}, ${data.firstname} ${
															Boolean(data.middle)
																? data.middle
																: ``
														}`}
														className="w-10 h-10 rounded-full object-contain bg-slate-300"
													/>
													<span className="text-base">
														{`${data.lastname}, ${
															data.firstname
														} ${
															Boolean(data.middle)
																? data.middle
																: ``
														}`}
													</span>
												</div>
											);
										})}
									</>
								)}
							</div>
							<div>
								<Pagination
									page={page}
									setPage={setPage}
									pageCount={pageCount}
									pageSize={pageSize}
									setPageSize={setPageSize}
									paginationClassName="flex !flex-col !items-start"
								/>
							</div>
						</div>
						{/* <Card>
							<CardHeader
								title={
									<Box display="flex">
										<Box flexGrow={1}> Patient List </Box>
										<Box>
											<IconButton
												color={
													!searchDisplay
														? "primary"
														: "secondary"
												}
												onClick={() =>
													setSearchDisplay(
														!searchDisplay
													)
												}
											>
												{!searchDisplay ? (
													<Search />
												) : (
													<XCircle />
												)}
											</IconButton>
										</Box>
									</Box>
								}
							/>

							<CardContent>
								<Box mb={1} hidden={!searchDisplay}>
									<TextField
										label="Search Patient"
										variant="outlined"
										fullWidth
										value={search}
										onChange={(e) =>
											setSearch(e.target.value)
										}
										InputProps={{
											endAdornment: (
												<InputAdornment>
													<Search />
												</InputAdornment>
											),
										}}
									/>
								</Box>

								<Box>
									{patient.ready ? (
										patient.data.length > 0 ? (
											<List component="div">
												{patient.data.map(
													(data, index) => (
														<ListItem
															key={index}
															button
															className="mb-2"
															onClick={() =>
																setSelectedPatient(
																	data.patient_id
																)
															}
															selected={
																selectedPatient ===
																data.patient_id
															}
														>
															<ListItemIcon>
																{data.image ===
																null ? (
																	<Avatar
																		className={
																			classes.primary
																		}
																	>
																		{data.lastname
																			.charAt()
																			.toUpperCase()}
																	</Avatar>
																) : (
																	<Avatar
																		className={
																			classes.primary
																		}
																		src={
																			data.avatar
																		}
																		alt=""
																	/>
																)}
															</ListItemIcon>
															<ListItemText
																className={`gtc-capitalize`}
																primary={`${
																	data.lastname
																}, ${
																	data.firstname
																} ${
																	Boolean(
																		data.middle
																	)
																		? data.middle
																		: ``
																} `}
															/>
														</ListItem>
													)
												)}
											</List>
										) : (
											Notify.noRecord()
										)
									) : (
										Notify.loading()
									)}
								</Box>

								<TablePagination
									component="div"
									count={searchable.length}
									rowsPerPageOptions={[10, 50, 100]}
									page={page}
									onChangePage={handleChangePage}
									rowsPerPage={rowsPerPage}
									onChangeRowsPerPage={
										handleChangeRowsPerPage
									}
									labelRowsPerPage="List"
								/>
							</CardContent>
						</Card> */}
					</Grid>

					<Grid item xs={12} md={8} lg={9}>
						{selectedPatient === "new-patient" ? (
							<NewPatientForm
								getDoctorsPatient={getDoctorsPatient}
							/>
						) : (
							<PatientDetails
								updatePatientData={updatePatientData}
								patient_id={selectedPatient}
								getDoctorsPatient={getDoctorsPatient}
							/>
						)}
					</Grid>
				</Grid>
			</Container>
		</Fragment>
	);
};

export default AdmissionPatientList;
