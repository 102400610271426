import React, { useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TextField,
  Grid,
  CardMedia,
} from "@material-ui/core";
import { CheckSquare } from "react-feather";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import moment from "moment";
import { DateTimePicker } from "@material-ui/pickers";

const NSPatientHistoryNew = ({ patient_id, info, getAllIllnessPE, close }) => {
  const [isProcess, setIsProcess] = useState(false);
  const printableRef = React.useRef();
  const { users } = React.useContext(UsersData);
  const [chartAdmissionDate, setChartAdmissionDate] = useState(null);
  const [chartDischargeDate, setChartDischargeDate] = useState(null);
  const [chartAttendingPhysician, setChartAttendingPhysician] = useState("");
  const [chartAdmittingImpression, setChartAdmittingImpression] = useState("");
  const [chartChieComplaint, setChartChieComplaint] = useState("");
  const [chartPertinentPE, setChartPertinentPE] = useState("");
  const [chartPasthistory, setChartPastHistory] = useState("");
  const [chartFamilyHistory, setChartFamilyHistory] = useState("");
  const [chartMedication, setChartMedication] = useState("");
  const [chartProcedure, setChartProcedure] = useState("");
  const [chartLabResult, setChartLabResult] = useState("");
  const [chartCourseInWard, setChartCourseInWard] = useState("");
  const [chartRecommendation, setChartRecommendation] = useState("");
  const [chartConditionOnDischarge, setChartConditionOnDischarge] =
    useState("");
  const [chartFinalDiagnosis, setChartFinalDiagnosis] = useState("");

  const handleMedicalAbstract = (e) => {
    e.preventDefault();
    e.persist();

    var form = new FormData(e.target);
    form.append("user_id", users.user_id);
    form.append("main_mgmt_id", users.main_mgmt_id);
    form.append("management_id", users.management_id);
    form.append("patient_id", patient_id);
    form.append(
      "datetime_admission",
      moment(chartAdmissionDate).format("YYYY-MM-DD HH:mm:ss")
    );
    form.append(
      "datetime_discharge",
      moment(chartDischargeDate).format("YYYY-MM-DD HH:mm:ss")
    );
    form.append("attending_physician", chartAttendingPhysician);
    form.append("admitting_impression", chartAdmittingImpression);
    form.append("chief_complaint", chartChieComplaint);
    form.append("pertinent_pe", chartPertinentPE);
    form.append("past_history", chartPasthistory);
    form.append("fam_history", chartFamilyHistory);
    form.append("medications", chartMedication);
    form.append("procedures", chartProcedure);
    form.append("laboratory_results", chartLabResult);
    form.append("course_inthe_ward", chartCourseInWard);
    form.append("recommendation", chartRecommendation);
    form.append("condition_on_discharge", chartConditionOnDischarge);
    form.append("final_diagnosis", chartFinalDiagnosis);

    let err = [];

    if (form.get("chief_complaint") === null) {
      err = "error";
      Notify.fieldRequired("chief complaint");
    }

    if (err.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcess(true);
      axios
        .post("nurse/create/history-illness-pe/patient-id", form)
        .then((response) => {
          let data = response.data;
          if (data === "success") {
            Notify.successRequest("history created");
            getAllIllnessPE();
            close();
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsProcess(false));
    }
  };

  return (
    <div>
      <Card ref={printableRef}>
        <CardContent>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Box align="center">
                  <CardMedia
                    component="img"
                    src="/bmcdc_logo.png"
                    alt="bmcdc logo"
                  />
                </Box>
              </Grid>

              <Grid item xs={8}>
                <Box align="center">
                  <Typography variant="h3">
                    <b>GLOBAL TELEMEDICINE CORP, INC.</b>
                  </Typography>
                  <Typography>
                    <b>MATINA PANGI, DAVAO CITY</b>
                  </Typography>
                  <Typography>
                    <b>Tel. 000-0000 Telefax: 000-0000</b>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box align="center">
                  <CardMedia
                    component="img"
                    src="/bmcdc_medical.png"
                    alt="medical logo"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box my={1}>
            <Typography align="center">
              <b>HISTORY OF PRESENT ILLNESS</b>
            </Typography>
          </Box>
          <form onSubmit={handleMedicalAbstract}>
            <Box>
              <Box my={1}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                  mb={1}
                >
                  <Box ml={2}>
                    <Button
                      color="primary"
                      startIcon={
                        isProcess ? (
                          <CircularProgress size={22} color="inherit" />
                        ) : (
                          <CheckSquare />
                        )
                      }
                      type="submit"
                      disabled={isProcess}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box my={2}>
              <Table className="table-bordered">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography variant="caption">Name:</Typography>
                      <Typography>
                        {info.ready
                          ? `${info.data.lastname}, ${info.data.firstname} ${
                              info.data.middle === null ? "" : info.data.middle
                            }`
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={4}>
                      <Typography variant="caption"> Age </Typography>
                      <Typography>
                        {info.ready
                          ? info.data.birthday === null
                            ? ""
                            : Notify.calculateAge(info.data.birthday)
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={4}>
                      <Typography variant="caption"> Gender </Typography>
                      <Typography>
                        {info.ready ? info.data.gender : ""}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography variant="caption">
                        Date/Time of Admission:
                      </Typography>
                      <Box>
                        <DateTimePicker
                          label=""
                          value={chartAdmissionDate}
                          onChange={(date) => setChartAdmissionDate(date)}
                          autoOk
                          fullWidth
                        />
                      </Box>
                    </TableCell>
                    <TableCell colSpan={6}>
                      <Typography variant="caption">
                        Date/Time of Discharge:
                      </Typography>
                      <Box>
                        <DateTimePicker
                          label=""
                          value={chartDischargeDate}
                          onChange={(date) => setChartDischargeDate(date)}
                          autoOk
                          fullWidth
                        />
                      </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography variant="caption">
                        Attending Physician:
                      </Typography>
                      <Box>
                        <TextField
                          fullWidth
                          name=""
                          value={chartAttendingPhysician}
                          onChange={(e) =>
                            setChartAttendingPhysician(e.target.value)
                          }
                        />
                      </Box>
                    </TableCell>
                    <TableCell colSpan={6}>
                      <Typography variant="caption">
                        Admitting Impression:
                      </Typography>
                      <Box>
                        <TextField
                          fullWidth
                          name=""
                          value={chartAdmittingImpression}
                          onChange={(e) =>
                            setChartAdmittingImpression(e.target.value)
                          }
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            <Box>
              <Box my={2}>
                <Typography variant="subtitle2">
                  Brief Clinical History / Chief Complaint
                </Typography>
                <TextField
                  fullWidth
                  name=""
                  value={chartChieComplaint}
                  onChange={(e) => setChartChieComplaint(e.target.value)}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">
                  Pertinent Physical Exam
                </Typography>
                <TextField
                  fullWidth
                  name=""
                  value={chartPertinentPE}
                  onChange={(e) => setChartPertinentPE(e.target.value)}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">
                  Past Medical History
                </Typography>
                <TextField
                  fullWidth
                  name=""
                  value={chartPasthistory}
                  onChange={(e) => setChartPastHistory(e.target.value)}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Family History</Typography>
                <TextField
                  fullWidth
                  name=""
                  value={chartFamilyHistory}
                  onChange={(e) => setChartFamilyHistory(e.target.value)}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Medications</Typography>
                <TextField
                  fullWidth
                  name=""
                  value={chartMedication}
                  onChange={(e) => setChartMedication(e.target.value)}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Procedures</Typography>
                <TextField
                  fullWidth
                  name=""
                  value={chartProcedure}
                  onChange={(e) => setChartProcedure(e.target.value)}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Laboratory Results</Typography>

                <TextField
                  fullWidth
                  name=""
                  value={chartLabResult}
                  onChange={(e) => setChartLabResult(e.target.value)}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Course in the Ward</Typography>
                <TextField
                  fullWidth
                  name=""
                  value={chartCourseInWard}
                  onChange={(e) => setChartCourseInWard(e.target.value)}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Recommendation</Typography>
                <TextField
                  fullWidth
                  name=""
                  value={chartRecommendation}
                  onChange={(e) => setChartRecommendation(e.target.value)}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">
                  Condition on Discharge
                </Typography>

                <TextField
                  fullWidth
                  name=""
                  value={chartConditionOnDischarge}
                  onChange={(e) => setChartConditionOnDischarge(e.target.value)}
                />
              </Box>

              <Box my={2}>
                <Typography variant="subtitle2">Final Diagnosis</Typography>
                <TextField
                  fullWidth
                  name=""
                  value={chartFinalDiagnosis}
                  onChange={(e) => setChartFinalDiagnosis(e.target.value)}
                />
              </Box>
            </Box>

            <Box my={3} display={"flex"} justifyContent={"flex-start"}>
              <Box align="center">
                <Box borderBottom={1} px={1}>
                  <Typography className="text-capitalize">
                    {chartAttendingPhysician}
                  </Typography>
                </Box>
                <Typography variant="caption">
                  Signature Over Printed Name of Physician
                </Typography>
              </Box>
            </Box>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default NSPatientHistoryNew;
