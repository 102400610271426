import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { formatDate } from "src/helpers/utils";
import { styles } from "../../styles/styles";
import { Box, Card, TablePagination } from "@material-ui/core";
import axios from "axios";
import Notify from "src/notification/Notify";

export default function CisPatientMedication({ patient }) {
  const [shown, setShown] = useState(true);
  const patientId = patient.id;
  const [patientMedication, setPatientMedication] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    axios
      .get(`v1/patient/medication/${patientId}`)
      .then(function (response) {
        // const notes = response.data;
        setPatientMedication(response.data);
        setLoading(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(true);
      })
      .finally(function () {
        // always executed
      });
  }, [shown]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const data = [
    {
      id: 123,
      date: "12/23/2022 ",
      time: "06:30 am",
      diagnosis: "Diagnosis1",
      medication: "medication1",
    },
    {
      id: 456,
      date: "12/28/2022",
      time: "03:30 am",
      diagnosis: "Diagnosis2",
      medication: "medication2",
    },
    {
      id: 789,
      date: "12/31/2022 ",
      time: "09:30 am",
      diagnosis: "Diagnosis3",
      medication: "medication3",
    },
  ];
  return (
    <div className="flex w-full flex-col pr-2">
      <div className="flex w-full mb-2 ">
        <div style={styles.headerContainer}>
          <b className="text-2xl">Medication</b>
        </div>
      </div>
      {shown ? (
        <>
          {loading ? (
            patientMedication.data.length > 0 ? (
              <Card elevation={3} className="p-4" component={Box}>
                <TableContainer style={styles.parentContainer}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={styles.tableHeader}>
                          <strong>Date</strong>
                        </TableCell>
                        <TableCell style={styles.tableHeader}>
                          <strong>Medication</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patientMedication.data.map(
                        ({ id, date, medication }) => (
                          <>
                            <TableRow className={styles.tableHover} key={id}>
                              <TableCell>{`${formatDate(
                                new Date(date)
                              )}`}</TableCell>

                              <TableCell>{medication}</TableCell>
                            </TableRow>
                          </>
                        )
                      )}
                    </TableBody>
                  </Table>

                  <TablePagination
                    labelRowsPerPage="List"
                    rowsPerPageOptions={[
                      10,
                      50,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    component="div"
                    count={patientMedication.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Card>
            ) : (
              Notify.noRecord()
            )
          ) : (
            Notify.loading()
          )}
        </>
      ) : null}
    </div>
  );
}
