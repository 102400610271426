import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Box,
  CardActions,
  Button,
} from "@material-ui/core";
import Container from "src/layout/Container";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import { useHistory } from "react-router-dom";
import IsMountedRef from "src/utils/IsMountedRef";
import Notify from "src/notification/Notify";

const AdmittingRooms = () => {
  const { users } = useContext(UsersData);
  const [list, setList] = useState([]);
  const mounted = IsMountedRef();

  const history = useHistory();

  const getRoomList = React.useCallback(async () => {
    let { data } = await axios.get(`admitting/rooms/get-roomlist`, {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setList(data);
    }
  }, [mounted, users]);

  useEffect(() => {
    getRoomList();
  }, [getRoomList]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "queueing",
          items: [{ name: "Dashboard", path: "/sph/app" }],
        }}
        title="Rooms"
      >
        <Grid container spacing={3}>
          {list.length > 0 ? (
            list.map((data, key) => (
              <Grid item xs={12} md={4} lg={3} key={key}>
                <Card>
                  <CardHeader title={data.room_name} />
                  <CardContent>
                    <Box mb={2}>
                      <Typography variant="caption"> NO OF ROOM </Typography>
                      <Typography> &bull; {data.no_of_rooms} </Typography>
                    </Box>

                    <Box>
                      <Typography variant="caption"> TYPE </Typography>
                      <Typography> &bull; {data.type} </Typography>
                    </Box>
                  </CardContent>

                  <CardActions>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        history.push(`/sph/app/admitting/rooms/${data.room_id}`)
                      }
                    >
                      View
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Box component={Grid} xs={12} textAlign={"center"}>
              {Notify.loading()}
            </Box>
          )}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default AdmittingRooms;
