import SelectInput from "@material-ui/core/Select/SelectInput";
import Tippy from "@tippyjs/react";
import axios from "axios";
import { useEffect, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import SelectInputField from "src/components/forms/SelectInputField";
import TextInputField from "src/components/forms/TextInputField";
import { v4 as uuidv4 } from "uuid";
const first_id = uuidv4();
const ItemRow = (props) => {
	const { item, medicalItems, addRow, removeRow, updateItems } = props;

	return (
		<div className="flex items-start gap-5 w-full">
			<div className="flex flex-col w-1/3">
				<SelectInputField
					label="Select item"
					// value={paginationState.pageSize}
					onChange={(e) => {
						// setSelected(
						// 	medicalItems.find((x) => x.id == e.target.value)
						// );
						updateItems(
							item?.key,
							medicalItems.find((x) => x.id == e.target.value)
						);
					}}
					options={medicalItems?.map((x) => {
						return {
							value: x.id,
							label: x.item?.name,
						};
					})}
					className=" mb-2"
					inputClassName="font-bold"
				/>
			</div>
			<div className="flex items-center gap-1 text-sm">
				<TextInputField
					label="Unit"
					placeholder="Quantity"
					readOnly
					value="Unit here"
				/>
			</div>
			<div className="w-[128px]">
				<TextInputField
					label="Quantity"
					className="text-center"
					inputClassName="text-center !bg-white !border-blue-300"
					placeholder="0"
				/>
			</div>
			<div className="flex items-center gap-1 pt-4">
				<Tippy content="Click to add more items">
					<ActionBtn type="success" onClick={addRow}>
						<FlatIcon icon="rr-plus" />
					</ActionBtn>
				</Tippy>
				<Tippy content="Click to remove item">
					<ActionBtn
						type="danger"
						onClick={() => {
							removeRow(item);
						}}
					>
						<FlatIcon icon="rr-cross" />
					</ActionBtn>
				</Tippy>
			</div>
		</div>
	);
};
const SelectItemsSelector = (props) => {
	const [items, setItems] = useState([
		{
			key: first_id,
		},
	]);
	const addItemRow = () => {
		setItems((prevItems) => [...prevItems, { key: uuidv4() }]);
	};
	const removeItemRow = (data) => {
		if (items?.length > 1) {
			setItems((prevItems) => prevItems.filter((x) => x.id != data?.id));
		}
	};

	const [medicalItems, setMedicalItems] = useState([]);

	const getInventories = () => {
		axios.get(`v1/item-inventory`).then((res) => {
			setMedicalItems(res.data.data);
		});
	};
	useEffect(() => {
		getInventories();
	}, []);
	const updateItems = (key, data) => {
		setItems((prevItems) =>
			prevItems.map((x) => (x.key == key ? { ...x, ...data } : x))
		);
	};
	return (
		<div className="flex flex-col w-full gap-y-3">
			{items?.map((item, index) => {
				return (
					<ItemRow
						medicalItems={medicalItems}
						item={item}
						addRow={addItemRow}
						removeRow={removeItemRow}
						updateItems={updateItems}
						key={`xitem-row-${index}`}
					/>
				);
			})}
		</div>
	);
};

export default SelectItemsSelector;
