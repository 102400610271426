import { Typography, Box, Grid, TextField } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
// import Footer from "src/laboratory/print/Footer";

const StooltestOrder = ({ order_id, patient_id, formheader }) => {
	const { users } = React.useContext(UsersData);

	const [details, setDetails] = useState([]);

	const getpaidLaboratoryOrderDetails = async () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set("trace_number", order_id);
		formdata.set("table", "laboratory_stooltest");
		formdata.set("connection", "local");

		try {
			let response = await axios.post(
				"doctor/patient/laboratory/order/paid-detailsbytable",
				formdata
			);
			const data = response.data;

			setDetails(data);
		} catch (error) {
			console.log("lab form order laboratory_hematology table error", error);
		}
	};

	useEffect(() => {
		getpaidLaboratoryOrderDetails();

		// eslint-disable-next-line
	}, []);

	return (
		<>
			{details.length > 0 && (
				<>
					<Box p={2}>
						{/* order details */}
						{details.map((data, index) => (
							<Fragment key={index}>
								{/* fecalysis order */}
								<Box hidden={!Boolean(parseInt(data.fecalysis))}>
									<Box mb={2} display="flex">
										<Box flexGrow={1}>
											<Typography variant="h6" align="center">
												<b> FECALYSIS </b>
											</Typography>
										</Box>
									</Box>

									<Box hidden={!Boolean(parseInt(data.is_processed))}>
										{/* hidden inputs */}
										<Box>
											<TextField
												margin="dense"
												fullWidth
												name="order_id[]"
												label="order_id"
												value={data.order_id}
												hidden
											/>
											<TextField
												margin="dense"
												fullWidth
												name="patient_id"
												label="patient_id"
												value={data.patient_id}
												hidden
											/>

											<TextField
												margin="dense"
												fullWidth
												name="doctors_id"
												label="doctor_id"
												value={data.doctor_id === null ? "" : data.doctor_id}
												hidden
											/>
										</Box>
										{/* fecal hidden if not process */}
										<Box>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={4}>
													<Box>
														<TextField
															label="Color"
															fullWidth
															defaultValue={data.color}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>
												</Grid>
												<Grid item xs={12} sm={4}>
													<Box>
														<TextField
															label="Consistency"
															defaultValue={data.consistency}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>
												</Grid>
												<Grid item xs={12} sm={4}>
													<Box>
														<TextField
															label="Occult Blood"
															defaultValue={data.occult_blood_result}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>
												</Grid>
											</Grid>
										</Box>

										<Box>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={3}>
													<Box mt={2}>
														<Typography>
															<strong> Microscopy </strong>
														</Typography>
													</Box>

													<Box mt={3}>
														<Typography>Ascaris Lumbricoides:</Typography>
													</Box>

													<Box>
														<Typography> Hookworm: </Typography>
													</Box>

													<Box mt={2}>
														<Typography>Blastocystis Hominis:</Typography>
													</Box>

													<Box mt={2.1}>
														<Typography>
															<strong> Giardia Lamblia </strong>
														</Typography>
													</Box>

													<Box>
														<Typography align="right"> Cyst: </Typography>
													</Box>

													<Box mt={1}>
														<Typography align="right">Trophozoite:</Typography>
													</Box>

													<Box mt={1}>
														<Typography> Trichuris Trichiura </Typography>
													</Box>

													<Box mt={1.3}>
														<Typography>
															<strong> Entamoeba Histolytica </strong>
														</Typography>
													</Box>

													<Box mt={2}>
														<Typography align="right"> Cyst: </Typography>
													</Box>

													<Box mt={1}>
														<Typography align="right">Trophozoite:</Typography>
													</Box>
												</Grid>

												<Grid item xs={12} sm={3}>
													<Box align="center" mt={3.2}>
														{data.dfs ? (
															<CheckBox color="primary" />
														) : (
															<CheckBoxOutlineBlank />
														)}
														Direct Fecal Smear
													</Box>

													<Box>
														<TextField
															label=""
															fullWidth
															defaultValue={data.dfs_ascaris}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>

													<Box>
														<TextField
															label=""
															fullWidth
															defaultValue={data.dfs_hookworm}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>

													<Box>
														<TextField
															label=""
															defaultValue={data.dfs_blastocystis}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>

													<Box mt={4}>
														<TextField
															label=""
															defaultValue={data.dfs_giardia_lamblia_cyst}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>

													<Box>
														<TextField
															label=""
															defaultValue={
																data.dfs_giardia_lamblia_trophozoite
															}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>

													<Box>
														<TextField
															label=""
															name="dfs_trichuris[]"
															fullWidth
															defaultValue={data.dfs_trichusris_trichuira}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>

													<Box mt={4}>
														<TextField
															label=""
															name="dfs_estamoeba_cyst[]"
															fullWidth
															defaultValue={data.dfs_entamoeba_lamblia_cyst}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>

													<Box>
														<TextField
															label=""
															name="dfs_estamoeba_trophozoite[]"
															fullWidth
															defaultValue={
																data.dfs_entamoeba_lamblia_trophozoite
															}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>
												</Grid>

												<Grid item xs={12} sm={3}>
													<Box mt={3.2}>
														{data.kt ? (
															<CheckBox color="primary" />
														) : (
															<CheckBoxOutlineBlank />
														)}
														Kato-Thick
													</Box>

													<Box>
														<TextField
															label=""
															name="kt_ascaris[]"
															fullWidth
															defaultValue={data.kt_ascaris}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>

													<Box>
														<TextField
															label=""
															name="kt_hookworm[]"
															fullWidth
															defaultValue={data.kt_hookworm}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>

													<Box>
														<TextField
															label=""
															name="kt_blasto[]"
															fullWidth
															defaultValue={data.kt_blastocystis}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>

													<Box mt={4}>
														<TextField
															label=""
															name="kt_giadia_cyst[]"
															defaultValue={data.kt_giardia_lamblia_cyst}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>

													<Box>
														<TextField
															label=""
															name="kt_giadia_trophozoite[]"
															defaultValue={data.kt_giardia_lamblia_trophozoite}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>

													<Box>
														<TextField
															label=""
															name="kt_trichuris[]"
															defaultValue={data.kt_trichusris_trichuira}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>

													<Box mt={4}>
														<TextField
															label=""
															name="kt_estamoeba_cyst[]"
															defaultValue={data.kt_entamoeba_lamblia_cyst}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>

													<Box>
														<TextField
															label=""
															name="kt_estamoeba_trophozoite[]"
															defaultValue={
																data.kt_entamoeba_lamblia_trophozoite
															}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>
												</Grid>

												<Grid item xs={12} sm={3}>
													<Box mt={3.2}>
														{data.kk ? (
															<CheckBox color="primary" />
														) : (
															<CheckBoxOutlineBlank />
														)}
														Kato-Katz
													</Box>

													<Box align="center">
														<TextField
															label=""
															name="kk_ascaris[]"
															defaultValue={data.kk_ascaris}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>

													<Box>
														<TextField
															label=""
															defaultValue={data.kk_hookworm}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>

													<Box>
														<TextField
															defaultValue={data.kk_blastocystis}
															InputProps={{
																readOnly: true,
															}}
															label=""
															name="kk_blasto[]"
															fullWidth
														/>
													</Box>

													<Box mt={4}>
														<TextField
															label=""
															name="kk_giadia_cyst[]"
															defaultValue={data.kk_giardia_lamblia_cyst}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>

													<Box>
														<TextField
															label=""
															name="kk_giadia_trophozoite[]"
															defaultValue={data.kk_giardia_lamblia_trophozoite}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>
													<Box>
														<TextField
															label=""
															name="kk_trichuris[]"
															defaultValue={data.kk_trichusris_trichuira}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>
													<Box mt={4}>
														<TextField
															label=""
															name="kk_estamoeba_cyst[]"
															defaultValue={data.kk_entamoeba_lamblia_cyst}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>

													<Box>
														<TextField
															disabled={!data.kk}
															label=""
															defaultValue={
																data.kk_entamoeba_lamblia_trophozoite
															}
															InputProps={{
																readOnly: true,
															}}
															fullWidth
														/>
													</Box>
												</Grid>
											</Grid>
										</Box>

										<Box>
											<TextField
												label="Other"
												multiline
												fullWidth
												defaultValue={data.others}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													readOnly: true,
												}}
											/>
										</Box>

										<Box>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={4}>
													<Box>
														<TextField
															label="Pus Cells"
															fullWidth
															defaultValue={data.pus_cells}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>

													<Box>
														<TextField
															label="Red Blood Cells"
															fullWidth
															defaultValue={data.reb_blood_cells}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>

													<Box>
														<TextField
															label="Fat Globules"
															fullWidth
															defaultValue={data.fat_globules}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>
												</Grid>
												<Grid item xs={12} sm={4}>
													<Box>
														<TextField
															label="Yeast Cells"
															fullWidth
															defaultValue={data.yeast_cells}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>
													<Box>
														<TextField
															label="Bacteria"
															fullWidth
															defaultValue={data.bacteria}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>
													<Box>
														<TextField
															label="Oil Droplets"
															fullWidth
															defaultValue={data.oil_droplets}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>
												</Grid>
												<Grid item xs={12} sm={4}>
													<Box>
														<TextField
															label="Undigested Food Particles"
															fullWidth
															defaultValue={data.undigested_foods_paticles}
															InputProps={{
																readOnly: true,
															}}
														/>
													</Box>
												</Grid>
											</Grid>
										</Box>
									</Box>
								</Box>
								{/* fecalysis order end */}
							</Fragment>
						))}
					</Box>

					{/* <Footer formheader={formheader} /> */}
				</>
			)}
		</>
	);
};

export default StooltestOrder;
