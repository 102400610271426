import { Typography, Box, Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";
// import Footer from "src/laboratory/print/Footer";

const FecalOrder = ({ order_id, patient_id, formheader }) => {
	const [details, setDetails] = useState([]);
	const { users } = React.useContext(UsersData);

	const getpaidLaboratoryOrderDetails = async () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set("trace_number", order_id);
		formdata.set("table", "laboratory_fecal_analysis");
		formdata.set("connection", "local");

		try {
			let response = await axios.post(
				"doctor/patient/laboratory/order/paid-detailsbytable",
				formdata
			);
			const data = response.data;

			setDetails(data);
		} catch (error) {
			console.log("lab form order laboratory_hematology table error", error);
		}
	};

	const checkResult = (order) => {
		return order === null ? (
			<Label color="error"> no result </Label>
		) : (
			<Label color="success"> {order} </Label>
		);
	};

	useEffect(() => {
		getpaidLaboratoryOrderDetails();

		// eslint-disable-next-line
	}, []);

	return (
		<>
			{details.length > 0 &&
				details.map((data, index) => (
					<Fragment key={index}>
						<Box my={2} p={2}>
							{Boolean(parseInt(data.fecal_analysis)) &&
								(parseInt(data.fecal_analysis) === 2 ? (
									<Box display={"flex"} mt={1}>
										<Box>
											<Typography variant="h6" align="center">
												Fecal Analalysis Order
											</Typography>
										</Box>
										<Box mx={2} align="center" minWidth={100} borderBottom={1}>
											<Label color="warning"> refund </Label>
										</Box>
									</Box>
								) : (
									<>
										<Box>
											<Typography variant="h6" align="center">
												Fecal Analalysis Order
											</Typography>
										</Box>

										<Box>
											<Box mt={2}>
												<Grid container spacing={2}>
													<Grid item xs={6} sm={6}>
														<>
															<Box>
																<Typography variant="caption">
																	<b> CELLULAR ELEMENTS </b>
																</Typography>
															</Box>
															<Grid container spacing={2}>
																<Grid item xs={12} sm={6}>
																	<Box mt={1} display="flex">
																		<Typography variant="caption">
																			COLOR:
																		</Typography>
																		<Box
																			mx={2}
																			align="center"
																			minWidth={100}
																			borderBottom={1}
																		>
																			{checkResult(
																				data.cellular_elements_color
																			)}
																		</Box>
																	</Box>

																	<Box mt={1} display="flex">
																		<Typography variant="caption">
																			CONSISTENCY:
																		</Typography>
																		<Box
																			mx={2}
																			align="center"
																			minWidth={100}
																			borderBottom={1}
																		>
																			{checkResult(
																				data.cellular_elements_consistency
																			)}
																		</Box>
																	</Box>
																</Grid>
																<Grid item xs={12} sm={6}>
																	<Box mt={1} display="flex">
																		<Typography variant="caption">
																			PUS:
																		</Typography>
																		<Box
																			mx={2}
																			align="center"
																			minWidth={100}
																			borderBottom={1}
																		>
																			{checkResult(data.cellular_elements_pus)}
																		</Box>
																		<Typography variant="caption">
																			/HPF
																		</Typography>
																	</Box>
																	<Box mt={1} display="flex">
																		<Typography variant="caption">
																			RBC:
																		</Typography>
																		<Box
																			mx={2}
																			align="center"
																			minWidth={100}
																			borderBottom={1}
																		>
																			{checkResult(data.cellular_elements_rbc)}
																		</Box>
																		<Typography variant="caption">
																			/HPF
																		</Typography>
																	</Box>
																</Grid>
															</Grid>
														</>
													</Grid>
													<Grid item xs={6} sm={6}>
														<Grid container>
															<Grid item xs={3} sm={3} />
															<Grid item xs={9} sm={9}>
																<Box>
																	<Box>
																		<Typography variant="caption">
																			<b> OTHERS </b>
																		</Typography>
																	</Box>

																	<Box mt={1} display="flex">
																		<Typography variant="caption">
																			FAT GLOBULES:
																		</Typography>
																		<Box
																			mx={2}
																			align="center"
																			minWidth={100}
																			borderBottom={1}
																		>
																			{checkResult(
																				data.cellular_elements_fat_globules
																			)}
																		</Box>
																	</Box>

																	<Box mt={1} display="flex">
																		<Typography variant="caption">
																			OCCULT BLOOD::
																		</Typography>
																		<Box
																			mx={2}
																			align="center"
																			minWidth={100}
																			borderBottom={1}
																		>
																			{checkResult(
																				data.cellular_elements_occultblood
																			)}
																		</Box>
																	</Box>

																	<Box mt={1} display="flex">
																		<Typography variant="caption">
																			BACTERIA:
																		</Typography>
																		<Box
																			mx={2}
																			align="center"
																			minWidth={100}
																			borderBottom={1}
																		>
																			{checkResult(
																				data.cellular_elements_bacteria
																			)}
																		</Box>
																	</Box>
																</Box>
															</Grid>
														</Grid>
													</Grid>
												</Grid>

												<Box mt={1}>
													<Typography variant="caption">
														<b> RESULT: </b>
													</Typography>
													<Box mx={2} minWidth={100}>
														<Typography variant="subtitle2">
															{checkResult(data.cellular_elements_result)}
														</Typography>
													</Box>
												</Box>
											</Box>
										</Box>
									</>
								))}
						</Box>

						{/* <Footer formheader={formheader} /> */}
					</Fragment>
				))}
		</>
	);
};

export default FecalOrder;
