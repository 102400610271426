import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import CisPatientAppointmentListItem from "src/cis-doctor/components/CisPatientAppointmentListItem";
import DateTimeClock from "src/clinic/components/DateTimeClock";
import ActionBtn from "src/components/ActionBtn";
import ButtonV3 from "src/components/ButtonV3";
import FlatIcon from "src/components/FlatIcon";
import LayoutContainer from "src/components/LayoutContainer";
import CisAcceptPatientModal from "./components/modals/CisAcceptPatientModal";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import CisPatientTBTreatmentProgram from "./components/CisPatientTBTreatmentProgram";
import ConfirmationModal from "src/components/modal/ConfirmationModal";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import ClinicInfo from "./components/ClinicInfo";
import useClinicHook from "src/clinic/services/useClinicHook";

const CisTBTreatmentModule = () => {
	const accept_patient_modal = useRef(null);
	const confirmation_modal = useRef(null);
	const { clinic, getQueue } = useClinicContext();
	const { doneServePatient, approveTBPatient } = useClinicHook();

	const [registeringPatients, setRegisteringPatients] = useState([]);
	const [inServicePatients, setInServicePatients] = useState([]);
	const [donePatients, setDonePatients] = useState([]);
	const [pendingList, setPendingList] = useState({
		loading: false,
		data: [],
	});
	const [approveList, setApproveList] = useState({
		loading: false,
		data: [],
	});

	useEffect(() => {
		getPendingList();
		getApproveList();
	}, []);

	const getPendingList = () => {
		setPendingList({
			loading: true,
			data: [],
		});
		if (clinic?.type == "municipality") {
			axios.get(`v1/tuberculosis-program/barangay-list`).then((res) => {
				setPendingList({
					loading: true,
					data: res.data.data,
				});
			});
		} else {
			axios
				.get(
					`v1/tuberculosis-program/rhu-list?municipality_clinic_id=1`
				)
				.then((res) => {
					setPendingList({
						loading: false,
						data: res.data.data,
					});
				});
		}
	};

	const getApproveList = () => {
		setApproveList({
			loading: true,
			data: [],
		});
		if (clinic?.type == "municipality") {
			axios.get(`v1/tuberculosis-program/sph-list`).then((res) => {
				setApproveList({
					loading: true,
					data: res.data.data,
				});
			});
		} else {
			axios.get(`v1/tuberculosis-program/sph-list`).then((res) => {
				setApproveList({
					loading: false,
					data: res.data.data,
				});
			});
		}
	};

	return (
		<LayoutContainer
			icon={<FlatIcon icon="rr-ballot" className="text-xl" />}
			title={`Anesthesia Program`}
			subtitle={`Anesthesia Program
			Patients who are in Anesthesia Program`}
			titleChildren={<ClinicInfo />}
		>
			<div className="grid grid-cols-1 lg:grid-cols-12 gap-6 pb-4">
				<div className="lg:col-span-6 flex flex-col gap-y-4">
					<div className="rounded-xl bg-slate-300 text-darker p-3 text-lg font-bold flex items-center gap-2">
						<FlatIcon icon="rr-form" />
						<span className="mr-auto">Referrals / Pending</span>
					</div>
					{pendingList?.data?.map((data) => {
						let patient = { ...data?.relationships };
						console.log(
							"datadatadatadata",
							data,
							patient?.patient?.id,
							patient
						);
						return (
							<CisPatientTBTreatmentProgram
								onClick={() => {
									confirmation_modal.current.show({
										title: "Approve patient",
										message: (
											<div className="py-6 px-2 text-xl  w-full flex flex-col items-center justify-center gap-4">
												<p className="font-bold">
													Patient will be added to in
													Approved list.
												</p>
											</div>
										),
										footer: ({
											loadingBtn,
											setLoadingBtn,
										}) => {
											return (
												<div className="flex items-center gap-4 w-full justify-end">
													<ActionBtn
														size="md"
														type="primary-gradient"
														onClick={() => {
															confirmation_modal.current.hide();
														}}
													>
														Cancel
													</ActionBtn>
													<ActionBtn
														size="md"
														type="success"
														loading={loadingBtn}
														onClick={() => {
															setLoadingBtn(true);
															approveTBPatient(
																data?.id
															).then((res) => {
																setTimeout(
																	() => {
																		setLoadingBtn(
																			false
																		);

																		toast.success(
																			"TB Patient approved!"
																		);
																	},
																	200
																);
																getPendingList();
																getApproveList();
																confirmation_modal.current.hide();
															});
														}}
													>
														Okay
													</ActionBtn>
												</div>
											);
										},
									});
								}}
								doctor={data?.relationships?.doctor}
								patient={{
									...patient,
									firstname: patient?.patient?.firstname,
									lastname: patient?.patient?.lastname,
									middle: patient?.patient?.middle,
									middlename: patient?.patient?.middle,
									birthday: patient?.patient?.birthday,
									gender: patient?.patient?.gender,
									reason: data?.brgy_notes,
									status: "pending",
								}}
							/>
						);
					})}
				</div>
				<div className="lg:col-span-6 flex flex-col gap-y-4">
					<div className="rounded-xl bg-blue-100 text-blue-700 p-3 text-lg font-bold flex items-center gap-2">
						<FlatIcon icon="rr-hand-holding-heart" />
						In Treatment / Approved
					</div>

					{approveList?.data?.map((data) => {
						let patient = { ...data?.relationships };
						console.log("datadatadatadata", patient);
						return (
							<CisPatientTBTreatmentProgram
								clickable={true}
								doctor={data?.relationships?.doctor}
								patient={{
									...patient,
									firstname: patient?.patient?.firstname,
									lastname: patient?.patient?.lastname,
									middle: patient?.patient?.middle,
									middlename: patient?.patient?.middle,
									birthday: patient?.patient?.birthday,
									gender: patient?.patient?.gender,
									reason: data?.brgy_notes,
									status: "approve",
								}}
							/>
						);
					})}
				</div>
				{/* <div className="lg:col-span-4 flex flex-col gap-y-4">
					<div className="rounded-xl bg-green-100 text-green-700 p-3 text-lg font-bold flex items-center gap-2">
						<FlatIcon icon="rr-memo-circle-check" />
						Done
					</div>
					<CisPatientTBTreatmentProgram
						patient={{
							firstname: "Carla",
							lastname: "Porlares",
							birthday: "1990-02-28",
							gender: "Female",
							reason: "Severe stomach ache.",
						}}
					/>
					<CisPatientAppointmentListItem
						patient={{
							firstname: "John",
							lastname: "Doe",
							birthday: "1993-01-20",
							gender: "Male",
							reason: "Heache",
						}}
					/>
					<CisPatientAppointmentListItem
						patient={{
							firstname: "Maria",
							lastname: "Clara",
							birthday: "1992-10-20",
							gender: "Female",
							reason: "Dry cough for couple of weeks",
						}}
					/>
					<CisPatientAppointmentListItem
						patient={{
							firstname: "Mona",
							lastname: "Lisa",
							birthday: "1985-09-10",
							gender: "Male",
							reason: "Dry cough for couple of weeks",
						}}
					/>
				</div> */}
			</div>
			<CisAcceptPatientModal ref={accept_patient_modal} />
			<ConfirmationModal ref={confirmation_modal} />
		</LayoutContainer>
	);
};

export default CisTBTreatmentModule;
