import {
	Grid,
	Box,
	Paper,
	FormHelperText,
	Divider,
	Button,
	TextField,
	Typography,
} from "@material-ui/core";
import React, { Fragment, useState, useEffect } from "react";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { UsersData } from "src/ContextAPI";
import HMOList from "./HMOList";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const HMO = () => {
	const { users } = React.useContext(UsersData);

	const [hmoList, sethmoList] = useState({
		data: [],
		ready: false,
	});

	const getFormData = (object) => {
		const formData = new FormData();

		Object.keys(object).forEach((key) => formData.append(key, object[key]));
		return formData;
	};

	const getHMOListFetch = async () => {
		try {
			var params = {
				management_id: users.management_id,
				main_mgmt_id: users.main_mgmt_id,
			};
			const request = await axios.get("cashier/get/all/hmo-list", { params });
			sethmoList({
				data: request.data,
				ready: true,
			});
		} catch (error) {
			Notify.requestError(error);
		}
	};

	useEffect(() => {
		getHMOListFetch();

		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: true,
					current: "imaging test",
					items: [{ name: "Dashboard", path: "/sph/app" }],
				}}
				title="HMO"
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={7} md={8}>
						<HMOList list={hmoList} getHMOListFetch={getHMOListFetch} />
					</Grid>

					<Grid item xs={12} sm={5} md={4}>
						<Box component={Paper} variant="outlined" p={2}>
							<Formik
								initialValues={{
									username: users.username,
									user_id: users.user_id,
									management_id: users.management_id,
									main_mgmt_id: users.main_mgmt_id,
									name: "",
									password: "",
								}}
								validationSchema={Yup.object().shape({
									name: Yup.string().trim().required(),
									password: Yup.string().required(),
								})}
								onSubmit={async (
									values,
									{ setErrors, setStatus, setSubmitting, resetForm }
								) => {
									try {
										const request = await axios.post(
											"cashier/create/new-hmo",
											getFormData(values)
										);
										if (request.data === "pass-invalid") {
											setErrors({ password: "Password is invalid." });
											Notify.fieldInvalid("password");
										}
										if (request.data === "success") {
											Notify.successRequest("add new hmo");
											resetForm();
											setSubmitting(true);
											getHMOListFetch();
										}
									} catch (error) {
										const message = error.message || "Something went wrong";
										setErrors({ submit: message });
										setSubmitting(false);
									}
								}}
							>
								{({
									errors,
									handleBlur,
									handleChange,
									handleSubmit,
									isSubmitting,
									setFieldValue,
									touched,
									values,
								}) => (
									<form noValidate onSubmit={handleSubmit}>
										<Box mb={2}>
											<Typography color="primary" variant="h5">
												<strong>New HMO</strong>
											</Typography>
										</Box>

										<Divider />

										<Box my={2} className={`labselect2`}>
											<TextField
												error={Boolean(touched.name && errors.name)}
												helperText={touched.name && errors.name}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.name}
												fullWidth
												required
												name="name"
												label="HMO name"
												variant="outlined"
											/>
										</Box>

										<Box mb={2}>
											<TextField
												fullWidth
												required
												error={Boolean(touched.password && errors.password)}
												helperText={touched.password && errors.password}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.password}
												name="password"
												label="Enter your password"
												variant="outlined"
												type="password"
											/>
										</Box>

										{errors.submit && (
											<Box mt={3}>
												<FormHelperText error>{errors.submit}</FormHelperText>
											</Box>
										)}

										<Box my={2} display="flex">
											<Box flexGrow={1} />

											<Button
												variant="contained"
												color="default"
												type="reset"
												startIcon={<ClearIcon />}
											>
												Clear
											</Button>
											<Box ml={2}>
												<Button
													type="submit"
													variant="contained"
													color="primary"
													startIcon={<CheckIcon />}
													disabled={isSubmitting}
												>
													Save
												</Button>
											</Box>
										</Box>
									</form>
								)}
							</Formik>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Fragment>
	);
};

export default HMO;
