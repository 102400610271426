import { Box, Grid, Paper, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Container from "src/layout/Container";
import GraphChemistry from "./GraphChemistry";
import GraphClinical from "./GraphClinical";
import GraphHemathology from "./GraphHemathology";

const GraphLaboratory = () => {
  const { patient_id, type } = useParams();
  const history = useHistory();
  const containerRef = React.useRef(null);
  const [isRefReady, setIsRefReady] = useState(false);

  const checkRefReady = () => {
    if (containerRef.current) {
      setIsRefReady(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      checkRefReady();
    }, 5000);
  }, [containerRef]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Laboratory",
        items: [
          { name: "Dashborad", path: "/sph/app/doctor" },
          { name: "Patients", path: "/sph/app/doctor/patient/list" },
          {
            name: "Information",
            path: `/sph/app/doctor/patient/details/tracenumber/${patient_id}`,
          },
        ],
      }}
      title={`Laboratory Graph`}
    >
      <Grid container>
        <Grid item xs={12}>
          <Paper component={Box} p={2} elevation={0} ref={containerRef}>
            {isRefReady ? (
              <RenderGraph
                type={type}
                containerRef={containerRef}
                patient_id={patient_id}
                history={history}
              />
            ) : (
              <Box>
                <CircularProgress color="inherit" size={25} /> generating
                graph...
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GraphLaboratory;

const RenderGraph = ({ type, containerRef, patient_id, history }) => {
  switch (type) {
    case "hemathology":
      return (
        <GraphHemathology
          containerRef={containerRef.current}
          type={type}
          patient_id={patient_id}
        />
      );
    case "chemistry":
      return (
        <GraphChemistry
          containerRef={containerRef.current}
          type={type}
          patient_id={patient_id}
        />
      );
    case "microscopy":
      return (
        <GraphClinical
          containerRef={containerRef.current}
          type={type}
          patient_id={patient_id}
        />
      );
    default:
      history.push(`/sph/app/pagenotfound`);
      break;
  }
};
