import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAddressCard,
	faHome,
	faPersonBooth,
	faUsers,
	faBars,
	faUserLock,
	faMapMarked,
	faChartBar,
	faListUl,
	faUserInjured,
	faVirus,
	faHouseUser,
	faChartArea,
	faTruckMoving,
} from "@fortawesome/free-solid-svg-icons";
import PhoDashboard from "src/pho/PhoDashboard";
import PhoPatientList from "src/pho/PhoPatientList";
import PhoPatients from "src/pho/PhoPatients";
import PhoAnalyticsMap from "src/pho/PhoAnalyticsMap";
import Account from "src/pho/account/Account";
import PhoForm from "src/pho/components/PhoFormModal";
import PhoDiseases from "src/pho/PhoDiseases";
import PhoHouseholds from "src/pho/PhoHouseholds";
import HouseholdFormPage from "src/pho/HouseholdFormPage";
import Test from "./Test";
import PhoHouseholdMemberFormPage from "src/pho/PhoHouseholdMemberPage";
import ManagePhoUsers from "src/pho/ManagePhoUsers";
import PhoAccount from "src/pho/PhoAccount";
import PhoClinicManagement from "src/pho/clinic-management/PhoClinicManagement";
import PhoClinicPersonnel from "src/pho/clinic-personnel/PhoClinicPersonel";
import PhoClinicProfile from "src/pho/clinic-management/PhoClinicProfile";
import PhoPhilhealthForm from "src/pho/PhoPhilhealthForm";
import ItemManagement from "src/pho/items/ItemManagement";
import SupplierManagement from "src/pho/supplier/SupplierManagement";
import PhoCensus from "src/pho/PhoCensus";
import Pmrf from "src/pho/printable-forms/Pmrf";
import PhoLMISPage from "src/pho/PhoLMISPage";
import LMISConsignmentForm from "src/pho/lmis/LMISConsignmentForm";
import LMISConsignmentView from "src/pho/lmis/LMISConsignmentView";
import ItemInventory from "src/pho/items/ItemInventory";
import BhsDashboard from "src/disease-treatment/BhsDashboard";
import BhsPatients from "src/disease-treatment/BhsPatients";
import RhuTBTreatment from "src/disease-treatment/RhuTBTreatment";
import RhuViewPatient from "src/disease-treatment/RhuViewPatient";
import RhuPatients from "src/disease-treatment/RhuPatients";
import BhsLMISPage from "src/disease-treatment/BhsLMISPage";
import RhuLMISPage from "src/disease-treatment/RhuLMISPage";
import RhuPatientReferrals from "src/disease-treatment/RhuPatientReferrals";
import SphPatientReferrals from "src/disease-treatment/SphPatientReferrals";

const RouteRHU = () => {
	const [sidebarHeader, setSidebarHeader] = useState([]);
	const userContext = useContext(UsersData);
	// console.log("userContextuserContext", userContext);
	const { users } = userContext;
	const [user, setUser] = useState(null);
	const [pendings, setPendings] = useState(null);
	const [pendings2, setPendings2] = useState(null);
	const [pendings3, setPendings3] = useState(null);
	const [pendings4, setPendings4] = useState(null);

	const getPendingLabReading = () => {
		axios
			.get(`/v1/clinic/doctor-pending-for-read-lab-result`)
			.then((res) => {
				console.log("resssss", res.data.data);
				setPendings3(res.data);
			});
	};

	const getPendingForConfirmation = () => {
		axios.get(`/v1/clinic/doctor-pending-for-confirmation`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};
	const getPendingForConsultation = () => {
		axios.get(`/v1/clinic/doctor-pending-for-consultation`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings2(res.data);
		});
	};

	const getPendingForCashier = () => {
		axios.get(`/v1/clinic/pending-cashier`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	const getPendingForBilling = () => {
		axios.get(`/v1/clinic/pending-billing`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	const getPendingForAcceptance = () => {
		axios.get(`/v1/clinic/nurse-pending-for-acceptance`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	const getXrayPending = () => {
		axios.get(`/v1/clinic/xray-pending-result`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	const getLabPending = () => {
		axios.get(`/v1/clinic/lab-order-pending-result`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	const getPharamaSignalRelease = () => {
		axios
			.get(`/v1/clinic/pharmacy-pending-signal-for-release`)
			.then((res) => {
				console.log("resssss", res.data.data);
				setPendings(res.data);
			});
	};

	const getPharamaMedlRelease = () => {
		axios
			.get(`/v1/clinic/pharmacy-pending-medicine-release`)
			.then((res) => {
				console.log("resssss", res.data.data);
				setPendings4(res.data);
			});
	};

	const getSidebarHeaderInfo = async () => {
		var params = { user_id: userContext.users.user_id };
		const { data } = await axios.get(
			"admitting/sidebar/header-infomartion",
			{
				params,
			}
		);
		setSidebarHeader(data);
	};

	useEffect(() => {
		if (users?.type == "RHU-DOCTOR" || users?.type == "SPH-DOCTOR") {
			getPendingForConfirmation();

			getPendingForConsultation();
			getPendingLabReading();
		}
		if (users?.type == "RHU-PHARMACY" || users?.type == "SPH-PHAR") {
			getPharamaSignalRelease();
			getPharamaMedlRelease();
		}
		if (users?.type == "RHU-NURSE" || users?.type == "SPH-NURSE") {
			getPendingForAcceptance();
		}
		if (users?.type == "RHU-LAB" || users?.type == "SPH-LAB") {
			getLabPending();
		}
		if (users?.type == "RHU-XRAY" || users?.type == "SPH-XRAY") {
			getXrayPending();
		}
		if (users?.type == "SPH-BILLING") {
			getPendingForBilling();
		}
		if (users?.type == "SPH-CASHIER") {
			getPendingForCashier();
		}
	}, []);

	useEffect(() => {
		let t = setInterval(() => {
			if (users?.type == "RHU-DOCTOR") {
				getPendingForConfirmation();
				getPendingForConsultation();
				getPendingLabReading();
			}
			if (users?.type == "RHU-PHARMACY") {
				getPharamaSignalRelease();
				getPharamaMedlRelease();
			}
			if (users?.type == "RHU-NURSE") {
				getPendingForAcceptance();
			}
			if (users?.type == "RHU-LAB") {
				getLabPending();
			}
			if (users?.type == "RHU-XRAY") {
				getXrayPending();
			}
			if (users?.type == "SPH-BILLING") {
				getPendingForBilling();
			}
			if (users?.type == "SPH-CASHIER") {
				getPendingForCashier();
			}
		}, 10000);
		return () => {
			clearTimeout(t);
		};
	}, []);

	const getAllUsers = () => {
		axios.get("v1/pho/users").then((res) => {
			let users_ = res.data.data;
			let found = users_?.find((x) => x.user_id == users?.user_id);

			setUser(found);
		});
	};

	const forRhuDoctor =
		users?.type == "RHU-DOCTOR"
			? [
					{
						name: "Patients Referrals",
						show: true,
						icon: (
							<FontAwesomeIcon
								icon={faUsers}
								color="white"
								style={{ fontSize: 18 }}
							/>
						),
						path: "/sph/app/rhu/patient-referrals",
						subitem: [],
					},
			  ]
			: [];

	const forSphNurse =
		users?.type == "SPH-NURSE"
			? [
					{
						name: "Patients Referrals",
						show: true,
						icon: (
							<FontAwesomeIcon
								icon={faUsers}
								color="white"
								style={{ fontSize: 18 }}
							/>
						),
						path: "/sph/app/rhu/sph-patient-referrals",
						subitem: [],
					},
			  ]
			: [];

	const forSphDoctor =
		users?.type == "SPH-DOCTOR"
			? [
					{
						name: "Patients Referrals",
						show: true,
						icon: (
							<FontAwesomeIcon
								icon={faUsers}
								color="white"
								style={{ fontSize: 18 }}
							/>
						),
						path: "/sph/app/rhu/patient-referrals",
						subitem: [],
					},
			  ]
			: [];
	const sidebarRoute = [
		// {
		// 	name: "TB Treatment Program",
		// 	show: true,
		// 	icon: (
		// 		<FontAwesomeIcon
		// 			icon={faHome}
		// 			color="white"
		// 			style={{ fontSize: 18 }}
		// 		/>
		// 	),
		// 	path: "/sph/app/rhu/dashboard",
		// 	subitem: [],
		// },
		...forSphDoctor,
		...forRhuDoctor,
		...forSphNurse,
		{
			name: "Patients",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUsers}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/rhu/patients",
			subitem: [],
			children: (
				<div
					className={`absolute left-0 top-0 ${
						(pendings?.count || 0) +
							(pendings2?.count || 0) +
							(pendings3?.count || 0) ==
						0
							? "hidden"
							: ""
					}`}
				>
					<div className="left-8  bg-red-500 rounded-full z-20 absolute top-2 text-white text-xs h-5  w-5 flex items-center justify-center">
						{(pendings?.count || 0) +
							(pendings2?.count || 0) +
							(pendings3?.count || 0)}
					</div>

					<div className="left-8  bg-red-500 rounded-full z-10 absolute top-2 text-white text-xs h-5  w-5 flex items-center justify-center animate-ping"></div>
				</div>
			),
		},
		{
			name: "Consignments",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faTruckMoving}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/rhu/lmis",
			subitem: [],
		},
		// {
		// 	name: "Item Management",
		// 	show: true,
		// 	icon: (
		// 		<FontAwesomeIcon
		// 			icon={faHouseUser}
		// 			color="white"
		// 			style={{ fontSize: 18 }}
		// 		/>
		// 	),
		// 	path: "/sph/app/rhu/item-management",
		// 	subitem: [],
		// },
		{
			name: "Item Inventory",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faHouseUser}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/rhu/item-inventory",
			subitem: [],
		},
		{
			name: "Account",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserLock}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/rhu/account",
			subitem: [],
		},
	];

	const handleRenderInfo = () => {
		getSidebarHeaderInfo();
	};

	useEffect(() => {
		getSidebarHeaderInfo();
		//eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<UsersHeader.Provider
				value={{
					sidebarHeader: sidebarHeader,
					renderPharmacyInfo: handleRenderInfo,
				}}
			>
				<BrowserRouter>
					<Sidebar
						notification={{
							enable: true,
							owner: "pho",
						}}
						header={{ ...sidebarHeader, ...users }}
						routes={sidebarRoute}
						module={users?.type?.replace("-", " ")}
					/>
					<Switch>
						<Route
							exact
							path="/"
							component={() => (
								<Redirect to="/sph/app/rhu/dashboard" />
							)}
						/>
						<Route
							exact
							path="/sph/app"
							component={() => (
								<Redirect to="/sph/app/rhu/dashboard" />
							)}
						/>
						<Route
							exact
							path="/sph/app/rhu/dashboard"
							component={RhuPatients}
						/>
						<Route
							exact
							path="/sph/app/rhu/sph-patient-referrals"
							component={SphPatientReferrals}
						/>
						<Route
							exact
							path="/sph/app/rhu/patient-referrals"
							component={RhuPatientReferrals}
						/>
						<Route
							exact
							path="/sph/app/rhu/patient/:id"
							component={RhuViewPatient}
						/>
						<Route
							exact
							path="/sph/app/rhu/patients"
							component={RhuPatients}
						/>
						<Route
							exact
							path="/sph/app/rhu/item-inventory"
							component={ItemInventory}
						/>
						<Route
							exact
							path="/sph/app/rhu/lmis"
							component={RhuLMISPage}
						/>
						<Route
							exact
							path="/sph/app/rhu/lmis/create"
							component={LMISConsignmentForm}
						/>
						<Route
							exact
							path="/sph/app/pho/lmis/view/:id"
							component={LMISConsignmentView}
						/>
						<Route
							exact
							path="/sph/app/rhu/lmis/view/:id"
							component={LMISConsignmentView}
						/>
						<Route
							exact
							path="/sph/app/rhu/account"
							component={PhoAccount}
						/>
						<Route
							exact
							path="/sph/app/rhu/pmrf/:id"
							component={Pmrf}
						/>
						<Route
							exact
							path="/sph/app/rhu/test"
							component={Test}
						/>
						<Route
							exact
							path="/sph/app/logout"
							component={Logout}
						/>
						<Route
							render={() => (
								<PageNotFound title="Page not found" />
							)}
						/>
					</Switch>
				</BrowserRouter>
			</UsersHeader.Provider>
		</Fragment>
	);
};

export default RouteRHU;
