import {
  Box,
  Paper,
  Typography,
  Divider,
  TextField,
  FormHelperText,
  Button,
} from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import { UsersData } from "src/ContextAPI";
import * as Yup from "yup";
import { Formik } from "formik";
import Notify from "src/notification/Notify";
import axios from "axios";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const AddBranches = ({ getAllBraches }) => {
  const { users } = useContext(UsersData);

  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <>
      <Fragment>
        <Box component={Paper} variant="outlined" p={2}>
          <Formik
            initialValues={{
              username: users.username,
              user_id: users.user_id,
              management_id: users.management_id,
              main_mgmt_id: users.main_mgmt_id,
              branch_name: "",
              branch_address: "",
              branch_type: "",
              user_username: "",
              user_pass: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              branch_name: Yup.string()
                .trim()
                .required("Branch Name is required"),
              branch_address: Yup.string()
                .trim()
                .required("Branch Address is required"),

              branch_type: Yup.string()
              .trim()
              .required("Branch Type is required"),

              user_username: Yup.string()
                .trim()
                .required("User's username is required"),
              user_pass: Yup.string()
                .trim()
                .required("User's password is required"),
              password: Yup.string().required("Your password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, resetForm }
            ) => {
              try {
                const request = await axios.post(
                  "hr/add/new-branch",
                  getFormData(values)
                );
                if (request.data === "pass-invalid") {
                  setErrors({ password: "Password is invalid." });
                  Notify.fieldInvalid("password");
                }
                if (request.data === "success") {
                  Notify.successRequest("add new imaging test");
                  resetForm();
                  setSubmitting(true);
                  getAllBraches();
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box mb={2}>
                  <Typography color="textPrimary" variant="subtitle2">
                    NEW BRANCH
                  </Typography>
                </Box>

                <Divider />

                <Box my={2} className={`labselect2`}>
                  <TextField
                    error={Boolean(touched.branch_name && errors.branch_name)}
                    helperText={touched.branch_name && errors.branch_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.branch_name}
                    fullWidth
                    required
                    name="branch_name"
                    label="Branch Name"
                    variant="outlined"
                  />
                </Box>

                <Box mb={2} className={`labselect2`}>
                  <TextField
                    error={Boolean(
                      touched.branch_address && errors.branch_address
                    )}
                    helperText={touched.branch_address && errors.branch_address}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.branch_address}
                    fullWidth
                    required
                    name="branch_address"
                    label="Branch Address"
                    variant="outlined"
                  />
                </Box>

                <Box mb={2} className={'labselect2'}>
                  <TextField
                    error={Boolean(
                      touched.branch_type && errors.branch_type
                    )}
                    helperText={touched.branch_type && errors.branch_type}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.branch_type}
                    fullWidth
                    required
                    name="branch_type"
                    label="Branch Type"
                    variant="outlined"
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> Select </option>
                    <option value="clinic"> Clinic </option>
                    <option value="van"> Mobile Van </option>
                    <option value="hq"> Head Quarters </option>
                  </TextField>
                </Box>

                <Box mb={2} className={`labselect2`}>
                  <TextField
                    error={Boolean(
                      touched.user_username && errors.user_username
                    )}
                    helperText={touched.user_username && errors.user_username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.user_username}
                    fullWidth
                    required
                    name="user_username"
                    label="User's username"
                    variant="outlined"
                  />
                </Box>

                <Box mb={2} className={`labselect2`}>
                  <TextField
                    error={Boolean(touched.user_pass && errors.user_pass)}
                    helperText={touched.user_pass && errors.user_pass}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.user_pass}
                    fullWidth
                    required
                    name="user_pass"
                    label="User's password"
                    variant="outlined"
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    fullWidth
                    required
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    label="Enter your password"
                    variant="outlined"
                    type="password"
                  />
                </Box>

                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Divider />

                <Box my={2} display="flex">
                  <Box flexGrow={1} />

                  <Button
                    variant="contained"
                    color="default"
                    type="reset"
                    startIcon={<ClearIcon />}
                  >
                    Clear
                  </Button>

                  <Box ml={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<CheckIcon />}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Fragment>
    </>
  );
};

export default AddBranches;
