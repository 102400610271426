import {
  Grid,
  Box,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Search } from "react-feather";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import CreatePsychologyOrder from "./CreatePsychologyOrder";
import { Edit } from "@material-ui/icons";
import Notify from "src/notification/Notify";

const PsychologyCreateOrder = () => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [search, setSearch] = useState("");
  const [psycOrderList, setPsycOrderList] = useState({
    data: [],
    ready: false,
  });
  const [edit, setEdit] = useState({
    data: null,
    open: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getPsychologyOrderList = React.useCallback(async () => {
    let response = await axios.get("accounting/get/psychology-test", {
      params: {
        management_id: users.management_id,
        user_id: users.user_id,
      },
    });

    if (mounted.current) {
      setPsycOrderList({ data: response.data, ready: true });
    }
  }, [users, mounted]);

  const handleEditTest = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    // formdata.append("management_id", users.management_id);

    var error = [];
    if (
      formdata.get("rate").trim() === "" ||
      formdata.get("rate").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("rate");
    }

    if (
      formdata.get("password").trim() === "" ||
      formdata.get("password").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/update/psychology/edit-rate", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            Notify.successRequest("edit rate");
            getPsychologyOrderList();
            setEdit({ data: null, open: false });
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    getPsychologyOrderList();
  }, [getPsychologyOrderList]);

  const searchable = psycOrderList.data.filter((data) => {
    return data.test.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Box component={Paper} p={2}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h6" color="textSecondary">
                Psychology Order List
              </Typography>
            </Box>
            <Box>
              <TextField
                label="Search order"
                variant="outlined"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Test</TableCell>
                    <TableCell align="center">Rate</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {psycOrderList.ready ? (
                    psycOrderList.data.length > 0 ? (
                      searchable.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>{data.test}</TableCell>
                          <TableCell align="right">{data.rate}</TableCell>
                          <TableCell align="center">
                            <Tooltip arrow title="Edit Rate">
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  setEdit({
                                    data: data,
                                    open: true,
                                  })
                                }
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          <Typography color="secondary">
                            No record found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        <Typography color="primary">Please wait...</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <CreatePsychologyOrder
          getPsychologyOrderList={() => getPsychologyOrderList()}
        />
      </Grid>

      {/* edit dialog */}
      {edit.data && edit.open && (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={edit.open}
          onClose={() => setEdit({ data: null, open: true })}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle> Edit Other Test </DialogTitle>
          <form onSubmit={handleEditTest}>
            <DialogContent dividers>
              <Box my={2}>
                <TextField
                  fullWidth
                  required
                  name="test_id"
                  label="Test Id"
                  variant="outlined"
                  hidden
                  defaultValue={edit.data.test_id}
                />
                <TextField
                  fullWidth
                  required
                  name="management_id"
                  label="Management ID"
                  variant="outlined"
                  hidden
                  defaultValue={edit.data.management_id}
                />
                <TextField
                  fullWidth
                  required
                  name="test"
                  label="Test"
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={edit.data.test}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="rate"
                  label="Order Rate"
                  variant="outlined"
                  type="number"
                  defaultValue={edit.data.rate}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 100000,
                      step: 0.01,
                    },
                  }}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setEdit({ data: null, open: true })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                startIcon={
                  isSubmitting && <CircularProgress size={20} color="inherit" />
                }
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Grid>
  );
};

export default PsychologyCreateOrder;
