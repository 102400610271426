import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import ButtonV3 from "src/components/ButtonV3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const MedicalCertificateForm = ({
	setSelectedIndex,
	patient,
	handleClickList,
}) => {
	const {
		setValue,
		register,
		formState: { errors },
		handleSubmit,
	} = useForm();

	const onSubmit = (data) => {
		console.log("onSubmit data", data);
		let formData = new FormData();
		formData.append("name", data?.name || "");
		formData.append("diagosis", data?.diagnosis || "");

		// axios
		//   .post("patient/medical-certificate/${patient.id}", formData)
		//   .then((res) => {});
	};

	return (
		<div className="flex flex-col gap-y-5 w-3/5">
			<div className="flex flex-col gap-y-6">
				<div className="flex flex-row justify-content-between gap-1">
					<h4 className="text-lg font-bold mb-0">
						Add Medical Certificate
					</h4>
				</div>
				<div className="flex flex-col lg:flex-row gap-4 ">
					<TextField
						className="w-full bg-white lg:w-3/4"
						id="date"
						name="date"
						label={
							<>
								Date <b className="text-red-500 ml-1">*</b>
							</>
						}
						type="date"
						variant="outlined"
						{...register("date", {
							required: {
								value: true,
								message: "This field is required",
							},
						})}
						error={errors?.date?.message}
						helperText={errors?.date?.message}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>
				<div className="flex flex-col lg:flex-row gap-4">
					<TextField
						className="lg:w-3/4"
						variant="outlined"
						name="diagnosis"
						label={
							<>
								Diagnosis <b className="text-red-500 ml-1">*</b>
							</>
						}
						{...register("diagnosis", {
							required: {
								value: true,
								message: "This field is required",
							},
						})}
						error={errors?.diagnosis?.message}
						helperText={errors?.diagnosis?.message}
						fullWidth
						rows={3}
						multiline
					/>
				</div>
			</div>
			<div className="flex items-center gap-4">
				<ButtonV3 onClick={handleSubmit(onSubmit)}>
					<div className="flex">
						<FontAwesomeIcon
							icon={faSave}
							color="primary"
							style={{ fontSize: 18 }}
							className="pr-2"
						/>
						Submit
					</div>
				</ButtonV3>
				<ButtonV3 type="primary-outline" onClick={handleClickList}>
					Cancel
				</ButtonV3>
			</div>
		</div>
	);
};

export default MedicalCertificateForm;
