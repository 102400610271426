import React, { Fragment, useContext, useEffect, useState } from "react";
import {
	Box,
	Grid,
	Typography,
	TextField,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	TablePagination,
	InputAdornment,
	Paper,
	IconButton,
} from "@material-ui/core";
import Notify from "../../notification/Notify";
import Axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import VirtualDetails from "./VirtualDetails";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { green } from "@material-ui/core/colors";
import Container from "../../layout/Container";
import { PharmacyRoleAndId, UsersData } from "../../ContextAPI";
import { ArrowForward } from "@material-ui/icons";

var interval = null;
function Virtual() {
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [patients, setPatients] = useState([]);
	const [patientsReady, setPatientsReady] = useState(false);
	const [search, setSearch] = useState("");
	const [selectedOrder, setSelectedOrder] = useState(null);
	const userData = useContext(UsersData);
	const userRAndId = useContext(PharmacyRoleAndId);

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const fetchPrescription = () => {
		var params = {
			user_id: userData.users.user_id,
			management_id: userData.users.management_id,
			pharmacy_id: userRAndId.userRoleAndId.pharmacy_id,
			connection: "online",
		};
		Axios.get("pharmacy/get-prescription-list", { params })
			.then((response) => {
				const data = response.data;
				setPatients(data);
				setPatientsReady(true);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const initializeInterval = () => {
		interval = setInterval(() => {
			fetchPrescription();
		}, 10000);
	};

	useEffect(() => {
		fetchPrescription();
		initializeInterval();

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line
	}, []);

	const searchable = patients.filter((data) => {
		return data.patientFullname.toLowerCase().indexOf(search.trim()) !== -1;
	});

	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: true,
					current: "virtual prescription",
					items: [{ name: "Dashboard", path: "/sph/app" }],
				}}
				title="Virtual Prescription"
			>
				<Box m={1}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4}>
							<Paper>
								<Box m={1}>
									<Box display="flex" p={2}>
										<Box flexGrow={1} />
										<Box>
											<TextField
												label="Search name"
												variant="outlined"
												margin="dense"
												value={search}
												onChange={(e) => setSearch(e.target.value)}
												InputProps={{
													endAdornment: (
														<InputAdornment>
															<SearchIcon />
														</InputAdornment>
													),
												}}
											/>
										</Box>
									</Box>

									<TableContainer component={Box}>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell align="center"> Service </TableCell>
													<TableCell align="center"> Name </TableCell>
													<TableCell align="center"> Order # </TableCell>
													<TableCell align="center"> Action </TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{patientsReady ? (
													patients.length > 0 ? (
														searchable
															.slice(
																page * rowsPerPage,
																page * rowsPerPage + rowsPerPage
															)
															.map((data, index) => (
																<TableRow key={index}>
																	<TableCell align="center">
																		{parseFloat(data.delivery) === 1 ? (
																			<MotorcycleIcon color="primary" />
																		) : (
																			<ShoppingBasketIcon
																				style={{ color: green[500] }}
																			/>
																		)}
																	</TableCell>
																	<TableCell>{data.patientFullname}</TableCell>
																	<TableCell> {data.order_no} </TableCell>
																	<TableCell align="center">
																		<IconButton
																			onClick={() =>
																				setSelectedOrder(data.order_no)
																			}
																			color={
																				selectedOrder === data.order_no
																					? "secondary"
																					: "primary"
																			}
																		>
																			<ArrowForward />
																		</IconButton>
																	</TableCell>
																</TableRow>
															))
													) : (
														<TableRow>
															<TableCell colSpan={7} align="center">
																<Typography
																	variant="subtitle2"
																	color="secondary"
																>
																	{" "}
																	No prescription added{" "}
																</Typography>
															</TableCell>
														</TableRow>
													)
												) : (
													<TableRow>
														<TableCell colSpan={7} align="center">
															<Typography variant="subtitle2" color="primary">
																{" "}
																Loading...{" "}
															</Typography>
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>

										<TablePagination
											component={"div"}
											rowsPerPageOptions={[5, 10, 25]}
											colSpan={3}
											count={patients.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onChangePage={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</TableContainer>
								</Box>
							</Paper>
						</Grid>
						<Grid item xs={12} sm={8}>
							<Box mt={1}>
								{selectedOrder === null && (
									<Box component={Paper} variant="outlined" p={2} mb={2}>
										<Box
											display="flex"
											justifyContent="center"
											alignItems="center"
											height={100}
										>
											<Typography variant="h3" color="primary">
												Select Order
											</Typography>
										</Box>
									</Box>
								)}
								{selectedOrder && (
									<VirtualDetails
										closeDetails={() => setSelectedOrder(null)}
										selectedOrder={selectedOrder}
									/>
								)}
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Fragment>
	);
}

export default Virtual;
