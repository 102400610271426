import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TabGroup from "src/components/TabGroup";
import QRCode from "qrcode.react";
import Img from "src/components/Img";
import InfoText from "src/components/InfoText";
import html2canvas from "html2canvas";
import {
	calculateAge,
	formatDate,
	replaceFname,
	replaceLname,
	replaceMname,
	dataURItoBlob,
	isBase64,
} from "src/helpers/utils";
// import CollapseContainer from "src/components/CollapseContainer";
import { useContext, useEffect, useRef, useState } from "react";
import ResponseDetails from "./ReponseDetails";
import { Link } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Button from "src/components/Button";
import axios from "axios";
import ImagePicker from "src/components/forms/ImagePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import ButtonV3 from "src/components/ButtonV3";
// import AddContactTracingModal from "./AddContactTracingModal";
const PatientProfile = ({ patient }) => {
	return (
		<div className="flex flex-col gap-y-4 items-start">
			<h5 className="text-base font-semibold text-tertiary mb-0 border-b w-2/3">
				Peronal Information
			</h5>
			<div className="grid grid-cols-1 lg:grid-cols-12 gap-3 w-full">
				<InfoText
					icon="rr-cursor-text"
					className="col-span-3"
					title="Lastname "
					value={patient?.lastname}
				/>
				<InfoText
					icon="rr-cursor-text"
					className="col-span-3"
					title="Firstname "
					value={patient?.firstname}
				/>
				<InfoText
					icon="rr-cursor-text"
					className="col-span-3"
					title="Middle Name"
					value={patient?.middle}
				/>
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-12 gap-3 w-full">
				<InfoText
					icon="rr-venus-mars"
					title="Gender"
					className="col-span-3"
					value={
						<>
							{patient?.gender == "Male" ? (
								<span className="text-blue-700">Male</span>
							) : (
								<span className="text-pink-700">Female</span>
							)}
						</>
					}
				/>
				<InfoText
					icon="rr-calendar-clock"
					title="Birthday"
					value={formatDate(patient?.birthday)}
					className="col-span-3"
				/>
				<InfoText
					icon="rr-following"
					title="Civil Status"
					value={patient?.civil_status}
					className="col-span-3"
				/>
				<InfoText
					icon="rr-file"
					title="Philhealth"
					value={patient?.philhealth}
					className="col-span-3"
				/>
			</div>
			<h5 className="text-base font-semibold text-tertiary mb-0 border-b w-2/3">
				Address
			</h5>

			<div className="grid grid-cols-1 lg:grid-cols-12 gap-3 w-full">
				<InfoText
					icon="rr-map-marker"
					title="Province"
					value={"Sarangani"}
					className="col-span-3"
				/>
				<InfoText
					icon="rr-map-marker"
					title="City/Municipality"
					value={patient?.municipality?.name}
					className="col-span-3"
				/>
				<InfoText
					icon="rr-map-marker"
					title="Barangay"
					value={patient?.barangay?.name}
					className="col-span-3"
				/>
				<InfoText
					icon="rr-map-marker"
					title="Zone"
					value={patient?.zone}
					className="col-span-3"
				/>
				<InfoText
					icon="rr-map-marker"
					title="Street"
					value={patient?.street}
					className="col-span-3"
				/>
			</div>
			{patient?.income?.length > 0 ? (
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-3 w-full">
					<div className="table col-span-6">
						<table>
							<thead>
								<tr>
									<th>Job/Source of Income</th>
									<th className="text-right">Amount</th>
								</tr>
							</thead>
							<tbody>
								{patient?.income?.map((income) => (
									<tr>
										<td>{income?.name}</td>
										<td className="text-right">{income?.amount?.toFixed(2)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};

const PhoViewPatientProfile = ({
	patient,
	setPatient,
	editable = true,
	className = "",
	profileDisplayClassName,
	add_patient_ref,
}) => {
	const { users } = useContext(UsersData);

	const saveAvatar = (avatar) => {
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
			// onUploadProgress: progressEvent => onProgress(progressEvent),
		};
		console.log("avataravatar", avatar);
		const form = new FormData();
		const file = dataURItoBlob(avatar);
		form.append("_method", "PATCH");
		form.append("avatar", file);

		axios.post(`v1/patient-profile/${patient?.id}`, form, config);
	};

	const PrintDiv = () => {
		/* html2canvas(document.getElementById("qr-gen"), {
				allowTaint: true,
				useCORS: true,
				logging: false,
			}).then(function (canvas) {
				var link = document.createElement("a");
				document.body.appendChild(link);
				link.download = `${patient.lastname}_${patient.firstname}.png`;
				console.log("PrintDiv canvas", canvas);
				link.href = canvas.toDataURL();
				link.target = "_blank";
				link.click();
			}); */
		html2canvas(document.getElementById("qr-gen"), {
			allowTaint: false,
			useCORS: true,
		}).then(function (canvas) {
			var anchorTag = document.createElement("a");
			document.body.appendChild(anchorTag);
			// document.getElementById("previewImg").appendChild(canvas);
			anchorTag.download = `${patient.lastname}_${patient.firstname}.jpg`;
			anchorTag.href = canvas.toDataURL();
			anchorTag.target = "_blank";
			anchorTag.click();
		});
	};

	useEffect(() => {
		if (patient?.id) {
			getBase64Avatar();
		}
	}, []);
	const getBase64Avatar = () => {
		axios.get(`v1/base64-patient/${patient?.id}`).then((res) => {
			console.log("resss", res.data);
			setPatient((current) => ({
				...current,
				avatarBase64: "data:image/png;base64," + res.data.image,
			}));
		});
	};
	return (
		<>
			<CardContainer className={`!p-0 rounded-xl ${className}`}>
				<div className="flex lg:grid lg:grid-cols-12 lx:grid-cols-12 flex-col gap-4 pb-4 z-20 bg-white">
					<div className="px-4 border-r py-4 bg-white xl:col-span-3 lg:col-span-4 max-h-[] overflow-hidden">
						<div className="relative">
							<div
								className="p-2 bg-white flex flex-col justify-center items-center"
								id="qr-gen"
							>
								<Img
									type="user"
									name={`${patient?.lastname}-${patient?.firstname}-${patient?.middle}`}
									src={patient?.avatarBase64 || ""}
									className="h-[144px] w-[144px] aspect-square object-contain bg-slate-900 mb-1"
									alt=""
									key={`key-${patient?.id}-${patient?.avatarBase64}`}
								/>
								<p className="font-bold text-primary text-center w-full mb-1">{`${patient?.lastname
									}, ${patient?.firstname} ${patient?.middle || ""}`}</p>

								<p className="capitalize mb-0">{patient?.barangay?.name}</p>
								<p className="uppercase mb-2">
									{patient?.municipality?.name}, Sarangani
								</p>
								<hr className="my-2 border-b w-full" />
								<h6 className="text-sm font-bold mb-2 text-center !text-secondary">
									Patient QRCode
								</h6>
								<QRCode
									value={JSON.stringify({
										patient_id: patient?.patient_id,
										user_id: users?.user_id,
										philhealth: "philhealth",
									})}
									level="H"
									size={144}
								/>
							</div>
							{/* <div className="absolute top-0 left-0 w-full h-full bg-white" /> */}
						</div>
						<ActionBtn size="sm" onClick={PrintDiv}>
							Download QR
						</ActionBtn>
					</div>
					<div className="xl:col-span-9 lg:col-span-8 w-full">
						<div className="flex flex-col lg:flex-row gap-4 items-center px-4 pt-4 border-b justify-center md:justify-start pb-4">
							<div className="group relative h-36 w-36 min-h-[144px] min-w-[144px] rounded-full aspect-square bg-background">
								<Img
									type="user"
									name={`${patient?.lastname}-${patient?.firstname}-${patient?.middle}`}
									src={patient?.avatar || ""}
									className="min-h-[144px] min-w-[144px] aspect-square object-cover rounded-full"
									alt=""
									id="user-image-sample"
									key={`key-${patient?.id}-${patient?.avatar}`}
								/>

								<ImagePicker
									className="absolute -bottom-0 -right-0 w-11 h-11 bg-primary bg-opacity-40 group-hover:bg-primary group-hover:bg-opacity-100 duration-200 rounded-full flex items-center justify-center"
									onChange={(data) => {
										setPatient((prevData) => ({
											...prevData,
											avatar: data,
										}));
										if (isBase64(data)) {
											saveAvatar(data);
										}
									}}
								>
									{() => {
										return (
											<FontAwesomeIcon
												title="Click to upload camera"
												icon={faCamera}
												color="white"
												size={"2x"}
											/>
										);
									}}
								</ImagePicker>
							</div>
							<div className="flex flex-col pl-4">
								<h6
									className={`text-left text-2xl mb-1 font-semibold flex items-center ${patient?.gender == "Male"
										? "text-blue-800"
										: "text-pink-800"
										} mb-0`}
								>
									{`${patient?.lastname}, ${patient?.firstname} ${patient?.middle || ""
										}`}
								</h6>
								<div className="flex gap-6 mb-2">
									<div className="flex items-center gap-2 text-base">
										<FlatIcon icon="rr-calendar-clock" className="text-lg" />
										<span>{calculateAge(patient?.birthday)} yrs. old</span>
									</div>
									<div className="flex items-center gap-2 text-base">
										<FlatIcon icon="rr-calendar" className="text-lg" />
										<span>{formatDate(patient?.birthday)}</span>
									</div>
								</div>
								<div className="flex gap-4 mb-2">
									<div className="flex items-center gap-2 text-base">
										<FlatIcon icon="rr-venus-mars" className="text-lg" />
										{patient?.gender == "Male" ? (
											<span className="text-blue-700">Male</span>
										) : (
											<span className="text-pink-700">Female</span>
										)}
									</div>
								</div>
								<div className="flex gap-4 mb-2">
									<Link
										target="_blank"
										to={`/sph/app/pho/pmrf/${patient?.id}`}
										className="!no-underline"
									>
										<ButtonV3
											type="primary"
										>
											<FlatIcon
												icon="rr-file"
												className="text-xl lg:mr-2"
											/>
											<span className="!no-underline hidden lg:block">
												Generate PMRF
											</span>
										</ButtonV3>
									</Link>
								</div>
							</div>
						</div>
						<TabGroup
							contents={[
								{
									title: (
										<>
											<FlatIcon icon="rr-share" className="mr-1" />
											Kobo Survey
										</>
									),
									content: (
										<div className="flex flex-col gap-y-5">
											<ResponseDetails patient={patient} />
										</div>
									),
								},
								{
									title: (
										<>
											<FlatIcon icon="rr-mode-portrait" className="mr-1" />
											Profile
										</>
									),
									content: <PatientProfile patient={patient} />,
								},
								/*
												{
													title: (
														<>
															<FlatIcon icon="rr-running" className="mr-1" />
															Walk-In additional
														</>
													),
													content: <WalkInPatient />,
												}, */
							]}
						/>
					</div>
				</div>
			</CardContainer>
		</>
	);
};

export default PhoViewPatientProfile;
