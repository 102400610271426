import { useEffect, useState } from "react";
import FlatIcon from "src/components/FlatIcon";
import {
	formatDate,
	replaceFname,
	replaceLname,
	replaceMname,
} from "src/helpers/utils";
import {
	calamities,
	collectors,
	garbage_collection,
	housing_electricity_sources,
	housing_status,
	main_sources,
	often_options,
	toilets,
} from "src/helpers/responseChoices";
import CollapseDiv from "./CollapseDiv";
import axios from "axios";

const InfoText = ({ className = "", title, value, ...rest }) => {
	return (
		<div className={`flex flex-col items-start ${className}`} {...rest}>
			<label className="text-placeholder text-sm border-b pr-4 mb-2 border-opacity-50 normal-case">
				{title}
			</label>

			<div className="text-darker text-base font-semibold normal-case">
				{value || (
					<>
						<span className="text-gray-200 font-normal text-xs italic">
							blank
						</span>{" "}
						&nbsp;
					</>
				)}
			</div>
		</div>
	);
};

/* const CollapseDiv = ({ title, children }) => {
	const [open, setOpen] = useState(false);
	return (
		<div
			className={`flex flex-col border rounded-xl w-full overflow-hidden duration-200 group ${
				open ? "max-h-[9999px]" : "max-h-[60px]"
			}`}
		>
			<div
				className="flex items-center bg-slate-100 cursor-pointer p-3"
				onClick={() => {
					setOpen((open) => !open);
				}}
			>
				<h3 className="mb-0 text-base font-bold text-primary capitalize  text-ellipsis">
					{title}
				</h3>
				<div
					className={`rounded-full aspect-square duration-200 bg-opacity-60 group-hover:bg-opacity-100 text-secondary ml-auto flex items-center justify-center ${
						open ? "rotate-180" : "rotate-0"
					}`}
				>
					<FlatIcon icon="rr-caret-down" className="text-2xl" />
				</div>
			</div>
			<div
				className={`bg-white p-4 duration-200  ${
					open ? "max-h-[9999px]" : "max-h-[0px]"
				}`}
			>
				{children}
			</div>
		</div>
	);
};
 */
const ResponseDetails = ({ patient: propPatient }) => {
	const [patient, setPatient] = useState(null);
	const formatTime = (dateToFormat) => {
		if (dateToFormat) {
			let date = new Date(dateToFormat);
			let h = date.getHours() - 12;
			let min = date.getMinutes();
			return "" + Math.abs(h) + ":" + min + " " + (h > 0 ? "AM" : "PM");
		}
		return "";
	};

	let total = 0;

	useEffect(() => {
		if (propPatient?.id) {
			getPatientInfo(propPatient?.id);
		}
	}, [propPatient?.id]);

	const getPatientInfo = (id) => {
		axios.get(`v1/patients/${id}`).then((res) => {
			setPatient(res.data.data);
		});
	};
	return (
		<>
			<CollapseDiv
				title="A. IDENTIFICATION"
				defaultOpen={true}
				headerClassName="bg-slate-100"
				bodyClassName="bg-white"
			>
				<div className="flex flex-col lg:grid lg:grid-cols-12 gap-4">
					<InfoText
						title={`Name of Respondent (Lastname, Firstname Middlename)`}
						value={`${patient?.lastname}, ${patient?.firstname} ${
							patient?.middle || ""
						}`}
						className="col-span-12"
					/>
					<InfoText
						title={`Province`}
						value={"Sarangani"}
						className="col-span-4"
					/>
					<InfoText
						title={`City/Municipality`}
						value={
							patient?.municipality?.name || patient?.municipality
						}
						className="col-span-4"
					/>
					<InfoText
						title={`Zone`}
						value={patient?.zone}
						className="col-span-4"
					/>
					<InfoText
						title={`Barangay`}
						value={patient?.barangay?.name}
						className="col-span-4"
					/>
					<InfoText
						title={`Purok/Sitio`}
						value={patient?.purok_text}
						className="col-span-4"
					/>
					<InfoText
						title={`Street:`}
						value={patient?.street}
						className="col-span-4"
					/>
					<InfoText
						title={`House Number`}
						value={patient?.household?.house_number}
						className="col-span-4"
					/>
					<InfoText
						title={`Household Identification Number`}
						value={patient?.household?.house_id}
						className="col-span-4"
					/>
				</div>
			</CollapseDiv>
			<CollapseDiv title="B. HOUSING CHARACTERISTICS">
				<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
					<InfoText
						title={`1. In what type of building does the household reside?`}
						value={
							patient?.household?.houseCharacteristics
								?.building_type
						}
					/>
					<InfoText
						title={`2. What type of construction materials are the roof made of?`}
						value={
							patient?.household?.houseCharacteristics
								?.roof_materials
						}
					/>
					<InfoText
						title={`3. What type of construction materials are the other walls made of?`}
						value={
							patient?.household?.houseCharacteristics
								?.wall_materials
						}
					/>
				</div>
			</CollapseDiv>
			<CollapseDiv title="C. CHARACTERISTICS OF THE HOUSEHOLD">
				<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
					<InfoText
						title={`4. How many members of the households are overseas workers?`}
						value={patient?.household?.rawAnswer?.overseas_members}
					/>
					<InfoText
						title={`5. How many families are there in the household?`}
						value={patient?.household?.rawAnswer?.nuclear_families}
					/>
					<InfoText
						title={`Any family planning method currently used?`}
						value={patient?.household?.rawAnswer?.fam_plan}
					/>
					{patient?.household?.rawAnswer?.fam_plan == "Yes" ? (
						<>
							<InfoText
								title={`Family Plan`}
								value={patient?.household?.rawAnswer?.fp}
							/>

							<InfoText
								title={`Any family planning method currently used?`}
								value={patient?.household?.rawAnswer?.fp_method}
							/>
						</>
					) : (
						""
					)}
					{/* <InfoText title={`Family Plan`} value="Modern Family Planning" /> */}
					<InfoText
						title={`Are there any pregnant women in the household?`}
						value={patient?.household?.rawAnswer?.pregnant}
					/>
					<InfoText
						title={`How many members of the household are pregnant?`}
						value={patient?.household?.rawAnswer?.pregnant_number}
					/>
					<InfoText
						title={`Are there any solo parents in the household?`}
						value={patient?.household?.rawAnswer?.solo}
					/>
					{/* <InfoText
							title={`How many members of the household are solo parents?`}
							value="1"
						/> */}
					<InfoText
						title={`Are there any PWD in the household?`}
						value={patient?.household?.rawAnswer?.pwd}
					/>
					{/* <InfoText
							title={`How many members of the household are PWD?`}
							value="1"
						/> */}
					<InfoText
						title={`Are there any domesticated dogs or cats in the household?`}
						value={patient?.household?.rawAnswer?.pets}
					/>
					{patient?.household?.rawAnswer?.pets == "yes" ?? (
						<>
							<InfoText
								title={`Select which one/ones`}
								value={
									patient?.household?.rawAnswer?.number_pets
								}
							/>
							<InfoText
								title={`Have all your pets been vaccinated not surpassing more than a year?`}
								value={patient?.household?.rawAnswer?.pet_vax}
							/>
							<InfoText
								title={`Vaccination Date`}
								value={
									patient?.household?.rawAnswer
										?.pet_vaccine_date
								}
							/>
						</>
					)}

					<InfoText
						title={`How many members are there in the household including OFWs?`}
						value={
							<div className="flex flex-col">
								<span className="font-semibold">
									{patient?.houseCharacteristics
										?.houseHoldMembers?.length || 0}{" "}
									members
								</span>
								<ul className="pl-4 pt-2">
									{patient?.houseCharacteristics?.houseHoldMembers?.map(
										(item) => {
											return (
												<li
													className="flex items-center cursor-pointer hover:text-primary duration-200"
													onClick={() => {
														household_modal.current.show(
															item
														);
													}}
												>
													<FlatIcon
														icon="rr-user"
														className="mr-2 text-sm"
													/>{" "}
													{item?.name}
												</li>
											);
										}
									)}
								</ul>
							</div>
						}
					/>
				</div>
			</CollapseDiv>
			<CollapseDiv title="K. WATER AND SANITATION">
				<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
					<InfoText
						title={`What is your household's main source of water for general use?`}
						value={
							main_sources.find(
								(x) =>
									x.value ==
									patient?.household?.rawAnswer?.main_source
							)?.label || ""
						}
					/>
					<InfoText
						title={`What is your household's main source of drinking water?`}
						value={
							main_sources.find(
								(x) =>
									x.value ==
									patient?.household?.rawAnswer?.drink_water
							)?.label || ""
						}
					/>
					{/* <InfoText title={`Specify other`} value="Test other response" /> */}
					{/* <InfoText
							title={`How far is this water source from your house?`}
							value={patient?.sanitation?.main_source}
						/> */}
					<InfoText
						title={`What type of toilet facility does the household have?`}
						value={
							toilets.find(
								(x) =>
									x.value ==
									patient?.household?.rawAnswer?.hh_toilet
							)?.label || ""
						}
					/>
					{/* <InfoText
							title={`If NO Own toilet, Access is from?`}
							value="Shared Toilet, Neighbor Relative"
						/>
						<InfoText title={`Specify other`} value="Test other response" /> */}
				</div>
			</CollapseDiv>
			<CollapseDiv title="L. HOUSING">
				<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
					<InfoText
						title={`What is the tenure status of the housing unit and lot occupied by your household`}
						value={
							housing_status.find(
								(x) =>
									x.value ==
									patient?.household?.rawAnswer?.status
							)?.label || ""
						}
					/>
					<InfoText
						title={`How long have you been staying in your house?`}
						value={
							patient?.household?.rawAnswer?.residence_area +
							" years"
						}
					/>
					<InfoText
						title={`Is there any electricity in the dwelling place?`}
						value={patient?.household?.rawAnswer?.electric}
					/>
					<InfoText
						title={
							"What is the source of electricity in the dwelling place?"
						}
						value={
							housing_electricity_sources.find(
								(x) =>
									x.value ==
									patient?.household?.rawAnswer
										?.electric_housing
							)?.label || ""
						}
					/>
					<InfoText
						title={`Specify other`}
						value="Test other response"
					/>
					<InfoText
						title={
							"What communication channel do you get your primary means of information?"
						}
						value={patient?.household?.rawAnswer?.com_channel.replace(
							/ /g,
							", "
						)}
					/>
				</div>
			</CollapseDiv>
			<CollapseDiv title="M. WASTE MANAGEMENT">
				<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
					<InfoText
						title={`What is the system of garbage disposal adopted by the household?`}
						value={
							<div className="flex flex-col">
								{patient?.household?.rawAnswer?.garbage_disposal
									.split(" ")
									.map((item) => {
										return (
											<span className="mb-2 w-full">
												{garbage_collection.find(
													(x) => x.value == item
												)?.label || ""}
											</span>
										);
									})}
							</div>
						}
					/>
					<InfoText
						title={`Who collects the garbage?`}
						value={
							<div className="flex flex-col">
								{patient?.household?.rawAnswer?.collector
									.split(" ")
									.map((item) => {
										return (
											<span className="mb-2 w-full">
												{collectors.find(
													(x) => x.value == item
												)?.label || ""}
											</span>
										);
									})}
							</div>
						}
					/>

					<InfoText
						title={`How often is the garbage collected?`}
						value={
							<div className="flex flex-col">
								{patient?.household?.rawAnswer?.often_garbage
									.split(" ")
									.map((item) => {
										return (
											<span className="mb-2 w-full">
												{often_options.find(
													(x) => x.value == item
												)?.label || ""}
											</span>
										);
									})}
							</div>
						}
					/>
					<InfoText
						title={`Estimated Volume of Waste`}
						value={`${patient?.household?.rawAnswer?.volume_waste} Kg/Month`}
					/>
					{patient?.household?.rawAnswer?.disposalothers ? (
						<InfoText
							title={`Specify other`}
							value={patient?.waste?.disposalothers}
						/>
					) : (
						""
					)}
				</div>
			</CollapseDiv>
			<CollapseDiv title="N. SOURCES OF INCOME">
				<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
					<InfoText
						title={
							<>
								<em>[Monthly]</em> Select sources of income
							</>
						}
						value={
							<table className="border">
								<thead>
									<tr>
										<th className="text-left border-b-2 px-3 py-1">
											Job name
										</th>
										<th className="text-left border-b-2 px-3 py-1 border-l">
											Monthly Income
										</th>
									</tr>
								</thead>
								<tbody className="divide-y">
									{patient?.household?.income?.map((item) => {
										total += parseFloat(item?.amount);
										return (
											<tr>
												<td className="font-normal text-sm px-3 py-1">
													{item?.name}
												</td>
												<td className="text-right font-normal text-sm px-3 py-1 border-l">
													₱{" "}
													{parseFloat(
														item?.amount
													).toFixed(2)}
												</td>
											</tr>
										);
									})}
									<tr>
										<td className="font-bold text-sm px-3 py-1 border-t-gray-300 border-t-2">
											TOTAL HOUSEHOLD INCOME
										</td>
										<td className="text-right font-bold text-sm px-3 py-1 border-l border-t-gray-300 border-t-2">
											₱ {total?.toFixed(2)}
										</td>
									</tr>
								</tbody>
							</table>
						}
					/>
				</div>
			</CollapseDiv>
			<CollapseDiv title="O. CALAMITY">
				<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
					<InfoText
						title={`Have you experienced any calamity in the last 6 months?`}
						value={
							patient?.household?.rawAnswer?.calamity_experienced
						}
					/>
					<InfoText
						title={`Which calamity have you experienced?`}
						value={
							<div className="flex flex-col">
								{patient?.household?.rawAnswer?.calamity
									.split(" ")
									.map((item) => {
										return (
											<span className="mb-1 w-full">
												{calamities.find(
													(x) => x.value == item
												)?.label || ""}
											</span>
										);
									})}
							</div>
						}
					/>
					<InfoText
						title={`What assistance did you receive from the government?`}
						value={
							patient?.household?.rawAnswer?.assistancecalamity
						}
					/>
					<InfoText
						title={`Name of Surveyor`}
						value={patient?.surveyor || "central-pho-ho"}
					/>
				</div>
			</CollapseDiv>
		</>
	);
};

export default ResponseDetails;
