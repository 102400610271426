import React, { useState, useContext, useRef, useEffect } from "react";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Dialog,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	FormHelperText,
	Typography,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import Notify from "src/notification/Notify";
import Img from "src/components/Img";
import FlatIcon from "src/components/FlatIcon";
import ActionBtn from "src/components/ActionBtn";

import QRCode from "qrcode.react";
import { calculateAge, formatCurrency, formatDate } from "src/helpers/utils";
import SPHPatientBillingModal from "src/disease-treatment/components/SPHPatientBillingModal";
import { toast } from "react-toastify";
const billing_data = [
	{
		particulars: "Drugs and Medicine (GF)",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Laboratory Examination",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Miscellaneous",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "NonDrugs / Supplies",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "PHIC",
		debit: 0.0,
		discount: 0.0,
		credit: 0.0,
		balance: 0.0,
	},
	{
		particulars: "Radiology",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Room and Board",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
];

const modalKey = uuidv4();
const PatientBillingDetails = ({
	details,
	appointment: dataProps,
	getAdmittedPatientsForBillout,
	resetDisplay,
	reloadPage,
}) => {
	const [appointment, setAppointment] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [patientBills, setPatientBills] = useState([]);
	const [modalKeys, setModalKeys] = useState(`${modalKey}`);
	const { users } = useContext(UsersData);
	const mounted = IsMountedRef();
	const sendToCashierRef = useRef(null);
	const [processPhilDialog, setProcessPhilDialog] = useState({
		open: false,
	});
	useEffect(() => {
		setAppointment(dataProps);
	}, [dataProps?.id]);
	const [sentToCashierDialog, setSentToCashierDialog] = useState({
		open: false,
	});

	const getallBillForPaymentByPatient = React.useCallback(async () => {
		axios
			.get(`billing/admitted/patient/get-patient-billslist`, {
				params: {
					patient_id: details?.patient_id,
					trace_number: details?.trace_number,
					management_id: users?.management_id,
					main_mgmt_id: users?.main_mgmt_id,
				},
			})
			.then(({ data }) => {
				if (mounted.current) {
					setPatientBills(data);
				}
			})
			.catch((err) => console.log(`error:`, err.message))
			.finally(() => setIsLoading(false));
	}, [details, mounted, users]);

	React.useEffect(() => {
		getallBillForPaymentByPatient();
	}, [getallBillForPaymentByPatient]);

	let totalbill = 0;

	const getSumObj = (objName) => {
		let total = 0;
		billing_data?.map((data) => {
			total += data[objName];
		});
		return formatCurrency(total);
	};

	const markAsPaid = (data) => {
		// setLoading(true);
		let formData = new FormData();
		formData.append("appointment_id", appointment?.id);
		formData.append("_method", "PATCH");

		axios
			.post(`/v1/clinic/mark-as-paid/${appointment?.id}`, formData)
			.then((res) => {
				// addToList(data);
				setTimeout(() => {
					// setLoading(false);
					// getPatientInfo();
					// onSuccess();
					toast.success(
						"Patient is now paid and can now be released!"
					);
				}, 400);
				// reloadPage();
				setAppointment((x) => ({
					...x,
					status: "done",
				}));
				// window.location.reload();
				// hide();
			})
			.finally(() => {
				// setLoading(false);
			});
	};
	return (
		<Box>
			<Card key={`ccccccard-${modalKeys}`}>
				<div className="col-span-12 w-full">
					<div className="flex flex-col lg:flex-row gap-4 items-center px-4 pt-4 border-b justify-center md:justify-start pb-4">
						<div className="group relative h-36 w-36 min-h-[144px] min-w-[144px] rounded-full aspect-square bg-background">
							<Img
								type="user"
								name={`${details?.lastname}-${details?.firstname}-${details?.middle}`}
								src={details?.avatar || ""}
								className="min-h-[144px] min-w-[144px] aspect-square object-cover rounded-full"
								alt=""
								id="user-image-sample"
								key={`key-${details?.id}-${details?.avatar}`}
							/>
						</div>
						<div className="flex flex-col pl-4">
							<h6
								className={`text-left text-2xl mb-1 font-semibold flex items-center ${
									details?.gender == "Male"
										? "text-blue-800"
										: "text-pink-800"
								} mb-0`}
							>
								{`${details?.lastname}, ${details?.firstname} ${
									details?.middle || ""
								}`}
							</h6>
							<div className="flex gap-6 mb-2">
								<div className="flex items-center gap-2 text-base">
									<FlatIcon
										icon="rr-calendar-clock"
										className="text-lg"
									/>
									<span>
										{calculateAge(details?.birthday)} yrs.
										old
									</span>
								</div>
								<div className="flex items-center gap-2 text-base">
									<FlatIcon
										icon="rr-calendar"
										className="text-lg"
									/>
									<span>{formatDate(details?.birthday)}</span>
								</div>
							</div>
							<div className="flex gap-4 mb-2">
								<div className="flex items-center gap-2 text-base">
									<FlatIcon
										icon="rr-venus-mars"
										className="text-lg"
									/>
									{details?.gender == "Male" ? (
										<span className="text-blue-700">
											Male
										</span>
									) : (
										<span className="text-pink-700">
											Female
										</span>
									)}
								</div>
							</div>
						</div>
						<div className="flex flex-col gap-y-1 ml-auto">
							<QRCode
								value={JSON.stringify({
									patient_id: details?.patient_id,
									user_id: users?.user_id,
									philhealth: "philhealth",
								})}
								level="H"
								size={144}
							/>
						</div>
					</div>
				</div>

				{appointment?.status === "pending-for-cashier" &&
					users?.type == "SPH-BILLING" && (
						<Box mt={2}>
							<FormHelperText error>
								Patient is successfully move to cashier for
								discharge slip or payment if needed.
							</FormHelperText>
						</Box>
					)}
				{appointment?.status == "pending-for-cashier" ||
				appointment?.status == "pending-for-billing" ? (
					<>
						<h3 className="!font-bold text-xl pt-4 mb-0 pl-3">
							Patient's Bill
						</h3>
						<CardContent className="flex flex-col">
							<div className="table text-base !bg-white">
								<table>
									<thead>
										<tr>
											<th className="text-center text-base !bg-white">
												PARTICULARS
											</th>
											<th className="text-right text-base !bg-white">
												DEBIT
											</th>
											<th className="text-right text-base !bg-white">
												DISCOUNT
											</th>
											<th className="text-right text-base !bg-white">
												CREDIT
											</th>
											<th className="text-right text-base !bg-white">
												BALANCE
											</th>
										</tr>
									</thead>
									<tbody>
										{billing_data?.map((data) => {
											return (
												<tr
													key={`bill-data-${data?.particulars}`}
												>
													<td className="text-left text-base !bg-white">
														{data?.particulars}
													</td>
													<td className="text-right text-base !bg-white">
														{formatCurrency(
															data?.debit
														)}
													</td>
													<td className="text-right text-base !bg-white">
														{formatCurrency(
															data?.discount
														)}
													</td>
													<td className="text-right text-base !bg-white">
														{formatCurrency(
															data?.credit
														)}
													</td>
													<td className="text-right text-base !bg-white">
														{formatCurrency(
															data?.balance
														)}
													</td>
												</tr>
											);
										})}
									</tbody>
									<tfoot className="border-t-2 border-t-black">
										<tr>
											<th className="border-t-2 border-t-black text-right text-base">
												<b>TOTAL AMOUNT TO PAY:</b>
											</th>
											<th className="border-t-2 border-t-black text-right text-base">
												{getSumObj("debit")}
											</th>
											<th className="border-t-2 border-t-black text-right text-base">
												{getSumObj("discount")}
											</th>
											<th className="border-t-2 border-t-black text-right text-base">
												{getSumObj("credit")}
											</th>
											<th className="border-t-2 border-t-black text-right text-base">
												{getSumObj("balance")}
											</th>
										</tr>
									</tfoot>
								</table>
							</div>
							{appointment?.status == "pending-for-billing" &&
								users.type == "SPH-BILLING" && (
									<ActionBtn
										className="ml-auto"
										onClick={() => {
											sendToCashierRef.current.show(
												appointment
											);
										}}
									>
										Send to cashier
									</ActionBtn>
								)}
							{appointment?.status == "pending-for-cashier" &&
								users?.type == "SPH-CASHIER" && (
									<ActionBtn
										className="ml-auto"
										onClick={() => {
											markAsPaid();
										}}
									>
										PAID and Release patient
									</ActionBtn>
								)}
						</CardContent>
					</>
				) : (
					<div className="flex items-center justify-center p-5 text-lg font-semibold text-slate-400">
						No billings to display
					</div>
				)}
			</Card>

			<SPHPatientBillingModal
				key={`SPHPatientBillingModal-${modalKeys}`}
				ref={sendToCashierRef}
				patient={details}
				refresh={() => {
					// setFilters((prevFilters) => ({
					// 	...prevFilters,
					// 	key: uuidv4(),
					// }));
					setModalKeys(uuidv4());
				}}
				onSuccess={() => {
					setModalKeys(uuidv4());
				}}
			/>
		</Box>
	);
};

export default PatientBillingDetails;
