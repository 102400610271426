import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Box,
  TextField,
  Grid,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import axios from "axios";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext } from "react";
import { CheckSquare, XCircle } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import * as Yup from "yup";

const DischargeForm = ({ patient_id, trace_number, onClose }) => {
  const { users } = useContext(UsersData);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <>
      <Formik
        initialValues={{
          username: users.username,
          user_id: users.user_id,
          management_id: users.management_id,
          main_mgmt_id: users.main_mgmt_id,
          patient_id: patient_id,
          trace_number: trace_number,
          date_admitted: moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
          discharged_date: moment(new Date(), "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          ),
        }}
        validationSchema={Yup.object().shape({
          //   payment: Yup.number().required("Payment is required."),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            const request = await axios.post(
              "cashier/discharged/patient/add-charge-slip",
              getFormData(values)
            );

            if (request.data.message === "success") {
              Notify.successRequest("patient discharged");
              resetForm();
              onClose();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box>
                <DatePicker
                  inputVariant="outlined"
                  margin="normal"
                  error={Boolean(touched.date_admitted && errors.date_admitted)}
                  helperText={touched.date_admitted && errors.date_admitted}
                  onChange={(date) => setFieldValue("date_admitted", date)}
                  value={values.date_admitted}
                  fullWidth
                  required
                  name="date_admitted"
                  label="Date Admitted"
                />
              </Box>

              <Box>
                <TextField
                  error={Boolean(
                    touched.admitted_reason && errors.admitted_reason
                  )}
                  helperText={touched.admitted_reason && errors.admitted_reason}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.admitted_reason}
                  fullWidth
                  required
                  name="admitted_reason"
                  label="Admitted Reason"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={3}
                />
              </Box>

              <Box>
                <TextField
                  error={Boolean(touched.diagnosis && errors.diagnosis)}
                  helperText={touched.diagnosis && errors.diagnosis}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.diagnosis}
                  fullWidth
                  required
                  name="diagnosis"
                  label="Diagnosis"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={3}
                />
              </Box>

              <Box>
                <TextField
                  error={Boolean(touched.treatment && errors.treatment)}
                  helperText={touched.treatment && errors.treatment}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.treatment}
                  fullWidth
                  required
                  name="treatment"
                  label="Treatment"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={3}
                />
              </Box>

              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <DatePicker
                        inputVariant="outlined"
                        margin="normal"
                        error={Boolean(
                          touched.discharged_date && errors.discharged_date
                        )}
                        helperText={
                          touched.discharged_date && errors.discharged_date
                        }
                        onChange={(date) =>
                          setFieldValue("discharged_date", date)
                        }
                        value={values.discharged_date}
                        fullWidth
                        required
                        name="discharged_date"
                        label="Discharged Date"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        error={Boolean(
                          touched.is_md_approved_discharged &&
                            errors.is_md_approved_discharged
                        )}
                        helperText={
                          touched.is_md_approved_discharged &&
                          errors.is_md_approved_discharged
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.is_md_approved_discharged}
                        fullWidth
                        required
                        name="is_md_approved_discharged"
                        label="Is approve by physician"
                        variant="outlined"
                        margin="normal"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <option value=""> Select </option>
                        <option value={1}> Yes </option>
                        <option value={0}> No </option>
                      </TextField>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <TextField
                  error={Boolean(
                    touched.discharged_reason && errors.discharged_reason
                  )}
                  helperText={
                    touched.discharged_reason && errors.discharged_reason
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.discharged_reason}
                  fullWidth
                  required
                  name="discharged_reason"
                  label="Discharge Reason"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={3}
                />
              </Box>

              <Box>
                <TextField
                  error={Boolean(touched.discharged_md && errors.discharged_md)}
                  helperText={touched.discharged_md && errors.discharged_md}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.discharged_md}
                  fullWidth
                  required
                  name="discharged_md"
                  label="Discharge MD"
                  variant="outlined"
                  margin="normal"
                />
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckSquare />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>

              <Button
                variant="contained"
                color="default"
                startIcon={<XCircle />}
                onClick={() => onClose()}
              >
                No
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </>
  );
};

export default DischargeForm;
