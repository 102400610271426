import React, { useEffect, Fragment, useState } from "react";
import { Box, Tabs, Tab, Grid, makeStyles, Paper } from "@material-ui/core";

import HealthPreLaboratory from "./HealthPreLaboratory";
import HealthMedication from "./HealthMedication";
import HealthAllergies from "./HealthAllergies";
import HealthDiagnosis from "./HealthDiagnosis";
import TabPanel from "../../utils/TabPanel";
import HealthFamilyHistory from "./HealthFamilyHistory";
import HealthDiet from "./HealthDiet";
import HealthBMI from "./HealthBMI";

const useStyle = makeStyles({
  indicatorLeft: {
    left: "0px",
  },
});

const Health = (props) => {
  const classes = useStyle();
  const [category, setCategory] = useState(0);

  useEffect(() => {}, [props.patient_id]);

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
          <Grid item xs={12} sm={12} md={10}>
            <Box>
              <TabPanel value={category} index={0}>
                <HealthPreLaboratory
                  patient_id={props.patient_id}
                  connection={props.connection}
                />
              </TabPanel>

              <TabPanel value={category} index={1}>
                <HealthBMI
                  patient_id={props.patient_id}
                  connection={props.connection}
                />
              </TabPanel>

              <TabPanel value={category} index={2}>
                <HealthAllergies
                  patient_id={props.patient_id}
                  connection={props.connection}
                />
              </TabPanel>

              <TabPanel value={category} index={3}>
                <HealthDiagnosis
                  patient_id={props.patient_id}
                  connection={props.connection}
                />
              </TabPanel>

              <TabPanel value={category} index={4}>
                <HealthMedication
                  patient_id={props.patient_id}
                  connection={props.connection}
                />
              </TabPanel>

              <TabPanel value={category} index={5}>
                <HealthDiet
                  patient_id={props.patient_id}
                  connection={props.connection}
                />
              </TabPanel>

              <TabPanel value={category} index={6}>
                <HealthFamilyHistory
                  patient_id={props.patient_id}
                  connection={props.connection}
                />
              </TabPanel>
            </Box>
          </Grid>
        </Box>
        <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
          <Grid item xs={12} sm={12} md={2}>
            <Paper variant="outlined">
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
                variant="scrollable"
                orientation={
                  window.innerWidth > 959 ? "vertical" : "horizontal"
                }
                classes={{
                  indicator: classes.indicatorLeft,
                }}
              >
                <Tab wrapped label="Pre Lab" />
                <Tab wrapped label="BMI Calcu" />
                <Tab wrapped label="Allergies" />
                <Tab wrapped label="Diagnosis" />
                <Tab wrapped label="Medication" />
                <Tab wrapped label="Diet" />
                <Tab wrapped label="Family Hist" />
              </Tabs>
            </Paper>
          </Grid>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default Health;
