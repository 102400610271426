import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import SelectItemsSelector from "./SelectItemsSelector";
import Img from "src/components/Img";
import { UsersData } from "src/ContextAPI";
import {
	dataURItoBlob,
	dateYYYYMMDD,
	formatDateMMDDYYYYHHIIA,
} from "src/helpers/utils";
import ItemServiceList from "./ItemServiceList";
import SelectInputField from "src/components/forms/SelectInputField";
import SelectToUseItems from "./SelectToUseItems";
import ImagePicker from "src/components/forms/ImagePicker";
const symptoms = [
	{
		value: "cough_for_3_weeks_or_longer",
		label: "Cough for three weeks or longer",
	},
	{
		value: "coughing_up_blood_or_mucus",
		label: "Coughing up blood or mucus",
	},
	{ value: "chest_pain", label: "Chest pain" },
	{
		value: "pain_with_breathing_or_coughing",
		label: "Pain with breathing or coughing",
	},
	{ value: "fever", label: "Fever" },
	{ value: "chills", label: "Chills" },
	{ value: "night_sweats", label: "Night sweats" },
	{ value: "weight_loss", label: "Weight loss" },
	{ value: "not_wanting_to_eat", label: "Not wanting to eat" },
	{ value: "tiredness", label: "Tiredness" },
	{
		value: "not_feeling_well_in_general",
		label: "Not feeling well in general",
	},
];

const AcceptPatientModal = (props, ref) => {
	const { patient, refresh, onSuccess } = props;
	const { users } = useContext(UsersData);
	const { register: register1, watch: watch1 } = useForm();
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		trigger,
		control,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(0);
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const [refreshKey, setRefreshKey] = useState(1);
	const [selected, setSelected] = useState(null);
	const [selectedRHU, setSelectedRHU] = useState(null);
	const [selectedClick, setSelectedClick] = useState(null);
	const [isPositive, setIsPositive] = useState(false);
	const [itemsUsed, setItemsUsed] = useState(false);
	const [forConfirmation, setForConfirmation] = useState(false);
	const [items, setItems] = useState([]);
	const [doctors, setDoctors] = useState([]);
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [specimen, setSpecimen] = useState(null);

	useEffect(() => {
		let t = setTimeout(() => {
			getTbDoctors();
		}, 100);
		return () => {
			clearTimeout(t);
		};
	}, []);

	// console.log("usersusersusers", users);

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const acceptPatient = (data) => {
		setLoading(true);
		let formdata = new FormData();
		formdata.append("doctor_id", data?.doctor);
		formdata.append("_method", "PATCH");

		axios
			.post(`v1/clinic/tb-accept-patient/${appointment?.id}`, formdata)
			.then((response) => {
				let data = response.data;
				// console.log(data);
				setLoading(false);
				setTimeout(() => {
					onSuccess();
					toast.success("Patient accepted!");
				}, 200);
				refresh();
				hide();
			})
			.catch((err) => {
				// setLoading(false);
				console.log(err);
			});
	};

	const getTbDoctors = () => {
		axios.get(`/v1/clinic/tb-doctors`).then((res) => {
			setDoctors(res.data.data);
		});
	};
	return (
		<Modal
			open={open}
			staticModal={true}
			size="lg"
			hide={hide}
			className=" w-full"
		>
			<ModalHeader
				title={`Accept patient`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
					<div className="table text-sm pt-4 lg:col-span-2">
						<table>
							<tbody>
								<tr>
									<th
										className="w-[144px] text-base"
										colSpan={4}
									>
										Appointment details
									</th>
								</tr>
								<tr>
									<th className="w-[144px] text-sm">Date:</th>
									<td className=" text-base text-black">
										{appointment?.date}
									</td>
									<th className="w-[144px] text-sm">Time:</th>
									<td className=" text-base text-black">
										{appointment?.time}
									</td>
								</tr>
								<tr>
									<th className="w-[144px] text-sm">
										Chief complaint:
									</th>
									<td className=" text-sm" colSpan={2}>
										{appointment?.pre_notes}
									</td>
								</tr>
								<tr>
									<th className="w-[144px] text-sm">
										Initial Diagnosis:
									</th>
									<td className=" text-sm" colSpan={3}>
										{appointment?.post_notes}
									</td>
								</tr>
								<tr>
									<th
										className="w-[144px] text-sm max-w-[50%] break-all"
										colSpan={1}
									>
										Brief Clinical History and Pertinent
										Physical Examination:
									</th>
									<td className=" text-sm" colSpan={3}>
										{appointment?.clinical_history}
									</td>
								</tr>
								<tr>
									<th
										className="w-[144px] text-sm max-w-[50%] break-all"
										colSpan={1}
									>
										Laboratory Findings (Including ECG,
										X-ray, and other diagnostic procedures)
									</th>
									<td className=" text-sm" colSpan={3}>
										{appointment?.lab_findings}
									</td>
								</tr>
								<tr>
									<th className="w-[144px] text-sm">
										Impression
									</th>
									<td className=" text-sm">
										{appointment?.impression}
									</td>
									<th className="w-[144px] text-sm">
										Action Taken
									</th>
									<td className=" text-sm">
										{appointment?.action_taken}
									</td>
								</tr>
								<tr>
									<th className="w-[144px] text-sm">
										Health Insurance Coverage
									</th>
									<td className=" text-sm">
										{
											appointment?.health_insurrance_coverage
										}
									</td>
									<th className="w-[144px] text-sm">
										Health Insurance Type
									</th>
									<td className=" text-sm">
										{
											appointment?.health_insurrance_coverage_if_yes_type
										}
									</td>
								</tr>
								<tr>
									<th className="w-[144px] text-sm">
										Reason:
									</th>
									<td className=" text-sm" colSpan={3}>
										{appointment?.reason}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<select
					className={`border py-2.5 border-transparent text-sm text-dark sm:text-sm rounded-lg focus:ring-1 focus:shadow-lg duration-100 shadow-blue-300 focus:ring-blue-500 block w-full p-2.5 ${
						errors?.doctor?.message
							? "border-red-500 text-red-500"
							: ""
					}`}
					{...register("doctor", {
						required: "This field is required.",
					})}
				>
					<option value="" selected disabled>
						Select TB Doctor
					</option>
					{doctors?.map((doc) => {
						return <option value={doc?.id}>{doc?.name}</option>;
					})}
				</select>
				{errors?.doctor?.message ? (
					<span className="text-danger text-sm">
						{errors?.doctor?.message}
					</span>
				) : (
					""
				)}
			</ModalBody>
			<ModalFooter className={"flex items-center justify-between gap-4"}>
				<ActionBtn
					className="w-1/3"
					onClick={handleSubmit(acceptPatient)}
					// disabled={!watch("positive") || isPositive}
				>
					<FlatIcon icon="rr-checkbox" className="-mt-[4px]" />
					Accept patient
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(AcceptPatientModal);
