class CheckerInternet {
  online = async () => {
    // return await fetch('https://www.google.com', {
    //   		mode: 'no-cors'
    // 	})
    return await fetch("https://ipv4.icanhazip.com/");
  };
}

const CheckInternet = new CheckerInternet();
export default CheckInternet;
