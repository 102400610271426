import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAddressCard,
	faHome,
	faPersonBooth,
	faUsers,
	faBars,
	faUserLock,
	faMapMarked,
	faChartBar,
	faListUl,
	faUserInjured,
	faVirus,
	faHouseUser,
	faChartArea,
	faTruckMoving,
	faVideo,
} from "@fortawesome/free-solid-svg-icons";
import PhoDashboard from "src/pho/PhoDashboard";
import PhoPatientList from "src/pho/PhoPatientList";
import PhoPatients from "src/pho/PhoPatients";
import PhoAnalyticsMap from "src/pho/PhoAnalyticsMap";
import Account from "src/pho/account/Account";
import PhoForm from "src/pho/components/PhoFormModal";
import PhoDiseases from "src/pho/PhoDiseases";
import PhoHouseholds from "src/pho/PhoHouseholds";
import HouseholdFormPage from "src/pho/HouseholdFormPage";
import Test from "./Test";
import PhoHouseholdMemberFormPage from "src/pho/PhoHouseholdMemberPage";
import ManagePhoUsers from "src/pho/ManagePhoUsers";
import PhoAccount from "src/pho/PhoAccount";
import PhoClinicManagement from "src/pho/clinic-management/PhoClinicManagement";
import PhoClinicPersonnel from "src/pho/clinic-personnel/PhoClinicPersonel";
import PhoClinicProfile from "src/pho/clinic-management/PhoClinicProfile";
import PhoPhilhealthForm from "src/pho/PhoPhilhealthForm";
import ItemManagement from "src/pho/items/ItemManagement";
import SupplierManagement from "src/pho/supplier/SupplierManagement";
import PhoCensus from "src/pho/PhoCensus";
import Pmrf from "src/pho/printable-forms/Pmrf";
import PhoLMISPage from "src/pho/PhoLMISPage";
import LMISConsignmentForm from "src/pho/lmis/LMISConsignmentForm";
import LMISConsignmentView from "src/pho/lmis/LMISConsignmentView";
import ItemInventory from "src/pho/items/ItemInventory";
import BhsDashboard from "src/disease-treatment/BhsDashboard";
import BhsPatients from "src/disease-treatment/BhsPatients";
import BhsLMISPage from "src/disease-treatment/BhsLMISPage";
import BhsAppointments from "src/disease-treatment/BhsAppointments";
import BhsVideoCall from "src/disease-treatment/BhsVideoCall";
import BhsPatientViewProfile from "src/disease-treatment/BhsPatientViewProfile";

const RouteBHS = () => {
	const [sidebarHeader, setSidebarHeader] = useState([]);
	const userContext = useContext(UsersData);
	// console.log("userContextuserContext", userContext);
	const { users } = userContext;
	const [user, setUser] = useState(null);
	const [pendings, setPendings] = useState(null);
	const getSidebarHeaderInfo = async () => {
		var params = { user_id: userContext.users.user_id };
		const { data } = await axios.get(
			"admitting/sidebar/header-infomartion",
			{
				params,
			}
		);
		setSidebarHeader(data);
	};

	const getPendingForMedsRelease = () => {
		axios.get(`/v1/clinic/bhw-pending-for-medicine-release`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	useEffect(() => {
		// let t = setInterval(() => {
		// 	getPendingForMedsRelease();
		// }, 5000);
		// return () => {
		// 	clearTimeout(t);
		// };
	}, []);
	useEffect(() => {
		let t = setTimeout(() => {
			getAllUsers();
			getPendingForMedsRelease();
		}, 100);
		return () => {
			clearTimeout();
		};
		// getPendingForMedsRelease();
	}, [1]);

	const getAllUsers = () => {
		axios.get("v1/pho/users").then((res) => {
			let users_ = res.data.data;
			let found = users_?.find((x) => x.user_id == users?.user_id);

			setUser(found);
		});
	};

	const forPHO =
		users?.type == "PHO-HO"
			? [
					{
						name: "Health Officers Accounts",
						show: true,
						icon: (
							<FontAwesomeIcon
								icon={faUsers}
								color="white"
								style={{ fontSize: 18 }}
							/>
						),
						path: "/sph/app/bhs/users",
						subitem: [],
					},
			  ]
			: [];
	const sidebarRoute = [
		{
			name: "Dashboard",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faHome}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/bhs/dashboard",
			subitem: [],
		},
		// {
		// 	name: "Appointments",
		// 	show: true,
		// 	icon: (
		// 		<FontAwesomeIcon
		// 			icon={faUsers}
		// 			color="white"
		// 			style={{ fontSize: 18 }}
		// 		/>
		// 	),
		// 	path: "/sph/app/bhs/appointments",
		// 	subitem: [],
		// },
		{
			name: "Patients",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUsers}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/bhs/patients",
			children: (
				<div
					className={`absolute left-0 top-0 ${
						pendings?.count == 0 && "hidden"
					}`}
				>
					<div className="left-8  bg-red-500 rounded-full z-20 absolute top-2 text-white text-xs h-5  w-5 flex items-center justify-center">
						{pendings?.count}
					</div>

					<div className="left-8  bg-red-500 rounded-full z-10 absolute top-2 text-white text-xs h-5  w-5 flex items-center justify-center animate-ping"></div>
				</div>
			),
			subitem: [],
		},
		{
			name: "Consignments",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faTruckMoving}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/bhs/lmis",
			subitem: [],
		},
		// {
		// 	name: "Item Management",
		// 	show: true,
		// 	icon: (
		// 		<FontAwesomeIcon
		// 			icon={faHouseUser}
		// 			color="white"
		// 			style={{ fontSize: 18 }}
		// 		/>
		// 	),
		// 	path: "/sph/app/bhs/item-management",
		// 	subitem: [],
		// },
		{
			name: "Item Inventory",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faHouseUser}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/bhs/item-inventory",
			subitem: [],
		},
		// {
		// 	name: "Video Call",
		// 	show: true,
		// 	icon: (
		// 		<FontAwesomeIcon
		// 			icon={faVideo}
		// 			color="white"
		// 			style={{ fontSize: 18 }}
		// 		/>
		// 	),
		// 	path: "/sph/app/bhs/video-call",
		// 	subitem: [],
		// },
		{
			name: "Account",
			show: true,
			icon: (
				<FontAwesomeIcon
					icon={faUserLock}
					color="white"
					style={{ fontSize: 18 }}
				/>
			),
			path: "/sph/app/bhs/account",
			subitem: [],
		},
	];

	const handleRenderInfo = () => {
		getSidebarHeaderInfo();
	};

	useEffect(() => {
		getSidebarHeaderInfo();
		//eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<UsersHeader.Provider
				value={{
					sidebarHeader: sidebarHeader,
					renderPharmacyInfo: handleRenderInfo,
				}}
			>
				<BrowserRouter>
					<Sidebar
						notification={{
							enable: true,
							owner: "pho",
						}}
						header={{ ...sidebarHeader, ...users }}
						routes={sidebarRoute}
						module={users?.type?.replace("-", " ")}
					/>
					<Switch>
						<Route
							exact
							path="/"
							component={() => (
								<Redirect to="/sph/app/bhs/dashboard" />
							)}
						/>
						<Route
							exact
							path="/sph/app"
							component={() => (
								<Redirect to="/sph/app/bhs/dashboard" />
							)}
						/>
						<Route
							exact
							path="/sph/app/bhs/dashboard"
							component={BhsDashboard}
						/>
						<Route
							exact
							path="/sph/app/bhs/appointments"
							component={BhsAppointments}
						/>
						<Route
							exact
							path="/sph/app/bhs/patients"
							component={BhsPatients}
						/>
						<Route
							exact
							path="/sph/app/bhs/patients/:id"
							component={BhsPatientViewProfile}
						/>

						<Route
							exact
							path="/sph/app/bhs/video-call"
							component={BhsVideoCall}
						/>

						<Route
							exact
							path="/sph/app/bhs/item-management"
							component={ItemManagement}
						/>
						<Route
							exact
							path="/sph/app/bhs/item-inventory"
							component={ItemInventory}
						/>
						<Route
							exact
							path="/sph/app/bhs/lmis"
							component={BhsLMISPage}
						/>
						<Route
							exact
							path="/sph/app/bhs/lmis/create"
							component={LMISConsignmentForm}
						/>

						<Route
							exact
							path="/sph/app/bhs/lmis/view/:id"
							component={LMISConsignmentView}
						/>
						<Route
							exact
							path="/sph/app/bhs/lmis/view/:id"
							component={LMISConsignmentView}
						/>

						<Route
							exact
							path="/sph/app/bhs/account"
							component={PhoAccount}
						/>

						<Route
							exact
							path="/sph/app/bhs/pmrf/:id"
							component={Pmrf}
						/>
						<Route
							exact
							path="/sph/app/bhs/test"
							component={Test}
						/>
						<Route
							exact
							path="/sph/app/logout"
							component={Logout}
						/>

						<Route
							render={() => (
								<PageNotFound title="Page not found" />
							)}
						/>
					</Switch>
				</BrowserRouter>
			</UsersHeader.Provider>
		</Fragment>
	);
};

export default RouteBHS;
