import React, { useEffect, Fragment, useCallback, useState } from "react";
import Notify from "src/notification/Notify";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "src/utils/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faClipboardCheck,
	faFolderPlus,
	faNewspaper,
	faVials,
	faWalking,
	faXRay,
} from "@fortawesome/free-solid-svg-icons";
// import Laboratory from "src/documentation/patientqueuemobile/tabpanel/Laboratory";
import DocuPatientDetailsPE from "./DocuPatientDetailsPE";
import DocuPatientDetailsXray from "./DocuPatientDetailsXray";
// import DocuPatientDetailsMedCert from "./DocuPatientDetailsMedCert";
import DocuPatientDetailsLabJabeli from "./DocuPatientDetailsLabJabeli";
import DocuPatientDetailsMedJabeli from "./DocuPatientDetailsMedJabeli";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import { Badge } from "@material-ui/core";
import DocuPatientDetailsECG from "./DocuPatientDetailsECG";
import DocuPatientDetailsOthers from "./DocuPatientDetailsOthers";

// import { UsersData } from "src/ContextAPI";

const DocuPatientDetails = ({
	selectedPatientInfo,
	patient,
	updatePatientList,
	selectedCompany,
	selectedBranch,
	patient_id,
}) => {
	//   const { users } = useContext(UsersData);
	const [category, setCategory] = React.useState("urinestool");
	const mounted = IsMountedRef();
	const [medTechList, setMedTechList] = useState({ data: [], ready: false });
	const [radiologist, setRadiologist] = useState({ data: [], ready: false });

	const handleChange = (event, newCategory) => {
		event.persist();
		setCategory(newCategory);
	};

	const getMedicalTechnologist = useCallback(async () => {
		var params = { management_id: selectedBranch };
		let response = await axios.get("van/get/medical-technologist", {
			params,
		});
		if (mounted.current) {
			setMedTechList({ data: response.data, ready: true });
		}
	}, [selectedBranch, mounted]);

	const getRadiologist = useCallback(async () => {
		var params = { management_id: selectedBranch };
		let response = await axios.get("van/get/radiologist", {
			params,
		});
		if (mounted.current) {
			setRadiologist({ data: response.data, ready: true });
		}
	}, [selectedBranch, mounted]);

	useEffect(() => {
		getMedicalTechnologist();
		getRadiologist();
		// eslint-disable-next-line
	}, [patient_id, selectedPatientInfo]);

	return (
		<>
			{/* {console.log("selectedPatientInfo", selectedPatientInfo)} */}

			{patient.ready && patient.data.length > 0 ? (
				<Fragment>
					<Box m={0} p={0}>
						<Box component={Paper} elevation={1} mb={2}>
							<Tabs
								value={category}
								onChange={handleChange}
								indicatorColor="primary"
								textColor="primary"
								variant="scrollable"
								scrollButtons="on"
							>
								<Tab
									value="urinestool"
									wrapped
									label="Urine/Stool"
									icon={
										<Badge
											color="secondary"
											badgeContent={
												parseInt(selectedPatientInfo.STOOL) +
												parseInt(selectedPatientInfo.URINALYSIS)
											}
										>
											<FontAwesomeIcon icon={faVials} size={"2x"} />
										</Badge>
									}
								/>

								<Tab
									value="xray"
									wrapped
									label="X-Ray/UTZ"
									icon={
										<Badge
											color="secondary"
											badgeContent={parseInt(selectedPatientInfo.count_xray)}
										>
											<FontAwesomeIcon icon={faXRay} size={"2x"} />
										</Badge>
									}
								/>

								<Tab
									value="ecg"
									wrapped
									label="ECG"
									icon={
										<Badge
											color="secondary"
											badgeContent={parseInt(selectedPatientInfo.ECG)}
										>
											<FontAwesomeIcon icon={faFolderPlus} size={"2x"} />
										</Badge>
									}
								/>

								<Tab
									value="others"
									wrapped
									label="Others"
									icon={
										<Badge
											color="secondary"
											badgeContent={parseInt(selectedPatientInfo.SARS)}
										>
											<FontAwesomeIcon icon={faClipboardCheck} size={"2x"} />
										</Badge>
									}
								/>

								<Tab
									value="physicalexam"
									wrapped
									label="P.E"
									icon={
										<Badge
											color="secondary"
											badgeContent={parseInt(selectedPatientInfo.PEXAM)}
										>
											<FontAwesomeIcon icon={faWalking} size={"2x"} />
										</Badge>
									}
								/>

								<Tab
									value="medcert"
									wrapped
									label="Med Cert"
									icon={
										<Badge
											color="secondary"
											badgeContent={parseInt(selectedPatientInfo.MEDCERT)}
										>
											<FontAwesomeIcon icon={faNewspaper} size={"2x"} />
										</Badge>
									}
								/>
							</Tabs>
						</Box>

						<TabPanel value={category} index={"urinestool"}>
							<DocuPatientDetailsLabJabeli
								patient_id={patient_id}
								selectedBranch={selectedBranch}
								updatePatientList={() => updatePatientList()}
								medTechList={medTechList}
							/>
						</TabPanel>

						<TabPanel value={category} index={"xray"}>
							<DocuPatientDetailsXray
								patient_id={patient_id}
								radiologist={radiologist}
								updatePatientList={() => updatePatientList()}
							/>
						</TabPanel>

						<TabPanel value={category} index={"ecg"}>
							<DocuPatientDetailsECG
								patient_id={patient_id}
								selectedBranch={selectedBranch}
								updatePatientList={() => updatePatientList()}
							/>
						</TabPanel>

						<TabPanel value={category} index={"others"}>
							<DocuPatientDetailsOthers
								patient_id={patient_id}
								selectedBranch={selectedBranch}
								updatePatientList={() => updatePatientList()}
							/>
						</TabPanel>

						<TabPanel value={category} index={"physicalexam"}>
							<DocuPatientDetailsPE
								patient_id={patient_id}
								updatePatientList={() => updatePatientList()}
							/>
						</TabPanel>

						<TabPanel value={category} index={"medcert"}>
							<DocuPatientDetailsMedJabeli
								patient_id={patient_id}
								selectedBranch={selectedBranch}
								updatePatientList={() => updatePatientList()}
							/>

							{/* <DocuPatientDetailsMedCert patient_id={patient_id} /> */}
						</TabPanel>
					</Box>
				</Fragment>
			) : (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					height={200}
				>
					{Notify.loading()}
				</Box>
			)}
		</>
	);
};

export default DocuPatientDetails;
