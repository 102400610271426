import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Box,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { Formik } from "formik";
import React, { useContext } from "react";
import { CheckSquare, XCircle } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import * as Yup from "yup";

const DischargePatient = ({
  patient_id,
  trace_number,
  onClose,
  getallBillForPaymentByPatient,
  getAdmittedPatientsForBillout,
  resetDisplay,
  amount,
  philhealth,
  philhealth_caseno,
  philhealth_amount,
}) => {
  const { users } = useContext(UsersData);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <>
      <Formik
        initialValues={{
          username: users.username,
          user_id: users.user_id,
          management_id: users.management_id,
          main_mgmt_id: users.main_mgmt_id,
          patient_id: patient_id,
          trace_number: trace_number,
          amount: amount,
          payment: 0,
          philhealth: philhealth,
          philhealth_caseno: philhealth_caseno,
          philhealth_amount: philhealth_amount,
        }}
        validationSchema={Yup.object().shape({
          payment: Yup.number().required("Payment is required."),
          remarks: Yup.string().trim().required("Remarks is required."),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            const request = await axios.post(
              "cashier/admitted/patient/dischaged-patient",
              getFormData(values)
            );

            if (request.data.message === "success") {
              Notify.successRequest("patient discharged");
              resetForm();
              onClose();
              getallBillForPaymentByPatient();
              getAdmittedPatientsForBillout();
              resetDisplay();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box>
                <TextField
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={touched.amount && errors.amount}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  fullWidth
                  required
                  name="amount"
                  label="Amount to pay"
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>

              <Box>
                <TextField
                  error={Boolean(touched.payment && errors.payment)}
                  helperText={touched.payment && errors.payment}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.payment}
                  fullWidth
                  required
                  name="payment"
                  label="Payment"
                  variant="outlined"
                  margin="normal"
                />
              </Box>

              <Box>
                <TextField
                  error={Boolean(touched.remarks && errors.remarks)}
                  helperText={touched.remarks && errors.remarks}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.remarks}
                  fullWidth
                  required
                  name="remarks"
                  label="Discharge Remarks"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={3}
                />
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckSquare />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>

              <Button
                variant="contained"
                color="default"
                onClick={() => onClose()}
                startIcon={<XCircle />}
              >
                No
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </>
  );
};

export default DischargePatient;
