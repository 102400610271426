import { faColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonV3 from "src/components/ButtonV3";
import LayoutContainer from "src/components/LayoutContainer";

const Test = () => {
	return (
		<LayoutContainer
			icon={
				<FontAwesomeIcon icon={faColumns} color="white" size={"2x"} />
			}
			title="Dashboard"
			subtitle={`Welcome Back, Test 👋`}
		>
			<div className="grid grid-cols-8 gap-6 py-6">
				<div className="col-span-8 font-bold text-lg">Buttons V3</div>
				<ButtonV3 type="primary">primary</ButtonV3>
				<ButtonV3 type="primary-outline">primary-outline</ButtonV3>
				<ButtonV3 type="secondary">secondary</ButtonV3>
				<ButtonV3 type="secondary-outline">secondary-outline</ButtonV3>
				<ButtonV3 type="tertiary">tertiary</ButtonV3>
				<ButtonV3 type="tertiary-outline">tertiary-outline</ButtonV3>
				<ButtonV3 type="success">success</ButtonV3>
				<ButtonV3 type="success-outline">success-outline</ButtonV3>
				<ButtonV3 type="warning">warning</ButtonV3>
				<ButtonV3 type="warning-outline">warning-outline</ButtonV3>
			</div>
		</LayoutContainer>
	);
};

export default Test;
