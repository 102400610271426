import React, { Fragment, useCallback, useEffect, useState } from "react";
import Axios from "axios";
import Notify from "../../notification/Notify";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Badge,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const AccountList = ({ account, accountActive, qrDialog, setQrDialog }) => {
  const classes = useStyles();
  const { users } = React.useContext(UsersData);
  const [accountList, setAccountList] = useState({ data: [], ready: false });

  const getAccountByActive = useCallback(async () => {
    var params = {
      branch: users.management_id,
      user_id: users.user_id,
      account: account,
    };
    await Axios.get("hims/get-all/account-list", { params })
      .then((response) => {
        const data = response.data;
        setAccountList({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users, account]);

  useEffect(() => {
    getAccountByActive();
  }, [account, getAccountByActive]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        {accountList.ready ? (
          accountList.data.length > 0 ? (
            accountList.data.map((data, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <List
                    component="nav"
                    aria-label="main mailbox folders"
                    // onClick={() => history.push('/sph/app/patient/appointment/doctor/' + data.user_id)}
                  >
                    <ListItem
                      button
                      onClick={() => {
                        setQrDialog({
                          ...qrDialog,
                          open: true,
                          data: data,
                        });
                      }}
                    >
                      {accountActive.data.length > 0 ? (
                        accountActive.data.map((data2, index2) => (
                          <ListItemAvatar key={index2}>
                            <Badge
                              classes={{
                                badge:
                                  data.user_id === data2.user_id
                                    ? classes.badgeOnline
                                    : classes.badgeOffline,
                              }}
                              overlap="circle"
                              badgeContent=" "
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              key={index2}
                            >
                              <Avatar
                                src={
                                  data.image === null
                                    ? "/doctorsIcon.png"
                                    : imageLocation + `${account}/` + data.image
                                }
                                className={classes.large}
                              />
                            </Badge>
                          </ListItemAvatar>
                        ))
                      ) : (
                        <Avatar
                          src={
                            data.image === null
                              ? "/doctorsIcon.png"
                              : imageLocation + `${account}/` + data.image
                          }
                          className={classes.large}
                        />
                      )}

                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle1"
                            color="primary"
                            noWrap
                          >
                            {data.name.toUpperCase()}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            noWrap
                          >
                            {data.position}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
              );
            })
          ) : (
            <Box m={2}>
              <Typography color="secondary">No {account} available</Typography>
            </Box>
          )
        ) : (
          <Box m={2}>
            <CircularProgress size={12} />
            <Typography color="primary" variant="caption">
              Processing...
            </Typography>
          </Box>
        )}
      </Grid>
    </Fragment>
  );
};

export default AccountList;

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(3),
  },
  badgeOnline: {
    backgroundColor: "green",
    color: "#fff",
  },
  badgeOffline: {
    backgroundColor: "grey",
    color: "#fff",
  },
}));
