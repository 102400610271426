import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import LocationPicker from "react-leaflet-location-picker";
import PickMapLocation from "./PickMapLocation";
const center = [6.0498006, 125.15];

const IdentificationForm = ({
	municipalities,
	household,
	setMunicipalities,
	brgys,
	setBrgys,
	register,
	errors,
	setValue,
	watch,
	purokList,
	setPurokList,
}) => {
	const [pointVals, setPointVals] = useState([[6.0498006, 125.15]]);
	const [position, setPosition] = useState({
		lat: 6.0498006,
		lng: 125.15,
	});

	const pointMode = {
		banner: false,
		zoom: 10,
		control: {
			values: pointVals,
			onClick: (point) => {
				setPointVals([point]);
				setValue("lat", point[0]);
				setValue("lng", point[1]);
				console.log("I've just been clicked on the map!", point);
			},
			onRemove: (point) => {
				console.log("I've just been clicked for removal :(", point);
			},
		},
	};
	useEffect(() => {
		console.log("householdhouseholdzzz", household?.lat, household?.lng);
		if (household?.lat && household?.lng) {
			// setPointVals([[household?.lat, household?.lng]]);
			setPosition({
				lat: household?.lat,
				lng: household?.lng,
			});
		}
	}, [household?.lat, household?.lng]);
	useEffect(() => {
		let timeout = setTimeout(() => {
			if (watch("barangay")) {
				getPurokList(watch("barangay"));
			}
		}, 400);
		return () => {
			clearTimeout(timeout);
		};
	}, [watch("barangay")]);
	useEffect(() => {
		setValue("purok", household?.purok?.id);
	}, [purokList]);
	const getPurokList = (brgy_id) => {
		axios.get(`v1/purok-by-barangay?barangay_id=${brgy_id}`).then((res) => {
			setPurokList(res.data);
		});
	};
	return (
		<div className="flex flex-col gap-y-8 patient-form">
			{/* <LocationPicker
				pointMode={pointMode}
				zoom={10}
				startPort={"auto"}
				showControls={true}
				precision={7}
			/>
			 */}
			<div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
				<div className="flex flex-col gap-y-6">
					<h4 className="text-lg font-bold ">
						* Record your current location
					</h4>
					<TextField
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`Latitude (x.y °)`}
						variant="outlined"
						error={Boolean(errors?.lat?.message)}
						helperText={errors?.lat?.message}
						{...register("lat", {
							required: "This field is required.",
						})}
					/>
					<TextField
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						label={`Longitude (x.y °)`}
						variant="outlined"
						error={Boolean(errors?.lng?.message)}
						helperText={errors?.lng?.message}
						{...register("lng", {
							required: "This field is required.",
						})}
					/>
				</div>
				<PickMapLocation
					position={position}
					setPosition={(pos) => {
						setPosition({ lat: pos.lat, lng: pos.lng });
						setValue("lat", pos?.lat);
						setValue("lng", pos?.lng);
					}}
				/>
			</div>
			<div className="flex flex-col gap-6">
				<TextField
					className="lg:w-2/3"
					label={"A1. Province"}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("province", {
						required: "This field is required.",
					})}
					error={errors?.province}
					helperText={errors?.province && "This field is required"}
				>
					<option value=""> SELECT </option>
					<option value="sarangani"> Sarangani </option>
				</TextField>
				<TextField
					className="lg:w-2/3"
					label={"A2. City/Municipality:"}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						onChange: (e) => {
							setValue("barangay", "");
							if (e.target.value) {
								setBrgys(
									municipalities.find(
										(x) => x.id == e.target.value
									).barangays
								);
							} else {
								setBrgys([]);
							}
						},
					}}
					{...register("municipality", {
						required: true,
					})}
					error={errors?.municipality}
					helperText={
						errors?.municipality && "This field is required"
					}
				>
					<option value=""> SELECT </option>
					{municipalities.map((item) => (
						<option value={item.id}>{item?.name}</option>
					))}
				</TextField>
				<TextField
					className="lg:w-2/3"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`A3. Zone:`}
					variant="outlined"
					error={errors?.zone}
					helperText={errors?.zone && "This field is required"}
					{...register("zone", {
						required: true,
					})}
				/>
			</div>
			<div className="flex flex-col gap-6">
				<TextField
					className="lg:w-2/3"
					label={"A4. Barangay:"}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("barangay", {
						required: true,
					})}
					InputProps={{
						onChange: (e) => {
							if (e.target.value) {
								getPurokList(e.target.value);
							} else {
							}
						},
					}}
					error={errors?.barangay}
					helperText={errors?.barangay && "This field is required"}
				>
					<option value=""> SELECT </option>
					{brgys.map((item) => {
						return <option value={item?.id}>{item?.name} </option>;
					})}
				</TextField>
				<TextField
					className="lg:w-2/3"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`A5. Purok/Sitio:`}
					variant="outlined"
					{...register("purok", {
						required: true,
					})}
					error={errors?.purok}
					helperText={errors?.purok && "This field is required"}
				>
					<option value=""> SELECT </option>
					{purokList.map((purok) => {
						return (
							<option value={purok?.id}>{purok?.name} </option>
						);
					})}
				</TextField>
			</div>
			<div className="flex flex-col gap-6">
				<TextField
					className="w-full"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`A6. Street:`}
					variant="outlined"
					{...register("street", {
						required: true,
					})}
					error={errors?.street}
					helperText={errors?.street && "This field is required"}
				/>
				<TextField
					className="w-full"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={`7. House Number`}
					variant="outlined"
					{...register("house_number", {
						required: true,
					})}
					error={errors?.house_number}
					helperText={
						errors?.house_number && "This field is required"
					}
				/>
				<TextField
					className="w-full"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={
						<>
							A8. Household Identification Number
							<span className="text-danger ml-2">*</span>
						</>
					}
					variant="outlined"
					{...register("house_id", {
						required: true,
					})}
					error={errors?.house_id}
					helperText={errors?.house_id && "This field is required"}
				/>
			</div>
			<TextField
				className="lg:w-2/3"
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={
					<>
						A9. Name of Respondent:
						<span className="text-danger ml-2">*</span>
					</>
				}
				variant="outlined"
				{...register("respondent", {
					required: true,
				})}
				error={errors?.respondent}
				helperText={errors?.respondent && "This field is required"}
			/>
		</div>
	);
};

export default IdentificationForm;
