import { Box, Grid, makeStyles, Tabs, Tab, Paper } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import React, { useEffect, useState } from "react";
import CisPatientAllergies from "./allergies/CisPatientAllergies";
import CisPatientDiagnosis from "./diagnosis/CisPatientDiagnosis";
import CisPatientMedication from "./medication/CisPatientMedication";
import CisPatientBmi from "./bmi/CisPatientBmi";
import CisPatientDiet from "./diet/CisPatientDiet";
import CisPatientPreLab from "./preLab/CisPatientPreLab";

const useStyle = makeStyles({
  indicatorLeft: {
    left: "0px",
  },
});

export default function CisPatientHealth({ patient }) {
  const classes = useStyle();
  const [category, setCategory] = useState("pre-lab");

  const handleChange = (event, cat) => {
    event.persist();
    setCategory(cat);
  };

  useEffect(() => {}, [patient]);

  return (
    <Grid container spacing={2}>
      <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
        <Grid item xs={12} sm={12} md={9}>
          <TabPanel value={category} index={"pre-lab"}>
            <CisPatientPreLab patient={patient} />
          </TabPanel>
          <TabPanel value={category} index={"bmi"}>
            <CisPatientBmi />
          </TabPanel>
          <TabPanel value={category} index={"allergies"}>
            <CisPatientAllergies patient={patient} />
          </TabPanel>
          <TabPanel value={category} index={"diagnosis"}>
            <CisPatientDiagnosis patient={patient} />
          </TabPanel>
          <TabPanel value={category} index={"medication"}>
            <CisPatientMedication patient={patient} />
          </TabPanel>
          <TabPanel value={category} index={"diet"}>
            <CisPatientDiet patient={patient} />
          </TabPanel>
        </Grid>
      </Box>

      <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
        <Grid item xs={12} sm={12} md={3}>
          <Box component={Paper} elevation={3} mb={2}>
            <Tabs
              value={category}
              onChange={handleChange}
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              orientation={window.innerWidth > 959 ? "vertical" : "horizontal"}
              classes={{
                indicator: classes.indicatorLeft,
              }}
            >
              <Tab wrapped value="pre-lab" label={"Pre Lab"} />
              <Tab wrapped value="bmi" label="BMI calculator" />
              <Tab wrapped value="allergies" label=" Allergies" />
              <Tab wrapped value="diagnosis" label=" Diagnosis" />
              <Tab wrapped value="medication" label=" Medication" />
              <Tab wrapped value="diet" label=" Diet" />
            </Tabs>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}
