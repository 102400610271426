import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import CashierSOAListDetails from "./CashierSOAListDetails";

const CashierSOAList = ({ branches }) => {
  const { users } = useContext(UsersData);
  const [selectedBranch, setSelectedBranch] = useState("no-selected");

  const handleMenuItemClick = (event, data) => {
    setSelectedBranch(data);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box component={Paper} borderRadius={4} p={2}>
            <Box mb={2}>
              <Typography color="textSecondary" variant="h6">
                Branches
              </Typography>
            </Box>

            <List component="div">
              {branches.length > 0
                ? branches.map((data, index) => (
                    <ListItem
                      key={index}
                      button
                      className="mb-2"
                      onClick={(e) => handleMenuItemClick(e, data)}
                      selected={
                        selectedBranch &&
                        selectedBranch.management_id === data.management_id
                      }
                      hidden={users.management_id === data.management_id}
                    >
                      <ListItemText
                        className={`gtc-capitalize`}
                        primary={`${data.name}`}
                        primaryTypographyProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                      />
                    </ListItem>
                  ))
                : Notify.noRecord()}
            </List>
          </Box>
        </Grid>

        <Grid item xs={8}>
          {selectedBranch === "no-selected" && <RenderUnClickMgmt />}
          {selectedBranch !== "no-selected" && (
            <CashierSOAListDetails selectedBranch={selectedBranch} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CashierSOAList;

const RenderUnClickMgmt = () => {
  return (
    <Box
      component={Paper}
      display={"flex"}
      width={"100%"}
      height={"calc(40vh - 200px)"}
      justifyContent="center"
      alignItems={"center"}
    >
      <Typography variant="h5" color="secondary" align="center">
        <b>SELECT ANY BRANCH TO VIEW CREATED SOA</b>
      </Typography>
    </Box>
  );
};
