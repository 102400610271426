import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import {
	faListUl,
	faTruckMoving,
	faUserInjured,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const CisLMIS = () => {
	const history = useHistory();
	const patient_profile_ref = useRef(null);
	const add_patient_ref = useRef(null);
	const [filterType, setFilterType] = useState("all");

	const [view, setView] = useState("table");
	const loading = false;
	// const { data, loading } = useDataTable("v1/patients");
	const data = {
		data: [
			{
				cof_number: "1234",
				date: "08/18/2023",
				consignor: "JOhn Doe",
				address: "0123-B Miguel Paras Ext., Tagb, Bohol",
				term: "30 days",
				hci_name: "Saragani Provincial Health Office",
				hci_number: "1234566789",
			},
			{
				cof_number: "1234",
				date: "08/18/2023",
				consignor: "JOhn Doe",
				address: "0123-B Miguel Paras Ext., Tagb, Bohol",
				term: "30 days",
				hci_name: "Saragani Provincial Health Office",
				hci_number: "1234566789",
			},
			{
				cof_number: "1234",
				date: "08/18/2023",
				consignor: "JOhn Doe",
				address: "0123-B Miguel Paras Ext., Tagb, Bohol",
				term: "30 days",
				hci_name: "Saragani Provincial Health Office",
				hci_number: "1234566789",
			},
		],
	};
	const columns = useMemo(
		() => [
			{
				header: "COF Number",
				accessorKey: "cof_number",
				headerClassName: "justify-center",
				className: "text-left",
				cell: (data) => {
					return data?.cof_number;
				},
			},
			{
				header: "Date",
				accessorKey: "date",
				headerClassName: "justify-center",
				className: "text-left",
				cell: (data) => {
					return data?.date;
				},
			},
			{
				header: "Consignor",
				accessorKey: "consignor",
				headerClassName: "justify-center",
				className: "text-left",
				cell: (data) => {
					return data?.consignor;
				},
			},

			{
				header: "Term",
				accessorKey: "term",
				headerClassName: "justify-center",
				className: "text-left",
				cell: (data) => {
					return data?.term;
				},
			},
			{
				header: "HCI Name",
				accessorKey: "address",
				headerClassName: "justify-center",
				className: "text-left",
				cell: (data) => {
					return data?.hci_name;
				},
			},
			{
				header: "HCI Number",
				accessorKey: "address",
				headerClassName: "justify-center",
				className: "text-left",
				cell: (data) => {
					return data?.hci_number;
				},
			},

			{
				header: "Action",
				accessorKey: "action",
				className: "w-[244px]",
				cell: (data) => {
					return (
						<div className="flex items-center gap-2">
							<ActionBtn
								size="sm"
								type="primary-light"
								onClick={() => {
									console.log("datadata", data);

									history.push(
										`/sph/app/cis/lmis/view/${data?.id}`
									);
									// patient_profile_ref.current.show(data);
								}}
							>
								<FlatIcon
									icon="rr-eye"
									className="text-lg mr-2"
								/>
								View
							</ActionBtn>
						</div>
					);
				},
			},
		],
		[]
	);

	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faTruckMoving}
						color="white"
						size={"2x"}
					/>
				}
				title="Consignements"
				// subtitle="View Kobotoolbox respondents"
			>
				<CardContainer
					containerClassName={` border border- border-opacity-10`}
					header={{
						title: (
							<div className="grid grid-cols-3 gap-6 w-full">
								<ActionBtn
									type={
										filterType == "all"
											? "primary"
											: "foreground-gradient"
									}
									className="!text-lg"
									onClick={() => {
										setFilterType("all");
									}}
								>
									All
								</ActionBtn>
								<ActionBtn
									type={
										filterType == "pending"
											? "primary"
											: "foreground-gradient"
									}
									className="!text-lg"
									onClick={() => {
										setFilterType("pending");
									}}
								>
									Pending
								</ActionBtn>
								<ActionBtn
									type={
										filterType == "approved"
											? "primary"
											: "foreground-gradient"
									}
									className="!text-lg"
									onClick={() => {
										setFilterType("approved");
									}}
								>
									Approved
								</ActionBtn>
							</div>
						),
						children: (
							<div className="ml-auto flex items-center gap-3">
								<ActionBtn
									className="text-lg"
									type="success"
									onClick={() => {
										history.push(
											`/sph/app/pho/lmis/create`
										);
									}}
								>
									<FlatIcon icon="rr-plus" /> Create COF
								</ActionBtn>
								<TextInputField
									inputClassName=" pl-10 py-3 !rounded-2xl "
									className="w-full !rounded-xl lg:w-[320px]"
									icon={
										<FlatIcon
											icon="rr-search"
											className="text-sm"
										/>
									}
									placeholder="Search..."
								/>
							</div>
						),
					}}
					className="!p-0"
				>
					<TableV2
						paginationClassName="px-4"
						columns={columns}
						pagination={true}
						loading={loading}
						data={data?.data}
						onTableChange={(data) => {}}
					/>
				</CardContainer>
			</LayoutContainer>
		</>
	);
};

export default CisLMIS;
