import { forwardRef, useImperativeHandle, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import QRCode from "qrcode.react";
import axios from "axios";
import ViewPatientProfile from "./ViewPatientProfile";
const InfoText = ({ className = "", title, value, ...rest }) => {
	return (
		<div className={`flex flex-col items-start ${className}`} {...rest}>
			<label className="text-placeholder text-sm border-b pr-4 mb-2 border-opacity-50 normal-case">
				{title}
			</label>

			<div className="text-darker text-base font-semibold normal-case">
				{value || (
					<>
						<span className="text-gray-200 font-normal text-xs italic">
							blank
						</span>{" "}
						&nbsp;
					</>
				)}
			</div>
		</div>
	);
};

const CollapseDiv = ({ title, children }) => {
	const [open, setOpen] = useState(false);
	return (
		<div
			className={`flex flex-col border rounded-xl w-full overflow-hidden duration-200 group ${
				open ? "max-h-[9999px]" : "max-h-[60px]"
			}`}
		>
			<div
				className="flex items-center bg-slate-100 cursor-pointer p-3"
				onClick={() => {
					setOpen((open) => !open);
				}}
			>
				<h3 className="mb-0 text-base font-bold text-primary capitalize">
					{title}
				</h3>
				<div
					className={`rounded-full aspect-square duration-200 bg-opacity-60 group-hover:bg-opacity-100 text-secondary ml-auto flex items-center justify-center ${
						open ? "rotate-180" : "rotate-0"
					}`}
				>
					<FlatIcon icon="rr-caret-down" className="text-2xl" />
				</div>
			</div>
			<div
				className={`bg-white p-4 duration-200  ${
					open ? "max-h-[9999px]" : "max-h-[0px]"
				}`}
			>
				{children}
			</div>
		</div>
	);
};

const PatientProfileModal = (props, ref) => {
	const { add_patient_ref } = props;
	const [open, setOpen] = useState(false);
	const [patient, setPatient] = useState(null);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const getPatientInfo = (id) => {
		return axios.get(`v1/patients/${id}`);
	};
	const show = (data) => {
		setPatient(data);
		getPatientInfo(data?.id).then((res) => {
			setPatient(res.data.data);
		});
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const formatDate = (dateToFormat) => {
		if (dateToFormat) {
			let date = new Date(dateToFormat);
			return (
				months[date.getMonth()] +
				" " +
				date.getDate() +
				", " +
				date.getFullYear()
			);
		}
		return "";
	};

	const formatTime = (dateToFormat) => {
		if (dateToFormat) {
			let date = new Date(dateToFormat);
			let h = date.getHours() - 12;
			let min = date.getMinutes();
			return "" + Math.abs(h) + ":" + min + " " + (h > 0 ? "AM" : "PM");
		}
		return "";
	};
	let total = 0;
	return (
		<Modal open={open} hide={hide} size="xl">
			<ModalHeader title={"Patient Profile"} hide={hide} />
			<ModalBody className={`!p-0 !bg-background`}>
				<ViewPatientProfile
					patient={patient}
					className="!bg-transparent"
					add_patient_ref={add_patient_ref}
				/>
			</ModalBody>
		</Modal>
	);
};

export default forwardRef(PatientProfileModal);
