export const styles = {
  label: {
    fontSize: "14px",
    color: "black",
  },
  inputContainer: {
    height: "30px",
    border: "1px solid",
    borderRadius: "4px",
    paddingLeft: "10px",
  },
  divContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "25px",
  },
  parentDivContainer: {
    display: "grid",
    gridTemplateColumns: " auto auto",
    gap: "25px",
  },
  parentDivContainerNoGap: {
    display: "grid",
    gridTemplateColumns: " 50% 50%",
  },
  parentContainerAdd: {
    backgroundColor: "white",
    maxWidth: "1000px",
    marginBottom: "40px",
    borderRadius: "4px",
  },
  divContainerColumn: {
    display: "grid",
    gridTemplateColumns: " auto auto",
    gap: "25px",
  },

  astirisColor: {
    color: "red",
  },
  button: {
    maxWidth: "1000px",
    color: "white",
  },
  parentContainer: {
    backgroundColor: "white",
    maxWidth: "1000px",
    margin: "auto",
    borderRadius: "4px",
  },
  headerContainer: {
    width: "1000px",
    margin: "auto",
    paddingTop: "10px",
    paddingBottom: "20px",
  },
  tableHeader: {
    fontSize: "18px",
  },
  tableHover: "hover:bg-gray-200",
  spaceBetween: {
    display: "grid",
    marginBottom: "10px",
    gridTemplateColumns: " auto auto",
    justifyContent: "start",
  },
  checkBox: {
    fontSize: "20px",
    marginRight: "10px",
    marginLeft: "10px",
  },
  // Tailwind css
  borderRight: "border-r-2 pr-4",
  borderLeft: "border-l-2 pl-4",
  buttonViewTable:
    "flex justify-center items-center text-md p-1 text-white bg-success w-full rounded-sm",
  buttonDeleteTable:
    "flex justify-center items-center text-md p-1 px-2 text-white bg-red-600 w-full rounded-sm",
  buttonEditTable:
    "flex justify-center items-center text-md p-1  px-2 text-white bg-primary w-full rounded-sm",
  buttonTable:
    "flex justify-center items-center text-xl px-3 text-primary w-full ",
  buttonContainer: " border-2 border-primary rounded-sm h-10 ",
  tuiLabel: "text-md pl-3",
  textContainer: "text-lg border-2 rounded-lg  flex items-center h-12 pl-3",
  tuiSpaceBetween: "flex justify-between items-center py-1",
  checkContainer:
    "text-lg border-2 rounded-lg  flex items-center h-12 justify-center px-2",
  cardContainer:
    "flex flex-col justify-center items-center gap-6 p-6 shadow-2xl",
};
