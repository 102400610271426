import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  Box,
  Button,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import LaboratoryOrderOtherTest from "src/utils/LaboratoryOrderOtherTest";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import { CheckCircle, XCircle } from "react-feather";

const AddOnTestCreateOrder = ({ getOtherTest }) => {
  const { users } = React.useContext(UsersData);

  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Formik
      initialValues={{
        username: users.username,
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
        user_id: users.user_id,
        department: "",
        order_name: "",
        order_amount: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        order_name: Yup.string().required(),
        department: Yup.string().required(),
        order_amount: Yup.number().min(1).required(),
        password: Yup.string().required(),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {
          const request = await axios.post(
            "accounting/other/order/new-othertest",
            getFormData(values)
          );
          if (request.data.message === "pass-invalid") {
            setErrors({ password: "Password is invalid." });
          }
          if (request.data.message === "success") {
            Notify.successRequest("new test");
            getOtherTest();
            resetForm();
            setSubmitting(true);
          }
        } catch (error) {
          const message = error.message || "Something went wrong";
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        resetForm,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Card>
            <CardHeader title="Create Order" />
            <CardContent>
              <Box mb={2}>
                <TextField
                  label="Department"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  error={Boolean(touched.department && errors.department)}
                  helperText={touched.department && errors.department}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.department}
                  name="department"
                >
                  <option value={""}>Select</option>
                  {LaboratoryOrderOtherTest.dept().map((data, index) => (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </TextField>
              </Box>

              <Box mb={2}>
                <TextField
                  label="Order"
                  fullWidth
                  variant="outlined"
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(touched.order_name && errors.order_name)}
                  helperText={touched.order_name && errors.order_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.order_name}
                  name="order_name"
                >
                  <option value={""}> Select </option>
                  {LaboratoryOrderOtherTest.list().map((data, index) => (
                    <option value={data.value} key={index}>
                      {data.label}
                    </option>
                  ))}
                </TextField>
              </Box>

              <Box mb={2}>
                <TextField
                  label="Order Amount"
                  fullWidth
                  variant="outlined"
                  error={Boolean(touched.order_amount && errors.order_amount)}
                  helperText={touched.order_amount && errors.order_amount}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.order_amount}
                  name="order_amount"
                />
              </Box>

              <Box>
                <TextField
                  fullWidth
                  required
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>

              {errors.submit && (
                <Box mt={2}>
                  <FormHelperText error> {errors.submit} </FormHelperText>
                </Box>
              )}
            </CardContent>

            <CardContent>
              <Box display="flex">
                <Box mr={2}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size={23} color="inherit" />
                      ) : (
                        <CheckCircle />
                      )
                    }
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>

                <Button
                  size="large"
                  variant="contained"
                  color="default"
                  onClick={resetForm}
                  startIcon={<XCircle />}
                >
                  No
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default AddOnTestCreateOrder;
