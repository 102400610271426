import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import { UsersData } from "src/ContextAPI";
import ActionBtn from "src/components/ActionBtn";

const PrivacyStatementModal = (props, ref) => {
	const {
		newPatient = false,
		patient,
		refresh,
		setPatientSelfie,
		patientSelfie,
		onSuccess,
	} = props;
	const { users } = useContext(UsersData);
	const { register: register1, watch: watch1 } = useForm();
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		trigger,
		control,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [stream, setStream] = useState(null);
	const [imageCaptured, setImageCaptured] = useState(null);
	const videoRef = useRef(null);

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
		stopCamera();
	};

	const startCamera = async () => {
		try {
			const mediaStream = await navigator.mediaDevices.getUserMedia({
				video: true,
			});
			setStream(mediaStream);
			videoRef.current.srcObject = mediaStream;
		} catch (error) {
			console.error("Error accessing camera:", error);
		}
	};

	const stopCamera = () => {
		if (stream) {
			stream.getTracks().forEach((track) => track.stop());
			setStream(null);
		}
	};
	const captureImage = async () => {
		const canvas = document.createElement("canvas");
		canvas.width = videoRef.current.videoWidth;
		canvas.height = videoRef.current.videoHeight;
		canvas
			.getContext("2d")
			.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

		const capturedImage = canvas.toDataURL("image/jpeg"); // Convert canvas to base64 JPEG

		// Now you can send the capturedImage to your server using Axios
		try {
			setImageCaptured(capturedImage);
			stopCamera();
			//   await axios.post('YOUR_UPLOAD_URL', { image: capturedImage });
			// console.log("Image uploaded successfully", capturedImage);
		} catch (error) {
			console.error("Error uploading image:", error);
		}
	};
	return (
		<Modal
			open={open}
			// staticModal={true}
			size="xl"
			hide={hide}
			className=" w-full"
		>
			{/* <ModalHeader
				title={`Add services`}
				// subtitle="Enter patient details"
				hide={hide}
			/> */}
			<ModalBody className={`p-0  bg-white !rounded-t-xl`}>
				<div className="grid grid-cols-1 lg:grid-cols-12 w-full !rounded-t-xl">
					<div className="col-span-3 flex flex-col border-r border-r-blue-500 p-3">
						<p className="text-lg text-blue-600">
							I have read and give my consent to let GTC process
							my health information according to rules set forth
							by the Data Privacy Act.
						</p>
						<label className="flex items-center text-sm gap-3 mb-0 mt-4 text-gray-600">
							<span
								className={
									errors?.agree?.message ? "text-red-500" : ""
								}
							>
								Check if you agree
							</span>

							<input
								type="checkbox"
								className={`${
									errors?.agree?.message
										? "border-red-500"
										: ""
								}`}
								{...register("agree", {
									required: "This field is required",
								})}
							/>
						</label>
						{errors?.agree?.message ? (
							<span className="text-red-500">
								{errors?.agree?.message}
							</span>
						) : (
							""
						)}
						{newPatient ? (
							""
						) : (
							<label className="flex items-center text-sm gap-2 mb-4 mt-2 text-gray-600">
								<span>Check to update profile picture</span>

								<input
									type="checkbox"
									{...register("update_profile", {})}
								/>
							</label>
						)}

						<div className="flex flex-col items-center mt-4 justify-center">
							<div className="flex flex-col mb-2">
								<video
									ref={videoRef}
									className={`border w-[256px] h-[256px] bg-black ${
										imageCaptured && !stream ? "hidden" : ""
									}`}
									autoPlay
									playsInline
									style={{ transform: "scaleX(-1)" }}
								/>
								<img
									src={imageCaptured}
									className={`border w-[256px] h-[256px] bg-black object-contain ${
										imageCaptured == null || stream
											? "hidden"
											: ""
									}`}
									style={{ transform: "scaleX(-1)" }}
								/>
								{stream ? (
									<ActionBtn
										size="sm"
										type="danger"
										className="!rounded-none"
										onClick={captureImage}
									>
										<FlatIcon icon="rr-camera" />
										Capture
									</ActionBtn>
								) : (
									<ActionBtn
										size="sm"
										type="success"
										className="!rounded-none"
										onClick={startCamera}
									>
										<FlatIcon icon="rr-record" />
										Start Camera
									</ActionBtn>
								)}
							</div>
							<p className="text-blue-600 text-lg mb-0 w-full text-center uppercase">
								Selfie of the patient
							</p>
						</div>
						<ActionBtn
							className="mt-3"
							disabled={!imageCaptured || errors?.agree?.message}
							onClick={handleSubmit((data) => {
								setPatientSelfie(imageCaptured);
								onSuccess(data);
								hide();
							})}
						>
							Click to proceed
						</ActionBtn>
					</div>
					<div className="col-span-9 p-3 flex flex-col">
						<h5 className="text-center text-xl">PRIVACY POLICY</h5>
						<p className="text-lg">INTRODUCTION</p>
						<p className="text-lg">
							GLOBAL TELEMEDICINE (GTC) CORP. respects and values
							your privacy and commits to ensure that all
							information especially personal data collected from
							you, our clients and customers, are processed in
							compliance with Republic Act No. 10173 or the Data
							Privacy Act of 2012 (DPA), its Implementing Rules
							and Regulations, and other relevant laws
							regulations, and policies, including issuances of
							the National Privacy Commission This Policy
							describes the Company's data processing practices
							which includes the collection, use, storage,
							disclosure and security of the information provided
							as well as the exercise of your rights over your
							personal data. SCOPE and LIMITATION
						</p>
						<p className="text-lg">
							All Company personnel as well as the Company's
							third-party providers must comply with the terms set
							out in this Policy, observing at all times the
							general principles of transparency, legitimate
							purpose, and proportionality.
						</p>
					</div>
				</div>
			</ModalBody>
			{/* <ModalFooter className={"flex items-center justify-between"}>
				Test
			</ModalFooter> */}
		</Modal>
	);
};

export default forwardRef(PrivacyStatementModal);
