import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import QRCode from "qrcode.react";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import {
	faListUl,
	faTruckMoving,
	faUserInjured,
} from "@fortawesome/free-solid-svg-icons";
import SelectInputField from "src/components/forms/SelectInputField";
import LMISItemOrderList from "./LMISItemOrderList";
import LMISViewItemsList from "./LMISViewItemsList";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { UsersData } from "src/ContextAPI";
import Img from "src/components/Img";
const UserPosition = ({ position }) => {
	switch (position) {
		case "PHO":
			return "Provincial Health Admin";
		case "PHO-HO":
			return "Provincial Health Officer";
		case "LMIS-RHU":
			return "Rural Health Officer";
		case "LMIS-BHS":
			return "Barangay Health Officer";
		case "LMIS-WH":
			return "PHO Warehouse";
		case "LMIS-CNOR":
			return "Consignor";
		default:
			return "System user";
	}
};
const LMISConsignmentView = () => {
	const { users } = useContext(UsersData);
	const history = useHistory();
	const { id } = useParams();
	const [data, setData] = useState(null);
	useEffect(() => {
		if (id) {
			getData();
		}
	}, [id]);
	const getData = () => {
		axios.get(`v1/consignment/show/${id}`).then((res) => {
			setData(res.data.data);
		});
	};

	const checkOrder = () => {
		axios
			.post(`v1/consignment/check/${id}`, {
				_method: "PATCH",
			})
			.then((res) => {
				setTimeout(() => {
					history.goBack();
				}, 200);
				toast.success("Consignment checked successfully!");
			});
	};
	const approve = () => {
		axios
			.post(`v1/consignment/approve/${id}`, {
				_method: "PATCH",
			})
			.then((res) => {
				setTimeout(() => {
					history.goBack();
				}, 200);
				toast.success("Consignment approved successfully!");
			});
	};
	const process = () => {
		axios
			.post(`v1/consignment/process-order/${id}`, {
				_method: "PATCH",
			})
			.then((res) => {
				setTimeout(() => {
					history.goBack();
				}, 200);
				toast.success("Consignment processed successfully!");
			});
	};
	const deliver = () => {
		axios
			.post(`v1/consignment/deliver-order/${id}`, {
				_method: "PATCH",
			})
			.then((res) => {
				setTimeout(() => {
					history.goBack();
				}, 200);
				toast.success("Consignment delivered successfully!");
			});
	};
	const receive = () => {
		axios
			.post(`v1/consignment/receive-order/${id}`, {
				_method: "PATCH",
			})
			.then((res) => {
				setTimeout(() => {
					history.goBack();
				}, 200);
				toast.success("Consignment received successfully!");
			});
	};

	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faTruckMoving}
						color="white"
						size={"2x"}
					/>
				}
				title="View Consignment Order"
				// subtitle="View Kobotoolbox respondents"
			>
				{/* <ActionBtn
					className="ml-auto mb-2"
					onClick={() => {
						history.push(`/sph/app/pho/lmis/print/${data?.id}`);
					}}
				>
					<FlatIcon icon="rr-print" />
					Print
				</ActionBtn> */}
				<CardContainer
					containerClassName={` border border- border-opacity-10`}
					header={{
						title: (
							<>
								Consignment Order Form{" "}
								<span className="ml-5 !text-black">
									Status:{" "}
									<span className="uppercase">
										{data?.status}
									</span>
								</span>{" "}
							</>
						),
					}}
				>
					<div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mb-6 w-full !text-md">
						<div className="lg:col-span-6">
							Date: <b>{data?.date}</b>
						</div>
						<div className="lg:col-span-6">
							COF Number:<b> {data?.cof_number}</b>
						</div>
						<div className="lg:col-span-6">
							Consignor:<b> {data?.consignor}</b>
						</div>
						<div className="lg:col-span-6">
							Term: <b>{data?.term}</b>
						</div>
						<div className="lg:col-span-6">
							Address: <b>{data?.address}</b>
						</div>
						<div className="col-span-6">
							HCI Name: <b>{data?.hci_name}</b>
						</div>
						<div className="col-span-6">
							HCI Number:<b> {data?.hci_number}</b>
						</div>
					</div>
					<div className="flex flex-col gap-y-4">
						{data?.locations?.map((location) => {
							let display = false;
							console.log(
								"users?.type",
								users,
								location.health_unit
							);
							if (
								users?.type?.includes("PHO") ||
								users?.type == "LMIS-CNOR" ||
								users?.type == "LMIS-WH"
							) {
								display = true;
							}
							if (
								users?.type == "LMIS-RHU" &&
								location.health_unit?.type == "RHU" &&
								users?.municipality_id ==
									location?.health_unit?.municipality_id
							) {
								display = true;
							}
							if (
								users?.type == "LMIS-BHS" &&
								location.health_unit?.type == "BHS" &&
								users?.barangay_id ==
									location?.health_unit?.barangay_id
							) {
								display = true;
							}
							if (users?.type == "BHS-BHW") {
								display = true;
							}
							if (users?.type == "RHU-PHARMACY") {
								display = true;
							}
							if (display) {
								return (
									<div
										className="border rounded-lg overflow-hidden"
										key={`location-row-${location?.id}`}
									>
										<div className="p-3 flex w-full items-center gap-4 text-lg">
											<label className="font-semibold mb-0">
												Location:
											</label>
											<b>
												{location?.health_unit?.name ||
													"SPH"}
											</b>
										</div>
										<LMISViewItemsList
											location={location}
										/>
									</div>
								);
							}
						})}
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-12 pt-3 gap-3">
						<div className="lg:col-span-3 flex flex-col">
							<span>Prepared by: </span>
							<div className="flex items-center gap-3 mb-1">
								<div>
									<Img
										className="w-[44px] h-[44px] rounded-full"
										type="user"
										src={data?.scheduledBy?.avatar}
										name={
											data?.scheduledBy?.name?.length > 0
												? data?.scheduledBy?.name
												: data?.scheduledBy?.username
										}
										alt=""
									/>
								</div>
								<QRCode
									value={`user-${data?.scheduledBy?.username}`}
									level="H"
									size={44}
								/>
							</div>
							<b>
								{data?.scheduledBy?.name?.length > 0
									? data?.scheduledBy?.name
									: data?.scheduledBy?.username}
							</b>
							<span className="italic border-t w-1/2 mt-1">
								<UserPosition
									position={data?.scheduledBy?.type}
								/>
							</span>
						</div>
						{data?.checkedBy ? (
							<div className="lg:col-span-3 flex flex-col">
								<span>Checked by: </span>
								<div className="flex items-center gap-3 mb-1">
									<div>
										<Img
											className="w-[44px] h-[44px] rounded-full"
											type="user"
											src={data?.checkedBy?.avatar}
											name={
												data?.checkedBy?.name?.length >
												0
													? data?.checkedBy?.name
													: data?.checkedBy?.username
											}
											alt=""
										/>
									</div>
									<QRCode
										value={`user-${data?.checkedBy?.username}`}
										level="H"
										size={44}
									/>
								</div>
								<b>
									{data?.checkedBy?.name?.length > 0
										? data?.checkedBy?.name
										: data?.checkedBy?.username}
								</b>
								<span className="italic border-t w-1/2 mt-1">
									<UserPosition
										position={data?.checkedBy?.type}
									/>
								</span>
							</div>
						) : (
							""
						)}
						{data?.approvedBy ? (
							<div className="lg:col-span-3 flex flex-col items-start justify-start">
								<span>Approved by: </span>
								<div className="flex items-center gap-3 mb-1">
									<div>
										<Img
											className="w-[44px] h-[44px] rounded-full"
											type="user"
											src={data?.approvedBy?.avatar}
											name={
												data?.approvedBy?.name?.length >
												0
													? data?.approvedBy?.name
													: data?.approvedBy?.username
											}
											alt=""
										/>
									</div>
									<QRCode
										value={`user-${data?.approvedBy?.username}`}
										level="H"
										size={44}
									/>
								</div>
								<b>
									{data?.approvedBy?.name?.length > 0
										? data?.approvedBy?.name
										: data?.approvedBy?.username}
								</b>
								<span className="italic border-t w-1/2 mt-1">
									<UserPosition
										position={data?.approvedBy?.type}
									/>
								</span>
							</div>
						) : (
							""
						)}

						{data?.processedBy ? (
							<div className="lg:col-span-3 flex flex-col">
								<span>Processed by: </span>
								<div className="flex items-center gap-3 mb-1">
									<div>
										<Img
											className="w-[44px] h-[44px] rounded-full"
											type="user"
											src={data?.processedBy?.avatar}
											name={
												data?.processedBy?.name
													?.length > 0
													? data?.processedBy?.name
													: data?.processedBy
															?.username
											}
											alt=""
										/>
									</div>
									<QRCode
										value={`user-${data?.processedBy?.username}`}
										level="H"
										size={44}
									/>
								</div>
								<b>
									{data?.processedBy?.name?.length > 0
										? data?.processedBy?.name
										: data?.processedBy?.username}
								</b>

								<span className="italic border-t w-1/2 mt-1">
									<UserPosition
										position={data?.processedBy?.type}
									/>
								</span>
							</div>
						) : (
							""
						)}
						{data?.deliveredBy ? (
							<div className="lg:col-span-3 flex flex-col">
								<span>Delivered by: </span>

								<div className="flex items-center gap-3 mb-1">
									<div>
										<Img
											className="w-[44px] h-[44px] rounded-full"
											type="user"
											src={data?.deliveredBy?.avatar}
											name={
												data?.deliveredBy?.name
													?.length > 0
													? data?.deliveredBy?.name
													: data?.deliveredBy
															?.username
											}
											alt=""
										/>
									</div>
									<QRCode
										value={`user-${data?.deliveredBy?.username}`}
										level="H"
										size={44}
									/>
								</div>
								<b>
									{data?.deliveredBy?.name?.length > 0
										? data?.deliveredBy?.name
										: data?.deliveredBy?.username}
								</b>

								<span className="italic border-t w-1/2 mt-1">
									<UserPosition
										position={data?.deliveredBy?.type}
									/>
								</span>
							</div>
						) : (
							""
						)}
						{data?.receivedBy ? (
							<div className="lg:col-span-3 flex flex-col">
								<span>Received by: </span>

								<div className="flex items-center gap-3 mb-1">
									<div>
										<Img
											className="w-[44px] h-[44px] rounded-full"
											type="user"
											src={data?.receivedBy?.avatar}
											name={
												data?.receivedBy?.name?.length >
												0
													? data?.receivedBy?.name
													: data?.receivedBy?.username
											}
											alt=""
										/>
									</div>
									<QRCode
										value={`user-${data?.receivedBy?.username}`}
										level="H"
										size={44}
									/>
								</div>
								<b>
									{data?.receivedBy?.name?.length > 0
										? data?.receivedBy?.name
										: data?.receivedBy?.username}
								</b>

								<span className="italic border-t w-1/2 mt-1">
									<UserPosition
										position={data?.receivedBy?.type}
									/>
								</span>
							</div>
						) : (
							""
						)}
					</div>
					<div className="pt-3 mt-3 border-t flex justify-end items-center">
						{data?.status == "pending" &&
						users?.type?.includes("PHO") ? (
							<ActionBtn
								size="md"
								type="primary"
								className="w-full lg:w-1/4 gap-2 !font-bold !text-lg"
								onClick={() => {
									checkOrder();
								}}
							>
								<FlatIcon icon="rr-check-circle" />
								Check order
							</ActionBtn>
						) : (
							""
						)}
						{data?.status == "checked" && users?.type == "PHO" ? (
							<ActionBtn
								size="md"
								type="primary"
								className="w-full lg:w-1/4 gap-2 !font-bold !text-lg"
								onClick={() => {
									approve();
								}}
							>
								<FlatIcon icon="rr-check-circle" />
								Approve order
							</ActionBtn>
						) : (
							""
						)}
						<span className="text-white">
							{users?.type}---{data?.status}
						</span>
						{data?.status == "approved" &&
						users?.type == "LMIS-CNOR" ? (
							<ActionBtn
								size="md"
								type="warning"
								className="w-full lg:w-1/4 gap-2 !font-bold !text-lg"
								onClick={() => {
									process();
								}}
							>
								<FlatIcon icon="rr-check-circle" />
								Process order
							</ActionBtn>
						) : (
							""
						)}
						{data?.status == "processed" &&
						users?.type == "LMIS-WH" ? (
							<ActionBtn
								size="md"
								type="success"
								className="w-full lg:w-1/4 gap-2 !font-bold !text-lg"
								onClick={() => {
									deliver();
								}}
							>
								<FlatIcon icon="rr-check-circle" />
								Deliver order
							</ActionBtn>
						) : (
							""
						)}
						{data?.status == "delivered" &&
						(users?.type == "LMIS-RHU" ||
							users?.type == "SPH-PHAR" ||
							users?.type == "RHU-PHARMACY" ||
							users?.type == "LMIS-BHS" ||
							users?.type == "BHS-BHW") ? (
							<ActionBtn
								size="md"
								type="success"
								className="w-full lg:w-1/4 gap-2 !font-bold !text-lg"
								onClick={() => {
									receive();
								}}
							>
								<FlatIcon icon="rr-check-circle" />
								Receive order
							</ActionBtn>
						) : (
							""
						)}
					</div>
				</CardContainer>
			</LayoutContainer>
		</>
	);
};

export default LMISConsignmentView;
