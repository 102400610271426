import {
	Box,
	Grid,
	Paper,
	Typography,
	TextField,
	Button,
	InputAdornment,
	Tooltip,
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Notify from "src/notification/Notify";
import Label from "src/utils/Label";

import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import { useHistory } from "react-router-dom";
import Reschedule from "./Reschedule";
import DetailsLocalSetAsComplete from "./DetailsLocalSetAsComplete";

import { Calendar, CheckSquare, Edit, User, XCircle } from "react-feather";
import IsMountedRef from "src/utils/IsMountedRef";
import { UsersData } from "src/ContextAPI";

const DetailsLocal = ({ appid }) => {
	const ismounted = IsMountedRef();
	const [details, setDetails] = React.useState({ data: [], ready: false });
	const { users } = React.useContext(UsersData);
	const [newRate, setNewRate] = useState(0);

	const getAppointmentDetails = async () => {
		try {
			var formdata = new FormData();
			formdata.set("app_id", appid);
			formdata.set("user_id", users.user_id);

			const response = await axios.post(
				"doctor/appointment/local/applocal-details",
				formdata
			);
			if (ismounted.current) {
				setDetails({ data: response.data, ready: true });
			}
		} catch (error) {
			Notify.requestError(error);
		}
	};

	useEffect(() => {
		getAppointmentDetails();

		// eslint-disable-next-line
	}, [ismounted]);

	return (
		<>
			<Grid container spacing={2}>
				<Box clone order={{ xs: 2, sm: 2, md: 1 }}>
					<Grid item xs={12} sm={12} md={9} lg={8}>
						<Details
							details={details}
							getAppointmentDetails={getAppointmentDetails}
							newRate={newRate}
							setNewRate={setNewRate}
						/>
					</Grid>
				</Box>

				<Box clone order={{ xs: 1, sm: 1, md: 2 }}>
					<Grid item xs={12} sm={12} md={3} lg={4}>
						<Box align="center">
							{details.data.length > 0 && (
								<>
									<Box mb={2}>
										<AccessAlarmIcon
											style={{ fontSize: window.innerWidth > 500 ? 100 : 60 }}
										/>
									</Box>

									<Box mb={2}>
										{Boolean(parseInt(details.data[0].is_reschedule)) ? (
											<Typography
												variant={window.innerWidth > 500 ? "h4" : "h5"}
											>
												{Notify.dateTimeConvert(
													details.data[0].is_reschedule_date
												)}
											</Typography>
										) : (
											<Typography
												variant={window.innerWidth > 500 ? "h4" : "h5"}
											>
												{Notify.dateTimeConvert(details.data[0].app_date)}
											</Typography>
										)}
									</Box>

									<Box>
										{Boolean(parseInt(details.data[0].is_complete)) && (
											<Label color="success"> APPOINTMENT COMPLETED </Label>
										)}
										{!Boolean(parseInt(details.data[0].is_complete)) &&
											!Boolean(
												parseInt(details.data[0].is_paid_bysecretary)
											) && <Label color="warning"> NEW APPOINTMENT </Label>}
										{!Boolean(parseInt(details.data[0].is_complete)) &&
											Boolean(
												parseInt(details.data[0].is_paid_bysecretary)
											) && (
												<Label color="primary"> APPOINTMENT APPROVED </Label>
											)}
									</Box>
								</>
							)}
						</Box>
					</Grid>
				</Box>
			</Grid>
		</>
	);
};

export default DetailsLocal;

const Details = ({ details, getAppointmentDetails, newRate, setNewRate }) => {
	const history = useHistory();
	const [isreschedule, setIsReschedule] = React.useState(false);
	const [iscomplete, setIsComplete] = React.useState(false);
	const [editRate, setEditRate] = React.useState(false);

	const { users } = useContext(UsersData);

	const handleNewRate = async (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.append("user_id", users.user_id);
		formdata.append(
			"patient_id",
			details.data.length > 0 ? details.data[0].patients_id : ""
		);
		formdata.append("username", users.username);
		formdata.append(
			"trace_number",
			details.data.length > 0 ? details.data[0].appointment_id : ""
		);
		var error = [];

		if (formdata.get("password").trim() === "") {
			error = "error";
			Notify.fieldRequired("password");
		}

		if (parseFloat(formdata.get("new_rate")) < 1) {
			error = "error";
			Notify.fieldInvalid("new rate");
		}

		if (error.length > 0) {
			console.log("form has an error");
		} else {
			let response = await axios.post(
				"doctor/patient/appointment/update-consultation-rate",
				formdata
			);

			if (response.data.message === "pass-invalid") {
				Notify.fieldInvalid("password");
			}

			if (response.data.message === "success") {
				setEditRate(false);
				getAppointmentDetails();
				setNewRate(0);
				Notify.successRequest("rate updated");
			}
		}
	};

	return (
		<>
			<Box component={Paper} p={2} elevation={2}>
				<Box mb={3}>
					<Typography color="textSecondary" variant="h6">
						Appointment Details
					</Typography>
				</Box>

				{details.ready ? (
					details.data.length > 0 ? (
						<>
							<Box className="gtc-textfield-noborder" mb={3}>
								<TextField
									label={"Patient"}
									variant={"outlined"}
									fullWidth
									value={details.data[0].patients_name}
									InputProps={{ readOnly: true }}
								/>
							</Box>

							<Box className="gtc-textfield-noborder" mb={3}>
								<TextField
									label={"Service"}
									variant={"outlined"}
									fullWidth
									value={details.data[0].services}
									InputProps={{ readOnly: true }}
								/>
							</Box>

							<Box className={editRate ? "" : "gtc-textfield-noborder"} mb={3}>
								<TextField
									label={"Rate"}
									variant={"outlined"}
									fullWidth
									value={editRate ? newRate : details.data[0].amount}
									InputProps={{
										readOnly: !editRate,
										endAdornment: (
											<InputAdornment>
												{editRate ? (
													<IconButton
														color="primary"
														onClick={() => setEditRate(false)}
													>
														<XCircle />
													</IconButton>
												) : (
													<IconButton
														color="primary"
														onClick={() => setEditRate(true)}
													>
														<Edit />
													</IconButton>
												)}
											</InputAdornment>
										),
									}}
									onChange={(e) => setNewRate(e.target.value)}
								/>
							</Box>

							<Box className="gtc-textfield-noborder" mb={3}>
								<TextField
									label={"Reason"}
									variant={"outlined"}
									fullWidth
									value={
										details.data[0].app_reason === null
											? "None"
											: details.data[0].app_reason
									}
									InputProps={{ readOnly: true }}
									multiline
								/>
							</Box>

							<Box className="gtc-textfield-noborder" mb={3}>
								<TextField
									label={"Request Appointment Date"}
									variant={"outlined"}
									fullWidth
									value={
										details.data[0].app_date === null
											? "None"
											: Notify.createdAt(details.data[0].app_date)
									}
									InputProps={{ readOnly: true }}
								/>
							</Box>

							<Box className="gtc-textfield-noborder" mb={3}>
								<TextField
									label={"Request Appointment Time"}
									variant={"outlined"}
									fullWidth
									value={
										details.data[0].app_date === null
											? "None"
											: Notify.createdTime(details.data[0].app_date)
									}
									InputProps={{ readOnly: true }}
								/>
							</Box>

							<Box className="gtc-textfield-noborder" mb={3}>
								<TextField
									label={"Reschedule"}
									variant={"outlined"}
									fullWidth
									value={
										Boolean(parseInt(details.data[0].is_reschedule))
											? "Yes"
											: "No"
									}
									InputProps={{ readOnly: true }}
								/>
							</Box>

							{Boolean(parseInt(details.data[0].is_reschedule)) && (
								<>
									<Box className="gtc-textfield-noborder" mb={3}>
										<TextField
											label={"New Appointment Date"}
											variant={"outlined"}
											fullWidth
											value={
												details.data[0].is_reschedule_date === null
													? "None"
													: Notify.createdAt(details.data[0].is_reschedule_date)
											}
											InputProps={{ readOnly: true }}
										/>
									</Box>

									<Box className="gtc-textfield-noborder" mb={3}>
										<TextField
											label={"New Appointment Time"}
											variant={"outlined"}
											fullWidth
											value={
												details.data[0].is_reschedule_date === null
													? "None"
													: Notify.createdTime(
															details.data[0].is_reschedule_date
													  )
											}
											InputProps={{ readOnly: true }}
										/>
									</Box>

									<Box className="gtc-textfield-noborder" mb={3}>
										<TextField
											label={"New Appointment Reason"}
											variant={"outlined"}
											fullWidth
											value={
												details.data[0].is_reschedule_reason === null
													? "None"
													: details.data[0].is_reschedule_reason
											}
											InputProps={{ readOnly: true }}
											multiline
										/>
									</Box>
								</>
							)}

							<Box className="gtc-textfield-noborder" mb={3}>
								<TextField
									label={"Appointment Status"}
									variant={"outlined"}
									fullWidth
									value={
										Boolean(parseInt(details.data[0].is_complete))
											? "Completed"
											: !Boolean(parseInt(details.data[0].is_complete)) &&
											  Boolean(parseInt(details.data[0].is_paid_bysecretary))
											? "Approved"
											: "New"
									}
									InputProps={{ readOnly: true }}
								/>
							</Box>

							{!Boolean(parseInt(details.data[0].is_paid_bysecretary)) && (
								<Box align="center" mb={2}>
									<Typography variant="body2" color="secondary">
										* THIS APPOINTMENT IS NOT YET PAID IN CASHIER *
									</Typography>
								</Box>
							)}

							<Box
								display={window.innerWidth > 1000 ? "flex" : "column"}
								justifyContent="flex-end"
							>
								<Box>
									<Button
										size={"large"}
										fullWidth
										variant="outlined"
										onClick={() =>
											history.push(
												`/sph/app/doctor/patient/details/${details.data[0].appointment_id}/${details.data[0].patients_id}`
											)
										}
										color="primary"
										startIcon={<User />}
									>
										View Profile
									</Button>
								</Box>
								<Box
									my={window.innerWidth > 1000 ? 0 : 1}
									mx={window.innerWidth > 1000 ? 1 : 0}
								>
									<Tooltip
										arrow
										title={
											Boolean(parseInt(details.data[0].is_complete))
												? "Appointment is completed"
												: "Change appointment date"
										}
									>
										<Box>
											<Button
												size={"large"}
												fullWidth
												variant="outlined"
												disabled={Boolean(
													parseInt(details.data[0].is_complete)
												)}
												color="primary"
												onClick={() => {
													setIsReschedule(true);
												}}
												startIcon={<Calendar />}
											>
												Reschedule date
											</Button>
										</Box>
									</Tooltip>
								</Box>
								<Box>
									<Tooltip
										arrow
										title={
											Boolean(parseInt(details.data[0].is_complete))
												? "Appointment is completed"
												: "Set appointment done/completed."
										}
									>
										<Box>
											<Button
												size={"large"}
												fullWidth
												disabled={Boolean(
													parseInt(details.data[0].is_complete)
												)}
												variant="contained"
												color="primary"
												endIcon={<CheckSquare />}
												onClick={() => setIsComplete(true)}
											>
												Set as complete
											</Button>
										</Box>
									</Tooltip>
								</Box>
							</Box>

							<Reschedule
								open={isreschedule}
								closeDialog={() => setIsReschedule(false)}
								appdate={
									Boolean(parseInt(details.data[0].is_reschedule))
										? details.data[0].is_reschedule_date
										: details.data[0].app_date
								}
								appid={details.data[0].appointment_id}
								getAppointmentDetails={getAppointmentDetails}
								connection={"local"}
							/>

							<DetailsLocalSetAsComplete
								open={iscomplete}
								close={() => {
									setIsComplete(false);
									getAppointmentDetails();
								}}
								appid={details.data[0].appointment_id}
							/>
						</>
					) : (
						Notify.noRecord()
					)
				) : (
					Notify.loading()
				)}
			</Box>

			{/* dialog edit rate */}
			<Dialog
				open={editRate}
				onClose={() => setEditRate(false)}
				disableBackdropClick
			>
				<DialogTitle> Edit Consultation Rate </DialogTitle>
				<DialogContent dividers>
					<form onSubmit={handleNewRate}>
						<Box mb={2}>
							<TextField
								label="New Rate"
								value={newRate}
								onChange={(e) => setNewRate(e.target.value)}
								name="new_rate"
								fullWidth
								variant="outlined"
								type="number"
								required
								InputProps={{
									inputProps: {
										min: 1,
										max: 99999,
									},
								}}
							/>
						</Box>

						<Box mb={2}>
							<TextField
								label="Password"
								name="password"
								fullWidth
								variant="outlined"
								type="password"
								required
							/>
						</Box>
						<Box display="flex">
							<Box mr={2}>
								<Button
									variant="contained"
									color="primary"
									type="submit"
									size="large"
								>
									Save
								</Button>
							</Box>
							<Button
								variant="contained"
								size="large"
								color="default"
								onClick={() => setEditRate(false)}
							>
								No
							</Button>
						</Box>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};
