import { Box, Paper, Tabs, Tab, Typography } from "@material-ui/core";
import PersonalInfoHeader from "../patients/PersonalInfoHeader";

import React, { useState } from "react";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LocalHospital from "@material-ui/icons/LocalHospital";
import Whatshot from "@material-ui/icons/Whatshot";
import EventNote from "@material-ui/icons/EventNote";
import HdrWeak from "@material-ui/icons/HdrWeak";
import InvertColors from "@material-ui/icons/InvertColors";
import VerticalSplit from "@material-ui/icons/VerticalSplit";
import EventAvailable from "@material-ui/icons/EventAvailable";
import { CardGiftcard, NaturePeople } from "@material-ui/icons";
import { faChartLine, faUserTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabPanel from "src/utils/TabPanel";
import PatientChartRecords from "../patients/PatientChartRecords";
import { Info } from "react-feather";
import Notes from "../patients/Notes";
import TreatmentPlan from "../patients/treatmentplan/TreatmentPlan";
import Psychology from "../patients/psychology/Psychology";
import Prescription from "../patients/Prescription";
import PersonalInfo from "../patients/PersonalInfo";
import Health from "../patients/Health";
import Vitals from "../patients/Vitals";
import Laboratory from "../patients/laboratory_new/Laboratory";
import Imaging from "../patients/imaging/Imaging";
import SentToDischargeNurse from "./SentToDischargeNurse";

const Details = ({
  patient_id,
  trace_number,
  details,
  getAdmittedPatientList,
  onClose,
}) => {
  const [category, setCategory] = useState("admission");
  const permission = [1];

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <>
      <Box>
        <PersonalInfoHeader patient_id={patient_id} connection={"local"} />
      </Box>

      <Box component={Paper} elevation={1} my={2}>
        <Tabs
          value={category}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab wrapped value="admission" label="Admission" icon={<Info />} />

          <Tab
            wrapped
            value="charts"
            label="Charts"
            icon={
              <FontAwesomeIcon
                icon={faChartLine}
                style={{ fontSize: "18px" }}
              />
            }
          />

          <Tab
            wrapped
            value="profile"
            label="Profile"
            icon={<AccountCircle />}
          />

          <Tab wrapped value="health" label="Health" icon={<LocalHospital />} />

          <Tab wrapped value="vitals" label="Vitals" icon={<Whatshot />} />

          {/* need further edit */}
          <Tab
            wrapped
            value="laboratory"
            label="Laboratory"
            icon={<InvertColors />}
          />

          <Tab
            wrapped
            value="imaging"
            label="Imaging"
            icon={<VerticalSplit />}
          />
          <Tab
            wrapped
            value="psychology"
            label="Psychology"
            icon={<NaturePeople />}
          />
          <Tab
            wrapped
            value="prescription"
            label="Prescription"
            icon={<HdrWeak />}
          />
          <Tab wrapped value="notes" label="Notes" icon={<EventNote />} />

          <Tab
            wrapped
            value="certificates"
            label="Certificates"
            icon={<CardGiftcard />}
          />

          <Tab
            wrapped
            value="treat-plan"
            label="Treatment Plan"
            icon={<EventAvailable />}
          />

          <Tab
            wrapped
            value="discharge"
            label="Sent To Discharge Nurse"
            icon={<FontAwesomeIcon icon={faUserTag} size={"2x"} />}
          />
        </Tabs>
      </Box>

      <Box>
        <TabPanel value={category} index={"admission"}>
          <Box component={Paper} p={2}>
            <Box>
              <Typography variant="caption">
                <b> ROOM: </b>
              </Typography>
              <Typography>{details?.room_name}</Typography>
            </Box>

            <Box my={1}>
              <Typography variant="caption">
                <b> ROOM NUMBER: </b>
              </Typography>
              <Typography>{details?.room_number}</Typography>
            </Box>

            <Box my={1}>
              <Typography variant="caption">
                <b> ROOM BED: </b>
              </Typography>
              <Typography>{details?.room_bed_number}</Typography>
            </Box>

            <Box my={1}>
              <Typography variant="caption">
                <b> NURSE DEPARTMENT: </b>
              </Typography>
              <Typography>{details?.nurse_department}</Typography>
            </Box>

            <Box my={1}>
              <Typography variant="caption">
                <b> REASON: </b>
              </Typography>
              <Typography>{details?.reason}</Typography>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value={category} index={"charts"}>
          <PatientChartRecords patient_id={patient_id} viewtype={"list"} />
        </TabPanel>

        <TabPanel value={category} index={"profile"}>
          <PersonalInfo patient_id={patient_id} connection={"local"} />
        </TabPanel>

        <TabPanel value={category} index={"health"}>
          <Health patient_id={patient_id} connection={"local"} />
        </TabPanel>

        <TabPanel value={category} index={"vitals"}>
          <Vitals patient_id={patient_id} connection={"local"} />
        </TabPanel>

        <TabPanel value={category} index={"laboratory"}>
          <Laboratory
            patient_id={patient_id}
            connection={"local"}
            getPermission={() => console.log("permission granted")}
            getPatientsList={() => getAdmittedPatientList()}
            permission={permission}
            trace_number={trace_number}
          />
        </TabPanel>

        <TabPanel value={category} index={"imaging"}>
          <Imaging
            patient_id={patient_id}
            connection={"local"}
            trace_number={trace_number}
          />
        </TabPanel>

        <TabPanel value={category} index={"psychology"}>
          <Psychology
            patient_id={patient_id}
            connection={"local"}
            getPermission={() => console.log("permission granted")}
            getPatientsList={() => getAdmittedPatientList()}
            permission={permission}
            trace_number={trace_number}
          />
        </TabPanel>

        <TabPanel value={category} index={"prescription"}>
          <Prescription
            trace_number={trace_number}
            patient_id={patient_id}
            connection={"local"}
          />
        </TabPanel>

        <TabPanel value={category} index={"notes"}>
          <Notes patient_id={patient_id} connection={"local"} />
        </TabPanel>

        <TabPanel value={category} index={"treat-plan"}>
          <TreatmentPlan patient_id={patient_id} connection={"local"} />
        </TabPanel>

        <TabPanel value={category} index={"discharge"}>
          <SentToDischargeNurse
            patient_id={patient_id}
            trace_number={trace_number}
            getAdmittedPatient={getAdmittedPatientList}
            onClose={onClose}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default Details;
