import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@material-ui/core";
import { useContext, useEffect, useRef } from "react";
import { UsersData } from "src/ContextAPI";
import ApprovedPatients from "src/cis/components/dashboard/ApprovedPatients";
import ListPatientHasDiseases from "src/cis/components/dashboard/ListPatientHasDiseases";
import Municipality from "src/cis/components/dashboard/Municipality";
import PatientCards from "src/cis/components/dashboard/PatientCards";
import LayoutContainer from "src/components/LayoutContainer";

const CisDashboard = () => {
	const userContext = useContext(UsersData);
	const { users } = userContext;
	const name = users?.name;
	const receive_patient_ref = useRef(null);

	return (
		<LayoutContainer
			icon={<FontAwesomeIcon icon={faHome} color="white" size={"2x"} />}
			title={
				<Typography variant={window.innerWidth > 500 ? "h4" : "h5"}>
					Dashboard
				</Typography>
			}
			subtitle={`Welcome Back, ${name}! 👋`}
			titleChildren={<></>}
			className={`relative`}
		>
			<div>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} lg={12}>
						<PatientCards />
					</Grid>

					<Grid item xs={4} sm={4} md={4}>
						<ListPatientHasDiseases />
					</Grid>
					<Grid item xs={4} sm={4} md={4}>
						<ApprovedPatients />
					</Grid>
					<Grid item xs={4} sm={4} md={4}>
						<Municipality />
					</Grid>
				</Grid>
			</div>
		</LayoutContainer>
	);
};

export default CisDashboard;
