import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TextField,
  IconButton,
  Dialog,
  CircularProgress,
  DialogActions,
  DialogTitle,
  DialogContent,
  TableContainer,
} from "@material-ui/core";
import React, { useContext, useCallback, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import FormHeader from "./FormHeader";
import { CheckSquare, Edit, Printer, XCircle } from "react-feather";
import { useReactToPrint } from "react-to-print";
import Notify from "src/notification/Notify";
import axios from "axios";
import {
  AddCircleOutline,
  Cancel,
  BorderColor,
  Delete,
  Add,
} from "@material-ui/icons";

const FormThirteen = ({
  patient_id,
  details,
  info,
  doctorsList,
  allowEdit,
}) => {
  const printableRef = React.useRef();
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [isProcess, setIsProcess] = React.useState(false);
  const { users } = useContext(UsersData);
  const [isprocessCancel, setIsProcessCancel] = React.useState(false);
  const [existingDoctorsOrderChart, setExistingDoctorsOrderChart] =
    React.useState({
      data: [],
      ready: false,
    });

  const [ccdof_id, setccdof_id] = useState(null);
  const [addToTable, setAddToTable] = useState(false);

  const [existingDoctorsOrderChartTable, setExistingDoctorsOrderChartTable] =
    React.useState({
      data: [],
      ready: false,
    });

  const [cancelOrder, setCancelOrder] = React.useState({
    data: null,
    dialog: false,
  });

  const [editOrder, setEditOrder] = React.useState({
    data: null,
    dialog: false,
  });

  const [_inputNewDoctorsOrder, setInputNewDoctorsOrder] = React.useState({
    data: [],
    ready: true,
  });

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const addField = () => {
    setInputNewDoctorsOrder({
      data: _inputNewDoctorsOrder.data.concat({
        date_time: "date_time[]",
        progress_name: "progress_name[]",
        order_name: "order_name[]",
      }),
      ready: true,
    });
  };

  const removeField = (index, length) => {
    const list = _inputNewDoctorsOrder.data;
    setInputNewDoctorsOrder({
      ..._inputNewDoctorsOrder,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputNewDoctorsOrder({
      data: list,
      ready: true,
    });
  };

  const getDoctorsOrderChart = useCallback(async () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/doctorsorder", { params })
      .then((response) => {
        const data = response.data;
        setExistingDoctorsOrderChart({ data, ready: true });
        setccdof_id(data[0].ccdof_id);
      })
      .catch((error) => console.log(error));
  }, [patient_id, users, details]);

  const getDoctorsOrderChartTable = useCallback(async () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/doctorsordertable", { params })
      .then((response) => {
        const data = response.data;
        setExistingDoctorsOrderChartTable({ data, ready: true });
      })
      .catch((error) => console.log(error));
  }, [patient_id, users, details]);

  //okay
  const handleSubmitDoctorsOrderChart = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", details.trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    // new
    formdata.set("status", ccdof_id === null ? "for-new" : "for-update");
    formdata.set("ccdof_id", ccdof_id);

    setIsProcess(true);
    axios
      .post("or/update/chart/doctorsorder", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          getDoctorsOrderChart();
          getDoctorsOrderChartTable();
          setEnableEdit(!enableEdit);
          Notify.successRequest("");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  const handleRemoveDoctorConsultation = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);

    setIsProcessCancel(true);
    axios
      .post("or/remove/chart/doctorsorder", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setCancelOrder({ data: null, dialog: false });
          getDoctorsOrderChartTable();
          Notify.successRequest("order cancel");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  const handleEditDoctorConsultation = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);

    setIsProcessCancel(true);
    axios
      .post("or/edit/chart/doctorsorder", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setEditOrder({ data: null, dialog: false });
          getDoctorsOrderChartTable();
          Notify.successRequest("order cancel");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  const handleAddDoctorsOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("ccdof_id", ccdof_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);
    formdata.set("trace_number", details.trace_number);

    setIsProcessCancel(true);
    axios
      .post("or/create/chart/doctorsordertable", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setAddToTable(false);
          getDoctorsOrderChartTable();
          Notify.successRequest("doctor order created");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  useEffect(() => {
    getDoctorsOrderChart();
    getDoctorsOrderChartTable();
  }, [patient_id, getDoctorsOrderChart, getDoctorsOrderChartTable]);

  return (
    <Box>
      <Card ref={printableRef}>
        <Box m={2}>
          <Box>
            <FormHeader />
          </Box>
          <Box>
            <form onSubmit={handleSubmitDoctorsOrderChart}>
              <Box my={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          disabled={isProcess}
                          color="primary"
                          startIcon={<CheckSquare />}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            Edit
                          </Button>
                        </Box>
                      )}

                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box align="center" mb={2}>
                <Typography>
                  <b> DOCTOR'S ORDER </b>
                </Typography>
              </Box>

              <Box mb={2}>
                <Table className="table-bordered" size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Box>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Name:
                            </span>
                          </Typography>
                          <Typography>
                            {!enableEdit &&
                              (info
                                ? `${info.lastname}, ${info.firstname} ${
                                    info.middle === null ? "" : info.middle
                                  }`
                                : "")}
                          </Typography>

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="name"
                                defaultValue={
                                  info
                                    ? `${info.lastname}, ${info.firstname} ${
                                        info.middle === null ? "" : info.middle
                                      }`
                                    : ""
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                              />

                              <TextField
                                fullWidth
                                name="ccdof_id"
                                defaultValue={
                                  existingDoctorsOrderChart.data.length > 0
                                    ? existingDoctorsOrderChart.data[0].ccdof_id
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                hidden
                              />
                            </>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Age:
                            </span>{" "}
                          </Typography>

                          {!enableEdit && (
                            <Typography>
                              {!enableEdit &&
                                (info
                                  ? `${
                                      info.birthday === null
                                        ? ""
                                        : Notify.calculateAge(info.birthday)
                                    }`
                                  : "")}
                            </Typography>
                          )}

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="age"
                                defaultValue={
                                  info
                                    ? `${
                                        info.birthday === null
                                          ? ""
                                          : Notify.calculateAge(info.birthday)
                                      }`
                                    : ""
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Sex:
                            </span>{" "}
                            {!enableEdit
                              ? existingDoctorsOrderChart.data.length > 0
                                ? existingDoctorsOrderChart.data[0].sex
                                : ""
                              : null}
                          </Typography>

                          <Typography>
                            {!enableEdit && (info ? `${info.gender}` : "")}
                          </Typography>

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="sex"
                                defaultValue={info ? `${info.gender}` : ""}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Box>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Room/Bed:
                            </span>{" "}
                          </Typography>
                          <Typography>
                            {!enableEdit
                              ? existingDoctorsOrderChart.data.length > 0
                                ? existingDoctorsOrderChart.data[0].room_bed
                                : ""
                              : null}
                          </Typography>

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="room_bed"
                                defaultValue={
                                  existingDoctorsOrderChart.data.length > 0
                                    ? existingDoctorsOrderChart.data[0].room_bed
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Box>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Case No:
                            </span>{" "}
                          </Typography>
                          <Typography>
                            {!enableEdit
                              ? existingDoctorsOrderChart.data.length > 0
                                ? existingDoctorsOrderChart.data[0].case_no_form
                                : ""
                              : null}
                          </Typography>
                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="case_no_form"
                                defaultValue={
                                  existingDoctorsOrderChart.data.length > 0
                                    ? existingDoctorsOrderChart.data[0]
                                        .case_no_form
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box mt={2} />
              <Box display={"flex"} hidden={!enableEdit}>
                <Box flexGrow={1} />
                <Box mx={1}>
                  <Button
                    color="primary"
                    onClick={addField}
                    startIcon={<AddCircleOutline />}
                  >
                    ADD
                  </Button>
                </Box>
                <Box>
                  <Button
                    color="secondary"
                    onClick={
                      _inputNewDoctorsOrder.data.length > 0
                        ? () =>
                            removeField(1, _inputNewDoctorsOrder.data.length)
                        : (e) => e.preventDefault()
                    }
                    startIcon={<Cancel />}
                  >
                    REMOVE
                  </Button>
                </Box>
              </Box>
              <Box
                display={"flex"}
                className="d-print-none"
                hidden={enableEdit}
              >
                <Box flexGrow={1} />
                <Box>
                  <IconButton
                    color={"primary"}
                    onClick={() => setAddToTable(true)}
                  >
                    <Add fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
              <TableContainer>
                <Table className="table-bordered" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        width={120}
                        className="width120tcell"
                      >
                        <strong>DATE/TIME</strong>
                      </TableCell>
                      <TableCell
                        align="center"
                        width={160}
                        className="width160tcell"
                      >
                        <strong>PROGRESS NOTES</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>ORDERS</strong>
                      </TableCell>
                      <TableCell
                        align="center"
                        className="d-print-none"
                        hidden={enableEdit}
                        width={100}
                      >
                        <strong>ACTION</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {enableEdit &&
                      _inputNewDoctorsOrder.ready &&
                      _inputNewDoctorsOrder.data.map((data, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <TextField
                                name={data.date_time}
                                type="datetime-local"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                fullWidth
                                required
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                name={data.progress_name}
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                name={data.order_name}
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}

                    {!enableEdit &&
                      existingDoctorsOrderChartTable.ready &&
                      existingDoctorsOrderChartTable.data.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {existingDoctorsOrderChartTable.data.length > 0
                                ? `${Notify.createdAt(
                                    data.date_time
                                  )} ${Notify.createdTime(data.date_time)}`
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                whiteSpace: "pre-line",
                                // inlineSize: "150px",
                                overflowWrap: "break-word",
                              }}
                            >
                              {data.progress_name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                whiteSpace: "pre-line",
                                // inlineSize: "150px",
                                overflowWrap: "break-word",
                              }}
                            >
                              {data.order_name}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" className="d-print-none">
                            <Box display={"flex"} justifyContent={"center"}>
                              <Box>
                                <IconButton
                                  color={"primary"}
                                  onClick={() =>
                                    setEditOrder({
                                      data: data,
                                      dialog: true,
                                    })
                                  }
                                >
                                  <BorderColor fontSize={"small"} />
                                </IconButton>
                              </Box>
                              <Box>
                                <IconButton
                                  color={"secondary"}
                                  onClick={() =>
                                    setCancelOrder({ data: data, dialog: true })
                                  }
                                >
                                  <Delete fontSize={"small"} />
                                </IconButton>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </form>
          </Box>
        </Box>
      </Card>

      <Dialog
        open={cancelOrder.dialog}
        onClose={() => setCancelOrder({ data: null, dialog: false })}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
      >
        {cancelOrder.data && cancelOrder.dialog && (
          <form onSubmit={handleRemoveDoctorConsultation}>
            <DialogTitle>Are you sure to remove this data?</DialogTitle>
            <TextField
              label={"Order ID"}
              variant="outlined"
              name="ccdoft_id"
              defaultValue={cancelOrder.data.ccdoft_id}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              hidden
            />
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setCancelOrder({ data: null, dialog: false })}
              >
                No
              </Button>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isprocessCancel}
                startIcon={
                  isprocessCancel && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                Yes
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      <Dialog
        open={editOrder.dialog}
        onClose={() => setEditOrder({ data: null, dialog: false })}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
      >
        {editOrder.data && editOrder.dialog && (
          <form onSubmit={handleEditDoctorConsultation}>
            <DialogTitle>Edit note</DialogTitle>
            <DialogContent>
              <Box mb={2}>
                <TextField
                  label={"Order ID"}
                  variant="outlined"
                  name="ccdoft_id"
                  defaultValue={editOrder.data.ccdoft_id}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  hidden
                />

                <TextField
                  label={"Date/Time"}
                  name="date_time"
                  defaultValue={
                    editOrder.data.date_time !== null
                      ? editOrder.data.date_time.replace(" ", "T")
                      : ""
                  }
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label={"Progress Note"}
                  variant="outlined"
                  name="progress_name"
                  defaultValue={
                    editOrder.data.progress_name !== null
                      ? editOrder.data.progress_name
                      : ""
                  }
                  fullWidth
                  multiline
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label={"Orders"}
                  variant="outlined"
                  name="order_name"
                  defaultValue={
                    editOrder.data.order_name !== null
                      ? editOrder.data.order_name
                      : ""
                  }
                  fullWidth
                  multiline
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setEditOrder({ data: null, dialog: false })}
              >
                No
              </Button>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isprocessCancel}
                startIcon={
                  isprocessCancel && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                Yes
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      <Dialog
        open={addToTable}
        onClose={() => setAddToTable(false)}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
      >
        <form onSubmit={handleAddDoctorsOrder}>
          <DialogTitle>Add consultation note</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <TextField
                label={"New Date/Time"}
                name="date_time"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                variant="outlined"
              />
            </Box>
            <Box mb={2}>
              <TextField
                label={"New Progress Note"}
                variant="outlined"
                name="progress_name"
                fullWidth
                multiline
              />
            </Box>

            <Box mb={2}>
              <TextField
                label={"New Orders"}
                variant="outlined"
                name="order_name"
                fullWidth
                multiline
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setAddToTable(false)}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isprocessCancel}
              startIcon={
                isprocessCancel && (
                  <CircularProgress size={20} color="inherit" />
                )
              }
            >
              Yes
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default FormThirteen;
