import {
  Box,
  Typography,
  Divider,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Grid,
} from "@material-ui/core";
import React, { useState, useContext } from "react";
import { UsersHeader, UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import Notify from "src/notification/Notify";
import axios from "axios";
import moment from "moment";

const LeaveForm = () => {
  const { users } = useContext(UsersData);
  const [value, setValue] = useState("");
  const { sidebarHeader, renderPharmacyInfo } = useContext(UsersHeader);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmitLeave = (e) => {
    e.preventDefault();
    var formdata = new FormData(e.target);

    var a = moment(formdata.get("date_from"));
    var b = moment(formdata.get("date_to"));
    var no_days = b.diff(a, "days");

    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("management_id", users.management_id);
    formdata.append("no_days", no_days);

    var error = [];
    if (
      formdata.get("purpose").length === 0 ||
      formdata.get("purpose").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("purpose of leave");
    }
    if (value === null) {
      error = "error";
      Notify.fieldRequired("leave type");
    }

    if (value === "sickleave") {
      if (no_days > sidebarHeader.SLCredit) {
        error = "error";
        Notify.fieldRequired("Not enought sick leave credit");
      }
    }

    if (value === "vacationleave") {
      if (no_days > sidebarHeader.VLCredit) {
        error = "error";
        Notify.fieldRequired("Not enought vacation leave credit");
      }
    }

    if (
      formdata.get("date_from").length === 0 ||
      formdata.get("date_from").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("date from");
    }

    if (
      formdata.get("date_to").length === 0 ||
      formdata.get("date_to").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("date to");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/save/leave-application", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("leave");
            e.target.reset();
            setValue(null);
            renderPharmacyInfo();
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Leave Form",
        items: [{ name: "Dashboard", path: "/sph/app" }],
      }}
      title={"Leave Form"}
    >
      <Box>
        <Box m={2} width={"50%"} marginLeft={"25%"}>
          <form onSubmit={handleSubmitLeave}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={1}
            >
              <Box>
                <Typography variant="h3" style={{ fontFamily: "-moz-initial" }}>
                  Global Telemedicine Corp.
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={2}
              mt={2}
            >
              <Box>
                <Typography variant="h4" style={{ fontFamily: "-moz-initial" }}>
                  LEAVE FORM
                </Typography>
              </Box>
            </Box>

            {sidebarHeader.name !== null && sidebarHeader.name !== undefined && (
              <Box mb={2}>
                <TextField
                  name="employee_name"
                  autoComplete="off"
                  fullWidth
                  label="Employee's Name"
                  value={sidebarHeader.name}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Box>
            )}

            <Box mb={2}>
              <Box mb={2}>
                <TextField
                  required
                  name="purpose"
                  autoComplete="off"
                  fullWidth
                  label="Purpose of Leave"
                  variant="outlined"
                />
              </Box>

              <Box mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    {sidebarHeader.SLCredit !== null &&
                      sidebarHeader.SLCredit !== undefined && (
                        <Box>
                          <TextField
                            name="current_sick_leave_credit"
                            autoComplete="off"
                            fullWidth
                            label="Sick Leave Credit"
                            value={sidebarHeader.SLCredit}
                            inputProps={{ readOnly: true }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                          />
                        </Box>
                      )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      {sidebarHeader.VLCredit !== null &&
                        sidebarHeader.VLCredit !== undefined && (
                          <Box>
                            <TextField
                              name="current_vacation_leave_credit"
                              autoComplete="off"
                              fullWidth
                              label="Vacation Leave Credit"
                              value={sidebarHeader.VLCredit}
                              inputProps={{ readOnly: true }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                            />
                          </Box>
                        )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box border={1} mb={2} borderColor="#AEAEAE" borderRadius={4}>
                <RadioGroup
                  aria-label="leave_type"
                  name="leave_type"
                  value={value}
                  onChange={handleRadioChange}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormControlLabel
                      value="sickleave"
                      control={<Radio />}
                      label="Sick Leave"
                      disabled={sidebarHeader.SLCredit === 0}
                    />

                    <FormControlLabel
                      value="vacantionleave"
                      control={<Radio />}
                      label="Vacation Leave"
                      disabled={sidebarHeader.VLCredit === 0}
                    />

                    <FormControlLabel
                      value="absent"
                      control={<Radio />}
                      label="Absent"
                    />
                  </Box>
                </RadioGroup>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box mb={2}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      variant="outlined"
                      label="Date From"
                      name="date_from"
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mb={2}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      variant="outlined"
                      label="Date To"
                      name="date_to"
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box mb={3}>
                <TextField
                  type="password"
                  required
                  name="password"
                  label="Password"
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                />
              </Box>

              <Box my={2} display="flex">
                <Box flexGrow={1} />

                <Button
                  variant="contained"
                  color="default"
                  startIcon={<ClearIcon />}
                  type="reset"
                >
                  Cancel
                </Button>

                <Box ml={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon />}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default LeaveForm;
