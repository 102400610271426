import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  TextField,
  Divider,
} from "@material-ui/core";
import FormHeader from "./FormHeader";
import { useReactToPrint } from "react-to-print";
import { XCircle, Printer, Edit, CheckSquare } from "react-feather";
import Notify from "src/notification/Notify";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import ReactQuill from "react-quill";
import { decode } from "html-entities";

const FormDischargeIns = ({
  patient_id,
  trace_number,
  info,
  doctorsList,
  allowEdit,
}) => {
  const printableRef = React.useRef();
  const [enableEdit, setEnableEdit] = useState(false);
  const { users } = React.useContext(UsersData);

  const [isProcess, setIsProcess] = useState(false);

  const [chartRd, setChartRd] = useState(false);
  const [chartType, setChartType] = useState("for-save");

  // chats state

  const [chartPCase, setChartPCase] = useState("");
  const [chartDateOfConsult, setChartDateOfConsult] = useState(null);
  const [chartPhysician, setChartPhysician] = useState("");

  const [chartDiagnosis, setChartDiagnosis] = useState("");
  const [chartMeds, setChartMeds] = useState("");
  const [chartMedsIns, setChartMedsIns] = useState("");

  const [chartFollowUp, setChartFollowUp] = useState("");
  const [chartReceivedBy, setChartReceivedBy] = useState("");
  const [chartReceivedOn, setChartReceivedOn] = useState(null);

  const mounted = IsMountedRef();

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  // const checkSelectedDoctor = (selDoctor) => {
  //   if (doctorsList.length > 0) {
  //     let xx = doctorsList.filter((x) => x.doctors_id === selDoctor);
  //     if (xx.length > 0) {
  //       return xx[0].name;
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  const handleUpdateDefaultState = (data) => {
    setChartPCase(data.case_no === null ? "" : data.case_no);
    setChartDateOfConsult(
      data.date_ofconsult === null ? "" : data.date_ofconsult
    );
    setChartPhysician(
      data.attending_physician === null ? "" : data.attending_physician
    );
    setChartDiagnosis(data.diagnosis === null ? "" : data.diagnosis);
    setChartMeds(data.medicines === null ? "" : decode(data.medicines));
    setChartMedsIns(
      data.instructions === null ? "" : decode(data.instructions)
    );
    setChartFollowUp(
      data.follow_up_checkup === null ? "" : data.follow_up_checkup
    );
    setChartReceivedBy(data.received_by === null ? "" : data.received_by);
    setChartReceivedOn(data.received_on === null ? "" : data.received_on);
  };

  const handleNewDischargeIns = (e) => {
    e.preventDefault();
    e.persist();

    let err = [];
    var form = new FormData(e.target);
    form.set("user_id", users.user_id);
    form.set("management_id", users.management_id);
    form.set("main_mgmt_id", users.main_mgmt_id);
    form.set("patient_id", patient_id);
    form.set("trace_number", trace_number);
    form.set("chart_type", chartType);
    form.set("case_no", chartPCase);
    form.set("attending_physician", chartPhysician);
    form.set(
      "date_ofconsult",
      moment(chartDateOfConsult).format("YYYY-MM-DD HH:mm:ss")
    );
    form.set("diagnosis", chartDiagnosis);
    form.set("medicines", chartMeds);
    form.set("instructions", chartMedsIns);
    form.set("follow_up_checkup", chartFollowUp);
    form.set("received_by", chartReceivedBy);
    form.set(
      "received_on",
      moment(chartReceivedOn).format("YYYY-MM-DD HH:mm:ss")
    );

    if (
      form.get("attending_physician").trim() === "" ||
      form.get("attending_physician").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("attending physician");
    }

    if (
      form.get("date_ofconsult").trim() === "" ||
      form.get("date_ofconsult").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("date of consult");
    }

    if (
      form.get("received_by").trim() === "" ||
      form.get("received_by").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("received_by");
    }

    if (
      form.get("received_on").trim() === "" ||
      form.get("received_on").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("received_on");
    }

    if (err.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcess(true);
      axios
        .post("or/charts/patient/patient-dischargeins", form)
        .then((response) => {
          let data = response.data;

          if (data.message === "success") {
            setEnableEdit(false);
            getDischargeIns();
            Notify.successRequest("Chart updated");
          }
        })
        .catch((err) => console.log(err.message))
        .finally(() => setIsProcess(false));
    }
  };

  const getDischargeIns = React.useCallback(() => {
    axios
      .get("or/charts/patient/patient-getdischargeins", {
        params: {
          patient_id,
          trace_number,
        },
      })
      .then((response) => {
        let data = response.data;
        if (mounted.current) {
          if (data.length) {
            let data = response.data[0];
            setChartType("for-update");
            handleUpdateDefaultState(data);
          }
        }
      })
      .catch((err) => console.log("error: ", err.message))
      .finally(() => setChartRd(true));
  }, [patient_id, trace_number, mounted]);

  useEffect(() => {
    getDischargeIns();
  }, [getDischargeIns]);

  return (
    <Card ref={printableRef}>
      <CardContent>
        <Box>
          <FormHeader />
        </Box>

        <Box my={3} align="center">
          <Typography variant="subtitle2">
            {/* <b> INFORMED CONSENT TO MEDICAL AND SURGICAL PROCEDURE </b> */}
            <b> DISCHARGE INSTRUCTIONS SHEET </b>
          </Typography>
        </Box>

        {chartRd ? (
          <form onSubmit={handleNewDischargeIns}>
            <Box my={3}>
              <Box
                display="flex"
                justifyContent="flex-end"
                className="d-print-none"
                mb={1}
              >
                {enableEdit ? (
                  <>
                    <Box>
                      <Button
                        color="secondary"
                        onClick={() => setEnableEdit(false)}
                        startIcon={<XCircle />}
                      >
                        Close
                      </Button>
                    </Box>

                    <Box ml={2}>
                      <Button
                        color="primary"
                        startIcon={
                          isProcess ? (
                            <CircularProgress size={22} color="inherit" />
                          ) : (
                            <CheckSquare />
                          )
                        }
                        type="submit"
                        disabled={isProcess}
                      >
                        Save
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    {allowEdit && (
                      <Box mr={2}>
                        <Button
                          color="primary"
                          onClick={() => setEnableEdit(true)}
                          startIcon={<Edit />}
                        >
                          Edit
                        </Button>
                      </Box>
                    )}

                    <Box>
                      <Button
                        color="primary"
                        onClick={handlePrint}
                        startIcon={<Printer />}
                      >
                        Print
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            <Box my={3}>
              <Grid container>
                <Grid item xs={6}>
                  <Box my={1} display={"flex"}>
                    <Typography>PCase No.</Typography>
                    {enableEdit ? (
                      <>
                        <TextField
                          fullWidth
                          value={chartPCase}
                          readOnly={!enableEdit}
                          onChange={(e) => setChartPCase(e.target.value)}
                          multiline
                        />
                      </>
                    ) : (
                      <Typography className="text-capitalize ml-1">
                        {chartPCase}
                      </Typography>
                    )}
                  </Box>

                  <Box my={1}>
                    <Typography>
                      Patient Name:
                      {info
                        ? ` ${info.lastname}, ${info.firstname} ${
                            info.middle === null ? "" : info.middle
                          }`
                        : ""}
                    </Typography>
                  </Box>

                  <Box display={"flex"}>
                    <Typography> Attending Physician: </Typography>
                    {enableEdit ? (
                      <>
                        <TextField
                          fullWidth
                          value={chartPhysician}
                          readOnly={!enableEdit}
                          onChange={(e) => setChartPhysician(e.target.value)}
                          multiline
                        />

                        {/* <TextField
                        fullWidth
                        name=""
                        value={chartPhysician}
                        InputProps={{
                          disableUnderline: !enableEdit,
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        readOnly={!enableEdit}
                        onChange={(e) => setChartPhysician(e.target.value)}
                      >
                        <option value=""> Select </option>
                        {doctorsList.length > 0 ? (
                          doctorsList.map((data, key) => (
                            <option key={key} value={data.doctors_id}>
                              {data.name}
                            </option>
                          ))
                        ) : (
                          <option value=""> No doctor found. </option>
                        )}
                      </TextField> */}
                      </>
                    ) : (
                      <Typography className="text-capitalize ml-1">
                        {/* {checkSelectedDoctor(chartPhysician)} */}
                        {chartPhysician}
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box my={1} display={"flex"}>
                    <Typography> Date of Consult: </Typography>

                    <Box mx={1}>
                      {enableEdit ? (
                        <DatePicker
                          format="dd/MM/yyyy"
                          label=""
                          views={["year", "month", "date"]}
                          value={chartDateOfConsult}
                          onChange={(date) => setChartDateOfConsult(date)}
                          autoOk
                        />
                      ) : (
                        <Typography>
                          {chartDateOfConsult === null
                            ? ""
                            : Notify.createdAt(
                                moment(chartDateOfConsult).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              )}
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  <Box display={"flex"} my={1}>
                    <Box flexGrow={1}>
                      <Typography>
                        Gender:
                        {info ? ` ${info.gender}` : ""}
                      </Typography>
                    </Box>

                    <Box flexGrow={1}>
                      <Typography>
                        Age:
                        {info
                          ? ` ${
                              info.birthday === null
                                ? ""
                                : Notify.calculateAge(info.birthday)
                            }`
                          : ""}
                      </Typography>
                    </Box>
                  </Box>

                  <Box my={1}>
                    <Typography>
                      Civil Status : {info ? `${info.civil_status}` : ""}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Box>
                <Typography>
                  <b>Diagnosis:</b>
                </Typography>
                {enableEdit ? (
                  <Box>
                    <TextField
                      fullWidth
                      value={chartDiagnosis}
                      onChange={(e) => setChartDiagnosis(e.target.value)}
                      multiline
                    />
                  </Box>
                ) : (
                  <Box mt={1}>
                    <Typography> {chartDiagnosis} </Typography>
                  </Box>
                )}
              </Box>

              <Box my={2}>
                <Divider />
              </Box>

              <Box>
                <Typography>
                  <b>Discharge Instructions/Medications:</b>
                </Typography>
              </Box>

              <Box my={2}>
                <Typography>
                  <b> Medicines: </b>
                </Typography>
                {enableEdit ? (
                  <Box>
                    <ReactQuill
                      name="xmeds"
                      id="xmeds"
                      value={chartMeds}
                      onChange={(value) => setChartMeds(value)}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: chartMeds ? decode(chartMeds) : null,
                      }}
                    />
                  </Box>
                )}
              </Box>

              <Box my={2}>
                <Typography>
                  <b> Instructions: </b>
                </Typography>
                {enableEdit ? (
                  <Box>
                    <ReactQuill
                      name="xmedsins"
                      id="xmedsins"
                      value={chartMedsIns}
                      onChange={(value) => setChartMedsIns(value)}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: chartMedsIns ? decode(chartMedsIns) : null,
                      }}
                    />
                  </Box>
                )}
              </Box>

              <Box my={2}>
                <Divider />
              </Box>

              <Box mb={2} mt={Boolean(chartPhysician) ? 0 : 2}>
                <Typography> Follow-up/ Check-up with: </Typography>
                {enableEdit ? (
                  <Box>
                    <TextField
                      fullWidth
                      value={chartFollowUp}
                      onChange={(e) => setChartFollowUp(e.target.value)}
                      multiline
                    />
                  </Box>
                ) : (
                  <Box>
                    <Typography> {chartFollowUp} </Typography>
                  </Box>
                )}
              </Box>

              <Box my={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box display={"flex"}>
                      <Box mr={1}>
                        <Typography> Received By: </Typography>
                      </Box>
                      {enableEdit ? (
                        <Box>
                          <TextField
                            fullWidth
                            value={chartReceivedBy}
                            onChange={(e) => setChartReceivedBy(e.target.value)}
                            multiline
                          />
                        </Box>
                      ) : (
                        <Box>
                          <Typography> {chartReceivedBy} </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box display={"flex"}>
                      <Box mr={1}>
                        <Typography> Received On: </Typography>{" "}
                      </Box>
                      {enableEdit ? (
                        <Box>
                          <DatePicker
                            format="dd/MM/yyyy"
                            label=""
                            views={["year", "month", "date"]}
                            value={chartReceivedOn}
                            onChange={(date) => setChartReceivedOn(date)}
                            autoOk
                          />
                        </Box>
                      ) : (
                        <Box>
                          <Typography>
                            {" "}
                            {chartReceivedOn === null
                              ? ""
                              : Notify.createdAt(
                                  moment(chartReceivedOn).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box mt={Boolean(chartPhysician) ? 2 : 5}>
                <Grid container>
                  <Grid item xs={7}>
                    <Box align="center" borderBottom={1}>
                      <Typography className="text-capitalize">
                        {/* {checkSelectedDoctor(chartPhysician)} */}
                        {chartPhysician}
                      </Typography>
                    </Box>

                    <Typography>
                      Signature Over Printed Name of Physician
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        ) : (
          Notify.loading()
        )}
      </CardContent>
    </Card>
  );
};

export default FormDischargeIns;
