import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import { main_sources, toilets } from "src/helpers/responseChoices";
const WaterAndSanitation = ({
	Controller,
	register,
	errors,
	setValue,
	control,
	watch,
}) => {
	const sources = [
		{ value: 1, label: `Own use faucet, community water system` },
		{ value: 2, label: `Shared faucet, community water system` },
		{ value: 3, label: `Own use tubed/piped deep well` },
		{ value: 4, label: `Shared tubed/piped deep well` },
		{ value: 5, label: `Tubed/piped deep well` },
		{ value: 6, label: `Dug well` },
		{ value: 7, label: `Protected spring` },
		{ value: 8, label: `Unprotected spring` },
		{ value: 9, label: `Lake, river, rain and others` },
		{ value: 10, label: `Peddler` },
		{ value: 11, label: `Bottled Water` },
		{ value: 12, label: `Others, specify` },
	];
	const h_sources = [
		{ value: 1, label: `Own use faucet, community water system` },
		{ value: 2, label: `Shared faucet, community water system` },
		{ value: 3, label: `Own use tubed/piped deep well` },
		{ value: 4, label: `Shared tubed/piped deep well` },
		{ value: 5, label: `Tubed/piped deep well` },
		{ value: 6, label: `Dug well` },
		{ value: 7, label: `Protected spring` },
		{ value: 8, label: `Unprotected spring` },
		{ value: 9, label: `Lake, river, rain and others` },
		{ value: 10, label: ` Peddler` },
		{ value: 11, label: ` Bottled Water` },
		{
			value: 12,
			label: ` Others, specify`,
		},
	];
	const types = [
		{
			value: 1,
			label: `Water-sealed, sewer septic tank, used exclusively by household`,
		},
		{
			value: 2,
			label: `Water-sealed, sewer septic tank, shared with other household`,
		},
		{
			value: 3,
			label: `Water-sealed, other depository, used exclusively by household`,
		},
		{
			value: 4,
			label: `Water-sealed, other depository, shared with other household`,
		},
		{ value: 5, label: `Closed pit` },
		{ value: 6, label: `Open pit` },
		{ value: 7, label: `Others (pail system and others)` },
		{ value: 8, label: `None` },
	];
	return (
		<div className="flex flex-col gap-y-8">
			<FormControl>
				<FormGroup>
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.main_source}
					>
						K1. What is your household's main source of water for
						general use?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.main_source && (
						<FormHelperText error={errors?.main_source}>
							This field is required.
						</FormHelperText>
					)}

					<Controller
						rules={{ required: true }}
						name="main_source"
						control={control}
						render={({ field }) => (
							<RadioGroup
								{...field}
								key={`main_source-${field?.value}`}
							>
								{main_sources.map((data, index) => {
									return (
										<FormControlLabel
											key={`main_source1-${data.value}`}
											className="!mb-0"
											value={data.value}
											control={<Radio />}
											label={`${data.label}`}
										/>
									);
								})}
							</RadioGroup>
						)}
					/>
				</FormGroup>
			</FormControl>
			<FormControl>
				<FormGroup>
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.drink_water}
					>
						K3. What is your household's main source of drinking
						water?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.drink_water && (
						<FormHelperText error={errors?.drink_water}>
							This field is required.
						</FormHelperText>
					)}
					<Controller
						rules={{ required: true }}
						name="drink_water"
						control={control}
						render={({ field }) => (
							<RadioGroup
								{...field}
								key={`drink_water-${field?.value}`}
							>
								{main_sources.map((data, index) => {
									return (
										<FormControlLabel
											key={`drink_water-${data.value}`}
											className="!mb-0"
											value={data.value}
											control={<Radio />}
											label={`${data.label}`}
										/>
									);
								})}
							</RadioGroup>
						)}
					/>
				</FormGroup>
			</FormControl>
			<TextField
				className=" lg:w-1/2"
				type="number"
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={
					<>
						K5. How far is this water source from your house? (In
						meters)
					</>
				}
				variant="outlined"
				{...register("far_house")}
				error={errors?.far_house}
				helperText={errors?.far_house && "This field is required"}
			/>
			<FormControl>
				<FormGroup>
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.hh_toilet}
					>
						K6. What type of toilet facility does the household
						have?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.hh_toilet && (
						<FormHelperText error={errors?.hh_toilet}>
							This field is required.
						</FormHelperText>
					)}
					<Controller
						rules={{ required: true }}
						name="hh_toilet"
						control={control}
						render={({ field }) => (
							<RadioGroup
								{...field}
								key={`hh_toilet-${field?.value}`}
							>
								{toilets.map((data, index) => {
									return (
										<FormControlLabel
											key={`hh_toilet-${data.value}`}
											className="!mb-0"
											value={data.value}
											control={<Radio />}
											label={`${data.label}`}
										/>
									);
								})}
							</RadioGroup>
						)}
					/>
				</FormGroup>
			</FormControl>
		</div>
	);
};

export default WaterAndSanitation;
