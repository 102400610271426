import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState } from "react";
import { CheckSquare } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const SentToDischargeNurse = ({
  patient_id,
  trace_number,
  getAdmittedPatient,
  onClose,
}) => {
  const [remarks, setRemarks] = useState("");
  const { users } = useContext(UsersData);
  const [isProcess, setIsProcess] = useState(false);

  const sentPatientToDischarge = () => {
    let formdata = new FormData();
    formdata.append(`discharge_by`, users.user_id);
    formdata.append(`management_id`, users.management_id);
    formdata.append(`patient_id`, patient_id);
    formdata.append(`trace_number`, trace_number);
    formdata.append(`remarks`, remarks);

    setIsProcess(true);
    axios
      .post(`admitting/nurse/patient/sent-todischarge`, formdata)
      .then(({ data }) => {
        if (data.message === "success") {
          Notify.successRequest("patient discharge");
          getAdmittedPatient();
          onClose();
        }
      })
      .catch((err) => console.log(`error:`, err.message))
      .finally(() => setIsProcess(false));
  };

  return (
    <div>
      <Card>
        <CardHeader title="Sent to discharge" />
        <CardContent>
          <Box mb={3}>
            <TextField
              placeholder="discharge remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              multiline
              rows={7}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box display={"flex"}>
            <Box flexGrow={1} />
            <Box>
              <Button
                color="primary"
                variant="contained"
                disabled={isProcess}
                startIcon={
                  isProcess ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckSquare />
                  )
                }
                onClick={() => sentPatientToDischarge()}
              >
                Sent to discharge
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default SentToDischargeNurse;
