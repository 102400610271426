import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faBox,
  faHome,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import StockRoom from "src/stockroom";
import Account from "src/stockroom/account/Account";
import StockRoomProducts from "src/stockroom/products";
import LeaveForm from "src/stockroom/leave/LeaveForm";

const RouteStockRoom = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("stockroom/sidebar/header-infomartion", {
        params,
      });

      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faHome} style={{ fontSize: 25 }} color="white" />
      ),
      path: "/sph/app/stockroom",
      subitem: [],
    },
    {
      name: "Products",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faBox} style={{ fontSize: 25 }} color="white" />
      ),
      path: "/sph/app/stockroom/products",
      subitem: [],
    },
    {
      name: "Leave Application",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faAddressCard}
          style={{ fontSize: 25 }}
          color="white"
        />
      ),
      path: "/sph/app/stockroom/leave-application",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faUserLock}
          style={{ fontSize: 25 }}
          color="white"
        />
      ),
      path: "/sph/app/stockroom/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
        }}
      >
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              owner: "Stockroom",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"Stockroom"}
          />
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/sph/app/stockroom" />}
            />

            <Route
              exact
              path="/bmcdc"
              component={() => <Redirect to="/sph/app/stockroom" />}
            />

            <Route
              exact
              path="/sph/app"
              component={() => <Redirect to="/sph/app/stockroom" />}
            />

            <Route exact path="/sph/app/stockroom" component={StockRoom} />

            <Route
              exact
              path="/sph/app/stockroom/products"
              component={StockRoomProducts}
            />

            <Route
              exact
              path="/sph/app/stockroom/leave-application"
              component={LeaveForm}
            />

            <Route exact path="/sph/app/logout" component={Logout} />

            <Route
              exact
              path="/sph/app/stockroom/account"
              component={Account}
            />

            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteStockRoom;
