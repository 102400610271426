import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardHeader, Typography, useTheme } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const GrandCardCollection = () => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [grandCollection, setGrandCollection] = useState(0);
  const theme = useTheme();

  const getAllCollection = React.useCallback(async () => {
    let response = await axios.get("accounting/grand/collection-totalamount", {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      if (response.data.length > 0) {
        calculateTotal(response.data);
      }
    }
  }, [mounted, users]);

  const calculateTotal = (data) => {
    let x = 0;

    data.forEach((element) => {
      x += parseFloat(element._total_collection);
    });

    setGrandCollection(x);
  };

  useEffect(() => {
    getAllCollection();
  }, [getAllCollection]);

  return (
    <Card elevation={5}>
      <CardHeader
        avatar={
          <FontAwesomeIcon
            icon={faFolderPlus}
            size={"5x"}
            style={{ color: `${theme.palette.primary.main}` }}
          />
        }
        title={
          <Typography variant="h4" color="textSecondary" noWrap>
            Collections
          </Typography>
        }
        // subheader={
        //   <Typography variant="h5">
        //     <strong> &#8369; {Notify.convertToNumber(grandCollection)} </strong>
        //   </Typography>
        // }
      />

      <Box mb={2}>
        <Typography variant="h4" align="center">
          <strong> &#8369; {Notify.convertToNumber(grandCollection)} </strong>
        </Typography>
      </Box>
    </Card>
  );
};

export default GrandCardCollection;
