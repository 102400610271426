import {
	Box,
	Dialog,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	CardMedia,
	CircularProgress,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "../../notification/Notify";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import HemathologyRef from "../references/Ref_Hemathology";
import DraggableDialog from "../../utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import { Fragment } from "react";
import Specimen from "./Specimen";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const OrderDetails = ({
	queue,
	formheader,
	order,
	getLabHemaOrder,
	resetDisplay,
	process_for,
}) => {
	const { users } = React.useContext(UsersData);
	const history = useHistory();
	const [savebtnDisabled, setSavebtnDisabled] = useState(true);
	const [orderDetails, setOrderDetails] = useState({
		data: null,
		ready: false,
	});

	const [pendingDialog, setPendingDialog] = useState(false);
	const [processDialog, setProcessDialog] = useState({
		data: null,
		dialog: false,
	});

	const [pendingSubmitting, setPendingSubmitting] = useState(false);
	const [processSubmitting, setProcessSubmitting] = useState(false);
	const [resultSubmitting, setResultSubmitting] = useState(false);

	const [orderItems, setOrderItems] = useState([]);

	const getLabOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order.trace_number };
		Axios.get("laboratory/order/ordernew-hemathology/details", { params })
			.then((response) => {
				const data = response.data;
				setOrderDetails({
					data: data,
					ready: true,
				});

				checkIfSaveBtnIsEnabled(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const checkIfSaveBtnIsEnabled = (data) => {
		for (let i = 0; i < data.length; i++) {
			if (Boolean(data[i].is_processed)) {
				setSavebtnDisabled(false);
			}
		}
	};

	useEffect(() => {
		getLabOrderDetails();

		// eslint-disable-next-line
	}, [order]);

	const handleSaveResult = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("trace_number", order.trace_number);
		formdata.set("queue", queue);
		formdata.set("process_for", process_for);

		var error = [];
		setResultSubmitting(true);
		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			Axios.post(
				"laboratory/order/ordernew-hemathology/save-process-result",
				formdata
			)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						Notify.successRequest("order result added. Redirect to receipt.");
						setTimeout(() => {
							history.push(
								`/sph/app/laboratory/record/print/order/${order.trace_number}`
							);
						}, 5000);

						setSavebtnDisabled(true);
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => setResultSubmitting(false));
		}
	};

	const handlePendingOrder = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", order.order_id);

		var error = [];

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setPendingSubmitting(true);
			Axios.post(
				"laboratory/order/ordernew-hemathology/save-setpending",
				formdata
			)
				.then((response) => {
					const data = response.data;
					if (data === "pass-invalid") {
						Notify.fieldInvalid("password");
					}
					if (data === "success") {
						getLabHemaOrder();
						resetDisplay();
						setPendingDialog(false);
						Notify.successRequest("order pending");
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => setPendingSubmitting(false));
		}
	};

	const handleProcessOrder = async (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", processDialog.data.order_id);
		formdata.set("trace_number", processDialog.data.trace_number);

		var error = [];
		setProcessSubmitting(true);

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			let response = await Axios.post(
				"laboratory/hemathology/order-setprocess/custom-qty",
				formdata
			);

			if (response.data.message === "reagent-error") {
				Notify.customToast("Reagent Not Error", "Reagent/items not available.");
			}

			if (response.data.message === "pass-invalid") {
				Notify.fieldInvalid("password");
			}

			if (response.data.message === "success") {
				Notify.successRequest("process");
				setProcessDialog({ data: null, dialog: false });
				getLabOrderDetails();
			}
		}

		setTimeout(() => {
			setProcessSubmitting(false);
		}, 2000);
	};

	const handleProcessSelectedOrder = async (data) => {
		let response = await Axios.get(
			"laboratory/items/laborder/list-available-items",
			{
				params: {
					user_id: users.user_id,
					management_id: users.management_id,
					order_id: data.order_id,
				},
			}
		);

		setOrderItems(response.data);

		setProcessDialog({ data: data, dialog: true });
	};

	return (
		<>
			<form onSubmit={handleSaveResult}>
				<Card elevation={0}>
					<Box display="flex" justifyContent="center">
						<Box mt={2}>
							{formheader && (
								<CardMedia
									style={{ width: 70 }}
									component={"img"}
									src={`${imageLocation}laboratory/logo/${formheader.logo}`}
								/>
							)}
						</Box>
						<Box>
							<CardHeader
								component={Box}
								align="center"
								title={formheader && formheader.name}
								subheader={formheader && formheader.address}
							/>
						</Box>
					</Box>

					<CardContent>
						{/* paitent information */}
						<Box display="flex">
							<Box flexGrow={1} mb={2}>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											NAME:
										</Typography>
										{`${order.lastname}, ${order.firstname} ${
											order.middle === null ? "" : order.middle
										}`}
									</Typography>
								</Box>

								<Box display="flex" mb={2}>
									<Box>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												AGE:
											</Typography>
											{order.birthday === null
												? "none"
												: Notify.calculateAge(order.birthday)}
										</Typography>
									</Box>
									<Box ml={5}>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												SEX:
											</Typography>
											{order.gender === null ? "none" : order.gender}
										</Typography>
									</Box>
								</Box>

								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											ADDRESS:
										</Typography>
										{`${order.street} ${order.barangay} ${order.city} ${order.zip} `}
									</Typography>
								</Box>

								<Box>
									<Typography variant="caption" className="font-weight-bold">
										PATIENT CONDITION:
									</Typography>
									{order.patient_condition === null
										? " none"
										: order.patient_condition}
								</Box>
							</Box>

							<Box>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											DATE:
										</Typography>
										{Notify.createdAt(new Date().toLocaleString())}
									</Typography>
								</Box>
							</Box>
						</Box>
					</CardContent>

					<CardContent>
						<Specimen
							patient_id={order.patient_id}
							trace_number={order.trace_number}
						/>
					</CardContent>

					<CardContent>
						{/* order details */}
						<Box>
							<Typography variant="h6" align="center">
								<b> HEMATOLOGY </b>
							</Typography>
						</Box>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell />
										<TableCell align="center">
											<b> RESULT </b>
										</TableCell>
										<TableCell align="center">
											<b> ACTION </b>
										</TableCell>
										<TableCell align="center">
											<b> REFERENCE VALUES </b>
										</TableCell>
										<TableCell align="center">
											<b> REMARKS </b>
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{orderDetails.ready ? (
										orderDetails.data.length > 0 ? (
											orderDetails.data.map((data, index) => (
												<Fragment key={index}>
													<TableRow hidden={data.hemoglobin === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																hemoglobin
															</Typography>
														</TableCell>
														<TableCell align="center">
															{/* hidden inputs */}
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="order_id[]"
																	label="order_id"
																	hidden
																	value={data.order_id}
																/>
																<TextField
																	margin="dense"
																	fullWidth
																	name="patient_id"
																	label="Result"
																	hidden
																	value={data.patient_id}
																/>

																<TextField
																	margin="dense"
																	fullWidth
																	name="doctors_id"
																	label="doctor_id"
																	hidden
																	value={
																		data.doctor_id === null
																			? ""
																			: data.doctor_id
																	}
																/>
															</Box>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="hemoglobin[]"
																	label="Result"
																	type="number"
																	required={
																		data.hemoglobin === null ? false : true
																	}
																	InputProps={{
																		inputProps: {
																			min: 1,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box> {HemathologyRef.hemoglobin()} </Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="hemoglobin_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.hematocrit === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																hematocrit
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="hematocrit[]"
																	required={
																		data.hematocrit === null ? false : true
																	}
																	label="Result"
																	type="number"
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* refenrece not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="hematocrit_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.rbc === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																RBC
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="rbc[]"
																	label="Result"
																	required={data.rbc === null ? false : true}
																	type="number"
																	InputProps={{
																		inputProps: {
																			min: 1,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{HemathologyRef.rbc()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="rbc_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.wbc === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																wbc
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="wbc[]"
																	label="Result"
																	required={data.wbc === null ? false : true}
																	type="number"
																	hidden={data.wbc === null}
																	InputProps={{
																		inputProps: {
																			min: 1,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{HemathologyRef.wbc()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="wbc_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.platelet_count === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																Platelet count
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="platelet_count[]"
																	required={
																		data.platelet_count === null ? false : true
																	}
																	label="Result"
																	type="number"
																	hidden={data.platelet_count === null}
																	InputProps={{
																		inputProps: {
																			min: 1,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{HemathologyRef.plateletCount()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="platelet_count_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.differential_count === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																differential count
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="differential_count[]"
																	hidden={data.differential_count === null}
																	required={
																		data.differential_count === null
																			? false
																			: true
																	}
																	label="Result"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>

														{/* no refenreces  */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="differential_count_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.neutrophil === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																neutrophil
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="neutrophil[]"
																	label="Result"
																	type="number"
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{HemathologyRef.neutrophil()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="neutrophil_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.lymphocyte === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																lymphocyte
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="lymphocyte[]"
																	label="Result"
																	type="number"
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{HemathologyRef.lymphocyte()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="lymphocyte_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.monocyte === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																monocyte
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="monocyte[]"
																	label="Result"
																	type="number"
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{HemathologyRef.monocyte()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="monocyte_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.eosinophil === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																eosinophil
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="eosinophil[]"
																	label="Result"
																	type="number"
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{HemathologyRef.eosinophil()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="eosinophil_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.basophil === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																basophil
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="basophil[]"
																	label="Result"
																	type="number"
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{HemathologyRef.basophil()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="basophil_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.bands === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																bands
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="bands[]"
																	label="Result"
																	type="number"
																	InputProps={{
																		inputProps: {
																			min: 0.01,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{HemathologyRef.bands()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="bands_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow
														hidden={data.abo_blood_type_and_rh_type === null}
													>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																ABO blood type / rh type
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="abo_blood_type_and_rh_type[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center"></TableCell>
														{/* no refernece values */}
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="abo_blood_type_and_rh_type_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.bleeding_time === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																bleeding time
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="bleeding_time[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{HemathologyRef.bleedingTime()} </Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="bleeding_time_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.clotting_time === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																clotting time
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="clotting_time[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell align="center">
															<Box>{HemathologyRef.clottingTime()}</Box>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="clotting_time_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.mcv === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																MCV
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="mcv[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="mcv_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.mch === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																mch
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="mch[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="mch_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.mchc === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																mchc
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="mchc[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="mchc_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.rdw === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																rdw
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="rdw[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="rdw_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.mpv === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																mpv
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="mpv[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="mpv_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.pdw === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																pdw
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="pdw[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="pdw_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.pct === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																pct
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="pct[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="pct_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.pct === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																pct
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="pct[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="pct_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.blood_typing_with_rh === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																Blood Typing / RH
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="blood_typing_with_rh[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="blood_typing_with_rh_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.ct_bt === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																CT/BT
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="ct_bt[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="ct_bt_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.esr === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																ESR
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="esr[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="esr_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.ferritin === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																Ferritin
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="ferritin[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="ferritin_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.aptt === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																APTT
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="aptt[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="aptt_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.peripheral_smear === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																Peripheral Smear
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="peripheral_smear[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="peripheral_smear_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.protime === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																Protime
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="protime[]"
																	label="Result"
																	multiline
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell align="center">
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														{/* reference not set */}
														<TableCell align="center"></TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="protime_remarks[]"
																	label="Remarks"
																	InputProps={{
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
													</TableRow>
												</Fragment>
											))
										) : (
											<TableRow>
												<TableCell align="center" colSpan={4}>
													<Typography color="secondary" variant="subtitle2">
														No record found.
													</Typography>
												</TableCell>
											</TableRow>
										)
									) : (
										<TableRow>
											<TableCell align="center" colSpan={4}>
												<Typography color="primary" variant="subtitle2">
													please wait...
												</Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
					<Box display="flex" m={1}>
						<Box flexGrow={1} />
						<Box>
							<CardActions>
								{/* <Tooltip
                  title={`${order.is_pending_reason}`}
                  open={Boolean(parseInt(order.is_pending))}
                  arrow
                >
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={() => setPendingDialog(true)}
                    disabled={Boolean(parseInt(order.is_pending))}
                  >
                    Set as Pending
                  </Button>
                </Tooltip>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={Boolean(parseInt(order.is_processed))}
                  onClick={() => {
                    setProcessDialog({ data: null, ready: false });
                  }}
                >
                  Set As PRocess
                </Button> */}

								<Button
									variant="contained"
									size="large"
									color="primary"
									type="submit"
									disabled={savebtnDisabled}
									startIcon={
										resultSubmitting && (
											<CircularProgress size={20} color="inherit" />
										)
									}
								>
									Save Result
								</Button>
							</CardActions>
						</Box>
					</Box>
				</Card>
			</form>

			{/* set as pending dialog */}
			<Dialog
				open={pendingDialog}
				onClose={() => setPendingDialog(false)}
				disableBackdropClick
				PaperComponent={DraggableDialog}
			>
				<DialogTitle id="draggable-handle">Set as pending</DialogTitle>
				<form onSubmit={handlePendingOrder}>
					<DialogContent dividers>
						<Box mb={2}>
							<TextField
								rows={5}
								fullWidth
								name="reason"
								label={`Pending Reason`}
								variant="outlined"
								multiline
							/>
						</Box>

						<Box>
							<TextField
								fullWidth
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setPendingDialog(false)}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="secondary"
							type="submit"
							disabled={pendingSubmitting}
							startIcon={
								pendingSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<ErrorOutlineIcon />
								)
							}
						>
							pending
						</Button>
					</DialogActions>
				</form>
			</Dialog>

			{/* set as process dialog */}
			<Dialog
				open={processDialog.dialog}
				onClose={() => setProcessDialog({ data: null, dialog: false })}
				disableBackdropClick
				PaperComponent={DraggableDialog}
				maxWidth={"xs"}
				fullWidth
			>
				<DialogTitle id="draggable-handle">Process order</DialogTitle>
				<form onSubmit={handleProcessOrder}>
					<DialogContent dividers>
						{orderItems.length > 0
							? orderItems.map((data, index) => (
									<Box key={index} mb={2}>
										<Box>
											<TextField
												fullWidth
												name="item_id[]"
												value={data.item_id}
												variant="outlined"
												hidden
											/>
										</Box>

										<Box>
											<TextField
												fullWidth
												required
												label={`${data.description} qty to process`}
												name="qty[]"
												variant="outlined"
												defaultValue={1}
												InputLabelProps={{
													shrink: true,
												}}
												// InputProps={{
												//   inputProps: {
												//     min: 0,
												//     max: data._total_qty_available,
												//   },
												// }}
												type="number"
											/>
										</Box>
									</Box>
							  ))
							: "No reagent/item found."}

						<Box mt={2}>
							<TextField
								fullWidth
								required
								defaultValue="ok"
								name="remarks"
								label={`Remarks`}
								variant="outlined"
							/>
						</Box>

						<Box mt={2}>
							<TextField
								fullWidth
								required
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setProcessDialog({ data: null, dialog: false })}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={processSubmitting}
							startIcon={
								processSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<CheckCircleIcon />
								)
							}
						>
							process
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default OrderDetails;
