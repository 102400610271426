import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
const HousingForm = ({
	household,
	Controller,
	register,
	errors,
	setValue,
	control,
	watch,
	municipalities,
	setMunicipalities,
	brgys,
	setBrgys,
	purokList,
	setPurokList,
}) => {
	return (
		<div className="flex flex-col gap-y-8 patient-form">
			<FormControl className="w-full">
				<FormLabel
					id="demo-controlled-radio-buttons-group"
					error={Boolean(errors?.building_type)}
				>
					B1. In what type of building does the household reside?
					<span className="text-danger ml-2">*</span>
				</FormLabel>
				{errors?.building_type && (
					<FormHelperText error={Boolean(errors?.building_type)}>
						This field is required.
					</FormHelperText>
				)}
				<Controller
					rules={{ required: true }}
					name="building_type"
					control={control}
					render={({ field }) => (
						<RadioGroup
							{...field}
							key={`building_type-${field?.value}`}
						>
							<FormControlLabel
								className="!mb-0"
								value="singlehouse"
								control={<Radio />}
								label="1 Single House"
							/>
							<FormControlLabel
								className="!mb-0"
								value="Married"
								control={<Radio />}
								label="2 Duplex"
							/>
							<FormControlLabel
								className="!mb-0"
								value="multiunitresidential"
								control={<Radio />}
								label="3 Multi-unit residential (three units or more)"
							/>
							<FormControlLabel
								className="!mb-0"
								value="commercialetc"
								control={<Radio />}
								label="4 Commercial/Industrial/Agricultural building (office, factory and others)"
							/>
							<FormControlLabel
								className="!mb-0"
								value="otherhousingunit"
								control={<Radio />}
								label="5 Other housing unit (boat, cave and others)"
							/>
						</RadioGroup>
					)}
				/>
			</FormControl>
			<FormControl className="w-full">
				<FormLabel
					id="demo-controlled-radio-buttons-group"
					error={Boolean(errors?.roof_materials)}
				>
					B2. What type of construction materials are the roof made
					of?
					<span className="text-danger ml-2">*</span>
				</FormLabel>
				{errors?.roof_materials && (
					<FormHelperText error={Boolean(errors?.roof_materials)}>
						This field is required.
					</FormHelperText>
				)}
				<Controller
					rules={{ required: true }}
					name="roof_materials"
					control={control}
					render={({ field }) => (
						<RadioGroup
							{...field}
							key={`roof_materials-${field?.value}`}
						>
							<FormControlLabel
								className="!mb-0"
								value="strongmaterials"
								control={<Radio />}
								label="1 Strong materials (galvanized iron, aluminum, tile, concrete, brick, stone, asbestos)"
							/>
							<FormControlLabel
								className="!mb-0"
								value="lightmaterials"
								control={<Radio />}
								label="2 Light Materials (cogon, nipa, anahaw)"
							/>
							<FormControlLabel
								className="!mb-0"
								value="salvaged"
								control={<Radio />}
								label="3 Salvaged/makeshift materials"
							/>
							<FormControlLabel
								className="!mb-0"
								value="mixedstrong"
								control={<Radio />}
								label="4 Mixed but predominantly strong materials"
							/>
							<FormControlLabel
								className="!mb-0"
								value="mixedlight"
								control={<Radio />}
								label="5 Mixed but predominantly light materials"
							/>
							<FormControlLabel
								className="!mb-0"
								value="mixedsalvaged"
								control={<Radio />}
								label="6 Mixed but predominantly salvaged materials"
							/>
							<FormControlLabel
								className="!mb-0"
								value="notapplicable"
								control={<Radio />}
								label="7 Not applicable"
							/>
						</RadioGroup>
					)}
				/>
			</FormControl>
			<FormControl className="w-full">
				<FormLabel
					id="demo-controlled-radio-buttons-group"
					error={errors?.wall_materials}
				>
					B3. What type of construction materials are the other walls
					made of?
					<span className="text-danger ml-2">*</span>
				</FormLabel>
				{errors?.wall_materials && (
					<FormHelperText error={Boolean(errors?.wall_materials)}>
						This field is required.
					</FormHelperText>
				)}
				<Controller
					rules={{ required: true }}
					name="wall_materials"
					control={control}
					render={({ field }) => (
						<RadioGroup
							{...field}
							key={`wall_materials-${field?.value}`}
						>
							<FormControlLabel
								className="!mb-0"
								value="strongmaterials"
								control={<Radio />}
								label="1 Strong materials (galvanized iron, aluminum, tile, concrete, brick, stone, asbestos)"
							/>
							<FormControlLabel
								className="!mb-0"
								value="lightmaterials"
								control={<Radio />}
								label="2 Light Materials (cogon, nipa, anahaw)"
							/>
							<FormControlLabel
								className="!mb-0"
								value="salvaged"
								control={<Radio />}
								label="3 Salvaged/makeshift materials"
							/>
							<FormControlLabel
								className="!mb-0"
								value="mixedstrong"
								control={<Radio />}
								label="4 Mixed but predominantly strong materials"
							/>
							<FormControlLabel
								className="!mb-0"
								value="mixedlight"
								control={<Radio />}
								label="5 Mixed but predominantly light materials"
							/>
							<FormControlLabel
								className="!mb-0"
								value="mixedsalvaged"
								control={<Radio />}
								label="6 Mixed but predominantly salvaged materials"
							/>
							<FormControlLabel
								className="!mb-0"
								value="notapplicable"
								control={<Radio />}
								label="7 Not applicable"
							/>
						</RadioGroup>
					)}
				/>
			</FormControl>
		</div>
	);
};

export default HousingForm;
