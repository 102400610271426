import FlatIcon from "src/components/FlatIcon";
import QRCode from "qrcode.react";
import ActionBtn from "src/components/ActionBtn";
import {
	calculateAgeV3,
	patientAddress,
	patientFullName,
} from "src/helpers/utils";
import Img from "src/components/Img";
const PatientQr = () => {
	return (
		<div className="flex flex-col gap-1">
			<h6 className="text-xs font-normal mb-0 text-center !text-secondary">
				Patient QRCode
			</h6>
			<div className="flex items-center justify-center pt-1 pb-2 w-full">
				<QRCode
					id="qr-gen"
					value={JSON.stringify({
						patient_id: 1234,
						// user_id: users?.user_id,
						// philhealth: patient?.philhealth,
					})}
					level="H"
					size={144}
				/>
			</div>
		</div>
	);
};
const PatientProfileOverview = ({ patient }) => {
	const downloadQRCode = () => {
		// Generate download with use canvas and stream
		const canvas = document.getElementById("qr-gen");
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = `${"patient.lastname"}_${"patient.firstname"}.png`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};
	return (
		<div className="rounded-xl bg-white flex flex-col items-center justify-start gap-3 w-full p-4 h-full max-h-[calc(100vh-274)] overflow-auto">
			<h2 className="text-lg font-bold text-blue-500 w-full">
				Patient Overview
			</h2>
			<div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
				<div className="min-w-[128px] flex flex-col gap-2">
					<div className="pt-[100%] relative">
						<Img
							src={patient?.avatar}
							alt=""
							type="user"
							name={patientFullName(patient)}
							className="absolute top-0 left-0 w-full h-full object-contain bg-slate-300 overflow-hidden rounded-xl"
						/>
					</div>
					<h3 className="font-bold text-xl mb-0 w-full text-center">
						{patientFullName(patient)}
					</h3>
					<div className="pt-4">
						<PatientQr />
					</div>
				</div>
				<div className="lg:col-span-2">
					<p className="text-left text-primary w-full text-sm mb-3 font-bold">
						Patient Details
					</p>

					<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
						<div className="flex flex-col gap-y-4">
							<div className="flex items-start gap-2 !text-sm text-slate-600">
								<FlatIcon
									icon="rr-id-badge"
									className="mb-[-2px]"
								/>
								First name:{" "}
								<b className="text-pin">{patient?.firstname}</b>
							</div>
							<div className="flex items-start gap-2 !text-sm text-slate-600">
								<FlatIcon
									icon="rr-id-badge"
									className="mb-[-2px]"
								/>
								Middle name:{" "}
								<b className="text-pin">{patient?.middle}</b>
							</div>
							<div className="flex items-start gap-2 !text-sm text-slate-600">
								<FlatIcon
									icon="rr-id-badge"
									className="mb-[-2px]"
								/>
								Last name:{" "}
								<b className="text-pin">{patient?.lastname}</b>
							</div>
							<div className="flex items-start gap-2 !text-sm text-slate-600">
								<FlatIcon
									icon="rr-venus-mars"
									className="mb-[-2px]"
								/>
								Sex:{" "}
								<b className="text-pin">{patient?.gender}</b>
							</div>
							<div className="flex items-start gap-2 !text-sm text-slate-600">
								<FlatIcon
									icon="rr-calendar-clock"
									className="mb-[-2px]"
								/>
								Age: <b>{calculateAgeV3(patient?.birthday)}</b>
							</div>
							<div className="flex items-start gap-2 !text-sm text-slate-600">
								<FlatIcon
									icon="rr-ruler-combined"
									className="mb-[-2px]"
								/>
								Height: <b>{patient?.height}</b>
							</div>
							<div className="flex items-start gap-2 !text-sm text-slate-600">
								<FlatIcon
									icon="rr-dashboard"
									className="mb-[-2px]"
								/>
								Weight: <b>{patient?.weight}</b>
							</div>
						</div>
						<div className="flex flex-col gap-y-4">
							{/* <div className="flex items-start gap-2 !text-sm text-slate-600">
								<FlatIcon
									icon="rr-raindrops"
									className="mb-[-2px]"
								/>
								Blood type: <b className="text-pin">AB+ </b>
							</div>
							<div className="flex items-start gap-2 !text-sm text-slate-600">
								<FlatIcon
									icon="rr-face-sad-sweat"
									className="mb-[-2px]"
								/>
								Allergies: <b>Penicilin, peanuts</b>
							</div>
							<div className="flex items-start gap-2 !text-sm text-slate-600">
								<FlatIcon
									icon="rr-face-mask"
									className="mb-[-2px]"
								/>
								Diseases: <b>Diabetes</b>
							</div>
							<div className="flex items-start gap-2 !text-sm text-slate-600">
								<FlatIcon
									icon="rr-dashboard"
									className="mb-[-2px]"
								/>
								Pressure: <b>130/80 mmHG</b>
							</div>
							<div className="flex items-start gap-2 !text-sm text-slate-600">
								<FlatIcon
									icon="rr-ruler-vertical"
									className="mb-[-2px]"
								/>
								Temperture: <b>36.8 °C</b>
							</div> */}
						</div>

						<div className="flex flex-col gap-y-4 lg:col-span-2">
							<div className="flex items-start flex-col gap-2 !text-sm text-slate-600">
								<span className="gap-2 flex items-center">
									<FlatIcon
										icon="rr-map-marker"
										className="mb-[-2px]"
									/>
									Address:
								</span>
								<b className="text-pin">
									{patientAddress(patient)}
									{/* 142-A, Sample St., Tuyan, Malapatan,
									Sarangani, Philippines */}
								</b>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PatientProfileOverview;
