import {
	TableHead,
	Table,
	TableCell,
	TableRow,
	TableBody,
	TableContainer,
	Typography,
	Box,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
// import Label from "src/utils/Label";
import SorologyRef from "src/laboratory/references/Ref_Sorology";
import { UsersData } from "src/ContextAPI";
// import Footer from "src/laboratory/print/Footer";

const SerologyOrder = ({ order_id, patient_id, formheader }) => {
	const { users } = React.useContext(UsersData);

	const [details, setDetails] = useState([]);

	const getpaidLaboratoryOrderDetails = async () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set("trace_number", order_id);
		formdata.set("table", "laboratory_sorology");
		formdata.set("connection", "local");

		try {
			let response = await axios.post(
				"doctor/patient/laboratory/order/paid-detailsbytable",
				formdata
			);
			const data = response.data;

			setDetails(data);
		} catch (error) {
			console.log("lab form order laboratory_hematology table error", error);
		}
	};

	// const checkResult = (order) => {
	//   return order === "new-order" ? (
	//     <Label color="error"> no result </Label>
	//   ) : order === "refund" ? (
	//     <Label color="warning"> {order} </Label>
	//   ) : (
	//     <Label color="success"> {order} </Label>
	//   );
	// };

	useEffect(() => {
		getpaidLaboratoryOrderDetails();

		// eslint-disable-next-line
	}, []);

	return (
		<>
			{details.length > 0 && (
				<>
					<Box my={2} p={2}>
						<Box>
							<Typography variant="h6" align="center">
								<strong> Serology Order </strong>
							</Typography>
						</Box>

						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align="center">
											{" "}
											Examination Requested{" "}
										</TableCell>
										<TableCell align="center"> Normal </TableCell>
										<TableCell align="center"> Result </TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{details.map((data, index) => (
										<Fragment key={index}>
											{data.hbsag && (
												<TableRow>
													<TableCell>
														<Typography
															variant="subtitle2"
															className={`gtc-uppercase`}
														>
															Hepatitis B surface Antigen (HBsAg)
														</Typography>
													</TableCell>
													<TableCell align="center">
														{SorologyRef.normal()}
													</TableCell>
													<TableCell align="center">
														<Typography>{data.hbsag}</Typography>
													</TableCell>
												</TableRow>
											)}
											{data.hav && (
												<TableRow>
													<TableCell>
														<Typography
															variant="subtitle2"
															className={`gtc-uppercase`}
														>
															HAV (Hepatitis A Virus) lgG/lgM
														</Typography>
													</TableCell>
													<TableCell align="center">
														{SorologyRef.normal()}
													</TableCell>
													<TableCell align="center">
														<Typography>{data.hav}</Typography>
													</TableCell>
												</TableRow>
											)}

											{data.vdrl_rpr && (
												<TableRow>
													<TableCell>
														<Typography
															variant="subtitle2"
															className={`gtc-uppercase`}
														>
															VDRL/RPR
														</Typography>
													</TableCell>
													<TableCell align="center">
														{SorologyRef.normal()}
													</TableCell>
													<TableCell align="center">
														<Typography>{data.vdrl_rpr}</Typography>
													</TableCell>
												</TableRow>
											)}

											{data.anti_hbc_igm && (
												<TableRow>
													<TableCell>
														<Typography
															variant="subtitle2"
															className={`gtc-uppercase`}
														>
															ANTI-HBC IGM
														</Typography>
													</TableCell>
													<TableCell align="center">
														{/* {SorologyRef.normal()} */}
													</TableCell>
													<TableCell align="center">
														<Typography>{data.anti_hbc_igm}</Typography>
													</TableCell>
												</TableRow>
											)}

											{data.hcv && (
												<TableRow>
													<TableCell>
														<Typography
															variant="subtitle2"
															className={`gtc-uppercase`}
														>
															HCV (Hepatitis C Virus)
														</Typography>
													</TableCell>
													<TableCell align="center">
														{SorologyRef.normal()}
													</TableCell>
													<TableCell align="center">
														<Typography>{data.hcv}</Typography>
													</TableCell>
												</TableRow>
											)}

											{data.beta_hcg_quali && (
												<TableRow>
													<TableCell>
														<Typography
															variant="subtitle2"
															className={`gtc-uppercase`}
														>
															BETA HCG (QUALI)
														</Typography>
													</TableCell>
													<TableCell align="center">
														{/* {SorologyRef.normal()} */}
													</TableCell>
													<TableCell align="center">
														<Typography>{data.beta_hcg_quali}</Typography>
													</TableCell>
												</TableRow>
											)}

											{data.h_pylori && (
												<TableRow>
													<TableCell>
														<Typography
															variant="subtitle2"
															className={`gtc-uppercase`}
														>
															H. PYLORI
														</Typography>
													</TableCell>
													<TableCell align="center">
														{/* {SorologyRef.normal()} */}
													</TableCell>
													<TableCell align="center">
														<Typography>{data.h_pylori}</Typography>
													</TableCell>
												</TableRow>
											)}

											{data.typhidot && (
												<TableRow>
													<TableCell>
														<Typography
															variant="subtitle2"
															className={`gtc-uppercase`}
														>
															TYPHIDOT
														</Typography>
													</TableCell>
													<TableCell align="center">
														{/* {SorologyRef.normal()} */}
													</TableCell>
													<TableCell align="center">
														<Typography>{data.typhidot}</Typography>
													</TableCell>
												</TableRow>
											)}

											{Boolean(parseInt(data.syphilis_test)) && (
												<TableRow>
													<TableCell>
														<Typography
															variant="subtitle2"
															className={`gtc-uppercase`}
														>
															VDRL/Syphilis Test
														</Typography>
													</TableCell>
													<TableCell align="center">
														{/* {SorologyRef.normal()} */}
													</TableCell>
													<TableCell align="center">
														<Typography>{data.syphilis_test_result}</Typography>
													</TableCell>
												</TableRow>
											)}

											{data.hact && (
												<TableRow>
													<TableCell>
														<Typography
															variant="subtitle2"
															className={`gtc-uppercase`}
														>
															HACT
														</Typography>
													</TableCell>
													<TableCell align="center">
														{/* {SorologyRef.normal()} */}
													</TableCell>
													<TableCell align="center">
														<Typography>{data.hact}</Typography>
													</TableCell>
												</TableRow>
											)}

											{data.ana && (
												<TableRow>
													<TableCell>
														<Typography
															variant="subtitle2"
															className={`gtc-uppercase`}
														>
															ANA
														</Typography>
													</TableCell>
													<TableCell align="center">
														{/* {SorologyRef.normal()} */}
													</TableCell>
													<TableCell align="center">
														<Typography>{data.ana}</Typography>
													</TableCell>
												</TableRow>
											)}

											{Boolean(parseInt(data.dengue_test)) && (
												<TableRow>
													<TableCell>
														<Typography
															variant="subtitle2"
															className={`gtc-uppercase`}
														>
															DENGUE TEST
														</Typography>
													</TableCell>
													<TableCell align="center">
														{/* {SorologyRef.normal()} */}
													</TableCell>
													<TableCell align="center">
														<Typography>{data.dengue_test_result}</Typography>
													</TableCell>
												</TableRow>
											)}
										</Fragment>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>

					{/* <Footer formheader={formheader} /> */}
				</>
			)}
		</>
	);
};

export default SerologyOrder;
