import {
	Typography,
	Grid,
	Box,
	List,
	TextField,
	InputAdornment,
	ListItem,
	ListItemText,
	Avatar,
	TablePagination,
	ListItemIcon,
	makeStyles,
	CardMedia,
	IconButton,
	Card,
	CardHeader,
	CardContent,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { UsersHeader } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import SearchIcon from "@material-ui/icons/Search";
import Container from "src/layout/Container";
import PatientQueueDetails from "./PatientQueueDetails";
import { Search, XCircle } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
	primary: {
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
}));

const PatientQueu = () => {
	const classes = useStyles();
	const {
		queue: { patient, getPatientsOnQueue },
	} = useContext(UsersHeader);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [selectedPatient, setSelectedPatient] = useState("new-patient");
	const [search, setSearch] = useState("");
	const [selectedPatientTracenumber, setSelectedPatientTracenumber] =
		useState(null);
	const [searchDisplay, setSearchDisplay] = useState(false);
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	const searchable = patient.filter((data) => {
		return (
			data?.firstname?.toLowerCase().indexOf(search.trim()) !== -1 ||
			data?.lastname?.toLowerCase().indexOf(search.trim()) !== -1
		);
	});

	return (
		<Container
			breadcrumbs={{
				enable: true,
				current: "Patient Queue",
				items: [{ name: "Dashboard", path: "/sph/app" }],
			}}
			title={"Patient Queue"}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={4} lg={3}>
					<Card>
						<CardHeader
							title={
								<Box display={"flex"}>
									<Box flexGrow={1}>Patient Queue</Box>
									<Box>
										<IconButton
											color={
												!searchDisplay
													? "primary"
													: "secondary"
											}
											onClick={() =>
												setSearchDisplay(!searchDisplay)
											}
										>
											{!searchDisplay ? (
												<Search />
											) : (
												<XCircle />
											)}
										</IconButton>
									</Box>
								</Box>
							}
						/>

						<CardContent>
							<List component="div">
								<Box mb={1} hidden={!searchDisplay}>
									<TextField
										label="Search Patient"
										variant="outlined"
										fullWidth
										// margin="dense"
										value={search}
										onChange={(e) =>
											setSearch(e.target.value)
										}
										InputProps={{
											endAdornment: (
												<InputAdornment>
													<SearchIcon />
												</InputAdornment>
											),
										}}
									/>
								</Box>

								{patient.length > 0
									? searchable
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((data, index) => (
												<ListItem
													key={index}
													button
													className="mb-2"
													onClick={() => {
														setSelectedPatient(
															data.patient_id
														);
														setSelectedPatientTracenumber(
															data.trace_number
														);
													}}
													selected={
														selectedPatient ===
														data.patient_id
													}
												>
													<ListItemIcon>
														{data.image === null ? (
															<Avatar
																className={
																	classes.primary
																}
															>
																{data?.lastname
																	?.charAt()
																	.toUpperCase()}
															</Avatar>
														) : (
															<Avatar
																className={
																	classes.primary
																}
																src={`${imageLocation}patients/${data.image}`}
																alt=""
															/>
														)}
													</ListItemIcon>
													<ListItemText
														className={`gtc-capitalize`}
														primary={`${
															data.lastname
														}, ${data.firstname} ${
															Boolean(data.middle)
																? data.middle
																: ``
														} `}
													/>
												</ListItem>
											))
									: Notify.noRecord()}
							</List>

							<TablePagination
								component="div"
								count={patient.length}
								rowsPerPageOptions={[
									10,
									50,
									100,
									{ label: "All", value: -1 },
								]}
								page={page}
								onChangePage={handleChangePage}
								rowsPerPage={rowsPerPage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								labelRowsPerPage="List"
							/>
						</CardContent>
					</Card>
				</Grid>

				<Grid item xs={12} sm={8} lg={9}>
					{console.log("selectedPatient", selectedPatient)}
					{selectedPatient === "new-patient" && (
						<RenderSelectPatient />
					)}
					{selectedPatient !== "new-patient" && (
						<PatientQueueDetails
							patient_id={selectedPatient}
							trace_number={selectedPatientTracenumber}
							getNurseOnQueue={() => getPatientsOnQueue()}
							onClose={() => setSelectedPatient("new-patient")}
						/>
					)}
				</Grid>
			</Grid>
		</Container>
	);
};

export default PatientQueu;

const RenderSelectPatient = () => (
	<Box align="center">
		<CardMedia
			component="img"
			src={"/gtc-logo.png"}
			alt=""
			style={{ width: 250 }}
		/>
		<Typography color="secondary" variant="h3">
			NO PATIENT SELECTED
		</Typography>

		<Typography color="secondary" variant="h6">
			Select patient in the list to view details.
		</Typography>
	</Box>
);
