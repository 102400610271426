import {
	Box,
	Dialog,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
	CardMedia,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "../../notification/Notify";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "../../utils/DraggableDialog";
import { useHistory } from "react-router-dom";

import { Fragment } from "react";
import ChemistryRef from "../references/Ref_Chemistry";
import { UsersData } from "src/ContextAPI";
import Specimen from "./Specimen";
import { CheckSquare, XCircle } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ClinicalChemistryOrderDetails = ({
	queue,
	formheader,
	order,
	getLabCliniclChemistryOrder,
	resetDisplay,
	process_for,
}) => {
	const { users } = React.useContext(UsersData);
	const history = useHistory();
	const [orderDetails, setOrderDetails] = useState({
		data: null,
		ready: false,
	});
	const [pendingDialog, setPendingDialog] = useState(false);
	const [processDialog, setProcessDialog] = useState({
		trace_number: null,
		order_id: null,
		dialog: false,
	});
	const [pendingSubmitting, setPendingSubmitting] = useState(false);
	const [processSubmitting, setProcessSubmitting] = useState(false);
	const [resultSubmitting, setResultSubmitting] = useState(false);
	const [savebtnDisabled, setSavebtnDisabled] = useState(true);
	const [orderItems, setOrderItems] = useState([]);
	const [confirmDialog, setConfirmDialog] = useState(false);
	const saveButtonRef = React.useRef();

	const getLabChemistryOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order.trace_number };
		Axios.get("laboratory/order/ordernew-clinicalchemistry/details", { params })
			.then((response) => {
				const data = response.data;
				setOrderDetails({
					data: data,
					ready: true,
				});

				checkIfSaveBtnIsEnabled(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const checkIfSaveBtnIsEnabled = (data) => {
		for (let i = 0; i < data.length; i++) {
			if (Boolean(data[i].is_processed)) {
				setSavebtnDisabled(false);
			}
		}
	};

	useEffect(() => {
		getLabChemistryOrderDetails();

		// eslint-disable-next-line
	}, [order]);

	const handleSaveResult = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("trace_number", order.trace_number);
		formdata.set("queue", queue);
		formdata.set("process_for", process_for);

		var error = [];

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setResultSubmitting(true);
			Axios.post(
				"laboratory/order/ordernew-clinicalchemistry/save-process-result",
				formdata
			)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						Notify.successRequest("order result added.");
						setTimeout(() => {
							history.push(
								`/sph/app/laboratory/record/print/order/${order.trace_number}`
							);
						}, 5000);
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				});
		}
	};

	const handlePendingOrder = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", order.order_id);

		var error = [];

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setPendingSubmitting(true);
			Axios.post(
				"laboratory/order/ordernew-clinicalchemistry/save-setpending",
				formdata
			)
				.then((response) => {
					const data = response.data;
					if (data === "pass-invalid") {
						Notify.fieldInvalid("password");
					}
					if (data === "success") {
						getLabCliniclChemistryOrder();
						resetDisplay();
						setPendingDialog(false);
						Notify.successRequest("order pending");
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => setPendingSubmitting(false));
		}
	};
	const handleProcessOrder = async (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", processDialog.order_id);
		formdata.set("trace_number", processDialog.trace_number);

		var error = [];
		setProcessSubmitting(true);

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			let response = await Axios.post(
				"laboratory/chemistry/order-setprocess/custom-qty",
				formdata
			);

			if (response.data.message === "reagent-error") {
				Notify.customToast("Reagent Not Error", "Reagent/items not available.");
			}

			if (response.data.message === "pass-invalid") {
				Notify.fieldInvalid("password");
			}

			if (response.data.message === "success") {
				Notify.successRequest("process");
				setProcessDialog({ trace_number: null, order_id: null, dialog: false });

				getLabChemistryOrderDetails();
			}
		}

		setTimeout(() => {
			setProcessSubmitting(false);
		}, 2000);
	};

	const handleProcessSelectedOrder = async (order_id, trace_number) => {
		let response = await Axios.get(
			"laboratory/items/laborder/list-available-items",
			{
				params: {
					user_id: users.user_id,
					management_id: users.management_id,
					order_id: order_id,
				},
			}
		);

		setOrderItems(response.data);

		setProcessDialog({
			trace_number: trace_number,
			order_id: order_id,
			dialog: true,
		});
	};

	return (
		<>
			<form onSubmit={handleSaveResult}>
				<Card elevation={0}>
					<Box display="flex" justifyContent="center">
						<Box mt={2}>
							{formheader && (
								<CardMedia
									style={{ width: 70 }}
									component={"img"}
									src={`${imageLocation}laboratory/logo/${formheader.logo}`}
								/>
							)}
						</Box>
						<Box>
							<CardHeader
								component={Box}
								align="center"
								title={formheader && formheader.name}
								subheader={
									<Box>
										<Typography>{formheader && formheader.address}</Typography>
										<Typography>
											{formheader && formheader.contact_number}
										</Typography>
									</Box>
								}
							/>
						</Box>
					</Box>

					<CardContent>
						{/* paitent information */}
						<Box display="flex">
							<Box flexGrow={1} mb={2}>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT NAME:{" "}
										</Typography>
										{`${order.lastname}, ${order.firstname} ${
											order.middle === null ? "" : order.middle
										}`}
									</Typography>
								</Box>

								<Box display="flex" mb={2}>
									<Box>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												AGE:{" "}
											</Typography>
											{order.birthday === null
												? "none"
												: Notify.calculateAge(order.birthday)}
										</Typography>
									</Box>
									<Box ml={5}>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												GENDER:{" "}
											</Typography>
											{order.gender === null ? "none" : order.gender}
										</Typography>
									</Box>
								</Box>

								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT ADDRESS:{" "}
										</Typography>
										{`${order.street} ${order.barangay} ${order.city} `}
									</Typography>
								</Box>

								<Box>
									<Typography variant="caption" className="font-weight-bold">
										PATIENT CONDITION:{" "}
									</Typography>
									{order.patient_condition === null
										? " none"
										: order.patient_condition}
								</Box>
							</Box>

							<Box>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											DATE:{" "}
										</Typography>
										{Notify.createdAt(new Date().toLocaleString())}
									</Typography>
								</Box>
							</Box>
						</Box>
					</CardContent>

					<CardContent>
						<Specimen
							patient_id={order.patient_id}
							trace_number={order.trace_number}
						/>
					</CardContent>

					<CardContent>
						{/* order details */}
						<Box>
							<Typography variant="h6" align="center">
								<b> CLINICAL CHEMISTRY </b>
							</Typography>
						</Box>

						{orderDetails.ready ? (
							orderDetails.data.length > 0 ? (
								<>
									<TableContainer>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell align="center">
														<b> TEST </b>
													</TableCell>
													<TableCell align="center">
														<b> RESULT </b>
													</TableCell>
													<TableCell align="center">
														<b> ACTION </b>
													</TableCell>
													<TableCell align="center">
														<b> NORMAL VALUE </b>
													</TableCell>
													<TableCell align="center">
														<b> REMARKS </b>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{orderDetails.data.map((data, index) => (
													<Fragment key={index}>
														<TableRow hidden={data.fbs === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	FBS
																</Typography>
															</TableCell>
															<TableCell>
																{/* hidden inputs */}
																<Box>
																	<Box>
																		<TextField
																			margin="dense"
																			fullWidth
																			name="order_id[]"
																			label="order_id"
																			value={data.order_id}
																			hidden
																		/>
																		<TextField
																			margin="dense"
																			fullWidth
																			name="patient_id"
																			label="patient_id"
																			value={data.patient_id}
																			hidden
																		/>

																		<TextField
																			margin="dense"
																			fullWidth
																			name="doctors_id"
																			label="doctor_id"
																			value={
																				data.doctor_id === null
																					? ""
																					: data.doctor_id
																			}
																			hidden
																		/>
																	</Box>
																	<TextField
																		margin="dense"
																		fullWidth
																		name="fbs[]"
																		label="Result"
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{ChemistryRef.fbs()}
															</TableCell>

															<TableCell align="center">
																<Box>
																	<TextField
																		margin="dense"
																		fullWidth
																		name="fbs_remarks[]"
																		label="Remarks"
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																	/>
																</Box>
															</TableCell>
														</TableRow>
														{/* hidden inputs */}
														<TableRow hidden={data.glucose === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	GLUCOSE
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="glucose[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* {ChemistryRef.glucose()} */}
															</TableCell>

															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="glucose_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.creatinine === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	CREATININE
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="creatinine[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{ChemistryRef.creatinine()}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="creatinine_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.uric_acid === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	URIC ACID
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="uric_acid[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{ChemistryRef.uric()}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="uric_acid_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.cholesterol === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	CHOLESTEROL
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="cholesterol[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{ChemistryRef.cholesterol()}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="cholesterol_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.triglyceride === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	TRIGLYCERIDE
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="triglyceride[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{ChemistryRef.triglyceride()}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="triglyceride_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.hdl_cholesterol === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	HDL CHOLESTEROL
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="hdl_cholesterol[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{ChemistryRef.hdlcholesterol()}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="hdl_cholesterol_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.ldl_cholesterol === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	LDL CHOLESTEROL
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ldl_cholesterol[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{ChemistryRef.ldlcholesterol()}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ldl_cholesterol_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.sgot === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	SGOT
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="sgot[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{ChemistryRef.sgot()}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="sgot_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.sgpt === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	SGPT
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="sgpt[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{ChemistryRef.sgpt()}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="sgpt_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.bun === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	bun
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="bun[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="bun_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.soduim === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	soduim
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="soduim[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="soduim_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.potassium === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	potassium
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="potassium[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="potassium_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.hba1c === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	hba1c
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="hba1c[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="hba1c_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow
															hidden={data.alkaline_phosphatase === null}
														>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	alkaline_phosphatase
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="alkaline_phosphatase[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="alkaline_phosphatase_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.albumin === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	albumin
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="albumin[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="albumin_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.calcium === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	calcium
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="calcium[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="calcium_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.magnesium === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	magnesium
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="magnesium[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="magnesium_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.chloride === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	chloride
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="chloride[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="chloride_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.serum_uric_acid === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	SERUM URIC ACID
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="serum_uric_acid[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="serum_uric_acid_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.lipid_profile === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	LIPID PROFILE
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="lipid_profile[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="lipid_profile_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.ldh === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	LDH
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ldh[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ldh_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.tpag_ratio === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	TPAG RATIO
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="tpag_ratio[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="tpag_ratio_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.bilirubin === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	BILIRUBIN
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="bilirubin[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="bilirubin_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.total_protein === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	TOTAL PROTEIN
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="total_protein[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="total_protein_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.potassium_kplus === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	POTASSIUM K+
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="potassium_kplus[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="potassium_kplus_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.na_plus_kplus === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	NA+/K+
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="na_plus_kplus[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="na_plus_kplus_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.ggt === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	GGT
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ggt[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ggt_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.cholinesterase === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	CHOLINESTERASE
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="cholinesterase[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="cholinesterase_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.phosphorous === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	PHOSPHOROUS
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="phosphorous[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="phosphorous_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.rbs === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	RBS
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="rbs[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="rbs_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.vldl === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	VLDL
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="vldl[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="vldl_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.rbc_cholinesterase === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	RBC CHOLINESTERASE
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="rbc_cholinesterase[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="rbc_cholinesterase_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.crp === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	CRP
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="crp[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="crp_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow hidden={data.pro_calcitonin === null}>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	PRO CALCITONIN
																</Typography>
															</TableCell>
															<TableCell>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="pro_calcitonin[]"
																		label="Result"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
															<TableCell align="center">
																{/* reference not set */}
															</TableCell>
															<TableCell align="center">
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="pro_calcitonin_crp_remarks[]"
																		label="Remarks"
																	/>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow
															hidden={data.ogct_take_one_50grm === null}
														>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	OGCT TAKE 1 (50GRM)
																</Typography>
															</TableCell>
															<TableCell colSpan={3}>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: true,
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_one_50grm[]"
																		value={
																			data.ogct_take_one_50grm === null
																				? "yes"
																				: "no"
																		}
																		hidden
																	/>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_one_50grm_baseline[]"
																		label="Baseline"
																	/>
																</Box>

																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_one_50grm_first_hour[]"
																		label="First Hour"
																	/>
																</Box>

																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_one_50grm_second_hour[]"
																		label="Second Hour"
																	/>
																</Box>
															</TableCell>

															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
														</TableRow>

														<TableRow
															hidden={data.ogct_take_one_75grm === null}
														>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	OGCT TAKE 1 (75GRM)
																</Typography>
															</TableCell>
															<TableCell colSpan={3}>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: true,
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_one_75grm[]"
																		value={
																			data.ogct_take_one_75grm === null
																				? "yes"
																				: "no"
																		}
																		hidden
																	/>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_one_75grm_baseline[]"
																		label="Baseline"
																	/>
																</Box>

																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_one_75grm_first_hour[]"
																		label="First Hour"
																	/>
																</Box>

																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_one_75grm_second_hour[]"
																		label="Second Hour"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
														</TableRow>

														<TableRow
															hidden={data.ogct_take_two_100grm === null}
														>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	OGTT TAKES 2 (100GRM)
																</Typography>
															</TableCell>
															<TableCell colSpan={3}>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: true,
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_two_100grm[]"
																		value={
																			data.ogct_take_two_100grm === null
																				? "yes"
																				: "no"
																		}
																		hidden
																	/>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_two_100grm_baseline[]"
																		label="Baseline"
																	/>
																</Box>

																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_two_100grm_first_hour[]"
																		label="First Hour"
																	/>
																</Box>

																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_two_100grm_second_hour[]"
																		label="Second Hour"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
														</TableRow>

														<TableRow
															hidden={data.ogct_take_two_75grm === null}
														>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	OGTT TAKES 2 (75GRM)
																</Typography>
															</TableCell>
															<TableCell colSpan={3}>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: true,
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_two_75grm[]"
																		value={
																			data.ogct_take_two_75grm === null
																				? "yes"
																				: "no"
																		}
																		hidden
																	/>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_two_75grm_baseline[]"
																		label="Baseline"
																	/>
																</Box>

																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_two_75grm_first_hour[]"
																		label="First Hour"
																	/>
																</Box>

																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_two_75grm_second_hour[]"
																		label="Second Hour"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
														</TableRow>

														<TableRow
															hidden={data.ogct_take_three_100grm === null}
														>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	OGTT TAKES 3 (100GRM)
																</Typography>
															</TableCell>
															<TableCell colSpan={3}>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: true,
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_three_100grm[]"
																		value={
																			data.ogct_take_three_100grm === null
																				? "yes"
																				: "no"
																		}
																		hidden
																	/>

																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_three_100grm_baseline[]"
																		label="Baseline"
																	/>
																</Box>

																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_three_100grm_first_hour[]"
																		label="First Hour"
																	/>
																</Box>

																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_three_100grm_second_hour[]"
																		label="Second Hour"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
														</TableRow>

														<TableRow
															hidden={data.ogct_take_four_100grm === null}
														>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	className={`gtc-uppercase`}
																>
																	OGTT TAKES 4 (100GRM)
																</Typography>
															</TableCell>
															<TableCell colSpan={3}>
																<Box>
																	<TextField
																		InputProps={{
																			readOnly: true,
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_four_100grm[]"
																		value={
																			data.ogct_take_four_100grm === null
																				? "yes"
																				: "no"
																		}
																		hidden
																	/>

																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_four_100grm_baseline[]"
																		label="Baseline"
																	/>
																</Box>

																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_four_100grm_first_hour[]"
																		label="First Hour"
																	/>
																</Box>

																<Box>
																	<TextField
																		InputProps={{
																			readOnly: !Boolean(
																				parseInt(data.is_processed)
																			),
																		}}
																		margin="dense"
																		fullWidth
																		name="ogct_take_four_100grm_second_hour[]"
																		label="Second Hour"
																	/>
																</Box>
															</TableCell>
															<TableCell align="center">
																<Button
																	disabled={Boolean(
																		parseInt(data.is_processed)
																	)}
																	size="small"
																	variant="contained"
																	color="primary"
																	onClick={() => {
																		handleProcessSelectedOrder(
																			data.order_id,
																			data.trace_number
																		);
																	}}
																>
																	Process
																</Button>
															</TableCell>
														</TableRow>
													</Fragment>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</>
							) : (
								<Typography color="secondary" variant="subtitle2">
									No record found.
								</Typography>
							)
						) : (
							<Typography color="primary" variant="subtitle2">
								please wait...
							</Typography>
						)}
					</CardContent>
					<Box display="flex" m={1}>
						<Box flexGrow={1} />
						<Box>
							<CardActions>
								<Button
									variant="contained"
									size="large"
									color="primary"
									type="submit"
									hidden
									disabled={!confirmDialog}
									ref={saveButtonRef}
								>
									Save Result Button
								</Button>

								<Button
									variant="contained"
									size="large"
									color="primary"
									onClick={() => setConfirmDialog(true)}
									disabled={savebtnDisabled}
								>
									Save Result
								</Button>
							</CardActions>
						</Box>
					</Box>
				</Card>
			</form>

			{/* set as pending dialog */}
			<Dialog
				open={pendingDialog}
				onClose={() => setPendingDialog(false)}
				disableBackdropClick
				PaperComponent={DraggableDialog}
			>
				<DialogTitle id="draggable-handle">Set as pending</DialogTitle>
				<form onSubmit={handlePendingOrder}>
					<DialogContent dividers>
						<Box mb={2}>
							<TextField
								rows={5}
								fullWidth
								name="reason"
								label={`Pending Reason`}
								variant="outlined"
								multiline
							/>
						</Box>

						<Box>
							<TextField
								fullWidth
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setPendingDialog(false)}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="secondary"
							type="submit"
							disabled={pendingSubmitting}
							startIcon={
								pendingSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<ErrorOutlineIcon />
								)
							}
						>
							pending
						</Button>
					</DialogActions>
				</form>
			</Dialog>

			{/* set as process dialog */}
			<Dialog
				open={processDialog.dialog}
				onClose={() =>
					setProcessDialog({
						trace_number: null,
						order_id: null,
						dialog: false,
					})
				}
				disableBackdropClick
				PaperComponent={DraggableDialog}
				maxWidth={"xs"}
				fullWidth
			>
				<DialogTitle id="draggable-handle">Process order</DialogTitle>
				<form onSubmit={handleProcessOrder}>
					<DialogContent dividers>
						{orderItems.length > 0
							? orderItems.map((data, index) => (
									<Box key={index} mb={2}>
										<Box>
											<TextField
												fullWidth
												name="item_id[]"
												value={data.item_id}
												variant="outlined"
												hidden
											/>
										</Box>

										<Box>
											<TextField
												fullWidth
												required
												label={`${data.description} qty to process`}
												name="qty[]"
												variant="outlined"
												defaultValue={1}
												InputLabelProps={{
													shrink: true,
												}}
												// InputProps={{
												//   inputProps: {
												//     min: 0,
												//     max: data._total_qty_available,
												//   },
												// }}
												type="number"
											/>
										</Box>
									</Box>
							  ))
							: "No reagent/item found."}

						<Box mt={2}>
							<TextField
								fullWidth
								required
								defaultValue="ok"
								name="remarks"
								label={`Remarks`}
								variant="outlined"
							/>
						</Box>

						<Box mt={2}>
							<TextField
								fullWidth
								required
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() =>
								setProcessDialog({
									trace_number: null,
									order_id: null,
									dialog: false,
								})
							}
							startIcon={<HighlightOffIcon />}
						>
							no
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={processSubmitting}
							startIcon={
								processSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<CheckCircleIcon />
								)
							}
						>
							process
						</Button>
					</DialogActions>
				</form>
			</Dialog>

			{/* confirmation dialog */}
			<Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
				<DialogContent>
					<Typography>Are you sure to continue and save result?</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="default"
						startIcon={<XCircle />}
						onClick={() => setConfirmDialog(false)}
					>
						No
					</Button>
					<Button
						variant="contained"
						color="primary"
						disabled={resultSubmitting}
						startIcon={
							resultSubmitting ? (
								<CircularProgress size={20} color="inherit" />
							) : (
								<CheckSquare />
							)
						}
						onClick={() => saveButtonRef.current.click()}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ClinicalChemistryOrderDetails;
