import React from "react";
export default class PsychologyOrder extends React.Component {
  static dept = () => {
    var orders = [
      {
        label: "Psychology",
        value: "psychology",
      },
    ];

    return orders;
  };

  static list = () => {
    var orders = [
      {
        department: "psychology",
        label: "Audiometry",
        value: "Audiometry",
      },

      {
        department: "psychology",
        label: "Neuro Examination",
        value: "Neuro Examination",
      },

      {
        department: "psychology",
        label: "Ishihara",
        value: "Ishihara",
      },
    ];

    return orders;
  };
}
