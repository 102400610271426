import { TextField } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";

const PhoPatientForm = ({ register, errors, patient }) => {
	const [locations, setLocations] = useState([]);
	const [brgys, setBrgys] = useState([]);

	useEffect(() => {
		getLocations();
	}, []);
	const getLocations = () => {
		axios.get("/v1/locations").then((res) => {
			console.log("resss", res.data);
			setLocations(res.data);
		});
	};
	return (
		<div className="p-4 flex flex-col">
			<h5 className="text-base font-semibold text-tertiary mb-4 border-b w-2/3">
				Patient Form
			</h5>
			<div className="grid grid-cols-3 gap-5 mb-4 w-full">
				<TextField
					label={
						<>
							Lastname
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					{...register("lastname", {
						required: true,
					})}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.lastname}
					helperText={errors?.lastname && "This field is required"}
				/>
				<TextField
					label={
						<>
							Firstname
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					{...register("firstname", {
						required: true,
					})}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.firstname}
					helperText={errors?.firstname && "This field is required"}
				/>
				<TextField
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={<>Middlename</>}
					variant="outlined"
					{...register("middlename")}
				/>
			</div>
			<div className="grid grid-cols-3 gap-5 mb-4">
				<TextField
					label={"Gender"}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("gender", {
						required: true,
					})}
					error={errors?.gender}
					helperText={errors?.gender && "This field is required"}
				>
					<option value=""> SELECT </option>
					<option value="Male"> Male </option>
					<option value="Female"> Female </option>
				</TextField>
				<TextField
					label={
						<>
							Birth Date
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					type="date"
					variant="outlined"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("birthdate", {
						required: true,
					})}
					error={errors?.birthdate}
					helperText={errors?.birthdate && "This field is required"}
				/>
				<TextField
					label={`Birth Place`}
					variant="outlined"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("birthplace", {
						required: false,
					})}
				/>
			</div>
			<div className="grid grid-cols-12 gap-6 mb-4">
				<TextField
					className="col-span-6"
					label={
						<>
							City/Municipality
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					select
					{...register("city", {
						required: true,
					})}
					error={errors?.city}
					helperText={errors?.city && "This field is required"}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					onChange={(e) => {
						if (e.target.value > 0) {
							setBrgys(locations.find((x) => x.id == e.target.value).barangays);
						} else {
							setBrgys([]);
						}
					}}
				>
					<option value=""> SELECT </option>
					{locations?.map((location) => {
						return (
							<option key={`location-${location?.id}`} value={location?.id}>
								{location?.name}{" "}
							</option>
						);
					})}
				</TextField>
				<TextField
					className="col-span-6"
					label={
						<>
							Barangay
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					{...register("barangay", {
						required: true,
					})}
					error={errors?.barangay}
					helperText={errors?.barangay && "This field is required"}
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
				>
					<option value=""> SELECT </option>
					{brgys?.map((brgy) => {
						return <option value={`brgy-${brgy?.id}`}>{brgy?.name} </option>;
					})}
				</TextField>
				<TextField
					className="col-span-4"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={
						<>
							Occupation
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					{...register("occupation", {
						required: true,
					})}
					error={errors?.occupation}
					helperText={errors?.occupation && "This field is required"}
				/>
				<TextField
					className="col-span-4"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={
						<>
							Religion
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					{...register("religion", {
						required: true,
					})}
					error={errors?.religion}
					helperText={errors?.religion && "This field is required"}
					variant="outlined"
				/>
				<TextField
					className="col-span-4"
					label={
						<>
							Civil Status
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					select
					{...register("civil_status", {
						required: true,
					})}
					error={errors?.civil_status}
					helperText={errors?.civil_status && "This field is required"}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
				>
					<option value=""> SELECT </option>
					<option value="Single"> Single </option>
					<option value="Maried"> Maried </option>
					<option value="Divorced"> Divorced </option>
				</TextField>
				<TextField
					className="col-span-4"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={
						<>
							Mobile
							<b className="text-red-500 ml-1">*</b>
						</>
					}
					variant="outlined"
					{...register("mobile", {
						required: true,
					})}
					error={errors?.mobile}
					helperText={errors?.mobile && "This field is required"}
				/>
				<TextField
					className="col-span-4"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={<>Telephone</>}
					{...register("telephone", {
						required: false,
					})}
					variant="outlined"
				/>
				<TextField
					className="col-span-4"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={<>Email</>}
					{...register("email", {
						required: false,
					})}
					variant="outlined"
					helperText=""
				/>
			</div>
			<div className="grid grid-cols-2 gap-5 mb-4">
				<TextField
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={<>Philhealth</>}
					{...register("philhealth")}
					variant="outlined"
				/>
			</div>
		</div>
	);
};

export default PhoPatientForm;
