import { Box, Card, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ButtonV3 from "src/components/ButtonV3";
import { styles } from "../styles/styles";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import { dateYYYYMMDD, timeHHII, timeHHIIA } from "src/helpers/utils";
import FlatIcon from "src/components/FlatIcon";
import axios from "axios";
import { toast } from "react-toastify";

export default function AddOR({ handleClosePopup, close, patient }) {
	const { clinic } = useClinicContext();
	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm();
	const [rooms, setRooms] = useState([]);
	const { fields, append, remove } = useFieldArray({
		control,
		name: "doctors",
	});

	useEffect(() => {
		let t = setTimeout(() => {
			getOperatingRooms();
		}, 300);
		return () => {
			clearTimeout(t);
		};
	}, []);
	const getOperatingRooms = () => {
		axios.get(`/v1/operating-rooms/list`).then((res) => {
			setRooms(res.data.data);
		});
	};

	const {
		register: doctor_register,
		handleSubmit: doctor_handleSubmit,
		formState: { errors: doctor_errors },
		reset: doctor_reset,
	} = useForm();

	const [isHealthcareClicked, setIsHealthcareClicked] = useState(false);
	const [healthcare, setHealthcare] = useState([]);

	const handleHealthcareClick = () => {
		setIsHealthcareClicked(true);
	};

	const handleDeleteProfessional = (index) => {
		setHealthcare((prevProfessionals) => {
			const updatedProfessionals = [...prevProfessionals];
			updatedProfessionals.splice(index, 1);
			return updatedProfessionals;
		});
	};

	const onSubmit = (data) => {
		console.log("onSubmit", data);
		// console.log(healthcare);
		// 	"clinic_id": 1,
		// "patient_id": 1807,
		// "date": "2023-06-22",
		// "time": "11:00",
		// "procedure": "sample procedure 2",
		// "priority": 0,
		// "room_number": null,
		// "appointment_id": null,
		// "healthcare_professionals": [
		//     {
		//         "title": "surgeon 1",
		//         "doctor_id": 1
		//     },
		//     {
		//         "title": "asst.surgeon 1",
		//         "doctor_id": 2
		//     },
		//     {
		//         "title": "asst.surgeon 2",
		//         "doctor_id": 3
		//     }
		// ]
		let formData = new FormData();
		formData.append("clinic_id", clinic?.id);
		formData.append("patient_id", patient?.id);
		formData.append("date", data?.date);
		formData.append("time", data?.time);
		formData.append("procedure", data?.procedure);
		formData.append("room_id", data?.room_id);
		data?.doctors?.map((item, index) => {
			formData.append(
				`healthcare_professionals[${index}][title]`,
				item?.type
			);
			formData.append(
				`healthcare_professionals[${index}][doctor_id]`,
				item?.id
			);
		});
		axios.post(`/v1/operating-room-chart/store`, formData).then((res) => {
			console.log("ressss", res);
			toast.success("Successfully added!");
			close();
		});
	};

	const addDoctor = (data) => {
		setIsHealthcareClicked(false);
		setHealthcare([...healthcare, data]);
		doctor_reset();
		console.log(data);
	};

	return (
		<div className="flex flex-col w-full">
			<form>
				<div className="mx-auto" style={styles.parentContainerAdd}>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
						<div style={styles.divContainer}>
							<TextField
								label={<label style={styles.label}>Date</label>}
								variant="outlined"
								fullWidth
								type="date"
								InputLabelProps={{
									shrink: true,
								}}
								defaultValue={dateYYYYMMDD()}
								{...register("date", { required: true })}
								error={errors.date}
								helperText={errors.date && "Date is required"}
							/>
						</div>
						<div style={styles.divContainer}>
							<TextField
								label={<label style={styles.label}>Time</label>}
								variant="outlined"
								fullWidth
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
								defaultValue={timeHHII()}
								{...register("time", { required: true })}
								error={errors.time}
								helperText={errors.time && "Date is required"}
							/>
						</div>
					</div>
					<div style={styles.divContainer}>
						<TextField
							label={
								<label style={styles.label}>
									Select Operating Room
								</label>
							}
							variant="outlined"
							id="room_id"
							fullWidth
							select
							SelectProps={{
								native: true,
							}}
							InputLabelProps={{
								shrink: true,
							}}
							{...register(`room_id`, {
								required: "This field is required",
							})}
							helperText={errors.room_id?.message}
						>
							<option value="">--Select--</option>
							{rooms?.map((room) => {
								return (
									<option value={room?.id}>
										{room?.name}
									</option>
								);
							})}
						</TextField>
					</div>
					<div style={styles.divContainer}>
						<TextField
							label={
								<label style={styles.label}>Procedure</label>
							}
							variant="outlined"
							id="procedure"
							fullWidth
							select
							SelectProps={{
								native: true,
							}}
							InputLabelProps={{
								shrink: true,
							}}
							{...register(`procedure`, {
								required: "This field is required",
							})}
							helperText={errors.procedure?.message}
						>
							<option value="">--Select--</option>

							<option value="Coronary Artery Bypass Graft">
								Coronary Artery Bypass Graft
							</option>
							<option value="Mitral/Aortic Valve Replacement">
								Mitral/Aortic Valve Replacement
							</option>
							<option value="Bentall's Procedure">
								Bentall's Procedure
							</option>
							<option value="Thoracie Aneurysm Repair">
								Thoracie Aneurysm Repair
							</option>
							<option value="Abdominal Aortic Aneurysm Repair">
								Abdominal Aortic Aneurysm Repair
							</option>
							<option value="Ventricular Septal Defect Closure">
								Ventricular Septal Defect Closure
							</option>
							<option value="Atrial Septal Defect">
								Atrial Septal Defect
							</option>
							<option value="Aortic Switch Operatic">
								Aortic Switch Operatic
							</option>
							<option value="BTS">BTS</option>
							<option value="Pda closure">Pda closure</option>
						</TextField>
					</div>

					<div className="flex flex-col gap-y-1 pb-3">
						<b className="text-black font-bold mb-3 text-sm">
							Healthcare Professionals
						</b>

						{fields.map((item, index) => (
							<div className="grid grid-cols-1 lg:grid-cols-12 gap-3 pb-3 w-full">
								<div className="lg:col-span-9">
									<TextField
										label={
											<label style={styles.label}>
												Doctors
											</label>
										}
										variant="outlined"
										id="doctor"
										fullWidth
										select
										SelectProps={{
											native: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											onChange: (e) => {
												console.log(
													"eeeeee",
													e,
													e.target.selectedOptions[0].getAttribute(
														"dataTitle"
													)
												);
												setValue(
													`doctors[${index}].type`,
													e.target.selectedOptions[0].getAttribute(
														"dataTitle"
													)
												);
											},
										}}
										{...register(`doctors[${index}].id`)}
										// error={doctor_errors.doctors}
										// helperText={
										// 	doctor_errors.doctors &&
										// 	"Doctors is required"
										// }
									>
										<option value="">--Select--</option>
										{clinic?.doctors?.map((doctor) => {
											return (
												<option
													value={doctor?.id}
													dataTitle={doctor?.title}
												>
													{doctor?.title
														? `[${doctor?.title}] - `
														: ""}
													{doctor?.name}
												</option>
											);
										})}
									</TextField>
								</div>
								<div className="lg:col-span-1 opacity-0">
									<TextField
										label={
											<label style={styles.label}>
												Doctor Type
											</label>
										}
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										className="w-full"
										{...register(`doctors[${index}].type`)}
										// error={errors.type}
										// helperText={
										// 	doctor_errors.doctor_type &&
										// 	"Doctor Type is required"
										// }
									/>
								</div>
								<div className="flex items-center justify-center lg:col-span-2">
									<ButtonV3
										type="danger"
										onClick={() => remove(index)}
									>
										<FlatIcon icon="rr-trash" />
										Remove
									</ButtonV3>
								</div>
							</div>
						))}
						<div className="w-full px-4 pb-3 !border-dashed !border-slate-500 flex items-center justify-center	">
							<ButtonV3
								onClick={() =>
									append({ doctorName: "", title: "" })
								}
							>
								<FlatIcon icon="rr-plus" />
								Add Healthcare Professionals
							</ButtonV3>
						</div>
					</div>
				</div>

				<div className="flex items-center justify-end">
					<ButtonV3
						size="lg"
						type="success"
						onClick={handleSubmit(onSubmit)}
					>
						<FlatIcon icon="rr-disk" />
						Submit
					</ButtonV3>
				</div>
			</form>
		</div>
	);
}
