import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
} from "@material-ui/core";
import {
  CheckBoxOutlineBlank,
  CheckBox,
  CheckBoxOutlineBlankOutlined,
} from "@material-ui/icons";
import React, { useContext, useEffect } from "react";
// import FormHeader from "./FormHeader";
import {
  CheckSquare,
  Edit,
  //  Printer,
  XCircle,
} from "react-feather";
// import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const FormNine = ({ patient_id, details, allowEdit }) => {
  const printableRefOld = React.useRef();
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [isProcess, setIsProcess] = React.useState(false);
  const { users } = useContext(UsersData);
  const [existingSurgicalMemChart, setExistingSurgicalMemChart] =
    React.useState({
      data: [],
      ready: false,
    });

  const [checkList, setCheckList] = React.useState({
    type_general: false,
    type_spinal: false,
    type_epidural: false,
    type_local: false,
    type_others: false,

    devices_none: false,
    devices_ngt: false,
    devices_et: false,
    devices_ivf: false,
    devices_bt: false,
    devices_drain: false,
    devices_urine_catheter: false,
    devices_others: false,

    pos_supine: false,
    pos_prone: false,
    pos_lateral: false,
    pos_lithotomy: false,
    pos_jackknife: false,
    pos_others: false,

    elec_monopolar: "",
    elec_bipolar: "",
    elec_betadine_cleanser: false,
    elec_betadine_antiseptec: false,
    elec_others: false,

    initial_sponges_correct: false,
    initial_sponges_lacking: false,
    initial_needles_correct: false,
    initial_needles_lacking: false,
    initial_instrument_correct: false,
    initial_instrument_lacking: false,
    if_lacking_xray: "",

    wa_sponges_correct2: false,
    wa_sponges_lacking2: false,
    wa_needles_correct2: false,
    wa_needles_lacking2: false,
    wa_instrument_correct2: false,
    wa_instrument_lacking2: false,
    wa_if_lacking_xray2: "",

    surgeon_notified_counts: "",
  });

  // const handlePrint = useReactToPrint({
  //   content: () => printableRefOld.current,
  // });

  const getSurgicalMemberChart = () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/surgicalmember", { params })
      .then((response) => {
        const data = response.data;
        setExistingSurgicalMemChart({ data, ready: true });
        if (data.length > 0) {
          setCheckList({
            ...checkList,
            type_general: parseInt(data[0].type_general) === 1 ? true : false,
            type_spinal: parseInt(data[0].type_spinal) === 1 ? true : false,
            type_epidural: parseInt(data[0].type_epidural) === 1 ? true : false,
            type_local: parseInt(data[0].type_local) === 1 ? true : false,
            type_others: parseInt(data[0].type_others) === 1 ? true : false,
            devices_none: parseInt(data[0].devices_none) === 1 ? true : false,
            devices_ngt: parseInt(data[0].devices_ngt) === 1 ? true : false,
            devices_et: parseInt(data[0].devices_et) === 1 ? true : false,
            devices_ivf: parseInt(data[0].devices_ivf) === 1 ? true : false,
            devices_bt: parseInt(data[0].devices_bt) === 1 ? true : false,
            devices_drain: parseInt(data[0].devices_drain) === 1 ? true : false,
            devices_urine_catheter:
              parseInt(data[0].devices_urine_catheter) === 1 ? true : false,
            devices_others:
              parseInt(data[0].devices_others) === 1 ? true : false,
            pos_supine: parseInt(data[0].pos_supine) === 1 ? true : false,
            pos_prone: parseInt(data[0].pos_prone) === 1 ? true : false,
            pos_lateral: parseInt(data[0].pos_lateral) === 1 ? true : false,
            pos_lithotomy: parseInt(data[0].pos_lithotomy) === 1 ? true : false,
            pos_jackknife: parseInt(data[0].pos_jackknife) === 1 ? true : false,
            pos_others: parseInt(data[0].pos_others) === 1 ? true : false,
            elec_monopolar:
              parseInt(data[0].elec_monopolar) === 1
                ? true
                : parseInt(data[0].elec_monopolar) === 0
                ? false
                : "",
            elec_bipolar:
              parseInt(data[0].elec_bipolar) === 1
                ? true
                : parseInt(data[0].elec_bipolar) === 0
                ? false
                : "",
            elec_betadine_cleanser:
              parseInt(data[0].elec_betadine_cleanser) === 1 ? true : false,
            elec_betadine_antiseptec:
              parseInt(data[0].elec_betadine_antiseptec) === 1 ? true : false,
            elec_others: parseInt(data[0].elec_others) === 1 ? true : false,
            initial_sponges_correct:
              parseInt(data[0].sponges_correct) === 1 ? true : false,
            initial_sponges_lacking:
              parseInt(data[0].sponges_lacking) === 1 ? true : false,
            initial_needles_correct:
              parseInt(data[0].needles_correct) === 1 ? true : false,
            initial_needles_lacking:
              parseInt(data[0].needles_lacking) === 1 ? true : false,
            initial_instrument_correct:
              parseInt(data[0].instruments_correct) === 1 ? true : false,
            initial_instrument_lacking:
              parseInt(data[0].instruments_lacking) === 1 ? true : false,
            if_lacking_xray:
              parseInt(data[0].if_lacking_xray) === 1
                ? true
                : parseInt(data[0].if_lacking_xray) === 0
                ? false
                : "",
            wa_sponges_correct2:
              parseInt(data[0].sponges_correct2) === 1 ? true : false,
            wa_sponges_lacking2:
              parseInt(data[0].sponges_lacking2) === 1 ? true : false,
            wa_needles_correct2:
              parseInt(data[0].needles_correct2) === 1 ? true : false,
            wa_needles_lacking2:
              parseInt(data[0].needles_lacking2) === 1 ? true : false,
            wa_instrument_correct2:
              parseInt(data[0].instruments_correct2) === 1 ? true : false,
            wa_instrument_lacking2:
              parseInt(data[0].instruments_lacking2) === 1 ? true : false,
            wa_if_lacking_xray2:
              parseInt(data[0].if_lacking_xray2) === 1
                ? true
                : parseInt(data[0].if_lacking_xray2) === 0
                ? false
                : "",
            surgeon_notified_counts:
              parseInt(data[0].surgeon_notified_counts) === 1
                ? true
                : parseInt(data[0].surgeon_notified_counts) === 0
                ? false
                : "",
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSubmitSurgicalMemberChart = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", details.trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    formdata.set("type_general", checkList.type_general ? 1 : 0);
    formdata.set("type_spinal", checkList.type_spinal ? 1 : 0);
    formdata.set("type_epidural", checkList.type_epidural ? 1 : 0);
    formdata.set("type_local", checkList.type_local ? 1 : 0);
    formdata.set("type_others", checkList.type_others ? 1 : 0);

    formdata.set("devices_none", checkList.devices_none ? 1 : 0);
    formdata.set("devices_ngt", checkList.devices_ngt ? 1 : 0);
    formdata.set("devices_et", checkList.devices_et ? 1 : 0);
    formdata.set("devices_ivf", checkList.devices_ivf ? 1 : 0);
    formdata.set("devices_bt", checkList.devices_bt ? 1 : 0);
    formdata.set("devices_drain", checkList.devices_drain ? 1 : 0);
    formdata.set(
      "devices_urine_catheter",
      checkList.devices_urine_catheter ? 1 : 0
    );
    formdata.set("devices_others", checkList.devices_others ? 1 : 0);

    formdata.set("pos_supine", checkList.pos_supine ? 1 : 0);
    formdata.set("pos_prone", checkList.pos_prone ? 1 : 0);
    formdata.set("pos_lateral", checkList.pos_lateral ? 1 : 0);
    formdata.set("pos_lithotomy", checkList.pos_lithotomy ? 1 : 0);
    formdata.set("pos_jackknife", checkList.pos_jackknife ? 1 : 0);
    formdata.set("pos_others", checkList.pos_others ? 1 : 0);
    formdata.set(
      "elec_monopolar",
      checkList.elec_monopolar ? 1 : checkList.elec_monopolar === false ? 0 : ""
    );
    formdata.set(
      "elec_bipolar",
      checkList.elec_bipolar ? 1 : checkList.elec_bipolar === false ? 0 : ""
    );
    formdata.set(
      "elec_betadine_cleanser",
      checkList.elec_betadine_cleanser ? 1 : 0
    );
    formdata.set(
      "elec_betadine_antiseptec",
      checkList.elec_betadine_antiseptec ? 1 : 0
    );
    formdata.set("elec_others", checkList.elec_others ? 1 : 0);

    formdata.set(
      "initial_sponges_correct",
      checkList.initial_sponges_correct ? 1 : 0
    );
    formdata.set(
      "initial_sponges_lacking",
      checkList.initial_sponges_lacking ? 1 : 0
    );
    formdata.set(
      "initial_needles_correct",
      checkList.initial_needles_correct ? 1 : 0
    );
    formdata.set(
      "initial_needles_lacking",
      checkList.initial_needles_lacking ? 1 : 0
    );
    formdata.set(
      "initial_instrument_correct",
      checkList.initial_instrument_correct ? 1 : 0
    );
    formdata.set(
      "initial_instrument_lacking",
      checkList.initial_instrument_lacking ? 1 : 0
    );
    formdata.set(
      "if_lacking_xray",
      checkList.if_lacking_xray
        ? 1
        : checkList.if_lacking_xray === false
        ? 0
        : ""
    );

    formdata.set("wa_sponges_correct2", checkList.wa_sponges_correct2 ? 1 : 0);
    formdata.set("wa_sponges_lacking2", checkList.wa_sponges_lacking2 ? 1 : 0);
    formdata.set("wa_needles_correct2", checkList.wa_needles_correct2 ? 1 : 0);
    formdata.set("wa_needles_lacking2", checkList.wa_needles_lacking2 ? 1 : 0);
    formdata.set(
      "wa_instrument_correct2",
      checkList.wa_instrument_correct2 ? 1 : 0
    );
    formdata.set(
      "wa_instrument_lacking2",
      checkList.wa_instrument_lacking2 ? 1 : 0
    );
    formdata.set(
      "wa_if_lacking_xray2",
      checkList.wa_if_lacking_xray2
        ? 1
        : checkList.wa_if_lacking_xray2 === false
        ? 0
        : ""
    );
    formdata.set(
      "surgeon_notified_counts",
      checkList.surgeon_notified_counts
        ? 1
        : checkList.surgeon_notified_counts === false
        ? 0
        : ""
    );

    setIsProcess(true);
    axios
      .post("or/update/chart/surgicalmember", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          getSurgicalMemberChart();
          setEnableEdit(!enableEdit);
          Notify.successRequest("");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  useEffect(() => {
    getSurgicalMemberChart();
    // eslint-disable-next-line
  }, [patient_id, details]);

  return (
    <Box>
      <Card ref={printableRefOld} elevation={0}>
        <Box m={2}>
          {/* <Box>
            <FormHeader />
          </Box> */}
          <Box>
            <form onSubmit={handleSubmitSurgicalMemberChart}>
              <Box my={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          disabled={isProcess}
                          color="primary"
                          startIcon={<CheckSquare />}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            Edit
                          </Button>
                        </Box>
                      )}

                      {/* <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box> */}
                    </>
                  )}
                </Box>

                {/* <Box align="center" mb={2}>
                  <Typography>
                    <b> SURGICAL TEAM MEMBERS </b>
                  </Typography>
                </Box> */}

                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box align="center">
                        <Box mt={2}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bolder" }}
                          >
                            INTRAOPERATIVE RECORD
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box px={3}>
                        <Box mt={2}>
                          <b>DEVICES PLACED IN O.R</b>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    devices_none: !checkList.devices_none,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.devices_none === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              None
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    devices_ngt: !checkList.devices_ngt,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.devices_ngt === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              NGT
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    devices_et: !checkList.devices_et,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.devices_et === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              ET tube Size:
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    devices_ivf: !checkList.devices_ivf,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.devices_ivf === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              IVF
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Site:
                              </span>{" "}
                              {!enableEdit
                                ? existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .devices_ivf_site
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <>
                                <TextField
                                  fullWidth
                                  name="devices_ivf_site"
                                  defaultValue={
                                    existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .devices_ivf_site
                                      : ""
                                  }
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  multiline
                                />
                              </>
                            )}
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    devices_bt: !checkList.devices_bt,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.devices_bt === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              BT
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    devices_drain: !checkList.devices_drain,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.devices_drain === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Drain
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    devices_urine_catheter:
                                      !checkList.devices_urine_catheter,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.devices_urine_catheter === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Urine Catheter
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    devices_others: !checkList.devices_others,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.devices_others === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Others
                            </Typography>
                          </Box>
                        </Box>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].devices_none
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            None
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].devices_ngt
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            NGT
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].devices_et
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            ET tube Size:
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].devices_ivf
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            IVF
                          </Box>
                          <Box ml={2}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Site:
                              </span>{" "}
                              {!enableEdit
                                ? existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .devices_ivf_site
                                  : ""
                                : null}
                            </Typography>
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].devices_bt
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            BT
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].devices_drain
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Drain
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .devices_urine_catheter
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Urine Catheter
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .devices_others
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Others
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Box mt={2}>
                          <b>POSITION</b>
                        </Box>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].pos_supine
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Supine
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].pos_prone
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Prone
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].pos_lateral
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Lateral R/L
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].pos_lithotomy
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Lithotomy
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].pos_jackknife
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Jackknife
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].pos_others
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Others, specify
                          </Box>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    pos_supine: !checkList.pos_supine,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.pos_supine === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Supine
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    pos_prone: !checkList.pos_prone,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.pos_prone === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Prone
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    pos_lateral: !checkList.pos_lateral,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.pos_lateral === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Lateral R/L
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    pos_lithotomy: !checkList.pos_lithotomy,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.pos_lithotomy === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Lithotomy
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    pos_jackknife: !checkList.pos_jackknife,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.pos_jackknife === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Jackknife
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    pos_others: !checkList.pos_others,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.pos_others === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Others,specify
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Box mt={2}>
                          <b>ELECTROSURGICAL UNIT</b>
                        </Box>
                        <Box hidden={enableEdit}>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              variant="subtitle2"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Monopolar :
                            </Typography>
                            <Box>
                              {existingSurgicalMemChart.data.length > 0 &&
                                (parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .elec_monopolar
                                ) === 1 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                ))}
                              Yes
                            </Box>
                            <Box>
                              {existingSurgicalMemChart.data.length > 0 &&
                                (parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .elec_monopolar
                                ) === 0 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                ))}
                              No
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Grounding Pad:
                              </span>{" "}
                              {!enableEdit
                                ? existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .elec_grounding_pad
                                  : ""
                                : null}
                            </Typography>
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              variant="subtitle2"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Bipolar :
                            </Typography>
                            <Box>
                              {existingSurgicalMemChart.data.length > 0 &&
                                (parseInt(
                                  existingSurgicalMemChart.data[0].elec_bipolar
                                ) === 1 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                ))}
                              Yes
                            </Box>
                            <Box>
                              {existingSurgicalMemChart.data.length > 0 &&
                                (parseInt(
                                  existingSurgicalMemChart.data[0].elec_bipolar
                                ) === 0 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                ))}
                              No
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Skin Prep Site:
                              </span>{" "}
                              {!enableEdit
                                ? existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .elec_skin_prep_site
                                  : ""
                                : null}
                            </Typography>
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .elec_betadine_cleanser
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Betadine Cleanser
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .elec_betadine_antiseptec
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Betadine Antiseptic
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].elec_others
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Others
                          </Box>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              variant="subtitle2"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Monopolar :
                            </Typography>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      elec_monopolar: true,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.elec_monopolar === true ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                Yes
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      elec_monopolar: false,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.elec_monopolar === false ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                No
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Grounding Pad:
                              </span>{" "}
                              {!enableEdit
                                ? existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .elec_grounding_pad
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="elec_grounding_pad"
                                defaultValue={
                                  existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .elec_grounding_pad
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              variant="subtitle2"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Bipolar :
                            </Typography>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      elec_bipolar: true,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.elec_bipolar === true ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                Yes
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      elec_bipolar: false,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.elec_bipolar === false ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                No
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Skin Prep Site:
                              </span>{" "}
                              {!enableEdit
                                ? existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .elec_skin_prep_site
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <TextField
                                fullWidth
                                name="elec_skin_prep_site"
                                defaultValue={
                                  existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .elec_skin_prep_site
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            )}
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    elec_betadine_cleanser:
                                      !checkList.elec_betadine_cleanser,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.elec_betadine_cleanser === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Betadine Cleanser
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    elec_betadine_antiseptec:
                                      !checkList.elec_betadine_antiseptec,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.elec_betadine_antiseptec === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Betadine Antiseptic
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    elec_others: !checkList.elec_others,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.elec_others === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Others
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Initial Count Done By:
                          </span>{" "}
                          {!enableEdit
                            ? existingSurgicalMemChart.data.length > 0
                              ? existingSurgicalMemChart.data[0].initial_count
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="initial_count"
                              defaultValue={
                                existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .initial_count
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Box mt={2}>
                          <b>Counts</b>
                        </Box>
                        <Box display={"flex"} alignItems={"center"}>
                          <b>1st Count</b>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Box mt={2}>
                          <b>Sponges</b>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    initial_sponges_correct:
                                      !checkList.initial_sponges_correct,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.initial_sponges_correct === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Correct
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    initial_sponges_lacking:
                                      !checkList.initial_sponges_lacking,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.initial_sponges_lacking === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Lacking
                            </Typography>
                          </Box>
                        </Box>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .sponges_correct
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Correct
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .sponges_lacking
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Lacking
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Box mt={2}>
                          <b>Needles</b>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    initial_needles_correct:
                                      !checkList.initial_needles_correct,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.initial_needles_correct === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Correct
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    initial_needles_lacking:
                                      !checkList.initial_needles_lacking,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.initial_needles_lacking === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Lacking
                            </Typography>
                          </Box>
                        </Box>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .needles_correct
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Correct
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .needles_lacking
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Lacking
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Box mt={2}>
                          <b>Instruments</b>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    initial_instrument_correct:
                                      !checkList.initial_instrument_correct,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.initial_instrument_correct ===
                                true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Correct
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    initial_instrument_lacking:
                                      !checkList.initial_instrument_lacking,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.initial_instrument_lacking ===
                                true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Lacking
                            </Typography>
                          </Box>
                        </Box>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .instruments_correct
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Correct
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .instruments_lacking
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Lacking
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Box hidden={enableEdit}>
                          <Box display={"flex"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                If lacking, x-ray taken
                              </span>{" "}
                            </Typography>
                            <Box>
                              {existingSurgicalMemChart.data.length > 0 ? (
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .if_lacking_xray
                                ) === 1 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )
                              ) : (
                                <CheckBoxOutlineBlank />
                              )}
                              Correct
                            </Box>
                            <Box>
                              {existingSurgicalMemChart.data.length > 0 ? (
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .if_lacking_xray
                                ) === 0 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )
                              ) : (
                                <CheckBoxOutlineBlank />
                              )}
                              No, please explain
                            </Box>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="if_lacking_xray_no"
                              defaultValue={
                                existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .if_lacking_xray_no
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                            />
                          </Box>
                        </Box>

                        <Box hidden={!enableEdit}>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                If lacking, x-ray taken
                              </span>{" "}
                            </Typography>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      if_lacking_xray: true,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.if_lacking_xray === true ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                Correct
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      if_lacking_xray: false,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.if_lacking_xray === false ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                No, please explain
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="if_lacking_xray_no"
                              defaultValue={
                                existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .if_lacking_xray_no
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Box hidden={enableEdit}>
                          <Box display={"flex"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Counted by (SN)
                              </span>{" "}
                              <span
                                style={{
                                  textDecoration: "underline",
                                  fontSize: 14,
                                }}
                              >
                                {!enableEdit
                                  ? existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .if_lacking_xray_no_sn
                                    : "_________________"
                                  : null}
                              </span>{" "}
                              <br />
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Verified by (CN){" "}
                              </span>
                              <span
                                style={{
                                  textDecoration: "underline",
                                  fontSize: 14,
                                }}
                              >
                                {!enableEdit
                                  ? existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .if_lacking_xray_no_cn
                                    : "_________________"
                                  : null}
                              </span>{" "}
                            </Typography>
                          </Box>
                        </Box>

                        <Box hidden={!enableEdit}>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Counted by (SN)
                              </span>
                            </Typography>
                            <Box>
                              <TextField
                                fullWidth
                                name="if_lacking_xray_no_sn"
                                defaultValue={
                                  existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .if_lacking_xray_no_sn
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            </Box>

                            <Typography>
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Verified by (CN)
                              </span>{" "}
                            </Typography>

                            <Box>
                              <TextField
                                fullWidth
                                name="if_lacking_xray_no_cn"
                                defaultValue={
                                  existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .if_lacking_xray_no_cn
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Initial Count Done By:
                          </span>{" "}
                          {!enableEdit
                            ? existingSurgicalMemChart.data.length > 0
                              ? existingSurgicalMemChart.data[0]
                                  .initial_count_two
                              : ""
                            : null}
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="initial_count_two"
                              defaultValue={
                                existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .initial_count_two
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Box mt={2}>
                          <b>Counts</b>
                        </Box>
                        <Box display={"flex"} alignItems={"center"}>
                          <b>2nd Count</b>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Box mt={2}>
                          <b>Sponges</b>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    wa_sponges_correct2:
                                      !checkList.wa_sponges_correct2,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.wa_sponges_correct2 === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Correct
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    wa_sponges_lacking2:
                                      !checkList.wa_sponges_lacking2,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.wa_sponges_lacking2 === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Lacking
                            </Typography>
                          </Box>
                        </Box>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .sponges_correct2
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Correct
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .sponges_lacking2
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Lacking
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Box mt={2}>
                          <b>Needles</b>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    wa_needles_correct2:
                                      !checkList.wa_needles_correct2,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.wa_needles_correct2 === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Correct
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    wa_needles_lacking2:
                                      !checkList.wa_needles_lacking2,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.wa_needles_lacking2 === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Lacking
                            </Typography>
                          </Box>
                        </Box>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .needles_correct2
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Correct
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .needles_lacking2
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Lacking
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Box mt={2}>
                          <b>Instruments</b>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    wa_instrument_correct2:
                                      !checkList.wa_instrument_correct2,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.wa_instrument_correct2 === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Correct
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    wa_instrument_lacking2:
                                      !checkList.wa_instrument_lacking2,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.wa_instrument_lacking2 === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Lacking
                            </Typography>
                          </Box>
                        </Box>
                        <Box hidden={enableEdit}>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .instruments_correct2
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Correct
                          </Box>
                          <Box>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .instruments_lacking2
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Lacking
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Box hidden={enableEdit}>
                          <Box display={"flex"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                If lacking, x-ray taken
                              </span>{" "}
                            </Typography>
                            <Box>
                              {existingSurgicalMemChart.data.length > 0 ? (
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .if_lacking_xray2
                                ) === 1 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )
                              ) : (
                                <CheckBoxOutlineBlank />
                              )}
                              Correct
                            </Box>
                            <Box>
                              {existingSurgicalMemChart.data.length > 0 ? (
                                parseInt(
                                  existingSurgicalMemChart.data[0]
                                    .if_lacking_xray2
                                ) === 0 ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )
                              ) : (
                                <CheckBoxOutlineBlank />
                              )}
                              No, please explain
                            </Box>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="wa_if_lacking_xray_no2"
                              defaultValue={
                                existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .if_lacking_xray_no2
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                            />
                          </Box>
                        </Box>

                        <Box hidden={!enableEdit}>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                If lacking, x-ray taken
                              </span>{" "}
                            </Typography>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      wa_if_lacking_xray2: true,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.wa_if_lacking_xray2 === true ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                Correct
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant={"subtitle1"}>
                                <IconButton
                                  onClick={() =>
                                    setCheckList({
                                      ...checkList,
                                      wa_if_lacking_xray2: false,
                                    })
                                  }
                                  disabled={!enableEdit}
                                >
                                  {checkList.wa_if_lacking_xray2 === false ? (
                                    <CheckBox fontSize="small" />
                                  ) : (
                                    <CheckBoxOutlineBlankOutlined fontSize="small" />
                                  )}
                                </IconButton>
                                No, please explain
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="wa_if_lacking_xray_no2"
                              defaultValue={
                                existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .if_lacking_xray_no2
                                  : ""
                              }
                              InputProps={{
                                disableUnderline: !enableEdit,
                                disabled: !enableEdit,
                              }}
                              multiline
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Box hidden={enableEdit}>
                          <Box display={"flex"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Counted by (SN)
                              </span>{" "}
                              <span
                                style={{
                                  textDecoration: "underline",
                                  fontSize: 14,
                                }}
                              >
                                {!enableEdit
                                  ? existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .if_lacking_xray_no_sn2
                                    : "_________________"
                                  : null}
                              </span>{" "}
                              <br />
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Verified by (CN){" "}
                              </span>{" "}
                              <span
                                style={{
                                  textDecoration: "underline",
                                  fontSize: 14,
                                }}
                              >
                                {!enableEdit
                                  ? existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .if_lacking_xray_no_cn2
                                    : "_________________"
                                  : null}
                              </span>{" "}
                            </Typography>
                          </Box>
                        </Box>

                        <Box hidden={!enableEdit}>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Counted by (SN)
                              </span>
                            </Typography>
                            <Box>
                              <TextField
                                fullWidth
                                name="wa_if_lacking_xray_no_sn2"
                                defaultValue={
                                  existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .if_lacking_xray_no_sn2
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            </Box>

                            <Typography>
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Verified by (CN){" "}
                              </span>{" "}
                            </Typography>

                            <Box>
                              <TextField
                                fullWidth
                                name="wa_if_lacking_xray_no_cn2"
                                defaultValue={
                                  existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .if_lacking_xray_no_cn2
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Box mt={2}>
                          <Box hidden={enableEdit}>
                            <Box display={"flex"}>
                              <Typography variant="subtitle2">
                                <span
                                  style={{
                                    color: "#000",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Surgeon Notified of Counts
                                </span>{" "}
                              </Typography>
                              <Box>
                                {existingSurgicalMemChart.data.length > 0 ? (
                                  parseInt(
                                    existingSurgicalMemChart.data[0]
                                      .surgeon_notified_counts
                                  ) === 1 ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                                Yes
                              </Box>
                              <Box>
                                {existingSurgicalMemChart.data.length > 0 ? (
                                  parseInt(
                                    existingSurgicalMemChart.data[0]
                                      .surgeon_notified_counts
                                  ) === 0 ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                                No
                              </Box>
                            </Box>
                          </Box>

                          <Box hidden={!enableEdit}>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="subtitle2">
                                <span
                                  style={{
                                    color: "#000",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Surgeon Notified of Counts
                                </span>{" "}
                              </Typography>
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        surgeon_notified_counts: true,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.surgeon_notified_counts ===
                                    true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Yes
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        surgeon_notified_counts: false,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.surgeon_notified_counts ===
                                    false ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Box hidden={enableEdit}>
                          <Box display={"flex"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Special Endorsement/s
                              </span>{" "}
                              <span
                                style={{
                                  textDecoration: "underline",
                                  fontSize: 14,
                                }}
                              >
                                {!enableEdit
                                  ? existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .special_endorsement
                                    : "_________________"
                                  : null}
                              </span>{" "}
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Specimen, pls. specify
                              </span>{" "}
                              <span
                                style={{
                                  textDecoration: "underline",
                                  fontSize: 14,
                                }}
                              >
                                {!enableEdit
                                  ? existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0].specimen
                                    : "_________________"
                                  : null}
                              </span>{" "}
                            </Typography>
                          </Box>
                        </Box>

                        <Box hidden={!enableEdit}>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Special Endorsement/s
                              </span>
                            </Typography>
                            <Box>
                              <TextField
                                fullWidth
                                name="special_endorsement"
                                defaultValue={
                                  existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .special_endorsement
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            </Box>
                            <Typography>
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Specimen, pls. specify
                              </span>{" "}
                            </Typography>
                            <Box>
                              <TextField
                                fullWidth
                                name="specimen"
                                defaultValue={
                                  existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0].specimen
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Box hidden={enableEdit}>
                          <Box display={"flex"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Surgeon
                              </span>{" "}
                              <span
                                style={{
                                  textDecoration: "underline",
                                  fontSize: 14,
                                }}
                              >
                                {!enableEdit
                                  ? existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .surgeon_three
                                    : "_________________"
                                  : null}
                              </span>{" "}
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Scrub Nurse
                              </span>{" "}
                            </Typography>
                          </Box>
                        </Box>

                        <Box hidden={!enableEdit}>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Surgeon
                              </span>
                            </Typography>
                            <Box>
                              <TextField
                                fullWidth
                                name="surgeon_three"
                                defaultValue={
                                  existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .surgeon_three
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            </Box>
                            <Typography>
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Scrub Nurse
                              </span>{" "}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Box hidden={enableEdit}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Circulating
                              </span>{" "}
                              <span
                                style={{
                                  textDecoration: "underline",
                                  fontSize: 14,
                                }}
                              >
                                {!enableEdit
                                  ? existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .circulating
                                    : "_________________"
                                  : null}
                              </span>{" "}
                            </Typography>
                          </Box>
                        </Box>

                        <Box hidden={!enableEdit}>
                          <Box alignItems={"center"}>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Circulating
                              </span>
                            </Typography>
                            <Box>
                              <TextField
                                fullWidth
                                name="circulating"
                                defaultValue={
                                  existingSurgicalMemChart.data.length > 0
                                    ? existingSurgicalMemChart.data[0]
                                        .circulating
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                multiline
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box align="center">
                        <Box mt={2}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bolder" }}
                          >
                            <b> SURGICAL TEAM MEMBERS </b>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    In Room:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0].in_room
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="in_room"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .in_room
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                    <TextField
                                      fullWidth
                                      name="ccstm_id"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .ccstm_id
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      hidden
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Surgeon:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .surgeon_one
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="surgeon_one"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .surgeon_one
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Induction:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .induction
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="induction"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .induction
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Surgeon:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .surgeon_two
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="surgeon_two"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .surgeon_two
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Procedure Start:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .procedure_start
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="procedure_start"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .procedure_start
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Anesthesiologist:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .anesthesiologist
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="anesthesiologist"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .anesthesiologist
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Cutting Time:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .cutting_time
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="cutting_time"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .cutting_time
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Anesthesiologist:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .anesthesiologist_two
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="anesthesiologist_two"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .anesthesiologist_two
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Closing:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0].closing
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="closing"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .closing
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Scrub Nurse:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .scrub_nurse_one
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="scrub_nurse_one"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .scrub_nurse_one
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Procedure Finish:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .procedure_finish
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="procedure_finish"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .procedure_finish
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Scrub Nurse:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .scrub_nurse_two
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="scrub_nurse_two"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .scrub_nurse_two
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Out of Room:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .out_room
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="out_room"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .out_room
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Circulating Nurse:
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .circulating_nurse
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="circulating_nurse"
                                      defaultValue={
                                        existingSurgicalMemChart.data.length > 0
                                          ? existingSurgicalMemChart.data[0]
                                              .circulating_nurse
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                      multiline
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Box mt={2}>
                          <b>TYPE OF ANESTHESIA</b>
                        </Box>
                        <Box hidden={!enableEdit}>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    type_general: !checkList.type_general,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.type_general === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              General,please specify
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    type_spinal: !checkList.type_spinal,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.type_spinal === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Spinal
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    type_epidural: !checkList.type_epidural,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.type_epidural === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Epidural
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    type_local: !checkList.type_local,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.type_local === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Local
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant={"subtitle1"}>
                              <IconButton
                                onClick={() =>
                                  setCheckList({
                                    ...checkList,
                                    type_others: !checkList.type_others,
                                  })
                                }
                                disabled={!enableEdit}
                              >
                                {checkList.type_others === true ? (
                                  <CheckBox fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlined fontSize="small" />
                                )}
                              </IconButton>
                              Others
                            </Typography>
                          </Box>
                        </Box>
                        <Box hidden={enableEdit}>
                          <Box mt={1}>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].type_general
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            General,please specify
                          </Box>
                          <Box mt={1}>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].type_spinal
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Spinal
                          </Box>
                          <Box mt={1}>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].type_epidural
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Epidural
                          </Box>
                          <Box mt={1}>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].type_local
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Local
                          </Box>
                          <Box mt={1}>
                            {existingSurgicalMemChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingSurgicalMemChart.data[0].type_others
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Others
                          </Box>
                        </Box>
                        <Box mt={1}>
                          <Box>
                            <Typography variant="subtitle2">
                              <span
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                Anesthesia used:
                              </span>{" "}
                              {!enableEdit
                                ? existingSurgicalMemChart.data.length > 0
                                  ? existingSurgicalMemChart.data[0]
                                      .type_anesthesia_used
                                  : ""
                                : null}
                            </Typography>
                            {enableEdit && (
                              <>
                                <TextField
                                  fullWidth
                                  name="type_anesthesia_used"
                                  defaultValue={
                                    existingSurgicalMemChart.data.length > 0
                                      ? existingSurgicalMemChart.data[0]
                                          .type_anesthesia_used
                                      : ""
                                  }
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  multiline
                                />
                              </>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default FormNine;
