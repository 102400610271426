import {
  Box,
  TextField,
  Card,
  // CardHeader,
  Typography,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import List from "./List";

const LaboratoryInventory = () => {
  const [search, setSearch] = useState("");
  const [inventory, setInventory] = useState({ data: [], ready: false });
  const { users } = useContext(UsersData);

  const getInventory = useCallback(async () => {
    let response = await axios.get(
      "laboratory/test/items/inventory/list-inventory",
      {
        params: {
          user_id: users.user_id,
          management_id: users.management_id,
        },
      }
    );

    setInventory({ data: response.data, ready: true });
  }, [users]);

  useEffect(() => {
    getInventory();
  }, [getInventory]);

  return (
    <Card>
      {/* <CardHeader
        title={
          <Box display="flex" justifyContent={"center"} alignItems={"center"}>
            <Box flexGrow={1}>
              <Typography variant="h5" color="textSecondary">
                <strong>Inventory List</strong>
              </Typography>
            </Box>
            <Box>
              <TextField
                label="Search Brand, Description."
                variant="outlined"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        }
      /> */}
      <Box m={2}>
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          mb={1}
        >
          <Box flexGrow={1}>
            <Typography variant="h5" color="textSecondary">
              <strong>Inventory List</strong>
            </Typography>
          </Box>
          <Box>
            <TextField
              label="Search Brand, Description."
              variant="outlined"
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Divider light />
        <Box>
          <List inventory={inventory} search={search} />
        </Box>
      </Box>
    </Card>
  );
};

export default LaboratoryInventory;
