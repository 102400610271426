import React, { useCallback, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Card,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  Tabs,
  Tab,
} from "@material-ui/core";
import axios from "axios";
import MoreVert from "@material-ui/icons/MoreVert";
import {
  AddCircle,
  Cancel,
  RemoveCircle,
  CheckCircleOutline,
  HighlightOff,
} from "@material-ui/icons";
import TabPanel from "src/utils/TabPanel";
import BankDepositTable from "./BankDepositTable";
import BankWithdrawalTable from "./BankWithdrawalTable";
import { Fragment } from "react";

const BankTransactionDetails = ({ selectedBankId, resetDisplay, close }) => {
  const { users } = useContext(UsersData);
  const [bankDetails, setBankDetails] = useState({ data: [], ready: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const [depositDialog, setDepositDialog] = useState(false);
  const [withdrawDialog, setWithdrawDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [category, setCategory] = useState("deposit");
  const [selectedDepositType, setSelectedDepositType] = useState(null);
  const [selectedWithdrawalType, setSelectedWithdrawalType] = useState(null);
  const [depositList, setDepositList] = useState({ data: [], ready: false });
  const [withdrawalList, setWithdrawalList] = useState({
    data: [],
    ready: false,
  });

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  const getTransactionDetails = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      bank_id: selectedBankId,
    };
    try {
      let response = await axios.get("accounting/get/bank-details-by-id", {
        params,
      });
      const data = response.data;
      setBankDetails({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users, selectedBankId]);

  const getAllTableDeposit = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      bank_id: selectedBankId,
    };
    try {
      let response = await axios.get("accounting/get/deposit-list", { params });
      const data = response.data;
      setDepositList({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users, selectedBankId]);

  const getAllTableWithdrawal = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      bank_id: selectedBankId,
    };
    try {
      let response = await axios.get("accounting/get/withdrawal-list", {
        params,
      });
      const data = response.data;
      setWithdrawalList({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users, selectedBankId]);

  const handleBankAddDeposit = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("bank_id", selectedBankId);

    var error = [];

    if (
      formdata.get("date_deposit").length === 0 ||
      formdata.get("date_deposit").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Date");
    }
    if (
      formdata.get("deposit_type").length === 0 ||
      formdata.get("deposit_type").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Deposit Type");
    }

    if (formdata.get("deposit_type") === "Check") {
      if (
        formdata.get("check_date").length === 0 ||
        formdata.get("check_date").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("Check Date");
      }
      if (
        formdata.get("check_no").length === 0 ||
        formdata.get("check_no").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("Check Number");
      }
      if (
        formdata.get("bank").length === 0 ||
        formdata.get("bank").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("Bank Name");
      }
    }

    if (
      formdata.get("deposit_amount").length === 0 ||
      formdata.get("deposit_amount").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Deposit Amount");
    }
    if (
      formdata.get("deposit_by").length === 0 ||
      formdata.get("deposit_by").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Deposit By");
    }
    if (
      formdata.get("deposit_note").length === 0 ||
      formdata.get("deposit_note").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Deposit Note");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/add/new-deposit", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            resetDisplay();
            getTransactionDetails();
            getAllTableDeposit();
            setDepositDialog(false);
            e.target.reset();
            Notify.successRequest("add deposit");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("Password");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const handleBankAddWithdrawal = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("bank_id", selectedBankId);

    var error = [];

    if (
      formdata.get("date_withdrawal").length === 0 ||
      formdata.get("date_withdrawal").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Date");
    }

    if (
      formdata.get("withdrawal_type").length === 0 ||
      formdata.get("withdrawal_type").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Withdrawal Type");
    }

    if (formdata.get("withdrawal_type") === "Check") {
      if (
        formdata.get("check_date").length === 0 ||
        formdata.get("check_date").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("Check Date");
      }
      if (
        formdata.get("check_no").length === 0 ||
        formdata.get("check_no").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("Check Number");
      }
      if (
        formdata.get("bank").length === 0 ||
        formdata.get("bank").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("Bank Name");
      }
    }

    if (
      formdata.get("withdrawal_amount").length === 0 ||
      formdata.get("withdrawal_amount").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Withdrawal Amount");
    }

    if (
      formdata.get("withdrawal_note").length === 0 ||
      formdata.get("withdrawal_note").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Withdrawal Note");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/add/new-withdrawal", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            resetDisplay();
            getTransactionDetails();
            getAllTableWithdrawal();
            setWithdrawDialog(false);
            e.target.reset();
            Notify.successRequest("add withdrawal");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("Password");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    getTransactionDetails();
    getAllTableDeposit();
    getAllTableWithdrawal();
    // eslint-disable-next-line
  }, [
    getTransactionDetails,
    getAllTableDeposit,
    getAllTableWithdrawal,
    selectedBankId,
  ]);

  return (
    <>
      {bankDetails.ready && bankDetails.data.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box flexGrow={1}>
                <Typography variant="h5">
                  {bankDetails.data[0].bank_name}{" "}
                  {bankDetails.data[0].bank_address}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  aria-label="delete"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  {/* <FontAwesomeIcon icon={faChevronCircleDown} /> */}
                  <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={() => setDepositDialog(true)}>
                    <AddCircle color="primary" /> Deposit
                  </MenuItem>
                  <MenuItem onClick={() => setWithdrawDialog(true)}>
                    <RemoveCircle color="secondary" /> Withdraw
                  </MenuItem>
                  <MenuItem onClick={() => close()}>
                    <Cancel color="inherit" /> Close
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader
                title={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h4" color="primary">
                      ₱{" "}
                      {Notify.convertToNumber(
                        parseFloat(bankDetails.data[0].bank_deposit) -
                          parseFloat(bankDetails.data[0].bank_withdrawal)
                      )}
                    </Typography>
                  </Box>
                }
                subheader={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      style={{ fontWeight: "bolder" }}
                    >
                      BANK BALANCE
                    </Typography>
                  </Box>
                }
              />
            </Card>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader
                title={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h4" color="primary">
                      ₱{" "}
                      {Notify.convertToNumber(
                        parseFloat(bankDetails.data[0].bank_deposit)
                      )}
                    </Typography>
                  </Box>
                }
                subheader={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      style={{ fontWeight: "bolder" }}
                    >
                      DEPOSIT
                    </Typography>
                  </Box>
                }
              />
            </Card>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader
                title={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h4" color="primary">
                      ₱{" "}
                      {Notify.convertToNumber(
                        parseFloat(bankDetails.data[0].bank_withdrawal)
                      )}
                    </Typography>
                  </Box>
                }
                subheader={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      style={{ fontWeight: "bolder" }}
                    >
                      WITHDRAWAL
                    </Typography>
                  </Box>
                }
              />
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab value="deposit" label="Deposit" />
                <Tab value="withdrawal" label="Withdrawal" />
              </Tabs>
            </Paper>

            <Box>
              <TabPanel value={category} index="deposit">
                <BankDepositTable depositList={depositList} />
              </TabPanel>
              <TabPanel value={category} index="withdrawal">
                <BankWithdrawalTable withdrawalList={withdrawalList} />
              </TabPanel>
            </Box>
          </Grid>

          <Dialog
            open={depositDialog}
            onClose={() => setDepositDialog(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Add Bank Deposit</DialogTitle>
            <DialogContent>
              <form onSubmit={handleBankAddDeposit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        variant="outlined"
                        label="Date"
                        name="date_deposit"
                        fullWidth
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Deposit Type"
                        variant="outlined"
                        fullWidth
                        select
                        onChange={(e) => setSelectedDepositType(e.target.value)}
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="deposit_type"
                      >
                        <option value=""> -- Select -- </option>
                        <option value="Cash"> Cash </option>
                        <option value="Check"> Check </option>
                      </TextField>
                    </Box>
                  </Grid>

                  {selectedDepositType === "Check" && (
                    <Fragment>
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="date"
                            variant="outlined"
                            label="Check Date"
                            name="check_date"
                            fullWidth
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            fullWidth
                            name="check_no"
                            label="Check #"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            fullWidth
                            name="bank"
                            label="Bank Name"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    </Fragment>
                  )}

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        name="deposit_amount"
                        label="Amount"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 0.01,
                            max: 9999999999999,
                            step: 0.01,
                          },
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={selectedDepositType === "Check" ? 12 : 6}
                  >
                    <Box>
                      <TextField
                        fullWidth
                        name="deposit_by"
                        label="Deposit By"
                        variant="outlined"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box>
                      <TextField
                        fullWidth
                        name="deposit_note"
                        label="Note"
                        variant="outlined"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Confirm Password"
                        name="password"
                        variant="outlined"
                        type="password"
                      />
                    </Box>
                  </Grid>

                  <Box m={1} mb={2} mt={1} display="flex">
                    <Box flexGrow={1} />
                    <Button
                      variant="contained"
                      color="default"
                      type="reset"
                      startIcon={<HighlightOff />}
                      onClick={() => setDepositDialog(false)}
                    >
                      Close
                    </Button>

                    <Box ml={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={
                          isSubmitting ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <CheckCircleOutline />
                          )
                        }
                        disabled={isSubmitting}
                      >
                        Add
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </form>
            </DialogContent>
          </Dialog>

          <Dialog
            open={withdrawDialog}
            onClose={() => setWithdrawDialog(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Add Bank Withdrawal</DialogTitle>
            <DialogContent>
              <form onSubmit={handleBankAddWithdrawal}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        variant="outlined"
                        label="Date"
                        name="date_withdrawal"
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Withdrawal Type"
                        variant="outlined"
                        fullWidth
                        select
                        onChange={(e) =>
                          setSelectedWithdrawalType(e.target.value)
                        }
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="withdrawal_type"
                      >
                        <option value=""> -- Select -- </option>
                        <option value="Cash"> Cash </option>
                        <option value="Check"> Check </option>
                      </TextField>
                    </Box>
                  </Grid>

                  {selectedWithdrawalType === "Check" && (
                    <Fragment>
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="date"
                            variant="outlined"
                            label="Check Date"
                            name="check_date"
                            fullWidth
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            fullWidth
                            name="check_no"
                            label="Check #"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            fullWidth
                            name="bank"
                            label="Bank Name"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    </Fragment>
                  )}

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        name="withdrawal_amount"
                        label="Amount"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 0.01,
                            max: 9999999999999,
                            step: 0.01,
                          },
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={selectedWithdrawalType === "Check" ? 12 : 6}
                  >
                    <Box>
                      <TextField
                        fullWidth
                        name="bank_balance"
                        label="Bank Balance"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 0.01,
                            max: 9999999999999,
                            step: 0.01,
                          },
                          readOnly: true,
                        }}
                        value={
                          bankDetails.data[0].bank_deposit -
                          bankDetails.data[0].bank_withdrawal
                        }
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box>
                      <TextField
                        fullWidth
                        name="withdrawal_note"
                        label="Note"
                        variant="outlined"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Confirm Password"
                        name="password"
                        variant="outlined"
                        type="password"
                      />
                    </Box>
                  </Grid>

                  <Box m={1} mb={2} mt={1} display="flex">
                    <Box flexGrow={1} />
                    <Button
                      variant="contained"
                      color="inherit"
                      type="reset"
                      startIcon={<HighlightOff />}
                      onClick={() => setWithdrawDialog(false)}
                    >
                      Close
                    </Button>

                    <Box ml={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={
                          isSubmitting ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <CheckCircleOutline />
                          )
                        }
                        disabled={isSubmitting}
                      >
                        Add
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </form>
            </DialogContent>
          </Dialog>
        </Grid>
      )}
    </>
  );
};

export default BankTransactionDetails;
