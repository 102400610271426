import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
  DialogContent,
  TableContainer,
} from "@material-ui/core";
import React, { useContext, useCallback, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import { useReactToPrint } from "react-to-print";
import { CheckSquare, Edit, Printer, XCircle } from "react-feather";
import FormHeader from "./FormHeader";
import axios from "axios";
import {
  AddCircleOutline,
  Cancel,
  BorderColor,
  Delete,
  Add,
} from "@material-ui/icons";
import Notify from "src/notification/Notify";

const Formfifteen = ({ patient_id, details, info, doctorsList, allowEdit }) => {
  const printableRef = React.useRef();
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [isProcess, setIsProcess] = React.useState(false);
  const { users } = useContext(UsersData);
  const [isprocessCancel, setIsProcessCancel] = React.useState(false);
  const [existingJPDrainMonitorChart, setExistingJPDrainMonitoringChart] =
    React.useState({
      data: [],
      ready: false,
    });

  const [
    existingJPDrainMonitorChartTable,
    setExistingJPDrainMonitoringChartTable,
  ] = React.useState({
    data: [],
    ready: false,
  });

  const [ccjd_id, setccjd_id] = useState(null);
  const [addToTable, setAddToTable] = React.useState(false);

  const [cancelOrder, setCancelOrder] = React.useState({
    data: null,
    dialog: false,
  });

  const [editOrder, setEditOrder] = React.useState({
    data: null,
    dialog: false,
  });

  const [_inputNewJPDrainMonitor, setInputNewJPDrainMonitor] = React.useState({
    data: [],
    ready: true,
  });

  const [chartOpDoctor, setChartOpDoctor] = useState("");

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const getJPDrainMonitoringChart = useCallback(async () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/jpdrainmonitoring", { params })
      .then((response) => {
        const data = response.data;
        setExistingJPDrainMonitoringChart({ data, ready: true });
        setccjd_id(data[0].ccjd_id);
      })
      .catch((error) => console.log(error));
  }, [patient_id, users, details]);

  const getJPDrainMonitoringChartTable = useCallback(async () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/jpdrainmonitoringtable", { params })
      .then((response) => {
        const data = response.data;
        setExistingJPDrainMonitoringChartTable({ data, ready: true });
      })
      .catch((error) => console.log(error));
  }, [patient_id, users, details]);

  const addField = () => {
    setInputNewJPDrainMonitor({
      data: _inputNewJPDrainMonitor.data.concat({
        jp_date_time: "jp_date_time[]",
        volume_ml_cc: "volume_ml_cc[]",
      }),
      ready: true,
    });
  };

  const removeField = (index, length) => {
    const list = _inputNewJPDrainMonitor.data;
    setInputNewJPDrainMonitor({
      ..._inputNewJPDrainMonitor,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputNewJPDrainMonitor({
      data: list,
      ready: true,
    });
  };

  const handleSubmitNewJPDrainMonitoring = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", details.trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    formdata.set("status", ccjd_id === null ? "for-new" : "for-update");
    formdata.set("ccjd_id", ccjd_id);

    setIsProcess(true);
    axios
      .post("or/update/chart/jpdrainmonitoring", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          getJPDrainMonitoringChart();
          getJPDrainMonitoringChartTable();
          e.target.reset();
          Notify.successRequest("add data");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  // const checkSelectedDoctor = (selDoctor) => {
  //   if (doctorsList.length > 0) {
  //     let xx = doctorsList.filter((x) => x.doctors_id === selDoctor);
  //     if (xx.length > 0) {
  //       return xx[0].name;
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  const handleRemoveDrain = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);

    setIsProcessCancel(true);
    axios
      .post("or/remove/chart/jpdrainmonitoring", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setCancelOrder({ data: null, dialog: false });
          getJPDrainMonitoringChartTable();
          Notify.successRequest("monitoring cancel");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  const handleEditDrain = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);

    setIsProcessCancel(true);
    axios
      .post("or/edit/chart/jpdrainmonitoring", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setEditOrder({ data: null, dialog: false });
          getJPDrainMonitoringChartTable();
          Notify.successRequest("monitoring edit");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  const handleAddNote = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("patient_id", patient_id);
    formdata.set("ccjd_id", ccjd_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("management_id", users.management_id);
    formdata.set("trace_number", details.trace_number);

    setIsProcessCancel(true);
    axios
      .post("or/create/chart/jpdrainmonitoringtable", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          setAddToTable(false);
          getJPDrainMonitoringChartTable();
          Notify.successRequest("note created");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsProcessCancel(false));
  };

  useEffect(() => {
    getJPDrainMonitoringChart();
    getJPDrainMonitoringChartTable();
  }, [patient_id, getJPDrainMonitoringChart, getJPDrainMonitoringChartTable]);

  return (
    <Box>
      <Card ref={printableRef}>
        <Box m={2}>
          <Box mb={2}>
            <FormHeader />
          </Box>
          <Box>
            <form onSubmit={handleSubmitNewJPDrainMonitoring}>
              <Box my={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          disabled={isProcess}
                          color="primary"
                          startIcon={<CheckSquare />}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            SET
                          </Button>
                        </Box>
                      )}
                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box mb={4}>
                <Table size="small" className="table-bordered">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Name of Patient:
                          </span>{" "}
                        </Typography>

                        <Typography variant="subtitle2" hidden={enableEdit}>
                          {info
                            ? `${info.lastname}, ${info.firstname} ${
                                info.middle === null ? "" : info.middle
                              }`
                            : ""}
                        </Typography>

                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="patient_name"
                              defaultValue={
                                info
                                  ? `${info.lastname}, ${info.firstname} ${
                                      info.middle === null ? "" : info.middle
                                    }`
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2">
                          <span style={{ color: "#000", fontWeight: "bolder" }}>
                            Doctor:{" "}
                          </span>
                          <span className="text-capitalize">
                            {!enableEdit
                              ? existingJPDrainMonitorChart.data.length > 0
                                ? // checkSelectedDoctor(
                                  //     existingJPDrainMonitorChart.data[0].doctor
                                  existingJPDrainMonitorChart.data[0].doctor
                                : ""
                              : null}
                          </span>
                        </Typography>
                        {enableEdit && (
                          <>
                            <TextField
                              fullWidth
                              name="doctor"
                              value={chartOpDoctor}
                              InputProps={{
                                readOnly: !enableEdit,
                              }}
                              onChange={(e) => setChartOpDoctor(e.target.value)}
                            />

                            {/* <TextField
                              fullWidth
                              name="doctor"
                              value={chartOpDoctor}
                              InputProps={{
                                disableUnderline: !enableEdit,
                                style: {
                                  textTransform: "capitalize",
                                },
                              }}
                              select
                              SelectProps={{ native: true }}
                              InputLabelProps={{ shrink: true }}
                              readOnly={!enableEdit}
                              onChange={(e) => setChartOpDoctor(e.target.value)}
                            >
                              <option value=""> Select </option>
                              {doctorsList.length > 0 ? (
                                doctorsList.map((data, key) => (
                                  <option key={key} value={data.doctors_id}>
                                    {data.name}
                                  </option>
                                ))
                              ) : (
                                <option value=""> No doctor found. </option>
                              )}
                            </TextField> */}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box align="center" mb={2}>
                <Box>
                  <Typography>
                    <b> JP DRAIN MONITORING SHEET </b>
                  </Typography>
                </Box>

                <Box display={"flex"} hidden={!enableEdit}>
                  <Box flexGrow={1} />
                  <Box mx={1}>
                    <Button
                      color="primary"
                      onClick={addField}
                      startIcon={<AddCircleOutline />}
                    >
                      ADD
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      color="secondary"
                      onClick={
                        _inputNewJPDrainMonitor.data.length > 0
                          ? () =>
                              removeField(
                                1,
                                _inputNewJPDrainMonitor.data.length
                              )
                          : (e) => e.preventDefault()
                      }
                      startIcon={<Cancel />}
                    >
                      REMOVE
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  display={"flex"}
                  className="d-print-none"
                  hidden={enableEdit}
                >
                  <Box flexGrow={1} />
                  <Box>
                    <IconButton
                      color={"primary"}
                      onClick={() => setAddToTable(true)}
                    >
                      <Add fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <TableContainer>
                  <Table size="small" className="table-bordered">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <strong>DATE</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>TIME</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>VOLUME IN ML/CC</strong>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-print-none"
                          hidden={enableEdit}
                        >
                          <strong>ACTION</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {enableEdit &&
                        _inputNewJPDrainMonitor.ready &&
                        _inputNewJPDrainMonitor.data.map((data, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell colSpan={2}>
                                <TextField
                                  name={data.jp_date_time}
                                  type="datetime-local"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  fullWidth
                                  required
                                />
                              </TableCell>
                              <TableCell colSpan={2}>
                                <TextField
                                  fullWidth
                                  name={data.volume_ml_cc}
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                  multiline
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}

                      {!enableEdit &&
                        existingJPDrainMonitorChartTable.ready &&
                        existingJPDrainMonitorChartTable.data.length > 0 &&
                        existingJPDrainMonitorChartTable.data.map(
                          (data, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {`${Notify.createdAt(data.jp_date_time)}`}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="subtitle2">
                                  {`${Notify.createdTime(data.jp_date_time)}`}
                                </Typography>
                              </TableCell>
                              <TableCell>{data.volume_ml_cc}</TableCell>
                              <TableCell
                                align="center"
                                className="d-print-none"
                              >
                                <Box display={"flex"} justifyContent={"center"}>
                                  <Box>
                                    <IconButton
                                      color={"primary"}
                                      onClick={() =>
                                        setEditOrder({
                                          data: data,
                                          dialog: true,
                                        })
                                      }
                                    >
                                      <BorderColor fontSize={"small"} />
                                    </IconButton>
                                  </Box>
                                  <Box>
                                    <IconButton
                                      color={"secondary"}
                                      onClick={() =>
                                        setCancelOrder({
                                          data: data,
                                          dialog: true,
                                        })
                                      }
                                    >
                                      <Delete fontSize={"small"} />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </form>
          </Box>
        </Box>
      </Card>

      <Dialog
        open={cancelOrder.dialog}
        onClose={() => setCancelOrder({ data: null, dialog: false })}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
      >
        {cancelOrder.data && cancelOrder.dialog && (
          <form onSubmit={handleRemoveDrain}>
            <DialogTitle>Are you sure to remove this data?</DialogTitle>
            <TextField
              label={"Monitor ID"}
              variant="outlined"
              name="ccjdt_id"
              defaultValue={cancelOrder.data.ccjdt_id}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              hidden
            />
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setCancelOrder({ data: null, dialog: false })}
              >
                No
              </Button>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isprocessCancel}
                startIcon={
                  isprocessCancel && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      <Dialog
        open={editOrder.dialog}
        onClose={() => setEditOrder({ data: null, dialog: false })}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
      >
        {editOrder.data && editOrder.dialog && (
          <form onSubmit={handleEditDrain}>
            <DialogTitle>Edit note</DialogTitle>
            <DialogContent>
              <Box mb={2}>
                <TextField
                  label={"Monitor ID"}
                  variant="outlined"
                  name="ccjdt_id"
                  defaultValue={editOrder.data.ccjdt_id}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  hidden
                />

                <TextField
                  label={"Date/Time"}
                  name="jp_date_time"
                  defaultValue={
                    editOrder.data.jp_date_time !== null
                      ? editOrder.data.jp_date_time.replace(" ", "T")
                      : ""
                  }
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Box>

              <Box mb={2}>
                <TextField
                  label={"Volume in ML/CC"}
                  variant="outlined"
                  name="volume_ml_cc"
                  defaultValue={
                    editOrder.data.volume_ml_cc !== null
                      ? editOrder.data.volume_ml_cc
                      : ""
                  }
                  fullWidth
                  multiline
                  required
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setEditOrder({ data: null, dialog: false })}
              >
                No
              </Button>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isprocessCancel}
                startIcon={
                  isprocessCancel && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                Yes
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      <Dialog
        open={addToTable}
        onClose={() => setAddToTable(false)}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
      >
        <form onSubmit={handleAddNote}>
          <DialogTitle>Add note</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <TextField
                label={"Date/Time"}
                name="jp_date_time"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                variant="outlined"
              />
            </Box>
            <Box mb={2}>
              <TextField
                label={"Volume in ML/CC"}
                variant="outlined"
                name="volume_ml_cc"
                fullWidth
                multiline
                required
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setAddToTable(false)}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isprocessCancel}
              startIcon={
                isprocessCancel && (
                  <CircularProgress size={20} color="inherit" />
                )
              }
            >
              Yes
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default Formfifteen;
