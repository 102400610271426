import React, { useState, useRef } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography,
	Zoom,
	Dialog,
	Avatar,
	FormControl,
	FormControlLabel,
	FormGroup,
	Checkbox,
	Card,
	CardHeader,
	CardContent,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { UsersData, UsersHeader } from "src/ContextAPI";
import { isMobile } from "react-device-detect";
import { Send } from "@material-ui/icons";
import Cropper from "react-cropper";
import axios from "axios";
import { CheckSquare, XCircle } from "react-feather";
import { useHistory } from "react-router-dom";

const NewPatientForm = ({ getDoctorsPatient }) => {
	const { users } = React.useContext(UsersData);
	const { sidebarHeader } = React.useContext(UsersHeader);
	const cropperRef = useRef();
	const [selectedSickness, setSelectedSickness] = useState([]);
	const [imgUploadTemp, setImgUploadTemp] = useState(null);
	const [image, setImage] = useState(null);
	const [openCropImage, setOpenCropImage] = useState(false);
	const [newSubmitting, setNewSubmitting] = useState(false);
	const history = useHistory();
	const [selectedTransaction, setSelectedTransaction] = useState("");

	const sickness = [
		"Dry cough",
		"Fever",
		"Shortness of breath",
		"Difficulty of breathing",
		"Sore throat",
		"Headache",
		"Nasal congestions",
		"Diarrhea",
		"Colds",
		"Fatigue or body malaise",
	];

	const handleChangeSickness = (e) => {
		let test = selectedSickness;
		if (test.includes(e.target.value)) {
			let testx = test.filter((item) => item !== e.target.value);
			setSelectedSickness(testx);
		} else {
			setSelectedSickness(test.concat(e.target.value));
		}
	};

	const handleSubmitNewPatient = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("management_id", users.management_id);
		formdata.set("main_mgmt_id", users.main_mgmt_id);
		formdata.set("sickness", selectedSickness);
		formdata.set("triage_staff", sidebarHeader.name);
		formdata.set(
			"trace_number",
			`trace-${Math.floor(Math.random() * 9999)}-${new Date().getTime()}`
		);

		var error = [];
		if (
			formdata.get("lastname").length === 0 ||
			formdata.get("lastname").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Lastname");
		}
		if (
			formdata.get("firstname").length === 0 ||
			formdata.get("firstname").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Firstname");
		}
		if (
			formdata.get("gender").length === 0 ||
			formdata.get("gender").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Gender");
		}
		if (
			formdata.get("birthday").length === 0 ||
			formdata.get("birthday").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Birth Date");
		}
		if (
			formdata.get("street").length === 0 ||
			formdata.get("street").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Street");
		}
		if (
			formdata.get("barangay").length === 0 ||
			formdata.get("barangay").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Barangay");
		}
		if (
			formdata.get("city").length === 0 ||
			formdata.get("city").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("City");
		}
		if (
			formdata.get("occupation").length === 0 ||
			formdata.get("occupation").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Occupation");
		}
		if (
			formdata.get("civil_status").length === 0 ||
			formdata.get("civil_status").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Civil Status");
		}
		if (
			formdata.get("religion").length === 0 ||
			formdata.get("religion").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Religion");
		}
		if (
			formdata.get("mobile").length === 0 ||
			formdata.get("mobile").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Mobile");
		}
		if (
			formdata.get("temp").length === 0 ||
			formdata.get("temp").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Temperature");
		}
		if (
			formdata.get("purpose_ofvisit").length === 0 ||
			formdata.get("purpose_ofvisit").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Purpose of visit");
		}
		if (
			formdata.get("transaction_type").length === 0 ||
			formdata.get("transaction_type").trim() === ""
		) {
			error = "error";
			Notify.fieldRequired("Transaction type");
		}
		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setNewSubmitting(true);
			axios
				.post("admission/patients/newpatient-save", formdata)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						e.target.reset();
						setImage(null);
						setNewSubmitting(false);
						getDoctorsPatient();
						Notify.successRequest("new patient added.");
						history.push("/sph/app/registration/queuing-list");
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				});
		}
	};

	return (
		<>
			<form onSubmit={handleSubmitNewPatient}>
				<Box my={1}>
					<Grid container spacing={2}>
						<Grid component={Box} hidden={image === null} item xs={12} sm={4}>
							<Box
								width={"100%"}
								height={
									isMobile
										? 200
										: window.innerWidth <= 959
										? 200
										: window.innerWidth <= 898
										? 200
										: 194
								}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								{image !== null && (
									<Avatar
										style={{
											width: isMobile
												? 180
												: window.innerWidth <= 959
												? 180
												: window.innerWidth <= 898
												? 180
												: 160,
											height: isMobile
												? 180
												: window.innerWidth <= 959
												? 180
												: window.innerWidth <= 898
												? 180
												: 160,
										}}
										alt="profile-pic"
										src={image}
									/>
								)}
							</Box>
						</Grid>

						<Grid item xs={12} sm={image === null ? 12 : 8}>
							<Box my={1}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="lastname"
												label={
													<span>
														Lastname
														<b style={{ color: "red", marginLeft: 2 }}>*</b>
													</span>
												}
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="firstname"
												label={
													<span>
														Firstname
														<b style={{ color: "red", marginLeft: 2 }}>*</b>
													</span>
												}
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="middlename"
												label="Middle Name"
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="gender"
												label={
													<span>
														Gender
														<b style={{ color: "red", marginLeft: 2 }}>*</b>
													</span>
												}
												variant="outlined"
												select
												SelectProps={{
													native: true,
												}}
												InputLabelProps={{
													shrink: true,
												}}
											>
												<option value={""}>Select</option>
												<option value={"Male"}>Male</option>
												<option value={"Female"}>Female</option>
											</TextField>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="birthday"
												label={
													<span>
														Birth Date
														<b style={{ color: "red", marginLeft: 2 }}>*</b>
													</span>
												}
												variant="outlined"
												type="date"
												InputLabelProps={{
													shrink: true,
												}}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="birthplace"
												label="Birth Place"
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="street"
												label={
													<span>
														House/Lot & Block No. and Street
														<b style={{ color: "red", marginLeft: 2 }}>*</b>
													</span>
												}
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="barangay"
												label={
													<span>
														Barangay
														<b style={{ color: "red", marginLeft: 2 }}>*</b>
													</span>
												}
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="city"
												label={
													<span>
														City
														<b style={{ color: "red", marginLeft: 2 }}>*</b>
													</span>
												}
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="occupation"
												label={
													<span>
														Occupation
														<b style={{ color: "red", marginLeft: 2 }}>*</b>
													</span>
												}
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="civil_status"
												label={
													<span>
														Civil Status
														<b style={{ color: "red", marginLeft: 2 }}>*</b>
													</span>
												}
												variant="outlined"
												select
												SelectProps={{
													native: true,
												}}
												InputLabelProps={{
													shrink: true,
												}}
											>
												<option value={""}>Select</option>
												<option value={"Single"}>Single</option>
												<option value={"Married"}>Married</option>
												<option value={"Divorced"}>Divorced</option>
											</TextField>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="religion"
												label={
													<span>
														Religion
														<b style={{ color: "red", marginLeft: 2 }}>*</b>
													</span>
												}
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="mobile"
												label={
													<span>
														Mobile
														<b style={{ color: "red", marginLeft: 2 }}>*</b>
													</span>
												}
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="telephone"
												label="Telephone"
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="email"
												label="Email"
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={6}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="philhealth"
												label="Philhealth"
												variant="outlined"
											/>
										</Box>
									</Grid>

									<Grid item xs={12} sm={6}>
										<Box>
											<TextField
												margin="normal"
												fullWidth
												name="temp"
												label={
													<span>
														Temperature
														<b style={{ color: "red", marginLeft: 2 }}>*</b>
													</span>
												}
												variant="outlined"
												type="number"
												InputProps={{
													inputProps: {
														min: 1,
														max: 10000,
														step: 0.01,
													},
												}}
											/>
										</Box>
									</Grid>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Box>
					<Box my={3}>
						<Box mb={2}>
							<Typography variant="h6">
								<strong>Contact Tracing Form</strong>
							</Typography>
						</Box>

						<Grid container spacing={1}>
							{sickness.map((data, index) => (
								<Grid key={index} item xs={12} sm={6} md={4}>
									<Box>
										<FormControl component="fieldset">
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															name="gilad"
															value={data}
															onChange={(e) => handleChangeSickness(e)}
														/>
													}
													label={data}
												/>
											</FormGroup>
										</FormControl>
									</Box>
								</Grid>
							))}
						</Grid>
					</Box>
				</Box>

				<Box mb={2}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Box>
								<TextField
									margin="normal"
									label={
										<span>
											Purpose of Visit/Medical Concerns/issues
											<b style={{ color: "red", marginLeft: 2 }}>*</b>
										</span>
									}
									variant="outlined"
									fullWidth
									name="purpose_ofvisit"
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<Box>
								<TextField
									margin="normal"
									label={"Any Allergies"}
									fullWidth
									variant="outlined"
									name="allergies"
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<Box>
								<TextField
									margin="normal"
									label={"Last Check Xray Taken"}
									fullWidth
									variant="outlined"
									name="last_xray_check"
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<Box>
								<TextField
									margin="normal"
									label={"Result of Cxray"}
									fullWidth
									variant="outlined"
									name="last_xray_result"
								/>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Box mb={2}>
					<Typography>
						<strong>History of Travel: When and where</strong>
					</Typography>
				</Box>

				<Box mb={2}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4} md={4}>
							<Box>
								<TextField
									margin="normal"
									fullWidth
									name="history_oftravel_date"
									label="Date"
									variant="outlined"
									type="date"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<Box>
								<TextField
									margin="normal"
									fullWidth
									name="history_oftravel_days"
									label="Days"
									variant="outlined"
									type="number"
									InputProps={{
										inputProps: {
											min: 1,
											max: 10000,
											step: 1,
										},
									}}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<Box>
								<TextField
									margin="normal"
									label={"Place"}
									fullWidth
									variant="outlined"
									multiline
									name="history_oftravel_place"
								/>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Box mb={2}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6}>
							<Box>
								<TextField
									margin="normal"
									label={"Any contact with PUI to PUM"}
									fullWidth
									variant="outlined"
									select
									SelectProps={{ native: true }}
									InputLabelProps={{ shrink: true }}
									name="contact_withpuipum"
								>
									<option value=""> SELECT </option>
									<option value="No"> No </option>
									<option value="Yes"> Yes </option>
								</TextField>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<Box>
								<TextField
									margin="normal"
									label={"Any contact with positive covid patient"}
									fullWidth
									variant="outlined"
									select
									SelectProps={{ native: true }}
									InputLabelProps={{ shrink: true }}
									name="contact_withpositive"
								>
									<option value=""> SELECT </option>
									<option value="No"> No </option>
									<option value="Yes"> Yes </option>
								</TextField>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Box>
					<Typography>
						<strong>For CRT Clients:</strong>
					</Typography>
				</Box>

				<Box my={2}>
					<TextField
						margin="normal"
						label={"Purpose of your request for CRT."}
						fullWidth
						variant="outlined"
						multiline
						name="crt_purpose"
					/>
				</Box>

				<Box my={2}>
					<TextField
						margin="normal"
						label={"Requested by"}
						fullWidth
						variant="outlined"
						multiline
						name="requested_by"
					/>
				</Box>

				<Box my={2}>
					<TextField
						margin="normal"
						label={"If for return to work, complete company name"}
						fullWidth
						variant="outlined"
						multiline
						name="complete_company"
					/>
				</Box>

				<Box my={2}>
					<TextField
						margin="normal"
						label={"Complete contact person, designation & numbers"}
						fullWidth
						variant="outlined"
						multiline
						name="contact_information"
					/>
				</Box>

				<Box>
					<Typography>
						<strong>
							If found to be positive of either IgG or IgM, are you willing to
							undergo quarantine or Swab testing testing (RT-PCR)?
						</strong>
					</Typography>
				</Box>

				<Box my={2}>
					<TextField
						margin="normal"
						label={"Select"}
						fullWidth
						variant="outlined"
						select
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						name="quarantine_ifpositive"
					>
						<option value=""> SELECT </option>
						<option value="No"> No </option>
						<option value="Yes"> Yes </option>
					</TextField>
				</Box>

				<Box my={3}>
					<Typography>
						<strong>
							I hereby authorize GTC(Global Telemedicine Corp.) to collect and
							process the data indicated herein for the purpose of effecting
							conroil of the COVID 19 infection. I understand that my personal
							information is protected by RA 10173, Data PrivacyAct of 2012 and
							that I am required by RA 11469 Bayanihan to Heal as One Act. to
							provide truthful information.
						</strong>
					</Typography>
				</Box>

				<Box my={2}>
					<TextField
						margin="normal"
						label={
							<span>
								Transaction Type
								<b style={{ color: "red", marginLeft: 2 }}>*</b>
							</span>
						}
						fullWidth
						variant="outlined"
						select
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						name="transaction_type"
						value={selectedTransaction}
						onChange={(e) => setSelectedTransaction(e.target.value)}
					>
						<option value=""> SELECT </option>
						<option value="reg-cashier">Walk-In(lab/x-ray)</option>
						<option value="reg-doctor"> Walk-In Appointment </option>
					</TextField>
				</Box>

				<Box my={2} hidden={selectedTransaction === "reg-cashier"}>
					<TextField
						margin="normal"
						label={
							<span>
								Sent to
								<b style={{ color: "red", marginLeft: 2 }}>*</b>
							</span>
						}
						fullWidth
						variant="outlined"
						select
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						name="patient_sent_to"
					>
						<option value=""> SELECT </option>
						<option value="opd-patient"> OPD Patient </option>
						<option value="er-patient"> ER Patient </option>
					</TextField>
				</Box>
				{/* 
				<Box mb={2} display="flex">
					<Box flexGrow={1} />

					<Button
						variant="contained"
						color="default"
						type="reset"
						startIcon={<XCircle />}
					>
						Clear
					</Button>
					<Box ml={2}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							startIcon={
								newSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<CheckSquare />
								)
							}
							disabled={newSubmitting}
						>
							Save
						</Button>
					</Box>
				</Box> */}
			</form>

			<Dialog
				open={openCropImage}
				fullScreen
				onClose={() => setOpenCropImage(false)}
				TransitionComponent={Zoom}
				transitionDuration={900}
			>
				<Box
					display="flex"
					width="100%"
					height="80vh"
					justifyContent="center"
					alignItems="center"
				>
					<Cropper
						style={{ height: "100%", width: "100%" }}
						zoomTo={0.5}
						aspectRatio={1}
						src={imgUploadTemp}
						viewMode={1}
						guides={false}
						background={false}
						responsive={true}
						dragMode={"move"}
						cropBoxResizable={true}
						checkOrientation={false}
						rotatable
						scalable
						ref={cropperRef}
					/>
				</Box>
				<Box
					display="flex"
					width="100%"
					height="20vh"
					justifyContent="center"
					alignItems="center"
					border={1}
				>
					<Button
						color="primary"
						variant="contained"
						startIcon={<Send />}
						onClick={() => {
							const imageElement = cropperRef.current;
							const cropper = imageElement.cropper;
							setImage(cropper.getCroppedCanvas().toDataURL());
							setImgUploadTemp(null);
							setOpenCropImage(false);
						}}
					>
						Confirm
					</Button>
				</Box>
			</Dialog>
		</>
	);
};

export default NewPatientForm;
