import {
	faDoorOpen,
	faUsers,
	faVirus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import Img from "src/components/Img";
import LayoutContainer from "src/components/LayoutContainer";
import ConfirmationModal from "src/components/modal/ConfirmationModal";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import { localArraySearch } from "src/helpers/utils";
import LaboratoryTestFormModal from "./components/LaboratoryTestFormModal";

const LaboratoryTests = () => {
	const [list, setList] = useState([]);
	const [keyword, setKeyword] = useState("");
	const labTestFormModalRef = useRef(null);
	const confirm_delete_ref = useRef(null);
	const {
		data,
		loading,
		updateInList,
		addToList,
		removeFromList,
		column,
		setColumn,
		direction,
		setDirection,
		setPaginate,
	} = useDataTable("/v1/laboratory/tests/list", setList, {});
	useEffect(() => {
		setPaginate(200);
	}, []);
	useEffect(() => {
		if (data?.data) {
			setList(data?.data || []);
		}
	}, [data]);

	const deleteData = (id) => {
		return axios.post(`/v1/laboratory/tests/delete/${id}`, {
			_method: "DELETE",
		});
	};

	const columns = [
		{
			header: "Name",
			accessorKey: "name",
			headerClassName: "text-left",
			className: "text-left",
		},
		{
			header: "Description",
			accessorKey: "description",
			headerClassName: "text-left",
			className: "text-left",
		},
		{
			header: "Type",
			accessorKey: "type",
			headerClassName: "text-left",
			className: "text-left",
		},
		{
			header: "Action",
			accessorKey: "action",
			cell: (data) => {
				return (
					<div className="flex items-center gap-2">
						<ActionBtn
							size="sm"
							type="primary-light"
							onClick={() => {
								console.log("datadata", data);
								labTestFormModalRef.current.show(data);
							}}
						>
							<FlatIcon icon="rr-edit" className="text-lg mr-2" />
							Edit
						</ActionBtn>
						<ActionBtn
							size="sm"
							type="danger-light"
							onClick={() => {
								confirm_delete_ref.current.show({
									title: "Delete record",
									message: "Are you sure to delete record?",
									footer: ({ loadingBtn, setLoadingBtn }) => {
										return (
											<div className="flex items-center gap-4 w-full justify-end">
												<ActionBtn
													size="sm"
													type="primary-gradient"
													onClick={() => {
														confirm_delete_ref.current.hide();
													}}
												>
													No
												</ActionBtn>
												<ActionBtn
													size="sm"
													type="danger"
													loading={loadingBtn}
													onClick={() => {
														setLoadingBtn(true);
														deleteData(
															data.id
														).then((res) => {
															setTimeout(() => {
																setLoadingBtn(
																	false
																);
																removeFromList(
																	data
																);
																toast.success(
																	"Clinic personnel deleted successfully!"
																);
															}, 200);
															confirm_delete_ref.current.hide();
														});
													}}
												>
													Confirm delete
												</ActionBtn>
											</div>
										);
									},
								});
							}}
						>
							<FlatIcon
								icon="rr-trash"
								className="text-lg mr-2"
							/>
							Delete
						</ActionBtn>
					</div>
				);
			},
		},
	];
	return (
		<LayoutContainer
			icon={
				<FontAwesomeIcon icon={faDoorOpen} color="white" size={"2x"} />
			}
			title="Laboratory Tests Management"
			subtitle="Add/Edit/Delete Laboratory Tests here."
		>
			<CardContainer
				containerClassName={` border border- border-opacity-10 w-full`}
				header={{
					title: "Laboratory Tests List data",
					children: (
						<div className="ml-auto flex items-center gap-3">
							<ActionBtn
								className="!no-underline !h-[42px]"
								size="sm"
								onClick={() => {
									labTestFormModalRef.current.show();
								}}
							>
								<FlatIcon icon="rr-plus" />
								<span className="!no-underline">Add</span>
							</ActionBtn>
							<TextInputField
								inputClassName=" pl-10"
								className="w-full lg:w-[320px]"
								onChange={(e) => {
									setKeyword(e.target.value);
								}}
								icon={
									<FlatIcon
										icon="rr-search"
										className="text-sm"
									/>
								}
								placeholder="Search"
							/>
						</div>
					),
				}}
				className="!p-0"
			>
				<div className="max-h-[calc(100vh-328px)] overflow-auto">
					<TableV2
						paginationClassName="px-4"
						columns={columns}
						direction={direction}
						setDirection={setDirection}
						pagination={false}
						loading={loading}
						column={column}
						setColumn={setColumn}
						data={
							keyword?.length > 0
								? localArraySearch(list, keyword)
								: list
						}
						onTableChange={(data) => {}}
						theadClassName={`sticky top-[-1px]`}
					/>
				</div>
			</CardContainer>
			<LaboratoryTestFormModal
				ref={labTestFormModalRef}
				addToList={addToList}
				updateInList={updateInList}
			/>

			<ConfirmationModal ref={confirm_delete_ref} />
		</LayoutContainer>
	);
};

export default LaboratoryTests;
