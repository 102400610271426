import {
  Card,
  CardContent,
  Box,
  CardHeader,
  Typography,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  TextField,
  CardActions,
  CircularProgress,
} from "@material-ui/core";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BedsList from "./BedsList";
import { CheckSquare, XCircle } from "react-feather";
import Notify from "src/notification/Notify";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const AdmitPatient = () => {
  const { trace_number, patient_id } = useParams();
  const { users } = useContext(UsersData);
  const [list, setList] = useState([]);
  const [roomNumberList, setRoomNumberList] = useState([]);
  const [roomNumberListRd, setRoomNumberListRd] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [isProcess, setIsProcess] = useState(false);

  const history = useHistory();

  const mounted = IsMountedRef();
  const classes = useStyles();

  const [selectedRoom, setSelectedRoom] = useState(null);

  const [dialog, setDialog] = useState({
    open: false,
    details: null,
    selectedBedNo: null,
  });

  const [reasons, setReasons] = useState("");
  const [md, setMd] = useState("");
  const [nurseType, setNurseType] = useState("");

  const getRoomList = React.useCallback(async () => {
    let { data } = await axios.get(`admitting/rooms/get-roomlist`, {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setList(data);
    }
  }, [mounted, users]);

  const getRoomNumberList = (room) => {
    setRoomNumberListRd(true);
    axios
      .get(`admitting/rooms/get-room-number-list`, {
        params: {
          management_id: users.management_id,
          room_id: room.room_id,
        },
      })
      .then(({ data }) => {
        setRoomNumberList(data);
        setSelectedRoom(room);
      })
      .catch((err) => console.log(`err:`, err.message))
      .finally(() => setRoomNumberListRd(false));
  };

  const handleAdmitPatients = () => {
    var formdata = new FormData();
    formdata.set("trace_number", trace_number);
    formdata.set("patient_id", patient_id);
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);

    formdata.set("management_id", users.management_id);
    formdata.set("room_id", dialog?.details?.room_id);
    formdata.set("room_number", dialog?.details?.room_number);
    formdata.set("room_bed_number", dialog?.selectedBedNo?.bed_number);
    formdata.set("bill_amount", dialog?.selectedBedNo?.amount);
    formdata.set("bill_name", selectedRoom?.room_name);

    formdata.set("md", md);
    formdata.set("nurse_type", nurseType);
    formdata.set("reason", reasons);

    let error = [];

    if (!Boolean(reasons)) {
      error = "error";
      Notify.fieldRequired("reasons");
    }

    if (!Boolean(nurseType)) {
      error = "error";
      Notify.fieldRequired("nurse_type");
    }

    if (!Boolean(md)) {
      error = "error";
      Notify.fieldRequired("md");
    }

    if (error.length > 0) {
      console.log(`Form has an error.`);
    } else {
      setIsProcess(true);

      axios
        .post(`admitting/rooms/new-admitpatient`, formdata)
        .then(({ data }) => {
          if (data.message === "success") {
            Notify.successRequest("Patient admitted");
            history.push(`/sph/app/admitting`);
          }
        })
        .catch((err) => console.log(`err:`, err.message))
        .finally(() => setIsProcess(false));
    }
  };

  const getDoctorsList = React.useCallback(async () => {
    let response = await axios.get("cashier/add-on/doctor/list-ofdoctors", {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setDoctors(response.data);
    }
  }, [mounted, users]);

  useEffect(() => {
    getRoomList();
    getDoctorsList();
  }, [getRoomList, getDoctorsList]);

  return (
    <>
      <Fragment>
        <Container
          breadcrumbs={{
            enable: true,
            current: "Patients For Admit",
            items: [{ name: "Dashboard", path: "/sph/app" }],
          }}
          title="Patients For Admit"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Select Room" />

                <CardContent>
                  {roomNumberListRd && (
                    <Typography align="center" color="primary">
                      Loading...
                    </Typography>
                  )}

                  {list.length > 0 ? (
                    list.map((data, key) => (
                      <Fragment key={key}>
                        <Accordion
                          elevation={0}
                          expanded={data.id === selectedRoom?.id}
                          onChange={() => {
                            if (data.id === selectedRoom?.id) {
                              setSelectedRoom(null);
                              return;
                            }
                            getRoomNumberList(data);
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${data.id}a-content`}
                            id={`pane${data.id}1a-header`}
                          >
                            <Typography className={classes.heading}>
                              {data.room_name}
                            </Typography>
                          </AccordionSummary>

                          <AccordionDetails>
                            <Box width={"100%"}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <Box>
                                    <Typography>{`No of rooms`}</Typography>
                                    <Typography>{data.no_of_rooms}</Typography>
                                  </Box>
                                </Grid>
                              </Grid>

                              <Box my={2}>
                                <Box mb={1}>
                                  <Typography>
                                    <strong> {`Room List`} </strong>
                                  </Typography>
                                </Box>

                                {roomNumberList.length > 0 ? (
                                  roomNumberList.map((__data, __key) => (
                                    <Box mb={2} key={__key} display="flex">
                                      <Box flexGrow={1}>
                                        <Box display={"flex"}>
                                          <Box mr={1}>
                                            <Typography>
                                              {`Room number:`}
                                            </Typography>
                                          </Box>

                                          <Box>
                                            <Typography>
                                              {__data.room_number}
                                            </Typography>
                                          </Box>
                                        </Box>

                                        <Box display={"flex"}>
                                          <Box mr={1}>
                                            <Typography>
                                              {`No of beds:`}
                                            </Typography>
                                          </Box>

                                          <Box>
                                            <Typography>
                                              {__data.no_of_beds}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>

                                      <Box>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={() => {
                                            setDialog({
                                              open: true,
                                              details: __data,
                                              selectedBedNo: null,
                                            });
                                          }}
                                        >
                                          Select
                                        </Button>
                                      </Box>
                                    </Box>
                                  ))
                                ) : (
                                  <Typography color="secondary">
                                    No room found.
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Fragment>
                    ))
                  ) : (
                    <Typography color="secondary">No room found.</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>

            {dialog?.selectedBedNo !== null && (
              <Grid item xs={12}>
                <Card>
                  <CardHeader title="Room Details" />
                  <CardContent>
                    <Box>
                      <Typography>
                        Selected Room : {dialog?.details?.room_id}
                      </Typography>

                      <Typography>
                        Selected Bed Number :{" "}
                        {dialog?.selectedBedNo?.bed_number}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>

                <Box mt={3}>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader title="Admit Reason" />
                      <CardContent>
                        <Box>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            placeholder={"Select Medical Doctor"}
                            select
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={md}
                            onChange={(e) => setMd(e.target.value)}
                            label="Select MD"
                          >
                            <option value=""> Select </option>
                            {doctors.length > 0 ? (
                              doctors.map((___data, ___key) => (
                                <option value={___data.user_id} key={___key}>
                                  {___data.name}
                                </option>
                              ))
                            ) : (
                              <option value=""> No doctor found. </option>
                            )}
                          </TextField>
                        </Box>

                        <Box>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            placeholder={"Select Nurse Station"}
                            select
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={nurseType}
                            onChange={(e) => setNurseType(e.target.value)}
                            label="Select Nurse Department"
                          >
                            <option value=""> Select </option>
                            <option value="room-department">Room Nurse</option>
                            <option value="ward-department">Ward Nurse</option>
                          </TextField>
                        </Box>

                        <Box>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={6}
                            placeholder={"Enter reason of admission"}
                            onChange={(e) => setReasons(e.target.value)}
                            value={reasons}
                          />
                        </Box>
                      </CardContent>

                      <CardActions>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={
                            isProcess ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              <CheckSquare />
                            )
                          }
                          onClick={() => handleAdmitPatients()}
                          disabled={isProcess}
                        >
                          Save
                        </Button>

                        <Button
                          variant="contained"
                          color="default"
                          startIcon={<XCircle />}
                          onClick={() => history.push(`/sph/app/admitting`)}
                        >
                          No
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>

          {/* beds list on selected room */}
          <Dialog
            open={dialog.open}
            fullWidth
            maxWidth={"xs"}
            onClose={() => {
              setDialog({
                open: false,
                details: null,
                selectedBedNo: null,
              });
            }}
          >
            {dialog.details && (
              <BedsList
                details={dialog.details}
                setDialog={setDialog}
                dialog={dialog}
              />
            )}
          </Dialog>
        </Container>
      </Fragment>
    </>
  );
};

export default AdmitPatient;
