import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

export default function Popup({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box>
          <Typography variant="h6" color="textSecondary">
            <strong>BMI Basic Table</strong>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <div>
          <Grid item xs={12} sm={12}>
            <Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"> BMI </TableCell>
                      <TableCell align="center"> ASSESSMENT </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow hover>
                      <TableCell align="center"> Below 18.5 </TableCell>
                      <TableCell align="center"> Underweight </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell align="center"> 18.5 - 25 </TableCell>
                      <TableCell align="center"> Healthy </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell align="center"> 25 - 30 </TableCell>
                      <TableCell align="center"> Overweight </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell align="center"> 30 - 40 </TableCell>
                      <TableCell align="center"> Obese </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell align="center"> 40+ </TableCell>
                      <TableCell align="center"> Morbidly Obese </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
