import React, {
	Fragment,
	useContext,
	useEffect,
	useState,
	useRef,
} from "react";
import {
	Box,
	Grid,
	Typography,
	TextField,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	TablePagination,
	InputAdornment,
	Paper,
	Dialog,
	DialogContent,
	Zoom,
	Button,
	IconButton,
} from "@material-ui/core";
import Notify from "../../notification/Notify";
import Axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import PrintIcon from "@material-ui/icons/Print";
import { UsersData } from "../../ContextAPI";
import Container from "../../layout/Container";
import ReceiptPrintModal from "./ReceiptPrintModal";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { Printer } from "react-feather";

const dateFormat = "YYYY-MM-DD";

function Receipt() {
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [page, setPage] = useState(0);
	const [receipt, setReceipt] = useState([]);
	const [receiptReady, setReceiptReady] = useState(false);
	const [search, setSearch] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [selectedReceipt, setSelectedReceipt] = useState(null);
	const userData = useContext(UsersData);

	const [dateFrom, setDateFrom] = useState(new Date());
	const [dateTo, setDateTo] = useState(new Date());

	const printableRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => printableRef.current,
	});

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 50));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const fetchReceiptList = () => {
		var params = {
			user_id: userData.users.user_id,
			management_id: userData.users.management_id,
			dateFrom: moment(dateFrom, dateFormat).format(dateFormat),
			dateTo: moment(dateTo, dateFormat).format(dateFormat),
		};
		setReceiptReady(false);
		Axios.get("pharmacy/get-receipt-list", { params })
			.then((response) => {
				const data = response.data;
				setReceipt(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			})
			.finally(() => setReceiptReady(true));
	};

	useEffect(() => {
		fetchReceiptList();
		// eslint-disable-next-line
	}, []);

	const searchable = receipt.filter((data) => {
		return (
			data.name_customer.toLowerCase().indexOf(search.trim()) !== -1 ||
			data.receipt_id.toLowerCase().indexOf(search.trim()) !== -1
		);
	});

	const handledateFilter = () => {
		if (
			!moment(moment(dateTo)).isSame(moment(dateFrom)) &&
			moment(moment(dateTo)).isBefore(moment(dateFrom))
		) {
			Notify.customToast(`Invalid date`, "Selected date is invalid.");
			return;
		}

		fetchReceiptList();
	};

	return (
		<Fragment>
			<Container
				breadcrumbs={{
					enable: true,
					current: "receipt",
					items: [{ name: "Dashboard", path: "/sph/app" }],
				}}
				title="Report"
			>
				<Grid container>
					<Grid item sm={12} xs={12}>
						<Paper>
							<Box pt={3}>
								<Typography variant="h4" align="center">
									PHARMACY CONSUMPTION REPORT
								</Typography>

								{/* <Typography variant="h6" align="center">
                  PHARMACY CONSUMPTION REPORT
                </Typography> */}

								<Typography variant="h6" align="center">
									<strong> MAUBAN HEALTH OFFICE </strong>
								</Typography>
							</Box>

							<Box>
								<Box display={window.innerWidth > 700 ? "flex" : ""} my={3}>
									<Box flexGrow={1} mx={1}>
										<TextField
											label="Search client or receipt"
											variant="outlined"
											margin="dense"
											value={search}
											onChange={(e) => setSearch(e.target.value)}
											InputProps={{
												endAdornment: (
													<InputAdornment>
														<SearchIcon />
													</InputAdornment>
												),
											}}
										/>
									</Box>

									<Box mx={1}>
										<DatePicker
											fullWidth
											inputVariant="outlined"
											margin="dense"
											value={dateFrom}
											onChange={(date) => setDateFrom(date)}
										/>
									</Box>

									<Box mx={1}>
										<DatePicker
											fullWidth
											inputVariant="outlined"
											margin="dense"
											value={dateTo}
											onChange={(date) => setDateTo(date)}
										/>
									</Box>

									<Box mx={1} mt={1.3}>
										<Button
											fullWidth
											variant="contained"
											color="primary"
											onClick={() => handledateFilter()}
										>
											Go
										</Button>
									</Box>

									<Box mx={1} mt={1.3}>
										<Button
											onClick={() => handlePrint()}
											variant="contained"
											color="primary"
											startIcon={<Printer />}
											disabled={searchable.length === 0}
										>
											Print
										</Button>
									</Box>
								</Box>
							</Box>

							<Box m={1} ref={printableRef}>
								<Box pt={3} display="none" displayPrint="block">
									<Typography variant="h4" align="center">
										PHARMACY CONSUMPTION REPORT
									</Typography>

									<Typography variant="h6" align="center">
										REPORT DATE: From{" "}
										{moment(dateFrom, dateFormat).format(dateFormat)} to{" "}
										{moment(dateTo, dateFormat).format(dateFormat)}
									</Typography>

									<Typography variant="h6" align="center">
										<strong> MAUBAN HEALTH OFFICE </strong>
									</Typography>
								</Box>

								<TableContainer component={Box}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell> Transaction Date </TableCell>
												<TableCell> Patient </TableCell>
												<TableCell> Prescribed Drug </TableCell>
												<TableCell> Brand </TableCell>
												<TableCell> Unit/s Dispensed </TableCell>
												<TableCell> Unit Cost </TableCell>
												<TableCell> Total Cost </TableCell>
												<TableCell className="hide-on-print">
													{" "}
													Action{" "}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{receiptReady ? (
												searchable.length > 0 ? (
													searchable
														.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map((data, index) => (
															<TableRow key={index}>
																<TableCell>
																	{Notify.dateTimeConvert(data.created_at)}
																</TableCell>
																<TableCell> {data.name_customer} </TableCell>
																<TableCell>{data.description}</TableCell>
																<TableCell align="right">
																	{data.product}
																</TableCell>

																<TableCell align="right">
																	{Notify.numberFormat(data.quantity)}
																</TableCell>

																<TableCell align="right">
																	{Notify.numberFormat(data.srp)}
																</TableCell>

																<TableCell align="right">
																	{Notify.numberFormat(data.total_cost)}
																</TableCell>

																<TableCell
																	align="center"
																	className="hide-on-print"
																>
																	<IconButton
																		color={
																			selectedReceipt === data.receipt_id
																				? "secondary"
																				: "primary"
																		}
																		onClick={() => {
																			setSelectedReceipt(data.receipt_id);
																			setOpenModal(true);
																		}}
																	>
																		<PrintIcon />
																	</IconButton>
																</TableCell>
															</TableRow>
														))
												) : (
													<TableRow>
														<TableCell colSpan={7} align="center">
															<Typography variant="subtitle2" color="secondary">
																{" "}
																No receipt added{" "}
															</Typography>
														</TableCell>
													</TableRow>
												)
											) : (
												<TableRow>
													<TableCell colSpan={7} align="center">
														<Typography variant="subtitle2" color="primary">
															{" "}
															Loading...{" "}
														</Typography>
													</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>

									<TablePagination
										component={"div"}
										rowsPerPageOptions={[50, 100, 250]}
										colSpan={3}
										count={searchable.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onChangePage={handleChangePage}
										onChangeRowsPerPage={handleChangeRowsPerPage}
									/>
								</TableContainer>
							</Box>
							<Dialog
								open={openModal}
								fullScreen
								disableBackdropClick
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
								TransitionComponent={Zoom}
								transitionDuration={1000}
							>
								<DialogContent>
									<ReceiptPrintModal
										closeModal={() => setOpenModal(false)}
										selectedReceipt={selectedReceipt}
									/>
								</DialogContent>
							</Dialog>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</Fragment>
	);
}

export default Receipt;
