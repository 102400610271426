import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TextField,
  IconButton,
} from "@material-ui/core";
import FormHeader from "./FormHeader";
import { useReactToPrint } from "react-to-print";
import { XCircle, Printer, CheckSquare, Edit, Square } from "react-feather";
import Notify from "src/notification/Notify";
import { DatePicker } from "@material-ui/pickers";

import axios from "axios";
import { UsersData } from "src/ContextAPI";
import moment from "moment";
import IsMountedRef from "src/utils/IsMountedRef";

const FormTwo = ({ patient_id, trace_number, info, allowEdit }) => {
  const printableRef = React.useRef();
  const [enableEdit, setEnableEdit] = useState(false);
  const { users } = React.useContext(UsersData);

  const [chartForwardedTo, setChartForwardedTo] = useState("");
  const [chartCashTrans, setChartCashTrans] = useState(null);

  const [chartCtas, setChartCtas] = useState("");
  const [chartNationality, setChartNationality] = useState("");

  const [chartPatientCategory, setChartPatientCategory] = useState("");

  const [chartEmployer, setChartEmployer] = useState({
    employeer: "",
    address: "",
    contact: "",
  });

  const [chartFather, setChartFather] = useState({
    father: "",
    address: "",
    contact: "",
  });

  const [chartMother, setChartMother] = useState({
    mother: "",
    address: "",
    contact: "",
  });
  const [chartPCase, setChartPCase] = useState("");
  const [chartHospitalNo, setChartHospitalNo] = useState("");
  const [chartAttPhysician, setChartAttPhysician] = useState("");
  const [chartRefferedBy, setChartRefferedBy] = useState("");
  const [chartAllergy, setChartAllergy] = useState("");
  const [chartHmoCoverage, setChartHmoCoverage] = useState("");
  const [chartInsurance, setChartInsurance] = useState("");
  // const [chartDateFurnished, setChartDateFurnished] = useState(null);
  const [chartAddOfInformant, setChartAddOfInformant] = useState("");
  const [chartNameOfInformant, setChartNameOfInformant] = useState("");
  const [chartInformantContactNo, setChartOfInformantContactNo] = useState("");
  const [chartRelationPatient, setChartRelationPatient] = useState("");
  const [chartChiefComplaint, setChartChiefComplaint] = useState("");
  const [chartDiagnosis, setChartDiagnosis] = useState("");
  const [chartSurgicalProcedures, setChartSurgicalProcedures] = useState("");
  const [chartOtherProcedures, setChartOtherProcedures] = useState("");
  const [chartOtherProceduresDisposition, setChartOtherProceduresDisposition] =
    useState("");
  const [chartOtherProceduresResults, setChartOtherProceduresResults] =
    useState("");
  const [chartOtherProceduresBiopsy, setChartOtherProceduresBiopsy] =
    useState("");

  const [isProcess, setIsProcess] = useState(false);
  const [chartRd, setChartRd] = useState(false);
  const [chartType, setChartType] = useState("for-save");
  const mounted = IsMountedRef();

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const getInformationSheet = React.useCallback(() => {
    axios
      .get("or/charts/patient/patient-getinformationsheet", {
        params: {
          patient_id,
          trace_number,
        },
      })
      .then((response) => {
        let data = response.data;
        if (mounted.current) {
          if (data.length) {
            let data = response.data[0];
            setChartType("for-update");
            handleUpdateDefaultState(data);
          }
        }
      })
      .catch((err) => console.log("error: ", err.message))
      .finally(() => setChartRd(true));
  }, [patient_id, trace_number, mounted]);

  const handleUpdateDefaultState = (data) => {
    setChartPCase(data.case_no === null ? "" : data.case_no);
    setChartHospitalNo(data.hospital_no === null ? "" : data.hospital_no);

    setChartForwardedTo(data.forwarded_to === null ? "" : data.forwarded_to);
    setChartCashTrans(
      data.cash_trans_date === null ? "" : data.cash_trans_date
    );

    setChartCtas(data.ctas === null ? "" : data.ctas);
    setChartNationality(data.nationality === null ? "" : data.nationality);

    setChartPatientCategory(
      data.patient_category === null ? "" : data.patient_category
    );

    setChartEmployer({
      employeer: data.employer === null ? "" : data.employer,
      address: data.employer_address === null ? "" : data.employer_address,
      contact: data.employer_no === null ? "" : data.employer_no,
    });

    setChartFather({
      father: data.fathers === null ? "" : data.fathers,
      address: data.fathers_address === null ? "" : data.fathers_address,
      contact: data.fathers_contact === null ? "" : data.fathers_contact,
    });

    setChartMother({
      mother: data.mothers === null ? "" : data.mothers,
      address: data.mothers_address === null ? "" : data.mothers_address,
      contact: data.mothers_contact === null ? "" : data.mothers_contact,
    });
    setChartAttPhysician(
      data.attending_physician === null ? "" : data.attending_physician
    );
    setChartRefferedBy(data.reffered_by === null ? "" : data.reffered_by);
    setChartAllergy(data.allergic_to === null ? "" : data.allergic_to);
    setChartHmoCoverage(
      data.hosipitalization_hmo_coverage === null
        ? ""
        : data.hosipitalization_hmo_coverage
    );
    setChartInsurance(data.insurance === null ? "" : data.insurance);
    // setChartDateFurnished(
    //   data.date_furnished === null ? "" : data.date_furnished
    // );
    setChartNameOfInformant(
      data.name_of_informant === null ? "" : data.name_of_informant
    );
    setChartAddOfInformant(
      data.address_of_informant === null ? "" : data.address_of_informant
    );
    setChartOfInformantContactNo(
      data.informant_contact_no === null ? "" : data.informant_contact_no
    );
    setChartRelationPatient(
      data.relation_to_patient === null ? "" : data.relation_to_patient
    );
    setChartChiefComplaint(
      data.chief_complaint === null ? "" : data.chief_complaint
    );
    setChartDiagnosis(data.diagnosis === null ? "" : data.diagnosis);
    setChartSurgicalProcedures(
      data.surgical_procedures === null ? "" : data.surgical_procedures
    );
    setChartOtherProcedures(
      data.other_operations === null ? "" : data.other_operations
    );
    setChartOtherProceduresDisposition(
      data.other_operations_disposition === null
        ? ""
        : data.other_operations_disposition
    );
    setChartOtherProceduresResults(
      data.other_operations_results === null
        ? ""
        : data.other_operations_results
    );
    setChartOtherProceduresBiopsy(
      data.other_operations_biopsy === null ? "" : data.other_operations_biopsy
    );
  };

  const handleNewInformation = (e) => {
    e.preventDefault();
    e.persist();

    let err = [];
    let form = new FormData(e.target);
    form.append("user_id", users.user_id);
    form.append("management_id", users.management_id);
    form.append("main_mgmt_id", users.main_mgmt_id);
    form.append("patient_id", patient_id);
    form.append("trace_number", trace_number);

    form.append("forwarded_to", chartForwardedTo);
    form.append(
      "cash_trans_date",
      moment(chartCashTrans).format("YYYY-MM-DD HH:mm:ss")
    );

    form.append("case_no", chartPCase);
    form.append("hospital_no", chartHospitalNo);

    form.append("ctas", chartCtas);
    form.append("nationality", chartNationality);

    form.append("patient_category", chartPatientCategory);

    form.append("employer", chartEmployer.employeer);
    form.append("employer_address", chartEmployer.address);
    form.append("employer_no", chartEmployer.contact);

    form.append("fathers", chartFather.father);
    form.append("fathers_address", chartFather.address);
    form.append("fathers_contact", chartFather.contact);

    form.append("mothers", chartMother.mother);
    form.append("mothers_address", chartMother.address);
    form.append("mothers_contact", chartMother.contact);

    form.append("attending_physician", chartAttPhysician);
    form.append("reffered_by", chartRefferedBy);
    form.append("allergic_to", chartAllergy);
    form.append("hosipitalization_hmo_coverage", chartHmoCoverage);

    form.append("insurance", chartInsurance);
    // form.append(
    //   "date_furnished",
    //   moment(chartDateFurnished).format("YYYY-MM-DD HH:mm:ss")
    // );
    form.append("name_of_informant", chartNameOfInformant);
    form.append("address_of_informant", chartAddOfInformant);
    form.append("informant_contact_no", chartInformantContactNo);
    form.append("relation_to_patient", chartRelationPatient);
    form.append("chief_complaint", chartChiefComplaint);
    form.append("diagnosis", chartDiagnosis);
    form.append("surgical_procedures", chartSurgicalProcedures);
    form.append("other_operations", chartOtherProcedures);
    form.append("chart_type", chartType);

    form.append(
      "other_operations_disposition",
      chartOtherProceduresDisposition
    );
    form.append("other_operations_results", chartOtherProceduresResults);
    form.append("other_operations_biopsy", chartOtherProceduresBiopsy);

    if (
      form.get("chief_complaint").trim() === "" ||
      form.get("chief_complaint").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("chief_complaint");
    }

    if (
      form.get("diagnosis").trim() === "" ||
      form.get("diagnosis").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("diagnosis");
    }

    if (
      form.get("surgical_procedures").trim() === "" ||
      form.get("surgical_procedures").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("surgical procedures");
    }

    if (err.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcess(true);
      axios
        .post("or/charts/patient/patient-informationsheet", form)
        .then((response) => {
          let data = response.data;
          if (data.message === "success") {
            getInformationSheet();
            Notify.successRequest("information updated");
          }
        })
        .catch((err) => console.log("error:", err.message))
        .finally(() => setIsProcess(false));
    }
  };

  useEffect(() => {
    getInformationSheet();
  }, [getInformationSheet]);

  return (
    <Card ref={printableRef}>
      <CardContent>
        <Box>
          <FormHeader />
        </Box>

        {chartRd ? (
          <form onSubmit={handleNewInformation}>
            <Box>
              <Box my={2}>
                <Typography align="center">
                  <b> INFORMATION SHEET </b>
                </Typography>
              </Box>

              <Box my={3}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                  mb={1}
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          color="primary"
                          startIcon={
                            isProcess ? (
                              <CircularProgress size={22} color="inherit" />
                            ) : (
                              <CheckSquare />
                            )
                          }
                          type="submit"
                          disabled={isProcess}
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            Edit
                          </Button>
                        </Box>
                      )}

                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box my={2}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Box display="flex">
                      <Box mr={1}>
                        {" "}
                        <Typography> PCase No: </Typography>{" "}
                      </Box>
                      {enableEdit ? (
                        <TextField
                          label=""
                          value={chartPCase}
                          fullWidth
                          onChange={(e) => setChartPCase(e.target.value)}
                        />
                      ) : (
                        <Typography style={{ marginLeft: "5px" }}>
                          {chartPCase}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box display="flex">
                      <Box mr={1}>
                        {" "}
                        <Typography> Forwarded To: </Typography>{" "}
                      </Box>
                      {enableEdit ? (
                        <TextField
                          label=""
                          value={chartForwardedTo}
                          fullWidth
                          onChange={(e) => setChartForwardedTo(e.target.value)}
                        />
                      ) : (
                        <Typography style={{ marginLeft: "5px" }}>
                          {chartForwardedTo}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box display="flex">
                      <Box mr={1}>
                        {" "}
                        <Typography> Hospital No: </Typography>{" "}
                      </Box>
                      {enableEdit ? (
                        <TextField
                          label=""
                          value={chartHospitalNo}
                          fullWidth
                          onChange={(e) => setChartHospitalNo(e.target.value)}
                        />
                      ) : (
                        <Typography style={{ marginLeft: "5px" }}>
                          {chartHospitalNo}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box display="flex">
                      <Typography> Cash/Tran Date: </Typography>
                      {enableEdit ? (
                        <DatePicker
                          format="dd/MM/yyyy"
                          label=""
                          views={["year", "month", "date"]}
                          value={chartCashTrans}
                          onChange={(date) => setChartCashTrans(date)}
                          autoOk
                        />
                      ) : (
                        <Typography style={{ marginLeft: "5px" }}>
                          {chartCashTrans
                            ? Notify.createdAt(
                                moment(chartCashTrans).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              )
                            : ""}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Table className="table-bordered charts-tablecell">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">
                            Patients Name (Last Name, First Name, Middle Name )
                          </Typography>
                          {info
                            ? `${info.lastname}, ${info.firstname} ${
                                info.middle === null ? "" : info.middle
                              }`
                            : ""}
                        </Box>
                      </TableCell>

                      <TableCell colSpan={3}>
                        <Box>
                          <Typography variant="subtitle2">
                            Patient No.
                          </Typography>
                          {info ? info.patient_id.replace("p-", "") : ""}
                        </Box>
                      </TableCell>

                      <TableCell colSpan={3}>
                        <Box>
                          <Typography variant="subtitle2"> CTAS </Typography>
                          {enableEdit ? (
                            <TextField
                              label=""
                              value={chartCtas}
                              fullWidth
                              onChange={(e) => setChartCtas(e.target.value)}
                              multiline
                            />
                          ) : (
                            <Typography> {chartCtas} </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={12}>
                        <Box>
                          <Typography variant="subtitle2">
                            Complete Address
                          </Typography>
                          {info
                            ? `${info.street}, ${info.barangay}, ${info.city}`
                            : ""}
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={2}>
                        <Box>
                          <Typography variant="subtitle2">Birthdate</Typography>
                          {info
                            ? info.birthday === null
                              ? ""
                              : Notify.createdAt(info.birthday)
                            : ""}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={1}>
                        <Box>
                          <Typography variant="subtitle2">Age</Typography>
                          {info
                            ? info.birthday === null
                              ? ""
                              : Notify.calculateAge(info.birthday)
                            : ""}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Box>
                          <Typography variant="subtitle2">
                            Contact No
                          </Typography>
                          {info ? info.mobile : ""}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Box>
                          <Typography variant="subtitle2">
                            Nationality
                          </Typography>

                          {enableEdit ? (
                            <TextField
                              label=""
                              value={chartNationality}
                              fullWidth
                              onChange={(e) =>
                                setChartNationality(e.target.value)
                              }
                              multiline
                            />
                          ) : (
                            <Typography> {chartNationality} </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Box>
                          <Typography variant="subtitle2">
                            Occupation
                          </Typography>
                          {info ? info.occupation : ""}
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={12}>
                        <Box>
                          <Typography variant="subtitle2">
                            Patient Category: {chartPatientCategory}
                          </Typography>

                          <Box>
                            <Grid container>
                              <Grid item xs={6}>
                                <Box>
                                  <Box>
                                    <Typography>
                                      <IconButton
                                        className={"text-dark"}
                                        disabled={!enableEdit}
                                        onClick={() => {
                                          setChartPatientCategory(
                                            "Uniformed Personel"
                                          );
                                        }}
                                        style={{ padding: "6px" }}
                                      >
                                        {chartPatientCategory ===
                                        "Uniformed Personel" ? (
                                          <CheckSquare />
                                        ) : (
                                          <Square />
                                        )}
                                      </IconButton>
                                      Uniformed Personel
                                    </Typography>
                                  </Box>

                                  <Box>
                                    <Typography>
                                      <IconButton
                                        className={"text-dark"}
                                        disabled={!enableEdit}
                                        onClick={() => {
                                          setChartPatientCategory("NUP");
                                        }}
                                        style={{ padding: "6px" }}
                                      >
                                        {chartPatientCategory === "NUP" ? (
                                          <CheckSquare />
                                        ) : (
                                          <Square />
                                        )}
                                      </IconButton>
                                      NUP
                                    </Typography>
                                  </Box>

                                  <Box>
                                    <Typography>
                                      <IconButton
                                        className={"text-dark"}
                                        disabled={!enableEdit}
                                        onClick={() => {
                                          setChartPatientCategory(
                                            "Retired PNP Personnel"
                                          );
                                        }}
                                        style={{ padding: "6px" }}
                                      >
                                        {chartPatientCategory ===
                                        "Retired PNP Personnel" ? (
                                          <CheckSquare />
                                        ) : (
                                          <Square />
                                        )}
                                      </IconButton>
                                      Retired PNP Personnel
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box>
                                  <Typography>
                                    <IconButton
                                      className={"text-dark"}
                                      disabled={!enableEdit}
                                      onClick={() => {
                                        setChartPatientCategory(
                                          "Authorized Civilian"
                                        );
                                      }}
                                      style={{ padding: "6px" }}
                                    >
                                      {chartPatientCategory ===
                                      "Authorized Civilian" ? (
                                        <CheckSquare />
                                      ) : (
                                        <Square />
                                      )}
                                    </IconButton>
                                    Authorized Civilian
                                  </Typography>
                                </Box>

                                <Box>
                                  <Typography>
                                    <IconButton
                                      className={"text-dark"}
                                      disabled={!enableEdit}
                                      onClick={() => {
                                        setChartPatientCategory("Dependent");
                                      }}
                                      style={{ padding: "6px" }}
                                    >
                                      {chartPatientCategory === "Dependent" ? (
                                        <CheckSquare />
                                      ) : (
                                        <Square />
                                      )}
                                    </IconButton>
                                    Dependent
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={4}>
                        <Box>
                          <Typography variant="subtitle2">
                            {" "}
                            Employer{" "}
                          </Typography>
                          {enableEdit ? (
                            <TextField
                              label=""
                              value={chartEmployer.employeer}
                              fullWidth
                              onChange={(e) =>
                                setChartEmployer({
                                  ...chartEmployer,
                                  employeer: e.target.value,
                                })
                              }
                              multiline
                            />
                          ) : (
                            <Typography> {chartEmployer.employeer} </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={4}>
                        <Box>
                          <Typography variant="subtitle2"> Address </Typography>
                          {enableEdit ? (
                            <TextField
                              label=""
                              fullWidth
                              value={chartEmployer.address}
                              onChange={(e) =>
                                setChartEmployer({
                                  ...chartEmployer,
                                  address: e.target.value,
                                })
                              }
                              multiline
                            />
                          ) : (
                            <Typography> {chartEmployer.address} </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={4}>
                        <Box>
                          <Typography variant="subtitle2">
                            Contact No.
                          </Typography>
                          {enableEdit ? (
                            <TextField
                              label=""
                              fullWidth
                              value={chartEmployer.contact}
                              onChange={(e) =>
                                setChartEmployer({
                                  ...chartEmployer,
                                  contact: e.target.value,
                                })
                              }
                              multiline
                            />
                          ) : (
                            <Typography> {chartEmployer.contact} </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={4}>
                        <Box>
                          <Typography variant="subtitle2">
                            Father's Name
                          </Typography>
                          {enableEdit ? (
                            <TextField
                              label=""
                              fullWidth
                              value={chartFather.father}
                              onChange={(e) =>
                                setChartFather({
                                  ...chartFather,
                                  father: e.target.value,
                                })
                              }
                              multiline
                            />
                          ) : (
                            <Typography> {chartFather.father} </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={4}>
                        <Box>
                          <Typography variant="subtitle2"> Address </Typography>

                          {enableEdit ? (
                            <TextField
                              label=""
                              fullWidth
                              value={chartFather.address}
                              onChange={(e) =>
                                setChartFather({
                                  ...chartFather,
                                  address: e.target.value,
                                })
                              }
                              multiline
                            />
                          ) : (
                            <Typography> {chartFather.address} </Typography>
                          )}
                        </Box>
                      </TableCell>

                      <TableCell colSpan={4}>
                        <Box>
                          <Typography variant="subtitle2">
                            Contact No.
                          </Typography>

                          {enableEdit ? (
                            <TextField
                              label=""
                              fullWidth
                              value={chartFather.contact}
                              onChange={(e) =>
                                setChartFather({
                                  ...chartFather,
                                  contact: e.target.value,
                                })
                              }
                              multiline
                            />
                          ) : (
                            <Typography> {chartFather.contact} </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={4}>
                        <Box>
                          <Typography variant="subtitle2">
                            Mother's Name
                          </Typography>
                          {enableEdit ? (
                            <TextField
                              label=""
                              fullWidth
                              value={chartMother.mother}
                              onChange={(e) =>
                                setChartMother({
                                  ...chartMother,
                                  mother: e.target.value,
                                })
                              }
                              multiline
                            />
                          ) : (
                            <Typography> {chartMother.mother} </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={4}>
                        <Box>
                          <Typography variant="subtitle2"> Address </Typography>
                          {enableEdit ? (
                            <TextField
                              label=""
                              fullWidth
                              value={chartMother.address}
                              onChange={(e) =>
                                setChartMother({
                                  ...chartMother,
                                  address: e.target.value,
                                })
                              }
                              multiline
                            />
                          ) : (
                            <Typography> {chartMother.address} </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={4}>
                        <Box>
                          <Typography variant="subtitle2">
                            Contact No.
                          </Typography>
                          {enableEdit ? (
                            <TextField
                              label=""
                              fullWidth
                              value={chartMother.contact}
                              onChange={(e) =>
                                setChartMother({
                                  ...chartMother,
                                  contact: e.target.value,
                                })
                              }
                              multiline
                            />
                          ) : (
                            <Typography> {chartMother.contact} </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">
                            Attending Physician
                          </Typography>
                          {enableEdit ? (
                            <TextField
                              label=""
                              fullWidth
                              value={chartAttPhysician}
                              onChange={(e) =>
                                setChartAttPhysician(e.target.value)
                              }
                              multiline
                            />
                          ) : (
                            <Typography> {chartAttPhysician} </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">
                            Reffered By: (Physician/Agency)
                          </Typography>
                          {enableEdit ? (
                            <TextField
                              label=""
                              fullWidth
                              value={chartRefferedBy}
                              onChange={(e) =>
                                setChartRefferedBy(e.target.value)
                              }
                              multiline
                            />
                          ) : (
                            <Typography> {chartRefferedBy} </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={12}>
                        <Typography variant="subtitle2">
                          {" "}
                          Allergic To{" "}
                        </Typography>
                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartAllergy}
                            onChange={(e) => setChartAllergy(e.target.value)}
                            multiline
                          />
                        ) : (
                          <Typography> {chartAllergy} </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={8}>
                        <Typography variant="subtitle2">
                          Hospitalization Plan / HMO Coverage
                        </Typography>

                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartHmoCoverage}
                            onChange={(e) =>
                              setChartHmoCoverage(e.target.value)
                            }
                            multiline
                          />
                        ) : (
                          <Typography> {chartHmoCoverage} </Typography>
                        )}
                      </TableCell>
                      <TableCell colSpan={4}>
                        <Typography variant="subtitle2"> Insurance </Typography>
                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartInsurance}
                            onChange={(e) => setChartInsurance(e.target.value)}
                            multiline
                          />
                        ) : (
                          <Typography> {chartInsurance} </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    {/* not okay */}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography variant="subtitle2">
                          Name of Informant
                        </Typography>
                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartNameOfInformant}
                            onChange={(e) =>
                              setChartNameOfInformant(e.target.value)
                            }
                            multiline
                          />
                        ) : (
                          <Typography> {chartNameOfInformant} </Typography>
                        )}

                        {/* <Typography variant="subtitle2">
                          Date Furnished
                        </Typography>
                        {enableEdit ? (
                          <DatePicker
                            format="dd/MM/yyyy"
                            label=""
                            views={["year", "month", "date"]}
                            value={chartDateFurnished}
                            onChange={(date) => setChartDateFurnished(date)}
                            autoOk
                          />
                        ) : (
                          <Typography>
                            {" "}
                            {chartDateFurnished
                              ? Notify.createdAt(
                                  moment(chartDateFurnished).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              : ""}{" "}
                          </Typography>
                        )} */}
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="subtitle2">
                          Address Of Informant
                        </Typography>
                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartAddOfInformant}
                            onChange={(e) =>
                              setChartAddOfInformant(e.target.value)
                            }
                            multiline
                          />
                        ) : (
                          <Typography> {chartAddOfInformant} </Typography>
                        )}
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="subtitle2">Contact No.</Typography>
                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartInformantContactNo}
                            onChange={(e) =>
                              setChartOfInformantContactNo(e.target.value)
                            }
                            multiline
                          />
                        ) : (
                          <Typography> {chartInformantContactNo} </Typography>
                        )}
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="subtitle2">
                          Relation to Patient
                        </Typography>

                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartRelationPatient}
                            onChange={(e) =>
                              setChartRelationPatient(e.target.value)
                            }
                            multiline
                          />
                        ) : (
                          <Typography> {chartRelationPatient} </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={12}>
                        <Typography variant="subtitle2">
                          Chief Complaint
                        </Typography>
                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartChiefComplaint}
                            onChange={(e) =>
                              setChartChiefComplaint(e.target.value)
                            }
                            multiline
                          />
                        ) : (
                          <Typography> {chartChiefComplaint} </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={12}>
                        <Typography variant="subtitle2"> Diagnosis </Typography>
                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartDiagnosis}
                            onChange={(e) => setChartDiagnosis(e.target.value)}
                            multiline
                          />
                        ) : (
                          <Typography> {chartDiagnosis} </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={12}>
                        <Typography variant="subtitle2">
                          Surgical Procedure
                        </Typography>

                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartSurgicalProcedures}
                            onChange={(e) =>
                              setChartSurgicalProcedures(e.target.value)
                            }
                            multiline
                          />
                        ) : (
                          <Typography> {chartSurgicalProcedures} </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={12}>
                        <Typography variant="subtitle2">
                          Other Operations / Procedures
                        </Typography>
                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartOtherProcedures}
                            onChange={(e) =>
                              setChartOtherProcedures(e.target.value)
                            }
                            multiline
                          />
                        ) : (
                          <Typography> {chartOtherProcedures} </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="subtitle2">Disposition</Typography>
                      </TableCell>
                      <TableCell colSpan={4}>
                        <Typography variant="subtitle2">Results</Typography>
                      </TableCell>
                      <TableCell colSpan={4}>
                        <Typography variant="subtitle2">Biopsy</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4}>
                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartOtherProceduresDisposition}
                            onChange={(e) =>
                              setChartOtherProceduresDisposition(e.target.value)
                            }
                            multiline
                          />
                        ) : (
                          <Typography>
                            {chartOtherProceduresDisposition}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell colSpan={4}>
                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartOtherProceduresResults}
                            onChange={(e) =>
                              setChartOtherProceduresResults(e.target.value)
                            }
                            multiline
                          />
                        ) : (
                          <Typography>
                            {" "}
                            {chartOtherProceduresResults}{" "}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell colSpan={4}>
                        {enableEdit ? (
                          <TextField
                            label=""
                            fullWidth
                            value={chartOtherProceduresBiopsy}
                            onChange={(e) =>
                              setChartOtherProceduresBiopsy(e.target.value)
                            }
                            multiline
                          />
                        ) : (
                          <Typography>
                            {" "}
                            {chartOtherProceduresBiopsy}{" "}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </form>
        ) : (
          Notify.loading()
        )}
      </CardContent>
    </Card>
  );
};

export default FormTwo;
