import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import {
  UsersHeader,
  BranchListContext,
  FormInformation,
  UsersData,
} from "../ContextAPI";
import Account from "../accounting/account/Account";
import AccountingDashboard from "src/accounting/Dashboard";
import AccountingLaboratory from "src/accounting/laboratory";
import AccountingImaging from "src/accounting/imaging";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faBox,
  faBoxOpen,
  // faBuilding,
  // faCheckDouble,
  // faClipboardList,
  faDesktop,
  faHome,
  faMoneyBill,
  // faPiggyBank,
  // faTv,
  faUserLock,
  faVials,
  faXRay,
  // faWarehouse,
  // faFileInvoiceDollar,
  faHandHoldingUsd,
  // faWallet,
  faAddressCard,
  faThumbsUp,
  faBorderAll,
  faBrain,
  faSpellCheck,
  faUserMd,
  faDoorOpen,
  // faList,
  // faClinicMedical,
  // faPaperclip,
  // faHandHolding,
  // faListUl,
} from "@fortawesome/free-solid-svg-icons";
import Packages from "src/accounting/packages";
import ForApprovalList from "src/accounting/for-approval/ForApprovalList";
import WarehouseProducts from "src/accounting/products";
import WarehouseMonitoring from "src/accounting/monitoring";
import CompanyList from "src/accounting/company-list";
import AccountingManagement from "src/accounting/management/AccountingManagement";
import BankList from "src/accounting/bank";
// import Expense from "src/accounting/expense/Expense";
import Receivable from "src/accounting/receivable/Receivable";
import Inventory from "src/accounting/branches/Inventory";
import BranchSales from "src/accounting/branches/Sales";
import Payable from "src/accounting/payable";
import ProductInventory from "src/accounting/inventory";
import Collection from "src/accounting/collection/Collection";
import AddExpense from "src/accounting/expense/add/AddExpense";
import ExpenseList from "src/accounting/expense/ExpenseList";
import LeaveForm from "src/accounting/leave-form/LeaveForm";
import LeaveApproval from "src/accounting/leave-form/LeaveApproval";
import ProductMonitoring from "src/accounting/products/ProductMonitoring";
import AccountingPsychology from "src/accounting/psychology";
// import AccountingPhysicalExam from "src/accounting/physical-exam";
// import { Disc } from "react-feather";
// import AddOnTest from "src/accounting/others/AddOnTest";
import AccountingOtherTest from "src/accounting/others";
import AccountingDoctor from "src/accounting/doctor";
import AccountingRequest from "src/accounting/request/AccountingRequest";
import ClinicSOAIndex from "src/accounting/clinic-soa/ClinicSOAIndex";
import ClinicSalesIndex from "src/accounting/clinic-sales/ClinicSalesIndex";
import HMOListAccounting from "src/accounting/hmo-list/HMOListAccounting";
import HospitalRooms from "src/accounting/hospital-rooms";

const RouteAccounting = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const [branches, setBranches] = useState([]);
  const [formInfo, setFormInfo] = useState([]);
  const userContext = useContext(UsersData);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get(
        "accounting/sidebar/header-infomartion",
        {
          params,
        }
      );
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const getBranchesByMainMngtId = async () => {
    var params = { main_management_id: userContext.users.main_mgmt_id };
    try {
      let response = await axios.get("accounting/management/get-branches", {
        params,
      });
      setBranches(response.data);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const getCurrentFormInfoForExpense = async () => {
    try {
      var params = {
        user_id: userContext.users.user_id,
        management_id: userContext.users.management_id,
        main_mgmt_id: userContext.users.main_mgmt_id,
      };
      const response = await axios.get(
        "accounting/get/expense/form-information",
        {
          params,
        }
      );
      setFormInfo(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faHome} color="white" style={{ fontSize: 23 }} />
      ),
      path: "/sph/app/accounting",
      subitem: [],
    },
    {
      name: "Management",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faDesktop}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/sph/app/management",
      subitem: [],
    },

    {
      name: "Rooms",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faDoorOpen}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/sph/app/rooms",
      subitem: [],
    },

    // {
    //   name: "Products",
    //   show: true,
    //   icon: (
    //     <FontAwesomeIcon icon={faBox} color="white" style={{ fontSize: 23 }} />
    //   ),
    //   path: "/sph/app/accounting",
    //   subitem: [
    //     {
    //       name: "List",
    //       show: true,
    //       icon: (
    //         <FontAwesomeIcon
    //           icon={faClipboardList}
    //           color="white"
    //           style={{ fontSize: 17 }}
    //         />
    //       ),
    //       path: "/sph/app/accounting/products",
    //     },
    //     {
    //       name: "Monitoring",
    //       show: true,
    //       icon: (
    //         <FontAwesomeIcon
    //           icon={faTv}
    //           color="white"
    //           style={{ fontSize: 17 }}
    //         />
    //       ),
    //       path: "/sph/app/accounting/product-monitoring",
    //     },
    //     {
    //       name: "Inventory",
    //       show: true,
    //       icon: (
    //         <FontAwesomeIcon
    //           icon={faWarehouse}
    //           color="white"
    //           style={{ fontSize: 17 }}
    //         />
    //       ),
    //       path: "/sph/app/accounting/product-inventory",
    //     },
    //     {
    //       name: "For Approval",
    //       show: true,
    //       icon: (
    //         <FontAwesomeIcon
    //           icon={faCheckDouble}
    //           color="white"
    //           style={{ fontSize: 17 }}
    //         />
    //       ),
    //       path: "/sph/app/accounting/for-approval",
    //     },
    //   ],
    // },
    {
      name: "Leave Application",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faBorderAll}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/sph/app/accounting",
      subitem: [
        {
          name: "Leave Form",
          show: true,
          icon: (
            <FontAwesomeIcon
              icon={faAddressCard}
              color="white"
              style={{ fontSize: 17 }}
            />
          ),
          path: "/sph/app/accounting/leave-form",
        },
        {
          name: "Leave For Approval",
          show: true,
          icon: (
            <FontAwesomeIcon
              icon={faThumbsUp}
              color="white"
              style={{ fontSize: 17 }}
            />
          ),
          path: "/sph/app/accounting/leave-approval",
        },
      ],
    },
    // {
    //   name: "HMO List",
    //   show: true,
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faListUl}
    //       color="white"
    //       style={{ fontSize: 23 }}
    //     />
    //   ),
    //   path: "/sph/app/accounting/hmo-list",
    //   subitem: [],
    // },
    {
      name: "Laboratory",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faVials}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/sph/app/accounting/laboratory",
      subitem: [],
    },
    {
      name: "Imaging",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faXRay} color="white" style={{ fontSize: 23 }} />
      ),
      path: "/sph/app/accounting/imaging",
      subitem: [],
    },
    {
      name: "Doctor",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faUserMd}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/sph/app/accounting/doctor",
      subitem: [],
    },

    // {
    //   name: "Physical Exam",
    //   show: true,
    //   icon: <Disc color="white" />,
    //   path: "/sph/app/accounting/physical-exam",
    //   subitem: [],
    // },

    {
      name: "Psychology",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faBrain}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/sph/app/accounting/psychology",
      subitem: [],
    },

    {
      name: "Other Test",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faSpellCheck}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/sph/app/accounting/other/order",
      subitem: [],
    },

    {
      name: "Packages",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faBoxOpen}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/sph/app/accounting/packages",
      subitem: [],
    },

    // {
    //   name: "Clinic",
    //   show: true,
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faClinicMedical}
    //       color="white"
    //       style={{ fontSize: 23 }}
    //     />
    //   ),
    //   path: "/sph/app/accounting",
    //   subitem: [
    //     {
    //       name: "SOA",
    //       show: true,
    //       icon: (
    //         <FontAwesomeIcon
    //           icon={faPaperclip}
    //           color="white"
    //           style={{ fontSize: 17 }}
    //         />
    //       ),
    //       path: "/sph/app/accounting/soa",
    //     },
    //     {
    //       name: "Sales",
    //       show: true,
    //       icon: (
    //         <FontAwesomeIcon
    //           icon={faHandHolding}
    //           color="white"
    //           style={{ fontSize: 17 }}
    //         />
    //       ),
    //       path: "/sph/app/accounting/sales",
    //     },
    //   ],
    // },

    // {
    //   name: "Clinic SOA",
    //   show: true,
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faClinicMedical}
    //       color="white"
    //       style={{ fontSize: 23 }}
    //     />
    //   ),
    //   path: "/sph/app/accounting/soa",
    //   subitem: [],
    // },

    // {
    //   name: "Company List",
    //   show: true,
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faBuilding}
    //       color="white"
    //       style={{ fontSize: 23 }}
    //     />
    //   ),
    //   path: "/sph/app/accounting/company-list",
    //   subitem: [],
    // },
    // {
    //   name: "Payable",
    //   show: true,
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faFileInvoiceDollar}
    //       color="white"
    //       style={{ fontSize: 23 }}
    //     />
    //   ),
    //   path: "/sph/app/accounting/payable",
    //   subitem: [],
    // },
    // {
    //   name: "Bank",
    //   show: true,
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faPiggyBank}
    //       color="white"
    //       style={{ fontSize: 23 }}
    //     />
    //   ),
    //   path: "/sph/app/accounting/bank",
    //   subitem: [],
    // },
    {
      name: "Expense",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faMoneyBill}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/sph/app/accounting/expense",
      subitem: [],
    },
    {
      name: "Receivable",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faHandHoldingUsd}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/sph/app/accounting/receivable",
      subitem: [],
    },
    // {
    //   name: "Collection",
    //   show: true,
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faWallet}
    //       color="white"
    //       style={{ fontSize: 23 }}
    //     />
    //   ),
    //   path: "/sph/app/accounting/collection",
    //   subitem: [],
    // },
    // {
    //   name: "Request List",
    //   show: true,
    //   icon: (
    //     <FontAwesomeIcon icon={faList} color="white" style={{ fontSize: 23 }} />
    //   ),
    //   path: "/sph/app/accounting/request-list",
    //   subitem: [],
    // },
    {
      name: "Account",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faUserLock}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/sph/app/accounting/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  const handleRenderBranches = () => {
    getBranchesByMainMngtId();
  };

  const handleRerenderFormInfo = () => {
    getCurrentFormInfoForExpense();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    getBranchesByMainMngtId();
    getCurrentFormInfoForExpense();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
        }}
      >
        <FormInformation.Provider
          value={{ formInfo: formInfo, renderFormInfo: handleRerenderFormInfo }}
        >
          <BranchListContext.Provider
            value={{
              branches: branches,
              renderBranchList: handleRenderBranches,
            }}
          >
            <BrowserRouter>
              <Sidebar
                notification={{
                  enable: true,
                  owner: "accounting",
                }}
                header={sidebarHeader}
                routes={sidebarRoute}
                module={"accounting"}
                render={() => getSidebarHeaderInfo()}
              />

              <Switch>
                <Route
                  exact
                  path="/"
                  component={() => <Redirect to="/sph/app/accounting" />}
                />

                <Route
                  exact
                  path="/bmcdc"
                  component={() => <Redirect to="/sph/app/accounting" />}
                />

                <Route
                  exact
                  path="/sph/app"
                  component={() => <Redirect to="/sph/app/accounting" />}
                />

                <Route
                  exact
                  path="/sph/app/accounting/leave-form"
                  component={LeaveForm}
                />

                <Route
                  exact
                  path="/sph/app/accounting/leave-approval"
                  component={LeaveApproval}
                />

                <Route
                  exact
                  path="/sph/app/accounting/products"
                  component={WarehouseProducts}
                />

                <Route
                  exact
                  path="/sph/app/accounting/product-monitoring"
                  component={WarehouseMonitoring}
                />

                <Route
                  exact
                  path="/sph/app/accounting"
                  component={AccountingDashboard}
                />

                <Route
                  exact
                  path="/sph/app/management"
                  component={AccountingManagement}
                />

                <Route exact path="/sph/app/rooms" component={HospitalRooms} />

                <Route
                  exact
                  path="/sph/app/accounting/for-approval"
                  component={ForApprovalList}
                />

                <Route
                  exact
                  path="/sph/app/accounting/hmo-list"
                  component={HMOListAccounting}
                />

                <Route
                  exact
                  path="/sph/app/accounting/laboratory"
                  component={AccountingLaboratory}
                />

                <Route
                  exact
                  path="/sph/app/accounting/imaging"
                  component={AccountingImaging}
                />

                <Route
                  exact
                  path="/sph/app/accounting/psychology"
                  component={AccountingPsychology}
                />

                <Route
                  exact
                  path="/sph/app/accounting/doctor"
                  component={AccountingDoctor}
                />

                {/* <Route
                  exact
                  path="/sph/app/accounting/physical-exam"
                  component={AccountingPhysicalExam}
                /> */}

                <Route
                  exact
                  path="/sph/app/accounting/packages"
                  component={Packages}
                />

                <Route
                  exact
                  path="/sph/app/accounting/soa"
                  component={ClinicSOAIndex}
                />

                <Route
                  exact
                  path="/sph/app/accounting/sales"
                  component={ClinicSalesIndex}
                />

                <Route
                  exact
                  path="/sph/app/accounting/company-list"
                  component={CompanyList}
                />

                <Route
                  exact
                  path="/sph/app/accounting/bank"
                  component={BankList}
                />

                {/* <Route
                exact
                path="/sph/app/accounting/expense"
                component={Expense}
              /> */}

                <Route
                  exact
                  path="/sph/app/accounting/expense"
                  component={ExpenseList}
                />

                <Route
                  exact
                  path="/sph/app/accounting/receivable"
                  component={Receivable}
                />

                <Route
                  exact
                  path="/sph/app/accounting/collection"
                  component={Collection}
                />

                <Route
                  exact
                  path="/sph/app/accounting/account"
                  component={Account}
                />

                <Route
                  exact
                  path="/sph/app/management/branch/sales/:branch/:management_id"
                  component={BranchSales}
                />

                <Route
                  exact
                  path="/sph/app/management/branch/inventory/:branch/:management_id"
                  component={Inventory}
                />

                <Route
                  exact
                  path="/sph/app/management/add-expense"
                  component={AddExpense}
                />

                <Route
                  exact
                  path="/sph/app/accounting/product-inventory"
                  component={ProductInventory}
                />

                <Route
                  exact
                  path="/sph/app/accounting/payable"
                  component={Payable}
                />

                <Route
                  exact
                  path="/sph/app/accounting/product/:product_id"
                  component={ProductMonitoring}
                />

                <Route
                  exact
                  path="/sph/app/accounting/request-list"
                  component={AccountingRequest}
                />

                <Route
                  exact
                  path="/sph/app/accounting/other/order"
                  component={AccountingOtherTest}
                />

                <Route exact path="/sph/app/logout" component={Logout} />

                <Route render={() => <PageNotFound title="Page not found" />} />
              </Switch>
            </BrowserRouter>
          </BranchListContext.Provider>
        </FormInformation.Provider>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteAccounting;
