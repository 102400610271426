import { TextField } from "@material-ui/core";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import ContactTracingForm from "./ContactTracingForm";

const AddContactTracingModal = (props, ref) => {
	const [open, setOpen] = useState(false);
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const sicknessValue = "";
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));
	const show = (data) => {
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};
	const sickness = [
		"Dry cough",
		"Fever",
		"Shortness of breath",
		"Difficulty of breathing",
		"Sore throat",
		"Headache",
		"Nasal congestions",
		"Diarrhea",
		"Colds",
		"Fatigue or body malaise",
	];
	const submit = (data) => {
		console.log("datadatadata", data);
	};
	return (
		<Modal open={open} hide={hide} size="lg">
			<ModalHeader title={"Contact Tracing Form"} hide={hide} />
			<ModalBody className={`p- !bg-white`}>
				<ContactTracingForm
					errors={errors}
					register={register}
					className={"p-4"}
					setValue={setValue}
					sicknessValue={sicknessValue}
				/>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<ActionBtn onClick={handleSubmit(submit)}>
					<FlatIcon icon="rr-disk" className="mr-2" /> Save Contact Tracing
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(AddContactTracingModal);
