import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import SelectItemsSelector from "./SelectItemsSelector";
import Img from "src/components/Img";
import { UsersData } from "src/ContextAPI";
import {
	calculateAge,
	dataURItoBlob,
	dateYYYYMMDD,
	formatDateMMDDYYYYHHIIA,
	patientAddress,
} from "src/helpers/utils";
import ItemServiceList from "./ItemServiceList";
import SelectInputField from "src/components/forms/SelectInputField";
import SelectToUseItems from "./SelectToUseItems";
import ImagePicker from "src/components/forms/ImagePicker";

const ReferToRhuModal = (props, ref) => {
	const { patient, refresh, appointmentVitalsRef, onSuccess } = props;
	const { users } = useContext(UsersData);
	const { register: register1, watch: watch1 } = useForm();
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		trigger,
		control,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			health_insurrance_coverage: "No",
		},
	});
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(0);
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const [refreshKey, setRefreshKey] = useState(1);
	const [selected, setSelected] = useState(null);
	const [selectedRHU, setSelectedRHU] = useState(null);
	const [selectedClick, setSelectedClick] = useState(null);
	const [isPositive, setIsPositive] = useState(false);
	const [itemsUsed, setItemsUsed] = useState(false);
	const [forConfirmation, setForConfirmation] = useState(false);
	const [vitals, setVitals] = useState(null);
	const [doctors, setDoctors] = useState([]);
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [specimen, setSpecimen] = useState(null);

	useEffect(() => {
		let t = setTimeout(() => {
			getRHUData();
			getRHUDoctors();
		}, 100);
		return () => {
			clearTimeout(t);
		};
	}, []);

	// console.log("usersusersusers", users);

	const [locationsData, setLocationsData] = useState([]);

	const getRHUData = () => {
		axios.get(`/v1/health-unit/list?type=RHU`).then((res) => {
			setLocationsData(res.data.data);
		});
	};

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (showData, propSelectedRhu = null) => {
		setAppointment(showData);
		if (showData?.pre_notes) {
			setValue("pre_notes", showData?.pre_notes);
		}
		if (propSelectedRhu) {
			setSelectedRHU(propSelectedRhu);
		}
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const submitForm = (data) => {
		setLoading(true);
		let formdata = new FormData();
		let url = `v1/clinic/tb-refer-to-rhu`;
		formdata.append("rhu_id", data?.rhu_id);
		if (appointment?.id) {
			formdata.append("_method", "PATCH");
			url = `v1/clinic/tb-refer-to-rhu/${appointment?.id}`;
		}

		formdata.append("patient_id", patient?.id);
		formdata.append("doctor_id", selectedDoctor);
		formdata.append("date", data?.date);
		formdata.append("pre_notes", data?.pre_notes);
		formdata.append("time", data?.time);
		formdata.append("clinical_history", data?.clinical_history);
		formdata.append("lab_findings", data?.lab_findings);
		formdata.append("impression", data?.impression);
		formdata.append("action_taken", data?.action_taken);
		formdata.append(
			"health_insurrance_coverage",
			data?.health_insurrance_coverage
		);
		formdata.append(
			"health_insurrance_coverage_if_yes_type",
			data?.health_insurrance_coverage_if_yes_type
		);
		formdata.append("reason", data?.reason);
		axios
			.post(url, formdata)
			.then((response) => {
				let data = response.data;
				// console.log(data);
				setLoading(false);
				setTimeout(() => {
					onSuccess();
					toast.success("Patient referral success!");
					refresh();
				}, 200);
				hide();
			})
			.catch((err) => {
				// setLoading(false);
				console.log(err);
			});
	};

	const openVitalsForm = () => {
		appointmentVitalsRef.current.show(null, { fn: setVitals });
	};

	const getRHUDoctors = () => {
		axios.get(`v1/clinic/rhu-doctors`).then((res) => {
			setDoctors(res?.data?.data);
		});
	};
	return (
		<Modal
			open={open}
			staticModal={true}
			size="md"
			hide={hide}
			className=" w-full max-w-[768px]"
		>
			<ModalHeader
				title={`Refer to RHU form`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
					<div className="lg:col-span-2 grid grid-cols-1 lg:grid-cols-3">
						<div className="lg:col-span-3 font-medium mb-1">
							Patient name
						</div>
						<div className="font-semibold flex flex-col items-center text-base justify-center text-center">
							&nbsp;{patient?.lastname}&nbsp;
							<span className="border-t w-2/3 font-normal text-slate-400 !text-xs">
								(Surname)
							</span>
						</div>
						<div className="font-semibold flex flex-col items-center text-base justify-center text-center">
							&nbsp;{patient?.firstname}&nbsp;
							<span className="border-t w-2/3 font-normal text-slate-400 !text-xs">
								(First name)
							</span>
						</div>
						<div className="font-semibold flex flex-col items-center text-base justify-center text-center">
							&nbsp;{patient?.middlename}&nbsp;
							<span className="border-t w-2/3 font-normal text-slate-400 !text-xs">
								(Middle name)
							</span>
						</div>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2">
						<div className="lg:col-span-3 font-medium mb-1">
							&nbsp; &nbsp;
						</div>
						<div className="font-semibold flex flex-col items-center text-base justify-center text-center">
							&nbsp;{calculateAge(patient?.birthday)}&nbsp;
							<span className="border-t w-2/3 font-normal text-slate-400 !text-xs">
								Age
							</span>
						</div>
						<div className="font-semibold flex flex-col items-center text-base justify-center text-center">
							&nbsp;{patient?.gender}&nbsp;
							<span className="border-t w-2/3 font-normal !text-xs text-slate-400">
								Sex
							</span>
						</div>
					</div>
					<div className="lg:col-span-3 flex flex-col">
						<h3 className="font-medium text-sm">Patient Address</h3>
						<span className="font-bold pl-5">
							{patientAddress(patient)}
						</span>
					</div>
					<div className="lg:col-span-3 grid lg:grid-cols-2 grid-cols-1 gap-6">
						<SelectInputField
							labelClassName="font-medium"
							label="Refer to RHU"
							placeholder="Select RHU"
							className="!mb-0"
							value={selectedRHU}
							// disabled={!isPositive}
							{...register("rhu_id", {
								required: "This field is required.",
							})}
							error={errors?.rhu_id?.message}
							onChange={(data) => {
								setSelectedRHU(data.target.value);
							}}
							options={locationsData?.map((x) => {
								return {
									label: x.name,
									value: x.id,
								};
							})}
						/>
						<SelectInputField
							labelClassName="font-medium"
							label="Select RHU Doc"
							placeholder="Select"
							className="!mb-0"
							value={selectedDoctor}
							// disabled={!isPositive}
							{...register("doctor_id", {
								// required: "This field is required.",
							})}
							error={errors?.doctor_id?.message}
							onChange={(data) => {
								setSelectedDoctor(data.target.value);
							}}
							options={doctors?.map((x) => {
								return {
									label: x.name,
									value: x.id,
								};
							})}
						/>
					</div>
					<TextInputField
						label="Date"
						type="date"
						error={errors?.date?.message}
						{...register("date", {
							required: "This field is required.",
						})}
					/>
					<TextInputField
						label="Time"
						type="time"
						error={errors?.time?.message}
						{...register("time", {
							required: "This field is required.",
						})}
					/>

					<div className="lg:col-span-3">
						<TextInputField
							label="Chief complaint:"
							error={errors?.pre_notes?.message}
							{...register("pre_notes", {
								required: "This field is required.",
							})}
						/>
					</div>
					<div className="lg:col-span-3">
						<TextInputField
							error={errors?.clinical_history?.message}
							{...register("clinical_history", {
								required: "This field is required.",
							})}
							label="Brief Clinical History and Pertinent Physical Examination"
						/>
					</div>
					<div className="lg:col-span-3">
						<TextInputField
							error={errors?.lab_findings?.message}
							{...register("lab_findings", {
								required: "This field is required.",
							})}
							label="Laboratory Findings (Including ECG, X-ray, and other diagnostic procedures)"
						/>
					</div>
					<div className="lg:col-span-3">
						<TextInputField
							error={errors?.impression?.message}
							{...register("impression", {
								required: "This field is required.",
							})}
							label="Impression"
						/>
					</div>
					<div className="lg:col-span-3">
						<TextInputField
							error={errors?.action_taken?.message}
							{...register("action_taken", {
								required: "This field is required.",
							})}
							label="Action Taken"
						/>
					</div>
					<div className="lg:col-span-1">
						<FormControl className="w-full">
							<FormLabel
								error={errors?.health_insurrance_coverage}
								id="demo-controlled-radio-buttons-group"
								className=" !mb-0 !font-semibold flex !text-black items-center"
							>
								Health Insurance Coverage
							</FormLabel>
							{errors?.health_insurrance_coverage && (
								<FormHelperText
									error={errors?.health_insurrance_coverage}
								>
									This field is required.
								</FormHelperText>
							)}
							<Controller
								rules={{ required: true }}
								name={"health_insurrance_coverage"}
								control={control}
								render={({ field }) => (
									<RadioGroup
										{...field}
										row
										className=""
										key={`tb-symp-${field?.value}`}
									>
										<FormControlLabel
											value={"Yes"}
											control={<Radio />}
											label={"Yes"}
										/>
										<FormControlLabel
											value={"No"}
											control={<Radio />}
											label={"No"}
										/>
									</RadioGroup>
								)}
							/>
						</FormControl>
					</div>
					<div className="lg:col-span-2">
						<TextInputField
							{...register(
								"health_insurrance_coverage_if_yes_type",
								{
									required:
										watch("health_insurrance_coverage") ==
										"Yes"
											? "This field is required."
											: false,
								}
							)}
							disabled={
								watch("health_insurrance_coverage") == "No"
							}
							label="If YES, state type of coverage:"
							placeholder="If YES, state type of coverage:"
						/>
					</div>
					<div className="lg:col-span-3">
						<TextInputField
							error={errors?.reason?.message}
							{...register("reason", {
								required: "This field is required.",
							})}
							label="Reason for Referral:"
						/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center justify-between gap-4"}>
				<ActionBtn
					className="w-1/3"
					type="danger"
					onClick={hide}
					// disabled={!watch("positive") || isPositive}
				>
					<FlatIcon icon="rr-cross-small" className="-mt-[4px]" />
					Cancel
				</ActionBtn>{" "}
				<ActionBtn
					className="w-1/3"
					type="accent"
					onClick={openVitalsForm}
					// disabled={!watch("positive") || isPositive}
				>
					<FlatIcon icon="rr-upload" className="-mt-[4px]" />
					Update Vitals
				</ActionBtn>
				<ActionBtn
					className="w-1/3"
					type="success"
					onClick={handleSubmit(submitForm)}
					disabled={vitals == null}
				>
					<FlatIcon icon="rr-disk" className="-mt-[4px]" />
					Submit referral
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(ReferToRhuModal);
