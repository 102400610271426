import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  Zoom,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import axios from "axios";
import React, { Fragment, useCallback, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import PrintIcon from "@material-ui/icons/Print";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearIcon from "@material-ui/icons/Clear";
import { Formik } from "formik";
import * as Yup from "yup";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import DoctorSalesPrint from "./DoctorSalesPrint";

const DoctorSales = () => {
  const { users } = React.useContext(UsersData);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [printSalesDiag, setPrintSalesDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const [total, setTotal] = useState(0);

  const [docSalesData, setDocSalesData] = useState({
    data: [],
    ready: false,
  });

  const calculateTotalSales = (data) => {
    var total = 0;
    for (let i = 0; i < data.length; i++) {
      total += parseFloat(data[i].bill_amount);
    }
    return total;
  };

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const fetchDocSalesList = useCallback(() => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    axios
      .get("hims/doctor/sales-result", { params })
      .then((response) => {
        const data = response.data;
        setDocSalesData({ data: data, ready: true });
        setTotal(calculateTotalSales(data));
      })
      .catch((error) => {
        console.log("error : ", error);
        // Notify.requestError(error);
      });
  }, [users]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  React.useEffect(() => {
    fetchDocSalesList();
  }, [fetchDocSalesList]);

  const searchable = docSalesData.data.filter((data) => {
    return data.name.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <Fragment>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography style={{ marginTop: 20 }} variant="h6">
            <strong>Overall Sales: {Notify.convertToNumber(total)}</strong>
          </Typography>
        </Box>
        <Box mb={1}>
          <TextField
            label="Search patient"
            variant="outlined"
            margin="dense"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Paper>
        <Box p={2} borderRadius={4}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box flexGrow={1} />
            <Box mr={2}>
              <IconButton
                title="Filter by Date"
                size="small"
                className={"d-print-none"}
                color="primary"
                onClick={() => setFilterDialog(true)}
              >
                <FilterListIcon />
              </IconButton>
            </Box>

            <IconButton
              title="Print"
              size="small"
              color="primary"
              onClick={() => setPrintSalesDialog(true)}
            >
              <PrintIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box m={1}>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center"> DATE </TableCell>
                  <TableCell align="center"> PATIENT </TableCell>
                  <TableCell align="center"> SERVICES </TableCell>
                  <TableCell align="center"> AMOUNT </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {docSalesData.ready ? (
                  docSalesData.data.length > 0 ? (
                    searchable
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <TableRow key={index}>
                          <TableCell align="right">
                            {Notify.createdAt(data.created_at)}
                          </TableCell>
                          <TableCell> {data.name} </TableCell>
                          <TableCell>{data.bill_name}</TableCell>
                          <TableCell align="right">
                            {data.bill_amount}
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <Typography variant="subtitle2" color="secondary">
                          {" "}
                          No product added{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography variant="subtitle2" color="primary">
                        {" "}
                        Loading...{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component={"div"}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={3}
              count={docSalesData.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Paper>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={filterDialog}
        TransitionComponent={Zoom}
        transitionDuration={1000}
        disableBackdropClick
      >
        <DialogTitle>
          <Typography color="primary">Filter By Date</Typography>
        </DialogTitle>
        <Formik
          initialValues={{
            user_id: users.user_id,
            management_id: users.management_id,
            date_from: "",
            date_to: "",
          }}
          validationSchema={Yup.object().shape({
            date_from: Yup.string().required("Date From is required"),
            date_to: Yup.string().required("Date to is required"),
          })}
          onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
            try {
              const request = await axios.post(
                "hims/doctor/filter-by-date/sales-result",
                getFormData(values)
              );
              const data = request.data;
              setDocSalesData({ data: data, ready: true });
              setTotal(calculateTotalSales(data));
              setFilterDialog(false);
              resetForm();
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Box mb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box mb={1}>
                        <TextField
                          required
                          fullWidth
                          label="Date From"
                          error={Boolean(touched.date_from && errors.date_from)}
                          helperText={touched.date_from && errors.date_from}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.date_from}
                          name="date_from"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box mb={1}>
                        <TextField
                          required
                          fullWidth
                          label="Date To"
                          error={Boolean(touched.date_to && errors.date_to)}
                          helperText={touched.date_to && errors.date_to}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.date_to}
                          name="date_to"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Box mb={1} display="flex">
                  <Box flexGrow={1} />
                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<ClearIcon />}
                    onClick={() => setFilterDialog(false)}
                  >
                    Close
                  </Button>
                  <Box ml={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<CheckIcon />}
                    >
                      Go
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        open={printSalesDiag}
        TransitionComponent={Zoom}
        transitionDuration={500}
        fullScreen
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Box display="flex">
                <Box flexGrow={1} mb={2}>
                  <Typography
                    component={Box}
                    align="center"
                    variant="h5"
                    style={{ fontWeight: "bolder" }}
                  >
                    SALES REPORT
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <DoctorSalesPrint close={() => setPrintSalesDialog(false)} />
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default DoctorSales;
