import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  useTheme,
  // useTheme,
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import Select from "react-select";

const New = ({ rerender }) => {
  const theme = useTheme();
  const { users } = useContext(UsersData);
  const [list, setList] = useState([]);
  const [descriptionList, setDescriptionList] = useState([]);
  const [selectedItemBatches, setSelectedItemBatches] = useState([]);

  // const [selectItem, setSelectedItem] = useState({});
  const [selectDesc, setSelectedDesc] = useState(null);
  const [newBatch, setNewBatch] = useState(false);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    formData.append("item_id", selectDesc.item_id);
    return formData;
  };

  // prev
  // const getItemLab = React.useCallback(async () => {
  //   let response = await axios.get("accounting/test/items/get-itemlist", {
  //     params: {
  //       management_id: users.management_id,
  //       main_mgmt_id: users.main_mgmt_id,
  //       user_id: users.user_id,
  //     },
  //   });
  //   setList(response.data);
  // }, [users]);

  const getItemLab = React.useCallback(async () => {
    let response = await axios.get("accounting/get/lab-item/product-list", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
        user_id: users.user_id,
      },
    });
    setList(response.data);
  }, [users]);

  const handleSelectedItem = async (data) => {
    console.log("selectedItem", data);
    let response = await axios.get("accounting/get/lab-item/product-desc", {
      params: {
        item: data.label,
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
        user_id: users.user_id,
      },
    });

    setDescriptionList(response.data);
    // setSelectedItem(data);
  };

  const handleSelectedDesc = async (data) => {
    setSelectedDesc(data);
    console.log("selectedDesc", data);

    let response = await axios.get(
      "accounting/test/items/get-itemlist/batches",
      {
        params: {
          item_id: data.item_id,
          management_id: users.management_id,
          main_mgmt_id: users.main_mgmt_id,
          user_id: users.user_id,
        },
      }
    );
    setSelectedItemBatches(response.data);
  };

  useEffect(() => {
    getItemLab();
  }, [getItemLab]);

  return (
    <Card>
      <CardHeader title="New Delivery" />
      <CardContent>
        <Formik
          enableReinitialize
          initialValues={{
            management_id: users.management_id,
            user_id: users.user_id,

            supplier: selectDesc !== null ? selectDesc.supplier : "",
            batch_number: "",
            mfg_date: "",
            expiration_date: "",
            qty: "",
            unit: selectDesc !== null ? selectDesc.unit : "",
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);
              let response = await axios.post(
                "accounting/test/items/delivery/new-tempdr",
                getFormData(values)
              );
              if (response.data.message === "success") {
                Notify.successRequest("delivery");
                resetForm();
                rerender();
              }
            } catch (e) {
              setErrors({ submit: e.message });
              console.log("error", e);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
            isSubmitting,
            touched,
            setFieldValue,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Box mb={3}>
                <Select
                  options={list}
                  onChange={(data) => handleSelectedItem(data)}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 99999,
                      color: "#000",
                    }),
                    control: (base) => ({
                      ...base,
                      minHeight: 55,
                      backgroundColor: "transparent",
                    }),
                    singleValue: () => ({
                      color: theme.palette.type === "dark" ? "#fff" : "#000",
                    }),
                  }}
                />
              </Box>

              <Box mb={3}>
                <Select
                  options={descriptionList}
                  onChange={(data) => handleSelectedDesc(data)}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 99999,
                      color: "#000",
                    }),
                    control: (base) => ({
                      ...base,
                      minHeight: 55,
                      backgroundColor: "transparent",
                    }),
                    singleValue: () => ({
                      color: theme.palette.type === "dark" ? "#fff" : "#000",
                    }),
                  }}
                />
              </Box>

              <Box mb={3}>
                <TextField
                  error={Boolean(touched.supplier && errors.supplier)}
                  helperText={touched.supplier && errors.supplier}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.supplier}
                  fullWidth
                  required
                  name="supplier"
                  label="Supplier"
                  variant="outlined"
                  disabled
                />
              </Box>

              <Box mb={3} display="flex">
                <Box width={"90%"}>
                  <Select
                    options={selectedItemBatches}
                    onChange={(data) => {
                      if (data.batch_number === values.batch_number) {
                        console.log("batch currently selected");
                        return;
                      }
                      setFieldValue("batch_number", data.batch_number);
                      setFieldValue("mfg_date", data.mfg_date);
                      setFieldValue("expiration_date", data.expiration_date);
                    }}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 99999,
                        color: "#000",
                      }),
                      control: (base) => ({
                        ...base,
                        minHeight: 55,
                        backgroundColor: "transparent",
                      }),
                      singleValue: () => ({
                        color: theme.palette.type === "dark" ? "#fff" : "#000",
                      }),
                    }}
                  />
                </Box>
                <Box ml={1}>
                  <Button
                    variant="outlined"
                    color={newBatch ? "secondary" : "primary"}
                    onClick={() => {
                      if (newBatch) {
                        setFieldValue("batch_number", "");
                        setFieldValue("mfg_date", "");
                        setFieldValue("expiration_date", "");
                      }
                      setNewBatch(!newBatch);
                    }}
                  >
                    {newBatch ? "Existing Batch" : "New Batch"}
                  </Button>
                </Box>
              </Box>

              {newBatch && (
                <Box mb={3}>
                  <TextField
                    error={Boolean(touched.batch_number && errors.batch_number)}
                    helperText={touched.batch_number && errors.batch_number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.batch_number}
                    fullWidth
                    required
                    name="batch_number"
                    label="Batch Number"
                    variant="outlined"
                  />
                </Box>
              )}

              <Box mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.mfg_date && errors.mfg_date)}
                      helperText={touched.mfg_date && errors.mfg_date}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mfg_date}
                      fullWidth
                      required
                      name="mfg_date"
                      label="Mafactured Date"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(
                        touched.expiration_date && errors.expiration_date
                      )}
                      helperText={
                        touched.expiration_date && errors.expiration_date
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.expiration_date}
                      fullWidth
                      required
                      name="expiration_date"
                      label="Expirated Date"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.qty && errors.qty)}
                      helperText={touched.qty && errors.qty}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.qty}
                      fullWidth
                      required
                      name="qty"
                      label="Qty"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.unit && errors.unit)}
                      helperText={touched.unit && errors.unit}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.unit}
                      fullWidth
                      required
                      name="unit"
                      label="Unit"
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Box mt={3} display="flex">
                <Box mr={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    startIcon={
                      isSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                    disabled={isSubmitting}
                  >
                    Add
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    size="large"
                    color="default"
                    onClick={resetForm}
                  >
                    Clear
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default New;
