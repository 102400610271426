import TabGroupHorizontal from "src/components/TabGroupHorizontal";
import BloodPressureChart from "src/clinic/components/charts/BloodPressureChart";
import GlucoseChart from "src/clinic/components/charts/GlucoseChart";
import PulseChart from "src/clinic/components/charts/PulseChart";
import RespirationChart from "src/clinic/components/charts/RespirationChart";
import PainTable from "./charts/PainTable";
import WeightChart from "src/clinic/components/charts/WeightChart";
import UricAcidChart from "src/clinic/components/charts/UricAcidChart";
import CholesterolChart from "src/clinic/components/charts/CholesterolChart";
import ButtonV3 from "src/components/ButtonV3";
import { Fullscreen } from "@material-ui/icons";
import { useState } from "react";
import FullscreenModal from "./FullscreenModal";
import TemperatureChart from "src/clinic/components/charts/TemperatureChart";

export default function CisVitalsChart({ patient }) {
	const [viewPain, setViewPain] = useState(false);
	const [viewTemperature, setViewTemperature] = useState(false);
	const [viewBlood, setViewBlood] = useState(false);
	const [viewPluse, setViewPulse] = useState(false);
	const [viewRespiratory, setViewRespiratory] = useState(false);
	const [viewWeight, setViewWeight] = useState(false);
	const [viewGLucose, setViewGlucose] = useState(false);
	const [viewAcid, setViewAcid] = useState(false);
	const [viewCholesterol, setViewCholesterrol] = useState(false);

	const handleOpenPopup = (id) => {
		if (id === "pain") {
			setViewPain(true);
		}
		if (id === "temp") {
			setViewTemperature(true);
		}
		if (id === "blood") {
			setViewBlood(true);
		}
		if (id === "pulse") {
			setViewPulse(true);
		}
		if (id === "respiratory") {
			setViewRespiratory(true);
		}
		if (id === "weight") {
			setViewWeight(true);
		}
		if (id === "glucose") {
			setViewGlucose(true);
		}
		if (id === "uric") {
			setViewAcid(true);
		}
		if (id === "cholesterol") {
			setViewCholesterrol(true);
		}
	};
	const handleClosePopup = () => {
		setViewPain(false);
		setViewTemperature(false);
		setViewBlood(false);
		setViewPulse(false);
		setViewRespiratory(false);
		setViewWeight(false);
		setViewGlucose(false);
		setViewAcid(false);
		setViewCholesterrol(false);
	};
	return (
		<div>
			<TabGroupHorizontal
				contentClassName={"max-h "}
				contents={[
					// {
					// 	title: "Pain",
					// 	content: (
					// 		<div className="flex flex-col w-full relative">
					// 			<div className="flex items-center justify-between pl-0 pr-4">
					// 				<h3 className="font-bold  flex items-center gap-3 relative mb-3 text-2xl">
					// 					<img
					// 						src="/vitals/pain.png"
					// 						className="w-11 h-11 object-contain"
					// 					/>
					// 					Pain Monitoring
					// 				</h3>

					// 				<ButtonV3
					// 					type="primary"
					// 					onClick={() => handleOpenPopup("pain")}
					// 				>
					// 					<Fullscreen />
					// 					Fullscreen
					// 				</ButtonV3>
					// 				<FullscreenModal
					// 					title={
					// 						<h3 className="font-bold  flex items-center gap-3 relative mb-3 text-2xl">
					// 							<img
					// 								src="/vitals/pain.png"
					// 								className="w-11 h-11 object-contain"
					// 							/>
					// 							Pain Monitoring
					// 						</h3>
					// 					}
					// 					open={viewPain}
					// 					onClose={handleClosePopup}
					// 					content={
					// 						<PainTable
					// 							patient={patient}
					// 							viewPain={viewPain}
					// 						/>
					// 					}
					// 				/>
					// 			</div>

					// 			<div>
					// 				<PainTable
					// 					patient={patient}
					// 					viewPain={viewPain}
					// 				/>
					// 			</div>
					// 		</div>
					// 	),
					// },
					{
						title: "Temperature",
						content: (
							<div className="flex flex-col w-full relative">
								<div className="flex items-center justify-between pl-0 pr-4">
									<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
										<img
											src="/vitals/temperature-celcius.png"
											className="w-11 h-11 object-contain"
										/>
										Temperature
									</h3>
									<ButtonV3
										type="primary"
										onClick={() => handleOpenPopup("temp")}
									>
										<Fullscreen />
										Fullscreen
									</ButtonV3>
									<FullscreenModal
										title={
											<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
												<img
													src="/vitals/temperature-celcius.png"
													className="w-11 h-11 object-contain"
												/>
												Temperature
											</h3>
										}
										open={viewTemperature}
										onClose={handleClosePopup}
										content={
											<div className="mx-auto">
												<TemperatureChart
													w={900}
													h={400}
													patient={patient}
												/>
											</div>
										}
									/>
								</div>
								<div className="mx-auto">
									<TemperatureChart
										w={512}
										h={384}
										patient={patient}
									/>
								</div>
							</div>
						),
					},
					{
						title: "Blood Pressure",
						content: (
							<div className="flex flex-col w-full relative">
								<div className="flex items-center justify-between pl-0 pr-4">
									<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
										<img
											src="/vitals/bp.png"
											className="w-11 h-11 object-contain"
										/>
										Blood Pressure
									</h3>
									<ButtonV3
										type="primary"
										onClick={() => handleOpenPopup("blood")}
									>
										<Fullscreen />
										Fullscreen
									</ButtonV3>
									<FullscreenModal
										title={
											<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
												<img
													src="/vitals/bp.png"
													className="w-11 h-11 object-contain"
												/>
												Blood Pressure
											</h3>
										}
										open={viewBlood}
										onClose={handleClosePopup}
										content={
											<div className="mx-auto">
												<BloodPressureChart
													w={900}
													h={400}
													patient={patient}
												/>
											</div>
										}
									/>
								</div>
								<div className="mx-auto">
									<BloodPressureChart
										w={512}
										h={384}
										patient={patient}
									/>
								</div>
							</div>
						),
					},
					{
						title: "Pulse",
						content: (
							<div className="flex flex-col w-full relative">
								<div className="flex items-center justify-between pl-0 pr-4">
									<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
										<img
											src="/vitals/heart-rate.png"
											className="w-11 h-11 object-contain"
										/>
										Pulse
									</h3>
									<ButtonV3
										type="primary"
										onClick={() => handleOpenPopup("pulse")}
									>
										<Fullscreen />
										Fullscreen
									</ButtonV3>
									<FullscreenModal
										title={
											<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
												<img
													src="/vitals/heart-rate.png"
													className="w-11 h-11 object-contain"
												/>
												Pulse
											</h3>
										}
										open={viewPluse}
										onClose={handleClosePopup}
										content={
											<div className="mx-auto">
												<PulseChart
													w={900}
													h={400}
													patient={patient}
												/>
											</div>
										}
									/>
								</div>
								<div className="mx-auto">
									<PulseChart
										w={512}
										h={384}
										patient={patient}
									/>
								</div>
							</div>
						),
					},
					{
						title: "Respiratory",
						content: (
							<div className="flex flex-col w-full relative">
								<div className="flex items-center justify-between pl-0 pr-4">
									<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
										<img
											src="/vitals/respiration.png"
											className="w-11 h-11 object-contain"
										/>
										Respiratory
									</h3>
									<ButtonV3
										type="primary"
										onClick={() =>
											handleOpenPopup("respiratory")
										}
									>
										<Fullscreen />
										Fullscreen
									</ButtonV3>
									<FullscreenModal
										title={
											<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
												<img
													src="/vitals/respiration.png"
													className="w-11 h-11 object-contain"
												/>
												Respiratory
											</h3>
										}
										open={viewRespiratory}
										onClose={handleClosePopup}
										content={
											<div className="mx-auto">
												<RespirationChart
													w={900}
													h={400}
													patient={patient}
												/>
											</div>
										}
									/>
								</div>
								<div className="mx-auto">
									<RespirationChart
										w={512}
										h={384}
										patient={patient}
									/>
								</div>
							</div>
						),
					},
					{
						title: "Weight",
						content: (
							<div className="flex flex-col w-full relative">
								<div className="flex items-center justify-between pl-0 pr-4">
									<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
										<img
											src="/vitals/weight.png"
											className="w-11 h-11 object-contain"
										/>
										Weight
									</h3>
									<ButtonV3
										type="primary"
										onClick={() =>
											handleOpenPopup("weight")
										}
									>
										<Fullscreen />
										Fullscreen
									</ButtonV3>
									<FullscreenModal
										title={
											<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
												<img
													src="/vitals/weight.png"
													className="w-11 h-11 object-contain"
												/>
												Weight
											</h3>
										}
										open={viewWeight}
										onClose={handleClosePopup}
										content={
											<div className="mx-auto">
												<WeightChart
													w={900}
													h={400}
													patient={patient}
												/>
											</div>
										}
									/>
								</div>
								<div className="mx-auto">
									<WeightChart
										w={512}
										h={384}
										patient={patient}
									/>
								</div>
							</div>
						),
					},
					{
						title: "Glucose",
						content: (
							<div className="flex flex-col w-full relative">
								<div className="flex items-center justify-between pl-0 pr-4">
									<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
										<img
											src="/vitals/glucose.png"
											className="w-11 h-11 object-contain"
										/>
										Glucose
									</h3>
									<ButtonV3
										type="primary"
										onClick={() =>
											handleOpenPopup("glucose")
										}
									>
										<Fullscreen />
										Fullscreen
									</ButtonV3>
									<FullscreenModal
										title={
											<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
												<img
													src="/vitals/glucose.png"
													className="w-11 h-11 object-contain"
												/>
												Glucose
											</h3>
										}
										open={viewGLucose}
										onClose={handleClosePopup}
										content={
											<div className="mx-auto">
												<GlucoseChart
													w={900}
													h={400}
													patient={patient}
												/>
											</div>
										}
									/>
								</div>
								<div className="mx-auto">
									<GlucoseChart
										w={512}
										h={384}
										patient={patient}
									/>
								</div>
							</div>
						),
					},
					{
						title: "Uric Acid",
						content: (
							<div className="flex flex-col w-full relative">
								<div className="flex items-center justify-between pl-0 pr-4">
									<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
										<img
											src="/vitals/uric-acid.png"
											className="w-11 h-11 object-contain"
										/>
										Uric Acid
									</h3>
									<ButtonV3
										type="primary"
										onClick={() => handleOpenPopup("uric")}
									>
										<Fullscreen />
										Fullscreen
									</ButtonV3>
									<FullscreenModal
										title={
											<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
												<img
													src="/vitals/uric-acid.png"
													className="w-11 h-11 object-contain"
												/>
												Uric Acid
											</h3>
										}
										open={viewAcid}
										onClose={handleClosePopup}
										content={
											<div className="mx-auto">
												<UricAcidChart
													w={900}
													h={400}
													patient={patient}
												/>
											</div>
										}
									/>
								</div>
								<div className="mx-auto">
									<UricAcidChart
										w={512}
										h={384}
										patient={patient}
									/>
								</div>
							</div>
						),
					},
					{
						title: "Cholesterol",
						content: (
							<div className="flex flex-col w-full relative">
								<div className="flex items-center justify-between pl-0 pr-4">
									<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
										<img
											src="/vitals/LDL.png"
											className="w-11 h-11 object-contain"
										/>
										Cholesterol
									</h3>
									<ButtonV3
										type="primary"
										onClick={() =>
											handleOpenPopup("cholesterol")
										}
									>
										<Fullscreen />
										Fullscreen
									</ButtonV3>
									<FullscreenModal
										title={
											<h3 className="font-bold px-0 flex items-center gap-3 relative mb-3 text-2xl">
												<img
													src="/vitals/LDL.png"
													className="w-11 h-11 object-contain"
												/>
												Cholesterol
											</h3>
										}
										open={viewCholesterol}
										onClose={handleClosePopup}
										content={
											<div className="mx-auto">
												<CholesterolChart
													w={900}
													h={400}
													patient={patient}
												/>
											</div>
										}
									/>
								</div>
								<div className="mx-auto">
									<CholesterolChart
										w={512}
										h={384}
										patient={patient}
									/>
								</div>
							</div>
						),
					},
				]}
			/>
		</div>
	);
}
