import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import Divider from "@material-ui/core/Divider";
import ECGPrintAll from "./forms/ECGPrintAll";

const ECGDashPrintAll = ({
  allowHeaderPatientInfo,
  patient_id,
  trace_number,
  patients,
  selectedBranch,
}) => {
  const { users } = useContext(UsersData);
  const [ECGOrderDetails, setECGOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const getECGOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get("laboratory/order/ordernew-ecg/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setECGOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getECGOrderDetails();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {ECGOrderDetails.ready && ECGOrderDetails.data.length > 0 && (
        <>
          <ECGPrintAll
            allowHeaderPatientInfo={allowHeaderPatientInfo}
            patients={patients}
            ECGOrderDetails={ECGOrderDetails}
          />
          <Divider light />
        </>
      )}
    </>
  );
};

export default ECGDashPrintAll;
