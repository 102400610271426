import React, { useContext, useEffect, useState } from "react";
import Container from "src/layout/Container";
import {
  Card,
  //   CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  Dialog,
  Box,
  Zoom,
  Divider,
  TextField,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import Notify from "src/notification/Notify";
// import { Print } from "@material-ui/icons";
import { Filter, X, Search } from "react-feather";
import PrintMobileVanOrders from "./PrintMobileVanOrders";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import { useHistory } from "react-router";
import PrintMobileVanOrdersPsychology from "./PrintMobileVanOrdersPsychology";
// import PrintMobileVanOrderDoctor from "./PrintMobileVanOrderDoctor";
import PrintMobileVanAllOrders from "./PrintMobileVanAllOrders";
import PrintMobileVanOrdersImaging from "./PrintMobileVanOrdersImaging";
// import PrintMobileVanOrderECG from "./PrintMobileVanOrderECG";
import PrintMobileVanOrderOther from "./PrintMobileVanOrderOther";
// import XrayPrintLayout from "./XrayPrintLayout";

const PrintResultForVan = () => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [patientList, setPatientList] = useState({ data: [], ready: false });
  const [selectedTraceNoData, setSelectedTraceNoData] = useState(null);
  const [printDialogResult, setPrintDialogResult] = useState(false);
  const [printPsychologyDialogResult, setPrintPsychologyDialogResult] =
    useState(false);
  const [printImagingDialogResult, setPrintImagingDialogResult] =
    useState(false);
  // const [printDoctorDialogResult, setPrintDoctorDialogResult] = useState(false);
  // const [printECGDialogResult, setPrintECGDialogResult] = useState(false);
  const [printOtherDialogResult, setPrintOtherDialogResult] = useState(false);
  const [printAllDataInOnePaper, setPrintAllDataInOnePaper] = useState(false);
  const [docuFilter, setDocuFilter] = useState(false);
  const [accreditedCompany, setAccreditedCompany] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [branchList, setBranchList] = useState({ data: [], ready: false });
  const [anchorEl, setAnchorEl] = useState(null);
  // const history = useHistory();

  const getAllBranches = React.useCallback(async () => {
    var params = {
      management_id: users.management_id,
      main_management_id: users.main_mgmt_id,
    };
    try {
      let response = await axios.get("hr/get/all-branches", { params });
      const data = response.data;
      setBranchList({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users.management_id, users.main_mgmt_id]);

  const getAccreditedCompanyList = React.useCallback(async () => {
    let response = await axios.get("admission/accredited/company/get-list", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
      },
    });

    if (mounted.current) {
      setAccreditedCompany(response.data);
    }
  }, [mounted, users.management_id, users.main_mgmt_id]);

  const handleSearchPatient = React.useCallback(async () => {
    var params = {
      user_id: users.user_id,
      company: selectedCompany,
      management_id: selectedBranch,
    };
    axios.get("van/get/online/to-print", { params }).then((response) => {
      const data = response.data;
      if (mounted.current) {
        setPatientList({ data, ready: true });
        setIsSearch(true);
      }
    });
  }, [users.user_id, mounted, selectedCompany, selectedBranch]);

  const handleCloseAction = () => {
    setAnchorEl(null);
  };

  const handleSelectedAction = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedTraceNoData(data);
  };

  useEffect(() => {
    getAllBranches();
    getAccreditedCompanyList();
  }, [getAllBranches, getAccreditedCompanyList]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Van Result Print",
        items: [{ name: "Dashboard", path: "/sph/app" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Van result print</Box>
        </Box>
      }
    >
      <Card>
        {/* <CardHeader title="Documentation's encoded" /> */}
        <Box m={0} display="flex" justifyContent="center" alignItems="center">
          <Box flexGrow={1} m={2} />
          <Box>
            <IconButton onClick={() => setDocuFilter(true)}>
              <Filter fontSize="small" />
            </IconButton>
          </Box>
        </Box>

        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isSearch ? (
                  patientList.ready ? (
                    patientList.data.length > 0 ? (
                      patientList.data.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {Notify.createdAt(data.created_at)}
                          </TableCell>
                          <TableCell>
                            {data.lastname}, {data.firstname}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              onClick={(e) => handleSelectedAction(e, data)}
                            >
                              <MoreVertIcon fontSize="small" />
                            </IconButton>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleCloseAction}
                            >
                              <MenuItem
                                onClick={() => setPrintDialogResult(true)}
                              >
                                Laboratory
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setPrintImagingDialogResult(true);
                                  // history.push(
                                  //   `/sph/app/documentation/imaging/${selectedTraceNoData.patient_id}/${selectedTraceNoData.management_id}`
                                  // );
                                }}
                              >
                                Imaging
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  setPrintPsychologyDialogResult(true)
                                }
                              >
                                Psychology
                              </MenuItem>
                              {/* <MenuItem
                                onClick={() => setPrintDoctorDialogResult(true)}
                              >
                                Doctor
                              </MenuItem> */}

                              {/* <MenuItem
                                onClick={() => setPrintECGDialogResult(true)}
                              >
                                ECG
                              </MenuItem> */}

                              <MenuItem
                                onClick={() => setPrintOtherDialogResult(true)}
                              >
                                Other
                              </MenuItem>
                              <MenuItem
                                onClick={() => setPrintAllDataInOnePaper(true)}
                              >
                                All
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <Typography color="secondary">
                            No result to edit.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <Typography color="primary">
                          Loading, please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography color="secondary">
                        Select Filter First
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {/* laboratory */}
      <Dialog
        open={printDialogResult}
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={800}
        onClose={() => {
          setPrintDialogResult(false);
          setSelectedTraceNoData(null);
        }}
      >
        <Box>
          <PrintMobileVanOrders
            close={() => {
              setPrintDialogResult(false);
            }}
            selectedTraceNoData={selectedTraceNoData}
            handleSearchPatient={() => handleSearchPatient()}
            selectedBranch={selectedBranch}
          />
        </Box>
      </Dialog>

      {/* Psychology */}
      <Dialog
        open={printPsychologyDialogResult}
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={800}
        onClose={() => {
          setPrintPsychologyDialogResult(false);
          setSelectedTraceNoData(null);
        }}
      >
        <Box>
          <PrintMobileVanOrdersPsychology
            close={() => {
              setPrintPsychologyDialogResult(false);
            }}
            selectedTraceNoData={selectedTraceNoData}
            handleSearchPatient={() => handleSearchPatient()}
            selectedBranch={selectedBranch}
          />
        </Box>
      </Dialog>

      {/* imaging is redirect */}
      <Dialog
        open={printImagingDialogResult}
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={800}
        onClose={() => {
          setPrintImagingDialogResult(false);
          setSelectedTraceNoData(null);
        }}
      >
        <Box>
          <PrintMobileVanOrdersImaging
            close={() => {
              setPrintImagingDialogResult(false);
            }}
            selectedTraceNoData={selectedTraceNoData}
            handleSearchPatient={() => handleSearchPatient()}
            selectedBranch={selectedBranch}
          />
        </Box>
      </Dialog>

      {/* doctor */}
      {/* <Dialog
        open={printDoctorDialogResult}
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={800}
        onClose={() => {
          setPrintDoctorDialogResult(false);
          setSelectedTraceNoData(null);
        }}
      >
        <Box>
          <PrintMobileVanOrderDoctor
            close={() => {
              setPrintDoctorDialogResult(false);
            }}
            selectedTraceNoData={selectedTraceNoData}
            handleSearchPatient={() => handleSearchPatient()}
            selectedBranch={selectedBranch}
          />
        </Box>
      </Dialog> */}

      {/* ecg */}
      {/* <Dialog
        open={printECGDialogResult}
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={800}
        onClose={() => {
          setPrintECGDialogResult(false);
          setSelectedTraceNoData(null);
        }}
      >
        <Box>
          <PrintMobileVanOrderECG
            close={() => {
              setPrintECGDialogResult(false);
            }}
            selectedTraceNoData={selectedTraceNoData}
            handleSearchPatient={() => handleSearchPatient()}
            selectedBranch={selectedBranch}
          />
        </Box>
      </Dialog> */}

      {/* other */}
      <Dialog
        open={printOtherDialogResult}
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={800}
        onClose={() => {
          setPrintOtherDialogResult(false);
          setSelectedTraceNoData(null);
        }}
      >
        <Box>
          <PrintMobileVanOrderOther
            close={() => {
              setPrintOtherDialogResult(false);
            }}
            selectedTraceNoData={selectedTraceNoData}
            handleSearchPatient={() => handleSearchPatient()}
            selectedBranch={selectedBranch}
          />
        </Box>
      </Dialog>

      {/* print all */}
      <Dialog
        open={printAllDataInOnePaper}
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={800}
        onClose={() => {
          setPrintAllDataInOnePaper(false);
          setSelectedTraceNoData(null);
        }}
      >
        <Box>
          <PrintMobileVanAllOrders
            close={() => {
              setPrintAllDataInOnePaper(false);
            }}
            selectedTraceNoData={selectedTraceNoData}
            selectedBranch={selectedBranch}
          />
          {/* <PrintMobileVanOrderDoctor
            close={() => {
              setPrintAllDataInOnePaper(false);
            }}
            selectedTraceNoData={selectedTraceNoData}
            handleSearchPatient={() => handleSearchPatient()}
            selectedBranch={selectedBranch}
          /> */}
        </Box>
      </Dialog>

      {/* filter */}
      <Dialog
        open={docuFilter}
        onClose={() => setDocuFilter(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="xs"
        fullWidth
      >
        <Box m={2}>
          <Box mb={1}>
            <Typography variant="h5">Filter</Typography>
          </Box>
          <Divider light />
          <Box mt={2} mb={2}>
            <TextField
              select
              SelectProps={{ native: true }}
              onChange={(e) => {
                setSelectedBranch(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              name="branch"
              label="Branch"
              variant="outlined"
              value={selectedBranch}
            >
              <option value={""}> Select </option>
              {branchList.data.length > 0 ? (
                branchList.data.map((data, index) => (
                  <option key={index} value={data.value}>
                    {data.label}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No branches found.
                </option>
              )}
            </TextField>
          </Box>
          <Box mb={2}>
            <TextField
              select
              SelectProps={{ native: true }}
              onChange={(e) => setSelectedCompany(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              name="company"
              label="Company"
              variant="outlined"
              value={selectedCompany}
            >
              <option value={""}> Select </option>
              {accreditedCompany.length > 0 ? (
                accreditedCompany.map((data, index) => (
                  <option key={index} value={data.company_id}>
                    {data.label}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No company found.
                </option>
              )}
            </TextField>
          </Box>

          <Box display="flex">
            <Box flexGrow={1} />
            <Box mr={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<X />}
                onClick={() => setDocuFilter(false)}
              >
                Close
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Search />}
                disabled={selectedCompany === null}
                onClick={handleSearchPatient}
              >
                Search
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Container>
  );
};

export default PrintResultForVan;
