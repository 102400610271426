import {
	Box,
	Button,
	Card,
	TextField,
	TextareaAutosize,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { CloudUpload } from "@material-ui/icons";
import { styles } from "../../styles/styles";
import ButtonV3 from "src/components/ButtonV3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import ImagePicker from "src/components/forms/ImagePicker";
import Img from "src/components/Img";
import TextInputField from "src/components/forms/TextInputField";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";
import { dataURItoBlob } from "src/helpers/utils";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import ReactQuillField from "src/components/forms/ReactQuillField";

const useStyles = makeStyles((theme) => ({
	button: {
		width: "100%",
		fontSize: "18px",
		padding: "6px",
	},
}));

export default function CisAddLaboratoryList({
	labType,
	typeOptions,
	setShown,
	refreshData,
	patient,
}) {
	const classes = useStyles();
	const [imagePreview, setImagePreview] = React.useState(null);

	const handleImageChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImagePreview(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const {
		register,
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		console.log(data);
		let formData = new FormData();
		formData.append("laboratory_order_id", 0);
		formData.append("laboratory_test_id", data?.laboratory_test_id);
		formData.append("results", data?.results);
		formData.append("remarks", data?.remarks);
		if (imagePreview) {
			const file = dataURItoBlob(imagePreview);
			formData.append("image", file);
		}
		// console.log(formData);
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		let url = "/v1/laboratory/results/store";
		axios
			.post(url, formData, config)
			.then((res) => {
				setTimeout(() => {
					refreshData();
					toast.success("Laboratory result successfully added!");
				}, 200);
				setShown(true);
				// hide();
			})
			.catch((err) => {
				toast.error("Error adding image, please check your input!");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className="flex w-full flex-col">
			<div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
				<div className="lg:col-span-7">
					<Controller
						name="image"
						control={control}
						rules={{
							required: {
								value: true,
								message: "This field is required",
							},
						}}
						render={({
							field: { onChange, onBlur, value, name, ref },
							fieldState: { invalid, isTouched, isDirty, error },
						}) => (
							<ImagePicker
								className={`rounded-full w-full bg-primary bg-opacity-40 group-hover:bg-primary group-hover:bg-opacity-100 duration-200 aspect-[3/2] ${
									errors?.image?.message
										? " border !border-danger"
										: ""
								}`}
								onChange={(data) => {
									setImagePreview(data);
									onChange(data);
									// setUser((prevData) => ({
									// 	...prevData,
									// 	avatar: data,
									// }));
									// setAvatar(data);
								}}
							>
								{() => {
									return (
										<Img
											key={`imgng-${patient?.id}`}
											src={
												imagePreview
													? imagePreview
													: `/no-image.png`
											}
											name={patient?.name}
											// type="user"
											alt=""
											className="object-contain bg-slate-300 w-full h-full rounded-lg"
										/>
									);
								}}
							</ImagePicker>
						)}
					/>

					{errors?.image?.message ? (
						<p className="-mt-2 text-sm text-red-600 dark:text-red-500 mb-0">
							{errors?.image?.message}
						</p>
					) : (
						""
					)}
				</div>
				<div className="lg:col-span-5 flex flex-col gap-y-4">
					<Controller
						name="laboratory_test_id"
						control={control}
						rules={{
							required: {
								value: true,
								message: "This field is required",
							},
						}}
						render={({
							field: { onChange, onBlur, value, name, ref },
							fieldState: { invalid, isTouched, isDirty, error },
						}) => (
							<ReactSelectInputField
								isClearable={false}
								label={
									<>
										Select Test Type
										<span className="text-danger ml-1">
											*
										</span>
									</>
								}
								inputClassName=" "
								ref={ref}
								value={value}
								onChange={onChange}
								onChangeGetData={(data) => {
									onChange(data?.value);
								}} // send value to hook form
								onBlur={onBlur} // notify when input is touched
								error={error?.message}
								placeholder="Select type"
								options={typeOptions}
							/>
						)}
					/>
					{/* <TextInputField
						label="Remarks"
						placeholder="Enter remarks"
						{...register("remarks", {
							required: {
								value: true,
								message: "This field is required.",
							},
						})}
						error={errors?.remarks?.message}
					/> */}

					<Controller
						name="remarks"
						control={control}
						rules={{
							required: {
								value: true,
								message: "This field is required",
							},
						}}
						render={({
							field: { onChange, onBlur, value, name, ref },
							fieldState: { invalid, isTouched, isDirty, error },
						}) => (
							<ReactQuillField
								name={name}
								error={error}
								label="Remarks"
								value={value}
								onChange={onChange}
								className="lg:col-span-12"
							/>
						)}
					/>

					<ButtonV3 onClick={handleSubmit(onSubmit)}>Submit</ButtonV3>
				</div>
			</div>
		</div>
	);
}
