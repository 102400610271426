import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";

const CSQMMHCovid19CheckListP2 = () => {
  return (
    <Box>
      <Box mb={2} mt={2}>
        <Typography component={"span"} variant="subtitle1">
          <span style={{ color: "red", fontWeight: "bold" }}>
            STEP 2. CASE DEFINITION
          </span>
        </Typography>
      </Box>

      <TableContainer>
        <Table className="table-bordered" size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "#79d8ed" }}>
              <TableCell align="center">
                <b>NON-COVID CASE</b>
              </TableCell>
              <TableCell align="center">
                <b>
                  SUSPECT <br /> Presence of Step 1 <br /> Plus any of the
                  following
                </b>
              </TableCell>
              <TableCell align="center">
                <b>
                  PROBABLE <br /> Presence of Step 1 <br /> Plus any one of the
                  following
                </b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  width: "33%",
                  fontSize: 14,
                  color: "#000",
                }}
              >
                *Absence of any of the symptoms listed in step 1 <br />
                <br />
                *Cannot be classified as suspect or probable
              </TableCell>
              <TableCell
                style={{
                  width: "34%",
                  fontSize: 14,
                  color: "#000",
                }}
              >
                *No other etiology fully explains the clinical presentation
                <br />
                <br />
                *No other etiology fully explains the clinical presentation and
                a history of travel to or residence in an area that reported
                local transmission of COVID-19 disease during the 14 days to
                symptom onset <br />
                <br />
                *With contact to a confirmed or probable case of COVID-19 <br />
                <br />
                *Exposures during the 2 days before and the 14 days after the
                onset of symptoms of a probable or confirmed case: <br />
                <br />
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>1.</Box>
                  <Box>
                    Face-to-face contact with a probable or confirmed case
                    within 1 meter and for more than 15 minutes
                  </Box>
                </Box>
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>2.</Box>
                  <Box>
                    Direct physical contact with a probable or confirmed case
                  </Box>
                </Box>
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>3.</Box>
                  <Box>
                    Direct care for a patient with probable or confirmed
                    COVID-19 disease without using proper PPE
                  </Box>
                </Box>
                <Box ml={4} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>Aged 60 years and above</Box>
                </Box>
                <Box ml={4} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>With a comorbidity</Box>
                </Box>
                <Box ml={4} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>High-risky pregnancy</Box>
                </Box>
                <Box ml={4} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>Health worker</Box>
                </Box>
              </TableCell>
              <TableCell
                style={{
                  width: "33%",
                  fontSize: 14,
                  color: "#000",
                }}
              >
                *Suspect case whom testing for COVID-19 is inconclusive
                <br />
                <br />
                *Suspect who underwent testing for COVID-19 not conducted in a
                officially accredited laboratory for COVID-19 confirmatory
                testing
                <br />
                <br />
                *Suspect case for whom testing could not be performed for any
                reason
                <br />
                <br />
              </TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow style={{ backgroundColor: "#79d8ed" }}>
              <TableCell align="center">
                <b>USUAL CARE</b>
              </TableCell>
              <TableCell align="center" colSpan={2}>
                <b>RAT OR RT-PCR</b>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>

      <Box className="next-page" />

      <Box mb={2} mt={2}>
        <Typography component={"span"} variant="subtitle1">
          <span style={{ color: "red", fontWeight: "bold" }}>
            STEP 3. SEVERITY CLASSIFICATION
          </span>
        </Typography>
      </Box>

      <TableContainer>
        <Table className="table-bordered" size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "#79d8ed" }}>
              <TableCell align="center">
                <b>MILD</b>
              </TableCell>
              <TableCell align="center">
                <b>MODERATE</b>
              </TableCell>
              <TableCell align="center">
                <b>SEVERE</b>
              </TableCell>
              <TableCell align="center">
                <b>CRITICAL</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  width: "25%",
                  fontSize: 14,
                  color: "#000",
                }}
              >
                *Symptomatic patients with NO signs of pneumonia or hypoxia
              </TableCell>
              <TableCell style={{ width: "25%", fontSize: 14, color: "#000" }}>
                *Adolescent or adult with clinical signs of non-severe pneumonia
                <br />
                <br />
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>RR = 21-30cm</Box>
                </Box>
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>{`O2 sat = >92% room air`}</Box>
                </Box>
                *Child with clinical signs of non-severe pneumonia <br />
                <br />
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>{`< 2 months RR`}</Box>
                </Box>
                <Box ml={2} mb={2} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>{`< 50 cpm`}</Box>
                </Box>
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>{`2-11 months RR`}</Box>
                </Box>
                <Box ml={2} mb={2} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>{`< 50 cpm`}</Box>
                </Box>
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>{`1-5 years RR`}</Box>
                </Box>
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>{`< 40 cpm`}</Box>
                </Box>
              </TableCell>
              <TableCell
                style={{
                  width: "25%",
                  fontSize: 14,
                  color: "#000",
                }}
              >
                *Adolescent or adult with clinical signs of severe pneumonia or
                severe acute respiratory infection
                <br />
                <br />
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>Fever</Box>
                </Box>
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>Cough</Box>
                </Box>
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>{`RR > 30 cpm`}</Box>
                </Box>
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>&bull;</Box>
                  <Box>O2 &#x2264; 92% room air</Box>
                </Box>
                *Child with clinical signs of non-severe pneumonia (cough of
                difficulty of breathing) plus atleast one of the following:
                <br />
                <br />
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>1.</Box>
                  <Box>{`Central cyanosis or 02 < 90%; severe respiratory distress (fast breathing grunting, very severe chest indrawing); general danger sign. Inability to breastfeed or drink, lethargy or unconsciousness, or convulsions`}</Box>
                </Box>
                <Box ml={2} mb={1} display={"flex"}>
                  <Box mr={1}>2.</Box>
                  <Box>{`Fast breathing`}</Box>
                </Box>
                <Box ml={4} mb={1}>
                  <Box>{`<2 months`}</Box>
                  <Box>RR &ge; 60cpm</Box>
                  <Box mt={1}>2-11months</Box>
                  <Box>RR &ge; 50cpm</Box>
                  <Box mt={1}>1-5 years</Box>
                  <Box>RR &ge; 40cpm</Box>
                </Box>
              </TableCell>
              <TableCell style={{ width: "25%", fontSize: 14, color: "#000" }}>
                *Acute Respiratory Distress Syndrome (ARDS)
                <br />
                <br />
                a.Patients with onset within 1 week of known critical insult
                (pneumonia) or new or worsening respiratory symptoms,
                progressing infiltrates on chest X-ray or chest CT scan, with
                respiratory failure not fully explained by cardiac failure or
                fluid overload.
                <br />
                <br />
                Sepsis
                <br />
                <br />
                a.Adults with life-threatening organ dysfunction caused by a
                dysregulated host response to suspended or proven infection.
                <br />
                <br />
                Signs of organ dysfunction include altered mental status,
                difficult or fast breathing, low oxygen saturation, reduced
                urine output, fast heart rate, weak pulse, cold extremities or
                low blood pressure, skin mottling, or laboratory evidence of
                coagulopathy, thrombocytopenia, acidosis, high lactate or
                hyperbilirubinemia
                <br />
                <br />
                b.Children with suspected or proven infection and {`>`} 2
                age-based systemic inflammatory response syndrome criteria
                (abnormal temperature({`>`} 38.5 &#8451; or {`<`} 36 &#8451;);
                tachycardia for age if {`<`} 1 year; tachypnea for age or need
                for mechanical ventilation; abnormal white blood cell count for
                age or {`>`} 10% bands), of which one must be abnormal
                temperature or white blood cell count.
                <br />
                <br />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CSQMMHCovid19CheckListP2;
