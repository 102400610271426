import { useLocation, useHistory } from "react-router-dom";
import FlatIcon from "src/components/FlatIcon";
import Img from "src/components/Img";
import {
	calculateAge,
	calculateAgeV3,
	patientFullName,
} from "src/helpers/utils";

export default function CisPatientListItem({
	patient,
	onMouseEnter,
	onClick,
	onMouseLeave,
}) {
	const history = useHistory();
	return (
		<div
			className="!border !border-slate-100 bg-white hover:!bg-blue-500 group duration-300 cursor-pointer rounded-[12px] bg-opacity-50 p-[16px] gap-3 flex items-start"
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className="relative w-11 aspect-square rounded-lg min-w-[44px]">
				<Img
					src={
						patient?.avatar?.length > 0
							? patient?.avatar
							: patient?.avatarBase64
							? patient?.avatarBase64
							: patient?.src
					}
					type="user"
					name={`${patient?.lastname}-${patient?.firstname}-${patient?.middle}`}
					className="absolute top-0 left-0 w-full h-full rounded-lg !border !border-white object-contain bg-slate-200"
				/>
			</div>
			<div className="flex flex-col gap-2 w-full">
				<span className={`font-bold text-sm group-hover:text-white`}>
					{` ${patient?.lastname}, ${patient?.firstname} ${patient?.middle}`}
				</span>
				<div className="flex items-center gap-4 divide-x !text-xs text-slate-600 group-hover:text-white">
					<span className="text-xs flex items-center gap-2">
						<FlatIcon icon="rr-venus-mars" className="mb-[-2px]" />
						<span className="capitalize">{patient?.gender}</span>
					</span>
					<span className="text-xs flex items-center gap-2 pl-3">
						<FlatIcon icon="rr-calendar" className="mb-[-2px]" />
						<span className="">
							{patient?.birthday?.length > 0
								? `${calculateAgeV3(patient?.birthday)} old`
								: "-"}
						</span>
					</span>
				</div>
			</div>
		</div>
	);
}
