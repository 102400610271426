import React, { useState } from "react";
import {
  faArrowLeft,
  faPencilAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CisNewAppointments from "./CisNewAppointments";
import { formatDate } from "src/helpers/utils";
import { Box, Button, Card } from "@material-ui/core";
import { styles } from "../styles/styles";
import ButtonV3 from "src/components/ButtonV3";

export default function CisAppointments() {
  const [shown, setShown] = useState(true);
  const data = [
    {
      id: 123,
      date: "09/12/2022",
      complaint: "Stomach Ache",
      service: "Physical",
      amount: "500.00",
      status: "Pending",
      reschedule: "Yes",
    },
    {
      id: 456,
      date: "2023-02-05",
      complaint: "Head Ache",
      service: "Consultation",
      amount: "300.00",
      status: "Pending",
      reschedule: "Yes",
    },
    {
      id: 789,
      date: "2022-09-09",
      complaint: "Back Ache",
      service: "Consultation",
      amount: "1200.00",
      status: "Complete",
      reschedule: "Yes",
    },
  ];
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full mb-2 items-center">
        <div style={styles.headerContainer}>
          <b className="text-2xl">Appointments</b>
        </div>
        {shown ? (
          <ButtonV3
            onClick={() => {
              setShown(false);
            }}
          >
            <div className="flex">
              <FontAwesomeIcon
                icon={faPlus}
                color="primary"
                style={{ fontSize: 18 }}
                className="pr-2"
              />
              Add
            </div>
          </ButtonV3>
        ) : (
          <ButtonV3
            onClick={() => {
              setShown(true);
            }}
          >
            <div className="flex">
              <FontAwesomeIcon
                icon={faArrowLeft}
                color="primary"
                style={{ fontSize: 18 }}
                className="pr-2"
              />
              back
            </div>
          </ButtonV3>
        )}
      </div>
      {shown ? (
        <Card elevation={3} className="p-4" component={Box}>
          <TableContainer style={styles.parentContainer}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={styles.tableHeader}>
                    <strong>Date</strong>
                  </TableCell>
                  <TableCell colSpan={3} style={styles.tableHeader}>
                    <strong>Complaint</strong>
                  </TableCell>
                  <TableCell style={styles.tableHeader}>
                    <strong>Service</strong>
                  </TableCell>
                  <TableCell style={styles.tableHeader}>
                    <strong>Amount</strong>
                  </TableCell>
                  <TableCell style={styles.tableHeader}>
                    <strong>Status</strong>
                  </TableCell>
                  <TableCell style={styles.tableHeader}>
                    <strong>Rescheduled?</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(
                  ({
                    id,
                    date,
                    status,
                    amount,
                    reschedule,
                    service,
                    complaint,
                  }) => (
                    <>
                      <TableRow key={id}>
                        <TableCell>{formatDate(new Date(date))}</TableCell>
                        <TableCell colSpan={3}>{complaint}</TableCell>
                        <TableCell>{service}</TableCell>
                        <TableCell>{amount}</TableCell>
                        <TableCell>{status}</TableCell>
                        <TableCell>{reschedule}</TableCell>
                      </TableRow>
                    </>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      ) : (
        <CisNewAppointments />
      )}
    </div>
  );
}
