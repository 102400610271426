import {
	Box,
	Dialog,
	TextField,
	Typography,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
	CardMedia,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Notify from "../../notification/Notify";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "../../utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import RefOralGlucose from "../references/Ref_OralGlucose";
import Specimen from "./Specimen";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const OralGlucoseOrderDetails = ({
	queue,
	formheader,
	order,
	getOralGlucoseTestOrder,
	resetDisplay,
	process_for,
}) => {
	const { users } = React.useContext(UsersData);
	const history = useHistory();
	const [orderDetails, setOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const [processDialog, setProcessDialog] = useState({
		data: null,
		dialog: false,
	});

	const [processSubmitting, setProcessSubmitting] = useState(false);
	const [resultSubmitting, setResultSubmitting] = useState(false);
	const [savebtnDisabled, setSavebtnDisabled] = useState(true);
	const [orderItems, setOrderItems] = useState([]);

	const getOralGlucoseOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order.trace_number };
		Axios.get("laboratory/order/ordernew-oralglucosetest/details", { params })
			.then((response) => {
				const data = response.data;
				setOrderDetails({
					data: data,
					ready: true,
				});

				checkIfSaveBtnIsEnabled(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const checkIfSaveBtnIsEnabled = (data) => {
		for (let i = 0; i < data.length; i++) {
			if (Boolean(data[i].is_processed)) {
				setSavebtnDisabled(false);
			}
		}
	};

	//kulang
	const handleSaveResult = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("trace_number", order.trace_number);
		formdata.set("queue", queue);
		formdata.set("process_for", process_for);

		var error = [];

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setResultSubmitting(true);
			Axios.post(
				"laboratory/order/ordernew-oralglucosetest/save-process-result",
				formdata
			)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						setSavebtnDisabled(true);
						Notify.successRequest("order result added.");
						setTimeout(() => {
							history.push(
								`/sph/app/laboratory/record/print/order/${order.trace_number}`
							);
						}, 5000);
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				});
		}
	};

	const handleProcessOrder = async (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", processDialog.data.order_id);
		formdata.set("trace_number", processDialog.data.trace_number);

		var error = [];
		setProcessSubmitting(true);

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			let response = await Axios.post(
				"laboratory/oralglucosetest/order-setprocess/custom-qty",
				formdata
			);

			if (response.data.message === "reagent-error") {
				Notify.customToast("Reagent Not Error", "Reagent/items not available.");
			}

			if (response.data.message === "pass-invalid") {
				Notify.fieldInvalid("password");
			}

			if (response.data.message === "success") {
				Notify.successRequest("process");
				setProcessDialog({ data: null, dialog: false });
				getOralGlucoseOrderDetails();
			}
		}

		setTimeout(() => {
			setProcessSubmitting(false);
		}, 2000);
	};

	const handleProcessSelectedOrder = async (data) => {
		let response = await Axios.get(
			"laboratory/items/laborder/list-available-items",
			{
				params: {
					user_id: users.user_id,
					management_id: users.management_id,
					order_id: data.order_id,
				},
			}
		);

		setOrderItems(response.data);
		setProcessDialog({ data: data, dialog: true });
	};

	useEffect(() => {
		getOralGlucoseOrderDetails();
		// eslint-disable-next-line
	}, [order]);

	return (
		<>
			<form onSubmit={handleSaveResult}>
				<Card elevation={0}>
					<Box mt={2}>
						{formheader && (
							<CardMedia
								style={{ width: 70 }}
								component={"img"}
								src={`${imageLocation}laboratory/logo/${formheader.logo}`}
							/>
						)}
					</Box>
					<Box>
						<CardHeader
							component={Box}
							align="center"
							title={formheader && formheader.name}
							subheader={formheader && formheader.address}
						/>
					</Box>

					<CardContent>
						{/* paitent information */}
						<Box display="flex">
							<Box flexGrow={1} mb={2}>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											NAME:
										</Typography>
										{`${order.lastname}, ${order.firstname} ${
											order.middle === null ? "" : order.middle
										}`}
									</Typography>
								</Box>

								<Box display="flex" mb={2}>
									<Box>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												AGE:
											</Typography>
											{order.birthday === null
												? "none"
												: Notify.calculateAge(order.birthday)}
										</Typography>
									</Box>
									<Box ml={5}>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												SEX:
											</Typography>
											{order.gender === null ? "none" : order.gender}
										</Typography>
									</Box>
								</Box>

								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											ADDRESS:
										</Typography>
										{`${order.street} ${order.barangay} ${order.city} ${order.zip} `}
									</Typography>
								</Box>

								<Box>
									<Typography variant="caption" className="font-weight-bold">
										PATIENT CONDITION:
									</Typography>
									{order.patient_condition === null
										? " none"
										: order.patient_condition}
								</Box>
							</Box>

							<Box>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											DATE:
										</Typography>
										{Notify.createdAt(new Date().toLocaleString())}
									</Typography>
								</Box>
							</Box>
						</Box>
					</CardContent>

					<CardContent>
						<Specimen
							patient_id={order.patient_id}
							trace_number={order.trace_number}
						/>
					</CardContent>

					<CardContent>
						<Box>
							<Typography variant="h6" align="center">
								<b> ORAL GLUCOSE TOLERANCE </b>
							</Typography>
							<Typography variant="subtitle1" align="center">
								75 GRAMS GLUCOLA
							</Typography>
						</Box>

						<Box>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell> Test </TableCell>
										<TableCell> Result </TableCell>
										<TableCell> Unit </TableCell>
										<TableCell> Action </TableCell>
										<TableCell> Remarks </TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{orderDetails.ready ? (
										orderDetails.data.length > 0 ? (
											orderDetails.data.map((data, index) => (
												<Fragment key={index}>
													<TableRow hidden={data.baseline === null}>
														<TableCell> Baseline </TableCell>
														<TableCell>
															{/* hidden inputs */}
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="order_id[]"
																	label="order_id"
																	hidden
																	value={data.order_id}
																/>
																<TextField
																	margin="dense"
																	fullWidth
																	name="patient_id"
																	label="Result"
																	hidden
																	value={data.patient_id}
																/>

																<TextField
																	margin="dense"
																	fullWidth
																	name="doctors_id"
																	label="doctor_id"
																	hidden
																	value={
																		data.doctor_id === null
																			? ""
																			: data.doctor_id
																	}
																/>
															</Box>
															{/* hidden input files */}

															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="baseline[]"
																	label="Result"
																	type="number"
																	required={
																		data.baseline === null ? false : true
																	}
																	InputProps={{
																		inputProps: {
																			min: 1,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell> {RefOralGlucose.baseline()} </TableCell>
														<TableCell>
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="baseline_remarks[]"
																	label="Remarks"
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.first_hour === null}>
														<TableCell> First Hour </TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="first_hour[]"
																	label="Result"
																	type="number"
																	required={
																		data.first_hour === null ? false : true
																	}
																	InputProps={{
																		inputProps: {
																			min: 1,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell>{RefOralGlucose.firstHour()}</TableCell>
														<TableCell>
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="first_hour_remarks[]"
																	label="Remarks"
																/>
															</Box>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.second_hour === null}>
														<TableCell> Second Hour </TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="second_hour[]"
																	label="Result"
																	type="number"
																	required={
																		data.second_hour === null ? false : true
																	}
																	InputProps={{
																		inputProps: {
																			min: 1,
																			max: 1000,
																			step: 0.01,
																		},
																		readOnly: !Boolean(
																			parseInt(data.is_processed)
																		),
																	}}
																/>
															</Box>
														</TableCell>
														<TableCell>{RefOralGlucose.secondHour()}</TableCell>
														<TableCell>
															<Button
																disabled={Boolean(parseInt(data.is_processed))}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
														<TableCell>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="second_hour_remarks[]"
																	label="Remarks"
																/>
															</Box>
														</TableCell>
													</TableRow>
												</Fragment>
											))
										) : (
											<TableRow>
												<TableCell colSpan={4}>
													<Typography> No record found. </Typography>
												</TableCell>
											</TableRow>
										)
									) : (
										<TableRow>
											<TableCell colSpan={4}>
												<Typography> loading, please wait... </Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</Box>
					</CardContent>
					<Box display="flex" m={1}>
						<Box flexGrow={1} />
						<Box>
							<CardActions>
								<Button
									variant="contained"
									size="large"
									color="primary"
									type="submit"
									disabled={savebtnDisabled}
									startIcon={
										resultSubmitting && (
											<CircularProgress size={20} color="inherit" />
										)
									}
								>
									Save Result
								</Button>
							</CardActions>
						</Box>
					</Box>
				</Card>
			</form>

			{/* set as pending dialog */}
			{/* <Dialog
        open={pendingDialog}
        onClose={() => setPendingDialog(false)}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as pending</DialogTitle>
        <form onSubmit={handlePendingOrder}>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                rows={5}
                fullWidth
                name="reason"
                label={`Pending Reason`}
                variant="outlined"
                multiline
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setPendingDialog(false)}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={pendingSubmitting}
              startIcon={
                pendingSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <ErrorOutlineIcon />
                )
              }
            >
              pending
            </Button>
          </DialogActions>
        </form>
      </Dialog> */}

			{/* set as process dialog */}
			<Dialog
				open={processDialog.dialog}
				onClose={() => setProcessDialog({ data: null, dialog: false })}
				disableBackdropClick
				PaperComponent={DraggableDialog}
				maxWidth={"xs"}
				fullWidth
			>
				<DialogTitle id="draggable-handle">Process order</DialogTitle>
				<form onSubmit={handleProcessOrder}>
					<DialogContent dividers>
						{orderItems.length > 0
							? orderItems.map((data, index) => (
									<Box key={index} mb={2}>
										<Box>
											<TextField
												fullWidth
												name="item_id[]"
												value={data.item_id}
												variant="outlined"
												hidden
											/>
										</Box>

										<Box>
											<TextField
												fullWidth
												required
												label={`${data.description} qty to process`}
												name="qty[]"
												variant="outlined"
												defaultValue={1}
												InputLabelProps={{
													shrink: true,
												}}
												// InputProps={{
												//   inputProps: {
												//     min: 0,
												//     max: data._total_qty_available,
												//   },
												// }}
												type="number"
											/>
										</Box>
									</Box>
							  ))
							: "No reagent/item found."}

						<Box mt={2}>
							<TextField
								fullWidth
								required
								defaultValue="ok"
								name="remarks"
								label={`Remarks`}
								variant="outlined"
								multiline
							/>
						</Box>

						<Box mt={2}>
							<TextField
								fullWidth
								required
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setProcessDialog({ data: null, dialog: false })}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={processSubmitting}
							startIcon={
								processSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<CheckCircleIcon />
								)
							}
						>
							process
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default OralGlucoseOrderDetails;
