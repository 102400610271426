import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  CardMedia,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import axios from "axios";
import Notify from "src/notification/Notify";

const Collection = () => {
  const { users } = useContext(UsersData);
  const [collection, setCollection] = useState({ data: [], ready: false });
  const [totalCollection, setTotalCollection] = useState(null);

  const getAllReceivable = useCallback(async () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    try {
      let response = await axios.get("accounting/get/collection-list", {
        params,
      });
      const data = response.data;
      setCollection({ data, ready: true });
      setTotalCollection(calculateCollection(data));
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users]);

  const calculateCollection = (data) => {
    var total = 0;
    for (let x = 0; x < data.length; x++) {
      total += parseFloat(data[x].bill_amount) * parseFloat(1);
    }
    return total;
  };

  useEffect(() => {
    if (users.management_name !== "BMCDC HQ") {
      getAllReceivable();
    }
    // eslint-disable-next-line
  }, [getAllReceivable]);

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Collection",
          items: [{ name: "Dashboard", path: "/sph/app" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Collection</Box>
            <Box>
              <Typography variant="subtitle2" style={{ fontWeight: "bolder" }}>
                Total Collection: P{Notify.convertToNumber(totalCollection)}
              </Typography>
            </Box>
          </Box>
        }
      >
        {collection.ready ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Patient</TableCell>
                  <TableCell align="center">Company</TableCell>
                  <TableCell align="center">Discount</TableCell>
                  <TableCell align="center">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collection.ready ? (
                  collection.data.length > 0 ? (
                    collection.data.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>{data.created_at}</TableCell>
                        <TableCell>{data.patient_name}</TableCell>
                        <TableCell>{data.companyName}</TableCell>
                        <TableCell align="right">{data.discount}</TableCell>
                        <TableCell align="right">{data.bill_amount}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography color="primary">
                        Loading, please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <RenderSelectReceivable />
        )}
      </Container>
    </>
  );
};

export default Collection;

const RenderSelectReceivable = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 270 }}
    />

    <Typography color="secondary" variant="h3">
      NO BRANCH SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select branch in the list to view details.
    </Typography>
  </Box>
);
