import { TextField } from "@material-ui/core";
const ContactTracingForm = ({
	errors,
	register,
	className,
	setValue,
	selectedSickness,
	setSelectedSickness,
	accreditedCompany = [],
}) => {
	const sickness = [
		"Dry cough",
		"Fever",
		"Shortness of breath",
		"Difficulty of breathing",
		"Sore throat",
		"Headache",
		"Nasal congestions",
		"Diarrhea",
		"Colds",
		"Fatigue or body malaise",
	];

	const handleChangeSickness = (e) => {
		let test = selectedSickness;
		if (test.includes(e.target.value)) {
			let testx = test.filter((item) => item !== e.target.value);
			setSelectedSickness(testx);
		} else {
			setSelectedSickness(test.concat(e.target.value));
		}
	};
	return (
		<div className={`${className ? className : "p-4"} flex flex-col`}>
			<h5 className="text-base font-semibold text-tertiary pb-1  mb-3 border-b w-2/3">
				Symptoms
			</h5>
			<div className="grid grid-cols-3 gap-3 mb-4">
				{sickness?.map((info) => {
					return (
						<label className="flex items-center text-sm cursor-pointer hover:text-primary duration-300 transition-all">
							<input
								type="checkbox"
								className="mr-2"
								{...register("gilad[]")}
								value={info}
								onChange={handleChangeSickness}
							/>
							<span>{info}</span>
						</label>
					);
				})}
			</div>
			<TextField
				className="mb-4"
				label={"Purpose of Visit/Medical Concerns/issues"}
				variant="outlined"
				fullWidth
				/*  error={Boolean(
                        touched.purpose_ofvisit && errors.purpose_ofvisit
                      )}
                      helperText={
                        touched.purpose_ofvisit && errors.purpose_ofvisit
                      } 
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.purpose_ofvisit}
				name="purpose_ofvisit"*/
				{...register("purpose_ofvisit", {
					required: true,
				})}
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				error={errors?.purpose_ofvisit}
				helperText={errors?.purpose_ofvisit && "This field is required"}
			/>
			<div className="grid grid-cols-3 gap-5 mb-4">
				<TextField
					label={"Any Allergies"}
					variant="outlined"
					{...register("allergies")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.allergies}
					helperText={errors?.allergies && "This field is required"}
				/>

				<TextField
					label={"Last Check Xray Taken"}
					variant="outlined"
					{...register("last_xray_check")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.last_xray_check}
					helperText={errors?.last_xray_check && "This field is required"}
				/>
				<TextField
					label={"Result of Cxray"}
					variant="outlined"
					{...register("last_xray_result")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					error={errors?.last_xray_result}
					helperText={errors?.last_xray_result && "This field is required"}
				/>
			</div>
			<h5 className="text-base font-semibold text-tertiary pb-1  mb-4 border-b w-2/3">
				History of Travel: When and where
			</h5>
			<div className="grid grid-cols-12 gap-5 mb-4">
				<TextField
					className="col-span-4"
					type="date"
					label={"Date"}
					variant="outlined"
					InputLabelProps={{
						shrink: true,
					}}
					{...register("history_oftravel_date")}
					SelectProps={{ native: true }}
				/>
				<TextField
					className="col-span-4"
					type="number"
					label={"Days"}
					variant="outlined"
					{...register("history_oftravel_days")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					className="col-span-4"
					label={"Place"}
					variant="outlined"
					{...register("place")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					className="col-span-4"
					label={"Any contact with PUI to PUM"}
					variant="outlined"
					select
					{...register("contact_withpuipum")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
				>
					<option value=""> SELECT </option>
					<option value="No"> No </option>
					<option value="Yes"> Yes </option>
				</TextField>
			</div>
			<h5 className="text-base font-semibold text-tertiary pb-1  mb-4 border-b w-2/3">
				For CRT Clients:
			</h5>
			<div className="grid grid-cols-1 gap-5 mb-4">
				<TextField
					label={"Purpose of your request for CRT"}
					variant="outlined"
					{...register("crt_purpose")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					label={"Requested by "}
					variant="outlined"
					{...register("requested_by")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					label={`If for return to work, complete company name`}
					variant="outlined"
					{...register("complete_company")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					select
				>
					<option value=""> Select </option>
					{accreditedCompany.length > 0 ? (
						accreditedCompany.map((data, index) => (
							<option key={index} value={data.value}>
								{data.label}
							</option>
						))
					) : (
						<option value="" disabled>
							No company found.
						</option>
					)}
				</TextField>
				<TextField
					label={`Complete contact person, designation & numbers`}
					variant="outlined"
					{...register("contact_information")}
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
				/>
			</div>
			<h5 className="text-base font-semibold text-tertiary pb-1  mb-4 border-b w-full">
				If found to be positive of either IgG or IgM, are you willing to undergo
				quarantine or Swab testing testing (RT-PCR)?
			</h5>
			<div className="grid grid-cols-4 gap-5 mb-4">
				<TextField
					label={"Select"}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("quarantine_ifpositive")}
				>
					<option value=""> SELECT </option>
					<option value="No"> No </option>
					<option value="Yes"> Yes </option>
				</TextField>
			</div>
			<h5 className="text-base font-semibold text-tertiary pb-1  mb-4 border-b w-full">
				I hereby authorize GTC(Global Telemedicine Corp.) to collect and process
				the data indicated herein for the purpose of effecting conroil of the
				COVID 19 infection. I understand that my personal information is
				protected by RA 10173, Data PrivacyAct of 2012 and that I am required by
				RA 11469 Bayanihan to Heal as One Act. to provide truthful information.
			</h5>
			<div className="grid grid-cols-2 gap-5 mb-4">
				<TextField
					label={"Transaction Type"}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("transaction_type")}
				>
					<option value=""> SELECT </option>
					<option value="No"> Walk-In {`(Lab/X-ray)`} </option>
					<option value="Yes"> Walk-In Appointment </option>
				</TextField>
				<TextField
					label={"Select"}
					variant="outlined"
					select
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					{...register("patient_sent_to")}
				>
					<option value=""> SELECT </option>
					<option value="No"> OPD Patient </option>
					<option value="Yes"> ER Patient </option>
				</TextField>
			</div>
		</div>
	);
};

export default ContactTracingForm;
