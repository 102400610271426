import {
	Typography,
	Grid,
	Box,
	List,
	TextField,
	ListItem,
	ListItemText,
	Avatar,
	TablePagination,
	ListItemIcon,
	makeStyles,
	CardMedia,
	InputAdornment,
	IconButton,
	Card,
	CardHeader,
	CardContent,
	CardActions,
} from "@material-ui/core";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import Container from "src/layout/Container";
import axios from "axios";
import { Search, XCircle } from "react-feather";
import NSPatientDetails from "./NSPatientDetails";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
	primary: {
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
}));

const NSPatientList = () => {
	const classes = useStyles();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [selectedPatient, setSelectedPatient] = useState("new-patient");
	const [search, setSearch] = useState("");
	const [displaySearch, setDisplaySearch] = useState(false);
	const { users } = useContext(UsersData);
	const [patient, setPatient] = useState({
		data: [],
		ready: false,
	});

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		event.persist();
		setPage(newPage);
	};

	const getAllPatientList = useCallback(() => {
		var params = {
			user_id: users.user_id,
			management_id: users.management_id,
			main_mgmt_id: users.main_mgmt_id,
		};
		axios
			.get("admission/patients/getpatient-list", { params })
			.then((response) => {
				const data = response.data;
				setPatient({ data: data, ready: true });
			})
			.catch((error) => {
				console.log("error: ", error);
			});
	}, [users]);

	const searchable = patient.data.filter((data) => {
		return (
			data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
			data.lastname.toLowerCase().indexOf(search.trim()) !== -1
		);
	});

	useEffect(() => {
		getAllPatientList();
	}, [getAllPatientList]);

	return (
		<Container
			breadcrumbs={{
				enable: true,
				current: "Patient List",
				items: [{ name: "Dashboard", path: "/csqmmh-clinic/app" }],
			}}
			title={"Patient List"}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} md={4} lg={3}>
					<Card>
						<CardHeader
							title={
								<Box
									display="flex"
									justifyContent={"center"}
									alignItems={"center"}
								>
									<Box flexGrow={1}>Patient List</Box>
									<Box>
										<IconButton
											onClick={() =>
												setDisplaySearch(!displaySearch)
											}
											color={
												!displaySearch
													? "primary"
													: "secondary"
											}
										>
											{!displaySearch ? (
												<Search />
											) : (
												<XCircle />
											)}
										</IconButton>
									</Box>
								</Box>
							}
						/>
						<CardContent>
							<List component="div">
								<Box hidden={!displaySearch}>
									<TextField
										label="Search Patient"
										variant="outlined"
										fullWidth
										value={search}
										onChange={(e) =>
											setSearch(e.target.value)
										}
										InputProps={{
											endAdornment: (
												<InputAdornment>
													<Search />
												</InputAdornment>
											),
										}}
									/>
								</Box>

								{patient.ready
									? patient.data.length > 0
										? searchable
												.slice(
													page * rowsPerPage,
													page * rowsPerPage +
														rowsPerPage
												)
												.map((data, index) => (
													<ListItem
														key={index}
														button
														className="my-2"
														onClick={() =>
															setSelectedPatient(
																data.patient_id
															)
														}
														selected={
															selectedPatient ===
															data.patient_id
														}
													>
														<ListItemIcon>
															{data.image ===
															null ? (
																<Avatar
																	className={
																		classes.primary
																	}
																>
																	{data?.lastname
																		.charAt()
																		.toUpperCase()}
																</Avatar>
															) : (
																<Avatar
																	className={
																		classes.primary
																	}
																	src={`${imageLocation}patients/${data.image}`}
																	alt=""
																/>
															)}
														</ListItemIcon>
														<ListItemText
															className={`gtc-capitalize`}
															primary={`${
																data?.lastname
															}, ${
																data?.firstname
															} ${
																Boolean(
																	data?.middle
																)
																	? data?.middle
																	: ``
															} `}
														/>
													</ListItem>
												))
										: Notify.noRecord()
									: Notify.loading()}
							</List>
						</CardContent>

						<CardActions>
							<TablePagination
								component="div"
								count={patient.data.length}
								rowsPerPageOptions={[
									10,
									50,
									100,
									{ label: "All", value: -1 },
								]}
								page={page}
								onChangePage={handleChangePage}
								rowsPerPage={rowsPerPage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								labelRowsPerPage="List"
							/>
						</CardActions>
					</Card>
				</Grid>

				<Grid item xs={12} md={8} lg={9}>
					{selectedPatient === "new-patient" && (
						<RenderSelectPatient />
					)}
					{selectedPatient !== "new-patient" && (
						<NSPatientDetails
							patient_id={selectedPatient}
							getAllPatientList={() => getAllPatientList()}
							onClose={() => setSelectedPatient("new-patient")}
						/>
					)}
				</Grid>
			</Grid>
		</Container>
	);
};

export default NSPatientList;

const RenderSelectPatient = () => (
	<Box align="center">
		<CardMedia
			component="img"
			src={"/gtc-logo.png"}
			alt=""
			style={{ width: 250 }}
		/>
		<Typography color="secondary" variant="h3">
			NO PATIENT SELECTED
		</Typography>

		<Typography color="secondary" variant="h6">
			Select patient in the list to view details.
		</Typography>
	</Box>
);
