import React, { useCallback, useEffect } from "react";
import Notify from "../../notification/Notify";
import Axios from "axios";
import Box from "@material-ui/core/Box";
import { Avatar, makeStyles, Paper, Typography } from "@material-ui/core";
import { useState } from "react";
import IsMountedRef from "src/utils/IsMountedRef";
import { UsersData } from "src/ContextAPI";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const imageLocationOnline = process.env.REACT_APP_API_IMAGE_VIRTUAL;

const useStyles = makeStyles((theme) => ({
	primary: {
		height: theme.spacing(15),
		width: theme.spacing(15),
	},
}));

const PersonalInfoHeader = ({ patient_id, connection }) => {
	const classes = useStyles();

	const { users } = React.useContext(UsersData);

	const ismounted = IsMountedRef();

	const [info, setInfo] = useState({ data: [], ready: false });

	const getPatientInformation = useCallback(() => {
		const params = {
			user_id: users.user_id,
			patient_id,
			connection,
		};

		Axios.get("doctor/patient/patient-information", { params })
			.then((response) => {
				const data = response.data;
				if (ismounted.current) {
					setInfo({ data: data, ready: true });
				}
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	}, [users, patient_id, connection, ismounted]);

	useEffect(() => {
		getPatientInformation();

		// eslint-disable-next-line
	}, [getPatientInformation]);

	return (
		<>
			<Box component={Paper} elevation={2} p={2} mb={2}>
				{info.ready ? (
					info.data.length > 0 ? (
						<>
							<Box display={"flex"}>
								<Box
									justifyContent="center"
									alignSelf="center"
									alignItems="center"
									mr={2}
								>
									{/* <CardMedia 
                                            component={`img`}
                                            className={ classes.primary }
                                            src={ info.data[0].image === null ? `/no-image.jpg`: `${connection === 'online' ? imageLocationOnline : imageLocation}patients/${info.data[0].image}` }
                                            alt="" 
                                        />  */}
									<Avatar
										className={classes.primary}
										src={
											info.data[0].image === null
												? `/no-image.jpg`
												: `${
														connection === "online"
															? imageLocationOnline
															: imageLocation
												  }patients/${info.data[0].image}`
										}
										alt=""
									/>
								</Box>

								<Box>
									<Box mb={1}>
										<Typography className="gtc-uppercase" variant="subtitle1">
											<b>
												{" "}
												{`${info.data[0].lastname}, ${info.data[0].firstname}  ${info.data[0].middle}`}{" "}
											</b>
										</Typography>
									</Box>
									<Box mb={1}>
										<Typography variant="subtitle1">
											<Typography variant="caption">
												{" "}
												<b> AGE </b>{" "}
											</Typography>
											: {Notify.calculateAge(info.data[0].birthday)}
										</Typography>
									</Box>
									<Box mb={1}>
										<Typography variant="subtitle1">
											<Typography variant="caption">
												{" "}
												<b> GENDER </b>{" "}
											</Typography>
											: {info.data[0].gender}
										</Typography>
									</Box>
									<Box>
										<Typography variant="subtitle1">
											<Typography variant="caption">
												{" "}
												<b> BIRTHDAY </b>{" "}
											</Typography>
											: {Notify.birthday(info.data[0].birthday)}
										</Typography>
									</Box>
								</Box>
							</Box>
						</>
					) : (
						Notify.noRecord()
					)
				) : (
					Notify.loading()
				)}
			</Box>
		</>
	);
};

export default PersonalInfoHeader;
