import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Button,
  TextField,
  IconButton,
  TableContainer,
  Grid,
} from "@material-ui/core";
import React, { useContext, useCallback, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import { useReactToPrint } from "react-to-print";
import { CheckSquare, Edit, Printer, XCircle } from "react-feather";
import Notify from "src/notification/Notify";
import {
  CheckBoxOutlineBlank,
  CheckBox,
  CheckBoxOutlineBlankOutlined,
} from "@material-ui/icons";
import axios from "axios";
import FormHeader from "./FormHeader";

const FormIDontKnow = ({
  patient_id,
  details,
  info,
  doctorsList,
  allowEdit,
}) => {
  const printableRef = React.useRef();
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [isProcess, setIsProcess] = React.useState(false);
  const { users } = useContext(UsersData);
  const [existingAttendanceSheetChart, setExistingAttendanceSheetChart] =
    React.useState({
      data: [],
      ready: false,
    });

  const [checkList, setCheckList] = React.useState({
    identity: false,
    procedure_site: false,
    consent: false,
    site_marked: "",

    history: false,
    assessment: false,
    diagnostic_radiologic: "",
    blood_product: "",
    any_special: "",

    confirm_identity: false,
    site_marked_procedure: "",
    patient_allergies: "",
    diff_airway: "",
    risk_blood: "",
    two_intravenous: false,
    anesthesia_machine: false,
    pulse_oximeter: false,
    all_member: false,

    introduction_team: false,
    informed_consent: false,
    site_mark_visible: "",
    relevant_images: "",
    non_routine_steps: false,
    case_duration: false,
    anticipated_blood_loss: false,
    specific_concerns: false,

    sterilization: false,
    antibiotic: false,
    before_incision: false,
    issues_concern: false,

    operative_procedure: false,
    sponges: "",
    instruments: "",
    needles: "",
    sharps: "",
    identified_specimen: "",
    problems_to_address: "",
  });

  const [chartSurgeon, setChartSurgeon] = useState("");

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const getAttendanceSheetChart = useCallback(async () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/attendancesheet", { params })
      .then((response) => {
        const data = response.data;
        setExistingAttendanceSheetChart({ data, ready: true });
      })
      .catch((error) => console.log(error));
  }, [patient_id, users, details]);

  const handleSubmitAttendanceSheetChart = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", details.trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    formdata.set("identity", checkList.identity ? 1 : 0);
    formdata.set("procedure_site", checkList.procedure_site ? 1 : 0);
    formdata.set("consent", checkList.consent ? 1 : 0);
    formdata.set(
      "site_marked",
      checkList.site_marked ? 1 : checkList.site_marked === false ? 0 : ""
    );

    formdata.set("history", checkList.history ? 1 : 0);
    formdata.set("assessment", checkList.assessment ? 1 : 0);
    formdata.set(
      "diagnostic_radiologic",
      checkList.diagnostic_radiologic
        ? 1
        : checkList.diagnostic_radiologic === false
        ? 0
        : ""
    );
    formdata.set(
      "blood_product",
      checkList.blood_product ? 1 : checkList.blood_product === false ? 0 : ""
    );
    formdata.set(
      "any_special",
      checkList.any_special ? 1 : checkList.any_special === false ? 0 : ""
    );

    formdata.set("confirm_identity", checkList.confirm_identity ? 1 : 0);
    formdata.set(
      "site_marked_procedure",
      checkList.site_marked_procedure
        ? 1
        : checkList.site_marked_procedure === false
        ? 0
        : ""
    );
    formdata.set(
      "patient_allergies",
      checkList.patient_allergies
        ? 1
        : checkList.patient_allergies === false
        ? 0
        : ""
    );
    formdata.set(
      "diff_airway",
      checkList.diff_airway ? 1 : checkList.diff_airway === false ? 0 : ""
    );
    formdata.set(
      "risk_blood",
      checkList.risk_blood ? 1 : checkList.risk_blood === false ? 0 : ""
    );
    formdata.set("two_intravenous", checkList.two_intravenous ? 1 : 0);
    formdata.set("anesthesia_machine", checkList.anesthesia_machine ? 1 : 0);
    formdata.set("pulse_oximeter", checkList.pulse_oximeter ? 1 : 0);
    formdata.set("all_member", checkList.all_member ? 1 : 0);

    formdata.set("introduction_team", checkList.introduction_team ? 1 : 0);
    formdata.set("informed_consent", checkList.informed_consent ? 1 : 0);
    formdata.set(
      "site_mark_visible",
      checkList.site_mark_visible
        ? 1
        : checkList.site_mark_visible === false
        ? 0
        : ""
    );
    formdata.set(
      "relevant_images",
      checkList.relevant_images
        ? 1
        : checkList.relevant_images === false
        ? 0
        : ""
    );
    formdata.set("non_routine_steps", checkList.non_routine_steps ? 1 : 0);
    formdata.set("case_duration", checkList.case_duration ? 1 : 0);
    formdata.set(
      "anticipated_blood_loss",
      checkList.anticipated_blood_loss ? 1 : 0
    );
    formdata.set("specific_concerns", checkList.specific_concerns ? 1 : 0);

    formdata.set("sterilization", checkList.sterilization ? 1 : 0);
    formdata.set("antibiotic", checkList.antibiotic ? 1 : 0);
    formdata.set("before_incision", checkList.before_incision ? 1 : 0);
    formdata.set("issues_concern", checkList.issues_concern ? 1 : 0);

    formdata.set("operative_procedure", checkList.operative_procedure ? 1 : 0);
    formdata.set(
      "sponges",
      checkList.sponges ? 1 : checkList.sponges === false ? 0 : ""
    );
    formdata.set(
      "instruments",
      checkList.instruments ? 1 : checkList.instruments === false ? 0 : ""
    );
    formdata.set(
      "needles",
      checkList.needles ? 1 : checkList.needles === false ? 0 : ""
    );
    formdata.set(
      "sharps",
      checkList.sharps ? 1 : checkList.sharps === false ? 0 : ""
    );
    formdata.set(
      "identified_specimen",
      checkList.identified_specimen
        ? 1
        : checkList.identified_specimen === false
        ? 0
        : ""
    );
    formdata.set(
      "problems_to_address",
      checkList.problems_to_address
        ? 1
        : checkList.problems_to_address === false
        ? 0
        : ""
    );

    setIsProcess(true);
    axios
      .post("or/update/chart/attendancesheet", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          getAttendanceSheetChart();
          setEnableEdit(!enableEdit);
          Notify.successRequest("");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  // const checkSelectedDoctor = (selDoctor) => {
  //   if (doctorsList.length > 0) {
  //     let xx = doctorsList.filter((x) => x.doctors_id === selDoctor);
  //     if (xx.length > 0) {
  //       return xx[0].name;
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  useEffect(() => {
    getAttendanceSheetChart();
  }, [patient_id, getAttendanceSheetChart]);

  return (
    <Box>
      <Card ref={printableRef}>
        <Box m={1}>
          <Box>
            <FormHeader />
          </Box>
          <Box>
            <form onSubmit={handleSubmitAttendanceSheetChart}>
              <Box my={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          disabled={isProcess}
                          color="primary"
                          startIcon={<CheckSquare />}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            SET
                          </Button>
                        </Box>
                      )}
                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              <Box mb={1}>
                <TableContainer>
                  <Table size="small" className="table-bordered">
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Name of Patient:
                            </span>{" "}
                          </Typography>

                          {!enableEdit && (
                            <Typography>
                              {info
                                ? `${info.lastname}, ${info.firstname} ${
                                    info.middle === null ? "" : info.middle
                                  }`
                                : ""}
                            </Typography>
                          )}

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="patient_name"
                                defaultValue={
                                  info
                                    ? `${info.lastname}, ${info.firstname} ${
                                        info.middle === null ? "" : info.middle
                                      }`
                                    : ""
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                fullWidth
                                name="ccas_id"
                                defaultValue={
                                  existingAttendanceSheetChart.data.length > 0
                                    ? existingAttendanceSheetChart.data[0]
                                        .ccas_id
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                hidden
                              />
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Age:
                            </span>{" "}
                          </Typography>
                          {!enableEdit && (
                            <Typography>
                              {info
                                ? info.birthday === null
                                  ? ""
                                  : Notify.calculateAge(info.birthday)
                                : ""}
                            </Typography>
                          )}

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="age"
                                defaultValue={
                                  info
                                    ? info.birthday === null
                                      ? ""
                                      : Notify.calculateAge(info.birthday)
                                    : ""
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Sex:
                            </span>{" "}
                          </Typography>
                          {!enableEdit && (
                            <Typography>
                              {info ? `${info.gender}` : ""}
                            </Typography>
                          )}

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="sex"
                                defaultValue={info ? `${info.gender}` : ""}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Birthdate:
                            </span>
                          </Typography>

                          {!enableEdit && (
                            <Typography>
                              {info
                                ? info.birthday === null
                                  ? ""
                                  : Notify.birthday(info.birthday)
                                : ""}
                            </Typography>
                          )}

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="birthdate"
                                type="date"
                                defaultValue={info.birthday}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Case No.:
                            </span>{" "}
                            {!enableEdit
                              ? existingAttendanceSheetChart.data.length > 0
                                ? existingAttendanceSheetChart.data[0]
                                    .case_number
                                : ""
                              : null}
                            {enableEdit && (
                              <>
                                <TextField
                                  fullWidth
                                  name="case_number"
                                  defaultValue={
                                    existingAttendanceSheetChart.data.length > 0
                                      ? existingAttendanceSheetChart.data[0]
                                          .case_number
                                      : ""
                                  }
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                />
                              </>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Name of Surgeon:
                            </span>{" "}
                          </Typography>

                          <Typography>
                            {!enableEdit
                              ? existingAttendanceSheetChart.data.length > 0
                                ? // checkSelectedDoctor(
                                  //     existingAttendanceSheetChart.data[0]
                                  //       .surgeon_name
                                  //   )
                                  existingAttendanceSheetChart.data[0]
                                    .surgeon_name
                                : ""
                              : null}
                          </Typography>

                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="surgeon_name"
                                value={chartSurgeon}
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                                onChange={(e) =>
                                  setChartSurgeon(e.target.value)
                                }
                              />

                              {/* <TextField
                                fullWidth
                                name="surgeon_name"
                                value={chartSurgeon}
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  style: {
                                    textTransform: "capitalize",
                                  },
                                }}
                                select
                                SelectProps={{ native: true }}
                                InputLabelProps={{ shrink: true }}
                                readOnly={!enableEdit}
                                onChange={(e) =>
                                  setChartSurgeon(e.target.value)
                                }
                              >
                                <option value=""> Select </option>
                                {doctorsList.length > 0 ? (
                                  doctorsList.map((data, key) => (
                                    <option key={key} value={data.doctors_id}>
                                      {data.name}
                                    </option>
                                  ))
                                ) : (
                                  <option value=""> No doctor found. </option>
                                )}
                              </TextField> */}
                            </>
                          )}
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Typography variant="subtitle2">
                            <span
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Name Operation:
                            </span>{" "}
                            {!enableEdit
                              ? existingAttendanceSheetChart.data.length > 0
                                ? existingAttendanceSheetChart.data[0]
                                    .operation_name
                                : ""
                              : null}
                            {enableEdit && (
                              <>
                                <TextField
                                  fullWidth
                                  name="operation_name"
                                  defaultValue={
                                    existingAttendanceSheetChart.data.length > 0
                                      ? existingAttendanceSheetChart.data[0]
                                          .operation_name
                                      : ""
                                  }
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                />
                              </>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <TableContainer>
                  <Table size="small" className="table-bordered">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>
                            PRE-PROCEDURE CHECK IN in Holding Area:
                          </strong>
                        </TableCell>
                        <TableCell>
                          <strong>
                            SIGN-IN Before induction of Anesthesia:
                          </strong>
                        </TableCell>
                        <TableCell>
                          <strong>TIME OUT Before Skin Incision:</strong>
                        </TableCell>
                        <TableCell>
                          <strong>
                            SIGN-OUT Before Patient leaves the Operating Room
                          </strong>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            <span style={{ color: "#000" }}>
                              Patient/patient legal representative Actively
                              confirm with Operating Room Receiving Nurse:
                            </span>{" "}
                            {!enableEdit
                              ? existingAttendanceSheetChart.data.length > 0
                                ? existingAttendanceSheetChart.data[0]
                                    .receive_nurse
                                : ""
                              : null}
                            {enableEdit && (
                              <>
                                <TextField
                                  fullWidth
                                  name="receive_nurse"
                                  defaultValue={
                                    existingAttendanceSheetChart.data.length > 0
                                      ? existingAttendanceSheetChart.data[0]
                                          .receive_nurse
                                      : ""
                                  }
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                />
                              </>
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            <span style={{ color: "#000" }}>
                              Circulating Nurse and Anesthesia Resident
                              Physicians/Anesthesiologist confirm
                            </span>{" "}
                            {!enableEdit
                              ? existingAttendanceSheetChart.data.length > 0
                                ? existingAttendanceSheetChart.data[0]
                                    .anesthesia_resident
                                : ""
                              : null}
                            {enableEdit && (
                              <>
                                <TextField
                                  fullWidth
                                  name="anesthesia_resident"
                                  defaultValue={
                                    existingAttendanceSheetChart.data.length > 0
                                      ? existingAttendanceSheetChart.data[0]
                                          .anesthesia_resident
                                      : ""
                                  }
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                />
                              </>
                            )}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography variant="caption">
                            <span style={{ color: "#000" }}>
                              Initiated by the Circulating nurse:
                            </span>{" "}
                            {!enableEdit
                              ? existingAttendanceSheetChart.data.length > 0
                                ? existingAttendanceSheetChart.data[0]
                                    .circulating_nurse
                                : ""
                              : null}
                            {enableEdit && (
                              <>
                                <TextField
                                  fullWidth
                                  name="circulating_nurse"
                                  defaultValue={
                                    existingAttendanceSheetChart.data.length > 0
                                      ? existingAttendanceSheetChart.data[0]
                                          .circulating_nurse
                                      : ""
                                  }
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                />
                              </>
                            )}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography variant="caption">
                            <span style={{ color: "#000" }}>
                              Ciculating Nurse confirms:
                            </span>{" "}
                            {!enableEdit
                              ? existingAttendanceSheetChart.data.length > 0
                                ? existingAttendanceSheetChart.data[0]
                                    .circulating_nurse_confirm
                                : ""
                              : null}
                            {enableEdit && (
                              <>
                                <TextField
                                  fullWidth
                                  name="circulating_nurse_confirm"
                                  defaultValue={
                                    existingAttendanceSheetChart.data.length > 0
                                      ? existingAttendanceSheetChart.data[0]
                                          .circulating_nurse_confirm
                                      : ""
                                  }
                                  InputProps={{
                                    disableUnderline: !enableEdit,
                                    disabled: !enableEdit,
                                  }}
                                />
                              </>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Box m={1}>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                1.Identity
                              </Typography>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .identity
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          identity: !checkList.identity,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.identity === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                2.Procedure and procedure site
                              </Typography>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .procedure_site
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          procedure_site:
                                            !checkList.procedure_site,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.procedure_site === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                3.Consent(s)
                              </Typography>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .consent
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          consent: !checkList.consent,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.consent === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box display={"flex"}>
                              <Typography variant="caption">
                                4.Site Marked by person Performing the Procedure
                                <Box hidden={enableEdit} display={"flex"}>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .site_marked
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    Yes
                                  </Box>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      !Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .site_marked
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    N/A
                                  </Box>
                                </Box>
                                <Box hidden={!enableEdit} display={"flex"}>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            site_marked: true,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.site_marked === true ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      Yes
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            site_marked: false,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.site_marked === false ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      N/A
                                    </Typography>
                                  </Box>
                                </Box>
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant="caption">
                                <strong>ORRN</strong> confirm presence of
                              </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                1.History and Physical Assessment
                              </Typography>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .history
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          history: !checkList.history,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.history === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                2.Pre-anesthesia assessment.
                              </Typography>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .assessment
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          assessment: !checkList.assessment,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.assessment === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box display={"flex"}>
                              <Typography variant="caption">
                                3.Diagnostic and Radiologic test results
                                <Box hidden={enableEdit} display={"flex"}>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .diagnostic_radiologic
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    Yes
                                  </Box>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      !Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .diagnostic_radiologic
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    N/A
                                  </Box>
                                </Box>
                                <Box hidden={!enableEdit} display={"flex"}>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            diagnostic_radiologic: true,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.diagnostic_radiologic ===
                                        true ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      Yes
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            diagnostic_radiologic: false,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.diagnostic_radiologic ===
                                        false ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      N/A
                                    </Typography>
                                  </Box>
                                </Box>
                              </Typography>
                            </Box>
                            <Box display={"flex"}>
                              <Typography variant="caption">
                                4.Blood products
                                <Box hidden={enableEdit} display={"flex"}>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .blood_product
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    Yes
                                  </Box>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      !Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .blood_product
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    N/A
                                  </Box>
                                </Box>
                                <Box hidden={!enableEdit} display={"flex"}>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            blood_product: true,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.blood_product === true ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      Yes
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            blood_product: false,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.blood_product === false ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      N/A
                                    </Typography>
                                  </Box>
                                </Box>
                              </Typography>
                            </Box>
                            <Box display={"flex"}>
                              <Typography variant="caption">
                                5.Any special equipment, devices, implants
                                <Box hidden={enableEdit} display={"flex"}>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .any_special
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    Yes
                                  </Box>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      !Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .any_special
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    N/A
                                  </Box>
                                </Box>
                                <Box hidden={!enableEdit} display={"flex"}>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            any_special: true,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.any_special === true ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      Yes
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            any_special: false,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.any_special === false ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      N/A
                                    </Typography>
                                  </Box>
                                </Box>
                              </Typography>
                            </Box>
                            <Box align="center" mt={5}>
                              <Box borderBottom={1}>
                                <Typography variant="subtitle2">
                                  {!enableEdit
                                    ? existingAttendanceSheetChart.data.length >
                                      0
                                      ? existingAttendanceSheetChart.data[0]
                                          .signature_receiving
                                      : ""
                                    : null}
                                </Typography>
                                {enableEdit && (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="signature_receiving"
                                      defaultValue={
                                        existingAttendanceSheetChart.data
                                          .length > 0
                                          ? existingAttendanceSheetChart.data[0]
                                              .signature_receiving
                                          : ""
                                      }
                                      InputProps={{
                                        disableUnderline: !enableEdit,
                                        disabled: !enableEdit,
                                      }}
                                    />
                                  </>
                                )}
                              </Box>
                              <Box>
                                <Typography variant="caption">
                                  Name and Signature of Receiving Nurse
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box m={1}>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                1.Confirmation of identity, procedure, procedure
                                site and consent(s).
                              </Typography>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .confirm_identity
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          confirm_identity:
                                            !checkList.confirm_identity,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.confirm_identity === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                2.Site marked by person performing the
                                procedure.
                                <Box hidden={enableEdit} display={"flex"}>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .site_marked_procedure
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    Yes
                                  </Box>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      !Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .site_marked_procedure
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    N/A
                                  </Box>
                                </Box>
                                <Box hidden={!enableEdit} display={"flex"}>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            site_marked_procedure: true,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.site_marked_procedure ===
                                        true ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      Yes
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            site_marked_procedure: false,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.site_marked_procedure ===
                                        false ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      N/A
                                    </Typography>
                                  </Box>
                                </Box>
                              </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                3.Patient allergies
                                <Box hidden={enableEdit} display={"flex"}>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .patient_allergies
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    Yes
                                  </Box>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      !Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .patient_allergies
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    N/A
                                  </Box>
                                </Box>
                                <Box hidden={!enableEdit} display={"flex"}>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            patient_allergies: true,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.patient_allergies ===
                                        true ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      Yes
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            patient_allergies: false,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.patient_allergies ===
                                        false ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      N/A
                                    </Typography>
                                  </Box>
                                </Box>
                              </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                4.Difficult airway or aspiration risk
                                <Box hidden={enableEdit} display={"flex"}>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .diff_airway
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    Yes (preparation confirmed)
                                  </Box>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      !Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .diff_airway
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    No
                                  </Box>
                                </Box>
                                <Box hidden={!enableEdit} display={"flex"}>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            diff_airway: true,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.diff_airway === true ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      Yes (preparation confirmed)
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            diff_airway: false,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.diff_airway === false ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      No
                                    </Typography>
                                  </Box>
                                </Box>
                              </Typography>
                            </Box>
                            <Box>
                              <Box display={"flex"} alignItems={"center"}>
                                <Typography variant="caption">
                                  5.Risk of Blood loss {"(>500ml)"}
                                  <Box hidden={enableEdit} display={"flex"}>
                                    <Box>
                                      {existingAttendanceSheetChart.data
                                        .length > 0 ? (
                                        Boolean(
                                          parseInt(
                                            existingAttendanceSheetChart.data[0]
                                              .risk_blood
                                          )
                                        ) ? (
                                          <CheckBox />
                                        ) : (
                                          <CheckBoxOutlineBlank />
                                        )
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )}
                                      Yes
                                    </Box>
                                    <Box>
                                      {existingAttendanceSheetChart.data
                                        .length > 0 ? (
                                        !Boolean(
                                          parseInt(
                                            existingAttendanceSheetChart.data[0]
                                              .risk_blood
                                          )
                                        ) ? (
                                          <CheckBox />
                                        ) : (
                                          <CheckBoxOutlineBlank />
                                        )
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )}
                                      N/A
                                    </Box>
                                  </Box>
                                  <Box hidden={!enableEdit} display={"flex"}>
                                    <Box>
                                      <Typography variant={"subtitle1"}>
                                        <IconButton
                                          onClick={() =>
                                            setCheckList({
                                              ...checkList,
                                              risk_blood: true,
                                            })
                                          }
                                          disabled={!enableEdit}
                                        >
                                          {checkList.risk_blood === true ? (
                                            <CheckBox fontSize="small" />
                                          ) : (
                                            <CheckBoxOutlineBlankOutlined fontSize="small" />
                                          )}
                                        </IconButton>
                                        Yes
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography variant={"subtitle1"}>
                                        <IconButton
                                          onClick={() =>
                                            setCheckList({
                                              ...checkList,
                                              risk_blood: false,
                                            })
                                          }
                                          disabled={!enableEdit}
                                        >
                                          {checkList.risk_blood === false ? (
                                            <CheckBox fontSize="small" />
                                          ) : (
                                            <CheckBoxOutlineBlankOutlined fontSize="small" />
                                          )}
                                        </IconButton>
                                        N/A
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Typography>
                              </Box>
                              <Box display={"flex"} alignItems={"center"}>
                                <Typography variant="caption">
                                  <span
                                    style={{
                                      color: "#000",
                                    }}
                                  >
                                    A.# of blood units available
                                  </span>{" "}
                                  {!enableEdit
                                    ? existingAttendanceSheetChart.data.length >
                                      0
                                      ? existingAttendanceSheetChart.data[0]
                                          .blood_units
                                      : ""
                                    : null}
                                  {enableEdit && (
                                    <>
                                      <TextField
                                        fullWidth
                                        name="blood_units"
                                        defaultValue={
                                          existingAttendanceSheetChart.data
                                            .length > 0
                                            ? existingAttendanceSheetChart
                                                .data[0].blood_units
                                            : ""
                                        }
                                        InputProps={{
                                          disableUnderline: !enableEdit,
                                          disabled: !enableEdit,
                                        }}
                                      />
                                    </>
                                  )}
                                </Typography>
                              </Box>
                              <Box display={"flex"} alignItems={"center"}>
                                <Typography variant="caption">
                                  B.Two(2) intravenous/Central Access?
                                </Typography>
                                <Box hidden={enableEdit}>
                                  <Typography variant="caption">
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .two_intravenous
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    Yes
                                  </Typography>
                                </Box>
                                <Box hidden={!enableEdit}>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            two_intravenous:
                                              !checkList.two_intravenous,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.two_intravenous === true ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      Yes
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                6.Anesthesia machine and medication check
                                complete?
                              </Typography>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .anesthesia_machine
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          anesthesia_machine:
                                            !checkList.anesthesia_machine,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.anesthesia_machine === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                7.Pulse oximeter is on the patient and is
                                functioning?
                              </Typography>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .pulse_oximeter
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          pulse_oximeter:
                                            !checkList.pulse_oximeter,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.pulse_oximeter === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <Typography variant="caption">
                                Briefing:
                              </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                All members of the team have discussed care plan
                                and addressed concerns.
                              </Typography>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .all_member
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          all_member: !checkList.all_member,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.all_member === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography variant="caption">
                              All other activities to be suspended(unless with a
                              life-threatening emergency)
                            </Typography>
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="caption">
                              1.Introduction of team members and role
                            </Typography>
                            <Box hidden={enableEdit}>
                              <Typography variant="caption">
                                {existingAttendanceSheetChart.data.length >
                                0 ? (
                                  Boolean(
                                    parseInt(
                                      existingAttendanceSheetChart.data[0]
                                        .introduction_team
                                    )
                                  ) ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                                Yes
                              </Typography>
                            </Box>
                            <Box hidden={!enableEdit}>
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        introduction_team:
                                          !checkList.introduction_team,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.introduction_team === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Yes
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="caption">All:</Typography>
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="caption">
                              1.Confirmation of the following;patient's
                              identity, procedure, incision site, informed
                              consent.
                            </Typography>
                            <Box hidden={enableEdit}>
                              <Typography variant="caption">
                                {existingAttendanceSheetChart.data.length >
                                0 ? (
                                  Boolean(
                                    parseInt(
                                      existingAttendanceSheetChart.data[0]
                                        .informed_consent
                                    )
                                  ) ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                                Yes
                              </Typography>
                            </Box>
                            <Box hidden={!enableEdit}>
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        informed_consent:
                                          !checkList.informed_consent,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.informed_consent === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Yes
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="caption">
                              2.Site is marked and visible.
                              <Box hidden={enableEdit} display={"flex"}>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .site_mark_visible
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Box>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    !Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .site_mark_visible
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  N/A
                                </Box>
                              </Box>
                              <Box hidden={!enableEdit} display={"flex"}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          site_mark_visible: true,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.site_mark_visible === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          site_mark_visible: false,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.site_mark_visible === false ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    N/A
                                  </Typography>
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="caption">
                              3.Relevant images properly labeled and displayed.
                              <Box hidden={enableEdit} display={"flex"}>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .relevant_images
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Box>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    !Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .relevant_images
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  N/A
                                </Box>
                              </Box>
                              <Box hidden={!enableEdit} display={"flex"}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          relevant_images: true,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.relevant_images === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          relevant_images: false,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.relevant_images === false ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    N/A
                                  </Typography>
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="caption">
                              Anticipated Critical Events
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="caption">
                              <span style={{ fontWeight: "bolder" }}>
                                Surgeon:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="caption">
                              States the following
                            </Typography>
                            <Box>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .non_routine_steps
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Critical or non-routine steps
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        non_routine_steps:
                                          !checkList.non_routine_steps,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.non_routine_steps === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Critical or non-routine steps
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .case_duration
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Case duration
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        case_duration: !checkList.case_duration,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.case_duration === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Case duration
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .anticipated_blood_loss
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  anticipated blood loss
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        anticipated_blood_loss:
                                          !checkList.anticipated_blood_loss,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.anticipated_blood_loss ===
                                    true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  anticipated blood loss
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="caption">
                              <span style={{ fontWeight: "bolder" }}>
                                Anesthesiologist:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <Box>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .specific_concerns
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Any patient-specific concerns?
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        specific_concerns:
                                          !checkList.specific_concerns,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.specific_concerns === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Any patient-specific concerns?
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="caption">
                              <span style={{ fontWeight: "bolder" }}>
                                Scrub and Circulating nurses:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <Box>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .sterilization
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Sterilization indicators have been confirmed
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        sterilization: !checkList.sterilization,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.sterilization === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Sterilization indicators have been confirmed
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Box hidden={enableEdit}>
                                <Typography variant="caption">
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .antibiotic
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Antibiotic prophylaxis within one hour
                                </Typography>
                              </Box>
                              <Box hidden={!enableEdit}>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        antibiotic: !checkList.antibiotic,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.antibiotic === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Sterilization indicators have been confirmed
                                </Typography>
                              </Box>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <Typography variant="caption">
                                Before incision.
                                <Box hidden={enableEdit} display={"flex"}>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .before_incision
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    Yes
                                  </Box>
                                  <Box>
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      !Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .before_incision
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    N/A
                                  </Box>
                                </Box>
                                <Box hidden={!enableEdit} display={"flex"}>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            before_incision: true,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.before_incision === true ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      Yes
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant={"subtitle1"}>
                                      <IconButton
                                        onClick={() =>
                                          setCheckList({
                                            ...checkList,
                                            before_incision: false,
                                          })
                                        }
                                        disabled={!enableEdit}
                                      >
                                        {checkList.before_incision === false ? (
                                          <CheckBox fontSize="small" />
                                        ) : (
                                          <CheckBoxOutlineBlankOutlined fontSize="small" />
                                        )}
                                      </IconButton>
                                      N/A
                                    </Typography>
                                  </Box>
                                </Box>
                              </Typography>
                            </Box>
                            <Box>
                              <Box>
                                <Box hidden={enableEdit}>
                                  <Typography variant="caption">
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .issues_concern
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    Any equipment issues or concerns?
                                  </Typography>
                                </Box>
                                <Box hidden={!enableEdit}>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          issues_concern:
                                            !checkList.issues_concern,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.issues_concern === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Any equipment issues or concerns?
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <Typography variant="caption">
                                <span style={{ fontWeight: "bolder" }}>
                                  Surgical Fire Risk Assessment:
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <Box>
                                <Box hidden={enableEdit}>
                                  <Typography variant="caption">
                                    {existingAttendanceSheetChart.data.length >
                                    0 ? (
                                      Boolean(
                                        parseInt(
                                          existingAttendanceSheetChart.data[0]
                                            .score
                                        )
                                      ) ? (
                                        <CheckBox />
                                      ) : (
                                        <CheckBoxOutlineBlank />
                                      )
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                    Score{" "}
                                    {!enableEdit
                                      ? existingAttendanceSheetChart.data
                                          .length > 0
                                        ? existingAttendanceSheetChart.data[0]
                                            .score_word
                                        : ""
                                      : null}
                                  </Typography>
                                </Box>
                                <Box hidden={!enableEdit}>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          score: !checkList.score,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.score === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Score
                                    {enableEdit && (
                                      <>
                                        <TextField
                                          fullWidth
                                          name="score_word"
                                          defaultValue={
                                            existingAttendanceSheetChart.data
                                              .length > 0
                                              ? existingAttendanceSheetChart
                                                  .data[0].score_word
                                              : ""
                                          }
                                          InputProps={{
                                            disableUnderline: !enableEdit,
                                            disabled: !enableEdit,
                                          }}
                                        />
                                      </>
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="caption">
                              1.Name of operative procedure.
                            </Typography>
                            <Box hidden={enableEdit}>
                              <Typography variant="caption">
                                {existingAttendanceSheetChart.data.length >
                                0 ? (
                                  Boolean(
                                    parseInt(
                                      existingAttendanceSheetChart.data[0]
                                        .operative_procedure
                                    )
                                  ) ? (
                                    <CheckBox />
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                                Yes
                              </Typography>
                            </Box>
                            <Box hidden={!enableEdit}>
                              <Box>
                                <Typography variant={"subtitle1"}>
                                  <IconButton
                                    onClick={() =>
                                      setCheckList({
                                        ...checkList,
                                        operative_procedure:
                                          !checkList.operative_procedure,
                                      })
                                    }
                                    disabled={!enableEdit}
                                  >
                                    {checkList.operative_procedure === true ? (
                                      <CheckBox fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                  Yes
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="caption">
                              2.Completion of counts:
                            </Typography>
                          </Box>
                          <Box display={"flex"}>
                            <Typography variant="caption">
                              A.Sponges
                              <Box hidden={enableEdit} display={"flex"}>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .sponges
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Box>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    !Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .sponges
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  N/A
                                </Box>
                              </Box>
                              <Box hidden={!enableEdit} display={"flex"}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          sponges: true,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.sponges === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          sponges: false,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.sponges === false ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    N/A
                                  </Typography>
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                          <Box display={"flex"}>
                            <Typography variant="caption">
                              B.Instruments
                              <Box hidden={enableEdit} display={"flex"}>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .instruments
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Box>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    !Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .instruments
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  N/A
                                </Box>
                              </Box>
                              <Box hidden={!enableEdit} display={"flex"}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          instruments: true,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.instruments === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          instruments: false,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.instruments === false ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    N/A
                                  </Typography>
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                          <Box display={"flex"}>
                            <Typography variant="caption">
                              C.Needles
                              <Box hidden={enableEdit} display={"flex"}>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .needles
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Box>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    !Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .needles
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  N/A
                                </Box>
                              </Box>
                              <Box hidden={!enableEdit} display={"flex"}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          needles: true,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.needles === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          needles: false,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.needles === false ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    N/A
                                  </Typography>
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                          <Box display={"flex"}>
                            <Typography variant="caption">
                              D.Sharps
                              <Box hidden={enableEdit} display={"flex"}>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .sharps
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Box>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    !Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .sharps
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  N/A
                                </Box>
                              </Box>
                              <Box hidden={!enableEdit} display={"flex"}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          sharps: true,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.sharps === true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          sharps: false,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.sharps === false ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    N/A
                                  </Typography>
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                          <Box display={"flex"}>
                            <Typography variant="caption">
                              3.Specimens identified and labeled
                              <Box hidden={enableEdit} display={"flex"}>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .identified_specimen
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Box>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    !Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .identified_specimen
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  N/A
                                </Box>
                              </Box>
                              <Box hidden={!enableEdit} display={"flex"}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          identified_specimen: true,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.identified_specimen ===
                                      true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          identified_specimen: false,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.identified_specimen ===
                                      false ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    N/A
                                  </Typography>
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                          <Box display={"flex"}>
                            <Typography variant="caption">
                              4.Any equipment problems to be addressed?
                              <Box hidden={enableEdit} display={"flex"}>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .problems_to_address
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  Yes
                                </Box>
                                <Box>
                                  {existingAttendanceSheetChart.data.length >
                                  0 ? (
                                    !Boolean(
                                      parseInt(
                                        existingAttendanceSheetChart.data[0]
                                          .problems_to_address
                                      )
                                    ) ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )
                                  ) : (
                                    <CheckBoxOutlineBlank />
                                  )}
                                  N/A
                                </Box>
                              </Box>
                              <Box hidden={!enableEdit} display={"flex"}>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          problems_to_address: true,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.problems_to_address ===
                                      true ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    Yes
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant={"subtitle1"}>
                                    <IconButton
                                      onClick={() =>
                                        setCheckList({
                                          ...checkList,
                                          problems_to_address: false,
                                        })
                                      }
                                      disabled={!enableEdit}
                                    >
                                      {checkList.problems_to_address ===
                                      false ? (
                                        <CheckBox fontSize="small" />
                                      ) : (
                                        <CheckBoxOutlineBlankOutlined fontSize="small" />
                                      )}
                                    </IconButton>
                                    N/A
                                  </Typography>
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="caption">
                              To all Surgical team members:
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="caption">
                              What are the key concerns for recovery and
                              management of the patient?
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              style={{ textDecoration: "underline" }}
                            >
                              {!enableEdit
                                ? existingAttendanceSheetChart.data.length > 0
                                  ? existingAttendanceSheetChart.data[0]
                                      .concerns_for_recovery
                                  : "____________________"
                                : null}
                              {enableEdit && (
                                <>
                                  <TextField
                                    fullWidth
                                    name="concerns_for_recovery"
                                    defaultValue={
                                      existingAttendanceSheetChart.data.length >
                                      0
                                        ? existingAttendanceSheetChart.data[0]
                                            .concerns_for_recovery
                                        : ""
                                    }
                                    InputProps={{
                                      disableUnderline: !enableEdit,
                                      disabled: !enableEdit,
                                    }}
                                  />
                                </>
                              )}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box mt={10}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Box align="center">
                        <Box borderBottom={1} width={220}>
                          <Typography variant="subtitle2">
                            {!enableEdit
                              ? existingAttendanceSheetChart.data.length > 0
                                ? existingAttendanceSheetChart.data[0]
                                    .signature_circulating
                                : ""
                              : null}
                          </Typography>
                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="signature_circulating"
                                defaultValue={
                                  existingAttendanceSheetChart.data.length > 0
                                    ? existingAttendanceSheetChart.data[0]
                                        .signature_circulating
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            </>
                          )}
                        </Box>
                        <Box>
                          <Typography variant="caption">
                            Name and Signature of Circulating Nurse
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box align="center">
                        <Box borderBottom={1} width={220}>
                          <Typography variant="subtitle2">
                            {!enableEdit
                              ? existingAttendanceSheetChart.data.length > 0
                                ? existingAttendanceSheetChart.data[0]
                                    .signature_anesthesiologist
                                : ""
                              : null}
                          </Typography>
                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="signature_anesthesiologist"
                                defaultValue={
                                  existingAttendanceSheetChart.data.length > 0
                                    ? existingAttendanceSheetChart.data[0]
                                        .signature_anesthesiologist
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            </>
                          )}
                        </Box>
                        <Box>
                          <Typography variant="caption">
                            Name and Signature of Anesthesiologist
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box align="center">
                        <Box borderBottom={1} width={220}>
                          <Typography variant="subtitle2">
                            {!enableEdit
                              ? existingAttendanceSheetChart.data.length > 0
                                ? existingAttendanceSheetChart.data[0]
                                    .signature_surgeon
                                : ""
                              : null}
                          </Typography>
                          {enableEdit && (
                            <>
                              <TextField
                                fullWidth
                                name="signature_surgeon"
                                defaultValue={
                                  existingAttendanceSheetChart.data.length > 0
                                    ? existingAttendanceSheetChart.data[0]
                                        .signature_surgeon
                                    : ""
                                }
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  disabled: !enableEdit,
                                }}
                              />
                            </>
                          )}
                        </Box>
                        <Box>
                          <Typography variant="caption">
                            Name & Signature of Surgeon
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default FormIDontKnow;
