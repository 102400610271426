import { useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import SelectInputField from "src/components/forms/SelectInputField";
import TextInputField from "src/components/forms/TextInputField";
const ItemRow = ({ item }) => {
	return (
		<tr>
			<td>{item?.item?.code}</td>
			<td>{item?.item?.name}</td>
			<td>{item?.quantity}</td>
			<td>{item?.item?.unit_measurement}</td>
			<td>{parseFloat(item?.price).toFixed(2)}</td>
			<td>{parseFloat(item?.amount).toFixed(2)}</td>
		</tr>
	);
};
const LMISViewItemsList = ({ location }) => {
	const new_item = {
		item_code: "",
		item_name: "",
		qty: "",
		unit: "",
		price: "",
		amount: "",
		location: "",
	};
	return (
		<div className="table mb-0">
			<table>
				<thead>
					<tr>
						<th>Item Code</th>
						<th>Item Name</th>
						<th style={{ width: 128 }}>Qty</th>
						<th style={{ width: 144 }}>Unit</th>
						<th style={{ width: 188 }}>Unit Price</th>
						<th style={{ width: 128 }}>Amount</th>
					</tr>
				</thead>
				<tbody>
					{location?.items?.map((item) => {
						return (
							<ItemRow item={item} key={`item-row-${item?.id}`} />
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default LMISViewItemsList;
