import { Box, Grid, Paper, Tabs, Tab, makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useState, useCallback, useEffect } from "react";
import TabPanel from "src/utils/TabPanel";
import FormOne from "./forms/FormOne";
import FormTwo from "./forms/FormTwo";
import FormThree from "./forms/FormThree";
import FormFour from "./forms/FormFour";
import { UsersData } from "src/ContextAPI";
import FormTwentySix from "./forms/FormTwentySix";
import FormTwentyFive from "./forms/FormTwentyFive";
import FormMedicalAbstract from "./forms/FormMedicalAbstract";
import FormClinicalSummary from "./forms/FormClinicalSummary";

import FormSeven from "./forms/FormSeven";
import FormEigth from "./forms/FormEight";
import FormNine from "./forms/FormNine";
import FormTen from "./forms/FormTen";
import FormEleven from "./forms/FormEleven";
import FormThirteen from "./forms/FormThirteen";
import FormFourteen from "./forms/FormFourteen";
import FormTwelve from "./forms/FormTwelve";
import FormDischargeIns from "./forms/FormDischargeIns";
import FormAddressoGraph from "./forms/FormAddressoGraph";
import FormTempAndPulse from "./forms/FormTempAndPulse";

import FormIDontKnow from "./forms/FormIDontKnow";
import FormFifteen from "./forms/FormFifteen";
import Covid19TriageChecklistForm from "./forms/Covid19TriageChecklistForm";
import FormMedicalTreatmentSheetStat from "./forms/FormMedicalTreatmentSheetStat";
import FormMedicalTreatmentSheetPRN from "./forms/FormMedicalTreatmentSheetPRN";

const useStyle = makeStyles({
  indicatorLeft: {
    left: "0px",
  },
});

const PatientCharts = ({
  patient_id,
  trace_number,
  details,
  allowEdit,
  viewtype,
}) => {
  const classes = useStyle();
  const [category, setCategory] = useState("form-1");
  const { users } = React.useContext(UsersData);
  const [info, setInfo] = useState({});
  const [doctors, setDoctors] = useState([]);

  console.log(`patient_id:`, patient_id);
  console.log(`trace_number:`, trace_number);

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  const getPatientInformation = useCallback(() => {
    var params = { patient_id };
    axios
      .get("nurse/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        setInfo(data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, [patient_id]);

  const getDoctorByManagement = useCallback(() => {
    var params = { management_id: users.management_id };
    axios
      .get("or/management/doctorlist-bymanagement", { params })
      .then((response) => {
        const data = response.data;
        setDoctors(data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, [users]);

  useEffect(() => {
    getPatientInformation();
    getDoctorByManagement();
  }, [getPatientInformation, getDoctorByManagement]);

  return (
    <Box>
      {viewtype === "history" ? (
        <FormClinicalSummary
          patient_id={patient_id}
          allowEdit={allowEdit}
          trace_number={trace_number}
          info={Object.keys(info).length > 0 ? info : null}
          doctorsList={doctors}
        />
      ) : (
        <Grid container spacing={2}>
          <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
            <Grid item xs={12} sm={12} md={9}>
              <Box>
                <TabPanel value={category} index="form-1">
                  <FormOne
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>

                <TabPanel value={category} index="form-1B">
                  <Covid19TriageChecklistForm
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    details={details}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>

                <TabPanel value={category} index="form-2">
                  <FormTwo
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                  />
                </TabPanel>
                <TabPanel value={category} index="form-3">
                  <FormThree
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>
                <TabPanel value={category} index="form-4">
                  <FormFour
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>
                <TabPanel value={category} index="form-7">
                  <FormSeven
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    info={Object.keys(info).length > 0 ? info : null}
                    details={details}
                    doctorsList={doctors}
                  />
                </TabPanel>
                <TabPanel value={category} index="form-8">
                  <FormEigth
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    info={Object.keys(info).length > 0 ? info : null}
                    details={details}
                    doctorsList={doctors}
                  />
                </TabPanel>
                <TabPanel value={category} index="form-9">
                  <FormNine
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    details={details}
                  />
                </TabPanel>
                <TabPanel value={category} index="form-10">
                  <FormTen
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    details={details}
                  />
                </TabPanel>
                <TabPanel value={category} index="form-11">
                  <FormEleven
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    details={details}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>
                <TabPanel value={category} index="form-12A">
                  <FormIDontKnow
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    details={details}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>
                <TabPanel value={category} index="form-12">
                  <FormTwelve
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    details={details}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>
                <TabPanel value={category} index="form-13">
                  <FormThirteen
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    details={details}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>
                <TabPanel value={category} index="form-14">
                  <FormFourteen
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    details={details}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>

                <TabPanel value={category} index="form-15">
                  <FormFifteen
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    details={details}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>

                <TabPanel value={category} index="form-20">
                  <FormTempAndPulse
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>

                <TabPanel value={category} index="form-21">
                  <FormAddressoGraph
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>

                <TabPanel value={category} index="form-21B">
                  <FormMedicalTreatmentSheetStat
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>

                <TabPanel value={category} index="form-21C">
                  <FormMedicalTreatmentSheetPRN
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>

                <TabPanel value={category} index="form-22">
                  <FormDischargeIns
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>

                <TabPanel value={category} index="form-23">
                  <FormClinicalSummary
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>

                <TabPanel value={category} index="form-24">
                  <FormMedicalAbstract
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>

                <TabPanel value={category} index="form-25">
                  <FormTwentyFive
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>
                <TabPanel value={category} index="form-26">
                  <FormTwentySix
                    patient_id={patient_id}
                    allowEdit={allowEdit}
                    trace_number={trace_number}
                    info={Object.keys(info).length > 0 ? info : null}
                    doctorsList={doctors}
                  />
                </TabPanel>
              </Box>
            </Grid>
          </Box>
          <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
            <Grid item xs={12} sm={12} md={3}>
              <Paper variant="outlined">
                <Tabs
                  value={category}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="on"
                  variant="scrollable"
                  orientation={
                    window.innerWidth > 959 ? "vertical" : "horizontal"
                  }
                  classes={{
                    indicator: classes.indicatorLeft,
                  }}
                >
                  <Tab wrapped value="form-1" label={"CHECKLIST"} />
                  <Tab wrapped value="form-1B" label={"COVID-19 CHECKLIST"} />
                  <Tab wrapped value="form-2" label={"INFORMATION SHEET"} />
                  <Tab wrapped value="form-3" label={"CONSENT"} />
                  <Tab wrapped value="form-4" label={"CARDIOPULMONARY"} />
                  <Tab wrapped value="form-7" label="LABORATORY" />
                  <Tab wrapped value="form-8" label="PERIOPERATIVE" />
                  {/* <Tab wrapped value="form-9" label="SURGICAL TEAM" />
                <Tab wrapped value="form-10" label="POSTOPERATIVE" /> */}
                  <Tab wrapped value="form-11" label="DOCTOR'S CONSULTATION" />
                  <Tab wrapped value="form-12A" label="W.H.O CHECKLIST" />
                  <Tab wrapped value="form-12" label="OPERATIVE RECORD" />
                  <Tab wrapped value="form-13" label="DOCTOR'S ORDER" />
                  <Tab wrapped value="form-14" label="POST ANESTHESIA UNIT" />
                  <Tab
                    wrapped
                    value="form-15"
                    label="JP DRAIN MONITORING SHEET"
                  />
                  <Tab wrapped value="form-20" label={"Temp And Pulse"} />
                  <Tab
                    wrapped
                    value="form-21"
                    label={"Medical/Treatment Sheet"}
                  />
                  <Tab
                    wrapped
                    value="form-21B"
                    label={"Medical/Treatment Sheet(STAT)"}
                  />
                  <Tab
                    wrapped
                    value="form-21C"
                    label={"Medical/Treatment Sheet(PRN)"}
                  />

                  <Tab
                    wrapped
                    value="form-22"
                    label={"Discharge Instructions"}
                  />
                  {/* <Tab
                    wrapped
                    value="form-23"
                    label={
                      "History of present illness and physical examination"
                    }
                  /> */}
                  <Tab wrapped value="form-24" label={"Medical Abstract"} />
                  <Tab wrapped value="form-25" label={"Bedside Notes"} />
                  <Tab wrapped value="form-26" label={"Billing"} />
                </Tabs>
              </Paper>
            </Grid>
          </Box>
        </Grid>
      )}
    </Box>
  );
};

export default PatientCharts;
