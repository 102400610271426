import { Box, Paper, Tabs, Tab } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import Container from "src/layout/Container";
import TabPanel from "src/utils/TabPanel";
import AddOnSales from "./AddOnSales";
// import ImagingTest from "./test/ImagingTest";
// import ImagingSales from "./sales/sales";
// import TestPerson from "./test-person";
import AddOnTest from "./AddOnTest";

const AccountingOtherTest = () => {
  const [category, setCategory] = useState("sales");
  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Other Test",
        items: [{ name: "Dashboard", path: "/sph/app/accounting" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Other test</Box>
          <Box>
            <Paper>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab value="sales" label="Sales" />
                <Tab value="test" label="Other Test" />
              </Tabs>
            </Paper>
          </Box>
        </Box>
      }
    >
      <Box>
        <TabPanel value={category} index="sales">
          <AddOnSales />
        </TabPanel>
        <TabPanel value={category} index="test">
          <AddOnTest />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default AccountingOtherTest;
