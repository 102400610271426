import {
	Box,
	Button,
	Grid,
	makeStyles,
	Popover,
	TextField,
	Typography,
	LinearProgress,
	IconButton,
	Tooltip,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Container from "src/layout/Container";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timePlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import axios from "axios";
import Notify from "src/notification/Notify";
import {
	Video as VcallIcon,
	ArrowRight as DetailsIcon,
	XCircle as CloseIcon,
} from "react-feather";
import { blue, green, orange } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import IsMountedRef from "src/utils/IsMountedRef";
import { UsersData } from "src/ContextAPI";

const useStyles = makeStyles(() => ({
	appointmentbg_success: {
		backgroundColor: green[500],
		borderColor: green[500],
		color: "#fff !important",
		cursor: "pointer",
	},
	appointmentbg_new: {
		backgroundColor: orange[500],
		borderColor: orange[500],
		color: "#fff !important",
		cursor: "pointer",
	},
	appointmentbg_approved: {
		backgroundColor: blue[500],
		borderColor: blue[500],
		color: "#fff !important",
		cursor: "pointer",
	},
}));

const FullcalendarAppointment = () => {
	const classes = useStyles();
	const ismounted = IsMountedRef();
	const [displayType, setDisplayType] = React.useState("dayGridMonth");
	const calendarRef = React.useRef(null);

	const [appointment, setAppointment] = React.useState([]);
	const [localReady, setLocalReady] = React.useState(false);
	const [virtualReady, setVirtualReady] = React.useState(false);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedEvent, setSelectedEvent] = React.useState(null);

	const { users } = React.useContext(UsersData);

	const updateDefaultDisplay = (data) => {
		calendarRef.current.getApi().changeView(data);
		setDisplayType(data);
	};

	const getAllLocalAppointment = () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);

		axios
			.post(
				"doctors/fullcalendar/appointment/listof-localappointment",
				formdata
			)
			.then((response) => {
				if (ismounted.current) {
					const data = response.data;
					generateEventFromQuery(data);
				}
			})
			.catch((error) => {
				Notify.requestError(error);
			})
			.finally(() => {
				setTimeout(() => {
					setVirtualReady(true);
					setLocalReady(true);
				}, 2000);
			});
	};

	// const getAllVirtualAppointment = () => {
	//   var formdata = new FormData();
	//   formdata.set("user_id", users.user_id);

	//   axios
	//     .post(
	//       "doctors/fullcalendar/appointment/listof-virtualappointment",
	//       formdata
	//     )
	//     .then((response) => {
	//       const data = response.data;
	//       if (ismounted.current) {
	//         generateEventFromQuery(data);
	//         setTimeout(() => {
	//           setVirtualReady(true);
	//           setLocalReady(true);
	//         }, 2000);
	//       }
	//     })
	//     .catch((error) => {
	//       console.log(error);
	//       // Notify.requestError(error);
	//     });
	// };

	const generateEventFromQuery = (data) => {
		setAppointment((appointment) => appointment.concat(data));
	};

	const handleDayClick = (e) => {
		calendarRef.current.getApi().gotoDate(e.date);
		setDisplayType("timeGridDay");
		calendarRef.current.getApi().changeView("timeGridDay");
	};

	const handleEventClick = (e) => {
		setAnchorEl(e.el);
		setSelectedEvent(e.event._def.extendedProps);
	};

	const handleEventDisplay = (e) => {
		const event = e.event._def.extendedProps;

		if (Boolean(parseInt(event.reference_no))) {
			//if reference numnber is not empty appointment is taken from virtual
			if (event.appointment_status === "successful") {
				e.el.className = `${e.el.className}  ${classes.appointmentbg_success}`;
			}

			if (event.appointment_status === "new") {
				e.el.className = `${e.el.className} ${classes.appointmentbg_new}`;
			}

			if (event.appointment_status === "approved") {
				e.el.className = `${e.el.className} ${classes.appointmentbg_approved}`;
			}
		} else {
			//if reference numnber is not empty appointment is taken from local

			if (
				Boolean(parseInt(event.is_complete)) &&
				Boolean(parseInt(event.is_paid_bysecretary))
			) {
				e.el.className = `${e.el.className}  ${classes.appointmentbg_success}`;
			}

			if (
				Boolean(parseInt(event.is_paid_bysecretary)) &&
				!Boolean(parseInt(event.is_complete))
			) {
				e.el.className = `${e.el.className}  ${classes.appointmentbg_approved}`;
			}

			if (
				!Boolean(parseInt(event.is_complete)) &&
				!Boolean(parseInt(event.is_paid_bysecretary))
			) {
				e.el.className = `${e.el.className}  ${classes.appointmentbg_new}`;
			}
		}
	};

	useEffect(() => {
		getAllLocalAppointment();
		// getAllVirtualAppointment();

		// eslint-disable-next-line
	}, [ismounted]);

	const open = Boolean(anchorEl);

	return (
		<Container
			breadcrumbs={{
				enable: true,
				current: "Appointment",
				items: [{ name: "Dashboard", path: "/sph/app/doctor" }],
			}}
			title="Appointment Calendar"
		>
			<>
				{!localReady && !virtualReady && (
					<LinearProgress color="primary" variant="query" />
				)}
				{localReady && virtualReady && (
					<>
						<Box mb={3}>
							<DefaultGridDisplay
								displayType={displayType}
								updateDefaultDisplay={updateDefaultDisplay}
							/>
						</Box>

						<Box mb={3} display="flex">
							<Box display="flex" mr={2}>
								<Box
									className={classes.appointmentbg_success}
									mr={1}
									width={20}
									height={20}
									borderRadius={4}
								/>{" "}
								-{" "}
								<Box ml={1}>
									{" "}
									<i> Completed </i>{" "}
								</Box>
							</Box>

							<Box display="flex" mr={2}>
								<Box
									className={classes.appointmentbg_approved}
									mr={1}
									width={20}
									height={20}
									borderRadius={4}
								/>{" "}
								-{" "}
								<Box ml={1}>
									{" "}
									<i> Approved </i>{" "}
								</Box>
							</Box>

							<Box display="flex" mr={2}>
								<Box
									className={classes.appointmentbg_new}
									mr={1}
									width={20}
									height={20}
									borderRadius={4}
								/>{" "}
								-{" "}
								<Box ml={1}>
									{" "}
									<i> New </i>{" "}
								</Box>
							</Box>
						</Box>

						<Paper component={Box} p={2} elevation={2} mb={2}>
							<FullCalendar
								ref={calendarRef}
								height={"calc(90vh - 110px)"}
								initialView={displayType}
								plugins={[
									listPlugin,
									dayGridPlugin,
									interactionPlugin,
									timePlugin,
								]}
								allDayContent=""
								events={appointment}
								dateClick={handleDayClick}
								eventClick={handleEventClick}
								eventDidMount={handleEventDisplay}
								eventDisplay="auto"
							/>
						</Paper>

						{selectedEvent && (
							<Popover
								open={open}
								anchorEl={anchorEl}
								onClose={() => {
									setAnchorEl(null);
									setSelectedEvent(null);
								}}
								anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
								transformOrigin={{ vertical: "top", horizontal: "center" }}
							>
								<Paper>
									<RenderAppointmentSelectedDetails
										selectedEvent={selectedEvent}
										close={() => {
											setAnchorEl(null);
											setSelectedEvent(null);
										}}
									/>
								</Paper>
							</Popover>
						)}
					</>
				)}
			</>
		</Container>
	);
};

export default FullcalendarAppointment;

const DefaultGridDisplay = ({ displayType, updateDefaultDisplay }) => {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	const [selectedType, setSelectedType] = React.useState("Month");

	const options = [
		{ value: "dayGridMonth", label: "Month" },
		{ value: "timeGridDay", label: "Day" },
		{ value: "listWeek", label: "Week" },
		{ value: "listYear", label: "Year" },
	];

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	const alignItems = window.innerWidth > 500 ? "flex-start" : "center";

	return (
		<Grid container direction="column" alignItems={alignItems}>
			<Grid item xs={12}>
				<ButtonGroup
					variant="contained"
					color="primary"
					ref={anchorRef}
					aria-label="split button"
				>
					<Button> Display By {selectedType} </Button>
					<Button color="primary" size="small" onClick={handleToggle}>
						<ArrowDropDownIcon />
					</Button>
				</ButtonGroup>
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					style={{ zIndex: 999999 }}
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === "bottom" ? "center top" : "center bottom",
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList>
										{options.map((option, index) => (
											<MenuItem
												key={index}
												selected={option.value === displayType}
												onClick={() => {
													updateDefaultDisplay(option.value);
													setSelectedType(option.label);
													setOpen(false);
												}}
											>
												{option.label}
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</Grid>
		</Grid>
	);
};

const RenderAppointmentSelectedDetails = ({ selectedEvent, close }) => {
	const history = useHistory();
	console.log(selectedEvent);
	return (
		<Box p={2}>
			<Box mb={3} display="flex">
				<Box flexGrow={1}>
					<Typography variant="h6" color="primary">
						{" "}
						Details{" "}
					</Typography>
				</Box>

				<Box>
					<IconButton onClick={close} color="primary">
						<CloseIcon />
					</IconButton>
				</Box>
			</Box>

			<Box mb={3}>
				<TextField
					label="Patient"
					fullWidth
					variant="outlined"
					multiline
					defaultValue={`${selectedEvent.lastname}, ${selectedEvent.firstname}`}
					InputProps={{ readOnly: true }}
				/>
			</Box>

			<Box mb={3}>
				<TextField
					label="Service"
					fullWidth
					variant="outlined"
					multiline
					defaultValue={
						Boolean(selectedEvent.reference_no)
							? selectedEvent.doctors_service
							: selectedEvent.services
					}
					InputProps={{ readOnly: true }}
				/>
			</Box>

			<Box mb={3}>
				<TextField
					label="Reason"
					fullWidth
					variant="outlined"
					multiline
					defaultValue={
						Boolean(selectedEvent.reference_no)
							? selectedEvent.appointment_reason === null
								? "None"
								: selectedEvent.appointment_reason
							: selectedEvent.app_reason === null
							? "None"
							: selectedEvent.app_reason
					}
					InputProps={{ readOnly: true }}
				/>
			</Box>

			<Box mb={3}>
				<TextField
					label="Appointment From"
					fullWidth
					variant="outlined"
					defaultValue={
						Boolean(selectedEvent.reference_no) ? `Virtual` : `Walk-In`
					}
					InputProps={{ readOnly: true }}
				/>
			</Box>

			<Box display="flex" mb={2}>
				<Box flexGrow={1} mr={2}>
					{Boolean(selectedEvent.reference_no) && (
						<Tooltip
							arrow
							title={
								selectedEvent.appointment_status === "successful"
									? "Appointment completed"
									: selectedEvent.appointment_status === "new"
									? "Appointment is not yet approve"
									: "Start video call"
							}
						>
							<Box>
								<Button
									fullWidth
									variant="contained"
									color="primary"
									startIcon={<VcallIcon />}
									disabled={
										selectedEvent.appointment_status === "successful" ||
										selectedEvent.appointment_status === "new"
									}
									onClick={() =>
										history.push(
											`/sph/app/doctor/appointment/room/${
												selectedEvent.reference_no
											}/${Notify.randomizeString(selectedEvent.reference_no)}`
										)
									}
								>
									{" "}
									Video call{" "}
								</Button>
							</Box>
						</Tooltip>
					)}
				</Box>

				<Box>
					<Button
						fullWidth
						variant="contained"
						color="default"
						endIcon={<DetailsIcon />}
						onClick={() =>
							history.push(
								`/sph/app/doctor/appointment/calendar/details/${
									Boolean(selectedEvent.reference_no) ? `virtual` : `local`
								}/${selectedEvent.appointment_id}`
							)
						}
					>
						{" "}
						Details{" "}
					</Button>
				</Box>
			</Box>
		</Box>
	);
};
