import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TableBody,
  Typography,
  Button,
} from "@material-ui/core";
import Container from "src/layout/Container";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import { useReactToPrint } from "react-to-print";
import { Printer } from "react-feather";

const dateFormat = "YYYY-MM-DD";

const Report = () => {
  const { users } = useContext(UsersData);
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState([]);
  const mounted = IsMountedRef();

  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [rerender, setRerender] = useState(0);

  const printableRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const getDailyServiceReport = useCallback(() => {
    if (
      !moment(moment(dateTo)).isSame(moment(dateFrom)) &&
      moment(moment(dateTo)).isBefore(moment(dateFrom))
    ) {
      Notify.customToast(`Invalid date`, "Selected date is invalid.");
      return;
    }

    axios
      .get(`doctor/appointment/get-all-appointment`, {
        params: {
          management_id: users.management_id,
          user_id: users.user_id,
          dateFrom: moment(dateFrom, dateFormat).format(dateFormat),
          dateTo: moment(dateTo, dateFormat).format(dateFormat),
          rerender,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setReport(data);
        }
      })
      .catch((err) => console.log("error:", err.message))
      .finally(() => setIsLoading(false));
  }, [users, mounted, rerender, dateFrom, dateTo]);

  const handleEllapseTime = (start, end) => {
    const startDate = moment(start);
    let elapsedDuration = moment.duration(moment(end).diff(startDate));
    return `${elapsedDuration.asMinutes().toFixed(2)} Minutes`;
  };

  useEffect(() => {
    getDailyServiceReport();

    return () => {
      setIsLoading(true);
      setReport([]);
    };
  }, [getDailyServiceReport]);

  return (
    <>
      <Container
        title="Service Report"
        breadcrumbs={{
          enable: true,
          current: "Report",
          items: [{ name: "Dashboard", path: "/sph/app/doctor" }],
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardHeader
                title={
                  <Box display={window.innerWidth > 700 ? "flex" : ""}>
                    <Box flexGrow={1}>Daily Service Report</Box>
                    <Box>
                      <Box display={window.innerWidth > 700 ? "flex" : ""}>
                        <Box>
                          <DatePicker
                            fullWidth
                            inputVariant="outlined"
                            margin="dense"
                            onChange={(date) => setDateFrom(date)}
                            value={dateFrom}
                          />
                        </Box>

                        <Box mx={window.innerWidth > 700 ? 1 : 0}>
                          <DatePicker
                            fullWidth
                            inputVariant="outlined"
                            margin="dense"
                            onChange={(date) => setDateTo(date)}
                            value={dateTo}
                          />
                        </Box>

                        <Box mt={1.2} mx={window.innerWidth > 700 ? 1 : 0}>
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setRerender(rerender + 1);
                            }}
                          >
                            go
                          </Button>
                        </Box>

                        <Box mt={1.2}>
                          <Button
                            onClick={() => handlePrint()}
                            variant="contained"
                            color="primary"
                            startIcon={<Printer />}
                            disabled={report.length === 0}
                          >
                            Print
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                }
              />
              <CardContent>
                <Box m={3} ref={printableRef}>
                  <Box textAlign={"center"} display="none" displayPrint="block">
                    <Typography variant="h4"> Daily Service Report </Typography>
                    <Typography>
                      REPORT DATE: From{" "}
                      {moment(dateFrom, dateFormat).format(dateFormat)} to{" "}
                      {moment(dateTo, dateFormat).format(dateFormat)}
                    </Typography>
                  </Box>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell> Date </TableCell>
                          <TableCell> Patient Id </TableCell>
                          <TableCell> Patient Name </TableCell>
                          <TableCell> Patient Type </TableCell>
                          <TableCell> Sex </TableCell>
                          <TableCell> Age </TableCell>
                          <TableCell> Start Consult Date </TableCell>
                          <TableCell> Start Time </TableCell>
                          <TableCell> End Consult Date </TableCell>
                          <TableCell> End Time </TableCell>
                          <TableCell> Elapsed Time </TableCell>
                          <TableCell> Seen By </TableCell>
                          <TableCell> Address </TableCell>
                          <TableCell> Brgy </TableCell>
                          <TableCell> Family Id </TableCell>
                          <TableCell> PhilHealth </TableCell>
                          <TableCell> Vital Signs </TableCell>
                          <TableCell> Complaints </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {isLoading ? (
                          <TableRow>
                            <TableCell colSpan={18}>
                              <Typography color="primary">
                                Loading, please wait...
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : report.length > 0 ? (
                          report.map((data, key) => (
                            <TableRow key={key}>
                              <TableCell>
                                {Notify.createdAt(data.created_at)}
                              </TableCell>
                              <TableCell>
                                {data.patients_id.replace("p-", "")}
                              </TableCell>
                              <TableCell>
                                {data.firstname} {data.lastname}
                              </TableCell>
                              <TableCell> Regular </TableCell>
                              <TableCell> {data.gender} </TableCell>
                              <TableCell>
                                {data.birthday === null
                                  ? ""
                                  : Notify.calculateAge(data.birthday)}{" "}
                                yr/s
                              </TableCell>
                              <TableCell>
                                {data.app_date === null
                                  ? ""
                                  : Notify.createdAt(data.app_date)}
                              </TableCell>
                              <TableCell>
                                {data.app_date === null
                                  ? ""
                                  : Notify.createdTime(data.app_date)}
                              </TableCell>
                              <TableCell>
                                {data.app_date_end === null
                                  ? ""
                                  : Notify.createdAt(data.app_date_end)}
                              </TableCell>
                              <TableCell>
                                {data.app_date_end === null
                                  ? ""
                                  : Notify.createdTime(data.app_date_end)}
                              </TableCell>
                              <TableCell>
                                {data.app_date_end === null
                                  ? "Ongoing"
                                  : handleEllapseTime(
                                      data.app_date,
                                      data.app_date_end
                                    )}
                              </TableCell>
                              <TableCell> {data.seen_by} </TableCell>
                              <TableCell> {data.city} </TableCell>
                              <TableCell> {data.barangay} </TableCell>
                              <TableCell> {""} </TableCell>
                              <TableCell> {data.philhealth} </TableCell>
                              <TableCell>
                                <Box>Temp: {data.temperature}</Box>
                                {data.blood_systolic === null ? (
                                  ""
                                ) : (
                                  <Box>
                                    BP:
                                    {`${data.blood_systolic}/${data.blood_diastolic}`}
                                  </Box>
                                )}
                                {data.rispiratory === null ? (
                                  ""
                                ) : (
                                  <Box>HR: {data.rispiratory}</Box>
                                )}
                              </TableCell>
                              <TableCell> {data.app_reason} </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={18}>
                              <Typography color="secondary">
                                No record found.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Report;
