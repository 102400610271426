import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";
import Container from "../../layout/Container";
import BasicInfo from "./BasicInfo";
import LoginInfo from "./LoginInfo";

const AccountBilling = () => {
  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "account",
          items: [{ name: "Dashboard", path: "/sph/app" }],
        }}
        title="Account"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BasicInfo />
          </Grid>
          <Grid item xs={12}>
            <LoginInfo />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default AccountBilling;
