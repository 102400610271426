import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

class chemistryRef {
  fbs = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          4.2 - 6.4 mmol/L
        </Typography>
      </Box>
    </Box>
  );

  glucose = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          3.85 - 6.05 mmol/L
        </Typography>
      </Box>
    </Box>
  );

  creatinine = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          44 - 130 umol/L
        </Typography>
      </Box>
      {/* <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          MALE: 62 - 105 μmol/L
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          FEMALE: 53 - 97 μmol/L
        </Typography>
      </Box> */}
    </Box>
  );

  uric = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          MALE: 214 - 488 μmol/L
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          FEMALE: 137 - 363 μmol/L
        </Typography>
      </Box>
    </Box>
  );

  cholesterol = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          {"<"} 5.17 mmol/L
        </Typography>
      </Box>
    </Box>
  );

  triglyceride = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          {"<"} 2.26 mmol/L
        </Typography>
      </Box>
    </Box>
  );

  // differentialCount = () => ( ) no references

  hdlcholesterol = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          MALE: 0.9 - 1.4 mmol/L
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          FEMALE: 1.2 - 1.7 mmol/L
        </Typography>
      </Box>
    </Box>
  );

  ldlcholesterol = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          {"<"} 3.9 mmol/L
        </Typography>
      </Box>
    </Box>
  );

  sgot = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          MALE: {"<"} 45 U/L
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          FEMALE: {"<"} 34 U/L
        </Typography>
      </Box>
    </Box>
  );

  sgpt = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          up to 40 U/L
        </Typography>
      </Box>
    </Box>
  );
}

const ChemistryRef = new chemistryRef();
export default ChemistryRef;
