import { faColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import ActionBtn from "src/components/ActionBtn";
import MagicInputFields from "src/components/forms/MagicInputFields";
import LayoutContainer from "src/components/LayoutContainer";

const type_of_consultations = [
	{
		value: "Adult Immunization",
		label: "Adult Immunization",
	},
	{
		value: "Animal Bite",
		label: "Animal Bite",
	},
	{
		value: "Child Care",
		label: "Child Care",
	},
	{
		value: "Child Immunization",
		label: "Child Immunization",
	},
	{
		value: "Child Nutrition",
		label: "Child Nutrition",
	},
	{
		value: "Covid Form",
		label: "Covid Form",
	},
	{
		value: "Dental care",
		label: "Dental care",
	},
	{
		value: "Family Planning ",
		label: "Family Planning ",
	},
	{
		value: "Firecracker Injury",
		label: "Firecracker Injury",
	},
	{
		value: "General",
		label: "General",
	},
	{
		value: "Injury",
		label: "Injury",
	},
	{
		value: "Post Partum",
		label: "Post Partum",
	},
	{
		value: "Prenatal",
		label: "Prenatal",
	},
	{
		value: "Sick Children",
		label: "Sick Children",
	},
	{
		value: "Tuberculosi",
		label: "Tuberculosi",
	},
];
const ConsulationForm = () => {
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm();
	const submitForm = (data) => {
		alert(1);
		console.log("submitForm data", data);
	};
	return (
		<LayoutContainer
			icon={
				<FontAwesomeIcon icon={faColumns} color="white" size={"2x"} />
			}
			title="Dashboard"
			subtitle={`Welcome Back, ConsulationForm 👋`}
		>
			<div className="p-4 bg-white border rounded-lg shadow w-full h-full flex flex-col">
				<h6 className="mb-4">Consultation Form</h6>
				<div className="grid grid-cols-12 w-full gap-6">
					<div className="col-span-4">
						{console.log(
							"errors?.nature_of_visit",
							errors?.nature_of_visit
						)}
						<MagicInputFields
							type="select"
							className={` lg:!w-full`}
							label={
								<>
									Nature of Visit
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							options={[
								{
									value: "",
									label: "",
								},
							]}
							error={errors?.nature_of_visit?.message}
							helperText={""}
							{...register("nature_of_visit", {
								required: true,
							})}
						/>
					</div>
					<div className="col-span-12">
						<FormGroup className="">
							<FormLabel
								id="type_of_consultation"
								error={errors?.type_of_consultation}
							>
								Type of Consultation / Purpose of visit:
							</FormLabel>
							{errors?.type_of_consultation && (
								<FormHelperText
									error={errors?.type_of_consultation}
								>
									This field is required.
								</FormHelperText>
							)}

							<div className="grid grid-cols-4 gap-1">
								{type_of_consultations.map((data, index) => {
									return (
										<label className="flex items-center gap-3">
											<span className="scale-[1.15]">
												<input
													type="checkbox"
													value={data.value}
													{...register(
														"type_of_consultation",
														{
															required: true,
														}
													)}
												/>
											</span>
											<span className="text-sm form-label">
												{data.label}
											</span>
										</label>
									);
								})}
							</div>
						</FormGroup>
					</div>

					<div className="col-span-4">
						<MagicInputFields
							type="date"
							className={` lg:!w-full`}
							label={
								<>
									Consultation date
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							error={false}
							helperText={""}
						/>
					</div>

					<div className="col-span-4">
						<MagicInputFields
							type="time"
							className={` lg:!w-full`}
							label={
								<>
									Consultation time
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							error={false}
							helperText={""}
						/>
					</div>

					<div className="col-span-4">
						<MagicInputFields
							type="select"
							className={` lg:!w-full`}
							label={
								<>
									Mode of Transaction
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							options={[
								{
									value: "",
									label: "",
								},
							]}
							error={false}
							helperText={""}
						/>
					</div>

					<div className="col-span-4">
						<MagicInputFields
							type="text"
							className={` lg:!w-full`}
							label={
								<>
									Patient Age in Year/s
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							error={false}
							helperText={""}
						/>
					</div>
					<div className="col-span-4">
						<MagicInputFields
							type="text"
							className={` lg:!w-full`}
							label={
								<>
									Patient Age in Month/s
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							error={false}
							helperText={""}
						/>
					</div>
					<div className="col-span-4">
						<MagicInputFields
							type="text"
							className={` lg:!w-full`}
							label={
								<>
									Patient Age in Day/s
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							error={false}
							helperText={""}
						/>
					</div>
					<div className="col-span-3">
						<MagicInputFields
							type="text"
							className={` lg:!w-full`}
							label={
								<>
									Patient Height
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							error={false}
							helperText={""}
						/>
					</div>
					<div className="col-span-3">
						<MagicInputFields
							type="text"
							className={` lg:!w-full`}
							label={
								<>
									Patient Weight
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							error={false}
							helperText={""}
						/>
					</div>
					<div className="col-span-3">
						<MagicInputFields
							type="text"
							className={` lg:!w-full`}
							label={
								<>
									Waste Circumference
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							error={false}
							helperText={""}
						/>
					</div>
					<div className="col-span-3">
						<MagicInputFields
							type="text"
							className={` lg:!w-full`}
							label={
								<>
									Body Mass Index
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							error={false}
							helperText={""}
						/>
					</div>
					<div className="col-span-4">
						<MagicInputFields
							type="select"
							className={` lg:!w-full`}
							label={
								<>
									BMI Category
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							selectPlaceholder={
								<option value="" disabled selected>
									{" "}
									-- SELECT BMI CATEGORY --{" "}
								</option>
							}
							options={[
								{
									value: "",
									label: "",
									disabled: true,
								},
							]}
							error={false}
							helperText={""}
						/>
					</div>
					<div className="col-span-4">
						<MagicInputFields
							type="select"
							className={` lg:!w-full`}
							label={
								<>
									Height for Age
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							selectPlaceholder={
								<option value="" disabled selected>
									{" "}
									-- SELECT HEIGHT FOR AGE --{" "}
								</option>
							}
							options={[
								{
									value: "",
									label: "",
									disabled: true,
								},
							]}
							error={false}
							helperText={""}
						/>
					</div>
					<div className="col-span-4">
						<MagicInputFields
							type="select"
							className={` lg:!w-full`}
							label={
								<>
									Weight for Age
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							selectPlaceholder={
								<option value="" disabled selected>
									{" "}
									-- SELECT Weight FOR AGE --{" "}
								</option>
							}
							options={[
								{
									value: "",
									label: "",
									disabled: true,
								},
							]}
							error={false}
							helperText={""}
						/>
					</div>
					<div className="col-span-4">
						<MagicInputFields
							type="select"
							className={` lg:!w-full`}
							label={
								<>
									Name of Attending
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							selectPlaceholder={
								<option value="" disabled selected>
									{" "}
									-- SELECT ATTENDING PROVIDER --{" "}
								</option>
							}
							options={[
								{
									value: "",
									label: "",
									disabled: true,
								},
							]}
							error={false}
							helperText={""}
						/>
					</div>
					<div className="col-span-8">
						<MagicInputFields
							type="textarea"
							rows={3}
							className={` lg:!w-full`}
							label={
								<>
									Chief Complaint
									<span className="text-red-500 ml-1">*</span>
									:{" "}
								</>
							}
							error={false}
							helperText={""}
						/>
					</div>
					<div className="col-span-12 flex flex-col">
						<FormControl>
							<FormLabel
								id="demo-controlled-radio-buttons-group"
								className="flex"
								error={errors?.patient_consent}
							>
								Patient Consent
								<span className="text-danger ml-2">*</span>
							</FormLabel>
							{errors?.patient_consent && (
								<FormHelperText error={errors?.patient_consent}>
									This field is required.
								</FormHelperText>
							)}

							<RadioGroup error={errors?.patient_consent}>
								<FormControlLabel
									value={"no"}
									control={<Radio />}
									label={
										<>
											<b>No,</b> I don't give my consent
											to transmit my health data to the
											PHIE Lite.
										</>
									}
								/>

								<FormControlLabel
									value={"yes"}
									control={<Radio />}
									label={
										<>
											<b>Yes,</b> I give my consent to
											transmit my health data to the PHIE
											Lite.
										</>
									}
								/>
							</RadioGroup>
						</FormControl>
						<span>
							[Click here to view the Patient Consent Form]
						</span>
					</div>
					<div className="col-span-12 gap-4 flex ">
						<ActionBtn
							onClick={() => {
								alert(1);
							}}
						>
							Submit
						</ActionBtn>

						<ActionBtn type="foreground">Cancel</ActionBtn>
					</div>
				</div>
			</div>
		</LayoutContainer>
	);
};

export default ConsulationForm;
