import { TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "./Header";
import { styles } from "../../styles/styles";

export default function EditOtherInformation({
  patient,
  setValue,
  register,
  errors,
}) {
  return (
    <>
      <Header title={"Other Information"} />
      <div className="px-4 mb-3">
        <div className="mx-auto" style={styles.parentDivContainerNoGap}>
          <div className={styles.borderRight}>
            <div style={styles.divContainerColumn}>
              <div style={styles.divContainer}>
                <TextField
                  label={
                    <label style={styles.label}>Philhealth Category</label>
                  }
                  variant="outlined"
                  fullWidth
                  defaultValue={patient?.phil_health_category_type}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("phil_health_category_type")}
                >
                  <option value="">Select</option>
                  <option value="Employee">Employee</option>
                  <option value="Self-employed">Self-Employed</option>
                  <option value="Individually-paying">
                    Individually Paying
                  </option>
                  <option value="Lifetime">Lifetime</option>
                </TextField>
              </div>
              <div style={styles.divContainer}>
                <TextField
                  label={<label style={styles.label}>Enlistment Date</label>}
                  variant="outlined"
                  type="date"
                  fullWidth
                  defaultValue={patient?.enlistment_date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("enlistment_date")}
                />
              </div>
            </div>
            <div style={styles.divContainer}>
              <TextField
                label={
                  <label style={styles.label}>Philhealth Status Type</label>
                }
                variant="outlined"
                fullWidth
                defaultValue={patient?.phil_health_status_type}
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("phil_health_status_type")}
              >
                <option value="">Select</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Pending">Pending</option>
              </TextField>
            </div>

            <div style={styles.spaceBetween}>
              <input
                style={styles.checkBox}
                type="checkbox"
                id="pcbEligible"
                defaultChecked={patient?.pcb_eligble}
                {...register("pcb_eligble", { defaultChecked: false })}
              />
              <label style={styles.label} htmlFor="pcbEligible">
                PCB Eligible?
              </label>
            </div>
            <div style={styles.spaceBetween}>
              <input
                style={styles.checkBox}
                type="checkbox"
                id="philhealthMember"
                defaultChecked={patient?.phil_health_member}
                {...register("phil_health_member", { defaultChecked: false })}
              />
              <label style={styles.label} htmlFor="philhealthMember">
                Philhealth Member
              </label>
            </div>

            <div style={styles.spaceBetween}>
              <input
                style={styles.checkBox}
                type="checkbox"
                id="dswdNhtsMember"
                defaultChecked={patient?.dswd_nhts}
                {...register("dswd_nhts", { defaultChecked: false })}
              />
              <label style={styles.label} htmlFor="dswdNhtsMember">
                DSWD NHTS Member
              </label>
            </div>
          </div>

          <div className={styles.borderLeft}>
            <div style={styles.divContainer}>
              <TextField
                label={
                  <label style={styles.label}>
                    Family Serial Number
                    <span style={styles.astirisColor}>*</span>
                  </label>
                }
                variant="outlined"
                fullWidth
                defaultValue={patient?.family_serial_no}
                {...register("family_serial_no", { required: true })}
                error={errors.family_serial_no}
                helperText={
                  errors.family_serial_no && "Family Serial Number is required"
                }
              />
            </div>
            <div style={styles.divContainer}>
              <TextField
                label={<label style={styles.label}>Family Member</label>}
                variant="outlined"
                fullWidth
                defaultValue={patient?.family_member}
                {...register("family_member")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
