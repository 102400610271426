import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import { faListUl, faUserInjured } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import TabGroup from "src/components/TabGroup";
import axios from "axios";
import { Fade } from "react-reveal";
import Img from "src/components/Img";
import PatientProfileModal from "./components/PatientProfileModal";
import { replaceFname, replaceLname, replaceMname } from "src/helpers/utils";

const PatientItem = ({ data, onClick }) => {
	return (
		<div
			className="flex items-center rounded-xl gap-3 p-2 bg-background mb-3 last:mb-0 !rounded-xl hover:bg-blue-50 cursor-pointer border"
			onClick={onClick}
		>
			<div className="w-11 h-11 rounded-full flex items-center justify-center p-2 bg-white">
				<img
					src="/patient.png"
					className="w-full  aspect-square object-cover"
					alt=""
				/>
			</div>
			<div className="flex flex-col items-center lg:items-start xl:items-start w-full text-left">
				<h6
					className={`text-left text-sm font-semibold ${
						data?.gender == "Male"
							? "text-blue-700"
							: "text-pink-700"
					} mb-0`}
				>
					{`${replaceLname(data?.lastname)}, ${replaceFname(
						data?.firstname
					)} ${replaceMname(replaceMname(data?.middle))}`}
				</h6>
				<p className="mb-0 text-placeholder">{`${
					data?.municipality ? data?.municipality + ", " : ""
				} ${data?.city ? data?.city + ", " : ""} ${
					data?.barangay ? data?.barangay + ", " : ""
				} ${data?.street}`}</p>
			</div>
		</div>
	);
};

const PhoPatientList = () => {
	const patient_profile_ref = useRef(null);
	const add_patient_ref = useRef(null);

	const [view, setView] = useState("table");
	const { data, loading } = useDataTable("v1/patients");
	const [patient, setPatient] = useState(null);

	const getPatientInfo = (id) => {
		axios.get(`v1/patients/${id}`).then((res) => {
			setPatient(res.data.data);
		});
	};
	const columns = useMemo(
		() => [
			{
				header: "Patient",
				accessorKey: "patient",
				cell: function (data) {
					return (
						<div
							className="flex items-center gap-3 cursor-pointer"
							onClick={() => {
								patient_profile_ref.current.show(data);
							}}
						>
							<div className="w-11 h-11 rounded-full flex items-center justify-center">
								<Img
									type="user"
									name={`${replaceLname(
										data?.lastname
									)}-${replaceFname(
										data?.firstname
									)}-${replaceMname(data?.middle)}`}
									className="w-full  aspect-square object-cover rounded-full"
									alt=""
								/>
							</div>
							<div className="flex flex-col items-center lg:items-start xl:items-start w-full text-left">
								<h6
									className={`text-left text-sm font-semibold ${
										data?.gender == "Male"
											? "text-blue-700"
											: "text-pink-700"
									} mb-1`}
								>
									{`${replaceLname(
										data?.lastname
									)}, ${replaceFname(
										data?.firstname
									)} ${replaceMname(data?.middle)}`}
								</h6>
							</div>
						</div>
					);
				},
			},
			{
				header: "Gender",
				accessorKey: "gender",
				headerClassName: "text-center",
				className: "text-center",
				cell: (data) => {
					return data?.gender;
				},
			},
			{
				header: "Civil Status",
				accessorKey: "civil_status",
				headerClassName: "text-center",
				className: "text-center",
				cell: (data) => {
					return data?.civil_status;
				},
			},
			{
				header: "Address",
				accessorKey: "address",
				cell: (data) => {
					return `${
						data?.municipality ? data?.municipality + ", " : ""
					} ${data?.city ? data?.city + ", " : ""} ${
						data?.barangay ? data?.barangay + ", " : ""
					} ${data?.street}`;
				},
			},
			{
				header: "Action",
				accessorKey: "action",
				className: "w-[244px]",
				cell: (data) => {
					return (
						<div className="flex items-center gap-2">
							<ActionBtn
								size="sm"
								type="primary-light"
								onClick={() => {
									console.log("datadata", data);
									patient_profile_ref.current.show(data);
								}}
							>
								<FlatIcon
									icon="rr-eye"
									className="text-lg mr-2"
								/>
								View Reponse Details
							</ActionBtn>
						</div>
					);
				},
			},
		],
		[]
	);
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faListUl}
						color="white"
						size={"2x"}
					/>
				}
				title="Analytics"
				subtitle="View Kobotoolbox respondents"
			>
				<CardContainer
					containerClassName={` border border- border-opacity-10`}
					header={{
						title: "Respondents List Data",
						children: (
							<div className="ml-auto flex items-center gap-3">
								<TextInputField
									inputClassName=" pl-10"
									className="w-full lg:w-[320px]"
									icon={
										<FlatIcon
											icon="rr-search"
											className="text-sm"
										/>
									}
									placeholder="Search patient"
								/>
							</div>
						),
					}}
					className="!p-0"
				>
					<TableV2
						paginationClassName="px-4"
						columns={columns}
						pagination={true}
						loading={loading}
						data={data?.data}
						onTableChange={(data) => {}}
					/>
				</CardContainer>
			</LayoutContainer>
			<PatientProfileModal ref={patient_profile_ref} />
		</>
	);
};

export default PhoPatientList;
