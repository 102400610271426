import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { UsersData } from "src/ContextAPI";
import { Box, TextField, Button, CircularProgress } from "@material-ui/core";
import Notify from "src/notification/Notify";

const NewPackageForm = ({ selectedDepartment, getPackageUnsave }) => {
  const { users } = useContext(UsersData);
  const [serviceList, setServiceList] = useState({ data: [], ready: false });

  const [imagingOrder, setImagingOrder] = useState([]);

  const [laboratoryCategory, setLaboratoryCategory] = useState([]);
  const [laboratoryTest, setLaboratoryTest] = useState({
    data: [],
    ready: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [psychologyTest, setPsychologyTest] = useState([]);
  const [otherTestList, setOtherTestList] = useState([]);

  const handleSubmitPackage = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.set("department", selectedDepartment);

    var error = [];

    if (selectedDepartment === null) {
      error = "error";
      Notify.fieldRequired("Category");
    }

    if (selectedDepartment === "doctor") {
      if (
        formdata.get("service_id").length === 0 ||
        formdata.get("service_id").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("Service");
      }
    }

    if (selectedDepartment === "imaging") {
      if (
        formdata.get("imaging_order_id").length === 0 ||
        formdata.get("imaging_order_id").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("Xray Order");
      }
    }

    if (selectedDepartment === "laboratory") {
      if (
        formdata.get("category").length === 0 ||
        formdata.get("category").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("Lab Category");
      }
      if (
        formdata.get("order_id").length === 0 ||
        formdata.get("order_id").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("Lab Test");
      }
    }

    if (selectedDepartment === "psychology") {
      if (
        formdata.get("order_id").length === 0 ||
        formdata.get("order_id").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("Lab Test");
      }
    }

    if (error.length > 0) {
      console.warn("Form has an error, observe please");
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/charge/add-package", formdata)
        .then((res) => {
          if (res.data.message === "success") {
            e.target.reset();
            getPackageUnsave();
            Notify.successRequest("add package");
          }
          if (res.data.message === "db-error") {
            Notify.warnRequest("add package");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  //doctor

  const getDoctorsServicesByMngt = async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    try {
      let response = await axios.get("accounting/get/doctor-services", {
        params,
      });
      const data = response.data;
      setServiceList({ data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  };

  //imaging
  const gettingAllImagingOrder = async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      department: selectedDepartment,
    };
    let response = await axios.get(
      "accounting/get/by/label/items-into-package",
      { params }
    );
    setImagingOrder(response.data);
  };

  //laboratory
  const gettingAllLaboratoryOrder = async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      department: selectedDepartment,
    };
    let response = await axios.get(
      "accounting/get/by/label/items-into-package",
      { params }
    );
    setLaboratoryCategory(response.data);
  };

  // Psychology
  const getAllPsychologyTest = async () => {
    let response = await axios.get("accounting/psychology/order-list", {
      params: {
        user_id: users.user_id,
        management_id: users.management_id,
      },
    });
    setPsychologyTest(response.data);
  };

  const handleGetTestByOrderId = async (e) => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      category: e.target.value,
    };
    try {
      let response = await axios.get("accounting/get/laboratory-test", {
        params,
      });
      const data = response.data;
      setLaboratoryTest({ data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  };

  const getOtherOrderList = async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    try {
      let response = await axios.get("accounting/other/order/get-othertest", {
        params,
      });
      const data = response.data;
      setOtherTestList(data);
    } catch (error) {
      Notify.requestError(error);
    }
  };

  useEffect(() => {
    if (selectedDepartment === "doctor") {
      getDoctorsServicesByMngt();
    }
    if (selectedDepartment === "imaging") {
      gettingAllImagingOrder();
    }
    if (selectedDepartment === "laboratory") {
      gettingAllLaboratoryOrder();
    }

    if (selectedDepartment === "psychology") {
      getAllPsychologyTest();
    }

    if (selectedDepartment === "others") {
      getOtherOrderList();
    }

    // eslint-disable-next-line
  }, [selectedDepartment]);

  return (
    <Fragment>
      <form onSubmit={handleSubmitPackage}>
        {selectedDepartment === "doctor" && (
          <Fragment>
            <TextField
              fullWidth
              required
              name="category"
              variant="outlined"
              value={"doctor"}
              hidden
            />

            <Box mb={1}>
              <TextField
                fullWidth
                required
                name="service_id"
                label="Services"
                variant="outlined"
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <option value={""}>Select</option>
                {serviceList.data.length > 0 &&
                  serviceList.data.map((data, index) => (
                    <option key={index} value={data.service_id}>
                      {data.services}
                    </option>
                  ))}
              </TextField>
            </Box>
          </Fragment>
        )}

        {selectedDepartment === "imaging" && (
          <Box>
            <TextField
              fullWidth
              required
              name="category"
              variant="outlined"
              value={"imaging"}
              hidden
            />

            <TextField
              fullWidth
              required
              name="imaging_order_id"
              label="Xray Order"
              variant="outlined"
              select
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <option value={""}>Select</option>
              {imagingOrder.length > 0 &&
                imagingOrder.map((data, index) => (
                  <option key={index} value={data.order_id}>
                    {data.order_desc}
                  </option>
                ))}
            </TextField>
          </Box>
        )}

        {selectedDepartment === "laboratory" && (
          <Fragment>
            <Box mb={2}>
              <TextField
                fullWidth
                required
                name="category"
                label="Lab Category"
                variant="outlined"
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleGetTestByOrderId}
              >
                <option value={""}>Select</option>
                {laboratoryCategory.length > 0 &&
                  laboratoryCategory.map((data, index) => (
                    <option key={index} value={data.category}>
                      {data.category === "clinical-chemistry"
                        ? "Blood Chemistry"
                        : data.category === "clinical-microscopy"
                        ? "Clinical Microscopy"
                        : data.category === "covid-19"
                        ? "Covid 19"
                        : data.category === "hemethology"
                        ? "Hemathology"
                        : data.category === "hepatitis-profile"
                        ? "Hepatitis Profile"
                        : data.category === "serology"
                        ? "Serology"
                        : data.category === "stool-test"
                        ? "Stool Test"
                        : data.category === "thyroid-profile"
                        ? "Thyroid Profile"
                        : data.category === "urinalysis"
                        ? "Urinalysis"
                        : data.category}
                    </option>
                  ))}
              </TextField>
            </Box>

            <Box mb={1}>
              <TextField
                fullWidth
                required
                name="order_id"
                label="Lab Test"
                variant="outlined"
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <option value={""}>Select</option>
                {laboratoryTest.data.length > 0 &&
                  laboratoryTest.data.map((data, index) => (
                    <option key={index} value={data.order_id}>
                      {data.laborder}
                    </option>
                  ))}
              </TextField>
            </Box>
          </Fragment>
        )}

        {selectedDepartment === "psychology" && (
          <Fragment>
            <Box>
              <TextField
                fullWidth
                required
                name="category"
                variant="outlined"
                value={"psychology"}
                hidden
              />
            </Box>

            <Box mb={1}>
              <TextField
                fullWidth
                required
                name="order_id"
                label="Psychology Test"
                variant="outlined"
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <option value={""}>Select</option>
                {psychologyTest.length > 0 &&
                  psychologyTest.map((data, index) => (
                    <option key={index} value={data.test_id}>
                      {data.test}
                    </option>
                  ))}
              </TextField>
            </Box>
          </Fragment>
        )}

        {selectedDepartment === "others" && (
          <Fragment>
            <Box>
              <TextField
                fullWidth
                required
                name="category"
                variant="outlined"
                value={"Other Test"}
                hidden
              />
            </Box>

            <Box mb={1}>
              <TextField
                fullWidth
                required
                name="order_id"
                label="Other Test"
                variant="outlined"
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <option value={""}>Select</option>
                {otherTestList.length > 0 &&
                  otherTestList.map((data, index) => (
                    <option key={index} value={data.order_id}>
                      {data.order_name}
                    </option>
                  ))}
              </TextField>
            </Box>
          </Fragment>
        )}

        <Box mt={2} display="flex">
          <Box flexGrow={1} />
          <Box>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              startIcon={
                isSubmitting && <CircularProgress size={20} color="inherit" />
              }
              disabled={isSubmitting}
            >
              Add
            </Button>
          </Box>
        </Box>
      </form>
    </Fragment>
  );
};

export default NewPackageForm;
