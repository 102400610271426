import { faColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutContainer from "src/components/LayoutContainer";
import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import MagicInputFields from "src/components/forms/MagicInputFields";
import useDataTable from "src/helpers/useDataTable";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import { useEffect, useRef, useState } from "react";
import PatientProfileOverview from "src/cis-doctor/components/PatientProfileOverview";
import CisPatientListItem from "./components/CisPatientListItem";
import PatientProfilePage from "./components/PatientProfilePage";
import ButtonV3 from "src/components/ButtonV3";
import { useHistory } from "react-router-dom";
import CreateNewPatientFormModal from "src/disease-treatment/components/CreateNewPatientFormModal";
import PrivacyStatementModal from "src/disease-treatment/components/PrivacyStatementModal";
import { v4 as uuidv4 } from "uuid";
const modalKey = uuidv4();
export default function CisDoctorPatients() {
	const { clinic } = useClinicContext();
	const history = useHistory();
	const {
		data,
		setPage,
		loading,
		setPaginate,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
		keyword,
		setKeyword,
	} = useDataTable(`v1/patients`, null, {});

	const newPatientFormRef = useRef(null);
	const viewPrivacyRef = useRef(null);

	const [list, setList] = useState([]);
	const [selected, setSelected] = useState(null);
	const [selectedClick, setSelectedClick] = useState(null);
	const [patientSelfie, setPatientSelfie] = useState(null);

	const [modalKeys, setModalKeys] = useState(`${modalKey}`);
	useEffect(() => {
		// setFilters((prevFilters) => ({
		// 	...prevFilters,
		// 	// clinic_id: 1,
		// }));
		// setPaginate(10);
	}, []);
	useEffect(() => {
		setList(data?.data);
	}, [data]);

	const updatePatient = (patient) => {
		console.log("updatePatientupdatePatient,", patient);
		setSelectedClick(patient);
		setList((prevList) =>
			prevList.map((p) =>
				patient?.id == p?.id
					? {
							...p,
							...patient,
					  }
					: p
			)
		);
	};
	return (
		<LayoutContainer
			hideHeader={true}
			// icon={
			// 	<FontAwesomeIcon icon={faColumns} color="white" size={"2x"} />
			// }
			// title="Clinic patients list"
		>
			<div className="flex flex-col gap-2 h-[calc(100vh-204px)]">
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
					<div className="lg:col-span-3 flex flex-col gap-4 h-[calc(100vh-65px)]">
						<div className="flex items-center gap-4">
							<h3 className="font-bold text-xl mb-0 mr-auto">
								Patient List
							</h3>

							<ButtonV3
								type="success"
								onClick={() => {
									setModalKeys(uuidv4());

									setTimeout(() => {
										viewPrivacyRef.current.show();
									}, 100);
									// history.push(`/sph/app/cis/create-patient`);
								}}
							>
								<FlatIcon icon="rr-plus" />
								Add
							</ButtonV3>
						</div>
						<div className="relative w-full flex items-center">
							<input
								type="text"
								placeholder="Search patient"
								className="bg-white rounded-xl !border !border-slate-300 py-2 pr-3 h-11 w-full pl-[48px] !duraton-200 text-sm"
								onChange={(e) => {
									setKeyword(e.target.value);
								}}
							/>
							<span className="absolute left-3 text-slate-300 text-base font-bold pr-2 !border-r flex items-center">
								<FlatIcon icon="rr-search" className=" " />
							</span>
						</div>

						<div className="flex flex-col gap-3 divide-y max-h-[calc(100vh-274)] overflow-auto">
							{loading ? (
								<div className="p-5 flex items-center justify-center bg-foreground animate-pulse rounded-xl">
									<b className="text-placeholder">
										Loading...
									</b>
								</div>
							) : list?.length == 0 ? (
								keyword?.length > 0 ? (
									<div className="p-5 flex items-center justify-center">
										<b className="text-placeholder">
											No results found for "{keyword}".
										</b>
									</div>
								) : (
									<div className="p-5 flex items-center justify-center">
										<b className="text-placeholder">
											No data to display.
										</b>
									</div>
								)
							) : (
								list?.map((x) => {
									return (
										<CisPatientListItem
											patient={x}
											key={`cpli-${x?.id}`}
											onClick={() => {
												history.push({
													// no need
													pathname: `/sph/app/cis/patients`,
													search: `id=${x?.id}`,
												});
												setSelectedClick(x);
											}}
											// onMouseEnter={() => {
											// 	setSelected(x);
											// }}
											// onMouseLeave={() => {
											// 	setSelected(null);
											// }}
										/>
									);
								})
							)}
						</div>
					</div>
					<div className="lg:col-span-9">
						{selectedClick ? (
							<PatientProfilePage
								key={`PatientProfilePage-${selectedClick?.id}`}
								patient={selectedClick}
								setPatient={updatePatient}
							/>
						) : selected == null ? (
							<div className="p-5 flex items-center justify-center text-placeholder-dark text-lg">
								{loading
									? "Loading, please wait..."
									: `Select a patient.`}
							</div>
						) : (
							<PatientProfileOverview patient={selected} />
						)}
					</div>
				</div>
			</div>

			<CreateNewPatientFormModal
				patientSelfie={patientSelfie}
				key={`CreateNewPatientFormModal-${modalKeys}`}
				ref={newPatientFormRef}
				onSuccess={() => {
					newPatientFormRef.current.hide();
					setFilters((prevFilters) => ({
						...prevFilters,
						key: uuidv4(),
					}));
				}}
				noRedirect={true}
			/>
			<PrivacyStatementModal
				newPatient={true}
				key={`PrivacyStatementModal-${modalKeys}`}
				onSuccess={(data) => {
					newPatientFormRef.current.show(null);
				}}
				patient={selectedClick}
				ref={viewPrivacyRef}
				patientSelfie={patientSelfie}
				setPatientSelfie={setPatientSelfie}
			/>
		</LayoutContainer>
	);
}
