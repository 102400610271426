import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Box,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { Formik } from "formik";
import React, { useContext } from "react";
import { CheckSquare, XCircle } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import * as Yup from "yup";

const AddRoom = ({ onClose, getRoomList, room_number, room_id }) => {
  const { users } = useContext(UsersData);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <>
      <Formik
        initialValues={{
          room_number: room_number,
          room_id: room_id,
          management_id: users.management_id,
          no_of_beds: 0,
        }}
        validationSchema={Yup.object().shape({
          no_of_beds: Yup.number().min(1).required("No of beds is required."),
        })}
        onSubmit={async (
          values,
          { setErrors, setSubmitting, resetForm, setFieldValue }
        ) => {
          try {
            const request = await axios.post(
              "accounting/room/bed/add-room-tolist",
              getFormData(values)
            );

            if (request.data.message === "success") {
              Notify.successRequest("room");
              resetForm();
              onClose();
              getRoomList();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box>
                <TextField
                  error={Boolean(touched.no_of_beds && errors.no_of_beds)}
                  helperText={touched.no_of_beds && errors.no_of_beds}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.no_of_beds}
                  fullWidth
                  required
                  name="no_of_beds"
                  label="No of beds"
                  variant="outlined"
                  margin="normal"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                />
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckSquare />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>

              <Button
                variant="contained"
                color="default"
                onClick={() => onClose()}
                startIcon={<XCircle />}
              >
                No
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AddRoom;
