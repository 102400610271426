import {
	Box,
	Card,
	CardContent,
	Dialog,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	withStyles,
	Zoom,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import AdmissionQueuingFullscreen from "./AdmissionQueuingFullscreen";

var interval = null;

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#00cc00",
		color: "#000000",
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const AdmissionQueuingList = () => {
	const { users } = useContext(UsersData);
	const [queuingList, setQueuingList] = useState({ data: [], ready: false });
	const [search, setSearch] = useState("");
	const [fullscreenDialog, setFullscreenDialog] = useState(false);

	const getQueuingList = async () => {
		var params = {
			user_id: users.user_id,
			management_id: users.management_id,
			main_mgmt_id: users.main_mgmt_id,
		};
		try {
			let response = await axios.get("admission/get/queuing-list", { params });
			const data = response.data;
			setQueuingList({ data, ready: true });
		} catch (error) {
			Notify.requestError(error);
		}
	};

	const initializeInterval = () => {
		interval = setInterval(() => {
			getQueuingList();
		}, 60000);
	};

	useEffect(() => {
		getQueuingList();
		initializeInterval();

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line
	}, []);

	const searchable = queuingList.data.filter((data) => {
		return (
			String(data?.firstname || "")
				.toLowerCase()
				.indexOf(search.trim()) !== -1 ||
			String(data?.lastname || "")
				.toLowerCase()
				.indexOf(search.trim()) !== -1
		);
	});

	return (
		<Container
			breadcrumbs={{
				enable: true,
				current: "queuing list",
				items: [{ name: "dashboard", path: "/sph/app/registration" }],
			}}
			title={
				<Box display="flex">
					<Box flexGrow={1}>Queuing List</Box>
				</Box>
			}
		>
			<Card>
				<CardContent>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						mb={2}
					>
						<Box flexGrow={1}>
							<Box maxWidth={230}>
								<TextField
									fullWidth
									label="Search Patient"
									variant="outlined"
									onChange={(e) => setSearch(e.target.value)}
									value={search}
									margin="dense"
								/>
							</Box>
						</Box>
						<Box>
							<IconButton
								color="primary"
								onClick={() => setFullscreenDialog(true)}
							>
								<FullscreenIcon />
							</IconButton>
						</Box>
					</Box>

					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<StyledTableCell>
										<Typography
											style={{ textTransform: "uppercase" }}
											variant="h4"
										>
											<b> No. </b>
										</Typography>
									</StyledTableCell>
									<StyledTableCell>
										<Typography
											style={{ textTransform: "uppercase" }}
											variant="h4"
										>
											<b> Patient </b>
										</Typography>
									</StyledTableCell>
									<StyledTableCell>
										<Typography
											style={{ textTransform: "uppercase" }}
											variant="h4"
										>
											<b> Department </b>
										</Typography>
									</StyledTableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{queuingList.ready ? (
									queuingList.data.length > 0 ? (
										searchable.map((data, index) => (
											<TableRow key={index}>
												<TableCell>
													<Typography
														style={{ textTransform: "uppercase" }}
														variant="h4"
													>
														{index + 1}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography
														style={{ textTransform: "uppercase" }}
														variant="h4"
													>
														<strong>
															{String(data?.firstname || "")}{" "}
															{String(data?.lastname || "").charAt(0)}.
														</strong>
													</Typography>
												</TableCell>
												<TableCell>
													<Typography
														style={{ textTransform: "uppercase" }}
														variant="h4"
													>
														{data.type === "endorsement"
															? "ENDORSEMENT"
															: data.type === "cashier"
															? "CASHIER"
															: data.type === "nursing-station"
															? "NURSE STATION"
															: data.type === "doctor"
															? "DOCTOR"
															: data.type === "laboratory"
															? "LABORATORY"
															: data.type === "imaging"
															? "IMAGING"
															: data.type === "psychology"
															? "PSYCHOLOGY"
															: "RECEIVING/EXTRACTION"}{" "}
														{data.doctorsName !== null &&
															`(${data.doctorsName})`}
													</Typography>
												</TableCell>
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell colSpan={3}>{Notify.noRecord()}</TableCell>
										</TableRow>
									)
								) : (
									<TableRow>
										<TableCell colSpan={3}>{Notify.loading()}</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Card>

			<Dialog
				open={fullscreenDialog}
				fullScreen
				onClose={() => setFullscreenDialog(false)}
				TransitionComponent={Zoom}
				transitionDuration={800}
			>
				<AdmissionQueuingFullscreen queuingList={queuingList} />
			</Dialog>
		</Container>
	);
};

export default AdmissionQueuingList;
