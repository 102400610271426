import ButtonV3 from "src/components/ButtonV3";
import Img from "src/components/Img";
import TabGroup from "src/components/TabGroup";
import CisVitalsChart from "./vitalsChart/CisVitalsChart";
import CisAppointments from "./appointment/CisAppointments";
import CisPatientHistory from "./history/CisPatientHistory";
import CisPatientHealth from "./health/CisPatientHealth";
import CisPatientLaboratory from "./laboratory/CisPatientLaboratory";
import CisPatientImaging from "./imaging/CisPatientsImaging";
import PrescriptionMainTab from "./patient-profile/prescriptions/PrescriptionMainTab";
import CisNotes from "./notes/CisNotes";
import CisTreatmentPlan from "./treatmentPlan/CisTreatmentPlan";
import MedicalCertificateList from "./patient-profile/medical-certificate/MedicalCertificateList";
import CisPatientsForm from "./profile/CisPatientsForm";

import ProfileConsultations from "src/cis/components/patient-profile/consultations/ProfileConsultations";
import { calculateAgeV2, patientFullName } from "src/helpers/utils";
import ClinicProfileVitals from "src/clinic/components/ClinicProfileVitals";
import ClinicProfileVitalsForm from "src/clinic/components/ClinicProfileVitalsForm";
import { formatDate } from "@fullcalendar/react";
import PhoViewPatientProfile from "src/pho/components/PhoViewPatientProfile";
import ViewPatientProfile from "./ViewPatientProfile";
const MenuTitle = ({ src, children }) => {
	return (
		<b className="text-base gap-2 flex flex-col items-center justify-center min-w-[148px]">
			<img src={src} className="h-11 w-11 object-contain" />
			{children}
		</b>
	);
};

const PatientProfilePage = ({ patient, setPatient }) => {
	return (
		<ViewPatientProfile
			patient={patient}
			setPatient={setPatient}
			className="!bg-transparent"
			// add_patient_ref={add_patient_ref}
		/>
	);
};

export default PatientProfilePage;
