import {
	forwardRef,
	useContext,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import FlatIcon from "src/components/FlatIcon";
import InfoText from "src/components/InfoText";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import CollapseDiv from "./CollapseDiv";

import {
	calamities,
	collectors,
	garbage_collection,
	housing_electricity_sources,
	housing_status,
	main_sources,
	often_options,
	toilets,
} from "../../helpers/responseChoices";
import axios from "axios";
import TabGroup from "src/components/TabGroup";
import ActionBtn from "src/components/ActionBtn";
import { Plus, Trash2 } from "react-feather";
import SelectMemberModal from "./SelectMemberModal";
import { relationship_to_hh_options } from "./Forms/household/patient_choices";
import Img from "src/components/Img";

let total = 0;

const MemberItem = ({ patient }) => {
	console.log("patientpatientpatient", patient?.firstname);
	const getRelToHousehold = () => {
		return relationship_to_hh_options.find(
			(x) => x.value == patient?.head_relation
		)?.label;
	};
	return (
		<div className="flex items-center flex-col lg:flex-row lg:items-start p-3 rounded-lg border bg-foreground gap-3">
			<Img
				type="user"
				name={`${patient?.lastname || ""}-${patient?.firstname || ""}-${
					patient?.middle || ""
				}`}
				src={patient?.avatar || ""}
				className=" h-[44px] w-[44px] min-h-[44px] min-w-[44px] rounded-xl"
				alt=""
				key={`key-${patient?.id}-${patient?.avatarBase64}`}
			/>
			<div className="flex flex-col items-center lg:items-start w-full first-letter:">
				<b className="text-base font-bold">{`${
					patient?.lastname || ""
				}, ${patient?.firstname || ""} ${patient?.middle || ""}`}</b>
				<span className="text-placeholder text-xs">
					Relation to Household Head:{" "}
					<b className="text-dark">{getRelToHousehold()}</b>
				</span>
			</div>
		</div>
	);
};
const ViewHouseHoldModal = (props, ref) => {
	const [household, setHousehold] = useState(null);
	const [open, setOpen] = useState(false);
	const select_member_ref = useRef(null);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const getHouseholdData = (id) => {
		return axios.get(`v1/households/${id}`).then((res) => {
			let data = res?.data?.data || null;
			setHousehold(data);
		});
	};
	const show = (data) => {
		getHouseholdData(data?.id);
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};
	return (
		<>
			{" "}
			<Modal open={open} hide={hide} size="md2" className="max-w-6xl">
				<ModalHeader
					title={"View household details"}
					// subtitle="Enter patient details"
					hide={hide}
				/>
				<ModalBody className={`!p-0 flex flex-col  bg-white`}>
					<TabGroup
						contentClassName={`!max-h-[calc(100vh-200px)] !min-h-[calc(100vh-200px)]`}
						contents={[
							{
								title: (
									<>
										<FlatIcon
											icon="rr-share"
											className="mr-1"
										/>
										Kobo Survey
									</>
								),
								content: (
									<div className="flex flex-col gap-y-5">
										<CollapseDiv
											title="A. IDENTIFICATION"
											defaultOpen={true}
											headerClassName="bg-slate-100"
											bodyClassName="bg-white"
										>
											<div className="flex flex-col lg:grid lg:grid-cols-12 gap-4">
												<InfoText
													title={`Respondent`}
													value={
														household?.respondent
													}
													className="col-span-4"
												/>
												<InfoText
													title={`Province`}
													value={"Sarangani"}
													className="col-span-4"
												/>
												<InfoText
													title={`City/Municipality`}
													value={
														household?.city?.name
													}
													className="col-span-4"
												/>
												<InfoText
													title={`Zone`}
													value={household?.zone}
													className="col-span-4"
												/>
												<InfoText
													title={`Barangay`}
													value={
														household?.barangay
															?.name
													}
													className="col-span-4"
												/>
												<InfoText
													title={`Purok/Sitio`}
													value={
														household?.purok?.name
													}
													className="col-span-4"
												/>
												<InfoText
													title={`Street:`}
													value={household?.street}
													className="col-span-4"
												/>
												<InfoText
													title={`House Number`}
													value={
														household?.house_number
													}
													className="col-span-4"
												/>
												<InfoText
													title={`Household Identification Number`}
													value={household?.house_id}
													className="col-span-4"
												/>
											</div>
										</CollapseDiv>
										<CollapseDiv title="B. HOUSING CHARACTERISTICS">
											<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
												<InfoText
													title={`1. In what type of building does the household reside?`}
													value={
														household
															?.houseCharacteristics
															?.building_type
													}
												/>
												<InfoText
													title={`2. What type of construction materials are the roof made of?`}
													value={
														household
															?.houseCharacteristics
															?.roof_materials
													}
												/>
												<InfoText
													title={`3. What type of construction materials are the other walls made of?`}
													value={
														household
															?.houseCharacteristics
															?.wall_materials
													}
												/>
											</div>
										</CollapseDiv>
										<CollapseDiv title="C. CHARACTERISTICS OF THE HOUSEHOLD">
											<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
												<InfoText
													title={`4. How many members of the households are overseas workers?`}
													value={
														household?.rawAnswer
															?.overseas_members
													}
												/>
												<InfoText
													title={`5. How many families are there in the household?`}
													value={
														household?.rawAnswer
															?.nuclear_families
													}
												/>
												<InfoText
													title={`Any family planning method currently used?`}
													value={
														household?.rawAnswer
															?.fam_plan
													}
												/>
												{household?.rawAnswer
													?.fam_plan == "Yes" ? (
													<>
														<InfoText
															title={`Family Plan`}
															value={
																household
																	?.rawAnswer
																	?.fp
															}
														/>

														<InfoText
															title={`Any family planning method currently used?`}
															value={
																household
																	?.rawAnswer
																	?.fp_method
															}
														/>
													</>
												) : (
													""
												)}
												{/* <InfoText title={`Family Plan`} value="Modern Family Planning" /> */}
												<InfoText
													title={`Are there any pregnant women in the household?`}
													value={
														household?.rawAnswer
															?.pregnant
													}
												/>
												<InfoText
													title={`How many members of the household are pregnant?`}
													value={
														household?.rawAnswer
															?.pregnant_number
													}
												/>
												<InfoText
													title={`Are there any solo parents in the household?`}
													value={
														household?.rawAnswer
															?.solo
													}
												/>
												{/* <InfoText
							title={`How many members of the household are solo parents?`}
							value="1"
						/> */}
												<InfoText
													title={`Are there any PWD in the household?`}
													value={
														household?.rawAnswer
															?.pwd
													}
												/>
												{/* <InfoText
							title={`How many members of the household are PWD?`}
							value="1"
						/> */}
												<InfoText
													title={`Are there any domesticated dogs or cats in the household?`}
													value={
														household?.rawAnswer
															?.pets
													}
												/>
												{household?.rawAnswer?.pets ==
													"yes" ?? (
													<>
														<InfoText
															title={`Select which one/ones`}
															value={
																household
																	?.rawAnswer
																	?.number_pets
															}
														/>
														<InfoText
															title={`Have all your pets been vaccinated not surpassing more than a year?`}
															value={
																household
																	?.rawAnswer
																	?.pet_vax
															}
														/>
														<InfoText
															title={`Vaccination Date`}
															value={
																household
																	?.rawAnswer
																	?.pet_vaccine_date
															}
														/>
													</>
												)}

												<InfoText
													title={`How many members are there in the household including OFWs?`}
													value={
														<div className="flex flex-col">
															<span className="font-semibold">
																{household
																	?.houseCharacteristics
																	?.houseHoldMembers
																	?.length ||
																	0}{" "}
																members
															</span>
															<ul className="pl-4 pt-2">
																{household?.houseCharacteristics?.houseHoldMembers?.map(
																	(item) => {
																		return (
																			<li
																				className="flex items-center cursor-pointer hover:text-primary duration-200"
																				onClick={() => {
																					household_modal.current.show(
																						item
																					);
																				}}
																			>
																				<FlatIcon
																					icon="rr-user"
																					className="mr-2 text-sm"
																				/>{" "}
																				{
																					item?.name
																				}
																			</li>
																		);
																	}
																)}
															</ul>
														</div>
													}
												/>
											</div>
										</CollapseDiv>
										<CollapseDiv title="K. WATER AND SANITATION">
											<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
												<InfoText
													title={`What is your household's main source of water for general use?`}
													value={
														main_sources.find(
															(x) =>
																x.value ==
																household
																	?.rawAnswer
																	?.main_source
														)?.label || ""
													}
												/>
												<InfoText
													title={`What is your household's main source of drinking water?`}
													value={
														main_sources.find(
															(x) =>
																x.value ==
																household
																	?.rawAnswer
																	?.drink_water
														)?.label || ""
													}
												/>
												{/* <InfoText title={`Specify other`} value="Test other response" /> */}
												{/* <InfoText
							title={`How far is this water source from your house?`}
							value={household?.sanitation?.main_source}
						/> */}
												<InfoText
													title={`What type of toilet facility does the household have?`}
													value={
														toilets.find(
															(x) =>
																x.value ==
																household
																	?.rawAnswer
																	?.hh_toilet
														)?.label || ""
													}
												/>
												{/* <InfoText
							title={`If NO Own toilet, Access is from?`}
							value="Shared Toilet, Neighbor Relative"
						/>
						<InfoText title={`Specify other`} value="Test other response" /> */}
											</div>
										</CollapseDiv>
										<CollapseDiv title="L. HOUSING">
											<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
												<InfoText
													title={`What is the tenure status of the housing unit and lot occupied by your household`}
													value={
														housing_status.find(
															(x) =>
																x.value ==
																household
																	?.rawAnswer
																	?.status
														)?.label || ""
													}
												/>
												<InfoText
													title={`How long have you been staying in your house?`}
													value={
														household?.rawAnswer
															?.residence_area +
														" years"
													}
												/>
												<InfoText
													title={`Is there any electricity in the dwelling place?`}
													value={
														household?.rawAnswer
															?.electric
													}
												/>
												<InfoText
													title={
														"What is the source of electricity in the dwelling place?"
													}
													value={
														housing_electricity_sources.find(
															(x) =>
																x.value ==
																household
																	?.rawAnswer
																	?.electric_housing
														)?.label || ""
													}
												/>
												<InfoText
													title={`Specify other`}
													value="Test other response"
												/>
												<InfoText
													title={
														"What communication channel do you get your primary means of information?"
													}
													value={household?.rawAnswer?.com_channel.replace(
														/ /g,
														", "
													)}
												/>
											</div>
										</CollapseDiv>
										<CollapseDiv title="M. WASTE MANAGEMENT">
											<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
												<InfoText
													title={`What is the system of garbage disposal adopted by the household?`}
													value={
														<div className="flex flex-col">
															{household?.rawAnswer?.garbage_disposal
																.split(" ")
																.map((item) => {
																	return (
																		<span className="mb-2 w-full">
																			{garbage_collection.find(
																				(
																					x
																				) =>
																					x.value ==
																					item
																			)
																				?.label ||
																				""}
																		</span>
																	);
																})}
														</div>
													}
												/>
												<InfoText
													title={`Who collects the garbage?`}
													value={
														<div className="flex flex-col">
															{household?.rawAnswer?.collector
																.split(" ")
																.map((item) => {
																	return (
																		<span className="mb-2 w-full">
																			{collectors.find(
																				(
																					x
																				) =>
																					x.value ==
																					item
																			)
																				?.label ||
																				""}
																		</span>
																	);
																})}
														</div>
													}
												/>

												<InfoText
													title={`How often is the garbage collected?`}
													value={
														<div className="flex flex-col">
															{household?.rawAnswer?.often_garbage
																.split(" ")
																.map((item) => {
																	return (
																		<span className="mb-2 w-full">
																			{often_options.find(
																				(
																					x
																				) =>
																					x.value ==
																					item
																			)
																				?.label ||
																				""}
																		</span>
																	);
																})}
														</div>
													}
												/>
												<InfoText
													title={`Estimated Volume of Waste`}
													value={`${household?.rawAnswer?.volume_waste} Kg/Month`}
												/>
												{household?.rawAnswer
													?.disposalothers ? (
													<InfoText
														title={`Specify other`}
														value={
															household?.waste
																?.disposalothers
														}
													/>
												) : (
													""
												)}
											</div>
										</CollapseDiv>
										<CollapseDiv title="N. SOURCES OF INCOME">
											<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
												<InfoText
													title={
														<>
															<em>[Monthly]</em>{" "}
															Select sources of
															income
														</>
													}
													value={
														<table className="border">
															<thead>
																<tr>
																	<th className="text-left border-b-2 px-3 py-1">
																		Job name
																	</th>
																	<th className="text-left border-b-2 px-3 py-1 border-l">
																		Monthly
																		Income
																	</th>
																</tr>
															</thead>
															<tbody className="divide-y">
																{household?.income?.map(
																	(item) => {
																		total +=
																			parseFloat(
																				item?.amount
																			);
																		return (
																			<tr>
																				<td className="font-normal text-sm px-3 py-1">
																					{
																						item?.name
																					}
																				</td>
																				<td className="text-right font-normal text-sm px-3 py-1 border-l">
																					₱{" "}
																					{parseFloat(
																						item?.amount
																					).toFixed(
																						2
																					)}
																				</td>
																			</tr>
																		);
																	}
																)}
																<tr>
																	<td className="font-bold text-sm px-3 py-1 border-t-gray-300 border-t-2">
																		TOTAL
																		HOUSEHOLD
																		INCOME
																	</td>
																	<td className="text-right font-bold text-sm px-3 py-1 border-l border-t-gray-300 border-t-2">
																		₱{" "}
																		{total?.toFixed(
																			2
																		)}
																	</td>
																</tr>
															</tbody>
														</table>
													}
												/>
											</div>
										</CollapseDiv>
										<CollapseDiv title="O. CALAMITY">
											<div className="flex flex-col lg:grid lg:grid-cols-1 gap-4">
												<InfoText
													title={`Have you experienced any calamity in the last 6 months?`}
													value={
														household?.rawAnswer
															?.calamity_experienced
													}
												/>
												<InfoText
													title={`Which calamity have you experienced?`}
													value={
														<div className="flex flex-col">
															{household?.rawAnswer?.calamity
																.split(" ")
																.map((item) => {
																	return (
																		<span className="mb-1 w-full">
																			{calamities.find(
																				(
																					x
																				) =>
																					x.value ==
																					item
																			)
																				?.label ||
																				""}
																		</span>
																	);
																})}
														</div>
													}
												/>
												<InfoText
													title={`What assistance did you receive from the government?`}
													value={
														household?.rawAnswer
															?.assistancecalamity
													}
												/>
												<InfoText
													title={`Name of Surveyor`}
													value={
														household?.surveyor ||
														"central-pho-ho"
													}
												/>
											</div>
										</CollapseDiv>
									</div>
								),
							},
							{
								title: (
									<>
										<FlatIcon
											icon="rr-users"
											className="mr-1"
										/>
										Members
									</>
								),
								content: (
									<div className="flex flex-col gap-y-6">
										<ActionBtn
											className="ml-auto"
											size="sm"
											onClick={() => {
												select_member_ref.current.show(
													household?.members
												);
											}}
										>
											<Plus className="w-4 mr-2" /> Manage
											household members
										</ActionBtn>
										{household?.members?.map((patient) => {
											return (
												<MemberItem
													key={`patients_${patient?.id}`}
													patient={patient}
												/>
											);
										})}
									</div>
								),
							},
						]}
					/>
				</ModalBody>
			</Modal>
			<SelectMemberModal
				ref={select_member_ref}
				household={household}
				setHousehold={setHousehold}
			/>
		</>
	);
};

export default forwardRef(ViewHouseHoldModal);
