import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";

const HouseholdCharacteristicsForm = ({
	Controller,
	register,
	errors,
	setValue,
	control,
	household,
	watch,
}) => {
	const natural = [
		{ label: `Lactation Amenorrhea Method (LAM)` },
		{ label: `Standard Days Method (SDM)` },
		{ label: `Sympto-Thermal Method (STM)` },
		{ label: `Basal Body Temperature (BBT)` },
		{
			label: `Billings Ovulation Method (BOM)/ Cervical Mucus Method (CMM)`,
		},
	];

	const modern = [
		{ label: "Condom" },
		{ label: "Pill: Combined-Oral Contraceptive Pill (COC)" },
		{ label: "Pill: Progestin-Only Pill (POP)" },
		{ label: "IUD" },
		{ label: "DMPA/Injectable" },
		{ label: "Vasectomy" },
		{ label: "Bilateral Tubal Ligation (BTL)" },
		{ label: "Progestin Subdermal Implant" },
	];
	return (
		<div className="flex flex-col gap-y-8 patient-form">
			<div className="flex gap-6">
				<TextField
					type="number"
					className=" lg:w-1/2"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={
						<>
							C1. How many members of the households are overseas
							workers? <span className="text-danger ml-2">*</span>
						</>
					}
					variant="outlined"
					{...register("overseas_members", {
						required: true,
					})}
					error={errors?.overseas_members}
					helperText={
						errors?.overseas_members && "This field is required"
					}
				/>
				<TextField
					type="number"
					className=" lg:w-1/2"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={
						<>
							C2. How many families are there in the household?
							<span className="text-danger ml-2">*</span>
						</>
					}
					variant="outlined"
					{...register("nuclear_families", {
						required: true,
					})}
					error={errors?.nuclear_families}
					helperText={
						errors?.nuclear_families && "This field is required"
					}
				/>
			</div>
			<div className="flex flex-wrap gap-6">
				<div className="flex flex-col gap-y-6">
					<FormControl>
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.fam_plan}
						>
							C3. Any family planning method currently used?
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.fam_plan && (
							<FormHelperText error={errors?.fam_plan}>
								This field is required.
							</FormHelperText>
						)}
						<Controller
							rules={{ required: true }}
							name="fam_plan"
							control={control}
							render={({ field }) => (
								<RadioGroup
									{...field}
									key={`fam_plan-${field?.value}`}
								>
									<FormControlLabel
										value="yes"
										control={<Radio />}
										label="Yes"
									/>
									<FormControlLabel
										value="no"
										control={<Radio />}
										label="No"
									/>
								</RadioGroup>
							)}
						/>
					</FormControl>
					{watch("fam_plan") == "yes" ? (
						<FormControl>
							<FormLabel
								id="demo-controlled-radio-buttons-group"
								className="flex"
								error={errors?.fp}
							>
								C4. Family Plan
								<span className="text-danger ml-2">*</span>
							</FormLabel>
							{errors?.fp && (
								<FormHelperText error={errors?.fp}>
									This field is required.
								</FormHelperText>
							)}
							<Controller
								rules={{ required: true }}
								name="fp"
								control={control}
								render={({ field }) => (
									<RadioGroup
										{...field}
										key={`fp-${field?.value}`}
									>
										{console.log("fieldfield", field)}
										<FormControlLabel
											value="modern"
											control={<Radio />}
											label="Modern Family Planning"
										/>
										<FormControlLabel
											value="natural"
											control={<Radio />}
											label="Natural Family Planning"
										/>
									</RadioGroup>
								)}
							/>
						</FormControl>
					) : (
						""
					)}
				</div>
				{watch("fp") == "natural" && (
					<FormControl className="">
						<FormGroup>
							<FormLabel
								id="demo-controlled-radio-buttons-group"
								className="flex"
							>
								C6. Natural Family Planning
								<span className="text-danger ml-2">*</span>
							</FormLabel>
							{errors?.fp_natural && (
								<FormHelperText error={errors?.fp_natural}>
									This field is required.
								</FormHelperText>
							)}
							<div className="flex flex-col gap-y-3 py-3">
								{natural.map((data, index) => {
									return (
										<label className="flex items-center gap-3">
											<span className="scale-[1.15]">
												<input
													type="checkbox"
													{...register("fp_natural")}
													value={index + 1}
												/>
											</span>
											<span className="text-sm form-label">
												{data.label}
											</span>
										</label>
									);
								})}
							</div>
						</FormGroup>
					</FormControl>
				)}{" "}
				{watch("fp") == "modern" && (
					<FormGroup>
						<FormLabel
							id="demo-controlled-radio-buttons-group"
							className="flex"
							error={errors?.fp_method}
						>
							C5. Modern Family Planning
							<span className="text-danger ml-2">*</span>
						</FormLabel>
						{errors?.fp_method && (
							<FormHelperText error={errors?.fp_method}>
								This field is required.
							</FormHelperText>
						)}
						<div className="flex flex-col gap-y-3 py-3">
							{modern.map((data, index) => {
								return (
									<label className="flex items-center gap-3">
										<span className="scale-[1.15]">
											<input
												type="checkbox"
												value={index + 1}
												{...register("fp_method", {
													required: true,
												})}
											/>
										</span>
										<span className="text-sm form-label">
											{data.label}
										</span>
									</label>
								);
							})}
						</div>
					</FormGroup>
				)}
			</div>
			<div className="flex gap-6">
				<FormControl className=" lg:w-1/2">
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.pregnant}
					>
						C14. Are there any pregnant women in the household?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.pregnant && (
						<FormHelperText error={errors?.pregnant}>
							This field is required.
						</FormHelperText>
					)}
					<Controller
						rules={{ required: true }}
						name="pregnant"
						control={control}
						render={({ field }) => (
							<RadioGroup
								{...field}
								row
								key={`pregnant-${field?.value}`}
							>
								<FormControlLabel
									value="yes"
									control={<Radio />}
									label="1 Yes"
								/>
								<FormControlLabel
									value="no"
									control={<Radio />}
									label="2 No"
								/>
							</RadioGroup>
						)}
					/>
				</FormControl>
				<TextField
					className=" lg:w-1/2"
					type="number"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={
						<>
							C15. How many members of the household are pregnant?
							<span className="text-danger ml-2">*</span>
						</>
					}
					variant="outlined"
					{...register("pregnant_number", {
						required: true,
					})}
					error={errors?.pregnant_number}
					helperText={
						errors?.pregnant_number && "This field is required"
					}
				/>
			</div>
			<div className="flex gap-6">
				<FormControl className=" lg:w-1/2">
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.solo}
					>
						C16. Are there any solo parents in the household?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.solo && (
						<FormHelperText error={errors?.solo}>
							This field is required.
						</FormHelperText>
					)}

					<Controller
						rules={{ required: true }}
						name="solo"
						control={control}
						render={({ field }) => (
							<RadioGroup
								row
								{...field}
								key={`solo-${field?.value}`}
							>
								<FormControlLabel
									value="yes"
									control={<Radio />}
									label="1 Yes"
								/>
								<FormControlLabel
									value="no"
									control={<Radio />}
									label="2 No"
								/>
							</RadioGroup>
						)}
					/>
				</FormControl>
				<TextField
					className=" lg:w-1/2"
					type="number"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={
						<>
							C17. How many members of the household are solo
							parents?
							<span className="text-danger ml-2">*</span>
						</>
					}
					variant="outlined"
					{...register("memberssolo", {
						required: true,
					})}
					error={errors?.memberssolo}
					helperText={errors?.memberssolo && "This field is required"}
				/>
			</div>
			<div className="grid grid-cols-2 gap-6">
				<FormControl>
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.pwd}
					>
						C18. Are there any PWD in the household?
						<span className="text-danger ml-2">*</span>
					</FormLabel>
					{errors?.pwd && (
						<FormHelperText error={errors?.pwd}>
							This field is required.
						</FormHelperText>
					)}
					<Controller
						rules={{ required: true }}
						name="pwd"
						control={control}
						render={({ field }) => (
							<RadioGroup
								{...field}
								row
								key={`pwd-${field?.value}`}
							>
								<FormControlLabel
									value="yes"
									control={<Radio />}
									label="1 Yes"
								/>
								<FormControlLabel
									value="no"
									control={<Radio />}
									label="2 No"
								/>
							</RadioGroup>
						)}
					/>
				</FormControl>
				<TextField
					type="number"
					SelectProps={{ native: true }}
					InputLabelProps={{ shrink: true }}
					label={
						<>
							C19. How many members of the household are PWD?
							<span className="text-danger ml-2">*</span>
						</>
					}
					variant="outlined"
					{...register("disabled_number", {
						required: true,
					})}
					error={errors?.disabled_number}
					helperText={
						errors?.disabled_number && "This field is required"
					}
				/>
			</div>
			<FormControl>
				<FormLabel
					id="demo-controlled-radio-buttons-group"
					className="flex"
					error={errors?.pets}
				>
					C20. Are there any domesticated dogs or cats in the
					household?
					<span className="text-danger ml-2">*</span>
				</FormLabel>
				{errors?.pets && (
					<FormHelperText error={errors?.pets}>
						This field is required.
					</FormHelperText>
				)}
				<Controller
					rules={{ required: true }}
					name="pets"
					control={control}
					render={({ field }) => (
						<RadioGroup {...field} row key={`pets-${field?.value}`}>
							<FormControlLabel
								value="yes"
								control={<Radio />}
								label="1 Yes"
							/>
							<FormControlLabel
								value="no"
								control={<Radio />}
								label="2 No"
							/>
						</RadioGroup>
					)}
				/>
			</FormControl>
			<FormControl>
				<FormGroup>
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
						error={errors?.number_pets}
					>
						C21. Select which one/ones
					</FormLabel>
					{errors?.number_pets && (
						<FormHelperText error={errors?.number_pets}>
							This field is required.
						</FormHelperText>
					)}

					<div className="flex flex-col gap-y-3 pt-3">
						{[
							{ value: 1, label: "Cats" },
							{ value: 2, label: "Dogs" },
						].map((data, index) => {
							return (
								<label className="flex items-center gap-3">
									<span className="scale-[1.15]">
										<input
											type="checkbox"
											{...register("number_pets")}
											value={data.value}
										/>
									</span>
									<span className="text-sm form-label">
										{data.label}
									</span>
								</label>
							);
						})}
					</div>
				</FormGroup>
			</FormControl>
			<FormControl>
				<FormLabel
					id="demo-controlled-radio-buttons-group"
					className="flex"
					error={errors?.pet_vax}
				>
					C22. Have all your pets been vaccinated not surpassing more
					than a year?
					<span className="text-danger ml-2">*</span>
				</FormLabel>
				{errors?.pet_vax && (
					<FormHelperText error={errors?.pet_vax}>
						This field is required.
					</FormHelperText>
				)}

				<Controller
					rules={{ required: true }}
					name="pet_vax"
					control={control}
					render={({ field }) => (
						<RadioGroup
							{...field}
							row
							key={`pet_vax-${field?.value}`}
						>
							<FormControlLabel
								value="yes"
								control={<Radio />}
								label="1 Yes"
							/>
							<FormControlLabel
								value="no"
								control={<Radio />}
								label="2 No"
							/>
						</RadioGroup>
					)}
				/>
			</FormControl>
			<TextField
				className=" lg:w-1/2"
				type="date"
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={<>C23. Vaccination Date</>}
				variant="outlined"
				{...register("pet_vaccine_date")}
				error={errors?.pet_vaccine_date}
				helperText={
					errors?.pet_vaccine_date && "This field is required"
				}
			/>
			<TextField
				type="number"
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: true }}
				label={
					<>
						C24. How many members are there in the household
						including OFWs?
						<span className="text-danger ml-2">*</span>
					</>
				}
				variant="outlined"
				{...register("number_of_hh", {
					required: true,
				})}
				error={errors?.number_of_hh}
				helperText={errors?.number_of_hh && "This field is required"}
			/>
		</div>
	);
};

export default HouseholdCharacteristicsForm;
