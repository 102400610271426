import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { useForm } from "react-hook-form";
import axios from "axios";
import TableV2 from "src/components/table/TableV2";

const MapLegendsModal = (props, ref) => {
	const [open, setOpen] = useState(false);
	const [diseases, setDiseases] = useState([]);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const getDiseases = () => {
		axios.get("/v1/diseases/").then((res) => {
			setDiseases(res.data);
		});
	};
	const show = (data) => {
		getDiseases();
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const columns = [
		{
			header: "Diseases and color",
			accessorKey: "name",
			headerClassName: "text-left min-w-[328px]",
			className: "text-left",
			cell: (data) => {
				return (
					<div className="flex">
						<div
							className="w-6 h-6 rounded-full mr-2"
							style={{ background: data?.color }}
						/>
						<div>{data?.name}</div>
					</div>
				);
			},
		},
		{
			header: "Radius",
			accessorKey: "radius",
			headerClassName: "text-left",
			className: "text-left",
			cell: (data) => {
				return data?.radius + " meters";
			},
		},
		{
			header: "Type",
			accessorKey: "type",
			headerClassName: "text-left",
			className: "text-left",
		},
	];
	return (
		<Modal open={open} hide={hide} size="xl" className="max-w-xl">
			<ModalHeader
				title={"MAP LEGENDS"}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="w-full max-h-[calc(100vh-328px)] overflow-auto relative">
					<TableV2
						paginationClassName="px-4"
						columns={columns}
						pagination={false}
						loading={false}
						data={diseases}
						onTableChange={(data) => {}}
						theadClassName={`sticky top-[-1px]`}
					/>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default forwardRef(MapLegendsModal);
