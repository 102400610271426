import React, { Fragment } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
  TableBody,
  Grid,
} from "@material-ui/core";
import HemathologyRef from "src/laboratory/references/Ref_Hemathology";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const HematologyPrintAll = ({
  allowHeaderPatientInfo,
  patients,
  hemaOrderDetails,
}) => {
  return (
    <>
      {hemaOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                HEMATOLOGY
              </span>
            </Typography>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="center">
                    <b style={{ color: "#000" }}> RESULT </b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ color: "#000" }}> REFERENCE VALUES </b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ color: "#000" }}> REMARKS </b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {hemaOrderDetails.data.map((data, index) => (
                  <Fragment key={index}>
                    {data.hemoglobin && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            hemoglobin
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.hemoglobin}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.hemoglobin()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.hemoglobin_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.hematocrit && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            hematocrit
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.hematocrit}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.hematocrit()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.hematocrit_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.rbc && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            RBC
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.rbc}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.rbc()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.rbc_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.wbc && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            wbc
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.wbc}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.wbc()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.wbc_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.platelet_count && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            Platelet count
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.platelet_count}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.plateletCount()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.platelet_count_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.differential_count && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            differential count
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.differential_count}
                          </Typography>
                        </TableCell>

                        {/*  No refenrece values */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.differential_count_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.neutrophil && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            neutrophil
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.neutrophil}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.neutrophil()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.neutrophil_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.lymphocyte && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            lymphocyte
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.lymphocyte}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.lymphocyte()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.lymphocyte_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.monocyte && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            monocyte
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.monocyte}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.monocyte()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.monocyte_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.eosinophil && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            eosinophil
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.eosinophil}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.eosinophil()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.eosinophil_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.basophil && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            basophil
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.basophil}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.basophil()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.basophil_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.bands && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            bands
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.bands}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.bands()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.bands_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.abo_blood_type_and_rh_type && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            ABO blood type / rh type
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.abo_blood_type_and_rh_type}
                          </Typography>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.abo_blood_type_and_rh_type_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.bleeding_time && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            bleeding time
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.bleeding_time}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.bleedingTime()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.bleeding_time_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.clotting_time && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            clotting time
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.clotting_time}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {HemathologyRef.clottingTime()}
                        </TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.clotting_time_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.mcv && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            MCV
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.mcv}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.mcv_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.mch && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            mch
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.mch}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.mch_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.mchc && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            mchc
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.mchc}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.mchc_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.rdw && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            rdw
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.rdw}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.rdw_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.mpv && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            mpv
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.mpv}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.mpv_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.pdw && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            pdw
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.pdw}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.pdw_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.pct && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            pct
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.pct}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.pct_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.blood_typing_with_rh && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            Blood Typing w/ RH
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.blood_typing_with_rh}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.blood_typing_with_rh_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ct_bt && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            CT/BT
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.ct_bt}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.ct_bt_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.esr && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            ESR
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.esr}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.esr_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ferritin && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            Ferritin
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.ferritin}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.ferritin_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.aptt && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            APTT
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.aptt}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.aptt_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.peripheral_smear && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            Peripheral Smear
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.peripheral_smear}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.peripheral_smear_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.protime && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            Protime
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.protime}
                          </Typography>
                        </TableCell>
                        {/* references not set */}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.protime_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box my={1} align="center">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {hemaOrderDetails.data.length > 0 &&
                        hemaOrderDetails.data[0].pathologist}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Pathologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {hemaOrderDetails.data.length > 0 &&
                          hemaOrderDetails.data[0].pathologist_lcn}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xs={12} sm={4}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {hemaOrderDetails.data.length > 0 &&
                        hemaOrderDetails.data[0].chief_medtech}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Chief Med Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {hemaOrderDetails.data.length > 0 &&
                          hemaOrderDetails.data[0].chief_medtech_lci}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      <span
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                      >
                        {hemaOrderDetails.data.length > 0 &&
                          hemaOrderDetails.data[0].medtech}
                      </span>
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Medical Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {hemaOrderDetails.data.length > 0 &&
                            hemaOrderDetails.data[0].lic_no}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default HematologyPrintAll;
