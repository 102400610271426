import { Box, Paper, Tabs, Tab } from "@material-ui/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Container from "src/layout/Container";
import TabPanel from "src/utils/TabPanel";
import SalesLaboratory from "./SalesLaboratory";
import SalesImaging from "./SalesImaging";
import SalesPackages from "./SalesPackages";

const BranchSales = () => {
  const [category, setCategory] = useState("imaging");
  const { branch } = useParams();

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: `Branch Sales`,
        items: [{ name: "Dashboard", path: "/sph/app" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}> {branch.replace("-", " ")} Sales </Box>
          <Box>
            <Paper>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab value="imaging" wrapped label="Imaging" />
                <Tab value="laboratory" wrapped label="Laboratory" />
                <Tab value="packages" wrapped label="Packages" />
              </Tabs>
            </Paper>
          </Box>
        </Box>
      }
    >
      <TabPanel value={category} index="imaging">
        <SalesImaging />
      </TabPanel>

      <TabPanel value={category} index="laboratory">
        <SalesLaboratory />
      </TabPanel>

      <TabPanel value={category} index="packages">
        <SalesPackages />
      </TabPanel>
    </Container>
  );
};

export default BranchSales;
