import axios from "axios";
import { useEffect, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import SelectInputField from "src/components/forms/SelectInputField";
import TextInputField from "src/components/forms/TextInputField";
const ItemRow = ({ onAdd, medicalItems, updateItem, onRemove }) => {
	const [item, setItem] = useState(null);
	const updateQty = (qty) => {
		setItem((item) => ({ ...item, item_id: item?.id, qty: qty }));
	};
	const updateprice = (price) => {
		setItem((item) => ({ ...item, item_id: item?.id, price: price }));
	};
	useEffect(() => {
		updateItem(item);
	}, [item]);
	console.log("item", item);
	return (
		<tr>
			<td>
				<SelectInputField
					placeholder="Select Item"
					className="!mb-0"
					value={item?.id}
					onChange={(data) => {
						setItem({
							...medicalItems.find(
								(x) => x.id == data.target.value
							),
							item_id: data.target.value,
							qty: 0,
							price: 0,
						});
					}}
					options={medicalItems.map((x) => ({
						value: x.id,
						label: `${x.code} - ${x.name}`,
					}))}
				/>
			</td>
			<td>{item?.unit_measurement}</td>
			<td>{item?.qty_left}</td>
			<td>
				<TextInputField
					type="number"
					inputClassName="bg-white !pl-[4px] !pr-[4px] text-right"
					className="!min-w-[88px]"
					placeholder="Qty"
					max={item?.qty_left}
					value={item?.qty}
					onChange={(e) => {
						if (
							e.target.value == "" ||
							(e.target.value > 0 &&
								e.target.value <= item?.qty_left)
						) {
							updateQty(e.target.value);
						}
					}}
				/>
			</td>
			<td>
				<TextInputField
					type="text"
					inputClassName="bg-white !pl-[4px] !pr-[4px] text-right"
					className="!w-[150px]"
					placeholder="Unit Price"
					value={item?.price}
					onChange={(e) => {
						updateprice(e.target.value);
					}}
				/>
			</td>
			<td className="text-right">
				<b>{parseFloat(item?.qty * item?.price || 0).toFixed(2)}</b>
			</td>

			<td className="!py-0">
				<div className="-mt-1 flex items-center justify-center gap-1">
					<ActionBtn
						type="success"
						size="sm-square"
						className="!rounded-full p-2"
						onClick={onAdd}
					>
						<FlatIcon icon="rr-plus" />
					</ActionBtn>
					<ActionBtn
						type="danger"
						size="sm-square"
						className="!rounded-full p-2"
						onClick={onRemove}
					>
						<FlatIcon icon="rr-minus" />
					</ActionBtn>
				</div>
			</td>
		</tr>
	);
};
const LMISItemOrderList = ({ location, updateLocation }) => {
	const new_item = {
		item_code: "",
		item_name: "",
		qty: "",
		unit: "",
		price: "",
		amount: "",
	};
	const [medicalItems, setMedicalItems] = useState([]);
	const [items, setItems] = useState([
		{
			key: 1,
			...new_item,
		},
	]);
	const addAction = () => {
		setItems((items) => [
			...items,
			{
				key: items?.length + 1,
				...new_item,
			},
		]);
	};
	const removeAction = (item) => {
		if (items.length > 1)
			setItems((items) => items.filter((x) => x.id != item?.id));
	};

	const getInventories = () => {
		axios.get(`v1/management/items?location_id=2`).then((res) => {
			setMedicalItems(res.data.data);
		});
	};
	useEffect(() => {
		getInventories();
	}, []);
	useEffect(() => {
		let t = setTimeout(() => {
			console.log("itemsitems", items);
			updateLocation((prevLocations) =>
				prevLocations.map((x) =>
					x.key == location?.key
						? {
								...location,
								items: items,
						  }
						: x
				)
			);
		}, 500);
		return () => {
			clearTimeout(t);
		};
	}, [items]);
	const updateItem = (item, key) => {
		setItems((items) =>
			items.map((x) => (x.key == key ? { ...item, key: key } : x))
		);
	};

	return (
		<div className="table !mb-0">
			<table>
				<thead>
					<tr>
						<th>Item Code - Item name</th>
						<th style={{ width: 144 }}>Unit</th>
						<th style={{ width: 128 }}>Qty on hand</th>
						<th style={{ width: 128 }}>Qty</th>
						<th style={{ width: 188 }}>Unit Price</th>
						<th style={{ width: 128 }}>Amount</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{items?.map((item, index) => {
						return (
							<ItemRow
								medicalItems={medicalItems}
								item={item}
								updateItem={(data) => {
									updateItem(data, item?.key);
								}}
								key={`item-row-${index}`}
								onAdd={addAction}
								onRemove={() => {
									removeAction(item);
								}}
							/>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default LMISItemOrderList;
