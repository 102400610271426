import {
	Grid,
	Typography,
	Box,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Paper,
	Button,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FormInformation, UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import ReactToPrint from "react-to-print";
import { Print, Send } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";

const CompanyHMOSoa = () => {
	const { users } = useContext(UsersData);
	const { formInfo } = useContext(FormInformation);
	const mounted = IsMountedRef();
	const [details, setDetails] = useState([]);
	const { hmo_id } = useParams();
	const [total, setTotal] = useState(0);
	let printRef = useRef();

	const [patients, setPatients] = useState({ data: [], ready: false });
	const [dateFrom, setDateFrom] = useState(new Date());
	const [dateTo, setDateTo] = useState(new Date());

	const getCompaniesTransaction = async (dateFrom, dateTo) => {
		setDetails([]);
		setTotal(0);
		let response = await axios.get("billing/hmo/soa/get-hmo-transactions", {
			params: {
				management_id: users.management_id,
				hmo_id: hmo_id,
				date_from: dateFrom,
				date_to: dateTo,
				hmo_category: "hmo",
			},
		});

		if (mounted.current) {
			setDetails(response.data);
			setTotal(calculateTotal(response.data));
		}
	};

	const getCompaniesTransactionByPatients = async () => {
		setPatients({ data: [], ready: false });
		let response = await axios.get(
			"billing/soa/get-companies-transactions-patients",
			{
				params: {
					management_id: users.management_id,
					company: hmo_id,
					date_from: dateFrom,
					date_to: dateTo,
					hmo_category: "hmo",
				},
			}
		);

		if (mounted.current) {
			setPatients({ data: response.data, ready: true });
		}
	};

	const handleUpdateSetAsPaid = () => {
		var formdata = new FormData();
		formdata.append("management_id", users.management_id);
		formdata.append("company_id", hmo_id);

		axios
			.post("accounting/set-as-paid/soa-by-company", formdata)
			.then((response) => {
				const data = response.data;
				if (data === "success") {
					Notify.successRequest("as as paid");
				}
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const calculateTotal = (data) => {
		let x = 0;
		for (let i = 0; i < data.length; i++) {
			x +=
				parseFloat(data[i]._transaction_total) -
				parseFloat(data[i]._transaction_paid_total);
		}

		return x;
	};

	const generateSOAByDate = () => {
		let from = new Date(dateFrom).getTime();
		let to = new Date(dateTo).getTime();

		if (parseInt(to) < parseInt(from)) {
			Notify.customToast("Date Invalid", "Request cannot be process.");
			return false;
		} else {
			getCompaniesTransaction(dateFrom, dateTo);
			getCompaniesTransactionByPatients();
		}
	};

	return (
		<Container
			breadcrumbs={{
				enable: true,
				current: "company",
				items: [
					{ name: "dashboard", path: "/sph/app/billing" },
					{ name: "soa", path: "/sph/app/billing/soa" },
				],
			}}
			title={
				<Box display="flex">
					<Box flexGrow={1}>Direct SOA</Box>
					<Box display="flex">
						<Box>
							<DatePicker
								autoOk
								fullWidth
								margin="dense"
								label="Date from"
								value={dateFrom}
								inputVariant="outlined"
								onChange={(date) => setDateFrom(date)}
								format="MM/dd/yyyy"
							/>
						</Box>
						<Box mx={1}>
							<DatePicker
								autoOk
								margin="dense"
								fullWidth
								label="Date to"
								value={dateTo}
								inputVariant="outlined"
								onChange={(date) => setDateTo(date)}
								format="MM/dd/yyyy"
							/>
						</Box>
						<Box mt={0.5}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => generateSOAByDate()}
							>
								Go
							</Button>
						</Box>
					</Box>
				</Box>
			}
		>
			<Paper component={Box} p={2} ref={printRef}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Box align="center" mb={2}>
								<Typography variant="h5">
									{Boolean(formInfo)
										? formInfo.subtitle1 !== null
											? formInfo.subtitle1
											: ""
										: ""}
								</Typography>
								<Typography variant="subtitle1">
									{Boolean(formInfo)
										? formInfo.subtitle2 !== null
											? formInfo.subtitle2
											: ""
										: ""}
								</Typography>
								<Typography variant="subtitle1">
									{Boolean(formInfo)
										? formInfo.subtitle3 !== null
											? formInfo.subtitle3
											: ""
										: ""}
								</Typography>
							</Box>
							<Box align="center">
								<Box mb={1}>
									<Typography
										variant="h6"
										className="textUppercase"
									>
										<b> STATEMENT OF ACCOUNT </b>
									</Typography>

									<Typography>
										{`${Notify.createdAt(
											dateFrom.toLocaleDateString()
										)} - ${Notify.createdAt(
											dateTo.toLocaleDateString()
										)}`}
									</Typography>
								</Box>
							</Box>
							<Box display="flex">
								<Box flexGrow={1}>
									<Typography>
										<b> The Manager </b>
									</Typography>
									<Typography>
										<b>Charge To: </b>
										<b
											style={{
												textTransform: "uppercase",
												fontWeight: "bolder",
											}}
										>
											{details.length > 0 &&
												details[0].company_name}
										</b>
									</Typography>
								</Box>
								<Box>
									<Typography>
										Statement No. {`${Date.now()}`}
									</Typography>
									<Typography>
										Statement Date.
										{Notify.createdAt(
											new Date().toDateString()
										)}
									</Typography>
								</Box>
							</Box>

							<Box mt={3}>
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<b> Date </b>
												</TableCell>
												<TableCell>
													<b> Control Number </b>
												</TableCell>
												<TableCell>
													<b> Patient </b>
												</TableCell>
												<TableCell />
											</TableRow>
										</TableHead>

										<TableBody>
											{details.length > 0 ? (
												details.map((data, index) => (
													<React.Fragment key={index}>
														<TableRow>
															<TableCell
																style={{
																	width: 250,
																}}
															>
																{Notify.createdAt(
																	data.created_at
																)}
															</TableCell>
															<TableCell
																style={{
																	width: 250,
																}}
																align="center"
															>
																{index++ + 1}
															</TableCell>
															<TableCell>
																{`${
																	data.lastname
																}, ${
																	data.firstname
																} ${
																	data.middle ===
																	null
																		? ""
																		: data.middle
																}`}
															</TableCell>
															<TableCell align="right" />
														</TableRow>

														<TableRow>
															<TableCell
																colSpan={4}
															>
																<Box>
																	<Table>
																		<TableHead>
																			<TableRow>
																				<TableCell>
																					{" "}
																				</TableCell>
																				<TableCell>
																					<b>
																						{" "}
																						Test{" "}
																					</b>
																				</TableCell>
																				<TableCell>
																					<b>
																						{" "}
																						Amount{" "}
																					</b>
																				</TableCell>
																				<TableCell>
																					<b>
																						{" "}
																						Payment{" "}
																					</b>
																				</TableCell>
																				<TableCell>
																					<b>
																						{" "}
																						Amount
																						Due{" "}
																					</b>
																				</TableCell>
																			</TableRow>
																		</TableHead>
																		<TableBody>
																			{patients
																				.data
																				.length &&
																				patients.data
																					.filter(
																						(
																							xdata
																						) =>
																							xdata.patient_id ===
																							data.patient_id
																					)
																					.map(
																						(
																							xdata,
																							xindex
																						) => {
																							return (
																								<TableRow
																									key={
																										xindex
																									}
																									style={{
																										border: "2px solid #ffffff00",
																									}}
																								>
																									<TableCell
																										style={{
																											width: 250,
																										}}
																									/>
																									<TableCell>
																										{
																											xdata.bill_from
																										}{" "}
																										-
																										{
																											xdata.bill_name
																										}
																									</TableCell>

																									<TableCell align="right">
																										{Boolean(
																											parseInt(
																												xdata.is_charged_paid
																											)
																										)
																											? 0
																											: xdata.bill_amount}
																									</TableCell>
																									<TableCell align="right">
																										{Boolean(
																											parseInt(
																												xdata.is_charged_paid
																											)
																										)
																											? xdata.bill_amount
																											: 0}
																									</TableCell>
																									<TableCell align="right">
																										{Boolean(
																											parseInt(
																												xdata.is_charged_paid
																											)
																										)
																											? 0
																											: xdata.bill_amount}
																									</TableCell>
																								</TableRow>
																							);
																						}
																					)}
																		</TableBody>
																	</Table>
																</Box>
															</TableCell>
														</TableRow>

														<TableRow>
															<TableCell
																colSpan={4}
																style={{
																	borderTop:
																		"2px solid transparent",
																}}
																align="right"
															>
																<span
																	style={{
																		paddingRight: 10,
																	}}
																>
																	<b>
																		{Notify.createdAt(
																			data.created_at
																		)}
																	</b>
																</span>
																{"-"}
																<span
																	style={{
																		paddingRight: 20,
																		paddingLeft: 10,
																	}}
																>
																	<b>
																		<span>
																			{Notify.convertToNumber(
																				parseFloat(
																					data._transaction_total
																				) -
																					parseFloat(
																						data._transaction_paid_total
																					)
																			)}
																		</span>
																	</b>
																</span>
															</TableCell>
														</TableRow>
													</React.Fragment>
												))
											) : (
												<TableRow>
													<TableCell
														colSpan={4}
														align="center"
													>
														<Typography color="secondary">
															No record found
														</Typography>
													</TableCell>
												</TableRow>
											)}

											<TableRow>
												<TableCell> </TableCell>
												<TableCell> </TableCell>
												<TableCell align="right">
													<b> GRAND TOTAL </b>
												</TableCell>
												<TableCell align="right">
													<b>
														{" "}
														{Notify.convertToNumber(
															total
														)}{" "}
													</b>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
								<Box mt={3}>
									<Typography color="textSecondary">
										To ensure proper credit please enclose a
										copy of this statement with your check.
										Kindly make all checks payable to
										BUHANGIN MEDICAL CLINIC AND DIAGNOSTIC
										CENTER. <br /> Thank you very much!
									</Typography>
								</Box>
								<Box my={2} mt={5}>
									<Box display="flex">
										<Box flexGrow={1} width={"50%"}>
											<Typography>
												{" "}
												Prepared by:{" "}
											</Typography>
											<Box align="center">
												{Boolean(formInfo)
													? formInfo.prepared_by !==
													  null
														? formInfo.prepared_by
														: ""
													: ""}
											</Box>
											<Box align="center">
												<Box
													style={{
														borderTop:
															"1px solid #888",
													}}
													maxWidth={350}
												>
													Cashier
												</Box>
											</Box>
										</Box>

										<Box width={"50%"}>
											<Typography>
												{" "}
												Verified by:{" "}
											</Typography>
											<Box align="center">
												{Boolean(formInfo)
													? formInfo.verified_by !==
													  null
														? formInfo.verified_by
														: ""
													: ""}
											</Box>
											<Box align="center">
												<Box
													style={{
														borderTop:
															"1px solid #888",
													}}
													maxWidth={350}
												>
													Accounting in Charge
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
								<Box>
									<Box display="flex">
										<Box flexGrow={1} width={"50%"}>
											<Typography>
												{" "}
												Checked by:{" "}
											</Typography>
											<Box align="center">
												{Boolean(formInfo)
													? formInfo.checked_by !==
													  null
														? formInfo.checked_by
														: ""
													: ""}
											</Box>
											<Box align="center">
												<Box
													style={{
														borderTop:
															"1px solid #888",
													}}
													maxWidth={350}
												>
													Clinic Coordinator
												</Box>
											</Box>
										</Box>

										<Box width={"50%"}>
											<Typography> Noted by: </Typography>
											<Box align="center">
												{Boolean(formInfo)
													? formInfo.noted_by !== null
														? formInfo.noted_by
														: ""
													: ""}
											</Box>
											<Box align="center">
												<Box
													style={{
														borderTop:
															"1px solid #888",
													}}
													maxWidth={350}
												>
													Accounting Head
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>

								<Box display="flex" mt={3}>
									<Box mr={2}>
										<Button
											className={"d-print-none"}
											variant="contained"
											color="primary"
											hidden={details.length === 0}
											startIcon={<Send />}
											onClick={handleUpdateSetAsPaid}
										>
											Set as paid
										</Button>
									</Box>
									<ReactToPrint
										trigger={() => {
											// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
											// to the root node of the returned component as it will be overwritten.
											return (
												<Button
													className={"d-print-none"}
													variant="contained"
													color="primary"
													hidden={
														details.length === 0
													}
													startIcon={<Print />}
												>
													Print
												</Button>
											);
										}}
										content={() => printRef.current}
									/>
								</Box>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</Container>
	);
};

export default CompanyHMOSoa;
