import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutContainer from "src/components/LayoutContainer";
import CensusCard from "./components/CensusCard";
import axios from "axios";
import { useEffect, useState } from "react";

const PhoCensus = () => {
	const [data, setData] = useState(null);
	const [municipalitydata, setMunicipalityData] = useState([]);
	const [barangaydata, setBarangayData] = useState([]);
	const [purokData, setPurokData] = useState([]);
	useEffect(() => {
		let t = setTimeout(() => {
			getCensus();
			getByMunicipality();
			getByBarangay();
			getByPurok();
		}, 250);
		return () => {
			clearTimeout(t);
		};
	}, []);
	const getCensus = () => {
		axios.get(`/v1/census/census-summary`).then((res) => {
			setData(res.data.data);
		});
	};
	const getByMunicipality = () => {
		axios.get(`v1/census/municipality-population`).then((res) => {
			setMunicipalityData(res.data.data);
		});
	};
	const getByBarangay = () => {
		axios.get(`v1/census/barangay-population`).then((res) => {
			setBarangayData(res.data.data);
		});
	};
	const getByPurok = () => {
		axios.get(`v1/census/purok-population/`).then((res) => {
			setPurokData(res.data.data);
		});
	};
	return (
		<LayoutContainer
			icon={
				<FontAwesomeIcon icon={faChartArea} color="white" size={"2x"} />
			}
			title="Census"
			subtitle="Total population of all ages."
		>
			<div className="grid lg:grid-cols-3 grid-cols-1 gap-5 w-full mb-4">
				<CensusCard
					theme="gray"
					title="Total Population"
					value={data?.total_population}
					icon="rr-users"
				/>
				<CensusCard
					theme="blue"
					title="Total Male"
					icon="rs-mars-double"
					value={data?.total_male}
				/>
				<CensusCard
					theme="red"
					title="Total Female"
					value={data?.total_female}
					icon="rs-venus-double"
				/>

				<CensusCard
					theme="green"
					title="Child"
					icon="rs-child-head"
					value={data?.total_children}
				/>
				<CensusCard
					theme="yellow"
					title="Adults"
					icon="rs-people"
					value={data?.total_adults}
				/>
				<CensusCard
					theme="orange"
					title="Senior"
					icon="rr-person-walking-with-cane"
					value={data?.total_seniors}
				/>
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
				<div className="rounded-xl shadow-xs bg-white bg-opacity-10 overflow-hidden !border !border-blue-500">
					<h3 className="text-lg font-bold text-blue-600 p-3 m-0">
						Population by Municipality
					</h3>
					<table className="w-full !border-t !border-blue-500">
						<thead>
							<tr className="divide-x bg-blue-500">
								<th className="py-3 px-3 text-sm border-b text-white">
									Municipality
								</th>
								<th className="py-3 px-3 text-sm border-b text-white w-1/4">
									Population
								</th>
								<th className="py-3 px-3 text-sm border-b text-white w-1/6 text-center">
									%
								</th>
							</tr>
						</thead>
						<tbody className="divide-y border-b">
							{municipalitydata?.map((value) => {
								return (
									<tr className="divide-x !border-blue-200">
										<td className="px-3 py-2 text-gray-500 !border-blue-100 text-sm">
											{value?.municipality}
										</td>
										<td className="px-3 py-2 text-gray-500 !border-blue-100 text-sm">
											{value?.population}
										</td>
										<td className="px-3 py-2 text-gray-500 !border-blue-100 text-sm w-1/6 text-center">
											{parseFloat(
												(value?.population /
													data?.total_population) *
													100
											).toFixed(1)}
											%
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div className="rounded-xl shadow-xs bg-white bg-opacity-10 overflow-hidden !border !border-orange-500">
					<h3 className="text-lg font-bold text-orange-700 p-3 m-0">
						Population by Barangay
					</h3>
					<div style={{ maxHeight: "calc(50vh)", overflow: "auto" }}>
						<table className="w-full !border-t border-b !border-orange-500">
							<thead className="sticky top-0">
								<tr className="divide-x bg-orange-500">
									<th className="py-3 px-3 text-sm border-b text-white">
										Municipality
									</th>
									<th className="py-3 px-3 text-sm border-b text-white">
										Barangay
									</th>
									<th className="py-3 px-3 text-sm border-b text-white w-1/4">
										Population
									</th>
									<th className="py-3 px-3 text-sm border-b text-white w-1/6 text-center">
										%
									</th>
								</tr>
							</thead>
							<tbody className="divide-y">
								{barangaydata?.map((value) => {
									return (
										<tr className="divide-x !border-orange-200">
											<td className="px-3 py-2 text-gray-500 !border-orange-100 text-sm">
												{value?.municipality}
											</td>
											<td className="px-3 py-2 text-gray-500 !border-orange-100 text-sm">
												{value?.barangay}
											</td>
											<td className="px-3 py-2 text-gray-500 !border-orange-100 text-sm">
												{value?.population}
											</td>
											<td className="px-3 py-2 text-gray-500 !border-orange-100 text-sm w-1/6 text-center">
												{parseFloat(
													(value?.population /
														data?.total_population) *
														100
												).toFixed(1)}
												%
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
				{/* <div className="rounded-xl shadow-xs bg-white bg-opacity-10 overflow-hidden !border !border-green-500">
					<h3 className="text-lg font-bold text-green-600 p-3 m-0">
						Population by Purok
					</h3>
					<table className="w-full !border-t !border-green-500">
						<thead>
							<tr className="divide-x bg-green-500">
								<th className="py-3 px-3 text-sm border-b text-white">
									Purok
								</th>
								<th className="py-3 px-3 text-sm border-b text-white w-1/4">
									Population
								</th>
								<th className="py-3 px-3 text-sm border-b text-white w-1/6 text-center">
									%
								</th>
							</tr>
						</thead>
						<tbody className="divide-y border-b">
							<tr className="divide-x !border-green-200">
								<td className="px-3 py-2 text-gray-500 !border-green-100 text-sm">
									Glan
								</td>
								<td className="px-3 py-2 text-gray-500 !border-green-100 text-sm">
									532,231
								</td>
								<td className="px-3 py-2 text-gray-500 !border-green-100 text-sm w-1/6 text-center">
									15%
								</td>
							</tr>
							<tr className="divide-x !border-green-200">
								<td className="px-3 py-2 text-gray-500 !border-green-100 text-sm">
									Glan
								</td>
								<td className="px-3 py-2 text-gray-500 !border-green-100 text-sm">
									532,231
								</td>
								<td className="px-3 py-2 text-gray-500 !border-green-100 text-sm w-1/6 text-center">
									15%
								</td>
							</tr>
							<tr className="divide-x !border-green-200">
								<td className="px-3 py-2 text-gray-500 !border-green-100 text-sm">
									Glan
								</td>
								<td className="px-3 py-2 text-gray-500 !border-green-100 text-sm">
									532,231
								</td>
								<td className="px-3 py-2 text-gray-500 !border-green-100 text-sm w-1/6 text-center">
									15%
								</td>
							</tr>
							<tr className="divide-x !border-green-200">
								<td className="px-3 py-2 text-gray-500 !border-green-100 text-sm">
									Glan
								</td>
								<td className="px-3 py-2 text-gray-500 !border-green-100 text-sm">
									532,231
								</td>
								<td className="px-3 py-2 text-gray-500 !border-green-100 text-sm w-1/6 text-center">
									15%
								</td>
							</tr>
						</tbody>
					</table>
				</div> */}
			</div>
		</LayoutContainer>
	);
};

export default PhoCensus;
