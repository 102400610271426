import {
  Card,
  CardContent,
  CardHeader,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Collapse,
  Button,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import Label from "src/utils/Label";
import Notify from "src/notification/Notify";
import AddBed from "./AddBed";
import AddRoom from "./AddRoom";

const Details = ({ details }) => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [roomList, setRoomList] = useState([]);

  const [selectedRoom, setSelectedRoom] = useState(null);

  const [listOfBeds, setListOfBeds] = useState([]);

  const [dialog, setDialog] = useState({
    open: false,
    no_of_beds: 0,
  });

  const [addRoomDialog, setAddRoomDialog] = useState({
    open: false,
  });

  const getRoomList = React.useCallback(async () => {
    let { data } = await axios.get(`accounting/room/list/room-numberlist`, {
      params: {
        management_id: users.management_id,
        room_id: details?.room_id,
      },
    });

    if (mounted.current) {
      setRoomList(data);
    }
  }, [details, mounted, users]);

  const handleRoomDetails = async (room) => {
    if (selectedRoom?.id === room?.id) {
      console.log(`room is currently selected`);
      return;
    }

    let { data } = await axios.get(`accounting/room/list/room-bedslist`, {
      params: {
        management_id: users.management_id,
        room_id: details?.room_id,
        room_number: room?.room_number,
      },
    });

    setListOfBeds(data);
    setSelectedRoom(room);
  };

  useEffect(() => {
    getRoomList();
  }, [getRoomList]);

  return (
    <>
      <Card>
        <CardHeader title={"Room Details"} />
        <CardContent>
          <Box mb={1}>
            <Typography>
              <strong> Room name </strong> : {details?.room_name}
            </Typography>
          </Box>

          <Box mb={1}>
            <Typography>
              <strong> No of rooms </strong> : {details?.no_of_rooms}
            </Typography>
          </Box>

          <Box>
            <Typography>
              <strong> Type </strong> : {details?.type}
            </Typography>
          </Box>
        </CardContent>
      </Card>

      <Box mt={3}>
        <Card>
          <CardHeader
            title={
              <Box display="flex">
                <Box flexGrow={1}>Room Lists</Box>

                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setAddRoomDialog({ open: true })}
                    disabled={
                      parseInt(roomList.length) ===
                      parseInt(details?.no_of_rooms)
                    }
                  >
                    Add Room To List
                  </Button>
                </Box>
              </Box>
            }
          />
          <CardContent>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell> Room Number </TableCell>
                    <TableCell> No of beds </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {roomList.length > 0 ? (
                    roomList.map((_data, _key) => (
                      <React.Fragment key={_key}>
                        <TableRow>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              onClick={() => {
                                handleRoomDetails(_data);
                              }}
                            >
                              {selectedRoom?.id === _data?.id ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell> {_data.room_number} </TableCell>
                          <TableCell align="right">
                            {_data.no_of_beds}
                          </TableCell>
                        </TableRow>

                        {selectedRoom?.id === _data?.id && (
                          <TableRow>
                            <TableCell colSpan={3}>
                              <Collapse
                                in={selectedRoom?.id === _data?.id}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box margin={1}>
                                  <Box display={"flex"} mb={1}>
                                    <Box flexGrow={1}>
                                      <Typography gutterBottom component="div">
                                        <strong> List of beds </strong>
                                      </Typography>
                                    </Box>

                                    <Box>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                          setDialog({
                                            open: true,
                                            no_of_beds: listOfBeds.length,
                                          });
                                        }}
                                        disabled={
                                          listOfBeds.length >=
                                          parseInt(_data.no_of_beds)
                                        }
                                      >
                                        New Bed
                                      </Button>
                                    </Box>
                                  </Box>

                                  <Box>
                                    <Table size="small">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell> Available </TableCell>
                                          <TableCell> Bed Number </TableCell>
                                          <TableCell> Amount </TableCell>
                                        </TableRow>
                                      </TableHead>

                                      <TableBody>
                                        {listOfBeds.length > 0 ? (
                                          listOfBeds.map((beds, bedsKey) => (
                                            <TableRow key={bedsKey}>
                                              <TableCell>
                                                <Label
                                                  color={
                                                    Boolean(
                                                      beds?._availabilityCount
                                                    )
                                                      ? "error"
                                                      : "success"
                                                  }
                                                >
                                                  {Boolean(
                                                    beds?._availabilityCount
                                                  )
                                                    ? "No"
                                                    : "Yes"}
                                                </Label>
                                              </TableCell>

                                              <TableCell>
                                                {beds.bed_number}
                                              </TableCell>

                                              <TableCell align="right">
                                                {Notify.convertToNumber(
                                                  beds.amount
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          ))
                                        ) : (
                                          <TableRow>
                                            <TableCell colSpan={3}>
                                              <Typography color="secondary">
                                                No record found.
                                              </Typography>
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </CardContent>
        </Card>

        {/*  add room dialog */}
        <Dialog
          open={addRoomDialog.open}
          onClose={() => {
            setAddRoomDialog({ open: false, no_of_beds: 0 });
          }}
        >
          <DialogTitle> New Room </DialogTitle>
          <AddRoom
            onClose={() => {
              setAddRoomDialog({ open: false, no_of_beds: 0 });
            }}
            getRoomList={getRoomList}
            room_number={roomList.length + 1}
            room_id={details?.room_id}
          />
        </Dialog>

        {/*  add new bed dialog */}
        <Dialog
          open={dialog.open}
          onClose={() => {
            setDialog({ open: false, no_of_beds: 0 });
            setSelectedRoom(null);
          }}
        >
          <DialogTitle> New Bed </DialogTitle>
          <AddBed
            getRoomList={getRoomList}
            selectedRoom={selectedRoom}
            setSelectedRoom={setSelectedRoom}
            noOfBed={dialog.no_of_beds}
            onClose={() => {
              setDialog({ open: false, no_of_beds: 0 });
              setSelectedRoom(null);
            }}
          />
        </Dialog>
      </Box>
    </>
  );
};

export default Details;
