import { TextField } from "@material-ui/core";
import axios from "axios";
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useForm } from "react-hook-form";
import ActionBtn from "src/components/ActionBtn";
import Button from "src/components/Button";
import FlatIcon from "src/components/FlatIcon";
import Img from "src/components/Img";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";

const Patient = ({ patient, hide, receive_patient_profile_ref }) => {
	return (
		<div className="rounded-xl border bg-slate-100 p-3 flex items-start gap-3">
			<div className="w-11 h-11 min-w-[44px] min-h-11[44px] rounded-full bg-slate-300 relative overflow-hidden">
				<Img
					className={`absolute top-0 left-0 w-full h-full object-contain`}
					src={patient?.avatar}
					name={`${patient?.lastname}, ${patient?.firstname}, ${patient?.middle}`}
					type="user"
				/>
			</div>
			<div className="flex flex-col gap-y-1 mr-auto">
				<span className="text-lg font-bold ">{`${patient?.lastname}, ${patient?.firstname}, ${patient?.middle}`}</span>
				<div className="flex items-center gap-2">
					<FlatIcon icon="rr-map" className="text-placeholder" />

					<span className="text-sm text-placeholder mr-auto">{`${patient?.municipality?.name}, ${patient?.barangay?.name}, ${patient?.purok?.name}`}</span>
				</div>
			</div>
			<ActionBtn
				onClick={() => {
					receive_patient_profile_ref.current.show(patient);
					hide();
				}}
			>
				Select Patient
			</ActionBtn>
		</div>
	);
};

const ReceivePatientModal = (props, ref) => {
	const { receive_patient_profile_ref, getQueue } = props;
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const btnRef = useRef(null);
	const [patients, setPatients] = useState([]);
	const [page, setPage] = useState(1);
	const [keyword, setKeyword] = useState("");
	const [meta, setMeta] = useState(null);

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	useEffect(() => {
		console.log("pagepagepage", page);
		setLoading(true);
		let t = setTimeout(() => {
			getPatients();
		}, 800);
		return () => {
			setLoading(false);
			clearTimeout(t);
		};
	}, [page, keyword]);

	const show = () => {
		setPage(1);
		setPatients([]);
		setKeyword("");
		setTimeout(() => {
			getPatients();
		}, 100);
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = (form) => {};

	const getPatients = () => {
		axios
			.get(
				`/v1/clinic-receiving/patients?clinic_id=1&page=${page}&keyword=${keyword}`
			)
			.then((res) => {
				setPatients(res.data.data);
				setMeta(res.data.meta);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<Modal
			open={open}
			hide={hide}
			className={`w-[768px] min-w-[768px] max-w-[768px]`}
			staticModal={true}
		>
			<ModalHeader title={`Receive Patient Modal`} hide={hide} />
			<ModalBody className={`p- bg-white !pb-6`}>
				<div className="pl-4 pt-4  pb-4 flex flex-col">
					<div className="flex flex-col gap-y-4 w-full">
						<div className="pr-4 w-full flex flex-col">
							<TextField
								label={
									<>
										Search patient
										<b className="text-red-500 ml-1">*</b>
									</>
								}
								variant="outlined"
								{...register("type", {
									required: true,
								})}
								SelectProps={{ native: true }}
								InputLabelProps={{ shrink: true }}
								error={errors?.type}
								helperText={
									errors?.type && "This field is required"
								}
								onChange={(e) => {
									setKeyword(e.target.value);
									setPatients([]);
								}}
							/>
						</div>
						<div className="flex flex-col gap-y-3 h-[384px] max-h-[512px] overflow-auto pr-3">
							{patients?.map((patient) => {
								return (
									<Patient
										patient={patient}
										key={`patient-${patient?.id}`}
										hide={hide}
										receive_patient_profile_ref={
											receive_patient_profile_ref
										}
									/>
								);
							})}
							{!loading &&
								patients?.length == 0 &&
								keyword?.length > 0 && (
									<div className="p-4 text-base text-danger border bg-slate-100 rounded-xl flex items-center justify-center ">
										Patient not found or Patient is already
										in queue.
									</div>
								)}
							{loading ? (
								<div className="p-4 text-base text-placeholder border bg-slate-100 rounded-xl flex items-center justify-center animate-pulse">
									Loading...
								</div>
							) : (
								meta?.last_page > meta?.current_page && (
									<div className="w-full flex items-center justify-center">
										<ActionBtn
											onClick={() => {
												setPage(
													(currentPage) =>
														currentPage + 1
												);
											}}
										>
											Show more...
										</ActionBtn>
									</div>
								)
							)}
						</div>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default forwardRef(ReceivePatientModal);
