import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import PatientProfileOverview from "src/cis-doctor/components/PatientProfileOverview";
import CisPatientListItem from "src/cis/components/CisPatientListItem";
import ButtonV3 from "src/components/ButtonV3";
import FlatIcon from "src/components/FlatIcon";
import LayoutContainer from "src/components/LayoutContainer";
import useDataTable from "src/helpers/useDataTable";
import RhuPatientProfile from "../RhuPatientProfile";
import HealthUnitDetails from "../components/HealthUnitDetails";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Pagination from "src/components/table/Pagination";
import PatientBillingDetails from "./components/PatientBillingDetails";
import { Fade } from "react-reveal";
import TableV2 from "src/components/table/TableV2";
import Img from "src/components/Img";
import { formatDateMMDDYYYYHHIIA } from "src/helpers/utils";

const DischargedBillingPatients = () => {
	const history = useHistory();
	const { users } = useContext(UsersData);
	const {
		data,
		meta,
		setPage,
		loading,
		setPaginate,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
		page,
		keyword,
		setKeyword,
		paginate,
	} = useDataTable(`v1/clinic/done-tb-patients`, null, {});

	const [list, setList] = useState([]);
	const [selected, setSelected] = useState(null);
	const [selectedClick, setSelectedClick] = useState(null);
	const [pendings, setPendings] = useState(null);

	const getPendingForCashier = () => {
		axios.get(`/v1/clinic/pending-cashier`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	const getPendingForBilling = () => {
		axios.get(`/v1/clinic/pending-billing`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	useEffect(() => {
		if (users?.type == "SPH-BILLING") {
			getPendingForBilling();
		}
		if (users?.type == "SPH-CASHIER") {
			getPendingForCashier();
		}
	}, []);

	const getPharamaSignalRelease = () => {
		axios
			.get(`/v1/clinic/pharmacy-pending-signal-for-release`)
			.then((res) => {
				console.log("resssss", res.data.data);
				setPendings(res.data);
			});
	};
	useEffect(() => {
		let t = setInterval(() => {
			if (users?.type == "SPH-BILLING") {
				getPendingForBilling();
			}
			if (users?.type == "SPH-CASHIER") {
				getPendingForCashier();
			}
		}, 10000);
		return () => {
			clearInterval(t);
		};
	}, []);

	useEffect(() => {
		setList(data?.data);
	}, [data]);

	const updatePatient = (patient) => {
		console.log("updatePatientupdatePatient,", patient);
		setSelectedClick(patient);
		setList((prevList) =>
			prevList.map((p) =>
				patient?.id == p?.id
					? {
							...p,
							...patient,
					  }
					: p
			)
		);
	};
	const columns = useMemo(
		() => [
			{
				header: "",
				accessorKey: "patient",
				className: "w-11",
				headerClassName: "w-11",
				cell: function (data) {
					let p = data?.patient;
					return (
						<div
							className="flex items-center cursor-pointer"
							onClick={() => {
								patient_profile_ref.current.show(p);
							}}
						>
							<div className="w-11 h-11 rounded-full flex items-center justify-center">
								<Img
									type="user"
									name={`${p?.lastname}-${p?.firstname}-${p?.middle}`}
									className="w-full  aspect-square object-cover rounded-full"
									alt=""
								/>
							</div>
						</div>
					);
				},
			},
			{
				header: "Lastname",
				accessorKey: "patient.lastname",
				cell: function (data) {
					return data?.patient?.lastname;
				},
			},
			{
				header: "Firstname",
				accessorKey: "patient.firstname",
				cell: function (data) {
					return data?.patient?.lastname;
				},
			},
			{
				header: "Middle",
				accessorKey: "patient.middle",
				cell: function (data) {
					return data?.patient?.middlename;
				},
			},
			{
				header: "Status",
				accessorKey: "status",
				cell: function (data) {
					return data?.status;
				},
			},
			{
				header: "Date & Time",
				accessorKey: "patient.middle",
				cell: function (data) {
					return data?.date
						? `${data?.date} ${data?.time}`
						: formatDateMMDDYYYYHHIIA(new Date(data?.updated_at));
				},
			},
			// {
			// 	header: "Action",
			// 	accessorKey: "action",
			// 	className: "w-[244px]",
			// 	cell: (data) => {
			// 		return (
			// 			<div className="flex items-center gap-2">
			// 				<ActionBtn
			// 					size="sm"
			// 					type="foreground-light"
			// 					onClick={() => {
			// 						patient_profile_ref.current.show(data);
			// 					}}
			// 				>
			// 					<FlatIcon icon="rr-eye" className="text-lg mr-2" />
			// 					View
			// 				</ActionBtn>
			// 				<ActionBtn
			// 					size="sm"
			// 					type="primary-light"
			// 					onClick={() => {
			// 						add_patient_ref.current.show(data);
			// 					}}
			// 				>
			// 					<FlatIcon icon="rr-edit" className="text-lg mr-2" />
			// 					Edit
			// 				</ActionBtn>
			// 				{/* <ActionBtn size="sm" type="danger-light">
			// 					<FlatIcon icon="rr-trash" className="text-lg mr-2" />
			// 					Delete
			// 				</ActionBtn> */}
			// 			</div>
			// 		);
			// 	},
			// },
		],
		[]
	);

	return (
		<LayoutContainer
			icon={<FontAwesomeIcon icon={faHome} color="white" size={"2x"} />}
			title={
				<Typography variant={window.innerWidth > 500 ? "h4" : "h5"}>
					Discharged patients
				</Typography>
			}
			subtitle={`List of patients, patients for consultations and confirmations`}
			titleChildren={<HealthUnitDetails />}
			className={`relative`}
		>
			<div className="flex flex-col gap-2 h-[calc(100vh-204px)]">
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
					<div className="lg:col-span-12 flex flex-col gap-4 h-[calc(100vh-204px)] border-r">
						<div className="flex items-center gap-4">
							<h3 className="font-bold text-xl mb-0 mr-auto">
								Patient List
							</h3>
						</div>

						<div className="flex flex-col gap-3 pb-3 divide-y max-h-[calc(100vh-274)] overflow-auto">
							<TableV2
								paginationClassName="px-4"
								columns={columns}
								pagination={true}
								loading={loading}
								data={data?.data}
								onTableChange={(data) => {
									console.log("onTableChange", data);
								}}
							/>
						</div>
						<div className="">
							<Pagination
								page={page}
								setPage={setPage}
								pageCount={meta?.last_page}
								pageSize={paginate}
								setPageSize={setPaginate}
								paginationClassName="flex !flex-col !items-start"
							/>
						</div>
					</div>
				</div>
			</div>{" "}
		</LayoutContainer>
	);
};

export default DischargedBillingPatients;
