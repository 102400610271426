import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
const formatter = new Intl.NumberFormat("en-PH", {
	style: "decimal",
	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const IncomeForm = ({
	Controller,
	register,
	errors,
	setValue,
	control,
	household,
	getValues,
	watch,
}) => {
	const sources = [
		{ value: 1, label: `Crop Farming and Gardening` },
		{ value: 2, label: `Livestock and Poultry Raising` },
		{ value: 3, label: `Fishing` },
		{ value: 4, label: `Forestry and Hunting` },
		{ value: 5, label: `Wholesale and Retail` },
		{ value: 6, label: `Manufacturing` },
		{
			value: 7,
			label: `Community Social Recreational and Personal Services`,
		},
		{
			value: 8,
			label: `Transportation, Storage, and Communication Services`,
		},
		{ value: 9, label: `Mining and Quarrying` },
		{ value: 10, label: `Construction` },
		{
			value: 11,
			label: `Remittances from Overseas Filipino Workers (OFW)`,
		},
		{
			value: 12,
			label: `Cash receipts, gifts, support, relief and other forms of assistance from abroad`,
		},
		{
			value: 13,
			label: `Cash receipts, support, assistance and relief from domestic sources.`,
		},
		{ value: 14, label: `Rental` },

		{
			value: 15,
			label: `Interest from bank deposits, interest from loans extended to other families`,
		},
		{
			value: 16,
			label: `Pension and retirement, workmen's compensation and social security benefits`,
		},
		{ value: 17, label: `Dividends from investments` },
		{
			value: 18,
			label: `Other sources`,
		},
	];

	const computeTotal = () => {
		console.log('getValues("incomeValues")', getValues("incomeValues"));
	};
	return (
		<div className="flex flex-col gap-y-8">
			<FormControl>
				<FormGroup>
					<FormLabel
						id="demo-controlled-radio-buttons-group"
						className="flex"
					>
						N1. Select sources of income (Monthly)
						<span className="text-danger ml-2">*</span>
					</FormLabel>

					<div className="flex flex-col gap-y-3 py-3">
						{sources.map((data, index) => {
							return (
								<label className="flex items-center gap-3">
									<span className="scale-[1.15]">
										<input
											type="checkbox"
											value={index + 1}
											{...register("jobList")}
										/>
									</span>
									<span className="text-sm form-label">
										{data.label}
									</span>
								</label>
							);
						})}
					</div>
				</FormGroup>
			</FormControl>
			{watch("jobList")?.map((data, index) => {
				return (
					<TextField
						type="number"
						className="lg:w-1/2"
						SelectProps={{ native: true }}
						placeholder={sources[data - 1 || 0]?.label || ""}
						InputLabelProps={{ shrink: true }}
						label={
							<>
								{`${sources[data - 1 || 0]?.label}`}{" "}
								<span className="text-danger ml-2">*</span>
							</>
						}
						variant="outlined"
						{...register(`incomeValues.${index}`)}
						defaultValue={
							watch("jobIncome")?.length > 0
								? watch("jobIncome").split(",")[index]
								: 0
						}
					/>
				);
			})}
			<div className="flex items-center lg:w-1/2">
				<label className="mb-0">(73) TOTAL HOUSEHOLD INCOME:</label>
				<b className="ml-auto">
					₱
					{formatter.format(
						watch("incomeValues").reduce(
							(a, b) => parseFloat(a) + parseFloat(b),
							0
						) ||
							watch("jobIncome")
								.split(",")
								.reduce(
									(a, b) => parseFloat(a) + parseFloat(b),
									0
								) ||
							0
					)}
				</b>
			</div>
		</div>
	);
};

export default IncomeForm;
