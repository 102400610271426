import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import {
	faListUl,
	faTruckMoving,
	faUserInjured,
} from "@fortawesome/free-solid-svg-icons";
import SelectInputField from "src/components/forms/SelectInputField";
import LMISItemOrderList from "./LMISItemOrderList";
import { v4 as uuidv4 } from "uuid";
import TextAreaField from "src/components/forms/TextAreaField";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { TextField } from "@material-ui/core";
import SelectBHSLocation from "./SelectBHSLocation";
import HealthUnitDetails from "src/disease-treatment/components/HealthUnitDetails";
import { useDiseaseTreatmentContext } from "src/disease-treatment/context/DiseaseTreatmentContext";
import { UsersData } from "src/ContextAPI";
import { dateYYYYMMDD } from "src/helpers/utils";
const first_id = uuidv4();
let d = new Date();
let cof_number_ = `${d.getTime()}`;
const LMISConsignmentForm = () => {
	const history = useHistory();
	const { users } = useContext(UsersData);
	const { healthUnit } = useDiseaseTreatmentContext();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			cof_number: cof_number_,
		},
	});
	const [municipalityId, setMunicipalityId] = useState(null);
	const [barangayId, setBarangayId] = useState(null);
	const [municipalities, setMunicipalities] = useState([]);
	const [locationsData, setLocationsData] = useState([]);
	const [RHUData, setRHUData] = useState([]);
	const [BHSData, setBHSData] = useState([]);
	const [locations, setLocations] = useState([]);

	const addAction = (key) => {
		console.log("keyyyy", key);
		setLocations((locations) => [
			...locations,
			{
				key: uuidv4(),
				type: "SPH", //SPH, RHU, BHS
				items: [],
			},
		]);
	};
	const removeAction = (item) => {
		setLocations((prevLocations) =>
			prevLocations.filter((x) => x.key != item?.key)
		);
	};

	const getLocations = () => {
		axios.get("/v1/locations").then((res) => {
			setMunicipalities(res.data);
		});
	};

	const getRHUData = () => {
		axios.get(`/v1/health-unit/list?type=RHU`).then((res) => {
			setLocationsData(res.data.data);
		});
	};

	const onSubmit = (data) => {
		console.log("dataa", data, locations);
		// return;
		let formData = new FormData();
		formData.append("cof_number", data?.cof_number);
		formData.append("consignor", data?.consignor);
		formData.append("date", data?.date);
		formData.append("hci_name", data?.hci_name || "");
		formData.append("hci_number", data?.hci_number || "");
		formData.append("term", data?.term || "");
		formData.append("address", data?.address);
		locations.map((location, locationIndex) => {
			formData.append(
				`location[${locationIndex}][type]`,
				healthUnit.type
			);
			formData.append(
				`location[${locationIndex}][location_id]`,
				healthUnit?.id
			);
			location?.items?.map((item, itemIndex) => {
				formData.append(
					`location[${locationIndex}][items][${itemIndex}][item_id]`,
					item.item_id
				);
				formData.append(
					`location[${locationIndex}][items][${itemIndex}][qty]`,
					item.qty
				);
				formData.append(
					`location[${locationIndex}][items][${itemIndex}][price]`,
					item.price
				);
			});
		});
		axios
			.post(`v1/consignment/store`, formData)
			.then(() => {
				setTimeout(() => {
					toast.success("Consignment order sumitted successfully!");
				}, 300);
				history.goBack();
			})
			.catch(() => {
				toast.error("Error");
			});
	};

	const updateLocations = (location_data) => {
		console.log("location_data", location_data, locations);
		setLocations((prevLocations) =>
			prevLocations.map((x) =>
				x.key == location_data.key
					? {
							...location_data,
					  }
					: x
			)
		);
	};
	useEffect(() => {
		getLocations();
		getRHUData();
		setTimeout(() => {
			addAction(uuidv4());
		}, 500);
	}, []);
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faTruckMoving}
						color="white"
						size={"2x"}
					/>
				}
				title="Consignment Order Form"
				// subtitle="View Kobotoolbox respondents"
				titleChildren={<HealthUnitDetails />}
			>
				<CardContainer
					containerClassName={`border border-opacity-10`}
					header={{
						title: "Consignment Order Form",
					}}
				>
					<div className="grid grid-cols-1 lg:grid-cols-12 gap-y-2 gap-x-4 mb-6">
						<div className="lg:col-span-6">
							<TextInputField
								inputClassName=" bg-slate-200"
								label="Date"
								value={dateYYYYMMDD()}
								placeholder="Date"
								type="date"
								{...register("date")}
							/>
						</div>
						<div className="lg:col-span-6">
							<TextInputField
								inputClassName=" bg-slate-200"
								label="COF Number"
								placeholder="COF Number"
								readOnly
								{...register("cof_number")}
							/>
						</div>
						<div className="lg:col-span-6">
							<TextInputField
								inputClassName=" bg-slate-200"
								label="Consignor"
								placeholder="Consignor"
								{...register("consignor")}
							/>
						</div>
						{users?.type?.includes("BHS") ? (
							""
						) : (
							<div className="lg:col-span-6">
								<TextInputField
									inputClassName=" bg-slate-200"
									label="Term"
									placeholder="Term"
									{...register("term")}
								/>
							</div>
						)}
						<div className="lg:col-span-6">
							<TextAreaField
								rows={5}
								inputClassName=" bg-slate-200"
								label="Address"
								placeholder="Address"
								{...register("address")}
							/>
						</div>
						{users?.type?.includes("BHS") ? (
							""
						) : (
							<div className="lg:col-span-6 gap-3 flex flex-col">
								<TextInputField
									inputClassName=" bg-slate-200"
									label="HCI Name"
									placeholder="HCI Name"
									{...register("hci_name")}
								/>
								<TextInputField
									inputClassName=" bg-slate-200"
									label="HCI Number"
									placeholder="HCI Number"
									{...register("hci_number")}
								/>
							</div>
						)}
					</div>
					<div className="flex flex-col gap-y-4">
						{locations?.map((location, index) => {
							return (
								<div
									className="border rounded-lg overflow-hidden"
									key={`location-row-${location.key}`}
								>
									<div className="p-3 flex w-full items-center gap-4">
										<div className="w-[244px] text-lg font-bold">
											{healthUnit?.name}
										</div>
										{/* <ActionBtn
												size="md"
												type="danger"
												className="ml-auto"
												onClick={() => {
													removeAction(location);
												}}
											>
												<FlatIcon icon="rr-cross" />
												Remove
											</ActionBtn> */}
									</div>
									<LMISItemOrderList
										location={location}
										updateLocation={setLocations}
									/>
								</div>
							);
						})}
						{/* <ActionBtn
							size="md"
							type="primary"
							className="w-full lg:w-1/4 gap-2 mx-auto font-bold"
							onClick={() => {
								
							}}
						>
							<FlatIcon icon="rr-plus" />
							Add location
						</ActionBtn> */}
					</div>

					<div className="pt-3 mt-3 border-t flex justify-end items-center">
						<ActionBtn
							size="md"
							type="success"
							className="w-full lg:w-1/4 gap-2 font-bold"
							onClick={handleSubmit(onSubmit)}
						>
							<FlatIcon icon="rr-disk" />
							Submit
						</ActionBtn>
					</div>
				</CardContainer>
			</LayoutContainer>
		</>
	);
};

export default LMISConsignmentForm;
