import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import PhoPatientForm from "./PhoPatientForm";
import { useForm } from "react-hook-form";
import usePatientHook from "src/admission/hooks/usePatientHook";
import { TextField } from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import { Filter } from "react-feather";
import axios from "axios";

const MapFilterModal = (props, ref) => {
	const { setFilters } = props;
	const [open, setOpen] = useState(false);
	const { handleSubmit, register, reset } = useForm();
	const [municipalities, setMunicipalities] = useState([]);
	const [patientLocations, setPatientLocations] = useState([]);
	const [brgys, setBrgys] = useState([]);
	const [diseases, setDiseases] = useState([]);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const getLocations = () => {
		axios.get("/v1/locations").then((res) => {
			setMunicipalities(res.data.data);
		});
	};

	const getPatientsLocations = () => {
		axios.get("/v1/pho/patient-maps").then((res) => {
			setPatientLocations(res.data.data);
		});
	};

	const getDiseases = () => {
		axios.get("/v1/diseases/").then((res) => {
			setDiseases(res.data);
		});
	};
	const show = (data) => {
		getLocations();
		getDiseases();
		getPatientsLocations();
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};
	const submit = (data) => {
		console.log("datadata", data);
		if (data?.date?.length) {
			let d = new Date(data?.date);
			data.date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
		}
		setFilters(data);
		reset();
		setTimeout(() => {
			hide();
		}, 500);
	};
	return (
		<Modal open={open} hide={hide} size="md2" className="max-w-xl">
			<ModalHeader
				title={"Filter Map"}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p- bg-white`}>
				<div className="w-full p-4 flex flex-col gap-y-5">
					<TextField
						className="w-full"
						label={<>Filter Date:</>}
						variant="outlined"
						type="date"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						{...register("date", {
							required: false,
						})}
					/>
					<TextField
						className="w-full"
						label={`Filter Disease`}
						variant="outlined"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						select
						{...register("disease", {
							required: false,
						})}
					>
						<option value=""> SELECT </option>
						{diseases.map((item) => {
							return (
								<option value={item?.id}>{item?.name} </option>
							);
						})}
					</TextField>
					<TextField
						className="w-full"
						label={`Filter Municipality`}
						variant="outlined"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						select
						InputProps={{
							onChange: (e) => {
								console.log("datadata 222", e.target.value);
								if (e.target.value) {
									setBrgys(
										municipalities.find(
											(x) => x.id == e.target.value
										).barangays
									);
								} else {
									setBrgys([]);
								}
							},
						}}
						{...register("municipality", {
							required: false,
						})}
					>
						<option value=""> SELECT </option>
						{municipalities?.map((item) => {
							return (
								<option value={item?.id}>{item?.name} </option>
							);
						})}
					</TextField>
					<TextField
						className="w-full"
						label={`Filter Barangay`}
						variant="outlined"
						SelectProps={{ native: true }}
						InputLabelProps={{ shrink: true }}
						select
						{...register("barangay", {
							required: false,
						})}
					>
						<option value=""> SELECT </option>
						{brgys.map((item) => {
							return (
								<option value={item?.id}>{item?.name} </option>
							);
						})}
					</TextField>
				</div>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button
					className="!    px-2"
					type="success"
					onClick={handleSubmit(submit)}
				>
					<Filter className="w-5 mr-2" />
					Filter map
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(MapFilterModal);
