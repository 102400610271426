import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import React, { useContext, useCallback, useEffect, useState } from "react";
import FormHeader from "./FormHeader";
import { CheckSquare, Edit, Printer, XCircle } from "react-feather";
import { useReactToPrint } from "react-to-print";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";

const FormSeven = ({ patient_id, details, allowEdit, info, doctorsList }) => {
  const printableRef = React.useRef();
  const [enableEdit, setEnableEdit] = React.useState(false);
  const { users } = useContext(UsersData);
  const [isProcess, setIsProcess] = useState(false);
  // const [chartAttendingSurgeon, setChartAttendingSurgeon] = useState("");

  const [existingLabChart, setExistingLabChart] = useState({
    data: [],
    ready: false,
  });

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const getLaboratoryChart = useCallback(async () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/laboratory", { params })
      .then((response) => {
        const data = response.data;
        setExistingLabChart({ data, ready: true });
      })
      .catch((error) => console.log(error));
  }, [users, patient_id, details.trace_number]);

  const handleSubmitLaboratoryChart = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", details.trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    // formdata.set("attending_physician", chartAttendingSurgeon);

    var error = [];

    if (
      formdata.get("patient_name").length === 0 ||
      formdata.get("patient_name").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Patient name");
    }
    if (formdata.get("age").length === 0 || formdata.get("age").trim() === "") {
      error = "error";
      Notify.fieldRequired("Age");
    }
    if (
      formdata.get("gender").length === 0 ||
      formdata.get("gender").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Gender");
    }
    if (
      formdata.get("attending_physician").length === 0 ||
      formdata.get("attending_physician").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Attending Physician");
    }
    if (error.length > 0) {
      console.warn("Form has an error.");
    } else {
      setIsProcess(true);
      axios
        .post("or/update/chart/laboratory", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            setExistingLabChart({ data: [], ready: false });
            e.target.reset();
            setEnableEdit(!enableEdit);
            Notify.successRequest("laboratory update");
            getLaboratoryChart();
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsProcess(false);
        });
    }
  };

  // const checkSelectedDoctor = (selDoctor) => {
  //   if (doctorsList.length > 0) {
  //     let xx = doctorsList.filter((x) => x.doctors_id === selDoctor);
  //     if (xx.length > 0) {
  //       return xx[0].name;
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  useEffect(() => {
    getLaboratoryChart();
  }, [patient_id, getLaboratoryChart]);

  return (
    <Box>
      <Card ref={printableRef}>
        <Box m={2}>
          <Box>
            <FormHeader />
          </Box>
          <Box>
            <form onSubmit={handleSubmitLaboratoryChart}>
              <Box my={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          disabled={isProcess}
                          color="primary"
                          startIcon={<CheckSquare />}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            SET
                          </Button>
                        </Box>
                      )}

                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box align="center" mb={2}>
                <Typography>
                  <b> LABORATORY </b>
                </Typography>
              </Box>

              {existingLabChart.ready && (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Box>
                          <Typography variant="subtitle2">
                            Patient's Name:
                          </Typography>
                          <TextField
                            fullWidth
                            name="patient_name"
                            defaultValue={
                              info
                                ? `${info.lastname}, ${info.firstname} ${
                                    info.middle === null ? "" : info.middle
                                  }`
                                : ""
                            }
                            InputProps={{
                              disableUnderline: !enableEdit,
                              readOnly: true,
                            }}
                          />

                          <TextField
                            fullWidth
                            name="ccl_id"
                            defaultValue={
                              existingLabChart.data.length > 0
                                ? existingLabChart.data[0].ccl_id
                                : ""
                            }
                            InputProps={{
                              disableUnderline: !enableEdit,
                              readOnly: true,
                            }}
                            hidden
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography variant="subtitle2">Age:</Typography>
                          <TextField
                            fullWidth
                            name="age"
                            defaultValue={
                              info
                                ? info.birthday === null
                                  ? ""
                                  : Notify.calculateAge(info.birthday)
                                : ""
                            }
                            InputProps={{
                              disableUnderline: !enableEdit,
                              readOnly: true,
                            }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography variant="subtitle2">Gender:</Typography>
                          <TextField
                            fullWidth
                            name="gender"
                            defaultValue={info ? info.gender : ""}
                            InputProps={{
                              disableUnderline: !enableEdit,
                              readOnly: true,
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Box>
                          <Typography variant="subtitle2">
                            Attending Physician :
                          </Typography>
                          <TextField
                            fullWidth
                            name="attending_physician"
                            defaultValue={
                              existingLabChart.data.length > 0
                                ? existingLabChart.data[0].attending_physician
                                : ""
                            }
                            InputProps={{
                              disableUnderline: !enableEdit,
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </form>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default FormSeven;
