import {
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const DocuPatientDetailsOthersSars = ({
  selectedBranch,
  patient_id,
  getSarsCovTest,
  details,
  updatePatientList,
}) => {
  const { users } = React.useContext(UsersData);
  const [savebtnSarsDisabled, setSavebtnSarsDisabled] = useState(false);

  const handleSaveSarsCovResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    var error = [];
    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setSavebtnSarsDisabled(true);
      axios
        .post("van/order/ordernew-sarscov/save-process-result", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getSarsCovTest();
            updatePatientList();
            Notify.successRequest("order result added.");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setSavebtnSarsDisabled(false);
        });
    }
  };

  React.useEffect(() => {}, [patient_id]);

  return (
    <Fragment>
      <form onSubmit={handleSaveSarsCovResult}>
        <Card elevation={0}>
          {details && (
            <Fragment>
              <CardContent>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <strong>Test</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>Result</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow hidden={!Boolean(parseInt(details.sars_cov))}>
                        <TableCell>
                          SARS-COV - 2 ANTIGEN
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="order_id[]"
                              label="order_id"
                              value={details.order_id}
                              hidden
                            />
                            <TextField
                              margin="dense"
                              fullWidth
                              name="patient_id"
                              label="patient_id"
                              value={details.patient_id}
                              hidden
                            />

                            <TextField
                              margin="dense"
                              fullWidth
                              name="doctors_id"
                              label="doctor_id"
                              value={
                                details.doctor_id === null
                                  ? ""
                                  : details.doctor_id
                              }
                              hidden
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Result"
                            name="sars_cov_result[]"
                            fullWidth
                            required
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
              <Box display="flex" m={1}>
                <Box flexGrow={1} />
                <Box>
                  <CardActions>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                      disabled={savebtnSarsDisabled}
                    >
                      Save Result
                    </Button>
                  </CardActions>
                </Box>
              </Box>
            </Fragment>
          )}
        </Card>
      </form>
    </Fragment>
  );
};

export default DocuPatientDetailsOthersSars;
