import {
  Box,
  Collapse,
  Paper,
  Typography,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "src/notification/Notify";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue } from "@material-ui/core/colors";
import IsMountedRef from "src/utils/IsMountedRef";
import DocuPatientDetailsOthersSars from "./DocuPatientDetailsOthersSars";
// import DocuPatientDetailsLabJabeliFecal from "./DocuPatientDetailsLabJabeliFecal";
// import DocuPatientDetailsLabJabeliUrine from "./DocuPatientDetailsLabJabeliUrine";

const DocuPatientDetailsOthers = ({
  selectedBranch,
  patient_id,
  updatePatientList,
  medTechList,
}) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [sarscovTest, setSarsCovTest] = useState({ data: [], ready: false });
  //   const [urineTest, setUrineTest] = useState({ data: [], ready: false });
  const mounted = IsMountedRef();

  const getSarsCovTest = React.useCallback(async () => {
    let response = await axios.get("van/order/sarscov-test", {
      params: {
        patient_id,
        management_id: selectedBranch,
      },
    });
    if (mounted.current) {
      setSarsCovTest({ data: response.data, ready: true });
    }
  }, [patient_id, selectedBranch, mounted]);

  //   const getUrinalysis = React.useCallback(async () => {
  //     let response = await axios.get("van/order/urine-test", {
  //       params: {
  //         patient_id,
  //         management_id: selectedBranch,
  //       },
  //     });

  //     if (mounted.current) {
  //       setUrineTest({ data: response.data, ready: true });
  //     }
  //   }, [patient_id, selectedBranch, mounted]);

  useEffect(() => {
    getSarsCovTest();
    // getUrinalysis();
    // eslint-disable-next-line
  }, [patient_id]);

  return (
    <>
      <Box my={1}>
        <Typography color="textSecondary" variant="h6">
          Other Order
        </Typography>
      </Box>
      {sarscovTest.ready
        ? sarscovTest.data.length > 0
          ? sarscovTest.data.map((data, index) => {
              return (
                <Fragment key={index}>
                  <Paper component={Box} my={2} p={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Box display="flex">
                          <Box mt={1} mr={2}>
                            <ListAltIcon
                              style={{ fontSize: "2rem", color: blue[500] }}
                            />
                          </Box>

                          <Box>
                            <Box>
                              <Typography
                                variant="subtitle1"
                                className={`gtc-uppercase`}
                              >
                                SARS COV - 2 ANTIGEN
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                className={`gtc-uppercase`}
                              >
                                {Notify.dateTimeConvert(data.created_at)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={() =>
                            setSelectedOrder(
                              selectedOrder &&
                                selectedOrder.trace_number === data.trace_number
                                ? null
                                : data
                            )
                          }
                        >
                          {selectedOrder &&
                          selectedOrder.order_id === data.order_id ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                    <>
                      {selectedOrder && (
                        <Collapse in={selectedOrder.order_id === data.order_id}>
                          <Box mt={2}>
                            <DocuPatientDetailsOthersSars
                              selectedBranch={selectedBranch}
                              patient_id={patient_id}
                              details={selectedOrder}
                              getSarsCovTest={() => {
                                getSarsCovTest();
                                setSelectedOrder(null);
                              }}
                              updatePatientList={() => updatePatientList()}
                            />
                          </Box>
                        </Collapse>
                      )}
                    </>
                  </Paper>
                </Fragment>
              );
            })
          : null
        : null}

      {/* {urineTest.ready
        ? urineTest.data.length > 0
          ? urineTest.data.map((data2, index2) => {
              return (
                <Fragment key={index2}>
                  <Paper component={Box} my={2} p={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Box display="flex">
                          <Box mt={1} mr={2}>
                            <ListAltIcon
                              style={{ fontSize: "2rem", color: blue[500] }}
                            />
                          </Box>

                          <Box>
                            <Box>
                              <Typography
                                variant="subtitle1"
                                className={`gtc-uppercase`}
                              >
                                URINALYSIS
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                className={`gtc-uppercase`}
                              >
                                {Notify.dateTimeConvert(data2.created_at)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={() =>
                            setSelectedOrder(
                              selectedOrder &&
                                selectedOrder.trace_number ===
                                  data2.trace_number
                                ? null
                                : data2
                            )
                          }
                        >
                          {selectedOrder &&
                          selectedOrder.order_id === data2.order_id ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                    {console.log("selectedOrder", selectedOrder)}
                    <>
                      {selectedOrder && (
                        <Collapse
                          in={selectedOrder.order_id === data2.order_id}
                        >
                          <Box mt={2}>
                            <DocuPatientDetailsLabJabeliUrine
                              selectedBranch={selectedBranch}
                              patient_id={patient_id}
                              details={selectedOrder}
                              getUrinalysis={() => {
                                getUrinalysis();
                                setSelectedOrder(null);
                              }}
                              updatePatientList={() => updatePatientList()}
                            />
                          </Box>
                        </Collapse>
                      )}
                    </>
                  </Paper>
                </Fragment>
              );
            })
          : null
        : null} */}

      {sarscovTest.data.length === 0 &&
        // urineTest.data.length === 0 &&
        Notify.noRecord()}
    </>
  );
};

export default DocuPatientDetailsOthers;
