import {
  Box,
  Grid,
  TextField,
  CircularProgress,
  FormHelperText,
  Button,
  IconButton,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import Axios from "axios";
import React, { useState, useContext } from "react";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import { Edit as EditIcon } from "@material-ui/icons";
import { UsersData } from "src/ContextAPI";
import { isMobile } from "react-device-detect";
import { CheckSquare, XCircle } from "react-feather";

const PatientProfileDetails = ({
  patient_id,
  getNurseOnQueue,
  getPatientInformation,
  info,
}) => {
  const { users } = useContext(UsersData);
  const [edit, setEdit] = useState(false);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box flexGrow={1}>Health Records</Box>
              <Box hidden={edit}>
                {isMobile ? (
                  <IconButton color="primary" onClick={() => setEdit(true)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => setEdit(true)}
                    style={{ borderRadius: 50 }}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            </Box>
          }
        />

        <CardContent>
          <Box className={edit ? "" : `gtc-textfield-noborder`}>
            {info.ready ? (
              info.data ? (
                <Formik
                  enableReinitialize
                  initialValues={{
                    username: users.username,
                    user_id: users.user_id,
                    patient_id: patient_id,
                    height: info.data.height === null ? "" : info.data.height,
                    weight: info.data.weight === null ? "" : info.data.weight,
                    pulse: info.data.pulse === null ? "" : info.data.pulse,
                    lmp: info.data.lmp === null ? "" : info.data.lmp,
                    temp:
                      info.data.temperature === null
                        ? ""
                        : info.data.temperature,
                    bp_systolic:
                      info.data.blood_systolic === null
                        ? ""
                        : info.data.blood_systolic,
                    bp_diastolic:
                      info.data.blood_diastolic === null
                        ? ""
                        : info.data.blood_diastolic,
                    blood_type:
                      info.data.blood_type === null ? "" : info.data.blood_type,
                  }}
                  validationSchema={Yup.object().shape({})}
                  onSubmit={async (
                    values,
                    { setErrors, setSubmitting, resetForm }
                  ) => {
                    try {
                      const request = await Axios.post(
                        "nurse/patients/edit-patient",
                        getFormData(values)
                      );
                      if (request.data === "success") {
                        // getNurseOnQueue();
                        getPatientInformation();
                        Notify.successRequest("update patient");
                        resetForm();
                        setSubmitting(true);
                        setEdit(false);
                      }
                    } catch (error) {
                      const message = error.message || "Something went wrong";
                      setErrors({ submit: message });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <Box>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                error={Boolean(touched.height && errors.height)}
                                helperText={touched.height && errors.height}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.height}
                                fullWidth
                                required
                                name="height"
                                label="Height(ft)"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 1,
                                    max: 10000,
                                    step: 0.01,
                                  },
                                  readOnly: !edit,
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                error={Boolean(touched.weight && errors.weight)}
                                helperText={touched.weight && errors.weight}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.weight}
                                fullWidth
                                required
                                name="weight"
                                label="Weight(kg)"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 1,
                                    max: 10000,
                                    step: 0.01,
                                  },
                                  readOnly: !edit,
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={info.data.gender === "Male" ? 6 : 3}
                          >
                            <Box>
                              <TextField
                                error={Boolean(touched.pulse && errors.pulse)}
                                helperText={touched.pulse && errors.pulse}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.pulse}
                                fullWidth
                                required
                                name="pulse"
                                label="Pulse"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 1,
                                    max: 10000,
                                    step: 0.01,
                                  },
                                  readOnly: !edit,
                                }}
                              />
                            </Box>
                          </Grid>

                          {info.data.gender !== "Male" && (
                            <Grid item xs={12} sm={3}>
                              <Box>
                                <TextField
                                  error={Boolean(touched.lmp && errors.lmp)}
                                  helperText={touched.lmp && errors.lmp}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.lmp}
                                  fullWidth
                                  InputProps={{
                                    readOnly: !edit,
                                  }}
                                  required
                                  name="lmp"
                                  label="LMP"
                                  variant="outlined"
                                  type="date"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Box>
                            </Grid>
                          )}

                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                error={Boolean(touched.temp && errors.temp)}
                                helperText={touched.temp && errors.temp}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.temp}
                                fullWidth
                                required
                                name="temp"
                                label="Temperature"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 1,
                                    max: 10000,
                                    step: 0.01,
                                  },
                                  readOnly: !edit,
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                error={Boolean(
                                  touched.bp_systolic && errors.bp_systolic
                                )}
                                helperText={
                                  touched.bp_systolic && errors.bp_systolic
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.bp_systolic}
                                fullWidth
                                required
                                name="bp_systolic"
                                label="BP Systolic"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 1,
                                    max: 10000,
                                    step: 0.01,
                                  },
                                  readOnly: !edit,
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                error={Boolean(
                                  touched.bp_diastolic && errors.bp_diastolic
                                )}
                                helperText={
                                  touched.bp_diastolic && errors.bp_diastolic
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.bp_diastolic}
                                fullWidth
                                required
                                name="bp_diastolic"
                                label="BP Diastolic"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 1,
                                    max: 10000,
                                    step: 0.01,
                                  },
                                  readOnly: !edit,
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                error={Boolean(
                                  touched.blood_type && errors.blood_type
                                )}
                                helperText={
                                  touched.blood_type && errors.blood_type
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.blood_type}
                                fullWidth
                                required
                                name="blood_type"
                                label="Blood Type"
                                variant="outlined"
                                select
                                SelectProps={{ native: true, disabled: !edit }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              >
                                <option value=""> Select </option>
                                <option value="A"> A </option>
                                <option value="B"> B </option>
                                <option value="AB"> AB </option>
                                <option value="O"> O </option>
                                <option value="A-positive"> A-Positive </option>
                                <option value="A-negative"> A-Negative </option>
                                <option value="B-positive"> B-Positive </option>
                                <option value="B-negative"> B-Negative </option>
                                <option value="O-positive"> O-Positive </option>
                                <option value="O-negative"> O-Negative </option>
                                <option value="AB-positive">
                                  AB-Positive{" "}
                                </option>
                                <option value="AB-negative">
                                  AB-Negative{" "}
                                </option>
                              </TextField>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box hidden={!edit}>
                        {errors.submit && (
                          <Box mt={2}>
                            <FormHelperText error>
                              {errors.submit}
                            </FormHelperText>
                          </Box>
                        )}

                        <Box mb={1} mt={2} display="flex">
                          <Box flexGrow={1} />

                          <Button
                            variant="contained"
                            color="default"
                            onClick={() => setEdit(false)}
                            startIcon={<XCircle />}
                          >
                            Cancel
                          </Button>
                          <Box ml={2}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              startIcon={
                                isSubmitting ? (
                                  <CircularProgress size={20} color="inherit" />
                                ) : (
                                  <CheckSquare />
                                )
                              }
                              disabled={isSubmitting}
                            >
                              Save
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </form>
                  )}
                </Formik>
              ) : (
                Notify.noRecord()
              )
            ) : (
              Notify.loading()
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PatientProfileDetails;
