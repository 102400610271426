import {
  faCalendarDay,
  faClock,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
//import timeGridPlugin from "@fullcalendar/timegrid";

// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
import { Typography } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import LayoutContainer from "src/components/LayoutContainer";
import { UsersData } from "src/ContextAPI";
import CisPatientAppointmentListItem from "./components/CisPatientAppointmentListItem";
import ActionBtn from "src/components/ActionBtn";

const events = [
  {
    title: "Mona Lisa",
    start: getDate("YEAR-MONTH-14"),
    onClick: () => {
      alert(1);
    },
  },
  { title: "John Doe", start: getDate("YEAR-MONTH-14") },
  { title: "Carla Polares", start: getDate("YEAR-MONTH-14") },
  { title: "Maria Clara", start: getDate("YEAR-MONTH-14") },
];

function getDate(dayString) {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }

  return dayString.replace("YEAR", year).replace("MONTH", month);
}

const CisDoctorPastAppointments = () => {
  const userContext = useContext(UsersData);
  const { users } = userContext;
  const name = users?.name;
  const [view, setView] = useState("dayGridMonth");
  const receive_patient_ref = useRef(null);

  return (
    <LayoutContainer
      icon={<FontAwesomeIcon icon={faClock} color="white" size={"2x"} />}
      title={
        <Typography variant={window.innerWidth > 500 ? "h4" : "h5"}>
          Patient appointments
        </Typography>
      }
      subtitle={"Your clinic dashboard schedule"}
      titleClassName="text-base"
      className={`relative`}
    >
      <div className="w-full grid grid-cols-1  gap-4">
        <div className="flex flex-col w-full gap-3">
          <div className="flex gap-3 items-center">
            <span className="text-xs font-semibold">Switch Views</span>
            <span
              className={`text-xs py-1 px-3 rounded-xl hover:bg-primary cursor-pointer duration-200 ${
                view == "dayGridMonth"
                  ? "bg-primary text-white"
                  : "bg-slate-400 text-white"
              }`}
              onClick={(e) => {
                setView("dayGridMonth");
              }}
            >
              Month View
            </span>
            <span
              className={`text-xs py-1 px-3 rounded-xl hover:bg-primary cursor-pointer duration-200 ${
                view == "timeGridWeek"
                  ? "bg-primary text-white"
                  : "bg-slate-400 text-white"
              }`}
              onClick={(e) => {
                setView("timeGridWeek");
              }}
            >
              Weekly View
            </span>
            <span
              className={`text-xs py-1 px-3 rounded-xl hover:bg-primary cursor-pointer duration-200 ${
                view == "timeGridDay"
                  ? "bg-primary text-white"
                  : "bg-slate-400 text-white"
              }`}
              onClick={(e) => {
                setView("timeGridDay");
              }}
            >
              Day View
            </span>
          </div>
          <div className="bg-white rounded-xl p-4">
            {view == "dayGridMonth" ? (
              <FullCalendar
                key={`view-dayGridMonth`}
                type={"dayGridMonth"}
                defaultView={"dayGridMonth"}
                initialView={"dayGridMonth"}
                header={{
                  left: "prev,next",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                themeSystem="Simplex"
                plugins={[dayGridPlugin, timeGridPlugin]}
                events={events}
              />
            ) : (
              ""
            )}
            {view == "timeGridWeek" ? (
              <FullCalendar
                key={`view-timeGridWeek`}
                type={"timeGridWeek"}
                initialView={"timeGridWeek"}
                defaultView={"timeGridWeek"}
                header={{
                  left: "prev,next",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                themeSystem="Simplex"
                plugins={[dayGridPlugin, timeGridPlugin]}
                events={events}
              />
            ) : (
              ""
            )}
            {view == "timeGridDay" ? (
              <FullCalendar
                key={`view-timeGridDay`}
                type={"timeGridDay"}
                defaultView={"timeGridDay"}
                initialView={"timeGridDay"}
                header={{
                  left: "prev,next",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                themeSystem="Simplex"
                plugins={[dayGridPlugin, timeGridPlugin]}
                events={events}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default CisDoctorPastAppointments;
