import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import SelectItemsSelector from "./SelectItemsSelector";
import Img from "src/components/Img";
import { UsersData } from "src/ContextAPI";
import {
	dataURItoBlob,
	dateYYYYMMDD,
	formatDateMMDDYYYYHHIIA,
} from "src/helpers/utils";
import ItemServiceList from "./ItemServiceList";
import SelectInputField from "src/components/forms/SelectInputField";
import SelectToUseItems from "./SelectToUseItems";
import ImagePicker from "src/components/forms/ImagePicker";

const CreateBhsServiceModal = (props, ref) => {
	const { patient, refresh, onSuccess } = props;
	const { users } = useContext(UsersData);
	const { register: register1, watch: watch1 } = useForm();
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		trigger,
		control,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(0);
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const [refreshKey, setRefreshKey] = useState(1);
	const [selected, setSelected] = useState(null);
	const [selectedRHU, setSelectedRHU] = useState(null);
	const [selectedClick, setSelectedClick] = useState(null);
	const [isPositive, setIsPositive] = useState(false);
	const [itemsUsed, setItemsUsed] = useState(false);
	const [forConfirmation, setForConfirmation] = useState(false);
	const [items, setItems] = useState([]);
	const [rhus, setRHUS] = useState([]);
	const [doctors, setDoctors] = useState([]);
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [specimen, setSpecimen] = useState(null);

	useEffect(() => {
		let t = setTimeout(() => {
			getRHUData();
			getTbDoctors();
			getRHUs();
		}, 100);
		return () => {
			clearTimeout(t);
		};
	}, []);

	// console.log("usersusersusers", users);

	const [locationsData, setLocationsData] = useState([]);

	const getRHUData = () => {
		axios
			.get(
				`/v1/health-unit/list?type=RHU&municipality_id=${users?.municipality_id}`
			)
			.then((res) => {
				setLocationsData(res.data.data);
			});
	};

	const useItems = () => {
		console.log("itemsssss", items);

		// items.map()
		///v1/doctor/laboratory-order/store
		const formData = new FormData();
		formData.append("order_date", dateYYYYMMDD());
		formData.append("patient_id", patient?.id);
		formData.append("appointment_id", appointment?.id);

		formData.append("laboratory_test_type", "tuberculosis");
		formData.append("notes", "tuberculosis");
		formData.append("_method", "PATCH");
		items.map((x) => {
			formData.append("inventory_id[]", x.id);
			formData.append("items[]", x.item?.id);
			formData.append("quantity[]", 1);
		});
		axios
			.post(`/v1/clinic/tb-lab-service/${appointment?.id}`, formData)
			.then((res) => {
				setItemsUsed(true);
				toast.success("Success! item used successfully!");
				// setRefreshKey((x) => x + 1);
			});
	};

	const proccessReferral = (data) => {
		setLoading(true);
		let formdata = new FormData();
		formdata.append("rhu_id", selectedRHU);
		formdata.append("doctor_id", selectedDoctor);
		formdata.append("_method", "PATCH");

		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
			// onUploadProgress: progressEvent => onProgress(progressEvent),
		};

		const file = dataURItoBlob(specimen);

		formdata.append("specimen", file);
		axios
			.post(
				`v1/clinic/tb-refer-to-rhu/${appointment?.id}`,
				formdata,
				config
			)
			.then((response) => {
				let data = response.data;
				// console.log(data);
				setLoading(false);
				refresh();
				setTimeout(() => {
					onSuccess();
					toast.success("Patient referral success!");
				}, 200);
				hide();
			})
			.catch((err) => {
				// setLoading(false);
				console.log(err);
			});
	};

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const submitForConfirmation = (data) => {
		setLoading(true);
		let formdata = new FormData();
		formdata.append("rhu_id", selectedRHU);
		formdata.append("doctor_id", selectedDoctor);
		formdata.append("_method", "PATCH");

		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
			// onUploadProgress: progressEvent => onProgress(progressEvent),
		};

		const file = dataURItoBlob(specimen);

		formdata.append("specimen", file);
		axios
			.post(
				`v1/clinic/tb-for-doctor-confirmation/${appointment?.id}`,
				formdata,
				config
			)
			.then((response) => {
				let data = response.data;
				// console.log(data);
				setLoading(false);
				refresh();
				setTimeout(() => {
					onSuccess();
					toast.success("Patient referral success!");
				}, 200);
				hide();
			})
			.catch((err) => {
				// setLoading(false);
				console.log(err);
			});
	};

	const getTbDoctors = () => {
		axios.get(`/v1/clinic/tb-doctors`).then((res) => {
			setDoctors(res.data.data);
		});
	};
	const getRHUs = () => {
		axios.get(`/v1/health-unit/list?type=RHU`).then((res) => {
			setRHUS(res.data.data);
		});
	};

	const getDoctorsBasedOnRHU = (rhu_id) => {
		axios
			.get(`/v1/doctors-health-unit?health_unit_id=${rhu_id}`)
			.then((res) => {
				setDoctors(res.data);
			});
	};
	return (
		<Modal
			open={open}
			staticModal={true}
			size="lg"
			hide={hide}
			className=" w-full"
		>
			<ModalHeader
				title={`Add services`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="pb-3 flex items-center gap-4">
					<SelectInputField
						label="Select disease"
						labelClassName="font-bold"
						placeholder="List of Diseases"
						className="!mb-0 w-1/3"
						value={selected}
						onChange={(data) => {
							setSelected(data.target.value);
						}}
						options={[
							{
								label: "Tuberculosis",
								value: "tuberculosis",
							},
							{
								label: "Animal Bite",
								value: "animal-bite",
								disabled: true,
							},
							{
								label: "Hypertension",
								value: "hypertension",
								disabled: true,
							},
						]}
					/>

					{isPositive ? (
						<>
							<SelectInputField
								labelClassName="font-bold"
								label="Select RHU"
								placeholder="Refer to RHU"
								className="!mb-0 w-1/3"
								value={selectedRHU}
								disabled={!isPositive}
								{...register1("rhu_id", {
									required: "This field is required.",
								})}
								error={errors?.rhu_id?.message}
								onChange={(data) => {
									setSelectedRHU(data.target.value);
								}}
								options={locationsData?.map((x) => {
									return {
										label: x.name,
										value: x.id,
									};
								})}
							/>
							<ActionBtn
								className="mt-3"
								loading={loading}
								onClick={handleSubmit(proccessReferral)}
								disabled={!selectedRHU}
							>
								Process{" "}
							</ActionBtn>
						</>
					) : (
						""
					)}
				</div>

				{itemsUsed && selected == "tuberculosis" ? (
					<>
						<ImagePicker
							className="border border-dashed rounded-lg mt-2 mb-4 flex items-center justify-center"
							onChange={(data) => {
								setSpecimen(data);
								// if (isBase64(data)) {
								// 	saveAvatar(data);
								// }
							}}
						>
							{({ imagePreview }) => {
								return (
									<>
										{imagePreview ? (
											<div className="relative w-full">
												<div className="pt-[45%]" />
												<img
													src={imagePreview}
													className="absolute object-contain bg-black top-0 left-0 w-full h-full"
												/>
											</div>
										) : (
											<div className="flex flex-col justify-center items-center gap-3 p-5 text-lg">
												<FlatIcon
													icon="rr-upload"
													className="text-lg text-slate-400"
												/>
												<span className=" text-slate-400">
													Upload specimen picture
												</span>
											</div>
										)}
									</>
								);
							}}
						</ImagePicker>
					</>
				) : selected ? (
					<div className="flex flex-col mb-3">
						<SelectToUseItems setSelectedItems={setItems} />
					</div>
				) : (
					""
				)}

				{itemsUsed ? (
					<>
						<SelectInputField
							labelClassName="font-bold"
							label="Select RHU"
							placeholder="Select RHU"
							className="!mb-4  w-full"
							error={errors?.doctor?.message}
							value={selectedRHU}
							{...register("rhu_id", {
								required: "You must select a rhu.",
							})}
							onChange={(data) => {
								setSelectedRHU(data.target.value);
								getDoctorsBasedOnRHU(data.target.value);
							}}
							options={[
								...rhus?.map((rhu) => ({
									label: `${rhu?.name}`,
									value: rhu?.id,
								})),
								// {
								// 	label: "Refer to SPH",
								// 	value: "SPH",
								// },
							]}
						/>
						<SelectInputField
							labelClassName="font-bold"
							label="Select TB Dots doctor"
							placeholder="Select TB Dots doctor"
							className="!mb-0 w-full"
							error={errors?.doctor?.message}
							value={selectedDoctor}
							{...register("doctor", {
								required: "You must select a doctor.",
							})}
							onChange={(data) => {
								setSelectedDoctor(data.target.value);
							}}
							options={[
								...doctors?.map((doctor) => ({
									label: `${doctor?.title} ${doctor?.name}`,
									value: doctor?.id,
								})),
								// {
								// 	label: "Refer to SPH",
								// 	value: "SPH",
								// },
							]}
						/>
					</>
				) : (
					""
				)}
			</ModalBody>
			<ModalFooter className={"flex items-center justify-between gap-4"}>
				{itemsUsed ? (
					<>
						<ActionBtn
							className="w-1/3"
							onClick={handleSubmit(submitForConfirmation)}
							// disabled={!watch("positive") || isPositive}
						>
							<FlatIcon icon="rr-user-md" className="-mt-[4px]" />
							Refer to doctor for confirmation
						</ActionBtn>
						{/* <ActionBtn
							className="w-1/3 font-bold"
							type="danger"
							onClick={handleSubmit(proccessReferral)}
							// disabled={!watch("positive") || isPositive}
						>
							Refer to RHU
							<FlatIcon
								icon="rr-share-square"
								className="-mt-[4px]"
							/>
						</ActionBtn> */}
						{/* <ActionBtn
							className={`ml-auto ${
								watch("negative") ? "" : "hidden"
							}`}
							type="danger"
							onClick={handleSubmit(submitNegative)}
						>
							TB Negative, Close!
						</ActionBtn> */}
					</>
				) : (
					<ActionBtn
						className="w-1/4"
						type="accent"
						disabled={!selected}
						onClick={useItems}
					>
						Continue and Use items
					</ActionBtn>
				)}
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(CreateBhsServiceModal);
