import Moment from "react-moment";
import Clock from "react-live-clock";
const DateTimeClock = ({ className = "" }) => {
	return (
		<div
			className={`flex flex-col justify-center items-center min-w-[156px] ${className}`}
		>
			<b className="text-2xl font-bold text-darker">
				<Clock
					format={"MMMM D, YYYY"}
					ticking={true}
					timezone={"ASIA/Manila"}
				/>
			</b>
			<b className="text-sm font-bold text-dark">
				<Clock
					format={"hh:mm:ss A"}
					ticking={true}
					timezone={"ASIA/Manila"}
				/>
			</b>
		</div>
	);
};

export default DateTimeClock;
