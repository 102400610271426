import { useEffect, useState } from "react";

const ImagePicker = ({ className = "", children, onChange }) => {
	const [imagePreview, setImagePreview] = useState(null);

	const handleChangeImage = (event) => {
		let reader = new FileReader();
		let file = event.target.files[0];

		reader.onload = (event) => {
			setImagePreview(event.target.result);
		};
		reader.readAsDataURL(file);
	};

	useEffect(() => {
		if (onChange && imagePreview) {
			onChange(imagePreview);
		}
	}, [imagePreview]);
	return (
		<label className={`cursor-pointer ${className}`}>
			<div className="absolute h-0 w-0 overflow-hidden">
				<input
					type="file"
					onChange={handleChangeImage}
					accept="image/*"
				/>
			</div>
			{children({ imagePreview })}
		</label>
	);
};

export default ImagePicker;
