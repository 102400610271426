import {
  Button,
  CardActions,
  CardContent,
  Box,
  Typography,
  Grid,
  CardMedia,
  CardHeader,
} from "@material-ui/core";
import React, { useEffect, useContext, useState } from "react";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";
import Notify from "src/notification/Notify";
import { useReactToPrint } from "react-to-print";
import { Printer, XCircle } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const PrintDischargeSlip = ({ onClose, details }) => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();

  const [patientInfo, setPatientInfo] = useState(null);

  const printableRef = React.useRef();

  const [formHeader, setFormHeader] = useState({
    data: [],
    ready: false,
  });

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const getPatientsDetails = React.useCallback(async () => {
    let { data } = await axios.get(
      `cashier/discharged/patient/get-patient-info`,
      {
        params: {
          management_id: users.management_id,
          patient_id: details?.patient_id,
        },
      }
    );

    if (mounted.current) {
      if (data.length > 0) {
        setPatientInfo(data[0]);
      }
    }
  }, [users, mounted, details]);

  const getLabFormHeader = React.useCallback(() => {
    var params = { management_id: users.management_id };
    axios
      .get("laboratory/order/formheader-details", { params })
      .then((response) => {
        const data = response.data;
        if (mounted.current) {
          setFormHeader({ data, ready: true });
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users, mounted]);

  useEffect(() => {
    getPatientsDetails();
    getLabFormHeader();
  }, [getPatientsDetails, getLabFormHeader]);

  return (
    <>
      <CardContent ref={printableRef}>
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formHeader.ready
              ? formHeader.data && (
                  <CardMedia
                    style={{ width: 70 }}
                    component={"img"}
                    src={`${imageLocation}laboratory/logo/${formHeader.data.logo}`}
                  />
                )
              : null}
          </Box>

          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={formHeader.data && formHeader.data.name}
              subheader={
                <Box>
                  <Typography>
                    {formHeader.data && formHeader.data.address}
                  </Typography>
                  <Typography>
                    {formHeader.data && formHeader.data.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <Box my={2}>
          <Typography variant="h6" align="center">
            PATIENT DISCHARGE FORM
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Typography>Patient Name</Typography>
              <Typography variant="h6">{`${patientInfo?.lastname}, ${patientInfo?.firstname}`}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography>Date Admitted</Typography>
              <Typography variant="h6">
                {Notify.createdAt(details?.date_admitted)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography>Reason</Typography>
              <Typography variant="h6"> {details?.admitted_reason} </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography>Diagnosis at Internment</Typography>
              <Typography variant="h6"> {details?.diagnosis} </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography>Please describe the treatment taken</Typography>
              <Typography variant="h6"> {details?.treatment} </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography>Date Discharged</Typography>
              <Typography variant="h6">
                {Notify.createdAt(details?.discharged_date)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography>
                Is this discharge approved by the physician?
              </Typography>
              <Typography variant="h6">
                {Boolean(parseInt(details?.discharge_approved_by_md))
                  ? "Yes"
                  : "No"}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography> Reason for Discharge </Typography>
              <Typography variant="h6">
                {" "}
                {details?.discharge_reason}{" "}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography> Discharge MD </Typography>
              <Typography variant="h6"> {details?.discharged_md} </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <Button
          onClick={() => handlePrint()}
          variant="contained"
          color="primary"
          startIcon={<Printer />}
        >
          Print
        </Button>

        <Button
          onClick={() => onClose()}
          variant="contained"
          color="default"
          startIcon={<XCircle />}
        >
          Close
        </Button>
      </CardActions>
    </>
  );
};

export default PrintDischargeSlip;
