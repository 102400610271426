import { faVirus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import ConfirmationModal from "src/components/modal/ConfirmationModal";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import { localArraySearch } from "src/helpers/utils";
import SupplierModalForm from "./components/SupplierModalForm";
import axios from "axios";


const SupplierManagement = () => {
	const [list, setList] = useState([]);
	const [keyword, setKeyword] = useState("");
	const supplier_form_modal = useRef(null);
	const confirm_delete_ref = useRef(null);
	const {
		data,
		loading,
		updateInList,
		addToList,
		removeFromList,
		column,
		setColumn,
		direction,
		setDirection,
	} = useDataTable("/v1/management/supplier", setList, {});
	useEffect(() => {
		 if (data) {
		 	setList(data || []);
		 }
	}, [data]);
	const columns = [
		{
			header: "Supplier Name",
			accessorKey: "name",
			headerClassName: "text-left min-w-[328px]",
			className: "text-left",
			sortable: true,
			
		},
		
		
		{
			header: "Action",
			accessorKey: "action",
			cell: (data) => {
				return (
					<div className="flex items-center gap-2">
						<ActionBtn
							size="sm"
							type="primary-light"
							onClick={() => {
								console.log("datadata", data);
								supplier_form_modal.current.show(data);
							}}
						>
							<FlatIcon icon="rr-edit" className="text-lg mr-2" />
							Edit
						</ActionBtn>
						<ActionBtn
							size="sm"
							type="danger-light"
							onClick={() => {
								confirm_delete_ref.current.show({
									title: "Delete supplier",
									message:
										"Are you sure to delete this supplier?",
									footer: ({ loadingBtn, setLoadingBtn }) => {
										return (
											<div className="flex items-center gap-4 w-full justify-end">
												<ActionBtn
													size="sm"
													type="primary-gradient"
													onClick={() => {
														confirm_delete_ref.current.hide();
													}}
												>
													No
												</ActionBtn>
												<ActionBtn
													size="sm"
													type="danger"
													loading={loadingBtn}
													onClick={() => {
														setLoadingBtn(true);
														deleteitem(
															data.id
														).then((res) => {
															setTimeout(() => {
																setLoadingBtn(
																	false
																);
																removeFromList(
																	data
																);
																toast.success(
																	"Supplier record deleted successfully!"
																);
															}, 200);
															confirm_delete_ref.current.hide();
														});
													}}
												>
													Confirm delete
												</ActionBtn>
											</div>
										);
									},
								});
							}}
						>
							<FlatIcon
								icon="rr-trash"
								className="text-lg mr-2"
							/>
							Delete
						</ActionBtn>
					</div>
				);
			},
		},
	];
	const deleteitem = (id) => {
		return axios.post(`/v1/management/supplier/${id}`, {
			_method: "DELETE",
		});
	};
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon icon={faVirus} color="white" size={"4x"} />
				}
				title="Supplier Management"
				subtitle="Add/Edit/Delete item data here."
			>
				<CardContainer
					containerClassName={` border border- border-opacity-10 w-full`}
					header={{
						title: "Supplier list data",
						children: (
							<div className="ml-auto flex items-center gap-3">
								<ActionBtn
									className="!no-underline !h-[42px]"
									size="sm"
									onClick={() => {
										supplier_form_modal.current.show();
									}}
								>
									<FlatIcon icon="rr-plus" className="mr-2" />
									<span className="!no-underline">
										Add supplier
									</span>
								</ActionBtn>
								<TextInputField
									inputClassName=" pl-10"
									className="w-full lg:w-[320px]"
									onChange={(e) => {
										setKeyword(e.target.value);
									}}
									icon={
										<FlatIcon
											icon="rr-search"
											className="text-sm"
										/>
									}
									placeholder="Search"
								/>
							</div>
						),
					}}
					className="!p-0"
				>
					<div className="max-h-[calc(100vh-328px)] overflow-auto">
						<TableV2
							paginationClassName="px-4"
							columns={columns}
							pagination={false}
							loading={loading}
							column={column}
							setColumn={setColumn}
							direction={direction}
							setDirection={setDirection}
							data={
								keyword?.length > 0
									? localArraySearch(list, keyword)
									: list
							}
							onTableChange={(data) => {}}
							theadClassName={`sticky top-[-1px]`}
						/>
					</div>
				</CardContainer>
			</LayoutContainer>
			<SupplierModalForm
				ref={supplier_form_modal}
				addToList={addToList}
				updateInList={updateInList}
			/>
			<ConfirmationModal ref={confirm_delete_ref} />
		</>
	);
};

export default SupplierManagement;
