import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import QRCode from "qrcode.react";
import axios from "axios";
import ViewPatientProfile from "src/admission/components/ViewPatientProfile";
import TabGroup from "src/components/TabGroup";
import Img from "src/components/Img";
import CardContainer from "src/components/CardContainer";
import ResponseDetails from "./ReponseDetails";
import {
	calculateAge,
	formatDate,
	replaceFname,
	replaceLname,
	replaceMname,
} from "src/helpers/utils";
import { UsersData } from "src/ContextAPI";
const PatientProfileModal = (props, ref) => {
	const { className } = props;
	const [open, setOpen] = useState(false);
	const [patient, setPatient] = useState(null);
	const { users } = useContext(UsersData);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const getPatientInfo = (id) => {
		return axios.get(`v1/patients/${id}`);
	};
	const show = (data) => {
		setPatient(data);
		getPatientInfo(data?.id).then((res) => {
			setPatient(res.data.data);
		});
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};
	let total = 0;

	const downloadQRCode = () => {
		// Generate download with use canvas and stream
		const canvas = document.getElementById("qr-gen");
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = `${patient.lastname}_${patient.firstname}.png`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	return (
		<Modal open={open} hide={hide} size="xl">
			<ModalHeader title={"View Response"} hide={hide} />
			<ModalBody className={`!py-0`}>
				<CardContainer className={`!p-0 rounded-xl ${className}`}>
					<div className="flex flex-col gap-4 lg:items-center px-4 pt-4 border-b pb-4">
						<div className="relative h-36  w-36 rounded-full overflow-hidden aspect-square bg-background mr-4">
							<Img
								type="user"
								name={`${replaceLname(
									patient?.lastname
								)}-${replaceFname(
									patient?.firstname
								)}-${replaceMname(patient?.middle)}`}
								className="w-full aspect-square object-cover"
								alt=""
							/>
						</div>
						<div className="flex flex-col pl-4">
							<h6
								className={`text-left text-2xl mb-1 font-semibold flex items-center ${
									patient?.gender == "Male"
										? "text-blue-800"
										: "text-pink-800"
								} mb-0`}
							>
								{`${replaceLname(
									patient?.lastname
								)}, ${replaceFname(
									patient?.firstname
								)} ${replaceMname(patient?.middle)}`}
							</h6>
							<div className="flex gap-6 mb-2 flex-wrap">
								<div className="flex items-center gap-2 text-base">
									<FlatIcon
										icon="rr-calendar-clock"
										className="text-lg"
									/>
									<span>
										{calculateAge(patient?.birthday)} yrs.
										old
									</span>
								</div>
								<div className="flex items-center gap-2 text-base">
									<FlatIcon
										icon="rr-calendar"
										className="text-lg"
									/>
									<span>{formatDate(patient?.birthday)}</span>
								</div>
							</div>
							<div className="flex items-center gap-2 text-base mb-2">
								<FlatIcon
									icon="rr-map-marker"
									className="text-lg"
								/>
								<span className="">{`${
									patient?.municipality
										? patient?.municipality + ", "
										: ""
								} ${
									patient?.city ? patient?.city + ", " : ""
								} ${
									patient?.barangay
										? patient?.barangay + ", "
										: ""
								} ${patient?.street}`}</span>
							</div>
							<div className="flex gap-6 mb-2 flex-wrap">
								<div className="flex items-center gap-2 text-base">
									<FlatIcon
										icon="rr-venus-mars"
										className="text-lg"
									/>
									{patient?.gender == "Male" ? (
										<span className="text-blue-700">
											Male
										</span>
									) : (
										<span className="text-pink-700">
											Female
										</span>
									)}
								</div>
								<div className="flex items-center gap-2 text-base">
									<FlatIcon
										icon="rr-rings-wedding"
										className="text-lg"
									/>
									<span>{patient?.civil_status}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="flex flex-col lg:flex-row gap-4 pb-4">
						<div className="px-4 border-r pt-4">
							<h6 className="text-sm font-bold mb-0 text-center !text-secondary">
								Patient QRCode
							</h6>
							<div className="flex items-center justify-center pt-1 pb-2 w-full">
								<QRCode
									id="qr-gen"
									value={JSON.stringify({
										patient_id: patient?.patient_id,
										user_id: users?.user_id,
										philhealth: "philhealth",
									})}
									level="H"
									size={144}
								/>
							</div>
							<ActionBtn size="sm" onClick={downloadQRCode}>
								Download QR
							</ActionBtn>
						</div>
						<div className="xl:col-span-9 lg:col-span-8 w-full">
							<div className="flex flex-col gap-y-4 p-3">
								<ResponseDetails patient={patient} />
							</div>
						</div>
					</div>
				</CardContainer>
			</ModalBody>
		</Modal>
	);
};

export default forwardRef(PatientProfileModal);
