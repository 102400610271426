import { Box, Card, Typography } from "@material-ui/core";
import React from "react";

export default function Cards({ bg, img, assessment, textColor }) {
  return (
    <div className="flex">
      <Card elevation={3} className=" my-4 mx-auto" component={Box}>
        <div className={`flex p-4 ${bg} ${textColor}  items-center`}>
          <img src={img} className="w-14 h-14 mr-4  object-contain" />
          <Typography variant="h3">
            <b>{assessment}</b>
          </Typography>
        </div>
      </Card>
    </div>
  );
}
