import { Box, CardMedia, Grid, Typography } from "@material-ui/core";
import React from "react";

const FormHeader = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Box align="center">
          <CardMedia
            component="img"
            src="/icons/sample-image.jpg"
            alt="sample logo"
          />
        </Box>
      </Grid>

      <Grid item xs={8}>
        <Box align="center">
          <Typography> Republic of the Philippines </Typography>
          <Typography> SAMPLE TEMPLATE </Typography>
          <Typography>
            <b> GLOBAL TELEMEDICINE CORP </b>
          </Typography>
          <Typography color="primary">
            SAMPLE TEMPLATE SURGICAL CLINIC
          </Typography>

          <Typography>Km. 7, NSDS, Matina Pangi, Davao City</Typography>
        </Box>
      </Grid>

      <Grid item xs={2}>
        <Box align="center">
          <CardMedia component="img" src="/bmcdc_logo.png" alt="gtc logo" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormHeader;
