import FlatIcon from "./FlatIcon";

const InfoText = ({ className = "", icon, title, value, ...rest }) => {
	return (
		<div className={`flex flex-col items-start ${className}`} {...rest}>
			<label className="text-placeholder flex items-center text-xs border-b pr-4 pb-1 mb-2 font-light border-opacity-50 capitalize">
				{icon ? <FlatIcon icon={icon} className="mr-1" /> : ""} {title}
			</label>

			<div className="text-darker text-sm font-semibold capitalize">
				{value || (
					<>
						<span className="text-gray-200 font-normal text-xs italic">
							blank
						</span>{" "}
						&nbsp;
					</>
				)}
			</div>
		</div>
	);
};

export default InfoText;
