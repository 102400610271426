import { Box, Paper, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ChartistGraph from "react-chartist";
import axios from "axios";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import { UsersData } from "src/ContextAPI";

const IncomeGraph = () => {
	const ismounted = IsMountedRef();
	const { users } = React.useContext(UsersData);
	const [year, setYear] = useState([new Date().getFullYear()]);
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [series, setSeries] = useState({ data: [], ready: false });

	const getIncomeReport = async () => {
		try {
			var params = {
				user_id: users.user_id,
				year: selectedYear,
			};

			const income = await axios.get("doctor/income/report/bymonth", {
				params,
			});
			const data = income.data;
			if (ismounted.current) {
				if (data.length > 0) {
					setSeries({
						data: [
							data[0].jan_income,
							data[0].feb_income,
							data[0].mar_income,
							data[0].apr_income,
							data[0].may_income,
							data[0].jun_income,
							data[0].jul_income,
							data[0].aug_income,
							data[0].sep_income,
							data[0].oct_income,
							data[0].nov_income,
							data[0].dec_income,
						],
						ready: true,
					});
				}
			}
		} catch (error) {
			console.log(error);
			Notify.requestError(error);
		}
	};

	const label = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const mobileLabel = [
		"01",
		"02",
		"03",
		"04",
		"05",
		"06",
		"07",
		"08",
		"09",
		"10",
		"11",
		"12",
	];

	const data = {
		labels: window.innerWidth > 600 ? label : mobileLabel,
		series: [series],
	};

	const options = {
		fullWidth: true,
		showArea: true,
		height: window.innerWidth > 600 ? 300 : 200,
	};

	const type = "Line";

	const generateYear = () => {
		if (ismounted.current) {
			for (let i = 2019; i <= parseInt(year); i++) {
				setYear((year) => year.concat(i++));
			}
		}
	};

	//   console.log(`Income Monitoring:`, year);

	React.useEffect(() => {
		getIncomeReport();

		setTimeout(() => {
			generateYear();
		}, 2000);

		return () => setSeries({ data: [], ready: false });

		// eslint-disable-next-line
	}, [selectedYear, ismounted]);

	return (
		<Box>
			<Paper component={Box} mt={2} p={1} elevation={2}>
				<Box display="flex" m={1}>
					<Box flexGrow={1}>
						<Typography variant="h6" color="textSecondary">
							Income Monitoring
						</Typography>
					</Box>

					<Box>
						<TextField
							select
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							required
							fullWidth
							name="gender"
							label="Year"
							variant="outlined"
							margin="dense"
							value={selectedYear}
							onChange={(e) => setSelectedYear(e.target.value)}
						>
							{[2019, 2020, 2021, 2022, 2023, 2024, 2025].map((data, index) => (
								<option key={index} value={data}>
									{" "}
									{data}{" "}
								</option>
							))}
						</TextField>
					</Box>
				</Box>

				{series.ready ? (
					<ChartistGraph data={data} options={options} type={type} />
				) : (
					Notify.loading()
				)}
			</Paper>
		</Box>
	);
};

export default IncomeGraph;
