import { useContext } from "react";
import { Fade } from "react-reveal";
import { ThemeContext } from "src/ContextAPI";
import FlatIcon from "./FlatIcon";

const LayoutContainer = ({
	icon,
	children,
	title,
	subtitle,
	className,
	subtitleClassName = "",
	titleClassName = "",
	hideHeader = false,
	titleChildren,
}) => {
	const themeContext = useContext(ThemeContext);

	return (
		<Fade>
			<div
				className={`w-full lg:w-[calc(100%-280px)] ml-auto mt-16 bg-background h-[calc(100vh-64px)] flex flex-col relative ${
					themeContext.sidebar.open ? "" : "min-w-full"
				}`}
			>
				{hideHeader ? (
					""
				) : (
					<div className="bg-foreground !px-5 !py-3 flex flex-col lg:flex-row lg:items-center gap-y-3">
						<div className="flex items-center !mt-3 lg:!mt-0">
							{icon ? (
								<div className="flex items-center justify-center text-center mr-3 rounded-xl w-11 h-11 bg-[#2196f3] text-white">
									{icon}
								</div>
							) : (
								""
							)}
							<div className="flex flex-col gap-0">
								{title ? (
									<span
										className={`text-xl !font-bold -mb-1 text-[#1e88e5] ${titleClassName}`}
									>
										{title}
									</span>
								) : (
									""
								)}

								{subtitle ? (
									<span
										className={`text-placeholder text-sm mb-0 ${subtitleClassName}`}
									>
										{subtitle}
									</span>
								) : (
									""
								)}
							</div>
						</div>
						{titleChildren}
					</div>
				)}
				<div
					className={`!p-2 lg:!p-6 flex flex-col ${
						className ? className : ""
					}`}
				>
					{children}
				</div>
			</div>
		</Fade>
	);
};

export default LayoutContainer;
