import React, { Fragment, useCallback } from "react";
import {
  Grid,
  Box,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";

const OthersTest = () => {
  const { users } = React.useContext(UsersData);

  const [testList, setTestList] = React.useState({
    data: [],
    ready: false,
  });

  const getOtherTest = useCallback(async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
      };
      const request = await axios.get(
        "admission/patients/other-test/test-getlist",
        { params }
      );
      setTestList({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users]);

  React.useEffect(() => {
    getOtherTest();
  }, [getOtherTest]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Box component={Paper} variant="outlined" p={2}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <strong> Order Desc </strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong> Rate </strong>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {testList.ready ? (
                    testList.data.length > 0 ? (
                      testList.data.map((data, index) => (
                        <TableRow key={index} hover>
                          <TableCell> {data.order_name} </TableCell>
                          <TableCell align="right">
                            {data.order_amount}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          <Typography variant="subtitle2" color="secondary">
                            No other test order added.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <Typography variant="subtitle2" color="primary">
                          Please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OthersTest;
