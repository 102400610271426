import React, { useContext, useState } from "react";
import {
  Box,
  Paper,
  Tabs,
  Tab,
  CardMedia,
  Typography,
} from "@material-ui/core";
import Container from "src/layout/Container";
import TabPanel from "src/utils/TabPanel";
import BankAccounts from "./BankAccounts";
import BankTransaction from "./transaction/BankTransaction";
import { UsersData } from "src/ContextAPI";

const BankList = () => {
  const [category, setCategory] = useState("transaction");
  const { users } = useContext(UsersData);

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Bank List",
          items: [{ name: "Dashboard", path: "/sph/app/accounting" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Bank List</Box>
            <Box>
              <Paper>
                <Tabs
                  value={category}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="on"
                >
                  <Tab value="transaction" label="Transaction" />
                  <Tab value="bank-account" label="Bank Accounts" />
                </Tabs>
              </Paper>
            </Box>
          </Box>
        }
      >
        {users.management_name === "BMCDC HQ" ? (
          <Box>
            <TabPanel value={category} index="transaction">
              <BankTransaction />
            </TabPanel>
            <TabPanel value={category} index="bank-account">
              <BankAccounts />
            </TabPanel>
          </Box>
        ) : (
          <RenderSelectReceivable />
        )}
      </Container>
    </>
  );
};

export default BankList;

const RenderSelectReceivable = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 270 }}
    />

    <Typography color="secondary" variant="h3">
      HQ BRANCH MUST BE SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select HQ branch in the list to view details.
    </Typography>
  </Box>
);
