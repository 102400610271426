import React, { Fragment, useContext, useEffect, useState } from "react";
import {
	Box,
	Grid,
	Typography,
	TextField,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	InputAdornment,
	Button,
	FormHelperText,
	IconButton,
} from "@material-ui/core";
import Notify from "../../notification/Notify";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import PrintIcon from "@material-ui/icons/Print";
import { Formik } from "formik";
import * as Yup from "yup";
import { UsersData } from "../../ContextAPI";
import { CancelOutlined } from "@material-ui/icons";

const getFormData = (object) => {
	const formData = new FormData();
	Object.keys(object).forEach((key) => formData.append(key, object[key]));
	return formData;
};

function ImagingSalesPrint({ close }) {
	const { users } = useContext(UsersData);
	const [search, setSearch] = useState("");
	const [total, setTotal] = useState(0);

	const [imgSalesData, setImgSalesData] = useState({
		data: [],
		ready: false,
	});

	const calculateTotalSales = (data) => {
		var total = 0;
		for (let i = 0; i < data.length; i++) {
			total += parseFloat(data[i].bill_amount);
		}
		return total;
	};

	const fetchImgSalesList = () => {
		var params = { user_id: users.user_id, management_id: users.management_id };
		axios
			.get("hims/imaging/sales-result", { params })
			.then((response) => {
				const data = response.data;
				setImgSalesData({ data: data, ready: true });
				setTotal(calculateTotalSales(data));
			})
			.catch((error) => {
				console.log("error : ", error);
				// Notify.requestError(error);
			});
	};

	useEffect(() => {
		fetchImgSalesList();
		// eslint-disable-next-line
	}, []);

	const searchable = imgSalesData.data.filter((data) => {
		return data.name.toLowerCase().indexOf(search.trim()) !== -1;
	});

	return (
		<Fragment>
			<Grid item sm={12} xs={12}>
				<Grid container>
					<Grid item xs={12} sm={12}>
						<Box>
							<TableContainer component={Box}>
								<Box display="flex">
									<Box flexGrow={1}>
										<Formik
											initialValues={{
												user_id: users.user_id,
												management_id: users.management_id,
												date_from: "",
												date_to: "",
											}}
											validationSchema={Yup.object().shape({
												date_from: Yup.string().required(
													"Date from is required"
												),
												date_to: Yup.string().required("Date to is required"),
											})}
											onSubmit={async (
												values,
												{ setErrors, setSubmitting, resetForm }
											) => {
												try {
													const request = await axios.post(
														"hims/imaging/filter-by-date/sales-result",
														getFormData(values)
													);
													const data = request.data;
													setImgSalesData({ data, ready: true });
													setTotal(calculateTotalSales(data));
													resetForm();
												} catch (error) {
													const message =
														error.message || "Something went wrong";
													setErrors({ submit: message });
													setSubmitting(false);
												}
											}}
										>
											{({
												errors,
												handleBlur,
												handleChange,
												handleSubmit,
												isSubmitting,
												touched,
												values,
											}) => (
												<form noValidate onSubmit={handleSubmit}>
													<Box display="flex" className={"d-print-none"}>
														<Box>
															<TextField
																required
																label="Date From"
																error={Boolean(
																	touched.date_from && errors.date_from
																)}
																helperText={
																	touched.date_from && errors.date_from
																}
																onBlur={handleBlur}
																onChange={handleChange}
																value={values.date_from}
																name="date_from"
																type="date"
																InputLabelProps={{
																	shrink: true,
																}}
															/>
														</Box>

														<Box ml={2}>
															<TextField
																required
																label="Date To"
																error={Boolean(
																	touched.date_to && errors.date_to
																)}
																helperText={touched.date_to && errors.date_to}
																onBlur={handleBlur}
																onChange={handleChange}
																value={values.date_to}
																name="date_to"
																type="date"
																InputLabelProps={{
																	shrink: true,
																}}
															/>
														</Box>

														{errors.submit && (
															<Box mt={3}>
																<FormHelperText error>
																	{errors.submit}
																</FormHelperText>
															</Box>
														)}

														<Box ml={1} display="flex" align="center">
															<IconButton color="primary" type="submit">
																<SearchIcon
																	fontSize="small"
																	style={{
																		border: "2px solid #000",
																		borderRadius: 4,
																		width: 35,
																		height: 35,
																	}}
																/>
															</IconButton>
														</Box>
													</Box>
												</form>
											)}
										</Formik>
									</Box>

									<Box mb={1}>
										<TextField
											className={"d-print-none"}
											label="Search patient"
											variant="outlined"
											margin="dense"
											value={search}
											onChange={(e) => setSearch(e.target.value)}
											InputProps={{
												endAdornment: (
													<InputAdornment>
														<SearchIcon />
													</InputAdornment>
												),
											}}
										/>
									</Box>
								</Box>

								<Table>
									<TableHead>
										<TableRow>
											<TableCell align="center"> PATIENT </TableCell>
											<TableCell align="center"> LAB TEST </TableCell>
											<TableCell align="center"> DATE </TableCell>
											<TableCell align="center"> AMOUNT </TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{imgSalesData.ready ? (
											imgSalesData.data.length > 0 ? (
												searchable.map((data, index) => (
													<TableRow key={index}>
														<TableCell> {data.name} </TableCell>
														<TableCell>{data.bill_name}</TableCell>
														<TableCell align="right">
															{data.created_at}
														</TableCell>
														<TableCell align="right">
															{data.bill_amount}
														</TableCell>
													</TableRow>
												))
											) : (
												<TableRow>
													<TableCell colSpan={4} align="center">
														<Typography variant="subtitle2" color="secondary">
															{" "}
															No sale found{" "}
														</Typography>
													</TableCell>
												</TableRow>
											)
										) : (
											<TableRow>
												<TableCell colSpan={4} align="center">
													<Typography variant="subtitle2" color="primary">
														{" "}
														Loading...{" "}
													</Typography>
												</TableCell>
											</TableRow>
										)}

										{imgSalesData.data.length > 0 ? (
											<TableRow>
												<TableCell colSpan={3} align="right">
													{" "}
													Total:{" "}
												</TableCell>
												<TableCell align="right">
													{" "}
													{Notify.convertToNumber(total)}{" "}
												</TableCell>
											</TableRow>
										) : null}
									</TableBody>
								</Table>
							</TableContainer>
							<Box display="flex" mt={2}>
								<Box flexGrow={1} />

								<Box mr={2}>
									<Button
										variant="outlined"
										className={"d-print-none"}
										color="default"
										startIcon={<CancelOutlined />}
										onClick={() => close()}
									>
										Close
									</Button>
								</Box>

								<Button
									variant="outlined"
									className={"d-print-none"}
									color="primary"
									startIcon={<PrintIcon />}
									onClick={() => window.print()}
								>
									Print
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Fragment>
	);
}

export default ImagingSalesPrint;
