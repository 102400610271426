import { Box, Grid } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import AddAnotherProdAsCopyInvoice from "./AddAnotherProdAsCopyInvoice";
import CopyAsInvoiceProductTemp from "./CopyAsInvoiceProductTemp";

const CopyAsInvoicee = () => {
  const { users } = useContext(UsersData);
  const { po_number } = useParams();

  const [copyInvoiceProducts, setCopyInvoiceProducts] = useState({
    data: [],
    ready: false,
  });

  const getAllProductsByPONumber = useCallback(async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      po_number,
    };
    try {
      let response = await axios.get("pharmacy/warehouse/get/prod-by-po", {
        params,
      });
      const data = response.data;
      setCopyInvoiceProducts({ data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, po_number]);

  useEffect(() => {
    getAllProductsByPONumber();
  }, [getAllProductsByPONumber]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Purchase Order",
        items: [{ name: "Dashboard", path: "/sph/app" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Copy As Invoice</Box>
        </Box>
      }
    >
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <AddAnotherProdAsCopyInvoice
              getAllProductsByPONumber={() => getAllProductsByPONumber()}
              po_number={po_number}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <CopyAsInvoiceProductTemp
              copyInvoiceProducts={copyInvoiceProducts}
              getAllProductsByPONumber={() => getAllProductsByPONumber()}
              po_number={po_number}
            />
          </Grid>
        </Grid>
      </>
    </Container>
  );
};

export default CopyAsInvoicee;
