import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "src/layout/Container";
import GTCNewsFull from "src/GTCNewsFull";
import { UsersHeader } from "src/ContextAPI";

const TriageNews = () => {
  const { sidebarHeader } = React.useContext(UsersHeader);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: false,
          current: "SAMOPLPERLS PAERL PSE",
          items: [],
        }}
        title={
          <>
            <Typography variant={window.innerWidth > 500 ? "h4" : "h5"}>
              {" "}
              Dashboard{" "}
            </Typography>
            <Typography variant="subtitle2">
              {" "}
              Welcome back, {sidebarHeader.name}! 👋{" "}
            </Typography>
          </>
        }
      >
        <GTCNewsFull />
      </Container>
    </Fragment>
  );
};

export default TriageNews;
