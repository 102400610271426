import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import PatientProfileOverview from "src/cis-doctor/components/PatientProfileOverview";
import CisPatientListItem from "src/cis/components/CisPatientListItem";
import PatientProfilePage from "src/cis/components/PatientProfilePage";
import ButtonV3 from "src/components/ButtonV3";
import FlatIcon from "src/components/FlatIcon";
import LayoutContainer from "src/components/LayoutContainer";
import { v4 as uuidv4 } from "uuid";
import useDataTable from "src/helpers/useDataTable";
import BhsPatientProfile from "./BhsPatientProfile";
import HealthUnitDetails from "./components/HealthUnitDetails";
import axios from "axios";
import Pagination from "src/components/table/Pagination";
import PrivacyStatementModal from "./components/PrivacyStatementModal";
import CreateNewPatientFormModal from "./components/CreateNewPatientFormModal";

const modalKey = uuidv4();

const BhsPatients = () => {
	const history = useHistory();
	const viewPrivacyRef = useRef(null);
	const newPatientFormRef = useRef(null);

	const [modalKeys, setModalKeys] = useState(`${modalKey}`);
	const [patient, setPatient] = useState(null);
	const [patientSelfie, setPatientSelfie] = useState(null);
	const {
		data,
		page,
		setPage,
		meta,
		loading,
		setFilters,
		column,
		setColumn,
		direction,
		setDirection,
		keyword,
		setKeyword,
		paginate,
		setPaginate,
	} = useDataTable(`v1/patients`, null, {});

	const [list, setList] = useState([]);
	const [selected, setSelected] = useState(null);
	const [selectedClick, setSelectedClick] = useState(null);
	const [pendings, setPendings] = useState(null);

	const getPendingForMedsRelease = () => {
		axios.get(`/v1/clinic/bhw-pending-for-medicine-release`).then((res) => {
			console.log("resssss", res.data.data);
			setPendings(res.data);
		});
	};

	// useEffect(() => {
	// 	let t = setInterval(() => {
	// 		getPendingForMedsRelease();
	// 	}, 5000);
	// 	return () => {
	// 		clearInterval(t);
	// 	};
	// }, []);

	useEffect(() => {
		// getPendingForMedsRelease();
		// setFilters((prevFilters) => ({
		// 	...prevFilters,
		// 	// clinic_id: 1,
		// }));
		// setPaginate(10);
	}, []);
	useEffect(() => {
		let t = setTimeout(() => {
			setList(data?.data);
		}, 200);
		return () => {
			clearTimeout(t);
		};
	}, [data]);

	const updatePatient = (patient) => {
		console.log("updatePatientupdatePatient,", patient);
		setSelectedClick(patient);
		setList((prevList) =>
			prevList?.map((p) =>
				patient?.id == p?.id
					? {
							...p,
							...patient,
					  }
					: p
			)
		);
	};

	return (
		<LayoutContainer
			icon={<FontAwesomeIcon icon={faHome} color="white" size={"2x"} />}
			title="Patients List"
			subtitle={`Patients`}
			titleChildren={<HealthUnitDetails />}
			className={`relative`}
		>
			<div className="flex flex-col gap-2">
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
					<div className="lg:col-span-3 flex flex-col gap-4 h-[calc(100vh-204px)]">
						<div className="flex items-center gap-4">
							<h3 className="font-bold text-xl mb-0 mr-auto">
								Patient List
							</h3>

							<ButtonV3
								type="success"
								onClick={() => {
									setModalKeys(uuidv4());

									// history.push(`/sph/app/cis/create-patient`);
									// newPatientFormRef.current.show();
									setTimeout(() => {
										viewPrivacyRef.current.show();
									}, 100);
								}}
							>
								<FlatIcon icon="rr-plus" />
								Add
							</ButtonV3>
						</div>
						{pendings?.count > 0 ? (
							<div className="flex flex-col bg-yellow-50 p-3 -mx-4 gap-y-4 mb-2 rounded-xl">
								<div className="text-orange-600 font-semibold text-sm flex items-center gap-3 ">
									<span>
										Patients pending for medicine release
									</span>
									<div
										className={`relative h-full ${
											pendings?.count == 0 && hidden
										}`}
									>
										<div className="bg-red-500 rounded-full z-20 absolute text-white text-xs h-5  w-5 flex items-center justify-center">
											{pendings?.count}
										</div>

										<div className="bg-red-500 rounded-full z-10 absolute text-white text-xs h-5  w-5 flex items-center justify-center animate-ping"></div>
									</div>
								</div>
								{pendings?.data?.map((item) => {
									return (
										<CisPatientListItem
											key={`cpili-${item?.id}`}
											patient={item?.patient}
											onClick={() => {
												setSelectedClick(
													item?.patient
												);
											}}
											// onMouseEnter={() => {
											// 	setSelected(item?.patient);
											// }}
											// onMouseLeave={() => {
											// 	setSelected(null);
											// }}
										/>
									);
								})}
							</div>
						) : (
							""
						)}
						<div className="relative w-full flex items-center">
							<input
								type="text"
								placeholder="Search patient"
								className="bg-white rounded-xl !border !border-slate-300 py-2 pr-3 h-11 w-full pl-[48px] !duraton-200 text-sm"
								onChange={(e) => {
									setKeyword(e.target.value);
								}}
							/>
							<span className="absolute left-3 text-slate-300 text-base font-bold pr-2 !border-r flex items-center">
								<FlatIcon icon="rr-search" className=" " />
							</span>
						</div>

						<div className="flex flex-col gap-3 divide-y max-h-[calc(100vh-274)] overflow-auto">
							{loading ? (
								<div className="p-5 flex items-center justify-center bg-foreground animate-pulse rounded-xl">
									<b className="text-placeholder">
										Loading...
									</b>
								</div>
							) : list?.length == 0 ? (
								keyword?.length > 0 ? (
									<div className="p-5 flex items-center justify-center">
										<b className="text-placeholder">
											No results found for "{keyword}".
										</b>
									</div>
								) : (
									<div className="p-5 flex items-center justify-center">
										<b className="text-placeholder">
											No data to display.
										</b>
									</div>
								)
							) : (
								list?.map((x) => {
									return (
										<CisPatientListItem
											patient={x}
											onClick={() => {
												setSelectedClick(x);
											}}
											// onMouseEnter={() => {
											// 	if (!selectedClick?.id)
											// 		setSelected(x);
											// }}
											// onMouseLeave={() => {
											// 	if (!selectedClick?.id)
											// 		setSelected(null);
											// }}
										/>
									);
								})
							)}
						</div>

						<div className="">
							<Pagination
								page={page}
								setPage={setPage}
								pageCount={meta?.last_page}
								pageSize={paginate}
								setPageSize={setPaginate}
								paginationClassName="flex !flex-col !items-start"
							/>
						</div>
					</div>
					<div className="lg:col-span-9">
						{selectedClick ? (
							<BhsPatientProfile
								key={`bhs-${selectedClick?.id}`}
								patient={selectedClick}
								setPatient={updatePatient}
							/>
						) : selected == null ? (
							<div className="p-5 flex items-center justify-center text-placeholder-dark text-lg">
								{loading
									? "Loading, please wait..."
									: `Select a patient.`}
							</div>
						) : (
							<PatientProfileOverview patient={selected} />
						)}
					</div>
				</div>
			</div>{" "}
			<CreateNewPatientFormModal
				patientSelfie={patientSelfie}
				key={`CreateNewPatientFormModal-${modalKeys}`}
				ref={newPatientFormRef}
			/>
			<PrivacyStatementModal
				newPatient={true}
				key={`PrivacyStatementModal-${modalKeys}`}
				onSuccess={(data) => {
					newPatientFormRef.current.show(null);
				}}
				patient={patient}
				ref={viewPrivacyRef}
				patientSelfie={patientSelfie}
				setPatientSelfie={setPatientSelfie}
			/>
		</LayoutContainer>
	);
};

export default BhsPatients;
