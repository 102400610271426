import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
import PickMapLocation from "src/pho/components/Forms/PickMapLocation";
import { v4 as uuidv4 } from "uuid";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";
import { geolocations, locations } from "src/helpers/locations";
import TextInputField from "src/components/forms/TextInputField";
const ClinicFormModal = (props, ref) => {
	const [mapKey, setMapKey] = useState(`map-${uuidv4()}`);
	const { staticModal, updateInList, addToList } = props;
	const [open, setOpen] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const [provinceList, setProvinceList] = useState([]);
	const [municipalityList, setMunicipalityList] = useState([]);
	const [municipalities, setMunicipalities] = useState([]);
	const [brgys, setBrgys] = useState([]);
	const [purokList, setPurokList] = useState([]);

	const [pointVals, setPointVals] = useState([[6.0498006, 125.15]]);
	const [position, setPosition] = useState({
		lat: 6.0498006,
		lng: 125.15,
	});
	const btnRef = useRef(null);

	useEffect(() => {
		getLocations().then((res) => {
			setMunicipalities(res.data);
		});
	}, []);

	useEffect(() => {
		console.log("municipality_id", data?.municipality_id);
		let t = setTimeout(() => {
			setValue("municipality", data?.municipality_id);
			if (data?.municipality_id) {
				let found = municipalities.find(
					(x) => x.id == data?.municipality_id
				);
				if (found?.barangays?.length > 0) {
					setBrgys(found?.barangays);
				}
			}
		}, 200);
		return () => {
			clearTimeout(t);
		};
	}, [municipalities, data?.municipality_id]);

	useEffect(() => {
		let t = setTimeout(() => {
			setValue("barangay", data?.barangay_id);
		}, 200);
		return () => {
			clearTimeout(t);
		};
	}, [brgys, data?.barangay_id]);

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		control,
		watch,
		formState: { errors },
	} = useForm();

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const getLocations = () => {
		return axios.get("/v1/locations");
	};
	const show = (showData) => {
		if (showData?.id) {
			console.log("datadata showData", showData);
			setData(showData);
			setValue("name", showData?.name);
			setValue("street", showData?.street);
			setValue("lat", showData?.lat);
			setValue("lng", showData?.lng);
			setValue("type", showData?.type);
			setTimeout(() => {
				// setValue("purok_id", showData?.purok_id);
				// setValue("barangay_id", showData?.barangay_id);
				// setValue("municipality_id", showData?.municipality_id);
				// setPosition({
				// 	lat: showData?.lat,
				// 	lng: showData?.lng,
				// });
			}, 1000);
		} else {
			reset({
				name: "",
				color: "",
				type: "",
				radius: "",
			});
		}
		setTimeout(() => {
			setMapKey(`map-${uuidv4()}`);
		}, 500);
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = (form) => {
		setLoading(true);
		let formData = new FormData();
		formData.append("name", form?.name);
		formData.append("purok_id", form?.purok);
		formData.append("barangay_id", form?.barangay);
		formData.append("municipality_id", form?.municipality);
		formData.append("region", form?.region);
		formData.append("municipality", form?.municipality);
		formData.append("province", form?.province);
		formData.append("barangay", form?.barangay);
		formData.append("purok", form?.purok);
		formData.append("street", form?.street);
		formData.append("lat", form?.lat);
		formData.append("lng", form?.lng);
		formData.append("type", form?.type);
		formData.append("tuberculosis", 0);
		formData.append("hypertension", 0);
		formData.append("animal_bites", 0);

		let url = `/v1/management/clinic`;

		if (data?.id) {
			formData.append("_method", "PATCH");
			url = url + `/${data?.id}`;
		}

		axios
			.post(url, formData)
			.then((res) => {
				setTimeout(() => {
					toast.success("Clinic record updated successfully!");
					if (data?.id) {
						updateInList(res.data.data);
					} else {
						addToList(res.data.data);
					}
				}, 200);
				hide();
			})
			.finally(() => {
				setLoading(false);
				// hide();
			});
	};
	// useEffect(() => {
	// 	let timeout = setTimeout(() => {
	// 		if (watch("barangay")) {
	// 			getPurokList(watch("barangay"));
	// 		}
	// 	}, 400);
	// 	return () => {
	// 		clearTimeout(timeout);
	// 	};
	// }, [watch("barangay")]);
	// useEffect(() => {
	// 	setValue("purok", data?.purok_id);
	// }, [purokList]);
	// const getPurokList = (brgy_id) => {
	// 	axios.get(`v1/purok-by-barangay?barangay_id=${brgy_id}`).then((res) => {
	// 		setPurokList(res.data);
	// 	});
	// };
	return (
		<Modal
			open={open}
			hide={hide}
			className={`w-[768px] min-w-[768px] max-w-[768px]`}
			staticModal={staticModal}
		>
			<ModalHeader
				title={(data?.id ? "Edit " : "Add ") + `Clinic Form`}
				hide={hide}
			/>
			<ModalBody className={`p- bg-white !pb-6`}>
				<div className="p-4 flex flex-col">
					<div className="flex flex-col gap-y-5 w-full">
						<TextField
							label={
								<>
									Name
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("name", {
								required: true,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.name}
							helperText={
								errors?.name && "This field is required"
							}
						/>
						<TextField
							label={
								<>
									Clinic Type
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("type", {
								required: true,
							})}
							SelectProps={{ native: true }}
							InputLabelProps={{ shrink: true }}
							error={errors?.type}
							helperText={
								errors?.type && "This field is required"
							}
							select
						>
							<option value={""}>SELECT</option>
							<option value={"municipality"}>
								Municipal/City Clinic
							</option>
							<option value={"barangay"}>Barangay Clinic</option>
							<option value={"purok"}>Purok Clinic</option>
						</TextField>
					</div>
					{/* <div className="flex flex-col gap-3 pl-2 mt-3">
						<label className="flex items-center gap-3">
							<span className="scale-[1.15]">
								<input
									type="checkbox"
									{...register("tuberculosis")}
								/>
							</span>
							<span className="text-sm form-label">
								Tuberculosis
							</span>
						</label>
						<label className="flex items-center gap-3">
							<span className="scale-[1.15]">
								<input
									type="checkbox"
									{...register("hypertension")}
								/>
							</span>
							<span className="text-sm form-label">
								Hypertension
							</span>
						</label>
						<label className="flex items-center gap-3">
							<span className="scale-[1.15]">
								<input
									type="checkbox"
									{...register("animal_bites")}
								/>
							</span>
							<span className="text-sm form-label">
								Animal Bites
							</span>
						</label>
					</div> */}
				</div>
				<div className="flex flex-col gap-y-8 patient-form">
					{/* <LocationPicker
				pointMode={pointMode}
				zoom={10}
				startPort={"auto"}
				showControls={true}
				precision={7}
			/>
			 */}
					<div className="grid grid-cols-1 gap-6 px-6">
						<div className="flex flex-col gap-6">
							<h4 className="text-lg font-bold ">
								*Record your current location
							</h4>
							<TextField
								SelectProps={{ native: true }}
								InputLabelProps={{ shrink: true }}
								label={`Latitude (x.y °)`}
								variant="outlined"
								error={Boolean(errors?.lat?.message)}
								helperText={errors?.lat?.message}
								{...register("lat", {
									required: "This field is required.",
								})}
							/>
							<TextField
								SelectProps={{ native: true }}
								InputLabelProps={{ shrink: true }}
								label={`Longitude (x.y °)`}
								variant="outlined"
								error={Boolean(errors?.lng?.message)}
								helperText={errors?.lng?.message}
								{...register("lng", {
									required: "This field is required.",
								})}
							/>
						</div>
						<PickMapLocation
							key={mapKey}
							position={position}
							setPosition={(pos) => {
								setPosition({ lat: pos.lat, lng: pos.lng });
								setValue("lat", pos?.lat);
								setValue("lng", pos?.lng);
							}}
						/>
					</div>
					<div className="grid lg:grid-cols-12 grid-cols-1 gap-6 pb-6 px-6">
						<div className="lg:col-span-4">
							<Controller
								name="region"
								control={control}
								rules={{
									required: {
										value: true,
										message: "This field is required",
									},
								}}
								render={({
									field: {
										onChange,
										onBlur,
										value,
										name,
										ref,
									},
									fieldState: {
										invalid,
										isTouched,
										isDirty,
										error,
									},
								}) => (
									<ReactSelectInputField
										isClearable={false}
										label={
											<>
												Select Region
												<span className="text-danger ml-1">
													*
												</span>
											</>
										}
										inputClassName=" "
										ref={ref}
										value={value}
										onChange={onChange}
										onChangeGetData={(data) => {
											setProvinceList(
												Object.keys(
													data.province_list
												).map((key) => {
													return {
														name: key,
														...data.province_list[
															key
														],
													};
												})
											);
										}} // send value to hook form
										onBlur={onBlur} // notify when input is touched
										error={error?.message}
										placeholder="Select Province"
										options={Object.values(
											geolocations
										).map((loc) => ({
											value: loc?.region_name,
											label: loc?.region_name,
											province_list: loc?.province_list,
										}))}
									/>
								)}
							/>
						</div>
						<div className="lg:col-span-4">
							<Controller
								name="province"
								control={control}
								rules={{
									required: {
										value: true,
										message: "This field is required",
									},
								}}
								render={({
									field: {
										onChange,
										onBlur,
										value,
										name,
										ref,
									},
									fieldState: {
										invalid,
										isTouched,
										isDirty,
										error,
									},
								}) => (
									<ReactSelectInputField
										isClearable={false}
										label={
											<>
												Select Province
												<span className="text-danger ml-1">
													*
												</span>
											</>
										}
										inputClassName=" "
										ref={ref}
										value={value}
										onChange={onChange} // send value to hook form
										onChangeGetData={(data) => {
											setMunicipalityList(
												Object.keys(
													data.municipality_list
												).map((key) => {
													return {
														name: key,
														...data
															.municipality_list[
															key
														],
													};
												})
											);
										}}
										onBlur={onBlur} // notify when input is touched
										error={error?.message}
										placeholder="Select Province"
										options={provinceList.map(
											(province) => ({
												label: province?.name,
												value: province?.name,
												municipality_list:
													province?.municipality_list,
											})
										)}
									/>
								)}
							/>
						</div>
						<div className="lg:col-span-4">
							<Controller
								name="municipality"
								control={control}
								rules={{
									required: {
										value: true,
										message: "This field is required",
									},
								}}
								render={({
									field: {
										onChange,
										onBlur,
										value,
										name,
										ref,
									},
									fieldState: {
										invalid,
										isTouched,
										isDirty,
										error,
									},
								}) => (
									<ReactSelectInputField
										isClearable={false}
										label={
											<>
												Select Municipality
												<span className="text-danger ml-1">
													*
												</span>
											</>
										}
										placeholder="Select Municipality"
										inputClassName="normal-case"
										ref={ref}
										value={value}
										onChange={(data) => {
											let selected_ = locations?.find(
												(x) =>
													String(
														x.name
													).toLowerCase() ==
													String(data).toLowerCase()
											);
											console.log(
												"selected_selected_",
												String(data).toLowerCase(),
												selected_
											);
											// setBrgys(
											// 	selected_?.barangays
											// );
											setValue(
												"zip_code",
												selected_?.zipcode || ""
											);
											onChange(data);
										}} // send value to hook form
										onChangeGetData={(data) => {
											setBrgys(
												data.barangay_list.map(
													(key) => {
														return {
															name: key,
														};
													}
												)
											);
										}}
										onBlur={onBlur} // notify when input is touched
										error={error?.message}
										options={municipalityList.map(
											(municipality) => ({
												label: municipality?.name,
												value: municipality?.name,
												barangay_list:
													municipality?.barangay_list,
											})
										)}
									/>
								)}
							/>
						</div>
						{watch("type") != "municipality" &&
						watch("type") != "" ? (
							<>
								<div className="lg:col-span-4">
									<Controller
										name="barangay"
										control={control}
										rules={{
											required: {
												value: true,
												message:
													"This field is required",
											},
										}}
										onChange={(data) => {}}
										render={({
											field: {
												onChange,
												onBlur,
												value,
												name,
												ref,
											},
											fieldState: {
												invalid,
												isTouched,
												isDirty,
												error,
											},
										}) => (
											<ReactSelectInputField
												isClearable={false}
												label={
													<>
														Select Barangay
														<span className="text-danger ml-1">
															*
														</span>
													</>
												}
												inputClassName=" "
												ref={ref}
												value={value}
												onChange={(data) => {
													let selected_ = brgys?.find(
														(x) => x.name == data
													);
													setPurokList(
														selected_?.puroks
													);
													onChange(data);
												}} // send value to hook form
												onBlur={onBlur} // notify when input is touched
												error={error?.message}
												placeholder="Select Barangay"
												options={brgys.map((data) => ({
													label: data?.name,
													value: data?.name,
												}))}
											/>
										)}
									/>
								</div>
								<TextInputField
									className="lg:col-span-4"
									label={<>Purok</>}
									placeholder="Input purok"
									{...register("purok", {
										required: {
											value: false,
										},
									})}
									error={errors?.purok?.message}
								/>
								<TextInputField
									className="lg:col-span-4"
									label={<>Street</>}
									placeholder="Input street"
									{...register("street", {
										required: {
											value: false,
											// message: "This field is required",
										},
									})}
									error={errors?.street?.message}
								/>
							</>
						) : (
							""
						)}
					</div>
				</div>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<Button
					onClick={handleSubmit(submit)}
					loading={loading}
					ref={btnRef}
				>
					Submit
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(ClinicFormModal);
