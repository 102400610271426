import {
	Dialog,
	DialogActions,
	DialogContent,
	Button,
	CardMedia,
	CardHeader,
	Box,
	Typography,
	Grid,
} from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import Notify from "src/notification/Notify";
import axios from "axios";
import ClinicalMicroscopy from "../orderform/ClinicalMicroscopy";
import Hemathology from "../orderform/Hemathology";
import SerologyOrder from "../orderform/SerologyOrder";
import ChemistryOrder from "../orderform/ChemistryOrder";
import FecalOrder from "../orderform/FecalOrder";
// import Footer from "src/laboratory/print/Footer";
import { UsersData } from "src/ContextAPI";
import UrinalysisOrder from "../orderform/UrinalysisOrder";
import ECGOrder from "../orderform/ECGOrder";
import MedicalExamOrder from "../orderform/MedicalExamOrder";
import PapsmearOrder from "../orderform/PapsmearOrder";
import OralGlucoseOrder from "../orderform/OralGlucoseOrder";
import MiscellaneousOrder from "../orderform/MiscellaneousOrder";
import HepatitisOrder from "../orderform/HepatitisOrder";
import ThyroidProfileOrder from "../orderform/ThyroidProfileOrder";
import StooltestOrder from "../orderform/StooltestOrder";
import ImmunologyOrder from "../orderform/ImmunologyOrder";
import CBCOrderForm from "../orderform/CBCOrderForm";
import CovidTestOrder from "../orderform/CovidTestOrder";
import TumorMakerOrder from "../orderform/TumorMakerOrder";
import DrugTestOrder from "../orderform/DrugTestOrder";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const PrintableOrder = ({ labownerid, order_id, patient_id, close }) => {
	const { users } = React.useContext(UsersData);

	const [printHeader, setPrintHeader] = useState([]);
	const [patientInfo, setPatientInfo] = useState([]);

	const getLaboratoryPrintHeader = useCallback(async () => {
		try {
			var formdata = new FormData();
			formdata.set("labownermngid", labownerid);
			formdata.set("user_id", users.user_id);
			formdata.set("patient_id", patient_id);

			const params = {
				labownermngid: labownerid,
				user_id: users.user_id,
				patient_id: patient_id,
			};

			const prHeader = await axios.get(
				"doctor/laboratory/order/printable/printheader",
				{ params }
			);
			setPrintHeader(prHeader.data);

			const patient = await axios.get("doctor/patient/patient-information", {
				params,
			});
			setPatientInfo(patient.data);
		} catch (error) {
			Notify.requestError(error);
		}
	}, [labownerid, users, patient_id]);

	useEffect(() => {
		getLaboratoryPrintHeader();

		// eslint-disable-next-line
	}, [getLaboratoryPrintHeader]);

	return (
		<>
			<Dialog open={Boolean(order_id)} onClose={close} fullScreen>
				<DialogContent>
					{
						//print header
						Object.keys(printHeader).length > 0 && (
							<Box display="flex" justifyContent="center">
								<Box mt={2}>
									{printHeader && (
										<CardMedia
											style={{ width: 70 }}
											component={"img"}
											src={`${imageLocation}laboratory/logo/${printHeader.logo}`}
										/>
									)}
								</Box>
								<Box>
									<CardHeader
										component={Box}
										align="center"
										title={printHeader && printHeader.name}
										subheader={
											<Box>
												<Typography>
													{printHeader && printHeader.address}
												</Typography>
												<Typography>
													{printHeader && printHeader.contact_number}
												</Typography>
											</Box>
										}
									/>
								</Box>
							</Box>
						)
					}

					<Box>
						{patientInfo.length > 0 && <Header details={patientInfo[0]} />}
					</Box>

					<Box>
						<Hemathology
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<ClinicalMicroscopy
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<SerologyOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<ChemistryOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<FecalOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<ECGOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<UrinalysisOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<MedicalExamOrder order_id={order_id} patient_id={patient_id} />
						<PapsmearOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<OralGlucoseOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<MiscellaneousOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<HepatitisOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<ThyroidProfileOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<StooltestOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<ImmunologyOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<CBCOrderForm
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<CovidTestOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<TumorMakerOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
						<DrugTestOrder
							order_id={order_id}
							patient_id={patient_id}
							formheader={printHeader}
						/>
					</Box>

					{/* <Box>{printHeader && <Footer formheader={printHeader} />}</Box> */}
				</DialogContent>

				<DialogActions className={`d-print-none`}>
					<Button variant="contained" color="inherit" onClick={close}>
						Cancel
					</Button>

					<Button
						variant="contained"
						color="primary"
						onClick={() => window.print()}
					>
						Print
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default PrintableOrder;

const Header = ({ details }) => {
	return (
		<>
			{/* paitent information */}
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<Box>
						<Box mb={1}>
							<Typography>
								<Typography variant="caption" className="font-weight-bold">
									PATIENT NAME:{" "}
								</Typography>
								<span className="text-capitalize">
									{`${details.lastname}, ${details.firstname} ${
										details.middle !== null ? details.middle : null
									}`}
								</span>
							</Typography>
						</Box>

						<Box display="flex" mb={1}>
							<Box>
								<Typography>
									<Typography variant="caption" className="font-weight-bold">
										AGE:{" "}
									</Typography>
									{details.birthday === null
										? "None"
										: Notify.calculateAge(details.birthday)}
								</Typography>
							</Box>
							<Box ml={5}>
								<Typography>
									<Typography variant="caption" className="font-weight-bold">
										GENDER:{" "}
									</Typography>
									{details.gender === null ? "None" : details.gender}
								</Typography>
							</Box>
						</Box>

						<Box mb={1}>
							<Typography>
								<Typography variant="caption" className="font-weight-bold">
									PATIENT ADDRESS:{" "}
								</Typography>
								<span className="text-capitalize">
									{`${details.street} ${details.barangay} ${details.city} `}
								</span>
							</Typography>
						</Box>

						<Box mb={1}>
							<Typography>
								<Typography variant="caption" className="font-weight-bold">
									DATE:{" "}
								</Typography>
								{Notify.createdAt(details.created_at)}
							</Typography>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Box display="flex" justifyContent="center">
						<Box flexGrow={1} />
						<Box>
							{details.image !== null ? (
								<CardMedia
									style={{ width: 120 }}
									component={"img"}
									src={`${imageLocation}patients/${details.image}`}
								/>
							) : (
								<CardMedia
									style={{ width: 110 }}
									component={"img"}
									src={`${imageLocation}doctor/no-image.jpg`}
								/>
							)}
						</Box>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};
