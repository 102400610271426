import React from 'react'
import { Box, LinearProgress, Typography } from '@material-ui/core';


const CheckingConnection = () => (
    <Box m={2} align="center">
        <Box>
            <LinearProgress />
            <Typography color="primary" variant="caption"> checking connection, please wait... </Typography>
        </Box>
    </Box>
)

export default CheckingConnection
