import React, { useState, useEffect, Fragment, useContext } from "react";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "src/utils/TabPanel";
import { Grid, makeStyles, Paper, Badge } from "@material-ui/core";
import Axios from "axios";
import { UsersData } from "src/ContextAPI";
import NSRXUnsavePrescription from "./NSRXUnsavePrescription";
import NSRXPrescription from "./NSRXPrescription";
import NSRXPrescriptionList from "./NSRXPrescriptionList";

const useStyle = makeStyles({
	indicatorLeft: {
		left: "0px",
	},
});

const NSPrescriptionTabs = ({ patient_id }) => {
	const classes = useStyle();
	const [category, setCategory] = useState("list");
	const [unsaveCount, setUnsaveCount] = useState(0);
	const { users } = useContext(UsersData);

	const handleChange = (event, newcategory) => {
		event.persist();
		setCategory(newcategory);
	};

	const getUnsavePrescription = async () => {
		try {
			var formdata = new FormData();
			formdata.set("user_id", users.user_id);
			formdata.set("patient_id", patient_id);
			formdata.set("connection", "local");

			const response = await Axios.post(
				"prescription/unsave/get-countby-presctype",
				formdata
			);
			const data = response.data;
			setUnsaveCount(data.length);
		} catch (error) {
			console.log("unable to fetch count by presc", error);
		}
	};

	useEffect(() => {
		getUnsavePrescription();
		// eslint-disable-next-line
	}, [patient_id]);

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Box clone order={{ xs: 2, sm: 2, md: 1 }}>
					<Grid item xs={12} sm={12} md={9} lg={10}>
						<Box p={2} component={Paper} elevation={2}>
							<TabPanel value={category} index={"list"}>
								<NSRXPrescriptionList patient_id={patient_id} />
							</TabPanel>

							<TabPanel value={category} index={"doctor"}>
								<NSRXPrescription
									unsaveCount={unsaveCount}
									getUnsavePrescription={() => getUnsavePrescription()}
									patient_id={patient_id}
									displayUnsave={() => setCategory("unsave")}
								/>
							</TabPanel>

							<TabPanel value={category} index={"unsave"}>
								<NSRXUnsavePrescription
									patient_id={patient_id}
									getUnsavePrescription={() => getUnsavePrescription()}
								/>
							</TabPanel>
						</Box>
					</Grid>
				</Box>
				<Box clone order={{ xs: 1, sm: 1, md: 2 }}>
					<Grid item xs={12} sm={12} md={3} lg={2}>
						<Paper elevation={2}>
							<Tabs
								value={category}
								onChange={handleChange}
								indicatorColor="primary"
								textColor="primary"
								scrollButtons="on"
								variant="scrollable"
								orientation={
									window.innerWidth > 959 ? "vertical" : "horizontal"
								}
								classes={{
									indicator: classes.indicatorLeft,
								}}
							>
								<Tab value="list" wrapped label="Presc. List" />
								<Tab value="doctor" wrapped label="Create Presc." />
								<Tab
									value="unsave"
									wrapped
									label={
										<Badge color="secondary" badgeContent={unsaveCount}>
											Unsave Presc
										</Badge>
									}
								/>
							</Tabs>
						</Paper>
					</Grid>
				</Box>
			</Grid>
		</Fragment>
	);
};

export default NSPrescriptionTabs;
