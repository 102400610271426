import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";

const ExpenseAllList = () => {
  const { users } = useContext(UsersData);
  const [expenseList, setExpenseList] = useState({ data: [], ready: false });
  const getAllExpense = useCallback(async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      management_name: users.management_name,
    };
    try {
      let response = await axios.get("accounting/get/expense-all-noo-group", {
        params,
      });
      const data = response.data;
      setExpenseList({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users]);

  useEffect(() => {
    getAllExpense();
  }, [getAllExpense]);

  return (
    <Box>
      <Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box flexGrow={1}>
            <Typography
              color="primary"
              variant={"subtitle1"}
              style={{ fontWeight: "bolder" }}
            >
              EXPENSE LIST
            </Typography>
          </Box>
        </Box>
        <Box>
          <TableContainer>
            <Table id="expense-report">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    INVOICE DATE
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    VOUCHER
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    DESCRIPTION
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    TIN
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    QUANTITY
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    UNIT PRICE
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    TAX
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bolder" }}>
                    AMOUNT
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expenseList.ready ? (
                  expenseList.data.length > 0 ? (
                    expenseList.data.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {Notify.createdAt(data.invoice_date)}
                        </TableCell>
                        <TableCell>{data.voucher}</TableCell>
                        <TableCell>{data.description}</TableCell>
                        <TableCell>{data.tin}</TableCell>
                        <TableCell align="right">{data.quantity}</TableCell>
                        <TableCell align="right">
                          {Notify.convertToNumber(data.unit_price)}
                        </TableCell>
                        <TableCell>{data.tax}</TableCell>
                        <TableCell align="right">
                          {Notify.convertToNumber(data.amount)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={8}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={8}>
                      <Typography color="primary">
                        Loading, Please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default ExpenseAllList;
