import React from "react";
export default class LaboratoryOrderOtherTest extends React.Component {
  static dept = () => {
    var orders = [
      {
        label: "Other Test",
        value: "Other Test",
      },
    ];

    return orders;
  };

  static list = () => {
    var orders = [
      // other test / test without form
      {
        department: "Other Test",
        label: "Eye Refraction",
        value: "Eye Refraction",
      },

      {
        department: "Other Test",
        label: "Psychological Test",
        value: "Psychological Test",
      },

      {
        department: "Other Test",
        label: "Visual Acuity",
        value: "Visual Acuity",
      },

      {
        department: "Other Test",
        label: "Dental",
        value: "Dental",
      },

      {
        department: "Other Test",
        label: "D.R.E",
        value: "D.R.E",
      },

      {
        department: "Other Test",
        label: "DRESSING",
        value: "DRESSING",
      },

      {
        department: "Other Test",
        label: "Physical Examination",
        value: "Physical Examination",
      },

      {
        department: "Other Test",
        label: "Medical Certificate",
        value: "Medical Certificate",
      },

      //////////////////////////////////qweqweqweqweeeeeeeeeeeeeeeeeeeeeeeeeeee//////////////////////////////////////

      {
        department: "Other Test",
        label: "T3, T4, TSH",
        value: "T3, T4, TSH",
      },
      {
        department: "Other Test",
        label: "T3",
        value: "T3",
      },
      {
        department: "Other Test",
        label: "T3T4",
        value: "T3T4",
      },
      {
        department: "Other Test",
        label: "T4",
        value: "T4",
      },
      {
        department: "Other Test",
        label: "TSH",
        value: "TSH",
      },
      {
        department: "Other Test",
        label: "FHT",
        value: "FHT",
      },
      {
        department: "Other Test",
        label: "FT3",
        value: "FT3",
      },
      {
        department: "Other Test",
        label: "FT4",
        value: "FT4",
      },
      {
        department: "Other Test",
        label: "ANTI-HBC IGM",
        value: "ANTI-HBC IGM",
      },
      {
        department: "Other Test",
        label: "HCV",
        value: "HCV",
      },
      {
        department: "Other Test",
        label: "BETA HCG (QUALI)",
        value: "BETA HCG (QUALI)",
      },
      {
        department: "Other Test",
        label: "H-PYLORI",
        value: "H-PYLORI",
      },
      {
        department: "Other Test",
        label: "VDRL / SYPHILIS TEST",
        value: "VDRL / SYPHILIS TEST",
      },
      {
        department: "Other Test",
        label: "HACT",
        value: "HACT",
      },
      {
        department: "Other Test",
        label: "ANA",
        value: "ANA",
      },
      {
        department: "Other Test",
        label: "DENGUE TEST",
        value: "DENGUE TEST",
      },
      {
        department: "Other Test",
        label: "ANTI-HAV IGM",
        value: "ANTI-HAV IGM",
      },
      {
        department: "Other Test",
        label: "ANTI-HBC(IGM)",
        value: "ANTI-HBC(IGM)",
      },
      {
        department: "Other Test",
        label: "ANTI-HAV IGM/IGG",
        value: "ANTI-HAV IGM/IGG",
      },
      {
        department: "Other Test",
        label: "ANTI-HBE",
        value: "ANTI-HBE",
      },
      {
        department: "Other Test",
        label: "ANTI-HBS(QUALI)",
        value: "ANTI-HBS(QUALI)",
      },
      {
        department: "Other Test",
        label: "ANTI-HBS(QUANTI)",
        value: "ANTI-HBS(QUANTI)",
      },
      {
        department: "Other Test",
        label: "ANTI-HCV",
        value: "ANTI-HCV",
      },
      {
        department: "Other Test",
        label: "HBEAG",
        value: "HBEAG",
      },
      {
        department: "Other Test",
        label: "HBSAG (QUALI)",
        value: "HBSAG (QUALI)",
      },
      {
        department: "Other Test",
        label: "HBSAG (QUANTI)",
        value: "HBSAG (QUANTI)",
      },
      {
        department: "Other Test",
        label: "ASO",
        value: "ASO",
      },
      {
        department: "Other Test",
        label: "BIOPSY",
        value: "BIOPSY",
      },
      {
        department: "Other Test",
        label: "C3",
        value: "C3",
      },
      {
        department: "Other Test",
        label: "CA 125",
        value: "CA 125",
      },
      {
        department: "Other Test",
        label: "CEA",
        value: "CEA",
      },
      {
        department: "Other Test",
        label: "PSA (PROSTATE)",
        value: "PSA (PROSTATE)",
      },
      {
        department: "Other Test",
        label: "AFP",
        value: "AFP",
      },
      {
        department: "Other Test",
        label: "BLOOD TYPING W/RH",
        value: "BLOOD TYPING W/RH",
      },
      {
        department: "Other Test",
        label: "CT/BT",
        value: "CT/BT",
      },
      {
        department: "Other Test",
        label: "ESR",
        value: "ESR",
      },
      {
        department: "Other Test",
        label: "FERRITIN",
        value: "FERRITIN",
      },
      {
        department: "Other Test",
        label: "CBC WITH PLATELET",
        value: "CBC WITH PLATELET",
      },
      {
        department: "Other Test",
        label: "HEMATOCRIT",
        value: "HEMATOCRIT",
      },
      {
        department: "Other Test",
        label: "HEMOGLOBIN",
        value: "HEMOGLOBIN",
      },
      {
        department: "Other Test",
        label: "APTT",
        value: "APTT",
      },
      {
        department: "Other Test",
        label: "PERIPHERAL SMEAR",
        value: "PERIPHERAL SMEAR",
      },
      {
        department: "Other Test",
        label: "PLATELET COUNT",
        value: "PLATELET COUNT",
      },
      {
        department: "Other Test",
        label: "PROTIME",
        value: "PROTIME",
      },
      {
        department: "Other Test",
        label: "FBS",
        value: "FBS",
      },
      {
        department: "Other Test",
        label: "OGCT 1 TAKE (50 GRM)",
        value: "OGCT 1 TAKE (50 GRM)",
      },
      {
        department: "Other Test",
        label: "OGCT 1 TAKE (75 GRM)",
        value: "OGCT 1 TAKE (75 GRM)",
      },
      {
        department: "Other Test",
        label: "OGTT 2 TAKE (100 GRM)",
        value: "OGTT 2 TAKE (100 GRM)",
      },
      {
        department: "Other Test",
        label: "OGTT 2 TAKE (75 GRM)",
        value: "OGTT 2 TAKE (75 GRM)",
      },
      {
        department: "Other Test",
        label: "OGTT 3 TAKE (100 GRM)",
        value: "OGTT 3 TAKE (100 GRM)",
      },
      {
        department: "Other Test",
        label: "OGTT 4 TAKE (100 GRM)",
        value: "OGTT 4 TAKE (100 GRM)",
      },
      {
        department: "Other Test",
        label: "CREATININE",
        value: "CREATININE",
      },
      {
        department: "Other Test",
        label: "SERUM URIC ACID",
        value: "SERUM URIC ACID",
      },
      {
        department: "Other Test",
        label: "CHOLESTEROL",
        value: "CHOLESTEROL",
      },
      {
        department: "Other Test",
        label: "TRIGLYCERIDES",
        value: "TRIGLYCERIDES",
      },
      {
        department: "Other Test",
        label: "HDL CHOLESTEROL",
        value: "HDL CHOLESTEROL",
      },
      {
        department: "Other Test",
        label: "LIPID PROFILE",
        value: "LIPID PROFILE",
      },
      {
        department: "Other Test",
        label: "BUN",
        value: "BUN",
      },
      {
        department: "Other Test",
        label: "LDH",
        value: "LDH",
      },
      {
        department: "Other Test",
        label: "ALKALINE PHOSPHATASE",
        value: "ALKALINE PHOSPHATASE",
      },
      {
        department: "Other Test",
        label: "SGOT/AST",
        value: "SGOT/AST",
      },
      {
        department: "Other Test",
        label: "SGPT/ALT",
        value: "SGPT/ALT",
      },
      {
        department: "Other Test",
        label: "TPAG RATIO",
        value: "TPAG RATIO",
      },
      {
        department: "Other Test",
        label: "BILIRUBIN (TOTAL/DIRECT)",
        value: "BILIRUBIN (TOTAL/DIRECT)",
      },
      {
        department: "Other Test",
        label: "TOTAL PROTEIN",
        value: "TOTAL PROTEIN",
      },
      {
        department: "Other Test",
        label: "ALBUMIN",
        value: "ALBUMIN",
      },
      {
        department: "Other Test",
        label: "SODIUM (NA+)",
        value: "SODIUM (NA+)",
      },
      {
        department: "Other Test",
        label: "POTASSIUM (K+)",
        value: "POTASSIUM (K+)",
      },
      {
        department: "Other Test",
        label: "NA+/K+",
        value: "NA+/K+",
      },
      {
        department: "Other Test",
        label: "HBA1C",
        value: "HBA1C",
      },
      {
        department: "Other Test",
        label: "CALCIUM",
        value: "CALCIUM",
      },

      {
        department: "Other Test",
        label: "MAGNESIUM",
        value: "MAGNESIUM",
      },
      {
        department: "Other Test",
        label: "CHLORIDE",
        value: "CHLORIDE",
      },
      {
        department: "Other Test",
        label: "CHOLINESTERASE",
        value: "CHOLINESTERASE",
      },
      {
        department: "Other Test",
        label: "LDL",
        value: "LDL",
      },

      {
        department: "Other Test",
        label: "GGT",
        value: "GGT",
      },
      {
        department: "Other Test",
        label: "PHOSPHORUS",
        value: "PHOSPHORUS",
      },
      {
        department: "Other Test",
        label: "RBS",
        value: "RBS",
      },
      {
        department: "Other Test",
        label: "VLDL",
        value: "VLDL",
      },
      {
        department: "Other Test",
        label: "RBC CHOLINESTERES",
        value: "RBC CHOLINESTERES",
      },
      {
        department: "Other Test",
        label: "CRP",
        value: "CRP",
      },
      {
        department: "Other Test",
        label: "PRO CALCITONIN",
        value: "PRO CALCITONIN",
      },
      {
        department: "Other Test",
        label: "PAPSMEAR",
        value: "PAPSMEAR",
      },
      {
        department: "Other Test",
        label: "PAPSMEAR with GRAMSTAIN",
        value: "PAPSMEAR with GRAMSTAIN",
      },
      {
        department: "Other Test",
        label: "MICRAL TEST",
        value: "MICRAL TEST",
      },
      {
        department: "Other Test",
        label: "SEMENALYSIS",
        value: "SEMENALYSIS",
      },
      {
        department: "Other Test",
        label: "URINALYSIS",
        value: "URINALYSIS",
      },
      {
        department: "Other Test",
        label: "FECALYSIS",
        value: "FECALYSIS",
      },
      {
        department: "Other Test",
        label: "OCCULT BLOOD",
        value: "OCCULT BLOOD",
      },
      {
        department: "Other Test",
        label: "DRUG TEST (2 PANELS)",
        value: "DRUG TEST (2 PANELS)",
      },
      {
        department: "Other Test",
        label: "DRUG TEST (3 PANELS)",
        value: "DRUG TEST (3 PANELS)",
      },
      {
        department: "Other Test",
        label: "DRUG TEST (5 PANELS)",
        value: "DRUG TEST (5 PANELS)",
      },
      {
        department: "Other Test",
        label: "COVID RAPID TEST - ANTIBODY",
        value: "COVID RAPID TEST - ANTIBODY",
      },
      {
        department: "Other Test",
        label: "COVID RAPID TEST - ANTIGEN",
        value: "COVID RAPID TEST - ANTIGEN",
      },

      //new
      {
        department: "Other Test",
        label: "2D ECHO ADULT",
        value: "2D ECHO ADULT",
      },
      {
        department: "Other Test",
        label: "2D ECHO PEDIA",
        value: "2D ECHO PEDIA",
      },
      {
        department: "Other Test",
        label: "ARTERIAL DUPLEX SCAN",
        value: "ARTERIAL DUPLEX SCAN",
      },
      {
        department: "Other Test",
        label: "CAROTID DUPLEX SCAN",
        value: "CAROTID DUPLEX SCAN",
      },
      {
        department: "Other Test",
        label: "VENOUS DUPLEX SCAN",
        value: "VENOUS DUPLEX SCAN",
      },
      {
        department: "Other Test",
        label: "ECG",
        value: "ECG",
      },
      {
        department: "Other Test",
        label: "SARS-CoV-2 Antigen",
        value: "SARS-CoV-2 Antigen",
      },

      {
        department: "Other Test",
        label: "Sputum",
        value: "Sputum",
      },
      {
        department: "Other Test",
        label: "Ishihara Test",
        value: "Ishihara Test",
      },
      {
        department: "Other Test",
        label: "Audiometry",
        value: "Audiometry",
      },
      {
        department: "Other Test",
        label: "RT-CPR (RHINOSTIC NASOPHARYNGEL SWAB)",
        value: "RT-CPR (RHINOSTIC NASOPHARYNGEL SWAB)",
      },
      {
        department: "Other Test",
        label: "RT-CPR (VTM SWAB)",
        value: "RT-CPR (VTM SWAB)",
      },
      {
        department: "Other Test",
        label: "Covid Rapid Test - Administration only w/kits",
        value: "Covid Rapid Test - Administration only w/kits",
      },
      {
        department: "Other Test",
        label: "CA19-9",
        value: "CA19-9",
      },
      {
        department: "Other Test",
        label: "H.Pylori Fecalysis",
        value: "H.Pylori Fecalysis",
      },
      {
        department: "Other Test",
        label: "IUD Removal",
        value: "IUD Removal",
      },
      {
        department: "Other Test",
        label: "Implant Removal",
        value: "Implant Removal",
      },
    ];

    return orders;
  };
}
