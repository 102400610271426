import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import LayoutContainer from "src/components/LayoutContainer";
import TabGroup from "src/components/TabGroup";
import { UsersData } from "src/ContextAPI";
import { calculateAgeV2, formatDate, patientFullName } from "src/helpers/utils";
import axios from "axios";
import { useParams } from "react-router-dom";
import Img from "src/components/Img";
import ClinicPrescription from "src/clinic/components/ClinicPrescription";
import ClinicDiagnosis from "src/clinic/components/ClinicDiagnosis";
import ClinicMedication from "src/clinic/components/ClinicMedication";
import ClinicProfileVitals from "src/clinic/components/ClinicProfileVitals";
import ClinicProfileVitalsForm from "src/clinic/components/ClinicProfileVitalsForm";
import DateTimeClock from "src/clinic/components/DateTimeClock";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import PrescriptionMainTab from "src/cis/components/patient-profile/prescriptions/PrescriptionMainTab";
import ProfileConsultations from "src/cis/components/patient-profile/consultations/ProfileConsultations";
import LaboratoryPage from "./components/patient-profile/LaboratoryPage";
import CisPatientsForm from "./components/profile/CisPatientsForm";
import CisPatientHistory from "./components/history/CisPatientHistory";
import CisVitalsChart from "./components/vitalsChart/CisVitalsChart";
import CisNotes from "./components/notes/CisNotes";
import CisAppointments from "./components/appointment/CisAppointments";
import CisTreatmentPlan from "./components/treatmentPlan/CisTreatmentPlan";
import MedicalCertificateList from "./components/patient-profile/medical-certificate/MedicalCertificateList";
import CisPatientImaging from "./components/imaging/CisPatientsImaging";
import CisPatientLaboratory from "./components/laboratory/CisPatientLaboratory";
import CisPatientHealth from "./components/health/CisPatientHealth";
import { Button } from "@material-ui/core";
import AddToQueue from "./components/utils/AddToQueue";
import PatientReferral from "./components/utils/PatientReferral";
import TbProgramReferral from "./components/utils/TbProgramReferral";
import AcceptPatient from "./components/utils/AcceptPatient";
import ButtonV3 from "src/components/ButtonV3";
import MarkAsDone from "./components/utils/MarkAsDone";
import CisAcceptPatientModal from "./components/modals/CisAcceptPatientModal";
import ClinicInfo from "./components/ClinicInfo";

const MenuTitle = ({ src, children }) => {
	return (
		<b className="text-base gap-2 flex flex-col items-center justify-center min-w-[148px]">
			<img src={src} className="h-11 w-11 object-contain" />
			{children}
		</b>
	);
};

export default function CisPatientProfile() {
	const userContext = useContext(UsersData);
	const {
		register,
		formState: { errors },
	} = useForm();
	const { users } = userContext;
	const name = users?.type;
	const accept_patient_modal = useRef(null);
	const { clinic, inServicePatients, registeringPatients } =
		useClinicContext();

	const [status, setStatus] = useState();
	const [patient, setPatient] = useState(null);
	const { id } = useParams();
	const [isProgramOpen, setProgramOpen] = useState(false);
	const [isQueueOpen, setQueueOpen] = useState(false);
	const [isPatientOpen, setPatientOpen] = useState(false);
	const [isAcceptOpen, setAcceptOpen] = useState(false);
	const [isDoneOpen, setDoneOpen] = useState(false);
	const isPatientInReg = () => {
		return registeringPatients.find((x) => x.patient_id == patient?.id);
	};
	const isPatientInService = () => {
		console.log("inServicePatients", inServicePatients);
		return inServicePatients.find((x) => x.patient_id == patient?.id);
	};
	useEffect(() => {
		if (id) {
			getPatient(id);
		}
	}, [id]);
	const getPatient = () => {
		axios.get(`/v1/patients/${id}`).then((res) => {
			setPatient(res.data.data);
		});
	};
	let forDoctors = [];

	useEffect(() => {
		axios
			.get(`v1/clinic-queueing/show/${id}`)
			.then(function (response) {
				setStatus(response.data);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.finally(function () {
				// always executed
			});
	}, []);

	if (users?.type == "Clinic-Doctor") {
		forDoctors = [
			{
				title: (
					<b className="text-base bgap-3 flex flex-col items-center justify-center">
						<img
							src="/vitals/prescription.png"
							className="h-11 w-11 object-contain"
						/>
						Prescription
					</b>
				),
				content: <ClinicPrescription patient={patient} />,
			},
			{
				title: (
					<b className="text-base bgap-3 flex flex-col items-center justify-center">
						<img
							src="/vitals/diagnosis-2.png"
							className="h-11 w-11 object-contain"
						/>
						Diagnosis
					</b>
				),
				content: <ClinicDiagnosis patient={patient} />,
			},
			{
				title: (
					<b className="text-base bgap-3 flex flex-col items-center justify-center">
						<img
							src="/vitals/medication.png"
							className="h-11 w-11 object-contain"
						/>
						Medication
					</b>
				),
				content: <ClinicMedication patient={patient} />,
			},
		];
	}

	const handleOpenPopup = (id) => {
		if (id === "queue") {
			setQueueOpen(true);
		}
		if (id === "patient") {
			setPatientOpen(true);
		}
		if (id === "program") {
			setProgramOpen(true);
		}
		if (id === "accept") {
			setAcceptOpen(true);
		}
		if (id === "done") {
			setDoneOpen(true);
		}
	};
	const handleClosePopup = () => {
		setTimeout(() => {
			getPatient(id);
			isPatientInReg();
			isPatientInService();
			setPatientOpen(false);
			setQueueOpen(false);
			setProgramOpen(false);
			setAcceptOpen(false);
			setDoneOpen(false);
		}, 100);
	};

	console.log(patient);
	return (
		<LayoutContainer
			icon={<FontAwesomeIcon icon={faUser} color="white" size={"2x"} />}
			title={<h2 className="text-2xl font-bold mb-0">Patient Profile</h2>}
			subtitle={clinic?.name || "Clinic"}
			titleChildren={<ClinicInfo />}
		>
			<div className="flex flex-col gap-y-5 w-full mx-auto bg-white rounded-xl p-4 min-h-[">
				<div className="flex flex-col justify-center items-center gap-4">
					<div className="grid grid-cols-1 lg:grid-cols-12 gap-6 w-full">
						<div className="flex flex-col col-span-1 lg:col-span-4 xl:col-span-3 items-center">
							<div className="bg-gray-200 mb-2 w-[128px] h-[128px] lg:w-[144px] lg:h-[144px]  rounded-full relative flex items-center justify-center overflow-hidden">
								<Img
									type="user"
									name={`${patient?.lastname}, ${patient?.firstname}, ${patient?.middle}`}
									src={patient?.avatar}
									className="absolute top-0 left-0 w-full h-full object-contain"
								/>
							</div>
							<div className=" flex flex-col justify-center items-center ">
								<h3 className="text-xl xl:text-2xl font-bold">
									{patientFullName(patient)}
								</h3>
							</div>
							<div className=" flex flex-col p-3 gap-3">
								{users?.type === "CIS-Nurse" &&
								!isPatientInReg() ? (
									<ButtonV3
										type="primary"
										onClick={() => {
											accept_patient_modal.current.show();
										}}
									>
										Add to queue
									</ButtonV3>
								) : (
									users?.type === "CIS-Doctor" && (
										<>
											{clinic?.type === "barangay" &&
											isPatientInService() ? (
												<>
													<ButtonV3
														type="secondary"
														onClick={() =>
															handleOpenPopup(
																"patient"
															)
														}
													>
														Patient Referral
													</ButtonV3>
												</>
											) : (
												clinic?.type ===
													"municipality" && (
													<>
														<ButtonV3
															type="tertiary"
															onClick={() =>
																handleOpenPopup(
																	"program"
																)
															}
														>
															Anesthesia Referral
														</ButtonV3>
													</>
												)
											)}
											{isPatientInReg() ? (
												<ButtonV3
													type="warning"
													onClick={() =>
														handleOpenPopup(
															"accept"
														)
													}
												>
													Accept patient
												</ButtonV3>
											) : (
												""
											)}
											{isPatientInService() ? (
												<ButtonV3
													type="success"
													onClick={() =>
														handleOpenPopup("done")
													}
												>
													Mark as Done
												</ButtonV3>
											) : (
												""
											)}
										</>
									)
								)}

								<CisAcceptPatientModal
									ref={accept_patient_modal}
								/>
								<PatientReferral
									open={isPatientOpen}
									onClose={handleClosePopup}
									patient={patient}
									clinic={clinic}
									data={isPatientInService()}
								/>
								<TbProgramReferral
									open={isProgramOpen}
									data={isPatientInService()}
									onClose={handleClosePopup}
								/>
								<AcceptPatient
									open={isAcceptOpen}
									onClose={() => {
										getPatient(id);
										handleClosePopup();
									}}
									patient={patient}
									data={isPatientInReg()}
								/>
								<MarkAsDone
									open={isDoneOpen}
									onClose={handleClosePopup}
									data={isPatientInService()}
								/>
							</div>
							<table className="table w-full">
								<tbody>
									<tr>
										<td
											colSpan={2}
											className="bg-slate-100"
										>
											<h6 className="font-bold text-base w-full mb-0">
												Personal Information
											</h6>
										</td>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											Firstname:
										</td>
										<th className="w-full">
											{patient?.firstname}
										</th>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											Lastname:
										</td>
										<th>{patient?.lastname}</th>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											Middlename:
										</td>
										<th>{patient?.middle}</th>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											Gender:
										</td>
										<th className="capitalize">
											{patient?.gender}
										</th>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											Age:
										</td>
										<th>
											{calculateAgeV2(
												new Date(patient?.birthday)
											)}{" "}
											yrs. old
										</th>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											Birthdate:
										</td>
										<th>
											{formatDate(
												new Date(patient?.birthday)
											)}
										</th>
									</tr>
									<tr>
										<td
											colSpan={2}
											className="bg-slate-100"
										>
											<h6 className="font-bold text-base w-full mb-0">
												Address
											</h6>
										</td>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											Province:
										</td>
										<th>Sarangani</th>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											City/Municipality:
										</td>
										<th>{patient?.municipality}</th>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											Barangay:
										</td>
										<th>{patient?.barangay}</th>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											Purok/Sitio:
										</td>
										<th>{patient?.purok}</th>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											Street:
										</td>
										<th>{patient?.street || ""}</th>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											Zone:
										</td>
										<th>{patient?.zone || ""}</th>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											House #:
										</td>
										<th>{patient?.house_number || "-"}</th>
									</tr>
									<tr>
										<td className="text-right w-[144px]">
											Household ID #:
										</td>
										<th>
											{String(
												patient?.household_id
											).padStart(5, "0")}
										</th>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="col-span-1 lg:col-span-8 xl:col-span-9  border-l flex flex-col">
							<TabGroup
								contentClassName={"max-h "}
								contents={[
									{
										title: (
											<MenuTitle src="/vitals/vitals.png">
												Vital signs
											</MenuTitle>
										),
										content: (
											<div className="flex flex-col w-full py-2 relative">
												<h3 className="font-bold px-4 flex items-center gap-2 relative mb-4 text-2xl">
													<img
														src="/vitals/vitals.png"
														className="h-11 w-11 object-contain"
													/>
													Vital signs
												</h3>
												{patient?.id && (
													<ClinicProfileVitals
														patient={patient}
													/>
												)}
											</div>
										),
									},
									...forDoctors,
									{
										title: (
											<MenuTitle src="/vitals/vitals-form.png">
												Vitals Form
											</MenuTitle>
										),
										content: ({ setSelectedIndex }) => (
											<div className="px-3 pt-2">
												<ClinicProfileVitalsForm
													setSelectedIndex={
														setSelectedIndex
													}
													patient={patient}
												/>
											</div>
										),
									},
									{
										title: (
											<MenuTitle src="/vitals/vitals-2.png">
												Vital charts
											</MenuTitle>
										),
										content: (
											<CisVitalsChart patient={patient} />
										),
									},
									{
										title: (
											<MenuTitle src="/vitals/appointment.png">
												Appointments
											</MenuTitle>
										),
										content: <CisAppointments />,
									},
									{
										title: (
											<MenuTitle src="/vitals/medical-report.png">
												History
											</MenuTitle>
										),
										content: (
											<CisPatientHistory
												patient={patient}
											/>
										),
									},
									{
										title: (
											<MenuTitle src="/landing-page.png">
												Vitals
											</MenuTitle>
										),
										content: "Vitals content here",
									},
									{
										title: (
											<MenuTitle src="/vitals/health.png">
												Health
											</MenuTitle>
										),
										content: (
											<CisPatientHealth
												patient={patient}
											/>
										),
									},
									{
										title: (
											<MenuTitle src="/vitals/laboratory.png">
												Laboratory
											</MenuTitle>
										),
										content: <CisPatientLaboratory />,
									},
									{
										title: (
											<MenuTitle src="/landing-page.png">
												Imaging
											</MenuTitle>
										),
										content: <CisPatientImaging />,
									},
									{
										title: (
											<MenuTitle src="/vitals/prescription.png">
												Prescription
											</MenuTitle>
										),
										content: ({ setSelectedIndex }) => (
											<div className="px-3 pt-2">
												<PrescriptionMainTab
													patient={patient}
												/>
											</div>
										),
									},
									{
										title: (
											<MenuTitle src="/vitals/notes.png">
												Notes
											</MenuTitle>
										),
										content: <CisNotes patient={patient} />,
									},
									{
										title: (
											<MenuTitle src="/vitals/treatment.png">
												Treatment Plan
											</MenuTitle>
										),
										content: (
											<CisTreatmentPlan
												patient={patient}
											/>
										),
									},
									{
										title: (
											<MenuTitle src="/vitals/certification.png">
												Med Cert
											</MenuTitle>
										),
										content: (
											<MedicalCertificateList
												patient={patient}
											/>
										),
									},
									{
										title: (
											<MenuTitle src="/vitals/form.png">
												Profile form
											</MenuTitle>
										),
										content: (
											<CisPatientsForm
												patient={patient}
											/>
										),
									},
									{
										title: (
											<MenuTitle src="/vitals/consulting.png">
												Consultations
											</MenuTitle>
										),
										content: ({ setSelectedIndex }) => (
											<div className="px-3 pt-2">
												<ProfileConsultations
													setSelectedIndex={
														setSelectedIndex
													}
													patient={patient}
												/>
											</div>
										),
									},
								]}
							/>
						</div>
					</div>
				</div>
			</div>
		</LayoutContainer>
	);
}
