import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TableBody,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { Printer } from "react-feather";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import { useHistory } from "react-router-dom";

const CashierSOAListDetails = ({ selectedBranch }) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { users } = useContext(UsersData);
  const history = useHistory();

  const [report, setReport] = useState({
    data: [],
    ready: false,
  });

  const getSOAList = useCallback(() => {
    var params = {
      management_id: users.management_id,
      bill_out_branch: selectedBranch.management_id,
    };
    axios
      .get("cashier/get/soa/list", { params })
      .then((response) => {
        const data = response.data;
        setReport({ data: data, ready: true });
        // setSOATempTotal(calculateAmount(data));
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users.management_id, selectedBranch.management_id]);

  //   const calculateAmount = (data) => {
  //     var total = 0;
  //     for (let x = 0; x < data.length; x++) {
  //       total +=
  //         data[x].discount !== null
  //           ? parseFloat(data[x].bill_total) -
  //             parseFloat(data[x].bill_total) * parseFloat(data[x].discount) +
  //             parseFloat(data[x].totalnotdiscounted) *
  //               parseFloat(data[x].discount)
  //           : data[x].home_service !== null
  //           ? parseFloat(data[x].bill_total) +
  //             parseFloat(data[x].bill_total) * parseFloat(data[x].home_service)
  //           : parseFloat(data[x].bill_total);
  //     }
  //     return total;
  //   };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  useEffect(() => {
    getSOAList();
    // eslint-disable-next-line
  }, [getSOAList]);

  return (
    <>
      <Box component={Paper} p={2}>
        <Box mb={1}>
          <Typography variant="h6" color="textSecondary">
            <b>SOA Records</b>
          </Typography>
        </Box>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <b> Date </b>
                </TableCell>
                <TableCell align="center">
                  <b> Control No. </b>
                </TableCell>
                <TableCell align="center">
                  <b> Action </b>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {report.ready ? (
                report.data.length > 0 ? (
                  (limit > 0
                    ? report.data.slice(page * limit, page * limit + limit)
                    : report.data
                  ).map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>{Notify.createdAt(data.created_at)}</TableCell>
                      <TableCell>{data.soa_id}</TableCell>
                      <TableCell align="center">
                        <Tooltip arrow title="Print SOA">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              history.push(
                                `/sph/app/cashier/soa/${data.soa_id}`
                              )
                            }
                          >
                            <Printer size={15} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography color="secondary">No data added.</Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography color="primary">
                      Loading, Please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <TablePagination
            component={Box}
            count={report.data.length}
            labelRowsPerPage="List"
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[
              10,
              20,
              50,
              { value: report.data.length, label: "All" },
            ]}
          />
        </TableContainer>
      </Box>
    </>
  );
};

export default CashierSOAListDetails;
