import { Popover, Menu, Transition } from "@headlessui/react";
import { TextField } from "@material-ui/core";
import axios from "axios";
import {
	forwardRef,
	Fragment,
	useImperativeHandle,
	useRef,
	useState,
	useEffect,
	useContext,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import VitalsForm from "src/clinic/components/VitalsForm";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import ActionBtn from "src/components/ActionBtn";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";
import TextInputField from "src/components/forms/TextInputField";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { patientFullName } from "src/helpers/utils";

const CisAcceptPatientModal = (props, ref) => {
	const { clinic } = useClinicContext();
	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors },
	} = useForm();
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));
	const [open, setOpen] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [patients, setPatients] = useState([]);
	const [doctors, setDoctors] = useState([]);

	const show = (patient) => {
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	const submit = async (formData) => {
		setLoading(true);
		console.log("formData", formData);

		try {
			const response = await axios.post("/v1/clinic-queueing/store", {
				patient_id: formData?.patient_id,
				purpose: formData?.purpose,
				doctor_id: formData?.doctor_id,
				clinic_id: clinic?.id, // identify where to retrieve clinic id
				type_service: formData?.type_service,
				temperature: formData?.temperature,
				pulse: formData?.pulse,
				blood_systolic: formData?.blood_systolic,
				blood_diastolic: formData?.blood_diastolic,
				respiratory_rate: formData?.respiratory_rate,
				weight: formData?.weight,
				height: formData?.height,
				glucose: formData?.glucose,
				cholesterol: formData?.glucose,
				priority: 1,
			});

			setTimeout(() => {
				toast.success("Success! Patient appointment added.");
			}, 100);
		} catch (error) {
			// Handle the error here (e.g., show an error message)
			console.error(error);
		} finally {
			setLoading(false);
			hide();
		}
	};

	const getPatients = async (query) => {
		let response_ = [];
		await axios
			.get(`/v1/patients?keyword=${query}&query=${query}`)
			.then((res) => {
				console.log(
					"getOptions(inputValue) res.data.data",
					res.data.data
				);
				response_ = res.data.data;
			});
		return response_.map((x) => ({
			label: `${patientFullName(x)}`,
			value: x?.id,
		}));
	};
	useEffect(() => {
		const fetchPatients = async () => {
			try {
				const response = await axios.get("/v1/patients");
				setPatients(response.data.data);
			} catch (error) {
				console.error("Error fetching patients:", error);
			}
		};

		const fetchDoctors = async () => {
			try {
				const response = await axios.get("/v1/clinic/doctors");
				setDoctors(response.data.data);
			} catch (error) {
				console.error("Error fetching doctors:", error);
			}
		};

		fetchPatients();
		fetchDoctors();
	}, []);

	return (
		<Modal
			open={open}
			className={`w-[768px] min-w-[768px] max-w-[768px]`}
			staticModal={true}
		>
			<ModalHeader title="Add patient to clinic queue" hide={hide} />
			<ModalBody className={`p- bg-slate-50 !pb-6`}>
				<div className="px-4 pt-4 pb-0 grid grid-cols-2 relative gap-4">
					<div className="col-span-2">
						<Controller
							name="patient_id"
							control={control}
							rules={{
								required: {
									value: true,
									message: "This field is required",
								},
							}}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: {
									invalid,
									isTouched,
									isDirty,
									error,
								},
							}) => (
								<ReactSelectInputField
									getOptions={getPatients}
									isClearable={false}
									label={
										<>
											Select Patient
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									async
									inputClassName=" "
									ref={ref}
									value={value}
									onChange={(val) => {
										console.log("onChangeonChange", val);
										if (onChange) {
											onChange(val);
										}
									}} // send value to hook form
									onBlur={onBlur} // notify when input is touched
									error={error?.message}
									placeholder="Select Patient"
									options={patients?.map((patient) => ({
										label: `${
											patient?.firstname +
											" " +
											patient?.middle +
											" " +
											patient?.lastname
										}`,
										value: patient?.id,
									}))}
								/>
							)}
						/>
					</div>
					<div className="col-span-2">
						<TextInputField
							label={
								<>
									Purpose
									<b className="text-red-500 ml-1">*</b>
								</>
							}
							variant="outlined"
							{...register("purpose", {
								required: true,
							})}
							inputClassName="bg-white"
							placeholder="Enter purpose..."
							InputLabelProps={{ shrink: true }}
							error={errors?.purpose}
							helperText={
								errors?.purpose && "This field is required"
							}
							className="w-full"
						/>
					</div>

					<Controller
						name="doctor_id"
						control={control}
						rules={{
							required: {
								value: true,
								message: "This field is required",
							},
						}}
						render={({
							field: { onChange, onBlur, value, name, ref },
							fieldState: { invalid, isTouched, isDirty, error },
						}) => (
							<ReactSelectInputField
								isClearable={false}
								label={
									<>
										Select Doctor
										<span className="text-danger ml-1">
											*
										</span>
									</>
								}
								inputClassName=" "
								ref={ref}
								value={value}
								onChange={(val) => {
									if (onChange) {
										onChange(val);
									}
								}} // send value to hook form
								onBlur={onBlur} // notify when input is touched
								error={error?.message}
								placeholder="Select Doctor"
								options={clinic?.doctors?.map((doctor) => ({
									label: doctor?.name,
									value: doctor?.id,
								}))}
							/>
						)}
					/>
					<div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
						<Controller
							name="type_service"
							control={control}
							rules={{
								required: {
									value: true,
									message: "This field is required",
								},
							}}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: {
									invalid,
									isTouched,
									isDirty,
									error,
								},
							}) => (
								<ReactSelectInputField
									isClearable={false}
									label={
										<>
											Type of service
											<span className="text-danger ml-1">
												*
											</span>
										</>
									}
									inputClassName=" "
									ref={ref}
									value={value}
									onChange={(val) => {
										if (onChange) {
											onChange(val);
										}
									}} // send value to hook form
									onBlur={onBlur} // notify when input is touched
									error={error?.message}
									placeholder="Type of service"
									options={[
										{
											label: "Physical",
											value: "Physical",
										},
										{
											label: "Medical Certificate",
											value: "Medical Certificate",
										},
										{
											label: "Consultation",
											value: "Consultation",
										},
									]}
								/>
							)}
						/>
					</div>
					<div className="col-span-2">
						<h5 className="font-bold text-base">Patient Vitals</h5>
						<VitalsForm register={register} errors={errors} />
					</div>
				</div>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				<ActionBtn onClick={handleSubmit(submit)} loading={loading}>
					Save appointment
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(CisAcceptPatientModal);
