import {
	Box,
	Toolbar,
	IconButton,
	Typography,
	makeStyles,
	Button,
	Popover,
	// Chip,
	useTheme,
	ClickAwayListener,
	ButtonGroup,
	Popper,
	MenuList,
	MenuItem,
	Grow,
	Paper,
	Select,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { ThemeContext, UsersData, UsersHeader } from "../ContextAPI";
import {
	blue,
	green,
	purple,
	red,
	grey,
	indigo,
	teal,
} from "@material-ui/core/colors";
import { Sidebar as MenuIcon, Menu as MenuIconClose } from "react-feather";
import {
	ColorLens,
	ArrowDropDown as ArrowDropDownIcon,
} from "@material-ui/icons";
import LogoutDialog from "./LogoutDialog";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";

const useStyles = makeStyles((theme) => ({
	menuButton: {
		marginRight: 10,
	},
	whiteColor: {
		color: "#fff",
	},
	primaryColor: {
		color: theme.palette.primary.main,
	},
	uppercase: {
		textTransform: "uppercase",
	},
	theme_blue: {
		width: "40px",
		height: "40px",
		borderRadius: "50%",
		boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
		position: "relative",
		background: `linear-gradient(-60deg, ${blue[500]} 50%, rgb(245, 245, 245) 0px)`,
	},
	theme_green: {
		width: "40px",
		height: "40px",
		borderRadius: "50%",
		boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
		position: "relative",
		background: `linear-gradient(-60deg, ${green[500]} 50%, rgb(245, 245, 245) 0px)`,
	},
	theme_red: {
		width: "40px",
		height: "40px",
		borderRadius: "50%",
		boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
		position: "relative",
		background: `linear-gradient(-60deg, ${red[500]} 50%, rgb(245, 245, 245) 0px)`,
	},
	theme_purple: {
		width: "40px",
		height: "40px",
		borderRadius: "50%",
		boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
		position: "relative",
		background: `linear-gradient(-60deg, ${purple[500]} 50%, rgb(245, 245, 245) 0px)`,
	},
	theme_indigo: {
		width: "40px",
		height: "40px",
		borderRadius: "50%",
		boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
		position: "relative",
		background: `linear-gradient(-60deg, ${indigo[500]} 50%, rgb(245, 245, 245) 0px)`,
	},
	theme_teal: {
		width: "40px",
		height: "40px",
		borderRadius: "50%",
		boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
		position: "relative",
		background: `linear-gradient(-60deg, ${teal[500]} 50%, rgb(245, 245, 245) 0px)`,
	},
	theme_dark: {
		width: "40px",
		height: "40px",
		borderRadius: "50%",
		boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
		position: "relative",
		background: `linear-gradient(-60deg, ${grey[700]} 50%, rgb(245, 245, 245) 0px)`,
	},
}));

const Topbar = ({ header, notification, module }) => {
	const mounted = IsMountedRef();
	const classes = useStyles();
	const themeContext = useContext(ThemeContext);
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [islogout, setIsLogout] = useState(false);
	const { users, updateUsers } = useContext(UsersData);
	const theme = useTheme();
	const anchorRef = React.useRef(null);
	const [selectedBranch, setSelectedBranch] = useState(null);
	const [branches, setBranches] = useState([]);
	const [openPopper, setPopper] = useState(false);

	const users_header = useContext(UsersHeader);

	const getBranchesByMainMngtId = React.useCallback(async () => {
		let response = await axios.get("general/management/get-branches", {
			params: {
				main_management_id: users.main_mgmt_id,
				type: "all",
			},
		});

		if (mounted.current) {
			setBranches(response.data);
		}
	}, [users, mounted]);

	const handleMenuItemClick = (event, data) => {
		setSelectedBranch(data.name);
		updateUsers({
			...users,
			management_id: data.management_id,
		});
		setPopper(false);
	};

	const handleToggle = () => {
		setPopper(!openPopper);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setPopper(false);
	};

	useEffect(() => {
		if (module === "COO" || module === "accounting") {
			getBranchesByMainMngtId();
		}
	}, [module, getBranchesByMainMngtId]);

	return (
		<>
			<Box display="flex">
				<Box flexGrow={1}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							className={clsx(classes.menuButton, {
								[classes.whiteColor]:
									!themeContext.sidebar.open,
								[classes.primaryColor]:
									themeContext.sidebar.open,
							})}
							onClick={() => {
								themeContext.sidebar.toggle(
									!themeContext.sidebar.open
								);
							}}
						>
							{themeContext.sidebar.open ? (
								<MenuIconClose strokeWidth={1.7} />
							) : (
								<MenuIcon />
							)}
						</IconButton>

						<Typography
							noWrap
							variant="h6"
							className={
								clsx(classes.uppercase, {
									[classes.whiteColor]:
										themeContext.sidebar.open,
									[classes.primaryColor]:
										themeContext.sidebar.open,
								}) + " font-bold"
							}
						>
							GTC{" "}
							{module === "documentation"
								? "enc"
								: module === "imaging"
								? "xray"
								: module === "laboratory"
								? "lab"
								: module === "psychology"
								? "psy"
								: module === "receiving"
								? "rcvng"
								: module === "doctor"
								? "doc"
								: module === "cashier"
								? "cshr"
								: module === "endorsement"
								? "endt"
								: module === "nurse"
								? "ns"
								: module === "registration"
								? "reg"
								: module}
						</Typography>
					</Toolbar>
				</Box>

				<Box>
					<Toolbar>
						{/* {module === "accounting" &&
              users.management_name !== undefined &&
              users.management_name !== null && (
                <Box>
                  <Chip
                    label={users.management_name}
                    variant="default"
                    color="primary"
                    // className={classes.primaryColor}
                  />
                </Box>
              )} */}

						{module === "COO" && (
							<Box hidden={!Boolean(users.main_mgmt_id)} mr={3}>
								<ButtonGroup
									variant="contained"
									color="primary"
									ref={anchorRef}
									size="large"
									aria-label="split button"
								>
									<Button>
										{selectedBranch !== null
											? selectedBranch
											: users.management_name}
									</Button>
									<Button
										color="primary"
										size="small"
										onClick={handleToggle}
									>
										<ArrowDropDownIcon />
									</Button>
								</ButtonGroup>
								<Popper
									open={openPopper}
									anchorEl={anchorRef.current}
									role={undefined}
									transition
								>
									{({ TransitionProps, placement }) => (
										<Grow
											{...TransitionProps}
											style={{
												transformOrigin:
													placement === "bottom"
														? "center top"
														: "center bottom",
											}}
										>
											<Paper>
												<ClickAwayListener
													onClickAway={handleClose}
												>
													<MenuList id="split-button-menu">
														{branches.map(
															(data, index) => (
																<MenuItem
																	key={index}
																	disabled={
																		data.management_id ===
																		users.management_id
																	}
																	selected={
																		data.management_id ===
																		users.management_id
																	}
																	onClick={(
																		event
																	) =>
																		handleMenuItemClick(
																			event,
																			data
																		)
																	}
																	style={{
																		textTransform:
																			"uppercase",
																	}}
																>
																	{data.name}
																</MenuItem>
															)
														)}
													</MenuList>
												</ClickAwayListener>
											</Paper>
										</Grow>
									)}
								</Popper>
							</Box>
						)}

						{module === "accounting" && (
							<Box hidden={!Boolean(users.main_mgmt_id)} mr={3}>
								<ButtonGroup
									variant="contained"
									color="primary"
									ref={anchorRef}
									size="large"
									aria-label="split button"
								>
									<Button>
										{selectedBranch !== null
											? selectedBranch
											: users.management_name}
									</Button>
									<Button
										color="primary"
										size="small"
										onClick={handleToggle}
									>
										<ArrowDropDownIcon />
									</Button>
								</ButtonGroup>
								<Popper
									open={openPopper}
									anchorEl={anchorRef.current}
									role={undefined}
									transition
								>
									{({ TransitionProps, placement }) => (
										<Grow
											{...TransitionProps}
											style={{
												transformOrigin:
													placement === "bottom"
														? "center top"
														: "center bottom",
											}}
										>
											<Paper>
												<ClickAwayListener
													onClickAway={handleClose}
												>
													<MenuList id="split-button-menu">
														{branches.map(
															(data, index) => (
																<MenuItem
																	key={index}
																	disabled={
																		data.management_id ===
																		users.management_id
																	}
																	selected={
																		data.management_id ===
																		users.management_id
																	}
																	onClick={(
																		event
																	) =>
																		handleMenuItemClick(
																			event,
																			data
																		)
																	}
																	style={{
																		textTransform:
																			"uppercase",
																	}}
																>
																	{data.name}
																</MenuItem>
															)
														)}
													</MenuList>
												</ClickAwayListener>
											</Paper>
										</Grow>
									)}
								</Popper>
							</Box>
						)}

						{module === "nurse" && (
							<Box>
								<Select
									value={users_header?.nurseDepartment}
									onChange={(e) =>
										users_header.setNurseDepartment(
											e.target.value
										)
									}
									variant="outlined"
									margin="dense"
								>
									<MenuItem value={"opd-department"}>
										OPD
									</MenuItem>
									<MenuItem value={"er-department"}>
										ER
									</MenuItem>
									<MenuItem value={"or-department"}>
										OR
									</MenuItem>
									<MenuItem value={"pacu-department"}>
										PACU
									</MenuItem>
									<MenuItem value={"ward-department"}>
										WARD
									</MenuItem>
									<MenuItem value={"room-department"}>
										ROOM
									</MenuItem>
									<MenuItem value={"discharge-department"}>
										DISCHARGE
									</MenuItem>
								</Select>
							</Box>
						)}

						<Box>
							<IconButton
								className={clsx(classes.menuButton, {
									[classes.primaryColor]:
										themeContext.sidebar.open,
									[classes.whiteColor]:
										!themeContext.sidebar.open,
								})}
								onClick={(e) => {
									setAnchorEl(e.currentTarget);
									setOpen(true);
								}}
							>
								<ColorLens />
							</IconButton>

							<ThemePickerPopover
								open={open}
								anchorEl={anchorEl}
								close={() => {
									setAnchorEl(null);
									setOpen(false);
									document
										.querySelector(
											'meta[name="theme-color"]'
										)
										.setAttribute(
											"content",
											`${`${
												theme.palette.type === "dark"
													? "#525252"
													: theme.palette.primary.main
											}`}`
										);
								}}
								themeContext={themeContext}
							/>
						</Box>

						<Box>
							<IconButton
								className={clsx(classes.menuButton, {
									[classes.primaryColor]:
										themeContext.sidebar.open,
									[classes.whiteColor]:
										!themeContext.sidebar.open,
								})}
								onClick={() => setIsLogout(true)}
							>
								<ExitToAppIcon />
							</IconButton>
						</Box>
					</Toolbar>
					<LogoutDialog
						open={islogout}
						close={() => setIsLogout(false)}
					/>
				</Box>
			</Box>
		</>
	);
};

export default Topbar;

const ThemePickerPopover = ({ open, close, anchorEl, themeContext }) => {
	const classes = useStyles();
	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={close}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
		>
			<Box p={2} maxWidth={180}>
				<Box mb={2}>
					<Typography color="primary"> Themes </Typography>
				</Box>

				<Box
					display="flex"
					mb={2}
					component={Button}
					onClick={() => themeContext.updategtcTheme("blue")}
					fullWidth
				>
					<Box className={classes.theme_blue} />
					<Box width={70} ml={2}>
						{" "}
						BLUE{" "}
					</Box>
				</Box>
				<Box
					display="flex"
					mb={2}
					component={Button}
					onClick={() => themeContext.updategtcTheme("green")}
					fullWidth
				>
					<Box className={classes.theme_green} />
					<Box width={70} ml={2}>
						{" "}
						GREEN{" "}
					</Box>
				</Box>
				<Box
					display="flex"
					mb={2}
					component={Button}
					onClick={() => themeContext.updategtcTheme("purple")}
					fullWidth
				>
					<Box className={classes.theme_purple} />
					<Box width={70} ml={2}>
						{" "}
						PURPLE{" "}
					</Box>
				</Box>
				<Box
					display="flex"
					mb={2}
					component={Button}
					onClick={() => themeContext.updategtcTheme("red")}
					fullWidth
				>
					<Box className={classes.theme_red} />
					<Box width={70} ml={2}>
						{" "}
						RED{" "}
					</Box>
				</Box>

				<Box
					display="flex"
					mb={2}
					component={Button}
					onClick={() => themeContext.updategtcTheme("indigo")}
					fullWidth
				>
					<Box className={classes.theme_indigo} />
					<Box width={70} ml={2}>
						{" "}
						INDIGO{" "}
					</Box>
				</Box>

				<Box
					display="flex"
					mb={2}
					component={Button}
					onClick={() => themeContext.updategtcTheme("teal")}
					fullWidth
				>
					<Box className={classes.theme_teal} />
					<Box width={70} ml={2}>
						{" "}
						TEAL{" "}
					</Box>
				</Box>

				<Box
					display="flex"
					component={Button}
					onClick={() => themeContext.updategtcTheme("dark")}
					fullWidth
				>
					<Box className={classes.theme_dark} />
					<Box width={70} ml={2}>
						{" "}
						DARK{" "}
					</Box>
				</Box>
			</Box>
		</Popover>
	);
};
