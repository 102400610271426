import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { Controller, useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import SelectItemsSelector from "./SelectItemsSelector";
import Img from "src/components/Img";
import { UsersData } from "src/ContextAPI";
import { dataURItoBlob, formatDateMMDDYYYYHHIIA } from "src/helpers/utils";

const ApproveReleaseMedicineModal = (props, ref) => {
	const { onSuccess } = props;
	const { users } = useContext(UsersData);
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		control,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(0);
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const [stream, setStream] = useState(null);
	const [imageCaptured, steImageCaptured] = useState(null);
	const videoRef = useRef(null);

	const startCamera = async () => {
		try {
			const mediaStream = await navigator.mediaDevices.getUserMedia({
				video: true,
			});
			setStream(mediaStream);
			videoRef.current.srcObject = mediaStream;
		} catch (error) {
			console.error("Error accessing camera:", error);
		}
	};

	const stopCamera = () => {
		if (stream) {
			stream.getTracks().forEach((track) => track.stop());
			setStream(null);
		}
	};

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const submitRelease = (data) => {
		// setLoading(true);
		// console.log("prescriptionItems", prescriptionItems);
		let formData = new FormData();
		// formData.append("appointment_id", appointment_id);
		formData.append("_method", "PATCH");
		appointment?.prescriptions.map((data) => {
			formData.append("inventory_id[]", data.id);
			formData.append("quantity[]", data.quantity);
			formData.append("items[]", data?.item?.id);
			formData.append("sig[]", data?.sig);
			formData.append("details[]", "medicine released");
		});
		axios
			.post(
				`/v1/clinic/tb-released-medicine/${appointment?.id}`,
				formData
			)
			.then((res) => {
				// addToList(data);
				// setTimeout(() => {
				// setLoading(false);
				setStep(2);
				toast.success("Prescription released!");
				// }, 400);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const captureImage = async () => {
		const canvas = document.createElement("canvas");
		canvas.width = videoRef.current.videoWidth;
		canvas.height = videoRef.current.videoHeight;
		canvas
			.getContext("2d")
			.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

		const capturedImage = canvas.toDataURL("image/jpeg"); // Convert canvas to base64 JPEG

		// Now you can send the capturedImage to your server using Axios
		try {
			steImageCaptured(capturedImage);
			stopCamera();
			//   await axios.post('YOUR_UPLOAD_URL', { image: capturedImage });
			// console.log("Image uploaded successfully", capturedImage);
		} catch (error) {
			console.error("Error uploading image:", error);
		}
	};
	const submitSatisction = (data) => {
		const formData = new FormData();
		formData.append("_method", "PATCH");
		formData.append("satisfaction", data.satisfaction);
		axios
			.post(
				`/v1/clinic/tb-satisfaction-rate/${appointment?.id}`,
				formData
			)
			.then((res) => {
				// addToList(data);
				// setTimeout(() => {
				// setLoading(false);
				setStep(3);
				toast.success("Satisfaction successfully submitted!");
				// }, 400);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const approveRelease = () => {
		const formData = new FormData();
		formData.append("_method", "PATCH");
		axios
			.post(
				`/v1/clinic/tb-approve-release-medication/${appointment?.id}`,
				formData
			)
			.then((res) => {
				onSuccess();
				setTimeout(() => {
					toast.success("Medicine is now approved for release!");
				}, 200);
				hide();
			});
	};

	return (
		<Modal
			open={open}
			staticModal={true}
			size="lg"
			hide={hide}
			className=" w-full"
		>
			<ModalHeader
				title={`Process Prescrition Release`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<div className="flex flex-col gap-y-1 rounded-xl mt-3">
					<div className="text-sm">
						{formatDateMMDDYYYYHHIIA(
							new Date(appointment?.created_at)
						)}
					</div>
					<b>RHU (TB DOTS), Alabel</b>
					<div>
						Doctor: <b>{appointment?.doctor?.name}</b>
					</div>
					<div>
						Pharmacist: <b>{users?.name}</b>
					</div>

					<div className="pl-2 text-lg py-2 border-l-4 border-l-gray-100 bg-yellow-50 mb-3">
						<span className="font-bold text-sm text-slate-500 mb-3">
							Prescriptions
						</span>
						<br />
						<ul className="pl-3" style={{ listStyleType: "disc" }}>
							{appointment?.prescriptions?.map((prescription) => {
								return (
									<li className="!text-sm flex flex-col">
										<b>
											{prescription?.item?.name} -{" "}
											{prescription?.quantity}{" "}
											{
												prescription?.item
													?.unit_measurement
											}
										</b>
										<div>
											Sig.:
											<span className=" ml-2">
												{prescription?.details}
											</span>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<div className="font-bold text-sm">
					List of items to be released
				</div>
				<div className="table">
					<table>
						<thead>
							<th>Item</th>
							<th>Unit</th>
							<th>Quantity</th>
						</thead>
						<tbody>
							{appointment?.prescriptions?.map((prescription) => {
								return (
									<tr>
										<td>{prescription?.item?.name}</td>
										<td>
											{
												prescription?.item
													?.unit_measurement
											}
										</td>
										<td>{prescription?.quantity}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-3">
					<div className="flex flex-col">
						<div className="flex items-center gap-3 mb-1">
							<div>
								<Img
									className="w-[44px] h-[44px]"
									type="user"
									src={appointment?.doctor?.avatar}
									name={appointment?.doctor?.name}
									alt=""
								/>
							</div>
							<QRCode value={`user-Test`} level="H" size={44} />
						</div>
						<b>{appointment?.doctor?.name}</b>

						<span className="italic border-t w-1/2 mt-1">
							RHU TB DOTS ALABEL
						</span>
					</div>
					<div className="flex flex-col">
						<div className="flex items-center gap-3 mb-1">
							<div>
								<Img
									className="w-[44px] h-[44px]"
									type="user"
									src={users?.avatar}
									name={users?.name}
									alt=""
								/>
							</div>
							<QRCode value={users?.name} level="H" size={44} />
						</div>
						<b>{users?.name}</b>
						<span className="italic border-t w-1/2 mt-1">
							RHU ALABEL Pharmacist
						</span>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center justify-between"}>
				<ActionBtn type="success" onClick={approveRelease}>
					<FlatIcon icon="rr-check" className="mr-0 text-lg" />
					Approve release
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(ApproveReleaseMedicineModal);
