import { faHouseUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TrainOutlined } from "@material-ui/icons";
import axios from "axios";
import { useEffect, useState } from "react";
import { Save } from "react-feather";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import ActionBtn from "src/components/ActionBtn";
import LayoutContainer from "src/components/LayoutContainer";
import LoadingTemplate from "src/components/LoaderTemplate";
import Notify from "src/notification/Notify";
import CollapseDiv from "./components/CollapseDiv";
import DemographyForm from "./components/Forms/household/DemogragphyForm";
import Demography from "./components/Forms/household/Demography";
import PatientAddressForm from "./components/Forms/household/PatientAddressForm";
import { TextField } from "@material-ui/core";

const SaveForm = ({ handleSubmit, submit, saving }) => {
	return (
		<div className="lg:ml-auto pt-2 lg:pt-0 flex items-center">
			<ActionBtn
				type="success"
				onClick={handleSubmit(submit)}
				loading={saving}
			>
				<Save className="w-5 mr-2" /> Save changes
			</ActionBtn>
		</div>
	);
};

const excluded_fields = [
	"household",
	"information",
	"immunization",
	"diseases",
];
const excluded_household_fields = [
	"rawAnswer",
	"barangay",
	"municipality",
	"members",
	"income",
];
const excluded_fields_info = [
	"vax_status",
	"crime_locations",
	"crime_locations",
	"nondisease",
];
const invalidValue = ["null", null, "undefined", undefined];

const valueToArray = (val) => {
	if (invalidValue.includes(val)) {
		return "";
	}
	return val.split(",");
};
const valueToArraySpace = (val) => {
	if (invalidValue.includes(val)) {
		return "";
	}
	return val.split(" ");
};
const PhoHouseholdMemberFormPage = () => {
	const params = useParams();
	const history = useHistory();
	const [patient, setPatient] = useState(null);
	const [loading, setLoading] = useState(false);
	const [diseases, setDiseases] = useState([]);
	const [saving, setSaving] = useState(false);
	const [municipalities, setMunicipalities] = useState([]);
	const [brgys, setBrgys] = useState([]);
	const [purokList, setPurokList] = useState([]);
	const {
		handleSubmit,
		register,
		formState: { errors },
		setValue,
		watch,
		control,
	} = useForm({
		covid_vaccinated: "no",
		diseases_field: ["1", "2"],
	});

	useEffect(() => {
		console.log("params?.id", params?.id);
		setLoading(true);

		getDiseases().then((res) => {
			setDiseases(res.data);
			getLocations().then((res) => {
				setMunicipalities(res.data?.data);
				setTimeout(() => {
					setLoading(false);
				}, 100);
				if (params?.id) {
					getPatientInfo()
						.then((res) => {
							let data = res?.data?.data || null;
							setPatient(data);
							setTimeout(() => {
								Object.keys(data).map((key) => {
									if (!excluded_fields.includes(key)) {
										setValue(
											key,
											invalidValue.includes(data[key])
												? ""
												: data[key]
										);
									}
								});
								setValue(
									"birthdate",
									invalidValue.includes(data?.birthday)
										? ""
										: data?.birthday
								);
								Object.keys(data?.information).map((key) => {
									if (!excluded_fields_info.includes(key)) {
										console.log(
											"keykeykey",
											"information",
											key,
											data?.information[key]
										);
										setValue(
											key,
											invalidValue.includes(
												data?.information[key]
											)
												? ""
												: data?.information[key]
										);
									}
								});
								setValue(
									`immunization`,
									valueToArray(data?.rawAnswer?.immunization)
								);

								Object.keys(data?.household).map((key) => {
									if (
										!excluded_household_fields.includes(key)
									) {
										console.log(
											"keykeykey",
											key,
											data?.household[key]
										);
										setValue(
											key,
											invalidValue.includes(
												data?.household[key]
											)
												? ""
												: data?.household[key]
										);
									}
								});
								setTimeout(() => {
									setValue(
										`barangay`,
										data?.household?.barangay?.id
									);
									setValue(
										`municipality`,
										data?.household?.municipality?.id
									);
								}, 200);
								setValue(
									`nondisease`,
									valueToArray(data?.rawAnswer?.nondisease)
								);
								setValue(
									`crime_victim`,
									valueToArray(data?.rawAnswer?.crime_victim)
								);
								setValue(
									`crime_locations`,
									valueToArray(
										data?.rawAnswer?.crime_locations
									)
								);

								console.log(
									"crime_locations",
									valueToArray(
										data?.rawAnswer?.crime_locations
									)
								);
								setValue(
									`vax_status`,
									data?.rawAnswer?.vax_status
								);
							}, 1000);
						})
						.finally(() => {
							setLoading(false);
						});
				}
			});
		});

		return () => {};
	}, [params?.id]);

	useEffect(() => {
		let t = setTimeout(() => {
			let com_diseases = patient?.diseases?.filter(
				(x) => x.type == "communicable"
			);
			console.log("com_diseases", com_diseases);
			let noncom_diseases = patient?.diseases?.filter(
				(x) => x.type == "non-communicable"
			);
			console.log("com_diseases non", noncom_diseases);
			if (com_diseases) {
				let com_values = com_diseases.map((x) => String(x.id));

				setValue("comnondisease", com_values);
			}
			if (noncom_diseases) {
				let noncom_values = noncom_diseases.map((x) => String(x.id));

				console.log("com_diseases non val", noncom_values);
				setValue("nondisease", noncom_values);
			}
		}, 1000);
		return () => {
			clearTimeout(t);
		};
	}, [diseases, patient?.id]);
	const getPatientInfo = () => {
		return axios.get(`v1/patients/${params?.id}`);
	};

	const getLocations = () => {
		return axios.get("/v1/locations");
	};
	const getDiseases = () => {
		return axios.get(`/v1/diseases`);
	};
	const submit = (data) => {
		setSaving(true);
		let url = `/v1/pho/patients`;
		let formdata = new FormData();

		formdata.append("prefix", data?.prefix || " ");
		formdata.append("suffix", data?.suffix || " ");
		formdata.append("firstname", data?.firstname || " ");
		formdata.append("lastname", data?.lastname || " ");
		formdata.append("middlename", data?.middlename || " ");
		formdata.append("gender", data?.gender || " ");
		formdata.append("birthdate", data?.birthdate || " ");
		formdata.append("birthplace", data?.birthplace || " ");
		formdata.append("barangay", data?.barangay || " ");
		formdata.append("city", data?.city || " ");
		formdata.append("civil_status", data?.civil_status || " ");
		formdata.append("telephone", data?.telephone || " ");
		formdata.append("email", data?.email || " ");
		formdata.append("philhealth", data?.philhealth || " ");
		formdata.append(
			"education_attainment",
			data?.education_attainment || " "
		);
		formdata.append("employment_status", data?.employment_status || " ");
		formdata.append("religion", data?.religion || " ");
		formdata.append("mother_firstname", data?.mother_firstname || " ");
		formdata.append("mother_lastname", data?.mother_lastname || " ");
		formdata.append("mother_middlename", data?.mother_middlename || " ");
		formdata.append("country", "PH");
		formdata.append("region", "SOCSKSARGEN Region");
		formdata.append("province", data?.province || " ");
		formdata.append("municipality", data?.municipality || " ");
		formdata.append("barangay", data?.barangay || " ");
		formdata.append("zip_code", data?.zip_code || "9502");
		formdata.append("street", data?.street || " ");
		formdata.append("purok", data?.purok || " ");
		formdata.append("email", data?.email || " ");
		formdata.append("mobile", data?.mobile || " ");
		formdata.append("telephone", data?.telephone || " ");
		formdata.append(
			"phil_health_category_type",
			data?.phil_health_category_type || " "
		);
		formdata.append(
			"phil_health_status_type",
			data?.phil_health_status_type || " "
		);
		formdata.append("enlistment_date", data?.enlistment_date || " ");
		formdata.append("family_serial_no", data?.family_serial_no || " ");
		formdata.append("family_member", data?.family_member || " ");
		formdata.append("blood_type", data?.blood_type || " ");
		formdata.append("mother_birthdate", data?.mother_birthdate || " ");
		Object.keys(data).map((key) => {
			formdata.append(key, data[key]);
		});
		formdata.append("city", data["municipality"]);

		if (params?.id) {
			url = url + `/${params?.id}`;
			formdata.append("_method", "PATCH");
		}
		console.log("dataaadataaa", data);
		axios
			.post(url, formdata)
			.then((res) => {
				setTimeout(() => {
					if (params?.id) {
						Notify.customToast(
							"success",
							"Patient updated successfully!"
						);
					} else {
						Notify.customToast(
							"success",
							"New patient created successfully!"
						);
					}
				}, 400);
				history.replace("/sph/app/cis/patients");
			})
			.catch((error) => {
				setSaving(false);
				Notify.requestError(error);
			})
			.finally(() => {
				setSaving(false);
			});
	};
	return (
		<>
			<LayoutContainer
				icon={
					<FontAwesomeIcon
						icon={faHouseUser}
						color="white"
						size={"2x"}
					/>
				}
				title={`${patient?.id ? "Edit" : "Add"} Patient`}
				subtitle={`${
					patient?.id ? "Update" : "Fill-in"
				} patient information`}
				titleChildren={
					<SaveForm
						handleSubmit={handleSubmit}
						submit={submit}
						saving={saving}
					/>
				}
			>
				<div className=" gap-y-4 flex flex-col ">
					{loading ? (
						<LoadingTemplate />
					) : (
						<>
							<CollapseDiv
								defaultOpen={true}
								headerClassName="bg-slate-100"
								bodyClassName="bg-white"
								title="D. DEMOGRAPHY"
							>
								{/* {console.log("errorserrorserrors", errors)} */}
								<Demography
									municipalities={municipalities}
									patient={patient}
									setMunicipalities={setMunicipalities}
									brgys={brgys}
									control={control}
									setBrgys={setBrgys}
									register={register}
									errors={errors}
									setValue={setValue}
									watch={watch}
									diseases={diseases}
									purokList={purokList}
									setPurokList={setPurokList}
								/>
							</CollapseDiv>
							{/* <CollapseDiv
								defaultOpen={true}
								headerClassName="bg-slate-100"
								bodyClassName="bg-white"
								title="D. DEMOGRAPHY"
							>
								<DemographyForm
									municipalities={municipalities}
									patient={patient}
									setMunicipalities={setMunicipalities}
									brgys={brgys}
									control={control}
									setBrgys={setBrgys}
									register={register}
									errors={errors}
									setValue={setValue}
									watch={watch}
									diseases={diseases}
									purokList={purokList}
									setPurokList={setPurokList}
								/>
							</CollapseDiv> */}
							<CollapseDiv
								defaultOpen={true}
								headerClassName="bg-slate-100"
								bodyClassName="bg-white"
								title="Address"
							>
								{/* <div className="grid grid-cols-1 w-full lg:grid-cols-12 2xl:grid-cols-12 gap-6">
									<TextField
										className="lg:col-span-4"
										SelectProps={{ native: true }}
										InputLabelProps={{ shrink: true }}
										label={`Province:`}
										variant="outlined"
										{...register("province", {
											required: "This field is required",
										})}
										error={errors?.province}
										helperText={errors?.province?.message}
									/>
									<TextField
										className="lg:col-span-4"
										SelectProps={{ native: true }}
										InputLabelProps={{ shrink: true }}
										label={`Municipality:`}
										variant="outlined"
										{...register("municipality", {
											required: "This field is required",
										})}
										error={errors?.municipality}
										helperText={
											errors?.municipality?.message
										}
									/>
									<TextField
										className="lg:col-span-4"
										SelectProps={{ native: true }}
										InputLabelProps={{ shrink: true }}
										label={`Barangay:`}
										variant="outlined"
										{...register("barangay", {
											required: "This field is required",
										})}
										error={errors?.barangay}
										helperText={errors?.barangay?.message}
									/>
									<TextField
										className="lg:col-span-3"
										SelectProps={{ native: true }}
										InputLabelProps={{ shrink: true }}
										label={`Zone:`}
										variant="outlined"
										{...register("zone", {
											required: "This field is required",
										})}
										error={errors?.zone}
										helperText={errors?.zone?.message}
									/>
									<TextField
										className="lg:col-span-3"
										SelectProps={{ native: true }}
										InputLabelProps={{ shrink: true }}
										label={`Zip Code:`}
										variant="outlined"
										{...register("zip_code", {
											required: "This field is required",
										})}
										error={errors?.zip_code}
										helperText={errors?.zip_code?.message}
									/>
									<TextField
										className="lg:col-span-4"
										SelectProps={{ native: true }}
										InputLabelProps={{ shrink: true }}
										label={`Street:`}
										variant="outlined"
										{...register("street", {
											required: "This field is required",
										})}
										error={errors?.street}
										helperText={errors?.street?.message}
									/>
									<TextField
										className="lg:col-span-3"
										SelectProps={{ native: true }}
										InputLabelProps={{ shrink: true }}
										label={`House Number:`}
										variant="outlined"
										{...register("house_number", {
											required: "This field is required",
										})}
										error={errors?.house_number}
										helperText={
											errors?.house_number?.message
										}
									/>
									<TextField
										className="lg:col-span-6"
										SelectProps={{ native: true }}
										InputLabelProps={{ shrink: true }}
										label={
											<>Household Identification Number</>
										}
										variant="outlined"
										{...register("house_id", {
											required: true,
										})}
										error={errors?.house_id}
										helperText={
											errors?.house_id &&
											"This field is required"
										}
									/>
								</div> */}
								{municipalities?.length > 0 ? (
									<PatientAddressForm
										municipalities={municipalities}
										patient={patient}
										household={patient?.household}
										setMunicipalities={setMunicipalities}
										brgys={brgys}
										setBrgys={setBrgys}
										register={register}
										control={control}
										errors={errors}
										setValue={setValue}
										watch={watch}
										purokList={purokList}
										setPurokList={setPurokList}
									/>
								) : (
									""
								)}
							</CollapseDiv>
						</>
					)}
					<SaveForm
						handleSubmit={handleSubmit}
						submit={submit}
						saving={saving}
					/>
				</div>
			</LayoutContainer>
		</>
	);
};

export default PhoHouseholdMemberFormPage;
