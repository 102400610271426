
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Container from './Container';

const RouteInvalid = () => {
    return (
        <React.Fragment>
            <Container
                breadcrumbs={{
                    enable: false,
                    current: '',
                    itesm: []
                }}
                title=" "
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignSelf="center"
                    alignItems="center"
                    align="center" 
                    width={'100%'} 
                    style={{ height: 'calc(100vh - 200px)' }}
                >
                    <Paper 
                        width={'50%'} 
                        component={Box}   
                        justifyContent="center"
                        alignSelf="center"
                        alignItems="center"
                        p={3} 
                    >
                        <Typography 
                            color="primary" 
                            variant="h1"
                        >
                            <b> 404 </b>
                        </Typography>

                        <Typography color="primary" variant="h6">
                            <b> PAGE NOT FOUND </b>
                        </Typography>

                        <Typography color="textSecondary" variant="subtitle1">
                            Looks like  you've followed a broken link or entered a URL that doens't exist on this site
                        </Typography>
                    </Paper>
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default RouteInvalid;