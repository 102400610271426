import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";

import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import VitalsForm from "src/clinic/components/VitalsForm";
import { calculateAge } from "src/helpers/utils";
const billing_data = [
	{
		particulars: "Drugs and Medicine (GF)",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Laboratory Examination",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Miscellaneous",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "NonDrugs / Supplies",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "PHIC",
		debit: 0.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Radiology",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
	{
		particulars: "Room and Board",
		debit: 500.0,
		discount: 0.0,
		credit: 0.0,
		balance: 500.0,
	},
];
const SPHPatientBillingModal = (props, ref) => {
	const { onSuccess, patient } = props;
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(null);
	const [loading, setLoading] = useState(false);
	const [prescriptionItems, setPrescriptionItems] = useState([]);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const submit = (data) => {
		setLoading(true);
		let formData = new FormData();
		formData.append("appointment_id", appointment?.id);
		formData.append("_method", "PATCH");

		axios
			.post(`/v1/clinic/send-to-cashier/${appointment?.id}`, formData)
			.then((res) => {
				// addToList(data);
				setTimeout(() => {
					setLoading(false);
					onSuccess();
					toast.success("Patient successfully sent to cashier!");
				}, 400);
				hide();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getSumObj = (objName) => {
		let total = 0;
		billing_data?.map((data) => {
			total += data[objName];
		});
		return total;
	};
	return (
		<Modal open={open} hide={hide} className="min-w-[512px] w-full">
			<ModalHeader
				title={`Confirm`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`px-4  py-10 bg-white`}>
				<div>
					<h3 className="mb-0 text-lg font-semibold text-center">
						Confirm send patient to cashier?
					</h3>
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center"}>
				<ActionBtn
					className="mr-auto"
					type="danger"
					onClick={hide}
					disabled={loading}
				>
					Close
				</ActionBtn>
				<ActionBtn
					className="ml-auto"
					type="success"
					onClick={handleSubmit(submit)}
					loading={loading}
				>
					Yes, Forward to cashier
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(SPHPatientBillingModal);
