import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Grid, Box, Card } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
	formControl: {
		padding: theme.spacing(1),
		width: "50%",
	},
	selectEmpty: {
		"&:before": {
			borderBottom: "none !important",
		},
		"&:after": {
			borderBottom: "none !important",
		},
		fontSize: 18,
		fontWeight: 800,
	},
	tableCell: {
		fontSize: 18,
		fontWeight: 800,
	},
}));

export default function Municipality() {
	const classes = useStyles();
	const [cardValue2, setCardValue2] = useState("");
	const data = [
		{
			id: 101,
			municipality: "Alabel",
			totalPopulation: 88294,
		},
		{
			id: 102,
			municipality: "Glan",
			totalPopulation: 109547,
		},
		{
			id: 103,
			municipality: "Kiamba",
			totalPopulation: 65774,
		},
		{
			id: 103,
			municipality: "Maasim",
			totalPopulation: 64940,
		},
		{
			id: 103,
			municipality: "Maitum",
			totalPopulation: 44185,
		},
		{
			id: 103,
			municipality: "Malapatan",
			totalPopulation: 80741,
		},
		{
			id: 103,
			municipality: "Malungon",
			totalPopulation: 105465,
		},
	];

	const handleChange = (event) => {
		setCardValue2(event.target.value);
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} lg={12}>
					<Card elevation={2} className={"pointer"} component={Box}>
						<div className="flex w-full bg-success px-3 py-1">
							<FormControl
								className={`${classes.formControl} bg-inherit`}
							>
								<Select
									value={cardValue2}
									onChange={handleChange}
									displayEmpty
									className={`${classes.selectEmpty} text-white`}
									inputProps={{
										"aria-label": "Without label",
									}}
								>
									<MenuItem value="">MUNICIPALITY</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className="px-2">
							<Table aria-label="simple table">
								<TableBody>
									{data.map(
										({
											id,
											municipality,
											totalPopulation,
										}) => (
											<>
												<TableRow key={id}>
													<TableCell
														className={
															classes.tableCell
														}
													>
														{municipality}
													</TableCell>
													<TableCell
														className={
															classes.tableCell
														}
													>
														{totalPopulation.toLocaleString()}
													</TableCell>
												</TableRow>
											</>
										)
									)}
								</TableBody>
							</Table>
						</div>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}
