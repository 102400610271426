import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Box,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { Formik } from "formik";
import React, { useContext } from "react";
import { CheckSquare, XCircle } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import * as Yup from "yup";

const AddBed = ({ getRoomList, selectedRoom, noOfBed, onClose }) => {
  const { users } = useContext(UsersData);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <>
      <Formik
        initialValues={{
          management_id: users.management_id,
          room_number: selectedRoom?.room_number,
          room_id: selectedRoom?.room_id,
          bed_number: noOfBed + 1,
          amount: 1,
        }}
        validationSchema={Yup.object().shape({
          amount: Yup.string().trim().required("Amount is required."),
        })}
        onSubmit={async (
          values,
          { setErrors, setSubmitting, resetForm, setFieldValue }
        ) => {
          try {
            const request = await axios.post(
              "accounting/room/bed/new-bed-in-room",
              getFormData(values)
            );

            if (request.data.message === "success") {
              Notify.successRequest("new bed");
              resetForm();
              getRoomList();
              onClose();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box>
                <TextField
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={touched.amount && errors.amount}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  fullWidth
                  required
                  name="amount"
                  label="Amount"
                  variant="outlined"
                  margin="normal"
                  type="amount"
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                />
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckSquare />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>

              <Button
                variant="contained"
                color="default"
                startIcon={<XCircle />}
                onClick={() => onClose()}
              >
                No
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AddBed;
