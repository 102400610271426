import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

class immunologyRef {
  alphaFetoprotein = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          0.00 - 13.6 ng/mL
        </Typography>
      </Box>
    </Box>
  );
}

const ImmunologyRef = new immunologyRef();
export default ImmunologyRef;
