import {
	TableHead,
	Table,
	TableCell,
	TableRow,
	TableBody,
	TableContainer,
	Typography,
	Box,
	Grid,
	IconButton,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import ChartistGraph from "react-chartist";
import { LinearScale } from "@material-ui/icons";

const PsychologyAudiometric = ({ order_id, patient_id }) => {
	const { users } = React.useContext(UsersData);
	const [details, setDetails] = useState([]);
	const [dataChart, setDataChart] = useState([]);
	const label = ["8000", "4000", "2000", "1000", "500", "250"];

	const options = {
		showArea: true,
		height: window.innerWidth > 400 ? 200 : 100,
		width: 750,
	};

	const data = {
		labels: label,
		series: dataChart,
	};

	const getpaidLaboratoryOrderDetails = () => {
		var formdata = new FormData();
		formdata.set("user_id", users.user_id);
		formdata.set("patient_id", patient_id);
		formdata.set("trace_number", order_id);
		formdata.set("table", "psychology_audiometry");
		formdata.set("connection", "local");

		axios
			.post("doctor/patient/psychology/order/paid-detailsbytable", formdata)
			.then((res) => {
				const data = res.data;
				setDetails(data);
				setDataChart([
					[
						data[0].left_8000,
						data[0].left_4000,
						data[0].left_2000,
						data[0].left_1000,
						data[0].left_500,
						data[0].left_250,
					],
					[
						data[0].right_8000,
						data[0].right_4000,
						data[0].right_2000,
						data[0].right_1000,
						data[0].right_500,
						data[0].right_250,
					],
				]);
			})
			.catch((er) => Notify.requestError(er));
	};

	useEffect(() => {
		getpaidLaboratoryOrderDetails();
		// eslint-disable-next-line
	}, [order_id]);

	return (
		<>
			{details.length > 0 && (
				<Box my={2} p={2}>
					<Box display="flex" mb={2}>
						<Box flexGrow={1}>
							<Typography variant="h6" align="center">
								<b> AUDIOMETRIC REPORT </b>
							</Typography>
						</Box>
					</Box>

					<Box align="center">
						<ChartistGraph data={data} options={options} type={"Line"} />
					</Box>

					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell> Frequency in hertz </TableCell>
									<TableCell align="center">
										<b> 8000 </b>
									</TableCell>
									<TableCell align="center">
										<b> 4000 </b>
									</TableCell>
									<TableCell align="center">
										<b> 2000 </b>
									</TableCell>
									<TableCell align="center">
										<b> 1000 </b>
									</TableCell>
									<TableCell align="center">
										<b> 500 </b>
									</TableCell>
									<TableCell align="center">
										<b> 250 </b>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{details.map((data, index) => (
									<Fragment key={index}>
										<TableRow hidden={data.audiometry_test === null}>
											<TableCell>
												<Typography
													variant="subtitle2"
													className={`gtc-uppercase`}
												>
													LEFT EAR
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Box>
													<Typography>{data.left_8000}</Typography>
												</Box>
											</TableCell>
											<TableCell align="center">
												<Box>
													<Typography>{data.left_4000}</Typography>
												</Box>
											</TableCell>
											<TableCell align="center">
												<Box>
													<Typography>{data.left_2000}</Typography>
												</Box>
											</TableCell>
											<TableCell align="center">
												<Box>
													<Typography>{data.left_1000}</Typography>
												</Box>
											</TableCell>
											<TableCell align="center">
												<Box>
													<Typography>{data.left_500}</Typography>
												</Box>
											</TableCell>
											<TableCell align="center">
												<Box>
													<Typography>{data.left_250}</Typography>
												</Box>
											</TableCell>
										</TableRow>
										<TableRow hidden={data.audiometry_test === null}>
											<TableCell>
												<Typography
													variant="subtitle2"
													className={`gtc-uppercase`}
												>
													RIGHT EAR
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Box>
													<Typography>{data.right_8000}</Typography>
												</Box>
											</TableCell>
											<TableCell align="center">
												<Box>
													<Typography>{data.right_4000}</Typography>
												</Box>
											</TableCell>
											<TableCell align="center">
												<Box>
													<Typography>{data.right_2000}</Typography>
												</Box>
											</TableCell>
											<TableCell align="center">
												<Box>
													<Typography>{data.right_1000}</Typography>
												</Box>
											</TableCell>
											<TableCell align="center">
												<Box>
													<Typography>{data.right_500}</Typography>
												</Box>
											</TableCell>
											<TableCell align="center">
												<Box>
													<Typography>{data.right_250}</Typography>
												</Box>
											</TableCell>
										</TableRow>
									</Fragment>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box m={5}>
						<Box display="flex">
							<Box flexGrow={1}>
								<Typography variant="h6">
									<b> INTERPRETATION </b>
								</Typography>
							</Box>
						</Box>
						<Box>
							<Grid container spacing={2}>
								<Grid item xs={4} sm={4}>
									<Box mt={2} align="center" display="flex">
										<IconButton color={"primary"}>
											<LinearScale />
										</IconButton>
										<Box mt={1.2}>
											<Typography variant="subtitle1">
												<b> LEFT EAR </b>
											</Typography>
										</Box>
									</Box>
								</Grid>
								<Grid item xs={8} sm={8}>
									<Box
										mt={2}
										align="center"
										borderBottom={1}
										borderColor={"#AEAEAE"}
									>
										<Typography variant="subtitle1">
											<b> {details[0].left_ear_interpret} </b>
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={4} sm={4}>
									<Box mt={2} align="center" display="flex">
										<IconButton color={"secondary"}>
											<LinearScale />
										</IconButton>
										<Box mt={1.2}>
											<Typography variant="subtitle1">
												<b> RIGHT EAR </b>
											</Typography>
										</Box>
									</Box>
								</Grid>
								<Grid item xs={8} sm={8}>
									<Box
										mt={2}
										align="center"
										borderBottom={1}
										borderColor={"#AEAEAE"}
									>
										<Typography variant="subtitle1">
											<b> {details[0].right_ear_interpret} </b>
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
};

export default PsychologyAudiometric;
