import axios from "axios";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActionBtn from "src/components/ActionBtn";
import FlatIcon from "src/components/FlatIcon";
import { formatDate, formatDateTime } from "src/helpers/utils";
import AddDiagnosisModal from "./AddDiagnosisModal";

const ClinicDiagnosis = ({ patient }) => {
	const { id } = useParams();
	const add_diagnosis_modal = useRef(null);
	const [data, setData] = useState([]);
	useEffect(() => {
		console.log("ClinicDiagnosis", patient?.id);
		if (patient?.id > 0) {
			getData();
		}
	}, [patient?.id]);
	const getData = () => {
		axios
			.get(`/v1/clinic/patient-diagnosis?patient_id=${patient?.id}`)
			.then((res) => {
				let a = res.data.data || [];
				setData(a);
			});
	};
	return (
		<>
			<div className="flex flex-col w-full mb-3">
				<h5 className="text-sm font-semibold text-indigo-700 w-2/3 flex items-center gap-2">
					<span>
						<img
							src="/vitals/history.png"
							className="h-8 object-contain"
						/>
					</span>
					<div>History</div>
					<ActionBtn
						onClick={() => {
							add_diagnosis_modal.current.show({
								id: patient?.id,
								name: `${patient?.lastname}, ${patient?.firstname}, ${patient?.middle}`,
							});
						}}
						size="sm"
					>
						<FlatIcon icon="rr-plus" />
						Add
					</ActionBtn>
				</h5>
				<div className="w-2/3 h-[1px] bg-indigo-600 mb-[2px]"></div>
				<div className="w-1/2 h-[1px] bg-indigo-400"></div>
			</div>
			<div className="py-2 px-4 flex flex-col">
				<div class=" mx-11">
					<ol class="relative border-l border-gray-200 dark:border-gray-700">
						{data?.length == 0 ? (
							<li className="text-placeholder pl-4">
								<i>No data to display.</i>
							</li>
						) : (
							""
						)}
						{data?.map((item) => {
							return (
								<li class="mb-10 ml-4">
									<div class="absolute w-3 h-3 bg-gray-200 rounded-full -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700" />
									<time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
										{formatDateTime(item?.datetime)}
									</time>
									<h3 class="text-lg font-semibold text-gray-900">
										{item?.title}
									</h3>
									<div className="flex flex-col mb-2">
										<span className="textm text-xs font-bold">
											Diagnosis
										</span>
										{item?.relationships?.diagnosis_list?.map(
											(x) => {
												return (
													<p className="mb-0 text-xs pl-2">
														- {x?.description}
													</p>
												);
											}
										)}
									</div>
									<span className="textm text-xs font-bold">
										Notes/Remarks
									</span>
									<div
										class="mb-4 text-base font-normal text-darker bg-blue-100 rounded-lg p-4 shadow-sm html-contents"
										dangerouslySetInnerHTML={{
											__html: item?.description,
										}}
									/>
								</li>
							);
						})}
					</ol>
				</div>

				<AddDiagnosisModal
					ref={add_diagnosis_modal}
					getData={getData}
				/>
			</div>
		</>
	);
};

export default ClinicDiagnosis;
