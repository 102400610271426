import {
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Box,
  TextField,
  InputAdornment,
  TablePagination,
  IconButton,
  Tooltip,
  DialogActions,
  Dialog,
  DialogContent,
  Button,
  Collapse,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import axios from "axios";
import React, { useState, Fragment } from "react";
import { Trash, ArrowRight, ArrowUp } from "react-feather";
import Notify from "src/notification/Notify";
// import Notify from "src/notification/Notify";

const List = ({ list, rerender }) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [remove, setRemove] = useState({ dialog: false, data: null });
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [details, setDetails] = useState([]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const searchable = list.data.filter((data) => {
    return (
      data.item.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1 ||
      data.description.toLowerCase().indexOf(search.trim().toLowerCase()) !==
        -1 ||
      data.supplier.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
    );
  });

  const handleSelectedRemove = async () => {
    var formdata = new FormData();
    formdata.set("remove_id", remove.data.id);
    let response = await axios.post(
      "laboratory/test/items/remove-item",
      formdata
    );

    if (response.data.message === "success") {
      rerender();
      setRemove({ dialog: false, data: null });
    }
  };

  const handleSelectedItemOrderUses = async (data) => {
    if (data.item_id === selectedItemId) {
      console.log("item is currently selected");
      return;
    }

    var formdata = new FormData();
    formdata.set("item_id", data.item_id);

    let response = await axios.post(
      "accounting/test/items/orderusesthisitem-list",
      formdata
    );

    setDetails(response.data);
    setSelectedItemId(data.item_id);
  };

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h6"> Reagent List </Typography>
            </Box>
            <Box>
              <TextField
                label="Search Brand, Description, Supplier"
                variant="outlined"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        }
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell> Date </TableCell> */}
                <TableCell> Brand </TableCell>
                <TableCell> Description </TableCell>
                <TableCell> Unit </TableCell>
                <TableCell> Supplier </TableCell>
                <TableCell> Msrp </TableCell>
                <TableCell align="center"> Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list.ready ? (
                searchable.length > 0 ? (
                  searchable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <Fragment key={index}>
                        <TableRow>
                          {/* <TableCell>
                            {Notify.dateTimeConvert(data.created_at)}
                          </TableCell> */}
                          <TableCell> {data.item} </TableCell>
                          <TableCell style={{ maxWidth: 250 }}>
                            {data.description}
                          </TableCell>
                          <TableCell> {data.unit} </TableCell>
                          <TableCell> {data.supplier} </TableCell>
                          <TableCell align="center">
                            {" "}
                            {Notify.convertToNumber(data.msrp)}{" "}
                          </TableCell>
                          <TableCell align="center" style={{ width: 130 }}>
                            <Tooltip
                              arrow
                              title={
                                !Boolean(data._order_user)
                                  ? "No order uses this item"
                                  : ""
                              }
                            >
                              <span>
                                <IconButton
                                  color="primary"
                                  disabled={
                                    !Boolean(data._order_user) ||
                                    data.item_id === selectedItemId
                                  }
                                  onClick={() =>
                                    handleSelectedItemOrderUses(data)
                                  }
                                >
                                  {data.item_id === selectedItemId ? (
                                    <ArrowUp />
                                  ) : (
                                    <ArrowRight />
                                  )}
                                </IconButton>
                              </span>
                            </Tooltip>

                            <Tooltip
                              title={
                                Boolean(data._order_user)
                                  ? "Item is in used, Cannot be deleted."
                                  : ""
                              }
                            >
                              <span>
                                <IconButton
                                  color="secondary"
                                  disabled={Boolean(data._order_user)}
                                  onClick={() => {
                                    setRemove({
                                      dialog: true,
                                      data: data,
                                    });
                                  }}
                                >
                                  <Trash />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={6}
                          >
                            <Collapse
                              in={data.item_id === selectedItemId}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Typography
                                  variant="subtitle1"
                                  color="textSecondary"
                                >
                                  Order Uses this item
                                </Typography>

                                <Box>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>
                                          <b> Order </b>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 250,
                                          }}
                                        >
                                          <b> Category </b>
                                        </TableCell>
                                        <TableCell>
                                          <b> Rate </b>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {details.length > 0 ? (
                                        details.map((datax, xindex) => (
                                          <TableRow key={xindex}>
                                            <TableCell>
                                              {datax.laborder}
                                            </TableCell>
                                            <TableCell>
                                              {datax.category}
                                            </TableCell>
                                            <TableCell align="right">
                                              {datax.rate}
                                            </TableCell>
                                          </TableRow>
                                        ))
                                      ) : (
                                        <TableRow>
                                          <TableCell colSpan={3}>
                                            <Typography color="secondary">
                                              No record found.
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Typography color="primary">
                      Loading, Please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={searchable.length}
            rowsPerPageOptions={[5, 50, 100]}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="List"
          />
        </TableContainer>
      </CardContent>

      {/* remove dialog */}
      {remove.dialog && (
        <RemoveDialog
          open={remove.dialog}
          action={handleSelectedRemove}
          close={() => setRemove({ dialog: false, data: null })}
        />
      )}
    </Card>
  );
};

export default List;

const RemoveDialog = ({ open, close, action }) => {
  return (
    <Dialog open={open} onClose={close}>
      <DialogContent>
        <Typography>Are you sure to remove this item?</Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="default" onClick={() => close()}>
          No
        </Button>
        <Button variant="contained" color="secondary" onClick={() => action()}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
