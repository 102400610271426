import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import ReactSelect from "react-select";
import SelectStyle from "src/utils/SelectStyle";
import Notify from "src/notification/Notify";

const PayablePaymentDialog = ({ open, close, selectedInvoice, action }) => {
  const { users } = useContext(UsersData);
  const [bank, setBank] = useState([]);
  const mounted = IsMountedRef();
  const selectStyle = SelectStyle();
  const [selectedBank, setSelectedBank] = useState("");
  const [bankPaymentType, setBankPaymentType] = useState("");
  const [isProcess, setIsProcess] = useState(false);

  const getBanksList = React.useCallback(async () => {
    let response = await axios.get("accounting/get/bank-list", {
      params: {
        management_id: users.management_id,
      },
    });

    const data = response.data;
    if (mounted.current) {
      setBank(data);
    }
  }, [users, mounted]);

  const handleInvoicePayment = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);

    var error = [];

    if (
      formdata.get("bank").length === 0 ||
      formdata.get("bank").trim() === ""
    ) {
      error = "bank";
      Notify.fieldRequired("bank");
    }
    if (
      formdata.get("bank_balance").length === 0 ||
      formdata.get("bank_balance").trim() === ""
    ) {
      error = "bank_balance";
      Notify.fieldRequired("bank balance");
    }
    if (
      formdata.get("amounto_pay").length === 0 ||
      formdata.get("amounto_pay").trim() === ""
    ) {
      error = "amounto_pay";
      Notify.fieldRequired("amount to pay");
    }

    if (
      parseFloat(formdata.get("amounto_pay")) >
      parseFloat(formdata.get("bank_balance"))
    ) {
      error = "bank_balance";
      Notify.customToast("Bank balance is not enough for payment.");
    }

    if (
      formdata.get("payment_type").length === 0 ||
      formdata.get("payment_type").trim() === ""
    ) {
      error = "payment_type";
      Notify.fieldRequired("Payment type");
    } else {
      if (formdata.get("payment_type") === "Check") {
        if (
          formdata.get("check_date").length === 0 ||
          formdata.get("check_date").trim() === ""
        ) {
          error = "check_date";
          Notify.fieldRequired("Check date to pay");
        }

        if (
          formdata.get("check_no").length === 0 ||
          formdata.get("check_no").trim() === ""
        ) {
          error = "check_no";
          Notify.fieldRequired("Check no. to pay");
        }
      }
    }

    if (error.length > 0) {
      console.log("form has an error.", error);
    } else {
      setIsProcess(true);
      let response = await axios.post(
        "accounting/payable/invoice-payment",
        formdata
      );

      let data = response.data;

      if (data.message === "success") {
        Notify.successRequest("invoice paid");
        action();
        close();
      }

      setIsProcess(false);
    }
  };

  useEffect(() => {
    getBanksList();
  }, [getBanksList]);

  return (
    <Dialog open={open} onClose={() => close()} maxWidth="xs" fullWidth>
      <DialogTitle> Invoice Payment </DialogTitle>
      <form onSubmit={handleInvoicePayment}>
        <DialogContent dividers>
          <Box>
            <TextField
              fullWidth
              label="Amount to pay"
              variant="outlined"
              value={selectedInvoice ? selectedInvoice.invoice_amount : ""}
              InputProps={{ readOnly: true }}
              name="amounto_pay"
            />
          </Box>
          <Box my={3}>
            <ReactSelect
              options={bank}
              styles={selectStyle}
              name="bank"
              onChange={(data) => setSelectedBank(data)}
            />
          </Box>

          <TextField hidden name="bank_name" value={selectedBank.bank_name} />
          <TextField
            hidden
            name="invoice_number"
            value={selectedInvoice.invoice_number}
          />
          <Box>
            <TextField
              label="Selected bank balance"
              variant="outlined"
              value={
                selectedBank === "" ? "0" : selectedBank.total_bank_balance
              }
              InputProps={{ readOnly: true }}
              name="bank_balance"
              fullWidth
            />
          </Box>

          <Box my={3}>
            <TextField
              label="Deposit Type"
              variant="outlined"
              fullWidth
              select
              onChange={(e) => setBankPaymentType(e.target.value)}
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true,
              }}
              name="payment_type"
            >
              <option value=""> -- Select -- </option>
              <option value="Cash"> Cash </option>
              <option value="Check"> Check </option>
            </TextField>
          </Box>

          {bankPaymentType === "Check" && (
            <>
              <Box my={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  variant="outlined"
                  label="Check Date"
                  name="check_date"
                  fullWidth
                />
              </Box>

              <Box my={3}>
                <TextField
                  fullWidth
                  name="check_no"
                  label="Check #"
                  variant="outlined"
                />
              </Box>
            </>
          )}

          <Box my={3}>
            <TextField
              fullWidth
              name="remarks"
              label="Remarks"
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="default" onClick={() => close()}>
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isProcess}
            startIcon={
              isProcess && <CircularProgress size={25} color="inherit" />
            }
          >
            Process
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PayablePaymentDialog;
