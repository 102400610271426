import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Notify from "src/notification/Notify";

const BankDepositTable = ({ depositList }) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Note</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {depositList.ready ? (
              depositList.data.length > 0 ? (
                depositList.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.date_transact}</TableCell>
                    <TableCell> {data.note} </TableCell>
                    <TableCell> {data.category} </TableCell>
                    <TableCell align="right"> {data.payment_type} </TableCell>
                    <TableCell align="right">
                      {Notify.convertToNumber(data.amount)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography color="secondary">No record found.</Typography>
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography color="primary">
                    Loading, please wait...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BankDepositTable;
