import {
  Box,
  // Grid, makeStyles, Tabs, Tab, Paper, Badge
} from "@material-ui/core";

import React, {
  useEffect,

  // useState, useContext
} from "react";
// import { PatientsUnRead } from 'src/ContextAPI';
// import TabPanel from 'src/utils/TabPanel';
import LaboratoryLocal from "./LaboratoryLocal";
// import VirtualOrder from './virtual/VirtualOrder';

// const useStyle = makeStyles({
//     indicatorLeft: {
//         left: "0px"
//     }
// });

const Laboratory = ({
  connection,
  patient_id,
  getPermission,
  getPatientsList,
  permission,
  trace_number,
}) => {
  // const classes = useStyle()
  // const [category, setCategory] = useState('local-laboratory')

  // const unread = useContext(PatientsUnRead);

  // const handleChange = (event, cat) => {
  //     event.persist()
  //     setCategory(cat);
  // };

  useEffect(() => {}, [patient_id, trace_number]);

  return (
    <Box>
      <LaboratoryLocal
        connection={connection}
        patient_id={patient_id}
        getPermission={getPermission}
        getPatientsList={getPatientsList}
        permission={permission}
        trace_number={trace_number}
      />

      {/* <Box display="flex" justifyContent="flex-end" mb={2}>   
                <Box>
                    <Paper>
                        <Tabs
                            value={category}
                            onChange={handleChange}  
                            scrollButtons="on" 
                            indicatorColor="primary"
                            textColor="primary"  
                            variant="scrollable"
                            orientation={"horizontal"}
                            classes={{
                                indicator: classes.indicatorLeft
                            }}
                        >
                            <Tab wrapped value="local-laboratory" label="Local Laboratory" />

                            <Tab wrapped value="virtual-laboratory" label="Virtual Laboratory" /> 
                        </Tabs>
                    </Paper>
                </Box>
            </Box>
            
            <Box>
                <TabPanel value={category} index={'local-laboratory'}>
                    <LaboratoryLocal 
                        connection = { connection }
                        patient_id = { patient_id }
                        getPermission = { getPermission }
                        getPatientsList = { getPatientsList }
                        permission = { permission }
                    />
                </TabPanel>

                <TabPanel value={category} index={'virtual-laboratory'}>
                    <VirtualOrder 
                        connection = { connection }
                        patient_id = { patient_id }
                        getPermission = { getPermission }
                        getPatientsList = { getPatientsList }
                        permission = { permission }
                    />
                </TabPanel>
            </Box>  */}
    </Box>
  );
};

export default Laboratory;
