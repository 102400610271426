import { faUserInjured } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardContainer from "src/components/CardContainer";
import LayoutContainer from "src/components/LayoutContainer";
import ActionBtn from "src/components/ActionBtn";
import axios from "axios";
import { useEffect, useState } from "react";
const PhoPhilhealthForm = () => {
	const [patient, setPatient] = useState(null);
	const getData = () => {
		axios.get(`v1/patients/8`).then((res) => {
			setPatient(res.data.data);
		});
	};
	useEffect(() => {
		getData();
	}, []);
	return (
		<LayoutContainer
			icon={
				<FontAwesomeIcon
					icon={faUserInjured}
					color="white"
					size={"2x"}
				/>
			}
			title="Patient List"
			subtitle="View, Register, and Update Patients"
		>
			<div className="flex items-center mb-4">
				<ActionBtn>Print</ActionBtn>
			</div>
			<CardContainer
				containerClassName={` border border- border-opacity-10  !w-[12in]`}
				className="!p-0"
			>
				<div className="bg-white border-black p-11 flex flex-col w-[8.5in] mx-auto p-[0.5in]">
					<b>ANNEX A1</b>
					<div className="w-full bg-white border-2 border-black  !min-h-[12in] mb-11">
						<div className="w-full flex items-center">
							<div className="w-[144px] aspect-square" />
							<div className="w-full flex flex-col items-center">
								<b>PHILIPPINE HEALTH INSURANCE CORPORATION</b>

								<span className="w-4/5 border-b-2 border-black ">
									&nbsp;
								</span>
								<b>(Name of PCB Provider)</b>
							</div>
							<div className="w-[144px] aspect-square" />
						</div>
						<div className="flex flex-col items-center w-full border-b-2 border-black ">
							<b>INDIVIDUAL HEALTH PROFILE</b>
							<div className="flex justify-between w-full">
								<span className="text-xs flex items-center gap-1">
									Print legibly. Mark appropriate boxes{" "}
									<input type="checkbox" readOnly /> with{" "}
									<b>"✓"</b>
								</span>
								<span className="flex items-center gap-1">
									<b>PIN:</b>
									<div className="border-t-2 border-l-2  !border-black h-6 flex items-center divide-x -black">
										<span className="h-6 w-6 !border-black" />
										<span className="h-6 w-6 !border-black" />
										<span className="h-6 w-6 !border-black" />
										<span className="h-6 w-6 !border-black" />
										<span className="h-6 w-6 !border-black" />
										<span className="h-6 w-6 !border-black" />
										<span className="h-6 w-6 !border-black" />
										<span className="h-6 w-6 !border-black" />
										<span className="h-6 w-6 !border-black" />
										<span className="h-6 w-6 !border-black" />
										<span className="h-6 w-6 !border-black" />
										<span className="h-6 w-6 !border-black" />
									</div>
								</span>
							</div>
						</div>
						<div className="grid grid-cols-4 divide-x h-14 border-b-2 border-black   ">
							<div className="border-black relative">
								<span className="bg-gray-200 font-bold absolute top-0 left-0 pr-4 text-xs">
									Patient name:
								</span>
								<span className="absolute top-5 w-full text-center">
									{patient?.lastname}
								</span>
								<span className="absolute bottom-0 right-14 text-xs">
									(Last Name)
								</span>
							</div>
							<div className="border-black relative">
								<span className="absolute bottom-0 left-1 text-xs">
									(First Name)
								</span>
							</div>
							<div className="border-black relative">
								<span className="absolute bottom-0 left-1 text-xs">
									(Middle Name)
								</span>
							</div>
							<div className="border-black relative">
								<span className="absolute bottom-0 left-1 text-xs">
									(Extension: Sr., Jr., etc.)
								</span>
							</div>
						</div>
						<div className="w-full border-b-2 border-black   font-bold text-xs">
							Note: If this is a follow-up consult or 2nd visit,
							please indicate if there are any changes in the
							Basic Demographic Data. Updating of this Individual
							Health Profile must be done before the fiscal year
							ends, to include review of consultation record
							(Annex A.3) Indicate the date when the new data has
							been entered. Please use additional page when
							necessary.
						</div>
						<div className="w-full border-b-2 border-black   font-bold relative h-7">
							<div className="bg-gray-200 absolute top-0 left-0 h-full p-1 pr-4 text-xs font-bold">
								Address:
							</div>
						</div>
						<div className="w-full border-b-2 border-black  relative text-xs p-0 gap-2 flex items-center">
							<div className="bg-gray-200  h-full p-1 pr-4 text-xs font-bold">
								Age:
							</div>
							<label className="mb-0 flex items-center text-xs gap-1 mb-0">
								<input type="checkbox" />0 - 1 year
							</label>
							<label className="mb-0 flex items-center text-xs gap-1 mb-0">
								<input type="checkbox" />2 - 5 years
							</label>
							<label className="mb-0 flex items-center text-xs gap-1 mb-0">
								<input type="checkbox" />6 - 15 years
							</label>
							<label className="mb-0 flex items-center text-xs gap-1 mb-0">
								<input type="checkbox" />
								16 - 24 years
							</label>
							<label className="mb-0 flex items-center text-xs gap-1 mb-0">
								<input type="checkbox" />
								25 - 59 years
							</label>
							<label className="mb-0 flex items-center text-xs gap-1">
								<input type="checkbox" />
								60 years and above
							</label>
						</div>

						<div className="w-full border-b-2 border-black   relative flex divide-x-2">
							<div className="flex">
								<div className="flex flex-col bg-gray-200 border-r text-xs p-1 pr-4">
									<span className="font-bold">
										Birthdate:
									</span>
									<span>(mm/dd/yyyy)</span>
								</div>
								<div className="divide-x h-full w-full ml-auto flex">
									<span className="w-7 h-full flex items-center justify-center">
										<span
											contentEditable={true}
											className="w-full text-center"
										/>
									</span>
									<span className="w-7 h-full flex items-center justify-center">
										<span
											contentEditable={true}
											className="w-full text-center"
										/>
									</span>
									<span className="w-7 h-full flex items-center justify-center text-3xl">
										/
									</span>
									<span className="w-7 h-full flex items-center justify-center">
										<span
											contentEditable={true}
											className="w-full text-center"
										/>
									</span>
									<span className="w-7 h-full flex items-center justify-center">
										<span
											contentEditable={true}
											className="w-full text-center"
										/>
									</span>
									<span className="w-7 h-full  flex items-center justify-center text-3xl">
										/
									</span>
									<span className="w-7 h-full flex items-center justify-center">
										<span
											contentEditable={true}
											className="w-full text-center"
										/>
									</span>
									<span className="w-7 h-full flex items-center justify-center">
										<span
											contentEditable={true}
											className="w-full text-center"
										/>
									</span>
									<span className="w-7 h-full flex items-center justify-center">
										<span
											contentEditable={true}
											className="w-full text-center"
										/>
									</span>
									<span className="w-7 h-full flex items-center justify-center">
										<span
											contentEditable={true}
											className="w-full text-center"
										/>
									</span>
								</div>
							</div>
							<div className="flex w-[128px]">
								<div className="flex flex-col bg-gray-200 text-xs p-1 pr-4">
									<span className="font-bold">Sex:</span>
								</div>
								<div className="flex flex-col p-1 px-2 gap-1">
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										<input type="checkbox" />
										Male
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										<input type="checkbox" />
										Female
									</label>
								</div>
							</div>
							<div className="flex">
								<div className="flex flex-col bg-gray-200 text-xs p-1 pr-4">
									<span className="font-bold">Religion:</span>
								</div>
							</div>
						</div>

						<div className="w-full border-b-2 border-black  relative text-xs p-0 gap-3 flex items-center">
							<div className="bg-gray-200 h-full p-1 pr-2 text-xs font-bold">
								Civil Status:
							</div>
							<label className="mb-0 flex items-center text-xs gap-1">
								<input type="checkbox" />
								Single
							</label>
							<label className="mb-0 flex items-center text-xs gap-1">
								<input type="checkbox" />
								Married
							</label>
							<label className="mb-0 flex items-center text-xs gap-1">
								<input type="checkbox" />
								Annuled
							</label>
							<label className="mb-0 flex items-center text-xs gap-1">
								<input type="checkbox" />
								Widowed
							</label>
							<label className="mb-0 flex items-center text-xs gap-1">
								<input type="checkbox" />
								Separated
							</label>

							<label className="mb-0 flex items-center text-xs gap-1">
								<input type="checkbox" />
								Others, specify{" "}
								<span className="border-b w-28 pt-3" />
							</label>
						</div>

						<div className="w-full border-b-2 border-black   relative flex">
							<div className="flex w-[188px] flex-col border-r-2 border-black  ">
								<div className="flex flex-col bg-gray-200 text-xs p-1 pr-4">
									<span className="font-bold">
										PHIC Membership:
									</span>
								</div>
								<div className="flex flex-col p-2 pl-4 gap-1">
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										<input type="checkbox" />
										Member
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										<input type="checkbox" />
										Dependent
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										<input type="checkbox" />
										Non-Member
									</label>
								</div>
							</div>
							<div className="flex flex-col w-full">
								<div className="flex flex-col bg-gray-200 text-xs p-1 pr-4">
									<span className="font-bold">
										Type of Membership:
									</span>
								</div>
								<div className="flex divide-x">
									<div className="flex flex-col pt-2 px-2">
										<label className="mb-0 flex items-center text-xs gap-1 font-normal">
											<input type="checkbox" />
											Sponsored
										</label>
										<div className="flex flex-col py-2 px-4">
											<div className="grid grid-cols-2 gap-1">
												<label className="mb-0 flex items-center text-xs gap-1 font-normal">
													<input type="checkbox" />
													NHTS
												</label>
												<label className="mb-0 flex items-center text-xs gap-1 font-normal">
													<input type="checkbox" />
													LGU
												</label>
												<label className="mb-0 flex items-center text-xs gap-1 font-normal">
													<input type="checkbox" />
													NGA
												</label>
												<label className="mb-0 flex items-center text-xs gap-1 font-normal">
													<input type="checkbox" />
													Private
												</label>
											</div>
										</div>
									</div>
									<div className="flex flex-col pt-2 px-2">
										<label className="mb-0 flex items-center text-xs gap-1 font-normal">
											<input type="checkbox" />
											Individually Paying Program (IPP)
										</label>
										<div className="flex flex-col py-2 px-4">
											<div className="grid grid-cols-1 gap-1">
												<label className="mb-0 flex items-center text-xs gap-1 font-normal">
													<input type="checkbox" />
													Organized Grop
												</label>
												<label className="mb-0 flex items-center text-xs gap-1 font-normal">
													<input type="checkbox" />
													OFW
												</label>
											</div>
										</div>
									</div>
									<div className="flex flex-col pt-2 px-2">
										<label className="mb-0 flex items-center text-xs gap-1 font-normal">
											<input type="checkbox" />
											Employed
										</label>
										<div className="flex flex-col py-2 px-4">
											<div className="grid grid-cols-1 gap-1">
												<label className="mb-0 flex items-center text-xs gap-1 font-normal">
													<input type="checkbox" />
													Government
												</label>
												<label className="mb-0 flex items-center text-xs gap-1 font-normal">
													<input type="checkbox" />
													Private
												</label>
											</div>
										</div>
									</div>
									<div className="flex flex-col pt-2 px-2">
										<label className="mb-0 flex items-center text-xs gap-1 font-normal">
											<input type="checkbox" />
											Lifetime
										</label>
									</div>
								</div>
							</div>
						</div>

						<div className="w-full border-b-2 border-black   relative h-7 text-xs p-0 gap-6 flex items-center">
							<div className="bg-gray-200 h-full p-1 pr-4 text-xs font-bold">
								Occupation:
							</div>
						</div>

						<div className="w-full border-b-2 border-black   relative text-xs p-0 flex flex-col items-center">
							<div className="bg-gray-200 p-1 pr-4 text-xs font-bold w-full">
								Highest Completed Educational Attainment:
							</div>
							<div className="flex items-center justify-center p-1 gap-11">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									College degree, post graduate
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									High School
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Elementary
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Vocational
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									No Schooling
								</label>
							</div>
						</div>

						<div className="w-full border-b-2 border-black   relative text-xs p-0 flex flex-col items-center">
							<div className="bg-gray-200 p-1 pr-4 text-xs font-bold w-full">
								Past Medical History:
							</div>

							<div className="grid grid-cols-3 gap-1 px-2 py-2">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Allergy, specify{" "}
									<span className="w-[96px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Emphysema
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Pneumonia
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Astma
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Epilepsy/Seizure disorder
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Thyroid disease
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Cancer, specify organ
									<span className="w-[64px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Hepatitis, specify type
									<span className="w-[64px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Tuberculosis, specify organ
									<span className="w-[64px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Cerebrovascular disease
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Hyperlipidemia
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal pl-6">
									If PTB, what category
									<span className="w-[88px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Coronary artery disease
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Hypertension, highest BP
									<span className="w-[64px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Urinary tract infection
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Diabetes mellitus
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Peptic ulcer disease
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Others
									<span className="w-[162px] border-b pt-2" />
								</label>
							</div>
						</div>

						<div className="w-full border-b-2 border-black   relative text-xs p-0 flex flex-col items-center">
							<div className="bg-gray-200 p-1 pr-4 text-xs font-bold w-full">
								Past Surgical History:
							</div>

							<div className="grid grid-cols-3 gap-3 px-8 py-2 w-full">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal col-span-2">
									Operation:
									<span className="w-[288px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									Date:
									<span className="w-[128px] border-b pt-2" />
								</label>
							</div>
							<div className="grid grid-cols-3 gap-3 px-8 py-2 w-full">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal col-span-2">
									Operation:
									<span className="w-[288px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									Date:
									<span className="w-[128px] border-b pt-2" />
								</label>
							</div>
						</div>

						<div className="w-full border-b-2 border-black   relative text-xs p-0 flex flex-col items-center">
							<div className="bg-gray-200 p-1 pr-4 text-xs font-bold w-full">
								Family Medical History:
							</div>

							<div className="grid grid-cols-3 gap-1 px-2 py-2">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Allergy, specify{" "}
									<span className="w-[96px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Emphysema
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Pneumonia
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Astma
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Epilepsy/Seizure disorder
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Thyroid disease
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Cancer, specify organ
									<span className="w-[64px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Hepatitis, specify type
									<span className="w-[64px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Tuberculosis, specify organ
									<span className="w-[64px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Cerebrovascular disease
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Hyperlipidemia
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal pl-6">
									If PTB, what category
									<span className="w-[88px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Coronary artery disease
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Hypertension, highest BP
									<span className="w-[64px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Urinary tract infection
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Diabetes mellitus
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Peptic ulcer disease
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Others
									<span className="w-[162px] border-b pt-2" />
								</label>
							</div>
						</div>

						<div className="w-full  relative text-xs p-0 flex flex-col items-center">
							<div className="bg-gray-200 p-1 pr-4 text-xs font-bold w-full">
								Personal/Social History:
							</div>
							<div className="flex flex-col px-6 gap-y-2 py-3 w-full">
								<div className="flex items-center gap-6">
									<div className="text-xs w-[15%]">
										Smoking:
									</div>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal w-[10%]">
										<input type="checkbox" />
										Yes
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal w-[10%]">
										<input type="checkbox" />
										No
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal w-[10%]">
										<input type="checkbox" />
										Quit
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal pl-6">
										No. of pack years?
										<span className="w-[88px] border-b pt-2" />
									</label>
								</div>
								<div className="flex items-center gap-6">
									<div className="text-xs w-[15%]">
										Alcohol:
									</div>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal w-[10%]">
										<input type="checkbox" />
										Yes
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal w-[10%]">
										<input type="checkbox" />
										No
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal w-[10%]">
										<input type="checkbox" />
										Quit
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal pl-6">
										No. of bottles/day?
										<span className="w-[88px] border-b pt-2" />
									</label>
								</div>
								<div className="flex items-center gap-6">
									<div className="text-xs w-[15%]">
										Illicit drugs:
									</div>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal w-[10%]">
										<input type="checkbox" />
										Yes
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal w-[10%]">
										<input type="checkbox" />
										No
									</label>
								</div>
							</div>
						</div>
					</div>

					<div className="w-full bg-white border-2 border-black  !min-h-[12in] mb-11">
						<div className="w-full border-b-2 border-black   relative text-xs p-0 flex flex-col items-center">
							<div className="bg-gray-200 p-1 pr-4 text-xs font-bold w-full">
								Immunizations:
							</div>
						</div>
						<div className="w-full border-b-2 border-black   relative text-xs p-0 flex">
							<div className="bg-gray-200 h-full p-1 pr-4 text-xs">
								For children:
							</div>
							<div className="grid grid-cols-7 gap-1 pl-4 py-4">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									BCG
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									OPV1
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									OPV2
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									OPV3
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									DPT1
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									DPT2
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									DPT3
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Measles
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Hepatitis B1
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Hepatitis B2
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Hepatitis A
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal col-span-2">
									<input type="checkbox" />
									Varicella (Chicken Pox)
								</label>
							</div>
						</div>
						<div className="w-full border-b-2 border-black   relative text-xs p-0 flex">
							<div className="bg-gray-200 h-full p-1 pr-4 text-xs">
								For young women:
							</div>
							<div className="grid grid-cols-2 gap-1 pl-4 py-1 border-r-2 border-black   pr-11">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									HPV
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									MMR
								</label>
							</div>
							<div className="bg-gray-200 h-full p-1 pr-4 text-xs">
								For pregnant women:
							</div>
							<div className="grid grid-cols-2 gap-1 pl-4 py-1 pr-11">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Tetanus toxoid
								</label>
							</div>
						</div>

						<div className="w-full border-b-2 border-black   relative text-xs p-0 flex">
							<div className="bg-gray-200 h-full p-1 pr-4 text-xs">
								For elderly and immunocompromised:
							</div>
							<div className="grid grid-cols-2 gap-1 py-1  px-11">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Pnuemococcal vaccine
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									<input type="checkbox" />
									Flue vaccine
								</label>
							</div>
						</div>
						<div className="w-full border-b-2 border-black   relative h-7 text-xs p-0 gap-6 flex items-center">
							<div className="bg-gray-200 h-full p-1 pr-4 text-xs">
								Others, specify:
							</div>
						</div>

						<div className="w-full border-b-2 border-black   relative text-xs p-0 flex flex-col items-center">
							<div className="bg-gray-200 p-1 pr-4 text-xs font-bold w-full">
								Menstrual History:
							</div>

							<div className="grid grid-cols-2 gap-3 px-8 py-1 w-full">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									Menarche:
									<span className="w-[128px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									Onset of sexual intercourse:
									<span className="w-[128px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									Last Menstrual Period:
									<span className="w-[128px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									Birth control method:
									<span className="w-[128px] border-b pt-2" />
								</label>
							</div>
							<div className="grid grid-cols-3 gap-3 px-8 py-1 w-full">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									Period Duration:
									<span className="w-[128px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									Interval/Cycle:
									<span className="w-[128px] border-b pt-2" />
								</label>
								<span className="flex items-center text-xs gap-4 font-normal">
									Menupause?
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										<input type="checkbox" />
										Yes
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										<input type="checkbox" />
										No
									</label>
								</span>
							</div>

							<div className="grid grid-cols-3 gap-3 px-8 pb-2 pt-1 w-full">
								<label className="mb-0 flex items-center text-xs gap-1 col-span-2 font-normal">
									No. of pads/day during menstruation:
									<span className="w-[128px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									If yes, what age?:
									<span className="w-[64px] border-b pt-2" />
								</label>
							</div>
						</div>

						<div className="w-full relative text-xs p-0 flex flex-col items-center">
							<div className="bg-gray-200 p-1 pr-4 text-xs font-bold w-full">
								Pregnancy History:
							</div>

							<div className="grid grid-cols-3 gap-1 px-4 pt-2 pb-1 w-full">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									Gravity(no. of pregnancy):
									<span className="w-[72px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									Parity(no. of delivery):
									<span className="w-[72px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									Type of Delivery:
									<span className="w-[72px] border-b pt-2" />
								</label>
							</div>

							<div className="grid grid-cols-4 gap-3 px-4 py-1 w-full">
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									# of Full term:
									<span className="w-[64px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									# of Premature:
									<span className="w-[64px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									# of Abortion:
									<span className="w-[64px] border-b pt-2" />
								</label>
								<label className="mb-0 flex items-center text-xs gap-1 font-normal">
									# of Living Children:
									<span className="w-[64px] border-b pt-2" />
								</label>
							</div>

							<div className="grid grid-cols-1 gap-3 border-b-2 border-black   px-4 pt-1 pb-2 w-full">
								<span className="flex items-center text-xs gap-4 font-normal">
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										<input type="checkbox" />
										Pregnancy-included hypertension
										(Pre-eclampsia)
									</label>
								</span>
							</div>

							<div className="w-full border-b-2 border-black   relative text-xs p-0 gap-6 flex items-center">
								<div className="bg-gray-200  top-0 left-0 h-full p-1 pr-4 text-xs font-bold">
									Access to Family Planing counceling:
								</div>
								<label className="mb-0 flex items-center text-xs gap-1">
									<input type="checkbox" />
									Yes
								</label>
								<label className="mb-0 flex items-center text-xs gap-1">
									<input type="checkbox" />
									No
								</label>
							</div>

							<div className="w-full border-b-2 border-black   relative text-xs p-0 flex flex-col items-center">
								<div className="bg-gray-200 p-1 pr-4 text-xs font-bold w-full">
									Patient Physical Examination Findings:
								</div>
								<div className="grid grid-cols-2 gap-x-11 gap-y-4 px-11 py-2 w-full">
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										BP:
										<span className="w-[128px] border-b pt-2" />
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										Height:
										<span className="w-[128px] border-b pt-2" />
										(cm)
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										HR:
										<span className="w-[128px] border-b pt-2" />
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										Weight:
										<span className="w-[128px] border-b pt-2" />
										(kg)
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										RR:
										<span className="w-[128px] border-b pt-2" />
									</label>
									<label className="mb-0 flex items-center text-xs gap-1 font-normal">
										Wait circumference:
										<span className="w-[128px] border-b pt-2" />
										(cm)
									</label>
								</div>
							</div>
							<div className="w-full border-b-2 border-black   relative text-xs p-0 flex">
								<div className="bg-gray-200  top-0 left-0 p-1 pr-5 text-xs font-bold">
									Skin:
								</div>
								<div className="flex flex-col gap-4 py-4 px-4 w-full">
									<div className="grid grid-cols-4 gap-4 ">
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											pallor
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											rashes
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											jaundice
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											good skin turgor
										</label>
									</div>
									<span className="w-full border-b pt-1" />
									<span className="w-full border-b pt-1" />
								</div>
							</div>

							<div className="w-full border-b-2 border-black   relative text-xs p-0 flex">
								<div className="bg-gray-200  top-0 left-0 p-1 pr-1 text-xs font-bold">
									HEENT:
								</div>
								<div className="flex flex-col gap-4 py-4 pl-2 pr-4 w-full">
									<div className="grid grid-cols-12 gap-y-4 ">
										<label className="mb-0 flex items-center text-xs gap-1 col-span-3">
											<input type="checkbox" />
											anicteric sclerae
										</label>
										<label className="mb-0 flex items-center text-xs gap-1 col-span-4 pl-4">
											<input type="checkbox" />
											intact tympanic memberane
										</label>
										<label className="mb-0 flex items-center text-xs gap-1 col-span-4">
											<input type="checkbox" />
											tonsillopharyngeal congestion
										</label>
										<label className="mb-0 flex items-center text-xs gap-1 col-span-1">
											<input type="checkbox" />
											exudates
										</label>
										<label className="mb-0 flex items-center text-xs gap-1 col-span-3">
											<input type="checkbox" />
											pupils briskly reactive to light
										</label>
										<label className="mb-0 flex items-center text-xs gap-1 col-span-4 pl-4">
											<input type="checkbox" />
											alar flaring
										</label>
										<label className="mb-0 flex items-center text-xs gap-1 col-span-4">
											<input type="checkbox" />
											hypertrophic tonsils
										</label>
										<label className="mb-0 flex items-center text-xs gap-1 col-span-3">
											<input type="checkbox" />
											aural discharge
										</label>
										<label className="mb-0 flex items-center text-xs gap-1 col-span-4 pl-4">
											<input type="checkbox" />
											nasal discharge
										</label>
										<label className="mb-0 flex items-center text-xs gap-1 col-span-4">
											<input type="checkbox" />
											palpable mass
										</label>
									</div>
									<span className="w-full border-b pt-1" />
									<span className="w-full border-b pt-1" />
								</div>
							</div>

							<div className="w-full border-b-2 border-black   relative text-xs p-0 flex">
								<div className="bg-gray-200  top-0 left-0 p-1 pr-5 text-xs font-bold">
									Chest/Lungs:
								</div>
								<div className="flex flex-col gap-4 py-3 px-4 w-full">
									<div className="grid grid-cols-3 gap-4 ">
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											symmetrical chest expansion
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											retractions
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											wheezes
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											clear breathsounds
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											crackles/rales
										</label>
									</div>
									<span className="w-full border-b pt-1" />
									<span className="w-full border-b pt-1" />
								</div>
							</div>

							<div className="w-full border-b-2 border-black   relative text-xs p-0 flex">
								<div className="bg-gray-200  top-0 left-0 p-1 pr-5 text-xs font-bold">
									Heart:
								</div>
								<div className="flex flex-col gap-4 py-2 px-4 w-full">
									<div className="grid grid-cols-4 gap-1 ">
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											adynamic precordium
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											normal rate regular rhythm
										</label>
										<label className="mb-0 flex items-center text-xs gap-1 pl-4">
											<input type="checkbox" />
											heaves/thrills
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											murmurs
										</label>
									</div>
									<span className="w-full border-b pt-1" />
									<span className="w-full border-b pt-1" />
								</div>
							</div>

							<div className="w-full border-b-2 border-black   relative text-xs p-0 flex">
								<div className="bg-gray-200  top-0 left-0 p-1 pr-5 text-xs font-bold">
									Abdomen:
								</div>
								<div className="flex flex-col gap-4 py-2 px-4 w-full">
									<div className="grid grid-cols-3 gap-1 ">
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											flat
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											flabby
										</label>
										<label className="mb-0 flex items-center text-xs gap-1 ">
											<input type="checkbox" />
											tenderness
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											globular
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											muscle guarding
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											palpable mass
										</label>
									</div>
									<span className="w-full border-b pt-1" />
									<span className="w-full border-b pt-1" />
								</div>
							</div>

							<div className="w-full relative text-xs p-0 flex">
								<div className="bg-gray-200  top-0 left-0 p-1 pr-5 text-xs font-bold">
									Extremities:
								</div>
								<div className="flex flex-col gap-4 py-2 px-4 w-full">
									<div className="grid grid-cols-3 gap-1 ">
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											gross deformity
										</label>
										<label className="mb-0 flex items-center text-xs gap-1">
											<input type="checkbox" />
											normal gait
										</label>
										<label className="mb-0 flex items-center text-xs gap-1 pl-4">
											<input type="checkbox" />
											full and equal pulses
										</label>
									</div>
									<span className="w-full border-b pt-1" />
									<span className="w-full border-b pt-1" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</CardContainer>
		</LayoutContainer>
	);
};

export default PhoPhilhealthForm;
