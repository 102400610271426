import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";

import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import VitalsForm from "src/clinic/components/VitalsForm";

const ReferToSPHModal = (props, ref) => {
	const { onSuccess } = props;
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(null);
	const [loading, setLoading] = useState(false);
	const [prescriptionItems, setPrescriptionItems] = useState([]);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (data) => {
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const submit = (data) => {
		setLoading(true);
		let formData = new FormData();
		formData.append("appointment_id", appointment?.id);
		formData.append("_method", "PATCH");

		axios
			.post(`/v1/clinic/tb-refer-to-sph/${appointment?.id}`, formData)
			.then((res) => {
				// addToList(data);
				setTimeout(() => {
					setLoading(false);
					onSuccess();
					toast.success("Patient successfully referred to SPH!");
				}, 400);
				hide();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Modal open={open} hide={hide} className="min-w-[512px] w-full">
			<ModalHeader
				title={`Confirm patient referral`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`p-4  bg-white`}>
				<h3 className="text-lg font-bold text-center">
					Are you sure to refer patient to SPH?
				</h3>
			</ModalBody>
			<ModalFooter className={"flex items-center"}>
				<ActionBtn
					className="mr-auto"
					type="danger"
					onClick={hide}
					disabled={loading}
				>
					No
				</ActionBtn>
				<ActionBtn
					className="ml-auto"
					type="success"
					onClick={handleSubmit(submit)}
					loading={loading}
				>
					Yes
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(ReferToSPHModal);
