import {
	Box,
	Dialog,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, Fragment, useRef } from "react";
import Notify from "../../notification/Notify";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "../../utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import SorologyRef from "../references/Ref_Sorology";
// import FormvalidationSorology from "./validation/FormvalidationSorology";
import { UsersData } from "src/ContextAPI";
// import Specimen from "./Specimen";
import { CheckSquare, XCircle } from "react-feather";

const SorologyOrderDetails = ({
	queue,
	formheader,
	order,
	getLabSorologyOrder,
	resetDisplay,
	process_for,
}) => {
	const { users } = React.useContext(UsersData);
	const history = useHistory();
	const [orderDetails, setOrderDetails] = useState({
		data: [],
		ready: false,
	});

	const [pendingDialog, setPendingDialog] = useState(false);
	const [processDialog, setProcessDialog] = useState({
		data: null,
		dialog: false,
	});

	const [pendingSubmitting, setPendingSubmitting] = useState(false);
	const [processSubmitting, setProcessSubmitting] = useState(false);
	const [resultSubmitting, setResultSubmitting] = useState(false);
	const [savebtnDisabled, setSavebtnDisabled] = useState(true);

	const [orderItems, setOrderItems] = useState([]);

	const [confirmDialog, setConfirmDialog] = useState(false);
	const saveButtonRef = useRef();

	const getLabSoroOrderDetails = () => {
		var params = { user_id: users.user_id, trace_number: order.trace_number };
		Axios.get("laboratory/order/ordernew-sorology/details", { params })
			.then((response) => {
				const data = response.data;
				setOrderDetails({
					data: data,
					ready: true,
				});

				checkIfSaveBtnIsEnabled(data);
			})
			.catch((error) => {
				Notify.requestError(error);
			});
	};

	const checkIfSaveBtnIsEnabled = (data) => {
		for (let i = 0; i < data.length; i++) {
			if (Boolean(data[i].is_processed)) {
				setSavebtnDisabled(false);
			}
		}
	};

	useEffect(() => {
		getLabSoroOrderDetails();

		// eslint-disable-next-line
	}, [order]);

	const handleSaveResult = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("trace_number", order.trace_number);
		formdata.set("queue", queue);
		formdata.set("process_for", process_for);

		var error = [];

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setResultSubmitting(true);
			Axios.post(
				"laboratory/order/ordernew-sorology/save-process-result",
				formdata
			)
				.then((response) => {
					const data = response.data;
					if (data === "success") {
						setSavebtnDisabled(true);
						Notify.successRequest("order result added.");
						setTimeout(() => {
							history.push(
								`/sph/app/laboratory/record/print/order/${order.trace_number}`
							);
						}, 5000);
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				});
		}
	};

	const handlePendingOrder = (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", order.order_id);

		var error = [];

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			setPendingSubmitting(true);
			Axios.post("laboratory/order/ordernew-sorology/save-setpending", formdata)
				.then((response) => {
					const data = response.data;
					if (data === "pass-invalid") {
						Notify.fieldInvalid("password");
					}
					if (data === "success") {
						getLabSorologyOrder();
						resetDisplay();
						setPendingDialog(false);
						Notify.successRequest("order pending");
					}
				})
				.catch((error) => {
					Notify.requestError(error);
				})
				.finally(() => setPendingSubmitting(false));
		}
	};

	const handleProcessOrder = async (e) => {
		e.preventDefault();
		e.persist();

		var formdata = new FormData(e.target);
		formdata.set("user_id", users.user_id);
		formdata.set("username", users.username);
		formdata.set("order_id", processDialog.data.order_id);
		formdata.set("trace_number", processDialog.data.trace_number);

		var error = [];
		setProcessSubmitting(true);

		if (error.length > 0) {
			console.log("Form has an error.");
		} else {
			let response = await Axios.post(
				"laboratory/serology/order-setprocess/custom-qty",
				formdata
			);

			if (response.data.message === "reagent-error") {
				Notify.customToast("Reagent Not Error", "Reagent/items not available.");
			}

			if (response.data.message === "pass-invalid") {
				Notify.fieldInvalid("password");
			}

			if (response.data.message === "success") {
				Notify.successRequest("process");
				setProcessDialog({ data: null, dialog: false });
				getLabSoroOrderDetails();
			}
		}

		setTimeout(() => {
			setProcessSubmitting(false);
		}, 2000);
	};

	const handleProcessSelectedOrder = async (data) => {
		let response = await Axios.get(
			"laboratory/items/laborder/list-available-items",
			{
				params: {
					user_id: users.user_id,
					management_id: users.management_id,
					order_id: data.order_id,
				},
			}
		);

		setOrderItems(response.data);

		setProcessDialog({ data: data, dialog: true });
	};

	return (
		<>
			<form onSubmit={handleSaveResult}>
				<Card elevation={0}>
					<Box>
						<CardHeader
							component={Box}
							align="center"
							title={formheader && formheader.name}
							subheader={
								<Box>
									<Typography>{formheader && formheader.address}</Typography>
									<Typography>
										{formheader && formheader.contact_number}
									</Typography>
								</Box>
							}
						/>
					</Box>
					<Box>
						<Typography variant="h6" align="center">
							<b> SEROLOGY </b>
						</Typography>
					</Box>
					<CardContent>
						{/* paitent information */}
						<Box display="flex">
							<Box flexGrow={1} mb={2}>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT NAME:{" "}
										</Typography>
										{`${order.lastname}, ${order.firstname} ${
											order.middle === null ? "" : order.middle
										}`}
									</Typography>
								</Box>

								<Box display="flex" mb={2}>
									<Box>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												AGE:{" "}
											</Typography>
											{order.birthday === null
												? "none"
												: Notify.calculateAge(order.birthday)}
										</Typography>
									</Box>
									<Box ml={5}>
										<Typography>
											<Typography
												variant="caption"
												className="font-weight-bold"
											>
												GENDER:{" "}
											</Typography>
											{order.gender === null ? "none" : order.gender}
										</Typography>
									</Box>
								</Box>

								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											PATIENT ADDRESS:{" "}
										</Typography>
										{`${order.street} ${order.barangay} ${order.city} `}
									</Typography>
								</Box>

								<Box>
									<Typography variant="caption" className="font-weight-bold">
										PATIENT CONDITION:{" "}
									</Typography>
									{order.patient_condition === null
										? " none"
										: order.patient_condition}
								</Box>
							</Box>

							<Box>
								<Box mb={2}>
									<Typography>
										<Typography variant="caption" className="font-weight-bold">
											DATE:{" "}
										</Typography>
										{Notify.createdAt(new Date().toLocaleString())}
									</Typography>
								</Box>
							</Box>
						</Box>
					</CardContent>

					{/* <CardContent>
            <Specimen
              patient_id={order.patient_id}
              trace_number={order.trace_number}
            />
          </CardContent> */}

					<CardContent>
						{/* order details */}
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align="center">
											<b> Test </b>
										</TableCell>
										<TableCell align="center">
											<b> RESULT </b>
										</TableCell>
										<TableCell align="center">
											<b> NORMAL VALUES </b>
										</TableCell>
										<TableCell align="center">
											<b> ACTION </b>
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{orderDetails.ready ? (
										orderDetails.data.length > 0 ? (
											orderDetails.data.map((data, index) => (
												<Fragment key={index}>
													<TableRow hidden={data.hbsag === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																Hepatitis B surface Antigen (HBsAg)
															</Typography>
														</TableCell>

														<TableCell align="center">
															{/* hidden inputs */}
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="order_id[]"
																	label="order_id"
																	hidden
																	value={data.order_id}
																/>
																<TextField
																	margin="dense"
																	fullWidth
																	name="patient_id"
																	label="patient_id"
																	hidden
																	value={data.patient_id}
																/>

																<TextField
																	margin="dense"
																	fullWidth
																	name="doctor_id"
																	label="Result"
																	hidden
																	value={
																		data.doctor_id === null
																			? ""
																			: data.doctor_id
																	}
																/>
															</Box>
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="hbsag[]"
																	label="Result"
																	// required={data.hbsag === null ? false : true}
																	InputProps={{
																		readOnly: !Boolean(data.is_processed),
																	}}
																/>
															</Box>
														</TableCell>

														<TableCell align="center">
															{SorologyRef.normal()}
														</TableCell>

														<TableCell align="center">
															<Button
																disabled={Boolean(data.is_processed)}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.hav === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																HAV (Hepatitis A Virus) lgG/lgM
															</Typography>
														</TableCell>

														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="hav[]"
																	label="Result"
																	// required={data.hav === null ? false : true}
																	InputProps={{
																		readOnly: !Boolean(data.is_processed),
																	}}
																/>
															</Box>
														</TableCell>

														<TableCell align="center">
															{SorologyRef.normal()}
														</TableCell>

														<TableCell align="center">
															<Button
																disabled={Boolean(data.is_processed)}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.vdrl_rpr === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																VDRL/RPR
															</Typography>
														</TableCell>

														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="vdrl_rpr[]"
																	label="Result"
																	// required={
																	//   // data.vdrl_rpr === null ? false : true
																	// }
																	InputProps={{
																		readOnly: !Boolean(data.is_processed),
																	}}
																/>
															</Box>
														</TableCell>

														<TableCell align="center">
															{SorologyRef.normal()}
														</TableCell>

														<TableCell align="center">
															<Button
																disabled={Boolean(data.is_processed)}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.anti_hbc_igm === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																ANTI-HBC IGM
															</Typography>
														</TableCell>

														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="anti_hbc_igm[]"
																	label="Result"
																	// required={
																	//   data.anti_hbc_igm === null ? false : true
																	// }
																	InputProps={{
																		readOnly: !Boolean(data.is_processed),
																	}}
																/>
															</Box>
														</TableCell>

														<TableCell align="center">
															{/* reference not set */}
														</TableCell>

														<TableCell align="center">
															<Button
																disabled={Boolean(data.is_processed)}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.hcv === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																HCV (Hepatitis C Virus)
															</Typography>
														</TableCell>

														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="hcv[]"
																	label="Result"
																	// required={data.hcv === null ? false : true}
																	InputProps={{
																		readOnly: !Boolean(data.is_processed),
																	}}
																/>
															</Box>
														</TableCell>

														<TableCell align="center">
															{SorologyRef.normal()}
														</TableCell>

														<TableCell align="center">
															<Button
																disabled={Boolean(data.is_processed)}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.beta_hcg_quali === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																BETA HCG (QUALI)
															</Typography>
														</TableCell>

														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="beta_hcg_quali[]"
																	label="Result"
																	// required={
																	//   data.beta_hcg_quali === null ? false : true
																	// }
																	InputProps={{
																		readOnly: !Boolean(data.is_processed),
																	}}
																/>
															</Box>
														</TableCell>

														<TableCell align="center">
															{/* reference not set */}
														</TableCell>

														<TableCell align="center">
															<Button
																disabled={Boolean(data.is_processed)}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.h_pylori === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																H. PYLORI
															</Typography>
														</TableCell>

														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="h_pylori[]"
																	label="Result"
																	// required={
																	//   data.h_pylori === null ? false : true
																	// }
																	InputProps={{
																		readOnly: !Boolean(data.is_processed),
																	}}
																/>
															</Box>
														</TableCell>

														<TableCell align="center">
															{/* reference not set */}
														</TableCell>

														<TableCell align="center">
															<Button
																disabled={Boolean(data.is_processed)}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.typhidot === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																TYPHIDOT
															</Typography>
														</TableCell>

														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="typhidot[]"
																	label="Result"
																	// required={
																	//   data.typhidot === null ? false : true
																	// }
																	InputProps={{
																		readOnly: !Boolean(data.is_processed),
																	}}
																/>
															</Box>
														</TableCell>

														<TableCell align="center">
															{/* reference not set */}
														</TableCell>

														<TableCell align="center">
															<Button
																disabled={Boolean(data.is_processed)}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
													</TableRow>

													<TableRow
														hidden={!Boolean(parseInt(data.syphilis_test))}
													>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																VDRL / SYPHILIS TEST
															</Typography>
														</TableCell>

														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="syphilis_test_result[]"
																	label="Result"
																	InputProps={{
																		readOnly: !Boolean(data.is_processed),
																	}}
																/>
															</Box>
														</TableCell>

														<TableCell align="center">
															{/* reference not set */}
														</TableCell>

														<TableCell align="center">
															<Button
																disabled={Boolean(data.is_processed)}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.hact === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																HACT
															</Typography>
														</TableCell>

														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="hact[]"
																	label="Result"
																	// required={data.hact === null ? false : true}
																	InputProps={{
																		readOnly: !Boolean(data.is_processed),
																	}}
																/>
															</Box>
														</TableCell>

														<TableCell align="center">
															{/* reference not set */}
														</TableCell>

														<TableCell align="center">
															<Button
																disabled={Boolean(data.is_processed)}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
													</TableRow>

													<TableRow hidden={data.ana === null}>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																ANA
															</Typography>
														</TableCell>

														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="ana[]"
																	label="Result"
																	// required={data.ana === null ? false : true}
																	InputProps={{
																		readOnly: !Boolean(data.is_processed),
																	}}
																/>
															</Box>
														</TableCell>

														<TableCell align="center">
															{/* reference not set */}
														</TableCell>

														<TableCell align="center">
															<Button
																disabled={Boolean(data.is_processed)}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
													</TableRow>

													<TableRow
														hidden={!Boolean(parseInt(data.dengue_test))}
													>
														<TableCell>
															<Typography
																variant="subtitle2"
																className={`gtc-uppercase`}
															>
																DENGUE TEST
															</Typography>
														</TableCell>

														<TableCell align="center">
															<Box>
																<TextField
																	margin="dense"
																	fullWidth
																	name="dengue_test_result[]"
																	label="Result"
																	// required={
																	//   data.dengue_test_result === null
																	//     ? false
																	//     : true
																	// }
																	InputProps={{
																		readOnly: !Boolean(data.is_processed),
																	}}
																/>
															</Box>
														</TableCell>

														<TableCell align="center">
															{/* reference not set */}
														</TableCell>

														<TableCell align="center">
															<Button
																disabled={Boolean(data.is_processed)}
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	handleProcessSelectedOrder(data);
																}}
															>
																Process
															</Button>
														</TableCell>
													</TableRow>
												</Fragment>
											))
										) : (
											<TableRow>
												<TableCell align="center" colSpan={3}>
													<Typography color="secondary" variant="subtitle2">
														No record found.
													</Typography>
												</TableCell>
											</TableRow>
										)
									) : (
										<TableRow>
											<TableCell align="center" colSpan={3}>
												<Typography color="primary" variant="subtitle2">
													please wait...
												</Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>

					<Box m={1}>
						<TextField margin="dense" fullWidth name="remarks" label="Others" />
					</Box>

					<Box display="flex" m={1}>
						<Box flexGrow={1} />
						<Box>
							<CardActions>
								<Button
									variant="contained"
									size="large"
									color="primary"
									type="submit"
									hidden
									ref={saveButtonRef}
									disabled={!confirmDialog}
								>
									Save Result Button
								</Button>

								<Button
									variant="contained"
									size="large"
									color="primary"
									onClick={() => setConfirmDialog(true)}
									disabled={savebtnDisabled}
								>
									Save Result
								</Button>
							</CardActions>
						</Box>
					</Box>
				</Card>
			</form>

			{/* confirmation dialog */}
			<Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
				<DialogContent>
					<Typography>Are you sure to continue and save result?</Typography>
				</DialogContent>

				<DialogActions>
					<Button
						variant="contained"
						color="default"
						startIcon={<XCircle />}
						onClick={() => setConfirmDialog(false)}
					>
						No
					</Button>
					<Button
						variant="contained"
						color="primary"
						disabled={resultSubmitting}
						startIcon={
							resultSubmitting ? (
								<CircularProgress size={20} color="inherit" />
							) : (
								<CheckSquare />
							)
						}
						onClick={() => saveButtonRef.current.click()}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>

			{/* set as pending dialog */}
			<Dialog
				open={pendingDialog}
				onClose={() => setPendingDialog(false)}
				disableBackdropClick
				PaperComponent={DraggableDialog}
			>
				<DialogTitle id="draggable-handle">Set as pending</DialogTitle>
				<form onSubmit={handlePendingOrder}>
					<DialogContent dividers>
						<Box mb={2}>
							<TextField
								rows={5}
								fullWidth
								name="reason"
								label={`Pending Reason`}
								variant="outlined"
								multiline
							/>
						</Box>

						<Box>
							<TextField
								fullWidth
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setPendingDialog(false)}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="secondary"
							type="submit"
							disabled={pendingSubmitting}
							startIcon={
								pendingSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<ErrorOutlineIcon />
								)
							}
						>
							pending
						</Button>
					</DialogActions>
				</form>
			</Dialog>

			{/* set as process dialog */}
			<Dialog
				open={processDialog.dialog}
				onClose={() => setProcessDialog({ data: null, dialog: false })}
				disableBackdropClick
				PaperComponent={DraggableDialog}
				maxWidth={"xs"}
				fullWidth
			>
				<DialogTitle id="draggable-handle">Process order</DialogTitle>
				<form onSubmit={handleProcessOrder}>
					<DialogContent dividers>
						{orderItems.length > 0
							? orderItems.map((data, index) => (
									<Box key={index} mb={2}>
										<Box>
											<TextField
												fullWidth
												name="item_id[]"
												value={data.item_id}
												variant="outlined"
												hidden
											/>
										</Box>

										<Box>
											<TextField
												fullWidth
												required
												label={`${data.description} qty to process`}
												name="qty[]"
												variant="outlined"
												defaultValue={1}
												InputLabelProps={{
													shrink: true,
												}}
												// InputProps={{
												//   inputProps: {
												//     min: 0,
												//     max: data._total_qty_available,
												//   },
												// }}
												type="number"
											/>
										</Box>
									</Box>
							  ))
							: "No reagent/item found."}

						<Box mt={2}>
							<TextField
								fullWidth
								required
								defaultValue="ok"
								name="remarks"
								label={`Remarks`}
								variant="outlined"
							/>
						</Box>

						<Box mt={2}>
							<TextField
								fullWidth
								required
								name="password"
								label={`Password`}
								variant="outlined"
								type="password"
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="default"
							onClick={() => setProcessDialog({ data: null, dialog: false })}
							startIcon={<HighlightOffIcon />}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={processSubmitting}
							startIcon={
								processSubmitting ? (
									<CircularProgress size={20} color="inherit" />
								) : (
									<CheckCircleIcon />
								)
							}
						>
							process
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default SorologyOrderDetails;
