import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Paper,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CheckSquare, XCircle } from "react-feather";
import { useParams } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const SentToAdmitting = ({ patient_id, setCategory }) => {
  const { users } = React.useContext(UsersData);
  const [isProcess, setIsProcess] = useState(false);
  const [orders, setOrders] = useState("");
  const mounted = IsMountedRef();
  const { tracenumber } = useParams();

  const [patientExist, setPatientExist] = useState(false);

  const getPatientCurrentAdmittingRecord = React.useCallback(async () => {
    let { data } = await axios.get(`doctors/patient/get-admitting-record`, {
      params: {
        patient_id,
        trace_number: tracenumber,
      },
    });

    if (mounted.current) {
      setOrders(data?.doctors_order ?? "");
    }
  }, [patient_id, mounted, tracenumber]);

  const handleSentToAdmin = () => {
    var formdata = new FormData();
    formdata.append(`patient_id`, patient_id);
    formdata.append(`user_id`, users.user_id);
    formdata.append(`management_id`, users.management_id);
    formdata.append(`doctors_order`, orders);
    formdata.append(`trace_number`, tracenumber);

    setIsProcess(true);
    axios
      .post(`doctors/patient/sent-to-admitting`, formdata)
      .then(({ data }) => {
        if (data.message === "pateint-exist") {
          Notify.fieldInvalid("Patient is currently admitted");
        }

        if (data.message === "success") {
          setCategory("appointment");
          Notify.successRequest("patient admit");
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsProcess(false));
  };

  const checkPatientInAdmitting = React.useCallback(async () => {
    let { data } = await axios.get(`doctors/patient/check-in-admitting`, {
      params: {
        patient_id,
        trace_number: tracenumber,
      },
    });

    if (mounted.current) {
      if (data.message === "patient-exist") {
        setPatientExist(true);
      }
    }
  }, [mounted, patient_id, tracenumber]);

  useEffect(() => {
    getPatientCurrentAdmittingRecord();
    checkPatientInAdmitting();
  }, [getPatientCurrentAdmittingRecord, checkPatientInAdmitting]);

  return (
    <div>
      {patientExist ? (
        <Paper>
          <Box p={2}>
            <Typography align="center" color="secondary">
              Patient is currently in room operations.
            </Typography>
          </Box>
        </Paper>
      ) : (
        <Dialog open={true} maxWidth={"xs"} fullWidth>
          <DialogTitle>Sent patient for admission </DialogTitle>
          <DialogContent dividers>
            <Box>
              <TextField
                label={`Order`}
                fullWidth
                multiline
                variant="outlined"
                rows={6}
                value={orders}
                onChange={(e) => setOrders(e.target.value)}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="default"
              startIcon={<XCircle />}
              onClick={() => setCategory("appointment")}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="primary"
              startIcon={
                isProcess ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  <CheckSquare />
                )
              }
              disabled={isProcess}
              onClick={() => handleSentToAdmin()}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default SentToAdmitting;
