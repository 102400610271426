import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TextField,
  IconButton,
} from "@material-ui/core";
import FormHeader from "./FormHeader";
import { useReactToPrint } from "react-to-print";
import { XCircle, CheckSquare, Printer, Edit, Square } from "react-feather";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import moment from "moment";
import { DateTimePicker } from "@material-ui/pickers";

const FormFour = ({
  patient_id,
  trace_number,
  info,
  doctorsList,
  allowEdit,
}) => {
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [isProcess, setIsProcess] = useState(false);

  const [chartAttendingSurgeon, setChartAttendingSurgeon] = useState("");
  const [chartAnesthesiologist, setChartAnesthesiologist] = useState("");
  const [chartProposedSurgery, setChartProposedSurgery] = useState("");
  const [chartCardioNotes, setChartCardioNotes] = useState("");

  const [chartCardioNotesHPN, setChartCardioNotesHPN] = useState("");
  const [chartCardioNotesChestPains, setChartCardioNotesChestPains] =
    useState("");
  const [chartCardioNotesExert, setChartCardioNotesExert] = useState("");
  const [chartCardioNotesOrtho, setChartCardioNotesOrtho] = useState("");
  const [chartCardioNotesParox, setChartCardioNotesParox] = useState("");
  const [chartCardioNotesAnkle, setChartCardioNotesAnkle] = useState("");
  const [chartCardioNotesMedication, setChartCardioNotesMedication] =
    useState("");
  const [chartCardioNotesPE, setChartCardioNotesPE] = useState("");
  const [chartCardioNotesRecommendation, setChartCardioNotesRecommendation] =
    useState("");
  const [chartCardiologist, setChartCardiologist] = useState("");

  const [chartPulmoNotes, setChartPulmoNotes] = useState("");
  const [chartPulmoNotesCough, setChartPulmoNotesCough] = useState("");
  const [chartPulmoNotesCoughLong, setChartPulmoNotesCoughLong] = useState("");
  const [chartPulmoNotesFever, setChartPulmoNotesFever] = useState("");
  const [chartPulmoNotesHxPTB, setChartPulmoNotesHxPTB] = useState("");
  const [chartPulmoNotesHxPTBWhen, setChartPulmoNotesHxPTBWhen] = useState("");
  const [chartPulmoNotesHxPTBTreated, setChartPulmoNotesHxPTBTreated] =
    useState("");
  const [chartPulmoNotesAsthma, setChartPulmoNotesAsthma] = useState("");
  const [chartPulmoNotesAsthmaTreated, setChartPulmoNotesAsthmaTreated] =
    useState("");
  const [chartPulmoNotesSmoker, setChartPulmoNotesSmoker] = useState("");
  const [
    chartPulmoNotesSmokerPackPerYear,
    setChartPulmoNotesSmokerPackPerYear,
  ] = useState("");
  const [
    chartPulmoNotesSmokerLastStickWhen,
    setChartPulmoNotesSmokerLastStickWhen,
  ] = useState("");
  const [chartPulmoNotesRecommendation, setChartPulmoNotesRecommendation] =
    useState("");
  const [chartPulmoNotesPE, setChartPulmoNotesPE] = useState("");
  const [chartPulmonologist, setChartPulmonologist] = useState("");

  const [chartOtherDiabetes, setChartOtherDiabetes] = useState("");
  const [chartOtherDiabetesLong, setChartOtherDiabetesLong] = useState("");
  const [chartOtherRenal, setChartOtherRenal] = useState("");
  const [chartOtherAnemia, setChartOtherAnemia] = useState("");
  const [chartOtherStroke, setChartOtherStroke] = useState("");
  const [chartOtherStrokeWhen, setChartOtherStrokeWhen] = useState("");
  const [chartOtherAllergies, setChartOtherAllergies] = useState("");
  const [chartOtherAllergiesWhat, setChartOtherAllergiesWhat] = useState("");
  const [chartOtherMeds, setChartOtherMeds] = useState("");
  const [chartOtherOnDialysis, setChartOtherOnDialysis] = useState("");
  const [chartOtherBleedingTendencies, setChartOtherBleedingTendencies] =
    useState("");

  const [chartOtherNodAware, setChartOtherNodAware] = useState("");

  const [chartOtherNodAwareDateTime, setChartOtherNodAwareDateTime] =
    useState(null);

  const mounted = IsMountedRef();
  const [chartRd, setChartRd] = useState(false);
  const [chartType, setChartType] = useState("for-save");

  const printableRef = React.useRef();

  const { users } = React.useContext(UsersData);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  // const checkSelectedDoctor = (selDoctor) => {
  //   if (doctorsList.length > 0) {
  //     let xx = doctorsList.filter((x) => x.doctors_id === selDoctor);
  //     if (xx.length > 0) {
  //       return xx[0].name;
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  const handleNewCadioClearance = (e) => {
    e.preventDefault();
    e.persist();
    let err = [];
    let form = new FormData(e.target);
    form.append("user_id", users.user_id);
    form.append("main_mgmt_id", users.main_mgmt_id);
    form.append("management_id", users.management_id);
    form.append("patient_id", patient_id);
    form.append("trace_number", trace_number);

    form.append("attending_surgeon", chartAttendingSurgeon);
    form.append("anesthesiologist", chartAnesthesiologist);
    form.append("proposed_surgery", chartProposedSurgery);
    form.append("cardio_notes", chartCardioNotes);
    form.append("cardio_notes_hpn", chartCardioNotesHPN === "yes" ? 1 : 0);
    form.append(
      "cardio_notes_chest_pains",
      chartCardioNotesChestPains === "yes" ? 1 : 0
    );
    form.append("cardio_notes_exert", chartCardioNotesExert === "yes" ? 1 : 0);
    form.append("cardio_notes_ortho", chartCardioNotesOrtho === "yes" ? 1 : 0);
    form.append("cardio_notes_parox", chartCardioNotesParox === "yes" ? 1 : 0);
    form.append("cardio_notes_ankle", chartCardioNotesAnkle === "yes" ? 1 : 0);
    form.append("cardio_notes_medication", chartCardioNotesMedication);
    form.append("cardio_notes_physical_exam", chartCardioNotesPE);
    form.append("cardio_notes_recommendations", chartCardioNotesRecommendation);
    form.append("cardio_notes_cardiologist_name", chartCardiologist);
    form.append("pulmo_notes", chartPulmoNotes);
    form.append("pulmo_notes_cough", chartPulmoNotesCough === "yes" ? 1 : 0);
    form.append("pulmo_notes_cough_long", chartPulmoNotesCoughLong);
    form.append("pulmo_notes_fever", chartPulmoNotesFever === "yes" ? 1 : 0);
    form.append("pulmo_notes_hxptb", chartPulmoNotesHxPTB === "yes" ? 1 : 0);
    form.append("pulmo_notes_hxptb_when", chartPulmoNotesHxPTBWhen);
    form.append(
      "pulmo_notes_hxptb_treated",
      chartPulmoNotesHxPTBTreated === "yes" ? 1 : 0
    );
    form.append("pulmo_notes_asthma", chartPulmoNotesAsthma === "yes" ? 1 : 0);
    form.append(
      "pulmo_notes_asthma_treated",
      chartPulmoNotesAsthmaTreated === "yes" ? 1 : 0
    );
    form.append("pulmo_notes_smoker", chartPulmoNotesSmoker === "yes" ? 1 : 0);
    form.append(
      "pulmo_notes_smoker_packyears",
      chartPulmoNotesSmokerPackPerYear
    );
    form.append(
      "pulmo_notes_last_stick_smoked_when",
      chartPulmoNotesSmokerLastStickWhen
    );
    form.append("pulmo_notes_recommendations", chartPulmoNotesRecommendation);
    form.append("pulmo_notes_pe", chartPulmoNotesPE);
    form.append("pulmonologist_name", chartPulmonologist);

    form.append("other_diabetes", chartOtherDiabetes === "yes" ? 1 : 0);
    form.append("other_diabetes_long", chartOtherDiabetesLong);
    form.append("other_renal_failure", chartOtherRenal === "yes" ? 1 : 0);
    form.append("other_anemia", chartOtherAnemia === "yes" ? 1 : 0);
    form.append("other_stroke", chartOtherStroke === "yes" ? 1 : 0);
    form.append("other_stroke_when", chartOtherStrokeWhen);
    form.append("other_allergies", chartOtherAllergies === "yes" ? 1 : 0);
    form.append("other_allergies_what", chartOtherAllergiesWhat);
    form.append("other_meds", chartOtherMeds);
    form.append("other_on_dialysis", chartOtherOnDialysis === "yes" ? 1 : 0);
    form.append(
      "other_bleeding_tendencies",
      chartOtherBleedingTendencies === "yes" ? 1 : 0
    );
    form.append("other_nod_aware", chartOtherNodAware);
    form.append(
      "other_datetime",
      chartOtherNodAwareDateTime
        ? moment(chartOtherNodAwareDateTime).format("YYYY-MM-DD HH:mm:ss")
        : ""
    );

    form.append("chart_type", chartType);

    if (
      form.get("attending_surgeon").trim() === "" ||
      form.get("attending_surgeon").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("Attending Surgeon");
    }

    if (
      form.get("anesthesiologist").trim() === "" ||
      form.get("anesthesiologist").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("Anesthesiologist");
    }

    if (
      form.get("cardio_notes_cardiologist_name").trim() === "" ||
      form.get("cardio_notes_cardiologist_name").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("cardiologist");
    }

    if (
      form.get("pulmonologist_name").trim() === "" ||
      form.get("pulmonologist_name").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("pulmonologist");
    }

    if (
      form.get("pulmonologist_name").trim() === "" ||
      form.get("pulmonologist_name").length === 0
    ) {
      err = "error";
      Notify.fieldRequired("pulmonologist");
    }

    if (err.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcess(true);
      axios
        .post("or/charts/patient/patient-cardiopulmonary", form)
        .then((response) => {
          let data = response.data;
          if (data.message === "success") {
            Notify.successRequest("chart updated");
            getCardiopulmonary();
            setEnableEdit(false);
          }
        })
        .catch((err) => console.log("err message", err.message))
        .finally(() => setIsProcess(false));
    }
  };

  const getCardiopulmonary = React.useCallback(() => {
    axios
      .get("or/charts/patient/patient-getcardiopulmonary", {
        params: {
          patient_id,
          trace_number,
        },
      })
      .then((response) => {
        let data = response.data;
        if (mounted.current) {
          if (data.length) {
            let data = response.data[0];
            setChartType("for-update");
            handleUpdateDefaultState(data);
          }
        }
      })
      .catch((err) => console.log("error: ", err.message))
      .finally(() => setChartRd(true));
  }, [patient_id, trace_number, mounted]);

  const handleUpdateDefaultState = (data) => {
    setChartAttendingSurgeon(
      data.attending_surgeon === null ? "" : data.attending_surgeon
    );
    setChartAnesthesiologist(
      data.anesthesiologist === null ? "" : data.anesthesiologist
    );
    setChartProposedSurgery(
      data.proposed_surgery === null ? "" : data.proposed_surgery
    );
    setChartCardioNotes(data.cardio_notes === null ? "" : data.cardio_notes);
    setChartCardioNotesHPN(Boolean(data.cardio_notes_hpn) ? "yes" : "no");
    setChartCardioNotesChestPains(
      Boolean(data.cardio_notes_chest_pains) ? "yes" : "no"
    );
    setChartCardioNotesExert(Boolean(data.cardio_notes_exert) ? "yes" : "no");
    setChartCardioNotesOrtho(Boolean(data.cardio_notes_ortho) ? "yes" : "no");
    setChartCardioNotesParox(Boolean(data.cardio_notes_parox) ? "yes" : "no");
    setChartCardioNotesAnkle(Boolean(data.cardio_notes_ankle) ? "yes" : "no");
    setChartCardioNotesMedication(
      data.cardio_notes_medication === null ? "" : data.cardio_notes_medication
    );
    setChartCardioNotesPE(
      data.cardio_notes_physical_exam === null
        ? ""
        : data.cardio_notes_physical_exam
    );
    setChartCardioNotesRecommendation(
      data.cardio_notes_recommendations === null
        ? ""
        : data.cardio_notes_recommendations
    );
    setChartCardiologist(
      data.cardio_notes_cardiologist_name === null
        ? ""
        : data.cardio_notes_cardiologist_name
    );
    setChartPulmoNotes(data.pulmo_notes === null ? "" : data.pulmo_notes);
    setChartPulmoNotesCough(Boolean(data.pulmo_notes_cough) ? "yes" : "no");
    setChartPulmoNotesCoughLong(
      data.pulmo_notes_cough_long === null ? "" : data.pulmo_notes_cough_long
    );
    setChartPulmoNotesFever(Boolean(data.pulmo_notes_fever) ? "yes" : "no");
    setChartPulmoNotesHxPTB(Boolean(data.pulmo_notes_hxptb) ? "yes" : "no");
    setChartPulmoNotesHxPTBWhen(
      data.pulmo_notes_hxptb_when === null ? "" : data.pulmo_notes_hxptb_when
    );
    setChartPulmoNotesHxPTBTreated(
      Boolean(data.pulmo_notes_hxptb_treated) ? "yes" : "no"
    );
    setChartPulmoNotesAsthma(Boolean(data.pulmo_notes_asthma) ? "yes" : "no");
    setChartPulmoNotesAsthmaTreated(
      Boolean(data.pulmo_notes_asthma_treated) ? "yes" : "no"
    );
    setChartPulmoNotesSmoker(Boolean(data.pulmo_notes_smoker) ? "yes" : "no");
    setChartPulmoNotesSmokerPackPerYear(
      data.pulmo_notes_smoker_packyears === null
        ? ""
        : data.pulmo_notes_smoker_packyears
    );
    setChartPulmoNotesSmokerLastStickWhen(
      data.pulmo_notes_last_stick_smoked_when === null
        ? ""
        : data.pulmo_notes_last_stick_smoked_when
    );
    setChartPulmoNotesRecommendation(
      data.pulmo_notes_recommendations === null
        ? ""
        : data.pulmo_notes_recommendations
    );
    setChartPulmoNotesPE(
      data.pulmo_notes_pe === null ? "" : data.pulmo_notes_pe
    );
    setChartPulmonologist(
      data.pulmonologist_name === null ? "" : data.pulmonologist_name
    );

    setChartOtherDiabetes(Boolean(data.other_diabetes) ? "yes" : "no");
    setChartOtherDiabetesLong(
      data.other_diabetes_long === null ? "" : data.other_diabetes_long
    );
    setChartOtherRenal(Boolean(data.other_renal_failure) ? "yes" : "no");
    setChartOtherAnemia(Boolean(data.other_anemia) ? "yes" : "no");
    setChartOtherStroke(Boolean(data.other_stroke) ? "yes" : "no");
    setChartOtherStrokeWhen(
      data.other_stroke_when === null ? "" : data.other_stroke_when
    );
    setChartOtherAllergies(Boolean(data.other_allergies) ? "yes" : "no");
    setChartOtherAllergiesWhat(
      data.other_allergies_what === null ? "" : data.other_allergies_what
    );
    setChartOtherMeds(data.other_meds);
    setChartOtherOnDialysis(Boolean(data.other_on_dialysis) ? "yes" : "no");
    setChartOtherBleedingTendencies(
      Boolean(data.other_bleeding_tendencies) ? "yes" : "no"
    );
    setChartOtherNodAware(
      data.other_nod_aware === null ? "" : data.other_nod_aware
    );
    setChartOtherNodAwareDateTime(data.other_datetime);
  };

  useEffect(() => {
    getCardiopulmonary();
  }, [getCardiopulmonary]);

  return (
    <div>
      <Card ref={printableRef}>
        <CardContent>
          <Box>
            <FormHeader />
          </Box>

          <Box my={1} mt={2}>
            <Typography align="center">
              <b> CARDIOPULMONARY CLEARANCE FORM </b>
            </Typography>
          </Box>

          {chartRd ? (
            <form onSubmit={handleNewCadioClearance}>
              <Box my={3}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                  mb={1}
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          color="primary"
                          startIcon={
                            isProcess ? (
                              <CircularProgress size={22} color="inherit" />
                            ) : (
                              <CheckSquare />
                            )
                          }
                          type="submit"
                          disabled={isProcess}
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            Edit
                          </Button>
                        </Box>
                      )}
                      <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box>
                <Table className="table-bordered charts-tablecell">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">
                            Name of Patient:
                          </Typography>
                          <Typography>
                            {info
                              ? `${info.lastname}, ${info.firstname} ${
                                  info.middle === null ? "" : info.middle
                                }`
                              : ""}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">
                            Age/Sex/Status:
                          </Typography>
                          <Typography>
                            {info
                              ? `${
                                  info.birthday === null
                                    ? ""
                                    : Notify.calculateAge(info.birthday)
                                }/ ${info.gender} / ${info.civil_status}`
                              : ""}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">Address</Typography>
                          <Typography>
                            {info
                              ? `${info.street}, ${info.barangay} ${info.city}`
                              : ""}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">Religion:</Typography>
                          <Typography>
                            {info
                              ? `${info.religion === null ? "" : info.religion}`
                              : ""}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">
                            Attending Surgeon:
                          </Typography>

                          {enableEdit ? (
                            <>
                              <TextField
                                fullWidth
                                multiline
                                value={chartAttendingSurgeon}
                                onChange={(e) =>
                                  setChartAttendingSurgeon(e.target.value)
                                }
                              />

                              {/* <TextField
                                fullWidth
                                name=""
                                value={chartAttendingSurgeon}
                                InputProps={{
                                  disableUnderline: !enableEdit,
                                  style: {
                                    textTransform: "capitalize",
                                  },
                                }}
                                select
                                SelectProps={{ native: true }}
                                InputLabelProps={{ shrink: true }}
                                readOnly={!enableEdit}
                                onChange={(e) =>
                                  setChartAttendingSurgeon(e.target.value)
                                }
                              >
                                <option value=""> Select </option>
                                {doctorsList.length > 0 ? (
                                  doctorsList.map((data, key) => (
                                    <option key={key} value={data.doctors_id}>
                                      {data.name}
                                    </option>
                                  ))
                                ) : (
                                  <option value=""> No doctor found. </option>
                                )}
                              </TextField> */}
                            </>
                          ) : (
                            <Typography className="text-capitalize">
                              {/* {checkSelectedDoctor(chartAttendingSurgeon)} */}
                              {chartAttendingSurgeon}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>

                      <TableCell colSpan={6}>
                        <Box>
                          <Typography variant="subtitle2">
                            Anesthesiologist:
                          </Typography>
                          {enableEdit ? (
                            <>
                              <TextField
                                fullWidth
                                multiline
                                value={chartAnesthesiologist}
                                onChange={(e) =>
                                  setChartAnesthesiologist(e.target.value)
                                }
                              />

                              {/* <TextField
                              fullWidth
                              name=""
                              value={chartAnesthesiologist}
                              InputProps={{
                                disableUnderline: !enableEdit,
                                style: {
                                  textTransform: "capitalize",
                                },
                              }}
                              select
                              SelectProps={{ native: true }}
                              InputLabelProps={{ shrink: true }}
                              readOnly={!enableEdit}
                              onChange={(e) =>
                                setChartAnesthesiologist(e.target.value)
                              }
                            >
                              <option value=""> Select </option>
                              {doctorsList.length > 0 ? (
                                doctorsList.map((data, key) => (
                                  <option key={key} value={data.doctors_id}>
                                    {data.name}
                                  </option>
                                ))
                              ) : (
                                <option value=""> No doctor found. </option>
                              )}
                            </TextField> */}
                            </>
                          ) : (
                            <Typography className="text-capitalize">
                              {/* {checkSelectedDoctor(chartAnesthesiologist)} */}
                              {chartAnesthesiologist}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Box>
                          <Typography variant="subtitle2">
                            Proposed Surgery:
                          </Typography>
                          {enableEdit ? (
                            <TextField
                              fullWidth
                              multiline
                              value={chartProposedSurgery}
                              onChange={(e) =>
                                setChartProposedSurgery(e.target.value)
                              }
                            />
                          ) : (
                            <Typography className="text-capitalize">
                              {chartProposedSurgery}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>

              <Box border={1} mt={3}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box borderRight={1} p={2}>
                      <Box>
                        <Typography>
                          <b> Cardio Notes: </b>
                        </Typography>
                        <Box>
                          {enableEdit ? (
                            <TextField
                              fullWidth
                              multiline
                              value={chartCardioNotes}
                              onChange={(e) =>
                                setChartCardioNotes(e.target.value)
                              }
                            />
                          ) : (
                            <Typography className="text-capitalize">
                              {chartCardioNotes}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <Box mt={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <Box>
                              <Typography> HPN </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={5}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartCardioNotesHPN("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartCardioNotesHPN === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartCardioNotesHPN("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartCardioNotesHPN === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <Box>
                              <Typography> Chest Pains </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={5}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartCardioNotesChestPains("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartCardioNotesChestPains === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartCardioNotesChestPains("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartCardioNotesChestPains === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <Box>
                              <Typography> Exertional Dyspnea </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={5}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartCardioNotesExert("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartCardioNotesExert === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartCardioNotesExert("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartCardioNotesExert === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <Box>
                              <Typography> Orthopnea </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={5}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartCardioNotesOrtho("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartCardioNotesOrtho === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartCardioNotesOrtho("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartCardioNotesOrtho === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <Box>
                              <Typography>
                                Paroxysmal Nocturnal Dyspnea
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={5}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartCardioNotesParox("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartCardioNotesParox === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartCardioNotesParox("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartCardioNotesParox === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <Box>
                              <Typography> Ankle Swelling </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={5}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartCardioNotesAnkle("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartCardioNotesAnkle === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartCardioNotesAnkle("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartCardioNotesAnkle === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Box mt={2}>
                          <Typography> Medication/s taken: </Typography>
                          <Box>
                            {enableEdit ? (
                              <TextField
                                fullWidth
                                multiline
                                value={chartCardioNotesMedication}
                                onChange={(e) =>
                                  setChartCardioNotesMedication(e.target.value)
                                }
                              />
                            ) : (
                              <Typography className="text-capitalize">
                                {chartCardioNotesMedication}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box p={2}>
                      <Box>
                        <Typography>
                          <b> Physical Examination: </b>
                        </Typography>
                      </Box>
                      <Box>
                        {enableEdit ? (
                          <TextField
                            fullWidth
                            multiline
                            value={chartCardioNotesPE}
                            onChange={(e) =>
                              setChartCardioNotesPE(e.target.value)
                            }
                          />
                        ) : (
                          <Typography className="text-capitalize">
                            {chartCardioNotesPE}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box borderTop={1} borderBottom={1} p={1}>
                      <Box>
                        <Typography> Recommendations: </Typography>
                      </Box>

                      <Box my={1} mb={1}>
                        <Box>
                          {enableEdit ? (
                            <TextField
                              fullWidth
                              multiline
                              value={chartCardioNotesRecommendation}
                              onChange={(e) =>
                                setChartCardioNotesRecommendation(
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <Typography className="text-capitalize">
                              {chartCardioNotesRecommendation}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <Box display={"flex"} justifyContent="flex-end">
                        <Box align="center">
                          <Box>
                            {enableEdit ? (
                              <TextField
                                fullWidth
                                multiline
                                value={chartCardiologist}
                                onChange={(e) =>
                                  setChartCardiologist(e.target.value)
                                }
                              />
                            ) : (
                              <Box borderBottom={1}>
                                <Typography className="text-capitalize">
                                  {chartCardiologist}
                                </Typography>
                              </Box>
                            )}
                          </Box>

                          <Typography align="center">
                            Signature Over Printed Name of Cardiologist
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box p={1} borderRight={1}>
                      <Box mb={1}>
                        <Typography>
                          <b> Pulmo Notes: </b>
                        </Typography>
                        <Box>
                          {enableEdit ? (
                            <TextField
                              fullWidth
                              multiline
                              value={chartPulmoNotes}
                              onChange={(e) =>
                                setChartPulmoNotes(e.target.value)
                              }
                            />
                          ) : (
                            <Typography className="text-capitalize">
                              {chartPulmoNotes}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Box>
                              <Typography> Cough</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={5}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesCough("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesCough === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesCough("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesCough === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={4}>
                            <Box display="flex">
                              <Typography> How long: </Typography>
                              <Box>
                                {enableEdit ? (
                                  <TextField
                                    fullWidth
                                    multiline
                                    value={chartPulmoNotesCoughLong}
                                    onChange={(e) =>
                                      setChartPulmoNotesCoughLong(
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <Typography className="text-capitalize">
                                    {chartPulmoNotesCoughLong}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Box>
                              <Typography> Fever </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={9}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesFever("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesFever === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesFever("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesFever === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Box>
                              <Typography> Hx of PTPB </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={5}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesHxPTB("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesHxPTB === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesHxPTB("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesHxPTB === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={4}>
                            <Box display="flex">
                              <Typography> When: </Typography>
                              <Box>
                                {enableEdit ? (
                                  <TextField
                                    fullWidth
                                    multiline
                                    value={chartPulmoNotesHxPTBWhen}
                                    onChange={(e) =>
                                      setChartPulmoNotesHxPTBWhen(
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <Typography className="text-capitalize">
                                    {chartPulmoNotesHxPTBWhen}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Box>
                              <Typography> Treated? </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={7}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesHxPTBTreated("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesHxPTBTreated === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesHxPTBTreated("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesHxPTBTreated === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Box>
                              <Typography> Asthma? </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={7}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesAsthma("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesAsthma === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesAsthma("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesAsthma === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Box>
                              <Typography> Treated? </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={7}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesAsthmaTreated("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesAsthmaTreated === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesAsthmaTreated("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesAsthmaTreated === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>{" "}
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Box>
                              <Typography> Smoker? </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={5}>
                            <Box display="flex">
                              <Box mr={1}>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesSmoker("yes");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesSmoker === "yes" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>
                                  Yes
                                </Typography>
                              </Box>

                              <Box>
                                <Typography>
                                  <IconButton
                                    className={"text-dark"}
                                    disabled={!enableEdit}
                                    onClick={() => {
                                      setChartPulmoNotesSmoker("no");
                                    }}
                                    style={{ padding: "6px" }}
                                  >
                                    {chartPulmoNotesSmoker === "no" ? (
                                      <CheckSquare />
                                    ) : (
                                      <Square />
                                    )}
                                  </IconButton>
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={4}>
                            <Box display="flex">
                              <Typography> Pack Years: </Typography>
                              <Box>
                                {enableEdit ? (
                                  <TextField
                                    fullWidth
                                    multiline
                                    value={chartPulmoNotesSmokerPackPerYear}
                                    onChange={(e) =>
                                      setChartPulmoNotesSmokerPackPerYear(
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <Typography className="text-capitalize">
                                    {chartPulmoNotesSmokerPackPerYear}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box mt={2}>
                        <Box display="flex">
                          <Typography>Last stick smoked when?</Typography>
                          <Box>
                            {enableEdit ? (
                              <TextField
                                fullWidth
                                multiline
                                value={chartPulmoNotesSmokerLastStickWhen}
                                onChange={(e) =>
                                  setChartPulmoNotesSmokerLastStickWhen(
                                    e.target.value
                                  )
                                }
                              />
                            ) : (
                              <Typography className="text-capitalize">
                                {chartPulmoNotesSmokerLastStickWhen}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box p={1}>
                      <Typography>
                        <b> Physical Examination: </b>
                      </Typography>

                      <Box>
                        {enableEdit ? (
                          <TextField
                            fullWidth
                            multiline
                            value={chartPulmoNotesPE}
                            onChange={(e) =>
                              setChartPulmoNotesPE(e.target.value)
                            }
                          />
                        ) : (
                          <Typography className="text-capitalize">
                            {chartPulmoNotesPE}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* gtc-page-breaker */}
              <div className="gtc-page-breaker" />

              <Box mt={2} mb={1}>
                <Box>
                  <Typography> Recommendations: </Typography>
                  <Box>
                    {enableEdit ? (
                      <TextField
                        fullWidth
                        multiline
                        value={chartPulmoNotesRecommendation}
                        onChange={(e) =>
                          setChartPulmoNotesRecommendation(e.target.value)
                        }
                      />
                    ) : (
                      <Typography className="text-capitalize">
                        {chartPulmoNotesRecommendation}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box my={2} display={"flex"} justifyContent="flex-end">
                <Box align="center">
                  <Box>
                    {enableEdit ? (
                      <TextField
                        fullWidth
                        multiline
                        value={chartPulmonologist}
                        onChange={(e) => setChartPulmonologist(e.target.value)}
                      />
                    ) : (
                      <Box borderBottom={1}>
                        <Typography className="text-capitalize">
                          {chartPulmonologist}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Typography align="center">
                    Signature Over Printed Name of Pulmonologist
                  </Typography>
                </Box>
              </Box>

              <Box mt={2} mb={1}>
                <Box>
                  <Typography>
                    {" "}
                    <b> Other Problems </b>{" "}
                  </Typography>
                </Box>

                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box>
                        <Typography> Diabetes </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box display="flex">
                        <Box mr={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherDiabetes("yes");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherDiabetes === "yes" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Yes
                          </Typography>
                        </Box>

                        <Box>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherDiabetes("no");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherDiabetes === "no" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            No
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box display="flex">
                        <Typography> How Long? </Typography>
                        <Box>
                          {enableEdit ? (
                            <TextField
                              fullWidth
                              multiline
                              value={
                                chartOtherDiabetesLong !== null
                                  ? chartOtherDiabetesLong
                                  : ""
                              }
                              onChange={(e) =>
                                setChartOtherDiabetesLong(e.target.value)
                              }
                            />
                          ) : (
                            <Typography className="text-capitalize">
                              {chartOtherDiabetesLong !== null
                                ? chartOtherDiabetesLong
                                : ""}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box>
                        <Typography> Renal Failure </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={8}>
                      <Box display="flex">
                        <Box mr={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherRenal("yes");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherRenal === "yes" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Yes
                          </Typography>
                        </Box>

                        <Box>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherRenal("no");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherRenal === "no" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            No
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box>
                        <Typography> Anemia </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Box display="flex">
                        <Box mr={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherAnemia("yes");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherAnemia === "yes" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Yes
                          </Typography>
                        </Box>

                        <Box>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherAnemia("no");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherAnemia === "no" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            No
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box>
                        <Typography> Stroke </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box display="flex">
                        <Box mr={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherStroke("yes");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherStroke === "yes" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Yes
                          </Typography>
                        </Box>

                        <Box>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherStroke("no");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherStroke === "no" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            No
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box display="flex">
                        <Typography> When? </Typography>
                        <Box>
                          {enableEdit ? (
                            <TextField
                              fullWidth
                              multiline
                              value={
                                chartOtherStrokeWhen !== null
                                  ? chartOtherStrokeWhen
                                  : ""
                              }
                              onChange={(e) =>
                                setChartOtherStrokeWhen(e.target.value)
                              }
                            />
                          ) : (
                            <Typography className="text-capitalize">
                              {chartOtherStrokeWhen !== null
                                ? chartOtherStrokeWhen
                                : ""}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box>
                        <Typography> Allergies </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box display="flex">
                        <Box mr={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherAllergies("yes");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherAllergies === "yes" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Yes
                          </Typography>
                        </Box>

                        <Box>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherAllergies("no");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherAllergies === "no" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            No
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box display="flex">
                        <Typography> What? </Typography>
                        <Box>
                          {enableEdit ? (
                            <TextField
                              fullWidth
                              multiline
                              value={
                                chartOtherAllergiesWhat !== null
                                  ? chartOtherAllergiesWhat
                                  : ""
                              }
                              onChange={(e) =>
                                setChartOtherAllergiesWhat(e.target.value)
                              }
                            />
                          ) : (
                            <Typography className="text-capitalize">
                              {chartOtherAllergiesWhat !== null
                                ? chartOtherAllergiesWhat
                                : ""}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={2}>
                  <Box>
                    <Typography> Meds: </Typography>
                  </Box>
                  <Box>
                    {enableEdit ? (
                      <TextField
                        fullWidth
                        multiline
                        value={chartOtherMeds}
                        onChange={(e) => setChartOtherMeds(e.target.value)}
                      />
                    ) : (
                      <Typography className="text-capitalize">
                        {chartOtherMeds}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box>
                        <Typography> On Dialysis </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Box display="flex">
                        <Box mr={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherOnDialysis("yes");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherOnDialysis === "yes" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Yes
                          </Typography>
                        </Box>

                        <Box>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherOnDialysis("no");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherOnDialysis === "no" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            No
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box>
                        <Typography> Bleeding tendencies </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Box display="flex">
                        <Box mr={1}>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherBleedingTendencies("yes");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherBleedingTendencies === "yes" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            Yes
                          </Typography>
                        </Box>

                        <Box>
                          <Typography>
                            <IconButton
                              className={"text-dark"}
                              disabled={!enableEdit}
                              onClick={() => {
                                setChartOtherBleedingTendencies("no");
                              }}
                              style={{ padding: "6px" }}
                            >
                              {chartOtherBleedingTendencies === "no" ? (
                                <CheckSquare />
                              ) : (
                                <Square />
                              )}
                            </IconButton>
                            No
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={2}>
                  <Box>
                    <Typography> NOD Aware: </Typography>
                  </Box>
                  <Box>
                    {enableEdit ? (
                      <TextField
                        fullWidth
                        multiline
                        value={chartOtherNodAware}
                        onChange={(e) => setChartOtherNodAware(e.target.value)}
                      />
                    ) : (
                      <Typography className="text-capitalize">
                        {chartOtherNodAware}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box>
                  <Box>
                    <Typography> Date/Time: </Typography>
                  </Box>
                  <Box>
                    {enableEdit ? (
                      <Box>
                        <DateTimePicker
                          label=""
                          value={chartOtherNodAwareDateTime}
                          onChange={(date) =>
                            setChartOtherNodAwareDateTime(date)
                          }
                          readOnly={!enableEdit}
                          InputProps={{
                            disableUnderline: !enableEdit,
                          }}
                          autoOk
                        />
                      </Box>
                    ) : (
                      <Typography>
                        {chartOtherNodAwareDateTime === null
                          ? ""
                          : Notify.dateTimeConvert(
                              moment(chartOtherNodAwareDateTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            )}{" "}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </form>
          ) : (
            Notify.loading()
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default FormFour;
