import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Header from "./Header";
import ImmunologyRef from "../references/Ref_Immunology";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ImmunologyOrder = ({ formheader, orderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        {/* <CardContent>
          <Header details={orderDetails} />
        </CardContent> */}

        <CardContent>
          <Box>
            <Header details={orderDetails} />
          </Box>
          <Box>
            <Box flexGrow={1}>
              <Typography
                variant="h6"
                align="center"
                style={{ fontWeight: "bolder", color: "#000" }}
              >
                <b> IMMUNOLOGY </b>
              </Typography>
            </Box>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          TEST
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          RESULT
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          REFERENCES
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          REMARKS
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {orderDetails.data.map((data, index) => (
                      <Fragment key={index}>
                        {data.alpha_fetoprotein && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                ALPHA FETOPROTEIN
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.alpha_fetoprotein}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <span
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                              >
                                {ImmunologyRef.alphaFetoprotein()}
                              </span>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.remarks}
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box>
                <Footer formheader={formheader} orderDetails={orderDetails} />
              </Box>
            </CardContent>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ImmunologyOrder;
