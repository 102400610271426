import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Button from "src/components/Button";
import ModalBody from "src/components/modal/components/ModalBody";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ModalHeader from "src/components/modal/components/ModalHeader";
import Modal from "src/components/modal/Modal";
import { toast } from "react-toastify";

import { v4 as uuidv4 } from "uuid";

const ConfirmationModal = (props, ref) => {
	const { staticModal } = props;

	const [open, setOpen] = useState(false);

	const [data, setData] = useState({
		title: "",
		message: "",
		footer: "",
	});
	const [loadingBtn, setLoadingBtn] = useState(false);

	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));

	const show = (showData) => {
		setOpen(true);
		setLoadingBtn(false);
		setTimeout(() => {
			setData(showData);
		}, 200);
	};

	const hide = () => {
		setOpen(false);
	};

	return open ? (
		<Modal
			open={open}
			hide={hide}
			className={`w-[512px] min-w-[512px] max-w-[512px]`}
			staticModal={staticModal}
		>
			<ModalHeader title={data?.title} hide={hide} />
			<ModalBody className={`!p-6 bg-white`}>
				<span className="text-center text-lg">{data?.message}</span>
			</ModalBody>
			<ModalFooter className={`flex items-center justify-end`}>
				{typeof data?.footer == "function"
					? data.footer({ loadingBtn, setLoadingBtn })
					: data.footer}
			</ModalFooter>
		</Modal>
	) : (
		""
	);
};

export default forwardRef(ConfirmationModal);
