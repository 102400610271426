import { useClinicContext } from "src/clinic/context/ClinicContext";

const ClinicInfo = () => {
	const { clinic } = useClinicContext();
	return (
		<div className="flex flex-col ml-auto">
			<h3 className="text-lg font-bold text-right mb-0 w-full">
				{clinic?.name || "Loading..."}
			</h3>
			<span className="text-sm capitalize text-right w-full">
				SPMC - Anesthesia Department
				{/* {clinic?.id ? `${clinic?.type} clinic` : "Loading..."} */}
			</span>
		</div>
	);
};

export default ClinicInfo;
