import {
  Box,
  Card,
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
} from "@material-ui/core";
import {
  CheckBoxOutlineBlank,
  CheckBox,
  CheckBoxOutlineBlankOutlined,
} from "@material-ui/icons";
import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
// import { useReactToPrint } from "react-to-print";
import { UsersData } from "src/ContextAPI";
// import FormHeader from "./FormHeader";
import {
  CheckSquare,
  Edit,
  //  Printer,
  XCircle,
} from "react-feather";
import Notify from "src/notification/Notify";

const FormTen = ({ patient_id, details, allowEdit }) => {
  const printableRefOld = React.useRef();
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const { users } = useContext(UsersData);
  const [existingPostOperativeChart, setExistingPostOperativeChart] = useState({
    data: [],
    ready: false,
  });

  // const handlePrint = useReactToPrint({
  //   content: () => printableRefOld.current,
  // });

  const [checkList, setCheckList] = useState({
    discharge_pacu: false,
    discharge_room: false,
    discharge_icu: false,
    discharge_morgue: false,
    level_awake: false,
    level_sedated: false,
    level_unconscious: false,
    level_semi_unconscious: false,
    level_expired: false,
    level_others: false,
    skin_pinkish: false,
    skin_pale: false,
    skin_warm: false,
    skin_cool: false,
    skin_cyanotic: false,
    skin_others: false,
    trans_et: false,
    trans_tracheostomy: false,
    trans_epid: false,
    trans_ngt: false,
    trans_urine: false,
    trans_drain: false,
    trans_ivf: false,
    trans_others: false,
  });

  const getPostOperativeChart = () => {
    var params = {
      patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: details.trace_number,
    };
    axios
      .get("or/get/chart/postoperative", { params })
      .then((response) => {
        const data = response.data;
        setExistingPostOperativeChart({ data, ready: true });
        if (data.length > 0) {
          setCheckList({
            ...checkList,
            discharge_pacu:
              parseInt(data[0].discharge_pacu) === 1 ? true : false,
            discharge_room:
              parseInt(data[0].discharge_room) === 1 ? true : false,
            discharge_icu: parseInt(data[0].discharge_icu) === 1 ? true : false,
            discharge_morgue:
              parseInt(data[0].discharge_morgue) === 1 ? true : false,
            level_awake: parseInt(data[0].level_awake) === 1 ? true : false,
            level_sedated: parseInt(data[0].level_sedated) === 1 ? true : false,
            level_unconscious:
              parseInt(data[0].level_unconscious) === 1 ? true : false,
            level_semi_unconscious:
              parseInt(data[0].level_semi_unconscious) === 1 ? true : false,
            level_expired: parseInt(data[0].level_expired) === 1 ? true : false,
            level_others: parseInt(data[0].level_others) === 1 ? true : false,
            skin_pinkish: parseInt(data[0].skin_pinkish) === 1 ? true : false,
            skin_pale: parseInt(data[0].skin_pale) === 1 ? true : false,
            skin_warm: parseInt(data[0].skin_warm) === 1 ? true : false,
            skin_cool: parseInt(data[0].skin_cool) === 1 ? true : false,
            skin_cyanotic: parseInt(data[0].skin_cyanotic) === 1 ? true : false,
            skin_others: parseInt(data[0].skin_others) === 1 ? true : false,
            trans_et: parseInt(data[0].trans_et) === 1 ? true : false,
            trans_tracheostomy:
              parseInt(data[0].trans_tracheostomy) === 1 ? true : false,
            trans_epid: parseInt(data[0].trans_epid) === 1 ? true : false,
            trans_ngt: parseInt(data[0].trans_ngt) === 1 ? true : false,
            trans_urine: parseInt(data[0].trans_urine) === 1 ? true : false,
            trans_drain: parseInt(data[0].trans_drain) === 1 ? true : false,
            trans_ivf: parseInt(data[0].trans_ivf) === 1 ? true : false,
            trans_others: parseInt(data[0].trans_others) === 1 ? true : false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSubmitPostOperativeChart = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", details.trace_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("discharge_pacu", checkList.discharge_pacu ? 1 : 0);
    formdata.set("discharge_room", checkList.discharge_room ? 1 : 0);
    formdata.set("discharge_icu", checkList.discharge_icu ? 1 : 0);
    formdata.set("discharge_morgue", checkList.discharge_morgue ? 1 : 0);
    formdata.set("level_awake", checkList.level_awake ? 1 : 0);
    formdata.set("level_sedated", checkList.level_sedated ? 1 : 0);
    formdata.set("level_unconscious", checkList.level_unconscious ? 1 : 0);
    formdata.set(
      "level_semi_unconscious",
      checkList.level_semi_unconscious ? 1 : 0
    );
    formdata.set("level_expired", checkList.level_expired ? 1 : 0);
    formdata.set("level_others", checkList.level_others ? 1 : 0);
    formdata.set("skin_pinkish", checkList.skin_pinkish ? 1 : 0);
    formdata.set("skin_pale", checkList.skin_pale ? 1 : 0);
    formdata.set("skin_warm", checkList.skin_warm ? 1 : 0);
    formdata.set("skin_cool", checkList.skin_cool ? 1 : 0);
    formdata.set("skin_cyanotic", checkList.skin_cyanotic ? 1 : 0);
    formdata.set("skin_others", checkList.skin_others ? 1 : 0);
    formdata.set("trans_et", checkList.trans_et ? 1 : 0);
    formdata.set("trans_tracheostomy", checkList.trans_tracheostomy ? 1 : 0);
    formdata.set("trans_epid", checkList.trans_epid ? 1 : 0);
    formdata.set("trans_ngt", checkList.trans_ngt ? 1 : 0);
    formdata.set("trans_urine", checkList.trans_urine ? 1 : 0);
    formdata.set("trans_drain", checkList.trans_drain ? 1 : 0);
    formdata.set("trans_ivf", checkList.trans_ivf ? 1 : 0);
    formdata.set("trans_others", checkList.trans_others ? 1 : 0);

    setIsProcess(true);
    axios
      .post("or/update/chart/postoperative", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          e.target.reset();
          getPostOperativeChart();
          setEnableEdit(!enableEdit);
          Notify.successRequest("");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsProcess(false);
      });
  };

  useEffect(() => {
    getPostOperativeChart();
    // eslint-disable-next-line
  }, [patient_id, details]);

  return (
    <Box>
      <Card ref={printableRefOld} elevation={0}>
        <Box m={2}>
          {/* <Box>
            <FormHeader />
          </Box> */}
          <Box>
            <form onSubmit={handleSubmitPostOperativeChart}>
              <Box my={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="d-print-none"
                >
                  {enableEdit ? (
                    <>
                      <Box>
                        <Button
                          color="secondary"
                          onClick={() => setEnableEdit(false)}
                          startIcon={<XCircle />}
                        >
                          Close
                        </Button>
                      </Box>

                      <Box ml={2}>
                        <Button
                          disabled={isProcess}
                          color="primary"
                          startIcon={<CheckSquare />}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {allowEdit && (
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            Edit
                          </Button>
                        </Box>
                      )}

                      {/* <Box>
                        <Button
                          color="primary"
                          onClick={handlePrint}
                          startIcon={<Printer />}
                        >
                          Print
                        </Button>
                      </Box> */}
                    </>
                  )}
                </Box>
              </Box>

              <Box align="center" mb={2}>
                <Typography>
                  <b> POSTOPERATIVE EVALUATION </b>
                </Typography>
              </Box>

              {existingPostOperativeChart.ready && (
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: "bolder" }}>
                      DISCHARGE TO:
                    </Typography>
                    <Box hidden={enableEdit}>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].discharge_pacu
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        PACU
                      </Box>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].discharge_room
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Room/Ward
                      </Box>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].discharge_icu
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        ICU
                      </Box>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0]
                                .discharge_morgue
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Morgue
                      </Box>
                    </Box>
                    <Box hidden={!enableEdit}>
                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                discharge_pacu: !checkList.discharge_pacu,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.discharge_pacu === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          PACU
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                discharge_room: !checkList.discharge_room,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.discharge_room === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Room/Ward
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                discharge_icu: !checkList.discharge_icu,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.discharge_icu === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          ICU
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                discharge_morgue: !checkList.discharge_morgue,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.discharge_morgue === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Morgue
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: "bolder" }}>
                      LEVEL OF CONSCIOUSNESS
                    </Typography>
                    <Box hidden={enableEdit}>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].level_awake
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Awake
                      </Box>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].level_sedated
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Sedated
                      </Box>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0]
                                .level_unconscious
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Unconscious
                      </Box>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0]
                                .level_semi_unconscious
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Semi conscious
                      </Box>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].level_expired
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Expired
                      </Box>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].level_others
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Others:
                      </Box>
                    </Box>
                    <Box hidden={!enableEdit}>
                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                level_awake: !checkList.level_awake,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.level_awake === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Awake
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                level_sedated: !checkList.level_sedated,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.level_sedated === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Sedated
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                level_unconscious: !checkList.level_unconscious,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.level_unconscious === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Unconscious
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                level_semi_unconscious:
                                  !checkList.level_semi_unconscious,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.level_semi_unconscious === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Semi conscious
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                level_expired: !checkList.level_expired,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.level_expired === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Expired
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                level_others: !checkList.level_others,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.level_others === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Others
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: "bolder" }}>
                      SKIN CONDITION
                    </Typography>
                    <Box hidden={enableEdit}>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].skin_pinkish
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Pinkish
                      </Box>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].skin_pale
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Pale
                      </Box>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].skin_warm
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Warm
                      </Box>

                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].skin_cool
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Cool
                      </Box>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].skin_cyanotic
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Cyanotic
                      </Box>
                      <Box>
                        {existingPostOperativeChart.data.length > 0 ? (
                          Boolean(
                            parseInt(
                              existingPostOperativeChart.data[0].skin_others
                            )
                          ) ? (
                            <CheckBox />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                        Others:
                      </Box>
                    </Box>
                    <Box hidden={!enableEdit}>
                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                skin_pinkish: !checkList.skin_pinkish,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.skin_pinkish === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Pinkish
                        </Typography>
                      </Box>

                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                skin_pale: !checkList.skin_pale,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.skin_pale === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Pale
                        </Typography>
                      </Box>

                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                skin_warm: !checkList.skin_warm,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.skin_warm === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Warm
                        </Typography>
                      </Box>

                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                skin_cool: !checkList.skin_cool,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.skin_cool === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Cool
                        </Typography>
                      </Box>

                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                skin_cyanotic: !checkList.skin_cyanotic,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.skin_cyanotic === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Cyanotic
                        </Typography>
                      </Box>

                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                skin_others: !checkList.skin_others,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.skin_others === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Others
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: "bolder" }}>
                      TRANSPORTED WITH:
                    </Typography>
                    <Box hidden={enableEdit}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Box>
                            {existingPostOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingPostOperativeChart.data[0].trans_et
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            ET
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            {existingPostOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingPostOperativeChart.data[0]
                                    .trans_tracheostomy
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Tracheostomy
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            {existingPostOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingPostOperativeChart.data[0].trans_epid
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Epid. Cath
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            {existingPostOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingPostOperativeChart.data[0].trans_ngt
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            NGT
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            {existingPostOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingPostOperativeChart.data[0].trans_urine
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Urine Cath
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            {existingPostOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingPostOperativeChart.data[0].trans_drain
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Drain
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            {existingPostOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingPostOperativeChart.data[0].trans_ivf
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            IVF
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            {existingPostOperativeChart.data.length > 0 ? (
                              Boolean(
                                parseInt(
                                  existingPostOperativeChart.data[0]
                                    .trans_others
                                )
                              ) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Others:
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box hidden={!enableEdit}>
                      <Box display={"flex"}>
                        <Box>
                          <Typography variant={"subtitle1"}>
                            <IconButton
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  trans_et: !checkList.trans_et,
                                })
                              }
                              disabled={!enableEdit}
                            >
                              {checkList.trans_et === true ? (
                                <CheckBox fontSize="small" />
                              ) : (
                                <CheckBoxOutlineBlankOutlined fontSize="small" />
                              )}
                            </IconButton>
                            ET
                          </Typography>
                        </Box>

                        <Box>
                          <Typography variant={"subtitle1"}>
                            <IconButton
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  trans_tracheostomy:
                                    !checkList.trans_tracheostomy,
                                })
                              }
                              disabled={!enableEdit}
                            >
                              {checkList.trans_tracheostomy === true ? (
                                <CheckBox fontSize="small" />
                              ) : (
                                <CheckBoxOutlineBlankOutlined fontSize="small" />
                              )}
                            </IconButton>
                            Tracheostomy
                          </Typography>
                        </Box>
                      </Box>

                      <Box display={"flex"}>
                        <Box>
                          <Typography variant={"subtitle1"}>
                            <IconButton
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  trans_epid: !checkList.trans_epid,
                                })
                              }
                              disabled={!enableEdit}
                            >
                              {checkList.trans_epid === true ? (
                                <CheckBox fontSize="small" />
                              ) : (
                                <CheckBoxOutlineBlankOutlined fontSize="small" />
                              )}
                            </IconButton>
                            Epid. Cath
                          </Typography>
                        </Box>

                        <Box>
                          <Typography variant={"subtitle1"}>
                            <IconButton
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  trans_ngt: !checkList.trans_ngt,
                                })
                              }
                              disabled={!enableEdit}
                            >
                              {checkList.trans_ngt === true ? (
                                <CheckBox fontSize="small" />
                              ) : (
                                <CheckBoxOutlineBlankOutlined fontSize="small" />
                              )}
                            </IconButton>
                            NGT
                          </Typography>
                        </Box>
                      </Box>

                      <Box display={"flex"}>
                        <Box>
                          <Typography variant={"subtitle1"}>
                            <IconButton
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  trans_urine: !checkList.trans_urine,
                                })
                              }
                              disabled={!enableEdit}
                            >
                              {checkList.trans_urine === true ? (
                                <CheckBox fontSize="small" />
                              ) : (
                                <CheckBoxOutlineBlankOutlined fontSize="small" />
                              )}
                            </IconButton>
                            Urine Cath
                          </Typography>
                        </Box>

                        <Box>
                          <Typography variant={"subtitle1"}>
                            <IconButton
                              onClick={() =>
                                setCheckList({
                                  ...checkList,
                                  trans_drain: !checkList.trans_drain,
                                })
                              }
                              disabled={!enableEdit}
                            >
                              {checkList.trans_drain === true ? (
                                <CheckBox fontSize="small" />
                              ) : (
                                <CheckBoxOutlineBlankOutlined fontSize="small" />
                              )}
                            </IconButton>
                            Drain
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                trans_ivf: !checkList.trans_ivf,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.trans_ivf === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          IVF
                        </Typography>
                      </Box>

                      <Box>
                        <Typography variant={"subtitle1"}>
                          <IconButton
                            onClick={() =>
                              setCheckList({
                                ...checkList,
                                trans_others: !checkList.trans_others,
                              })
                            }
                            disabled={!enableEdit}
                          >
                            {checkList.trans_others === true ? (
                              <CheckBox fontSize="small" />
                            ) : (
                              <CheckBoxOutlineBlankOutlined fontSize="small" />
                            )}
                          </IconButton>
                          Others
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant="subtitle2">
                        <span style={{ color: "#000", fontWeight: "bolder" }}>
                          Operation Done:
                        </span>{" "}
                        {!enableEdit
                          ? existingPostOperativeChart.data.length > 0
                            ? existingPostOperativeChart.data[0].operation_done
                            : ""
                          : null}
                      </Typography>
                      {enableEdit && (
                        <>
                          <TextField
                            fullWidth
                            name="operation_done"
                            defaultValue={
                              existingPostOperativeChart.data.length > 0
                                ? existingPostOperativeChart.data[0]
                                    .operation_done
                                : ""
                            }
                            InputProps={{
                              disableUnderline: !enableEdit,
                              disabled: !enableEdit,
                            }}
                            multiline
                          />
                          <TextField
                            fullWidth
                            name="ccpef_id"
                            defaultValue={
                              existingPostOperativeChart.data.length > 0
                                ? existingPostOperativeChart.data[0].ccpef_id
                                : ""
                            }
                            InputProps={{
                              disableUnderline: !enableEdit,
                              disabled: !enableEdit,
                            }}
                            hidden
                          />
                        </>
                      )}
                    </Box>
                    <Box mt={2}>
                      <Typography variant="subtitle2">
                        <span style={{ color: "#000", fontWeight: "bolder" }}>
                          Post Op Diagnosis:
                        </span>{" "}
                        {!enableEdit
                          ? existingPostOperativeChart.data.length > 0
                            ? existingPostOperativeChart.data[0]
                                .post_op_diagnosis
                            : ""
                          : null}
                      </Typography>
                      {enableEdit && (
                        <TextField
                          fullWidth
                          name="post_op_diagnosis"
                          defaultValue={
                            existingPostOperativeChart.data.length > 0
                              ? existingPostOperativeChart.data[0]
                                  .post_op_diagnosis
                              : ""
                          }
                          InputProps={{
                            disableUnderline: !enableEdit,
                            disabled: !enableEdit,
                          }}
                          multiline
                        />
                      )}
                    </Box>
                    <Box mt={4}>
                      <Typography variant="subtitle2">
                        <span style={{ color: "#000", fontWeight: "bolder" }}>
                          Patient/Documents Endorsed to: (Name), RN
                        </span>{" "}
                        {!enableEdit
                          ? existingPostOperativeChart.data.length > 0
                            ? existingPostOperativeChart.data[0].endorsed_to
                            : ""
                          : null}
                      </Typography>
                      {enableEdit && (
                        <TextField
                          fullWidth
                          name="endorsed_to"
                          defaultValue={
                            existingPostOperativeChart.data.length > 0
                              ? existingPostOperativeChart.data[0].endorsed_to
                              : ""
                          }
                          InputProps={{
                            disableUnderline: !enableEdit,
                            disabled: !enableEdit,
                          }}
                          multiline
                        />
                      )}
                    </Box>
                    <Box mt={2}>
                      <Typography variant="subtitle2">
                        <span style={{ color: "#000", fontWeight: "bolder" }}>
                          Patient/Documents Endorsed by: (Name), RN
                        </span>{" "}
                        {!enableEdit
                          ? existingPostOperativeChart.data.length > 0
                            ? existingPostOperativeChart.data[0].endorsed_by
                            : ""
                          : null}
                      </Typography>
                      {enableEdit && (
                        <TextField
                          fullWidth
                          name="endorsed_by"
                          defaultValue={
                            existingPostOperativeChart.data.length > 0
                              ? existingPostOperativeChart.data[0].endorsed_by
                              : ""
                          }
                          InputProps={{
                            disableUnderline: !enableEdit,
                            disabled: !enableEdit,
                          }}
                          multiline
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              )}
            </form>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default FormTen;
