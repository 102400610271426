import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import Modal from "src/components/modal/Modal";
import ModalHeader from "src/components/modal/components/ModalHeader";
import ModalBody from "src/components/modal/components/ModalBody";

import TextInputField from "src/components/forms/TextInputField";
import FlatIcon from "src/components/FlatIcon";
import { useForm } from "react-hook-form";
import ModalFooter from "src/components/modal/components/ModalFooter";
import ActionBtn from "src/components/ActionBtn";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Clock from "react-live-clock";
import Notify from "src/notification/Notify";
import SelectPrescriptionItems from "./SelectPrescriptionItems";
import { toast } from "react-toastify";
import VitalsForm from "src/clinic/components/VitalsForm";
import SelectInputField from "src/components/forms/SelectInputField";

const ReferToSPHDoctorModal = (props, ref) => {
	const { onSuccess } = props;
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();
	const [open, setOpen] = useState(false);
	const [appointment, setAppointment] = useState(null);
	const [loading, setLoading] = useState(false);
	const [doctors, setDoctors] = useState([]);
	const [selectedDoctor, setSelectedDoctor] = useState(0);
	useImperativeHandle(ref, () => ({
		show: show,
		hide: hide,
	}));
	useEffect(() => {
		let t = setTimeout(() => {
			getDoctors();
		}, 300);
		return () => {
			clearTimeout(t);
		};
	}, []);
	const show = (data) => {
		setAppointment(data);
		setOpen(true);
	};
	const hide = () => {
		// setUser(null);
		// reset();
		setOpen(false);
	};
	const getDoctors = () => {
		axios.get(`/v1/clinic/sph-tb-doctors`).then((res) => {
			setDoctors(res.data.data);
		});
	};
	const submit = (data) => {
		setLoading(true);
		let formData = new FormData();
		formData.append("appointment_id", appointment?.id);
		formData.append("doctor_id", selectedDoctor);
		formData.append("_method", "PATCH");

		axios
			.post(`/v1/clinic/refer-to-sph-doctor/${appointment?.id}`, formData)
			.then((res) => {
				// addToList(data);
				setTimeout(() => {
					setLoading(false);
					onSuccess();
					toast.success("Patient successfully referred to SPH!");
				}, 400);
				hide();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Modal open={open} hide={hide} className="min-w-[512px] w-full">
			<ModalHeader
				title={`Assign to SPH TB Doctor`}
				// subtitle="Enter patient details"
				hide={hide}
			/>
			<ModalBody className={`px-4 py-5  bg-white`}>
				<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
					<SelectInputField
						labelClassName="font-bold"
						label="Select to SPH TB Dots doctor"
						placeholder="Select SPH TB Dots doctor"
						className="!mb-0 w-full col-span-3"
						value={selectedDoctor}
						onChange={(data) => {
							setSelectedDoctor(data.target.value);
						}}
						options={[
							...doctors?.map((doctor) => ({
								label: `${doctor?.title} ${doctor?.name}`,
								value: doctor?.id,
							})),
							// {
							// 	label: "Refer to SPH",
							// 	value: "SPH",
							// },
						]}
					/>
				</div>
			</ModalBody>
			<ModalFooter className={"flex items-center"}>
				<ActionBtn
					className="mr-auto"
					type="danger"
					onClick={hide}
					disabled={loading}
				>
					Cancel
				</ActionBtn>
				<ActionBtn
					className="ml-auto"
					type="success"
					onClick={handleSubmit(submit)}
					loading={loading}
				>
					Confirm
				</ActionBtn>
			</ModalFooter>
		</Modal>
	);
};

export default forwardRef(ReferToSPHDoctorModal);
