import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import CisPatientAppointmentListItem from "src/cis-doctor/components/CisPatientAppointmentListItem";
import DateTimeClock from "src/clinic/components/DateTimeClock";
import ActionBtn from "src/components/ActionBtn";
import ButtonV3 from "src/components/ButtonV3";
import FlatIcon from "src/components/FlatIcon";
import LayoutContainer from "src/components/LayoutContainer";
import CisAcceptPatientModal from "./components/modals/CisAcceptPatientModal";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useClinicContext } from "src/clinic/context/ClinicContext";
import Visits from "./components/Visits";
import ClinicInfo from "./components/ClinicInfo";
import Visits2 from "./components/Visits2";
import SelectInputField from "src/components/forms/SelectInputField";
import ReactSelectInputField from "src/components/forms/ReactSelectInputField";

const CisVisits = () => {
	const [selected, setSelected] = useState(null);
	const [rooms, setRooms] = useState([]);

	const getOperatingRooms = () => {
		axios.get(`/v1/operating-rooms/list`).then((res) => {
			setRooms(res.data.data);
		});
	};

	useEffect(() => {
		getOperatingRooms();
	}, []);

	return (
		<LayoutContainer
			icon={<FlatIcon icon="rr-ballot" className="text-xl" />}
			title={`Patient Queue`}
			// subtitle={`Manage your clinic visits`}
			titleChildren={<ClinicInfo />}
		>
			<div className="mb-4 flex items-center justify-between">
				<div className="w-[256px]"></div>
				<DateTimeClock className="!items-center justify-center" />
				<div className="w-[256px]"></div>
			</div>
			<Visits2 />
		</LayoutContainer>
	);
};

export default CisVisits;
