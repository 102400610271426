import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import Axios from "axios";
import Notify from "src/notification/Notify";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import {
  TextField,
  Button,
  Grid,
  DialogContent,
  FormHelperText,
  DialogActions,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Select from "react-select";
import { CheckSquare, XCircle } from "react-feather";

function CreateAppointment({ patient_id, close, reload, trace_number }) {
  const { users } = useContext(UsersData);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [doctorList, setDoctorList] = useState({
    data: [],
    ready: false,
  });

  const [serviceList, setServiceList] = useState({
    data: [],
    ready: false,
  });

  const [selectedRate, setSelectedRate] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const fetchAllDoctors = useCallback(() => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    Axios.get("nurse/get-all-doctors", { params })
      .then((response) => {
        const data = response.data;
        setDoctorList({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users.user_id, users.management_id]);

  const getAllDoctorsServices = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    try {
      const request = await axios.get("doctor/get/all-service", { params });
      setServiceList({ data: request.data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users]);

  const getFormData = (object) => {
    const formData = new FormData();
    formData.append("app_date", selectedDate.toLocaleString());
    formData.append("patient_id", patient_id);
    formData.append("fee", selectedRate);
    formData.append("service", selectedService);
    formData.append("trace_number", trace_number);

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  useEffect(() => {
    fetchAllDoctors();
    getAllDoctorsServices();
  }, [fetchAllDoctors, getAllDoctorsServices, selectedRate]);

  return (
    <Fragment>
      <Formik
        initialValues={{
          username: users.username,
          user_id: users.user_id,
          management_id: users.management_id,
          main_mgmt_id: users.main_mgmt_id,
          password: "",
          doctor: "",
          reason: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required("Password is required."),
          doctor: Yup.string().required("Doctor is required."),
          reason: Yup.string().required("Reason is required."),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          // var error = [];
          if (selectedService === null) Notify.fieldRequired("service");
          else {
            try {
              const request = await Axios.post(
                "nurse/patient/create-appointment",
                getFormData(values)
              );
              if (request.data === "pass-invalid") {
                setErrors({ password: "Password is invalid." });
                Notify.fieldInvalid("password");
              }
              if (request.data === "has-appointment") {
                setErrors({ submit: "Patient has an active appointment." });
                Notify.customToast(
                  "Appointment Active",
                  "Patient has an active appointment."
                );
              }

              if (request.data === "success") {
                Notify.successRequest("new appointment");
                resetForm();
                close();
                reload();
                setSubmitting(true);
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Box>
                  <DatePicker
                    required
                    fullWidth
                    disablePast
                    margin="normal"
                    id="date-picker"
                    label="Date of Appointment"
                    value={selectedDate}
                    inputVariant="outlined"
                    onChange={(date) => setSelectedDate(date)}
                    name="app_date"
                  />
                </Box>

                <Box mb={2}>
                  <TimePicker
                    required
                    fullWidth
                    margin="normal"
                    id="time-picker"
                    label="Time of Appointment"
                    value={selectedDate}
                    inputVariant="outlined"
                    onChange={(date) => setSelectedDate(date)}
                    name="app_time"
                  />
                </Box>

                <Box mb={1}>
                  <TextField
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    fullWidth
                    error={Boolean(touched.doctor && errors.doctor)}
                    helperText={touched.doctor && errors.doctor}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "doctor",
                        e.target.value === null ? "" : e.target.value
                      );
                    }}
                    value={values.doctor}
                    name="doctor"
                    label="Doctor"
                    variant="outlined"
                  >
                    <option value={""}> Select </option>
                    {doctorList.data.length > 0 &&
                      doctorList.data &&
                      doctorList.data.map((data, index) => {
                        return (
                          <option key={index} value={data.value}>
                            {" "}
                            {data.label}{" "}
                          </option>
                        );
                      })}
                  </TextField>
                </Box>

                <Box>
                  <TextField
                    required
                    error={Boolean(touched.reason && errors.reason)}
                    helperText={touched.reason && errors.reason}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reason}
                    fullWidth
                    label="Reason of Appointment"
                    margin="normal"
                    variant="outlined"
                    multiline
                    name="reason"
                  />
                </Box>

                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Box mt={2}>
                      <Select
                        options={serviceList.data}
                        onChange={(data) => {
                          setFieldValue("service", data.service);
                          setSelectedService(data.services);
                          setSelectedRate(data.amount);
                        }}
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          control: (provided) => ({ ...provided, height: 55 }),
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <Box>
                      <TextField
                        required
                        value={selectedRate === null ? "" : selectedRate}
                        fullWidth
                        label="Rate"
                        margin="normal"
                        variant="outlined"
                        name="fee"
                        type="number"
                        InputProps={{
                          readOnly: true,
                          inputProps: {
                            min: 1,
                            max: 10000,
                            step: 0.01,
                          },
                        }}
                        autoComplete="off"
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Box mb={1}>
                  <TextField
                    required
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    type="password"
                    fullWidth
                    label="Password"
                    margin="normal"
                    variant="outlined"
                    name="password"
                  />
                </Box>
              </MuiPickersUtilsProvider>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={close}
                startIcon={<XCircle />}
              >
                Close
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<CheckSquare />}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Fragment>
  );
}

export default CreateAppointment;
