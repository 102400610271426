import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  CardContent,
  CardActions,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";

const DrugTestEditForm = ({ info, close }) => {
  const { users } = useContext(UsersData);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", info.data.trace_number);
    formdata.set("order_id", info.data.order_id);
    setResultSubmitting(true);
    axios
      .post("laboratory/drug-test/edit-result", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("order result edit successful");
          close();
          setSavebtnDisabled(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setResultSubmitting(false));
  };

  return (
    <>
      <CardContent>
        <Fragment>
          <form onSubmit={handleSaveResult}>
            {info.ready && (
              <>
                <Box hidden={!Boolean(parseInt(info.data.two_panels))}>
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <Typography variant="subtitle1">
                        <b> DRUG TEST (2 PANELS)</b>
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={1}>
                    <TextField
                      label="Result"
                      fullWidth
                      name="two_panels_result"
                      variant="outlined"
                      multiline
                      defaultValue={
                        info.data.two_panels_result === null
                          ? ""
                          : info.data.two_panels_result
                      }
                    />
                  </Box>
                </Box>

                <Box hidden={!Boolean(parseInt(info.data.three_panels))}>
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <Typography variant="subtitle1">
                        <b> DRUG TEST (3 PANELS)</b>
                      </Typography>
                    </Box>
                  </Box>

                  <Box mt={1}>
                    <TextField
                      label="Result"
                      fullWidth
                      name="three_panels_result"
                      variant="outlined"
                      multiline
                      defaultValue={
                        info.data.three_panels_result === null
                          ? ""
                          : info.data.three_panels_result
                      }
                    />
                  </Box>
                </Box>

                <Box hidden={!Boolean(parseInt(info.data.five_panels))}>
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <Typography variant="subtitle1">
                        <b> DRUG TEST (5 PANELS)</b>
                      </Typography>
                    </Box>
                  </Box>

                  <Box mt={1}>
                    <TextField
                      label="Result"
                      fullWidth
                      name="five_panels_result"
                      variant="outlined"
                      multiline
                      defaultValue={
                        info.data.five_panels_result === null
                          ? ""
                          : info.data.five_panels_result
                      }
                    />
                  </Box>
                </Box>
              </>
            )}

            <Box display="flex">
              <Box flexGrow={1} />
              <Box>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={savebtnDisabled}
                    startIcon={
                      resultSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Save Result
                  </Button>
                </CardActions>
              </Box>
            </Box>
          </form>
        </Fragment>
      </CardContent>
    </>
  );
};

export default DrugTestEditForm;
