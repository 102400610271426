import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef, useState } from "react";
import ActionBtn from "src/components/ActionBtn";
import CardContainer from "src/components/CardContainer";
import FlatIcon from "src/components/FlatIcon";
import TextInputField from "src/components/forms/TextInputField";
import LayoutContainer from "src/components/LayoutContainer";
import TableV2 from "src/components/table/TableV2";
import useDataTable from "src/helpers/useDataTable";
import {
	faListUl,
	faTruckMoving,
	faUserInjured,
} from "@fortawesome/free-solid-svg-icons";
import SelectInputField from "src/components/forms/SelectInputField";
import LMISItemOrderList from "./LMISItemOrderList";
import LMISViewItemsList from "./LMISViewItemsList";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const LMISConsignmentPrint = () => {
	const history = useHistory();
	const { id } = useParams();
	const [data, setData] = useState(null);
	useEffect(() => {
		if (id) {
			getData();
		}
	}, [id]);
	const getData = () => {
		axios.get(`v1/consignment/show/${id}`).then((res) => {
			setData(res.data.data);
		});
	};
	const approve = () => {
		axios
			.post(`v1/consignment/approve/${id}`, {
				_method: "PATCH",
			})
			.then((res) => {
				setTimeout(() => {
					history.push("/sph/app/pho/lmis");
				}, 200);
				toast.success("Consignment approved successfully!");
			});
	};
	return (
		<>
			<div className="bg-white w-[8.5in] h-[13in] my-4 mx-auto">Test</div>
		</>
	);
};

export default LMISConsignmentPrint;
